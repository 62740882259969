import React, { useRef } from 'react';
import { OptionProps } from './types';

function MultiSelectOption<OptionType, SelectType>({
  active,
  index,
  mouseMoved,
  onAddSelectedOption,
  onSelectedOptionIndexChange,
  option,
  renderOption,
  usingMouse,
  setUsingMouse,
  skipRootBlur
}: OptionProps<OptionType, SelectType>) {
  const domNode = useRef<HTMLDivElement>(null);

  // if we're NOT using the mouse and this option is active, focus on it
  if (active && !usingMouse) {
    domNode.current?.scrollIntoView(false);
  }

  const onMouseOver = () => {
    // only pay attention to the mouse over if the mouse has moved;
    // if the user is scrolling via the keyboard, this event will get triggered when the container scrolls
    if (mouseMoved) {
      // set the index to this option, and tell our parent that we're using the mouse
      // if the mouse is being used, we don't want to scroll the node into view since it's implied that it's already in view;
      // scrolling it into view while using the mouse makes things jittery
      onSelectedOptionIndexChange(index);
      setUsingMouse(true);
    }
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onAddSelectedOption(option);
    e.preventDefault();
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      className={active ? 'multiselect-option-active' : 'multiselect-option'}
      onMouseDown={skipRootBlur}
      onMouseOver={onMouseOver}
      onClick={onClick}
      ref={domNode}
    >
      {renderOption(option, index)}
    </div>
  );
}

export default MultiSelectOption;
