import React from 'react';
import { unescape, isEmpty } from 'lodash';
import I18n from 'common/i18n';
import { NULL_GROUPING } from './Constants';
import { ValueRenderer as valueRenderer, IValueRendererProps } from './ValueRenderer';
import { shouldRenderAsHtml } from './helpers/TableColumnFormatting';

export const GroupAndTotalRenderer = (props: IValueRendererProps) => {
  // Since props.data can be either TData or undefined, we want to skip rendering
  // if the dataset is empty or if there are zero results, as DataTypeFormatter expects a result.
  // Sample props.data = {date: '2021-01-30T00:00:00.000', id: '1'}
  // isRowPinned is used to determine if it is a Totals row
  if (isEmpty(props.data) && props.node.isRowPinned()) {
    return null;
  }

  const footer = props.node.footer;
  const isRootLevel = props.node.level === -1;
  const totalLabel = I18n.t('shared.visualizations.charts.table.total');
  const subtotalLabel = I18n.t('shared.visualizations.charts.table.subtotal');
  const noValueText = I18n.t('shared.visualizations.charts.common.summary_table.no_value');
  const columnMetadata = props.context.columnMetadata ?? [];

  let value = '';

  if (props.node.key === NULL_GROUPING) {
    value = noValueText;
  } else {
    // We are unescaping the value returned by valueRenderer
    // because AgGrid auto group will escape the value we pass, and we do not want to double escape
    // except URL or Email
    value = shouldRenderAsHtml(props.node, columnMetadata)
      ? valueRenderer(props)
      : unescape(valueRenderer(props));
  }

  if (footer) {
    if (isRootLevel) return <span>{totalLabel}</span>;
    else
      return (
        <span>
          {subtotalLabel} {value}
        </span>
      );
  } else return <span>{value}</span>;
};
