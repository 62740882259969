import { ColumnRef, Expr, NamedExpr, TypedExpr, isColumnEqualIgnoringPosition, isExpressionEqualIgnoringPosition, isTypedColumnRef, AnalyzedSelectedExpression, UnAnalyzedSelectedExpression } from 'common/types/soql';
import React from 'react';
import * as _ from 'lodash';
import ColumnPicker from '../ColumnPicker';
import { AstNode, ExprProps, matchEexpr, matchEexprNA } from '../VisualExpressionEditor';
import { Option, option, none, some } from 'ts-option';
import { getColumnSubset } from '../../lib/filter-helpers';
import { zipSelection } from '../../lib/soql-helpers';
import { ProjectionInfo, ProjectionInfoNA, ViewColumnColumnRef } from '../../lib/selectors';
import { PickableColumn, ProjectionExpr, Selected, matchPicked } from '../../lib/column-picker-helpers';
import { whichAnalyzer } from '../../lib/feature-flag-helpers';
import I18n from 'common/i18n';
import { Either } from 'common/either';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

const toViewColumnColumnRef = (columnRef: ColumnRef, columns: ViewColumnColumnRef[]): Option<ViewColumnColumnRef> => (
  option(_.find(columns, (c) => isColumnEqualIgnoringPosition(c.ref, columnRef)))
);

interface SelectedExpression {
  unanalyzed: UnAnalyzedSelectedExpression,
  analyzed: AnalyzedSelectedExpression
}

const aliasColumnRefToViewColumnColumnRef = (columnRef: ColumnRef, typedExpr: Expr, columns: ViewColumnColumnRef[], projectionInfo?: Either<ProjectionInfo, ProjectionInfoNA>): Option<ViewColumnColumnRef> => {
  if (_.isUndefined(projectionInfo)) {
    return none;
  }
  return projectionInfo.fold(
    pi => findMatchingExprFromAlias(columnRef, typedExpr, pi).flatMap(({analyzed}) => {
      if (isTypedColumnRef(analyzed.expr)) {
        return toViewColumnColumnRef(analyzed.expr, columns);
      } else {
        return none;
      }
    }),
    pi => findMatchingExprFromAliasNA(columnRef, typedExpr, pi).flatMap(({expr}) => {
      if (isTypedColumnRef(expr)) {
        return toViewColumnColumnRef(expr, columns);
      } else {
        return none;
      }
    })
  );
};

const findMatchingExprFromAlias = (columnRef: ColumnRef, typedExpr: Expr, projectionInfo?: ProjectionInfo): Option<SelectedExpression> => {
  if (_.isUndefined(projectionInfo)) { return none; }
  return projectionInfo.flatMap(({ tableAliases, viewContext, unanalyzed, analyzed }) => {

    const selectedExpressions = _.zip(
      zipSelection(viewContext, tableAliases, unanalyzed, analyzed).exprs,
      analyzed
    ).map(([unanalyzedSelExpr, analyzedSelExpr]) => {
      if (!_.isUndefined(unanalyzedSelExpr) && !_.isUndefined(analyzedSelExpr)) {
        return some({unanalyzed: unanalyzedSelExpr, analyzed: analyzedSelExpr});
      } else {
        return none;
      }
    }).filter(x => x.nonEmpty).map(x => x.get);

    return option(_.find(selectedExpressions, ({ analyzed: analyzedSelExpr }) => {
      return columnRef.value === analyzedSelExpr.name && isExpressionEqualIgnoringPosition(typedExpr, analyzedSelExpr.expr);
    }));
  });
};
const findMatchingExprFromAliasNA = (columnRef: ColumnRef, typedExpr: Expr, projectionInfo?: ProjectionInfoNA): Option<NamedExpr> => {
  if (_.isUndefined(projectionInfo)) { return none; }
  return projectionInfo.flatMap(({ selection }) => {
    return option(_.find(selection.exprs, (namedExpr) => {
      return columnRef.value === namedExpr.name?.name && isExpressionEqualIgnoringPosition(typedExpr, namedExpr.expr);
    }));
  });
};

/* Given column ref, typed expr, and projection info, find the analyzed selected expression and translate it into a ProjectionExpr. */
const toProjectionExpr = (columnRef: ColumnRef, typedExpr: Expr, projectionInfo?: Either<ProjectionInfo, ProjectionInfoNA>): Option<ProjectionExpr> => {
  if (_.isUndefined(projectionInfo)) {
    return none;
  }

  return projectionInfo.fold(
    pi => findMatchingExprFromAlias(columnRef, typedExpr, pi).flatMap(({analyzed, unanalyzed}) => {
      return some({
        expr: unanalyzed.expr,
        typedExpr: analyzed.expr,
        name: analyzed.name,
        ref: (unanalyzed.name) ? some(columnRef) : none
      } as ProjectionExpr);
    }),
    pi => findMatchingExprFromAliasNA(columnRef, typedExpr, pi).flatMap(({expr, name}) => {
      return some({
        expr,
        typedExpr: expr,
        name: name?.name,
        ref: name ? some(columnRef) : none
      } as ProjectionExpr);
    })
  );
};

export default function EditColumnRef(props: ExprProps<ColumnRef, TypedExpr>) {
  const {
    addFilterType,
    columns,
    eexpr,
    forceShowSuccess,
    hasGroupOrAggregate,
    isTypeAllowed,
    projectionInfo,
    querySucceeded,
    showKebab,
    showRemove,
    update
  } = props;

  const onSelectColumn = (picked: PickableColumn) => {
    matchPicked(
      picked,
      (vccr: ViewColumnColumnRef) => update(
        whichAnalyzer(
          () => vccr.ref,
          () => vccr.typedRef
        )()
      ),
      (pexpr: ProjectionExpr) => update(
        whichAnalyzer(
          () => pexpr.ref.getOrElseValue(pexpr.expr as any) as Expr,
          () => pexpr.typedExpr // CMU NOTE: hope this is ok!! idk!
        )()
      )
    );
  };

  let selected: Option<Selected> = toViewColumnColumnRef(
    eexpr.fold(eexprOA => eexprOA.untyped, eexprNA => eexprNA.expr as ColumnRef),
    columns
  );

  eexpr.fold(
    eexprOA => matchEexpr(
      eexprOA,
      (editable) => {
        if (isTypedColumnRef(editable.typed) && selected.isEmpty) {
          // we're dealing with an aliased Column Ref, translate into the actual column ref
          selected = aliasColumnRefToViewColumnColumnRef(editable.untyped, editable.typed, columns, projectionInfo);
        }
        if (!isTypedColumnRef(editable.typed)) { // calculated or aggregated column
          // find the analyzed selected expression so that we know the current alias of the query column
          selected = toProjectionExpr(editable.untyped, editable.typed, projectionInfo);
        }
      },
      (_uneditable) => _.noop()
    ),
    eexprNA => matchEexprNA(
      eexprNA,
      (editable) => {
        if (isTypedColumnRef(editable.expr) && selected.isEmpty) {
          // we're dealing with an aliased Column Ref, translate into the actual column ref
          selected = aliasColumnRefToViewColumnColumnRef(editable.expr, editable.expr, columns, projectionInfo);
        }
      },
      (_uneditable) => _.noop()
    )
  );

  const candidates = columns.filter(c => isTypeAllowed(c.typedRef.soql_type));
  const showSuccess = forceShowSuccess !== undefined && forceShowSuccess && querySucceeded;

  return (
    <AstNode
      {...props}
      className="column-ref"
      removable={showRemove !== undefined && showRemove}
      changeableElement={showKebab ? 1 : undefined}
      showSuccess={showSuccess}>
      <ColumnPicker
        className="btn btn-default"
        prompt={I18n.t('shared.explore_grid.edit_nodes.choose_column')}
        onSelect={onSelectColumn}
        projectionInfo={projectionInfo || whichAnalyzer<ProjectionInfo, ProjectionInfoNA>(() => none, () => none)()}
        selected={selected}
        columns={candidates}
        columnSubset={getColumnSubset(addFilterType, hasGroupOrAggregate)} />
      {showKebab &&
        <ForgeIconButton>
          <button
            type="button"
            data-testid="edit-column-ref-kebab-btn"
            aria-label={I18n.t('shared.explore_grid.vee_kebab_menu.button_label')}
            className="tyler-icons">
            <ForgeIcon name="more_vert" />
          </button>
        </ForgeIconButton>
      }
    </AstNode>
  );
}
