import $ from 'jquery';
import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import Button, { VARIANTS, SIZES } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { ForgeIcon } from '@tylertech/forge-react';

/** Props for an individual view tab. */
interface TabProps {
  /** Function to call when this tab is selected. */
  onClick: () => void;
  /** Whether this tab is selected. */
  selected: boolean;
  /** The name of the tab. */
  tabName: 'visualization' | 'summary-table';
  /** Unique identifier set for the visualization. Set in BaseVisualization. */
  visualizationId: string;
  /** Used to provide more useful label text. */
  visualizationType: 'map' | 'chart' | 'table';
  newVizCardLayoutEnabled: boolean;
}

const ViewTab: FunctionComponent<TabProps> = ({
  onClick,
  selected,
  tabName,
  visualizationId,
  visualizationType,
  newVizCardLayoutEnabled
}) => {
  const scope = 'shared.visualizations.charts.common.view_tabs';

  // If forge is turned on, we want to use the new chart icon for charts.
  // If the forge icon is off, we use all the old socrata icons.
  const tabIcon =
    newVizCardLayoutEnabled && visualizationType === 'chart' ? (
      <ForgeIcon name="bar_chart" aria-hidden={true} tab-index={-1} />
    ) : (
      <SocrataIcon name={visualizationType as IconName} aria-hidden={true} tab-index={-1} />
    );

  return (
    <Button
      aria-controls={`${tabName}-panel-${visualizationId}`}
      aria-label={I18n.t(`aria_label.${visualizationType}`, { scope })}
      aria-selected={selected}
      className={classnames(`btn-${tabName}-tab`, { selected })}
      id={`${tabName}-button-${visualizationId}`}
      onClick={() => {
        onClick();
        $(`#${tabName}-panel-${visualizationId} label`).trigger('focus');
      }}
      onBlur={(e) => {
        hideFlyout(e.target);
      }}
      onFocus={(e) => {
        showFlyout(e.target, I18n.t(visualizationType, { scope }));
      }}
      onMouseEnter={(e) => {
        showFlyout(e.target, I18n.t(visualizationType, { scope }));
      }}
      onMouseLeave={(e) => {
        hideFlyout(e.target);
      }}
      role="tab"
      size={SIZES.X_SMALL}
      variant={VARIANTS.TRANSPARENT}
    >
      {/* We use slightly more descriptive text for screenreaders */}
      {tabIcon}
      <span className="btn-visualization-tab-label" aria-hidden={true} hidden={newVizCardLayoutEnabled}>
        {I18n.t(visualizationType, { scope })}
      </span>
    </Button>
  );
};

/** Props for the ViewTabs component. */
export interface Props {
  /** Whether the visualization is a map. */
  isMap: boolean;
  /** Function to call when the user selects the table tab. */
  onClickTableTab: () => void;
  /** Function to call when the user selects the visualization tab. */
  onClickVisualizationTab: () => void;
  /** Whether the table panel should be showing. */
  shouldShowTable: boolean;
  /** Unique identifier set for the visualization. Set in BaseVisualization. */
  visualizationId: string;
  newVizCardLayoutEnabled: boolean;
}

/**
 * Renders tabs for switching between the summary table and chart or map view of a visualization.
 * See ViewTabsContainer for the tab panel implementation.
 */
const ViewTabs: FunctionComponent<Props> = ({
  isMap,
  onClickTableTab,
  onClickVisualizationTab,
  shouldShowTable,
  visualizationId,
  newVizCardLayoutEnabled
}) => {
  const visualizationType = isMap ? 'map' : 'chart';
  return (
    <>
      <ViewTab
        onClick={onClickVisualizationTab}
        selected={!shouldShowTable}
        tabName={'visualization'}
        visualizationId={visualizationId}
        visualizationType={visualizationType}
        newVizCardLayoutEnabled={newVizCardLayoutEnabled}
      />
      <ViewTab
        onClick={onClickTableTab}
        selected={shouldShowTable}
        tabName={'summary-table'}
        visualizationId={visualizationId}
        visualizationType="table"
        newVizCardLayoutEnabled={newVizCardLayoutEnabled}
      />
    </>
  );
};

function showFlyout(target: EventTarget, text: string) {
  target.dispatchEvent(
    new CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
      detail: {
        element: target,
        content: $('<div>', { class: 'socrata-flyout-title' }).text(text),
        rightSideHint: false,
        belowTarget: false,
        dark: true
      },
      bubbles: true
    })
  );
}

function hideFlyout(target: EventTarget) {
  target.dispatchEvent(
    new CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
      detail: null,
      bubbles: true
    })
  );
}

export default ViewTabs;
