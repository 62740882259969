import _ from 'lodash';

import { ConfigurationsNotificationsState, Preferences } from 'common/notifications/types';

/**
 * Filters a list of preferences down to only the preferences
 * enabled by the given server-side configuration.
 */
const calculateAvailablePreferences = (configurations: ConfigurationsNotificationsState, preferences: Preferences): Array<string> => {
  const {
    haveOtherDatasetRights,
    shouldShowMyAssetsApprovalsPreference,
    showApprovalPreference,
    showNewAssetPublishedPreference,
    showUserPreference
  } = configurations;

  const availablePreferences = _.keys(preferences);
  if (!haveOtherDatasetRights) {
    _.pull(availablePreferences, 'all_asset_permission_change', 'all_assets_deleted', 'all_asset_collaborator_change');
  }

  if (!showUserPreference) {
    _.pull(availablePreferences, 'user_accounts');
  }

  if (!showNewAssetPublishedPreference) {
    _.pull(availablePreferences, 'all_assets_published');
  }

  if (!showApprovalPreference) {
    _.pull(availablePreferences, 'all_assets_approvals');
  }

  if (!shouldShowMyAssetsApprovalsPreference) {
    _.pull(availablePreferences, 'my_assets_approvals');
  }



  return availablePreferences;
};

export default calculateAvailablePreferences;
