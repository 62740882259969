/* Imports */
/* ============================================================================= */
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { BaseGuidanceSummaryV2Helper, GuidanceCheckSummary, WorkflowGuidanceCheckMethods } from 'common/core/approvals/Types';
import { summarizeGuidanceCheckMethod, createWorkflowGuidanceHelperMethodsObject } from '.';





/* Method */
/* ============================================================================= */
/**
 * Check for which workflow has a published asset UID. Throws an error if more than 1 is found
 * @param guidance GuidanceSummaryV2 object
 * @param guidanceHelper BaseGuidanceSummaryV2Helper object
 * @returns GuidanceCheckSummary object
 */
export const summarizePublishedUid = (guidance: GuidanceSummaryV2, guidanceHelper: BaseGuidanceSummaryV2Helper): GuidanceCheckSummary<string> => {
  const response = summarizeGuidanceCheckMethod<string, undefined>(
    guidance,
    createWorkflowGuidanceHelperMethodsObject<WorkflowGuidanceCheckMethods<string>>(guidanceHelper, 'publishedUid'),
    undefined
  );

  if (response.length > 1)
    throw new Error('Invalid asset state detected - withGuidanceV2.summarizePublishedUid');

  return response;
};

export default summarizePublishedUid;
