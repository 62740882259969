// Rails API endpoints at /internal
import { fetchJsonWithDefaultHeaders } from 'common/http';

const BASE_URL = '/internal';

export const moduleList = () =>
  fetchJsonWithDefaultHeaders(`${BASE_URL}/config_gates/module_list.json`);

export const featureFlagList = () =>
  fetchJsonWithDefaultHeaders(`${BASE_URL}/config_gates/feature_flag_list.json`);

export const featureFlagTypes = () =>
  fetchJsonWithDefaultHeaders(`${BASE_URL}/feature_flag_types.json`);

export const domainFeatureFlags = (cname) =>
  fetchJsonWithDefaultHeaders(`${BASE_URL}/domains/${cname}/feature_flags.json`);

export const flushCache = (cname) => {
  const apiPath = `${BASE_URL}/domains/${cname}/flush_cache`;
  return fetchJsonWithDefaultHeaders(apiPath, { method: 'POST' });
};

// featureFlags is an object, keys should be flag name, values should be flag value
export const setDomainFeatureFlags = (cname, featureFlags) => {
  const apiPath = `${BASE_URL}/domains/${cname}/set_feature_flags.json`;
  const body = { feature_flags: featureFlags };

  const options = {
    body: JSON.stringify(body),
    method: 'POST'
  };

  return fetchJsonWithDefaultHeaders(apiPath, options);
};

// featureFlags is an object, keys should be flag name, values should be flag value
export const resetDomainFeatureFlags = (cname, featureFlags) => {
  const apiPath = `${BASE_URL}/domains/${cname}/set_feature_flags.json`;
  const stringifiedKeys = Object.keys(featureFlags)  // necessary for the endpoint to handle correctly
    .reduce((obj, key) => ({ ...obj, [key]: 'Reset To Default' }), {});

  const body = { reset_to_default: stringifiedKeys, feature_flags: stringifiedKeys };

  const options = {
    body: JSON.stringify(body),
    method: 'POST'
  };

  return fetchJsonWithDefaultHeaders(apiPath, options);
};

export const auditDomainExpectation = (targetCname, contractKey) => {
  const apiPath = `${BASE_URL}/config_contracts/audit.json?target_cname=${targetCname}&contract_key=${contractKey}`;
  return fetchJsonWithDefaultHeaders(apiPath);
};

export const enforceDomainExpectation = (targetCname, contractKey) => {
  const apiPath = `${BASE_URL}/config_contracts/enforce.json?domain_cname=${targetCname}&contract_key=${contractKey}`;
  const options = { method: 'POST' };
  return fetchJsonWithDefaultHeaders(apiPath, options);
};
