import type {
  CatalogInteractiveUser,
  UsersCatalogSearchResult,
  CatalogUserOrTeam
} from 'common/types/users/catalogUsers';
import type { AccessManagerPublishedToState } from '../types';
import type PublishedToActions from '../actions/PublishedToActionType';
import type PermissionsActions from '../actions/PermissionsActionType';

import { PublishedToActionTypes } from 'common/components/AccessManager/actions/PublishedToActions';
import { PermissionsActionsTypes } from 'common/components/AccessManager/actions/PermissionsActions';
import { filterSearchResults } from 'common/components/AccessManager/Util';

/** User is typing into the search box */
const publishedToSearchQueryChanged = (
  state: AccessManagerPublishedToState,
  query: string
): AccessManagerPublishedToState => ({
  ...state,
  query,

  // blank out the results as well
  results: null
});

/** PublishedToSagas will call this with the search results from the catalog */
const publishedToSearchResultsFetchSuccess = (
  state: AccessManagerPublishedToState,
  results: UsersCatalogSearchResult,
  existingUsers: CatalogUserOrTeam[]
): AccessManagerPublishedToState => ({
  ...state,
  results: filterSearchResults(results, state.selectedUsers, existingUsers, state.query || '', state.mode)
});

/** AddUsersSaga will call this when catalog query fails */
const publishedToSearchResultsFetchFail = (
  state: AccessManagerPublishedToState,
  error: any
): AccessManagerPublishedToState => {
  console.error('Error fetching user search results', error);
  return state;
};

/** User is selected from the search results */
const addSelectedPublishedTo = (
  state: AccessManagerPublishedToState,
  user: CatalogUserOrTeam
): AccessManagerPublishedToState => ({
  ...state,
  selectedUsers: [...(state.selectedUsers || []), user],
  results: null,
  query: ''
});

/** Remove a user that has been selected */
const removeSelectedPublishedTo = (
  state: AccessManagerPublishedToState,
  user: CatalogUserOrTeam
): AccessManagerPublishedToState => {
  const selectedUsers = state.selectedUsers || [];

  let indexToRemove = null;

  // ideally, we have the user id here
  // sometimes, however, we have to rely on the email address
  // (note to self: should we just _always_ rely on email? What about for teams?)
  if (user.id) {
    indexToRemove = selectedUsers.findIndex((u) => user.id === u.id);
  } else {
    indexToRemove = selectedUsers.findIndex((u) => user.email === u.email);
  }

  if (indexToRemove !== null) {
    selectedUsers.splice(indexToRemove, 1);
  }

  return {
    ...state,
    selectedUsers: [...selectedUsers]
  };
};

const resetState = (state: AccessManagerPublishedToState) => ({
  ...state,
  selectedUsers: [],
  query: '',
  results: null
});

export default (
  state: AccessManagerPublishedToState = {} as any,
  action: PublishedToActions | PermissionsActions
) => {
  switch (action.type) {
    // publishedToActions
    case PublishedToActionTypes.PUBLISHED_TO_SEARCH_QUERY_CHANGED:
      return publishedToSearchQueryChanged(state, action.payload.query);
    case PublishedToActionTypes.PUBLISHED_TO_SEARCH_RESULTS_FETCH_SUCCESS:
      return publishedToSearchResultsFetchSuccess(
        state,
        action.payload.results,
        action.payload.existingUsers
      );
    case PublishedToActionTypes.PUBLISHED_TO_SEARCH_RESULTS_FETCH_FAIL:
      return publishedToSearchResultsFetchFail(state, action.payload.error);
    case PublishedToActionTypes.ADD_SELECTED_PUBLISHED_TO:
      return addSelectedPublishedTo(state, action.payload.user);
    case PublishedToActionTypes.REMOVE_SELECTED_PUBLISHED_TO:
      return removeSelectedPublishedTo(state, action.payload.user);

    case PermissionsActionsTypes.ADD_USERS: // Dispatched when the "Add" button is clicked and the users have been added to the list of users with access
      return resetState(state);
    default:
      return state;
  }
};
