import _ from 'lodash';

import { defaultHeaders, fetchJson } from 'common/http';

// Extracts column info from /api/views/four-four response and converts it into
// picklist options format.
const formatColumnData = (response) => {
  const columns = _.get(response, 'columns', []);

  return columns.map((column) => {
    return {
      title: column.name,
      value: column.fieldName,
      column_type: column.dataTypeName
    };
  });
};

// Converts soql response fetching top x columns into picklist options format.
const formatColumnValues = (data, column) => {
  return data.map((datum) => {
    return { title: datum[column], value: datum[column] };
  });
};

const datasetAPI = {
  // Get columns in the given view along with the column's type(datatype).
  getColumns: (params) => {
    return fetchJson(`/api/views/${params.viewId}.json`, {
      method: 'GET',
      headers: defaultHeaders,
      credentials: 'same-origin'
    }).then(formatColumnData);
  },
  // Get top x values in the params.column in params.viewId matching
  // the given params.search using like operator.
  // Params:
  //    column      : column in the dataset to search for matching values
  //    searchText  : search term
  //    viewId      : view id to search for matching values.
  getMatchingColumnValues: (params) => {
    const { column, viewId } = params;
    let { searchText } = params;
    searchText = encodeURIComponent(_.toUpper(searchText));

    const conditionText = `$select=${column}&$group=${column}&$$read_from_nbe=true` +
      `&$where=UPPER(${column}) like '%25${searchText}%25'`;

    return fetchJson(`/resource/${viewId}.json?${conditionText}`, {
      method: 'GET',
      headers: defaultHeaders,
      credentials: 'same-origin'
    }).then((response) => formatColumnValues(response, column));
  }
};

export default datasetAPI;
