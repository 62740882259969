// Vendor Imports
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './colorBuckets.scss';

// Project Imports
import Tabs from '../ColorAndCharmPicker/Tabs';
import I18n from 'common/i18n';
import { ENTER, ESCAPE, SPACE, isolateEventByKeys } from 'common/dom_helpers/keycodes_deprecated';
import { EMPTY_TRANSPARENT_COLOR } from 'common/authoring_workflow/constants';
import { getSiteAppearanceColorPickerHexCodes } from 'common/visualizations/helpers/SiteAppearanceColors';

// Constants
const scope = 'shared.components.color_picker';
const DEFAULT_COLOR_PALETTE = [
  '#f0f2ff', '#eff8fb', '#eff8fb', '#f7f7f7', '#fdeddd', '#f6eef7', '#feffc8', '#fdebe1', '#fdffac',
  '#bed7e8', '#b5e3e2', '#b5cce5', '#cccccc', '#fac07e', '#bfc8e3', '#a2deb2', '#f8b2b8', '#fad04b',
  '#71abd9', '#6ac5a3', '#8f92c9', '#969696', '#f98d27', '#6da7d2', '#4cb6c6', '#f45ca1', '#f98d27',
  '#3d7ec0', '#31a75a', '#894baa', '#636363', '#e25200', '#2a919a', '#387bbb', '#c3008c', '#ec3001',
  '#1e489f', '#067126', '#80007f', '#252525', '#a33200', '#0f6f59', '#2d2298', '#79007a', '#ba001e'
];
export class ColorBuckets extends Component {
  constructor(props) {
    super(props);

    const siteAppearanceHexCodes = getSiteAppearanceColorPickerHexCodes();

    this.state = {
      selectedColor: props.value,
      selectedTab: props.selectedTab || ColorBuckets.default,
      siteAppearanceHexCodes
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({ selectedColor: nextProps.selectedColor });
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.showingBuckets && this.props.showingBuckets) {
      this.colorBucketsRef.focus();
    }
  };

  onChangeInputColor = ({ event, applyOnSixDigitRgbOnly }) => {
    const selectedColor = event.target.value;
    this.setState({ selectedColor });
    const regex = applyOnSixDigitRgbOnly ?
      /(^#[0-9A-F]{6}$)/i :
      /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

    const isValidColor = regex.test(selectedColor);

    if (isValidColor) {
      this.props.onClickBucket(selectedColor);
    }
  };

  onKeyUpColorBucket = (color, event) => {
    const { keyCode } = event;
    isolateEventByKeys(event, [ENTER, SPACE, ESCAPE]);

    if (keyCode === ENTER || keyCode === SPACE) {
      this.props.onClickBucket(color);
    } else if (keyCode === ESCAPE) {
      this.props.onClose();
    }
  };

  onKeyDownColorBucket = (event) => {
    isolateEventByKeys(event, [ENTER, SPACE]);
  };

  onKeyUpHexInput = (event) => {
    const { keyCode } = event;
    isolateEventByKeys(event, [ENTER, ESCAPE]);

    if (keyCode === ENTER) {
      this.onChangeInputColor({ event, applyOnSixDigitRgbOnly: false });
    } else if (keyCode === ESCAPE) {
      this.props.onClose();
    }
  };

  renderColorBucket = (color, key) => {
    const isSelectedColor = color === this.props.selectedColor;
    const attributes = {
      key,
      id: color,
      'data-testid': `color-bucket-${key}`,
      tabIndex: 0,
      role: 'option',
      onClick: () => this.props.onClickBucket(color),
      onKeyUp: (event) => this.onKeyUpColorBucket(color, event),
      onKeyDown: this.onKeyDownColorBucket,
      style: { backgroundColor: color },
      'aria-selected': isSelectedColor,
      'aria-label': `${I18n.t('pickable_color', { scope })} ${color}`,
      className: classNames('color-bucket',
        { 'selected-color': isSelectedColor },
        { 'color-none': color === EMPTY_TRANSPARENT_COLOR }
      )
    };

    return <div {...attributes}></div>;
  };

  renderTabs() {
    const tabsProps = {
      tabs: [{
        title: I18n.t('default', { scope }),
        value: ColorBuckets.default
      }, {
        title: I18n.t('custom', { scope }),
        value: ColorBuckets.custom
      }],
      selectedTab: this.state.selectedTab,
      onTabChange: (selectedTab) => {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.setState({ selectedTab });
      }
    };
    return (
      <Tabs {...tabsProps} />
    );
  }

  render() {
    const { bucketRevealDirection, palette, showingBuckets } = this.props;
    const { selectedColor, selectedTab, siteAppearanceHexCodes } = this.state;
    const hasSiteAppearanceHexCodes = !_.isEmpty(siteAppearanceHexCodes);
    const colors = hasSiteAppearanceHexCodes && (selectedTab === ColorBuckets.custom) ?
      siteAppearanceHexCodes :
      palette;

    const colorBuckets = _.map(colors, this.renderColorBucket);
    const bucketContainerClassName = classNames('color-buckets-container', {
      'hidden': !showingBuckets,
      'reveal-from-top': bucketRevealDirection === 'top'
    });

    const colorBucketsAttributes = {
      className: `color-buckets color-${palette.length}`,
      ref: ref => this.colorBucketsRef = ref,
      role: 'listbox',
      tabIndex: 0,
      'aria-activedescendant': selectedColor
    };

    const hexInputAttributes = {
      onChange: (event) => this.onChangeInputColor({ event, applyOnSixDigitRgbOnly: true }),
      onKeyUp: this.onKeyUpHexInput,
      type: 'text',
      value: selectedColor
    };

    const tabs = hasSiteAppearanceHexCodes ? this.renderTabs() : null;

    return (
      <div className={bucketContainerClassName} data-testid="color-buckets">
        {tabs}
        <input {...hexInputAttributes} />
        <div {...colorBucketsAttributes}>
          {colorBuckets}
        </div>
      </div>
    );
  }
}

ColorBuckets.propTypes = {
  bucketRevealDirection: PropTypes.string,
  onClickBucket: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  onClose: PropTypes.func,
  palette: PropTypes.array,
  selectedColor: PropTypes.string,
  selectedTab: PropTypes.string, // used by tests only
  showingBuckets: PropTypes.bool
};

ColorBuckets.default = 'default';
ColorBuckets.custom = 'custom';

ColorBuckets.defaultProps = {
  value: DEFAULT_COLOR_PALETTE[0],
  palette: DEFAULT_COLOR_PALETTE
};

export default ColorBuckets;
