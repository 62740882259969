import React, { FunctionComponent } from 'react';

import type { View } from 'common/types/view';
import InteractiveUser from 'common/types/users/interactiveUser';

import WatchAssetModal from './components/WatchAssetModal';
import { WatchProvider } from './context';

export interface WatchAssetProps {
  currentUser: InteractiveUser;
  view: View;
  onDismiss: () => void;
}

const WatchAssetManager: FunctionComponent<WatchAssetProps> = (props) => {
  const { view, onDismiss, currentUser } = props;

  return (
    <WatchProvider>
      <WatchAssetModal fourfour={view.id} onDismiss={onDismiss} currentUser={currentUser} view={view}/>
    </WatchProvider>
  );
};

export default WatchAssetManager;
