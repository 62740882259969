import _ from 'lodash';
import $ from 'jquery';

import { getCharmSvgSrc } from 'common/resources/charms';
import { isMobileOrTablet } from 'common/visualizations/helpers/MediaQueryHelper';

import { Annotation, BaseVisualization, LegendGroup, LegendItem, ReferenceLine } from './types';
import { VisualizationMeasure } from 'common/visualizations/helpers/types';
import { Vif } from 'common/visualizations/vif';

import {
  LEGEND_BAR_HEIGHT,
  LEGEND_PANE_WIDTH,
  LEGEND_PANE_SMALL_WIDTH,
  LEGEND_POSITION_LEFT,
  LEGEND_POSITION_RIGHT,
  SERIES_TYPE_FLYOUT,
  SMALL_VIEWPORT_WIDTH
} from '../SvgConstants';

import {
  getShowLegend,
  isPieVisualization,
  newVizCardLayoutEnabled
} from 'common/visualizations/helpers/VifSelectors';

import { EMPTY_TRANSPARENT_COLOR } from 'common/authoring_workflow/constants';
import { sortMeasuresBasedOnIndex } from 'common/visualizations/helpers/BucketHelper';
import { renderLegendPane, removeLegendPane } from './LegendPaneContainer';
import { attachLegendBarEventHandlers, renderLegendBar, removeLegendBar } from './LegendBarContainer';

import I18n from 'common/i18n';

interface ViewPortSize {
  width: number;
  height: number;
}

/**
 * LegendBar, LegendPane, and the SVGHistogram legend are all
 * wrappers around this code.
 *
 * There is no `template` because the various versions do not have a
 * common container.
 */

const getShowAnnotationsInLegend = (vif: Vif, defaultValue = true) => {
  return _.get(vif, 'configuration.showAnnotationsInLegend', defaultValue);
};

const getLegendPosition = (vif: Vif): string => _.get(vif, 'configuration.legendPosition', '');

export const _renderLegendGroup = (
  legendGroup: LegendGroup,
  $legendMenu: JQuery,
  annotations: Annotation[] = []
) => {
  const $legendGroup = $('<div>', { class: 'socrata-legend-group' });
  const $ul = $('<ul>', { class: 'socrata-legend-items' });
  annotations.forEach((annotation: Annotation, index: number) => {
    const $annotationCountElement = $('<span>', { class: 'annotation-count' }).text(index + 1);
    const $annotationLabelElement = $('<div>', { class: 'annotation-label' }).text(annotation.date);
    const $annotationDescriptionElement = $('<div>', { class: 'annotation-description' }).text(
      annotation.description
    );
    $annotationLabelElement.append($annotationCountElement);

    $ul.append(
      $('<li>', { class: 'annotation-legend' }).append([
        $annotationLabelElement,
        $annotationDescriptionElement
      ])
    );
  });

  legendGroup.items.forEach((item: LegendItem) => {
    // WARNING do not use this value with .html() below, which would create an XSS potential.
    const itemLabelText = _.unescape(item.label);
    if (item.dashed) {
      $ul.append(
        $('<li>')
          .text(itemLabelText)
          .append($('<span>', { class: 'dashed', style: `border-top-color:${item.color}` }))
      );
    } else if (item.showStackInLegend) {
      $ul.append(
        $('<li>')
          .text(itemLabelText)
          .append(
            $('<span>', {
              class: 'multiple-points-symbol',
              style: `border: 2px solid ${item.outlineColor};background-color:${item.color}`
            })
          )
      );
    } else {
      let spanElement = $('<span>', { style: `background-color:${item.color}` });

      if (item.color === EMPTY_TRANSPARENT_COLOR) {
        spanElement = $('<span>', { class: 'color-none' });
      }

      if (legendGroup.showCharm && item.charmName) {
        const $charmElement = $('<img>', { class: 'charm-icon', src: getCharmSvgSrc(item.charmName) });
        $(spanElement).append($charmElement);
      }

      $ul.append($('<li>').text(itemLabelText).append(spanElement));
    }
  });

  if (_.isString(legendGroup.title)) {
    const $titleDiv = $('<div>', { class: 'socrata-legend-title' });
    $titleDiv.text(legendGroup.title);
    $legendGroup.append($titleDiv);
  }
  $legendGroup.append($ul);

  $legendMenu.append($legendGroup);
};

interface RenderLegendOptions {
  annotations: Annotation[];
  measures: VisualizationMeasure[];
  referenceLines: ReferenceLine[];
  viewportSize: ViewPortSize;
  legendPieData: LegendItem[];
}

export const renderLegend = (
  self: BaseVisualization,
  { annotations = [], measures, referenceLines, viewportSize, legendPieData }: RenderLegendOptions
) => {
  const nonFlyoutMeasures = _.reject(measures, (measure) => {
    return _.get(measure, 'palette.series.type') === SERIES_TYPE_FLYOUT;
  });

  const items = getLegendItems({
    vif: self.getVif(),
    measures: nonFlyoutMeasures,
    referenceLines,
    legendPieData
  });

  return renderLegendWithLegendItems(self, {
    items,
    annotations: getShowLegend(self.getVif()) && getShowAnnotationsInLegend(self.getVif()) ? annotations : [],
    viewportSize
  });
};

interface RenderLegendWithLegendItemsOptions {
  annotations: Annotation[];
  items: LegendItem[];
  viewportSize: ViewPortSize;
}

/**
 * Decisions for which legend (bar vs pane) and in what configuration.
 * @param self
 * @param options
 */
export const renderLegendWithLegendItems = (
  self: BaseVisualization,
  { annotations, items, viewportSize }: RenderLegendWithLegendItemsOptions
) => {
  const isLegendBarInitiallyDisplayed =
    self.$container.find('.socrata-visualization-legend-bar-container').children().length > 0;
  const isLegendPaneInitiallyDisplayed =
    self.$container.find('.socrata-visualization-legend-pane-container').children().length > 0;
  const size = _.clone(viewportSize);
  const isSmallWidth = size.width < SMALL_VIEWPORT_WIDTH;
  const legendPaneWidth = isSmallWidth ? LEGEND_PANE_SMALL_WIDTH : LEGEND_PANE_WIDTH;

  self.$container.toggleClass('small-width', !isMobileOrTablet() && isSmallWidth);

  if (getShowLegend(self.getVif())) {
    // For the time being, if a chart is a pie chart, the position is set as right side only
    // If we decide to enable all of the standard legend options in AX for pie charts, we can update this.
    const position =
      isPieVisualization(self.getVif()) && newVizCardLayoutEnabled(self.getVif())
        ? LEGEND_POSITION_RIGHT
        : getLegendPosition(self.getVif());
    const isPositionLeftOrRight = position === LEGEND_POSITION_LEFT || position === LEGEND_POSITION_RIGHT;

    if (!isMobileOrTablet() && isPositionLeftOrRight) {
      removeLegendBar(self.$container);
      renderLegendPane(self.$container, {
        groups: [{ items }],
        annotations,
        position
      });

      if (isLegendBarInitiallyDisplayed) {
        // if legendBar is initially displayed and now is not displayed
        size.height += LEGEND_BAR_HEIGHT; // viewportHeight increases
      }

      if (!isLegendPaneInitiallyDisplayed) {
        // if legendPane is not initially displayed and now is displayed
        size.width -= legendPaneWidth; // viewportWidth decreases
      }
    } else if (_.isEmpty(items) && !getShowAnnotationsInLegend(self.getVif())) {
      removeLegendBar(self.$container);
      removeLegendPane(self.$container);
      if (isLegendBarInitiallyDisplayed) {
        // if legendBar is initially displayed and now is not displayed
        size.height += LEGEND_BAR_HEIGHT; // viewportHeight increases
      }

      if (isLegendPaneInitiallyDisplayed) {
        // if legendPane is initially displayed and now is not displayed
        size.width += legendPaneWidth; // viewportWidth increases
      }
    } else {
      renderLegendBar(self, [{ items }], annotations);
      removeLegendPane(self.$container);
      attachLegendBarEventHandlers(self.$container);

      if (!isLegendBarInitiallyDisplayed) {
        // if legendBar is not initially displayed and now is displayed
        size.height -= LEGEND_BAR_HEIGHT; // viewportHeight decreases
      }

      if (isLegendPaneInitiallyDisplayed) {
        // if legendPane is initially displayed and now is not displayed
        size.width += legendPaneWidth; // viewportWidth increases
      }
    }
  } else {
    if (!_.isEmpty(annotations)) {
      renderLegendBar(self, [], annotations);
      removeLegendPane(self.$container);
      attachLegendBarEventHandlers(self.$container);
    } else {
      removeLegendBar(self.$container);
      removeLegendPane(self.$container);
    }

    if (isLegendBarInitiallyDisplayed) {
      // if legendBar is initially displayed and now is not displayed
      size.height += LEGEND_BAR_HEIGHT; // viewportHeight increases
    }

    if (isLegendPaneInitiallyDisplayed) {
      // if legendPane is initially displayed and now is not displayed
      size.width += legendPaneWidth; // viewportWidth increases
    }
  }

  return size;
};

interface GetLegendItemsOptions {
  vif: Vif;
  measures: VisualizationMeasure[];
  referenceLines: ReferenceLine[];
  legendPieData: LegendItem[];
}

const getLegendItems = ({
  vif,
  measures,
  referenceLines,
  legendPieData
}: GetLegendItemsOptions): LegendItem[] => {
  // If custom palette is used, `sortMeasuresBasedOnIndex` will append custom sort order as `index` to each measure.
  const newMeasures: VisualizationMeasure[] = sortMeasuresBasedOnIndex(measures, vif);
  const referenceLinesWithLabels = _.filter(referenceLines, (line) => !_.isEmpty(line.label));
  const referenceLineItems = referenceLinesWithLabels.map((line) => {
    return {
      label: line.label!,
      color: line.color,
      dashed: true
    };
  });

  let measureItems: LegendItem[] = [];

  if (measures) {
    const measuresWithLabels = _.filter(newMeasures, (measure) => !_.isEmpty(measure.labelHtml));
    measureItems = _.chain(measuresWithLabels)
      .map((measure) => {
        const color = measure.getColor(measure.measureIndex);
        const label = measure.labelHtml || measure.tagValue;
        return {
          label,
          index: measure.index ?? measure.measureIndex, // prefer index set by custom color palette
          color,
          dashed: false
        };
      })
      .sortBy('index')
      .value();
  }

  let pieDataItems: LegendItem[] = [];

  if (legendPieData) {
    pieDataItems = getLegendItemsForPieData(legendPieData);
    measureItems = [];
  }

  return [...referenceLineItems, ...measureItems, ...pieDataItems];
};

export const getLegendItemsForPieData = (legendPieData: LegendItem[]) =>
  legendPieData.map((pieItem: LegendItem) => ({
    // EN-55702: if the label is undefined or null, use no value as the label
    label: pieItem.label ? pieItem.label : I18n.t('shared.visualizations.charts.common.no_value'),
    color: pieItem.color,
    dashed: false
  }));
