import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';
import { FeatureFlags } from 'common/feature_flags';
import './index.scss';
import { CUSTOM_ALERT_TRIGGER_TYPE, CUSTOM_ALERT_TYPES } from '../../constants';
import TimeBasedTriggerOptions from '../TimeBasedTriggerOptions';
import AlertSnoozeOptions from '../AlertSnoozeOptions';
import FieldTitle from '../FieldTitle';

const scope = 'shared.components.create_alert_modal';

class AlertTriggerPage extends Component {
  renderWebhook() {
    const { onWebhookUrlChange, webhookUrl } = this.props;

    if (!FeatureFlags.value('enable_notifications_alerts_webhooks')) {
      return null;
    }

    return (
      <div className="web-hook web-hook-container">
        <div className="web-hook-section">
          <FieldTitle
            flyoutId="alert-web-hook-flyout-info"
            flyoutText={I18n.t('alert_trigger_page.webhook_flyout_text', { scope })}
            title={I18n.t('custom_alert.webhook_title', { scope })}
          />
          <div className="input-section">
            <span className="sticky-text">https:// </span>
            <input
              id="web-hook-input"
              onChange={(event) => onWebhookUrlChange(event.target.value)}
              placeholder={I18n.t('custom_alert.webhook_placeholder', { scope })}
              type="text"
              value={webhookUrl}
            />
          </div>
        </div>
      </div>
    );
  }

  renderAlertTypeOption() {
    const { customAlertType, onCustomAlertTypeChange } = this.props;
    const alertTypes = [
      {
        title: I18n.t('custom_alert.threshold_title', { scope }),
        value: CUSTOM_ALERT_TYPES.THRESHOLD
      },
      {
        title: I18n.t('custom_alert.incident_alert_title', { scope }),
        value: CUSTOM_ALERT_TYPES.INCIDENT
      }
    ];

    alertTypes.push({
      title: I18n.t('custom_alert.time_based', { scope }),
      value: CUSTOM_ALERT_TYPES.TIME_BASED
    });

    const alertTypeOptions = {
      options: alertTypes,
      onSelection: (option) => onCustomAlertTypeChange(option.value),
      showOptionsBelowHandle: true,
      size: 'small',
      value: customAlertType
    };

    return (
      <div className="alert-options alert-type-options">
        <FieldTitle
          flyoutId="alert-type-flyout-info"
          flyoutText={I18n.t('alert_trigger_page.alert_type_flyout_text', { scope })}
          title={I18n.t('alert_trigger_page.alert_type_title', { scope })}
        />
        <Dropdown className="dropdown-size-large" {...alertTypeOptions} />
      </div>
    );
  }

  renderAlertSnoozeOptions() {
    const {
      alertSnoozeEnabled,
      alertSnoozePeriodic,
      alertSnoozeTime,
      customAlertType,
      customAlertTriggerType,
      onAlertSnoozeTimeChange,
      onAlertSnoozePeriodicChange,
      onToggleAlertSnooze
    } = this.props;

    const isAlertSnoozeOptionSupported =
      customAlertType === CUSTOM_ALERT_TYPES.THRESHOLD &&
      customAlertTriggerType === CUSTOM_ALERT_TRIGGER_TYPE.ROLLING;

    if (isAlertSnoozeOptionSupported) {
      return (
        <AlertSnoozeOptions
          {...{
            alertSnoozeEnabled,
            alertSnoozePeriodic,
            alertSnoozeTime,
            onAlertSnoozeTimeChange,
            onAlertSnoozePeriodicChange,
            onToggleAlertSnooze
          }}
        />
      );
    }
  }

  renderAlertTriggerOption() {
    const {
      alertSentDays,
      alertSentTime,
      alertTimeZone,
      customAlertType,
      onAlertFrequencyChange,
      onAlertSentDaysChange,
      onAlertSentTimeChange,
      onAlertTimeZoneChange,
      periodicFrequency
    } = this.props;

    if (customAlertType === CUSTOM_ALERT_TYPES.TIME_BASED) {
      return (
        <TimeBasedTriggerOptions
          {...{
            alertSentDays,
            alertSentTime,
            alertTimeZone,
            customAlertType,
            onAlertFrequencyChange,
            onAlertSentDaysChange,
            onAlertSentTimeChange,
            onAlertTimeZoneChange,
            periodicFrequency
          }}
        />
      );
    } else {
      return this.renderDataTriggerOption();
    }
  }

  renderDataTriggerOption() {
    const { customAlertTriggerType, onTriggerTypeChange } = this.props;
    const conditionDropDownOption = {
      options: [
        {
          title: I18n.t('custom_alert.rolling_title', { scope }),
          value: CUSTOM_ALERT_TRIGGER_TYPE.ROLLING
        },
        {
          title: I18n.t('custom_alert.one_and_done_title', { scope }),
          value: CUSTOM_ALERT_TRIGGER_TYPE.ONE_AND_DONE
        }
      ],
      onSelection: (option) => onTriggerTypeChange(option.value),
      showOptionsBelowHandle: true,
      size: 'small',
      value: customAlertTriggerType
    };

    return (
      <div className="alert-options alert-trigger-options">
        <FieldTitle
          flyoutId="alert-trigger-flyout-info"
          flyoutText={I18n.t('alert_trigger_page.trigger_flyout_text', { scope })}
          title={I18n.t('alert_trigger_page.trigger_title', { scope })}
        />
        <Dropdown className="dropdown-size-large" {...conditionDropDownOption} />
      </div>
    );
  }

  render() {
    return (
      <div className="alert-trigger-page">
        <div className="alert-trigger-title">Select an alert type and trigger method.</div>
        <div className="trigger-options">
          <div className="split-section">
            {this.renderAlertTypeOption()}
            {this.renderAlertSnoozeOptions()}
          </div>
          {this.renderAlertTriggerOption()}
        </div>
        {this.renderWebhook()}
      </div>
    );
  }
}

AlertTriggerPage.propTypes = {
  alertSentDays: PropTypes.array,
  alertSentTime: PropTypes.string,
  alertTimeZone: PropTypes.string,
  alertSnoozeEnabled: PropTypes.bool,
  alertSnoozePeriodic: PropTypes.string,
  alertSnoozeTime: PropTypes.string,
  customAlertTriggerType: PropTypes.string,
  customAlertType: PropTypes.string,
  onAlertFrequencyChange: PropTypes.func,
  onAlertSnoozeTimeChange: PropTypes.func,
  onAlertSnoozePeriodicChange: PropTypes.func,
  onToggleAlertSnooze: PropTypes.func,
  onAlertSentDaysChange: PropTypes.func,
  onAlertSentTimeChange: PropTypes.func,
  onAlertTimeZoneChange: PropTypes.func,
  onCustomAlertTypeChange: PropTypes.func,
  onTriggerTypeChange: PropTypes.func,
  onWebhookUrlChange: PropTypes.func,
  periodicFrequency: PropTypes.string,
  webhookUrl: PropTypes.string
};

export default AlertTriggerPage;
