import type { GuidanceSummaryV2 } from 'common/types/approvals';

import { View, ViewRight } from 'common/types/view';
import { isStoryDraft } from 'common/views/view_types';
import { hasRights } from 'common/views/has_rights';
import { currentUserHasRight } from 'common/current_user';
import { AssetStatus, assetStatusFor } from 'common/views/asset_status';
import DomainRights from 'common/types/domainRights';

/**
 * Determines whether the current user can delete the given view OR draft.
 *
 * Note that assets that are pending approval cannot be deleted, and must be withdrawn from the approvals
 * queue before they can actually be deleted.
 *
 * Note that story drafts are handled a little differently here! See implementation for details.
 *
 * This mostly hinges on the user having the `delete_view` right on the View.
 * This right is only given to the "true owner" of the view (or to members of the team that own the view).
 * Co-owners and other grant types will not have this right.
 *
 * @param view View to check for deletion privileges of
 * @param approvalsGuidance Approvals guidance summary
 *  Used to determine if there is a pending approval, in which case the asset must be withdrawn before it can be deleted.
 * @param assetIsStoryDraft Whether or not this is a story draft.
 *  This has to sometimes be passed in explicitly because if we're only given a view from core and we're not actually in storyteller
 *  (for example, using AssetBrowser a.k.a. SIAM) then we have to ask the catalog if we're looking at a story draft as core
 *  does not know about story drafts right now.
 * @returns Whether or not the current user can delete the given view
 */
const currentUserCanDeleteView = (
  view: View,
  approvalsGuidance: GuidanceSummaryV2,
  assetIsStoryDraft = false
) => {
  // Stories are treated a little differently, with different checks for being able to delete drafts or not.
  // Note that story drafts do NOT have actual lenses behind them and as such do not have UIDs...
  // BUT they do come back from the catalog! `isStoryDraft` only works if we're in storyteller since it looks at a magic
  // boolean that's shoved on the window. `assetIsStoryDraft` is an explicit check that is derived from the catalog.
  if (view.locked) {
    return false;
  }

  if (isStoryDraft(view) || assetIsStoryDraft) {
    // Keep this consistent with checks for `delete_since_last_publication` in
    // https://github.com/socrata/platform-ui/blob/main/storyteller/app/controllers/application_controller.rb#L264
    // Check ensures that user can edit the story
    return hasRights(view, ViewRight.Write) || currentUserHasRight(DomainRights.edit_others_stories);
  }

  const assetStatus = assetStatusFor(view, approvalsGuidance);

  return (
    hasRights(view, ViewRight.DeleteView) &&
    (assetStatus === AssetStatus.Published || assetStatus === AssetStatus.Draft)
  );
};

export default currentUserCanDeleteView;
