import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import './index.scss';
import { FeatureFlags } from 'common/feature_flags';

const scope = 'shared.components.create_alert_modal';

class AlertMetaPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  renderDescriptionField() {
    const { alertDescription, onAlertDescriptionChange } = this.props;

    return (
      <div className="alert-description-section alert-description-section">
        <label htmlFor="alert-description-content">{I18n.t('alert_description_label', { scope })}</label>
        <span>{I18n.t('alert_description_subtext', { scope })}</span>
        <textarea
          id="alert-description-content"
          rows="10"
          placeholder={I18n.t('alert_description_placeholder', { scope })}
          value={alertDescription}
          onChange={onAlertDescriptionChange}
        />
      </div>
    );
  }

  renderAlertNameField() {
    const { alertName, onAlertNameChange } = this.props;

    return (
      <div className="alert-name-section">
        <label htmlFor="custom-alert-name-input">{I18n.t('alert_name_label', { scope })}:</label>
        <input
          id="custom-alert-name-input"
          type="text"
          placeholder={I18n.t('alert_name_placeholder', { scope })}
          value={alertName}
          onChange={onAlertNameChange}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="alert-meta-page">
        {this.renderAlertNameField()}
        {this.renderDescriptionField()}
      </div>
    );
  }
}

AlertMetaPage.propTypes = {
  alertDescription: PropTypes.string,
  alertName: PropTypes.string,
  onAlertDescriptionChange: PropTypes.func,
  onAlertNameChange: PropTypes.func
};

export default AlertMetaPage;
