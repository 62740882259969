// Vendor Imports
import _ from 'lodash';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import Picklist from '../Picklist';
import SocrataIcon from '../SocrataIcon';
import I18n from 'common/i18n';
import { getIconForDataType } from 'common/icons';

// Constants
const OPTION_TYPE_BACK = 'back';
const scope = 'shared.components.filter_bar';

export class ComputedColumnSelector extends Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['onClickOption']);
  }

  onClickOption(option) {
    const { computedColumns, onClickBack, onClickColumn } = this.props;

    if (option.type === OPTION_TYPE_BACK) {
      onClickBack();
    } else {
      const column = _.find(computedColumns, ['fieldName', option.value]);
      onClickColumn(column);
    }

    this.unselectOption();
  }

  unselectOption() {
    setTimeout(() => {
      $('.computed-column-selector-container').
        find('.picklist-option').
        removeClass('picklist-option-selected');
    });
  }

  renderBackOption(option) {
    return (
      <div className="filter-bar-column-option">
        <SocrataIcon name="arrow-left" />
        <span>{option.title}</span>
      </div>
    );
  }

  renderComputedColumnOption(option) {
    return (
      <div className="filter-bar-column-option">
        <SocrataIcon name={getIconForDataType('geospatial')} />
        <span>{option.title}</span>
      </div>
    );
  }

  render() {
    const columnOptions = _.map(this.props.computedColumns, (column) => ({
      render: this.renderComputedColumnOption,
      title: column.name,
      value: column.fieldName
    }));
    const options = [
      {
        render: this.renderBackOption,
        title: I18n.t('back', { scope }),
        type: OPTION_TYPE_BACK,
        value: 'back'
      },
      ...columnOptions
    ];
    const picklistProps = {
      id: 'computed-column-selector-picklist',
      onSelection: this.onClickOption,
      options,
      placeholder: I18n.t('select', { scope }),
      size: 'small'
    };

    return (
      <div className="computed-column-selector-container">
        <Picklist {...picklistProps} />
      </div>
    );
  }
}

ComputedColumnSelector.propTypes = {
  computedColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickColumn: PropTypes.func.isRequired
};

export default ComputedColumnSelector;
