// Helpers for dealing with federation scenarios.
import { Type } from '@socrata/core-federations-api';

/**
 * Note that core filters out federations that the user can't see.
 * Frontend then does further filtering on the list to only include incoming, accepted federation.
 * (look for where window.socrata.incomingFederations is set in application_helper.rb)
 * If the user has the `view_data_federation_source_domain` right then they can always see all incoming federations.
 * If they don't have that, then they cannot see incoming data federations.
 * @return incoming federation types active on the current domain.
 */
export const getIncomingFederations = () => window.socrata?.incomingFederations || [];

/**
 * Returns incoming federations. The `getIncomingFederations` method above will return
 * federations of type 'scope' only.
 * @returns incoming data federations active on the current domain
 */
export const getAllIncomingFederations = () => window.socrata?.allIncomingFederations || [];


/**
 * @returns true if the current domain has _any_ incoming federations that the user can see
 */
export const hasAnyIncomingFederation = () => getIncomingFederations().length !== 0;

/**
 * @return outgoing federation types active on the current domain.
 */
export const getOutgoingFederations = () => window.socrata?.outgoingFederations || [];

/**
 * @return true if the current domain has an active outgoing data
 * federation.
 */
export const hasOutgoingInternalToPublicFederation = () =>
  getOutgoingFederations().some((federation) => federation.type === Type.Data);

/**
 * @return true if the current domain has an active outgoing catalog federation.
 */
export const hasOutgoingCatalogFederation = () =>
  getOutgoingFederations().some((federation) => federation.type === Type.Catalog);
