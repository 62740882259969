import _ from 'lodash';
import union from '@turf/union';
import rewind from '@turf/rewind';

import { MAP_TYPES } from 'common/visualizations/views/mapConstants';
import { getFeatureDiameter } from 'common/visualizations/views/map/vifOverlays/partials/PointsAndStacks';
import { getFeatureWidth } from 'common/visualizations/views/map/vifOverlays/partials/Lines';
import Highlight from 'common/visualizations/views/map/vifOverlays/partials/Highlight';

export default class HighlightHandler {
  constructor(map) {
    this._map = map;
    this._highlight = new Highlight(map);
  }

  initialize() {
    this._highlight.initialize();
  }

  highlight(highlightOptions = {}) {
    const { highlightFeature, highlightLayerId, renderOptions, vif } = highlightOptions;
    const { idBy, resizeByRange, aggregateAndResizeBy } = renderOptions;
    const layerId = _.get(highlightFeature, 'layer.id');
    const mapType = _.get(vif, 'series[0].mapOptions.mapType');

    if (!_.includes(highlightLayerId, layerId)) {
      return;
    }

    if (mapType === MAP_TYPES.POINT_MAP) {
      if (vif.isRegionMap()) {
        const shapePrimaryKey = _.get(renderOptions, 'shapePrimaryKey');
        const feature = this.getUncroppedFeature(highlightFeature, shapePrimaryKey, layerId);
        this._highlight.highlightLineOrShape(feature, vif.getShapeOutlineWidth(), mapType, layerId);
      } else {
        const featureDiameter = getFeatureDiameter({
          featureProperties: highlightFeature.properties,
          resizeByRange,
          aggregateAndResizeBy,
          vif
        });
        this._highlight.highlightPoint(highlightFeature, featureDiameter, layerId);
      }
    } else if (mapType === MAP_TYPES.BOUNDARY_MAP) {
      const feature = this.getUncroppedFeature(highlightFeature, idBy, layerId);
      this._highlight.highlightLineOrShape(feature, vif.getShapeOutlineWidth(), mapType, layerId);
    } else if (mapType === MAP_TYPES.LINE_MAP) {
      const feature = this.getUncroppedFeature(highlightFeature, idBy, layerId);
      const lineWidth = getFeatureWidth(highlightFeature, resizeByRange, aggregateAndResizeBy, vif);
      this._highlight.highlightLineOrShape(feature, lineWidth, mapType, layerId);
    }
  }

  hideHighlightLayer() {
    this._highlight.hideHighlightPointLayer();
  }

  // When feature spans across multiple tiles,
  // the feature gets fetched as separate parts in a each tile data call.
  // on hovering over a part of a feature(in a tile),
  // we need to highlight the entire feature, rather than just the part in the currently hovered tile.
  // given a feature, this function returns the entire feature (union of all parts of the feature)
  getUncroppedFeature(highlightFeature, idBy, layerId) {
    const highlightSourceId = _.get(highlightFeature, 'layer.source');
    const rowId = _.get(highlightFeature.properties, idBy);

    const querySourceFeatures = this._map.querySourceFeatures(highlightSourceId, {
      sourceLayer: layerId,
      filter: ['==', idBy, rowId]
    });

    if (querySourceFeatures.length > 1) {
      let unionFeatures;
      _.each(querySourceFeatures, (feature) =>{
        if (unionFeatures) {
          unionFeatures = union(unionFeatures, feature);
        } else {
          unionFeatures = feature;
        }
      });

      return rewind(unionFeatures);
    }

    return highlightFeature;
  }

  removeHighlight() {
    this._highlight.destroy();
  }
}
