import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import once from 'lodash/once';
import getOr from 'lodash/fp/getOr';
// https://github.com/lodash/lodash/wiki/FP-Guide#mapping
// The `lodash/fp/map` iteratee is capped at one argument:
// (value)
import map from 'lodash/map';
import omit from 'lodash/fp/omit';

import { componentsOfType } from 'common/propTypes';
import { conditionallyMapChildren } from 'common/react_helpers';

import TableCell from './TableCell';
import TableColumn from './TableColumn';
import TableRow from './TableRow';
import TableHeaderCell from './TableHeaderCell';
import TableHeaderRow from './TableHeaderRow';

import './results-table.scss';

const mapChildrenIfVisible = conditionallyMapChildren(getOr(false, 'props.isVisible'));

/**
 * A table of results. What makes a "result" a "result"? Nothing much.
 *
 * Features:
 *  - Optional loading animation.
 *  - Customizable message when result set is empty.
 *  - Per-cell custom rendering (pass in your component).
 *  - Customizable class per row.
 *  - Rows can be keyed on a specific column's value.
 */

class ResultsTable extends Component {
  static Column = TableColumn;
  static propTypes = {
    /**
     * An ordered list of <ResultsTable.Column> instances defining the behavior of each column.
     */
    children: componentsOfType(TableColumn.displayName).isRequired,
    /**
     * Class to be applied on the table's root node.
     */
    className: PropTypes.string,
    /**
     * An array of rows.
     */
    data: PropTypes.array.isRequired,
    /**
     * Whether or not to display the loading spinner.
     */
    loadingData: PropTypes.bool,
    /**
     * The message to display when there are zero rows.
     */
    noResultsMessage: PropTypes.node.isRequired,
    /**
     * The row element to use as the React element key (might be useful for animations).
     * If the row data is hash-based, this should be the hash key of the value to be used.
     * If the row data is array-based, this should be the numeric index of the value to be used.
     * If omitted, will default to the row's index.
     */
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * A function mapping rows (entries in the "data" prop) to class names to be applied on each "tr".
     */
    rowClassFunction: PropTypes.func
  };

  static defaultProps = {
    loadingData: false
  };

  constructor() {
    super();
    this.warnBothComponentAndChildren = once((cellProps) => {
      const { cellComponent, children } = cellProps;
      console.warn(
        'A TableColumn was given both a cellComponent prop and a children prop. Ignoring the children prop. ' +
        'There may be more, but this warning will only be printed once.',
        { cellComponent, children }
      );
    });
  }

  renderHeaderRow = () => {
    const { children } = this.props;
    const headerCells = mapChildrenIfVisible(({ props }) => <TableHeaderCell {...props} />, children);
    return <TableHeaderRow>{headerCells}</TableHeaderRow>;
  };

  renderDataRows = () => {
    const { children, data, rowKey, rowClassFunction } = this.props;
    // Use normal map, not fp-map, since we care about the index.
    // https://github.com/lodash/lodash/wiki/FP-Guide#mapping
    // The `lodash/fp/map` iteratee is capped at one argument:
    // (value)
    const rows = map(data, (rowData, index) => {
      const cells = mapChildrenIfVisible(
        ({ props }) => {
          const { cellComponent, children } = props;
          if (cellComponent && children && children !== TableColumn.defaultProps.children) {
            this.warnBothComponentAndChildren(props);
          }
          return <TableCell {...props} rowData={rowData} />;
        },
        children
      );
      const key = rowKey && rowKey.length ? rowData[rowKey] : index;
      return (
        <TableRow key={key} rowClassName={rowClassFunction && rowClassFunction(rowData)}>
          {cells}
        </TableRow>
      );
    });
    return <tbody>{rows}</tbody>;
  };

  renderDataLoading = (colSpan) => (
    <tbody>
      <tr>
        <td colSpan={colSpan} className="no-results-message">
          <span className="spinner-default spinner-large" />
        </td>
      </tr>
    </tbody>
  );

  renderNoResults = (colSpan) => {
    const { noResultsMessage } = this.props;
    return (
      <tbody>
        <tr>
          <td colSpan={colSpan} className="no-results-message">
            {noResultsMessage}
          </td>
        </tr>
      </tbody>
    );
  };

  render() {
    const props = omit(Object.keys(ResultsTable.propTypes), this.props);
    const className = cx(
      'results-list-table table table-discrete table-condensed table-borderless',
      this.props.className
    );
    const count = React.Children.count(this.props.children);
    const hasData = this.props.data.length !== 0;
    const renderDataLoaded = () => hasData ? this.renderDataRows() : this.renderNoResults(count);
    const tableBody = this.props.loadingData ? this.renderDataLoading(count) : renderDataLoaded();

    return (
      <table {...props} className={className}>
        {this.renderHeaderRow()}
        {tableBody}
      </table>
    );
  }
}

export default ResultsTable;
