import isEmpty from 'lodash/isEmpty';
import React, { FunctionComponent } from 'react';

import type { ViewsNeedingDeletionResponse } from 'common/views/delete_helpers';
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Button, { VARIANTS } from 'common/components/Button';

import { translationScope } from './';

interface ViewsBlockingDeletionInfoProps {
  /** UID (4x4) of view we're trying to delete */
  viewId: string;

  /** Response from /api/views/${uid}.json?method=getViewsThatNeedToBeDeletedToDeleteView */
  response: ViewsNeedingDeletionResponse;
}

/**
 * If a view has views that are blocking its deletion, this will let the user know and will show a button that
 * will download a CSV of those views.
 */
const ViewsBlockingDeletionInfo: FunctionComponent<ViewsBlockingDeletionInfoProps> = ({
  viewId,
  response
}) => {
  const { viewsBlockingDeletion } = response;
  const listDownloadHref = `/views/${viewId}.csv?method=getViewsThatNeedToBeDeletedToDeleteView`;

  // viewsBlockingDeletion will only come back if the user has the right privileges to see them
  const showDownloadButton = !isEmpty(viewsBlockingDeletion);

  return (
    <div className="delete-blocked">
      <p
        className="first-paragraph"
        dangerouslySetInnerHTML={{
          __html: I18n.t(`${translationScope}.delete_blocked_by_child_views.description_paragraph_1`)
        }}
      />
      {showDownloadButton && (
        <Button
          data-testid="download-child-views-button"
          variant={VARIANTS.PRIMARY}
          inverse
          href={listDownloadHref}
        >
          <SocrataIcon name={IconName.Download} className="download-views-blocking-icon" />
          {I18n.t(`${translationScope}.delete_blocked_by_child_views.button_text`)}
        </Button>
      )}
      <p>{I18n.t(`${translationScope}.delete_blocked_by_child_views.description_paragraph_2`)}</p>
    </div>
  );
};

export default ViewsBlockingDeletionInfo;
