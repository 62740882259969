import _ from 'lodash';
import convertToUrlComponent from 'common/js_utils/convertToUrlComponent';

export const getDomainNameLink = (domainCname) => {
  if (_.isEmpty(domainCname)) {
    return null;
  }

  return `//${domainCname}`;
};

export const getDatasetLink = (domainCname, name, uId) => {
  if (_.isEmpty(domainCname) || _.isEmpty(name) || _.isEmpty(uId)) {
    return null;
  }

  return `//${domainCname}/dataset/${convertToUrlComponent(name)}/${uId}`;
};


export const getStoryLink = (domainCname, name, uId, path) => {
  if (_.isEmpty(domainCname) || _.isEmpty(name) || _.isEmpty(uId)) {
    return null;
  }

  return `//${domainCname}/stories/s/${convertToUrlComponent(name)}/${uId}/${path}`;
};

export const getDraftLink = (domainCname, name, datasetId, details) => {
  const publishedUid = _.get(details, 'published_view_uid', datasetId);
  const draftType = _.get(details, 'draft_type', '').toLowerCase();
  const draftId = _.get(details, 'draft_id', '');
  if (draftType === 'story') {
    return getStoryLink(domainCname, name, publishedUid, 'preview');
  } else {
    if (_.isEmpty(draftId)) {
      return getDatasetLink(domainCname, name, publishedUid);
    } else {
      if (draftType === 'revision') {
        return getRevisionLink(domainCname, name, publishedUid, draftId);
      } else {
        return getDatasetLink(domainCname, name, draftId);
      }
    }
  }
};

export const getDownloadLink = (domainCname, uId, query) => {
  if (_.isEmpty(domainCname) || _.isEmpty(query) || _.isEmpty(uId)) {
    return null;
  }
  // query format: Select * form table where id=10 limit 10
  // Adding limit if query doesn't have limit by default
  if (!/\sLIMIT\s/i.test(query)) {
    query = query + ' LIMIT 50000';
  }

  return `//${domainCname}/resource/${uId}.csv?$query=${encodeURIComponent(query)}`;
};

const getRevisionLink = (domainCname, name, publishedUid, seqNumber) => {
  if (_.isEmpty(domainCname) || _.isEmpty(name) || _.isEmpty(publishedUid) || _.isEmpty(seqNumber)) {
    return null;
  }

  return `//${domainCname}/dataset/${convertToUrlComponent(name)}/${publishedUid}/revisions/${seqNumber}`;
};
