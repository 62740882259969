import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import { ThreeStateValue } from 'common/components/ThreeStateCheckbox';

import PreferenceSection, { PreferenceRow, MatchParam, MatchParamResult } from './PreferenceSection';
import {
  NotificationsState,
  ConfigurationsNotificationsState,
  Preferences
} from 'common/notifications/types';
import calculateAvailablePreferences from 'common/notifications/helpers/calculateAvailablePreferences';
import { FeatureFlags } from 'common/feature_flags';
const scope = 'shared.site_chrome.notifications.alert_setting_modal';
const t = (k: string): string => I18n.t(k, { scope });


const scheduleFailureParams: MatchParam<number>[] = [
  {
    name: t('consecutive_failures_title'),
    type: 'integer',
    default: 5,
    required: true,
    key: 'consecutive_failures',
    intoString: (n: number) => `${n}`,
    validate: (input: string): MatchParamResult<number> => {
      const int = parseInt(input);
      if (_.isNaN(int) || int <= 0) {
        return { type: 'error', message: t('consecutive_failures_integer') };
      } else {
        return { type: 'ok', value: int};
      }
    }
  }
];

const approvalSuppressionParams = (): MatchParam<string[]>[] => {
  if (FeatureFlags.value('enable_approval_notification_suppression_for_users')) {
    return [
      {
        name: t('suppress_approval_notifications_title'),
        type: 'list',
        default: [],
        required: false,
        key: 'suppressed_users',
        placeholder: t('suppress_approval_notifications_placeholder'),
        intoString: (emails: string[]) => emails.join(', '),
        validate: (input: string): MatchParamResult<string[]> => {
          const emails = (input || '').split(',').map(e => e.trim()).filter(v => v.length > 0);
          return { type: 'ok', value: emails };
        }
      }
    ];
  }
  return [];
};


export type OnAlertNotificationChange = (
  preferenceName: string,
  preferenceValues: {
    enable_product_notification: boolean;
    enable_email_notification: boolean;
    match_params?: any;
  }
) => void;

interface Props {
  preferences: Preferences;
  availablePreferences: Array<string>;
  onAlertNotificationChange: OnAlertNotificationChange;
  onSelectAllPreferenceTypeChange: (
    notificationKey: string,
    preferencesInSection: Array<string>,
    value: ThreeStateValue
  ) => any;
  configurations: ConfigurationsNotificationsState;
}

export class PreferenceTable extends Component<Props> {
  render() {
    return (
      <div className="preference-table">
        {this.renderMyAssets()}
        {this.renderAllAssets()}
        {this.renderUsers()}
      </div>
    );
  }

  renderMyAssets() {
    const { showMyAssetsTeamMessage } = this.props.configurations;
    const {
      availablePreferences,
      onAlertNotificationChange,
      onSelectAllPreferenceTypeChange,
      preferences
    } = this.props;
    let title;

    if (showMyAssetsTeamMessage) {
      title = t('my_assets.title');
    } else {
      title = t('my_assets.individual_title');
    }

    const rows: PreferenceRow[] = [
      {
        preferenceKey: 'my_assets_updated',
        description: t('my_assets.my_assets_updated')
      },
      {
        preferenceKey: 'my_assets_watched_asset_updated',
        description: t('my_assets.watch_assets')
      },
      {
        preferenceKey: 'my_assets_collaborators_change',
        description: t('my_assets.collaborators_change')
      }
    ];

    if (_.includes(availablePreferences, 'my_assets_approvals')) {
      rows.push({
        preferenceKey: 'my_assets_approvals',
        description: t('my_assets.my_assets_approval'),
        matchParams: approvalSuppressionParams()
      });
    }

    rows.push({
      preferenceKey: 'my_schedule_failed',
      description: t('my_assets.my_schedule_failed'),
      matchParams: scheduleFailureParams
    });
    rows.push({
      preferenceKey: 'my_schedule_succeeded',
      description: t('my_assets.my_schedule_succeeded')
    });

    rows.push({
      preferenceKey: 'my_agent_online',
      description: t('my_assets.my_agent_online')
    });
    rows.push({
      preferenceKey: 'my_agent_offline',
      description: t('my_assets.my_agent_offline')
    });

    return (
      <PreferenceSection
        onSelectAllPreferenceTypeChange={onSelectAllPreferenceTypeChange}
        onAlertNotificationChange={onAlertNotificationChange}
        preferences={preferences}
        title={title}
        rows={rows}
      />
    );
  }

  renderAllAssets() {
    const {
      onSelectAllPreferenceTypeChange,
      availablePreferences,
      onAlertNotificationChange,
      preferences
    } = this.props;
    const allAssetTitle = t('all_assets.title');
    const allAssetDescription = t('all_assets.description');

    const assetApprovalText = t('all_assets.approval_changes');
    const assetPublishedText = t('all_assets.new_asset_published');
    const collaboratorText = t('all_assets.collaborators_change');
    const dataChangeText = t('all_assets.data_change');
    const deleteAssetText = t('all_assets.delete_asset');
    const metaDataText = t('all_assets.meta_data_change');
    const permissionChangeText = t('all_assets.permission_change');

    const rows = [];
    if (_.includes(availablePreferences, 'all_assets_published')) {
      rows.push({
        preferenceKey: 'all_assets_published',
        description: assetPublishedText
      });
    }

    if (_.includes(availablePreferences, 'all_assets_approvals')) {
      rows.push({
        preferenceKey: 'all_assets_approvals',
        description: assetApprovalText,
        matchParams: approvalSuppressionParams()
      });
    }

    rows.push({
      preferenceKey: 'all_assets_meta_change',
      description: metaDataText
    });
    rows.push({
      preferenceKey: 'all_assets_data_change',
      description: dataChangeText
    });

    if (_.includes(availablePreferences, 'all_asset_permission_change')) {
      rows.push({
        preferenceKey: 'all_asset_permission_change',
        description: permissionChangeText
      });
    }

    if (_.includes(availablePreferences, 'all_asset_collaborator_change')) {
      rows.push({
        preferenceKey: 'all_asset_collaborator_change',
        description: collaboratorText
      });
    }

    if (_.includes(availablePreferences, 'all_assets_deleted')) {
      rows.push({
        preferenceKey: 'all_assets_deleted',
        description: deleteAssetText
      });
    }

    rows.push({
      preferenceKey: 'any_schedule_failed',
      description: t('all_assets.any_schedule_failed'),
      matchParams: scheduleFailureParams
    });
    rows.push({
      preferenceKey: 'any_schedule_succeeded',
      description: t('all_assets.any_schedule_succeeded')
    });

    rows.push({
      preferenceKey: 'any_agent_online',
      description: t('all_assets.any_agent_online')
    });
    rows.push({
      preferenceKey: 'any_agent_offline',
      description: t('all_assets.any_agent_offline')
    });

    return (
      <PreferenceSection
        onSelectAllPreferenceTypeChange={onSelectAllPreferenceTypeChange}
        onAlertNotificationChange={onAlertNotificationChange}
        preferences={preferences}
        title={allAssetTitle}
        description={allAssetDescription}
        rows={rows}
      />
    );
  }

  renderUsers() {
    const {
      onSelectAllPreferenceTypeChange,
      availablePreferences,
      onAlertNotificationChange,
      preferences
    } = this.props;

    if (_.includes(availablePreferences, 'user_accounts')) {
      return (
        <PreferenceSection
          onSelectAllPreferenceTypeChange={onSelectAllPreferenceTypeChange}
          onAlertNotificationChange={onAlertNotificationChange}
          preferences={preferences}
          title={t('user_accounts.title')}
          rows={[
            {
              preferenceKey: 'user_accounts',
              description: t('user_accounts.site_user')
            }
          ]}
        />
      );
    }
  }
}

const mapStateToProps = (state: NotificationsState, props: { preferences: Preferences }) => {
  return {
    availablePreferences: calculateAvailablePreferences(state.configurations, props.preferences),
    configurations: state.configurations
  };
};

export default connect(mapStateToProps)(PreferenceTable);
