import { isObject, merge, get, set } from 'lodash';

export default function addToWindow<T>(thing: T, name: string): T {
  const prevTarget = get(window, name);
  if (isObject(prevTarget)) {
    thing = merge({}, prevTarget, thing);
    // if its not an object, tough, we're overwriting it
  }
  set(window, name, thing);
  return thing;
}
