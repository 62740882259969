import { configureStore } from '@reduxjs/toolkit';
import storyCopierSlice from 'store/reducers/StoryCopierSlice';
import collaboratorsReducer from 'store/reducers/CollaboratorsReducer';
import linkTipSlice from 'store/reducers/LinkTipSlice';
import digestReducer from 'store/reducers/DigestReducer';
import downtimeSlice from 'store/reducers/DowntimeSlice';
import modalReducer from 'store/reducers/ModalReducer';
import blockRemovalConfirmationReducer from 'store/reducers/BlockRemovalConfirmationReducer';
import storyWithHistoryReducer from 'store/reducers/StoryWithHistoryReducer';
import autosaveMiddleware from 'store/middleware/autosaveMiddleware';
import storySaveStatusReducer from 'store/reducers/StorySaveStatusReducer';
import actionComponentReducer from 'store/reducers/ActionComponentReducer';

// import reducers and slices here
export const StorytellerReduxStore = configureStore({
  reducer: {
    actionComponentReducer,
    blockRemovalConfirmationReducer,
    collaboratorsReducer,
    digest: digestReducer,
    downtimeSlice,
    storyWithHistory: storyWithHistoryReducer,
    linkTipSlice,
    storyCopierSlice,
    storySaveStatus: storySaveStatusReducer,
    modals: modalReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).prepend(autosaveMiddleware)
});

export type StorytellerState = ReturnType<typeof StorytellerReduxStore.getState>;

export type StorytellerDispatch = typeof StorytellerReduxStore.dispatch;
