import _ from 'lodash';

import * as genericContentFormatter from './genericContentFormatter';
import * as mapConstants from 'common/visualizations/views/mapConstants';

// Sample Point Feature: (Geojson object got from mapbox-gl map)
//    {
//      "type": "Feature",
//      "geometry": {
//        "type": "Point",
//        "coordinates": [-122.44754076004028,37.8044394394888]
//      },
//      "properties": {
//        "cluster": false
//        "__aggregate_by__": 97491
//      },
//      "layer": { ... }
//    }
// feature -> Geojson Feature object with geometry(snapped_for_zoom)

// Builds html tipsy content for a point.
export async function setPopupContentForPoints(popupParams) {
  const { element, vifs, renderOptions, features } = popupParams;
  const retrieveDataConditions = _.chain(features).
    map((feature, index) => {
      const rowId = _.get(features[index], ['properties', renderOptions[index].idBy]);

      return _.isUndefined(rowId) ? null : `:id ="${rowId}"`;
    }).
    compact().
    value();

  if (!_.isEmpty(retrieveDataConditions)) {
    return await genericContentFormatter.setPopupContent(
      element,
      vifs,
      renderOptions,
      retrieveDataConditions
    );
  }
}

