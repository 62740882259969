import React, { FunctionComponent } from 'react';

import {
  userCanMakeAssetPublic,
  isPublicAudienceDisabled,
  viewIsVizCanWithNonPublicDataSource
} from 'common/components/AccessManager/Util';
import { getCurrentUser } from 'common/current_user';

import I18n from 'common/i18n';

import AudienceScopeChooserRadioButton from '.';

import type { View } from 'common/types/view';
import { AudienceScope } from 'common/types/view';

interface PublicButtonProps {
  view: Partial<View>;
  visualizationCanvasHasPublicDataSource?: boolean;
}

const getPublicAudienceDisabledMessage = (
  view: Partial<View>,
  visualizationCanvasHasPublicDataSource?: boolean
) => {
  const canMakeAssetPublic = userCanMakeAssetPublic(getCurrentUser());
  const isVizCanWithNonPublicDataSource = viewIsVizCanWithNonPublicDataSource(
    view,
    visualizationCanvasHasPublicDataSource
  );

  if (!canMakeAssetPublic) {
    return I18n.t('shared.site_chrome.access_manager.audience.cannot_make_asset_public');
  } else if (isVizCanWithNonPublicDataSource) {
    return I18n.t('shared.site_chrome.access_manager.audience.cannot_set_vizcan_public');
  } else {
    return null;
  }
};

const PublicButton: FunctionComponent<PublicButtonProps> = ({
  view,
  visualizationCanvasHasPublicDataSource
}) => {
  const publicAudienceDisabled = isPublicAudienceDisabled(view, visualizationCanvasHasPublicDataSource);
  const publicAudienceDisabledMessage = getPublicAudienceDisabledMessage(
    view,
    visualizationCanvasHasPublicDataSource
  );

  return (
    <AudienceScopeChooserRadioButton
      view={view}
      disabled={publicAudienceDisabled}
      hoverMessage={publicAudienceDisabledMessage}
      scope={AudienceScope.Public}
    />
  );
};

export default PublicButton;
