import { defaultTo, map, mapValues, minBy, maxBy, min, merge, pick, toString, values } from 'lodash';

import I18n from 'common/i18n';
import { getPrecision, roundToPrecision } from 'common/numbers';
import { assertIsNil, assertIsString } from 'common/assertions';
import formatString from 'common/js_utils/formatString';
import DataTypeFormatter from 'common/DataTypeFormatter';

import { FilterValue, FILTER_FUNCTION, NumberSoqlFilter, SoqlFilter } from '../../SoqlFilter';
import * as BaseFilter from './BaseFilter';
import { FilterBarColumn } from '../../types';

export function getMinValue(columns: BaseFilter.FilterColumnsMap) {
  const minColumn = minBy(values(columns), 'rangeMin');
  return minColumn?.hasOwnProperty('rangeMin') ? (minColumn as FilterBarColumn).rangeMin : '';
}

export function getMaxValue(columns: BaseFilter.FilterColumnsMap) {
  const maxColumn = maxBy(values(columns), 'rangeMax');
  return maxColumn?.hasOwnProperty('rangeMax') ? (maxColumn as FilterBarColumn).rangeMax : '';
}

export function isPercentage(columns: BaseFilter.FilterColumnsMap) {
  return BaseFilter.getFormattingColumn(columns)?.format?.precisionStyle === 'percentage';
}

export function getPercentScale(columns: BaseFilter.FilterColumnsMap) {
  return BaseFilter.getFormattingColumn(columns)?.format?.percentScale ?? '1';
}

export function migrateFilter(
  filter: SoqlFilter,
  columns: BaseFilter.FilterColumnsMap
): NumberSoqlFilter {
  if (filter.function === FILTER_FUNCTION.VALUE_RANGE) {
    // This function is deprecated. See its entry in VIF.md.
    // valueRange pretends to be a rangeInclusive, but it isn't actually (it's half inclusive, half exclusive).
    // However, the UI strongly suggests to the user that it's properly rangeInclusive. So, make that a reality
    // here.
    const step = min(map([getMinValue(columns), getMaxValue(columns)], getPrecision));
    // The result of this computation is what we show the user. It is reasonable
    // for them to assume we actually use the number we display as the end point (which
    // we don't, for valueRange).
    const fudgedEnd = roundToPrecision(filter.arguments?.end ?? getMaxValue(columns), step);

    const newFilter: SoqlFilter = {
      displayName: filter.displayName,
      dataTypeName: filter.dataTypeName,
      function: FILTER_FUNCTION.RANGE_INCLUSIVE,
      columns: filter.columns,
      arguments: {
        start: toString(filter.arguments?.start),
        end: toString(fudgedEnd),
        includeNullValues: filter.arguments?.includeNullValues
      }
    };
    return newFilter;
  } else {
    return filter as NumberSoqlFilter;
  }
}

export function getNumberFilterHumanText(filter: NumberSoqlFilter, column: FilterBarColumn): string {
  const filterFunction = filter.function;
  assertIsString(filterFunction, `Expected 'function' property to be a string, was: ${filter.function}`);

  // Apart from valueRange, these filters use strings in their arguments for arbitrary numerical precision.
  const { start, end, value } = defaultTo(filter.arguments, {});
  const rangeFilter = 'shared.components.filter_bar.range_filter';

  // Note early return above.
  let key;
  switch (filterFunction) {
    case FILTER_FUNCTION.RANGE_INCLUSIVE:
      assertIsString(start);
      assertIsString(end);
      key = `${rangeFilter}.range_inclusive_label`;
      break;
    case FILTER_FUNCTION.RANGE_EXCLUSIVE:
      assertIsString(start);
      assertIsString(end);
      key = `${rangeFilter}.range_exclusive_label`;
      break;
    case FILTER_FUNCTION.EQUALS:
      assertIsString(value);
      key = `${rangeFilter}.equals_label`;
      break;
    case FILTER_FUNCTION.NOT_EQUAL:
      assertIsString(value);
      key = `${rangeFilter}.not_equals_label`;
      break;
    case FILTER_FUNCTION.GREATER_THAN:
      assertIsString(value);
      key = `${rangeFilter}.above_label`;
      break;
    case FILTER_FUNCTION.GREATER_THAN_EQUAL_TO:
      assertIsString(value);
      key = `${rangeFilter}.at_least_label`;
      break;
    case FILTER_FUNCTION.LESS_THAN:
      assertIsString(value);
      key = `${rangeFilter}.below_label`;
      break;
    case FILTER_FUNCTION.LESS_THAN_EQUAL_TO:
      assertIsString(value);
      key = `${rangeFilter}.at_most_label`;
      break;
    case FILTER_FUNCTION.EXCLUDE_NULL:
      assertIsNil(value);
      key = `${rangeFilter}.exclude_null_label`;
      break;
    default:
      console.error(`Unknown function: ${filterFunction}`);
      key = `${rangeFilter}.invalid_value`;
      break;
  }

  const formattedArgs = mapValues(pick(filter.arguments || {}, ['start', 'end', 'value']), (val) =>
    DataTypeFormatter.renderCellHTML(val, column)
  );

  return formatString(I18n.t(key), formattedArgs);
}

export function getNumberFilterForDrilldowns(
  filterOptions: NumberSoqlFilter,
  columnValue: FilterValue
): NumberSoqlFilter {
  const newFilterOptions = {
    function: FILTER_FUNCTION.EQUALS,
    arguments: {
      value: columnValue,
      includeNullValues: false
    }
  };

  return merge({}, filterOptions, newFilterOptions);
}
