import { assign, chain, find, get, includes, isEmpty, isNil, isNull, map, merge } from 'lodash';
import I18n from 'common/i18n';
import { PicklistOption } from 'common/components/Picklist';

import {
  BinaryOperator,
  FILTER_FUNCTION,
  NoopFilter,
  OPERATOR,
  SoqlFilter,
  DataSourceColumn
} from '../../SoqlFilter';
import * as BaseFilter from './BaseFilter';
import { DataProvider } from '../../types';
import { ViewColumn } from 'common/types/viewColumn';
import { isEqualityOperator, isEqualityFilter, getOperator, groupAllFiltersByDataset } from './index';

import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';

export interface CuratedRegion {
  uid: string;
  geometryLabel: string;
}
interface ResultByValue {
  value: string;
}
interface ResultByCount {
  value: string;
  __count_alias__: number;
}

const FETCH_RESULTS_COUNT = 40;
const SHOW_RESULTS_COUNT = 10;
const DEFAULT_ALL_FILTERS: SoqlFilter[] = [];
const COUNT_ALIAS = '__count_alias__';
const SUGGESTION = '__suggestion';
const ASSOCIATED_DATA = '__associated_data';

export type ComputedColumnSoqlFilter = BinaryOperator | NoopFilter;

export function getComputedColumnFilter(
  config: BinaryOperator | NoopFilter,
  columns: BaseFilter.FilterColumnsMap,
  values: (PicklistOption | null)[],
  isNegated: boolean
) {
  if (isEmpty(values)) {
    const { isHidden } = config;
    return merge({}, BaseFilter.getNoopFilter(columns, config.isDrilldown, config.displayName), { isHidden });
  } else {
    const toArgument = (value: PicklistOption | null) => {
      if (isNull(value)) {
        return {
          operator: isNegated ? OPERATOR.NOT_NULL : OPERATOR.NULL
        };
      } else {
        return {
          operator: isNegated ? OPERATOR.NOT_EQUAL : OPERATOR.EQUALS,
          operand: value.value,
          operandLabel: value.title
        };
      }
    };

    return assign({}, config, {
      function: FILTER_FUNCTION.BINARY_OPERATOR,
      joinOn: isNegated ? 'AND' : 'OR',
      arguments: map(values, toArgument)
    });
  }
}

export async function getTopXOptionsRespectingFilters({
  filter,
  allFilters,
  columns,
  offset,
  limit = SHOW_RESULTS_COUNT,
  dataProviderConfigs = []
}: {
  filter: ComputedColumnSoqlFilter;
  allFilters: SoqlFilter[];
  columns: BaseFilter.FilterColumnsMap;
  offset: number;
  limit?: number;
  dataProviderConfigs: DataProvider[];
}) {
  const allFiltersByDataset = groupAllFiltersByDataset(allFilters);

  const getTopOptionsPromises: Promise<ResultByCount[] | ResultByValue[]>[] = filter.columns.map(
    async (column) => {
      const { datasetUid } = column;
      const dataProviderConfig = find(dataProviderConfigs, { datasetUid }) ?? { datasetUid };
      const dataProvider = new SoqlDataProvider(dataProviderConfig, true);
      const topValuesOptions = {
        allFilters: allFiltersByDataset[datasetUid] || [],
        column: columns[datasetUid],
        filter,
        offset,
        limit
      };

      return dataProvider.getTopXOptionsInColumn(topValuesOptions, datasetUid);
    }
  );

  const allOptions = await Promise.all(getTopOptionsPromises);
  if (allOptions.length == 0 || (allOptions.length == 1 && allOptions[0][COUNT_ALIAS] === '0')) {
    return {
      topXOptions: [],
      picklistOffset: 0
    };
  }

  // Now get the region names matching the top X
  const regionNameSuggestionsPromises: Promise<CuratedRegion[]>[] = filter.columns.map(
    async (column, index) => {
      const datasetUid = get(column, 'datasetUid');
      const primaryKey = columns[column.datasetUid].computationStrategy?.parameters?.primary_key;

      const regionValues = chain(allOptions[index])
        .map((value) => value[column.fieldName])
        .compact()
        .value();
      const dataProviderConfig = find(dataProviderConfigs, { datasetUid }) ?? {
        datasetUid
      };

      return getSpatialLensDataProvider(column, columns, dataProviderConfig).getSpatialLensRegions(
        primaryKey,
        regionValues
      );
    }
  );

  const allRegions = await Promise.all(regionNameSuggestionsPromises);

  // TODO EN-62079: Make this work for all data sources
  const metadataProvider = new MetadataProvider(dataProviderConfigs[0], true);
  const curatedRegions: CuratedRegion[] = await metadataProvider.getCuratedRegions();

  // Now build the top X options
  const topXoptions = filter.columns.map((column, index) => {
    const datasetUid = get(column, 'datasetUid');
    const primaryKey = columns[datasetUid].computationStrategy?.parameters?.primary_key;
    const curatedRegion = find(
      curatedRegions,
      (item) => item.uid === (columns[datasetUid] as ViewColumn).uid
    );
    const geometryLabel = get(curatedRegion, 'geometryLabel', null);
    const noValueLabel = I18n.t('shared.components.filter_bar.text_filter.no_value');
    const getTitle = (columnValue: string) => {
      if (!geometryLabel || !primaryKey) {
        return noValueLabel;
      }
      const region = find(allRegions[index], (item) => item[primaryKey] === columnValue);
      return isNil(region) || isNil(region[geometryLabel]) ? noValueLabel : region[geometryLabel];
    };

    const topXOptions = map(allOptions[index], (topColumnValue) => {
      const columnValue = get(topColumnValue, column.fieldName);
      return isNil(columnValue)
        ? {
            title: I18n.t('shared.components.filter_bar.text_filter.no_value'),
            value: null,
            group: I18n.t('shared.components.filter_bar.text_filter.suggested_values')
          }
        : {
            group: I18n.t('shared.components.filter_bar.text_filter.suggested_values'),
            title: getTitle(columnValue),
            value: columnValue
          };
    });

    return topXOptions;
  });

  return {
    topXOptions: topXoptions,
    picklistOffset: offset + limit
  };
}

export async function getSuggestions({
  filter,
  searchTerm,
  columns,
  dataProviderConfigs = []
}: {
  filter: ComputedColumnSoqlFilter;
  searchTerm: string;
  columns: BaseFilter.FilterColumnsMap;
  limit?: number;
  dataProviderConfigs?: DataProvider[];
}) {
  if (isEmpty(searchTerm)) {
    return { results: [] };
  }

  // Get the search column name which is the shape label (geometry label) and
  // the associated data column which is the primary key value.
  const allSuggestionsPromises: Promise<Pick<any, '__suggestion' | '__associated_data'>[]>[] =
    filter.columns.map(async (column) => {
      // Get the search column name which is the shape label (geometry label) and
      // the associated data column which is the primary key value.
      const { datasetUid } = column;
      const associatedDataColumnName = get(columns[datasetUid], 'computationStrategy.parameters.primary_key');
      const dataProviderConfig = find(dataProviderConfigs, { datasetUid }) ?? { datasetUid };
      const metadataProvider = new MetadataProvider(dataProviderConfig, true);

      const curatedRegions = await metadataProvider.getCuratedRegions();
      const curatedRegion = find(curatedRegions, (item) => item.uid === columns[datasetUid].uid) || {};
      const searchColumnName = get(curatedRegion, 'geometryLabel');

      // Now do the search in the shapefile's dataset for regions matching the search term.
      const searchOptions = {
        associatedDataColumnName,
        filters: DEFAULT_ALL_FILTERS,
        limit: FETCH_RESULTS_COUNT,
        searchColumnName,
        searchTerm
      };

      return getSpatialLensDataProvider(column, columns, dataProviderConfig).searchInSpatialLensDataset(
        searchOptions
      ) as Promise<Pick<any, '__suggestion' | '__associated_data'>[]>;
    });

  const selectedValues = getOperands(filter);
  const selectedValuesValues = map(selectedValues, (selectedValue) => selectedValue.value);

  const allSuggestions = await Promise.all(allSuggestionsPromises);

  const results = allSuggestions.map((suggestions) => {
    // Process the results from the query.

    return chain(suggestions)
      .reject((suggestion) => includes(selectedValuesValues, suggestion.__associated_data))
      .map((suggestion) => ({
        matches: [],
        title: suggestion[SUGGESTION],
        value: suggestion[ASSOCIATED_DATA]
      }))
      .compact()
      .take(SHOW_RESULTS_COUNT)
      .value();
  });

  return { results: results[0] };
}

export function getEqualityComputedColumnFilter(
  filter: ComputedColumnSoqlFilter,
  {
    operator = getOperator(filter),
    values
  }: {
    operator?: OPERATOR;
    values: Pick<PicklistOption, 'value' | 'title'>[];
  }
): ComputedColumnSoqlFilter {
  const isOperatorNegated = operator === OPERATOR.NOT_EQUAL;
  const isSingleSelect = filter.singleSelect;
  const isNegated = !isSingleSelect && isOperatorNegated;
  const toArgument = (argument: Pick<PicklistOption, 'value' | 'title'>) => {
    if (isNull(argument.value)) {
      return {
        operandLabel: argument.title,
        operator: isNegated ? OPERATOR.NOT_NULL : OPERATOR.NULL
      };
    } else {
      return {
        operandLabel: argument.title,
        operator: isNegated ? OPERATOR.NOT_EQUAL : OPERATOR.EQUALS,
        operand: argument.value
      };
    }
  };

  const isEmptyFilter = isEmpty(values);

  const updatedConfig = assign({}, filter, {
    function: isEmptyFilter ? FILTER_FUNCTION.NOOP : FILTER_FUNCTION.BINARY_OPERATOR,
    arguments: isEmptyFilter ? null : map(values, toArgument),
    joinOn: isNegated ? 'AND' : 'OR'
  });
  return updatedConfig;
}

export function setOperator(filter: ComputedColumnSoqlFilter, operator: OPERATOR): ComputedColumnSoqlFilter {
  if (isEqualityFilter(filter) !== isEqualityOperator(operator)) {
    // If we're switching between suggestions vs user input values, then
    // also reset the selected values.
    return getEqualityComputedColumnFilter(filter, { operator, values: [] });
  }

  return getEqualityComputedColumnFilter(filter, { operator, values: getOperands(filter) });
}

// This functions is pretty much the same as the one in TextFilter.ts
// but here we return the value and title of the selected values
export function getOperands(filter: ComputedColumnSoqlFilter) {
  if (filter.function === FILTER_FUNCTION.BINARY_OPERATOR) {
    return ((filter as BinaryOperator).arguments || []).map((argument) => {
      const { operand, operandLabel } = argument;
      return { value: operand, title: operandLabel };
    });
  } else {
    return [];
  }
}

/**
 * Get the soql data provider for the _spatial lens_, which is a different dataset
 * than the one we're currently filtering.
 * @returns SoqlDataProvider
 */
function getSpatialLensDataProvider(
  column: DataSourceColumn,
  computedColumns: BaseFilter.FilterColumnsMap,
  dataProvider: DataProvider
) {
  const computedColumnDatasetUid = get(column, 'datasetUid');
  const computedColumnViewColumn = computedColumns[computedColumnDatasetUid];
  return new SoqlDataProvider({
    datasetUid: get(computedColumnViewColumn, 'uid') ?? computedColumnDatasetUid,
    domain: dataProvider.domain
  });
}
