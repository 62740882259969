import _ from 'lodash';
import d3 from 'd3';
import $ from 'jquery';

// Used in SVGHistogram
export function clamp(x, min, max) {
  if (!_.isNumber(x) || !_.isNumber(min) || !_.isNumber(max)) {
    throw new Error('DistributionChartHelpers.clamp inputs must be numbers');
  }

  return Math.max(Math.min(x, max), min);
}

function throwGetBucketingOptionsError(domain) {
  throw new Error('Bad domain to getBucketingOptions ' + JSON.stringify(domain));
}

export function getBucketingOptions(domain, bucketTypeOverride, vif) {
  const invalidDomain = !_.isObject(domain) || !_.isFinite(domain.min) || !_.isFinite(domain.max);
  const currentVizType = _.get(vif, 'series[0].type');

  if (invalidDomain) {
    (currentVizType == 'histogram') ? $.fn.socrataSvgHistogram.validateVif(vif) : throwGetBucketingOptionsError(domain);
  }

  const absMax = Math.max(Math.abs(domain.min), Math.abs(domain.max));
  const threshold = 2000;

  const result = {};

  // If we have a defined bucket type, use that.  Otherwise, determine the
  // bucket type based upon the data.
  if (_.isString(bucketTypeOverride)) {
    result.bucketType = bucketTypeOverride;
  } else {
    result.bucketType = absMax >= threshold ? 'logarithmic' : 'linear';
  }

  if (result.bucketType === 'linear') {
    const buckets = d3.scale
      .linear()
      .nice()
      .domain([domain.min, domain.max])
      .ticks(20);

    if (buckets.length >= 2) {
      // We are vulnerable to floating point errors here so rounding off
      result.bucketSize = Math.round((buckets[1] - buckets[0]) * 1000000) / 1000000;
    } else {
      result.bucketSize = 1;
    }
  }

  return result;
}

/**
 * Given an array of buckets, normalizes the data to an array of bucket
 * objects containing start, end, and value keys representing the
 * inclusive minimum, exclusive maximum, and value of the bucket.  There
 * are two different possible paths based on whether or not we are using
 * a logarithmic scale or a linear scale.
 *
 * returns [{start:, end:, value:}];
 */
export function bucketData(input, options) {
  // Input validation
  if (!_.isArray(input) || _.isEmpty(input)) {
    return [];
  }

  if (!_.isObject(options)) {
    throw new Error('Missing options from bucketData');
  }

  if (options.bucketType === 'linear' && !_.isNumber(options.bucketSize)) {
    return null;
  }

  const dataByMagnitude = helpers.getDataByMagnitude(input, options);
  const range = helpers.getMagnitudeRange(dataByMagnitude, options);

  // Map over the range, converting magnitudes into start and end keys.
  return _.map(range, function(magnitude) {
    // Try to get the value for the original bucket, defaulting to zero.
    let value = _.get(dataByMagnitude, magnitude + '.value', 0);

    // This is (hopefully temporarily) in place to remedy an issue where
    // sum aggregation on a histogram causes many issues if there are
    // no values to sum by for a particular bucket. Rather than deal with
    // the ensuing NaNs in the visualization, we set them to zero here.
    if (!_.isFinite(value)) {
      value = 0;
    }

    if (options.bucketType === 'logarithmic') {
      return helpers.getLogarithmicBucket(magnitude, value);
    } else {
      if (options.bucketType !== 'linear') {
        console.warn(`Unknown bucket type "${options.bucketType}", defaulting to linear`);
      }

      return helpers.getLinearBucket(magnitude, value, options.bucketSize);
    }
  });
}

// Returns an object mapping magnitudes to buckets. Also merges the
// bucket with magnitude zero into the bucket with magnitude one for logarithmic bucketing
export function getDataByMagnitude(data, options) {
  const dataByMagnitude = _.keyBy(_.cloneDeep(data), 'magnitude');
  const bucketType = _.get(options, 'bucketType');

  // Merge zero-bucket into one-bucket, if logarithmic
  if (bucketType === 'logarithmic') {
    if (_.isPlainObject(dataByMagnitude[0])) {
      if (_.isPlainObject(dataByMagnitude[1])) {
        dataByMagnitude[1].value += dataByMagnitude[0].value;
      } else {
        dataByMagnitude[1] = { magnitude: 1, value: dataByMagnitude[0].value };
      }
    }
  }

  return dataByMagnitude;
}

// Returns a range of magnitudes to iterate over. The range must be
// continuous because of the use of an ordinal scale. The zero bucket
// must be eliminated due to the current way zero buckets are treated.
export function getMagnitudeRange(dataByMagnitude, options) {
  const bucketType = _.get(options, 'bucketType');
  const forceIncludeZero = _.get(options, 'forceIncludeZero', false);
  const extent = d3.extent(_.map(dataByMagnitude, 'magnitude'));
  let min = extent[0];
  let max = extent[1];
  let range;

  if (forceIncludeZero) {
    if (min > 0 && max > 0) {
      min = 0;
    } else if (min < 0 && max < 0) {
      max = 0;
    }
  }

  // +1 is there because _.range is a [min, max) range
  range = _.range(min, max + 1);

  // If we artificially extended the range so that it ends with '0', we need to remove '0' after the range is created
  // since 'getLinearBucket' will take each range value as a 'start', and set the 'end' to one magnitude above that
  // we would end up with '0 + bucketSize' as the last tick, as opposed to '0' if we didn't omit here
  if (forceIncludeZero && _.last(range) === 0) {
    range.pop();
  }

  // Only pull 0's out for Logarithmic buckets
  return bucketType === 'linear' ? range : _.pull(range, 0);
}

// Converts magnitude to start and end
export function getLogarithmicBucket(magnitude, value) {
  let start = 0;
  let end = 0;

  if (magnitude > 0) {
    start = Math.pow(10, magnitude - 1);
    end = Math.pow(10, magnitude);

    // TODO we shouldn't be doing this - it groups
    // an infinite number of orders of magnitude into
    // one bucket.
    if (start === 1) {
      start = 0;
    }
  } else if (magnitude < 0) {
    start = -Math.pow(10, Math.abs(magnitude));
    end = -Math.pow(10, Math.abs(magnitude + 1));

    // TODO we shouldn't be doing this - it groups
    // an infinite number of orders of magnitude into
    // one bucket.
    if (end === -1) {
      end = 0;
    }
  }

  return {
    start: start,
    end: end,
    value: value
  };
}

// Converts magnitude to start and end
export function getLinearBucket(magnitude, value, bucketSize) {
  // Also vulnerable to floating point weirdness here; applying same fix as above for now
  // until we can settle on a formal way to deal with floating point math
  const start = Math.round(magnitude * bucketSize * 1000000) / 1000000;
  const end = Math.round((magnitude + 1) * bucketSize * 1000000) / 1000000;

  return {
    start: start,
    end: end,
    value: value
  };
}

// Used in tests.
// Returns an x and a y scale based on the current data.  The x scale maps buckets labels to pixel
// values along the horizontal axis, and the y scale maps the values in the bucket to pixel values
// along the y axis.
export function getScaleForData(data) {
  if (!_.isObject(data)) {
    return;
  }

  const scale = {
    x: d3.scale.ordinal(),
    y: d3.scale.linear()
  };

  // First determine the list of buckets and set the x scale's domain.
  const buckets = _.map(data.unfiltered, 'start');

  if (!_.isEmpty(buckets)) {
    buckets.push(_.last(data.unfiltered).end);
  }

  scale.x.domain(buckets);

  // Then determine the extent of the data, extend it to zero if necessary and set the y domain.
  const dataRangeUnfiltered = d3.extent(data.unfiltered, _.property('value'));
  const dataRangeFiltered = d3.extent(data.filtered, _.property('value'));
  const extentY = d3.extent(dataRangeUnfiltered.concat(dataRangeFiltered));

  if (extentY[0] > 0) {
    extentY[0] = 0;
  } else if (extentY[0] < 0 && extentY[1] < 0) {
    extentY[1] = 0;
  }

  scale.y.domain(extentY);

  return scale;
}

const helpers = {
  clamp,
  getBucketingOptions,
  bucketData,
  getDataByMagnitude,
  getMagnitudeRange,
  getLogarithmicBucket,
  getLinearBucket,
  getScaleForData
};

export default helpers;
