import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { classNameScope } from './Results';

export class Result extends React.Component {
  static propTypes = {
    onChooseResult: PropTypes.func.isRequired,
    onResultFocusChanged: PropTypes.func.isRequired,
    onResultsVisibilityChanged: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    matchOffsets: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    focused: PropTypes.bool
  };

  handleClick = (event) => {
    const { onResultsVisibilityChanged, onChooseResult } = this.props;

    onResultsVisibilityChanged(false);

    // actually search for the clicked item
    onChooseResult(this.props.name);
    event.preventDefault();
  };

  handleMouseOver = () => {
    // we set focus on mouseover so if somebody mouses over a result,
    // then uses the arrow keys, we start from the proper spot
    this.props.onResultFocusChanged(this.props.index);
  };

  // Returns array of `name` prop fragments decorated with <span class="highlight">
  // around the matches specified in the `matchOffsets` prop.
  displayTitle = () => {
    const { matchOffsets, name } = this.props;
    const displayTitleFragments = [];

    // Beginning of title, before any matches
    displayTitleFragments.push(name.substring(0, _.get(matchOffsets[0], 'start')));

    matchOffsets.forEach((offset, index) => {
      // Wrap each match with <span class="highlight">
      displayTitleFragments.push(
        <span className="highlight" key={index}>
          {name.substring(offset.start, offset.length + offset.start)}
        </span>
      );

      // Push the following non-highlighted substring up until the next highlighted substring,
      // or the rest of the name if there isn't another highlighted substring.
      const nextMatch = matchOffsets[index + 1];
      const nextMatchStart = nextMatch ? nextMatch.start : undefined;

      displayTitleFragments.push(name.substring(offset.length + offset.start, nextMatchStart));
    });

    return displayTitleFragments;
  };

  render() {
    return (
      <div
        key={this.props.name}
        className={`${classNameScope}--${this.props.focused === true ? 'result-focused' : 'result'}`}
        onMouseDown={this.handleClick}
        onMouseOver={this.handleMouseOver}
      >
        {this.displayTitle()}
      </div>
    );
  }
}

export default Result;

/** For testing purposes */
export const ResultClass = Result;
