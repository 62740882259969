import { kebabCase } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import Button, { VARIANTS } from 'common/components/Button';
import FlyoutFactory from 'common/components/legacy/Flyout';
import './index.scss';

interface Props {
  /**
   * id is used to identify which tooltip should be triggered by which icon on
   * the page
   */
  id: string | React.ReactNode | null;
  /**
   * (Default: undefined)
   * An object used for populating the flyout attached to the
   * `?` icon. If nothing is specified, a flyout will not appear.
   */
  content?: any;
  /**
   * (Default: <span className={'icon-question'} />)
   * An object where the tooltip attaches to. By default this is a span with
   * a question mark icon
   */
  children?: React.ReactNode;
}

const HelperTooltip: FunctionComponent<Props> = ({
  id,
  content,
  children,
}) => {
  const [flyoutElement, setFlyoutElement] = useState<HTMLDivElement | null>(null);
  const flyoutName = id ? `${kebabCase(id.toString())}-flyout` : undefined;

  const childrenWithProps = React.Children.map(children,
    child => React.cloneElement(child as React.ReactElement<any>)
  );

  // setup flyout if applicable
  // checking for [data-flyout] is necessary because on mounting the component
  // goes through multiple renders as the underlying state hooks change.
  // Specifically, there is a render prior to the final render in which
  // `flyoutElement` is set, but does not have a `[data-flyout]` yet. If we
  // create a new FlyoutFactory with a `flyoutElement` which lacks a
  // `[data-flyout]` element, a warning is logged to console, and we want to
  // avoid that.
  if (content && flyoutElement && flyoutElement.querySelectorAll('[data-flyout]').length > 0) {
    new FlyoutFactory(flyoutElement);
  }

  const renderFlyout = () => {
    if (flyoutElement) {
      return (
        <div id={flyoutName} className="flyout flyout-hidden flyout-block-label">
          <section className="flyout-content">
            {content}
          </section>
        </div>
      );
    }
  };

  const renderHelpIcon = () => {
    if (content && flyoutElement) {
      return (
        <Button
          variant={VARIANTS.LINK}
          data-flyout={flyoutName}
          className="helper-tooltip-icon"
          role="tooltip"
          aria-labelledby={`label-${flyoutName}`}
          aria-describedby={flyoutName}
        >
          {childrenWithProps}
        </Button>
      );
    }
  };

  return (
    <div
      className="helper-tooltip"
      ref={element => {setFlyoutElement(element);}}
      id={`label-${flyoutName}`}
    >
      {renderHelpIcon()}
      {renderFlyout()}
    </div>
  );
};

HelperTooltip.defaultProps = {
  id: 'helper-tooltip',
  children: <span className={'icon-question'} />
};

export default HelperTooltip;
