/* Imports
================================================================================ */
import React, { Component } from 'react';
import { ForgeBadge, ForgeIcon } from '@tylertech/forge-react';

import { withGuidanceV2 } from 'common/core/approvals/index_new';
import {
  ApprovalState,
  Outcome as ApprovalOutcome,
  WorkflowTargetAudience
} from 'common/core/approvals_enums';
import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { AudienceScope, View } from 'common/types/view';
import { AssetStatus } from 'common/views/asset_status';
import { assetIsDraft } from 'common/views/helpers';

/* Pre-defined Values
================================================================================ */

/* Types & Interfaces
---------------------------------------------------------------------- */
type ForgeStatusToUiBadge = {
  translationKey: string;
  type: 'badge-warning' | 'badge-danger';
  name?: undefined;
};
type ForgeStatusToUiIcon = { translationKey: string; type: 'icon'; name: string };
type ForgeStatusToUi = ForgeStatusToUiBadge | ForgeStatusToUiIcon;

export interface PublicationStateProps {
  approvalsGuidance: GuidanceSummaryV2;
  view: View;
}

/**
 * An asset can have many different states of publication that come from multiple places...
 * If this is an ApprovalOutcome, then it means the asset is awaiting approval.
 * If this is an AudienceScope, it means the asset is already published.
 * If this is AssetStatus.Draft then the asset has not yet been published (or we're looking at a draft of an existing asset)
 * locked is also included here, because it appears in this spot, but it's not technically a publication state
 *
 * Note that while this is a union of separate enums, it is actually backed by strings.
 */
type StatusKey = ApprovalOutcome | AudienceScope | AssetStatus.Draft | 'locked' | null;
const locked = 'locked';

/* Component
================================================================================ */
class PublicationState extends Component<PublicationStateProps> {
  renderAssetStatus(
    statusKey: StatusKey,
    targetAudience: WorkflowTargetAudience = WorkflowTargetAudience.PUBLIC
  ) {
    const scope = 'shared.components.asset_action_bar.publication_state';
    const statusToUi: { [statusKey: string]: ForgeStatusToUi } = {
      // the asset is in the approval queue and the outcome of it being approved
      // is that is will become public
      [ApprovalOutcome.CHANGE_AUDIENCE]: {
        translationKey: targetAudience + '_approval_requested',
        type: 'badge-warning'
      },

      // the asset is already published but is going through a revision
      // and that revision will require approval
      [ApprovalOutcome.UPDATE_PUBLISHED_ASSET]: {
        translationKey: 'update_approval_requested',
        type: 'badge-warning'
      },

      // the asset is a draft that is not yet published
      [AssetStatus.Draft]: {
        translationKey: 'draft',
        type: 'badge-warning'
      },

      // the asset is published to the site/internal scope
      [AudienceScope.Site]: {
        name: 'business',
        translationKey: 'internal',
        type: 'icon'
      },

      // the asset is published to privately
      [AudienceScope.Private]: {
        name: 'lock',
        translationKey: 'private',
        type: 'icon'
      },

      // the asset is published (and approved, if necessary) to the public
      [AudienceScope.Public]: {
        name: 'public',
        translationKey: 'public',
        type: 'icon'
      },

      [locked]: {
        translationKey: 'locked',
        type: 'badge-danger'
      }
    };

    const { name, translationKey, type } = statusToUi[statusKey as string];
    const translationText = I18n.t(translationKey, { scope });
    const statusBadge =
      type === 'icon' ? (
        <>
          <ForgeIcon name={name} external external-type="standard" /> {translationText}
        </>
      ) : (
        <ForgeBadge theme={type.replace(/badge-/, '')}>{translationText}</ForgeBadge>
      );

    return <div className={'publication-state forge-typography--subtitle2'}>{statusBadge}</div>;
  }

  renderChangeStatus() {
    const { approvalsGuidance, view } = this.props;

    let pendingChange: StatusKey = null;
    let targetAudience = withGuidanceV2(approvalsGuidance).whichIsPending();

    if (targetAudience) {
      // if the asset is pending approval for a target audience according to guidance, we want to show it as pending
      // currentOutcome here will either be that it's a change audience request or a data update request
      pendingChange = approvalsGuidance[targetAudience].currentOutcome;
    } else if (view.approvals) {
      // if the view itself has approvals on it, we use the outcome from that instead
      const pendingApproval = view.approvals.find((approval) => approval.state === ApprovalState.PENDING);

      if (pendingApproval) {
        pendingChange = pendingApproval.submissionOutcome || null;
        targetAudience = pendingApproval.targetAudience;
      }
    }

    return pendingChange && this.renderAssetStatus(pendingChange, targetAudience);
  }

  renderDraftStatus() {
    const { view } = this.props;

    return assetIsDraft({ coreView: view }) && this.renderAssetStatus(AssetStatus.Draft);
  }

  renderScopeStatus() {
    const { view } = this.props;
    const scopeKey = view?.permissions?.scope;

    return scopeKey ? this.renderAssetStatus(scopeKey) : null;
  }

  renderLockedStatus() {
    const { view } = this.props;
    const viewIsLocked = FeatureFlags.valueOrDefault('enable_asset_locking', false) && view?.locked;

    return viewIsLocked ? this.renderAssetStatus(locked) : null;
  }

  render() {
    const draftStatus = this.renderDraftStatus();
    const scopeStatus = !draftStatus && this.renderScopeStatus();
    const changeStatus = this.renderChangeStatus();
    const lockedStatus = this.renderLockedStatus();

    const divider = changeStatus && (draftStatus || scopeStatus) && <div className="divider" />;

    return (
      <div className="publication-state-container">
        {draftStatus}
        {scopeStatus}
        {divider}
        {changeStatus}
        {lockedStatus}
      </div>
    );
  }
}

export default PublicationState;
