import { findUserWithAccessLevel } from 'common/components/AccessManager/Util';
import type { AccessManagerState } from '../types';
import { AccessLevelName } from 'common/types/view';

// ui selectors
export const getUiMode = (state: AccessManagerState) => state.ui.mode;
export const getOnConfirm = (state: AccessManagerState) => state.ui.onConfirm;
export const getOnDismiss = (state: AccessManagerState) => state.ui.onDismiss!;
export const getShowApprovalMessage = (state: AccessManagerState) => state.ui.showApprovalMessage;
export const getShowRejectedMessage = (state: AccessManagerState) => state.ui.showRejectedMessage;
export const getApprovalOutcomeMessages = (state: AccessManagerState) => ({
  showApprovalMessage: getShowApprovalMessage(state),
  showRejectedMessage: getShowRejectedMessage(state)
});
export const getTargetAudience = (state: AccessManagerState) => state.ui.targetAudience;
export const getFooterConfig = (state: AccessManagerState) => state.ui.footer;
export const getErrors = (state: AccessManagerState) => state.ui.errors;
export const getErrorIntro = (state: AccessManagerState) => state.ui.errorIntro;
export const getModalTransition = (state: AccessManagerState) => state.ui.modalTransition;

// permissions selectors
export const getAssetUid = (state: AccessManagerState) => state.permissions.view.id;
export const getPermissions = (state: AccessManagerState) => state.permissions.permissions;
export const getApprovalsGuidance = (state: AccessManagerState) => state.permissions.approvalsGuidance;
export const getAddedUsers = (state: AccessManagerState) => state.permissions.permissions?.users || [];
export const getCurrentOwner = (state: AccessManagerState) =>
  findUserWithAccessLevel(state.permissions.permissions?.users || [], AccessLevelName.CurrentOwner);
export const getCurrentView = (state: AccessManagerState) => state.permissions.view;
export const getCurrentScope = (state: AccessManagerState) =>
  state.permissions.permissions ? state.permissions.permissions.scope : null;
export const getVisualizationCanvasHasPublicDataSource = (state: AccessManagerState) =>
  state.permissions.visualizationCanvasHasPublicDataSource;
export const getAvailableAccessLevels = (state: AccessManagerState) =>
  state.permissions.permissions?.accessLevels;

// addCollaborators selectors
export const getCurrentUserSearchQuery = (state: AccessManagerState) => state.addCollaborators.query;
export const getSelectedUsers = (state: AccessManagerState) =>
  state.addCollaborators && state.addCollaborators.selectedUsers;
export const getAccessLevel = (state: AccessManagerState) => state.addCollaborators.accessLevel;
export const getAddCollaboratorsSearchResults = (state: AccessManagerState) =>
  state.addCollaborators.results?.results || undefined;
export const getAddCollaboratorsSearchQuery = (state: AccessManagerState) => state.addCollaborators.query;
export const getAddCollaboratorsSelectedUsers = (state: AccessManagerState) =>
  state.addCollaborators.selectedUsers;
export const getAddCollaboratorsValidationErrors = (state: AccessManagerState) =>
  state.addCollaborators.validationErrors || [];

// publishedTo selectors
export const getSelectedPublishTo = (state: AccessManagerState) => state.publishedTo.selectedUsers;
export const getPublishedToUserSearchQuery = (state: AccessManagerState) => state.publishedTo.query || '';
export const getPublishedToSearchResults = (state: AccessManagerState) =>
  state.publishedTo.results ? state.publishedTo.results.results : undefined;
export const getPublishedToSelectedUsers = (state: AccessManagerState) => state.publishedTo.selectedUsers;

// changeOwner selectors
export const getSelectedOwner = (state: AccessManagerState) =>
  state.changeOwner.selectedOwner ? state.changeOwner.selectedOwner[0] : undefined;

// schedule count selectors
export const getScheduleCounts = (state: AccessManagerState) => state.permissions.scheduleCounts;
