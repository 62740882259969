import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CopyAssetModal from 'common/components/CopyAssetModal';
import { AssetType } from 'common/types/view';

import { getCopyAPIUrl } from 'lib/StoryHelper';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { closeCopyStoryModal } from 'store/reducers/StoryCopierSlice';
import { copyStoryModalSelector } from 'store/selectors/StoryCopierSelectors';

const StoryCopyModal = () => {
  const dispatch = useDispatch();
  const isStoryCopyModalOpen = useSelector(copyStoryModalSelector);
  const dismissCopyModal = () => dispatch(closeCopyStoryModal());
  const storyTitle = useSelector(storySelectors.getStoryTitleToCopy);

  if (!isStoryCopyModalOpen) {
    return null;
  }

  const onCopy = (title) => {
    window.open(`${getCopyAPIUrl()}?title=${title}`, '_blank');
    dismissCopyModal();
  };

  return (
    <CopyAssetModal
      assetTitle={storyTitle}
      onCopy={onCopy}
      assetType={AssetType.Story}
      onDismiss={dismissCopyModal}
    />
  );
};

export default StoryCopyModal;
