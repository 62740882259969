/* Imports */
/* ============================================================================= */
import { coreUidFromAssetId } from 'common/cetera/catalog_id';
import { fetchJsonWithParsedError } from 'common/http/index';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import {
  getGuidanceSummaryHelper,
  getWorkflowGuidanceHelper,
  isGuidanceSummary,
  isWorkflowGuidance
} from 'common/core/approvals/guidanceHelpers';
import * as Types from 'common/core/approvals/Types';





/* Exports */
/* ============================================================================= */
export * from 'common/core/approvals/guidanceLogic';





/* Guidance Fetch Method(s) */
/* ============================================================================= */
/**
 * @param assetId: catalog asset id; is one of:
 *  - 4x4 (all published assets, working copies)
 *  - 4x4:<revision seq> (revisions)
 *  - 4x4:draft (story draft)
 * @returns A Promise object returning a GuidanceSummaryV2 object
 */
export const fetchApprovalsGuidanceV2 = (assetId: string): Promise<GuidanceSummaryV2> => {
  const coreUid = coreUidFromAssetId(assetId);
  const apiPath = `/api/views/${coreUid}/approvals?method=guidance_v2&assetId=${assetId}`;
  const options = {};

  return fetchJsonWithParsedError(apiPath, options);
};





/* Main Method */
/* ============================================================================= */
/**
 * Method to consume a guidance response object and return a guidance helper object. Will error if it can't create a helper object.
 * @param guidance Either a WorkflowGuidance or GuidanceSummaryV2 object
 * @returns A guidance helper object. The type of the returned object depends on the type of guidance passed in:
 *  - WorkflowGuidance -> WorkflowGuidanceHelper
 *  - GuidanceSummaryV2 -> GuidanceSummaryV2Helper
 */
export const withGuidanceV2 = <T extends Types.GuidanceObject = Types.GuidanceObject>(guidance: T): Types.WithGuidanceV2<T> => {
  let response;

  if (isWorkflowGuidance(guidance)) {
    response = getWorkflowGuidanceHelper(guidance);
  } else if (isGuidanceSummary(guidance)) {
    response = getGuidanceSummaryHelper(guidance);
  } else {
    throw new Error('Invalid guidance object provided to withGuidanceV2 helper');
  }

  return response as Types.WithGuidanceV2<T>;
};
