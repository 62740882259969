import { BlockDict, Story } from 'types';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { storyReducerStoriesSelector, storyReducerBlocksSelector } from 'store/TopLevelSelector';
import { selectors as digestSelectors } from '../DigestSelectors';
import { selectors as dataSourceSelectors } from 'store/selectors/DataSourceSelectors';
import { BlockComponent, StorySerialized } from 'types';

import {
  doesStoryExist,
  isStoryInternal,
  isStoryPublic,
  isStorySaved,
  isStorySavePossible,
  getStoryPublishedStory,
  isDraftUnpublished,
  draftHasChanges,
  getBlockAndComponent,
  getStory,
  getStoryTitle,
  getStoryTitleToCopy,
  getStoryDescription,
  getStoryTileTitle,
  getStoryTileDescription,
  getStoryTheme,
  getStoryLayout,
  getStoryUpdatedAt,
  getStoryPermissions,
  serializeStory
} from 'store/selectors/StorySelectors/Stories';

import {
  getStoryBlockIds,
  getStoryBlockAtIndex,
  getStoryBlockIdAtIndex,
  getBlockLayout,
  getBlockComponents,
  isBlockPresentable,
  getBlockBackgroundColor,
  getBlockComponentAtIndex,
  isBlockRestrictedToMeasureCardsOnly
} from 'store/selectors/StorySelectors/Blocks';

interface StoryState {
  stories: StoryDict;
  blocks: BlockDict;
}

interface StoryDict {
  [storyUid: string]: Story;
}

export const selectors = {
  doesStoryExist: (state: StorytellerState, storyUid: string) =>
    doesStoryExist(storyUid, storyReducerStoriesSelector(state)),
  isStoryPublic: (state: StorytellerState, storyUid: string) =>
    isStoryPublic(storyUid, storyReducerStoriesSelector(state)),
  isStoryInternal: (state: StorytellerState, storyUid: string) =>
    isStoryInternal(storyUid, storyReducerStoriesSelector(state)),
  isStorySaved,
  isStorySavePossible,
  getStoryPublishedStory: (state: StorytellerState, storyUid: string) =>
    getStoryPublishedStory(storyUid, storyReducerStoriesSelector(state)),
  isDraftUnpublished: (state: StorytellerState, storyUid: string) =>
    isDraftUnpublished(storyUid, storyReducerStoriesSelector(state), digestSelectors.getStoryDigest(state)),
  draftHasChanges: (state: StorytellerState, storyUid: string) =>
    draftHasChanges(storyUid, storyReducerStoriesSelector(state), digestSelectors.getStoryDigest(state)),
  getBlockAndComponent: (state: StorytellerState, blockId: string, componentIndex: number) =>
    getBlockAndComponent(blockId, componentIndex, storyReducerBlocksSelector(state)),
  getStoryTitle: (state: StorytellerState, storyUid: string) =>
    getStoryTitle(storyUid, storyReducerStoriesSelector(state)),
  getStoryTitleToCopy: (state: StorytellerState) => getStoryTitleToCopy(storyReducerStoriesSelector(state)),
  getStory: (state: StorytellerState, storyUid: string) =>
    getStory(storyUid, storyReducerStoriesSelector(state)),
  getStoryDescription: (state: StorytellerState, storyUid: string) =>
    getStoryDescription(storyUid, storyReducerStoriesSelector(state)),
  getStoryTileTitle: (state: StorytellerState, storyUid: string) =>
    getStoryTileTitle(storyUid, storyReducerStoriesSelector(state)),
  getStoryTileDescription: (state: StorytellerState, storyUid: string) =>
    getStoryTileDescription(storyUid, storyReducerStoriesSelector(state)),
  getStoryTheme: (state: StorytellerState, storyUid: string) =>
    getStoryTheme(storyUid, storyReducerStoriesSelector(state)),
  getStoryLayout: (state: StorytellerState, storyUid: string) =>
    getStoryLayout(storyUid, storyReducerStoriesSelector(state)),
  getStoryUpdatedAt: (state: StorytellerState, storyUid: string) =>
    getStoryUpdatedAt(storyUid, storyReducerStoriesSelector(state)),
  getStoryPermissions: (state: StorytellerState, storyUid: string) =>
    getStoryPermissions(storyUid, storyReducerStoriesSelector(state)),

  getBlocks: (state: StorytellerState) => storyReducerBlocksSelector(state),
  getStoryBlockIds: (state: StorytellerState, storyUid: string) =>
    getStoryBlockIds(storyUid, storyReducerStoriesSelector(state)),
  getStoryBlockAtIndex: (state: StorytellerState, storyUid: string, index: number) =>
    getStoryBlockAtIndex(
      storyUid,
      index,
      storyReducerStoriesSelector(state),
      storyReducerBlocksSelector(state)
    ),
  getStoryBlockIdAtIndex: (state: StorytellerState, storyUid: string, index: number) =>
    getStoryBlockIdAtIndex(storyUid, index, storyReducerStoriesSelector(state)),
  getBlockLayout: (state: StorytellerState, blockId: string) =>
    getBlockLayout(blockId, storyReducerBlocksSelector(state)),
  getBlockComponents: (state: StorytellerState, blockId: string): BlockComponent[] =>
    getBlockComponents(blockId, storyReducerBlocksSelector(state)),
  isBlockPresentable: (state: StorytellerState, blockId: string) =>
    isBlockPresentable(blockId, storyReducerBlocksSelector(state)),
  getBlockBackgroundColor: (state: StorytellerState, blockId: string) =>
    getBlockBackgroundColor(blockId, storyReducerBlocksSelector(state)),
  getBlockComponentAtIndex: (state: StorytellerState, blockId: string, index: number): BlockComponent =>
    getBlockComponentAtIndex(blockId, index, storyReducerBlocksSelector(state)),

  serializeStory: (state: StorytellerState, storyUid: string): StorySerialized => {
    const story = storyReducerStoriesSelector(state)[storyUid];
    const dataSources = dataSourceSelectors.getDataSourceForSerialize(state);
    return serializeStory(story, storyReducerBlocksSelector(state), dataSources);
  },

  isBlockRestrictedToMeasureCardsOnly: (state: StorytellerState, blockId: string) =>
    isBlockRestrictedToMeasureCardsOnly(blockId, storyReducerBlocksSelector(state))
};
