import { Vif, CustomColorPaletteWithQuantification } from 'common/visualizations/vif';
import { QUANTIFICATION_METHODS } from 'common/authoring_workflow/constants';

/**
 * Contains helpers to grab the correct color palette for a given column and index
 *
 * Developer notes: I tried to look at the broader picture of how we store and retrieve color
 * palette for all of Viz. That got very overwhelming. Instead, lets start small with this
 * refactor and then we can step by step code ourselves out of this giant knot.
 *
 * Eventually it might make sense to pull this into custom_palette that Charlotte started
 * It will be a larger project and ticket to refactor all of Viz to use that.
 */

type QuantificationMethods = 'category' | 'linear' | undefined;

/**
 * Lets abstract out this logic so that engineers don't need to understand
 * what quantification methods are and why they are important.
 *
 * As an engineer I should just be able to call a function and get the correct custom color palette regardless
 * of the quantification method, viz type, etc.
 */

/**
 * This will give you the correct custom color palette for a given vif, seriesIndex, and columnId
 */
export const getColumnCustomColorPalette = (vif: Vif, seriesIndex: number, columnId: string) => {
  const paletteType = vif.series[seriesIndex].color?.palette;
  const customColorPalettes = vif.series[seriesIndex].color?.customPalette;

  if (paletteType !== 'custom' || !customColorPalettes) return {};

  const currentCustomColorPalette = getCustomColorPaletteByColumnId(vif, customColorPalettes, columnId, seriesIndex);
  return currentCustomColorPalette || {};
};

/**
 * Sometimes you want to get the custom color palette for a given column. Use this function.
 * This assumes that the colorPalettes has keys of columnIds and values of custom color palettes
 */
export const getCustomColorPaletteByColumnId = (vif: Vif, colorPalettes: CustomColorPaletteWithQuantification, columnId: string, seriesIndex = 0) => {
  if (!columnId) return {};
  const currentCustomColorPalette = colorPalettes[columnId];
  const quantificationMethod = getColorByQuantificationMethod(vif, seriesIndex);
  return getCustomColorPaletteByQuantification(currentCustomColorPalette, quantificationMethod);
};

/**
 * Sometimes you want to get the custom color palette for a given quantification method. Use this function.
 * This assumes that the currentPalette is for a specific column
 */
export const getCustomColorPaletteByQuantification = (currentPalette: object, quantificationMethod: QuantificationMethods) => {
  if (currentPalette === undefined) return {};
  return quantificationMethod && currentPalette[quantificationMethod] ? currentPalette[quantificationMethod] : currentPalette;
};

const getColorByQuantificationMethod = (vif: Vif, seriesIndex: number) => {
  return vif.series[seriesIndex].mapOptions?.colorByQuantificationMethod ?? QUANTIFICATION_METHODS.category.value;
};
