import _ from 'lodash';
import $ from 'jquery';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import isolateScrolling from 'common/js_utils/isolateScrolling';
import SocrataIcon from 'common/components/SocrataIcon';
import Picklist from 'common/components/Picklist';
import { ESCAPE, DOWN, SPACE, isolateEventByKeys, isOneOfKeys } from 'common/dom_helpers/keycodes_deprecated';

import { picklistSizingStrategy } from './picklistSizingStrategy';
import { positionPicklist } from './helper';

import './index.scss';
import { DROPDOWN_CATEGORIES } from './constants';

export class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: this.getSelectedOption(props),
      focused: false,
      opened: false,
      mousedDownOnDropdown: false
    };

    this.hasSetScrollEvents = false;

    _.bindAll(this, [
      'onMouseDown',
      'onAnyScroll',
      'onClickPlaceholder',
      'onFocusPlaceholder',
      'onBlurPlaceholder',
      'onKeyDownPlaceholder',
      'onKeyUpPlaceholder',
      'onFocusPicklist',
      'onBlurPicklist',
      'onSelectOption',
      'getSelectedOption',
      'openPicklist',
      'toggleIsolateScrolling',
      'toggleScrollEvents',
      'renderPlaceholder'
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedOption: this.getSelectedOption(nextProps)
    });
  }

  componentDidMount() {
    if (this.props.autoFocus && this.placeholderRef) {
      this.placeholderRef.focus();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only render when something visible happens
    return (
      this.state.focused !== nextState.focused ||
      this.state.opened !== nextState.opened ||
      !_.isEqual(this.props, nextProps) ||
      // This allows changing selected value from parent component
      this.state.selectedOption !== nextState.selectedOption
    );
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // Only attach event handlers when the picklist is visible
    // And detach them when it is not
    if (this.state.focused !== nextState.focused || this.state.opened !== nextState.opened) {
      this.toggleScrollEvents(nextState.opened);
      this.toggleIsolateScrolling(nextState.opened);
      this.toggleDocumentMouseDown(nextState.opened);
      // Do not position the picklist if it is not visible (getBoundingClientRect triggers a reflow)
      if (!this.props.skipPositionPicklist) {
        positionPicklist(this.optionsRef, this.dropdownRef, this.props);
      }
    }
  }

  componentDidUpdate() {
    if (this.state.opened && !this.props.skipPositionPicklist) {
      positionPicklist(this.optionsRef, this.dropdownRef, this.props);
    }
  }

  componentWillUnmount() {
    this.toggleScrollEvents(false);
    this.toggleIsolateScrolling(false);
    this.toggleDocumentMouseDown(false);
  }

  /*
   * document-level event listeners
   */

  /**
   * Looks up the DOM tree from the target and determine if the
   * options container is an ancestor. If not, close up the options
   * container because the user is scrolling outside of component.
   */
  onAnyScroll(event) {
    if (event && this.state.opened) {
      const scrollInDropdownComponent = this.dropdownRef.contains(event.target);
      const scrollInOptionsList = this.dropdownRef.querySelector('.dropdown-options-list').contains(event.target);

      // Closing the dropdown when the user scrolls can be tricky for users with
      // a touch mouse (ie Magic Mouse). It is common to accidentally scroll 1px
      // when clicking or even resting your finger on your mouse before clicking,
      // so closing dropdown on wheel/scroll events is disabled if event occurs in
      // Dropdown button.
      if (scrollInDropdownComponent && !scrollInOptionsList) {
        event.preventDefault();
        event.stopPropagation();
      } else if (!scrollInDropdownComponent && !scrollInOptionsList) {
        this.setState({ opened: false });
      }
    }
  }

  /**
   * Safari and IE blur when the scrollbar is clicked to initiate a scrolling action.
   * This handler  will detect mouseDown on any part of the dropdown or picklist (most importantly
   * on the scrollbar itself) and set mousedDownOnDropdown to true.
   * mousedDownOnDropdown is consumed by onBlurPicklist() which will ignore the blur event from the picklist
   * if it's set to true
   * */
  onMouseDown(event) {
    if (event && this.state.opened && this.dropdownRef) {
      const dropdownOptionsList = _.find(this.dropdownRef.children, (elem) => {
        return elem.classList.contains('dropdown-options-list');
      });
      const dropdownRect = dropdownOptionsList.getBoundingClientRect();
      const [mouseX, mouseY] = [event.clientX, event.clientY];

      const xWithinDropdown = mouseX >= dropdownRect.left && mouseX <= dropdownRect.right;
      const yWithinDropdown = mouseY >= dropdownRect.top && mouseY <= dropdownRect.bottom;

      const clickedInDropdown = this.dropdownRef.contains(event.target);
      const mousedDownOnDropdown = (xWithinDropdown && yWithinDropdown) || clickedInDropdown;

      // Close the dropdown if outside click detected
      if (!mousedDownOnDropdown) {
        this.setState({ opened: false, focused: true });
      }

      this.setState({ mousedDownOnDropdown });
    }
  }

  /*
   * component-level event listeners
   */

  onFocusPlaceholder() {
    this.setState({ focused: true });
  }

  // if the dropdown content is longer than the container, this will scroll the selected item into view
  scrollToSelectedItem() {
    if (this.props.disableAutoScroll) return;
    setTimeout(() => {
      if (this.picklistRef && this.picklistRef.picklist) {
        const selectedElement = this.picklistRef.picklist.querySelector('.picklist-option-selected');
        if (selectedElement && typeof selectedElement.scrollIntoView === 'function') {
          // API: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
          selectedElement.scrollIntoView({ block: 'center' });
        }
      }
    }, 0);
  }

  handleOnClickPlaceholderDefaultCategory() {
    this.setState({ opened: !this.state.opened }, () => {
      if (this.state.opened && this.picklistRef) {
        this.picklistRef.picklist.focus();
        this.scrollToSelectedItem();
      }
    });
  }

  handleOnClickPlaceholderAggregationCategory() {
    this.setState({ opened: !this.state.opened }, () => {
      if (this.state.opened && this.picklistRef) {
        this.picklistRef.picklist.focus();
      }
    });
  }

  onClickPlaceholder() {
    switch (this.props.dropdownCategory) {
      case DROPDOWN_CATEGORIES.AGGREGATION:
        this.handleOnClickPlaceholderAggregationCategory();
        break;
      default:
        this.handleOnClickPlaceholderDefaultCategory();
    }
  }

  /**
   * The state variable mousedDownOnDropdown determines
   * whether or not the blur we received should be
   * responded to. Mousedown is set to off regardless of
   * how we respond to ready for the next blur.
   */
  onBlurPlaceholder() {
    if (!this.placeholderRef) { return; }

    this.setState({ mousedDownOnDropdown: false }, () => {
      if (!this.state.mousedDownOnDropdown) {
        this.optionsRef.scrollTop = 0;
        this.setState({ focused: false });
      } else if (!this.state.opened && this.placeholderRef) {
        this.placeholderRef.focus();
      }
    });
  }

  onKeyDownPlaceholder(event) {
    isolateEventByKeys(event, [DOWN, SPACE]);
  }

  onKeyUpPlaceholder(event) {
    isolateEventByKeys(event, [DOWN, SPACE]);

    if (isOneOfKeys(event, [ESCAPE])) {
      this.onBlurPlaceholder();
    } else if (isOneOfKeys(event, [DOWN, SPACE])) {
      this.openPicklist();
    }
  }

  onFocusPicklist() {
    this.setState({ focused: false });
  }

  onBlurPicklist() {
    if (!this.state.mousedDownOnDropdown) {
      this.setState({ focused: true, opened: false }, () => {
        if (this.placeholderRef && !this.props.noFocusOnSelection) {
          this.placeholderRef.focus();
        }
      });
    }
  }

  onSelectOption(selectedOption) {
    // If the passed in onSelection handler explicitly returns false,
    // do not complete option selection.
    const shouldStopSelection = this.props.onSelection(selectedOption) === false;

    this.setState({
      focused: true,
      opened: false,
      ...(!shouldStopSelection && { selectedOption })
    }, () => {
      if (this.placeholderRef && !this.props.noFocusOnSelection) {
        this.placeholderRef.focus();
      }
    });
  }

  getSelectedOption(props) {
    const { value, options } = props;
    return _.find(options, { value }) || null;
  }

  openPicklist() {
    const { options } = this.props;
    const selectedOption = this.state.selectedOption || _.first(options);

    this.setState({
      opened: true,
      selectedOption
    }, () => {
      if (this.picklistRef) {
        this.picklistRef.picklist.focus();
        this.scrollToSelectedItem();
      }
    });
  }

  /**
   * When scrolling the options, we don't want the outer containers
   * to accidentally scroll once the start or end of the options is
   * reached.
   */
  toggleIsolateScrolling(onOrOff) {
    if (!this.optionsRef) { return; }

    isolateScrolling($(this.optionsRef), onOrOff);
  }

  toggleDocumentMouseDown(onOrOff) {
    document[onOrOff ? 'addEventListener' : 'removeEventListener']('mousedown', this.onMouseDown);
  }

  /**
   * Places scrolling event listeners on all ancestors that are scrollable.
   *
   * This is done to properly hide the dropdown when the user
   * scrolls an inner container.
   *
   * This functions as a toggle that will add or remove the event listeners
   * depending on a boolean value passed as the first parameter.
   */
  toggleScrollEvents(onOrOff) {
    if (!this.dropdownRef) return;
    if (!this.hasSetScrollEvents) return;

    this.hasSetScrollEvents = onOrOff;


    const action = onOrOff ? 'addEventListener' : 'removeEventListener';
    const toggleEvents = (element) => {
      element[action]('scroll', this.onAnyScroll);
      element[action]('wheel', this.onAnyScroll);
    };

    const setEventsOnEveryParent = (node) => {
      let parent = node.parentNode;

      while (parent !== null && parent instanceof HTMLElement) {
        const overflowY = window.getComputedStyle(parent).overflowY;
        const isScrollable = overflowY === 'scroll' || overflowY === 'auto';
        const hasScrollableArea = parent.scrollHeight > parent.clientHeight;

        if (isScrollable && hasScrollableArea) {
          toggleEvents(parent);
        }

        parent = parent.parentNode;
      }
    };

    // Rummage through all the ancestors
    // and apply/remove the event handlers.
    setEventsOnEveryParent(this.dropdownRef);

    // Apply/remove scrolling events on the window as well.
    toggleEvents(window);
  }

  renderPlaceholder() {
    let { disabled, placeholder, tabIndex, label, labelledBy } = this.props;
    let icon = null;
    const { opened, selectedOption } = this.state;
    const caret = <SocrataIcon name="arrow-down" className="dropdown-caret" key="dropdown-caret" />;
    const placeholderText = text => <span className="placeholder" key="placeholder">{text}</span>;
    const placeholderIsFunction = typeof placeholder === 'function';
    const placeholderIsString = typeof placeholder === 'string';
    const placeholderIsElementOrArrayOfElements = _.every(
      placeholder, (item) => typeof item === 'string' ||
        (typeof item === 'object' && item.$$typeof === Symbol('react.element')));

    // check if component is a html button or our custom Button component
    const placeholderIsButton = (placeHolder) => {
      return (placeholder?.type === 'button' || placeHolder?.type?.displayName === 'Button');
    };

    if (placeholderIsFunction) {
      placeholder = placeholder({ isOpened: opened });
    } else if (selectedOption) {
      placeholder = [placeholderText(selectedOption.title), caret];
      // TODO: Make this an explicit test for a SocrataIcon,
      // or make it wrap a string into a SocrataIcon.
      if (typeof selectedOption.icon === 'object') {
        icon = selectedOption.icon;
      }
    } else if (placeholderIsString) {
      placeholder = [placeholderText(placeholder), caret];
    } else if (placeholder === null) {
      placeholder = [placeholderText(I18n.t('shared.components.dropdown.select')), caret];
    } else if (placeholderIsElementOrArrayOfElements) {
      placeholder = Array(placeholder).map(item => typeof item === 'string' ? placeholderText(item) : item);
    }

    const attributes = {
      onFocus: this.onFocusPlaceholder,
      onBlur: this.onBlurPlaceholder,
      onClick: this.onClickPlaceholder,
      onKeyUp: this.onKeyUpPlaceholder,
      onKeyDown: this.onKeyDownPlaceholder,
      ref: (ref) => (this.placeholderRef = ref),
      className: classNames({
        'default': _.get(selectedOption, 'defaultOption'),
        'dropdown-placeholder': !placeholderIsFunction,
        'dropdown-computed-placeholder': placeholderIsFunction,
        'dropdown-selected': !!selectedOption,
        'dropdown-selected-with-icon': !!selectedOption && !!icon
      }),
      'aria-labelledby': labelledBy,
      'aria-label': label,
      // Indicates that this will reveal a list of options
      'aria-haspopup': 'listbox'
    };

    if (_.isInteger(tabIndex)) {
      attributes.tabIndex = disabled ? '-1' : `${tabIndex}`;
    }

    // We only want button role if the placeholder is not a button (accessibility reasons - no nested interactive controls)
    if (!placeholderIsButton(placeholder)) {
      attributes.role = 'button';
    }

    return <div {...attributes}>{icon}{placeholder}</div>;
  }

  render() {
    const { className, disabled, id, name, options, size, buildPicklist } = this.props;
    const { focused, opened, selectedOption } = this.state;
    const value = _.get(selectedOption, 'value', null);

    const dropdownAttributes = {
      id: id,
      ref: ref => this.dropdownRef = ref,
      className: classNames(className, 'dropdown-container', `dropdown-size-${size}`, {
        'dropdown-focused': focused,
        'dropdown-opened': opened,
        'dropdown-disabled': disabled
      }),
      'data-testid': this.props['data-testid']
    };

    const dropdownOptionsAttributes = {
      ref: ref => this.optionsRef = ref,
      className: classNames('dropdown-options-list', {
        'dropdown-invisible': !opened
      })
    };

    const picklistAttributes = {
      disabled,
      id: id + '-dropdown',
      name,
      onBlur: this.onBlurPicklist,
      onFocus: this.onFocusPicklist,
      onSelection: this.onSelectOption,
      options,
      ref: ref => this.picklistRef = ref,
      size,
      value
    };

    const picklist  = buildPicklist ? buildPicklist(picklistAttributes) : <Picklist {...picklistAttributes} />;

    const placeholder = this.renderPlaceholder();

    return (
      <div {...dropdownAttributes}>
        {placeholder}
        <div {...dropdownOptionsAttributes}>
          {picklist}
        </div>
      </div>
    );
  }
}

const dropdownOptionShape = PropTypes.shape({
  title: PropTypes.any,
  value: PropTypes.any,
  group: PropTypes.any,
  disabled: PropTypes.bool
});

Dropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  // Whether to focus the placeholder on mount
  autoFocus: PropTypes.bool,
  // Controls the width of the picklist.
  picklistSizingStrategy: PropTypes.oneOf(Object.keys(picklistSizingStrategy)),
  id: PropTypes.string,
  // This function can return a boolean shouldStopSelection,
  // which will prevent an option from being selected.
  onSelection: PropTypes.func,
  // This prop stops the selected option from being automatically scrolled into
  // the center of the container view when the picklist is opened
  disableAutoScroll: PropTypes.bool,
  options: PropTypes.arrayOf(dropdownOptionShape),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.array
  ]),
  showOptionsBelowHandle: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  // Whether to skip the default placement logic. If this is true,
  // you should implement your own picklist placement logic.
  skipPositionPicklist: PropTypes.bool,
  value: PropTypes.any,
  // Since this dropdown is not a form input element (gotta love custom
  // components that reimplement browser functionality), we can't rely
  // on built-in accessibility affordances like <label for="some-id">.
  // Fortunately, the Aria spec allows for this using the aria-labelledby
  // attribute, which you can set using this prop.
  labelledBy: PropTypes.string,
  // Alternatively, you can provide the label text to this prop. Note: This
  // will not be visible and is intended only for screenreaders.
  label: PropTypes.string,
  // fun which takes picklist props and returns the picklist
  buildPicklist: PropTypes.func,
  // value of the tabindex HTML attribute for the DropDown. Set to `null` for no tabindex at all.
  tabIndex: PropTypes.number,
  noFocusOnSelection: PropTypes.bool,
  // Used to determine certain behaviors based on what is using this dropdown. Currently
  // being used to determine the behavior of `onClickPlaceholder` method.
  dropdownCategory: PropTypes.oneOf(Object.values(DROPDOWN_CATEGORIES))
};

Dropdown.defaultProps = {
  disabled: false,
  onSelection: _.noop,
  options: [],
  placeholder: null,
  showOptionsBelowHandle: false,
  size: 'large',
  skipPositionPicklist: false,
  picklistSizingStrategy: picklistSizingStrategy.SAME_AS_DROPDOWN,
  buildPicklist: null,
  tabIndex: 0,
  dropdownCategory: DROPDOWN_CATEGORIES.DEFAULT
};

export default Dropdown;
