import type { GuidanceSummaryV2 } from 'common/types/approvals';

import { View, ViewRight } from 'common/types/view';
import { hasRights } from 'common/views/has_rights';
import { AssetStatus, assetStatusFor } from 'common/views/asset_status';

/**
 * Determines whether the current user can transfer ownership of the given view.
 *
 * This hinges off of having the `transfer_ownership` right on the view, which is given by core if:
 * - The current user has the `chown_datasets` domain right
 * OR
 * - The `strict_permissions` feature flag is on AND
 * - The current user is a site member AND
 * - The current user is the "true owner" of the view
 *
 * Which is to say, if strict_permissions if off, only users with the `chown_datasets` domain right can transfer ownership.
 *
 * This logic is all encapsulated in core so that we only have to worry about checking the view rights here :)
 *
 * @param view View to check for deletion privileges of
 * @param approvalsGuidance Approvals guidance summary
 */
const currentUserCanTransferOwnership = (view: View, approvalsGuidance: GuidanceSummaryV2) =>
  !view.locked &&
  assetStatusFor(view, approvalsGuidance) === AssetStatus.Published &&
  hasRights(view, ViewRight.TransferOwnership);

export default currentUserCanTransferOwnership;
