import PropTypes from 'prop-types';
import values from 'lodash/fp/values';

import {
  PeriodSizes,
  PeriodTypes
} from 'common/performance_measures/lib/constants';

export default PropTypes.shape({
  endsBeforeDate: PropTypes.string, // As YYYY-MM-DD, first date not included in measure's calculation
  startDateConfig: PropTypes.shape({
    type: PropTypes.string.isRequired, // 'floating' or 'fixed'
    duration: PropTypes.number, // 1-30 if type is floating
    date: PropTypes.string // YYYY-MM-DD
  }),
  type: PropTypes.oneOf(values(PeriodTypes)),
  size: PropTypes.oneOf(values(PeriodSizes)),
  firstQuarterStartMonth: PropTypes.number // represents the month, see first_quarter_start_month domain config
});
