import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import getCsrfToken from 'common/js_utils/getCsrfToken';
import MostRecentlyUsed from 'common/most_recently_used';
import StatefulAutocomplete from 'common/autocomplete/components/StatefulAutocomplete';
import { getBrowseOrSIAMUrl } from 'common/autocomplete/Util';
import SiteChromeBrandedHeader from
  'common/site_chrome/app/assets/javascripts/socrata_site_chrome/site_chrome';
import SiteChromeAdminHeader from
  'common/site_chrome/app/assets/javascripts/socrata_site_chrome/admin_header';
import 'common/notifications/main';
import { Toastmaster } from 'common/components/ToastNotification/Toastmaster';
import SessionTimeoutModal from 'common/components/SessionTimeoutModal';
import { getCurrentUser } from 'common/current_user';
import { triggerExternalLinkDisclaimer } from 'common/external_link_disclaimer';
import ConfigExpectationBanner from 'common/components/ConfigExpectationBanner';
import IEDeprecationBanner from 'common/components/IEDeprecationBanner';

// We are using a centralized location to register Forge Icons for the following reason:
// Note: [Importing the desired icon(s) and defining them within the Forge icon registry] should be done as
// early in your application bootstrapping process as possible. Make sure all icons are defined, prior to
// the page rendering or else the component will not be able to find the icon data when it is instantiated.
import 'common/js_utils/forgeIconRegistry';

/**
 * This will import all Tyler Forge web components. If we want selectively import components
 * to reduce our bundle size, see https://forge.tylertech.com/resources/getting-started-as-a-developer/basic
 * for more details.
 */
import { defineComponents } from '@tylertech/forge';

// This function should be called as early as possible when your application is bootstrapping
defineComponents();


function siteWideInit() {
  // Every rails layout should have a "csrf-token" meta tag
  // This grabs the meta tag and sets it in a cookie
  // This cookie is sent along with requests to core, which validates it against either the
  // `X-CSRF-Token` or `form_authenticity_token` header that is sent with the request
  const csrfCookieName = 'socrata-csrf-token';
  const csrfToken = getCsrfToken();

  if (!_.isEmpty(csrfToken)) {
    document.cookie = `${csrfCookieName}=${csrfToken};secure;path=/`;
  }

  // Attempt to find the current user id in the various places it might be found
  const currentUser = getCurrentUser();

  // This adds a "lastAccessed" object on window that is used for keeping track when users access a
  // dataset by adding a 4x4 and timestamp.
  const userId = _.get(currentUser, 'id', 'unknown');
  window.lastAccessed = new MostRecentlyUsed({ namespace: `socrata:assets:mru:${userId}` });

  // Both of these function calls below are to bind event listeners to the SiteChrome header dropdown menus
  // for both admin and branded headers. Though only one of the two headers will ever appear at once time,
  // the function call will be a NOOP when the header in question is not present.
  SiteChromeAdminHeader();
  SiteChromeBrandedHeader();

  // This adds a "autocomplete" function that can be called by an external application
  // (i.e. socrata_site_chrome) to scan the DOM and transform any search fields with the attribute
  // data-autocomplete="true" into autocomplete search fields after the page has loaded.
  Array.from(document.querySelectorAll('[data-autocomplete="true"]')).forEach((container) => {
    const {
      autocompleteAnonymous,
      autocompleteCollapsible,
      autocompleteDisableAnimation,
      autocompleteMobile
    } = container.dataset;

    const collapsible = autocompleteCollapsible === 'true';
    const animate = autocompleteDisableAnimation !== 'true';
    const mobile = autocompleteMobile === 'true';

    // EN-23730 SCGC feature: If autocomplete-anonymous is `true`, redirect header search queries to /browse
    const anonymous = autocompleteAnonymous !== 'false';

    const options = {
      animate,
      anonymous,
      collapsible,
      mobile,
      onChooseResult: (searchTerm) => {
        window.location.href = getBrowseOrSIAMUrl(searchTerm, anonymous);
      }
    };

    ReactDOM.render(<StatefulAutocomplete options={options} />, container);
  });

  // Place a reference to the autocomplete function on window, so that external consumers can use it.
  window.autocomplete = function(containerSelector, options, defaultState) {
    _.noConflict();
    const rootNode = document.querySelector(containerSelector);

    if (!rootNode) {
      console.error(`Cannot render Autocomplete; no node matched the selector: ${containerSelector}`);
      return;
    }

    ReactDOM.render(<StatefulAutocomplete defaultState={defaultState} options={options} />, rootNode);
  };

  const toastmasterNode = document.querySelector('#toastmaster');
  if (toastmasterNode) {
    ReactDOM.render(
      <Toastmaster />,
      toastmasterNode
    );
  }

  const configExpectationBannerNode = document.querySelector('#config-expectation-banner');
  if (configExpectationBannerNode) {
    ReactDOM.render(
      <ConfigExpectationBanner />,
      configExpectationBannerNode
    );
  }

  // EN-44386: remove below after IE 11 is not supported
  const ieDeprecationBannerNode = document.querySelector('#internet-explorer-deprecation-banner');
  if (ieDeprecationBannerNode) {
    ReactDOM.render(
      <IEDeprecationBanner />,
      ieDeprecationBannerNode
    );
  }

  // If domain has external_link_disclaimer configuration, ask confirmation before redirecting to external site
  if (window.socrata.externalLinkDisclaimer) {
    triggerExternalLinkDisclaimer();
  }

  const sessionTimeoutModalContainer = document.createElement('div');
  document.body.appendChild(sessionTimeoutModalContainer);
  ReactDOM.render(
    <SessionTimeoutModal />,
    sessionTimeoutModalContainer
  );
}

// we can't use jQuery ready handler here because it might not be defined
// if site wide is executed in the HTML head ... *cough* storyteller *cough*
// so we use the native document ready state checker
if (document.body || document.readyState === 'complete') {
  siteWideInit();
} else {
  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      siteWideInit();
    }
  };
}
