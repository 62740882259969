// Return: Decimal between 0 to 1.
//  - 1 means value is highest in the domain. (if checking with yScale, it is topmost in the chart)
//  - 0.5 means value is in the middle of the scale.
//  - 0 means value is lowest in the domain.
export const getValueLocationInScaleAsRatio = (scale, value) => {
  const yDomainMin = scale.domain()[0];
  const yDomainMax = scale.domain()[1];

  return (value - yDomainMin) / (yDomainMax - yDomainMin);
};

export const getAxisHeight = (showDimensionLabels, viewportHeight, marginTop) => {
  // Compute height based on the presence or absence of x-axis data labels.
  if (showDimensionLabels) {
    return viewportHeight;
  } else {
    // In this case we want to mirror the top margin on the bottom so
    // that the chart is visually centered (scatter charts have no bottom
    // margin by default).
    return Math.max(0, viewportHeight - marginTop);
  }
};
