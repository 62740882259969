import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import Slider from 'common/components/Slider';
import SocrataIcon from 'common/components/SocrataIcon';

import './index.scss';

const MIN_VALUE = 1;
const MAX_VALUE = 10;
const STEP = 1;

/**
  Shows common/components/slider along with step up/down arrows.

  @prop onChange   - gets called when the selected value changes
  @prop value      - current value
*/
class RadiusSlider extends Component {
  onRadiusChange = (value) => {
    const { onChange } = this.props;
    onChange(_.clamp(value, MIN_VALUE, MAX_VALUE));
  };

  onStepUp = () => {
    this.onRadiusChange(this.props.value + STEP);
  };

  onStepDown = () => {
    this.onRadiusChange(this.props.value - STEP);
  };

  translationScope = 'shared.components.create_alert_modal.custom_alert';

  renderStepUpDownArrows() {
    return (
      <div className="section icon-section">
        <span className="icon-step-up" onClick={this.onStepUp}>
          <SocrataIcon name="arrow-up" />
        </span>
        <span className="icon-step-down" onClick={this.onStepDown}>
          <SocrataIcon name="arrow-down" />
        </span>
      </div>
    );
  }

  render() {
    const { value } = this.props;
    const sliderProps = {
      onChange: this.onRadiusChange,
      rangeMax: MAX_VALUE,
      rangeMin: MIN_VALUE,
      step: STEP,
      value: value
    };

    return (
      // Adding classname so that we can override styling for radius sliders added in sitechrome header,
      // which requires two variations of radius slider
      <div className="radius-slider">
        <div className="section radius-section">
          <Slider {...sliderProps} />
        </div>
        <div className="section">
          {I18n.t('radius_text', { scope: this.translationScope })}:{value}
        </div>
        {this.renderStepUpDownArrows()}
      </div>
    );
  }
}

RadiusSlider.defaultProps = {
  value: 1
};

RadiusSlider.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default RadiusSlider;
