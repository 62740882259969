import React from 'react';
import I18n from 'common/i18n';
import { ForgeMenu, ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { Operator } from './Types';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.functions' });

interface BooleanDividerProps {
  selectedOperator: Operator;
  onUpdate: (operator: Operator) => void;
}

const BooleanDivider = ({ selectedOperator, onUpdate }: BooleanDividerProps) => {
  const dropdownOptions = [
    { label: t('op$and'), value: Operator.AND },
    { label: t('op$or'), value: Operator.OR }
  ];

  return (<div className="boolean-divider" data-testid="visual-node-boolean-divider">
    <ForgeMenu
      options={dropdownOptions}
      placement='bottom'
      dense={true}
      on-forge-menu-select={(v: CustomEvent) => onUpdate(v.detail.value)}
    >
      <ForgeButton>
        <button type='button'>
          {t(selectedOperator.toLowerCase())}
          <ForgeIcon name="keyboard_arrow_down" />
        </button>
      </ForgeButton>
    </ForgeMenu>
  </div>);
};

export default BooleanDivider;
