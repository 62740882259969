// Functions for determining what type of asset a given view is
import _ from 'lodash';
import { View, ViewFlag, DisplayType, ViewType, PublicationStage } from 'common/types/view';
import hasFlag from 'common/views/has_flag';
import { ViewWithVQEColumns } from 'common/explore_grid/redux/store';

const DATASET_DISPLAY_TYPES: DisplayType[] = [DisplayType.AssetInventory, DisplayType.Draft];
const DATASET_TABULAR_DISPLAY_TYPES: (DisplayType | string)[] = [DisplayType.Table, DisplayType.Page, DisplayType.FatRow, ''];

export function isBlob(coreView?: View): boolean {
  return coreView?.viewType === ViewType.Blobby;
}

export function isClassicVisualization(coreView?: View): boolean {
  return coreView?.displayType === DisplayType.Chart ||
    coreView?.displayType === DisplayType.Map;
}

export function isDataset(coreView?: View): boolean {
  if (!coreView) {
    return false;
  }

  const { displayType } = coreView;
  const isDefault = isDefaultView(coreView);

  // DANGER: this returns true even if the view is not default for 'draft' and 'assetinventory' :(
  // change pending impact discussion with original author
  if (DATASET_DISPLAY_TYPES.includes(displayType)) {
    return true;
  }

  return isDefault && isTabular(coreView);
}

export function isHref(coreView?: View): boolean {
  // There are some old stories where the viewType is 'href'. See EN-28667 and EN-28703
  return coreView?.viewType === ViewType.Href &&
    coreView?.displayType !== DisplayType.Story;
}

export function isTabular(coreView?: View): boolean {
  if (!coreView) {
    return false;
  }

  const { displayType, viewType } = coreView;
  return viewType === ViewType.Tabular && DATASET_TABULAR_DISPLAY_TYPES.includes(displayType);
}

export function isDerivedView(coreView?: View): boolean {
  if (!coreView) {
    return false;
  }

  return !isDefaultView(coreView) && (isTabular(coreView) || coreView.displayType == DisplayType.Draft);
}

export function isDefaultView(coreView?: Partial<View>): boolean {
  return hasFlag(coreView, ViewFlag.Default);
}

// this flag may coincide with the 'default' flag in some circumstances:
// when the viewing user does not have access to the parent dataset
// AND ( hide_derived_view_definition_from_users_without_parent_view_read_access is true
// OR the view is federated from a different site)
export function isSoQLBased(coreView?: Partial<View>): boolean {
  return hasFlag(coreView, ViewFlag.SoqlBasedView);
}

export function isBrokenQueryJSONBased(coreView?: View | ViewWithVQEColumns): boolean {
  // broken jsonQuery views have their query attribute set to {} and
  // message attribute set to some error message
  return (_.isEmpty(coreView?.query) &&
    !_.isEmpty(coreView?.message) &&
    !isSoQLBased(coreView as Partial<View>));
}

export function isQueryJSONBased(coreView?: View | ViewWithVQEColumns): boolean {
  return !_.isEmpty(coreView?.query) ||
    !_.isEmpty(coreView?.searchString) ||
    isBrokenQueryJSONBased(coreView);
}

export function isPublished(coreView?: View): boolean {
  return coreView?.publicationStage === PublicationStage.Published;
}

export function isFederated(coreView?: View): boolean {
  return hasFlag(coreView, ViewFlag.Federated);
}

export function isInternalToPublicFederated(coreView?: View): boolean {
  return hasFlag(coreView, ViewFlag.InternalToPublicFederatedView);
}

export function isInternalToPublicFederatedSoQLBasedView(coreView?: View): boolean {
  return isSoQLBased(coreView) && isInternalToPublicFederated(coreView);
}

export function isForm(coreView?: Partial<View>): boolean {
  if (!coreView) {
    return false;
  }

  const { displayType, viewType } = coreView;
  return displayType === DisplayType.Form && viewType === ViewType.Tabular;
}

export function isMeasure(coreView?: Partial<View>): boolean {
  return coreView?.displayType === DisplayType.Measure;
}

export function isStory(coreView?: Partial<View>): boolean {
  // There are some old stories where the viewType is 'href'. See EN-28667 and EN-28703
  return coreView?.viewType === ViewType.Story ||
    coreView?.displayType === DisplayType.Story;
}

export function isStoryDraft(coreView?: Partial<View>): boolean {
  return isStory(coreView) &&
    // this will only exist in storyteller when you're looking at a story
    _.get(window, 'IS_STORY_PUBLISHED') === false ||

    // story drafts have special UIDs BUT only from the catalog!
    // story drafts do not currently have unique UIDs (lenses) in core
    !!(coreView?.id?.match(/^\w{4}-\w{4}:draft$/));
}

export function isInitialDatasetDraft(coreView?: View): boolean {
  if (!coreView) {
    return false;
  }
  return coreView.displayType === DisplayType.Draft;
}

export function isVisualizationCanvas(coreView?: Partial<View>): boolean {
  return coreView?.displayType === DisplayType.Visualization || // Legacy support for now.
    coreView?.displayType === DisplayType.VisualizationCanvasTable ||
    coreView?.displayType === DisplayType.VisualizationCanvasCalendar ||
    coreView?.displayType === DisplayType.VisualizationCanvasChart ||
    coreView?.displayType === DisplayType.VisualizationCanvasMap;
}
