import { action } from 'typesafe-actions';

import type { ViewPermissions, ViewUser, AccessLevel } from 'common/types/view';
import { AudienceScope } from 'common/types/view';
import type { GuidanceSummaryV2 } from 'common/types/approvals';
import type { CatalogUserOrTeam } from 'common/types/users/catalogUsers';

import { MODES } from '../Constants';

export enum PermissionsActionsTypes {
  FETCH_PERMISSIONS = 'FETCH_PERMISSIONS',
  FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL',
  CHANGE_AUDIENCE_SCOPE = 'CHANGE_AUDIENCE_SCOPE',
  CHANGE_USER_ACCESS_LEVEL = 'CHANGE_USER_ACCESS_LEVEL',
  REMOVE_USER_ACCESS = 'REMOVE_USER_ACCESS',
  ADD_USERS = 'ADD_USERS',
  SAVE_FAIL = 'SAVE_FAIL',
  GET_SCHEDULE_COUNT = 'GET_SCHEDULE_COUNT',
  GET_SCHEDULE_COUNT_SUCCESS = 'GET_SCHEDULE_COUNT_SUCCESS',
  GET_SCHEDULE_COUNT_FAIL = 'GET_SCHEDULE_COUNT_FAIL',
  CLEAR_SCHEDULE_COUNT = 'CLEAR_SCHEDULE_COUNT'
}

/** Call out to core to get all the permissions for the assigned asset */
export const fetchPermissions = (assetUid: string) =>
  action(PermissionsActionsTypes.FETCH_PERMISSIONS, { assetUid });

/** Permissions were fetched successfully from core */
export const fetchPermissionsSuccess = ({
  permissions,
  approvalsGuidance,
  visualizationCanvasHasPublicDataSource,
  mode
}: {
  permissions: ViewPermissions;
  approvalsGuidance: GuidanceSummaryV2;
  visualizationCanvasHasPublicDataSource?: boolean;
  mode: MODES;
}) =>
  action(PermissionsActionsTypes.FETCH_PERMISSIONS_SUCCESS, {
    permissions,
    approvalsGuidance,
    visualizationCanvasHasPublicDataSource,
    mode
  });

/** There was an error fetching the permissions */
export const fetchPermissionsFail = (error: any) =>
  action(PermissionsActionsTypes.FETCH_PERMISSIONS_FAIL, { error });

/**
 * Request to change the audience scope of the dataset in the store.
 * Note that this does not persist to the database until the save button is clicked.
 */
export const changeAudienceScope = (scope: AudienceScope) =>
  action(PermissionsActionsTypes.CHANGE_AUDIENCE_SCOPE, { scope });

/** Change the access level for a user */
export const changeUserAccessLevel = (user: ViewUser, accessLevel: AccessLevel) =>
  action(PermissionsActionsTypes.CHANGE_USER_ACCESS_LEVEL, {
    user,
    accessLevel
  });

/** Remove a user from the list */
export const removeUserAccess = (user: ViewUser) =>
  action(PermissionsActionsTypes.REMOVE_USER_ACCESS, { user });

/** Add user(s) to the list with access levels */
export const addUsers = (users: CatalogUserOrTeam[], accessLevel: AccessLevel, mode: MODES) =>
  action(PermissionsActionsTypes.ADD_USERS, { users, accessLevel, mode });

/** Save failed */
export const saveFail = (error: any) => action(PermissionsActionsTypes.SAVE_FAIL, { error });

/** Get count of user schedules */
export const getScheduleCount = (
  namespace: { name: string; type: string },
  user: ViewUser,
  removeUserIfNoSchedules = false
) =>
  action(PermissionsActionsTypes.GET_SCHEDULE_COUNT, {
    namespace,
    user,
    removeUserIfNoSchedules
  });

/** Save user schedule count after success */
export const getScheduleCountSuccess = (scheduleCounts: { total: number }, user: ViewUser) =>
  action(PermissionsActionsTypes.GET_SCHEDULE_COUNT_SUCCESS, {
    scheduleCounts,
    user
  });

/** Getting schedule count failed */
export const getScheduleCountFail = (error: any) =>
  action(PermissionsActionsTypes.GET_SCHEDULE_COUNT_FAIL, {
    error
  });

/** Clear user schedules from store */
export const clearScheduleCount = (user: ViewUser) =>
  action(PermissionsActionsTypes.CLEAR_SCHEDULE_COUNT, {
    user
  });
