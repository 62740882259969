/**
 * This function converts a string to a url safe format
 * copied from urlSafe() in util/util.js. There is also
 * a ruby equivalent in string_extensions.rb
 */
export default (text) => {
  const output = text
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9_\-]/g, '-')
    .replace(/\-+/g, '-');
  return output.length < 1 ? '-' : output.slice(0, 50);
};
