import React, { FunctionComponent, useEffect } from 'react';
import {
  ForgeList,
  ForgeListItem } from '@tylertech/forge-react';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { View } from 'common/types/view';
import './watchlist.scss';

export interface WatchlistItemsProps {
  watches: View[];
  title: string;
}

const WatchlistItems: FunctionComponent<WatchlistItemsProps> = (props) => {
  const { watches, title } = props;

  const WatchlistItem = (watch: View) => (
    <ForgeListItem key={watch.id} className='watch-item'>
        <SocrataIcon name={IconName.Story}/>
        <span> <a href={`/stories/s/${watch.id}?modal=WatchAssetManager`}>{watch.name}</a> </span>
     </ForgeListItem>
  );

  return (
    <div>
      <div className='watch-title'> {title} </div>
      <ForgeList>
        {watches.map((watch) => WatchlistItem(watch))}
      </ForgeList>
    </div>

  );
};

export default WatchlistItems;
