// Vendor Imports
import _ from 'lodash';

// Project Imports
import I18n from 'common/i18n';
import * as MonthPredicateHelper from 'common/visualizations/dataProviders/MonthPredicateHelper';

/**
 * The purpose of this function is to apply the order by specified in the
 * original vif to the composite data table object built by
 * 'mapGroupingDataResponsesToMultiSeriesTable'. This is necessary because
 * we have been combining rows from multiple independent query responses, so
 * we have not been able to rely on the ordering in the responses to be
 * consistent in terms of the overall collection of responses.
 */
export function applyOrderBy(dataTable, vif) {
  const dimensionIndex = 0;
  const measureIndex = 1;

  const otherCategoryName = I18n.t(
    'shared.visualizations.charts.common.other_category'
  );

  const orderBy = _.get(vif, 'series[0].dataSource.orderBy', {});
  const orderByParameter = (orderBy.parameter || 'measure').toLowerCase();
  const orderBySort = (orderBy.sort || 'desc').toLowerCase();
  const orderingByDimension = (orderByParameter === 'dimension');

  const sortValueIndex = (orderingByDimension) ?
    dimensionIndex :
    measureIndex;

  const sumRowMeasureValues = (row) => {
    return _.chain(row.slice(dimensionIndex + 1)).
      sumBy((value) => _.isFinite(value) ? value : 0).
      value();
  };

  // Determine whether all values for ordering are numeric.
  // See note on compare function at bottom.
  const doSortNumeric = _(dataTable.rows).
    map((row) => {
      return orderingByDimension ?
        row[sortValueIndex] :
        sumRowMeasureValues(row);
    }).
    compact().
    every((val) => !_.isNaN(_.toNumber(val)));

  // Determine whether all values for ordering are months in the same
  // language, and assume that language is the one in the first row.
  const language = MonthPredicateHelper.detectLanguage(_.get(dataTable, ['rows', 0, sortValueIndex], null));
  const doSortMonths =
    !doSortNumeric &&
    language &&
    _.every(
      dataTable.rows,
      (row) =>
        !_.isUndefined(MonthPredicateHelper.monthIndex(row[sortValueIndex], language)) ||
        (row[sortValueIndex] === otherCategoryName)
    );

  let transformer = _.identity;
  if (doSortNumeric) {
    transformer = _.toNumber;
  } else if (doSortMonths) {
    transformer = (x) => _.toNumber(MonthPredicateHelper.monthIndex(x, language));
  }

  const compareValues = makeValueComparator(orderBySort, transformer);
  const compareCategoriesToNullAndOther = (a, b) => {
    if (a === otherCategoryName) {
      return 1;
    } else if (b === otherCategoryName) {
      return -1;
    } else if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    } else {
      // Categories that aren't null or "other" need to use a different sort.
      return 0;
    }
  };

  const comparator = (a, b) => {
    // If we are ordering by the dimension, the order should always be:
    //
    // <Non-null dimension values>
    // <Null dimension value> (if present in the dimension values)
    // <Other category>
    //
    // Since the dimension values will all be unique, we do not have to
    // handle some comparisons (e.g. if both a and b are '(Other)' or null).
    //
    // If we are ordering by the measure, the order should always be:
    //
    // <Non-null dimension values and/or other category, depending on measure>
    // <Null measure values>
    const categoryA = a[0];
    const categoryB = b[0];

    if (orderingByDimension) {

      return compareCategoriesToNullAndOther(categoryA, categoryB) ||
        compareValues(categoryA, categoryB);

    } else {

      const sumOfA = sumRowMeasureValues(a);
      const sumOfB = sumRowMeasureValues(b);

      return compareValues(sumOfA, sumOfB) ||
        compareCategoriesToNullAndOther(categoryA, categoryB) ||
        compareValues(categoryA, categoryB);
    }
  };

  dataTable.rows.sort(comparator);

  return dataTable;
}

export function applyOrderByToErrorBars(dataTable) {
  if (_.isUndefined(dataTable.errorBars)) {
    return dataTable;
  }

  const dimensionIndex = 0;
  dataTable.errorBars = dataTable.rows.map((row) =>
    _.find(dataTable.errorBars, (errorBar) => (errorBar[dimensionIndex] == row[dimensionIndex]))
  );

  return dataTable;
}

// Generalized comparison routine for arbitrary values.
//
// Note that, at this point, we may have lost type information - the results of
// the query might cast everything into strings, which is very inconvenient...
// so we need to stipulate explicitly whether our order comparison should use
// lexicographic or numeric ordering.
//
// NOTE: There's a possibility that we can move this logic further down into
// makeSocrataCategoricalDataRequest, but at time of writing that module isn't
// covered by tests, so the change is too risky to attempt.
function makeValueComparator(direction, transformer = _.identity) {
  const _compare = (direction === 'asc') ? _.gt : _.lt;

  return (a, b) => {
    if (a === b) {
      return 0;
    } else {
      return _compare(transformer(a), transformer(b)) ? 1 : -1;
    }
  };
}
