export function replaceAt<T>(arr: T[], thing: T, index: number): T[] {
  return [
    ...arr.slice(0, index),
    thing,
    ...arr.slice(index + 1)
  ];
}

export function splice<T>(arr: T[], thing: T, index: number): T[] {
  return [
    ...arr.slice(0, index),
    thing,
    ...arr.slice(index)
  ];
}
