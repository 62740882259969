import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';

import { ViewFlag } from 'common/types/view';

import type { AccessManagerState } from './types';
import { getUiMode, getPermissions, getSelectedUsers } from './sagas/Selectors';
import { MODES } from './Constants';

const getConfirmButtonDisabledManually = (state: AccessManagerState) => state.ui.footer.confirmButtonDisabled;
const getOriginalPermissions = (state: AccessManagerState) => state.permissions.originalPermissions;

const getIsAccessLevelSelected = (state: AccessManagerState) => state.addCollaborators?.accessLevel;

const getAssetWasOriginallyInternalToPublicFederated = (state: AccessManagerState) =>
  includes(state.permissions?.view?.flags || [], ViewFlag.InternalToPublicFederatedSourceView);

const getNewCollaboratorsSelected = createSelector(
  getSelectedUsers,
  (selectedUsers = []) => selectedUsers.length > 0
);

const getPermissionsChanged = createSelector(
  [getPermissions, getOriginalPermissions],
  (permissions, originalPermissions) => !isEqual(permissions, originalPermissions)
);

// True if the user has some changes that apply to MANAGE_COLLABORATORS mode
const getManageCollaboratorsHasChanges = createSelector(
  [getPermissionsChanged, getNewCollaboratorsSelected, getIsAccessLevelSelected],
  (permissionsChanged, newCollaboratorsSelected, isAccessLevelSelected) => {
    const pendingChanges = newCollaboratorsSelected && isAccessLevelSelected;
    return permissionsChanged || pendingChanges;
  }
);

export const getViewerChangeRequiresConfirmation = createSelector(
  [getUiMode, getPermissionsChanged, getAssetWasOriginallyInternalToPublicFederated],
  (mode, permissionsChanged, assetWasOriginallyInternalToPublicFederated) => {
    return (
      mode === MODES.CHANGE_AUDIENCE && permissionsChanged && assetWasOriginallyInternalToPublicFederated
    );
  }
);

export const getConfirmButtonDisabled = createSelector(
  [getUiMode, getConfirmButtonDisabledManually, getManageCollaboratorsHasChanges],
  (mode, disabledManually, manageCollaboratorsHasChanges) => {
    if ([MODES.MANAGE_COLLABORATORS, MODES.MANAGE_PLUGIN].includes(mode)) {
      // If we're editing collaborators, disable if no changes have been made
      return !manageCollaboratorsHasChanges;
    } else {
      // i.e., by setting the state with the SET_CONFIRM_BUTTON_DISABLED action
      return disabledManually;
    }
  }
);
