// Vendor Imports
import $ from 'jquery';
import _, { get } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

// Project Imports
import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

import BaseVisualization from './BaseVisualization/index';
import { VisualizationOptions } from './BaseVisualization/types';
import MetadataProvider, {
  ColumnAggregation,
  getFilterableColumns
} from 'common/visualizations/dataProviders/MetadataProvider';
import { Vif, Hierarchy } from '../vif';
import { ColDef, Column, ColumnState, IServerSideDatasource } from '@ag-grid-community/core';
import { ViewColumn } from 'common/types/viewColumn';
import GridContainer from './agGridReact/GridContainer';
import { Filters } from 'common/components/FilterBar/types';
import SoqlDataProvider from '../dataProviders/SoqlDataProvider';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import { ExportData } from './agGridReact/types';

export interface AgTableViewOptions extends VisualizationOptions {
  locale?: string;
  handleColumnReorder: (columnState: ColumnState[]) => void;
  handleColumnResize: (columns: Column[] | null) => void;
  handleColumnRowGroupChange: (columnState: ColumnState[], columns: Column[], hierarchyId?: string) => void;
  handleColumnSort: (columnState: ColumnState[], hierarchyId?: string) => void;
  handleColumnValueChange: (columns: Column[], hierarchyId?: string) => void;
  handleColumnVisibilityChange: (
    columnState: ColumnState[],
    columns: Column[] | null,
    hierarchyId?: string
  ) => void;
  handleGetGrandTotalRow: (hierarchyConfig: Hierarchy) => Promise<any>;
  handleHierarchyTabChange: (hierarchyId: string) => void;
  handleFilterChange: (newFilters: Filters) => void;
  datasource: IServerSideDatasource;
  paginationPageSize?: number;
  pagination?: boolean;
  defaultColDefOverrides?: ColDef;
  displayColumnFilters?: boolean;
  useSetFilters?: boolean;
  initializeRowStripeStyle?: () => RowStripeStyle;
  inVisualizationPreview?: boolean;
  getExportData?: (selectedFiltered: boolean) => Promise<ExportData>;
}
export default class AgTableView extends BaseVisualization {
  locale?: string;
  $element: JQuery;
  vifToRender: Vif;
  columnMetadata: ViewColumn[];
  nonStandardAggregations: ColumnAggregation[] | null;

  superRenderError: (messages: string[]) => void;
  options: AgTableViewOptions;
  metadataProvider: MetadataProvider;
  soqlDataProvider: SoqlDataProvider;
  vizUid: string | undefined;

  constructor(element: JQuery, originalVif: Vif, options: AgTableViewOptions) {
    super(element, originalVif, options);

    this.$element = element;
    this.locale = _.get(options, 'locale');
    this.options = options;
    this.vifToRender = originalVif;
    this.columnMetadata = [];
    this.nonStandardAggregations = null;
    this.vizUid = this.$element.find('.socrata-visualization').attr('id');

    // fix renderError since the way it is defined in BaseVisualization disallows normal method overrides
    this.superRenderError = this.renderError.bind(this);
    this.renderError = () => {
      this.$element.find('.socrata-visualization-container').removeClass('loaded');

      this.superRenderError(I18n.t('shared.visualizations.charts.table.error_unable_to_render', this.locale));
    };

    this.$element.find('.socrata-visualization').addClass('socrata-visualization-ag-grid');

    this.renderTemplate();
  }

  updateDataSource(newDataSource: IServerSideDatasource) {
    this.options.datasource = newDataSource;
  }

  shouldFetchAggregationSuggestions() {
    // When removing this feature flag, we can switch to _just_ checking the showAgGridColumnAggregations option
    const sideBarAggregationsEnabled =
      FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false) &&
      // When showAgGridColumnAggregations is not set, default to true
      (this.getOptions()?.showAgGridColumnAggregations ?? true);
    // Once flexible hierarchies is true, we never set this option. Ignore it.
    const hasPreviouslySavedNonStandardAggregation = _.get(
      this.getVif(),
      'series[0].dataSource.nonStandardAggregation'
    );

    return sideBarAggregationsEnabled || hasPreviouslySavedNonStandardAggregation;
  }

  async render(newVif: Vif): Promise<void> {
    if (!_.isEqual(newVif, this.vifToRender)) {
      this.vifToRender = newVif;

      this.updateVif(newVif);
      await this.updateColumnMetadata(newVif);
    }

    // https://socrata.atlassian.net/browse/EN-47840
    // Ensures the filters get rendered
    try {
      await this.updateFilterableColumns(newVif);
      const { metadata } = await this.getDatasetMetadata(newVif);
      const datasetName = get(metadata, 'name');

      const vifColumns: ViewColumn[] = _.get(this.vifToRender, 'series[0].dataSource.dimension.columns', []);

      if (vifColumns.length && !this.columnMetadata.length) {
        await this.updateColumnMetadata(this.vifToRender);
      }

      // in the case that the vif references columns that no longer exist on the dataset, we will render an error
      if (vifColumns.some((col) => !this.columnMetadata.find((cm) => cm.fieldName === col.fieldName))) {
        // TODO: is this a bug? We should probably pass the right error message down here.
        this.renderError();
        return;
      } else {
        this.clearError();
      }

      const {
        datasource,
        handleColumnReorder,
        handleColumnResize,
        handleColumnRowGroupChange,
        handleColumnSort,
        handleColumnValueChange,
        handleColumnVisibilityChange,
        handleGetGrandTotalRow,
        handleHierarchyTabChange,
        paginationPageSize,
        pagination,
        defaultColDefOverrides,
        handleFilterChange,
        displayColumnFilters,
        useSetFilters,
        showAgGridColumnMenu,
        showAgGridColumnAggregations,
        initializeRowStripeStyle,
        inVisualizationPreview,
        getExportData
      } = this.options;

      const agGridContainer = this.$element.find('.socrata-ag-table');
      ReactDOM.render(
        <GridContainer
          columnMetadata={this.columnMetadata}
          nonStandardAggregations={this.nonStandardAggregations}
          datasource={datasource}
          onHierarchyTabChange={handleHierarchyTabChange}
          getGrandTotalRow={handleGetGrandTotalRow}
          onColumnReorder={handleColumnReorder}
          onColumnResize={handleColumnResize}
          onColumnRowGroupChange={handleColumnRowGroupChange}
          onColumnSort={handleColumnSort}
          onColumnValueChange={handleColumnValueChange}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          vif={this.vifToRender}
          paginationPageSize={paginationPageSize}
          pagination={pagination}
          defaultColDefOverrides={defaultColDefOverrides}
          onFilterChange={(filters: Filters) => {
            handleFilterChange(filters);
          }}
          displayColumnFilters={displayColumnFilters}
          useSetFilters={useSetFilters}
          showAgGridColumnMenu={showAgGridColumnMenu}
          showAgGridColumnAggregations={showAgGridColumnAggregations}
          initializeRowStripeStyle={initializeRowStripeStyle}
          openToolPanelByDefault={inVisualizationPreview}
          vizUid={this.vizUid}
          datasetName={datasetName}
          getExportData={getExportData}
        />,
        agGridContainer[0]
      );
    } catch (e) {
      this.showVisualizationRenderError();
    }
  }

  renderCollocationMessage(): void {
    this.renderErrorMessage(I18n.t('shared.visualizations.charts.table.collocation_message', this.locale));
  }

  showVisualizationRenderError(): void {
    this.renderGenericError(
      I18n.t('shared.errors.private_or_deleted_asset.message'),
      'privateOrDeletedAsset'
    );
  }

  renderErrorMessage(message: string): void {
    this.$element.find('.socrata-visualization-container').removeClass('loaded');

    this.$container.find('.socrata-visualization-error-message').text(message);

    this.$container.removeClass('socrata-visualization-busy').addClass('socrata-visualization-error');
  }

  // If the 'small' class is added, the css will change in the agGridReact/index.css file
  renderComponentSize(width: number): void {
    if (width >= 450) {
      this.$element.find('.ag-grid-visualization').removeClass('small');
    } else {
      this.$element.find('.ag-grid-visualization').addClass('small');
    }
  }

  destroy(): void {
    this.$element.find('.socrata-ag-table').remove();
  }

  private renderTemplate(): void {
    const tableEl = $('<div>', { class: 'socrata-ag-table' });

    this.$element.find('.socrata-visualization-chart-container').append(tableEl);
  }

  async updateFilterableColumns(newVif: Vif): Promise<void> {
    const { metadata } = await this.getDatasetMetadata(newVif);
    // this call updates the columns in the base visualization,
    // which in turn updates the filter bar
    this.updateColumns(getFilterableColumns(metadata));
  }

  private async updateColumnMetadata(vif: Vif): Promise<void> {
    const { metadata, nonStandardAggregations } = await this.getDatasetMetadata(vif);
    this.columnMetadata = metadata.columns;
    this.nonStandardAggregations = nonStandardAggregations;
  }

  private async getDatasetMetadata(vif: Vif): Promise<any> {
    const datasetUid = _.get(vif, 'series[0].dataSource.datasetUid');
    if (this.shouldInitializeProviders(datasetUid)) {
      const dataProviderConfig = {
        datasetUid: datasetUid,
        domain: _.get(vif, 'series[0].dataSource.domain'),
        readFromNbe: _.get(vif, 'series[0].dataSource.readFromNbe', true)
      };
      this.metadataProvider = new MetadataProvider(dataProviderConfig, true);
      this.soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
    }

    return {
      metadata: await this.metadataProvider.getDatasetMetadata(),
      nonStandardAggregations: this.shouldFetchAggregationSuggestions()
        ? await this.metadataProvider.getAggregationSuggestions()
        : null
    };
  }

  private shouldInitializeProviders(datasetUid: string) {
    if (!this.metadataProvider || !this.soqlDataProvider) {
      return true;
    }

    if (
      !_.isEqual(datasetUid, this.soqlDataProvider.getConfigurationProperty('datasetUid')) ||
      !_.isEqual(datasetUid, this.metadataProvider.getConfigurationProperty('datasetUid'))
    ) {
      return true;
    }

    return false;
  }
}
