/* Imports */
/* ============================================================================= */
import { isEmpty } from 'lodash';

import { defaultHeaders } from 'common/http/index';
import { GuidanceV2 } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Returns a promise from fetch that will hit the approvals API to submit an asset to an approvals workflow.
 *
 * @param guidanceAction Guidance object to get submission URL from
 * @returns A Promise object
 */
export const submitFromGuidanceV2 = (guidanceAction: GuidanceV2) => {
  if (!guidanceAction.queueOptions || isEmpty(guidanceAction.queueOptions[0])) {
    return Promise.reject('no queueOptions provided to submitFromGuidanceV2');
  }
  const apiPath = guidanceAction.queueOptions[0].submissionUrl;
  const options: RequestInit = {
    body: JSON.stringify(guidanceAction.submission),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'POST'
  };

  return fetch(apiPath, options);
};

export default submitFromGuidanceV2;
