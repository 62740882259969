import { defaultHeaders } from 'common/http';

export function deleteNotification(notificationId) {
  fetch(`/api/notifications_and_alerts/notifications/${notificationId}`, {
    method: 'DELETE',
    credentials: 'same-origin'
  });
}

export function deleteAllNotifications() {
  fetch('/api/notifications_and_alerts/notifications', {
    method: 'DELETE',
    credentials: 'same-origin'
  });
}

export function toggleNotificationReadState(notificationId, toggle) {
  fetch(`/api/notifications_and_alerts/notifications/${notificationId}`, {
    method: 'PUT',
    headers: defaultHeaders,
    credentials: 'same-origin',
    body: JSON.stringify({
      'notification': { 'read': toggle }
    })
  });
}
