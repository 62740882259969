// Vendor Imports
import React from 'react';

// Project Imports
import CalendarDateFilterEditor, { CalendarDateFilterEditorProps } from './CalendarDateFilterEditor';
import CheckboxFilterEditor, { CheckboxFilterEditorProps } from './CheckboxFilterEditor';
import ComputedColumnFilterEditor, { ComputedColumnFilterEditorProps } from './ComputedColumnFilterEditor';
import NumberFilterEditor, { NumberFilterEditorProps } from './NumberFilterEditor';
import RadiusFilterEditor, { RadiusFilterEditorProps } from './RadiusFilterEditor';
import TextFilterEditor, { TextFilterEditorProps } from './TextFilterEditor';
import { FilterDataType, FilterEditorProps } from '../types';

const FilterEditor = (props: FilterEditorProps) => {
  const { appliedFilter } = props;
  if (!appliedFilter.dataTypeName) {
    return null;
  }

  switch (appliedFilter.dataTypeName) {
    case FilterDataType.CHECKBOX:
      return <CheckboxFilterEditor {...(props as CheckboxFilterEditorProps)} />;
    case FilterDataType.COMPUTED:
      return <ComputedColumnFilterEditor {...(props as ComputedColumnFilterEditorProps)} />;
    case FilterDataType.DATE:
      return <CalendarDateFilterEditor {...(props as CalendarDateFilterEditorProps)} />;
    case FilterDataType.NUMBER:
      return <NumberFilterEditor {...(props as NumberFilterEditorProps)} />;
    case FilterDataType.RADIUS:
      return <RadiusFilterEditor {...(props as RadiusFilterEditorProps)} />;
    case FilterDataType.TEXT:
      return <TextFilterEditor {...(props as TextFilterEditorProps)} />;
    default:
      return null;
  }
};

export default FilterEditor;
