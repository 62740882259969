import { cloneDeep, get, isNil, set, has, unset } from 'lodash';

const flagPath = 'socrata.featureFlags';

export const FeatureFlags = {
  // Returns true if feature flags are available, false otherwise. The flags may be unavailable
  // if we're not on a Socrata domain (i.e., in a Javascript-powered visualization embed).
  available: () => has(window, flagPath),
  value: function(key) {
    if (typeof globalDefault() === 'boolean') {
      return this.valueOrDefault(key, globalDefault());
    }

    const flagValue = get(window, `${flagPath}.${key}`);

    if (isNil(flagValue)) {
      throw new Error(`Invalid feature flag: '${key}'. Check that window.${flagPath} is present or that withTestFeatureFlags() has set this feature flag.`);
    }

    return flagValue;
  },
  valueOrDefault: (key, defaultValue) => {
    const flagValue = get(window, `${flagPath}.${key}`);
    if (isNil(flagValue)) {
      return defaultValue;
    }
    return flagValue;
  }
};

const globalDefault = () => get(window, 'socrata.featureFlags.DEFAULT_VALUE');

// Sets feature flags for the duration of the current describe block.
// Passing "undefined" or "null" is supported, this marks feature flags
// as unavailable for the duration of the test.
// Restores old values afterwards.
//
// Usage:
//
// import { withTestFeatureFlags } from 'common/feature_flags';
//
// describe('some feature-flagged capability', () => {
//   withTestFeatureFlags({
//     awesomeness_enabled: true,
//     old_busted_feature_enabled: false
//   });
//
//   it('respects feature flag', () => { ... });
// });
//
// Does not currently work with flags stored in locations other than
// the socrata namespace; will fix this very shortly.

export const withTestFeatureFlags = (flags) => {
  let originalValues;

  // mocha -> window.before / window.after
  // jest -> beforeAll / afterAll
  // eslint-disable-next-line no-undef
  const beforeFunction = window.before ? window.before : beforeAll;

  // eslint-disable-next-line no-undef
  const afterFunction = window.after ? window.after : afterAll;

  beforeFunction(() => {
    originalValues = has(window, flagPath) ? cloneDeep(get(window, flagPath)) : null;
    if (flags) {
      set(window, flagPath, {
        ...originalValues,
        ...flags
      });
    } else {
      unset(window, flagPath);
    }
  });

  afterFunction(() => {
    if (originalValues) {
      set(window, flagPath, originalValues);
    } else {
      unset(window, flagPath);
    }
  });
};

export default FeatureFlags;
