import url from 'url';
import _ from 'lodash';

import I18n from 'common/i18n';
import ceteraUtils from 'common/cetera/utils';
import { regexMatchPatterns } from 'common/utilities/Constants';

let parsedCurrentUrl = null;

// 'true' here means to parse the query string into an object
function parseCurrentUrl() {
  if (_.isEmpty(parsedCurrentUrl)) {
    parsedCurrentUrl = url.parse(window.location.href, true);
  }

  return parsedCurrentUrl;
}

/** Get the URL for performing the autocomplete search.
    If anonymous is true, redirect to the Open Data catalog. Else, redirect to SIAM. */
export function getBrowseOrSIAMUrl(searchTerm, anonymous = true) {
  const currentUrl = parseCurrentUrl();

  const browseOrSIAMPath = anonymous ? '/browse' : '/admin/assets';

  const currentUrlIsBrowseOrSIAM =
    currentUrl.pathname.match(new RegExp(`(\/${I18n.locale})?\\${browseOrSIAMPath}`));

  if (!currentUrlIsBrowseOrSIAM) {
    const shouldPrefixLocale = new RegExp(`^\/(${I18n.locale})`).test(currentUrl.pathname);
    const browseOrSIAMPathWithLocalePrefix = `/${I18n.locale}${browseOrSIAMPath}`;
    currentUrl.pathname = shouldPrefixLocale ? browseOrSIAMPathWithLocalePrefix : browseOrSIAMPath;
  }

  currentUrl.query = { };
  if (regexMatchPatterns.FOUR_BY_FOUR_PATTERN.test(searchTerm.trim())) {
    currentUrl.query.ids = searchTerm.trim();
  } else {
    currentUrl.query.q = searchTerm;
  }

  // Browse expects different query params for sort ordering than SIAM.
  if (anonymous) {
    currentUrl.query.sortBy = 'relevance';
  } else {
    // SIAM's default sort (when a query is present) is relevance. Strip any existing sorts.

    delete currentUrl.query.orderColumn;
    delete currentUrl.query.orderDirection;
  }

  // New search means we should return to the first page
  delete currentUrl.query.page;

  // have to blank this out to make the 'query' object get used instead
  currentUrl.search = null;

  return url.format(currentUrl);
}


export const DEFAULT_NUMBER_OF_RESULTS = 7;

export function getCeteraResults(searchTerm, callback, numberOfResults, anonymous) {
  if (_.isEmpty(searchTerm)) {
    return;
  }

  ceteraUtils.autocompleteQuery(searchTerm).then(
    (searchResults) => {
      /*
        * We ask for way more results than we need, since each result isn't necessarily distinct
        * i.e. if you have 10 datasets called "Crime Data" and do a search that only asks for
        * top 10 results, you would only get back 1 result of "Crime Data".
        * So we ask for more than we need and only take the top n
        */
      const number = _.isEmpty(numberOfResults) ? DEFAULT_NUMBER_OF_RESULTS : numberOfResults;

      searchResults.results = _.take(searchResults.results, number);
      callback(searchResults);
    },
    (error) => console.error('Failed to fetch data', error)
  ).catch((ex) => console.error('Error parsing JSON', ex));
}
