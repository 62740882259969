// Vendor Imports
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import Dropdown from 'common/components/Dropdown';
import FilterHeader from '../FilterHeader';
import FilterFooter from '../FilterFooter';
import { ENTER, isolateEventByKeys } from 'common/dom_helpers/keycodes_deprecated';
import I18n from 'common/i18n';
import { getPrecision } from 'common/numbers';
import { addPopupListener } from './InputFocus';
import { FilterEditorProps } from '../types';
import { FILTER_FUNCTION, NoopFilter, NumberFilterArgument, NumberSoqlFilter } from '../SoqlFilter';
import { PicklistOption } from 'common/components/Picklist';
import * as NumberFilter from '../lib/Filters/NumberFilter';
import * as BaseFilter from '../lib/Filters/BaseFilter';

const requiresRange = (func: string) => _.includes(['rangeInclusive', 'rangeExclusive'], func);

export interface NumberFilterEditorProps extends FilterEditorProps {
  appliedFilter: NumberSoqlFilter | NoopFilter;
  columns: BaseFilter.FilterColumnsMap;
}

interface NumberFilterEditorState {
  /**
   * NOTE: Other filter editors store individual UI components' values in state
   * and then apply them to the config separately. Number filter applies the values
   * directly to the filter config, so we store the entire dirty config in state to
   * keep rerenders the same.
   */
  dirtyFilter: NumberSoqlFilter | NoopFilter;
}

class NumberFilterEditor extends Component<NumberFilterEditorProps, NumberFilterEditorState> {
  numberFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: NumberFilterEditorProps) {
    super(props);

    const initialFilter = this.getInitialFilterState();
    this.state = {
      dirtyFilter: initialFilter
    };
  }

  componentDidMount() {
    const { popupRef } = this.props;
    this.removePopupListener = addPopupListener(popupRef, this.numberFilter, () => {
      this.popupListenerRemoved = true;
    });
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  getInitialFilterState = () => {
    const { appliedFilter, columns } = this.props;

    const initialFilter = appliedFilter;

    // If filter is single value, ensure the function is '=' and that the arguments.value is set.
    if (initialFilter.singleSelect) {
      _.set(initialFilter, 'function', FILTER_FUNCTION.EQUALS);
      _.set(initialFilter, 'arguments.includeNullValues', false);

      const didRequireRange = requiresRange(initialFilter.function);

      if (didRequireRange) {
        const value = _.get(appliedFilter, 'arguments.start', _.toString(NumberFilter.getMinValue(columns)));
        _.set(initialFilter, 'arguments.value', value);
        _.unset(initialFilter, 'arguments.start');
        _.unset(initialFilter, 'arguments.end');
      } else {
        const value = _.get(appliedFilter, 'arguments.value', _.toString(NumberFilter.getMinValue(columns)));
        _.set(initialFilter, 'value', value);
      }
    }

    return initialFilter;
  };

  onDropdownChange = (newValue: PicklistOption) => {
    const { appliedFilter, columns } = this.props;
    const { dirtyFilter } = this.state;
    const nowRequiresRange = requiresRange(newValue.value);
    const didRequireRange = requiresRange(dirtyFilter.function);
    const newFilter = _.cloneDeep(dirtyFilter);

    _.set(newFilter, 'function', newValue.value);

    if (nowRequiresRange && didRequireRange) {
      newFilter.arguments = dirtyFilter.arguments;
    } else if (nowRequiresRange) {
      const start = _.get(dirtyFilter, 'arguments.value', _.toString(NumberFilter.getMinValue(columns)));
      _.set(newFilter, 'arguments.start', start);
      _.set(newFilter, 'arguments.end', _.toString(NumberFilter.getMaxValue(columns)));
    } else if (didRequireRange) {
      const value = _.get(dirtyFilter, 'arguments.start', _.toString(NumberFilter.getMinValue(columns)));
      _.set(newFilter, 'arguments.value', value);
    } else {
      const value = _.get(dirtyFilter, 'arguments.value', _.toString(NumberFilter.getMinValue(columns)));
      _.set(newFilter, 'arguments.value', value);
    }

    let includeNullValues;
    const filterFunction = newFilter.function;
    if (filterFunction === FILTER_FUNCTION.EQUALS) {
      includeNullValues = false;
    } else if (filterFunction === FILTER_FUNCTION.EXCLUDE_NULL) {
      _.set(newFilter, 'arguments', {});
      includeNullValues = false;
    } else {
      includeNullValues = _.get(appliedFilter, 'arguments.includeNullValues', true);
    }

    _.set(newFilter, 'arguments.includeNullValues', includeNullValues);

    this.setState({ dirtyFilter: newFilter });
  };

  onNullCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { dirtyFilter } = this.state;
    const newFilter = _.cloneDeep(dirtyFilter);
    _.set(newFilter, 'arguments.includeNullValues', event.target.checked);

    this.setState({ dirtyFilter: newFilter });
  };

  isDirty = () => {
    const { dirtyFilter } = this.state;
    const { appliedFilter } = this.props;

    const isFilterInvalid = !!dirtyFilter.singleSelect && _.isEmpty(dirtyFilter.arguments?.value);
    return !(_.isEqual(dirtyFilter, appliedFilter) || isFilterInvalid);
  };

  applyFilter = () => {
    const { onUpdate } = this.props;
    const { dirtyFilter } = this.state;
    const newFilter = _.cloneDeep(dirtyFilter);

    // Swap the start and end if necessary to ensure the range is valid
    const { start, end } = (dirtyFilter.arguments as NumberFilterArgument) || {};

    // Because the filter arguments are always strings, coerce strings to
    // numbers before doing this comparison. For example: start of '24000'
    // and end of '230000' would have caused these to flip.
    if (_.toNumber(start) > _.toNumber(end)) {
      newFilter.arguments = {
        start: end,
        end: start
      };
    }

    this.setState({ dirtyFilter: newFilter });
    onUpdate(newFilter);
  };

  applyOnEnter = (event: React.KeyboardEvent) => {
    isolateEventByKeys(event, [ENTER]);

    if (event.keyCode === ENTER && !this.isDirty()) {
      this.applyFilter();
    }
  };

  getStepInterval = (): number | undefined => {
    const { columns } = this.props;

    return _.min(_.map([NumberFilter.getMinValue(columns), NumberFilter.getMaxValue(columns)], getPrecision));
  };

  resetFilter = () => {
    const { appliedFilter, columns, onUpdate } = this.props;

    const resetFilter = BaseFilter.reset(appliedFilter, columns);
    onUpdate(resetFilter);
  };

  formatValue = (value: string | number) => {
    const { columns } = this.props;

    // value is always a string
    if (!NumberFilter.isPercentage(columns)) {
      return value;
    }
    const scale = NumberFilter.getPercentScale(columns);
    return new BigNumber(value).mul(100).div(scale).toString();
  };

  unformatValue = (value: string) => {
    const { columns } = this.props;

    // value is always a string
    if (!NumberFilter.isPercentage(columns)) {
      return value;
    }
    const scale = NumberFilter.getPercentScale(columns);
    return new BigNumber(value).div(100).mul(scale).toString();
  };

  renderRangeInputFields = () => {
    const { dirtyFilter } = this.state;
    const { columns } = this.props;

    const setArgument = (argument: string, value: string) => {
      const nextFilter = _.cloneDeep(dirtyFilter);
      _.set(nextFilter, ['arguments', argument], this.unformatValue(value));
      this.setState({ dirtyFilter: nextFilter });
    };

    const inputProps = {
      className: 'range-input text-input',
      type: 'number',
      step: this.getStepInterval(),
      onKeyUp: this.applyOnEnter
    };

    const start = this.formatValue(dirtyFilter.arguments?.start ?? (NumberFilter.getMinValue(columns) || ''));
    const end = this.formatValue(dirtyFilter.arguments?.end ?? (NumberFilter.getMaxValue(columns) || ''));

    return (
      <div className="range-text-inputs-container input-group">
        <input
          id="start"
          data-testid="start"
          value={start}
          onChange={(event) => {
            setArgument('start', event.target.value);
          }}
          aria-label={I18n.t('shared.components.filter_bar.from')}
          placeholder={I18n.t('shared.components.filter_bar.from')}
          {...inputProps}
        />
        <span className="range-separator">-</span>
        <input
          id="end"
          data-testid="end"
          value={end}
          onChange={(event) => {
            setArgument('end', event.target.value);
          }}
          aria-label={I18n.t('shared.components.filter_bar.to')}
          placeholder={I18n.t('shared.components.filter_bar.to')}
          {...inputProps}
        />
      </div>
    );
  };

  renderSingleInputField = () => {
    const { dirtyFilter } = this.state;
    const { columns } = this.props;
    const value = dirtyFilter.arguments?.value ?? (NumberFilter.getMinValue(columns) || '');

    const inputProps = {
      'aria-label': I18n.t('shared.components.filter_bar.range_filter.value'),
      className: 'range-input text-input',
      'data-testid': 'value',
      id: 'value',
      onKeyUp: this.applyOnEnter,
      placeholder: I18n.t('shared.components.filter_bar.range_filter.value'),
      step: this.getStepInterval(),
      type: 'number',
      value: this.formatValue(value),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const nextFilter = _.set(
          _.cloneDeep(this.state.dirtyFilter),
          'arguments.value',
          this.unformatValue(event.target.value)
        );
        this.setState({ dirtyFilter: nextFilter });
      }
    };

    return (
      <div className="range-text-inputs-container input-group">
        <input {...inputProps} />
      </div>
    );
  };

  shouldRenderInputFields = () => {
    const { dirtyFilter } = this.state;
    if (dirtyFilter.singleSelect) {
      return true;
    }

    const filterFunction = dirtyFilter.function;
    if (!filterFunction) {
      return false;
    }
    switch (filterFunction) {
      case FILTER_FUNCTION.NOOP:
      case FILTER_FUNCTION.EXCLUDE_NULL:
        return false;
      default:
        return true;
    }
  };

  renderInputFields = () => {
    const { dirtyFilter } = this.state;
    if (dirtyFilter.singleSelect) {
      return this.renderSingleInputField();
    }

    return requiresRange(dirtyFilter.function)
      ? this.renderRangeInputFields()
      : this.renderSingleInputField();
  };

  renderDropdown = () => {
    const operators = 'shared.components.filter_bar.range_filter.operators';
    const props = {
      onSelection: this.onDropdownChange,
      options: [
        {
          title: I18n.t(`${operators}.equal.title`),
          value: '=',
          symbol: I18n.t(`${operators}.equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.not_equal.title`),
          value: '!=',
          symbol: I18n.t(`${operators}.not_equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.less_than.title`),
          value: '<',
          symbol: I18n.t(`${operators}.less_than.symbol`)
        },
        {
          title: I18n.t(`${operators}.greater_than.title`),
          value: '>',
          symbol: I18n.t(`${operators}.greater_than.symbol`)
        },
        {
          title: I18n.t(`${operators}.less_than_or_equal.title`),
          value: '<=',
          symbol: I18n.t(`${operators}.less_than_or_equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.greater_than_or_equal.title`),
          value: '>=',
          symbol: I18n.t(`${operators}.greater_than_or_equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.range_exclusive`),
          value: 'rangeExclusive'
        },
        {
          title: I18n.t(`${operators}.range_inclusive`),
          value: 'rangeInclusive'
        },
        { title: I18n.t(`${operators}.exclude_null`), value: 'excludeNull' }
      ],
      size: 'small',
      value: this.state.dirtyFilter.function,
      alwaysCalculateHeight: true
    };
    return <Dropdown {...props} />;
  };

  shouldRenderDropdown = () => {
    return !this.state.dirtyFilter.singleSelect;
  };

  shouldRenderNullValueCheckbox = () => {
    const { dirtyFilter } = this.state;

    if (dirtyFilter.singleSelect) {
      return false;
    }
    const filterFunction = dirtyFilter.function;
    if (!filterFunction) {
      return false;
    }
    switch (filterFunction) {
      case FILTER_FUNCTION.NOOP:
      case FILTER_FUNCTION.EXCLUDE_NULL:
      case FILTER_FUNCTION.EQUALS:
        return false;
      default:
        return true;
    }
  };

  renderNullValueCheckbox = () => {
    const { dirtyFilter } = this.state;
    const className = classNames('checkbox');

    const checked = _.get(dirtyFilter, 'arguments.includeNullValues', true);
    const nullToggleId = _.uniqueId('include-nulls-');
    const inputAttributes = {
      id: nullToggleId,
      className: 'include-nulls-toggle',
      type: 'checkbox',
      onChange: this.onNullCheckboxChange,
      checked
    };

    return (
      <div className={className}>
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor={nullToggleId}>
          <span className="fake-checkbox">
            <span className="icon-checkmark3" />
          </span>
          {I18n.t('shared.components.filter_bar.range_filter.include_null_values')}
        </label>
      </div>
    );
  };

  render() {
    const { appliedFilter, isReadOnly, onRemove, showRemoveButtonInFooter, columns } = this.props;
    const { dirtyFilter } = this.state;
    const headerProps = {
      name: BaseFilter.getFilterName(appliedFilter, columns)
    };

    const footerProps = {
      disableApplyFilter: !this.isDirty(),
      isDrilldown: dirtyFilter.isDrilldown,
      isReadOnly,
      onClickApply: this.applyFilter,
      onClickRemove: onRemove,
      onClickReset: this.resetFilter,
      showRemoveButton: showRemoveButtonInFooter
    };

    return (
      <div className="filter-controls number-filter" ref={(el: HTMLDivElement) => (this.numberFilter = el)}>
        <div className="range-filter-container">
          <FilterHeader {...headerProps} />
          {this.shouldRenderDropdown() && this.renderDropdown()}
          {this.shouldRenderInputFields() && this.renderInputFields()}
          {this.shouldRenderNullValueCheckbox() && this.renderNullValueCheckbox()}
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default NumberFilterEditor;
