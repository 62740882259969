/* Imports */
/* ============================================================================= */
import { extend } from 'lodash';

import { WorkflowTargetAudience } from 'common/core/approvals_enums';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import {
  BaseGuidanceSummaryV2Helper,
  GuidanceSummaryV2Helper,
  CheckMethodsObject
} from 'common/core/approvals/Types';
import {
  hasTruthyWorkflow,
  createWorkflowGuidanceHelperMethodsObject,
  getPublishedUid,
  runWrappedApprovalActionMethod,
  runWrappedSubmitActionMethod
} from '.';





/* Method */
/* ============================================================================= */
/**
 * Add functions to a guidance summary helper object for a given guidance summary to mimic workflow guidance helper methods
 * @param guidance GuidanceSummaryV2 object
 * @param guidanceHelper BaseGuidanceSummaryV2Helper object
 * @returns GuidanceSummaryV2Helper object
 */
export const decorateGuidanceSummaryHelper = (guidance: GuidanceSummaryV2, guidanceHelper: BaseGuidanceSummaryV2Helper): GuidanceSummaryV2Helper => {

  extend(guidanceHelper, {
    /* Async Approval Actions */
    /* ----------------------------------------------- */
    approve: (notes = '') => runWrappedApprovalActionMethod(
      guidance,
      guidanceHelper.summarizeIsPending(),
      createWorkflowGuidanceHelperMethodsObject<CheckMethodsObject<Promise<Response>>>(guidanceHelper, 'approve'),
      notes
    ),
    reject: (notes = '') => runWrappedApprovalActionMethod(
      guidance,
      guidanceHelper.summarizeIsPending(),
      createWorkflowGuidanceHelperMethodsObject<CheckMethodsObject<Promise<Response>>>(guidanceHelper, 'reject'),
      notes
    ),
    withdraw: () => runWrappedApprovalActionMethod(
      guidance,
      guidanceHelper.summarizeIsPending(),
      createWorkflowGuidanceHelperMethodsObject<CheckMethodsObject<Promise<Response>>>(guidanceHelper, 'withdraw')
    ),


    /* Async Submission Actions */
    /* ----------------------------------------------- */
    submitChangeAudienceRequest: (workflow: WorkflowTargetAudience) => runWrappedSubmitActionMethod(
      guidance,
      guidanceHelper.summarizeCanSubmitForApproval(),
      workflow,
      createWorkflowGuidanceHelperMethodsObject<CheckMethodsObject<Promise<Response>>>(guidanceHelper, 'submitChangeAudienceRequest')
    ),
    submitForApproval: (workflow: WorkflowTargetAudience) => runWrappedSubmitActionMethod(
      guidance,
      guidanceHelper.summarizeCanSubmitForApproval(),
      workflow,
      createWorkflowGuidanceHelperMethodsObject<CheckMethodsObject<Promise<Response>>>(guidanceHelper, 'submitForApproval')
    ),
    submitUpdatePublishedAssetRequest: (workflow: WorkflowTargetAudience) => runWrappedSubmitActionMethod(
      guidance,
      guidanceHelper.summarizeCanSubmitForApproval(),
      workflow,
      createWorkflowGuidanceHelperMethodsObject<CheckMethodsObject<Promise<Response>>>(guidanceHelper, 'submitUpdatePublishedAssetRequest')
    ),


    /* Synchronous Boolean Checks */
    /* ----------------------------------------------- */
    canSubmitChangeAudienceRequest: (workflow?: WorkflowTargetAudience) =>
      hasTruthyWorkflow(guidanceHelper.summarizeCanSubmitChangeAudienceRequest(), workflow),
    canSubmitForApproval: (workflow?: WorkflowTargetAudience) =>
      hasTruthyWorkflow(guidanceHelper.summarizeCanSubmitForApproval(), workflow),
    canSubmitUpdatePublishedAssetRequest: (workflow?: WorkflowTargetAudience) =>
      hasTruthyWorkflow(guidanceHelper.summarizeCanSubmitUpdatePublishedAssetRequest(), workflow),
    isPending: (workflow?: WorkflowTargetAudience) =>
      hasTruthyWorkflow(guidanceHelper.summarizeIsPending(), workflow),
    willEnterApprovalQueue: (workflow?: WorkflowTargetAudience) =>
      hasTruthyWorkflow(guidanceHelper.summarizeWillEnterApprovalQueue(), workflow),


    /* Utility */
    /* ----------------------------------------------- */
    publishedUid: (workflow?: WorkflowTargetAudience) => getPublishedUid(guidanceHelper, workflow),
    sitesThatWillBeFederatedToIfApprovedToPublic: () =>
      guidanceHelper.public?.sitesThatWillBeFederatedToIfApprovedToPublic() || [],
    sitesThatWillBeFederatedToIfMadePublic: () =>
      guidanceHelper.public?.sitesThatWillBeFederatedToIfMadePublic() || [],
  });

  return guidanceHelper as GuidanceSummaryV2Helper;
};

export default decorateGuidanceSummaryHelper;
