import { takeEvery, takeLatest, put, select, delay } from 'redux-saga/effects';

import { checkExpectedOutcomes } from 'common/components/AccessManager/Util';
import type { GuidanceSummaryV2 } from 'common/types/approvals';

import {
  PermissionsActionsTypes,
  changeAudienceScope
} from 'common/components/AccessManager/actions/PermissionsActions';
import {
  UiActionTypes,
  didSwitchModes,
  switchModes
} from 'common/components/AccessManager/actions/UiActions';

import * as selectors from './Selectors';
import { View } from 'common/types/view';

function* onChangeAudienceScope(action: ReturnType<typeof changeAudienceScope>) {
  const approvalsGuidance: GuidanceSummaryV2 = yield select(selectors.getApprovalsGuidance);
  const view: Partial<View> = yield select(selectors.getCurrentView);
  const { scope } = action.payload;

  yield checkExpectedOutcomes(approvalsGuidance, scope, view);
}

function* onSwitchModes(action: ReturnType<typeof switchModes>) {
  const { newMode } = action.payload;

  // Unfortunately this value has to be duplicated w/ the CSS animation
  yield delay(150);
  yield put(didSwitchModes(newMode));
}

export default [
  takeLatest(PermissionsActionsTypes.CHANGE_AUDIENCE_SCOPE, onChangeAudienceScope),
  takeEvery(UiActionTypes.SWITCH_MODES, onSwitchModes)
];
