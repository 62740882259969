const formatToTwoPlaces = (value) => (value < 10 ? `0${value.toString()}` : value.toString());

export default (date) => {
  // The month component of JavaScript dates is 0-indexed (I have no idea
  // why) so when we are serializing a JavaScript date as ISO-8601 date we
  // need to increment the month value.
  const fullYear = date.getFullYear();
  const month = formatToTwoPlaces(date.getMonth() + 1);
  const formattedDate = formatToTwoPlaces(date.getDate());
  const hours = formatToTwoPlaces(date.getHours());
  const minutes = formatToTwoPlaces(date.getMinutes());
  const seconds = formatToTwoPlaces(date.getSeconds());
  return `${fullYear}-${month}-${formattedDate}T${hours}:${minutes}:${seconds}`;
};
