import { StorytellerState } from 'store/StorytellerReduxStore';

export const getEditorId = (state: StorytellerState) => {
  return state.linkTipSlice.editorId;
};

export const getBoundingClientRect = (state: StorytellerState) => {
  return state.linkTipSlice.boundingClientRect;
};

export const getVisibility = (state: StorytellerState) => {
  return state.linkTipSlice.visible;
};

export const getInputs = (state: StorytellerState) => {
  return state.linkTipSlice.inputs;
};

export const shouldRemoveLink = (state: StorytellerState, editorId: string) => {
  return editorId === state.linkTipSlice.editorId && state.linkTipSlice.remove;
};
