/* Imports */
/* ============================================================================= */
import {
  GuidanceV2,
  WorkflowGuidance,
} from 'common/types/approvals';

import { getSubmissionActionObject } from 'common/core/approvals/guidanceHelpers';
import { executeApprovalsRequestV2 } from 'common/core/approvals/guidanceLogic';





/* Method */
/* ============================================================================= */
/**
 * Given a WorkflowGuidance, this will find the proper submission details and then will
 * submit an asset for approval.
 *
 * This can result in any of the keys in `SUBMISSION_KEYS_V2` being used to submit the asset.
 * At the time of writing, this uses one of:
 * - toChangeAudience: Changing the audience (a.k.a. visibility) of an asset to that of the given WorkflowGuidance object (public or internal).
 * - toUpdatePublishedAsset: Making an update to an already published asset.
 * - toPublishToPublic: Publishing an asset for the first time _and_ making it public.
 *
 * @param guidance WorkflowGuidance object
 * @returns A Promise object
 */
export const submitAnyAvailableRequestV2 = (guidance: WorkflowGuidance) => {
  const guidanceAction: GuidanceV2 | undefined = getSubmissionActionObject(guidance);

  if (!guidanceAction)
    return Promise.reject('No submissions available for workflow guidance');

  return executeApprovalsRequestV2(guidance, guidanceAction);
};

export default submitAnyAvailableRequestV2;
