import { checkStatus, defaultHeaders } from 'common/http';

const deleteAlertAPI = {
  // Deletes an alert
  deleteAlert: (alertId) => {
    return fetch(`/api/notifications_and_alerts/alerts/${alertId}`, {
      method: 'DELETE',
      headers: defaultHeaders,
      credentials: 'same-origin'
    }).then(checkStatus);
  },
  // Bulk Delete
  bulkDelete: (alertIds) => fetch('/api/notifications_and_alerts/alerts/bulk_delete', {
    method: 'POST',
    headers: defaultHeaders,
    credentials: 'same-origin',
    body: JSON.stringify({ ids: alertIds.join(',') })
  }).then(checkStatus)
};

export default deleteAlertAPI;
