import { isNumber } from 'lodash';
import assert from './assert';

type AssertIsNumber =  (value: unknown) => asserts value is number;

/**
 * Ensures the given value is a number.
 *
 * @param {unknown} value - The value to check
 */
const assertIsNumber: AssertIsNumber = (value) => {
  assert(isNumber(value), `Expected ${value} to be a number.`);
};

export default assertIsNumber;
