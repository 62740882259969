import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import { VARIANTS } from 'common/components/Button';
import { ForgeDialog, ForgeToolbar, ForgeButton } from '@tylertech/forge-react';

interface ConfirmationModalProps {
  /** Function to call when clicking the confirm button */
  onAgree: () => void;

  /** Function to call when clicking the cancel button */
  onCancel: () => void;

  /** Text to put inside cancel button; defaults to "Cancel" */
  cancelButtonText?: string;

  /** Whether or not to hide the cancel button. It is shown by default. */
  hideCancelButton?: boolean;

  /** Text to put inside agree button; defaults to "OK" */
  agreeButtonText?: string;

  /** Variant to use for confirm button; defaults to PRIMARY. Forge version Only ERROR is used */
  agreeButtonVariant?: VARIANTS;

  /** Optional text to show in modal header */
  headerText?: string;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  children,
  onCancel,
  onAgree,
  headerText,
  cancelButtonText,
  agreeButtonText,
  agreeButtonVariant,
  hideCancelButton
}) => (
  <ForgeDialog open={true} onDismiss={onCancel} >
    <div className='confirmation-dialog-container-forged'>
      <div className='container-body'>
        <h1 id="confirmation-modal-title" className='forge-typography--title forge-header-title'>
          {headerText}
        </h1>
        <p className="forge-typography--body2">
          {children}
        </p>
      </div>
      <ForgeToolbar inverted={true}>
        {!hideCancelButton &&
          <ForgeButton
            type="outlined"
            onClick={onCancel}
            className="confirmation-cancel"
            slot="end"
          >
            <button type="button" data-testid='confirmation-cancel-button'>
              {cancelButtonText || I18n.t('shared.components.confirmation.cancel')}
            </button>
          </ForgeButton>
        }
        <ForgeButton
          type="raised"
          onClick={onAgree}
          className={`confirmation-accept ${agreeButtonVariant === VARIANTS.ERROR ? 'confirmation-forge-danger-style' : ''}`}
          slot="end"
        >
          <button type="button" data-testid='confirmation-accept-button'>
            {agreeButtonText || I18n.t('shared.components.confirmation.agree')}
          </button>
        </ForgeButton>
      </ForgeToolbar>
    </div>
  </ForgeDialog>
);

export default ConfirmationModal;
