import React, { FunctionComponent } from 'react';
import I18n from 'common/i18n';

import './confirm-destructive-audience-change.scss';

const classNameScope = 'common--components--AccessManager--components--ConfirmDestructiveAudienceChange';

/**
 * At this time, destructive audience changes are when a public, data federated asset
 * is made private or internal.
 */
const ConfirmDestructiveAudienceChange: FunctionComponent = () => {
  const scope = 'shared.site_chrome.access_manager.confirm_destructive_audience_change';
  return (
    <div
      className={`${classNameScope}--confirm-destructive-audience-change`}
      dangerouslySetInnerHTML={{ __html: I18n.t('message', { scope }) }}
    ></div>
  );
};

export default ConfirmDestructiveAudienceChange;
