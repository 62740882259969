import DomainRights from 'common/types/domainRights';
import FeatureFlag from 'common/feature_flags';
import _ from 'lodash';

const domainModules = _.get(window, 'socrata.modules');
const userFlags = _.get(window, 'socrata.currentUser.flags');
const currentUser = _.get(window, 'socrata.currentUser', {});
const usersRights = _.get(currentUser, 'rights');

export const canSeeCreateAssetsDialog = () => {
  if (!currentUser) {
    return false;
  }
  return isSuperAdmin() || canCreateDatasets() ||
    canCreateMeasures() || canCreateESRIMapLayers() ||
    canCreateStories();
};

export const canCreateMeasures = () => {
  if (!currentUser) {
    return false;
  }
  return FeatureFlag.value('open_performance_standalone_measures') &&
    (isSuperAdmin() || _.includes(usersRights, DomainRights.create_measures));
};

export const canCreateStories = () => {
  if (!currentUser) {
    return false;
  }
  return FeatureFlag.value('stories_enabled') &&
    (isSuperAdmin() || _.includes(usersRights, DomainRights.create_story));
};

export const canCreateDatasets = () => {
  if (!currentUser) {
    return false;
  }
  return isSuperAdmin() || _.includes(usersRights, DomainRights.create_datasets);
};

export const canCreateESRIMapLayers = () => {
  if (!currentUser || !_.get(domainModules, 'esri_integration', false)) {
    return false;
  }
  return isSuperAdmin() || _.includes(usersRights, DomainRights.create_datasets);
};

export const isSuperAdmin = () => {
  if (!currentUser) {
    return false;
  }
  return _.includes(userFlags, 'admin');
};
