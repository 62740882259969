import _ from 'lodash';
import $ from 'jquery';

import MapHelper from 'common/visualizations/helpers/MapHelper';
import {
  QUANTIFICATION_METHODS,
  VIF_CONSTANTS,
  NULL_CATEGORY_VALUE,
  VECTOR_BASEMAP_STYLES
} from 'common/authoring_workflow/constants';
import {
  DEFAULT_STACK_RADIUS_PADDING,
  HIGHLIGHT,
  RADIUS_TO_CHARM_IMAGE_SIZE
} from 'common/visualizations/views/mapConstants';
import { getCharmSvgSrc, MAPBOX_GL_CHARMS_FONT_NAME } from 'common/resources/charms';
import { getLinearBucketValueForFeature } from 'common/visualizations/helpers/RangeHelper';
import { getRangeBucket } from 'common/visualizations/helpers/BucketHelper';
import { getBasemapStyle } from 'common/visualizations/views/map/basemapStyle';

export const SOURCES = Object.freeze({
  POINTS_AND_STACKS: 'points-and-stacks-source'
});

export const LAYERS = Object.freeze({
  STACKS_CIRCLE: 'stacks-circle-layer',
  STACKS_LABEL: 'stacks-label-layer',
  POINTS_CIRCLE: 'points-circle-layer',
  POINTS_SYMBOL: 'point-symbol-layer'
});

// Renders points and stacks retrieved by the tile data call.
// - If a location has only one feature, we draw a point circle.
// - If a location has more than one feature, we draw a stack circle.
// We snap-and-group points on server side along with count. On top of
// it we cluster on mapbox, so that it doesn't look like a checker-board.
// If a location has only one record, but it is a snap-and-group of more
// than one feature, we draw a stack circle.
export default class PointsAndStacks {
  constructor(map, seriesId) {
    this._map = map;

    this.layerIds = MapHelper.getNameToIdMap(_.values(LAYERS), seriesId);
    this.sourceIds = MapHelper.getNameToIdMap(_.values(SOURCES), seriesId);
  }

  alreadySetup() {
    return this._map.getSource(this.sourceIds[SOURCES.POINTS_AND_STACKS]);
  }

  setup(vif, renderOptions, overlayOptions) {
    const pointAndStackRadius = vif.getPointAndStackCircleRadiusPaintProperty(
      renderOptions.resizeByRange,
      renderOptions.aggregateAndResizeBy
    );
    const stackOutlineColor = vif.getStackOutlineColor(
      renderOptions.layerStyles,
      renderOptions.colorByBuckets,
    );
    const stackCircleRadius = overlayOptions.hasMultiplePointMapSeries ?
      overlayOptions.stackSize + DEFAULT_STACK_RADIUS_PADDING :
      pointAndStackRadius;
    const shouldShowStackCount = vif.isLayerVisible() &&
      vif.shouldShowStackCount() &&
      !overlayOptions.hasMultiplePointMapSeries;
    const isMultiPointColumn = vif.isMultiPointColumn(renderOptions.datasetMetadata);
    const stackCircleLayerVisibility = vif.isLayerVisible() && !isMultiPointColumn ? 'visible' : 'none';
    const stackSymbolLayerVisibility = shouldShowStackCount && !isMultiPointColumn ? 'visible' : 'none';
    const pointAndSymbolFilter = getPointAndSymbolFilter(renderOptions.countBy, isMultiPointColumn);

    this._map.addSource(this.sourceIds[SOURCES.POINTS_AND_STACKS], this.sourceOptions(vif, renderOptions));

    this._map.addLayer({
      'id': this.layerIds[LAYERS.STACKS_CIRCLE],
      'type': 'circle',
      'source': this.sourceIds[SOURCES.POINTS_AND_STACKS],
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', renderOptions.countBy, 1, '1']],
      'layout': {
        'visibility': stackCircleLayerVisibility
      },
      'paint': {
        'circle-radius': stackCircleRadius,
        'circle-color': renderOptions.layerStyles.STACK_COLOR,
        'circle-stroke-width': renderOptions.layerStyles.STACK_BORDER_SIZE,
        'circle-stroke-color': stackOutlineColor,
        'circle-stroke-opacity': vif.getPointOpacity()
      }
    }, overlayOptions.renderLayersBefore);

    this._map.addLayer({
      id: this.layerIds[LAYERS.STACKS_LABEL],
      type: 'symbol',
      'source': this.sourceIds[SOURCES.POINTS_AND_STACKS],
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', renderOptions.countBy, 1, '1']],
      layout: {
        'text-field': getPointCountAbbreviationExpression(renderOptions.countBy),
        'text-size': stackCircleRadius,
        'text-allow-overlap': true,
        'visibility': stackSymbolLayerVisibility
      },
      paint: {
        'text-color': VIF_CONSTANTS.STACK_TEXT_COLOR
      }
    }, overlayOptions.renderLayersBefore);

    this._map.addLayer({
      id: this.layerIds[LAYERS.POINTS_CIRCLE],
      type: 'circle',
      'source': this.sourceIds[SOURCES.POINTS_AND_STACKS],
      'source-layer': '_geojsonTileLayer',
      'filter': pointAndSymbolFilter,
      'layout': {
        'visibility': vif.isLayerVisible() ? 'visible' : 'none'
      },
      'paint': {
        'circle-radius': pointAndStackRadius,
        'circle-color': vif.getFeatureColor(renderOptions),
        'circle-opacity': vif.getPointOpacity()
      }
    }, overlayOptions.renderLayersBefore);

    this._map.addLayer({
      id: this.layerIds[LAYERS.POINTS_SYMBOL],
      type: 'symbol',
      'source': this.sourceIds[SOURCES.POINTS_AND_STACKS],
      'source-layer': '_geojsonTileLayer',
      'filter': pointAndSymbolFilter,
      'layout': {
        'visibility': vif.isLayerVisible() && vif.isCharmsLayerVisible() ? 'visible' : 'none',
        'text-font': [
          MAPBOX_GL_CHARMS_FONT_NAME
        ],
        'text-field': vif.getPaintPropertyForCharm(renderOptions),
        'text-offset': [0, 0.2],
        'text-allow-overlap': true,
        'text-size': vif.getCharmSizePaintProperty(
          renderOptions.resizeByRange,
          renderOptions.aggregateAndResizeBy
        )
      },
      'paint': {
        'text-color': '#ffffff',
        'text-opacity': vif.getPointOpacity()
      }
    }, overlayOptions.renderLayersBefore);
  }

  update(vif, renderOptions, overlayOptions) {
    const pointAndStackRadius = vif.getPointAndStackCircleRadiusPaintProperty(
      renderOptions.resizeByRange,
      renderOptions.aggregateAndResizeBy
    );
    const stackOutlineColor = vif.getStackOutlineColor(
      renderOptions.layerStyles,
      renderOptions.colorByBuckets
    );
    const stackCircleRadius = overlayOptions.hasMultiplePointMapSeries ?
      overlayOptions.stackSize + DEFAULT_STACK_RADIUS_PADDING :
      pointAndStackRadius;
    const shouldShowStackCount = vif.isLayerVisible() &&
      vif.shouldShowStackCount() &&
      !overlayOptions.hasMultiplePointMapSeries;
    const isMultiPointColumn = vif.isMultiPointColumn(renderOptions.datasetMetadata);
    const stackCircleLayerVisibility = vif.isLayerVisible() && !isMultiPointColumn ? 'visible' : 'none';
    const stackSymbolLayerVisibility = shouldShowStackCount && !isMultiPointColumn ? 'visible' : 'none';
    const pointAndSymbolFilter = getPointAndSymbolFilter(renderOptions.countBy, isMultiPointColumn);

    // Updating point color/radius based on new vif
    this._map.setLayoutProperty(this.layerIds[LAYERS.POINTS_CIRCLE],
      'visibility',
      vif.isLayerVisible() ? 'visible' : 'none'
    );
    this._map.setFilter(this.layerIds[LAYERS.POINTS_CIRCLE], pointAndSymbolFilter);
    this._map.setPaintProperty(this.layerIds[LAYERS.POINTS_CIRCLE],
      'circle-color',
      vif.getFeatureColor(renderOptions));
    this._map.setPaintProperty(this.layerIds[LAYERS.POINTS_CIRCLE],
      'circle-radius',
      pointAndStackRadius);
    this._map.setPaintProperty(this.layerIds[LAYERS.POINTS_CIRCLE],
      'circle-opacity',
      vif.getPointOpacity());

    // Updating stack look and feel based on new base-map-style in vif
    this._map.setLayoutProperty(this.layerIds[LAYERS.STACKS_CIRCLE],
      'visibility',
      stackCircleLayerVisibility
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.STACKS_CIRCLE],
      'circle-radius',
      stackCircleRadius);
    this._map.setPaintProperty(this.layerIds[LAYERS.STACKS_CIRCLE],
      'circle-color',
      renderOptions.layerStyles.STACK_COLOR);
    this._map.setPaintProperty(this.layerIds[LAYERS.STACKS_CIRCLE],
      'circle-stroke-width',
      renderOptions.layerStyles.STACK_BORDER_SIZE);
    this._map.setPaintProperty(this.layerIds[LAYERS.STACKS_CIRCLE],
      'circle-stroke-color',
      stackOutlineColor);
    this._map.setPaintProperty(this.layerIds[LAYERS.STACKS_CIRCLE],
      'circle-stroke-opacity',
      vif.getPointOpacity());
    this._map.setLayoutProperty(this.layerIds[LAYERS.STACKS_LABEL],
      'visibility',
      stackSymbolLayerVisibility
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.STACKS_LABEL],
      'text-color',
      VIF_CONSTANTS.STACK_TEXT_COLOR);
    this._map.setLayoutProperty(this.layerIds[LAYERS.STACKS_LABEL],
      'text-size',
      stackCircleRadius);

    this._map.setLayoutProperty(
      this.layerIds[LAYERS.POINTS_SYMBOL],
      'visibility',
      vif.isLayerVisible() && vif.isCharmsLayerVisible() ? 'visible' : 'none'
    );
    this._map.setLayoutProperty(
      this.layerIds[LAYERS.POINTS_SYMBOL],
      'text-field',
      vif.getPaintPropertyForCharm(renderOptions)
    );
    this._map.setLayoutProperty(
      this.layerIds[LAYERS.POINTS_SYMBOL],
      'text-size',
      vif.getCharmSizePaintProperty(
        renderOptions.resizeByRange,
        renderOptions.aggregateAndResizeBy
      )
    );
    this._map.setPaintProperty(
      this.layerIds[LAYERS.POINTS_SYMBOL],
      'text-opacity',
      vif.getPointOpacity()
    );
  }

  renderFeatureInSpiderLeg = (spiderLegOptions) => {
    const { spiderLeg, renderOptions, vif } = spiderLegOptions;
    const basemapStyle = getBasemapStyle(vif);
    const darkAndSatelliteStyle = [VECTOR_BASEMAP_STYLES.dark.value, VECTOR_BASEMAP_STYLES.satellite.value];

    const $spiderPointDiv = $('<div>', { class: 'spider-point-circle' });
    const spiderFeatureDiameter = getFeatureDiameter({
      featureProperties: spiderLeg.feature,
      resizeByRange: renderOptions.resizeByRange,
      aggregateAndResizeBy: renderOptions.aggregateAndResizeBy,
      vif
    });
    const spiderFeatureOptions = getSpiderFeatureOptions(spiderLeg.feature, renderOptions, vif);
    if (_.includes(darkAndSatelliteStyle, basemapStyle)) {
      $(spiderLeg.elements.line).addClass('dark-and-satellite-style');
    }

    $($spiderPointDiv).
      on('mouseover', function() {
        $(this).css('box-shadow', `0 0 0 2px ${HIGHLIGHT.COLOR}`);
      }).
      on('mouseleave', function() {
        $(this).css({ 'box-shadow': '0 0 0 0' });
      });

    $(spiderLeg.elements.pin).append($spiderPointDiv);

    if (vif.isCharmsLayerVisible() && spiderFeatureOptions.charmName) {
      const $charmIcon = $('<img>', {
        class: 'charm-icon',
        src: getCharmSvgSrc(spiderFeatureOptions.charmName)
      });

      const iconDimension = (spiderFeatureDiameter / 2) * RADIUS_TO_CHARM_IMAGE_SIZE;
      const iconMargin = (spiderFeatureDiameter - iconDimension) / 2;
      $($charmIcon).css({
        'height': `${iconDimension}px`,
        'top': `${iconMargin}px`
      });

      $($spiderPointDiv).append($charmIcon);
    }

    $spiderPointDiv.css({
      'width': `${spiderFeatureDiameter}px`,
      'height': `${spiderFeatureDiameter}px`,
      'margin-left': `-${spiderFeatureDiameter / 2}px`,
      'margin-top': `-${spiderFeatureDiameter / 2}px`,
      'background-color': _.get(spiderFeatureOptions, 'color', VIF_CONSTANTS.FEATURE_COLOR),
      'opacity': vif.getPointOpacity()
    });
  };

  hidePointsWithId = (layerId, renderOptions, rowIds) => {
    const { countBy, idBy } = renderOptions;

    this._map.setFilter(
      layerId,
      ['all',
        ['!in', idBy, ...rowIds],
        ['!has', 'point_count'],
        ['in', countBy, 1, '1']
      ]
    );
  };

  unhidePoints = (layerId, countBy) => {
    this._map.setFilter(
      layerId,
      ['all',
        ['!has', 'point_count'],
        ['in', countBy, 1, '1']
      ]
    );
  };

  sourceOptions(vif, renderOptions) {
    let options = {
      'type': 'vector',
      'geojsonTile': true,
      'clusterRadius': vif.getStackRadius(),
      'clusterProperties': getClusterProperties(renderOptions),
      'tiles': [renderOptions.dataUrl]
    };

    if (vif.isMultiPointColumn(renderOptions.datasetMetadata)) {
      options = _.merge({}, options, { cluster: false , minzoom: VIF_CONSTANTS.MULTI_POINT_MIN_ZOOM_LEVEL });
    } else {
      options = _.merge({}, options, { cluster: true , minzoom: vif.getMaxClusteringZoomLevel() });
    }

    return options;
  }

  destroy() {
    _.each(this.layerIds, (layerId) => {
      this._map.removeLayer(layerId);
    });
    _.each(this.sourceIds, (sourceId) => {
      this._map.removeSource(sourceId);
    });
  }
}

function getPointAndSymbolFilter(countBy, isMultiPointColumn = false) {
  const pointAndSymbolFilter = ['all', ['!has', 'point_count']];

  if (!isMultiPointColumn) {
    pointAndSymbolFilter.push(['in', countBy, 1, '1']);
  }

  return pointAndSymbolFilter;
}

export function getClusterProperties(renderOptions) {
  return _.chain([renderOptions.aggregateAndResizeBy, renderOptions.countBy]).
    uniq().
    map((propertyToAggregate) => {
      return [
        propertyToAggregate,
        ['+', ['to-number', ['get', propertyToAggregate]]]
      ];
    }).
    fromPairs().
    value();
}

// If it is a cluster, it would have already converted the countBy value to number based on
// the expression in `clusterProperties`. Otherwise the reszieby value is returned in json
// as a string. We are converting it into number before using it.
export function getPointCountAbbreviationExpression(countBy) {
  return [
    'let',
    'i',
    [
      'case',
      ['has', 'point_count'],
      ['get', countBy],
      ['to-number', ['get', countBy]]
    ],
    [
      'case',
      // Condition 1
      ['>=', ['var', 'i'], 10 * 1000],
      // Value 1:
      [
        'concat',
        ['round', ['/', ['var', 'i'], 1000]],
        'k'
      ],
      // Condition:
      ['>=', ['var', 'i'], 1000],
      // value:
      [
        'concat',
        ['/', ['round', ['/', ['var', 'i'], 100]], 10],
        'k'
      ],
      // Default
      [ 'concat', ['var', 'i'], '']
    ]
  ];
}

// Returns
// {
//    color: '#ffffff',
//    charmName: 'wheelchair'
// }
function getSpiderFeatureOptions(feature, renderOptions, vif) {
  const { colorByBuckets, colorBy } = renderOptions;

  if (_.isNil(colorByBuckets)) {
    return {
      color: _.get(vif, 'series[0].color.primary', VIF_CONSTANTS.FEATURE_COLOR),
      charmName: _.get(vif, 'series[0].mapOptions.charmName')
    };
  }

  const defaultBucket = colorByBuckets[colorByBuckets.length - 1];
  const bucketValue = _.get(feature, colorBy, NULL_CATEGORY_VALUE);

  if (vif.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value) {
    return getRangeBucket({
      buckets: colorByBuckets,
      value: bucketValue,
      bucketCount: vif.getColorByBucketsCount()
    });
  } else {
    const categoryToBucketMap = _.keyBy(colorByBuckets, (colorForCategory) =>
      _.get(colorForCategory, 'id', colorForCategory.label)
    );

    return _.get(categoryToBucketMap, bucketValue, defaultBucket);
  }
}

export function getFeatureDiameter({ featureProperties, resizeByRange, aggregateAndResizeBy, vif }) {
  if (!_.isString(vif.getResizePointsByColumn())) {
    let pointSize = _.get(vif, 'series[0].mapOptions.pointSize', VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT);
    if (vif.isCharmsLayerVisible()) {
      pointSize = pointSize < VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT ?
        VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT : pointSize;
    }
    return pointSize;
  }

  const { minValue, maxValue, dataClasses } = vif.getResizeByRangeOptions();
  const featureValue = _.get(featureProperties, aggregateAndResizeBy, null);

  const rangeOptions = {
    dataClasses,
    featureValue,
    maxValue,
    minValue,
    resizeByRange
  };

  return getLinearBucketValueForFeature(rangeOptions);
}
