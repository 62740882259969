import { isEmpty } from 'lodash';
import {
  FunCall, isFunCall,
  isStringLiteral, isNumberLiteral,
  Expr,
  WindowFunctionInfo
} from 'common/types/soql';
import { parens } from '../util';

const specialFunctions = {
  operator: /^op\$(?<op>.*)/,
  arithmeticOperator: /^(?<op>.)[NDM]{2}$/,
  binaryPlus: /^(?<op>\+)$/,
  binaryMinus: /^(?<op>\-)$/,
  unaryPlusMinus: /^unary (?<op>[+-])$/,
};
const isOperator = (function_name: string | undefined): boolean => !!function_name && specialFunctions.operator.test(function_name);
const isArithmeticOperator = (function_name: string | undefined): boolean => !!function_name && specialFunctions.arithmeticOperator.test(function_name);
const isBinaryPlus = (function_name: string | undefined): boolean => !!function_name && specialFunctions.binaryPlus.test(function_name);
const isBinaryMinus = (function_name: string | undefined): boolean => !!function_name && specialFunctions.binaryMinus.test(function_name);
const isUnaryPlusMinus = (function_name: string | undefined): boolean => !!function_name && specialFunctions.unaryPlusMinus.test(function_name);
const isCast = (function_name: string | undefined): boolean => !!function_name && function_name.startsWith('cast$');
const isSubscript = (function_name: string | undefined): boolean => !!function_name && function_name === 'op$[]';
const isField = (function_name: string): boolean => function_name in fields;

const isAnyOperator = (function_name: string): boolean => isOperator(function_name) || isCast(function_name) || isArithmeticOperator(function_name) || isBinaryPlus(function_name) || isBinaryMinus(function_name) || isUnaryPlusMinus(function_name);

import { renderExpr } from '../expr';
import { renderOrderBy } from '../orderBy';
import { fields, operators } from './index';

const parensIfOperator = (expr: Expr): string => {
  const rendered = renderExpr(expr);
  if (isFunCall(expr) && isOperator(expr.function_name)) {
    return parens(rendered);
  }
  return rendered;
};

const renderParentheticalFunction = (expr: FunCall): string => [
  expr.function_name,
  parens(expr.args.map(renderExpr).join(', ')),
].join('');

const renderWindowFunctionInfo = (info: WindowFunctionInfo): string => {
  const parts = [];
  if (!isEmpty(info.partitions)) {
    parts.push(`PARTITION BY ${info.partitions.map(renderExpr).join(', ')}`);
  }
  if (!isEmpty(info.orderings)) {
    parts.push(`ORDER BY ${info.orderings.map(renderOrderBy).join(', ')}`);
  }
  info.frames.forEach((frame) => {
    if (isStringLiteral(frame) || isNumberLiteral(frame)) {
      parts.push(` ${renderExpr(frame)}`);
    }
  });
  return parts.join(' ');
};

const renderWindowFunction = (expr: FunCall): string => {
  const operand = parensIfOperator(expr.args[0]);
  return `${operand} OVER(${renderWindowFunctionInfo(expr.window!)})`;
};

const extractOperatorFromFunctionName = (function_name: string): string => {
  if (isSubscript(function_name)) {
    return '.';
  } else if (isOperator(function_name)) {
    return specialFunctions.operator.exec(function_name)!.groups!.op;
  } else if (isArithmeticOperator(function_name)) {
    return specialFunctions.arithmeticOperator.exec(function_name)!.groups!.op;
  } else if (isBinaryPlus(function_name)) {
    return specialFunctions.binaryPlus.exec(function_name)!.groups!.op;
  } else if (isBinaryMinus(function_name)) {
    return specialFunctions.binaryMinus.exec(function_name)!.groups!.op;
  } else if (isUnaryPlusMinus(function_name)) {
    return specialFunctions.unaryPlusMinus.exec(function_name)!.groups!.op;
  } else {
    throw new Error(`Attempted to extract unrecognized operator type from ${function_name}.`);
  }
};
const renderOperator = (expr: FunCall): string => {
  if (isCast(expr.function_name)) {
    const [_, to] = expr.function_name.split('$');
    return `(${parensIfOperator(expr.args[0])}::${to})`;
  }

  if (isSubscript(expr.function_name)) {
    const [arg, literal] = expr.args;
    if (isStringLiteral(literal)) {
      return `${parensIfOperator(arg)}.${literal.value}`;
    } else {
      throw new Error(`${JSON.stringify(literal)} must be a string literal`);
    }
  }

  const op = extractOperatorFromFunctionName(expr.function_name);
  if (expr.args.length === 1) {
    if (expr.function_name === 'op$NOT') {
      return `NOT ${parensIfOperator(expr.args[0])}`;
    } else {
      return `${op}${parensIfOperator(expr.args[0])}`;
    }
  } else if (expr.args.length === 2) {
    return `${parensIfOperator(expr.args[0])} ${op} ${parensIfOperator(expr.args[1])}`;
  } else {
    throw new Error(`Found a non-unary, non-binary operator: ${op}`);
  }
};

const renderBetween = (expr: FunCall): string => {
  const args = expr.args.map(parensIfOperator);
  return `${args[0]} BETWEEN ${args[1]} AND ${args[2]}`;
};

const renderNotBetween = (expr: FunCall): string => {
  const args = expr.args.map(parensIfOperator);
  return `${args[0]} NOT BETWEEN ${args[1]} AND ${args[2]}`;
};

const renderIsNull = (expr: FunCall): string => `${parensIfOperator(expr.args[0])} IS NULL`;
const renderIsNotNull = (expr: FunCall): string => `${parensIfOperator(expr.args[0])} IS NOT NULL`;

const renderIn = (expr: FunCall): string => {
  const args = expr.args.map(parensIfOperator);
  return `${args[0]} IN (${args.slice(1).join(',')})`;
};
const renderNotIn = (expr: FunCall): string => {
  const args = expr.args.map(parensIfOperator);
  return `${args[0]} NOT IN (${args.slice(1).join(',')})`;
};

const renderLike = (expr: FunCall): string => {
  const args = expr.args.map(parensIfOperator);
  return `${args[0]} LIKE ${args[1]}`;
};
const renderNotLike = (expr: FunCall): string => {
  const args = expr.args.map(parensIfOperator);
  return `${args[0]} NOT LIKE ${args[1]}`;
};

const renderField = (expr: FunCall): string => {
  const property = fields[expr.function_name];
  return `${parensIfOperator(expr.args[0])}.${property}`;
};

export const renderStrategyFor = (name: string) => {
  if (isAnyOperator(name)) {
    return renderOperator;
  } else if (isField(name)) {
    return renderField;
  } else {
    switch (name) {
      case '#BETWEEN': return renderBetween;
      case '#NOT_BETWEEN': return renderNotBetween;
      case '#IS_NULL': return renderIsNull;
      case '#IS_NOT_NULL': return renderIsNotNull;
      case '#IN': return renderIn;
      case '#NOT_IN': return renderNotIn;
      case '#LIKE': return renderLike;
      case '#NOT_LIKE': return renderNotLike;
      case '#WF_OVER': return renderWindowFunction;
      default: return renderParentheticalFunction;
    }
  }
};
