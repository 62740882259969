import { useSelector } from 'react-redux';

import { selectors as dataSourceSelectors } from 'store/selectors/DataSourceSelectors';
import { filtersAreEquivalent } from 'lib/FilterUtils';

import { Measure } from 'common/performance_measures/types';
import FeatureFlags from 'common/feature_flags';
import { GlobalFilters, GlobalParameterOverrides } from 'types';
import { applyAdditionalFiltersToMeasure } from 'common/performance_measures/measureCalculator/helpers';
import { isEqual } from 'lodash';

const useMeasureConfigWithGlobalFilterParameters = (measure: Measure | null): Measure | null => {
  const additionalFilters = useSelector(
    dataSourceSelectors.getGlobalFilters,
    (filters: GlobalFilters, newFilters: GlobalFilters) => {
      return (
        // This feature flag check prevents us from rerendering when global filters are changed but the flag is false
        !FeatureFlags.valueOrDefault('enable_global_filters_measures', false) ||
        filtersAreEquivalent(filters, newFilters)
      );
    }
  );

  const parameterOverrides = useSelector(
    dataSourceSelectors.getParameterOverrides,
    (overrides: GlobalParameterOverrides, newOverrides: GlobalParameterOverrides) => {
      return (
        // This feature flag check prevents us from rerendering when global filters are changed but the flag is false
        !FeatureFlags.valueOrDefault('enable_global_filters_measures', false) ||
        isEqual(overrides, newOverrides)
      );
    }
  );

  if (!measure || !FeatureFlags.valueOrDefault('enable_global_filters_measures', false)) {
    return measure;
  }

  const dataSourceUid = measure?.dataSourceLensUid ?? '';

  const measureWithAdditionalFilters = applyAdditionalFiltersToMeasure(
    measure,
    additionalFilters[dataSourceUid]
  );

  if (measureWithAdditionalFilters) {
    measureWithAdditionalFilters.metricConfig.parameterOverrides = parameterOverrides[dataSourceUid];
  }

  return measureWithAdditionalFilters;
};

export default useMeasureConfigWithGlobalFilterParameters;
