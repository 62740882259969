import _ from 'lodash';

import { configureStore } from '@reduxjs/toolkit';
import Store from 'Store';
import Actions from 'Actions';
import linkModalReducer, {
  LINK_MODAL_SLICE_NAME,
  openLinkModal,
  closeLinkModal,
  setLinkModalInputs,
  setLinkModalAccepted
} from '../../store/reducers/LinkModalSlice';

import {
  getLinkModalEditorId,
  getLinkModalVisibility,
  getLinkModalAccepted,
  getLinkModalInputs,
  getLinkModalValidity,
  getLinkModalURLValidity,
  shouldSelectLink,
  shouldInsertLink
} from '../../store/selectors/LinkModalSelectors';

const linkModalReduxStore = configureStore({
  reducer: {
    [LINK_MODAL_SLICE_NAME]: linkModalReducer
  }
});

export var linkModalStore = new LinkModalStore();
export default function LinkModalStore(dispatcher) {
  _.extend(this, new Store(dispatcher));

  var self = this;

  this.register(function (payload) {
    switch (payload.action) {
      case Actions.LINK_MODAL_OPEN:
        linkModalReduxStore.dispatch(openLinkModal(payload));
        self._emitChange();
        break;
      case Actions.LINK_MODAL_CLOSE:
        linkModalReduxStore.dispatch(closeLinkModal());
        self._emitChange();
        break;
      case Actions.LINK_MODAL_UPDATE:
        linkModalReduxStore.dispatch(setLinkModalInputs(payload));
        self._emitChange();
        break;
      case Actions.LINK_MODAL_ACCEPT:
        linkModalReduxStore.dispatch(setLinkModalAccepted(payload));
        self._emitChange();
        break;
      default:
        break;
    }
  });

  this.getEditorId = function () {
    return getLinkModalEditorId(linkModalReduxStore.getState());
  };

  this.getVisibility = function () {
    return getLinkModalVisibility(linkModalReduxStore.getState());
  };

  this.getAccepted = function () {
    return getLinkModalAccepted(linkModalReduxStore.getState());
  };

  this.getInputs = function () {
    return getLinkModalInputs(linkModalReduxStore.getState());
  };

  this.getValidity = function () {
    return getLinkModalValidity(linkModalReduxStore.getState());
  };

  this.getURLValidity = function () {
    return getLinkModalURLValidity(linkModalReduxStore.getState());
  };

  this.shouldSelectLink = function (targetEditorId) {
    return shouldSelectLink(linkModalReduxStore.getState(), targetEditorId);
  };

  this.shouldInsertLink = function (targetEditorId) {
    return shouldInsertLink(linkModalReduxStore.getState(), targetEditorId);
  };
}
