// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import I18n from 'common/i18n';
import Button, { SIZES, VARIANTS } from 'common/components/Button';
import { resetAllFiltersToDefaults } from 'common/components/FilterBar/lib/allFilters';
import { SoqlFilter } from './SoqlFilter';
import { FilterBarColumns } from './index';
import { FilterBarPendoIds } from './types';
import { FilterParameterConfiguration, FilterItem } from 'common/types/reportFilters';

// Constants
const scope = 'shared.components.filter_bar';

export interface ResetFiltersButtonProps {
  filterParameterConfigurations?: FilterParameterConfiguration[];
  columns: FilterBarColumns;
  isReadOnly?: boolean;
  onReset: (updated: SoqlFilter[]) => void;
  onResetAll: (updated: FilterParameterConfiguration[]) => void;
  pendoIds?: FilterBarPendoIds;
}

export class ResetFiltersButton extends Component<ResetFiltersButtonProps> {
  onClick = () => {
    const { filterParameterConfigurations, columns, isReadOnly, onResetAll, onReset } = this.props;
    if (filterParameterConfigurations) {
      const resetFilters = resetAllFiltersToDefaults(filterParameterConfigurations, columns, isReadOnly);
      onResetAll(resetFilters);

      // This is needed for visualization & measures filters to be reset
      // with the onUpdate callback
      onReset(
        resetFilters.map((filter) => {
          return filter.config as SoqlFilter;
        })
      );
    }
  };

  isDisabled = () => {
    const { filterParameterConfigurations, isReadOnly } = this.props;
    const onlyFilters = filterParameterConfigurations?.filter(
      (filter) => filter.type === 'filter'
    ) as FilterItem[];
    return !_.some(onlyFilters, (item) => {
      const isViewable = !isReadOnly || !item.config.isHidden;
      const hasValue = !_.isEmpty(item.config.arguments);
      const canValueBeChanged = !item.config.isOverridden;

      return isViewable && hasValue && canValueBeChanged;
    });
  };

  render() {
    const { pendoIds } = this.props;
    return (
      <div
        className="reset-filters"
        id={pendoIds?.resetAllFilters}
        data-testid="filter-bar-reset-all-filters"
      >
        <Button
          variant={VARIANTS.LINK}
          size={SIZES.SMALL}
          inverse
          className="btn-reset-filters"
          disabled={this.isDisabled()}
          onClick={this.onClick}
        >
          {I18n.t('clear_all_filters', { scope })}
        </Button>
      </div>
    );
  }
}

export default ResetFiltersButton;
