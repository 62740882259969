/* Imports */
/* ============================================================================= */
import { WorkflowGuidance } from 'common/types/approvals';

import { getSubmissionActionObject } from 'common/core/approvals/guidanceHelpers';





/* Method */
/* ============================================================================= */
/**
 * Returns whether or not an asset can be submitted _in some way_ to approvals for the provided WorkflowGuidance object.
 *
 * See the SUBMISSION_KEYS array for possible parts of the guidance summary that can be considered "submissions".
 * To check for specific submission availabilities, see `canSubmitChangeAudience` and `canSubmitUpdatePublished`
 *
 * @param guidance WorkflowGuidance object
 * @returns boolean
 */
export const canSubmitV2 = (guidance: WorkflowGuidance) => {
  return !!getSubmissionActionObject(guidance);
};

export default canSubmitV2;
