import _ from 'lodash';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import Environment from '../../StorytellerEnvironment';
import { exceptionNotifier } from '../../services/ExceptionNotifier';
import { createSlice } from '@reduxjs/toolkit';

export const DOWNTIME_MOMENT_FORMAT_STRING = 'YYYY-MM-DD[t]HH:mmZ';

interface Downtime {
  message_start: string; // timestamp of when the maintenance banner will start showing
  message_end: string; // timestamp of when the maintenance banner will stop showing
  downtime_start: string; // timestamp that will render on the maintenance banner as the start of the downtime
  downtime_end: string; // timestamp that will render on the maintenance banner as the end of the downtime
}

// Keeps cookie in sync with frontend cookie without overwriting other values
function getCookieConverter() {

  return Cookies.withConverter({
    read: (value: any, name: string) => {
      if (name === 'maintenance_ack') {
        return JSON.parse(unescape(value));
      }
    },
    write: (value: any, name: string) => {
      if (name === 'maintenance_ack') {
        return escape(value);
      } else {
        return '';
      }
    }
  });
}

function getCookieJSON() {
  return Cookies.get('maintenance_ack');
}

function downtimeToAck(message_start: string) {
  return moment(message_start, DOWNTIME_MOMENT_FORMAT_STRING).unix();
}

function isDowntimeOpen({ message_start, message_end }: { message_start: string, message_end: string }) {
  const messageStart = moment(message_start, DOWNTIME_MOMENT_FORMAT_STRING);
  const messageEnd = moment(message_end, DOWNTIME_MOMENT_FORMAT_STRING);
  return moment().isBetween(messageStart, messageEnd);
}

//Acknowledges the downtime and adds it to the Cookie, then returns the state of the cookie
function _acknowledge(downtime: Downtime) {
  if (!downtime) {
    exceptionNotifier.notify(new Error('Did not specify a downtime to acknowledge'));
    return '';
  }
  const cookies = getCookieConverter();
  const message_start = downtime.message_start;

  const acknowledgements = cookies.get('maintenance_ack') || [];
  cookies.set('maintenance_ack', acknowledgements.concat(downtimeToAck(message_start)));

  return getCookieJSON();
}

const downtimeSlice = createSlice({
  name: 'downtimeSlice',
  initialState: {
    cookies: getCookieJSON()
  },
  reducers: {
    resetCookie(state) {
      const cookieConverter = getCookieConverter();
      cookieConverter.set('maintenance_ack', {});
      state.cookies = getCookieJSON();
    },
    downtimeAcknowledge(state, action) {
      const { payload } = action;
      state.cookies = _acknowledge(payload);
    }
  }
});

export const getUnacknowledgedDowntimes = () => {
  const knownDowntimes = Environment.DOWNTIMES;
  const cookies = getCookieConverter();
  const acknowledgements = cookies.get('maintenance_ack') || [];
  return _.reject(knownDowntimes, (downtime: Downtime) => {
    return _.includes(acknowledgements, downtimeToAck(downtime.message_start)) || !isDowntimeOpen(downtime);
  });
};

const { actions, reducer } = downtimeSlice;

export const { resetCookie, downtimeAcknowledge } = actions;
export default reducer;
