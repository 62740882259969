import React, { Component } from 'react';
import get from 'lodash/get';
import { ForgeButton } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import airbrake from 'common/airbrake';
import {
  showToastNow,
  showToastOnPageReload,
  ToastType
} from 'common/components/ToastNotification/Toastmaster';
import { reload as windowLocationReload } from 'common/window_location';

interface SaveViewButtonState {
  disabled: boolean;
  busy: boolean;
}

class SaveViewButton extends Component<any, SaveViewButtonState> {
  static getGridViewDataset = () => {
    const gridViewDataset = get(window, 'blist.dataset');

    if (!gridViewDataset) {
      throw new Error('SaveViewButton exists but no window.blist.dataset is available');
    }

    return gridViewDataset;
  };

  state = {
    disabled: true,
    busy: false
  };

  componentDidMount() {
    // these events will get sent whenever dataset.js decides things have changed
    const gridViewDataset = SaveViewButton.getGridViewDataset();
    gridViewDataset.bind('query_change', this.setEnabled);
    gridViewDataset.bind('query_string_change', this.setEnabled);
    gridViewDataset.bind('columns_changed', this.setEnabled);
    gridViewDataset.bind('row_change', this.setEnabled);
  }

  componentWillUnmount() {
    const gridViewDataset = SaveViewButton.getGridViewDataset();
    gridViewDataset.unbind('query_change', this.setEnabled);
    gridViewDataset.unbind('query_string_change', this.setEnabled);
    gridViewDataset.unbind('columns_changed', this.setEnabled);
    gridViewDataset.unbind('row_change', this.setEnabled);
  }

  onViewSaveSuccess = () => {
    showToastOnPageReload({
      type: ToastType.SUCCESS,
      content: I18n.t('shared.components.asset_action_bar.save_success')
    });

    // just reload the window, because grid view is cool and likes to be reloaded a lot
    windowLocationReload();
  };

  onViewSaveError = (error: any) => {
    showToastNow({
      type: ToastType.ERROR,
      content: I18n.t('shared.components.asset_action_bar.save_failed')
    });
    airbrake.notify({
      error: error,
      context: { component: 'SaveViewButton#onClick' }
    });

    // make button enabled so they can try again if they so please
    this.setState({ busy: false, disabled: false });
  };

  onClick = () => {
    const { disabled, busy } = this.state;

    if (!disabled && !busy) {
      this.setState({ busy: true });
      SaveViewButton.getGridViewDataset().saveView(this.onViewSaveSuccess, this.onViewSaveError);
    }
  };

  setEnabled = () => {
    this.setState({ disabled: false });
  };

  render() {
    const { disabled, busy } = this.state;

    return (
      <ForgeButton type="raised" onClick={this.onClick}>
        <button type="button" disabled={disabled || busy} className="edit-button primary-action-button">
          {I18n.t('shared.components.asset_action_bar.save')}
        </button>
      </ForgeButton>
    );
  }
}

export default SaveViewButton;
