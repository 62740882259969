import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import { Vif } from 'common/visualizations/vif';
import MetadataTitle from 'common/visualizations/views/BaseVisualization/MetadataTitle';
import { BaseVisualization } from './types';
import { isTableVisualization, newVizCardLayoutEnabled } from 'common/visualizations/helpers/VifSelectors';

/**
 * The title and description for the chart.
 */

export const template = (): JQuery => $('<div>', { 'class': 'socrata-visualization-metadata-container' }).
  append([
    $('<div>', { 'class': 'socrata-visualization-title-container' }).
      append(
        $('<div>', { 'class': 'socrata-visualization-title' })
      ),
    $('<div>', { 'class': 'socrata-visualization-description-container' }).
      append(
        $('<div>', { 'class': 'socrata-visualization-description' })
      )
  ]);

export const onUpdateVif = ($container: JQuery, vif: Vif, self: BaseVisualization) => {
  renderTitle($container, vif, self);
  renderDescription($container, vif, self);
  triggerTableResizeIfTableViz(self);
};

const renderTitle = ($container: JQuery, vif: Vif, self: BaseVisualization) => {
  const $title = $container.find('.socrata-visualization-title');
  const titleText = _.get(vif, 'title', null);

  if (titleText) {
    $title.
      removeClass('no-text').
      attr('data-full-text', titleText).
      text(titleText);
    $container.addClass('socrata-visualization-title');
  } else {
    $title.
      addClass('no-text').
      removeAttr('data-full-text').
      text('');
    $container.removeClass('socrata-visualization-title');
  }
};


const renderDescription = ($container: JQuery, vif: Vif, self: BaseVisualization) => {
  const $description = $container.find('.socrata-visualization-description');
  const descriptionText = _.get(vif, 'description', null);

  if (descriptionText) {

    $description.
      attr('data-full-text', descriptionText).
      text(descriptionText);
    $container.addClass('socrata-visualization-description');
  } else {

    $description.
      removeAttr('data-full-text').
      text('');
    $container.removeClass('socrata-visualization-description');
  }
};

export const attachEvents = (self: BaseVisualization) => {
  const $description = self.$element.find('.socrata-visualization-description');
  const $title = self.$element.find('.socrata-visualization-title');

  $title.on('mouseover', self.showFlyout);
  $title.on('mouseout', self.hideFlyout);

  $description.on('mouseover', self.showFlyout);
  $description.on('mouseout', self.hideFlyout);
};

export const detachEvents = (self: BaseVisualization) => {
  const $description = self.$element.find('.socrata-visualization-description');
  const $title = self.$element.find('.socrata-visualization-title');

  $title.on('mouseover', self.showFlyout);
  $title.on('mouseout', self.hideFlyout);

  $description.on('mouseover', self.showFlyout);
  $description.on('mouseout', self.hideFlyout);
};

const triggerTableResizeIfTableViz = (self: BaseVisualization) => {
  if (isTableVisualization(self.getVif())) {
    // resize the table and recalculate the number of visible rows
    self.$container.trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
  }
};
