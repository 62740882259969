import React, { FunctionComponent } from 'react';

export enum AlertType {
  Info = 'info',
  Notice = 'notice',
  Warning = 'warning',
  Error = 'error',
  Success = 'success'
}

interface AlertProps {
  /** Whether to render the error message or not, defaults to true */
  show?: boolean;
  /**
   * Alert types available.
   * @see platform-ui/common/styleguide/partials/_alerts.scss
   */
  type: AlertType;
}

type Props = AlertProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * An inline accessible Alert box that wraps around the message element
 * @example
 *  <Alert type={AlertType.Error} show={hasError}>
 *    {this.getErrorMessage(error)}
 *  </Alert>
 */
const Alert: FunctionComponent<Props> = ({ show = true, type, className, ...rest }) => {
  if (!show) {
    return null;
  }

  return <div role="alert" className={`alert ${type}${className ? ` ${className}` : ''}`} {...rest} />;
};

export default Alert;
