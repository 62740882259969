import $ from 'jquery';
import _ from 'lodash';

import Store from 'Store';
import assertIsOneOfTypes from 'common/assertions/assertIsOneOfTypes.js';
import Constants from 'lib/Constants';
import Dispatcher from '/Dispatcher';

export type WindowSizeClasses = {
  xlarge: boolean;
  large: boolean;
  medium: boolean;
  small: boolean;
};

/**
 * @function getAllWindowSizeClasses
 * @description
 * Returns all of the class breaks that are available to window.
 * This includes:
 * - small
 * - medium
 * - large
 * - xlarge
 * @returns {WindowSizeClasses} - All class breaks defined in _computeWindowSizeClasses.
 */
export const getAllWindowSizeClasses = (lastWindowSizeClasses: WindowSizeClasses): WindowSizeClasses => {
  return lastWindowSizeClasses || computeWindowSizeClasses(window.innerWidth);
};

/**
 * @function getWindowSizeClass
 * @description
 * When the window is resized, we have specific breaks that
 * choose a class that is relevant for that size of screen, such as
 * small for devices smaller than 768px.
 *
 * This function returns the current, relevant class break name.
 *
 * @returns {String} - The current window size class break.
 */
export const getWindowSizeClass = (lastWindowSizeClasses: WindowSizeClasses): string => {
  return _.findKey(getAllWindowSizeClasses(lastWindowSizeClasses), (sizeClass) => !!sizeClass) as string;
};

/**
 * @function getUnusedWindowSizeClasses
 * @description
 * This is the complement of getWindowSizeClass. In other words, it returns
 * all classes that are NOT currently relevant to the window size.
 *
 * @returns {Array} - An array of class break names.
 */
export const getUnusedWindowSizeClasses = (lastWindowSizeClasses: WindowSizeClasses): string[] => {
  return _.keys(
    _.omit(getAllWindowSizeClasses(lastWindowSizeClasses), getWindowSizeClass(lastWindowSizeClasses))
  );
};

/**
 * Private methods
 */

const computeWindowSizeClasses = (windowSize: number): WindowSizeClasses => {
  assertIsOneOfTypes(windowSize, 'number');

  return {
    xlarge: windowSize >= Constants.WINDOW_SIZE_BREAK_XLARGE,
    large: windowSize < Constants.WINDOW_SIZE_BREAK_XLARGE && windowSize >= Constants.WINDOW_SIZE_BREAK_LARGE,
    medium:
      windowSize < Constants.WINDOW_SIZE_BREAK_LARGE && windowSize >= Constants.WINDOW_SIZE_BREAK_MEDIUM,
    small: windowSize < Constants.WINDOW_SIZE_BREAK_MEDIUM
  };
};

const haveWindowSizeClassesChanged = (
  newClasses: WindowSizeClasses,
  oldClasses?: WindowSizeClasses
): boolean => {
  if (!oldClasses) {
    return true;
  }

  return _.some(newClasses, (isEnabled, className) => {
    return oldClasses[className as keyof WindowSizeClasses] !== isEnabled;
  });
};

export const handleResize = (
  lastWindowSizeClasses: WindowSizeClasses,
  event: JQuery.ResizeEvent<Window, any, Window, Window>
): WindowSizeClasses => {
  if (event.target !== null) {
    const target = event.target as Window;
    const width = target.innerWidth;
    const sizeClasses = computeWindowSizeClasses(width);

    if (haveWindowSizeClassesChanged(sizeClasses, lastWindowSizeClasses)) {
      lastWindowSizeClasses = sizeClasses;
    }
  }
  return lastWindowSizeClasses;
};
