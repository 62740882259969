import get from 'lodash/get';

import type { GuidanceSummaryV2 } from 'common/types/approvals';

import FeatureFlags from 'common/feature_flags';
import { View, ViewRight } from 'common/types/view';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import { getCurrentUser } from 'common/current_user';
import { AssetStatus } from 'common/views/asset_status';
import { hasRights, hasOneOfRights } from 'common/views/has_rights';

import assetIsUnsupportedByAssetActionBar from './asset_is_unsupported';

/**
 * What the primary action (button) should be.
 */
export enum PrimaryAction {
  /** There is no primary action and the button will not be rendered */
  NONE = 'NONE',

  /** Link to a list of revision changes on DSMP. */
  SHOW_DSMP_EDITS = 'SHOW_DSMP_EDITS',

  /** Link to Primer page. */
  SHOW_PRIMER = 'SHOW_PRIMER',

  /**
   * Let the app render whatever it thinks is the primary action.
   * Note that sometimes A2B will refuse to delegate even if the app
   * supports it (the conditions are too many to enumerate here).
   */
  DELEGATE_BUTTON_TO_APP = 'DELEGATE_BUTTON_TO_APP',

  /** A draft is open, user can submit it or continue editing */
  SUBMIT_TO_APPROVAL_OR_EDIT_DRAFT = 'SUBMIT_TO_APPROVAL_OR_EDIT_DRAFT',

  /** The built-in EditButton should be shown (no open draft yet). */
  GENERIC_EDIT_BUTTON = 'GENERIC_EDIT_BUTTON',

  /** A draft is open, user can submit it or continue editing. */
  PUBLISH_OR_EDIT_DRAFT = 'PUBLISH_OR_EDIT_DRAFT',

  /** A draft is open, the user can edit it but not publish it. */
  EDIT_DRAFT = 'EDIT_DRAFT'
}

/** Encapsulates information needed to determine what primary action a user can take */
export interface GetPrimaryActionArgs {
  /**
   * Some apps know how to render their own publication buttons. These should pass true here.
   */
  appProvidesOwnPrimaryButton: boolean;

  /** The view's status; i.e. published, unpublished, or pending approval */
  assetStatus: AssetStatus;

  /** Guidance summary for the view */
  approvalsGuidance: GuidanceSummaryV2;

  /** The view to get actions for */
  view: View;

  /** DSLP sometimes wants to override pretty much everything. */
  showDSLPButton?: boolean;
}

/**
 * The primary action on the AssetActionBar (AAB) is a big, huge button on the bar.
 *
 * There will only ever be one primary action. This function determines which primary action to show.
 */
export const getPrimaryAction = ({
  appProvidesOwnPrimaryButton,
  assetStatus,
  approvalsGuidance,
  view,
  showDSLPButton
}: GetPrimaryActionArgs): PrimaryAction => {
  if (!approvalsGuidance || assetIsUnsupportedByAssetActionBar(view)) {
    return PrimaryAction.NONE;
  }

  const viewerOnly =
    get(window as any, 'initialState.view.hasViewerGrant', false) &&
    !hasOneOfRights(view, ViewRight.Write, ViewRight.UpdateView);

  const openRevisions = get(window as any, 'initialState.view.openRevisions', []);

  if (viewerOnly) {
    if (openRevisions.length > 0) {
      return PrimaryAction.SHOW_DSMP_EDITS;
    } else {
      return PrimaryAction.NONE;
    }
  }

  if (showDSLPButton) {
    return PrimaryAction.SHOW_PRIMER;
  }

  if ((view.rights || []).length === 0) {
    return PrimaryAction.NONE;
  }

  const strictPermissions = FeatureFlags.value('strict_permissions');
  const isOwner = get(view, 'owner.id') === get(getCurrentUser(), 'id');
  const canPublishAsset = hasRights(view, ViewRight.Grant) || (!strictPermissions && isOwner);
  const isDraft = assetStatus === AssetStatus.Draft;
  // EN66455 FOLLOW UP: Verify that `canSubmitUpdatePublishedAssetRequest` is the correct check
  const isDraftAndRequiresApproval =
    isDraft && withGuidanceV2(approvalsGuidance).canSubmitUpdatePublishedAssetRequest();

  const shouldRenderEditButton =
    hasOneOfRights(view, ViewRight.Write, ViewRight.UpdateView) &&
    [AssetStatus.Published, AssetStatus.Pending].includes(assetStatus);

  if (canPublishAsset && appProvidesOwnPrimaryButton) {
    return PrimaryAction.DELEGATE_BUTTON_TO_APP;
  } else if (canPublishAsset && isDraftAndRequiresApproval) {
    return PrimaryAction.SUBMIT_TO_APPROVAL_OR_EDIT_DRAFT;
  } else if (shouldRenderEditButton) {
    return PrimaryAction.GENERIC_EDIT_BUTTON;
  } else if (hasRights(view, ViewRight.UpdateView) && isDraft) {
    if (canPublishAsset) {
      return PrimaryAction.PUBLISH_OR_EDIT_DRAFT;
    } else {
      return PrimaryAction.EDIT_DRAFT;
    }
  }

  return PrimaryAction.NONE;
};
