/** The user's "segment" (site member, community member, etc.) */
enum UserSegment {
  /**
   * The user is a service account (and by extension a superadmin)
   * This generally means that the "user" is a Socrata-internal application.
   */
  ServiceAccount = 'service_account',

  /** The user is a super-admin and NOT a service account (an employee) */
  SuperAdmin = 'superadmin',

  /** The user has a role on the domain */
  SiteMember = 'site_member',

  /** The user is logged in but does NOT have a role on the domain */
  CommunityMember = 'community_member',

  /** An actual community user */
  CommunityUser = 'community_user'
}

export default UserSegment;
