import { assert, assertIsOneOfTypes } from 'common/assertions';

const MOUSE_WHEEL_EVENTS = 'mousewheel DOMMouseScroll MozMousePixelScroll';

/**
 * Controls page scrolling behavior when inside the given element.
 * If enable is true, isolates scrolling to given element when inside by preventing
 * scrolling from bubbling up to the document. (If outside element, page scrolling
 * behaves as usual).
 * If set to false, disables scrolling isolation, and re-enables page scrolling.
 *
 * @param {jQuery wrapped DOM || Element} $element - the element on which to isolate scrolling behavior
 * @param {boolean} enable - whether to isolate scrolling to element and prevent page scrolling
 */
export default function isolateScrolling($element, enable) {
  let hasPreventPageScrolling;
  let needToRegister;
  let needToUnregister;
  let element;

  assertIsOneOfTypes(enable, 'boolean');
  assert($element, '`element` must exist');
  if ($element.jquery) {
    assert($element.length === 1, '`element` selection must have length 1');
    element = $element[0];
  } else if ($element instanceof Element) {
    element = $element;
  } else {
    throw new Error('`element` must be a jQuery wrapped DOM element, or a plain DOM element');
  }

  hasPreventPageScrolling = element.hasOwnProperty('preventPageScrolling');

  needToRegister = enable && !hasPreventPageScrolling;
  needToUnregister = !enable && hasPreventPageScrolling;

  if (needToRegister) {
    // Helper to prevent page scrolling when inside the given element
    element.preventPageScrolling = function(e) {
      // Base prevention of page scrolling on scroll status of the given element
      const scrollingElement = element;
      const scrollTop = scrollingElement.scrollTop;

      // IE/Chrome/Safari use 'wheelDelta', Firefox uses 'detail'
      const scrollingUp = e.wheelDelta > 0 || e.detail < 0;

      if (scrollingUp) {
        const atTop = scrollTop === 0;
        if (atTop) {
          e.preventDefault();
        }
      } else {
        const innerHeight = scrollingElement.clientHeight;
        const scrollHeight = scrollingElement.scrollHeight;

        const atBottom = scrollTop >= scrollHeight - innerHeight;
        if (atBottom) {
          e.preventDefault();
        }
      }
    };

    MOUSE_WHEEL_EVENTS.split(' ').forEach((eventType) => {
      try {
        $element[0].addEventListener(eventType, $element[0].preventPageScrolling, false);
      } catch (ignored) {
        /* */
      }
    });
  } else if (needToUnregister) {
    MOUSE_WHEEL_EVENTS.split(' ').forEach((eventType) => {
      try {
        $element[0].removeEventListener(eventType, $element[0].preventPageScrolling);
      } catch (ignored) {
        /* */
      }
    });
    delete element.preventPageScrolling;
  }
}
