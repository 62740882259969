import React from 'react';
import PropTypes from 'prop-types';

/**
 * TableCell is a wrapper to allow for better visualizing the flow of props through the table, and to
 * reconcile a cell's data with the index into that data
 */
const TableCell = ({ children, cellComponent: Component, dataClassName, rowData, dataIndex, cellProps }) => (
  <td className={dataClassName}>{Component ? <Component {...rowData} {...cellProps} /> : children(rowData[dataIndex], rowData)}</td>
);

TableCell.propTypes = {
  /**
   * A function that takes the cell value and returns the nodes to render for the cell.
   * By default, will render the cell value directly.
   */
  children: PropTypes.func,
  /**
   * Class to apply to the td.
   */
  dataClassName: PropTypes.string,
  /**
   * The data for this cell.
   */
  rowData: PropTypes.any
};

export default TableCell;
