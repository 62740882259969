import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import cx from 'classnames';

import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import SelectedOptionPill from './SelectedOptionPill';
import MultiSelectInput from './MultiSelectInput';
import MultiSelectOptionList from './MultiSelectOptionList';
import SelectedOptionsCount from './SelectedOptionsCount';
import multiSelectKeyDownHandler from './MultiSelectKeyDownHandler';

import { BaseProps, InputProps, KeyDownHandlerProps, OptionListProps, OptionPillProps } from './types';

import './index.scss';

/**
 * Renders a list of selected options from given options.
 *
 * Note that (almost) all of the props for this component are required, and
 * everything is "controlled" and has to be passed in.
 *
 * Various callbacks will let you know when the component is changed.
 *
 * This component takes in two generic type parameters.
 * The first one is the type of the options that are listed,
 * and the second one is the type of what is being selected.
 *
 * For example, if you're choosing from a list of `Users` but you're selecting
 * a list of `strings`, then you would render this as...
 *
 * ```
 * <MultiSelect<User, string> {...multiSelectProps} />
 * ```
 *
 * You can also pass the same type into both type parameters if you want...
 *
 * ```
 * <MultiSelect<User, User> {...multiSelectProps} />
 * ```
 */
function MultiSelect<OptionType, SelectType>({
  allowCustomInput = false,
  disableInput = false,
  disableOptions = false,
  renderSelectedOptionPills = true,
  inputPlaceholder = I18n.t('shared.components.multiselect.default_placeholder'),
  loadingOptions = false,
  maxSelectedOptions,
  maxSelectedOptionsPlaceholder,
  selectedOptions = [],
  shouldRenderResultsWhenQueryIsEmpty = false,
  onRemoveSelectedOption,
  onAddSelectedOption,
  optionsCountMessage,
  renderSelectedOptionContents,
  onlyShowPlaceholderWhenNoSelectedOptions = false,
  options,
  currentQuery,
  noOptionsMessage,
  renderOption,
  onCurrentQueryChanged,
  clearCurrentQuery,
  showOptionsCount = false,
  showPlaceholderWhenQueryEmpty = false,
  preventBackspaceClearsSelectedUsers = false
}: BaseProps<OptionType, SelectType>) {
  /**
   * Basically just used to determine className of the wrapper
   */
  const [focused, setFocused] = useState(false);

  /**
   * Used to determine is "mouseOver" events should be triggered for options;
   * they get skipped if the mouse hasn't moved, since they can be triggered by the container scrolling
   * (i.e. when the user is scrolling through options with the keyboard)
   */
  const [mouseMoved, setMouseMoved] = useState(false);

  /**
   * Whether or not the list of options is currently visible
   */
  const [optionsVisible, _setOptionsVisible] = useState(false);
  const setOptionsVisible = disableOptions ? () => {} : _setOptionsVisible; // I'm sorry

  /**
   * The currently selected option in the options array.
   */
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | undefined>(undefined);

  /**
   * Used to "override" the root node's onBlur
   * This happens when an option is clicked with the mouse; technically
   * the option gains focus and the root node's onBlur is called.
   *
   * However, we still want its onBlur to be called when i.e. tabbing out of
   * the input field or clicking away from it.
   */
  const [skipBlur, setSkipBlur] = useState(false);

  /**
   * If this is false, then we're scrolling through options using the keyboard
   * and want to scroll to their dom nodes as they gain focus.
   * If this is true, a mouse is being used and we don't want to jump around as the user scrolls.
   */
  const [usingMouse, setUsingMouse] = useState(false);

  // Ref for multi-select root div element
  const rootRef = useRef<HTMLDivElement>(null);

  // Ref for child input element
  const [inputRef, setInputRef] = useState(useRef<HTMLInputElement>(null));

  useEffect(() => {
    // we have to track if the mouse has moved because, when scrolling through
    // the options list with the keyboard, "mouseover" events trigger if the mouse
    // is over an option after the container scrolls.
    // If the mouse hasn't moved, we basically ignore this mouseover event
    // (see MultiSelectOption)
    rootRef.current?.addEventListener('mousemove', onDocumentMouseMove);

    return () => {
      // unsubscribe event
      rootRef.current?.removeEventListener('mousemove', onDocumentMouseMove);
    };
  }, [rootRef]);

  const onDocumentMouseMove = () => {
    if (mouseMoved === false) {
      setMouseMoved(true);
    }
  };

  const handleFocus = () => {
    setOptionsVisible(true);
    setFocused(true);
  };

  /**
   * Handle the blur event on the root of the component.
   * Used to hide the options list when i.e. clicking or tabbing out of
   * the component
   */
  const handleBlur = () => {
    if (!skipBlur) {
      // not told to skip this blur, so we hide the options
      // effectively making the component "unfocused"
      setOptionsVisible(false);
      setFocused(false);

      if (typeof clearCurrentQuery === 'function') {
        clearCurrentQuery();
      }
    } else {
      // skipBlur is true (meaning an option was clicked with the mouse)
      // set skipBlur to false so that next time the blur event is fired,
      // we know to change the optionsVisible boolean
      setSkipBlur(false);
    }
  };

  /**
   * This method calls the "onAddSelectedOption" that's been passed in as props,
   * but will pay attention to the "maxSelectedOptions" prop passed in and prevent adding
   * more than the allowed amount.
   */
  const addSelectedOption = (option?: OptionType) => {
    if (
      _.isNil(maxSelectedOptions) ||
      _.isEmpty(selectedOptions) ||
      selectedOptions.length < maxSelectedOptions
    ) {
      onAddSelectedOption(option);
    }
  };

  const renderPills = () => {
    const selectOptionPillProps: OptionPillProps<OptionType, SelectType> = {
      onRemoveSelectedOption,
      renderSelectedOptionContents
    };

    if (selectedOptions && renderSelectedOptionPills) {
      return selectedOptions.map((option: SelectType, index: number) => (
        <SelectedOptionPill
          key={`selected-option-pill-${index}`}
          selectedOption={option}
          {...selectOptionPillProps}
        />
      ));
    }

    return null;
  };

  const renderSelectedOptionsCount = () => {
    // only render if we actually have a maximum set
    if (!_.isNil(maxSelectedOptions)) {
      return (
        <SelectedOptionsCount maxSelectedOptions={maxSelectedOptions} selectedOptions={selectedOptions} />
      );
    }

    return null;
  };

  const keyDownListenerProps: KeyDownHandlerProps<OptionType, SelectType> = {
    allowCustomInput,
    currentQuery,
    options,
    optionsVisible,
    onAddSelectedOption: addSelectedOption,
    onOptionsVisibilityChanged: setOptionsVisible,
    onRemoveSelectedOption,
    onSelectedOptionIndexChange: setSelectedOptionIndex,
    selectedOptions,
    selectedOptionIndex,
    setUsingMouse: setUsingMouse,
    preventBackspaceClearsSelectedUsers
  };

  const optionListProps: OptionListProps<OptionType, SelectType> = {
    maxSelectedOptions,
    mouseMoved,
    shouldRenderResultsWhenQueryIsEmpty,
    currentQuery,
    showOptionsCount,
    optionsCountMessage,
    noOptionsMessage,
    onAddSelectedOption: addSelectedOption,
    onOptionsVisibilityChanged: setOptionsVisible,
    onSelectedOptionIndexChange: setSelectedOptionIndex,
    options,
    optionsVisible,
    renderOption,
    selectedOptionIndex,
    selectedOptions,
    setUsingMouse: setUsingMouse,
    skipRootBlur: () => setSkipBlur(true),
    usingMouse
  };

  const inputProps: InputProps<OptionType, SelectType> = {
    // this "setInputRef" function will get called by the child and return the DOM node
    // of the input box, which we need to focus on when the container is clicked
    setInputRef: (childRef: React.RefObject<HTMLInputElement>) => setInputRef(childRef),
    currentQuery,
    disableInput,
    inputPlaceholder,
    maxSelectedOptions,
    maxSelectedOptionsPlaceholder,
    onlyShowPlaceholderWhenNoSelectedOptions,
    onCurrentQueryChanged,
    clearCurrentQuery,
    onOptionsVisibilityChanged: setOptionsVisible,
    onSelectedOptionIndexChange: setSelectedOptionIndex,
    selectedOptions,
    showPlaceholderWhenQueryEmpty
  };

  const formContainerClassNames = cx('multiselect-form-container', {
    focused: focused,
    disabled: disableInput
  });

  return (
    <div
      className="multiselect-root"
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={(e) => multiSelectKeyDownHandler(e, keyDownListenerProps)}
      ref={rootRef}
    >
      <div className={formContainerClassNames} onClick={() => inputRef.current?.focus()}>
        {' '}
        {/* Focus on the text box when the container is clicked */}
        <div className="multiselect-search-icon-container">
          {loadingOptions ? (
            <span className="spinner-default multiselect-search-icon"></span>
          ) : (
            <SocrataIcon name={IconName.Search} className="multiselect-search-icon" />
          )}
        </div>
        <div className="multiselect-form">
          {renderPills()}
          <MultiSelectInput<OptionType, SelectType> {...inputProps} />
        </div>
        {renderSelectedOptionsCount()}
      </div>
      <MultiSelectOptionList<OptionType, SelectType> {...optionListProps} />
    </div>
  );
}

export default MultiSelect;
