import React, { FunctionComponent, useState, useRef } from 'react';
import { useMutation, UseMutateFunction } from 'react-query';

import { Federation, ErrorResponse } from '@socrata/core-federations-api';

import { View } from 'common/types/view';
import I18n from 'common/i18n';
import { AssetFederationApi, parseError, coreErrorCodeToTranslationString } from 'common/core/federations';
import Button, { VARIANTS } from 'common/components/Button';
import { Form, Input } from 'common/components/Forms';
import Alert, { AlertType } from 'common/components/Alert';

/**
 * Called when the form is submitted
 *
 * @param e Form submit event
 * @param targetDomain Text that was entered into input
 * @param setTargetDomainError Function to call if there's an error
 * @param addFederationRelationship Function to call out to API
 */
const onAddFederationRelationshipFormSubmit = (
  e: React.FormEvent<HTMLFormElement>,
  targetDomain: string,
  setTargetDomainError: React.Dispatch<React.SetStateAction<string | undefined>>,
  addFederationRelationship: UseMutateFunction<Federation, ErrorResponse, string>
) => {
  e.preventDefault();

  // empty input!
  if (!targetDomain || targetDomain.trim().length === 0) {
    setTargetDomainError(
      I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.please_enter_target')
    );
    return;
  }

  try {
    // this will throw an error if an invalid cname was entered
    new URL(targetDomain.startsWith('https://') ? targetDomain : `https://${targetDomain}`);
  } catch (error) {
    setTargetDomainError(
      I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.please_enter_valid_domain')
    );
    return;
  }

  // this will call out to the API to actually add the relationship to the view
  addFederationRelationship(targetDomain);
};

interface AddFederationProps {
  /** View that we're adding a federation to */
  view: View;

  /**
   * Function to call to re-fetch federations for the view.
   * This will be called after successfully adding a new federation.
   */
  refetchFederationRelationships: () => void;

  /** Whether or not the form should be open by default */
  openByDefault: boolean;
}

/**
 * Show a form that can be used to add a new outgoing federation to an asset.
 */
const AddFederation: FunctionComponent<AddFederationProps> = ({
  view,
  refetchFederationRelationships,
  openByDefault
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [targetDomain, setTargetDomain] = useState('');
  const [targetDomainError, setTargetDomainError] = useState<string | undefined>(undefined);

  const {
    mutate: addFederationRelationship,
    isLoading: loadingAddFederation,
    isError: isAddFederationError
  } = useMutation<Federation, ErrorResponse, string>(
    async (targetDomainCName: string) => {
      try {
        return await AssetFederationApi.createAssetFederation({ viewId: view.id, targetDomainCName });
      } catch (e) {
        console.error(e);
        throw await parseError(e);
      }
    },
    {
      // clear out any error when calling the mutate function
      onMutate: () => setTargetDomainError(undefined),
      onSuccess: () => {
        // get the new federation relationships
        refetchFederationRelationships();

        // reset input state
        setTargetDomain('');
        setTargetDomainError(undefined);
      },
      onError: (error: ErrorResponse) => {
        setTargetDomainError(coreErrorCodeToTranslationString(error.code));
      }
    }
  );

  return (
    <details
      open={openByDefault}
      className="federate-to--add-federation-details"
      // focus on the input when toggling open the details
      onToggle={(e) => (e.currentTarget as HTMLDetailsElement).open && inputRef.current?.focus()}
    >
      <summary>
        <h2 className="federate-to--add-federation-title">
          {I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.add_federation')}
        </h2>
      </summary>
      <Form
        onSubmit={(e) =>
          onAddFederationRelationshipFormSubmit(
            e,
            targetDomain,
            setTargetDomainError,
            addFederationRelationship
          )
        }
      >
        {isAddFederationError && (
          <Alert type={AlertType.Error}>
            {I18n.t(
              'shared.components.asset_action_bar.publication_action.federate_modal.error_adding_federation'
            )}
          </Alert>
        )}
        <Input
          autoFocus
          valid={!targetDomainError}
          errorMessage={targetDomainError}
          label={I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.target_domain')}
          value={targetDomain}
          ref={inputRef}
          onChange={(e) => {
            setTargetDomain(e.target.value);
            setTargetDomainError(undefined);
          }}
        />
        <Button type="submit" variant={VARIANTS.PRIMARY} busy={loadingAddFederation}>
          {I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.add_federation')}
        </Button>
      </Form>
    </details>
  );
};

export default AddFederation;
