import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import UserSearch, { UserSearchProps } from 'common/components/UserSearch';

import { AccessLevelName, ViewUser } from 'common/types/view';
import { findUserWithAccessLevel } from 'common/components/AccessManager/Util';

import * as changeOwnerActions from 'common/components/AccessManager/actions/ChangeOwnerActions';
import * as uiActions from 'common/components/AccessManager/actions/UiActions';

import UserDetails from 'common/components/AccessManager/components/UserDetails';

import './change-owner.scss';
import { CatalogUserOrTeam, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import { AccessManagerState } from 'common/components/AccessManager/types';

const classNameScope = 'common--components--AccessManager--components--ChangeOwner';

interface StateProps {
  confirmButtonBusy: boolean;
  searchResults?: UsersCatalogSearchResults[];
  currentSearchQuery: string;
  selectedOwner: CatalogUserOrTeam[];
  users: ViewUser[];
}

interface DispatchProps {
  addSelectedOwner: (user?: UsersCatalogSearchResults) => void;
  removeSelectedOwner: (user?: CatalogUserOrTeam) => void;
  ownerSearchQueryChanged: (e: ChangeEvent<HTMLInputElement>) => void;
  setConfirmButtonDisabled: (disabled: boolean) => void;
}

type ChangeOwnerProps = StateProps & DispatchProps;

/**
 * Displays the current owner of the asset,
 * a search box to select a new owner,
 * and a button to confirm/cancel changing the owner.
 */
export class ChangeOwner extends Component<ChangeOwnerProps> {
  static defaultProps = {
    confirmButtonBusy: false,
    currentSearchQuery: '',
    selectedOwner: [],
    users: []
  };

  static updateConfirmButton = (props: ChangeOwnerProps) => {
    const { selectedOwner, setConfirmButtonDisabled } = props;

    setConfirmButtonDisabled(!selectedOwner || selectedOwner.length !== 1);
  };

  UNSAFE_componentWillReceiveProps(nextProps: ChangeOwnerProps) {
    if (nextProps.selectedOwner !== this.props.selectedOwner) {
      ChangeOwner.updateConfirmButton(nextProps);
    }
  }

  renderCurrentOwner = () => {
    const { users } = this.props;
    const currentOwner = findUserWithAccessLevel(users, AccessLevelName.CurrentOwner);

    return (
      <div className={`${classNameScope}--current-owner-label`}>
        <h3 className={`${classNameScope}--section-label`}>
          {I18n.t('shared.site_chrome.access_manager.current_owner')}
        </h3>
        <hr className={`${classNameScope}--separator`} />
        <UserDetails user={currentOwner!} />
      </div>
    );
  };

  renderChangeOwnerSearch = () => {
    const {
      addSelectedOwner,
      currentSearchQuery,
      removeSelectedOwner,
      searchResults,
      selectedOwner,
      ownerSearchQueryChanged
    } = this.props;

    const userSearchProps: UserSearchProps = {
      maxSelectedUsers: 1, // can only have one owner
      addToSelectedUsers: addSelectedOwner,
      currentQuery: currentSearchQuery,
      results: searchResults,
      removeFromSelectedUsers: removeSelectedOwner,
      selectedUsers: selectedOwner,
      userSearchQueryChanged: ownerSearchQueryChanged
    };

    return (
      <div>
        <h3 className={`${classNameScope}--section-label`}>
          {I18n.t('shared.site_chrome.access_manager.new_owner')}
        </h3>
        <hr className={`${classNameScope}--separator`} />

        <div className={`${classNameScope}--search-container`}>
          <UserSearch {...userSearchProps} />
        </div>
      </div>
    );
  };

  render() {
    const { confirmButtonBusy } = this.props;
    return (
      <div>
        {this.renderCurrentOwner()}
        {!confirmButtonBusy && this.renderChangeOwnerSearch()}
      </div>
    );
  }
}

const mapStateToProps: (state: AccessManagerState) => StateProps = (state: AccessManagerState) => ({
  confirmButtonBusy: state.ui.footer.confirmButtonBusy,

  // for UserSearch
  searchResults: state.changeOwner.results?.results,
  currentSearchQuery: state.changeOwner.query,
  selectedOwner: state.changeOwner.selectedOwner,
  users: state.permissions.permissions?.users || []
});

/* eslint @typescript-eslint/ban-types: "warn" */
const mapDispatchToProps: (dispatch: any) => DispatchProps = (dispatch: any) => ({
  addSelectedOwner: (searchResult?: UsersCatalogSearchResults) =>
    // this is the "raw" search result from the catalog, which will container either `user` OR `team` (but never both...)
    searchResult && dispatch(changeOwnerActions.addSelectedOwner(searchResult.user || searchResult.team!)),
  removeSelectedOwner: (user?: CatalogUserOrTeam) =>
    user && dispatch(changeOwnerActions.removeSelectedOwner(user)),
  ownerSearchQueryChanged: (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(changeOwnerActions.ownerSearchQueryChanged(event.target.value)),
  setConfirmButtonDisabled: (disabled: boolean) => dispatch(uiActions.setConfirmButtonDisabled(disabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOwner);
