// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import FilterFooter from '../FilterFooter';
import FilterHeader from '../FilterHeader';

import Autocomplete from 'common/components/Autocomplete';
import Dropdown from 'common/components/Dropdown';
import Picklist, { PicklistOption, PicklistSizes } from 'common/components/Picklist';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { FILTER_SORTING } from 'common/authoring_workflow/constants';
import { FilterValue, OPERATOR } from '../SoqlFilter';
import { FilterEditorProps } from '../types';
import { addPopupListener } from './InputFocus';
import * as TextFilter from '../lib/Filters/TextFilter';
import * as BaseFilter from '../lib/Filters/BaseFilter';
import { getOperator, isEqualityFilter } from '../lib/Filters/index';

// Constants
const scope = 'shared.components.filter_bar.text_filter';
export const DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME = 400;
export const SHOW_RESULTS_COUNT = 20;
export const FILTER_OFFSET = { DEFAULT: 0, MAX: 20 };
const INFINITE_SCROLL_DATA_LOAD_OFFSET = 25;

export interface TextFilterEditorProps extends FilterEditorProps {
  appliedFilter: TextFilter.TextSoqlFilter;
  columns: BaseFilter.FilterColumnsMap;
}
interface TextFilterEditorState {
  /**
   * NOTE: Other filter editors store individual UI components' values in state
   * and then apply them to the config separately. Text filter applies the values
   * directly to the filter config, so we store the entire dirty filter in state to
   * keep rerenders the same.
   */
  dirtyFilter: TextFilter.TextSoqlFilter;
  isLoadingData: boolean;
  topXOptions: PicklistOption[];
  picklistOffset: number;
}

class TextFilterEditor extends Component<TextFilterEditorProps, TextFilterEditorState> {
  textFilter: HTMLDivElement;
  picklistContainerRef: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: TextFilterEditorProps) {
    super(props);

    const { appliedFilter } = props;

    const picklistOffset = FILTER_OFFSET.DEFAULT;

    this.state = {
      dirtyFilter: _.cloneDeep(appliedFilter),
      isLoadingData: true,
      topXOptions: [],
      picklistOffset
    };

    _.bindAll(this, [
      'applyFilter',
      'getSuggestions',
      'isDirty',
      'onSelectOption',
      'onUnselectOption',
      'renderHeader',
      'renderLoadingSpinner',
      'renderSelectedOption',
      'renderTopXOption',
      'renderSuggestionsAutocomplete',
      'resetFilter',
      'updateSelectedValues'
    ]);
  }

  getDataProvider(props: FilterEditorProps) {
    return props.dataProvider[0];
  }

  componentDidMount() {
    this.getTopXOptionsRespectingFilters();
    const { popupRef } = this.props;
    this.removePopupListener = addPopupListener(popupRef, this.textFilter, () => {
      this.popupListenerRemoved = true;
    });
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  handleScrollForPicklist = _.debounce(() => {
    const picklistContainerScrollTop = this.picklistContainerRef?.scrollTop;
    const picklistContainerHeight = this.picklistContainerRef?.clientHeight;
    const picklistContainerScrollHeight = this.picklistContainerRef?.scrollHeight;
    const picklistContainerBottomPosition = picklistContainerScrollTop + picklistContainerHeight;

    if (picklistContainerBottomPosition + INFINITE_SCROLL_DATA_LOAD_OFFSET > picklistContainerScrollHeight) {
      this.getTopXOptionsRespectingFilters();
    }
  }, DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME);

  getTopValuesLabel = () => {
    const { dirtyFilter } = this.state;
    const defaultFilterOrderBy = _.get(FILTER_SORTING[0], 'orderBy');
    const orderBy = dirtyFilter.orderBy ?? defaultFilterOrderBy;

    return _.chain(FILTER_SORTING).find({ orderBy }).get('description').value();
  };

  /**
   * Top X options are set up once and placed into state. This gets the Top X items
   *  and inserts the (No Value) item at the top of the list.
   */
  getTopXOptionsRespectingFilters = () => {
    const { allFilters, allParameters, appliedFilter, dataProvider, columns } = this.props;
    const { dirtyFilter, picklistOffset, topXOptions } = this.state;

    this.setState({ isLoadingData: true });
    const filter = appliedFilter;
    const infiniteScroll = appliedFilter.columns.length === 1;

    TextFilter.getTopXOptionsRespectingFilters({
      filter,
      allFilters,
      allParameters,
      offset: infiniteScroll ? picklistOffset : 0,
      dataProviderConfigs: dataProvider
    })
      .then((result) => {
        // Check to make sure component is mounted
        if (!this.textFilter) {
          return;
        }

        const group = this.getTopValuesLabel();
        const newTopXOptions: PicklistOption[] = (result.topXOptions ?? []).map((option) => {
          return {
            title: option.value,
            value: option.value,
            group: group,
            render: this.renderTopXOption
          };
        });

        if (!infiniteScroll || (infiniteScroll && picklistOffset === 0)) {
          newTopXOptions.unshift(this.getNullOption());
        }
        if (infiniteScroll && picklistOffset > 0) {
          newTopXOptions.unshift(...topXOptions);
        }

        const newPicklistOffset = picklistOffset + SHOW_RESULTS_COUNT;
        this.setState({
          isLoadingData: false,
          topXOptions: newTopXOptions,
          picklistOffset: newPicklistOffset
        });
      })
      .catch((error) => {
        this.setState({ isLoadingData: false });
        console.error(`Soql like top values failed for ${BaseFilter.getFilterName(dirtyFilter, columns)}:`);
        console.error(error);
      });
  };

  getNullOption = () => {
    // Create the "null" suggestion to allow filtering on empty values.
    return {
      title: I18n.t('no_value', { scope }),
      value: null,
      group: this.getTopValuesLabel(),
      render: this.renderTopXOption
    };
  };

  getSuggestions(searchTerm: string, callback: (results: { results: any[] }) => void) {
    const { allFilters, allParameters, dataProvider, columns } = this.props;
    const { dirtyFilter } = this.state;

    if (_.isEmpty(searchTerm)) {
      if (typeof callback === 'function') {
        return callback({ results: [] });
      } else {
        return { results: [] };
      }
    }

    const filter = dirtyFilter;
    return TextFilter.getSuggestions({
      filter,
      searchTerm,
      allFilters,
      allParameters,
      dataProviderConfigs: dataProvider
    }).catch((error) => {
      console.error(`Soql like search failed for ${BaseFilter.getFilterName(dirtyFilter, columns)}:`);
      console.error(error);
    });
  }

  onSelectOption(option?: PicklistOption | null) {
    const { dirtyFilter } = this.state;

    if (dirtyFilter.singleSelect) {
      this.updateSelectedValues([option?.value], this.applyFilter);
    } else {
      this.updateSelectedValues(_.union(TextFilter.getOperands(dirtyFilter), [option?.value]));
    }
  }

  onUnselectOption(option?: PicklistOption | null) {
    const { dirtyFilter } = this.state;

    this.updateSelectedValues(_.without(TextFilter.getOperands(dirtyFilter), option?.value));
  }

  updateSelectedValues(nextSelectedValues: FilterValue[], callback?: () => void) {
    const { dirtyFilter } = this.state;
    const updatedFilter = TextFilter.getEqualityTextFilter(dirtyFilter, {
      values: _.uniq(nextSelectedValues)
    });
    this.setState({ dirtyFilter: _.cloneDeep(updatedFilter) }, callback);
  }

  resetFilter() {
    const { columns, onUpdate } = this.props;
    const { dirtyFilter } = this.state;

    const resetFilter = BaseFilter.reset(dirtyFilter, columns);
    onUpdate(resetFilter);
  }

  applyFilter() {
    const { onUpdate } = this.props;
    const { dirtyFilter } = this.state;

    onUpdate(dirtyFilter);
  }

  isDirty() {
    const { dirtyFilter } = this.state;
    const { appliedFilter } = this.props;

    return TextFilter.hasOperands(dirtyFilter) && !_.isEqual(dirtyFilter, appliedFilter);
  }

  renderHeader() {
    const { dirtyFilter } = this.state;
    const { columns } = this.props;
    const headerProps = {
      name: BaseFilter.getFilterName(dirtyFilter, columns)
    };

    let placeholder;

    switch (getOperator(dirtyFilter)) {
      case OPERATOR.NOT_EQUAL:
        placeholder = I18n.t('is_not', { scope });
        break;
      case OPERATOR.STARTS_WITH:
        placeholder = I18n.t('starts_with', { scope });
        break;
      case OPERATOR.CONTAINS:
        placeholder = I18n.t('contains', { scope });
        break;
      case OPERATOR.DOES_NOT_CONTAIN:
        placeholder = I18n.t('does_not_contain', { scope });
        break;
      default: // default to equals
        placeholder = I18n.t('is', { scope });
        break;
    }

    const dropdownProps = {
      onSelection: (option?: PicklistOption | null) => {
        const updatedFilter: TextFilter.TextSoqlFilter = TextFilter.setOperator(dirtyFilter, option?.value);
        this.setState({ dirtyFilter: updatedFilter });
      },
      options: [
        { title: I18n.t('is', { scope }), value: OPERATOR.EQUALS },
        { title: I18n.t('is_not', { scope }), value: OPERATOR.NOT_EQUAL },
        { title: I18n.t('starts_with', { scope }), value: OPERATOR.STARTS_WITH },
        { title: I18n.t('contains', { scope }), value: OPERATOR.CONTAINS },
        { title: I18n.t('does_not_contain', { scope }), value: OPERATOR.DOES_NOT_CONTAIN }
      ],
      placeholder,
      size: PicklistSizes.SMALL
    };

    const dropdown = !_.get(dirtyFilter, 'singleSelect', false) ? <Dropdown {...dropdownProps} /> : null;

    return <FilterHeader {...headerProps}>{dropdown}</FilterHeader>;
  }

  renderSelectedOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;

    return (
      <div className="picklist-selected-option">
        <SocrataIcon name={IconName.Filter} />
        <span className="picklist-selected-option-title">{title}</span>
        <SocrataIcon name={IconName.Close2} />
      </div>
    );
  }

  renderTopXOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;

    return <div className="picklist-suggestion-option">{title}</div>;
  }

  renderSuggestionsAutocomplete() {
    const autocompleteProps = {
      focusFirstResult: true,
      query: '',
      getSearchResults: this.getSuggestions,
      millisecondsBeforeSearch: DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME,
      onChooseResult: (suggestion: FilterValue) => this.onSelectOption({ value: suggestion }),
      placeholder: I18n.t('search_placeholder', { scope }),
      onSelectSetSelectionAsQuery: false,
      showLoadingSpinner: true,
      isLazyLoading: false
    };
    return (
      <div className="suggestions-autocomplete-container">
        <Autocomplete {...autocompleteProps} />
      </div>
    );
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner-container">
        <span className="spinner-default" />
      </div>
    );
  }

  renderPicklist() {
    const { dirtyFilter, isLoadingData, topXOptions } = this.state;
    const { appliedFilter } = this.props;

    const selectedValues = TextFilter.getOperands(dirtyFilter);
    const infiniteScroll = appliedFilter.columns.length === 1;

    const options = _.filter(topXOptions, (option) => !_.includes(selectedValues, option.value));
    const topXPicklistProps = {
      options,
      onSelection: this.onSelectOption,
      size: PicklistSizes.SMALL,
      value: false // To prevent highlighting of any item no-value option
    };

    const selectedOptions = _.map(selectedValues, (selectedValue) => ({
      group: I18n.t('selected_values', { scope }),
      title: _.isNull(selectedValue) ? I18n.t('no_value', { scope }) : selectedValue,
      value: selectedValue,
      render: this.renderSelectedOption
    }));

    const selectionPicklistProps = {
      options: selectedOptions,
      onSelection: this.onUnselectOption,
      size: PicklistSizes.SMALL,
      value: false, // To prevent highlighting of any item no-value option
      disabled: selectedOptions.length === 0
    };

    const selectedValuesSection = !dirtyFilter.singleSelect ? (
      <div className="picklist-selected-options">
        <Picklist {...selectionPicklistProps} />
      </div>
    ) : null;
    let picklistContainerAttributes;
    if (infiniteScroll) {
      picklistContainerAttributes = {
        className: 'picklist-options-container',
        onScroll: this.handleScrollForPicklist,
        ref: (ref: HTMLDivElement) => (this.picklistContainerRef = ref)
      };
    } else {
      picklistContainerAttributes = {
        className: 'picklist-options-container'
      };
    }

    return (
      <div {...picklistContainerAttributes}>
        {selectedValuesSection}
        <div className="picklist-suggested-options">
          <Picklist {...topXPicklistProps} />
          {isLoadingData && this.renderLoadingSpinner()}
        </div>
      </div>
    );
  }

  renderTextBox() {
    const containsValue = TextFilter.getOperands(this.state.dirtyFilter)[0] ?? '';
    const inputProps = {
      className: 'text-input',
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const { dirtyFilter } = this.state;
        const updatedFilter = TextFilter.getContainsTextFilter(dirtyFilter, {
          operand: event.target?.value ?? ''
        });
        this.setState({ dirtyFilter: _.cloneDeep(updatedFilter) });
      },
      value: containsValue as string
    };

    return (
      <div className="value-filter-container input-group">
        <input {...inputProps} />
      </div>
    );
  }

  render() {
    const { isReadOnly, onRemove, showRemoveButtonInFooter } = this.props;
    const { dirtyFilter } = this.state;

    const footerProps = {
      disableApplyFilter: !this.isDirty(),
      isDrilldown: dirtyFilter.isDrilldown,
      isReadOnly,
      onClickApply: () => this.applyFilter(),
      onClickRemove: onRemove,
      onClickReset: this.resetFilter,
      showApplyButton: !dirtyFilter.singleSelect,
      showRemoveButton: showRemoveButtonInFooter
    };

    let suggestionsAutocomplete;
    let picklist;
    let textBox;

    if (isEqualityFilter(dirtyFilter)) {
      suggestionsAutocomplete = this.renderSuggestionsAutocomplete();
      picklist = this.renderPicklist();
      textBox = null;
    } else {
      suggestionsAutocomplete = null;
      picklist = null;
      textBox = this.renderTextBox();
    }

    return (
      <div className="filter-controls text-filter" ref={(el: HTMLDivElement) => (this.textFilter = el)}>
        <div className="column-container">
          {this.renderHeader()}
          {suggestionsAutocomplete}
          {picklist}
          {textBox}
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default TextFilterEditor;
