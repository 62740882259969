import some from 'lodash/some';

import { PrimaryAction } from './primary_action';
import { AllowedSecondaryActions } from './secondary_actions';

/**
 * If this returns false, it means that the current user does not have any actions that
 * they can take on the current asset so the AssetActionBar (AAB) will simply not be rendered.
 */
export const anyActionAvailable = (primaryAction: PrimaryAction, secondaryActions: AllowedSecondaryActions) =>
  primaryAction !== PrimaryAction.NONE || some(secondaryActions);
