import _ from 'lodash';
import {
  TOGGLE_USER_NOTIFICATIONS_LOADING,
  UPDATE_ENQUEUED_NOTIFICATIONS,
  UPDATE_NEW_USER_NOTIFICATION_COUNT,
  UPDATE_TRANSIENT_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS_BY_TYPE
} from 'common/notifications/actions/UserNotificationActions';
import { getUserNotificationCount } from 'common/notifications/helpers/NotificationFormatHelper';


export default function(state = {}, action) {
  switch (action.type) {
    case TOGGLE_USER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        areUserNotificationsLoading: action.loading
      };
    case UPDATE_USER_NOTIFICATIONS_BY_TYPE:
      const { userNotifications } = state;
      const currentNotifications = _.get(userNotifications, action.notificationType + '.notifications', []);
      userNotifications[action.notificationType] = {
        ...userNotifications[action.notificationType],
        ...action.notificationConfigs,
        notifications: currentNotifications.concat(action.newNotifications)
      };
      return {
        ...state,
        userNotifications,
        unreadUserNotificationCount: getUserNotificationCount(userNotifications)
      };
    case UPDATE_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: action.userNotifications,
        unreadUserNotificationCount: getUserNotificationCount(action.userNotifications)
      };
    case UPDATE_ENQUEUED_NOTIFICATIONS:
      return {
        ...state,
        enqueuedNotifications: action.enqueuedNotifications
      };
    case UPDATE_NEW_USER_NOTIFICATION_COUNT:
      const currentUserNotifications = state.userNotifications;
      let notificationsConfigs = _.get(currentUserNotifications, action.notificationType, {});

      notificationsConfigs.total += action.count;
      notificationsConfigs.unread += action.count;
      notificationsConfigs.offset += action.count;
      currentUserNotifications[action.notificationType] = notificationsConfigs;
      return {
        ...state,
        userNotifications: currentUserNotifications,
        unreadUserNotificationCount: getUserNotificationCount(currentUserNotifications)
      };
    case UPDATE_TRANSIENT_NOTIFICATIONS:
      return {
        ...state,
        transientNotifications: action.transientNotifications
      };
    default:
      return state;
  }
}
