import React, { FunctionComponent } from 'react';
import { IMenuSelectEventData } from '@tylertech/forge';
import { ForgeMenu, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';

const LanguageSwitcher: FunctionComponent = () => {
  const { availableLocales } = window.socrata;

  const options = availableLocales
    ? availableLocales.map((locale) => ({ value: locale.url, label: locale.text }))
    : null;
  const onMenuSelect = ({ detail }: CustomEvent<IMenuSelectEventData>) => {
    window.open(`${detail.value}`, '_self');
  };

  return (
    <>
      <ForgeMenu options={options} on-forge-menu-select={onMenuSelect}>
        <ForgeIconButton>
          <button type="button" aria-label="View user profile" className="tyler-icons">
            <ForgeIcon name="translate" />
          </button>
        </ForgeIconButton>
      </ForgeMenu>
    </>
  );
};

export default LanguageSwitcher;
