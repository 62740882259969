import { times } from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';
import { getBasemapLayerStyles } from './basemapStyle';
import { MAXIMUM_MAP_LAYERS } from 'common/authoring_workflow/constants';
import { MIN_ZOOM } from 'common/visualizations/views/mapConstants';

export const INDEX_LAYER_ID_PREFIX = 'empty-index-layer-';

// Given if max layers(VifXOverlay) is 10 (MAXIMUM_MAP_LAYERS), it adds 10 empty layers below
// INSERT_FILL_LAYERS_BEFORE(common/visualizations/views/map/baseMapStyle.js) layer.
// For each layer(VifXOverlay) at index I, we will insert the mapbox-gl-layers for them below
// the empty mapbox-gl-layer at index I. Thereby, reordering of layers of layers will render
// the overlays in same order as specified from the AX.
// Background Info: Each basemap from mapboxgl has a bunch of layers, rendered in the same order
// as the layers. We have different INSERT_FILL_LAYERS_BEFORE for each basemap. We insert our vifXOverlays
// below these mapbox-gl-layers, so that city/state names are displayed above our overlays. (Otherwise,
// shapes/heatoverlays will completely hide all the map stuff below them.
export default class VifIndexLayers {
  constructor(map) {
    this._map = map;
  }

  initialize(vif) {
    return this._addLayers(vif);
  }

  update(vif) {
    return this._addLayers(vif);
  }

  async _addLayers(vif) {
    const baseMapLayerStyles = getBasemapLayerStyles(vif);

    await MapHelper.afterMapLoad(this._map);

    times(MAXIMUM_MAP_LAYERS, (index) => {
      const layerId = getIndexLayerIdAt(index);

      if (!this._map.getLayer(layerId)) {
        // If layer doesn't exist, add it.
        this._addLayer(layerId, baseMapLayerStyles.INSERT_FILL_LAYERS_BEFORE);
      }
    });
  }

  _addLayer(layerId, insertLayerBefore) {
    this._map.addLayer({
      id: layerId,
      type: 'circle',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      },
      // Setting max zoom to 0(min zoom), and the layer will be hidden for zoom levels greater than this.
      // By that, these empty layers wont add even a slight overhead for most of the zoom levels.
      maxzoom: MIN_ZOOM
    }, insertLayerBefore);
  }
}

export const getIndexLayerIdAt = (index) => {
  return `${INDEX_LAYER_ID_PREFIX}${index}`;
};
