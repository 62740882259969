import React from 'react';

export const addPopupListener = (
  popupRef: React.RefObject<any>,
  inputElement: HTMLDivElement,
  listenerRemovedCallback: () => void
) => {
  const callback = () => {
    focusInput(inputElement);
    popupRef?.current?.removeEventListener('forge-popup-position', callback);
    listenerRemovedCallback();
  };

  popupRef?.current?.addEventListener('forge-popup-position', callback);
  return callback;
};

const focusInput = (element: HTMLDivElement) => {
  const actionableElements = element.querySelectorAll('button, a, input, div[tabindex="0"]');
  if (actionableElements.length > 0) {
    const firstActionableElement = actionableElements[0] as HTMLElement;
    firstActionableElement.focus();
  }
};
