type EventCallback = (data?: any) => void;

class EventBus {
  on(event: string, callback: EventCallback) {
    const listener = (e: Event) => callback((e as CustomEvent).detail);
    document.addEventListener(event, listener as EventListener);
  }

  dispatch(event: string, data?: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  remove(event: string, callback: EventCallback) {
    const listener = (e: Event) => callback((e as CustomEvent).detail);
    document.removeEventListener(event, listener as EventListener);
  }
}

export default new EventBus();
