import _ from 'lodash';
import {
  ALERT_QUERY_TYPE,
  ALERT_TYPES,
  CUSTOM_ALERT_TYPES,
  CUSTOM_ALERT_TRIGGER_TYPE,
  PERIODIC_TYPE
} from '../constants';

export const formatAlertParams = (stateParams, alert) => {
  const { alertType, customAlert, customAlertType, customAlertTriggerType } = stateParams;
  let periodicType = PERIODIC_TYPE.ALERT_BASED;
  let selectedAlertType;
  let alertSnoozeEnabled = _.get(stateParams, 'alertSnoozeEnabled', false);

  if (customAlertType != CUSTOM_ALERT_TYPES.THRESHOLD || customAlertTriggerType != CUSTOM_ALERT_TRIGGER_TYPE.ROLLING) {
    // alert snooze option should only enable for threshold alert with rolling trigger type
    alertSnoozeEnabled = false;
  }

  if (customAlertType === CUSTOM_ALERT_TYPES.TIME_BASED) {
    // changed alert type & periodic if alert is time based alert
    periodicType = PERIODIC_TYPE.TIME_BASED;
    selectedAlertType = CUSTOM_ALERT_TYPES.THRESHOLD;
  } else {
    selectedAlertType = customAlertType;
  }

  const alertParams = {
    description: _.get(stateParams, 'alertDescription'),
    webhookUrl: _.get(stateParams, 'webhookUrl'),
    recurrence: customAlertTriggerType,
    watchOn: 'data',
    changesOn: selectedAlertType,
    alertTriggerType: periodicType,
    periodicFrequency: _.get(stateParams, 'alerPeriodicFrequency'),
    alertSendingDays: _.get(stateParams, 'alertSentDays'),
    alertSendingTime: _.get(stateParams,'alertSentTime'),
    timeZone: _.get(stateParams,'alertTimeZone'),
    name: _.get(stateParams, 'alertName'),
    isSnoozeEnabled: alertSnoozeEnabled,
    snoozeFrequency: _.get(stateParams, 'alertSnoozePeriodic'),
    snoozeTimePeriod: Number(_.get(stateParams, 'alertSnoozeTime', '')),
  };

  // params will be changed based on alert type
  if (_.isEmpty(alert)) {
    alertParams.domain = _.get(window, 'location.host');
    alertParams.datasetUid = _.get(window, 'sessionData.viewId');
  } else {
    alertParams.domain = _.get(alert, 'domain');
    alertParams.datasetUid = _.get(alert, 'datasetUid');
  }
  if (alertType === ALERT_TYPES.CUSTOM_ALERT) {
    alertParams.queryType = ALERT_QUERY_TYPE.CUSTOM_ALERT;
    alertParams.abstractParams = customAlert.map(alert => _.omitBy(alert, _.isNil));
  } else {
    alertParams.queryString = _.get(stateParams, 'rawSoqlQuery');
    alertParams.queryType = ALERT_QUERY_TYPE.ADVANCED_ALERT;
  }

  return alertParams;
};
