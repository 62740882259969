import { has, isPlainObject } from 'lodash';
import { ColumnRef } from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';

export interface QualifiedViewColumn {
  qualifier: string;
  column: ViewColumn;
}

export const parens = (arg: string): string => `(${arg})`;
export const isString = (arg: any): arg is string => typeof arg === 'string';
export const isNumber = (arg: any): arg is number => typeof arg === 'number';
export const isViewColumn = (arg: any): arg is ViewColumn => arg && has(arg, 'fieldName') && has(arg, 'dataTypeName');
export const isQualifiedViewColumn = (arg: any): arg is QualifiedViewColumn => isPlainObject(arg) && isString(arg.qualifier) && isViewColumn(arg.column);
export const asString = (arg: string): string => `'${arg.replace("'", "''")}'`;

const guaranteeQualifierPrefix = (arg: string | null | undefined): string | null | undefined => arg ? (arg.startsWith('@') ? arg : `@${arg}`) : arg;
/**
 * Encode a string as a ColumnRef object.
 * @param arg The string to encode as a reference
 * @param qualifier The alias for a join target.
 */
export const encodeStringAsColumnRef = (arg: string, qualifier?: string): ColumnRef => ({ type: 'column_ref', value: arg, qualifier: guaranteeQualifierPrefix(qualifier) || null });
/**
 * Decorate a ViewColumn with a table qualifier.
 * @param column The ViewColumn to encode as a QualifiedViewColumn.
 * @param qualifier The alias for a join target.
 */
export const buildQualifiedViewColumn = (column: ViewColumn, qualifier: string): QualifiedViewColumn => ({ qualifier: guaranteeQualifierPrefix(qualifier)!, column });
