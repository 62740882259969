import React, {FunctionComponent, useEffect, useState} from 'react';

import './dismissible-session-banner.scss';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Button, {VARIANTS} from 'common/components/Button';
import I18n from 'common/i18n';

const DismissibleSessionBanner: FunctionComponent<SessionBannerProps> = (props) => {
  const {
    children,
    sessionStorageKey,
    alertWhenDismissed,
    className,
    bannerType
  } = props;

  const [isDismissed, dismissBanner] = useSessionStorage(sessionStorageKey, alertWhenDismissed);

  if (isDismissed) {
    return null;
  }

  const classes = [
    'dismissible-session-banner alert alert-full-width-top',
    bannerType || 'info',
    className || ''
  ].filter(Boolean).join(' ');

  return (
    <div className={classes}>
      <span className={'banner-text'}>{children}</span>
      <Button variant={VARIANTS.TRANSPARENT} onClick={dismissBanner}>
        <SocrataIcon name={IconName.Close}
                     className={'btn-dismiss-banner'}
                     aria-label={I18n.t('shared.components.dismissible_session_banner.close')} />
      </Button>
    </div>
  );
};

function useSessionStorage(key: string, shouldAlert: boolean): [boolean, () => void] {
  const [storageVal, setStorageVal] = useState(() => sessionStorage.getItem(key) || '');

  useEffect(() => {
    if (shouldAlert) {
      alert('You can get it back by opening a new browser tab or window.');
    }
    sessionStorage.setItem(key, storageVal);
  }, [storageVal]);

  const DISMISSED_VAL = 'true';
  return [storageVal === DISMISSED_VAL, () => setStorageVal(DISMISSED_VAL)]; // [isDismissed, dismissBanner]
}

interface SessionBannerProps {
  sessionStorageKey: string;
  alertWhenDismissed: boolean;
  bannerType?: BannerType,
  className?: string;
}

export enum BannerType {
  Info= 'info',
  Warning = 'warning',
  Error = 'error'
}

export default DismissibleSessionBanner;
