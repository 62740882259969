import React from 'react';
import _ from 'lodash';

import I18n from '../../i18n/index.js';
import MultiSelectOption from './MultiSelectOption';
import { OptionListProps } from './types';

function MultiSelectOptionList<OptionType, SelectType>({
  currentQuery,
  mouseMoved,
  maxSelectedOptions,
  noOptionsMessage = I18n.t('shared.components.multiselect.no_results'),
  onAddSelectedOption,
  onSelectedOptionIndexChange,
  options,
  optionsCountMessage,
  optionsVisible,
  renderOption,
  selectedOptions,
  selectedOptionIndex,
  setUsingMouse,
  shouldRenderResultsWhenQueryIsEmpty,
  showOptionsCount,
  skipRootBlur,
  usingMouse
}: OptionListProps<OptionType, SelectType>) {
  const renderOptions = (optionsThatForSureExist: any[]) => {
    const optionProps = {
      mouseMoved,
      maxSelectedOptions,
      onAddSelectedOption,
      onSelectedOptionIndexChange,
      renderOption,
      selectedOptions,
      setUsingMouse,
      skipRootBlur,
      usingMouse
    };

    return optionsThatForSureExist.map((option: any, index: number) => (
      <MultiSelectOption
        active={selectedOptionIndex === index}
        index={index}
        key={`multiselect-option-${index}`}
        option={option}
        {...optionProps}
      />
    ));
  };

  const renderContents = () => {
    if (_.isNil(options)) {
      // if we're given null or undefined options, render a spinner
      // (indicates that options are being fetched)
      return <span className="spinner-default multiselect-options-spinner" />;
    } else if (options.length === 0) {
      // No options means we display our no options message
      return <em className="multiselect-options-no-results">{noOptionsMessage}</em>;
    } else {
      return renderOptions(options);
    }
  };

  // if showOptionsCount is true, return custom or default "# match(es) found" message
  const renderOptionsCount = () => {
    const i18nScope = 'shared.components.multiselect';
    if (options && showOptionsCount) {
      return (
        <div className="multiselect-possible-options-count">
          {optionsCountMessage
            ? optionsCountMessage
            : I18n.t('matches_found', { count: options.length, scope: i18nScope })}
        </div>
      );
    }
  };

  // return if we're not visible,
  // or if we're told to hide the results when the query is empty
  if (!optionsVisible || (!shouldRenderResultsWhenQueryIsEmpty && !currentQuery)) {
    return null;
  }

  // don't render the options if the maximum amount has already been selected
  // (user has to remove an option to select another one)
  if (
    maxSelectedOptions !== undefined &&
    selectedOptions !== undefined &&
    !_.isEmpty(selectedOptions) &&
    selectedOptions.length >= maxSelectedOptions
  ) {
    return null;
  }

  return (
    <div className="multiselect-options-container" tabIndex={-1}>
      {renderOptionsCount()}
      {renderContents()}
    </div>
  );
}

export default MultiSelectOptionList;
