import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { DOWN, ENTER, SPACE } from 'common/dom_helpers/keycodes_deprecated';
import { handleKeyEvent } from 'common/dom_helpers/keyPressHelpers';
import { SocrataIcon } from 'common/components/SocrataIcon';
import { collapseChanged } from '../actions';

const classNameScope = 'common--autocomplete--components';

function CollapsedIcon({ onCollapsedChanged }) {
  return (
    <div
      className={`collapsed ${classNameScope}--collapsed-icon`}
      onClick={() => onCollapsedChanged(false)}
      onKeyDown={handleKeyEvent([DOWN, ENTER, SPACE], () => onCollapsedChanged(false))}
      tabIndex="0"
    >
      <SocrataIcon name="search" aria-label="search" />
    </div>
  );
}

CollapsedIcon.propTypes = {
  onCollapsedChanged: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  onCollapsedChanged: (visible) => {
    dispatch(collapseChanged(visible));
  }
});

export default connect(null, mapDispatchToProps)(CollapsedIcon);
