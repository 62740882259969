import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';

import UserDetails from 'common/components/AccessManager/components/UserDetails';

import './owner-user-details.scss';
import { ViewUser } from 'common/types/view';

const classNameScope = 'common--components--AccessManager--components--UserList--owner-user-details';

interface OwnerUserDetailsProps {
  user: ViewUser;
}

/**
 * Renders user details for the current owner of the asset.
 * If the current user can change the owner, they will also see a button
 * to do so.
 */
const OwnerUserDetails: FunctionComponent<OwnerUserDetailsProps> = ({ user }) => (
  <UserDetails user={user}>
    <div className={`${classNameScope}--change-owner-container`}>
      {I18n.t('shared.site_chrome.access_manager.owner')}
    </div>
  </UserDetails>
);

export default OwnerUserDetails;
