import _ from 'lodash';
import $ from 'jquery';

import { BaseVisualization } from './types';

export const template = (): JQuery => $('<div>', { 'class': 'socrata-visualization-actions-toolbar-container' });

export const onUpdateVif = (self: BaseVisualization) => {
  self.$container.addClass('socrata-visualization-toolbar');
};
