import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';

import {
  AGGREGATION_TYPES,
  DATE_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES
} from 'common/components/CreateAlertModal/constants';
import { aggregateOptions } from './helpers';
import '../components.scss';
import SoqlSliceBuilderPropType from './SoqlSliceBuilderPropType';
import SoqlSliceBuilderValueField from './SoqlSliceBuilderValueField';

/**
 @prop slice - object represent slice values
 @prop sliceIndex - slice index number
 @prop datasetColumns - dataset column values
 @prop removeSliceEntry - trigger when slice delete button clicked
 @prop onSliceValueChange - trigger when slice values change
*/

/**
 Used in conjunction with SoqlBuilder. Shows form field(one row of SoqlBuilder) for creating
 one slice of a soql query. Different fields are as below
 - Logical operator: AND|OR
 - Statement: Group by
 - Count(*): count in each group or in the entire dataset.
 - Column: Columns in the dataset.
 - Aggregate: SUM|AVG|MIN|MAX|MEDIAN
 - Operator: +|-|>|<|<=|>=|NEAR(location)|WITHIN(date)|NOT WITHIN(date)
 - Value: DateRangePicker|GeoCodeTypeahead|ColumnValueTypeahead|SimpleTextBox

 The second dropdown lists groupBy|count(*) and columns. Based on which option is selected,
 the remaining applicable form fields show up.

 On selecting GroupBy  : It shows only one more form field for selecting the column.
 On selecting Count(*) : It shows form fields: 'operator' and 'value'
 On selecting Column   :
    For number column: It shows 'aggregate and operator'
      - On selecting aggregate, it shows 'operator', 'value'
      - On selecting operator it shows 'value'
    For other columns: It shows 'operator', 'value'

 For Value selection:
 - DateRangePicker   : is shown when the selected column is of type date or similar.
 - GeocoderTypeahead : is shown when the selected column is of type location or point.
 - DatasetColumnValueTypeahead   : is shown when the sected column is text or simillar.

 Sample Soql slices:
 [
   {column: 'rowcount', operator: '>', 'value': 10},
   {column: 'number', operator: '>', value: '10', logical_operator: 'and'},
   {column: 'date', operator: 'within', start_date: '1/29/2017', end_date: '3/29/2017},
   {column: 'text', '=', value: 'abc', logical_operator: 'no'},
   {column: 'location', 'with in', location: 'abc' lat: '10.344', lng: '23.2323', radius: 5},
   {column: 'group', operator: 'department'},
 ]

 -------------------------------------
 Sample rendering of the above slices:
 -------------------------------------
 | Logical Operator | StatementOrCountOrColumn | Column    | Aggregate | Operator/FunctionalOperator | Value | location | lat | lng | start_date | end_date|
 |                  |   rowcount               |           |           |          >                  | 10    |          |     |     |            |         |
 |   and            |    number                |           |   sum     |          >                  |  20   |          |     |     |            |         |
 |    or            |    text                  |           |           |          =                  | abc   |          |     |     |            |         |
 |   and            |    location              |           |           |         near                |       |    USA   |10.33|43.23|            |         |
 |   and            |    group                 |department |           |                             |       |
 |   and            |    date                  |           |           |        with in              |       |          |     |      |24/3/2016  |20/9/2017|
*/
class SoqlSliceBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumnValues: [],
      selectedDatasetColumn: this.getSelectedOption(props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedDatasetColumn: this.getSelectedOption(nextProps)
    });
  }

  onSliceColumnChange = (newSlice) => {
    const { slice, sliceIndex, onSliceValueChange } = this.props;

    // adding logical operator value on column change
    if (!_.isEmpty(slice.logical_operator)) {
      newSlice.logical_operator = slice.logical_operator;
    }
    // adding statemnt value on column change
    if (!_.isEmpty(slice.statement)) {
      newSlice.statement = slice.statement;
    }
    onSliceValueChange(newSlice, sliceIndex);
  };

  onAggregateSelect = (option) => {
    const { slice } = this.props;
    const { selectedDatasetColumn } = this.state;
    const columnType = _.get(selectedDatasetColumn, 'column_type');
    const today = moment().format('YYYY-MM-DD');

    // spliting aggregation, operator values
    if (_.includes(AGGREGATION_TYPES, option.value)) {
      this.onSliceParamChange('aggregation', option.value);
      this.onSliceParamChange('operator', null);
    } else {
      this.onSliceParamChange('aggregation', null);
      this.onSliceParamChange('operator', option.value);
      if (_.includes(DATE_COLUMN_TYPES, columnType)) {
        // added default value for slice date
        this.onSliceParamChange('start_date', _.get(slice, 'start_date', today));
        this.onSliceParamChange('end_date', _.get(slice, 'end_date', today));
      }
    }
  };

  onSliceParamChange = (field, value) => {
    const { slice, sliceIndex, onSliceValueChange } = this.props;

    slice[field] = value;
    onSliceValueChange(slice, sliceIndex);
  };

  onDatasetFieldSelect = (option, removeStatement) => {
    // group by option is consider as statement
    if (option.column_type === 'groupBy') {
      this.onSliceParamChange('statement', option.value);
    } else {
      if (removeStatement) {
        this.onSliceParamChange('statement', null);
      }
      // resetting slice values on column change
      this.onSliceColumnChange({ column: option.value });
    }
  };

  onLocationValueChange = (geocodeResult) => {
    const { slice } = this.props;
    const coordinates = _.get(geocodeResult, 'geometry.coordinates', []);

    // Todo: called multiple times. Need to fix this.
    // location columns lat, lng values
    this.onSliceParamChange('lng', coordinates[0]);
    this.onSliceParamChange('lat', coordinates[1]);
    this.onSliceParamChange('location', _.get(geocodeResult, 'value'));

    // adding default value for radius slider
    this.onSliceParamChange('radius', _.get(slice, 'radius', 1));
  };

  getSelectedOption = (props) => {
    const { slice, datasetColumns } = props;
    const value = _.get(slice, 'column');

    return _.find(datasetColumns, { value }) || {};
  };

  translationScope = 'shared.components.create_alert_modal.custom_alert';

  renderFunctionalOperatorField() {
    const { slice } = this.props;
    const conditionDropDownOption = {
      options: [
        { title: '>', value: '>' },
        { title: '<', value: '<' },
        { title: '>=', value: '>=' },
        { title: '<=', value: '<=' },
        { title: '=', value: '=' }
      ],
      placeholder: I18n.t('placeholder.operator', { scope: this.translationScope }),
      showOptionsBelowHandle: true,
      size: 'small'
    };

    if (_.includes(AGGREGATION_TYPES, slice.aggregation)) {
      return (
        <div className="field-selector function-operator-field">
          <Dropdown
            {...conditionDropDownOption}
            onSelection={(option) => this.onSliceParamChange('function_operator', option.value)}
            value={slice.function_operator}
          />
        </div>
      );
    }

    return null;
  }

  renderOtherValueInput() {
    const { aggregation, function_operator: functionOperator, operator, value } = this.props.slice;
    const { selectedDatasetColumn } = this.state;
    const columnType = _.get(selectedDatasetColumn, 'column_type');
    const otherInputTypes = _.concat(NUMBER_COLUMN_TYPES, ['row_identifier']);
    const showAggregationInput = _.includes(AGGREGATION_TYPES, aggregation);
    const placeHolder = I18n.t('placeholder.value', { scope: this.translationScope });
    const showValueInput =
      (showAggregationInput && !_.isEmpty(functionOperator)) ||
      (!_.isEmpty(operator) && !showAggregationInput);

    if (showValueInput && _.includes(otherInputTypes, columnType)) {
      return (
        <div className="field-selector value-field">
          <input
            className="value-input"
            type="text"
            placeholder={placeHolder}
            value={value}
            onChange={(event) => this.onSliceParamChange('value', event.target.value)}
          />
        </div>
      );
    }

    return null;
  }

  renderAggregateTypeField() {
    const { aggregation, column, operator, statement } = this.props.slice;
    const { selectedDatasetColumn } = this.state;
    const aggregateDropDownOption = {
      options: aggregateOptions(selectedDatasetColumn),
      placeholder: I18n.t('placeholder.aggregation', { scope: this.translationScope }),
      showOptionsBelowHandle: true,
      size: 'small'
    };
    const aggregationValue = operator || aggregation;

    if (_.isEmpty(statement) && !_.isEmpty(column)) {
      return (
        <div className="field-selector aggregation-selector">
          <Dropdown
            {...aggregateDropDownOption}
            onSelection={this.onAggregateSelect}
            value={aggregationValue}
          />
        </div>
      );
    }

    return null;
  }

  renderDatasetColumn() {
    const { datasetColumns, slice } = this.props;
    const dropDownAttributes = {
      placeholder: I18n.t('placeholder.column', { scope: this.translationScope }),
      showOptionsBelowHandle: true,
      size: 'small'
    };

    return (
      <div className="field-selector dataset-column-selector">
        <Dropdown
          {...dropDownAttributes}
          onSelection={(option) => this.onDatasetFieldSelect(option, false)}
          options={datasetColumns}
          value={slice.column}
        />
      </div>
    );
  }

  renderStatementOrCountOrColumnInput() {
    const { slice, datasetColumns } = this.props;
    const dropDownAttributes = {
      placeholder: I18n.t('placeholder.column', { scope: this.translationScope }),
      showOptionsBelowHandle: true,
      size: 'small'
    };

    if (!_.isEmpty(slice.statement)) {
      return (
        <div className="field-selector">
          <Dropdown
            {...dropDownAttributes}
            onSelection={(option) => this.onDatasetFieldSelect(option, true)}
            options={datasetColumns}
            value={slice.statement}
          />
        </div>
      );
    }

    return null;
  }

  renderLogicalOperator() {
    const { sliceIndex, slice } = this.props;
    const dropDownAttributes = {
      showOptionsBelowHandle: true,
      size: 'small'
    };
    const options = [
      { title: I18n.t('aggregation.and', { scope: this.translationScope }), value: 'and' },
      { title: I18n.t('aggregation.or', { scope: this.translationScope }), value: 'or' }
    ];

    if (sliceIndex > 0) {
      return (
        <div className="field-selector logical-operator">
          <Dropdown
            {...dropDownAttributes}
            onSelection={(option) => this.onSliceParamChange('logical_operator', option.value)}
            options={options}
            value={slice.logical_operator}
          />
        </div>
      );
    }

    return null;
  }

  renderValueInputField() {
    const { slice, viewId } = this.props;
    const { selectedDatasetColumn } = this.state;
    return (
      <SoqlSliceBuilderValueField
        selectedColumn={selectedDatasetColumn}
        slice={slice}
        viewId={viewId}
        onValueChange={this.onSliceParamChange}
      />
    );
  }

  render() {
    const { sliceIndex, removeSliceEntry } = this.props;

    return (
      <div className="soql-slice-builder">
        {/* Logical operator */}
        {this.renderLogicalOperator()}
        {/* Groupby/count(*)/Dataset Column */}
        {this.renderStatementOrCountOrColumnInput()}
        {/* Dataset Column */}
        {this.renderDatasetColumn()}
        {/* Column Aggregate */}
        {this.renderAggregateTypeField()}
        {/* Operator */}
        {this.renderFunctionalOperatorField()}
        {/* Value Form fields */}
        {this.renderValueInputField()}
        {/* Remove slice button */}
        {sliceIndex > 0 ? (
          <span className="delete-icon icon-close" onClick={() => removeSliceEntry(sliceIndex)} />
        ) : null}
      </div>
    );
  }
}

SoqlSliceBuilder.propTypes = {
  datasetColumns: PropTypes.array,
  slice: SoqlSliceBuilderPropType.isRequired,
  sliceIndex: PropTypes.number,
  onSliceValueChange: PropTypes.func,
  removeSliceEntry: PropTypes.func
};

export default SoqlSliceBuilder;
