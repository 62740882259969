/* Imports */
/* ============================================================================= */
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { GuidanceCheckSummary } from 'common/core/approvals/Types';





/* Method */
/* ============================================================================= */
/**
 * Check that a GuidanceCheckSummary object has at least 1 truthy value.
 * If a specific WorkflowTargetAudience value was passed,
 * check that the GuidanceCheckSummary has a truthy value for that specific WorkflowTargetAudience
 * @param workflows GuidanceCheckSummary object
 * @param workflow (optional) WorkflowTargetAudience value to check for a truthy value in the GuidanceCheckSummary object
 * @returns boolean
 */
export const hasTruthyWorkflow = (workflows: GuidanceCheckSummary<any>, workflow?: WorkflowTargetAudience): boolean => {
  return !!(workflows.length && (!workflow || workflows[workflow]));
};

export default hasTruthyWorkflow;
