import _ from 'lodash';
import BigNumber from 'bignumber.js';

import assert from 'common/assertions/assert';

import {
  filterWhereClauses,
  joinWhereClauses,
  setupSoqlDataProvider
} from './helpers';

// Returns: BigNumber.
export const count = async (dataProvider, whereClauses) => {
  assert(whereClauses.length > 0, 'At least one where clause must be supplied.');

  const countAlias = '__measure_count_alias__';
  const query = `select count(*) as ${countAlias} where ${joinWhereClauses(whereClauses)}`;
  const data = await dataProvider.rawQuery(query);
  return new BigNumber(data[0][countAlias] || 0); // Note that SoQL queries sometimes return empty and sometimes 0.
};

export const calculateCountMeasure = async (
  errors,
  measure,
  dateRangeWhereClause,
  dataProvider = setupSoqlDataProvider(measure), // For test injection
  countFunction = count // For test injection
) => {
  const dateColumn = _.get(measure, 'metricConfig.dateColumn');
  const calculationFilters = _.get(measure, 'metricConfig.arguments.calculationFilters', []);
  const calculationWhereClause = filterWhereClauses(calculationFilters);

  let value = null;

  if (dataProvider && dateRangeWhereClause) {
    value = (await countFunction(
      dataProvider,
      [calculationWhereClause, dateRangeWhereClause]
    ));
  } else {
    errors.calculationNotConfigured = !dateColumn;
  }

  return { errors, result: { value } };
};
