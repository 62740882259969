import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface FooterProps {
  className?: string;
}

export const Footer: FunctionComponent<PropsWithChildren<FooterProps>> = ({ children, className }) => {
  const footerClasses = classNames({
    'modal-footer': true,
    [className as any]: !!className
  });

  return (
    <footer className={footerClasses}>
      <div className="modal-footer-actions">{children}</div>
    </footer>
  );
};

export default Footer;
