import { createAction, createReducer } from '@reduxjs/toolkit';

import { assert } from 'common/assertions';
import { hasBeenPublished } from 'common/views/publicationHelpers';
import { GenericUser } from 'common/types/users';

import Actions from 'Actions';
import Environment from 'StorytellerEnvironment';
import { FluxPayload, StorySerialized } from 'types';
import httpRequest from 'services/httpRequest.js';
import { exceptionNotifier } from 'services/ExceptionNotifier';
import { saveStory } from 'store/TopLevelActions';

// TODO: this seems rather silly, why not just store the actual save state here? (e.g. saving, saved, etc.)
export interface StorySaveStatusReducerState {
  saveInProgress: boolean;
  poisonedWithSaveConflictForever: boolean;
  lastSerializedStory: StorySerialized | null;
  lastSaveError: string | null;
  userCausingConflict: {
    id: number;
    displayName: string;
  } | null;
  hasBeenPublished: boolean;
  errorMessage?: string;
}

const initialState = {
  saveInProgress: false,
  poisonedWithSaveConflictForever: false,
  lastSerializedStory: null,
  lastSaveError: null,
  userCausingConflict: null,
  hasBeenPublished: hasBeenPublished(Environment.CORE_VIEW)
} as StorySaveStatusReducerState;

/* Action Creators */
export const coreViewUpdated = createAction<FluxPayload>(Actions.CORE_VIEW_UPDATED);
export const storySaveFailed = createAction<FluxPayload>(Actions.STORY_SAVE_FAILED);
export const storySaveStarted = createAction<FluxPayload>(Actions.STORY_SAVE_STARTED);
// TODO: Remove this
export const populateLastSerializedStory = createAction<FluxPayload>('POPULATE_LAST_SERIALIZED_STORY');

const storySaveStatusReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(coreViewUpdated, (state, action) => {
      const { payload } = action;
      state.hasBeenPublished = hasBeenPublished(payload);
    })
    .addCase(saveStory, (state) => {
      storySavedSuccessfully(state);
    })
    .addCase(storySaveFailed, (state, action) => {
      const { payload } = action;
      state.lastSaveError = payload;
      state.poisonedWithSaveConflictForever = state.poisonedWithSaveConflictForever || payload.conflict;
      state.lastSaveError = {
        conflict: payload.conflict
      };
      state.errorMessage = payload.message?.responseJSON?.error;
      state.saveInProgress = false;

      if (payload.conflict) {
        const userUrl = `/api/users/${payload.conflictingUserId}.json`;

        httpRequest('GET', userUrl)
          .then(({ data }: { data: GenericUser }) => {
            state.userCausingConflict = data;
          })
          .catch(exceptionNotifier.notify);
      }
    })
    .addCase(storySaveStarted, (state, action) => {
      const { serializedStory } = action.payload;
      assert(state.saveInProgress === false, 'Can only have one pending save at a time.');
      state.saveInProgress = true;
      state.lastSerializedStory = serializedStory;
    })
    .addCase(populateLastSerializedStory, (state, action) => {
      const { payload } = action;
      state.lastSerializedStory = payload;
    });
});

export default storySaveStatusReducer;

// Helper functions
const storySavedSuccessfully = (state: StorySaveStatusReducerState) => {
  state.saveInProgress = false;
  state.lastSaveError = null;
};
