import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './index.scss';

import FlyoutFactory from 'common/components/legacy/Flyout';

class FieldTitle extends Component {
  componentDidMount() {
    if (this.flyoutRef) {
      new FlyoutFactory(this.flyoutRef);
    }
  }

  render() {
    const { flyoutId, flyoutText, title } = this.props;

    return (
      <div
        className="field-title"
        ref={(element) => {
          this.flyoutRef = element;
        }}
      >
        <label>
          {title}:
          <i className="socrata-icon-question trigger-link" data-flyout={flyoutId} />
        </label>
        <div id={flyoutId} className="flyout-info flyout flyout-hidden flyout-block-label">
          <section className="flyout-content">
            {flyoutText}
          </section>
        </div>
      </div>
    );
  }
}

FieldTitle.propTypes = {
  flyoutId: PropTypes.string,
  flyoutText: PropTypes.string,
  title: PropTypes.string
};

export default FieldTitle;
