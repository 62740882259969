// Vendor Imports
import $ from 'jquery';
import _ from 'lodash';

// Project Imports
import CategoricalDataManager from './dataProviders/CategoricalDataManager';
import { getSoqlVifValidator } from './dataProviders/SoqlVifValidator';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import SvgBarChart from './views/SvgBarChart';
import { getVisualizationData } from './VisualizationCommon';

import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';

// Constants
import { WINDOW_RESIZE_RERENDER_DELAY } from './views/SvgConstants';

$.fn.socrataSvgBarChart = function (originalVif, options) {
  originalVif = migrateVif(originalVif);

  const $element = $(this);
  const visualization = new SvgBarChart($element, originalVif, options);
  let rerenderOnResizeTimeout;

  /**
   * Event handling
   */

  function attachApiEvents() {
    // Destroy on (only the first) 'SOCRATA_VISUALIZATION_DESTROY' event.
    $element.one('SOCRATA_VISUALIZATION_DESTROY', () => {
      clearTimeout(rerenderOnResizeTimeout);
      visualization.destroy();
      detachInteractionEvents();
      detachApiEvents();
    });

    $(window).on('resize', handleWindowResize);

    $element.on('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.on('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function attachInteractionEvents() {
    $element.on('SOCRATA_VISUALIZATION_BAR_CHART_FLYOUT', handleFlyout);
    $element.on(
      'SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED',
      handleDimensionLabelAreaSizeChanged
    );
  }

  function detachApiEvents() {
    $(window).off('resize', handleWindowResize);

    $element.off('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.off('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function detachInteractionEvents() {
    $element.off('SOCRATA_VISUALIZATION_BAR_CHART_FLYOUT', handleFlyout);
    $element.off(
      'SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED',
      handleDimensionLabelAreaSizeChanged
    );
  }

  function handleWindowResize() {
    clearTimeout(rerenderOnResizeTimeout);

    rerenderOnResizeTimeout = setTimeout(
      visualization.render(),
      // Add some jitter in order to make sure multiple visualizations are
      // unlikely to all attempt to rerender themselves at the exact same
      // moment.
      WINDOW_RESIZE_RERENDER_DELAY + Math.floor(Math.random() * 10)
    );
  }

  function handleFlyout(event) {
    const payload = event.originalEvent.detail;

    $element[0].dispatchEvent(
      new window.CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
        detail: payload,
        bubbles: true
      })
    );
  }

  function handleDimensionLabelAreaSizeChanged(event) {
    const newSize = event.originalEvent.detail;
    const newVif = _.cloneDeep(visualization.getVif());

    if (_.isFinite(newSize)) {
      _.set(newVif, 'configuration.dimensionLabelAreaSize', newSize);
    } else {
      delete (newVif.configuration || {}).dimensionLabelAreaSize;
    }

    visualization.emitVifEvent(newVif);
  }

  function handleRenderVif(event) {
    const newVif = event.originalEvent.detail;
    updateData(migrateVif(newVif));
  }

  function handleError(error) {
    let messages;

    if (window.console && console.error) {
      console.error(error);
    }

    if (error.vifValidatorErrors) {
      messages = error.vifValidatorErrors;
    } else if (error.soqlError) {
      const errorCode = _.get(error, 'soqlError.errorCode');

      messages = errorCode
        ? I18n.t(`shared.visualizations.charts.common.soql_error.${errorCode}`)
        : I18n.t('shared.visualizations.charts.common.error_generic');
    } else {
      return visualization.renderGenericError(
        I18n.t('shared.errors.private_or_deleted_asset.message'),
        'privateOrDeletedAsset'
      );
    }

    visualization.renderError(messages);
  }

  async function updateData(newVif) {
    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_START');
    visualization.showBusyIndicator();
    detachInteractionEvents();

    try {
      await $.fn.socrataSvgBarChart.validateVif(newVif);
      const data = await getVisualizationData({ newVif });
      renderVisualization(data);
    } catch (error) {
      handleError(error);
    }
  }

  function renderVisualization({
    newColumns,
    newComputedColumns,
    newData,
    newFlyoutData,
    newVif,
    newTableVif
  }) {
    const overMaxRowCount = newData.rows.length > CategoricalDataManager.MAX_ROW_COUNT;

    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE');
    visualization.hideBusyIndicator();

    if (overMaxRowCount) {
      const error = formatString(
        I18n.t('shared.visualizations.charts.bar_chart.error_exceeded_max_bar_count'),
        CategoricalDataManager.MAX_ROW_COUNT
      );
      visualization.renderGenericError(error, 'exceededMaxRowCount');
    } else {
      attachInteractionEvents();
      visualization.render({
        newColumns,
        newComputedColumns,
        newData,
        newFlyoutData,
        newVif,
        newTableVif
      });
    }
  }

  /**
   * Actual execution starts here
   */

  attachApiEvents();
  updateData(originalVif);

  return this;
};

/**
 * Checks a VIF for compatibility with this visualization. The intent of this
 * function is to provide feedback while authoring a visualization, not to
 * provide feedback to a developer. As such, messages returned are worded to
 * make sense to a user.
 *
 * Returns a Promise.
 *
 * If the VIF is usable, the promise will resolve.
 * If the VIF is not usable, the promise will reject with an object:
 * {
 *   ok: false,
 *   vifValidatorErrors: Array<String>
 * }
 */
$.fn.socrataSvgBarChart.validateVif = (vif) =>
  getSoqlVifValidator(vif).then((validator) => validator.requireAtLeastOneSeries().toPromise());

export default $.fn.socrataSvgBarChart;
