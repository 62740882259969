import $ from 'jquery';
import _ from 'lodash';

import 'lib/StorytellerJQueryUtils';

var CACHE_ATTRIBUTE_NAME = 'data-layout-height';

/**
 * Applies `value.layout.height` from componentData to the selection.
 * If the height was changed, trigger `invalidateSize` on any children
 * of class `component-content`.
 *
 * If `value.layout.height` is not defined, defaultHeight is used. If
 * defaultHeight is not provided or is blank, the height is removed and
 * the component will be allowed to layout normally.
 *
 * @param {object} componentData - The component's data blob from the database.
 * @param {number} defaultHeight - Optional. The default height.
 */
$.fn.withLayoutHeightFromComponentData = withLayoutHeightFromComponentData;

export default function withLayoutHeightFromComponentData(componentData, defaultHeight) {
  if (this.closest('.layout-expanded').length > 0 && this.closest('.block .fluid6').length) {
    return; // Ignore all height settings in embed6 blocks with expanded layout.
  }
  const self = this;
  const height = _.get(componentData, 'value.layout.height', _.isFinite(defaultHeight) ? defaultHeight : '');

  this.updateAttributeWithCallbackIfChanged(CACHE_ATTRIBUTE_NAME, String(height), function () {
    const renderedVif = self[0].getAttribute('data-rendered-vif');
    const $componentContent = self.find('.component-content');
    // StoryVisualization components handle data-rendered-vif in React
    const isStoryVizReactComponent = $componentContent.hasClass('story-viz-react');

    self.outerHeight(height);

    if (!isStoryVizReactComponent && renderedVif !== null) {
      // Normal case, use the standard invalidate size API used by visualizations on the component-content
      $componentContent.triggerHandler('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    } else if (isStoryVizReactComponent) {
      // Since its react, there's an extra div, we need to trigger on that child
      self.find('.common-viz-react').triggerHandler('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    } else {
      // If there is no `data-rendered-vif` attribute on the element,
      // then we should use the 'invalidateSize' handler.
      $componentContent.triggerHandler('invalidateSize');
    }
  });

  return this;
}
