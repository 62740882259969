import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

// A simple controlled checkbox. The containing app is in full
// control of whether or not the checkbox is checked.
// To provide a label, pass it as a child node.
// You can provide a separate ariaLabel prop if you need to
// provide additional context to screen-reader users.
const Checkbox = React.forwardRef(({ ariaLabel, checked, className, disabled, id, onChange, children }, ref) => {

  if (!id) {
    // This is absolutely required for onChange events to work properly.
    throw new Error('<Checkbox> component requires an "id" prop, but none was provided.');
  }

  const inputAttributes = {
    checked,
    disabled,
    id,
    onChange,
    'aria-label': ariaLabel
  };

  const classes = classnames('checkbox', className, { disabled });

  return (
    <div ref={ref} className={classes}>
      <input type="checkbox" {...inputAttributes} />
      <label className="inline-label" htmlFor={id}>
        <span className="fake-checkbox"><span className="icon-checkmark3"></span></span>
        {children}
      </label>
    </div>
  );
});

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  onChange: _.noop
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
