import _ from 'lodash';

import { View } from 'common/types/view';
import { SoQLType } from 'common/types/soql';
import { ViewWithVQEColumns } from 'common/explore_grid/redux/store';

// for default datasets-- filtered views support all types
const DSMP_SUPPORTED_TYPES: { [key in SoQLType]: boolean } = {
  'text': true,
  'number': true,
  'checkbox': true,
  'boolean': true,
  'date': true,
  'fixed_timestamp': true,
  'calendar_date': true,
  'floating_timestamp': true,
  'location': true,
  'point': true,
  'multipoint': true,
  'line': true,
  'multiline': true,
  'polygon': true,
  'multipolygon': true,
  'url': true,
  'json': true,
  'blob': false,
  'photo': false,
  'document': false
};


// if any type is not in the dsmp supported types list, it is a legacy
// type, and the dataset must be edited through non-dsmp pages
export function containsLegacyDataTypes(coreView: View | ViewWithVQEColumns) {
  // ugh why
  if (!_.isArray(coreView.columns)) return false;

  return _.some(coreView.columns.map((c: any) => c.dataTypeName), (typ) => {
    return !DSMP_SUPPORTED_TYPES[typ];
  });
}
