import { isEmpty } from 'lodash';

import { View, ViewRight } from 'common/types/view';
import { hasRights } from 'common/views/has_rights';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { AssetStatus, assetStatusFor } from 'common/views/asset_status';

/**
 * Returns true if the current user is eligible for managing viewers on a view.
 *
 * NOTE: Managing "viewers" is a distinct action from managing "collaborators" despite them being controlled by
 * the same API, database tables, and models behind the scenes! See the AccessManager component for more details.
 *
 * On internal domains that have the `strict_permissions` feature flag on, managing "viewers" is actually
 * managing the asset's visibility (a.k.a. "scope") which can be private, internal (visible to users with the `can_view_internal_data` domain right),
 * or public. Internal domains ALSO have the ability to add "published viewers" which is a distinct grant type that is different from
 * the "viewer" grant that exists on domains with `strict_permissions` off.
 *
 * On domains that have the `strict_permissions` feature flag off, managing viewers is just changing
 * whether the asset is private or public and nothing more. Adding users with the "viewer" grant must actually
 * be done by managing collaborators.
 *
 * A user can manage viewers if...
 * - The view is published and approved (not allowed on draft-y assets like story drafts or revisions, nor on assets pending approval)
 * - The user has the `grant` right on the asset
 * - If strict_permissions is on, the user will be able to see this modal so that they can add PUBLISHED_VIEWER grants to users on this view,
 *   but they must have at least one of the `can_make_asset_[public|internal|private]` domain rights to change the scope (which is checked in the corresponding buttons)
 * - The current user must not be a community user; they must be a site member (they have a role) or a superadmin
 *
 * @param view View to check for manage viewers privileges of
 * @param approvalsGuidance Approvals guidance summary
 * @returns Whether or not the current user can manage viewers
 */
export default (view: Partial<View>, approvalsGuidance?: GuidanceSummaryV2): boolean => {
  if (isEmpty(view) || !approvalsGuidance || isEmpty(approvalsGuidance) || view.locked) {
    return false;
  } else {
    // we only allow changing viewers on published and approved assets
    // this function will return "Pending" if the asset is awaiting approval,
    // in which case the approval request must first be withdrawn
    const isPublished = assetStatusFor(view, approvalsGuidance) === AssetStatus.Published;

    // The user has to have the grant right on the view to actually be able to manage permissions
    // This is given if one of the following is true:
    // - The user is the "true owner" of the asset
    // - The user has an "owner" (a.k.a. "co-owner") grant on the asset
    // - The user has the `edit_others_datasets` domain right (in which case they get `grant` on _every_ asset)
    const hasGrantRight = hasRights(view, ViewRight.Grant);

    return isPublished && hasGrantRight;
  }
};
