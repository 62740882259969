// Vendor Imports
import _ from 'lodash';

// Project Imports
import * as actions from '../actions';

// Constants
import { CALENDAR, SERIES_TYPE_FLYOUT, SCATTER_CHART, VIF_CONSTANTS } from '../constants';
import formatString from 'common/js_utils/formatString';
import { getTodayDate } from 'common/dates';
import { FeatureFlags } from 'common/feature_flags';

/**
 * Note: These properties are in alphabetical order.
 */
const paths = {
  annotations: 'series[0].annotations',
  additionalFlyoutColumns: 'series[{0}].mapOptions.additionalFlyoutColumns',
  baseLayerOpacity: 'configuration.baseLayerOpacity',
  baseLayerUrl: 'configuration.baseLayerUrl',
  basemapStyle: 'configuration.basemapOptions.basemapStyle',
  basemapOpacity: 'configuration.basemapOptions.basemapOpacity',
  castNullAsFalseInSeries: 'series[{0}].mapOptions.castNullAsFalseInSeries',
  charmName: 'series[{0}].mapOptions.charmName',
  clusterRadius: 'configuration.basemapOptions.clusterRadius',
  colorBoundariesBy: 'series[{0}].mapOptions.colorBoundariesBy',
  colorByBucketsCount: 'series[{0}].mapOptions.colorByBucketsCount',
  colorByColumn: 'series[2].dataSource.measure.columnName',
  colorByQuantificationMethod: 'series[{0}].mapOptions.colorByQuantificationMethod',
  colorLinesBy: 'series[{0}].mapOptions.colorLinesBy',
  colorPalette: 'series[{0}].color.palette',
  colorPointsBy: 'series[{0}].mapOptions.colorPointsBy',
  columnOrderConfiguration: 'configuration.order',
  computedColumnName: 'series[{0}].computedColumnName',
  customColorPalette: 'series[{0}].color.customPalette',
  currentDrilldownColumnName: 'series[{0}].dataSource.dimension.currentDrilldownColumnName',
  datasetName: 'series[{0}].dataSource.name',
  datasetUid: 'series[{0}].dataSource.datasetUid',
  dateDisplayFormat: 'series[0].dataSource.dateDisplayFormat',
  description: 'description',
  defaultDisplayDate: 'configuration.defaultDisplayDate',
  dimensionColumnName: 'series[{0}].dataSource.dimension.columnName',
  drilldowns: 'series[{0}].dataSource.dimension.drilldowns',
  dimensionGroupingColumnName: 'series[{0}].dataSource.dimension.grouping.columnName',
  dimensionLabelAreaSize: 'configuration.dimensionLabelAreaSize',
  dimensionLabelRotationAngle: 'configuration.dimensionLabelRotationAngle',
  domain: 'series[{0}].dataSource.domain',
  errorBarsBarColor: 'series[0].errorBars.barColor',
  errorBarsLowerBoundColumnName: 'series[0].errorBars.lowerBoundColumnName',
  errorBarsUpperBoundColumnName: 'series[0].errorBars.upperBoundColumnName',
  eventBackgroundColor: 'series[{0}].color.eventBackgroundColor',
  eventOutlineColor: 'series[{0}].color.eventOutlineColor',
  eventTextColor: 'series[{0}].color.eventTextColor',
  geoCoderControl: 'configuration.basemapOptions.geoCoderControl',
  geoLocateControl: 'configuration.basemapOptions.geoLocateControl',
  groupingOrderBy: 'series[0].dataSource.dimension.grouping.orderBy',
  isIndented: 'configuration.isIndented',
  rowStripeStyle: 'configuration.rowStripeStyle',
  alternateRowStripeStyle: 'configuration.alternateRowStripeStyle',
  labelBottom: 'configuration.axisLabels.bottom',
  labelLeft: 'configuration.axisLabels.left',
  labelRight: 'configuration.axisLabels.right',
  labelTop: 'configuration.axisLabels.top',
  layerToggleControl: 'configuration.basemapOptions.layerToggleControl',
  legendPosition: 'configuration.legendPosition',
  limit: 'series[0].dataSource.limit',
  lineColorOpacity: 'series[{0}].mapOptions.lineColorOpacity',
  lineWeight: 'series[{0}].mapOptions.lineWeight',
  lockCalendarViewControl: 'configuration.lockCalendarViewControl',
  logarithmicScale: 'configuration.logarithmicScale',
  mapCenterAndZoom: 'configuration.mapCenterAndZoom',
  mapFlyoutPrecision: 'configuration.mapFlyoutPrecision',
  mapFlyoutTitleColumnName: 'series[{0}].mapOptions.mapFlyoutTitleColumnName',
  mapLayerVisible: 'series[{0}].visible',
  mapLegendPrecision: 'configuration.mapLegendPrecision',
  mapPitchAndBearing: 'configuration.mapPitchAndBearing',
  mapType: 'series[{0}].mapOptions.mapType',
  mapZoomLevel: 'configuration.mapZoomLevel',
  maxClusteringZoomLevel: 'configuration.basemapOptions.maxClusteringZoomLevel',
  maxClusterSize: 'configuration.basemapOptions.maxClusterSize',
  maximumLineWeight: 'series[{0}].mapOptions.maximumLineWeight',
  maximumPointSize: 'series[{0}].mapOptions.maximumPointSize',
  maximumPlotSize: `series[${SCATTER_CHART.RESIZE_BY_SERIES_INDEX}].chartOptions.maximumPlotSize`,
  measureAggregationFunction: 'series[{0}].dataSource.measure.aggregationFunction',
  measureAxisMaxValue: 'configuration.measureAxisMaxValue',
  measureAxisMinValue: 'configuration.measureAxisMinValue',
  measureAxisPrecision: 'configuration.measureAxisPrecision',
  measureAxisScale: 'configuration.measureAxisScale',
  measureColumnName: 'series[{0}].dataSource.measure.columnName',
  midpoint: 'series[{0}].mapOptions.midpoint',
  minimumLineWeight: 'series[{0}].mapOptions.minimumLineWeight',
  minimumPointSize: 'series[{0}].mapOptions.minimumPointSize',
  minimumPlotSize: `series[${SCATTER_CHART.RESIZE_BY_SERIES_INDEX}].chartOptions.minimumPlotSize`,
  navigationControl: 'configuration.basemapOptions.navigationControl',
  negativeColor: 'configuration.legend.negativeColor',
  // When removing enable_flexible_table_hierarchies: We don't need to set this anymore
  nonStandardAggregation: 'series[{0}].dataSource.nonStandardAggregation',
  numberOfDataClasses: 'series[{0}].mapOptions.numberOfDataClasses',
  orderBy: 'series[0].dataSource.orderBy',
  plotSize: 'configuration.plotSize',
  pointOpacity: 'series[{0}].mapOptions.pointOpacity',
  pointAggregation: 'series[{0}].mapOptions.pointAggregation',
  pointSize: 'series[{0}].mapOptions.pointSize',
  pointThreshold: 'configuration.basemapOptions.pointThreshold',
  positiveColor: 'configuration.legend.positiveColor',
  precision: 'series[0].dataSource.precision',
  primaryColor: 'series[{0}].color.primary',
  primaryLayer: 'series[{0}].primary',
  rangeBucketType: 'series[{0}].mapOptions.rangeBucketType',
  referenceLineColor: 'referenceLines[{0}].color',
  referenceLineLabel: 'referenceLines[{0}].label',
  referenceLines: 'referenceLines',
  referenceLineValue: 'referenceLines[{0}].value',
  regionMapFlyoutColumnAndAggregations: 'series[{0}].mapOptions.regionMapFlyoutColumnAndAggregations',
  resizeByColumn: 'series[3].dataSource.measure.columnName',
  resizePointsBy: 'series[{0}].mapOptions.resizePointsBy',
  rowInspectorTitleColumnName: 'configuration.rowInspectorTitleColumnName',
  scatterChartNumberOfDataClasses: 'series[0].chartOptions.numberOfDataClasses',
  secondaryColor: 'series[{0}].color.secondary',
  secondaryMeasureAxisMaxValue: 'configuration.secondaryMeasureAxisMaxValue',
  secondaryMeasureAxisMinValue: 'configuration.secondaryMeasureAxisMinValue',
  secondaryMeasureAxisPrecision: 'configuration.secondaryMeasureAxisPrecision',
  secondaryMeasureAxisScale: 'configuration.secondaryMeasureAxisScale',
  searchBoundaryLowerRightLatitude: 'configuration.basemapOptions.searchBoundaryLowerRightLatitude',
  searchBoundaryLowerRightLongitude: 'configuration.basemapOptions.searchBoundaryLowerRightLongitude',
  searchBoundaryUpperLeftLatitude: 'configuration.basemapOptions.searchBoundaryUpperLeftLatitude',
  searchBoundaryUpperLeftLongitude: 'configuration.basemapOptions.searchBoundaryUpperLeftLongitude',
  series: 'series',
  seriesType: 'series[{0}].type',
  shapefileGeometryLabel: 'series[{0}].shapefile.geometryLabel',
  shapefilePrimaryKey: 'series[{0}].shapefile.primaryKey',
  shapefileUid: 'series[{0}].shapefile.uid',
  shapeFillColor: 'series[{0}].mapOptions.shapeFillColor',
  shapeFillOpacity: 'series[{0}].mapOptions.shapeFillOpacity',
  shapeOutlineColor: 'series[{0}].mapOptions.shapeOutlineColor',
  shapeOutlineWidth: 'series[{0}].mapOptions.shapeOutlineWidth',
  showAnnotationsInLegend: 'configuration.showAnnotationsInLegend',
  showDataTableControl: 'configuration.showDataTableControl',
  showDimensionLabels: 'configuration.showDimensionLabels',
  showLineStylePoints: 'series[{0}].lineStyle.points',
  showLineStylePointRadius: 'series[{0}].lineStyle.pointRadius',
  showLineValueLabels: 'configuration.showLineValueLabels',
  showNullsAsFalse: 'configuration.showNullsAsFalse',
  showOtherCategory: 'configuration.showOtherCategory',
  showValueLabels: 'configuration.showValueLabels',
  showValueLabelsAsPercent: 'configuration.showValueLabelsAsPercent',
  showLegend: 'series[{0}].showLegend',
  showLegendForMap: 'configuration.showLegendForMap',
  showLegendOpened: 'configuration.showLegendOpened',
  showMultiplePointsSymbolInLegend: 'configuration.showMultiplePointsSymbolInLegend',
  simplificationLevel: 'series[{0}].mapOptions.simplificationLevel',
  stacked: 'series[0].stacked',
  stackRadius: 'configuration.basemapOptions.stackRadius',
  stackedOneHundredPercent: 'series[0].stacked.oneHundredPercent',
  tableColumns: 'series[{0}].dataSource.dimension.columns',
  tableHierarchies: 'series[{0}].dataSource.hierarchies',
  columnFormat: 'series[{0}].columnFormat',
  columnFormats: 'series[{0}].formatting.columnFormat',
  headerFormat: 'series[{0}].formatting.headerFormat',
  headerTextFormat: 'series[{0}].formatting.headerFormat.fontStyle',
  rowFormat: 'series[{0}].rowFormat',
  activeHierarchyId: 'series[{0}].dataSource.activeHierarchyId',
  title: 'title',
  treatNullValuesAsZero: 'configuration.treatNullValuesAsZero',
  unitOne: 'series[{0}].unit.one',
  unitOther: 'series[{0}].unit.other',
  useSecondaryAxisForColumns: 'configuration.useSecondaryAxisForColumns',
  useSecondaryAxisForLines: 'configuration.useSecondaryAxisForLines',
  viewSourceDataLink: 'configuration.viewSourceDataLink',
  weighLinesBy: 'series[{0}].mapOptions.weighLinesBy',
  wrapDimensionLabels: 'configuration.wrapDimensionLabels',
  xAxisScalingMode: 'configuration.xAxisScalingMode',
  xAxisColumn: 'series[0].dataSource.measure.columnName',
  yAxisColumn: 'series[1].dataSource.measure.columnName',
  zeroColor: 'configuration.legend.zeroColor'
};

const hasVifPath = (vif) => (path) => _.has(vif, path);
const getVifPath = (vif) => (path) => _.get(vif, path, null);

/**
 * load() communicates to the redux store the individual
 * pieces of any VIF it encounters and attempts to create
 * actions. These actions are then processed by each VIF reducer and
 * applied to its configuration (if relevant).
 *
 * Loading also includes kicking off the dataSource HTTP requests
 * that grab dataset metadata.
 */
export const load = (dispatch, vif) => {
  const has = hasVifPath(vif);
  const get = getVifPath(vif);
  let primarySeriesIndex = 0;

  if (has(paths.series)) {
    const seriesCount = get(paths.series).length;
    let flyoutSeriesIndex = 0;

    for (let seriesIndex = 0; seriesIndex < seriesCount; seriesIndex++) {
      const seriesTypePath = formatString(paths.seriesType, seriesIndex);

      // Series types can be of the form: '{type}' or '{type}.{variant}'.
      // For instance: 'columnChart' or 'comboChart.line'
      // The visualizationType uses the first segment of the type of the first series.
      let seriesType;
      let seriesVariant;
      let visualizationType;

      if (has(seriesTypePath)) {
        seriesType = get(seriesTypePath);
        visualizationType = seriesType;

        const parts = seriesType.split('.');

        if (parts.length > 1) {
          visualizationType = parts[0];
          seriesVariant = parts[1];
        }
      }

      const isFlyoutSeries = seriesType === SERIES_TYPE_FLYOUT;
      const isMapSeries = seriesType === 'map';

      // Each visType has a default vif and they have default series array. For scatterChart the default
      // vif has four default empty series items whereas other charts have empty series array. So based
      // on the defaultSeriesLength, we append seriesItem to series or use the existing ones.
      let defaultSeriesLength;
      if (seriesType === 'scatterChart') {
        defaultSeriesLength = SCATTER_CHART.DEFAULT_VIF_SERIES_LENGTH;
      } else if (seriesType === 'calendar') {
        defaultSeriesLength = CALENDAR.DEFAULT_VIF_SERIES_LENGTH;
      } else {
        defaultSeriesLength = VIF_CONSTANTS.DEFAULT_VIF_SERIES_LENGTH;
      }

      if (seriesIndex > defaultSeriesLength) {
        // the first series already exists in the vif templates, no need to create it.

        dispatch(
          actions.appendSeries({
            isFlyoutSeries,
            isInitialLoad: true,
            seriesIndex,
            seriesVariant,
            isMapSeries
          })
        );
      } else {
        // Set visualization type to the type of the first series only
        if (!_.isNil(visualizationType)) {
          dispatch(actions.setVisualizationType(visualizationType));
        }

        // At present this only affects combo chart whose series type at index 0 could be,
        // comboChart.column or comboChart.line.  It defaults to comboChart.column so we need to
        // explicitly set the variant it to the vif value.
        if (!_.isNil(seriesVariant)) {
          dispatch(actions.setSeriesVariant(seriesIndex, seriesVariant));
        }
      }

      if (isMapSeries) {
        dispatch(actions.setCurrentMapLayerIndex(seriesIndex));

        if (get(formatString(paths.primaryLayer, seriesIndex))) {
          primarySeriesIndex = seriesIndex;
        }
      }

      const measureColumnNamePath = formatString(paths.measureColumnName, seriesIndex);
      if (has(measureColumnNamePath)) {
        dispatch(
          actions.setMeasureColumn({
            columnName: get(measureColumnNamePath),
            isFlyoutSeries,
            relativeIndex: isFlyoutSeries ? flyoutSeriesIndex : seriesIndex
          })
        );
      }

      const measureAggregationFunctionPath = formatString(paths.measureAggregationFunction, seriesIndex);
      if (has(measureAggregationFunctionPath)) {
        dispatch(
          actions.setMeasureAggregation({
            aggregationFunction: get(measureAggregationFunctionPath),
            isFlyoutSeries,
            relativeIndex: isFlyoutSeries ? flyoutSeriesIndex : seriesIndex
          })
        );
      }

      const primaryColorPath = formatString(paths.primaryColor, seriesIndex);
      if (has(primaryColorPath)) {
        dispatch(actions.setPrimaryColor(seriesIndex, get(primaryColorPath)));
      }

      const primaryLayerPath = formatString(paths.primaryLayer, seriesIndex);
      if (has(primaryLayerPath)) {
        dispatch(actions.setLayerAsPrimary(seriesIndex, get(primaryLayerPath)));
      }

      const secondaryColorPath = formatString(paths.secondaryColor, seriesIndex);
      if (has(secondaryColorPath)) {
        dispatch(actions.setSecondaryColor(seriesIndex, get(secondaryColorPath)));
      }

      const unitOnePath = formatString(paths.unitOne, seriesIndex);
      if (has(unitOnePath)) {
        dispatch(actions.setUnitsOne(seriesIndex, get(unitOnePath)));
      }

      const unitOtherPath = formatString(paths.unitOther, seriesIndex);
      if (has(unitOtherPath)) {
        dispatch(actions.setUnitsOther(seriesIndex, get(unitOtherPath)));
      }

      const pointOpacityPath = formatString(paths.pointOpacity, seriesIndex);
      if (has(pointOpacityPath)) {
        dispatch(actions.setPointOpacity(get(pointOpacityPath)));
      }

      const pointSizePath = formatString(paths.pointSize, seriesIndex);
      if (has(pointSizePath)) {
        dispatch(actions.setPointSize(get(pointSizePath)));
      }

      const lineWeightPath = formatString(paths.lineWeight, seriesIndex);
      if (has(lineWeightPath)) {
        dispatch(actions.setLineWeight(get(lineWeightPath)));
      }

      const lineColorOpacityPath = formatString(paths.lineColorOpacity, seriesIndex);
      if (has(lineColorOpacityPath)) {
        dispatch(actions.setLineColorOpacity(get(lineColorOpacityPath)));
      }

      const lineStylePoints = formatString(paths.showLineStylePoints, seriesIndex);
      if (has(lineStylePoints)) {
        dispatch(actions.setLineStylePoints(get(lineStylePoints)));
      }

      const lineStylePointRadius = formatString(paths.showLineStylePointRadius, seriesIndex);
      if (has(lineStylePointRadius)) {
        dispatch(actions.setLineStylePointRadius(get(lineStylePointRadius)));
      }

      const minimumLineWeightPath = formatString(paths.minimumLineWeight, seriesIndex);
      if (has(minimumLineWeightPath)) {
        dispatch(actions.setMinimumLineWeight(get(minimumLineWeightPath)));
      }

      const maximumLineWeightPath = formatString(paths.maximumLineWeight, seriesIndex);
      if (has(maximumLineWeightPath)) {
        dispatch(actions.setMaximumLineWeight(get(maximumLineWeightPath)));
      }

      const minimumPointSizePath = formatString(paths.minimumPointSize, seriesIndex);
      if (has(minimumPointSizePath)) {
        dispatch(actions.setMinimumPointSize(get(minimumPointSizePath)));
      }

      const maximumPointSizePath = formatString(paths.maximumPointSize, seriesIndex);
      if (has(maximumPointSizePath)) {
        dispatch(actions.setMaximumPointSize(get(maximumPointSizePath)));
      }

      const numberOfDataClassesPath = formatString(paths.numberOfDataClasses, seriesIndex);
      if (has(numberOfDataClassesPath)) {
        dispatch(actions.setNumberOfDataClasses(get(numberOfDataClassesPath)));
      }

      const colorByBucketsCountPath = formatString(paths.colorByBucketsCount, seriesIndex);
      if (has(colorByBucketsCountPath)) {
        dispatch(actions.setColorByBucketsCount(get(colorByBucketsCountPath)));
      }

      const midpointPath = formatString(paths.midpoint, seriesIndex);
      if (has(midpointPath)) {
        dispatch(actions.setMidpoint(get(midpointPath)));
      }

      const resizePointsByPath = formatString(paths.resizePointsBy, seriesIndex);
      if (has(resizePointsByPath)) {
        dispatch(actions.setResizePointsByColumn(get(resizePointsByPath)));
      }

      const colorPointsByPath = formatString(paths.colorPointsBy, seriesIndex);
      if (has(colorPointsByPath)) {
        dispatch(actions.setColorPointsByColumn(get(colorPointsByPath)));
      }

      const eventBackgroundColorPath = formatString(paths.eventBackgroundColor, seriesIndex);
      if (has(eventBackgroundColorPath)) {
        dispatch(actions.setEventBackgroundColor(get(eventBackgroundColorPath)));
      }

      const eventOutlineColorPath = formatString(paths.eventOutlineColor, seriesIndex);
      if (has(eventOutlineColorPath)) {
        dispatch(actions.setEventOutlineColor(get(eventOutlineColorPath)));
      }

      const eventTextColorPath = formatString(paths.eventTextColor, seriesIndex);
      if (has(eventTextColorPath)) {
        dispatch(actions.setEventTextColor(get(eventTextColorPath)));
      }

      const weighLinesByPath = formatString(paths.weighLinesBy, seriesIndex);
      if (has(weighLinesByPath)) {
        dispatch(actions.setWeighLinesByColumn(get(weighLinesByPath)));
      }

      const colorLinesByPath = formatString(paths.colorLinesBy, seriesIndex);
      if (has(colorLinesByPath)) {
        dispatch(actions.setColorLinesByColumn(get(colorLinesByPath)));
      }

      const colorBoundariesByPath = formatString(paths.colorBoundariesBy, seriesIndex);
      if (has(colorBoundariesByPath)) {
        dispatch(actions.setBoundaryColorByColumn(get(colorBoundariesByPath)));
      }

      const mapTypePath = formatString(paths.mapType, seriesIndex);
      if (has(mapTypePath)) {
        dispatch(actions.setMapType(get(mapTypePath)));
      }

      const colorByQuantificationMethodPath = formatString(paths.colorByQuantificationMethod, seriesIndex);
      if (has(colorByQuantificationMethodPath)) {
        dispatch(actions.setColorByQuantificationMethod(get(colorByQuantificationMethodPath)));
      }

      const rangeBucketTypePath = formatString(paths.rangeBucketType, seriesIndex);
      if (has(rangeBucketTypePath)) {
        dispatch(actions.setRangeBucketType(get(rangeBucketTypePath)));
      }

      const pointAggregationPath = formatString(paths.pointAggregation, seriesIndex);
      if (has(pointAggregationPath)) {
        dispatch(actions.setPointAggregation(get(pointAggregationPath)));
      }

      const shapefileGeometryLabelPath = formatString(paths.shapefileGeometryLabel, seriesIndex);
      if (has(shapefileGeometryLabelPath)) {
        dispatch(actions.setShapefileGeometryLabel(get(shapefileGeometryLabelPath)));
      }

      const shapefilePrimaryKeyPath = formatString(paths.shapefilePrimaryKey, seriesIndex);
      if (has(shapefilePrimaryKeyPath)) {
        dispatch(actions.setShapefilePrimaryKey(get(shapefilePrimaryKeyPath)));
      }

      const shapefileUidPath = formatString(paths.shapefileUid, seriesIndex);
      if (has(shapefileUidPath)) {
        dispatch(actions.setShapefileUid(get(shapefileUidPath)));
      }

      const mapFlyoutTitleColumnNamePath = formatString(paths.mapFlyoutTitleColumnName, seriesIndex);
      if (has(mapFlyoutTitleColumnNamePath)) {
        dispatch(actions.setMapFlyoutTitleColumnName(get(mapFlyoutTitleColumnNamePath)));
      }

      const additionalFlyoutColumnsPath = formatString(paths.additionalFlyoutColumns, seriesIndex);
      if (has(additionalFlyoutColumnsPath)) {
        dispatch(actions.setAdditionalFlyoutColumns(get(additionalFlyoutColumnsPath)));
      }

      const regionMapFlyoutColumnAndAggregationsPath = formatString(
        paths.regionMapFlyoutColumnAndAggregations,
        seriesIndex
      );
      if (has(regionMapFlyoutColumnAndAggregationsPath)) {
        dispatch(
          actions.setRegionMapFlyoutColumnAndAggregations(get(regionMapFlyoutColumnAndAggregationsPath))
        );
      }

      const shapeFillColorPath = formatString(paths.shapeFillColor, seriesIndex);
      if (has(shapeFillColorPath)) {
        dispatch(actions.setShapeFillColor(get(shapeFillColorPath)));
      }

      const shapeFillOpacityPath = formatString(paths.shapeFillOpacity, seriesIndex);
      if (has(shapeFillOpacityPath)) {
        dispatch(actions.setShapeFillOpacity(get(shapeFillOpacityPath)));
      }

      const shapeOutlineColorPath = formatString(paths.shapeOutlineColor, seriesIndex);
      if (has(shapeOutlineColorPath)) {
        dispatch(actions.setShapeOutlineColor(get(shapeOutlineColorPath)));
      }

      const shapeOutlineWidthPath = formatString(paths.shapeOutlineWidth, seriesIndex);
      if (has(shapeOutlineWidthPath)) {
        dispatch(actions.setShapeOutlineWidth(get(shapeOutlineWidthPath)));
      }

      const mapLayerVisiblePath = formatString(paths.mapLayerVisible, seriesIndex);
      if (has(mapLayerVisiblePath)) {
        dispatch(actions.setMapLayerVisible(seriesIndex, get(mapLayerVisiblePath)));
      }

      const simplificationLevelPath = formatString(paths.simplificationLevel, seriesIndex);
      if (has(simplificationLevelPath)) {
        dispatch(actions.setSimplificationLevel(seriesIndex, get(simplificationLevelPath)));
      }

      const castNullAsFalseInSeriesPath = formatString(paths.castNullAsFalseInSeries, seriesIndex);
      if (has(castNullAsFalseInSeriesPath)) {
        dispatch(actions.setCastNullAsFalseInSeries(get(castNullAsFalseInSeriesPath)));
      }

      const charmNamePath = formatString(paths.charmName, seriesIndex);
      if (has(charmNamePath)) {
        dispatch(actions.setCharmName(seriesIndex, get(charmNamePath)));
      }

      const showLegendPath = formatString(paths.showLegend, seriesIndex);
      if (has(showLegendPath)) {
        dispatch(actions.setShowLegend(get(showLegendPath)));
      }

      if (has(paths.showLegendForMap)) {
        dispatch(actions.setShowLegendForMap(get(paths.showLegendForMap)));
      }

      const computedColumnNamePath = formatString(paths.computedColumnName, seriesIndex);
      if (has(computedColumnNamePath)) {
        dispatch(actions.setComputedColumn(get(computedColumnNamePath)));
      }

      const customColorPalettePath = formatString(paths.customColorPalette, seriesIndex);
      if (has(customColorPalettePath)) {
        _.each(get(customColorPalettePath), (palette, columnName) => {
          dispatch(actions.setCustomColorPalette(palette, columnName, seriesIndex));
        });
      }

      const datasetNamePath = formatString(paths.datasetName, seriesIndex);
      if (has(datasetNamePath)) {
        dispatch(actions.setMapLayerName(get(datasetNamePath)));
      }

      const datasetUidPath = formatString(paths.datasetUid, seriesIndex);
      if (has(datasetUidPath)) {
        dispatch(actions.setDatasetUid(get(datasetUidPath)));
      }

      const domainPath = formatString(paths.domain, seriesIndex);
      if (has(domainPath)) {
        dispatch(actions.setDomain(get(domainPath)));
      }

      const dimensionColumnNamePath = formatString(paths.dimensionColumnName, seriesIndex);
      if (has(dimensionColumnNamePath)) {
        dispatch(actions.setDimension(get(dimensionColumnNamePath)));
      }

      const currentDrilldownColumnNamePath = formatString(paths.currentDrilldownColumnName, seriesIndex);
      if (has(currentDrilldownColumnNamePath)) {
        dispatch(actions.setCurrentDrilldownColumnName(get(currentDrilldownColumnNamePath)));
      }

      const drilldownsPath = formatString(paths.drilldowns, seriesIndex);
      if (has(drilldownsPath)) {
        dispatch(actions.setDrilldowns(get(drilldownsPath)));
      }

      const tableColumnsPath = formatString(paths.tableColumns, seriesIndex);
      if (has(tableColumnsPath)) {
        dispatch(actions.setTableColumns(get(tableColumnsPath)));
      }

      const tableHierarchiesPath = formatString(paths.tableHierarchies, seriesIndex);
      if (has(tableHierarchiesPath)) {
        dispatch(actions.setTableHierarchies(get(tableHierarchiesPath)));
      }

      const columnFormatPath = formatString(paths.columnFormat, seriesIndex);
      if (has(columnFormatPath)) {
        dispatch(actions.setColumnFormat(get(columnFormatPath)));
      }

      const columnFormatsPath = formatString(paths.columnFormats, seriesIndex);
      if (has(columnFormatsPath)) {
        dispatch(actions.setTableVisualizationColumnFormats(get(columnFormatsPath)));
      }

      const headerFormatPath = formatString(paths.headerFormat, seriesIndex);
      if (has(headerFormatPath)) {
        dispatch(actions.updateTableVisualizationHeaderStyle(get(headerFormatPath)));
      }

      const headerTextFormatPath = formatString(paths.headerTextFormat, seriesIndex);
      if (has(headerTextFormatPath)) {
        dispatch(actions.updateTableVisualizationHeaderTextStyle(get(headerTextFormatPath)));
      }

      const rowFormatPath = formatString(paths.rowFormat, seriesIndex);
      if (has(rowFormatPath)) {
        dispatch(actions.setRowFormat(get(rowFormatPath)));
      }

      const activeHierarchyIdPath = formatString(paths.activeHierarchyId, seriesIndex);
      if (has(activeHierarchyIdPath)) {
        dispatch(actions.setActiveHierarchyId(get(activeHierarchyIdPath)));
      }

      // When removing enable_flexible_table_hierarchies: We don't need to set this anymore
      const nonStandardAggregationPath = formatString(paths.nonStandardAggregation, seriesIndex);
      if (has(nonStandardAggregationPath)) {
        dispatch(actions.setNonStandardAggregation(get(nonStandardAggregationPath)));
      }

      const dimensionGroupingColumnNamePath = formatString(paths.dimensionGroupingColumnName, seriesIndex);
      if (has(dimensionGroupingColumnNamePath)) {
        dispatch(actions.setDimensionGroupingColumnName(get(dimensionGroupingColumnNamePath)));
      }

      // This must happen after the grouping column name is set on the vif.
      const colorPalettePath = formatString(paths.colorPalette, seriesIndex);
      if (has(colorPalettePath)) {
        dispatch(actions.setColorPalette(get(colorPalettePath)));
      }

      dispatch(
        actions.setDataSource(
          get(formatString(paths.domain, seriesIndex)),
          get(formatString(paths.datasetUid, seriesIndex)),
          null,
          true,
          seriesIndex
        )
      );

      dispatch(
        actions.setCuratedRegions(
          get(formatString(paths.domain, seriesIndex)),
          get(formatString(paths.datasetUid, seriesIndex)),
          seriesIndex
        )
      );

      if (FeatureFlags.value('computed_region_system_columns')) {
        dispatch(actions.setComputedRegionColumns(
          get(formatString(paths.domain, seriesIndex)),
          get(formatString(paths.datasetUid, seriesIndex)),
          seriesIndex
        ));
      }

      if (isFlyoutSeries) {
        flyoutSeriesIndex++;
      }

      if (isMapSeries && seriesIndex !== VIF_CONSTANTS.DEFAULT_VIF_SERIES_LENGTH) {
        dispatch(actions.setColorPaletteProperties());
      }
    }

    if (get(formatString(paths.seriesType, 0)) === 'map') {
      dispatch(actions.setCurrentMapLayerIndex(primarySeriesIndex));
      dispatch(actions.setCurrentMapLayerMode('list_view'));
    }
  }

  if (has(paths.referenceLines)) {
    const referenceLinesCount = get(paths.referenceLines).length;

    for (let i = 0; i < referenceLinesCount; i++) {
      dispatch(actions.appendReferenceLine());

      const referenceLineColorPath = formatString(paths.referenceLineColor, i);
      if (has(referenceLineColorPath)) {
        dispatch(
          actions.setReferenceLineColor({ referenceLineIndex: i, color: get(referenceLineColorPath) })
        );
      }

      const referenceLineLabelPath = formatString(paths.referenceLineLabel, i);
      if (has(referenceLineLabelPath)) {
        dispatch(
          actions.setReferenceLineLabel({ referenceLineIndex: i, label: get(referenceLineLabelPath) })
        );
      }

      const referenceLineValuePath = formatString(paths.referenceLineValue, i);
      if (has(referenceLineValuePath)) {
        dispatch(
          actions.setReferenceLineValue({ referenceLineIndex: i, value: get(referenceLineValuePath) })
        );
      }
    }
  }

  if (has(paths.baseLayerOpacity)) {
    dispatch(actions.setBaseLayerOpacity(get(paths.baseLayerOpacity)));
  }

  if (has(paths.baseLayerUrl)) {
    dispatch(actions.setBaseLayer(get(paths.baseLayerUrl)));
  }

  if (has(paths.dateDisplayFormat)) {
    dispatch(actions.setDateDisplayFormat(get(paths.dateDisplayFormat)));
  }

  if (has(paths.description)) {
    dispatch(actions.setDescription(get(paths.description)));
  }

  if (has(paths.columnOrderConfiguration)) {
    dispatch(actions.setColumnOrderConfiguration(get(paths.columnOrderConfiguration)));
  }

  if (has(paths.errorBarsBarColor)) {
    dispatch(actions.setErrorBarsBarColor(get(paths.errorBarsBarColor)));
  }

  if (has(paths.errorBarsLowerBoundColumnName)) {
    dispatch(actions.setErrorBarsLowerBoundColumnName(get(paths.errorBarsLowerBoundColumnName)));
  }

  if (has(paths.errorBarsUpperBoundColumnName)) {
    dispatch(actions.setErrorBarsUpperBoundColumnName(get(paths.errorBarsUpperBoundColumnName)));
  }

  if (has(paths.isIndented)) {
    dispatch(actions.setTableIndentedLayout(get(paths.isIndented)));
  }

  if (has(paths.labelBottom)) {
    dispatch(actions.setLabelBottom(get(paths.labelBottom)));
  }

  if (has(paths.labelLeft)) {
    dispatch(actions.setLabelLeft(get(paths.labelLeft)));
  }

  if (has(paths.labelRight)) {
    dispatch(actions.setLabelRight(get(paths.labelRight)));
  }

  if (has(paths.labelTop)) {
    dispatch(actions.setLabelTop(get(paths.labelTop)));
  }

  if (has(paths.legendPosition)) {
    dispatch(actions.setLegendPosition(get(paths.legendPosition)));
  }

  if (has(paths.lockCalendarViewControl)) {
    dispatch(actions.setLockCalendarViewControl(get(paths.lockCalendarViewControl)));
  }

  if (has(paths.logarithmicScale)) {
    dispatch(actions.setLogarithmicScale(get(paths.logarithmicScale)));
  }

  if (has(paths.dimensionLabelAreaSize)) {
    dispatch(actions.setDimensionLabelAreaSize(get(paths.dimensionLabelAreaSize)));
  }

  if (has(paths.defaultDisplayDate)) {
    const lockCalendarViewControl = get(paths.lockCalendarViewControl);

    if (lockCalendarViewControl) {
      dispatch(actions.setDefaultDisplayDate(get(paths.defaultDisplayDate)));
    } else {
      dispatch(actions.setDefaultDisplayDate(getTodayDate()));
    }
  }

  if (has(paths.dimensionLabelRotationAngle)) {
    dispatch(actions.setDimensionLabelRotationAngle(get(paths.dimensionLabelRotationAngle)));
  }

  if (has(paths.limit)) {
    if (has(paths.showOtherCategory)) {
      dispatch(actions.setLimitCountAndShowOtherCategory(get(paths.limit), get(paths.showOtherCategory)));
    } else {
      dispatch(actions.setLimitCountAndShowOtherCategory(get(paths.limit), false));
    }
  } else if (has(paths.showOtherCategory)) {
    dispatch(actions.setShowOtherCategory(get(paths.showOtherCategory)));
  }

  if (has(paths.mapCenterAndZoom)) {
    dispatch(actions.setCenterAndZoom(get(paths.mapCenterAndZoom)));
  }

  if (has(paths.measureAxisMaxValue)) {
    dispatch(actions.setMeasureAxisMaxValue(get(paths.measureAxisMaxValue)));
  }

  if (has(paths.measureAxisMinValue)) {
    dispatch(actions.setMeasureAxisMinValue(get(paths.measureAxisMinValue)));
  }

  if (has(paths.measureAxisPrecision)) {
    dispatch(actions.setMeasureAxisPrecision(get(paths.measureAxisPrecision)));
  }

  if (has(paths.mapLegendPrecision)) {
    dispatch(actions.setMapLegendPrecision(get(paths.mapLegendPrecision)));
  }

  if (has(paths.mapFlyoutPrecision)) {
    dispatch(actions.setMapFlyoutPrecision(get(paths.mapFlyoutPrecision)));
  }

  if (has(paths.measureAxisScale)) {
    dispatch(actions.setMeasureAxisScale(get(paths.measureAxisScale)));
  }

  if (has(paths.secondaryMeasureAxisMaxValue)) {
    dispatch(actions.setSecondaryMeasureAxisMaxValue(get(paths.secondaryMeasureAxisMaxValue)));
  }

  if (has(paths.secondaryMeasureAxisMinValue)) {
    dispatch(actions.setSecondaryMeasureAxisMinValue(get(paths.secondaryMeasureAxisMinValue)));
  }

  if (has(paths.secondaryMeasureAxisPrecision)) {
    dispatch(actions.setSecondaryMeasureAxisPrecision(get(paths.secondaryMeasureAxisPrecision)));
  }

  if (has(paths.secondaryMeasureAxisScale)) {
    dispatch(actions.setSecondaryMeasureAxisScale(get(paths.secondaryMeasureAxisScale)));
  }

  if (has(paths.negativeColor)) {
    dispatch(actions.setNegativeColor(get(paths.negativeColor)));
  }

  if (has(paths.groupingOrderBy)) {
    dispatch(actions.setGroupingOrderBy(get(paths.groupingOrderBy)));
  }

  if (has(paths.orderBy)) {
    dispatch(actions.setOrderBy(get(paths.orderBy)));
  }

  if (has(paths.positiveColor)) {
    dispatch(actions.setPositiveColor(get(paths.positiveColor)));
  }

  if (has(paths.precision)) {
    dispatch(actions.setPrecision(get(paths.precision)));
  }

  if (has(paths.rowInspectorTitleColumnName)) {
    dispatch(actions.setRowInspectorTitleColumnName(get(paths.rowInspectorTitleColumnName)));
  }

  if (has(paths.showDimensionLabels)) {
    dispatch(actions.setShowDimensionLabels(get(paths.showDimensionLabels)));
  }

  if (has(paths.showLineValueLabels)) {
    dispatch(actions.setShowLineValueLabels(get(paths.showLineValueLabels)));
  }

  if (has(paths.showNullsAsFalse)) {
    dispatch(actions.setShowNullsAsFalse(get(paths.showNullsAsFalse)));
  }

  if (has(paths.showValueLabels)) {
    dispatch(actions.setShowValueLabels(get(paths.showValueLabels)));
  }

  if (has(paths.showValueLabelsAsPercent)) {
    dispatch(actions.setShowValueLabelsAsPercent(get(paths.showValueLabelsAsPercent)));
  }

  if (has(paths.showLegendOpened)) {
    dispatch(actions.setShowLegendOpened(get(paths.showLegendOpened)));
  }

  if (has(paths.showMultiplePointsSymbolInLegend)) {
    dispatch(actions.setShowMultiplePointsSymbolInLegend(get(paths.showMultiplePointsSymbolInLegend)));
  }

  if (has(paths.stackedOneHundredPercent)) {
    dispatch(actions.setStacked({ stacked: true, oneHundredPercent: get(paths.stackedOneHundredPercent) }));
  } else if (has(paths.stacked)) {
    dispatch(actions.setStacked({ stacked: true, oneHundredPercent: false }));
  }

  if (has(paths.title)) {
    dispatch(actions.setTitle(get(paths.title)));
  }

  if (has(paths.treatNullValuesAsZero)) {
    dispatch(actions.setTreatNullValuesAsZero(get(paths.treatNullValuesAsZero)));
  }

  if (has(paths.useSecondaryAxisForColumns)) {
    dispatch(actions.setUseSecondaryAxisForColumns(get(paths.useSecondaryAxisForColumns)));
  }

  if (has(paths.useSecondaryAxisForLines)) {
    dispatch(actions.setUseSecondaryAxisForLines(get(paths.useSecondaryAxisForLines)));
  }

  if (has(paths.viewSourceDataLink)) {
    dispatch(actions.setViewSourceDataLink(get(paths.viewSourceDataLink)));
  }

  if (has(paths.annotations)) {
    dispatch(actions.setAnnotations(get(paths.annotations)));
  }

  if (has(paths.wrapDimensionLabels)) {
    dispatch(actions.setWrapDimensionLabels(get(paths.wrapDimensionLabels)));
  }

  if (has(paths.xAxisScalingMode)) {
    const shouldFit = get(paths.xAxisScalingMode) === 'fit';
    dispatch(actions.setXAxisScalingMode({ shouldFit }));
  }

  if (has(paths.plotSize)) {
    dispatch(actions.setPlotSize(get(paths.plotSize)));
  }

  if (has(paths.xAxisColumn)) {
    dispatch(actions.setXAxisColumn(get(paths.xAxisColumn)));
  }

  const isCalendarType = get(formatString(paths.seriesType, CALENDAR.START_DATE_SERIES_INDEX)) === 'calendar';
  if (isCalendarType) {
    const startDateColumnPath = formatString(paths.dimensionColumnName, CALENDAR.START_DATE_SERIES_INDEX);
    if (startDateColumnPath) {
      dispatch(actions.setStartDateColumn(get(startDateColumnPath)));
    }

    const endDateColumnPath = formatString(paths.dimensionColumnName, CALENDAR.END_DATE_SERIES_INDEX);
    if (endDateColumnPath) {
      dispatch(actions.setEndDateColumn(get(endDateColumnPath)));
    }

    const eventTitleColumnPath = formatString(paths.dimensionColumnName, CALENDAR.EVENT_TITLE_SERIES_INDEX);
    if (eventTitleColumnPath) {
      dispatch(actions.setEventTitleColumn(get(eventTitleColumnPath)));
    }
  }

  if (has(paths.minimumPlotSize)) {
    dispatch(actions.setMinimumPlotSize(get(paths.minimumPlotSize)));
  }

  if (has(paths.maximumPlotSize)) {
    dispatch(actions.setMaximumPlotSize(get(paths.maximumPlotSize)));
  }

  if (has(paths.scatterChartNumberOfDataClasses)) {
    dispatch(actions.setNumberOfDataClasses(get(paths.scatterChartNumberOfDataClasses)));
  }

  if (has(paths.yAxisColumn)) {
    dispatch(actions.setYAxisColumn(get(paths.yAxisColumn)));
  }

  if (has(paths.zeroColor)) {
    dispatch(actions.setZeroColor(get(paths.zeroColor)));
  }

  if (has(paths.maxClusteringZoomLevel)) {
    dispatch(actions.setMaxClusteringZoomLevel(get(paths.maxClusteringZoomLevel)));
  }

  if (has(paths.pointThreshold)) {
    dispatch(actions.setPointThreshold(get(paths.pointThreshold)));
  }

  if (has(paths.clusterRadius)) {
    dispatch(actions.setClusterRadius(get(paths.clusterRadius)));
  }

  if (has(paths.maxClusterSize)) {
    dispatch(actions.setMaxClusterSize(get(paths.maxClusterSize)));
  }

  if (has(paths.colorByColumn)) {
    const colorByColumnSeriesType = get(formatString(paths.seriesType, SCATTER_CHART.COLOR_BY_SERIES_INDEX));

    if (colorByColumnSeriesType === 'scatterChart') {
      dispatch(actions.setColorByColumn(get(paths.colorByColumn)));
    }
  }

  if (has(paths.resizeByColumn)) {
    const resizeByColumnSeriesType = get(
      formatString(paths.seriesType, SCATTER_CHART.RESIZE_BY_SERIES_INDEX)
    );

    if (resizeByColumnSeriesType === 'scatterChart') {
      dispatch(actions.setResizeByColumn(get(paths.resizeByColumn)));
    }
  }

  if (has(paths.stackRadius)) {
    dispatch(actions.setStackRadius(get(paths.stackRadius)));
  }

  if (has(paths.navigationControl)) {
    dispatch(actions.setNavigationControl(get(paths.navigationControl)));
  }

  if (has(paths.layerToggleControl)) {
    dispatch(actions.setLayerToggleControl(get(paths.layerToggleControl)));
  }

  if (has(paths.geoCoderControl)) {
    dispatch(actions.setGeoCoderControl(get(paths.geoCoderControl)));
  }

  if (has(paths.geoLocateControl)) {
    dispatch(actions.setGeoLocateControl(get(paths.geoLocateControl)));
  }

  if (has(paths.searchBoundaryLowerRightLatitude)) {
    dispatch(actions.setSearchBoundaryLowerRightLatitude(get(paths.searchBoundaryLowerRightLatitude)));
  }

  if (has(paths.searchBoundaryLowerRightLongitude)) {
    dispatch(actions.setSearchBoundaryLowerRightLongitude(get(paths.searchBoundaryLowerRightLongitude)));
  }

  if (has(paths.searchBoundaryUpperLeftLatitude)) {
    dispatch(actions.setSearchBoundaryUpperLeftLatitude(get(paths.searchBoundaryUpperLeftLatitude)));
  }

  if (has(paths.searchBoundaryUpperLeftLongitude)) {
    dispatch(actions.setSearchBoundaryUpperLeftLongitude(get(paths.searchBoundaryUpperLeftLongitude)));
  }

  if (has(paths.basemapStyle)) {
    dispatch(actions.setBasemapStyle(get(paths.basemapStyle)));
  }

  if (has(paths.basemapOpacity)) {
    dispatch(actions.setBasemapOpacity(get(paths.basemapOpacity)));
  }

  if (has(paths.showDataTableControl)) {
    dispatch(actions.setShowDataTableControl(get(paths.showDataTableControl)));
  }

  if (has(paths.showAnnotationsInLegend)) {
    dispatch(actions.setShowAnnotationsInLegend(get(paths.showAnnotationsInLegend)));
  }

  if (has(paths.mapPitchAndBearing)) {
    dispatch(actions.setPitchAndBearing(get(paths.mapPitchAndBearing)));
  }

  if (has(paths.mapZoomLevel)) {
    dispatch(actions.setMapZoomLevel(get(paths.mapZoomLevel)));
  }

  if (has(paths.rowStripeStyle)) {
    dispatch(actions.setTableRowStripeStyleAction(get(paths.rowStripeStyle)));
  }

  dispatch(
    actions.setDataSource(
      get(formatString(paths.domain, primarySeriesIndex)),
      get(formatString(paths.datasetUid, primarySeriesIndex)),
      null,
      true,
      primarySeriesIndex
    )
  );
};
