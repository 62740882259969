import $ from 'jquery';
import React, { FunctionComponent, useRef, useEffect } from 'react';
import * as SocrataVisualizations from 'common/visualizations';
import { renderStoryComponent } from '../utils';
import 'lib/components/block-component-renderers/shared/componentBase';
import 'lib/components/block-component-renderers';
import 'lib/components/Modal';
import { BlockComponent } from 'types';
import { GlobalFilters, GlobalParameterOverrides } from 'types';

interface Props {
  component: BlockComponent;
  globalFilters: GlobalFilters;
  parameterOverrides: GlobalParameterOverrides;
}

SocrataVisualizations.views.RowInspector.setup();

const ViewBlockItem: FunctionComponent<Props> = (props) => {
  const elementRef = useRef<null | HTMLDivElement>(null);
  const { component, globalFilters, parameterOverrides } = props;

  useEffect(() => {
    if (elementRef.current) {
      const $element = $(elementRef.current);
      const elementProps = {
        blockId: null,
        componentIndex: null,
        theme: null,
        componentData: component,
        additionalFilters: globalFilters,
        parameterOverrides: parameterOverrides
      };

      renderStoryComponent({ elementProps, $element });
    }
  });

  return (
    <div
      ref={elementRef}
      className={`component-container flexible-component col${component.layout.w} ${
        component.type == 'html' ? 'typeset squire-formatted' : ''
      }`}
    />
  );
};

export default ViewBlockItem;
