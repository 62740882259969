/* Imports */
/* ============================================================================= */
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { BaseGuidanceSummaryV2Helper } from 'common/core/approvals/Types';
import { getOnlyMatchingWorkflow } from '.';





/* Method */
/* ============================================================================= */
/**
 * Return the target audience of the workflow which has a pending approval request.
 * Throws an error if more than 1 workflow has a pending approval request.
 * Returns undefinded if no workflows do.
 * @param guidanceHelper BaseGuidanceSummaryV2Helper object
 * @returns WorkflowTargetAudience value or undefined
 */
export const whichIsPending = (guidanceHelper: BaseGuidanceSummaryV2Helper): WorkflowTargetAudience | undefined => {
  const workflows = guidanceHelper.summarizeIsPending();

  return workflows.length === 1 ? getOnlyMatchingWorkflow(workflows) : undefined;
};

export default whichIsPending;
