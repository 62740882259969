import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import I18n from 'common/i18n';
import currentUserCanManageViewers from 'common/views/current_user_can_manage_viewers';
import { MODES } from 'common/components/AccessManager/Constants';
import { AccessLevelName, AccessLevelVersion } from 'common/types/view';
import { userHasAccessLevel } from 'common/components/AccessManager/Util';
import * as uiActions from 'common/components/AccessManager/actions/UiActions';
import {
  getAddedUsers,
  getApprovalsGuidance,
  getCurrentScope,
  getCurrentView,
  getUiMode,
  getFooterConfig
} from 'common/components/AccessManager/sagas/Selectors';

const i18nScope = 'shared.site_chrome.access_manager.collaborators_toggle';

const CollaboratorsToggle: FunctionComponent = () => {
  const dispatch = useDispatch();
  const mode = useSelector(getUiMode);
  const users = useSelector(getAddedUsers);
  const scope = useSelector(getCurrentScope);
  const view = useSelector(getCurrentView);
  const approvalsGuidance = useSelector(getApprovalsGuidance);
  const { hideCollaboratorsToggle } = useSelector(getFooterConfig);

  const showManageViewersToggle =
    mode != MODES.MANAGE_PLUGIN &&
    view &&
    approvalsGuidance &&
    currentUserCanManageViewers(view, approvalsGuidance);

  if (hideCollaboratorsToggle) {
    return null;
  }

  if (![MODES.MANAGE_COLLABORATORS, MODES.MANAGE_PLUGIN, MODES.CHANGE_AUDIENCE].includes(mode)) {
    return null;
  }

  if (!users || !scope) {
    // Prevent a flashing "missing translation" message
    return null;
  }

  // TODO: replace these with reselect selectors if updates seem sluggish
  let viewCount = 0;
  let editCount = 0;
  users.forEach((user) => {
    if (userHasAccessLevel(user, AccessLevelName.Viewer, AccessLevelVersion.Published)) {
      viewCount += 1;
    } else {
      editCount += 1;
    }
  });

  // Note the viewCount is wrong/confusing when looking at the draft.
  const viewText = I18n.t(`view.${scope}`, { count: viewCount, scope: i18nScope });
  const accessText = I18n.t('access.any', { count: editCount + viewCount, scope: i18nScope });
  const editText = I18n.t('edit.any', { count: editCount, scope: i18nScope });

  const linkToViewers = [MODES.MANAGE_COLLABORATORS, MODES.MANAGE_PLUGIN].includes(mode);
  const linkToEditors = ![MODES.MANAGE_COLLABORATORS, MODES.MANAGE_PLUGIN].includes(mode);

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    dispatch(uiActions.switchModes(linkToViewers ? MODES.CHANGE_AUDIENCE : MODES.MANAGE_COLLABORATORS));
    event.preventDefault();
  };

  const viewersLink = linkToViewers ? (
    <a href="#" onClick={onClick}>
      {viewText}
    </a>
  ) : (
    viewText
  );
  const spacer = ' • ';

  return (
    <div>
      {showManageViewersToggle && viewersLink}
      {showManageViewersToggle && spacer}
      {linkToEditors ? (
        <a href="#" onClick={onClick}>
          {editText}
        </a>
      ) : mode === MODES.MANAGE_PLUGIN ? (
        accessText
      ) : (
        editText
      )}
    </div>
  );
};

export default CollaboratorsToggle;
