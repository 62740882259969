import React, { ReactElement } from 'react';
import cx from 'classnames';

import Dropdown from 'common/components/Dropdown';

/** The value behind an option in the dropdown */

/** A single option in the dropdown list */
export interface DropdownOption<T> {
  title?: string;
  value: T;
  group?: string;
  render?: (op: DropdownOption<T>) => JSX.Element | null;
  disabled?: boolean;
}

export interface PicklistAttributes<T> {
  id?: string;
  disabled?: boolean;
  value?: T;
  horizontal?: boolean;
  options: DropdownOption<T>[];
  onSelection?: (value: T) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  size?: 'small' | 'medium' | 'large';
}

export enum DropdownSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

interface Props<T> {
  /**
   * Used in the htmlFor and a aria-labelledBy
   * This should be unique for every dropdown on the page
   */
  dropdownLabel?: string;

  /** Content to put in the label that's above the dropdown */
  labelContent?: string | JSX.Element;

  /** Optional classname to add to the dropdown */
  dropdownClassName?: string;

  /** Optional classNames for the <label /> component */
  labelClassName?: string;

  /** classNames for the wrapper around the label and dropdown */
  wrapperClassName?: string;

  /** Array of options that are present in the dropdown */
  options: DropdownOption<T>[];

  /**
   * Sizing strategy for the underlying picklist
   * @see common/components/Picklist
   */
  picklistSizingStrategy?: 'EXPAND_TO_WIDEST_ITEM' | 'SAME_AS_DROPDOWN';

  /** Whether or not this dropdown is currently disabled */
  disabled?: boolean;

  /** Size of the dropdown */
  size?: DropdownSize;

  /** Optional id to give the dropdown */
  id?: string;

  /**
   * The currently selected value of the dropdown
   * If this is null or undefined, it means that nothing is selected.
   */
  value?: T;

  /** Callback for when the user changes their selection */
  handleSelection: ({ value }: { value: T }) => void;

  /**
   * Whether or not to show the options below the handle of the dropdown
   * ("handle" here being the element you click to open it)
   * If this is false, the options are shown on top of the handle
   * Default: false
   */
  showOptionsBelowHandle?: boolean;

  /** What to display in the dropdown when value is undefined or null */
  placeholder?: string | JSX.Element | (() => JSX.Element);

  buildPicklist?: (picklistAttributes: PicklistAttributes<T>) => JSX.Element | null;
}

/**
 * Provides a label similar to <Input /> and Form components attached to a <Dropdown />
 * @see common/components/Dropdown
 */
export function DropdownWithLabel<T>({
  dropdownLabel,
  wrapperClassName,
  labelClassName,
  labelContent,
  dropdownClassName = '',
  handleSelection,
  ...props
}: Props<T>): ReactElement {
  return (
    <div className={cx('form-component-dropdown__wrapper', wrapperClassName)}>
      <div className={cx('form-component-dropdown__label', labelClassName)}>
        <label htmlFor={dropdownLabel} id={dropdownLabel}>
          {labelContent}
        </label>
      </div>
      <Dropdown
        {...props}
        className={cx('form-component-dropdown', dropdownClassName)}
        labelledBy={dropdownLabel}
        onSelection={handleSelection}
      />
    </div>
  );
}

export default DropdownWithLabel;
