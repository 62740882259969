import _ from 'lodash';

export interface UserSessionState {
  isSessionValid: boolean;
  loginInProgress: boolean;
  cookieCheckInterval: NodeJS.Timeout | null;
  checkUserSession: () => void;
  options: {
    sessionCheckDebounceMilliseconds: number;
    cookieCheckIntervalMilliseconds: number;
  };
}

export const isSessionValid = (state: UserSessionState) => {
  return state.isSessionValid;
};

export const _onAjaxError = (state: UserSessionState, event: any, jqXhr: any) => {
  //Recheck if expired or invalid
  if (jqXhr.status === 401 || jqXhr.status === 403) {
    state.checkUserSession();
  }
};

export const _loginButtonClick = (state: UserSessionState) => {
  if (!state.loginInProgress) {
    /*
      Recheck the session.

      Due to how LoginWindowRenderer works we must set _loginInProgress synchronously
      (otherwise the browser thinks the login window is a popup, and prevents it from opening).
      Currently if the user session validates without intervention, the login window will open
      and immediately close. This scenario is rare and resolving it is not in the pipeline.
    */
    state.checkUserSession();
    state.loginInProgress = true;
  }
};

export const _onCookieChange = (state: UserSessionState) => {
  let lastCookie = document.cookie;
  state.cookieCheckInterval = setInterval(() => {
    if (document.cookie !== lastCookie) {
      lastCookie = document.cookie;
      state.checkUserSession();
    }
  }, state.options.cookieCheckIntervalMilliseconds);
};

export const debounceCheckUserSession = (state: UserSessionState, emitChange: () => void) => {
  return _.debounce(() => {
    $.get('/api/users/current.json').then(
      function success() {
        if (!state.isSessionValid) {
          state.isSessionValid = true;
          state.loginInProgress = false;
          emitChange();
        }
      },
      function error() {
        if (state.isSessionValid) {
          state.isSessionValid = false;
          emitChange();
        }
      }
    );
  }, state.options.sessionCheckDebounceMilliseconds);
};
