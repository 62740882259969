import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { hasOutgoingInternalToPublicFederation } from 'common/federation/utils';
import I18n from 'common/i18n';
import { isDataFederationUnsupportedAssetType } from 'common/js_utils/federation';
import type { FederatedSite } from 'common/types/approvals';
import type { View } from 'common/types/view';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import './index.scss';

interface SitesFederatedToListProps {
  /** List of sites to show */
  siteList: FederatedSite[];
  /**
   * Descriptive text to show above site list. If not provided, defaults to generic text.
   * Intended to explain the effect of a pending or proposed federation.
   * If the view is unfederatable, this text does not apply.
   * Instead, a warning is shown.
   */
  infoText?: string;

  /** The flags of the view to determine if it is federatable */
  view: Partial<View>;
}

interface SitesFederatedToListState {
  /**
   * If there are more than 2 sites, we show a norgie (a "disclosure widget")
   * This tracks whether or not the component is showing the expanded list.
   */
  expanded: boolean;
}

/**
 * This component will list all of the sites that an asset will federate to if a specific audience level is selected.
 *
 * If there is more than one site in the list, it will show a norgie (a "disclosure widget") that will let the user see _all_ the sites.
 */
class SitesFederatedToList extends Component<SitesFederatedToListProps, SitesFederatedToListState> {
  static defaultProps = {
    siteList: [],
    view: {
      flags: []
    }
  };

  state = {
    expanded: false
  };

  /** Renders a list of links to federated sites */
  renderSiteList = (siteList: FederatedSite[]) =>
    siteList.map((site) => (
      <a
        key={site.domainCname}
        className="external-site-link external-link-container"
        href={`https://${site.domainCname}`}
        target="_blank"
      >
        <SocrataIcon name={IconName.ExternalSquare} />
        https://{site.domainCname}
      </a>
    ));

  /** Renders a link that will show/hide the list of sites (only shown if there are 2 or more sites) */
  renderExpandButton = (expanded: boolean, siteList: FederatedSite[]) => (
    <a className="expand-site-list-link" href="#" onClick={() => this.setState({ expanded: !expanded })}>
      <span className="expand-site-list-test">
        {I18n.t('shared.site_chrome.access_manager.audience.federation_number_of_sites', {
          count: (siteList || []).length
        })}
      </span>
      <SocrataIcon name={expanded ? IconName.ArrowUp : IconName.ArrowDown} />
    </a>
  );

  render() {
    const { expanded } = this.state;
    const { infoText, siteList, view } = this.props;

    // No sites
    if (isEmpty(siteList)) {
      return null;
    }

    const shouldRenderList: boolean = expanded || siteList.length < 2;
    const domainHasOutgoingDataFederation: boolean = hasOutgoingInternalToPublicFederation();
    const showDisclaimer = domainHasOutgoingDataFederation && isDataFederationUnsupportedAssetType(view);

    const notice = showDisclaimer
      ? I18n.t('shared.site_chrome.access_manager.audience.asset_will_not_be_federated')
      : infoText || I18n.t('shared.site_chrome.access_manager.audience.public_federation_note');

    // Yes, sites
    return (
      <div className="sites-federated-to">
        <div className="federation-note">
          {!showDisclaimer && <SocrataIcon name={IconName.Federation} />}
          <span dangerouslySetInnerHTML={{ __html: notice }}></span>
        </div>

        {/*
            Only render the expand button if we have 2 or more sites and this is not a soql derived view on
            a domain that is a data federation source.
          */}
        {siteList.length >= 2 && !showDisclaimer && this.renderExpandButton(expanded, siteList)}

        {shouldRenderList && !showDisclaimer && this.renderSiteList(siteList)}
      </div>
    );
  }
}

export default SitesFederatedToList;
