import { assertHasProperty, assertIsNotNil } from 'common/assertions';
import assertInstanceOf from 'common/assertions/assertInstanceOf';

import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';

import RichTextEditor from './RichTextEditor';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Ri... Remove this comment to see the full error message
import RichTextEditorToolbar, { richTextEditorToolbar } from './RichTextEditorToolbar';

// TODO EN-50252 remove this stub type when the toolbar is converted to TS
interface RichTextEditorToolbar {
  link: (a: any) => void;
  unlink: () => void;
}

export default class RichTextEditorManager {
  private formats: typeof Constants['RICH_TEXT_FORMATS'];
  private toolbar: RichTextEditorToolbar;
  private editors: { [editorId: string]: RichTextEditor } = {};

  constructor(toolbar: RichTextEditorToolbar, formats: typeof Constants['RICH_TEXT_FORMATS']) {
    assertInstanceOf(toolbar, RichTextEditorToolbar);
    assertInstanceOf(formats, Array);

    this.toolbar = toolbar;
    this.formats = formats;
  }

  createEditor(element: JQuery<HTMLElement>, editorId: string, contentToPreload?: string): RichTextEditor {
    this.editors[editorId] = new RichTextEditor(element, editorId, this.formats, contentToPreload);

    return this.editors[editorId];
  }

  getEditor(editorId?: string): null | RichTextEditor {
    let editor = null;

    if (typeof editorId !== 'undefined' && this.editors.hasOwnProperty(editorId)) {
      editor = this.editors[editorId];
    }

    return editor;
  }

  getAllEditors(): { [editorId: string]: RichTextEditor } {
    return this.editors;
  }

  deleteEditor(editorId: string): void {
    this.editors[editorId].destroy();

    delete this.editors[editorId];
  }

  linkToolbar(editorId: string): void {
    const editor = this.getEditor(editorId);
    assertIsNotNil(editor);
    this.toolbar.link(editor.getFormatController());
  }

  unlinkToolbar(): void {
    this.toolbar.unlink();
  }
}

export const richTextEditorManager = StorytellerUtils.export(
  new RichTextEditorManager(richTextEditorToolbar, Constants.RICH_TEXT_FORMATS),
  'storyteller.richTextEditorManager'
);
