import { FeatureFlags } from 'common/feature_flags';
import type { GridApi } from '@ag-grid-community/core';

// TODO_PRINT_TABLE: Don't check the URL like this. Should ref from StorytellerEnvironment.
// Add a `PRINT_MODE` constant to _storyteller_environment.html.erb
// Can probably use `should_load_print_styles` from `stories_helper.rb`
// Also need to make StorytellerEnvironment available in common.
export const isPrintTableEnabled = (): boolean => {
  return (
    FeatureFlags.valueOrDefault('enable_enhanced_table_print', false) &&
    new URLSearchParams(window.location.search).get('print') === 'true'
  );
};

export const getItemIdToPrint = () => {
  return new URLSearchParams(window.location.search).get('itemId');
};

// TODO_PRINT_TABLE: Basically, everything below here should be in a React component.
// That component should probably be connected to a new version of `print=true` in Rails.
// That might mean fiddling more in the show.html.erb and attaching React to a new Div there.
// Making a new rails page and ENV stuff that high up should make a lot of this feature easier,
// because you can override props and the VIF and such up in view/index.

// TODO_PRINT_TABLE: Make an actual header for this.
const PRINT_HEADER_IMAGE_SOURCE =
  'https://jhyatt.test-socrata.com/api/views/rspg-egcg/files/2a150ee1-1daa-441f-8344-6620540fdd72?download=true&filename=reportBanner[45].png';

export const renderPrintTablePseudoComponent = (api: GridApi) => {
  const setPrintEventListeners = () => {
    // `beforeprint` and `afterprint` are built-in events that are fired when the user prints a page.
    window.addEventListener('beforeprint', function () {
      api.updateGridOptions({ domLayout: 'print' });
    });

    window.addEventListener('afterprint', function () {
      api.updateGridOptions({ domLayout: 'autoHeight' });
    });
  };

  // TODO_PRINT_TABLE: It looks like this should be possible with only CSS, not a resize observer
  const adjustAgGridHeight = () => {
    const targetElement: HTMLElement | null = document.querySelector('.socrata-visualization-ag-grid');
    const observedElement: HTMLElement | null = document.querySelector('.ag-root-wrapper');
    if (targetElement && observedElement) {
      // Create a ResizeObserver instance
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === observedElement) {
            const observedElementHeight = observedElement.clientHeight;
            targetElement.style.height = observedElementHeight + 150 + 'px';
          }
        }
      });

      // Start observing the observedElement
      resizeObserver.observe(observedElement);

      // Set initial height
      const observedElementHeight = observedElement.clientHeight;
      targetElement.style.height = observedElementHeight + 150 + 'px';
    }
  };

  const createPrintHeader = () => {
    const element = document.querySelector('header');
    if (element) {
      return; // If a header already exists, do nothing
    }
    const header = document.createElement('header');
    header.id = 'print-header';
    // Create an image element
    const img = document.createElement('img');
    img.src = PRINT_HEADER_IMAGE_SOURCE;

    // Append the image to the header
    header.appendChild(img);

    // Insert the header at the top of the body
    document.body.insertBefore(header, document.body.firstChild);
  };

  setPrintEventListeners();
  adjustAgGridHeight();
  createPrintHeader();
};
