import _ from 'lodash';
import { Filter } from '@airbrake/browser/dist/filter/filter';

// Adds information about the environment on the context object
const environmentFilter: Filter = (notice) => {
  if (window.serverConfig?.airbrakeEnvironment !== undefined) {
    notice.context.environment = window.serverConfig.airbrakeEnvironment;
  }

  return notice;
};

export default environmentFilter;
