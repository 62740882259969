import { View, ViewRight } from 'common/types/view';
import { currentUserHasRight, currentUserIsLoggedIn } from 'common/current_user';
import DomainRights from 'common/types/domainRights';
import { hasRights } from 'common/views/has_rights';
import {
  isStory,
  isStoryDraft,
  isDerivedView,
  isMeasure,
  isVisualizationCanvas
} from 'common/views/view_types';

const currentUserCanCopy = (view: View) => {
  // shared checks across all asset types
  if (!hasRights(view, ViewRight.Read)) {
    return false;
  }
  return checkCanCopyByAssetType(view);
};

const checkCanCopyByAssetType = (view: View) => {
  if (isStoryDraft(view)) {
    return !!window.DRAFT_STORY_COPYABLE_BY_USER;
  }

  if (isStory(view)) {
    return !!window.PUBLISHED_STORY_COPYABLE_BY_USER;
  }

  if (isMeasure(view)) {
    return currentUserHasRight(DomainRights.create_measures);
  }

  if (isDerivedView(view)) {
    return currentUserIsLoggedIn() && !!window.initialState?.canReadFromAllParents;
  }

  if (isVisualizationCanvas(view)) {
    return currentUserIsLoggedIn();
  }

  // these are the only copyable asset types so far.
  return false;
};

export default currentUserCanCopy;
