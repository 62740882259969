// Constants
const LOAD_MORE_BOTTOM_HEIGHT = 5;

export const shouldLoadMoreDataForContainer = (containerRef) => {
  const containerScrollTop = containerRef.scrollTop;
  const containerHeight = containerRef.clientHeight;
  const containerScrollHeight = containerRef.scrollHeight;
  const containerBottomPosition = containerScrollTop + containerHeight;

  return (containerBottomPosition + LOAD_MORE_BOTTOM_HEIGHT) > containerScrollHeight;
};
