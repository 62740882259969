import { CatalogInteractiveUser } from 'common/types/users/catalogUsers';
import React, { FC } from 'react';

import { removeFromRecipients } from '../actions';
import { useWatchContext } from '../context';

import Recipient from './Recipient';

const classNameScope = 'common--components--AccessManager--components--UserDetails--user-label';

interface RecipientListProps {
  recipients: CatalogInteractiveUser[],
  canRemove: boolean
}

const RecipientList: FC<RecipientListProps> = ({ recipients, canRemove }) => {
  const { dispatch } = useWatchContext();

  return (
    <div slot="body" className="scrollable-body">
      <div className={`${classNameScope}--container`}>
        {recipients.map((user) => (
          <Recipient
            key={user.id}
            catalogUser={user}
            removeUser={() => dispatch(removeFromRecipients(user))}
            canRemove={canRemove}
          />
        ))}
      </div>
    </div>
  );
};

export default RecipientList;
