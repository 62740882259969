import _ from 'lodash';
import moment, { Moment, MomentInput } from 'moment';

import assert from 'common/assertions/assert';
import { SoqlHelpers } from 'common/visualizations/dataProviders';

import { PeriodSizes } from './constants';

import { Measure } from '../types';

// Represents a date range, defined by a start date and a range size (day, week, month, year).
// The range is understood to be inclusive of the start moment, and exclusive of the end moment.
// For instance, this DateRange represents all of January 1988 in Zulu time (but none of February):
// new Moment('1988-01-01T00:00:00.000Z', 'month')
export default class DateRange {
  start: Moment;
  end: Moment;
  size: PeriodSizes;

  // Constructs a new date range.
  // start: datelike (string/Date/moment). The start of the date range.
  // size: The size of the date range. day/week/month/quarter/year.
  // end: datelike (string/Date/moment). The end of the date range and is optional. If not specified,
  // it will default to 1 size (day, week, month, quarter, year) ahead
  constructor({ start, size, end }: { start: MomentInput; size: PeriodSizes; end?: MomentInput }) {
    // TODO: is there a better way to keep runtime and TS checks in sync?
    // Cast is necessary to treat the values as strings rather than the enum
    // This code may not even be necessary now since we currently support all
    // period sizes
    const supportedSizes = _.values(PeriodSizes) as string[];
    if (!_(supportedSizes).includes(size)) {
      throw new Error(`Unsupported date range size: ${size}. Supported: ${supportedSizes.join()}`);
    }

    if ((start && !end && !size) || (end && !start && !size) || (!start && !end)) {
      throw new Error('Cannot calculate DateRange from provided information.');
    }

    this.start = start ? moment(start) : moment(end).subtract(1, size);
    this.end = end ? moment(end) : moment(start).add(1, size);
    this.size = size;
  }

  // The date range starting on this date range's end date and having this date range's
  // size.
  next() {
    return new DateRange({ start: this.end, size: this.size });
  }

  inclusiveEnd() {
    return moment(this.end).subtract(1, 'day').endOf('day');
  }

  // Return a daterange that covers the entire reporting period as per the given
  // measure's configuration.
  // If the given measure has cumulative math enabled, return a copy of this
  // DateRange that extends back to the cumulative math start date. Otherwise,
  // return this DateRange unchanged.
  // Note that `size` will still refer to the original range size, which may
  // not match the new dates.
  // TODO: it might be better to have ReportingPeriods generate this and have
  // a notion of "display dates" and "calculation dates"
  forCalculation(measure: Measure | null) {
    const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);

    if (isCumulativeMath) {
      const startDate = _.get(measure, 'metricConfig.reportingPeriod.startDateConfig.date');
      const cumulativeStartDate = _.get(
        measure,
        'metricConfig.arguments.cumulativeMathStartDate',
        moment(startDate)
      );

      return new DateRange({ start: cumulativeStartDate, size: this.size, end: this.end });
    } else {
      return this;
    }
  }

  // Encodes the date range as a SoQL expression on a given column.
  asSoQL(columnName: string) {
    if (!_.isString(columnName)) {
      assert(_.isString(columnName), `columnName expected string, was ${columnName}`);
    }

    const startDateSoql = SoqlHelpers.soqlEncodeValue(this.start.toDate());
    const endDateSoql = SoqlHelpers.soqlEncodeValue(this.end.toDate());
    const column = SoqlHelpers.soqlEncodeColumnName(columnName);
    return `(${column} >= ${startDateSoql} AND ${column} < ${endDateSoql})`;
  }
}
