import React, { FunctionComponent } from 'react';
import Flyout from 'common/components/Flyout';
import I18n from 'common/i18n';

import './index.scss';

interface Props {
  hover?: any;
  remove: any;
}

/**
 * The ComponentDraggerControl component will render the controls within a
 * React component or appended to DOM element
 */
const ComponentDraggerControl: FunctionComponent<Props> = ({
  hover,
  remove
}) => {
  return (
    <div className="component-dragger-bar">
      <div
        className="component-remove"
        onClick={remove}
        onMouseEnter={hover}
        onMouseLeave={hover}
      >
        <Flyout text={I18n.t('shared.components.filter_bar.remove')}>
          <span className="socrata-icon-delete" />
        </Flyout>
      </div>
      <button className="component-dragger">
        <span className="socrata-icon-grab-handle" />
      </button>
    </div>
  );
};

export default ComponentDraggerControl;
