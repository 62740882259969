import _ from 'lodash';

import SoqlDataProvider from './SoqlDataProvider';
import SoqlHelpers from './SoqlHelpers';
import { SCATTER_CHART_COLOR_BY_SERIES_INDEX } from '../views/SvgConstants';
import { getParameterOverrides, isScatterVisualization } from '../helpers/VifSelectors';

export const MAX_ROW_COUNT = 1000 * 10;

/**
 * Given a vif without any dimensions/grouping and with multiple series with measures and flyouts,
 * it returns the rows/columns for the given vif.
 * @param  {Object} vif         Vif Object without any dimension/grouping but with
 *                              multiple series(of type flyout/scatterChart/..) containing measures.
 * @return {Object} data        Returns Data
 *            - data.rows       array of row with each row containing values in the
 *                              same order as the measures/flyout in each series.
 *                              Note: First element in each row is null (alloted for dimension),
 *                              to match with data format of other DataManagers.
 *            - data.columns    columns of each measure/flyout same order as in the
 *                              rows/series
 *                              Note: First element in each row is null (alloted for dimension),
 *                              to match with data format of other DataManagers.
 */
export const getData = (vif) => {
  const limitFromVif = _.get(vif, 'series[0].dataSource.limit', null);
  const limit = (_.isNumber(limitFromVif)) ? parseInt(limitFromVif, 10) : MAX_ROW_COUNT;

  const measureColumnNames = _.chain(vif).
    get('series').
    map((seriesItem) => _.get(seriesItem, 'dataSource.measure.columnName')).
    value();

  const measureSelects = _.chain(measureColumnNames).
    map((measureColumnName, index) => {
      if (isScatterVisualization(vif) && index === SCATTER_CHART_COLOR_BY_SERIES_INDEX) {
        return measureColumnName ? `\`${measureColumnName}\` || '' as ${SoqlHelpers.measureAlias(index)}` : null;
      }

      return measureColumnName ? `\`${measureColumnName}\` as ${SoqlHelpers.measureAlias(index)}` : null;
    }).
    compact().
    value();

  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);
  const whereClause = (whereClauseComponents.length > 0) ? `WHERE ${whereClauseComponents}` : '';

  const soqlDataProvider = new SoqlDataProvider({
    datasetUid: _.get(vif, 'series[0].dataSource.datasetUid', null),
    domain: _.get(vif, 'series[0].dataSource.domain', null),
    clientContextVariables: getParameterOverrides(vif)
  });

  const queryString = [
    `SELECT ${measureSelects.join(',')}`,
    whereClause,
    `ORDER BY ${SoqlHelpers.measureAlias(0)} ASC`,
    `LIMIT ${limit + 1}`
  ].join(' ');

  return soqlDataProvider.rawQuery(queryString).
    then((resultRows) => {
      const rows = _.map(resultRows, (resultRow) => {
        const measureValues = _.map(vif.series, (seriesItem, index) => {
          const value = resultRow[SoqlHelpers.measureAlias(index)];

          return _.isUndefined(value) ? null : value;
        });

        // First value is the dimension, since no dimension applicable here setting null
        return [null].concat(measureValues);
      });

      return {
        rows,
        columns: [null].concat(measureColumnNames)
      };
    });
};
