import React, { FC } from 'react';
import { ForgeButton, ForgeToolbar } from '@tylertech/forge-react';

import { deleteAllForAsset } from 'common/notifications/api/ScreenshotSubscriptionsAPI';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';

import { useWatchContext } from '../context';

interface DeleteSubscriptionButtonsProps {
  fourfour: string;
  onDismiss: () => void;
}

const DeleteSubscriptionButtons: FC<DeleteSubscriptionButtonsProps> = (props) => {
  const { t } = useWatchContext();
  const { onDismiss, fourfour } = props;

  const deleteSubscriptions = () => {
    deleteAllForAsset(fourfour).then((_) => {
      onDismiss();
      showToastNow({
        type: ToastType.SUCCESS,
        content: t('subscription_deletion')
      });
    });
  };

  return (
    <div slot="footer" className="watch-footer">
      <ForgeToolbar inverted={true} className="footer-toolbar">
        <div slot="start">
          <ForgeButton type="outlined">
            <button id="delete-button" type="button" onClick={deleteSubscriptions}>
              {t('delete')}
            </button>
          </ForgeButton>
        </div>
        <div slot="end">
          <ForgeButton type="raised">
            <button id="done-button" type="button" onClick={onDismiss}>
              {t('done')}
            </button>
          </ForgeButton>
        </div>
      </ForgeToolbar>
    </div>
  );
};

export default DeleteSubscriptionButtons;
