import { compact, isNull, partition } from 'lodash';
import {
  StarSelection as SoqlStarSelection,
  UnAnalyzedSelectedExpression,
  UnAnalyzedSelection,
  Expr, Literal,
} from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { buildColumnRef, buildLiteral, renderExpr, renderQualifier } from './expr';
import {
  isString, isNumber, isViewColumn,
  QualifiedViewColumn, isQualifiedViewColumn,
  encodeStringAsColumnRef
} from './util';

export interface NamedSelect {
  expr: QualifiedViewColumn | ViewColumn | Expr | Literal['value'] | number;
  name: string;
}

export interface PrerenderedExpression extends NamedSelect {
  type: 'prerendered-expression';
  expr: string;
}

type SelectableInputs = NamedSelect | PrerenderedExpression | UnAnalyzedSelectedExpression;
export const isPrerenderedExpression = (x: SelectableInputs): x is PrerenderedExpression => 'type' in x && x.type === 'prerendered-expression';
/**
 * Construct a Prerendered Expression where the soqltext is already written.
 *
 * @remarks
 * This should be not be used unless you're forced into such a situation because this bypasses the type system almost completely.
 *
 * @param expr The prerendered string being injected into the soql query.
 * @param name The alias for your selection.
 */
export const buildPrerenderedExpression = (expr: string, name: string): PrerenderedExpression => ({
  type: 'prerendered-expression',
  expr, name
});

export interface StarSelection {
  qualifier?: string;
  system: boolean;
  exceptions: (ViewColumn | string)[];
}
/**
 * Construct a Star Selection object, e.g. `SELECT *`; `SELECT @foo.*`; `SELECT :* (EXCEPT a, b, c)`.
 *
 * @remarks
 * You should not need this function; it is a smell in custom-designed queries.
 *
 * @param system Whether or not this is a system star selection.
 * @param qualifier The qualifier being used on a user star selection.
 * @param exceptions Columns to NOT select for in the star selection.
 */
export const buildStarSelection = (system = false, qualifier: string | null | undefined = undefined, exceptions: StarSelection['exceptions'] = []): StarSelection => {
  if (system && qualifier) throw new Error('you cannot have a qualifier on a system star selection');
  return {
    qualifier: qualifier || undefined,
    system,
    exceptions
  };
};

/**
 * Construct a single SELECT object, expected to become a member of an array.
 *
 * @remarks
 * You should not try to do a SELECT *; it's a smell in custom-designed queries.
 *
 * @param expr An expression you wish to select.
 * @param name The alias for your selection.
 */
export const buildSelect = (expr: NamedSelect['expr'], name: string): NamedSelect => ({ expr, name });

export const convertSelect = (select: NamedSelect): UnAnalyzedSelectedExpression | PrerenderedExpression => {
  const name = { name: select.name, position: { line: 0, column: 0 } };
  let expr;

  if (isPrerenderedExpression(select)) {
    return select;
  } else if (isQualifiedViewColumn(select.expr)) {
    expr = buildColumnRef(select.expr.column, select.expr.qualifier);
  } else if (isViewColumn(select.expr)) {
    expr = buildColumnRef(select.expr);
  } else if (isString(select.expr) || isNumber(select.expr) || true === select.expr || false === select.expr || isNull(select.expr)) {
    expr = buildLiteral(select.expr);
  } else {
    expr = select.expr;
  }

  return { expr, name };
};

interface ConvertedStarSelections {
  systemStar: UnAnalyzedSelection['all_system_except'];
  userStars: UnAnalyzedSelection['all_user_except'];
}
export const convertStarSelection = (s: StarSelection[]): ConvertedStarSelections => {
  const [[ systemStarSelection, ...otherSystemStars ], userStarSelections] = partition(s, 'system');
  if (otherSystemStars.length > 0) {
    throw new Error('multiple system star selections are not allowed');
  }

  const convertStar = (star: StarSelection | undefined): SoqlStarSelection | null => {
    if (!star) return null;

    const qualifier = star.qualifier ?? null;
    return {
      qualifier,
      exceptions: star.exceptions.map(e => {
        if (isViewColumn(e)) {
          return buildColumnRef(e, star.qualifier);
        } else {
          return encodeStringAsColumnRef(e, star.qualifier);
        }
      })
    };
  };

  return {
    systemStar: convertStar(systemStarSelection),
    userStars: compact(userStarSelections.map(convertStar))
  };
};

export const renderUnAnalyzedSelectedExpression = (expr: UnAnalyzedSelectedExpression | PrerenderedExpression): string => {
  const rendered = isPrerenderedExpression(expr) ? expr.expr : renderExpr(expr.expr);
  const alias = isPrerenderedExpression(expr) ? expr.name : expr.name?.name;

  return compact([rendered, alias]).join(' as ');
};

// The weird parameter typing is because those are all we care about.
// And also, query/build screws around with UnAnalyzedAst.selection a bit.
export const renderStarSelections = (selection: Pick<UnAnalyzedSelection, 'all_system_except' | 'all_user_except'>): string[] => {
  const results: string[] = [];
  const renderExceptions = (exceptions: SoqlStarSelection['exceptions']): string => {
    if (exceptions.length === 0) { return ''; }
    // No qualifiers, because those just don't work.
    return ` (EXCEPT ${exceptions.map(cr => `\`${cr.value}\``).join(', ')})`;
  };
  if (selection.all_system_except) {
    results.push(`:*${renderExceptions(selection.all_system_except.exceptions)}`);
  }
  if (selection.all_user_except.length > 0) {
    selection.all_user_except.forEach(userStar => {
      const star = compact([renderQualifier(userStar.qualifier), '*']).join('.');
      results.push(`${star}${renderExceptions(userStar.exceptions)}`);
    });
  }
  return results;
};
