/* Imports */
/* ============================================================================= */
import { Status } from 'common/core/approvals_enums';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { BaseGuidanceSummaryV2Helper, GuidanceCheckSummary, WorkflowGuidanceCheckMethods } from 'common/core/approvals/Types';
import { summarizeGuidanceCheckMethod, createWorkflowGuidanceHelperMethodsObject } from '.';





/* Method */
/* ============================================================================= */
/**
 * Summarize the expected outcome (expectedState) of each workflow within a GuidanceSummaryV2 object
 * @param guidance GuidanceSummaryV2 object
 * @param guidanceHelper BaseGuidanceSummaryV2Helper object
 * @returns A GuidanceCheckSummary object with a Status value or undefined for each workflow attribute
 */
export const summarizeExpectedOutcome = (guidance: GuidanceSummaryV2, guidanceHelper: BaseGuidanceSummaryV2Helper): GuidanceCheckSummary<Status> => {
  const response = summarizeGuidanceCheckMethod<Status | undefined, undefined>(
    guidance,
    createWorkflowGuidanceHelperMethodsObject<WorkflowGuidanceCheckMethods<Status | undefined>>(guidanceHelper, 'expectedOutcome'),
    undefined
  );

  return response;
};

export default summarizeExpectedOutcome;
