import PropTypes from 'prop-types';

// This is essentially the shape of what `withComputedMeasure` returns
export default PropTypes.shape({
  // Columns that the calculation is based on. Essentially what is returned for columns by /api/views.
  calculationColumns: PropTypes.arrayOf(PropTypes.object),

  // Represents a single result of a metric calculation for the most recent or current reporting period
  // For closed reporting periods, this means the most recently finished reporting period
  // For open reporting periods, this means the reporting period that today is in
  result: PropTypes.shape({

    // The actual value from a metric calculation
    value: PropTypes.object,

    // (Rate measures only) - result of a count or sum
    numerator: PropTypes.object,

    // (Rate measures only) - result of a count or sum OR a direct input
    denominator: PropTypes.object
  }),

  // Represents results for all date ranges within a reporting period
  // eg: [ ['2002-12-01T00:00:00.000', 42], [], ... ]
  series: PropTypes.arrayOf(PropTypes.array),

  // Represents any metric configuration or calculation errors
  errors: PropTypes.shape({

    // Indicates an insufficiently-specified calculation, which varies by calculation type
    calculationNotConfigured: PropTypes.bool,

    // Indicates that no valid dataset has been set in the Data Source panel
    dataSourceNotConfigured: PropTypes.bool,

    // The user does not have access to the measure's underlying dataset (but may have access to the
    // measure itself)
    dataSourcePermissionDenied: PropTypes.bool,

    // (Rate measures only)
    dividingByZero: PropTypes.bool,

    // Indicates that no reporting period is usable (this can happen if the start date is in the future,
    // or we're using closed reporting periods and no period has closed yet).
    noReportingPeriodAvailable: PropTypes.bool,

    // Indicates that no reporting period was set by the user
    noReportingPeriodConfigured: PropTypes.bool
  }).isRequired
});
