import React from 'react';
import PropTypes from 'prop-types';

const TableHeaderRow = ({ children }) => {
  return (
    <thead className="results-list-header">
      <tr role="row">{children}</tr>
    </thead>
  );
};

TableHeaderRow.propTypes = {
  children: PropTypes.any
};

export default TableHeaderRow;
