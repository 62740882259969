import _ from 'lodash';
import { call, put, take, takeEvery, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { Socket, LongPoll } from 'phoenix';
import { isIE11 } from 'common/notifications/api/helper';
import * as selectors from 'common/notifications/Selectors';

import { NOTIFICATIONS_PER_PAGE } from 'common/notifications/constants';
import {
  getNotificationIndex,
  setNotificationObject,
  transformNotification
} from 'common/notifications/helpers/NotificationFormatHelper';
import { buildQueryString, fetchJson } from 'common/http';
import * as Actions from 'common/notifications/actions/UserNotificationActions';
import { FeatureFlags } from 'common/feature_flags';


export function initSocket(channelId, socketParams, options) {
  return eventChannel(emitter => {
    const socket = new Socket(`wss://${window.location.host}/api/notifications_and_alerts/socket`, socketParams);
    socket.connect();

    if (options.debugLog) {
      socket.onError(() => {
        console.warn('User Notifications: Error connecting, disabling connection in development mode.');
        socket.disconnect();
      });
    }

    socket.onClose(() => {
      fetchJson(
        '/api/notifications_and_alerts/socket_token',
        { method: 'POST', credentials: 'same-origin' }
      ).then((response) => socket.params.token = _.get(response, 'token'));
    });

    const channel = socket.channel(channelId, {});

    channel.join().receive('error', (resp) => {
      console.info('Socket Error: Unable to join', resp);
    });

    channel.on('new_notification', (response) => { return emitter(Actions.loadNewNotification(response)); });
    channel.on('delete_notification', (response) => { return emitter(Actions.onDeleteUserNotification(response)); });
    channel.on('delete_all_notifications', (response) => { return emitter(Actions.onDeleteAllNotifications(response)); });
    channel.on('mark_notification_as_read', (response) => { return emitter(Actions.onNotificationReadStateChange(response, true)); });
    channel.on('mark_notification_as_unread', (response) => { return emitter(Actions.onNotificationReadStateChange(response, false)); });

    return () => { };
  });
}

export function* loadSocketConnection({ userId, options }) {
  const channelId = `user: ${userId}`;
  try {
    const response = yield call(
      fetchJson,
      '/api/notifications_and_alerts/socket_token',
      { method: 'POST', credentials: 'same-origin' }
    );

    const socketParams = { params: { user_id: userId, token: response.token } };

    if (isIE11() || FeatureFlags.valueOrDefault('force_longpolling_due_to_proxies', false)) {
      socketParams.transport = LongPoll;
    }

    const channel = yield call(initSocket, channelId, socketParams, options);

    yield put(Actions.loadNotifications('activity', 0));

    if (options.loadAlerts) {
      yield put(Actions.loadNotifications('alert', 0));
    }
    /* eslint-disable no-constant-condition */
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }

  } catch (error) {
    if (options.loadAlerts) {
      yield put(Actions.updateUserNotificationsByType(setNotificationObject(), 'alert'));
    }
    yield put(Actions.updateUserNotificationsByType(setNotificationObject(), 'activity'));
    console.info('User Notifications: Error Socket connecting', error);
  }
}

export function* loadNotifications({ notificationType, offset }) {

  yield put(Actions.toggleUserNotificationLoading(true));

  const params = { limit: NOTIFICATIONS_PER_PAGE, offset, type: notificationType };
  const queryString = buildQueryString(params);
  const loadNotificationsUrl = `/api/notifications_and_alerts/notifications?${queryString}`;
  try {
    const response = yield call(
      fetchJson,
      loadNotificationsUrl,
      { credentials: 'same-origin' }
    );

    yield put(Actions.toggleUserNotificationLoading(false));

    offset += NOTIFICATIONS_PER_PAGE;
    const { total, unread } = response.count;
    const hasMoreNotifications = offset < total;
    let notifications = _.get(response, 'data', []);

    notifications = transformNotifications(notifications);
    notifications = setNotificationObject({
      hasMoreNotifications,
      notifications,
      total,
      offset,
      unread
    });
    yield put(Actions.updateUserNotificationsByType(notifications, notificationType));
  } catch (error) {
    yield put(Actions.toggleUserNotificationLoading(false));
    yield put(Actions.updateUserNotificationsByType(setNotificationObject(), notificationType));
    console.error('error on load notifications', error);
  }
}

export function* loadNewNotifications({ response }) {
  if (_.isUndefined(response.notification) || !_.isObject(response.notification)) {
    console.info('invalid response', response);
    return;
  }

  const isTransientNotificationsPreferenceEnabled = yield select(selectors.isTransientNotificationEnabled);
  const showNotificationPanel = yield select(selectors.getShowNotificationPanelFlag);
  const { enqueuedNotifications, transientNotifications } = yield select(selectors.getUserNotificationsConfigs);

  const { notification } = response;
  const { type } = notification;
  if (type == 'alert') {
    const showAlertNotifications = yield select(selectors.getShowAlertFlag);
    if (!showAlertNotifications) {
      return;
    }
  }
  if (!showNotificationPanel && isTransientNotificationsPreferenceEnabled) {
    transientNotifications.unshift(transformNotification(notification));
    yield put(Actions.updateTransientNotifications(transientNotifications));
  } else {
    enqueuedNotifications.unshift(transformNotification(notification));
    yield put(Actions.updateEnqueuedNotifications(enqueuedNotifications));
  }
  yield put(Actions.increaseNewNotificationCount(type, 1));
}

export function* onDeleteUserNotification({ response }) {
  if (!isValidNotificationResponse(response)) {
    return;
  }
  const { transientNotifications, userNotifications } = yield select(selectors.getUserNotificationsConfigs);

  const { notification_id: notificationId, type } = response;
  const notificationIndex = getNotificationIndex(
    userNotifications[type].notifications,
    notificationId
  );

  if (notificationIndex !== -1) {
    if (userNotifications[type].notifications[notificationIndex].read === false) {
      userNotifications[type].unread--;
    }
    userNotifications[type].notifications.splice(notificationIndex, 1);
    userNotifications[type].total--;
    userNotifications[type].offset--;
  } else {
    const transientNotificationIndex = getNotificationIndex(transientNotifications, notificationId);
    if (transientNotificationIndex !== -1) {
      if (transientNotifications[transientNotificationIndex].read === false) {
        userNotifications[type].unread--;
      }

      transientNotifications.splice(transientNotificationIndex, 1);
      yield put(Actions.updateTransientNotifications(transientNotifications));
    }
  }

  yield put(Actions.updateUserNotifications(userNotifications));
}

export function* onDeleteUserAllNotifications() {
  const userNotifications = yield select(selectors.getUserNotifications);

  userNotifications.activity = setNotificationObject();
  if (userNotifications.alert) {
    userNotifications.alert = setNotificationObject();
  }

  yield put(Actions.updateEnqueuedNotifications([]));
  yield put(Actions.updateUserNotifications(userNotifications));
}

export function* onNotificationReadStateChange({ response, readState }) {
  if (!isValidNotificationResponse(response)) {
    return;
  }

  const { notification_id: notificationId, type } = response;
  const { transientNotifications, userNotifications } = yield select(selectors.getUserNotificationsConfigs);

  const notificationIndex = getNotificationIndex(
    userNotifications[type].notifications,
    notificationId
  );

  if (notificationIndex !== -1) {
    userNotifications[type].notifications[notificationIndex].read = readState;
  } else {
    const transientNotificationIndex = getNotificationIndex(transientNotifications, notificationId);
    transientNotifications[transientNotificationIndex].read = readState;
    yield put(Actions.updateTransientNotifications(transientNotifications));
  }
  if (readState) {
    userNotifications[type].unread--;
  } else {
    userNotifications[type].unread++;
  }
  yield put(Actions.updateUserNotifications(userNotifications));
}

export function* seeNewNotifications({ notificationType }) {
  const matchesType = _.matches({ type: notificationType });
  let { enqueuedNotifications, userNotifications } = yield select(selectors.getUserNotificationsConfigs);

  userNotifications[notificationType].notifications = _.filter(enqueuedNotifications, matchesType).
    concat(userNotifications[notificationType].notifications);

  enqueuedNotifications = _.reject(enqueuedNotifications, matchesType);

  yield put(Actions.updateEnqueuedNotifications(enqueuedNotifications));
  yield put(Actions.updateUserNotifications(userNotifications));
  yield put(Actions.updateTransientNotifications([]));
}

export function* moveTransientNotificationIntoPanel({ notificationId }) {
  const { transientNotifications, userNotifications } = yield select(selectors.getUserNotificationsConfigs);
  const notificationIndex = getNotificationIndex(transientNotifications, notificationId);

  if (notificationIndex !== -1) {
    const notificationType = transientNotifications[notificationIndex].type;
    const notifications = userNotifications[notificationType].notifications;
    const userNotificationIndex = getNotificationIndex(notifications, notificationId);

    if (userNotificationIndex === -1) {
      userNotifications[notificationType].notifications.unshift(transientNotifications[notificationIndex]);
    }
    yield put(Actions.updateUserNotifications(userNotifications));
  }
}

export function* removeTransientNotification({ notificationId }) {
  const transientNotifications = yield select(selectors.getTransientNotifications);
  const notificationIndex = getNotificationIndex(transientNotifications, notificationId);

  if (notificationIndex !== -1) {
    transientNotifications.splice(notificationIndex, 1);
    yield put(Actions.updateTransientNotifications(transientNotifications));
  }
}

const transformNotifications = (notifications) => _.map(notifications, transformNotification);

const isValidNotificationResponse = ({ notification_id: notificationId, type }) => _.isNumber(notificationId) && _.isString(type);

export default [
  takeEvery(Actions.LOAD_NOTIFICATIONS, loadNotifications),
  takeEvery(Actions.LOAD_SOCKET_CONNECTION, loadSocketConnection),
  takeEvery(Actions.LOAD_NEW_NOTIFICATIONS, loadNewNotifications),
  takeEvery(Actions.ON_DELETE_USER_NOTIFICATION, onDeleteUserNotification),
  takeEvery(Actions.ON_DELETE_ALL_USER_NOTIFICATION, onDeleteUserAllNotifications),
  takeEvery(Actions.ON_USER_NOTIFICATION_READ_STATE_CHANGE, onNotificationReadStateChange),
  takeEvery(Actions.SEE_NEW_USER_NOTIFICATION, seeNewNotifications),
  takeEvery(Actions.MOVE_TRANSIENT_NOTIFICATION, moveTransientNotificationIntoPanel),
  takeEvery(Actions.REMOVE_TRANSIENT_NOTIFICATION, removeTransientNotification)
];
