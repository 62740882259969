import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react';
import I18n from 'common/i18n';

// components
import TableHierarchyGroupingKebabMenu from './TableHierarchyGroupingKebabMenu';

//types
import { Hierarchy, HierarchyColumnConfig } from 'common/visualizations/vif';
import { ViewColumn } from 'common/types/viewColumn';
import TableHierarchyGroupingHeaderInput from './TableHierarchyGroupingHeaderInput';

// Tippy Tooltip SCSS
import 'common/visualizations/views/agGridReact/HeaderIconWithTooltip/index.scss';

// project constants
const scope = 'shared.visualizations.panes.data';

interface TableHierarchyGroupingHeaderProps {
  columnMetadata: ViewColumn[];
  hierarchy: Hierarchy;
  duplicationAllowed: boolean;
  removeAllowed: boolean;
  hierarchyGroupingColumnConfigs: HierarchyColumnConfig[];
  setActiveHierarchy: () => void;
  handleOnRemoveHierarchy: () => void;
  handleOnDuplicateHierarchy: () => void;
  handleOnResetHierarchy: () => void;
  handleOnUpdateHierarchyName: (name: string) => void;
}

export const MAX_HEADER_TEXT_LENGTH = 70;
export const getHierarchyHeaderText = (
  hierarchyGroupingColumnConfigs: HierarchyColumnConfig[],
  columnMetadata: ViewColumn[]
) => {
  let headerText = I18n.t('fields.table_hierarchies.header.ungrouped', { scope });

  const groupingColumnNames = hierarchyGroupingColumnConfigs.map((h: HierarchyColumnConfig) => {
    const column = columnMetadata.find(({ fieldName }) => h.columnName === fieldName);
    return column?.name || h.columnName;
  });

  if (_.size(groupingColumnNames)) {
    headerText = `${I18n.t('fields.table_hierarchies.header.by', { scope })} `;

    if (_.size(groupingColumnNames) > 2) {
      groupingColumnNames.forEach((name: string, idx: number) => {
        if (idx === groupingColumnNames.length - 1)
          headerText += `${I18n.t('fields.table_hierarchies.header.and', { scope })} ${name}`;
        else headerText += `${name}, `;
      });
    } else {
      headerText += groupingColumnNames.join(` ${I18n.t('fields.table_hierarchies.header.and', { scope })} `);
    }
  }

  return headerText;
};

export const shortenHeaderText = (headerText: string) => {
  return headerText.substring(0, MAX_HEADER_TEXT_LENGTH);
};

const TableHierarchyGroupingHeader = (props: TableHierarchyGroupingHeaderProps) => {
  const {
    columnMetadata,
    hierarchy,
    hierarchyGroupingColumnConfigs,
    duplicationAllowed,
    removeAllowed,
    setActiveHierarchy,
    handleOnRemoveHierarchy,
    handleOnDuplicateHierarchy,
    handleOnResetHierarchy
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [editingTitle, setEditingTitle] = useState<boolean>(false);

  const headerText = hierarchy.name || getHierarchyHeaderText(hierarchyGroupingColumnConfigs, columnMetadata);
  const [hierarchyTitle, setHierarchyTitle] = useState<string>(headerText);

  const outsideClickHandler = (e: MouseEvent) => {
    if (inputRef.current) {
      if (editingTitle && !inputRef.current.contains(e.target as Node)) {
        handleHierarchyNameChange(hierarchyTitle);
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', outsideClickHandler);
    return () => {
      document.body.removeEventListener('click', outsideClickHandler);
    };
  });

  useEffect(() => {
    if (!hierarchy.name) {
      setHierarchyTitle(getHierarchyHeaderText(hierarchyGroupingColumnConfigs, columnMetadata));
    } else if (hierarchy.name !== hierarchyTitle) {
      setHierarchyTitle(hierarchy.name);
    }
  }, [hierarchy.name, hierarchyGroupingColumnConfigs]);

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const { code } = e;
    if (code === 'Space') {
      e.stopPropagation();
    }

    if (code === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      handleHierarchyNameChange(hierarchyTitle);
    }
  }

  // Stops inputField re-renders
  function handleClick(e: React.MouseEvent<HTMLInputElement>) {
    e.stopPropagation();
  }

  function handleHierarchyNameChange(newName: string) {
    props.handleOnUpdateHierarchyName(newName);
    setEditingTitle(false);
  }

  function handleOnClickEdit(e: React.MouseEvent<HTMLInputElement>) {
    e.stopPropagation();
    setEditingTitle(true);
  }

  function handleOnTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setHierarchyTitle(e.target.value);
  }

  return (
    <div className="table-hierarchy-header">
      <div className="editable-hierarchy-title" onClick={() => setActiveHierarchy()}>
        <span className="icon socrata-icon-table-hierarchy" />
        {editingTitle ? (
          <TableHierarchyGroupingHeaderInput
            ref={inputRef}
            value={hierarchyTitle}
            onChange={handleOnTitleChange}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            autoFocus
          />
        ) : (
          <div className="hierarchy-title-container">
            <Tippy placement="bottom" content={shortenHeaderText(hierarchyTitle)}>
              <span className="hierarchy-title">{shortenHeaderText(hierarchyTitle)}</span>
            </Tippy>
            <span role="button" className="icon socrata-icon-edit" onClick={handleOnClickEdit} />
          </div>
        )}
      </div>
      <TableHierarchyGroupingKebabMenu
        duplicationAllowed={duplicationAllowed}
        removeAllowed={removeAllowed}
        handleOnDuplicateHierarchy={handleOnDuplicateHierarchy}
        handleOnRemoveHierarchy={handleOnRemoveHierarchy}
        handleOnResetHierarchy={handleOnResetHierarchy}
      />
    </div>
  );
};

TableHierarchyGroupingHeader.defaultProps = {
  columnMetadata: []
};

export default TableHierarchyGroupingHeader;
