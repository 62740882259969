import { compact, has } from 'lodash';
import { BinaryTree, UnAnalyzedAst, UnAnalyzedJoin, UnAnalyzedSelectedExpression, Expr, Hint, TableName, Distinct } from 'common/types/soql';
import { isBinaryTree, buildLeaf, mapOverBinaryTree } from '../binaryTree';
import { convertDistinct } from '../distinct';
import { buildLiteral } from '../expr';
import { convertGroupBy } from '../groupBy';
import { convertOrderBy } from '../orderBy';
import { PrerenderedExpression, StarSelection, convertSelect, convertStarSelection, isPrerenderedExpression } from '../select';

interface BaseQueryParts {
  hints?: Hint[];
  from?: TableName;
  distinct?: Parameters<typeof convertDistinct>[0];
  joins?: UnAnalyzedJoin<UnAnalyzedAst | UnAnalyzedAstWithPrerenderedParts>[];
  where?: Expr;
  groups?: (Parameters<typeof convertGroupBy>[0] | undefined)[];
  orders?: (Parameters<typeof convertOrderBy>[0] | undefined)[];
  having?: Expr;
  search?: string;
  limit?: number;
  offset?: number;
  legacyWhereClause?: string
}

interface QueryPartsWithRequiredSelect extends BaseQueryParts {
  selects: (Parameters<typeof convertSelect>[0] | PrerenderedExpression | undefined)[];
  starSelects?: StarSelection[];
}
interface QueryPartsWithRequiredStarSelect extends BaseQueryParts {
  selects?: (Parameters<typeof convertSelect>[0] | PrerenderedExpression | undefined)[];
  starSelects: StarSelection[];
}
export type QueryParts = QueryPartsWithRequiredSelect | QueryPartsWithRequiredStarSelect;

export interface UnAnalyzedAstWithPrerenderedParts {
  selection: {
    all_system_except: UnAnalyzedAst['selection']['all_system_except'];
    all_user_except: UnAnalyzedAst['selection']['all_user_except'];
    exprs: (UnAnalyzedSelectedExpression | PrerenderedExpression)[];
  };
  joins: UnAnalyzedJoin<UnAnalyzedAst | UnAnalyzedAstWithPrerenderedParts>[];
  legacyWhereClause: string | null;

  hints: UnAnalyzedAst['hints'];
  from: UnAnalyzedAst['from'];
  distinct: UnAnalyzedAst['distinct'];
  where: UnAnalyzedAst['where'];
  group_bys: UnAnalyzedAst['group_bys'];
  order_bys: UnAnalyzedAst['order_bys'];
  having: UnAnalyzedAst['having'];
  search: UnAnalyzedAst['search'];
  limit: UnAnalyzedAst['limit'];
  offset: UnAnalyzedAst['offset'];
}
export const isUnAnalyzedAstWithPrerenderedParts = (x: UnAnalyzedAst | UnAnalyzedAstWithPrerenderedParts): x is UnAnalyzedAstWithPrerenderedParts => {
  if ('legacyWhereClause' in x) {
    return true;
  }
  if (x.selection.exprs.some(isPrerenderedExpression)) {
    return true;
  }
  return false;
};

export const isQueryParts = (parts: unknown): parts is QueryParts => has(parts, 'selects') || has(parts, 'starSelects');
const buildSimpleQuery = (parts: QueryParts): UnAnalyzedAstWithPrerenderedParts => {
  const { systemStar, userStars } = convertStarSelection(parts.starSelects || []);
  const selection = {
    exprs: compact(parts.selects || []).map(convertSelect),
    all_system_except: systemStar,
    all_user_except: userStars
  };
  const group_bys = compact(parts.groups || []).map(convertGroupBy);
  const order_bys = compact(parts.orders || []).map(convertOrderBy);
  const joins = parts.joins || [];
  const distinct = convertDistinct(parts.distinct || 'indistinct');

  return {
    selection,
    hints: parts.hints || [],
    from: parts.from || null,
    where: parts.where || null,
    group_bys,
    order_bys,
    joins,
    search: parts.search || null,
    having: parts.having || null,
    distinct,
    limit: parts.limit || null,
    offset: parts.offset || null,
    legacyWhereClause: parts.legacyWhereClause || null
  };
};

type Ast = UnAnalyzedAst | UnAnalyzedAstWithPrerenderedParts;
export const buildQueryAsBinaryTree = (parts: QueryParts | BinaryTree<QueryParts>): BinaryTree<Ast> => {
  if (isBinaryTree<QueryParts>(parts, isQueryParts)) {
    return mapOverBinaryTree<QueryParts, Ast>(parts, buildSimpleQuery);
  }
  return buildLeaf<Ast>(buildSimpleQuery(parts));
};

export const buildQuery = (parts: QueryParts | BinaryTree<QueryParts>): Ast | BinaryTree<Ast> => {
  if (isBinaryTree<QueryParts>(parts, isQueryParts)) {
    return mapOverBinaryTree<QueryParts, Ast>(parts, buildSimpleQuery);
  }
  return buildSimpleQuery(parts);
};
