/* Imports */
/* ============================================================================= */
import { GuidanceV2, WorkflowGuidance } from 'common/types/approvals';

import { SubmissionKeyV2 } from 'common/core/approvals/Types';
import { SUBMISSION_KEYS_V2 } from '.';





/* Method */
/* ============================================================================= */
/**
 * Get the submission object from the given WorkflowGuidance object if one exists.
 * @param guidance WorkflowGuidance Object.
 * @returns The submission object (Guidancev2) if one is found on the WorkflowGuidance object, or undefined of one is not found.
 */
export const getSubmissionActionObject = (guidance: WorkflowGuidance): GuidanceV2 | undefined => {
  // It should be the case that only one submission key is present in a guidance response
  const targetKey: SubmissionKeyV2 | undefined = SUBMISSION_KEYS_V2.find((key) => guidance[key]);

  return targetKey && guidance[targetKey];
};

export default getSubmissionActionObject;
