import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SocrataIcon } from 'common/components/SocrataIcon';

import './myAlerts.scss';

class AlertShareButton extends Component {

  render() {
    const { alert, onShareAlertClick } = this.props;

    return (
      <div className="alert-share" onClick={() => onShareAlertClick(alert)}>
        <SocrataIcon name="share" />
      </div>
    );
  }
}


AlertShareButton.propTypes = {
  alert: PropTypes.object.isRequired,
  onShareAlertClick: PropTypes.func.isRequired
};

export default AlertShareButton;
