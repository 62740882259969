/**
 * Returns the decimal character for given locale for formatting a number, defaults to period
 */
export default function(locale) {
  switch (locale) {
    case 'en':
    case 'zh':
      return '.';

    case 'ca':
    case 'es':
    case 'fr':
    case 'it':
    case 'nl':
      return ',';

    default:
      // use the browser's toLocaleString method and grab the decimal character from that
      // (assuming we're given a locale and the browser knows how to format numbers)
      if (locale && !!(typeof Intl === 'object' && Intl && typeof Intl.NumberFormat === 'function')) {
        const localized = Number(12.34).toLocaleString(locale);
        return localized.match(/12(.*)34/)[1];
      }

      return '.';
  }
}
