// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import FilterFooter from '../FilterFooter';
import FilterHeader from '../FilterHeader';
import Autocomplete from 'common/components/Autocomplete';
import Dropdown from 'common/components/Dropdown';
import Picklist, { PicklistOption, PicklistSizes } from 'common/components/Picklist';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { addPopupListener } from './InputFocus';

// Constants
import { DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME } from './TextFilterEditor';
import { FilterEditorProps } from '../types';
import { OPERATOR } from '../SoqlFilter';
import { getOperator } from '../lib/Filters/index';
import * as ComputedColumnFilter from '../lib/Filters/ComputedColumnFilter';
import * as BaseFilter from '../lib/Filters/BaseFilter';

const scope = 'shared.components.filter_bar.text_filter';
export const SHOW_RESULTS_COUNT = 20;
export const FILTER_OFFSET = { DEFAULT: 0, MAX: 20 };

export interface ComputedColumnFilterEditorProps extends FilterEditorProps {
  appliedFilter: ComputedColumnFilter.ComputedColumnSoqlFilter;
}

interface ComputedColumnFilterEditorState {
  isLoadingData: boolean;
  dirtyFilter: ComputedColumnFilter.ComputedColumnSoqlFilter;
  topXOptions: PicklistOption[];
}

/**
 * This is very similar to `TextFilter`, but does some extra stuff to properly resolve
 * the label of computed columns. Ultimately, it would be cool to extract the
 * data-fetching logic from these components and pass the results into a shared UI component.
 */
class ComputedColumnFilterEditor extends Component<
  ComputedColumnFilterEditorProps,
  ComputedColumnFilterEditorState
> {
  curatedRegions: ComputedColumnFilter.CuratedRegion[];
  autocompleteContainer: HTMLDivElement;
  computedColumnFilter: HTMLDivElement;
  picklistContainerRef: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: ComputedColumnFilterEditorProps) {
    super(props);

    const { appliedFilter } = props;

    this.state = {
      dirtyFilter: _.cloneDeep(appliedFilter),
      isLoadingData: true,
      topXOptions: []
    };

    _.bindAll(this, [
      'applyFilter',
      'getSuggestions',
      'isDirty',
      'onSelectOption',
      'onUnselectOption',
      'renderHeader',
      'renderLoadingSpinner',
      'renderSelectedOption',
      'renderSuggestionsAutocomplete',
      'renderTopXOption',
      'resetFilter',
      'updateSelectedValues'
    ]);
  }

  getDataProvider(props: FilterEditorProps) {
    return props.dataProvider[0];
  }

  componentDidMount() {
    const { popupRef } = this.props;
    this.removePopupListener = addPopupListener(popupRef, this.computedColumnFilter, () => {
      this.popupListenerRemoved = true;
    });

    this.getTopXOptionsRespectingFilters();
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  getNullOption = () => {
    // Create the "null" suggestion to allow filtering on empty values.
    return {
      title: I18n.t('no_value', { scope }),
      value: null,
      group: I18n.t('suggested_values', { scope }),
      render: this.renderTopXOption
    };
  };

  getTopXOptionsRespectingFilters() {
    const { appliedFilter, allFilters, dataProvider, columns } = this.props;
    const { dirtyFilter } = this.state;

    this.setState({
      isLoadingData: true
    });
    const filter = appliedFilter;

    ComputedColumnFilter.getTopXOptionsRespectingFilters({
      filter,
      allFilters,
      columns,
      offset: 0,
      dataProviderConfigs: dataProvider
    })
      .then((result) => {
        // Check to make sure component is mounted
        if (!this.computedColumnFilter) {
          return;
        }
        const group = I18n.t('suggested_values', { scope });
        const newTopXOptions: PicklistOption[] = (result.topXOptions[0] ?? []).map((option) => {
          return {
            title: option.title,
            value: option.value,
            group,
            render: this.renderTopXOption
          };
        });

        this.setState({
          isLoadingData: false,
          topXOptions: newTopXOptions
        });
      })
      .catch((error) => {
        this.setState({ isLoadingData: false });
        console.error(`Soql like top values failed for ${BaseFilter.getFilterName(dirtyFilter, columns)}:`);
        console.error(error);
      });
  }

  getSuggestions(searchTerm: string, callback: (results: { results: any[] }) => void) {
    const { columns, dataProvider } = this.props;
    const { dirtyFilter } = this.state;

    if (_.isEmpty(searchTerm)) {
      if (typeof callback === 'function') {
        return callback({ results: [] });
      } else {
        return { results: [] };
      }
    }

    const filter = dirtyFilter;
    return ComputedColumnFilter.getSuggestions({
      filter,
      searchTerm,
      columns,
      dataProviderConfigs: dataProvider
    }).catch((error) => {
      console.error(`Soql like search failed for ${BaseFilter.getFilterName(dirtyFilter, columns)}:`);
      console.error(error);
    });
  }

  onSelectOption(option?: PicklistOption | null) {
    const { dirtyFilter } = this.state;
    const selectedValue = _.pick(option, ['title', 'value']);

    if (_.get(dirtyFilter, 'singleSelect', false)) {
      this.updateSelectedValues([selectedValue], this.applyFilter);
    } else {
      const unionedValues = _.unionBy(
        ComputedColumnFilter.getOperands(dirtyFilter),
        [selectedValue],
        (existingValue: PicklistOption) => existingValue.value
      );
      this.updateSelectedValues(unionedValues);
    }
  }

  onUnselectOption(option?: PicklistOption | null) {
    const { dirtyFilter } = this.state;

    const selectedValues = _.reject(
      ComputedColumnFilter.getOperands(dirtyFilter),
      (selectedValue) => selectedValue.value === option?.value
    );

    this.updateSelectedValues(selectedValues);
  }

  updateSelectedValues(nextSelectedValues: Pick<PicklistOption, 'value' | 'title'>[], callback?: () => void) {
    const { dirtyFilter } = this.state;
    const updatedFilter = ComputedColumnFilter.getEqualityComputedColumnFilter(dirtyFilter, {
      values: _.uniq(nextSelectedValues)
    });

    this.setState({ dirtyFilter: _.cloneDeep(updatedFilter) }, callback);
  }

  resetFilter() {
    const { columns, onUpdate } = this.props;
    const { dirtyFilter } = this.state;

    this.updateSelectedValues([]);

    const resetFilter = BaseFilter.reset(dirtyFilter, columns);
    onUpdate(resetFilter);
  }

  applyFilter() {
    const { onUpdate } = this.props;
    const { dirtyFilter } = this.state;

    onUpdate(dirtyFilter);
  }

  isDirty() {
    const { dirtyFilter } = this.state;
    const { appliedFilter } = this.props;
    return !_.isEqual(dirtyFilter, appliedFilter);
  }

  renderHeader() {
    const { dirtyFilter } = this.state;
    const { columns} = this.props;
    const headerProps = {
      name: BaseFilter.getFilterName(dirtyFilter, columns)
    };

    const placeholder =
      getOperator(dirtyFilter) === OPERATOR.NOT_EQUAL ? I18n.t('is_not', { scope }) : I18n.t('is', { scope }); // default to equals

    const dropdownProps = {
      onSelection: (option?: PicklistOption | null) => {
        const updatedFilter: ComputedColumnFilter.ComputedColumnSoqlFilter = ComputedColumnFilter.setOperator(
          dirtyFilter,
          option?.value
        );

        this.setState({ dirtyFilter: updatedFilter });
      },
      options: [
        { title: I18n.t('is', { scope }), value: OPERATOR.EQUALS },
        { title: I18n.t('is_not', { scope }), value: OPERATOR.NOT_EQUAL }
      ],
      placeholder,
      size: PicklistSizes.SMALL
    };

    const dropdown = !_.get(dirtyFilter, 'singleSelect', false) ? <Dropdown {...dropdownProps} /> : null;

    return <FilterHeader {...headerProps}>{dropdown}</FilterHeader>;
  }

  renderSelectedOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;

    return (
      <div className="picklist-selected-option">
        <SocrataIcon name={IconName.Filter} />
        <span className="picklist-selected-option-title">{title}</span>
        <SocrataIcon name={IconName.Close2} />
      </div>
    );
  }

  renderTopXOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;
    return <div className="picklist-suggestion-option">{title}</div>;
  }

  renderSuggestionsAutocomplete() {
    const autocompleteProps = {
      focusFirstResult: true,
      query: '',
      getSearchResults: this.getSuggestions,
      millisecondsBeforeSearch: DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME,
      onChooseResult: (title: string, result: PicklistOption) =>
        this.onSelectOption({
          title: result.title,
          value: result.value
        }),
      placeholder: I18n.t('search_placeholder', { scope }),
      onSelectSetSelectionAsQuery: false,
      showLoadingSpinner: true
    };
    return (
      <div
        className="suggestions-autocomplete-container"
        ref={(el: HTMLDivElement) => (this.autocompleteContainer = el)}
      >
        <Autocomplete {...autocompleteProps} />
      </div>
    );
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner-container">
        <span className="spinner-default" />
      </div>
    );
  }

  renderPicklist() {
    const { dirtyFilter, isLoadingData, topXOptions } = this.state;

    const fromFilterSelectedValues = ComputedColumnFilter.getOperands(dirtyFilter);

    const options = _.filter(topXOptions, (option) => {
      const value = _.find(fromFilterSelectedValues, (selectedValue) => selectedValue.value === option.value);
      return _.isNil(value);
    });

    const topXPicklistProps = {
      options,
      onSelection: this.onSelectOption,
      size: PicklistSizes.SMALL,
      value: false // To prevent highlighting of any item no-value option
    };

    const selectedOptions = _.map(fromFilterSelectedValues, (selectedValue) => ({
      group: I18n.t('selected_values', { scope }),
      title: _.isNull(selectedValue.title) ? I18n.t('no_value', { scope }) : selectedValue.title,
      value: selectedValue.value,
      render: this.renderSelectedOption
    }));

    const selectionPicklistProps = {
      options: selectedOptions,
      onSelection: this.onUnselectOption,
      size: PicklistSizes.SMALL,
      value: false // To prevent highlighting of any item no-value option
    };

    const selectedValuesSection = !dirtyFilter.singleSelect ? (
      <div className="picklist-selected-options">
        <Picklist {...selectionPicklistProps} />
      </div>
    ) : null;

    const picklistContainerAttributes = {
      className: 'picklist-options-container'
    };

    return (
      <div {...picklistContainerAttributes}>
        {selectedValuesSection}
        <div className="picklist-suggested-options">
          {isLoadingData ? this.renderLoadingSpinner() : <Picklist {...topXPicklistProps} />}
        </div>
      </div>
    );
  }

  render() {
    const { isReadOnly, onRemove, showRemoveButtonInFooter } = this.props;
    const { dirtyFilter } = this.state;

    const footerProps = {
      disableApplyFilter: !this.isDirty(),
      isReadOnly,
      onClickApply: () => this.applyFilter(),
      onClickRemove: onRemove,
      onClickReset: this.resetFilter,
      showApplyButton: !dirtyFilter.singleSelect,
      showRemoveButton: showRemoveButtonInFooter
    };

    const suggestionsAutocomplete = this.renderSuggestionsAutocomplete();
    const picklist = this.renderPicklist();

    return (
      <div
        className="filter-controls computed-column-filter text-filter"
        ref={(el: HTMLDivElement) => (this.computedColumnFilter = el)}
      >
        <div className="column-container">
          {this.renderHeader()}
          {suggestionsAutocomplete}
          {picklist}
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default ComputedColumnFilterEditor;
