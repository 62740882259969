import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from 'common/i18n';
import AlertSettingModal from 'common/notifications/components/AlertSettingModal/AlertSettingModal';
import { handleTab } from 'common/dom_helpers/keyPressHelpers';
import { ForgeButton } from '@tylertech/forge-react';

import './panel-footer.scss';

const scope = 'shared.site_chrome.notifications';

interface PanelFooterProps {
  clearAllUserNotifications: () => void;
  hasUnreadNotifications: boolean;
  hasUserNotifications: boolean;
  openClearAllUserNotificationsPrompt: boolean;
  showUserNotifications: boolean;
  toggleClearAllUserNotificationsPrompt: (open: boolean) => void;
}

interface PanelFooterState {
  showAlertSettingModal: boolean;
}

class PanelFooter extends Component<PanelFooterProps, PanelFooterState> {
  clearAllButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: PanelFooterProps) {
    super(props);
    this.clearAllButtonRef = React.createRef();
    this.state = { showAlertSettingModal: false };
  }

  closeModal = () => {
    this.setState({ showAlertSettingModal: false });
  };

  toggleSettingsModal = () => {
    this.setState({ showAlertSettingModal: !this.state.showAlertSettingModal });
  };

  renderModal = () => {
    const { showAlertSettingModal } = this.state;

    if (showAlertSettingModal) {
      return (
        <AlertSettingModal
          onClose={this.closeModal} />
      );
    }

    return null;
  };

  focusClearAllButton = () => {
    if (this.clearAllButtonRef.current) {
      this.clearAllButtonRef.current.focus();
    }
  };

  renderClearAllNotificationsPrompt = () => {
    const {
      clearAllUserNotifications,
      openClearAllUserNotificationsPrompt,
      toggleClearAllUserNotificationsPrompt
    } = this.props;
    if (openClearAllUserNotificationsPrompt) {
      return (
        <div className="inline-prompt">
          <p className="heading-text">{I18n.t('clear_all_text', { scope })}</p>
          <p>{I18n.t('clear_all_confirm', { scope })}</p>
          <div className="prompt-buttons-wrapper clearfix">
            <ForgeButton
              type="outlined"
              className="cancel-button"
              onClick={() => {
                this.focusClearAllButton();
                toggleClearAllUserNotificationsPrompt(false);
              }}
            >
              <button
                type="button"
              >
                {I18n.t('clear_all_confirm_no', { scope })}
              </button>
            </ForgeButton>
            <ForgeButton
              type="raised"
              onClick={() => {
                this.focusClearAllButton();
                toggleClearAllUserNotificationsPrompt(false);
                clearAllUserNotifications();
              }}
            >
              <button
                type="button"
              >
                {I18n.t('clear_all_confirm_yes', { scope })}
              </button>
            </ForgeButton>
          </div>
        </div>
      );
    }

    return null;
  };

  focusClosePanelButton = () => {
    const focusClosePanelButton = document.querySelector('.close-notifications-panel-link') as HTMLElement;
    if (focusClosePanelButton) {
      focusClosePanelButton.focus();
    }
  };

  renderSettingsButton = () => {
    const { showUserNotifications } = this.props;

    if (showUserNotifications) {
      return (
        <ForgeButton
          type="raised"
          className="setting-button"
          onClick={this.toggleSettingsModal}
          onKeyPress={handleTab(
            // Sometimes the user can accidentally put focus back on this panel while the AlertSettingModal
            // is still open. In that case, we should let them tab out of this panel and into AlertSettingModal.
            this.state.showAlertSettingModal ? null : this.focusClosePanelButton,
            this.focusClearAllButton,
            true)}
        >
          <button type="button">{I18n.t('setting', { scope })}</button>
        </ForgeButton>
      );
    }

    return null;
  };

  renderFooter = () => {
    const {
      hasUserNotifications,
      openClearAllUserNotificationsPrompt,
      toggleClearAllUserNotificationsPrompt
    } = this.props;

    return (
      <div className="buttons-wrapper clearfix">
        <ForgeButton
          type="outlined"
          ref={this.clearAllButtonRef}
          className="clear-all-button"
          onClick={() => toggleClearAllUserNotificationsPrompt(hasUserNotifications && !openClearAllUserNotificationsPrompt)}
        >
          <button
            type="button"
            disabled={!hasUserNotifications}
          >
            {I18n.t('clear_all_text', { scope })}
          </button>
        </ForgeButton>
        {this.renderClearAllNotificationsPrompt()}
        {this.renderSettingsButton()}
      </div>
    );
  };

  render() {
    return (
      <div className="footer-bar">
        {this.renderFooter()}
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state: { configurations: { showUserNotifications: any; }; }) => ({
  showUserNotifications: state.configurations.showUserNotifications
});

export default connect(mapStateToProps)(PanelFooter);
