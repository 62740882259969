import React from 'react';

interface RequiredStarProps {
  /** Whether or not to actually render the star */
  required: boolean;
}

/** A red star that visually denotes a form element as being required */
const RequiredStar: React.FunctionComponent<RequiredStarProps> = ({ required }) => {
  if (required !== true) {
    return null;
  }

  // we use aria-hidden here so that this is not read aloud by screen readers
  // since inputs are properly marked with aria-required, the screen reader already announces them as required
  return (
    <span aria-hidden="true" className="form-component-input-label-required-star" data-testid="required-star">
      *
    </span>
  );
};

export default RequiredStar;
