import { Component } from 'react';
import PropTypes from 'prop-types';

import TableCell from './TableCell';

/**
 * TableColumn doesn't render anything, it's just a props bag for configuration of the columns/row cells
 */
class TableColumn extends Component {
  static displayName = 'TableColumn';
  static propTypes = {
    /**
     * A component used to render each cell. By default, will render the cell value directly.
     * The row's value is provided as props, as in <MyComponent {...row} />.
     * Overrides any "children" function.
     */
    cellComponent: PropTypes.any,
    /**
     * Any additional props to send the cellComponent.
     */
    cellProps: PropTypes.object,
    /**
     * A function that takes the cell value and returns the nodes to render for the cell.
     * By default, will render the cell value directly.
     */
    children: PropTypes.func,

    /**
     * Class to apply to the td of cells in this column.
     */
    dataClassName: PropTypes.string,

    /**
     * The table is composed of an array of rows, each represented by an
     * array or hash (your choice).
     *
     * In turn, each TableColumn (that's this component!) is responsible
     * for showing one part of each row (again, an array or hash).
     * This prop specifies which part of the row this column should display.
     *
     * If each row is an array, this prop's value should be set to a numeric
     * index, which will be used to index into the row array.
     *
     * If each row is a hash, this prop's value should be set to the desired
     * key (a string), which will be used to look up the value in the hash.
     *
     * For example, if the table is rendered with the following data:
     * [
     *   { foo: 'cat', bar: 'tiger' }
     *   { foo: 'hyena', bar: 'lion' }
     * ]
     *
     * a <TableColumn dataIndex="bar" /> will render its column as:
     * +-----+
     * |tiger|
     * +-----+
     * |lion |
     * +-----+
     *
     */
    dataIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Whether or not this column is visible.
     */
    isVisible: PropTypes.bool,

    // Of note:
    // header: PropTypes.string.isRequired.
    // isActive: PropTypes.bool
    // onSort: PropTypes.func,
    // sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTION))
    ...TableCell.propTypes
  };

  static defaultProps = {
    children: (arg) => arg,
    dataClassName: null,
    isVisible: true
  };

  render() {
    return null;
  }
}

export default TableColumn;
