import CopyAssetModal from 'common/components/CopyAssetModal';
import { openModal, closeModal } from 'common/components/AssetActionBar/lib/modal_helper';
import { AssetType } from 'common/types/view';

export function launchCopyAssetModal(
  assetTitle: string,
  assetType: AssetType,
  onCopy?: (name: string) => void,
  basedOnName?: string
) {
  const copyAndCloseModal = (name: string) => {
    onCopy && onCopy(name);
    closeModal();
  };

  openModal(CopyAssetModal, {
    assetTitle,
    onCopy: copyAndCloseModal,
    onDismiss: closeModal,
    basedOnName: basedOnName,
    assetType
  });
}
