import React from 'react';

import SocrataIcon, { IconName } from '../SocrataIcon';
import { OptionPillProps } from './types';
/**
 * Render a "pill" representing an option that has been selected.
 * Also has a "delete" button to un-select the option.
 */
function SelectedOptionPill<OptionType, SelectType>({
  onRemoveSelectedOption,
  renderSelectedOptionContents,
  selectedOption,
  selectedOptionIndex
}: OptionPillProps<OptionType, SelectType>) {
  return (
    <div className="multiselect-pill">
      {renderSelectedOptionContents(selectedOption, selectedOptionIndex)}
      <button
        tabIndex={-1}
        className="multiselect-pill-remove-button"
        onClick={(e) => {
          e.preventDefault();
          onRemoveSelectedOption(selectedOption, selectedOptionIndex);
          return false;
        }}
      >
        <SocrataIcon name={IconName.Close} className="multiselect-pill-remove-icon" />
      </button>
    </div>
  );
}

export default SelectedOptionPill;
