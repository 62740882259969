import React, { Component } from 'react';
import I18n from 'common/i18n';
import _ from 'lodash';

import './index.scss';
import { ForgeFloatingActionButton, ForgeIcon } from '@tylertech/forge-react';

interface Props {
  pageYOffsetThreshold: number;
  toggleVisibilityCallback: (isVisible: boolean) => void;
  floatingActionButtonProps: object
}

interface State {
  isVisible: boolean;
}

export class ScrollToTop extends Component<Props, State> {
  static defaultProps = { pageYOffsetThreshold: 1500, toggleVisibilityCallback: _.noop};

  constructor(props: Props) {
    super(props);
    this.state = {
      isVisible: this.isPastThreshold()
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', (e) => {
      this.onScroll();
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.isVisible !== prevState.isVisible) {
      this.props.toggleVisibilityCallback(this.state.isVisible);
    }
  }

  isPastThreshold() {
    return window.pageYOffset >= this.props.pageYOffsetThreshold;
  }

  onScroll() {
    const shouldRender = this.isPastThreshold();

    if (shouldRender !== this.state.isVisible) {
      this.setState({ isVisible: shouldRender });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    const { isVisible } = this.state;
    const { floatingActionButtonProps } = this.props;
    const scrollToTopText = I18n.t('shared.scroll_to_top');
    return (
      <div className="scroll-to-top">
        { isVisible && (
          <ForgeFloatingActionButton {...floatingActionButtonProps}>
            <button type="button" aria-label={scrollToTopText} onClick={() => this.scrollToTop()}>
              <ForgeIcon name="arrow_upward" aria-hidden="true"/>
            </button>
          </ForgeFloatingActionButton>
      )}
     </div>
    );
  }
}

export default ScrollToTop;
