import _ from 'lodash';
import React, { Component, ReactElement } from 'react';

import I18n from 'common/i18n';

import { DefaultTimelineSamplingSizes, TimelineScopes } from '../../lib/constants';
import { allowTimelineScopeCurrent, hasMeasureEnded } from '../../lib/measureHelpers';

import * as ReportingPeriods from '../../lib/reportingPeriods';

import ComputedMeasureChart, { ComputedMeasureChartProps } from './ComputedMeasureChart';
import { Measure } from '../../types';

import '../measure-chart.scss';

interface State {
  lastMeasure: Measure | null;
  timelineScope: TimelineScopes;
}

export interface ChartProps extends Omit<ComputedMeasureChartProps, 'timelineScope'> {}

const getTimelineScope = (measure: Measure | null): TimelineScopes => {
  const scope = measure?.metricConfig?.display?.timelineScope;
  if (measure && scope && allowTimelineScopeCurrent(measure)) return scope;
  return TimelineScopes.ALL;
};

const translationScope = 'shared.performance_measures.chart';

// A high-level component to render a Performance Measures timeline chart. It is currently
// featured in the Measure landing page and in Measures embedded in Stories.
//
// All you need to use this component is a Measure object obtained from /api/measures_v1/<uid>.json
// and permission for the current user to see the Measure. The component itself will handle
// fetching data when needed.
//
// Internally, MeasureChart is a light wrapper around ComputedMeasureChart (a dumb component that
// expects all data as props). MeasureChart is also responsible for rendering the chart scope
// selector buttons (Current/All reporting periods), as changing scope involves temporarily
// modifying the Measure object and re-fetching data. Data fetching is handled by wrapping
// ComputedMeasureChart in the withComputedMeasure Higher Order Component.
export class MeasureChart extends Component<ChartProps, State> {
  // Take a look at ComputedMeasureChart props. We mostly pass
  // props straight through.
  static defaultProps = { measure: null };
  constructor(props: ChartProps) {
    super(props);

    this.state = {
      lastMeasure: props.measure,
      timelineScope: getTimelineScope(props.measure)
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: ChartProps) {
    // The timelineScope state is ephemeral - loading a new measure should reset it to the value in
    // the measure config. To implement this, we must track the last seen measure.
    if (this.state.lastMeasure !== newProps.measure) {
      this.setState({
        lastMeasure: newProps.measure,
        timelineScope: getTimelineScope(newProps.measure)
      });
    }
  }

  // This method takes a measure and applies the current state.timelineScope to it.
  // Please note that this method is a noop if state.timelineScope is TimelineScopes.ALL.
  //
  // To minimize impact on the rest of the platform, we treat switching to the "current"
  // reporting period as applying these transforms on the measure:
  // 1. Reporting period changed to "open"
  // 2. Reporting period start date changed to the current reporting period's start date.
  // 3. Reporting period length changed to timelineSampling.
  getScopedMeasure = (measure: Measure) => {
    const { timelineScope } = this.state;

    if (timelineScope === TimelineScopes.ALL || !allowTimelineScopeCurrent(measure)) {
      return measure;
    }

    /* eslint @typescript-eslint/no-non-null-asserted-optional-chain: "warn" */
    const reportingPeriod = measure?.metricConfig?.reportingPeriod!;
    if (!ReportingPeriods.isConfigValid(reportingPeriod)) {
      return measure;
    }

    const period = ReportingPeriods.getLastOpenReportingPeriod(reportingPeriod);

    const scopedMeasure = _.cloneDeep(measure);

    // Limit the measure to just _this_ reporting period. We're not interested in
    // historical data.
    scopedMeasure.metricConfig!.reportingPeriod!.startDateConfig.date =
      // TODO: is this a bug?
      period!.start.format('YYYY-MM-DD');

    // Set the size to the selected interval.
    const defaultSampling = DefaultTimelineSamplingSizes[reportingPeriod.size!];
    scopedMeasure.metricConfig!.reportingPeriod!.size =
      measure?.metricConfig?.display?.timelineSampling ?? defaultSampling;

    return scopedMeasure;
  };

  renderTimelineScopeChooser = () => {
    const { measure } = this.props;
    const { timelineScope } = this.state;
    const scopeOption = (scope: TimelineScopes, text: string): ReactElement =>
      scope === timelineScope ? (
        <span>{text}</span>
      ) : (
        <a
          href="#"
          className={`scope-${scope}`}
          onClick={(e) => {
            this.setState({ timelineScope: scope });
            e.preventDefault();
          }}
        >
          {text}
        </a>
      );

    if (!measure || !allowTimelineScopeCurrent(measure)) {
      return null;
    }

    return (
      <div className="measure-chart-scope-selector">
        {scopeOption(TimelineScopes.ALL, I18n.t('all_periods', { scope: translationScope }))}
        {scopeOption(
          TimelineScopes.CURRENT,
          hasMeasureEnded(measure)
            ? I18n.t('last_period', { scope: translationScope })
            : I18n.t('current_period', { scope: translationScope })
        )}
      </div>
    );
  };

  render() {
    const { measure } = this.props;
    const { timelineScope } = this.state;

    const scopedMeasure = measure ? this.getScopedMeasure(measure) : null;

    return (
      <div className="measure-chart">
        <ComputedMeasureChart {...this.props} measure={scopedMeasure} timelineScope={timelineScope}>
          {measure && this.renderTimelineScopeChooser()}
        </ComputedMeasureChart>
      </div>
    );
  }
}

export default MeasureChart;
