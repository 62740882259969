import PropTypes from 'prop-types';
import React from 'react';

export const FlannelFooter = (props) => (
  <section className="socrata-flannel-footer">{props.children}</section>
);

FlannelFooter.propTypes = {
  children: PropTypes.node
};

export default FlannelFooter;
