import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserNotification from './UserNotification';

class TransientNotifications extends Component {
  render() {
    const {
      onClearUserNotification,
      onToggleReadUserNotification,
      moveTransientNotificationIntoPanel,
      removeTransientNotification,
      transientNotifications
    } = this.props;

    return (
      <div>
        <ul className="transient-notifications-list">
          {transientNotifications.map((notification) =>
            <UserNotification
              isTransientNotification
              key={notification.id}
              moveTransientNotificationIntoPanel={moveTransientNotificationIntoPanel}
              removeTransientNotification={removeTransientNotification}
              notification={notification}
              onClearUserNotification={onClearUserNotification}
              onToggleReadUserNotification={onToggleReadUserNotification} />
          )}
        </ul>
      </div>
    );
  }
}

TransientNotifications.propTypes = {
  onClearUserNotification: PropTypes.func.isRequired,
  onToggleReadUserNotification: PropTypes.func.isRequired,
  moveTransientNotificationIntoPanel: PropTypes.func.isRequired,
  removeTransientNotification: PropTypes.func.isRequired,
  transientNotifications: PropTypes.array.isRequired
};

export default TransientNotifications;
