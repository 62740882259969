const reposition = function(hoverable) {
  const flyout = document.querySelector(`#${hoverable.getAttribute('data-flyout')}`);
  if (!flyout) {
    return;
  }
  const arrowHeight = 16;
  const flyoutWidth = flyout.offsetWidth;
  const windowWidth = document.body.offsetWidth;
  const hoverableDimensions = hoverable.getBoundingClientRect();

  let left = hoverableDimensions.left + (hoverable.offsetWidth / 2);
  let top = hoverableDimensions.top + hoverable.offsetHeight + arrowHeight;

  if (left + flyoutWidth > windowWidth) {
    flyout.classList.remove('flyout-right');
    flyout.classList.add('flyout-left');
    left -= flyoutWidth;
  } else {
    flyout.classList.remove('flyout-left');
    flyout.classList.add('flyout-right');
  }

  flyout.style.left = `${left}px`;
  flyout.style.top = `${top}px`;
};

const optionsFromElement = function(element) {
  const explicitClose = element.getAttribute('data-flyout-close-button') === 'true';

  return {
    explicitClose
  };
};

export default function FlyoutFactory(element) {
  const hoverables = Array.prototype.slice.apply(
    element.querySelectorAll('[data-flyout]')
  ).map(element => ({ element, options: optionsFromElement(element) }));

  if (element.hasAttribute('data-flyout')) {
    hoverables.push({ element, options: optionsFromElement(element) });
  }

  const hoverablesExist = !!hoverables.length;

  if (!hoverablesExist) {
    console.warn('FlyoutFactory: Unable to locate any hoverable elements.');
    return;
  }

  hoverables.forEach(function({ element: hoverable, options }) {
    if (hoverable.hasAttribute('flyout-bound')) {
      return;
    } else {
      hoverable.setAttribute('flyout-bound', true);
    }

    const flyout = element.querySelector(`#${hoverable.getAttribute('data-flyout')}`);

    // A mouseover on the flyout should not bubble up to the flyout anchor aka "hoverable".
    // Allowing it to do so triggers open(), which repositions the flyout.
    if (flyout) {
      flyout.addEventListener('mouseover', (e) => e.stopPropagation());
    }
    function show() {
      flyout.classList.remove('flyout-hidden');
    }

    const open = () => {
      show();
      reposition(hoverable);
    };
    const close = () => {
      flyout.classList.add('flyout-hidden');
    };

    hoverable.addEventListener('mouseover', open);
    hoverable.addEventListener('focus', open);

    if (options.explicitClose) {
      const closeNode = flyout.querySelector('.flyout-close');
      if (closeNode) {
        closeNode.addEventListener('click', e => {
          if (!e) {
            return;
          }
          e.preventDefault();
          close();
        });
        closeNode.addEventListener('keydown', e => {
          if (!e) {
            return;
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            close();
          } else if (e.keyCode === 13) {
            e.preventDefault();
            close();
          }
        });
      } else {
        hoverable.addEventListener('mouseout', close);
      }
    } else {
      hoverable.addEventListener('mouseout', close);
      hoverable.addEventListener('blur', close);
    }
  });
}
