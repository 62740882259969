import { cloneDeep, isNull, get, isNil, isUndefined, defaultTo, uniqBy, find, some, filter } from 'lodash';
import moment from 'moment';

import SoqlDataProvider from './SoqlDataProvider';
import SoqlHelpers from './SoqlHelpers.js';
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import { Vif } from 'common/visualizations/vif';

// Constants
const MAX_LEGAL_JAVASCRIPT_DATE_STRING = '9999-01-01';
const MIN_LEGAL_DATE_STRING = '1000-01-01';

export type TimeGrouping = 'year' | 'month' | 'day';

interface DataTable {
  columns: string[];
  rows: DataTableRows;
}

type DataTableRows = DataTableRow[];
type DataTableRow = (string | number | null)[];

export interface Options {
  maxRowCount: number;
  dateTruncFunction: () => void;
  precision: TimeGrouping;
}

function makeSocrataTimeDataRequest(vif: Vif, seriesIndex: number, options: Options) {
  const series = vif.series[seriesIndex];
  const soqlDataProvider = new SoqlDataProvider({
    datasetUid: get(series, 'dataSource.datasetUid'),
    domain: get(series, 'dataSource.domain'),
    clientContextVariables: get(series, 'dataSource.parameterOverrides')
  });
  const dimension = SoqlHelpers.dimension(vif, seriesIndex);
  const measure = SoqlHelpers.measure(vif, seriesIndex);
  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(vif, seriesIndex);
  const dateGuardClauseComponent = [
    `${dimension} IS NOT NULL AND`,
    `${dimension} < '${MAX_LEGAL_JAVASCRIPT_DATE_STRING}' AND`,
    `${dimension} >= '${MIN_LEGAL_DATE_STRING}' AND`,
    '(1=1)'
  ].join(' ');
  const whereClause =
    whereClauseComponents.length > 0
      ? `WHERE ${whereClauseComponents} AND ${dateGuardClauseComponent}`
      : `WHERE ${dateGuardClauseComponent}`;
  const groupByClause = SoqlHelpers.aggregationClause(vif, seriesIndex, 'dimension');

  const limit = options.maxRowCount + 1;
  const isUnaggregatedQuery =
    isNil(series.dataSource.dimension.aggregationFunction) &&
    isNil(series.dataSource.measure?.aggregationFunction);

  let queryString;

  const requireGroupingInSelect = vif.requireGroupingInSelect;

  // If there is no aggregation, we do not select the dimension with a
  // `date_trunc` function, but rather just ask for the actual values.
  if (isUnaggregatedQuery) {
    queryString = [
      'SELECT',
      `${dimension} AS ${SoqlHelpers.dimensionAlias()},`,
      `${measure} AS ${SoqlHelpers.measureAlias()}`,
      whereClause,
      `LIMIT ${limit}`
    ].join(' ');
  } else if (requireGroupingInSelect) {
    queryString = [
      'SELECT',
      `${options.dateTruncFunction}(${dimension}) AS ${SoqlHelpers.dimensionAlias()},`,
      `${vif.groupingColumnName} AS ${SoqlHelpers.groupingAlias()},`,
      `${measure} AS ${SoqlHelpers.measureAlias()}`,
      whereClause,
      `GROUP BY ${options.dateTruncFunction}(${groupByClause}), ${SoqlHelpers.groupingAlias()}`,
      `LIMIT ${limit}`
    ].join(' ');
  } else {
    queryString = [
      'SELECT',
      `${options.dateTruncFunction}(${dimension}) AS ${SoqlHelpers.dimensionAlias()},`,
      `${measure} AS ${SoqlHelpers.measureAlias()}`,
      whereClause,
      `GROUP BY ${options.dateTruncFunction}(${groupByClause})`,
      `LIMIT ${limit}`
    ].join(' ');
  }

  return soqlDataProvider
    .query(
      queryString,
      SoqlHelpers.dimensionAlias(),
      SoqlHelpers.measureAlias(),
      '',
      '',
      requireGroupingInSelect ? SoqlHelpers.groupingAlias() : null
    )
    .then(dealWithQueryResponse(vif, seriesIndex, options));
}

function dealWithQueryResponse(vif: Vif, seriesIndex: number, options: Options) {
  return (queryResponse: DataTable) => {
    if (queryResponse.rows.length > options.maxRowCount) {
      const error = new Error();
      const message = formatString(
        I18n.t('shared.visualizations.charts.timeline_chart.error_exceeded_max_row_count'),
        options.maxRowCount
      );
      error.message = message;
      error.name = 'exceededMaxRowCount';

      throw error;
    }

    const dimensionIndex = queryResponse.columns.indexOf(SoqlHelpers.dimensionAlias());
    const groupingIndex = queryResponse.columns.indexOf(SoqlHelpers.groupingAlias());
    const measureIndex = queryResponse.columns.indexOf(SoqlHelpers.measureAlias());
    const typeComponents = get(vif, `series[${seriesIndex}].type`, '').split('.');
    const chartType = typeComponents[0];
    const typeVariant = defaultTo(typeComponents[1], 'area');
    const treatNullValuesAsZero = get(vif, 'configuration.treatNullValuesAsZero', false);

    queryResponse.columns[dimensionIndex] = 'dimension';
    queryResponse.columns[measureIndex] = 'measure';
    if (groupingIndex !== -1) {
      queryResponse.columns[groupingIndex] = 'grouping';
    }

    // The dimension comes back as an ISO-8601 date, which means we can sort
    // dimension values lexically. This sort puts the dates in ascending
    // order, and the following forEach will cast numbers-as-strings to
    // numbers and undefined measure values as null.
    queryResponse.rows
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .sort((a, b) => (a[dimensionIndex]! <= b[dimensionIndex]! ? -1 : 1))
      // Note that because we are using a forEach and then assigning to the
      // argument provided by the forEach, we cannot use e.g. _.sortBy since
      // the collection it returns will be a clone of the original, not a
      // reference to it, and as such the reassignment-in-place will be done
      // on something that gets thrown away.
      .forEach((row: DataTableRow) => {
        const value = row[measureIndex];

        if (isUndefined(value)) {
          row[measureIndex] = treatNullValuesAsZero ? 0 : null;
        } else {
          row[measureIndex] = isNaN(Number(value)) ? value : Number(value);
        }
      });

    let rows;

    // Previously makeSocrataTimeDataRequest was only used by timelineCharts
    // of line and area variations, and only timelineChart.line needs to add
    // a blank row.  Now, time data can be requested by column, bar, timeline
    // and combo charts, so we specifically check for timelineChart.line.
    if (chartType === 'timelineChart' && typeVariant === 'line') {
      rows = addBlankRowAfterLastRow(vif, dimensionIndex, queryResponse.rows);
    } else {
      // If there are no values in a specific interval (according to the
      // date_trunc_* function) then we will not get a response row for that
      // interval.
      //
      // This complicates our ability to render gaps in the timeline for these
      // intervals, since d3 will just interpolate over them.
      //
      // The solution is to explicitly provide null values for intervals with
      // no values, which means that we need to expand the result rows into an
      // equivalent set in which the domain is monotonically increasing.
      //
      // This is only necessary for (or relevant to) area charts, however,
      // since the normal interpolation behavior is actually what we want for
      // the 'line' variant (a simple line chart, not an area chart).
      rows = forceDimensionMonotonicity(vif, seriesIndex, options.precision, queryResponse);
    }

    return {
      columns: queryResponse.columns,
      rows,
      precision: options.precision
    };
  };
}

function incrementDateByPrecision(startDate: string, precision: TimeGrouping) {
  switch (precision) {
    case 'year':
    case 'month':
    case 'day':
      return moment(startDate, moment.ISO_8601).add(1, precision).format(moment.HTML5_FMT.DATETIME_LOCAL_MS);

    default:
      throw new Error(`Cannot increment date by invalid precision "${precision}".`);
  }
}

// This is necessary to get area variant series to correctly show
// discontinuities in the data; otherwise intervals with no value (which are not
// returned by the SoQL API and must be inferred) will not be rendered, but
// rather interpolated over, which misrepresents the data.
//
// XXX: This tightly-coupled code needed a workaround to handle grouped results
// correctly. Tough cookies. The idea is: grouped results just need a single
// entry, with no specified group, for each "monotonic" time interval. However,
// they typically come in with more than one existing entry for each time
// interval already present. The existing algorithm here can only handle single
// dimension values, and cannot easily be forced to operate in other ways.
export function forceDimensionMonotonicity(
  vif: Vif,
  seriesIndex: number,
  precision: TimeGrouping,
  dataTable: DataTable
) {
  let rows;
  const dimensionIndex = dataTable.columns.indexOf('dimension');
  const treatNullValuesAsZero = get(vif, 'configuration.treatNullValuesAsZero', false);
  const requireGroupingInSelect = vif.requireGroupingInSelect || false;

  if (requireGroupingInSelect) {
    rows = uniqBy(dataTable.rows, (row) => row[0]);
  } else {
    rows = dataTable.rows;
  }

  if (rows.length === 0) {
    return rows;
  }

  const monotonicRows = createTimeBuckets(rows, dimensionIndex, precision);
  const timeBucketsWithData = fillTimeBucketsWithData(monotonicRows, rows, precision, treatNullValuesAsZero);

  if (requireGroupingInSelect) {
    // Now, we take the monotonic rows, transform them into 3-column results to
    // include grouping, remove entries we already have in the original, and
    // append /that/ to the original rows result. Also, yay linear search. This
    // assumes layout and ignores different column indices. Then again, so does
    // the rest of "forceDimensionMonotonicity".
    const arbitraryGroupingRow = find(dataTable.rows, (row) => !isNull(row[1]) && !isUndefined(row[1]));
    const arbitraryGrouping = arbitraryGroupingRow ? arbitraryGroupingRow[1] : '';
    const monotonicRowsWithGrouping = timeBucketsWithData.map((row) => {
      const [dimension, measure] = row;
      return [dimension, arbitraryGrouping, measure];
    });
    const monotonicRowsNotPresentInOriginal = filter(monotonicRowsWithGrouping, (row) => {
      return !some(dataTable.rows, (originalRow) => {
        return originalRow[0] === row[0];
      });
    });
    return dataTable.rows.concat(monotonicRowsNotPresentInOriginal);
  } else {
    return timeBucketsWithData;
  }
}

function createTimeBuckets(dataTableRows: DataTableRows, dimensionIndex: number, precision: TimeGrouping) {
  const chartStartDate = dataTableRows[0][dimensionIndex];
  const chartEndDate = moment(
    dataTableRows[dataTableRows.length - 1][dimensionIndex],
    moment.ISO_8601
  ).format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
  // create buckets
  const buckets = [];
  let bucketStartDate = moment(chartStartDate, moment.ISO_8601)
    .startOf(precision)
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS);

  // Probably should be an error, but we'll just return an empty array
  if (isNull(bucketStartDate)) return [];

  while (moment(bucketStartDate, moment.ISO_8601).isSameOrBefore(chartEndDate)) {
    buckets.push([bucketStartDate, null]);
    bucketStartDate = incrementDateByPrecision(bucketStartDate, precision);
  }

  return buckets;
}

function fillTimeBucketsWithData(
  timeBuckets: DataTableRows,
  dataTableRows: DataTableRows,
  precision: TimeGrouping,
  treatNullValuesAsZero: boolean
) {
  const timeBucketsWithData = cloneDeep(timeBuckets);
  dataTableRows.forEach((row) => {
    // We find the correct spot to fill the data with binary search
    // Maybe consider erroring early if there's enough data to make it worth it? The query will fail anyway.
    let low = 0;
    let high = timeBucketsWithData.length - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const bucketStartDate = timeBucketsWithData[mid][0];
      const nextBucketStartDate = incrementDateByPrecision(bucketStartDate as string, precision);
      if (isDateBetween(row[0] as string, bucketStartDate as string, nextBucketStartDate)) {
        if (treatNullValuesAsZero && isNull(row[1])) {
          timeBucketsWithData[mid][1] = 0;
        } else {
          timeBucketsWithData[mid][1] = row[1];
        }
        break;
      } else if (moment(row[0]).isBefore(bucketStartDate)) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }
  });

  return timeBucketsWithData;
}

// This is necessary to get line variant series to render the last point in the
// series with enough space on its right to be a target for the highlight and
// flyout. It should only be used for line variant series.
function addBlankRowAfterLastRow(vif: Vif, dimensionIndex: number, rows: any[]) {
  const secondToLastRowDatetime = new Date(rows[rows.length - 2][dimensionIndex]);
  const lastRowDatetime = new Date(rows[rows.length - 1][dimensionIndex]);
  const lastRowIntervalInMilliseconds = lastRowDatetime.getTime() - secondToLastRowDatetime.getTime();
  const treatNullValuesAsZero = get(vif, 'configuration.treatNullValuesAsZero', false);
  const blankRow = [];
  const dimensionValue = new Date(lastRowDatetime.getTime() + lastRowIntervalInMilliseconds)
    .toISOString()
    .substring(0, 23);
  const measureValue = treatNullValuesAsZero ? 0 : null;

  if (dimensionIndex === 0) {
    blankRow.push(dimensionValue);
    blankRow.push(measureValue);
  } else {
    blankRow.push(measureValue);
    blankRow.push(dimensionValue);
  }

  return rows.concat([blankRow]);
}

const isDateBetween = (date: string, bucketStartDate: string, nextBucketStartDate: string) => {
  // The [) make it so that it is checking inclusive of bucketStartDate but exclusive of nextBucketStartDate
  return moment(date).isBetween(bucketStartDate, nextBucketStartDate, undefined, '[)');
};

export default makeSocrataTimeDataRequest;
