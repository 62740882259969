import assign from 'lodash/assign';
import isFinite from 'lodash/isFinite';

import getDecimalCharacter from './getDecimalCharacter';
import getGroupCharacter from './getGroupCharacter';
import getLocale from './getLocale';

// Given a number or a string representing a number, returns a string delimited
// by options.groupCharacter (default , for en locale) that separates digits into groups of 3.
// The decimal portion will be separated by options.decimalCharacter (default . for en locale).
export default function(value, options) {
  const isNumeric = isFinite(value);

  value = String(value);

  const locale = getLocale(window);

  const defaultOptions = {
    groupCharacter: getGroupCharacter(locale),
    decimalCharacter: getDecimalCharacter(locale)
  };

  const commaifyOptions = assign({}, defaultOptions, options);

  // If input is numeric, decimal will always be period. Otherwise look for period, comma and default character respectively.
  const periodIndex = value.lastIndexOf('.');
  const commaIndex = value.lastIndexOf(',');

  const decimalCharacter = commaIndex !== -1 ? ',' : defaultOptions.decimalCharacter;
  const nonNumericDecimalCharacter = periodIndex > commaIndex ? '.' : decimalCharacter;
  const decimalCharacterToLookFor = isNumeric ? '.' : nonNumericDecimalCharacter;

  let pos = value.indexOf(decimalCharacterToLookFor);

  if (pos === -1) {
    pos = value.length;
  } else {
    value = value.replace(decimalCharacterToLookFor, commaifyOptions.decimalCharacter);
  }

  pos -= 3;

  while (pos > 0 && value.charAt(pos - 1) >= '0' && value.charAt(pos - 1) <= '9') {
    value = value.substring(0, pos) + commaifyOptions.groupCharacter + value.substring(pos);
    pos -= 3;
  }

  return value;
}
