import React from 'react';

import { SelectedOptionsCountProps } from './types';

/**
 * Used to let the user know how many of the max options
 * they've selected. This will not get rendered if no max is set.
 */
function SelectedOptionsCount<OptionType, SelectType>({
  maxSelectedOptions,
  selectedOptions = []
}: SelectedOptionsCountProps<OptionType, SelectType>) {
  return (
    <div className="multiselect-selected-options-count">
      {selectedOptions.length} / {maxSelectedOptions}
    </div>
  );
}

export default SelectedOptionsCount;
