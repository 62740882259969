import { get } from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { ForgeBadge } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import UserNotificationList from 'common/notifications/components/UserNotifications/UserNotificationList';

const scope = 'shared.site_chrome.notifications';

interface TabsProps {
  clearAllUserNotifications: () => void;
  filterNotifications: (tab: string) => void;
  hasEnqueuedUserNotifications: boolean;
  hasError: boolean;
  onClearUserNotification: (notificationId: string) => void;
  onLoadMoreUserNotifications: () => void;
  onSeeNewUserNotifications: () => void;
  onToggleReadUserNotification: (notificationId: string) => void;
  openClearAllUserNotificationsPrompt: boolean;
  selectedTab: string;
  showUserNotifications: boolean;
  tabs: string[];
  toggleClearAllUserNotificationsPrompt: (open: boolean) => void;
  userNotifications: {
    activity: {
      unread: number;
      alerts: number;
    };
  };
  viewOlderLink: boolean;
}

interface TabsState {
  showAlertSettingModal: boolean;
}

export class Tabs extends Component<TabsProps, TabsState> {
  renderTabs() {
    const { tabs } = this.props;

    return tabs.map((tab, index) => this.renderTab(tab, index));
  }

  renderTab = (tab: string, index: number) => {
    const { filterNotifications, selectedTab } = this.props;

    const unreadCount = this.unreadNotificationsCount(tab);

    return (
      <li key={index} className="notifications-tab-list-item">
        <Button
          className={classNames('notification-tab', { selected: tab === selectedTab })}
          onClick={() => filterNotifications(tab)}
        >
          {I18n.t(`filter_${tab}_notifications_tab_text`, { scope })}
          {unreadCount > 0 ? <ForgeBadge>{unreadCount}</ForgeBadge> : null}
        </Button>
      </li>
    );
  };

  unreadNotificationsCount = (tab: string) => {
    const { userNotifications } = this.props;
    let alertsAndNotificationsCount;
    switch (tab) {
      case 'activity':
        alertsAndNotificationsCount = get(userNotifications, 'activity.unread');
        break;
      case 'alert':
        alertsAndNotificationsCount = get(userNotifications, 'alert.unread');
        break;
    }
    return alertsAndNotificationsCount;
  };

  renderUserNotificationsList() {
    const {
      clearAllUserNotifications,
      hasEnqueuedUserNotifications,
      hasError,
      onClearUserNotification,
      onLoadMoreUserNotifications,
      onSeeNewUserNotifications,
      onToggleReadUserNotification,
      openClearAllUserNotificationsPrompt,
      selectedTab,
      toggleClearAllUserNotificationsPrompt,
      userNotifications,
      showUserNotifications,
      viewOlderLink
    } = this.props;

    return (
      <UserNotificationList
        clearAllUserNotifications={clearAllUserNotifications}
        filterNotificationsBy={selectedTab}
        hasEnqueuedUserNotifications={hasEnqueuedUserNotifications}
        hasError={hasError}
        onClearUserNotification={onClearUserNotification}
        onLoadMoreUserNotifications={onLoadMoreUserNotifications}
        onSeeNewUserNotifications={onSeeNewUserNotifications}
        onToggleReadUserNotification={onToggleReadUserNotification}
        openClearAllUserNotificationsPrompt={openClearAllUserNotificationsPrompt}
        showUserNotifications={showUserNotifications}
        toggleClearAllUserNotificationsPrompt={toggleClearAllUserNotificationsPrompt}
        userNotifications={userNotifications}
        viewOlderLink={viewOlderLink}
      />
    );
  }

  render() {
    const { selectedTab } = this.props;
    const notificationItemsClassName = classNames('notification-items-wrapper');

    return (
      <div className="notification-panel">
        <div className="notification-tabs">
          <ul className="notification-filters clearfix">{this.renderTabs()}</ul>
        </div>
        <div className={notificationItemsClassName} id={`${selectedTab}-notification-items`}>
          <div className="notification-items">{this.renderUserNotificationsList()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { userId: any; configurations: { showUserNotifications: any } }) => ({
  userId: state.userId,
  showUserNotifications: state.configurations.showUserNotifications
});

export default connect(mapStateToProps)(Tabs);
