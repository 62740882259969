import _ from 'lodash';
import BigNumber from 'bignumber.js';

import {
  filterWhereClauses,
  joinWhereClauses,
  setupSoqlDataProvider
} from './helpers';

export const calculateRecentValueMeasure = async (
  errors,
  measure,
  dateRangeWhereClause,
  dataProvider = setupSoqlDataProvider(measure) // For test injection
) => {
  const valueColumn = _.get(measure, 'metricConfig.arguments.valueColumn');
  const dateColumn = _.get(measure, 'metricConfig.dateColumn');
  const calculationFilters = _.get(measure, 'metricConfig.arguments.calculationFilters', []);

  let value = null;

  if (dateRangeWhereClause && valueColumn && dateColumn && dataProvider) {
    const mostRecentWhereClause = `(${dateRangeWhereClause} AND ${valueColumn} is not null)`;

    const calculationWhereClause = filterWhereClauses(calculationFilters);
    const whereClauses = joinWhereClauses([calculationWhereClause, mostRecentWhereClause]);

    const data = await dataProvider.rawQuery(
      `select ${valueColumn} where ${whereClauses} order by ${dateColumn} DESC, :id DESC limit 1`
    );

    value = _.get(data, [0, valueColumn], null);

    if (_.isNil(value)) {
      errors.notEnoughData = true;
    } else {
      value = new BigNumber(value);
    }
  } else {
    errors.calculationNotConfigured = !valueColumn || !dateColumn;
  }

  return { errors, result: { value } };
};
