import PropTypes from 'prop-types';
import React from 'react';

import { SocrataIcon } from 'common/components/SocrataIcon';

import { classNameScope } from './Autocomplete';

function CollapsedIcon({ onCollapsedChanged }) {
  return (
    <div
      className={`collapsed ${classNameScope}--collapsed-icon`}
      onClick={() => onCollapsedChanged(false)}>
      <SocrataIcon name="search" />
    </div>
  );
}

CollapsedIcon.propTypes = {
  onCollapsedChanged: PropTypes.func.isRequired
};

export default CollapsedIcon;
