import { ClientContextVariable } from 'common/types/clientContextVariable';

/**
 * @deprecated Use a ClientContextVariable[] instead. These types
 * are just documentation for the method below.
 */
type ParameterOverrides = Map<string, ParameterOverride>;
/** @deprecated Use a ClientContextVariable instead */
interface ParameterOverride {
  paramOverrideValue: string;
  paramDataType: string;
}

/**
 * @deprecated This method should only be used for merging old-style parameter overrides maps
 * into a ClientContextVariable array. Only stories with no `dataSource.filterParameterConfigurations`
 * key will have this old-style parameter overrides map. Since story JSON versioning is now done
 * server-side, this should never happen.
 */
export const mergeParameterOverrides = (
  parameterOverrides: ParameterOverrides,
  availableParameters: ClientContextVariable[] = []
): ClientContextVariable[] => {
  if (parameterOverrides instanceof Map) {
    return availableParameters.map((param) => {
      const override = parameterOverrides.get(param.name + '.' + param.viewId);
      if (override) {
        return { ...param, overrideValue: override.paramOverrideValue };
      }
      return param;
    });
  }

  return availableParameters;
};

export const mergeClientContextVariables = (
  overrides: ClientContextVariable[],
  all: ClientContextVariable[]
): ClientContextVariable[] => {
  return all.map((variable) => {
    const override = overrides.find(
      ({ name, viewId }) => name === variable.name && viewId === variable.viewId
    );
    if (override?.overrideValue) {
      return { ...variable, overrideValue: override.overrideValue };
    }
    return variable;
  });
};
