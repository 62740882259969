import sift from 'common/js_utils/sift';

export const TOKEN_NAMES = {
  MAPBOX_ACCESS_TOKEN: 'MAPBOX_ACCESS_TOKEN'
};

export const DEFAULT_TOKENS = {
  [TOKEN_NAMES.MAPBOX_ACCESS_TOKEN]:
    'pk.eyJ1Ijoic29jcmF0YSIsImEiOiJjamR3Y3k0cTA0MmF4MnhwMGE5MXRkaHdlIn0._REvgTbhb6Yg7E0DVkZrrQ'
};

export const getApiToken = (tokenName) => {
  switch (tokenName) {
    case TOKEN_NAMES.MAPBOX_ACCESS_TOKEN:
      // mapbox access token is set in
      //  - window.serverConfig.mapboxAccessToken (frontend)
      //  - window.MAPBOX_ACCESS_TOKEN   (storyteller)
      const configuredToken = sift(
        window,
        'serverConfig.mapboxAccessToken',
        'MAPBOX_ACCESS_TOKEN'
      );
      return configuredToken || DEFAULT_TOKENS[tokenName];
    default:
      throw new Error(`Unrecognized token name: ${tokenName}`);
  }
};
