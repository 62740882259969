import _ from 'lodash';
import Checkbox from 'common/components/Checkbox';
import I18n from 'common/i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './preference.scss';

const scope = 'shared.site_chrome.notifications.alert_setting_modal';

class TransientNotificationSettings extends Component {

  renderCheckBox(category, value, label) {
    const { onSettingsChange } = this.props;
    const checkboxId = _.uniqueId(category);

    return (
      <div className="preferences-checkbox">
        <Checkbox
          id={checkboxId}
          checked={value}
          onChange={(event) => onSettingsChange(category, { enable: event.target.checked })}>
          {label}
        </Checkbox>
      </div>
    );
  }

  renderNotificationSettingRow() {
    const { settings } = this.props;
    const category = 'transient_notification';
    const categoryData = _.get(settings, `${category}.0`, {});
    const label = I18n.t('transient_notification.notify_transient', { scope });

    return (
      <div className="transient_notification-option alert-preference-single-option-row">
        {this.renderCheckBox(category, (categoryData.enable || false), label)}
      </div>
    );
  }

  render() {
    return (
      <div className="transient_notification-settings preference-table">
        <div className="alert-preference-description-row">
          <div className="preference-title">
            {I18n.t('transient_notification.title', { scope })}
          </div>
        </div>
        {this.renderNotificationSettingRow()}
      </div>
    );
  }
}

TransientNotificationSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func
};

export default TransientNotificationSettings;
