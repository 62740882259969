import React, { Component } from 'react';
import {
  ForgeTextField,
  ForgeIconButton,
  ForgeButton,
  ForgeSelect,
  ForgeIcon,
  ForgeTooltip,
  ForgeCheckbox,
  ForgeInlineMessage
} from '@tylertech/forge-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { map } from 'lodash';
import { FeatureFlags } from 'common/feature_flags';
import { fetchTranslation } from 'common/locale';
import { ExportFormat } from 'common/downloadLinks';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal');

export interface ResourceType {
  label: string;
  url: string;
  value: ExportFormat;
  defaultType: boolean;
}

interface Props {
  onCopy: () => void;
  resources: ResourceType[];
  onSelectedUrlChange: (type: ResourceType) => void;
  selectedResource: ResourceType;
  foundryUrl?: string;
  filteringRadioButtons: JSX.Element | null;
  useResourceName: boolean;
  /** Only pass in if you have a resource name available. */
  onResourceNameUpdate?: (checked: boolean) => void;
  showApiLimitWarning: boolean;
  apiOrOdata: string;
  isOdata: boolean;
}

export default class UrlEndpoint extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onCopyClipboard = () => {
    this.props.onCopy();
  };

  renderResourceSelect() {
    const { resources } = this.props;
    const selectedResourceUrl = this.props.selectedResource.url;

    const options = map(resources, (type) => ({ label: type.label, value: type }));

    const props = {
      onChange: (event: CustomEvent) => {
        this.props.onSelectedUrlChange(event.detail);
      },
      options: options,
      label: this.props.isOdata ? t('odata_version') : t('data_format'),
      className: 'forge-select export-formats',
      selectedIndex: resources.findIndex((opt: ResourceType) => opt.url === selectedResourceUrl)
    };

    return <ForgeSelect data-testid={`${this.props.apiOrOdata}-endpoint-data-format-selector`} {...props} />;
  }

  renderAPIDocumentationLinks() {
    const { foundryUrl } = this.props;

    if (!foundryUrl || !FeatureFlags.value('enable_dataset_landing_page_foundry_links')) {
      return null;
    }

    return (
      <div className="api-documentation-links">
        <ForgeButton className="foundry-link">
          <a data-testid="api-foundry-doc-link" href={foundryUrl} target="_blank" rel="noreferrer">
            <span>{t('api_documentation_link_label')}</span>
            <ForgeIcon name="open_in_new" />
          </a>
        </ForgeButton>
        <ForgeButton>
          <a data-testid="api-dev-doc-link" href="https://dev.socrata.com" target="_blank" rel="noreferrer">
            <span>{t('developer_portal_link_label')}</span>
            <ForgeIcon name="open_in_new" />
          </a>
        </ForgeButton>
      </div>
    );
  }

  renderOdataDocumentationLinks() {
    const odataDoc =
      'https://support.socrata.com/hc/en-us/articles/115005364207-Access-Data-Insights-Data-using-OData';
    return (
      <div className="odata-documentation-links">
        <ForgeButton className="foundry-link">
          <a data-testid="odata-foundry-doc-link" href={odataDoc} target="_blank" rel="noreferrer">
            <span>{t('odata_documentation_link_label')}</span>
            <ForgeIcon name="open_in_new" />
          </a>
        </ForgeButton>
      </div>
    );
  }

  // we only show the use resource name checkbox when the onResourceNameUpdate function is provided
  renderUseResourceName() {
    // prevent function possibly undefined ts complaint
    const updateFunction = this.props.onResourceNameUpdate;
    if (updateFunction) {
      return (
        <ForgeCheckbox className="resource-name-checkbox" data-testid="use-resource-name-checkbox">
          <input
            type="checkbox"
            id="resource-checkbox-field"
            checked={this.props.useResourceName}
            onChange={(event: any) => updateFunction(event.target.checked)}
          />
          <label htmlFor="resource-checkbox-field" slot="label">
            {t('use_resource_name')}
          </label>
        </ForgeCheckbox>
      );
    } else {
      return null;
    }
  }

  renderApiLimitMessage() {
    if (this.props.showApiLimitWarning) {
      return (
        <ForgeInlineMessage theme="warning" className="api-limit-message">
          <ForgeIcon name="warning" slot="icon" />
          <div slot="title">{t('api_limit_title')}</div>
          <div dangerouslySetInnerHTML={{ __html: t('api_limit_body') }} />
        </ForgeInlineMessage>
      );
    } else {
      return null;
    }
  }

  render() {
    const { filteringRadioButtons, isOdata, apiOrOdata } = this.props;
    const selectedResourceUrl = this.props.selectedResource.url;
    return (
      <div className="resource-toggle endpoint">
        {this.renderResourceSelect()}
        {filteringRadioButtons}
        {!isOdata && this.renderApiLimitMessage()}
        <ForgeTextField>
          <input
            type="text"
            data-testid={`${apiOrOdata}-endpoint-input`}
            id={`${apiOrOdata}-endpoint`}
            value={selectedResourceUrl || ''}
            readOnly={true}
          />
          <label htmlFor={`${apiOrOdata}-endpoint`}>{t(`${apiOrOdata}_endpoint`)}</label>
          <span slot="trailing">
            <CopyToClipboard text={selectedResourceUrl}>
              <ForgeIconButton>
                <button
                  data-testid={`inline-${apiOrOdata}-copy`}
                  type="button"
                  onClick={this.onCopyClipboard}
                >
                  <ForgeIcon name="content_copy" />
                  <ForgeTooltip position={'bottom'}>{t('copy_clipboard')}</ForgeTooltip>
                </button>
              </ForgeIconButton>
            </CopyToClipboard>
          </span>
        </ForgeTextField>
        {this.renderUseResourceName()}
        {isOdata ? this.renderOdataDocumentationLinks() : this.renderAPIDocumentationLinks()}
      </div>
    );
  }
}
