/* Imports */
/* ============================================================================= */
import { isEmpty } from 'lodash';

import {
  GuidanceV2,
  WorkflowGuidance,
} from 'common/types/approvals';

import { submitFromGuidanceV2 } from 'common/core/approvals/guidanceLogic';





/* Method */
/* ============================================================================= */
/**
 * Given a WorkflowGuidance object and a GuidanceV2 object, this will use the GuidanceV2 object's submission URL to
 * make an actual submission to the approvals API.
 *
 * @param guidance WorkflowGuidance object
 * @param guidanceAction GuidanceV2 object to get submission URL from
 * @returns A Promise object
 */
export const executeApprovalsRequestV2 = (guidance: WorkflowGuidance, guidanceAction: GuidanceV2) => {
  let response: Promise<Response>;

  if (isEmpty(guidance)) {
    response = Promise.reject('no guidance response provided to executeApprovalsRequestV2');
  } else if (isEmpty(guidanceAction)) {
    response = Promise.reject('no guidance action provided to executeApprovalsRequestV2');
  } else {
    response = submitFromGuidanceV2(guidanceAction);
  }

  return response;
};

export default executeApprovalsRequestV2;
