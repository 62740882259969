import React, { FunctionComponent } from 'react';

import { internalSharingEnabled, isInternalAudienceDisabled } from 'common/components/AccessManager/Util';

import I18n from 'common/i18n';

import AudienceScopeChooserRadioButton from '.';

import { View, AudienceScope } from 'common/types/view';

interface InternalButtonProps {
  view: Partial<View>;
}

const InternalButton: FunctionComponent<InternalButtonProps> = ({ view }) => {
  const internalAudienceDisabled = isInternalAudienceDisabled();
  const internalAudienceDisabledMessage = internalAudienceDisabled
    ? I18n.t('shared.site_chrome.access_manager.audience.cannot_make_asset_internal')
    : null;

  if (!internalSharingEnabled()) {
    return null;
  }

  return (
    <AudienceScopeChooserRadioButton
      view={view}
      disabled={internalAudienceDisabled}
      hoverMessage={internalAudienceDisabledMessage}
      scope={AudienceScope.Site}
    />
  );
};

export default InternalButton;
