import React, { FunctionComponent, useState } from 'react';
import { ForgeIcon, ForgeIconButton, ForgeTooltip } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import CreateAssetsDialog from './../create_assets_dialog';
import { canSeeCreateAssetsDialog } from '../../utils/site_chrome_rights';

const CreateAssetButton: FunctionComponent = () => {
  const scope = 'shared.site_chrome.forge_omnibar.navigation';
  const [showDialog, setShowDialog] = useState(false);

  if (!canSeeCreateAssetsDialog()) {
    return null;
  }

  return (
    <>
      <ForgeIconButton slot="action" id="create-assets">
        <button
          type="button"
          aria-label="Create Assets"
          className="tyler-icons"
          onClick={() => setShowDialog(true)}
        >
          <ForgeIcon name="add_circle_outline" external external-type="standard" />
        </button>
        <div id="create-assets-dialog-container"></div>
      </ForgeIconButton>
      <ForgeTooltip open position={'bottom'}>
        {I18n.t(`${scope}.create`)}
      </ForgeTooltip>
      <CreateAssetsDialog open={showDialog} closeDialogCallback={() => setShowDialog(false)} />
    </>
  );
};

export default CreateAssetButton;
