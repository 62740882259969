import _ from 'lodash';
import { checkStatus, getDefaultPreferences } from 'common/notifications/api/helper';

function getDefaultHeaders() {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
}

function encodePreferenceFormat(preferences) {
  return _.values(preferences);
}


function decodePreferenceFormat(preferences) {
  let decodedSubscriptionPreferences = {};
  let subscriptionPreferences = _.get(preferences, 'subscription_preferences', []);

  subscriptionPreferences = _.unionBy(subscriptionPreferences, getDefaultPreferences(), 'name');
  _.each(subscriptionPreferences, (preference) => {
    decodedSubscriptionPreferences[preference.name] = preference;
  });

  return {
    subscription_preferences: decodedSubscriptionPreferences,
    settings: decodeSettings(preferences)
  };
}

function decodeSettings(preferences) {
  let settings = _.get(preferences, 'settings', {});
  if (_.isEmpty(settings)) {
    return {};
  }
  return _.groupBy(settings, 'name');
}

function encodeSettings(settings) {
  return _.flattenDeep(_.values(settings));
}

export const AlertPreferenceAPI = (() => {
  return {
    get: () => {
      return fetch('/api/notifications_and_alerts/preferences', {
        method: 'GET',
        headers: getDefaultHeaders(),
        credentials: 'same-origin'
      }).
      then((response) => checkStatus(response, 'An error was encountered while getting alert preferences, please try again or contact support@socrata.com')).
      then((response) => response.json()).
      then((response) => {
        return decodePreferenceFormat(response.data);
      });
    },
    set: (preferences, settings) => {
      let encodePreferences = encodePreferenceFormat(preferences);
      return fetch('/api/notifications_and_alerts/preferences', {
        method: 'POST',
        headers: getDefaultHeaders(),
        credentials: 'same-origin',
        body: JSON.stringify({ subscription_preferences: encodePreferences, settings: encodeSettings(settings) })
      }).
      then((response) => checkStatus(response, 'An error was encountered while getting alert preferences, please try again or contact support@socrata.com')).
      then((response) => response.json()).
      then((response) => response.data);

    }
  };
})();

export default AlertPreferenceAPI;
