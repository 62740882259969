import React, { Fragment } from 'react';
import _ from 'lodash';
const convertFromSnakeCaseToNormalCase = (snakeCase: string, forceUpperCase: boolean) => {
  const words = snakeCase.split('_');
  return words
    .map((word) => (forceUpperCase ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.slice(1)))
    .join(' ');
};

const mapOperatorToLabel = (operator: string) => {
  switch (operator) {
    case '=':
      return 'IS';
    case '!=':
      return 'IS NOT';
    case '>':
      return 'GREATER THAN';
    case '<':
      return 'LESS THAN';
    case '>=':
      return 'GREATER THAN OR EQUAL TO';
    case '<=':
      return 'LESS THAN OR EQUAL TO';
    case 'rangeInclusive':
      return 'BETWEEN AND INCLUDING';
    case 'rangeExclusive':
      return 'BETWEEN';
    case 'excludeNull':
      return 'EXCLUDE MISSING VALUES';
    case 'relativeDateRange':
      return 'RELATIVE DATE RANGE';
    case 'timeRange':
      return 'TIME RANGE';
    default:
      return convertFromSnakeCaseToNormalCase(operator, true);
  }
};

const parseNumericalArguments = (argument: any) => {
  let filterValue = '';
  if (argument.start && argument.end) {
    filterValue = `${argument.start} to ${argument.end}`;
  } else {
    if (argument.value) {
      filterValue = argument.value;
    }
  }
  return (
    <p className="selected-arguments-container">
      {<span className="argument-selected">{filterValue} </span>}
    </p>
  );
};

const parseDateTimeArguments = (argument: any) => {
  let filterValue = '';
  if (argument.start && argument.end) {
    filterValue = `${argument.start} to ${argument.end}`;
  } else {
    filterValue = `from ${new Date().toISOString().split('T')[0]} , PERIOD : ${argument.period}`;
  }
  return (
    <p className="selected-arguments-container">
      {<span className="argument-selected">{filterValue} </span>}
    </p>
  );
};

const parseFilter = (filter: any) => {
  if (filter.config.dataTypeName === 'text') {
    return (
      <>
        {convertFromSnakeCaseToNormalCase(filter.config.columns[0].fieldName, false)}
        <p>{mapOperatorToLabel(filter.config.arguments[0].operator)} : </p>
        {argumentsSelected(filter.config.arguments)}
      </>
    );
  } else {
    if (filter.config.dataTypeName === 'number') {
      return (
        <>
          {convertFromSnakeCaseToNormalCase(filter.config.columns[0].fieldName, false)}
          <p>{mapOperatorToLabel(filter.config.function)}</p>
          {parseNumericalArguments(filter.config.arguments)}
        </>
      );
    } else {
      return (
        <>
          {convertFromSnakeCaseToNormalCase(filter.config.columns[0].fieldName, false)}
          <p>{mapOperatorToLabel(filter.config.function)}</p>
          {parseDateTimeArguments(filter.config.arguments)}
        </>
      );
    }
  }
};

const argumentsSelected = (filterArguments: { [key: string]: string }[]) => {
  return (
    <p className="selected-arguments-container">
      {filterArguments.map((argument, index) => (
        <span key={index} className="argument-selected">
          {argument.operand} {index != filterArguments.length - 1 && ' , '}{' '}
        </span>
      ))}
    </p>
  );
};

export default function GlobalFilterPrintMode({ globalFilters }: { globalFilters: any[] }) {
  return (
    <div className="global-filter-print-mode">
      {globalFilters.map((filter, index) => {
        return (
          !_.isEmpty(filter.config.arguments) && (
            <Fragment key={index + ''.concat(filter.config.columns[0].fieldName)}>
              <span className="filter-name-print">{parseFilter(filter)}</span>
              {index !== globalFilters.length - 1 && <br />}
            </Fragment>
          )
        );
      })}
    </div>
  );
}
