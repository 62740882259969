// Vendor Imports
import d3 from 'd3';
import _ from 'lodash';
import $ from 'jquery';

// Project Imports
import BaseVisualization from './BaseVisualization';
import { onDrilldown, renderDrilldownPane } from './BaseVisualization/DrilldownContainer';
import I18n from 'common/i18n';

import { getMeasures } from '../helpers/measure';
import { DRILLDOWN_ANIMATION_DURATION } from './SvgConstants';
import Palette, { COLOR_VARY_BY } from '../helpers/palettes';
import { renderLegend } from './BaseVisualization/Legend';
import {
  getReferenceLinesWithValues,
  getShowLegend,
  getShowSlicePercentsInFlyouts,
  getShowValueLabels,
  getShowValueLabelsAsPercent,
  newVizCardLayoutEnabled,
  shouldRenderDrillDown,
  shouldAnimateColumnOrBar
} from 'common/visualizations/helpers/VifSelectors';



function SvgPieChart($element, vif, options) {
  const self = this;

  _.extend(this, new BaseVisualization($element, vif, options));

  // Constants
  const MARGINS = {
    verticalLayoutPieMargin: newVizCardLayoutEnabled(self.getVif()) ? 0.83 : 0.7,
    // arc multiplier for determining flyout position
    flyoutArcInnerMultiplier: 1.4,
    flyoutArcOuterMultiplier: 1.8,
    textLabelArcMultiplier: 1.6,
    // space between pie and legend, multiplied by container width
    pieToLegendMargin: 0.1
  };

  const LEGEND_CONTAINER_PADDING = 20;
  const LEGEND_RECT_SIZE = 18;
  const LEGEND_SPACING = 4;
  const LEGEND_WRAP_PADDING = 5;
  const MAX_HORIZONTAL_LEGEND_SIZE = 250;
  const MINIMUM_PIE_CHART_WIDTH = 100;
  const PERCENT_LABEL_THRESHOLD = 20;
  const PI2 = Math.PI * 2;
  const VALUE_LABEL_THRESHOLD = 25;
  const VERTICAL_LEGEND_SPACING = 20;

  let $chartElement; // chart container element
  let color; // renderData and renderLegend uses this
  let dimensionIndex;
  let flyoutDataToRender;
  let height; // container element height
  let legend;
  let measures;
  let outerWidth = 0; // width of pie
  let pieData; // Result of D3's pie chart helper.
  let pieDataToRender;
  let svg; // main svg element
  let width; // container element width

  // To animate the slices, set the below variable to
  // {
  //    startAngle: ..., // slice's start angle
  //    endAngle: ..., // slice's ending angle
  // }
  // When drilling down, the clicked on columns coordinates are set here.
  // When the new data is rendered, they will be animated from this clicked
  // column's position
  let animateSlicesFrom = null;
  let isDrilldownEnabled = true; // this.shouldRenderDrillDown();

  renderTemplate();
  renderSvgTemplate();

  /**
   * Public methods
   */

  this.render = ({
    newColumns,
    newComputedColumns,
    newData,
    newFlyoutData,
    newVif,
    newTableVif
  } = {}) => {
    if (!newData && !newFlyoutData && !pieDataToRender) {
      return;
    }

    this.clearError();

    self._previouslyTouchedPieValue = null;
    self._hidePieChartFlyoutContent = false;
    isDrilldownEnabled = shouldRenderDrillDown(newVif);

    if (newVif) {
      this.updateVif(newVif);
    }

    if (newColumns || newComputedColumns) {
      this.updateColumns(newColumns, newComputedColumns);
    }

    if (newData) {
      if (!_.isEqual(pieDataToRender, newData)) {
        renderSvgTemplate();
      }

      pieDataToRender = newData;
      self.setDefaultMeasureColumnPrecision(pieDataToRender);
    }

    if (newTableVif) {
      this.updateSummaryTableVif(newTableVif);
    }

    if (newFlyoutData) {
      flyoutDataToRender = newFlyoutData;
      self.setDefaultMeasureColumnPrecision(flyoutDataToRender);
    }

    if (self.isOnlyNullOrZeroValues(pieDataToRender)) {
      self.renderNoDataError();
      return;
    }

    renderData();
  };

  this.invalidateSize = () => {
    if ($chartElement && pieDataToRender) {
      renderData();
    }
  };

  this.destroy = () => {
    d3.select(self.$element[0]).select('svg').remove();

    self.$element.find('.socrata-visualization-container').remove();
  };

  /**
   * Private methods
   */

  function renderTemplate() {
    $chartElement = $(
      '<div>',
      {
        'class': 'pie-chart'
      }
    );

    self.$element.find('.socrata-visualization-chart-container').
      append($chartElement);
  }

  function renderSvgTemplate() {
    d3.select($chartElement.get(0)).select('svg').remove();
    svg = d3.select($chartElement.get(0)).append('svg');

    svg.
      append('g').
      attr('class', 'slices');
  }

  /**
   * Render Pie
   */
  function renderData() {
    renderDrilldownPane(self);

    determineSize();

    // creating pie
    pieData = d3.layout.pie().value(d => d[1]).sort(null);
    const total = pieDataToRender.rows.map(d => d[1]).reduce((acc, d) => acc + d, 0);
    measures = getMeasures(self, pieDataToRender);

    dimensionIndex = pieDataToRender.columns.indexOf('dimension');

    const measureIndex = 1;
    const measure = measures[0];
    measure.palette = new Palette({
      vif: self.getVif(),
      seriesIndex: measure.seriesIndex,
      colorVaryBy: COLOR_VARY_BY.DIMENSION
    });

    // create main svg element
    // append a "g" to group slices
    svg = d3.select($chartElement.get(0)).select('svg').
      attr('width', width).
      attr('height', height);

    let g = svg.
      select('g.slices');

    // Color from custom palette
    color = (datum, index) => {
      return d3.rgb(measure.getColor(index, datum.data[dimensionIndex]));
    };

    // pie slices
    let arcs = g.datum(pieDataToRender.rows).
      selectAll('g.slice-group').
      data(pieData).
      call((selection) => {
        const newSliceGroups = selection.
          enter().
          append('svg:g').
          attr('class', 'slice-group').
          attr('data-dimension-index', (d, dimensionIndex) => dimensionIndex);

        newSliceGroups.
          append('path').
          attr('class', 'slice');

        // invisible dot at the center of pie
        // hack solution to find "true" center of pie chart.
        newSliceGroups.
          append('svg:circle').
          attr('r', 0.1).
          attr('fill-opacity', 0);
      }).
      call((selection) => {
        selection.
          exit().
          remove();
      });

    let arcPaths = arcs.
      select('path.slice').
      attr('data-dimension-index', (d, dimensionIndex) => dimensionIndex).
      attr('data-percent', (d) => {
        // This calculates the percent for each slice scaled by the value
        return (total !== 0) ?
          (100 * d.data[measureIndex]) / total :
          null;
      }).
      attr('fill', color);

    attachPieEvents(arcs);

    if (!newVizCardLayoutEnabled(self.getVif())) { //Render old D3 legend - remove during EN-55520
      renderOldLegend();
    } else { // Render common legend used by other viz types
      const referenceLines = getReferenceLinesWithValues(self.getVif());
      const legendPieData = pieDataToRender.rows.map((pieItem, index) => {
        return {
          label: pieItem[0],
          index: index,
          color: measure.getColor(index, pieItem[0]),
          dashed: false
        };
      });
      const viewportWidth = Math.max(0, $chartElement.parent().width());
      const viewportHeight = Math.max(0, $chartElement.parent().height());
      renderLegend(self, {
        measures,
        referenceLines,
        viewportSize: {
          height: viewportHeight,
          width: viewportWidth
        },
        legendPieData
      });
    }

    renderArcLabels(getShowValueLabelsAsPercent(self.getVif()) );

    determineSize();

    svg.
      attr('width', width).
      attr('height', height);

    // pie radius
    const radius = outerWidth / 2;

    let leftOffset;
    let topOffset;

    if (isHorizontalLayout()) {
      leftOffset = horizontalLayoutOffsets().pieLeft;
      topOffset = horizontalLayoutOffsets().pieTop;
    } else {
      if (newVizCardLayoutEnabled(self.getVif())) {
        leftOffset = layoutOffsets().pieLeft;
        topOffset = layoutOffsets().pieTop;
      } else {
        leftOffset = verticalLayoutOffsets().pieLeft;
        topOffset = verticalLayoutOffsets().pieTop;
      }


    }

    svg.select('g.slices').
      attr(
        'transform',
        `translate(${leftOffset}, ${topOffset})`
      );

    // pie arc
    let arc = getArc(radius);

    // flyout's bigger arc
    let textLabelArc = getArc(radius * MARGINS.textLabelArcMultiplier);

    // apply arcs
    if (animateSlicesFrom) {
      // Note: We cannot use css transitions for svg:paths. Instead we have to use custom tweener, that
      // properly expands/slims down arcs.
      // When drilling down, we render the new slices using d3 interpolation. At the begining of the
      // animation, all slices will rendered as the parent slice (same location/size/start-angle).
      // At the end of the animation, each slice will take their respective position/size/start-angle.
      // During the animation, depending on the how long the animation is to happen and the current
      // time, we interpolate the slice attributes.
      const tweenStart = {
        startAngle: animateSlicesFrom.startAngle,
        endAngle: animateSlicesFrom.endAngle,
        padAngle: 0
      };

      const tweenPie = (d) => {
        const interpolate = d3.interpolate(tweenStart, d);
        return function(t) { return arc(interpolate(t)); };
      };

      arcPaths.
        transition().
        // ease("bounce").
        duration(DRILLDOWN_ANIMATION_DURATION).
        attrTween('d', tweenPie);

      animateSlicesFrom = null;
    } else {
      arcPaths.
        attr('d', arc);
    }

    // align labels
    // use textLabelArc for positioning
    svg.selectAll('g.slice-group text').
      attr('transform', (d) => `translate(${textLabelArc.centroid(d)})`);

    const arcRadius = arc.outerRadius()();

    // Show/hide labels according to length of each slice
    const labelVisibilityThreshold =
      getShowValueLabelsAsPercent(self.getVif())
        ? PERCENT_LABEL_THRESHOLD
        : VALUE_LABEL_THRESHOLD;

    svg.selectAll('g.slice-group path').
      each(function(d) {
        const length = calculateArcLength(arcRadius, d.startAngle, d.endAngle);
        const textEl = d3.select(this.parentNode).select('text');
        const visibility = length >= labelVisibilityThreshold ? 'visible' : 'hidden';

        textEl.style('visibility', visibility);
      });
  }

  /**
   * Render old D3 legend - remove during EN-55520
   */
  function renderOldLegend() {
    // Clear any existing legend rows
    svg.selectAll('.legend-row').remove();

    if (!getShowLegend(self.getVif(), true)) {
      return;
    }

    // create legend rows with a domain created with data and decorate rows
    legend = svg.
      datum(pieDataToRender.rows).
      selectAll('.legend-row').
      data(pieData).
      call((selection) => {
        selection.
          enter().
          append('g').
          attr('class', 'legend-row');
      }).
      call((selection) => {
        selection.
          exit().
          remove();
      });

    // create colored squares
    legend.append('rect').
      attr('width', LEGEND_RECT_SIZE).
      attr('height', LEGEND_RECT_SIZE).
      attr('shape-rendering', 'crispEdges').
      style('fill', color).
      style('stroke', color);

    const getText = (d) => self.getDimensionColumnFormattedValueText({
      dataToRender: pieDataToRender,
      value: _.get(d.data, dimensionIndex)
    });

    // create legend texts
    legend.append('text').
      attr('x', LEGEND_RECT_SIZE + LEGEND_SPACING).
      attr('y', LEGEND_RECT_SIZE - LEGEND_SPACING).
      attr('data-text', getText).
      text(getText);

    attachLegendEvents(legend);
    resizeLegend(legend);
  }

  /**
   * Render Arc Labels
   */
  function renderArcLabels(showPercentages) {
    svg.
      selectAll('g.slice-group').
      selectAll('text').
      remove();

    if (!getShowValueLabels(self.getVif()) ) {
      return;
    }

    svg.selectAll('g.slice-group').
      append('svg:text').
        // Positioning text on bigger arc's center point
        attr('text-anchor', 'middle').
        // old function syntax used for this binding
        text(function(d) {
          // This renders the percent value (calculated above) into the flyout
          let percent = d3.select(this.parentNode).select('.slice').attr('data-percent');
          let percentAsString = renderPercentLabel(percent);

          if (showPercentages) {
            return percentAsString;
          } else {
            return self.getMeasureColumnFormattedValueText({
              dataToRender: pieDataToRender,
              measureIndex: 0,
              value: d.data[1]
            });
          }
        });
  }

  /**
   * Resize and reposition legend
   */
  function resizeLegend(legend) {

    if (isHorizontalLayout()) {

      svg.selectAll('.legend-row').
        attr(
          'transform',
          (d, index) => {

            return `translate(${horizontalLayoutOffsets().legendLeft}, ` +
              `${horizontalLayoutOffsets().legendTop(index)})`;
          }
        );

      const textMaxLength = width -
        horizontalLayoutOffsets().legendLeft -
        LEGEND_CONTAINER_PADDING;

      // go over all texts to wrap
      svg.selectAll('.legend-row')[0].forEach(el => {
        let thisNode = d3.select(el.childNodes[1]);
        let text = thisNode.node().getAttribute('data-text');
        thisNode.text(text);

        let textLength = thisNode.node().getComputedTextLength();
        let computedTextMaxLength = textMaxLength - 2 * LEGEND_WRAP_PADDING;

        while (textLength > computedTextMaxLength && text.length > 0) {
          text = text.slice(0, -1);
          thisNode.text(`${text}...`);
          textLength = thisNode.node().getComputedTextLength();
        }
      });
    } else {

      // Max height available to legend
      const legendMaxHeight = (height - verticalLayoutOffsets().legendTop);
      // Row height
      const rowHeight = LEGEND_RECT_SIZE + LEGEND_SPACING;
      // Max row count can fit in available space
      const maxRowCount = Math.floor(legendMaxHeight / rowHeight);
      // Distributing items to rows
      const itemsPerRow = Math.ceil(
        svg.selectAll('.legend-row')[0].length / maxRowCount
      );
      // Max possible item width
      const maxLegendItemWidth = width * 0.8 / itemsPerRow;

      let rowLengths = [];

      svg.selectAll('.legend-row')[0].forEach((el, index) => {
        // Current Row
        let currentRow = index == 0 ? 0 : Math.floor(index / itemsPerRow);
        // Restore legend text to original
        let thisNode = d3.select(el.childNodes[1]);
        let text = thisNode.node().getAttribute('data-text');

        thisNode.text(text);

        // Get legend text length
        let textLength = thisNode.node().getComputedTextLength();

        // Ellipsis wrap legend text
        while (
          textLength >
          (
            maxLegendItemWidth -
            VERTICAL_LEGEND_SPACING -
            LEGEND_WRAP_PADDING
          ) &&
          text.length > 0
        ) {

          text = text.slice(0, -1);
          thisNode.text(`${text}...`);
          textLength = thisNode.node().getComputedTextLength();
        }

        // Complete length of item
        // colored rect + spacing + text
        let itemLength = textLength +
          LEGEND_RECT_SIZE +
          LEGEND_SPACING +
          VERTICAL_LEGEND_SPACING;

        // add item length to total current row length
        rowLengths[currentRow] = (rowLengths[currentRow] === undefined) ?
          itemLength :
          rowLengths[currentRow] + itemLength;
      });

      let rowPaddings = rowLengths.map(val => (width - val) / 2);
      let lastEndPosition = 0;

      svg.selectAll('.legend-row')[0].forEach((el, index) => {
        let textLength = d3.select(el.childNodes[1]).node().
          getComputedTextLength();

        // Current Row
        let currentRow = Math.floor(index / itemsPerRow);

        // Offset from top for current row
        let offsetTop = verticalLayoutOffsets().legendTop +
          currentRow *
          rowHeight;

        // Complete length of item
        // colored rect + spacing + text
        let itemLength = textLength + LEGEND_RECT_SIZE + LEGEND_SPACING;

        // item's left offset, reset to 0 every VERTICAL_LEGEND_ITEMS_PER_ROW
        let offsetLeft = index % itemsPerRow === 0 ? rowPaddings[currentRow] :
          lastEndPosition + VERTICAL_LEGEND_SPACING;

        // set left offset
        el.setAttribute('transform', `translate(${offsetLeft}, ${offsetTop})`);

        // set last end point for next item
        lastEndPosition = offsetLeft + itemLength;
      });
    }
  }

  /**
   * Get size of container, determine outer width of pie
   * Sets width, height, outerWidth in upper scope
   */
  function determineSize() {
    width = $chartElement.width();
    height = $chartElement.height();

    // Start by fitting the pie within the allotted margins.
    outerWidth = width * MARGINS.verticalLayoutPieMargin;
    // Clamp the value between a min-width and max (based on height).
    outerWidth = _.clamp(outerWidth, MINIMUM_PIE_CHART_WIDTH, height * MARGINS.verticalLayoutPieMargin);
  }

  /**
   * Determines if layout is horizontal
   *
   * @returns {boolean}
   * EN-55520 - Can be removed when we clean up after Forge is released.
   */
  function isHorizontalLayout() {
    if (newVizCardLayoutEnabled(self.getVif())) {
      return false;
    }
    const padding = width * MARGINS.pieToLegendMargin;
    const contentWidth = getShowLegend(self.getVif(), true) ?
      outerWidth + padding + MAX_HORIZONTAL_LEGEND_SIZE :
      outerWidth;

    return contentWidth < width;
  }

  /**
   * Calculates pie center left offset and layout left offset in horizontal
   * layout.
   *
   * @returns {
   *   {
   *     pieLeft: number,
   *     pieTop: number,
   *     legendLeft: number,
   *     legendTop: function
   *   }
   * }
   */
  function horizontalLayoutOffsets() {
    const padding = width * MARGINS.pieToLegendMargin;
    const contentWidth = getShowLegend(self.getVif(), true) ?
      outerWidth + padding + MAX_HORIZONTAL_LEGEND_SIZE :
      outerWidth;

    const radius = outerWidth / 2;
    const pieTop = height / 2;
    const leftPadding = (width - contentWidth) / 2;
    const pieLeft = leftPadding + radius;
    // single legend row height
    const legendRowHeight = LEGEND_RECT_SIZE + LEGEND_SPACING;
    // total legend height
    const legendHeight = legendRowHeight * pieDataToRender.rows.length;
    const legendLeft = pieLeft + radius + padding;
    // legend offset from top
    const legendTopOffset = (height - legendHeight) / 2;
    const legendTop = index => legendTopOffset + index * legendRowHeight;

    return { pieLeft, pieTop, legendLeft, legendTop };
  }

  /**
   * Calculates pie center left offset and layout left offset in vertical layout
   *
   * @returns {
   *   {
   *     pieLeft: number,
   *     pieTop: number,
   *     legendLeft: number,
   *     legendTop: number
   *   }
   * }
   */
  function verticalLayoutOffsets() {
    const radius = outerWidth / 2;
    const pieMargin = outerWidth * 0.05;
    const pieTop = radius + pieMargin;
    const pieLeft = width / 2;
    const legendLeft = pieLeft;
    const legendTop = outerWidth + pieMargin * 2;

    return { pieLeft, pieTop, legendLeft, legendTop };
  }

  function layoutOffsets() {
    const radius = outerWidth / 2;
    const pieMargin = outerWidth * 0.05;
    const pieTop = radius + pieMargin;
    const pieLeft = width / 2;

    return { pieLeft, pieTop };
  }

  /**
   * Generate arc with given radius
   *
   * @param radius
   * @returns D3 Arc
   */
  function getArc(radius) {

    return d3.svg.arc().
      innerRadius(0).
      outerRadius(radius);
  }

  function setAnimateSlicesFrom(d) {
    if (isDrilldownEnabled) {
      animateSlicesFrom = {
        startAngle: d.startAngle,
        endAngle: d.endAngle
      };
    }
  }

  function shouldAnimateArc(dimensionValue) {
    shouldAnimateColumnOrBar({
      vif: self.getVif(),
      columnData: pieDataToRender,
      dimensionValue
    });
  }

  /**
   * Attach pie events
   */
  function attachPieEvents(arcs) {

    // bind flyout to slices
    arcs.
      on('mouseover', (d, dimensionIndex, measureIndex) => {
        if (self._isTouchstartEventEnabled && self._hidePieChartFlyoutContent) {
          return;
        }
        const pathElement = svg.select(`.slice[data-dimension-index="${dimensionIndex}"]`)[0][0];

        // Pie chart radius
        const radius = outerWidth / 2;

        // This arc's middle point in radian
        const arcMidAngle = ((d.endAngle - d.startAngle) / 2) + d.startAngle;

        // This arc's outer border length in px
        const length = calculateArcLength(radius, d.startAngle, d.endAngle);

        // Are labels plain or percentage ?
        const labelVisibilityThreshold =
          getShowValueLabelsAsPercent(self.getVif())
            ? PERCENT_LABEL_THRESHOLD
            : VALUE_LABEL_THRESHOLD;

        // Is labels visible with this arc's length and label styling ?
        const labelVisibility = length >= labelVisibilityThreshold ?
          'visible' :
          'hidden';

        // Decide which arc multiplier to use
        let arcMultiplier;
        if (labelVisibility) {
          // If between 130 and 315 degrees display flyout on a bigger arc's middle point
          // If not use a smaller arc's middle point
          // In other words; try to avoid covering label with flyout window.
          arcMultiplier = _.inRange(arcMidAngle, 2.35619, 5.49779) ?
            MARGINS.flyoutArcInnerMultiplier :
            MARGINS.flyoutArcOuterMultiplier;
        } else {
          arcMultiplier = MARGINS.textLabelArcMultiplier;
        }

        // create flyout's arc
        const flyoutArc = getArc(radius * arcMultiplier);

        // Mid point of flyout arc
        const midPoint = flyoutArc.centroid(d);

        // Getting pie center from the dot at the center
        const pieCenter = svg.select('circle')[0][0].getBoundingClientRect();

        // Arc mid point is relative, so we're adding pie center offset to it
        const flyoutPositionX = pieCenter.left + midPoint[0];
        const flyoutPositionY = pieCenter.top + midPoint[1];

        const dimensionValue = _.get(d, 'data[0]');
        const percent = getShowSlicePercentsInFlyouts(self.getVif())  ?
          Math.round(Number(pathElement.getAttribute('data-percent'))) :
          undefined;
        const value = d.value;

        showFlyout({
          dimensionIndex,
          dimensionValue,
          element: pathElement,
          flyoutPositionX,
          flyoutPositionY,
          measureIndex,
          percent,
          value
        });
      }).
      on('click', (d) => {
        if (self._isTouchstartEventEnabled) {
          return;
        }
        const dimensionValue = _.get(d, 'data[0]');

        if (shouldAnimateArc(dimensionValue)) {
          setAnimateSlicesFrom(d);
        }

        onDrilldown(self, dimensionValue, pieDataToRender);
      }).
      on('touchstart', (d) => {
        const dimensionValue = _.get(d, 'data[0]');

        if (self._previouslyTouchedPieValue === dimensionValue) {
          self._hidePieChartFlyoutContent = true;
          hideFlyout();

          if (shouldAnimateArc(dimensionValue)) {
            setAnimateSlicesFrom(d);
          }

          onDrilldown(self, dimensionValue, pieDataToRender);
          self._previouslyTouchedPieValue = null;
        } else {
          self._previouslyTouchedPieValue = dimensionValue;
        }

        self._isTouchstartEventEnabled = true;
      }).
      on('mouseleave', hideFlyout);
  }

  /**
   * Attach legend events
   */
  function attachLegendEvents(legend) {
    // bind flyout to legend rows
    legend.
      on('mouseover', (d, dimensionIndex) => {
        // Delegate to slice.
        var evt = new MouseEvent('mouseover');
        svg.node().querySelector(`.slice-group[data-dimension-index="${dimensionIndex}"]`).dispatchEvent(evt);
      }).
      on('mouseleave', hideFlyout);
  }

  function showFlyout({
    dimensionIndex,
    dimensionValue,
    element,
    flyoutPositionX,
    flyoutPositionY,
    measureIndex,
    percent,
    value
  }) {
    const $content = self.getFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measureIndex,
      measures,
      nonFlyoutDataToRender: pieDataToRender,
      percent,
      value
    });

    // Payload
    const payload = {
      element,
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true,
      flyoutOffset: {
        left: flyoutPositionX,
        top: flyoutPositionY
      }
    };

    self.emitEvent(
      'SOCRATA_VISUALIZATION_PIE_CHART_FLYOUT',
      payload
    );
  }

  /**
   * Hide Flyout
   */
  function hideFlyout() {

    self.emitEvent(
      'SOCRATA_VISUALIZATION_PIE_CHART_FLYOUT',
      null
    );
  }

  /**
   * Formats given percentage as string
   */
  function renderPercentLabel(percent) {
    return Math.round(Number(percent)) + I18n.t('shared.visualizations.charts.common.percent_symbol');
  }

  /**
   * Calculates arc's length for given startAngle and endAngle
   * @param {number} radius
   * @param {number} startAngle
   * @param {number} endAngle
   * @return {number}
   */
  function calculateArcLength(radius, startAngle, endAngle) {
    var angleDiff = endAngle - startAngle;
    var circumference = PI2 * radius;
    return (angleDiff * circumference) / PI2;
  }

}

export default SvgPieChart;
