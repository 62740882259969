import { defaultHeaders, checkStatus } from 'common/http';

export interface ViewBlockingDeletion {
  owner: string;
  uid: string;
  domain: string;
  name: string;
  link: string;
}

export interface ViewsNeedingDeletionResponse {
  hasViewsBlockingDeletion: boolean;
  /**
   * List of views blocking deletion. Provided only when the user has permission.
   */
  viewsBlockingDeletion?: Array<ViewBlockingDeletion>;
}

export const fetchViewsThatNeedToBeDeletedToDeleteView =
  async (uid: string, revisionSeq: number | undefined, assetIsStoryDraft: boolean): Promise<ViewsNeedingDeletionResponse> => {
  if (revisionSeq != undefined || assetIsStoryDraft) {
    return {
      hasViewsBlockingDeletion: false
    };
  }
  const fetchOptions: RequestInit = {
    method: 'GET',
    credentials: 'same-origin',
    headers: defaultHeaders
  };

  const url = `/views/${uid}.json?method=getViewsThatNeedToBeDeletedToDeleteView`;
  const response = await (fetch(url, fetchOptions).then(checkStatus));
  return await response.json() as ViewsNeedingDeletionResponse;
};

export const deleteView = async (uid: string) => {
  return await deleteCall(`/api/views/${uid}.json`);
};

export const deleteRevision = async (uid: string, revisionSeq: number) => {
  return await deleteCall(`/api/publishing/v1/revision/${uid}/${revisionSeq}`);
};

export const deleteStoryDraft = async (uid: string) => {
  return await deleteCall(`/stories/api/v1/stories/${uid}/drafts`);
};

const deleteCall = async (url: string) => {
  const fetchOptions: RequestInit = {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: defaultHeaders
  };

  return await checkStatus(await fetch(url, fetchOptions));
};
