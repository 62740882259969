/* Imports */
/* ============================================================================= */
import { WorkflowGuidance } from 'common/types/approvals';

import {
  approveAssetV2,
  assetIsPendingV2,
  canSubmitChangeAudienceV2,
  canSubmitUpdatePublishedV2,
  canSubmitV2,
  changeAudienceRequestV2,
  expectedOutcome,
  publishedUidV2,
  rejectAssetV2,
  sitesThatWillBeFederatedToIfApprovedToPublicV2,
  sitesThatWillBeFederatedToIfMadePublicV2,
  submitAnyAvailableRequestV2,
  updatedPublishedAssetRequestV2,
  willEnterApprovalQueueV2,
  withdrawAssetV2
} from 'common/core/approvals/guidanceLogic';
import { WorkflowGuidanceHelper } from 'common/core/approvals/Types';
import { isWorkflowGuidance } from '.';





/* Method */
/* ============================================================================= */
/**
 * Get a guidance helper object for a given workflow guidance
 * @param guidance WorkflowGuidance object
 * @returns WorkflowGuidanceHelper object
 */
export const getWorkflowGuidanceHelper = (guidance: WorkflowGuidance): WorkflowGuidanceHelper => {
  if (!isWorkflowGuidance(guidance)) throw new Error('Invalid guidance object passed to getWorkflowGuidanceHelper');

  const guidanceHelper: WorkflowGuidanceHelper = {
    /* Async Approval Actions */
    /* ----------------------------------------------- */
    approve: (notes = '') => approveAssetV2(guidance, notes),
    reject: (notes = '') => rejectAssetV2(guidance, notes),
    withdraw: () => withdrawAssetV2(guidance),


    /* Async Submission Actions */
    /* ----------------------------------------------- */
    submitForApproval: () => submitAnyAvailableRequestV2(guidance),
    submitChangeAudienceRequest: () => changeAudienceRequestV2(guidance),
    submitUpdatePublishedAssetRequest: () => updatedPublishedAssetRequestV2(guidance),


    /* Synchronous Boolean Checks */
    /* ----------------------------------------------- */
    canSubmitChangeAudienceRequest: () => canSubmitChangeAudienceV2(guidance),
    canSubmitForApproval: () => canSubmitV2(guidance),
    canSubmitUpdatePublishedAssetRequest: () => canSubmitUpdatePublishedV2(guidance),
    isPending: () => assetIsPendingV2(guidance),
    willEnterApprovalQueue: () => willEnterApprovalQueueV2(guidance),


    /* Utility */
    /* ----------------------------------------------- */
    expectedOutcome: () => expectedOutcome(guidance),
    publishedUid: () => publishedUidV2(guidance),
    sitesThatWillBeFederatedToIfApprovedToPublic: () =>
      sitesThatWillBeFederatedToIfApprovedToPublicV2(guidance),
    sitesThatWillBeFederatedToIfMadePublic: () => sitesThatWillBeFederatedToIfMadePublicV2(guidance),
  };

  return guidanceHelper;
};

export default getWorkflowGuidanceHelper;
