import React, { FunctionComponent } from 'react';

import type { CatalogInteractiveUser, CatalogUserOrTeam } from 'common/types/users/catalogUsers';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

interface SelectedUserPillContentsProps {
  /** User to render in the pill */
  user: CatalogUserOrTeam;
}

/**
 * Renders the contents of a selected user pill from the multiselect
 */
const SelectedUserPillContents: FunctionComponent<SelectedUserPillContentsProps> = ({ user }) => (
  <div className="user-search-pill-contents">
    {/*
          Unfortunately we can't just use "type" here as the icon name since
          the type is "interactive" but the icon is "user"
      */}
    {user?.type === 'team' ? (
      <SocrataIcon name={IconName.Team} className="user-search-pill-user-icon" />
    ) : (
      <SocrataIcon name={IconName.User} className="user-search-pill-user-icon" />
    )}
    <div className="user-search-pill-user-name">
      {user?.screen_name || (user as CatalogInteractiveUser)?.email}
    </div>
  </div>
);

export default SelectedUserPillContents;
