import React, { FunctionComponent } from 'react';
import HelperTooltip from 'common/components/HelperTooltip';
import './index.scss';

interface Props {
  /**
   * The title to be wrapped by this BlockLabel
   */
  title: string | React.ReactNode;
  /**
   * (Default: undefined)
   * The class name for the title's encapsulating <span>
   */
  titleClassName?: string;
  /**
   * (Default: undefined)
   * String that is the id of the element that this label describes. Highly
   * recommended for max accessibility.
   */
  htmlFor?: string;
  /**
   * (Default: undefined)
   * An object used for populating the flyout attached to the child component
   * (which by default is the `?` icon).
   */
  description?: any;
  /**
   * (Default: 'inline')
   * A string which can be `inline`, `right`, or `left`. Determines where
   * the default the `?` icon renders in reference to the title.
   */
  iconAlignment?: 'inline' | 'right' | 'left';
}

const BlockLabel: FunctionComponent<Props> = ({
  description,
  iconAlignment,
  title,
  titleClassName,
  htmlFor
}) => {
  return (
    <div className={`block-label-container ${iconAlignment}`}>
      <label className="block-label" htmlFor={htmlFor}>
        <span className={titleClassName}>{title}</span>
      </label>
      <HelperTooltip id={title} content={description} />
    </div>
  );
};

BlockLabel.defaultProps = {
  iconAlignment: 'inline',
};

export default BlockLabel;
