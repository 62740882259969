import { ConditionalConfig, FontStyle, FormatStyle } from 'common/authoring_workflow/reducers/types';
import { FILTER_FUNCTION } from 'common/components/FilterBar/SoqlFilter';

export const defaultFontStyle = (): FontStyle => ({
  isBold: false,
  isItalic: false,
  isWrapped: false
});

export const defaultConditionStyle = (): FormatStyle => ({
  backgroundColor: '#ffffff',
  textColor: '#000000',
  textFont: '',
  fontStyle: defaultFontStyle()
});

export const defaultConditionalConfig = (isDate = false): ConditionalConfig => ({
  expression: {
    function: isDate ? FILTER_FUNCTION.TIME_RANGE : FILTER_FUNCTION.EQUALS,
    arguments: {
      start: '',
      end: '',
      value: ''
    }
  },
  style: defaultConditionStyle()
});
