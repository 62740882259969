import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import I18n from 'common/i18n';

import { strictPermissionsEnabled, userHasAccessLevel } from 'common/components/AccessManager/Util';
import { MODES } from 'common/components/AccessManager/Constants';
import { AccessLevelName, AccessLevelVersion, ViewUser } from 'common/types/view';
import ErrorList from 'common/components/ErrorList';

import UserList from 'common/components/AccessManager/components/UserList';
import {
  getUiMode,
  getAddCollaboratorsValidationErrors
} from 'common/components/AccessManager/sagas/Selectors';

import AddCollaborators from './AddCollaborators';

/* User filters */
const hidePublishedViewers = (user: ViewUser) =>
  !userHasAccessLevel(user, AccessLevelName.Viewer, AccessLevelVersion.Published);
const showAll = () => true;

/**
 * Shows all of the current collaborators, with ways to add, remove, and change them
 */
const ManageCollaborators: FunctionComponent = () => {
  const mode = useSelector(getUiMode);
  const validationErrors = useSelector(getAddCollaboratorsValidationErrors);

  const noUsersMessageKey = mode === MODES.MANAGE_PLUGIN ? 'manage_plugin.no_users' : 'no_collaborators';

  return (
    <div>
      {/* User List filtered to only show users WITHOUT published viewer access */}
      <UserList
        hideOwner={false}
        noUsersMessage={I18n.t(`shared.site_chrome.access_manager.${noUsersMessageKey}`)}
        hideAccessLevelDropdown={strictPermissionsEnabled()}
        userFilter={strictPermissionsEnabled() ? hidePublishedViewers : showAll}
      />
      <AddCollaborators />
      <ErrorList errors={validationErrors} />
    </div>
  );
};

export default ManageCollaborators;
