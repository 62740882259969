import PropTypes from 'prop-types';
import React, { ReactElement } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

interface FlyoutProps {
  left?: boolean;
  right?: boolean;
  top?: boolean;
  text: string | JSX.Element;
  id?: string;
}
interface FlyoutState {
  containerRef: React.Ref<HTMLDivElement>;
  flyoutRef: React.Ref<HTMLDivElement>;
  flyoutTop: number | undefined;
  hidden: boolean;
  horizontalOffset: number | undefined;
}

export default class Flyout extends React.Component<FlyoutProps, FlyoutState> {
  state = {
    containerRef: React.createRef<HTMLDivElement>(),
    hidden: true,
    flyoutOffset: undefined,
    flyoutRef: React.createRef<HTMLDivElement>(),
    flyoutTop: undefined,
    horizontalOffset: undefined
  };

  onMouseEnter = () => {
    this.setState({
      hidden: false
    });
  };

  onMouseLeave = () => {
    this.setState({
      flyoutTop: undefined,
      hidden: true,
      horizontalOffset: undefined
    });
  };

  adjustFlyoutPosition() {
    const { containerRef, flyoutRef, hidden, horizontalOffset } = this.state;

    if (hidden || !flyoutRef.current) return;

    if (horizontalOffset === undefined) {
      const { left, right } = flyoutRef.current.getBoundingClientRect();
      if (left < 0) {
        this.setState({ horizontalOffset: left });
      } else if (right > window.innerWidth) {
        this.setState({ horizontalOffset: window.innerWidth - right });
      }
    }

    if (this.props.top && containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const flyoutHeight = flyoutRef.current.getBoundingClientRect().height;
      const flyoutTop = containerTop - flyoutHeight;
      if (flyoutTop != this.state.flyoutTop) this.setState({ flyoutTop });
    }
  }

  componentDidMount() {
    this.adjustFlyoutPosition();
  }

  componentDidUpdate() {
    this.adjustFlyoutPosition();
  }

  render() {
    const { children, id, left, right, top, text } = this.props;

    const flyoutClass = classNames('flyout', {
      'flyout-hidden': this.state.hidden,
      'flyout-right': right,
      'flyout-left': left,
      'flyout-top': top
    });

    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child as React.ReactElement<any>)
    );

    return (
      <div
        className="flyout-container"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={this.state.containerRef}
      >
        {childrenWithProps}
        <div
          className={flyoutClass}
          id={id}
          ref={this.state.flyoutRef}
          style={{ top: this.state.flyoutTop, right: this.state.horizontalOffset }}
        >
          {_.isString(text) ? (
            <section className="flyout-content">
              <p className="flyout-content-text">{text}</p>
            </section>
          ) : (
            text
          )}
        </div>
      </div>
    );
  }
}
