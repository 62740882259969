import { FeatureFlags } from 'common/feature_flags';
import { AccountModules } from 'common/account_modules';
import { isDerivedView, isVisualizationCanvas } from 'common/views/view_types';
import { AudienceScope } from 'common/types/view';
import { MODES } from 'common/components/AccessManager/Constants';

// Takes response from /api/views/<4x4> and determines
// whether or not the asset can change visibility.
export const canChangeVisibility = (coreView, parentScope = null) => {
  if (!isVisualizationCanvas(coreView)) return true;

  // do not allow vizcans to have higher access scope than their parent
  if (coreView.permissions.scope == AudienceScope.Public && (parentScope == AudienceScope.Site || parentScope == AudienceScope.Private)) {
    // this view: public
    // parent view: site (internal) or private
    return false;
  } else if (coreView.permissions.scope == AudienceScope.Site && parentScope == AudienceScope.Private) {
    // this view: site (internal)
    // parent view: private
    return false;
  }

  return true;
};
