/* Imports */
/* ============================================================================= */
import { isFunction } from 'lodash';

import { WorkflowGuidanceCheckMethod, WorkflowGuidanceCheckMethods } from 'common/core/approvals/Types';
import { WORKFLOWS } from '.';





/* Method */
/* ============================================================================= */
/**
 * Generates a WorkflowGuidanceMethodsObject Object where the function provided
 * through the `method` param will be used for each workflow.
 *
 * Difference between this function and `createWorkflowGuidanceHelperMethodsObject`:
 *  - This function is used to run a single, provided method against each
 * workflow's data.
 *  - In contrast, the `createWorkflowGuidanceHelperMethodsObject` function is
 * for running the instance of a named method within each workflowHelper against
 * that workflow's data.
 * @param method A WorkflowGuidanceBooleanCheckMethod
 * @returns A WorkflowGuidanceBooleanCheckMethods Object
 */
export const createWorkflowGuidanceMethodsObject = <T = undefined>(
  method: WorkflowGuidanceCheckMethod<T>
): WorkflowGuidanceCheckMethods<T> => {
  const response = {};

  if (isFunction(method)) WORKFLOWS.forEach((workflow) => response[workflow] = method);

  return response as WorkflowGuidanceCheckMethods<T>;
};

export default createWorkflowGuidanceMethodsObject;
