import includes from 'lodash/includes';

import assert from './assert';

/**
 * Ensures the given value is of any of the provided types.
 *
 * @param {any} value - The value to check
 * @param {string[]} [types] - List of acceptable types
 */
export default (value, ...types) =>
  assert(
    includes(types, typeof value),
    `Value must be one of [${types.join(', ')}] (is of type ${typeof value}).`
  );
