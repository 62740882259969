import $ from 'jquery';
import _ from 'lodash';
import I18n from 'common/i18n';

import DataTypeFormatter from 'common/DataTypeFormatter';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { getColumnFormatWithPrecision } from 'common/visualizations/helpers/ColumnFormattingHelpers';
import {
  NULL_CATEGORY_VALUE,
  NUMERIC_COLUMN_TYPES,
  QUANTIFICATION_METHODS,
  VIF_CONSTANTS
} from 'common/authoring_workflow/constants';

// Given soql condition to retrieve a feature(point/line/shape), it will fetch the feature
// and set the popup content for the feature.
export async function setPopupContent(popupElement, vifs, renderOptions, retrieveDataConditions) {

  // While data is loading, show loading spinner in the popup.
  $(popupElement).html(getLoadingSpinnerContent());

  const popupContentPromises = _.map(retrieveDataConditions, async (retrieveDataCondition, index) => {
    const isLayerVisible = vifs[index].isLayerVisible();
    if (!isLayerVisible) {
      return;
    }

    if (_.isEmpty(vifs[index].getAllFlyoutColumns())) {
      return null;
    }
    // The retrieve data condition will be precise and will fetch only one
    // record back from the soql call. So we take the first feature and set the popup
    // content based on it.
    const features = (await getFeaturesWithPopupData(vifs[index], retrieveDataCondition))[0];

    return formatFeaturePopupContent(features, vifs[index], renderOptions[index]);
  });

  const genericPopupContent = await Promise.all(popupContentPromises);
  $(popupElement).html(genericPopupContent.join(''));
}

// Given a soql condition to retrieve feature(s), it will retrieve feature(s) with
// default filters from vif plus the given retrieve condition with the required columns
// for displaying popup.
export async function getFeaturesWithPopupData(vif, retrieveDataCondition, additionalSelects = []) {
  const datasetSoqlDataProvider = vif.getDatasetSoqlDataProvider();
  const titleColumnName = vif.getFlyoutTitleColumn();
  const selectColumns = additionalSelects.concat(
    _.map(vif.getAllFlyoutColumns(), (flyoutColumn) => SoqlHelpers.escapeColumnName(flyoutColumn))
  );
  const conditions = [`(${retrieveDataCondition})`];
  const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);

  if (!_.isEmpty(filters)) {
    conditions.push(filters);
  }

  // To use :id='....' in the where condition, we need to have the :id in the select clause.
  // Otherwise soql breaks.
  selectColumns.push(':id');

  const query = `SELECT ${selectColumns.join(',')} WHERE ${conditions.join(' AND ')}`;
  return await datasetSoqlDataProvider.rawQuery(query);
}

export async function getFeaturesGroupedByBuckets(vif, retrieveDataCondition, renderOptions) {
  let query;
  const datasetSoqlDataProvider = vif.getDatasetSoqlDataProvider();
  const colorByPointColumnName = SoqlHelpers.escapeColumnName(vif.getColorPointsByColumn());
  const conditions = [`(${retrieveDataCondition})`];
  const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);

  if (!_.isEmpty(filters)) {
    conditions.push(filters);
  }

  if (vif.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value) {
    let caseConditions = [];
    const colorByBucketsCount = vif.getColorByBucketsCount();

    _.chain(renderOptions.colorByBuckets).
      take(colorByBucketsCount).
      each((colorByBucket, index) => {
        let isLastBucket = index === (colorByBucketsCount - 1);
        let condition;
        let value;

        condition = `(${colorByPointColumnName} >= ${colorByBucket.start} and ` +
          `${colorByPointColumnName} < ${colorByBucket.end})`;
        value = colorByBucket.start + '';
        caseConditions.push(condition, value);

        if (isLastBucket) {
          caseConditions.push(`(${colorByPointColumnName} >= ${colorByBucket.end})`, colorByBucket.end + '');
        }
      }).
      flatten().
      value();

    query = `SELECT CASE(${caseConditions.join(',')}) as ${renderOptions.colorBy}, count(*) as ${renderOptions.countBy} ` +
      `WHERE ${conditions.join(' AND ')} GROUP BY ${renderOptions.colorBy}`;
  } else {
    query = `SELECT ${colorByPointColumnName}||'' as ${renderOptions.colorBy}, count(*) as ${renderOptions.countBy} ` +
    `WHERE ${conditions.join(' AND ')} GROUP BY ${renderOptions.colorBy}`;
  }

  return await datasetSoqlDataProvider.rawQuery(query);
}

// Given a feature(point/line/shape), it returns the html popup content for the feature.
export function formatFeaturePopupContent(feature, vif, renderOptions) {
  if (_.isEmpty(vif.getAllFlyoutColumns()) || _.isUndefined(feature)) {
    return;
  }

  const { titleColumn, additionalColumns } = getTitleAndAdditionalFlyoutColumns(renderOptions, vif);

  return '<div class="point-map-popup point-popup">' +
    formatTitleColumnValue(vif, feature, titleColumn) +
    formatAdditionalColumnValues(vif, feature, additionalColumns) +
  '</div>';
}

function formatTitleColumnValue(vif, rowData, column) {
  if (_.isUndefined(column)) {
    return '';
  }

  const formattedValue = getFormattedValue(vif, rowData, column);

  return '<div class="popup-title">' +
    valueOrNoValue(formattedValue) +
  '</div>';
}

function formatAdditionalColumnValues(vif, rowData, columns) {
  const tbodyContent = getAdditionalColumnContent(vif, rowData, columns);
  if (_.isEmpty(tbodyContent)) {
    return '';
  }
  return `<table class="mapboxgl-popup-table generic-content">${tbodyContent}</table>`;
}

function getAdditionalColumnContent(vif, rowData, columns) {
  return _.map(columns, (column) => {
    const formattedValue = getFormattedValue(vif, rowData, column);
    const formattedColumnName = DataTypeFormatter.renderFormattedTextHTML(column.name);
    return '<tr class="mapboxgl-popup-row">' +
      '<td class="mapboxgl-popup-cell">' +
        `<span class="category mapboxgl-overflow">${formattedColumnName}</span>` +
      '</td>' +
      '<td colspan="2" class="mapboxgl-popup-cell">' +
        '<span class="count mapboxgl-overflow">' +
          valueOrNoValue(formattedValue) +
        '</span>' +
      '</td>' +
    '</tr>';
  }).join('');
}

export function getLoadingSpinnerContent() {
  return '<div class="loading-spinner-container">' +
    '<div class="loading-spinner"></div>' +
    '</div>';
}

function valueOrNoValue(value) {
  return value === '' ? I18n.t('shared.visualizations.charts.common.no_value') : value;
}

function getFormattedValue(vif, rowData, column) {
  const isCheckbox = _.get(column, 'renderTypeName') === 'checkbox';
  const isCastNullAsFalseInSeries = vif.getCastNullAsFalseInSeries();
  const defaultValue = isCheckbox && isCastNullAsFalseInSeries ?
    VIF_CONSTANTS.DEFAULT_BOOLEAN_COLUMN_VALUE :
    NULL_CATEGORY_VALUE;
  const rowValue = _.get(rowData, column.fieldName, defaultValue);
  const mapFlyoutPrecision = vif.getMapFlyoutPrecision();

  if (isCheckbox) {
    return rowValue;
  } else {
    const columnFormatWithPrecision = getColumnFormatWithPrecision(column, mapFlyoutPrecision);
    return DataTypeFormatter.renderCellHTML(
      rowValue,
      columnFormatWithPrecision,
      vif.getDomain(),
      vif.getDatasetUid(),
      { openUrlInNewTab: true }
    );
  }
}

function getTitleAndAdditionalFlyoutColumns(renderOptions, vif) {
  const columnNameToColumnMap = _.keyBy(renderOptions.datasetMetadata.columns, 'fieldName');
  const additionalColumns = _.pick(columnNameToColumnMap, vif.getFlyoutAdditionalColumns());
  const titleColumn = columnNameToColumnMap[vif.getFlyoutTitleColumn()];

  return { titleColumn, additionalColumns };
}
