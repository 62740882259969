// Vendor Imports
import { isPlainObject, isNull, get } from 'lodash';

// Project Imports
import { assert, assertIsNumber } from 'common/assertions';
import I18n from 'common/i18n';
import StandardPalette, { FALLBACK_MODE } from './standard_palette';
import CustomPalette from './custom_palette';
import SiteAppearanceColorPalette from './site_appearance_color_palette';
import { isSiteAppearanceColorPalette } from '../SiteAppearanceColors.js';

import { Series, Vif } from '../../vif';
import {VisualizationMeasure } from '../types';


// Represents a color variation scheme.
// Some charts change element colors by measure (i.e., by column).
// Others change element colors by dimension (i.e., by row).
export const COLOR_VARY_BY = {
  DIMENSION: 'DIMENSION', // like a pie chart.
  MEASURE: 'MEASURE' // like a bar chart.
};

// Represents a palette defined on a particular series in a VIF. Reconciles custom palettes being by value
// where normal palettes are by index.
export default class Palette {
  colorVaryBy: string;
  series: Series;
  customPalette: CustomPalette;
  siteAppearanceColorPalette: SiteAppearanceColorPalette;
  standardPalette: StandardPalette;

  constructor({ vif, seriesIndex, colorVaryBy = COLOR_VARY_BY.MEASURE }: { vif: Vif, seriesIndex: number, colorVaryBy: string }) {
    assert(isPlainObject(vif), 'vif must be a plain object');
    this.colorVaryBy = colorVaryBy;
    this.series = vif.series[seriesIndex];
    this.customPalette = new CustomPalette(vif, seriesIndex);
    this.siteAppearanceColorPalette = new SiteAppearanceColorPalette(
      vif,
      seriesIndex,
      FALLBACK_MODE.ROTATION
    );
    this.standardPalette = new StandardPalette(
      vif,
      seriesIndex,
      colorVaryBy === COLOR_VARY_BY.MEASURE ? FALLBACK_MODE.SOLID_COLOR : FALLBACK_MODE.ROTATION
    );
  }

  getColor(measure?: VisualizationMeasure, dimensionIndex?: number, data?: string) {
    const paletteName = get(this.series, 'color.palette', null);

    // Looks up standard palettes via measureIndex, and custom palettes with
    // measure tagValues.
    if (this.colorVaryBy === COLOR_VARY_BY.MEASURE && !!measure) {
      const standardColor = this.standardPalette.getColor(measure.measureIndex);
      if (paletteName === 'custom' && !!measure?.tagValue) {
        return this.customPalette.getColor(measure.tagValue) || standardColor;
      } else if (isSiteAppearanceColorPalette(paletteName)) {
        return this.siteAppearanceColorPalette.getColor(measure.measureIndex) || standardColor;
      } else {
        return standardColor;
      }
    }

    // Looks up standard palettes via dimensionIndex, and custom palettes with
    // row data.
    if (this.colorVaryBy === COLOR_VARY_BY.DIMENSION) {
      // If hit, check that your chart is passing dimensionIndex and data.
      // Currently, only COLOR_VARY_BY.DIMENSION charts need to.
      assertIsNumber(dimensionIndex);

      const standardColor = this.standardPalette.getColor(dimensionIndex);

      if (paletteName === 'custom' && !!data) {
        return this.customPalette.getColor(data) || standardColor;
      } else if (paletteName === 'custom' && isNull(data)) {
        // In this case, a null here refers to a piece of data with "No value"
        return this.customPalette.getColor(I18n.t('shared.visualizations.charts.common.no_value'));
      } else if (isSiteAppearanceColorPalette(paletteName)) {
        return this.siteAppearanceColorPalette.getColor(dimensionIndex) || standardColor;
      } else {
        return standardColor;
      }
    }
  }
}
