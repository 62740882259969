import getLocale from 'common/js_utils/getLocale';

export default function optionallyLocalizeUrls(url: string) {

  // function expects url without https://domain so strip that out
  if (url.startsWith(window.location.origin)) {
    url = url.replace(window.location.origin, '');
  }

  const locale = getLocale(window);
  const { defaultLocale } = window.socrata;

  // stories have a different locale format - https://domain/stories/locale/s
  if (url.startsWith('/stories/s') && defaultLocale !== locale) {
    url = url.replace('/stories/s', '');
    return `/stories/${locale}/s${url}`;
  }

  return defaultLocale === locale ? url : `/${locale}${url}`;
}
