import { FluxPayload } from 'types';
import assertHasProperty from 'common/assertions/assertHasProperty';
import { assertIsNumber, assertIsString } from 'common/assertions';

interface ActionComponentStoreState {
  actionType: string | null;
  sourceBlockId: string | null;
  sourceComponentIndex: number | null;
  isUserChoosingTargetComponent: boolean;
}

export const actionStarted = (payload: FluxPayload, state: ActionComponentStoreState): void => {
  assertHasProperty(payload, 'actionType');
  assertIsString(payload.actionType);
  assertHasProperty(payload, 'blockId');
  assertIsString(payload.blockId);
  assertHasProperty(payload, 'componentIndex');
  assertIsNumber(payload.componentIndex);

  const { blockId, componentIndex, actionType } = payload;

  state.sourceBlockId = blockId;
  state.sourceComponentIndex = componentIndex;
  state.isUserChoosingTargetComponent = true;
  state.actionType = actionType;
};

export const actionEnded = (state: ActionComponentStoreState): void => {
  state.isUserChoosingTargetComponent = false;
};

export const actionCancelled = (state: ActionComponentStoreState): void => {
  state.isUserChoosingTargetComponent = false;
  state.sourceBlockId = null;
  state.sourceComponentIndex = null;
};
