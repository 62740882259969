import isPlainObject from 'lodash/isPlainObject';

import { withGuidanceV2 } from 'common/core/approvals/index_new';
import { PUBLICATION_STAGE } from './constants';

export const getPublishedViewUid = (view, approvalsGuidance) => {
  if (!isPlainObject(view)) {
    return;
  }

  const { id, publicationStage, publishedViewUid, viewType } = view;

  if (publicationStage === PUBLICATION_STAGE.UNPUBLISHED) {
    return publishedViewUid;
  } else if (publicationStage === PUBLICATION_STAGE.PUBLISHED) {
    if (viewType === 'story' && !window.IS_STORY_PUBLISHED) {
      // For a story, publicationStage published means that a published version of the story exists,
      // and !window.IS_STORY_PUBLISHED means we're on a draft of that story.
      return id; // A draft story has the same uid as its published version
    }

    if (withGuidanceV2(approvalsGuidance).isPending()) {
      return publishedViewUid;
    }
  }
};

export const hasBeenPublished = (view = {}) => {
  const { publicationStage, publishedViewUid } = view;
  if (publicationStage === PUBLICATION_STAGE.UNPUBLISHED) {
    return !!publishedViewUid;
  } else if (publicationStage === PUBLICATION_STAGE.PUBLISHED) {
    return true;
  }
  return false;
};
