// Vendor Imports
import React from 'react';

// Project Imports
import ColorPicker from 'common/components/ColorPicker';

// Constants
import { COLORS } from 'common/authoring_workflow/constants';

export interface AgColorSelectorProps {
  color: string,
  label: string,
  palette: string[],
  onChangeColor: (newColor: string) => void;
}

const AgColorSelector = (props: AgColorSelectorProps) : JSX.Element =>  {
  const { color, onChangeColor, label, palette = COLORS } = props;
    const colorPickerAttributes = {
      handleColorChange: onChangeColor,
      palette: palette,
      value: color,
      id: 'ag-grid-color-picker'
    };

    return (
      <div className='authoring-field' data-testid='ag-grid-color-selector'>
        <label className="block-label" htmlFor="primary-color">{label}</label>
        <ColorPicker {...colorPickerAttributes} />
      </div>
    );
};

export default AgColorSelector;
