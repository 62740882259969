import { action } from 'typesafe-actions';

import type { UsersCatalogSearchResult, CatalogUserOrTeam } from 'common/types/users/catalogUsers';

export enum PublishedToActionTypes {
  PUBLISHED_TO_SEARCH_QUERY_CHANGED = 'PUBLISHED_TO_SEARCH_QUERY_CHANGED',
  PUBLISHED_TO_SEARCH_RESULTS_FETCH_SUCCESS = 'PUBLISHED_TO_SEARCH_RESULTS_FETCH_SUCCESS',
  PUBLISHED_TO_SEARCH_RESULTS_FETCH_FAIL = 'PUBLISHED_TO_SEARCH_RESULTS_FETCH_FAIL',
  ADD_SELECTED_PUBLISHED_TO = 'ADD_SELECTED_PUBLISHED_TO',
  REMOVE_SELECTED_PUBLISHED_TO = 'REMOVE_SELECTED_PUBLISHED_TO'
}

/** Typing into the publishedTo search box */
export const publishedToSearchQueryChanged = (query: string, domain?: string) =>
  action(PublishedToActionTypes.PUBLISHED_TO_SEARCH_QUERY_CHANGED, {
    query,
    domain
  });

/** Getting results back from the catalog */
export const publishedToSearchResultsFetchSuccess = (
  results: UsersCatalogSearchResult,
  existingUsers: CatalogUserOrTeam[]
) => action(PublishedToActionTypes.PUBLISHED_TO_SEARCH_RESULTS_FETCH_SUCCESS, { results, existingUsers });

/** Failed to get results from catalog */
export const publishedToSearchResultsFetchFail = (error: any) =>
  action(PublishedToActionTypes.PUBLISHED_TO_SEARCH_RESULTS_FETCH_FAIL, {
    error
  });

/** Add a publishedTo to the combobox */
export const addSelectedPublishedTo = (user: CatalogUserOrTeam) =>
  action(PublishedToActionTypes.ADD_SELECTED_PUBLISHED_TO, {
    user
  });

/** Remove a publishedTo from the combobox */
export const removeSelectedPublishedTo = (user: CatalogUserOrTeam) =>
  action(PublishedToActionTypes.REMOVE_SELECTED_PUBLISHED_TO, {
    user
  });
