import _ from 'lodash';
import Environment from '../../StorytellerEnvironment';
import Actions from 'Actions';
import assertIsOneOfTypes from 'common/assertions/assertIsOneOfTypes';
import { storyStore } from '../../editor/stores/StoryStore';
import { createAction, createReducer } from '@reduxjs/toolkit';

interface RichTextEditorColorState {
  defaultColors: (string | null)[];
  savedCustomColors: (string | null)[];
  activeCustomColor: null;
}

const initialState = {
  defaultColors: [null, null, '#fff', '#666', '#D2160A'],
  savedCustomColors: [null, null, null, null, null],
  activeCustomColor: null,
} as RichTextEditorColorState;

/* Action Creators */
export const updateStoryColors = createAction(Actions.UPDATE_STORY_COLORS);

const richTextEditorColorReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateStoryColors, (state) => {
      updateDefaultColors(storyStore.getStoryTheme(Environment.STORY_UID), state);
    });
});

/* Private functions */
const updateDefaultColors = (themeId: string, state: RichTextEditorColorState) => {
  assertIsOneOfTypes(themeId, 'string');

  if (_.startsWith(themeId, 'custom-')) {
    themeId = parseInt(_.trimStart(themeId, 'custom-'), 10).toString();
  }

  const defaultThemes = Environment.DEFAULT_THEMES?.themes;
  const customThemes = Environment.CUSTOM_THEMES || [];

  const themes = defaultThemes?.concat(customThemes);
  const currentThemeObj = _.find(themes, { id: themeId });

  if (!currentThemeObj || !currentThemeObj.css_variables) return;
  const cssVars = currentThemeObj.css_variables;
  if (cssVars['$heading-type-color']) {
    state.defaultColors[0] = cssVars['$heading-type-color'];
  }
  if (cssVars['$default-type-color']) {
    state.defaultColors[1] = cssVars['$default-type-color'];
  }
};

export default richTextEditorColorReducer;
