import React, { FunctionComponent } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';

import { Federation, ErrorResponse } from '@socrata/core-federations-api';

import { View } from 'common/types/view';
import { AssetFederationApi, parseError } from 'common/core/federations';
import I18n from 'common/i18n';
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/AccessibleModal';
import Button from 'common/components/Button';
import Spinner from 'common/components/Spinner';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Alert, { AlertType } from 'common/components/Alert';

import AddFederation from './add_federation';
import AssetFederationTable from './asset_federation_table';

import './index.scss';

export interface ManageFederationsModalProps {
  /** View that we're looking at */
  view: View;

  /** Function to call to close modal */
  onDismiss: () => void;
}

/**
 * This is a modal that shows all of the domains that an asset federates out to,
 * and lets you add new outgoing federations.
 *
 * This is often referred to as "selective federation".
 *
 * The list of federations for the asset will be fetched when the modal is opened.
 */
const ManageFederationsModal: FunctionComponent<ManageFederationsModalProps> = ({ view, onDismiss }) => {
  const {
    isLoading,
    isError,
    data: federationRelationships,
    refetch: refetchFederationRelationships
  } = useQuery<Federation[], ErrorResponse>(
    `federationRelationShips-${view.id}`,
    async () => {
      try {
        return await AssetFederationApi.getAssetFederations({ viewId: view.id });
      } catch (e) {
        console.error(e);
        throw await parseError(e);
      }
    },
    {
      // keep the value cached for 30 seconds
      staleTime: 30 * 1000
    }
  );

  return (
    <Modal className="federate-to--modal">
      <ModalHeader onDismiss={onDismiss} className="federate-to--modal-header">
        <SocrataIcon name={IconName.Federation} className="federate-to--modal-header-icon" />
        <h1 className="h5 modal-header-title">
          {I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.title')}
        </h1>
      </ModalHeader>
      <ModalContent>
        {isLoading && <Spinner />}
        {isError && (
          <Alert type={AlertType.Error}>
            {I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.error_loading')}
          </Alert>
        )}
        {federationRelationships && (
          <AssetFederationTable
            federations={federationRelationships}
            view={view}
            refetchFederationRelationships={refetchFederationRelationships}
          />
        )}
        <AddFederation
          view={view}
          refetchFederationRelationships={refetchFederationRelationships}
          openByDefault={Boolean(federationRelationships && federationRelationships.length === 0)}
        />
      </ModalContent>
      <ModalFooter>
        <Button onClick={onDismiss}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

const queryClient = new QueryClient();

const QueryClientWrappedManageFederations = (props: ManageFederationsModalProps) => (
  <QueryClientProvider client={queryClient}>
    <ManageFederationsModal {...props} />
  </QueryClientProvider>
);

export default QueryClientWrappedManageFederations;
