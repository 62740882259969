import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import I18n from 'common/i18n';

export interface ComponentActionOverlayProps {
  actionButtonText: string;
  isActive: boolean;
  onActionClick: (event: React.MouseEvent) => void;
  onCancelAction?: (event: Event) => void;
  shouldShowCancelButton: boolean;
  shouldShowActionButton: boolean;
}

const ComponentActionOverlay: FunctionComponent<ComponentActionOverlayProps> = ({
  actionButtonText,
  isActive,
  onActionClick,
  onCancelAction,
  shouldShowActionButton,
  shouldShowCancelButton
}) => {
  if (!isActive) {
    return null;
  }

  const overlayClasses = classnames('component-edit-action-overlay', {
    'action-target': shouldShowActionButton,
    'action-source': shouldShowCancelButton
  });

  const overlayProps = {
    className: overlayClasses,
    onClick: shouldShowActionButton ? onActionClick : _.noop
  };

  return (
    <div {...overlayProps}>
      {shouldShowActionButton && <button
        className='btn btn-alternate-2 btn-action-target'
      >
        {actionButtonText}
      </button>}
      {shouldShowCancelButton && <button
        className='btn btn-simple btn-action-cancel'
        onClick={onCancelAction || _.noop}
      >
        {I18n.t('editor.components.edit_controls.cancel')}
      </button>}
    </div>
  );
};

export default ComponentActionOverlay;
