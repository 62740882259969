import type { GuidanceSummaryV2 } from 'common/types/approvals';

import { hasRights } from 'common/views/has_rights';
import { View, ViewRight } from 'common/types/view';
import { currentUserIsCommunity } from 'common/current_user';
import { withGuidanceV2 } from 'common/core/approvals/index_new';

/**
 * Returns whether the current user can withdraw an approval request on an asset.
 *
 * This can only be done if the user has the `write` right on the asset, the asset is currently
 * pending approval, and the user is not a community user (they are a site member or a superadmin)
 *
 * @param view View to check for being able to withdraw approval on
 * @param approvalsGuidance Approvals guidance summary
 */
const currentUserCanWithdrawApprovalRequest = (view: View, guidanceSummary: GuidanceSummaryV2) =>
  !view.locked &&
  hasRights(view, ViewRight.Write) &&
  withGuidanceV2(guidanceSummary).isPending() &&
  !currentUserIsCommunity();

export default currentUserCanWithdrawApprovalRequest;
