/* Imports */
/* ============================================================================= */
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { BaseGuidanceSummaryV2Helper, GuidanceBooleanCheckSummary, WorkflowGuidanceCheckMethods } from 'common/core/approvals/Types';
import { summarizeGuidanceCheckMethod, createWorkflowGuidanceHelperMethodsObject } from '.';





/* Method */
/* ============================================================================= */
/**
 * Check for which workflows an asset can be submitted for approval
 * @param guidance GuidanceSummaryV2 object
 * @param guidanceHelper BaseGuidanceSummaryV2Helper object
 * @returns GuidanceBooleanCheckSummary object
 */
export const summarizeCanSubmitForApproval = (guidance: GuidanceSummaryV2, guidanceHelper: BaseGuidanceSummaryV2Helper): GuidanceBooleanCheckSummary => {
  const response = summarizeGuidanceCheckMethod<boolean, false>(
    guidance,
    createWorkflowGuidanceHelperMethodsObject<WorkflowGuidanceCheckMethods<boolean>>(guidanceHelper, 'canSubmitForApproval'),
    false
  );

  return response;
};

export default summarizeCanSubmitForApproval;
