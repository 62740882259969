import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import LayerToggleSelector from 'common/components/LayerToggleSelector';

export default class LayerToggleControl {
  constructor(layers) {
    this._layers = layers;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-layer-toggle mapboxgl-ctrl';
    this._renderToggleLayerContent();
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  _renderToggleLayerContent() {
    const props = { map: this._map, layers: this._layers };

    ReactDOM.render(
      React.createElement(LayerToggleSelector, props),
      this._container
    );
  }
}
