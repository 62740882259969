import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';

import SocrataIcon from 'common/components/SocrataIcon';
import './index.scss';
import { ALERT_MODAL_PAGES } from './constants';

const scope = 'shared.components.create_alert_modal.info';
/**
 For alert's create/edit operations, renders
   - form-data-loading spinner.
   - validation messages.
*/
class AlertInfo extends Component {
  validationMessage() {
    const { alertName, alertModalPage, isInvalidQuery, isInvalidWebhook } = this.props;

    if (_.isEmpty(alertName) && alertModalPage == ALERT_MODAL_PAGES.TRIGGER_PAGE) {
      return (
        <div className="alert-info error-info name-error">
          {I18n.t('name_error', { scope })}
        </div>
      );
    } else if (isInvalidWebhook) {
      return (
        <div className="alert-info error-info invalid-webhook">
          <SocrataIcon name="close" className="info-icon" />
          {I18n.t('invalid_webhook', { scope })}
        </div>
      );
    } else if (isInvalidQuery) {
      return (
        <div className="alert-info error-info invalid-query">
          <SocrataIcon name="close" className="info-icon" />
          {I18n.t('invalid_query', { scope })}
        </div>
      );
    }

    return (
      <div className="alert-info success-info valid-query">
        <SocrataIcon name="check" className="info-icon" />
        {I18n.t('valid_query', { scope })}
      </div>
    );
  }

  render() {
    const { enableValidationInfo, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className="alert-info success-info loading-message">
          {I18n.t('loading', { scope })}
        </div>
      );
    } else if (enableValidationInfo) {
      return this.validationMessage();
    }

    return null;
  }
}

AlertInfo.propTypes = {
  alertName: PropTypes.string,
  enableValidationInfo: PropTypes.bool,
  isInvalidQuery: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default AlertInfo;
