import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { createStore, combineReducers, applyMiddleware, compose, Store } from 'redux';

import type { AccessManagerState } from './types';
import type AllActionsType from './actions/AllActionsType';

import uiReducer from './reducers/UiReducer';
import uiSagas from './sagas/UiSagas';
import permissionsReducer from './reducers/PermissionsReducer';
import addCollaboratorsReducer from './reducers/AddCollaboratorsReducer';
import permissionsSagas from './sagas/PermissionsSagas';
import addCollaboratorsSagas from './sagas/AddCollaboratorsSagas';
import changeOwnerReducer from './reducers/ChangeOwnerReducer';
import changeOwnerSagas from './sagas/ChangeOwnerSagas';
import publishedToReducer from './reducers/PublishedToReducer';
import publishedToSagas from './sagas/PublishedToSagas';

export type AccessManagerStoreType = Store<AccessManagerState, AllActionsType>;

/**
 * Creates a store for the AccessManager component to use,
 * with redux dev tools and sagas added.
 *
 * An initial state can be passed in.
 */
export default (initialState: AccessManagerState) => {
  // use the redux devtool's composeEnhancers to keep them around
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'AccessManager' })) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();

  const store: AccessManagerStoreType = createStore<AccessManagerState, AllActionsType, null, any>(
    combineReducers({
      permissions: permissionsReducer,
      addCollaborators: addCollaboratorsReducer,
      changeOwner: changeOwnerReducer,
      ui: uiReducer,
      publishedTo: publishedToReducer
    }),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  // combine all the sagas together
  function* sagas() {
    yield all([
      ...permissionsSagas,
      ...addCollaboratorsSagas,
      ...changeOwnerSagas,
      ...uiSagas,
      ...publishedToSagas
    ]);
  }
  sagaMiddleware.run(sagas);

  return store;
};
