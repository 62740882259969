import _ from 'lodash';
import { TILE_CALL_DELAY_ON_ZOOM } from 'common/visualizations/views/mapConstants';

// Used to wrap all partials. This relays the render call to the partials as
//  - setup (if not already setup) or
//  - update (if already setup)
//  - destroy and setup (if the source options for the new VIF are different than the existing ones)
export default class PartialWrapper {
  constructor(map, partial) {
    this._map = map;
    this._partial = partial;

    // Desktop:
    //    - Using zoom controls(discrete zoom): 'zoomstart' gets fired, then while zooming,
    //    it keeps firing 'zoom' and on finish it fires 'zoom end'.
    //    - Using mousewheel(analog zoom): it fires 'zoomstart' and 'zoom' events till the mouse
    //    wheel is used and finally when the user stops, it fires 'zoomend'.
    // Touch Devices:
    //    When a user starts pinching the map to zoom, it fires a 'zoomstart', while zooming
    //    depending on the pinch gesture it continously fires 'zoom' event. When the user is
    //    not pinching or have not released the touch, no events are fired. On releasing the
    //    touch, it fires a 'zoomend' event.
    // Mapbox checks if tile data calls needs to be made on 'zoom' events. So we are pausing the
    // data-sources(our custom point|line|shape data-sources, we leave the basemap datasource as
    // it is) on 'zoomstart'. We resume the data sources when the 'zoom' event is not fired for
    // the past (mapConstants:)TILE_CALL_DELAY_ON_ZOOM milliseconds.
    // We are not using the 'zoomend' event to resume the data source as it does not get fired
    // till the user releases the touch on a touch device. If we used 'zoomend', when a user
    // pinch zooms, the basemap will load immediately, but the map data will start loading only
    // when the user releases the touch. And if the user holds the touch for 10 mins, then the
    // data(point/shape/lines) will not load for 10 mins.
    this._map.on('zoomstart', this._pauseOverlaySources);
    this._map.on(
      'zoom',
      _.debounce(this._resumeOverlaySources, TILE_CALL_DELAY_ON_ZOOM, { leading: false })
    );
  }

  render(vif, renderOptions, overlayOptions) {
    if (this._destroyed) {
      return;
    }

    if (!this._partial.alreadySetup()) {
      // alreadySetup => Checks if the sources are already added to the map.
      // Calling setup the first time and update on subsequent render calls
      // wont work because when the basemap style is changed in the VIF,
      // all the sources/layers gets removed, then the new basemap is initialized.
      // And we need to add the sources/layers again, meaning we need to setup
      this._partial.setup(vif, renderOptions, overlayOptions);
    } else {
      const shouldSetupAgain = this._shouldSetupAgain({
        oldVif: this._existingVif,
        oldRenderOptions: this._existingRenderOptions,
        oldOverlayOptions: this._existingOverlayOptions,
        newVif: vif,
        newRenderOptions: renderOptions,
        newOverlayOptions: overlayOptions
      });

      if (shouldSetupAgain) {
        this._partial.destroy();
        this._partial.setup(vif, renderOptions, overlayOptions);
      } else {
        this._partial.update(vif, renderOptions, overlayOptions);
      }
    }
    this._existingVif = vif;
    this._existingRenderOptions = renderOptions;
    this._existingOverlayOptions = overlayOptions;
  }

  destroy() {
    if (this._partial.alreadySetup()) {
      this._partial.destroy();
    }
    this._destroyed = true;
  }

  getLayerId(layerName) {
    return this._partial.layerIds[layerName];
  }

  renderFeatureInSpiderLeg = (spiderLegOptions) => {
    this._partial.renderFeatureInSpiderLeg(spiderLegOptions);
  };

  unhidePoints = (layerId, countBy) => {
    this._partial.unhidePoints(layerId, countBy);
  };

  hidePointsWithId = (layerId, idBy, rowIds) => {
    this._partial.hidePointsWithId(layerId, idBy, rowIds);
  };

  _getOverlaySourceCaches = () => {
    return _.chain(this._partial.sourceIds).
      values().
      map((sourceId) => _.get(this._map, ['style', 'sourceCaches', sourceId])).
      compact().
      value();
  };

  _pauseOverlaySources = () => {
    _.each(this._getOverlaySourceCaches(), (sourceCache) => sourceCache.pause());
  };

  _resumeOverlaySources = () => {
    _.each(this._getOverlaySourceCaches(), (sourceCache) => sourceCache.resume());
  };

  _shouldSetupAgain(params) {
    const {
      oldVif, oldRenderOptions, oldOverlayOptions,
      newVif, newRenderOptions, newOverlayOptions
    } = params;

    return !_.isEqual(
      this._partial.sourceOptions(oldVif, oldRenderOptions),
      this._partial.sourceOptions(newVif, newRenderOptions)
    ) || !_.isEqual(oldOverlayOptions, newOverlayOptions);
  }
}
