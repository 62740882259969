// Vendor Imports
import _, { get } from 'lodash';
import set from 'lodash/set';
import $ from 'jquery';
import {
  Column,
  ColumnState,
  IServerSideGetRowsRequest,
  IServerSideGetRowsParams
} from '@ag-grid-community/core';

import { Hierarchy, HierarchyColumnConfig, OrderConfig, Vif } from 'common/visualizations/vif';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';

import {
  setInlineDataQuery,
  setRawSoqlDataQuery,
  setAgSoqlDataQuery,
  setSocrataViewDataQuery,
  setGrandTotalDataQuery,
  setAgSoqlExportQuery
} from 'common/visualizations/helpers/TableDataHelpers';
import { VisualizationOptions } from './views/BaseVisualization/types';
import AgTable from './views/AgTable';
import { Filters } from 'common/components/FilterBar/types';
import { isPrintTableEnabled, getItemIdToPrint } from 'common/visualizations/helpers/AgGridPrintHelper';

import { ViewColumn } from 'common/types/viewColumn';
import { GROUP_COLUMN_PREFIX } from 'common/visualizations/views/agGridReact/Constants';
import { getTableHierarchies, getParameterOverrides } from 'common/visualizations/helpers/VifSelectors';

export interface SocrataAgTable extends JQuery {
  initialize: () => void;
  teardown: () => void;
  emitVifChange: () => void;
  attachEvents: () => void;
  detachEvents: () => void;
  render: () => void;
  handleRenderVif: (event: any) => void;
  handleGetGrandTotalRow: (hierarchyConfig: Hierarchy) => Promise<any>;
  handleHierarchyTabChange: (hierarchyId: string) => void;
  handleColumnReorder: (columnState: ColumnState[]) => void;
  handleColumnResize: (columns: Column[] | null) => void;
  handleColumnRowGroupChange: () => void;
  handleColumnSort: (columnState: ColumnState[]) => void;
  handleColumnValueChange: () => void;
  handleSetDataQueryError: (event: any) => void;
  handleFilterChange: (newFilters: Filters) => void;
  initiateDataQuery: (
    vifForDataQuery: Vif,
    startIndex: number,
    pageSize: number,
    order: any,
    agRequest: IServerSideGetRowsRequest
  ) => void;
  setDataQuery: (
    vifForDataQuery: Vif,
    startIndex: number,
    pageSize: number,
    order: any,
    agRequest: IServerSideGetRowsRequest
  ) => void;
  updateState: (newPartialState: Partial<AgTableRenderState>, newVif?: Vif) => void;
  setState: (newState: AgTableRenderState, newVif?: Vif) => void;
}
interface AgTableRenderState {
  error?: boolean;
  busy?: boolean;
  collocating?: boolean;
  fetchingRowCount?: boolean;
  width?: number;
  pageSize?: number;
  pagination?: boolean;
  lastOrderState?: OrderConfig[];
}

$.fn.socrataAgTable = socrataAgTable;

export default function socrataAgTable(originalVif: Vif, options: VisualizationOptions): SocrataAgTable {
  originalVif = migrateVif(_.cloneDeep(originalVif));

  const $element = $(this);
  const pagingEnabled = _.get(options, 'pagingEnabled', true);
  let visualization = $element.data('visualization');

  const printTableEnabled = isPrintTableEnabled() && getItemIdToPrint() !== null;

  // TODO_PRINT_TABLE: Do this work in a declarative way, or pass down an already modified VIF.
  // This process for setting the print state does work, but has a strong code smell.
  // We wait until we're asking for the DataSource, then call updateState, which seems weird.
  function setVifForPrint(datasetRowCount: number) {
    const nextVif = _.cloneDeep(visualization.getVif());
    set(nextVif, 'configuration.pagination', true);
    // No more than 500 rows. That makes for ~20 'paper' pages. It does lag a bit.
    set(
      nextVif,
      'configuration.paginationPageSize',
      Math.min(500, Math.max(get(nextVif, 'configuration.paginationPageSize') || 0, datasetRowCount))
    );
    set(nextVif, 'configuration.printMode', true);
    set(nextVif, 'configuration.defaultColDefOverrides', { autoHeight: true, wrapText: true });
    return nextVif;
  }

  // Holds all state regarding the table's visual presentation.
  // Do _NOT_ update this directly, use setState() or updateState().
  // This is to ensure all state changes are reflected in the UI.
  // NOTE: Avoid adding things to this object!
  let renderState = $element.data('renderState') || {
    vif: null,
    // Is the table busy?
    busy: false,
    // Did we freak out somewhere trying to get data?
    error: false,
    fetchingRowCount: false,
    collocating: false,
    width: null,
    lastOrderState: null
  };

  const getExportData = async (selectedFiltered: boolean) => {
    const hierarchies = _.get(visualization.getVif(), 'series[0].dataSource.hierarchies', []);
    const activeHierarchyId = _.get(visualization.getVif(), 'series[0].dataSource.activeHierarchyId');
    const activeHierarchyIndex = hierarchies.findIndex(
      (hierarchy: Hierarchy) => hierarchy.id == activeHierarchyId
    );
    const configurationOrder = _.get(
      visualization.getVif(),
      `series[0].dataSource.hierarchies[${activeHierarchyIndex}].order`
    );
    const data = await setExportDataQuery(visualization.getVif(), configurationOrder, selectedFiltered);
    return data;
  };

  function getVisualizationDataSource() {
    const datasource = {
      getRows: async (params: IServerSideGetRowsParams) => {
        const { request } = params;
        let { startRow } = request;
        const { endRow } = request;
        let pageSize = 100; //Ag-grids default page
        if (!startRow) {
          startRow = 0;
        }
        if (startRow && endRow) {
          pageSize = endRow - startRow;
        }
        const searchString = _.get(visualization.getVif(), 'series[0].dataSource.searchString');

        // Pull out hierarchies information from vif to determine which column sorts to use
        const hierarchies = _.get(visualization.getVif(), 'series[0].dataSource.hierarchies', []);
        const activeHierarchyId = _.get(visualization.getVif(), 'series[0].dataSource.activeHierarchyId');
        const activeHierarchyIndex = hierarchies.findIndex((h: Hierarchy) => h.id == activeHierarchyId);
        const configurationOrder = _.get(
          visualization.getVif(),
          `series[0].dataSource.hierarchies[${activeHierarchyIndex}].order`
        );

        try {
          const datasetRows = await setDataQuery(
            visualization.getVif(),
            startRow,
            pageSize,
            configurationOrder,
            request,
            searchString
          );

          // Determine if we need to log to the lookup tool usage.
          const { lookupLogCallback } = options;
          if (lookupLogCallback !== undefined && typeof lookupLogCallback === 'function') {
            const filters = _.get(visualization.getVif(), 'series[0].dataSource.filters', []);
            const { rowGroupCols } = request;
            lookupLogCallback(filters, rowGroupCols, datasetRows?.datasetRowCount);
          }

          if (printTableEnabled) {
            const nextVif: Vif = setVifForPrint(datasetRows?.datasetRowCount);
            updateState({}, nextVif);
          }

          return params.success({
            rowData: datasetRows?.rows,
            rowCount: datasetRows?.datasetRowCount
          });
        } catch (e) {
          return;
        }
      }
    };
    return datasource;
  }

  function initializeRowStripeStyle() {
    return {
      base: {
        text: get(visualization.getVif(), 'configuration.rowStripeStyle.base.text'),
        fill: get(visualization.getVif(), 'configuration.rowStripeStyle.base.fill')
      },
      alternate: {
        text: get(visualization.getVif(), 'configuration.rowStripeStyle.alternate.text'),
        fill: get(visualization.getVif(), 'configuration.rowStripeStyle.alternate.fill')
      }
    };
  }

  function initialize() {
    if (visualization) {
      return;
    }

    $element.addClass('socrata-paginated-table');

    // Get the current width, store that in state,
    // and use that to determine if the viz needs to be rendered with special small CSS
    const width = parseInt($element.css('width').slice(0, -2));
    updateState({ width: width });

    visualization = new AgTable($element, originalVif, {
      ...options,
      datasource: getVisualizationDataSource(),
      handleGetGrandTotalRow,
      handleColumnReorder,
      handleColumnResize,
      handleColumnRowGroupChange,
      handleColumnSort,
      handleColumnValueChange,
      handleColumnVisibilityChange,
      handleHierarchyTabChange,
      handleFilterChange,
      initializeRowStripeStyle,
      getExportData
    });
    $element.data('visualization', visualization);

    visualization.render(originalVif);
  }

  function teardown() {
    visualization.destroy();
    $element.removeData('visualization');
    $element.removeData('renderState');
    detachEvents();
  }

  function emitVifChange(nextVif: Vif) {
    visualization.emitVifEvent(nextVif);

    if (options.onAgTableVifUpdate) {
      options.onAgTableVifUpdate(nextVif);
    }
  }

  function attachEvents() {
    detachEvents();

    $element.on('SOCRATA_VISUALIZATION_DESTROY.socrata-table', teardown);

    $element.on('SOCRATA_VISUALIZATION_RENDER_VIF.socrata-table', handleRenderVif);

    $element.on('SOCRATA_VISUALIZATION_INVALIDATE_SIZE.socrata-table', handleComponentResize);
  }

  function detachEvents() {
    $element.off('.socrata-table');
  }

  function render(nextVif?: Vif) {
    const { error, collocating, width } = renderState;

    if (collocating) {
      return visualization.renderCollocationMessage();
    }

    if (error) {
      return visualization.renderError();
    }

    visualization.hideBusyIndicator();
    visualization.renderComponentSize(width);

    if (nextVif) {
      visualization.render(nextVif);
    }
  }

  // Event handler functions

  type VifEvent = {
    originalEvent: {
      detail: Vif;
    };
  } & JQuery.TriggeredEvent;

  async function handleRenderVif(e: JQuery.Event) {
    const event = e as VifEvent;
    const nextVif = migrateVif(_.cloneDeep(event.originalEvent?.detail));

    updateState({}, nextVif);
  }

  function handleHierarchyTabChange(hierarchyId: any) {
    const currentHierarchy = _.get(visualization.getVif(), 'series[0].dataSource.activeHierarchyId');
    if (currentHierarchy !== hierarchyId) {
      const nextVif = _.cloneDeep(visualization.getVif());
      set(nextVif, 'series[0].dataSource.activeHierarchyId', hierarchyId);
      updateState({}, nextVif);
      emitVifChange(nextVif);
    }
  }

  /**
   * triggered from onFilterChange in common/visualizations/views/AgTable.tsx
   * @param newFilters the new vif filters we've changed to
   */
  function handleFilterChange(newFilters: Filters) {
    const nextVif = _.cloneDeep(visualization.getVif());
    set(nextVif, 'series[0].dataSource.filters', newFilters);
    emitVifChange(nextVif);
  }

  function handleColumnReorder(columnState: ColumnState[]) {
    const columnsFromVif = _.get(visualization.getVif(), 'series[0].dataSource.dimension.columns', []);

    const updatedColumnState = columnState.reduce((updatedColumns, columnFromGrid) => {
      const columnFromVif = columnsFromVif.find(
        (vifColumn: { fieldName: string | undefined }) => vifColumn.fieldName === columnFromGrid.colId
      );

      // the column is no longer in the vif, so we will not try to add it back
      if (!columnFromVif) {
        return updatedColumns;
      }

      return updatedColumns.concat(columnFromVif);
    }, []);

    const newUpdatedColumns = _.compact(updatedColumnState);

    if (!_.isEqual(columnsFromVif, newUpdatedColumns)) {
      const nextVif = _.cloneDeep(visualization.getVif());

      set(nextVif, 'series[0].dataSource.dimension.columns', newUpdatedColumns);

      updateState({}, nextVif);
      emitVifChange(nextVif);
    }
  }

  function handleColumnResize(columns: Column[] | null) {
    if (columns) {
      // There are three cases we need to handle:
      // 1. Individual columns being resized - column.getColDef().field is defined and its value is column's fieldName
      // 2. Grouped columns being resized: column.getColDef().field is undefined
      //    a. isIndented is true and AG Grid uses a single column for grouped columns - column.getColId() is
      //       set to GROUP_COLUMN_PREFIX
      //    b. isIndented is false and AG Grid uses multiple columns for grouped columns - column.getColId() is
      //       set to "{GROUP_COLUMN_PREFIX}-{column's fieldName}"

      const nextVif: Vif = visualization.getVif();
      columns.forEach((column) => {
        // We want to handle case 2a first, because we set the width in the hierarchy configuration.
        // For 1 and 2b, we set it in dimension.columns array.
        const colId = column.getColId();
        if (colId === GROUP_COLUMN_PREFIX) {
          // case 2a
          // If the single auto column is resized, we want to store the width in hierarchy configuration
          // This allows us to keep original width for individual columns, so that columns that are being
          // removed from grouping can be resized back to their original width.
          const activeHierarchyId: string = _.get(nextVif, 'series[0].dataSource.activeHierarchyId');
          const activeHierarchyIndex: number = _.get(
            nextVif,
            'series[0].dataSource.hierarchies',
            []
          ).findIndex((h: Hierarchy) => h.id == activeHierarchyId);
          const affectedHierarchy = _.get(
            nextVif,
            `series[0].dataSource.hierarchies[${activeHierarchyIndex}]`
          ) as Hierarchy | undefined;
          if (affectedHierarchy) {
            affectedHierarchy.singleAutoColumnWidth = column.getActualWidth();
          }
        } else {
          // field is only defined for case 1.
          let fieldName = column.getColDef().field;

          if (!fieldName) {
            // case 2b
            // colId is in the format "{GROUP_COLUMN_PREFIX}-{column's fieldName}" - extract column's fieldName
            fieldName = colId.split(`${GROUP_COLUMN_PREFIX}-`, 2)[1];
          }

          const affectedColumn = _.find(_.get(nextVif, 'series[0].dataSource.dimension.columns'), {
            fieldName
          }) as ViewColumn | undefined;
          if (affectedColumn) {
            affectedColumn.width = column.getActualWidth();
          }
        }
      });
      emitVifChange(nextVif);
    }
  }

  async function handleGetGrandTotalRow(hierarchyConfig: Hierarchy) {
    try {
      const grandTotalRows = await setGrandTotalDataQuery(
        visualization.getVif(),
        hierarchyConfig,
        visualization.shouldFetchAggregationSuggestions()
      );
      return grandTotalRows;
    } catch (e) {
      return [];
    }
  }

  /**
   * triggered from onColumnRowGroupChange in common/visualizations/views/AgTable.tsx
   * @param columnState agGridColumn state
   * @param columns AG Grid Columns that changed
   * @param hierarchyId The hierarchy that received the column row group change
   */
  function handleColumnRowGroupChange(columnState: ColumnState[], columns: Column[], hierarchyId?: string) {
    if (!hierarchyId) {
      // This is an ungrouped table.
      return;
    }

    const nextVif = _.cloneDeep(visualization.getVif());
    const hierarchies = getTableHierarchies(visualization.getVif()) ?? [];
    const hierarchyIndex = hierarchies.findIndex((h: Hierarchy) => h.id == hierarchyId);
    const hierarchyColumnConfigurations = hierarchies[hierarchyIndex].columnConfigurations;
    const newHierarchyColumnConfigurations = _.cloneDeep(hierarchyColumnConfigurations);

    // For each of the columns in the table, find its corresponding hierarchy definition and update it.
    columns.forEach((col) => {
      const columnConfigIdx = _.findIndex(
        hierarchyColumnConfigurations,
        (columnConfig) => columnConfig.columnName === col.getColDef().field
      );

      if (columnConfigIdx >= 0) {
        const newConfig: HierarchyColumnConfig = {
          columnName: col.getColDef().field ?? '',
          aggregation: col.isValueActive() ? (col.getAggFunc() as string) ?? null : null,
          isGrouping: col.isRowGroupActive(),
          hidden: !col.isVisible()
        };
        newHierarchyColumnConfigurations[columnConfigIdx] = newConfig;
      }
    });

    // Now reorder the list of configs so that the grouped columns are in sorted order at the
    // beginning followed by the non-grouped columns. We have to get the rowGroupIndex from the
    // columnState because it is not set correctly in the columns data.
    const groupingColumns = newHierarchyColumnConfigurations.filter((config) => config.isGrouping);
    const nonGroupingColumns = newHierarchyColumnConfigurations.filter((config) => !config.isGrouping);

    const findRowGroupIndex = (rowGroup: HierarchyColumnConfig) => {
      const rowGroupIndex = _.findIndex(columnState, { colId: rowGroup.columnName });
      return columnState[rowGroupIndex].rowGroupIndex ?? 0;
    };
    const sortedGroupingColumns = groupingColumns.sort((a, b) =>
      findRowGroupIndex(a) < findRowGroupIndex(b) ? -1 : 1
    );
    const finalHierarchColumnConfigs = sortedGroupingColumns.concat(nonGroupingColumns);

    set(
      nextVif,
      `series[0].dataSource.hierarchies[${hierarchyIndex}].columnConfigurations`,
      finalHierarchColumnConfigs
    );

    updateState({}, nextVif);
    emitVifChange(nextVif);
  }

  /**
   * triggered from onColumnValueChange in common/visualizations/views/AgTable.tsx
   * @param columns AG Grid Columns that changed
   * @param hierarchyId The hierarchy that received the column row group change
   */
  function handleColumnValueChange(columns: Column[], hierarchyId?: string) {
    if (!hierarchyId) {
      // This is an ungrouped table.
      return;
    }

    const nextVif = _.cloneDeep(visualization.getVif());
    const hierarchies = getTableHierarchies(visualization.getVif()) ?? [];
    const hierarchyIndex = hierarchies.findIndex((h: Hierarchy) => h.id == hierarchyId);
    const hierarchyColumnConfigurations = hierarchies[hierarchyIndex].columnConfigurations;

    columns.forEach((col) => {
      const newConfig: HierarchyColumnConfig = {
        columnName: col.getColDef().field ?? '',
        aggregation: col.isValueActive() ? (col.getAggFunc() as string) ?? null : null,
        isGrouping: col.isRowGroupActive(),
        hidden: !col.isVisible()
      };
      const columnConfigIdx = _.findIndex(
        hierarchyColumnConfigurations,
        (columnConfig) => columnConfig.columnName === col.getColDef().field
      );

      if (columnConfigIdx >= 0) {
        set(
          nextVif,
          `series[0].dataSource.hierarchies[${hierarchyIndex}].columnConfigurations[${columnConfigIdx}]`,
          newConfig
        );
      } else {
        // There was no hierarchy column config for this column. Add a new one with the aggregation.
        const newHierarchyColumnConfigurations = get(
          nextVif,
          `series[0].dataSource.hierarchies[${hierarchyIndex}].columnConfigurations`,
          []
        );
        newHierarchyColumnConfigurations.push(newConfig);
        set(
          nextVif,
          `series[0].dataSource.hierarchies[${hierarchyIndex}].columnConfigurations`,
          newHierarchyColumnConfigurations
        );
      }
    });

    updateState({}, nextVif);
    emitVifChange(nextVif);
  }

  /**
   * triggered from onColumnSort in common/visualizations/views/AgTable.tsx
   * @param columnState contains the single column with non-null "sort" value
   * @param hierarchyId The hierarchy that received the column row group change
   */
  function handleColumnSort(columnState: ColumnState[], hierarchyId?: string) {
    const dataSourceType = _.get(visualization.getVif(), 'series[0].dataSource.type');
    const hierarchies = _.get(visualization.getVif(), 'series[0].dataSource.hierarchies');

    if (dataSourceType !== 'socrata.soql' && dataSourceType !== 'socrata.rawSoql') {
      return;
    }

    if (renderState.busy) {
      return;
    }

    // based on column state data, select only sorted columns
    const sortedColumns: ColumnState[] = columnState.filter((column) => column.sort !== null);
    // .sort mutates the array
    // This sorts the columns based on the sortIndex, organizing the columns in the multi sort order.
    sortedColumns.sort((colA: ColumnState, colB: ColumnState) => {
      const aSortIndex = colA?.sortIndex ?? 0;
      const bSortIndex = colB?.sortIndex ?? 0;

      return aSortIndex - bSortIndex;
    });

    const firstRowGroup = columnState.filter((column) => column.rowGroupIndex == 0)[0];

    // restructure to a vif-friendly format
    const vifFriendlyOrder = sortedColumns.map(({ colId, sort }) => ({
      columnName:
        colId === GROUP_COLUMN_PREFIX && !!firstRowGroup
          ? `${GROUP_COLUMN_PREFIX}-${firstRowGroup.colId}`
          : colId,
      ascending: sort === 'asc'
    }));

    // put the order onto the vif in one of two formats:
    // 1. as global order when there are no hierarchies created
    // 2. as localized order specific to active hierarchy
    if (hierarchies.length === 0) {
      const existingOrder = _.get(visualization.getVif(), 'configuration.order');
      if (!_.isEqual(existingOrder, vifFriendlyOrder)) {
        const nextVif = _.cloneDeep(visualization.getVif());

        set(nextVif, 'configuration.order', vifFriendlyOrder);

        updateState({ lastOrderState: existingOrder }, nextVif);
        emitVifChange(nextVif);
      }
    } else {
      const activeHierarchyId = _.get(visualization.getVif(), 'series[0].dataSource.activeHierarchyId');
      const activeHierarchyIndex = hierarchies.findIndex((h: Hierarchy) => h.id == activeHierarchyId);

      if (activeHierarchyId !== hierarchyId) {
        return;
      }

      const existingHierarchyOrder = hierarchies[activeHierarchyIndex].order || [];
      if (!_.isEqual(existingHierarchyOrder, vifFriendlyOrder)) {
        const nextVif = _.cloneDeep(visualization.getVif());

        set(nextVif, `series[0].dataSource.hierarchies[${activeHierarchyIndex}].order`, vifFriendlyOrder);

        updateState({}, nextVif);
        emitVifChange(nextVif);
      }
    }
  }

  /**
   * triggered from onColumnVisibilityChange in common/visualizations/views/AgTable.tsx
   * @param columnsState agGridColumn state, which includes the hide state for each column
   * @param columns AG Grid Columns
   * @param hierarchyId The hierarchy that received the column visibility change
   */
  function handleColumnVisibilityChange(
    columnsState: ColumnState[],
    columns: Column[] | null,
    hierarchyId?: string
  ) {
    const hierarchies = _.get(visualization.getVif(), 'series[0].dataSource.hierarchies');
    const nextVif = visualization.getVif();

    if (hierarchies.length !== 0 && columns) {
      const activeHierarchyId = _.get(visualization.getVif(), 'series[0].dataSource.activeHierarchyId');
      const activeHierarchyIndex = hierarchies.findIndex((h: Hierarchy) => h.id == activeHierarchyId);

      if (activeHierarchyId === hierarchyId) {
        // Note: This list might not include all columns in the table
        const hierarchyColumnConfigurations: HierarchyColumnConfig[] = _.get(
          visualization.getVif(),
          `series[0].dataSource.hierarchies[${activeHierarchyIndex}].columnConfigurations`
        );

        // For each of the columns in the table, find its corresponding hierarchy definition
        // and update its visibility.
        columns.forEach((column) => {
          const columnName = column.getColDef().field!;

          const columnConfigIdx = _.findIndex(
            hierarchyColumnConfigurations,
            (columnConfig) => columnConfig.columnName === columnName
          );

          if (columnConfigIdx >= 0) {
            const isGrouping = _.get(hierarchyColumnConfigurations[columnConfigIdx], 'isGrouping', false);
            set(
              nextVif,
              `series[0].dataSource.hierarchies[${activeHierarchyIndex}].columnConfigurations[${columnConfigIdx}].hidden`,
              // We hide grouping columns from AG Grid's columns overlay,
              // so a grouping column will have column.isVisible() == true
              !isGrouping && !column.isVisible()
            );
          } else {
            // There was no hierarchy column config for this column. Add a new one with the column visibility.
            hierarchyColumnConfigurations.push({
              columnName,
              aggregation: null,
              isGrouping: false,
              hidden: !column.isVisible()
            });
            set(
              nextVif,
              `series[0].dataSource.hierarchies[${activeHierarchyIndex}].columnConfigurations`,
              hierarchyColumnConfigurations
            );
          }
        });
      }
    } else if (columns) {
      // When there are no hierarchies defined, set the visibility on the columns directly.
      columns.forEach((column) => {
        const columnName = column.getColDef().field!;
        const affectedColumn = _.find(_.get(nextVif, 'series[0].dataSource.dimension.columns'), {
          fieldName: columnName
        }) as ViewColumn | undefined;
        if (affectedColumn) {
          affectedColumn.hide = !column.isVisible();
        }
      });
    }
    emitVifChange(nextVif);
  }

  /**
   * If the component is resized in storyteller, update the state with the new width,
   * which is sued to determine if the viz should be rendered with special small CSS
   */
  function handleComponentResize() {
    const renderStateWidth = renderState.width;
    const width = parseInt($element.css('width').slice(0, -2));

    if (!_.isEqual(renderStateWidth, width)) {
      updateState({ width: width });
    }
  }

  /**
   * Data Requests
   */

  function handleSetDataQueryError(error: any) {
    if (window.console && _.isFunction(window.console.error)) {
      console.error('Error while fulfilling table data request:', error.soqlError ?? error.message ?? error);
    }

    const collocating = _.get(error, 'soqlError.status') === 'in-progress';

    // There was an issue populating this table with data. Retry?
    updateState({ busy: false, error: true, collocating });

    return Promise.reject(`Error while fulfilling table data request: ${JSON.stringify(error)}`);
  }

  const setExportDataQuery = async (vif: Vif, order: OrderConfig[], selectedFiltered: boolean) => {
    try {
      visualization.showBusyIndicator();
      updateState({ busy: true });
      $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_START');
      const previousOrder = renderState.lastOrderState;
      const data = await setAgSoqlExportQuery(vif, order, previousOrder, selectedFiltered);
      $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE');
      return data;
    } catch (error) {
      return {
        columns: [],
        rows: [],
        rowIds: []
      };
    } finally {
      visualization.hideBusyIndicator();
    }
  };

  async function setDataQuery(
    vifForDataQuery: Vif,
    startIndex: number,
    pageSize: number,
    order: OrderConfig[],
    agRequest: any,
    searchString: string
  ) {
    try {
      visualization.showBusyIndicator();
      updateState({ busy: true });

      $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_START');

      const {
        rows,
        vif: nextVif,
        datasetRowCount,
        grandTotal
      } = await initiateDataQuery({
        agRequest,
        order,
        pageSize,
        searchString,
        startIndex,
        vifForDataQuery
      });

      $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE');
      updateState({ busy: false, fetchingRowCount: false }, nextVif);

      return { datasetRowCount, rows, grandTotal };
    } catch (error) {
      await handleSetDataQueryError(error);
    } finally {
      visualization.hideBusyIndicator();
    }
  }

  function initiateDataQuery({
    agRequest,
    order,
    pageSize,
    searchString,
    startIndex,
    vifForDataQuery
  }: {
    agRequest: IServerSideGetRowsRequest;
    order: OrderConfig[];
    pageSize: number;
    searchString: string;
    startIndex: number;
    vifForDataQuery: Vif;
  }) {
    const dataSourceType = _.get(vifForDataQuery, 'series[0].dataSource.type');
    switch (dataSourceType) {
      case 'socrata.inline':
        return setInlineDataQuery(vifForDataQuery, null, startIndex, pageSize, pagingEnabled);

      case 'socrata.soql':
        // This can update the VIF with an order
        return setAgSoqlDataQuery({
          vifForDataQuery,
          startIndex,
          pageSize,
          order,
          agRequest,
          searchString,
          previousOrder: renderState.lastOrderState,
          shouldFetchAggregationSuggestions: visualization.shouldFetchAggregationSuggestions()
        });

      case 'socrata.rawSoql':
        return setRawSoqlDataQuery(vifForDataQuery, null, startIndex, pageSize, order);

      case 'socrata.view':
        return setSocrataViewDataQuery(vifForDataQuery, null);

      default:
        return Promise.reject(`Invalid data source type in vif: '${dataSourceType}'.`);
    }
  }

  // Updates only specified UI state.
  function updateState(newPartialState: Partial<AgTableRenderState>, nextVif?: Vif) {
    setState(_.extend({}, renderState, newPartialState), nextVif);
  }

  // Replaces entire UI state.
  function setState(newState: AgTableRenderState, nextVif?: Vif) {
    const vifChanged = nextVif && !_.isEqual(visualization.getVif(), nextVif);
    const dataChanged =
      nextVif && !_.isEqual(getParameterOverrides(visualization.getVif()), getParameterOverrides(nextVif));
    if (dataChanged) {
      renderState = newState;
      // Update dataSource with new vif state that include parameterOverrides
      const newDataSource = getVisualizationDataSource();
      visualization.updateDataSource(newDataSource);
    }

    if (vifChanged || !_.isEqual(renderState, newState)) {
      const becameIdle = !newState.busy && renderState.busy;

      const newWidth = renderState.width ? !_.isEqual(renderState.width, newState.width) : null;

      renderState = newState;

      if (becameIdle || vifChanged || newWidth) {
        render(nextVif);
      }
    }
  }

  initialize();
  attachEvents();

  return this;
}
