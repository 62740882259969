import _ from 'lodash';
import moment from 'moment';
import { getStartOfQuarterMonth } from 'common/dates';
import { PeriodSizes } from '../constants';
import * as ReportingPeriods from '../reportingPeriods';

function endDateToEndsBeforeDate(measure) {
  const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
  const endDate = _.get(reportingPeriod, 'endDate');
  if (!endDate) {
    return reportingPeriod;
  }

  const endsBeforeDate = legacyGetReportingPeriodStartContainingDate(measure, endDate);
  let updatedReportingPeriod = reportingPeriod;
  _.set(updatedReportingPeriod, 'endsBeforeDate', endsBeforeDate);
  _.unset(updatedReportingPeriod, 'endDate');
  return updatedReportingPeriod;
}

export default function convertToVersion2(measure) {
  let measureV2 = _.cloneDeep(measure);
  const updatedReportingPeriod = endDateToEndsBeforeDate(measure);
  _.set(measureV2, 'metricConfig.reportingPeriod', updatedReportingPeriod);

  _.set(measureV2, 'version', 2);
  return measureV2;
}

/**
 * Given a measure and a date, returns the start of the reporting period which
 * contains that date. If the reporting period of the measure is not fully
 * configured, it just returns the date.
 *
 * This is pulled into this file because after V3 the startDate schema is different
 * and will fail the v1 to v2 migration
 */
const legacyGetReportingPeriodStartContainingDate = (measure, date) => {
  if (!date) {
    return null;
  }

  const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
  const startDate = _.get(measure, 'metricConfig.reportingPeriod.startDate');

  const { firstQuarterStartMonth, size } = reportingPeriod;
  if (!size || !startDate || moment(date).isSameOrBefore(startDate)) {
    return date;
  }

  let correctedDate;

  switch (size) {
    case PeriodSizes.DAY: {
      const dateRange = _.last(ReportingPeriods.seriesThroughDate(startDate, size, date));
      correctedDate = moment(dateRange.start);
      break;
    }
    case PeriodSizes.WEEK: {
      const dateRange = _.last(ReportingPeriods.seriesThroughDate(startDate, size, date));
      correctedDate = moment(dateRange.start);
      break;
    }
    case PeriodSizes.MONTH:
      correctedDate = moment(date).startOf('month');
      break;
    case PeriodSizes.QUARTER: {
      const dateMoment = moment(date);
      const correctedMonth = getStartOfQuarterMonth(dateMoment.month(), firstQuarterStartMonth);
      correctedDate = dateMoment.month(correctedMonth).startOf('month');
      if (correctedMonth > 9) {
        // This ensures that we don't leap ahead 3 quarters instead of back 1 quarter
        correctedDate.subtract(1, 'year');
      }
      break;
    }
    case PeriodSizes.YEAR: {
      const dateMoment = moment(date);
      correctedDate = dateMoment.
        month(moment(startDate).month()).
        startOf('month');
      if (dateMoment.month() !== 0) {
        // reporting period spans the year line, so be sure not to move forward in time
        correctedDate.subtract(1, 'year');
      }
      break;
    }
    default:
      throw new Error('Unrecognized reporting period size');
  }

  return correctedDate.format('YYYY-MM-DD');
};
