import includes from 'lodash/includes';
import 'common/types/windowSocrata';

/**
 * Get the current environment.
 * This will match whatever `Rails.env` is for the current app.
 * In some cases, this falls back to `process.env` which is set by node when using create-react-app or similar.
 */
export const getEnvironment = () => window.socrata?.environment || window.ENVIRONMENT || process.env.NODE_ENV || window.serverConfig?.environment;

/** @returns Whether or not we're current in the development environment */
export const isDevelopmentEnvironment = () => includes(['development', 'develop_against_staging'], getEnvironment());

/** @returns Whether or not we're current in the test environment */
export const isTestEnvironment = () => getEnvironment() === 'test';

/** @returns Whether or not we're current in the production environment */
export const isProductionEnvironment = () => getEnvironment() === 'production';
