import _ from 'lodash';

import { MAP_TYPES } from 'common/visualizations/views/mapConstants';
import { POINT_AGGREGATIONS } from 'common/authoring_workflow/constants';
import VifHeatOverlay from './vifOverlays/VifHeatOverlay';
import VifLineOverlay from './vifOverlays/VifLineOverlay';
import VifOverlayWrapper from './vifOverlays/VifOverlayWrapper';
import VifPointOverlay from './vifOverlays/VifPointOverlay';
import VifRegionOverlay from './vifOverlays/VifRegionOverlay';
import VifShapeOverlay from './vifOverlays/VifShapeOverlay';

// Based on the newVif/existingVif, it will return an overlay, that can
// render the data dictated by the newVif. It will
//  - return the existing overlay (OR)
//  - return a new overlay(VifHeatOverlay|VifLineOverlay|...).
export function getOverlaysFor(params) {
  const { newVif, existingVif, existingOverlays, map, mouseInteractionHandler } = params;

  return _.map(_.get(newVif, 'series'), (series, index) => {
    const newMapType = _.get(newVif, `series[${index}].mapOptions.mapType`);
    const newPointAggregation = _.get(newVif, `series[${index}].mapOptions.pointAggregation`);
    const isNewSeriesRegionMap = newVif.cloneWithSingleSeries(index).isRegionMap();

    if (index > existingVif.series.length - 1) return generateNewOverlays(newMapType, newPointAggregation, isNewSeriesRegionMap, map, mouseInteractionHandler);

    const isExistingSeriesRegionMap = existingVif && existingVif.cloneWithSingleSeries(index).isRegionMap();
    const existingMapType = _.get(existingVif, `series[${index}].mapOptions.mapType`);
    const existingPointAggregation = _.get(existingVif, `series[${index}].mapOptions.pointAggregation`);

    if (existingMapType === newMapType &&
        existingPointAggregation === newPointAggregation &&
        isExistingSeriesRegionMap === isNewSeriesRegionMap &&
        existingOverlays && existingOverlays[index]) {
      return existingOverlays[index];
    } else {
      return generateNewOverlays(newMapType, newPointAggregation, isNewSeriesRegionMap, map, mouseInteractionHandler);
    }
  });
}

const generateNewOverlays = (newMapType, newPointAggregation, isNewSeriesRegionMap, map, mouseInteractionHandler) => {
  let overlay;

  if (newMapType === MAP_TYPES.POINT_MAP) {
    if (newPointAggregation === POINT_AGGREGATIONS.HEAT_MAP) {
      overlay = new VifHeatOverlay(map);
    } else if (isNewSeriesRegionMap) {
      overlay = new VifRegionOverlay(map, mouseInteractionHandler);
    } else {
      overlay = new VifPointOverlay(map, mouseInteractionHandler);
    }
  } else if (newMapType === MAP_TYPES.LINE_MAP) {
    overlay = new VifLineOverlay(map, mouseInteractionHandler);
  } else if (newMapType === MAP_TYPES.BOUNDARY_MAP) {
    overlay = new VifShapeOverlay(map, mouseInteractionHandler);
  } else {
    throw new Error(`Unknown map type '${newMapType}'`);
  }

  return new VifOverlayWrapper(map, overlay);
};
