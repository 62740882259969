import PropTypes from 'prop-types';

/**
 * Representation of a Filter within the FilterBar component
 *
 * To learn more about Filter objects, check out:
 * http://github.com/socrata/platform-ui/blob/main/common/visualizations/VIF.md#appendix-soql-filter-objects
 * This PropType definition is a 1:1 mapping of the properties described in the doc above
 */
export default PropTypes.shape({
  arguments: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  columns: PropTypes.arrayOf(PropTypes.shape({ fieldName: PropTypes.string, datasetUid: PropTypes.string })),
  function: PropTypes.string,
  isDrilldown: PropTypes.bool,
  isHidden: PropTypes.bool,
  joinOn: PropTypes.string
});
