import { cloneDeep, isEmpty, get, set, isUndefined } from 'lodash';
import { Vif, Series } from 'common/visualizations/vif';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';

export function migrateVif5ToVif6(vifToMigrate: any): Vif {
  const datasetUid = get(vifToMigrate, 'series[0].dataSource.datasetUid');
  const migratedVif = cloneDeep(vifToMigrate);

  const series = get(migratedVif, 'series');
  const modifiedSeries = series.map((seriesItem: Series) => {
    const newFilters = addColumnsToFilters(seriesItem.dataSource?.filters, datasetUid);
    set(seriesItem, 'dataSource.filters', newFilters);
    return seriesItem;
  });

  set(migratedVif, 'series', modifiedSeries);
  migratedVif.format.version = 6;
  return migratedVif;
}

export function addColumnsToFilters(filters: SoqlFilter[] | undefined, datasetUid: string) {
  if (isEmpty(filters) || isUndefined(filters)) {
    return [] as SoqlFilter[];
  }

  return filters
    .map((filter) => {
      if (filter.columns) {
        return filter as SoqlFilter;
      }

      // If the filter does not have columns, it is an old SingleSourceSoqlFilter.
      // If it has columnName, convert it to a new SoqlFilter, replacing columnName with columns.
      const singleSourceFilter = filter as any;
      if (isEmpty(singleSourceFilter.columnName)) {
        return undefined;
      }

      const datasetColumn = {
        fieldName: singleSourceFilter.columnName,
        datasetUid
      };

      return {
        ...singleSourceFilter,
        columns: [datasetColumn]
      } as SoqlFilter;
    })
    .filter((filter) => filter !== undefined) as SoqlFilter[];
}
