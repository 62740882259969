import React, { TextareaHTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';
import omit from 'lodash/omit';

import { CustomInputProps } from './types';
import RequiredStar from './RequiredStar';

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & PropsWithChildren<CustomInputProps>;

/**
 * Generic TextArea component
 *
 * Renders with a label (and required * if it's required) and handles valid/disabled/etc.
 *
 * All props will be passed to the "real" <textarea /> that is rendered.
 *
 * Children will be rendered next to the label. This is useful for i.e. buttons that open modals to show more
 * info about the input.
 */
const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { children, label, id, name, valid, required, disabled, className, errorMessage } = props;

  // since we need an id to link the label, we use the name if we're not passed an id
  const inputId = id || `form-textarea-${name}`;

  // used in aria-describedby for an error message, if there is one
  const errorId = `${inputId}-error`;

  const inputClassName = cx('form-component-textarea text-input', className, {
    'text-input-error': !valid && !disabled
  });

  // omit any "non-form" props that have been passed in
  // otherwise, pass the props to the input wholesale
  const filteredProps = omit(props, [
    'key', // if rendering an array of inputs
    'children', // we put this in the label
    'containerClassName',
    'valid',
    'errorMessage',
    'iconName',
    'label'
  ]);

  const inputProps = {
    ...filteredProps,
    id: inputId,
    className: inputClassName,
    'aria-required': required
  };

  // we only want to add the `aria-describedby` if we have an error message
  if (!valid) {
    inputProps['aria-describedby'] = errorId;
    inputProps['aria-invalid'] = !valid;
  }

  return (
    <div>
      <div className="form-component-input-label-container">
        <label className="form-component-input-label" htmlFor={inputId}>
          {label} <RequiredStar required={required || false} />
          {children}
        </label>
        {!valid && (
          <div className="form-component-input-error-message" id={errorId}>
            {errorMessage}
          </div>
        )}
      </div>
      <textarea ref={ref} {...inputProps} />
    </div>
  );
});

TextArea.defaultProps = {
  required: false,
  valid: true,
  disabled: false
};

TextArea.displayName = 'TextArea'; // forwardRef alters the name

export default TextArea;
