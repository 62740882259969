import React, { FunctionComponent, useRef, useState } from 'react';
import noop from 'lodash/noop';

import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';
import { userCanChangeAudience } from 'common/components/AccessManager/Util';
import { getCurrentUser } from 'common/current_user';
import { FEATURE_FLAGS } from 'common/components/AccessManager/Constants';
import { ForgeButton } from '@tylertech/forge-react';
import { ForgePopup } from '@tylertech/forge-react';

const getActionTextKey = (isUsaid: boolean, previouslyPublished: boolean) => {
  if (isUsaid) {
    return 'usaid';
  } else if (previouslyPublished) {
    return 'previously_published';
  } else {
    return 'never_published';
  }
};

interface PublishButtonProps {
  previouslyPublished: boolean;
  onClick: () => void;
  disabled?: boolean;
  disabledReason?: string | JSX.Element;
}

export const PublishButton: FunctionComponent<PublishButtonProps> = ({
  previouslyPublished = false,
  disabled = false,
  disabledReason,
  onClick = noop
}: PublishButtonProps) => {
  const targetRef = useRef<any>();
  const [flyoutOpen, setFlyoutOpen] = useState(false);

  const isUsaid = FeatureFlags.value(FEATURE_FLAGS.USAID_FEATURES_ENABLED);
  const actionTextKey = getActionTextKey(isUsaid, previouslyPublished);

  const userCannotChangeAudience = !userCanChangeAudience(getCurrentUser());

  // button is disabled if user cannot change audience and is publishing for the first time
  const updateDisabledDueToUserAudiencePrivileges =
    userCannotChangeAudience && actionTextKey != 'previously_published';
  const buttonIsDisabled = updateDisabledDueToUserAudiencePrivileges || disabled;

  // determine the action
  const key = getActionTextKey(isUsaid, previouslyPublished);
  const actionText = I18n.t(
    `shared.components.asset_action_bar.publication_action.draft.primary_action_text.${key}`
  );

  const getDisabledExplanation = () => {
    if (userCannotChangeAudience) {
      return I18n.t('shared.components.asset_action_bar.publication_action.publish_disabled');
    }
    return disabledReason;
  };
  const onClickAction = buttonIsDisabled ? noop : onClick;
  const disabledExplanation = getDisabledExplanation();

  const onMouseEnter = () => {
    setFlyoutOpen(true);
  };

  const onMouseLeave = () => {
    setFlyoutOpen(false);
  };

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ForgeButton type="raised" onClick={onClickAction}>
        <button data-testid="aab-publish-button" ref={targetRef} type="button" disabled={buttonIsDisabled}>
          {actionText}
        </button>
      </ForgeButton>
      {buttonIsDisabled && disabledExplanation && (
        <ForgePopup
          targetElementRef={targetRef}
          open={flyoutOpen}
          onDismiss={() => {}}
          options={{ placement: 'bottom-end' }}
        >
          <div className="forge-typography--body2">
            <div className="publish-button-flyout-content">{disabledExplanation}</div>
          </div>
        </ForgePopup>
      )}
    </div>
  );
};
