import React, { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';

interface ContentProps {
  className?: string;
  styleOverride?: React.CSSProperties;
}

export const Content: FunctionComponent<PropsWithChildren<ContentProps>> = ({ className, styleOverride, ...restProps }) => (
  <section style={{
    ...styleOverride,
  }} className={cx(className, 'modal-content')} {...restProps} />
);

export default Content;
