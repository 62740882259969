import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StorytellerReduxStore } from '../../store/StorytellerReduxStore';
import StoryCopyModal from '../components/StoryCopyModal';

export default function StoryCopierRenderer(options) {
  const container = options.storyCopierContainerElement || null;
  ReactDOM.render(<Provider store={StorytellerReduxStore}><StoryCopyModal /></Provider>, container[0]);
}
