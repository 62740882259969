import $ from 'jquery';
import _ from 'lodash';
import Geojson2wkt from 'geojson2wkt';
import MapboxglSpiderifier from '@socrata/mapboxgl-spiderifier';

import airbrake from 'common/airbrake';

import { getFeaturesWithPopupData } from '../contentFormatters/genericContentFormatter';
import { getRetrieveDataCondition, getSnappedFeaturesInCluster } from '../contentFormatters/stackContentFormatter';

import * as mapConstants from 'common/visualizations/views/mapConstants';
import { RESIZE_BY_ALIAS } from 'common/visualizations/views/map/vifOverlays/VifPointOverlay';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { getBasemapStyle } from 'common/visualizations/views/map/basemapStyle';
import { VECTOR_BASEMAP_STYLES } from 'common/authoring_workflow/constants';

// Spiderfies a cluster. In every tile data call, we snap the geometries
// based on zoom level, group them (to deduplicate) and fetch them. The
// retrieved snapped-features are then again clustered by mapbox, otherwise
// the snapped features will look like a checker-board.
// To spiderfy, we make a soql call based on the snapped features in the
// cluster and get individual features and spiderfy them.
export default class SpiderfyHandler {
  constructor(map, popupHandler) {
    this._map = map;
    this._popupHandler = popupHandler;
    this._spiderifier = new MapboxglSpiderifier(map, {
      customPin: true,
      animate: true,
      initializeLeg: this.initializeSpiderLeg
    });
  }

  // Spiderfies a cluster feature.
  // featureConfigs =  [
  //  { feature: <>, renderOptions: <>, vif: <vif> }
  //  { feature: <>, renderOptions: <>, vif: <vif> }
  // ]
  async spiderfy(featureConfigs, renderFeatureInSpiderLeg) {
    // All the features in the array are on the same location (or overlapping locations.)
    // The spiderfication should be anchored to the top most point(zero th index).
    // So taking the first feature's coordinates and anchoring the spiderfication to it.
    const clusterCoordinates = _.get(featureConfigs[0].feature, 'geometry.coordinates');
    this._map.fire(mapConstants.MAP_EVENTS.LOADING_SPIDER_DATA);
    // UnSpiderfy if there is already a spiderfication.
    this.unspiderfy();
    this._spiderfyStartProcess = true;
    this._renderFeatureInSpiderLeg = renderFeatureInSpiderLeg;
    const vif = _.get(featureConfigs, '0.vif');

    const featuresAndClusterConfig = await getFeaturesAndClusterConfig(featureConfigs, this._map);

    if (this._spiderfyStartProcess) {
      const features = _.chain(featuresAndClusterConfig).
        flatten().
        compact().
        take(mapConstants.DEFAULT_SPIDERFY_FEATURES_COUNT).
        value();

      if (features.length > 1) {
        setStyleProperities(this._map, vif);
        this._spiderifier.spiderfy(clusterCoordinates, features);
      }
    }
    this._map.fire(mapConstants.MAP_EVENTS.FINISHED_SPIDER_DATA);
  }

  // Unspiderfies if there are spiderfies created by this instance in the map.
  unspiderfy() {
    this._spiderfyStartProcess = false;
    this._spiderifier.unspiderfy();
  }

  // Creates/Decorates pin in each spider leg based on the feature, vif and renderOptions.
  initializeSpiderLeg = (spiderLeg) => {
    const { renderOptions, vif } = _.get(spiderLeg.feature, 'clusterConfig', {});
    const featureAndRenderOptions = { spiderLeg, renderOptions, vif };

    this._renderFeatureInSpiderLeg(featureAndRenderOptions);
    this._popupHandler.initializePopupForSpideredMarker(featureAndRenderOptions);
  };
}

async function getFeaturesAndClusterConfig(featureConfigs, map) {
  const featurePromises = _.map(featureConfigs, async (featureConfig) => {
    const { feature, renderOptions, vif } = featureConfig;
    const additionalSelects = [];
    const isLayerVisible = vif.isLayerVisible();
    const colorByPointColumnName = vif.getColorPointsByColumn();
    const resizeByColumn = vif.getResizePointsByColumn();

    if (!isLayerVisible) {
      return [];
    }

    if (!_.isNil(colorByPointColumnName)) {
      additionalSelects.push(
        `${SoqlHelpers.escapeColumnName(colorByPointColumnName)}||'' as ${renderOptions.colorBy}`
      );
    }

    if (!_.isNil(resizeByColumn)) {
      additionalSelects.push(
        `${SoqlHelpers.escapeColumnName(resizeByColumn)}||'' as ${RESIZE_BY_ALIAS}`
      );
    }

    try {
      const snappedFeatures = await getSnappedFeaturesInCluster(feature, map);
      if (!_.isEmpty(snappedFeatures)) {
        const retrieveDataConditions = getRetrieveDataCondition(snappedFeatures, map, vif);
        const featuresWithPopupData = await getFeaturesWithPopupData(vif, retrieveDataConditions, additionalSelects);

        _.each(featuresWithPopupData, (featureWithPopupData) => {
          featureWithPopupData.clusterConfig = { vif, renderOptions };
        });

        return featuresWithPopupData;
      }
    } catch (e) {
      airbrake.notify({
        error: e,
        context: { component: 'SpiderfyHandler' }
      });
    }
  });

  try {
    const featuresAndClusterConfig = await Promise.all(featurePromises);
    return featuresAndClusterConfig;
  } catch (e) {
    airbrake.notify({
      error: e,
      context: { component: 'SpiderfyHandler' }
    });
  }

  return [];
}

function setStyleProperities(map, vif) {
  const basemapStyle = getBasemapStyle(vif);
  const darkAndSatelliteStyle = [VECTOR_BASEMAP_STYLES.dark.value, VECTOR_BASEMAP_STYLES.satellite.value];
  if (_.includes(darkAndSatelliteStyle, basemapStyle)) {
    const $mapElement = $(map._container).find(`.${mapConstants.MAP_CANVAS_CLASSNAME}`);
    const mapHeight = $($mapElement).css('height');
    const mapWidth = $($mapElement).css('width');

    $(map._container).find(`.${mapConstants.MAP_HIGHLIGHT_CONTAINER_CLASSNAME}`).css({
      'width': `${mapWidth}`,
      'height': `${mapHeight}`
    });
  } else {
    $(map._container).find(`.${mapConstants.MAP_CANVAS_CLASSNAME}`).
      css('opacity', mapConstants.MAP_CANVAS_OPACITY);
  }
}
