import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * TableRow is a wrapper to allow for better visualizing the flow of props through the table
 */
const TableRow = ({ children, rowClassName }) => (
  <tr className={cx(rowClassName, 'results-list-row')}>{children}</tr>
);

TableRow.propTypes = {
  children: PropTypes.any
};

export default TableRow;
