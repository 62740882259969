import { action } from 'typesafe-actions';

import type { UsersCatalogSearchResult, CatalogUserOrTeam } from 'common/types/users/catalogUsers';

export enum ChangeOwnerActionTypes {
  OWNER_SEARCH_QUERY_CHANGED = 'OWNER_SEARCH_QUERY_CHANGED',
  OWNER_SEARCH_RESULTS_FETCH_SUCCESS = 'OWNER_SEARCH_RESULTS_FETCH_SUCCESS',
  OWNER_SEARCH_RESULTS_FETCH_FAIL = 'OWNER_SEARCH_RESULTS_FETCH_FAIL',
  ADD_SELECTED_OWNER = 'ADD_SELECTED_OWNER',
  REMOVE_SELECTED_OWNER = 'REMOVE_SELECTED_OWNER',
  CONFIRM_SELECTED_OWNER = 'CONFIRM_SELECTED_OWNER'
}

/** Typing into the owner search box */
export const ownerSearchQueryChanged = (query: string) =>
  action(ChangeOwnerActionTypes.OWNER_SEARCH_QUERY_CHANGED, { query });

/** Getting results back from catalog */
export const ownerSearchResultsFetchSuccess = (results: UsersCatalogSearchResult) =>
  action(ChangeOwnerActionTypes.OWNER_SEARCH_RESULTS_FETCH_SUCCESS, { results });

/** Failed to get results from catalog */
export const ownerSearchResultsFetchFail = (error: any) =>
  action(ChangeOwnerActionTypes.OWNER_SEARCH_RESULTS_FETCH_FAIL, { error });

/** Add a owner to the combobox */
export const addSelectedOwner = (owner: CatalogUserOrTeam) =>
  action(ChangeOwnerActionTypes.ADD_SELECTED_OWNER, { owner });

/** Remove the pending owner from the combobox */
export const removeSelectedOwner = (owner: CatalogUserOrTeam) =>
  action(ChangeOwnerActionTypes.REMOVE_SELECTED_OWNER, { owner });

/** Confirm changing ownership to the selected owner */
export const confirmSelectedOwner = (owner: CatalogUserOrTeam) =>
  action(ChangeOwnerActionTypes.CONFIRM_SELECTED_OWNER, { owner });
