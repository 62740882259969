import _ from 'lodash';

import { assertInstanceOf } from 'common/assertions';
import parseJsonOrEmpty from 'common/js_utils/parseJsonOrEmpty';
import {
  applyAdditionalFiltersToVif,
  applyParameterOverridesToVif,
  getVifSeriesDatasets
} from 'common/visualizations/helpers/VifHelpers';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import { Vif, V1TableVif } from 'common/visualizations/vif';

import { GlobalFilters, GlobalParameterOverrides } from 'types';
import Constants from 'lib/Constants';
import I18n from 'common/i18n';

import { filtersAreEquivalent } from './FilterUtils';

export const vifsAreEquivalent = (vif1: Vif | V1TableVif, vif2: Vif | V1TableVif): boolean => {
  if (vif1 === vif2) {
    // We clone a lot but this can happen in some cases!
    // Important not to clone if we don't have to, since this is called very frequently.
    return true;
  }
  const vif1Clone = _.cloneDeep(vif1);
  const vif2Clone = _.cloneDeep(vif2);

  // The 'createdAt' property is updated when a visualization is added to the
  // story, but if everything else is the same the two visualizations are
  // equivalent anyway. We can therefore delete this key from the two cloned
  // vifs and measure functional equality using _.isEqual().
  delete vif1Clone.createdAt;
  delete vif2Clone.createdAt;

  return _.isEqual(vif1Clone, vif2Clone);
};

export const updateVifWithDefaults = (vif: Vif): Vif => {
  assertInstanceOf(vif, Object);

  const newVif = _.cloneDeep(vif);

  _.defaults(newVif, {
    unit: {
      one: I18n.t('editor.visualizations.default_unit.one'),
      other: I18n.t('editor.visualizations.default_unit.other')
    }
  });

  // Core strips null values from our vif when we retrieve it with the ViewsService.
  // See Block class (rails) for another spot where we fill in the stripped fields.
  // Important fields that will break viz if they don't exist:
  //
  //    series[...].dataSource.filters (default this to empty array)
  //    series[...].dataSource.filters[...].argument (default this to null)
  //
  _.forEach(newVif.series, (series) => {
    if (_.isUndefined(series.dataSource.filters)) {
      series.dataSource.filters = [];
    } else {
      _.forEach(series.dataSource.filters, (filter) => {
        if (_.isUndefined(filter.arguments)) {
          filter.arguments = null;
        }
      });
    }
  });

  return newVif;
};

// At some point in the future we may want to do a check to see if the
// datasetUid is available on `tileserver[1..n].api.us.socrata.com` before
// falling back to the dataset's host domain.
//
// For now, this should be sufficient.
export const updateFeatureMapVifWithDefaults = (vif: Vif): Vif => {
  assertInstanceOf(vif, Object);

  const newVif = _.cloneDeep(vif);

  _.defaults(newVif, {
    configuration: {
      baseLayerUrl: Constants.SOCRATA_VISUALIZATION_FEATURE_MAP_DEFAULT_BASE_LAYER,
      baseLayerOpacity: 0.8,
      locateUser: true,
      panAndZoom: true
    }
  });

  return newVif;
};

// Serializes a vif and additional filters onto a jQuery element.
// If either one differs from the last time this function was called, calls the
// onChanged callback.
export function memoizeVif(
  $element: JQuery,
  vif: Vif | V1TableVif,
  additionalFilters: GlobalFilters,
  onChanged: () => void,
  parameterOverrides: GlobalParameterOverrides = {}
): void {
  const renderedVif = parseJsonOrEmpty($element.attr('data-rendered-vif'));
  const vifsAreNotEquivalent = !vifsAreEquivalent(renderedVif, vif);

  const renderedAdditionalFilters = parseJsonOrEmpty($element.attr('data-rendered-additional-filters'));
  const additionalFiltersAreNotEquivalent = !filtersAreEquivalent(
    renderedAdditionalFilters,
    additionalFilters
  );
  const renderedParameterOverrides = parseJsonOrEmpty($element.attr('data-rendered-parameter-overrides'));
  const parametersAreNotEquivalent = !_.isEqual(renderedParameterOverrides, parameterOverrides);

  if (vifsAreNotEquivalent || additionalFiltersAreNotEquivalent || parametersAreNotEquivalent) {
    const datasets = getVifSeriesDatasets(vif);
    $element.data('datasetUid', datasets[0]);
    $element.attr('data-rendered-vif', JSON.stringify(vif));
    $element.attr('data-rendered-additional-filters', JSON.stringify(additionalFilters));
    $element.attr('data-rendered-parameter-overrides', JSON.stringify(parameterOverrides));
    onChanged();
  }
}

export function prepareVifForRender(
  $element: JQuery,
  vif: Vif | V1TableVif,
  additionalFilters: GlobalFilters,
  onVifChanged: (vif: Vif) => void,
  parameterOverrides?: GlobalParameterOverrides
): void {
  memoizeVif(
    $element,
    vif,
    additionalFilters,
    (): void => {
      // The VIF might not have been migrated to the latest version yet.
      const migratedVif = migrateVif(vif);
      const vifWithFilters = applyAdditionalFiltersToVif(migratedVif, additionalFilters);
      const vifWithParameterOverrides = applyParameterOverridesToVif(vifWithFilters, parameterOverrides);
      onVifChanged(vifWithParameterOverrides);
    },
    parameterOverrides
  );
}

export const getDefaultVisualizationProps = () => ({
  displayFilterBar: true,
  drilldown: {
    emitDrilldownChange: false,
    handleVifUpdatesInternally: true
  }
});
