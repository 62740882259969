import { Series, Vif } from 'common/visualizations/vif';
import { get } from 'lodash';

import { getGroupByColumnName } from '../VifSelectors';

// Maps tags (grouping column values, column names, placeholders like (Other)) to colors.
// Has no fallback behavior.
export default class CustomPalette {
  series: Series;
  columnName: string;
  constructor(vif: Vif, seriesIndex: number) {
    const columnName = getGroupByColumnName(vif);

    this.series = vif.series[seriesIndex];
    this.columnName = columnName;
  }

  getColor = (value: string): string => {
    return (
      get(
        this.series,
        ['color', 'customPalette', this.columnName, value, 'color']
      )
    );
  };
}

