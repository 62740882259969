import { QueryAnalysisFailed, QueryCompilationFailed } from 'common/types/compiler';
import { Expr, TypedExpr } from 'common/types/soql';

export enum Tab {
  Code = 'code',
  Filter = 'filter',
  ColumnManager = 'column_manager',
  Join = 'join',
  Aggregate = 'aggregate',
  Search = 'search',
  Parameters = 'parameters'
}

export enum QueryStatus {
  QUERY_FAILURE = 'query_failure',
  QUERY_META_FAILURE = 'query_meta_failure',
  QUERY_META_IN_PROGRESS = 'query_meta_in_progress',
  QUERY_META_SUCCESS = 'query_meta_success',
  QUERY_SUCCESS = 'query_success'
}

export enum FilterType {
  WHERE = 'WHERE',
  HAVING = 'HAVING',
  EITHER = 'EITHER'
}

export interface EditableExpression<U = Expr, T = TypedExpr> {
  untyped: U;
  typed: T;
}

export interface UnEditableExpression<U = Expr> {
  untyped: U;
  error: QueryCompilationFailed;
}
export type Eexpr<U, T> = EditableExpression<U, T> | UnEditableExpression<U>;

export interface EditableExpressionNA<E = TypedExpr> {
  expr: E;
}
export interface UnEditableExpressionNA<E = TypedExpr> {
  expr: E;
  error: QueryAnalysisFailed;
}
export type EexprNA<E> = EditableExpressionNA<E> | UnEditableExpressionNA<E>;
