/**
 * Utilities around Lookup Tool Log functionality
 */

import _ from 'lodash';
import { FeatureFlags } from 'common/feature_flags';
import Environment from 'StorytellerEnvironment';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'serv... Remove this comment to see the full error message
import httpRequest from 'services/httpRequest';

// Used to "cache" previous filters and count.
declare global {
  interface Window {
    LOOKUP_TOOL_LOG_FILTERS: SoqlFilter[];
    LOOKUP_TOOL_LOG_COUNT: number;
  }
}

/**
 * Checks whether feature flag and configuration are enabled for Lookup Tool Log,
 * and the current story is the target for lookup tool log.
 */
export function shouldEnableLookupLog() {
  const { EDIT_MODE, STORY_UID } = Environment;
  const isNotEditMode = EDIT_MODE !== true;
  const isFeatureFlagEnabled = !!FeatureFlags.valueOrDefault('enable_lookup_log', false);
  // If "lookupLogStoryId" exists, the feature flag should be enabled. But doesn't hurt to double check.
  const configuredStoryId = window.socrata?.lookupLogStoryId;
  const isLogNotAttached = !window.socrata?.lookupLogAttached;
  const isConfigurationEnabled = configuredStoryId !== undefined;
  const isTargetStory = configuredStoryId === STORY_UID;

  return isNotEditMode && isFeatureFlagEnabled && isConfigurationEnabled && isLogNotAttached && isTargetStory;
}

export function markLookupLogAttached() {
  window.socrata.lookupLogAttached = true;
}

/**
 * Parses story data to find a global filter bar and get its dataset ID.
 */
export function getDatasetIdFromGlobalFilterBar() {
  const { STORY_DATA } = Environment;
  const datasetId: string | undefined = STORY_DATA?.dataSource?.dataSourceList?.[0]?.datasetUid;

  return datasetId;
}

/**
 * Sends an API request to log lookup tool usage.
 */
function logRequest(filters: SoqlFilter[], count: number) {
  const filtersAsString = filters
    .map((filter) => (filter as SoqlFilter).columns[0].fieldName || '')
    .filter((filter) => filter !== '')
    .join(',');
  if (filtersAsString === '') return;

  const { STORY_UID } = Environment;
  const url = `/stories/api/v1/stories/${STORY_UID}/lookup-log`;
  const data = {
    columns_filtered: filtersAsString,
    rows_returned: count
  };

  // Send request but ignore error.
  httpRequest('POST', url, { data }).catch(() => {});
}

const debouncedLogRequest = _.debounce(logRequest, 300, {
  leading: true,
  trailing: false
});

export function sendLookupToolLogRequest(filters: SoqlFilter[], rowGroupCols: any[], count: number) {
  // do not log if there is no filter applied
  if (filters.length === 0) return;

  // do not log if there is a group by applied
  if (rowGroupCols.length > 0) return;

  // compare filters and count with previous call
  // this prevents multiple logs when tabs are switched
  if (_.isEqual(filters, window.LOOKUP_TOOL_LOG_FILTERS) && count === window.LOOKUP_TOOL_LOG_COUNT) return;

  window.LOOKUP_TOOL_LOG_FILTERS = filters;
  window.LOOKUP_TOOL_LOG_COUNT = count;
  debouncedLogRequest(filters, count);
}
