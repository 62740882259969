/* Imports */
/* ============================================================================= */
import { isEmpty } from 'lodash';

import { GuidanceSummaryV2} from 'common/types/approvals';

import { WORKFLOWS, isWorkflowGuidance } from '.';





/* Method */
/* ============================================================================= */
/**
 * Verify that the provided guidance value is a GuidanceSummaryV2 object
 * @param guidance The value to test
 * @returns boolean
 */
export const isGuidanceSummary = (guidance: any): guidance is GuidanceSummaryV2 => {
  return (
    // Guidance is not empty
    !isEmpty(guidance) &&

    // Guidance has at least 1 WorkflowTargetAudience keyed attribute with a valid WorkflowGuidance value
    !!WORKFLOWS.find((workflow) => workflow in guidance && isWorkflowGuidance(guidance[workflow]))
  );
};

export default isGuidanceSummary;
