/* Imports */
/* ============================================================================= */
import { forEach, isFunction } from 'lodash';

import { BaseGuidanceSummaryV2Helper, WorkflowGuidanceMethodsObject } from 'common/core/approvals/Types';
import { WORKFLOWS } from '.';





/* Method */
/* ============================================================================= */
/**
 * Generates a WorkflowGuidanceMethodsObject Object by using the method name
 * provided in the `useMethod` param to extract the named method from each
 * workflowHelper within the guidanceHelper object.
 *
 * Difference between this function and `createWorkflowGuidanceMethodsObject`:
 *  - This function is for running the instance of a named method within each
 * workflowHelper against that workflow's data.
 *  - In contrast, the `createWorkflowGuidanceMethodsObject` function is used to run a single,
 * provided method against each workflow's data.
 * @param guidanceHelper A BaseGuidanceSummaryV2Helper object
 * @param useMethod A string indicating the key of a WorkflowGuidanceMethod within a WorkflowGuidanceHelper object
 * @returns A WorkflowGuidanceMethodsObject Object
 */
export const createWorkflowGuidanceHelperMethodsObject = <T = WorkflowGuidanceMethodsObject<any>>(
  guidanceHelper: BaseGuidanceSummaryV2Helper,
  useMethod: string
): T => {
  const response = {};

  forEach(WORKFLOWS, (workflow) => {
    const workflowHelper = guidanceHelper[workflow];

    if (workflowHelper && isFunction(workflowHelper[useMethod]))
      response[workflow] = workflowHelper[useMethod];
  });

  return response as T;
};

export default createWorkflowGuidanceHelperMethodsObject;
