/**
 * Custom assertion error class
 * @todo Use this for all assertion tests, to be sure the tests are not throwing other errors
 */
export class AssertionError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'AssertionError';
  }
}

type AssertType = (expression: boolean, message?: string) => asserts expression is true;

const assert: AssertType = (expression: boolean, message?: string): asserts expression is true => {
  if (expression === false) {
    throw new AssertionError(message);
  }
};

export default assert;
