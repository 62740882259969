import { StorytellerState } from 'store/StorytellerReduxStore';
import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import { isComponentTypeMovable } from 'lib/StoryHelper';
import { ComponentType } from 'types';
import { storyStore } from 'editor/stores/StoryStore';

export const selectors = {
  isActionComponentStoreActive: (state: StorytellerState) => state.actionComponentReducer.isUserChoosingTargetComponent,
  getActionComponentActionType: (state: StorytellerState) => state.actionComponentReducer.actionType,
  getSourceBlockId: (state: StorytellerState) => state.actionComponentReducer.sourceBlockId,
  getSourceComponentIndex: (state: StorytellerState) => state.actionComponentReducer.sourceComponentIndex,
  isUserChoosingMoveDestination: (state: StorytellerState) => isUserChoosingMoveDestination(state),
  isComponentBeingMoved: (blockId: string, componentIndex: number, state: StorytellerState) => isComponentBeingMoved(blockId, componentIndex, state),
  isComponentValidMoveDestination: (blockId: string, componentIndex: number, state: StorytellerState) => isComponentValidMoveDestination(blockId, componentIndex, state),
};

const isUserChoosingMoveDestination = (state: StorytellerState): boolean => {
  return selectors.isActionComponentStoreActive(state) && selectors.getActionComponentActionType(state) === COMPONENT_ACTION_TYPES.MOVE;
};

const isComponentBeingMoved = (
  blockId: string,
  componentIndex: number,
  state: StorytellerState
): boolean => {
  return blockId === state.actionComponentReducer.sourceBlockId && componentIndex === state.actionComponentReducer.sourceComponentIndex;
};

const isComponentValidMoveDestination = (
  blockId: string,
  componentIndex: number,
  state: StorytellerState
): boolean => {
  const { sourceBlockId, sourceComponentIndex } = state.actionComponentReducer;
  const sourceMeasureOnly = sourceBlockId && storyStore.isBlockRestrictedToMeasureCardsOnly(sourceBlockId);
  const destMeasureOnly = blockId && storyStore.isBlockRestrictedToMeasureCardsOnly(blockId);

  const sourceComponent =
    sourceBlockId && storyStore.getBlockComponentAtIndex(sourceBlockId, sourceComponentIndex);

  const destComponent = blockId && storyStore.getBlockComponentAtIndex(blockId, componentIndex);

  if (isComponentBeingMoved(blockId, componentIndex, state)) {
    return false;
  }

  // Dest is valid if what's in dest is movable in the first place, AND
  // either the pair of components can accept all movable types, OR the types
  // are acceptable to both components, OR we're swapping in an empty block.
  const typeOk = (type?: ComponentType): boolean => type === 'measure.card' || type === 'assetSelector';
  const movingAcceptableRestricted =
    (!sourceComponent || typeOk(sourceComponent.type)) && (!destComponent || typeOk(destComponent.type));
  const unrestricted = !destMeasureOnly && !sourceMeasureOnly;
  return isComponentTypeMovable(destComponent.type) && (unrestricted || movingAcceptableRestricted);
};
