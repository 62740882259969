import _ from 'lodash';

import Actions from 'Actions';
import Store from 'Store';
/* Intermediate Redux Store */
import { configureStore } from '@reduxjs/toolkit';
import richTextEditorColorReducer, {
  updateStoryColors,
} from '../../store/reducers/RichTextEditorColorReducer';

const richTextEditorColorReduxStore = configureStore({
  reducer: richTextEditorColorReducer
});

export var richTextEditorColorStore = new RichTextEditorColorStore();
export default function RichTextEditorColorStore(dispatcher) {
  _.extend(this, new Store(dispatcher));

  var self = this;
  this.register(function(payload) {
    var action = payload.action;

    switch (action) {
      case Actions.STORY_CREATE:
      case Actions.STORY_UPDATE_THEME:
        richTextEditorColorReduxStore.dispatch(updateStoryColors());
        self._emitChange();
        break;

      default:
        break;
    }
  });

  this.getColors = function() {
    const {
      defaultColors,
      savedCustomColors,
      activeCustomColor
    } = richTextEditorColorReduxStore.getState();
    return {
      defaultColors,
      savedCustomColors,
      activeCustomColor
    };
  };

}
