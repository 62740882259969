import { cloneDeep, get, set, unset } from 'lodash';
import { ColumnState } from '@ag-grid-community/core';
import { Hierarchy, Vif } from 'common/visualizations/vif';
import { ViewColumn } from 'common/types/viewColumn';
import { GROUP_COLUMN_PREFIX } from 'common/visualizations/views/agGridReact/Constants';

interface AdditionalColumnProps {
  [key: string]: {
    width?: number;
    hide?: boolean | null;
  };
}

export function migrateVif6ToVif7(vifToMigrate: any): Vif {
  const migratedVif = cloneDeep(vifToMigrate);

  const agGridColumnState = get(migratedVif, 'configuration.agGridColumnState');
  const columns = get(migratedVif, 'series[0].dataSource.dimension.columns');
  const hierarchies = get(migratedVif, 'series[0].dataSource.hierarchies', []);

  if (agGridColumnState && columns) {
    const { newColumns, singleAutoColumnWidth } = migrateAgGridColumnState(agGridColumnState, columns);
    set(migratedVif, 'series[0].dataSource.dimension.columns', newColumns);

    // AG Grid Column State does not distinguish the Single Auto Column Group width
    // between different tabs (hierarchy), so set the same width for all hierarchies.
    if (singleAutoColumnWidth && hierarchies.length > 0) {
      hierarchies.forEach((hierarchy: Hierarchy) => {
        set(hierarchy, 'singleAutoColumnWidth', singleAutoColumnWidth);
      });
    }

    // Remove the old AG Grid Column State
    unset(migratedVif, 'configuration.agGridColumnState');
  }

  migratedVif.format.version = 7;
  return migratedVif;
}

function migrateAgGridColumnState(
  agGridColumnState: ColumnState[],
  columns: ViewColumn[]
): {
  newColumns: ViewColumn[];
  singleAutoColumnWidth: number | undefined;
} {
  const valuesToExtend = parseColumnState(agGridColumnState);

  // Prefer to use group column's width if it exists (for non-indented layout)
  // They are identified by having GROUP_COLUMN_PREFIX.
  Object.keys(valuesToExtend).forEach((key) => {
    if (key.includes(GROUP_COLUMN_PREFIX) && key !== GROUP_COLUMN_PREFIX) {
      const originalColumnName = key.replace(`${GROUP_COLUMN_PREFIX}-`, '');
      if (valuesToExtend.hasOwnProperty(originalColumnName)) {
        valuesToExtend[originalColumnName].width = valuesToExtend[key].width;
      } else {
        // I don't think this is possible, but just in case.
        // Grouped column should be hidden
        valuesToExtend[originalColumnName] = { width: valuesToExtend[key].width, hide: true };
      }
    }
  });

  const newColumns = columns.map((column: ViewColumn) => {
    const columnState = valuesToExtend[column.fieldName];
    if (columnState) {
      const newColumn = cloneDeep(column);
      // hide can be null in AG Grid Column State, so coerce to a boolean value
      newColumn.hide = !!columnState.hide;
      newColumn.width = columnState.width;
      return newColumn;
    }
    return column;
  });

  // Extract the single column group width (for indented layout) if applicable.
  const singleAutoColumnWidth = valuesToExtend[GROUP_COLUMN_PREFIX]?.width;

  return { newColumns, singleAutoColumnWidth };
}

function parseColumnState(columnState: ColumnState[]): AdditionalColumnProps {
  const columnStateByKey = columnState.reduce((acc, column) => {
    const { colId, hide, width } = column;
    acc[colId] = { hide, width };
    return acc;
  }, {} as AdditionalColumnProps);

  return columnStateByKey;
}
