import assertHasProperty from './assertHasProperty';
import assertIsOneOfTypes from './assertIsOneOfTypes';
import assert from './assert';

/**
 * Asserts that the given collection is of the expected length.
 *
 * @param {Array | Array-like} collection - The collection to check.
 * @param {Number} expectedLength - The expected length.
 */
export default (collection, expectedLength) => {
  assertHasProperty(collection, 'length');
  assertIsOneOfTypes(expectedLength, 'number');
  assert(
    collection.length === expectedLength,
    `Expected '${collection}' to have length ${expectedLength} was ${collection.length}.`
  );
};
