import React from 'react';
import { agGridDataFormatter, shouldRenderAsHtml } from './helpers/TableColumnFormatting';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CustomAgGridContext } from 'common/types/agGrid/context';

export interface IValueRendererProps extends ICellRendererParams<any, any, Partial<CustomAgGridContext>> {
  domain: string;
  datasetUid: string;
  wrapWithDivElement: boolean;
  forExport?: boolean;
}

export const ValueRenderer = (params: IValueRendererProps) => {
  const ctx = params.context;
  const columnMetadata = ctx.columnMetadata ?? [];
  const columnFormats = ctx.columnFormats ?? {};
  const nonStandardAggregations = ctx.nonStandardAggregations ?? [];

  const renderTextAsHtml = (text: string) => {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
  };

  const formattedValue = agGridDataFormatter(
    params.domain,
    params.datasetUid,
    columnMetadata,
    nonStandardAggregations,
    params,
    columnFormats
  );

  // Currently, we do not need to export values enclosed in div elements in the Excel export.
  if (params.forExport) {
    return formattedValue;
  }

  return params.wrapWithDivElement || shouldRenderAsHtml(params.node, columnMetadata)
    ? renderTextAsHtml(formattedValue)
    : formattedValue;
};
