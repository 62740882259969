import _ from 'lodash';
import { fetchJson } from 'common/http';
import { getApiToken, TOKEN_NAMES } from 'common/api_tokens';

// Given a search string, it geocodes using mapbox API and returns back matching
// results in pick list options format.
export const geoSearch = (searchString, searchOptions) => {
  const mapboxAccessToken = getApiToken(TOKEN_NAMES.MAPBOX_ACCESS_TOKEN);
  const encodedSearchString = encodeURIComponent(searchString);
  const geoQueryUrl = 'https://a.tiles.mapbox.com/geocoding/v5/mapbox.places/';
  const boundary = _.get(searchOptions, 'boundary');
  let queryEndpoint = `${geoQueryUrl}${encodedSearchString}.json?access_token=${mapboxAccessToken}`;

  if (!_.isNil(boundary)) {
    queryEndpoint += `&bbox=${boundary}`;
  }

  return fetchJson(queryEndpoint, { method: 'GET' }).then(formatMapboxGeocodeResponse);
};

// Converts mapbox geocode response to picklist options format.
// Mapbox Geocode Sample Response:
// {
//   "type":"FeatureCollection",
//   "query":["coi"],
//   "features":[
//     {
//       "id": "place.10456850936703450",
//       "type":"Feature",
//       "place_type": ["place"],
//       "relevance": 1,
//       "properties": {"wikidata":"Q3348861"},
//       "text":"Coimbra",
//       "place_name":"Coimbra, Coimbra, Portugal",
//       "bbox":[-8.591664,40.098923,-8.312903,40.352013],
//       "center":[-8.45229,40.2255],
//       "geometry":{  "type": "Point", "coordinates": [-8.45229,40.2255] },
//       "context":[...]},
//     }
//   ],
//   ...
// }
// Additional details can be found in the Mapbox API here:
// https://www.mapbox.com/api-documentation/#response-object
const formatMapboxGeocodeResponse = (response) => {
  const features = _.get(response, 'features', []);

  return features.map((feature) => {
    return {
      title: feature.place_name,
      value: feature.place_name,
      geometry: feature.geometry
    };
  });
};
