import React, { Component } from 'react';
import { option } from 'ts-option';

import I18n from 'common/i18n';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import Button, { VARIANTS } from 'common/components/Button';
import { AssetType } from 'common/types/view';

import './index.scss';

const scope = 'shared.components.copy_asset_modal';

interface CopyAssetModalProps {
  assetTitle: string;
  basedOnName?: string;
  assetType: AssetType;
  onCopy: (title: string) => void;
  onDismiss: () => void;
}

interface CopyAssetModalState {
  title: string;
}

class CopyAssetModal extends Component<CopyAssetModalProps, CopyAssetModalState> {
  constructor(props: CopyAssetModalProps) {
    super(props);
    this.state = { title: I18n.t('new_asset_title', { scope, assetTitle: props.assetTitle }) };
  }

  render() {
    const { onCopy, onDismiss, assetType, basedOnName } = this.props;
    const { title } = this.state;
    const setTitle = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ title: e.target.value });
    const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onCopy(title);
    };
    const header = assetType === AssetType.Filter ? I18n.t('view_header', { scope }) : I18n.t('header', { scope });
    const basedOn = option(basedOnName).map(name => <div className="caption based-on-caption" key="based-on-caption">{I18n.t('based_on', { scope, name })}</div>);

    return (
      <Modal className="copy-asset-modal-container" onDismiss={onDismiss}>
        <ModalHeader onDismiss={onDismiss}>
        </ModalHeader>
        <ModalContent>
          <h1>{header}</h1>
          <form onSubmit={onSubmit}>
            <div className="input-container">
              <label htmlFor="copy-asset-modal-title">{I18n.t('asset_name', { scope })}</label>
              <input
                id="copy-asset-modal-title"
                type="text"
                className="text-input copy-asset-modal-title"
                value={title}
                onChange={setTitle}
              />
              {basedOn.isDefined && basedOn.get}
              <div className="copy-asset-modal-help-text">{I18n.t('copy_asset_help_text', { scope })}</div>
            </div>
          </form>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onDismiss} className="copy-asset-modal-cancel">
            {I18n.t('cancel_button', { scope })}
          </Button>
          <Button onClick={onSubmit} className="copy-asset-modal-copy" variant={VARIANTS.PRIMARY}>
            {I18n.t('copy_button', { scope })}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CopyAssetModal;
