import type { AccessLevel } from 'common/types/view';
import { AccessLevelName, AccessLevelVersion } from 'common/types/view';

/** How many milliseconds to wait to send searches to the users catalog when typing in the search box */
export const CATALOG_SEARCH_DEBOUNCE_MILLISECONDS = 250;

/**
 * The access manager can be displayed in multiple different "modes".
 * Each mode is essentially a different modal, but they all share common redux state.
 */
export enum MODES {
  /**
   * Changing the "audience" of an asset.
   * This is who can see the _published_ version of the asset.
   *
   * When strict_permissions is off, the only options are "Private" and "Public".
   *
   * When strict_permissions is on, an additional option of "Internal" (a.k.a. "Site") is shown,
   * along with a list of "published viewers" that you can add users to.
   */
  CHANGE_AUDIENCE = 'change_audience',

  /**
   * Changing the "true owner" of an asset.
   * This is basically just a users search box that lets you select a user.
   */
  CHANGE_OWNER = 'change_owner',

  /**
   * Sometimes, when you change the audience of an asset, you can horribly break other assets that use it.
   * This shows a nice warning about this :)
   */
  CONFIRM_DESTRUCTIVE_AUDIENCE_CHANGE = 'confirm_destructive_audience_change',

  /**
   * Lets you add "collaborators" to an asset.
   *
   * When strict_permissions is off, you can choose between viewer, contributor, and co-owner.
   * When strict_permissions is on, you can only add co-owners.
   */
  MANAGE_COLLABORATORS = 'manage_collaborators',

  /**
   * Manage permissions for a gateway plugin.
   */
  MANAGE_PLUGIN = 'manage_plugin',

  /**
   * Publish an asset.
   */
  PUBLISH = 'publish'
}

/**
 * Represents different types of user
 * NOTE: This is basically only used to "augment" results we get back from the catalog!
 */
export const USER_TYPES = {
  /** A normal user who can login to the site and use the API */
  INTERACTIVE: 'interactive',

  /** A special type of user that is actually a collection of users :) */
  TEAM: 'team'
};

/**
 * Represents the "true owner" of the asset.
 */
export const OWNER_ACCESS_LEVEL: AccessLevel = {
  name: AccessLevelName.CurrentOwner,
  version: AccessLevelVersion.All
};

/**
 * Represents a view that can see draft and published versions of the asset.
 * NOTE: This only exists when `strict_permissions` is off
 */
export const ALL_VIEWER_ACCESS_LEVEL: AccessLevel = {
  name: AccessLevelName.Viewer,
  version: AccessLevelVersion.All
};

/**
 * Represents a "contributor" who can make some edits on the asset
 * NOTE: This only exists when `strict_permissions` is off
 */
export const CONTRIBUTOR_ACCESS_LEVEL: AccessLevel = {
  name: AccessLevelName.Contributor,
  version: AccessLevelVersion.All
};

/**
 * A co-owner of the asset who can pretty much do anything but delete it and transfer ownership.
 * NOTE: When `strict_permissions` is on, this is referred to as "collaborator" in the UI
 */
export const CO_OWNER_ACCESS_LEVEL: AccessLevel = {
  name: AccessLevelName.Owner,
  version: AccessLevelVersion.All
};

/**
 * A user who can only see the published version of an asset.
 * NOTE: This only exists when `strict_permissions` is on.
 * Otherwise, only "viewer all" is used
 */
export const PUBLISHED_VIEWER_ACCESS_LEVEL: AccessLevel = {
  name: AccessLevelName.Viewer,
  version: AccessLevelVersion.Published
};

export const FEATURE_FLAGS = {
  STRICT_PERMISSIONS: 'strict_permissions',
  USAID_FEATURES_ENABLED: 'usaid_features_enabled',
  ENABLE_GRANULAR_RIGHTS: 'enable_granular_rights',
  ENABLE_TEAMS: 'enable_teams'
};
