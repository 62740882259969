import _ from 'lodash';
import { FeatureFlags } from 'common/feature_flags';
import Environment from 'StorytellerEnvironment';
import { Layout } from 'react-grid-layout';

/**
 * isLayoutEqual: compares the layout of two different components.
 * We only care about the x, y, h, and w of the layout. All other
 * information is irrelevant.
 */
export const isLayoutEqual = (layout1: Layout, layout2: Layout): boolean => {
  const oldLayout = _.pick(layout1, ['h', 'w', 'x', 'y']);

  const newLayout = _.pick(layout2, ['h', 'w', 'x', 'y']);

  return _.isEqual(oldLayout, newLayout);
};

export const sharedFlexRGLProps = {
  className: 'grid-layout',
  cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
  rowHeight: 30,
  containerPadding: [20, 0],
  margin: [20, 0]
};

export const isFlexibleStory = (): boolean => {
  return FeatureFlags.value('enable_flexible_story_layout') && Environment.STORY_DATA?.isFlexibleStory;
};
