import { isEqual, get, find, includes, isEmpty, omit } from 'lodash';

import {
  FILTER_FUNCTION,
  JOIN_FUNCTION,
  OPERATOR,
  BinaryOperator,
  SoqlFilter
} from 'common/components/FilterBar/SoqlFilter';
import { FILTER_TYPES } from 'common/dates';
import {
  FilterType,
  NumberFilterType,
  TextFilterType,
  AgFilter,
  AgColumnFilter,
  AgMultiFilter,
  AgCombineFilter,
  SimpleAgFilter
} from 'common/types/agGrid/filters';
import { Option, none, option, some } from 'ts-option';
import SoqlDataProvider from '../dataProviders/SoqlDataProvider';
import { Vif } from '../vif';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';
import { View } from 'common/types/view';
import {
  CALENDAR_DATE_COLUMN_TYPE,
  DATE_COLUMN_TYPE,
  NUMBER_COLUMN_TYPE
} from 'common/authoring_workflow/constants';
import { FilterBarColumn } from 'common/components/FilterBar/types';
import { getColumnsForFilter } from 'common/components/FilterBar/lib/Filters/BaseFilter';
import DataTypeFormatter from 'common/DataTypeFormatter';
import MetadataProvider from '../dataProviders/MetadataProvider';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { FetchedRawResult } from 'common/components/FilterBar/lib/Filters/TextFilter';

export const getFilterTypeFromColumnMetadata = (columnDataType: string) => {
  switch (columnDataType) {
    case 'number':
      return 'agNumberColumnFilter';
    case 'text':
      return 'agTextColumnFilter';
    case 'date':
    case 'fixed_timestamp':
    case 'calendar_date':
    case 'floating_timestamp':
      return 'agDateColumnFilter';
    case 'checkbox':
      return 'agSetColumnFilter';
  }
};

function getDistinctValuesForFilter(datasetUid: string, fieldName: string) {
  const dataProvider = new SoqlDataProvider({ datasetUid }, true);
  const alias = `${fieldName}_distinct`;

  return dataProvider
    .getDistinctValuesInColumn(datasetUid, fieldName, alias, Number.MAX_SAFE_INTEGER)
    .then((distinctValues: FetchedRawResult[]) => {
      const values = distinctValues.map((result: FetchedRawResult) => {
        return result[alias];
      });
      if (values[values.length - 1] === undefined) {
        // make sure the 'Blanks' option is at the top, where its discoverable
        return [null, ...values];
      }
      return values;
    });
}

export const getAgColFilterObject = ({
  columnFieldName,
  datasetUid,
  dataTypeName,
  useSetFilters
}: {
  columnFieldName: string;
  datasetUid: string;
  dataTypeName: string;
  useSetFilters: boolean;
}) => {
  switch (dataTypeName) {
    case 'checkbox':
      return {
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [null, 'True', 'False']
        }
      };
    case 'text':
    case 'number':
    case 'date':
    case 'fixed_timestamp':
    case 'calendar_date':
    case 'floating_timestamp':
      if (useSetFilters) {
        return {
          filter: 'agMultiColumnFilter',
          filterParams: {
            filters: [
              {
                filter: getFilterTypeFromColumnMetadata(dataTypeName)
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  suppressSorting: true, // getSetFiltersValues should provide the values pre-sorted
                  values: (params: any) => {
                    getDistinctValuesForFilter(datasetUid, columnFieldName).then((r: any) => {
                      params.success(r);
                    });
                  }
                }
              }
            ]
          }
        };
      } else {
        return { filter: getFilterTypeFromColumnMetadata(dataTypeName) };
      }
  }
};

const agGridOpToVifOp = (agGridOp: NumberFilterType) => {
  switch (agGridOp) {
    case NumberFilterType.EQUALS:
      return FILTER_FUNCTION.EQUALS;
    case NumberFilterType.NOT_EQUAL:
      return FILTER_FUNCTION.NOT_EQUAL;
    case NumberFilterType.LESS_THAN:
      return FILTER_FUNCTION.LESS_THAN;
    case NumberFilterType.LESS_THAN_OR_EQUAL:
      return FILTER_FUNCTION.LESS_THAN_EQUAL_TO;
    case NumberFilterType.GREATER_THAN:
      return FILTER_FUNCTION.GREATER_THAN;
    case NumberFilterType.GREATER_THAN_OR_EQUAL:
      return FILTER_FUNCTION.GREATER_THAN_EQUAL_TO;
    case NumberFilterType.IN_RANGE:
      return FILTER_FUNCTION.RANGE_EXCLUSIVE;
    default:
      throw Error(`Received unimplemented/unexpected agGrid filter function: ${agGridOp}`);
  }
};

const agGridTextOpToVifOp = (agGridOp: TextFilterType) => {
  switch (agGridOp) {
    case TextFilterType.CONTAINS:
      return OPERATOR.CONTAINS;
    case TextFilterType.NOT_CONTAINS:
      return OPERATOR.DOES_NOT_CONTAIN;
    case TextFilterType.EQUALS:
      return OPERATOR.EQUALS;
    case TextFilterType.NOT_EQUAL:
      return OPERATOR.NOT_EQUAL;
    case TextFilterType.STARTS_WITH:
      return OPERATOR.STARTS_WITH;
    case TextFilterType.ENDS_WITH:
      return OPERATOR.ENDS_WITH;
    default:
      throw Error(`Received unimplemented/unexpected agGrid filter function: ${agGridOp}`);
  }
};

function* setMinus(A: Set<any>, B: Set<any>) {
  const setA = new Set(A);
  const setB = new Set(B);

  for (const v of setB.values()) {
    if (!setA.delete(v)) {
      yield v;
    }
  }

  for (const v of setA.values()) {
    yield v;
  }
}

const simpleToSoqlFilter = (
  innerFilter: SimpleAgFilter,
  columnName: string,
  allSetValues: Set<string | null>,
  datasetUid: string
): Option<SoqlFilter> => {
  return option(simpleToSoqlFilterX(innerFilter, columnName, allSetValues, datasetUid));
};

const simpleToSoqlFilterX = (
  innerFilter: SimpleAgFilter,
  columnName: string,
  allSetValues: Set<string | null>,
  datasetUid: string
): SoqlFilter | null => {
  // 'blank' and 'not blank' are the same regardless of column type,
  // but also special in that the 'arguments' is really the function
  // so this catches those cases
  if ('type' in innerFilter) {
    switch (innerFilter.type) {
      case NumberFilterType.BLANK:
        return {
          function: FILTER_FUNCTION.BINARY_OPERATOR,
          ...getColumnsForFilter(columnName, datasetUid),
          arguments: [
            {
              operator: OPERATOR.NULL
            }
          ]
        } as BinaryOperator;
      case NumberFilterType.NOT_BLANK:
        return {
          function: FILTER_FUNCTION.BINARY_OPERATOR,
          ...getColumnsForFilter(columnName, datasetUid),
          arguments: [
            {
              operator: OPERATOR.NOT_NULL
            }
          ]
        } as BinaryOperator;
    }
  }

  switch (innerFilter.filterType) {
    case FilterType.NUMBER:
      if (Number.isNaN(innerFilter.filter) || Number.isNaN(innerFilter.filterTo)) {
        // happens when user types '.' into the filter input
        return null;
      } else if (innerFilter.type == NumberFilterType.IN_RANGE) {
        return {
          ...getColumnsForFilter(columnName, datasetUid),
          function: FILTER_FUNCTION.RANGE_EXCLUSIVE,
          arguments: {
            start: `${innerFilter.filter}`,
            end: `${innerFilter.filterTo}`,
            includeNullValues: false
          }
        };
      } else {
        return {
          ...getColumnsForFilter(columnName, datasetUid),
          function: agGridOpToVifOp(innerFilter.type),
          arguments: {
            value: `${innerFilter.filter}`,
            includeNullValues: false
          }
        };
      }
    case FilterType.TEXT:
      return {
        ...getColumnsForFilter(columnName, datasetUid),
        function: FILTER_FUNCTION.BINARY_OPERATOR,
        arguments: [
          {
            operator: agGridTextOpToVifOp(innerFilter.type),
            operand: `${innerFilter.filter}`
          }
        ]
      } as BinaryOperator;
    case FilterType.DATE:
      if (innerFilter.type == NumberFilterType.IN_RANGE) {
        return {
          ...getColumnsForFilter(columnName, datasetUid),
          function: FILTER_FUNCTION.TIME_RANGE,
          arguments: {
            calendarDateFilterType: FILTER_TYPES.RANGE,
            start: innerFilter.dateFrom,
            end: innerFilter.dateTo
          }
        };
      } else {
        return {
          ...getColumnsForFilter(columnName, datasetUid),
          function: agGridOpToVifOp(innerFilter.type),
          arguments: {
            value: `${innerFilter.dateFrom.split(' ')[0]}`,
            includeNullValues: false
          }
        };
      }
    case FilterType.SET:
      if (innerFilter.values.length == 0) {
        // this is a hack to create a filter condition that's impossible to be true
        // `IN ()` is considered invalid in SoQL and
        // Vif Filter type requires the filter to reference a column
        // so we check `column` = NULL, which resolves to NULL, which is falsey
        // (note that is this is different from `column` IS NULL which uses OPERATOR.NULL and may be true or false)
        return {
          ...getColumnsForFilter(columnName, datasetUid),
          function: FILTER_FUNCTION.BINARY_OPERATOR,
          arguments: [
            {
              operator: OPERATOR.EQUALS,
              operand: null
            }
          ]
        } as BinaryOperator;
      }
      // we don't grab allSetValues for the boolean filter, since its always 3 options
      // and the structure of the filter object is different
      const isBooleanSetFilter = allSetValues.size === 0;
      const selectedValues = new Set(innerFilter.values);
      const excludedValues = new Set(Array.from(setMinus(allSetValues, selectedValues)));

      // we're including every possible value, therefore: no filter
      if (!isBooleanSetFilter && excludedValues.size === 0) return null;
      const nullInSet = isBooleanSetFilter || allSetValues.has(null);
      if (isBooleanSetFilter || excludedValues.size > selectedValues.size) {
        return constructSetFilter(columnName, selectedValues, false, nullInSet, datasetUid);
      } else {
        return constructSetFilter(columnName, excludedValues, true, nullInSet, datasetUid);
      }
  }
};

// col NOT IN (x, y, z, null) => col NOT IN (x, y, z) -- just remove the null from the list
// col NOT IN (x, y, z) => col NOT IN (x, y, z) OR col is null

// col IN (x, y, z, null) => col IN (x, y, z) OR col is null
// col IN (x, y, z) => col IN (x, y, z)  -- just remove the null from the list
const constructSetFilter = (
  columnName: string,
  filterValues: Set<string | null>,
  useNotIn: boolean,
  possibleValuesHadNull: boolean,
  datasetUid: string
): SoqlFilter | null => {
  const filterValuesHadNull = filterValues.has(null);
  const tryingToIncludeNull = (!useNotIn && filterValuesHadNull) || (useNotIn && !filterValuesHadNull);
  filterValues.delete(null);
  const setFilter: SoqlFilter = {
    ...getColumnsForFilter(columnName, datasetUid),
    function: useNotIn ? FILTER_FUNCTION.NOT_IN : FILTER_FUNCTION.IN,
    arguments: Array.from(filterValues)
  };
  const includeNullFilter: SoqlFilter = {
    ...getColumnsForFilter(columnName, datasetUid),
    function: FILTER_FUNCTION.BINARY_OPERATOR,
    arguments: [{ operator: OPERATOR.NULL }]
  } as BinaryOperator;

  // no nulls involved; no modifications needed
  if (!possibleValuesHadNull) return setFilter;
  // the *only* thing we're trying to include is null
  if (filterValues.size === 0 && tryingToIncludeNull) return includeNullFilter;
  // the *only* thing we're trying to exclude is null
  if (filterValues.size === 0 && !tryingToIncludeNull) {
    return {
      ...getColumnsForFilter(columnName, datasetUid),
      function: FILTER_FUNCTION.BINARY_OPERATOR,
      arguments: [{ operator: OPERATOR.NOT_NULL }]
    } as BinaryOperator;
  }
  // 'NOT IN' and 'IN' filters naturally exclude null from results
  if (!tryingToIncludeNull) return setFilter;

  // include or exclude some list, but also plus nulls please
  return {
    ...getColumnsForFilter(columnName, datasetUid),
    function: JOIN_FUNCTION.OR,
    arguments: [setFilter, includeNullFilter]
  };
};

const toSoqlFilter = (
  innerFilter: AgFilter,
  colName: string,
  allSetValues: Set<string | null>,
  datasetUid: string
): Option<SoqlFilter> => {
  if (innerFilter.filterType == FilterType.MULTI) {
    const multiFilter = innerFilter as AgMultiFilter;
    const nonNullInnerFilters: AgFilter[] = multiFilter.filterModels.filter((f): f is AgFilter => f !== null);
    if (nonNullInnerFilters.length === 1) {
      return toSoqlFilter(nonNullInnerFilters[0], colName, allSetValues, datasetUid);
    } else {
      const args: SoqlFilter[] = nonNullInnerFilters
        .map((f) => toSoqlFilter(f, colName, allSetValues, datasetUid))
        .filter((f) => f.nonEmpty)
        .map((f) => f.get);

      return args.length > 0
        ? some({
            ...getColumnsForFilter(colName, datasetUid),
            function: JOIN_FUNCTION.AND,
            arguments: args
          })
        : none;
    }
  } else if ('operator' in innerFilter) {
    const combineFilter = innerFilter as AgCombineFilter;
    const args: SoqlFilter[] = [
      toSoqlFilter(combineFilter.conditions[0], colName, allSetValues, datasetUid),
      toSoqlFilter(combineFilter.conditions[1], colName, allSetValues, datasetUid)
    ]
      .filter((f) => f.nonEmpty)
      .map((f) => f.get);

    return args.length > 0
      ? some({
          ...getColumnsForFilter(colName, datasetUid),
          function: combineFilter.operator,
          arguments: args
        })
      : none;
  } else {
    return simpleToSoqlFilter(innerFilter as SimpleAgFilter, colName, allSetValues, datasetUid);
  }
};

export const agGridFilterToVifFilter = (
  agGridFilter: AgColumnFilter,
  agGridApi: any,
  datasetUid: string
): SoqlFilter[] => {
  return Object.entries(agGridFilter)
    .map((filter) => {
      const [colName, innerFilter] = filter;
      const filterInstance = agGridApi.getColumnFilterInstance(colName);

      // must be a multi filter, where the set filter position is non-null
      const allSetValues =
        filterInstance.filters && filterInstance.filters.length > 1 && filterInstance.filters[1]
          ? filterInstance.filters[1].getValues()
          : [];
      return toSoqlFilter(innerFilter, colName, new Set(allSetValues), datasetUid);
    })
    .filter((f) => f.nonEmpty)
    .map((f) => f.get);
};

//To get datasetMetaData with columnStat in Visualization
export const getMetaDataWithColumnStat = (
  datasetMetadata: View,
  datasetMetadataWithColumnStats: FilterBarColumn[]
) => {
  const metaDataWithColumnStat = datasetMetadata.columns.map((column: any, index: number) => ({
    ...column,
    ...datasetMetadataWithColumnStats[index]
  }));
  const metaData = { ...datasetMetadata, columns: metaDataWithColumnStat };
  return metaData;
};

// To get columns with columnStat in AX
export const getSelectedColumnStat = async (vif: Vif, columns: ViewColumn[], fieldName: string) => {
  const datasetUid = get(vif, 'series[0].dataSource.datasetUid');
  const getColumnStat = async (): Promise<{
    columnStats: any[];
    datasetMetaDataWithColumnStat: any;
  }> => {
    try {
      const dataProviderConfig = {
        datasetUid: datasetUid,
        domain: get(vif, 'series[0].dataSource.domain'),
        readFromNbe: get(vif, 'series[0].dataSource.readFromNbe', true)
      };
      const soqlDataProvider = new SoqlDataProvider(dataProviderConfig, true);
      const columnStats = await soqlDataProvider.getColumnStats(columns, datasetUid);
      const metadataProvider = new MetadataProvider(dataProviderConfig, true);
      const datasetMetadata = await metadataProvider.getDatasetMetadata();
      const datasetMetadataWithColumnStats = await soqlDataProvider.getColumnStats(
        datasetMetadata.columns,
        datasetUid
      );
      const datasetMetaDataWithColumnStat = getMetaDataWithColumnStat(
        datasetMetadata,
        datasetMetadataWithColumnStats
      );
      return { columnStats, datasetMetaDataWithColumnStat } as const;
    } catch (error) {
      return { columnStats: [], datasetMetaDataWithColumnStat: [] };
    }
  };
  const matchedColumn = find(columns, { fieldName: fieldName });
  const matchedDataType = get(matchedColumn, 'dataTypeName', '');
  const isDateColumn = includes([CALENDAR_DATE_COLUMN_TYPE, DATE_COLUMN_TYPE], matchedDataType);
  const isNumberColumn = isEqual(matchedDataType, NUMBER_COLUMN_TYPE);
  let columnStats: any[] = [];
  let metadata = [];
  if (isDateColumn || isNumberColumn) {
    const { columnStats: stats, datasetMetaDataWithColumnStat } = await getColumnStat();
    columnStats = stats;
    metadata = datasetMetaDataWithColumnStat.columns;
  }
  const displayedColumnStat = columns.map((column: ViewColumn, index: number) => ({
    ...column,
    ...columnStats[index]
  }));
  const updatedColumn = find(displayedColumnStat, { fieldName: fieldName });
  const columnMin = get(updatedColumn, 'rangeMin', '');
  const columnMax = get(updatedColumn, 'rangeMax', '');
  const result = updatedColumn ? { isDateColumn, columnMin, columnMax, metadata: null as any } : {};

  const updatedMetadata = find(metadata, { fieldName: fieldName });
  if (updatedMetadata) result.metadata = updatedMetadata;

  return result;
};

export const getDefaultColumnFormat = (matchedColumn: ViewColumn) => {
  if (isEmpty(matchedColumn)) {
    return {};
  }
  const format: ColumnFormat = {};
  const formatPrecisionStyle = get(matchedColumn, 'format.precisionStyle', '');
  const formatPercentScale = get(matchedColumn, 'format.percentScale');
  const formatPrecision = get(matchedColumn, 'format.precision');
  const view = get(matchedColumn, 'format.view');

  format.align = DataTypeFormatter.getCellAlignment(matchedColumn);

  if (formatPrecisionStyle) {
    format.precisionStyle = formatPrecisionStyle;
  }

  if (formatPercentScale) {
    format.percentScale = formatPercentScale;
  }

  if (formatPrecision) {
    format.precision = formatPrecision;
  }

  if (view) {
    format.view = view;
  }

  return format;
};

export const isColumnFormatEqual = (columnFormat: TableColumnFormat, defaultFormat: TableColumnFormat) => {
  const currentColumnFormat = omit(columnFormat, 'displayName');
  const currentDefaultFormat = omit(defaultFormat, 'displayName');

  return isEqual(currentColumnFormat, currentDefaultFormat);
};
