import { Expr, FunCall, isNullLiteral, isStringLiteral, SoQLType, TypedSoQLFunCall } from 'common/types/soql';
import { typedComponent } from '../../lib/selectors';
import { dateToString, isFixed, isFloating } from '../../lib/soql-helpers';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { some } from 'ts-option';
import 'react-datepicker/dist/react-datepicker.css';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import I18n from 'common/i18n';
import { ForgeTextField, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';
import { createUpdateContents } from 'common/explore_grid/lib/feature-flag-helpers';

interface DateInputProps {
  onChange?: (e: any) => void;
  value?: string | number;
  onClick?: (e: any) => void;
}

interface DateInputState {
  editedValue: string | number | undefined;
}

const isTypeAllowed = (type: SoQLType) =>
  type === SoQLType.SoQLFloatingTimestampAltT ||
  type === SoQLType.SoQLFloatingTimestampT ||
  type === SoQLType.SoQLFixedTimestampT;

class CustomDateInput extends React.Component<DateInputProps, DateInputState> {
  constructor(props: DateInputProps) {
    super(props);
    this.state = {
      editedValue: props.value
    };
  }

  componentDidUpdate(prevProps: DateInputProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ editedValue: this.props.value });
    }
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editedValue: e.target.value });
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.onChange!(e);
  };

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.props.onChange!(e);
    }
  };

  render() {
    return (
      <ForgeTextField>
        <input
          type="text"
          name="date-editor"
          aria-label={I18n.t('shared.explore_grid.edit_nodes.choose_date')}
          onClick={this.props.onClick}
          value={this.state.editedValue}
          onChange={this.onInputChange}
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
        />
      </ForgeTextField>
    );
  }
}

function EditDateLikeLiteral(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  const [arg, typ] = props.eexpr.fold(
    eexpr => [eexpr.untyped.args[0], typedComponent(eexpr).map(t => t.soql_type)],
    eexpr => [eexpr.expr.args[0], some(eexpr.expr.soql_type)]
  );
  if (!isStringLiteral(arg)) {
    console.error('argument error', arg);
    throw new Error('Attempt to render Date picker with invalid arg!');
  }

  const toDate = () => {
    return typ.map((st) => {
      if (isFixed(st)) {
        return moment.utc(arg.value).toDate();
      }
      if (isFloating(st)) {
        return moment(arg.value).toDate();
      }
    });
  };

  const fromDate = (d: Date) => typ.flatMap((st) => dateToString(d, st));

  const update = (newDate: string) =>
    props.update(props.eexpr.mapBoth(
      eexpr => ({
        ...eexpr.untyped,
        args: [{ type: 'string_literal', value: newDate }]
      }),
      eexpr => ({
        ...eexpr.expr,
        args: [{ type: 'string_literal', value: newDate, soql_type: SoQLType.SoQLTextT }]
      })
    ));

  const onChange = (e: Date) => {
    fromDate(e).map((newValue: string) => {
      if (newValue === 'Invalid date') {
        fromDate(moment(moment.now()).toDate()).map((defaultDate) => update(defaultDate));
      } else {
        update(newValue);
      }
    });
  };

  return (
    <AstNode
      {...props}
      className="funcall"
      removable={false}
      changeableElement={1}
      showSuccess={props.querySucceeded}
    >
      {/* Ideally we would swap this with ForgeDatePicker but it does not have a time option */}
      <DatePicker
        disabled={typ.isEmpty}
        key="date-editor"
        name="date-editor"
        showTimeSelect
        fixedHeight
        dateFormat="yyyy MMM dd hh:mm:ss a"
        popperPlacement="bottom"
        portalId="visual-node-date-popper"
        popperModifiers={[
          {
            name: 'flip',
            options: {
              behavior: ['bottom'] // don't allow it to flip to be above. doesn't work with the drag to resize container.
            }
          },
          {
            name: 'preventOverflow',
            options: {
              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            }
          },
          {
            name: 'hide',
            options: {
              enabled: false // turn off since needs preventOverflow to be enabled
            }
          }
        ]}
        timeIntervals={15}
        timeCaption="time"
        selected={toDate().getOrElseValue(undefined)}
        onChange={onChange}
        customInput={<CustomDateInput />}
      />
      {props.showKebab && (
        <ForgeIconButton>
          <button
            type="button"
            data-testid="edit-date-like"
            aria-label={I18n.t('shared.explore_grid.vee_kebab_menu.button_label')}
            className="tyler-icons">
            <ForgeIcon name="more_vert" />
          </button>
        </ForgeIconButton>
      )}
    </AstNode>
  );
}

const shouldRenderDateLikeLiteral = (f: FunCall) =>
  f.args.length === 1 &&
  (isStringLiteral(f.args[0]) || isNullLiteral(f.args[0])) &&
  [
    SoQLType.SoQLFixedTimestampAltT,
    SoQLType.SoQLFixedTimestampT,
    SoQLType.SoQLFloatingTimestampAltT,
    SoQLType.SoQLFloatingTimestampT
  ]
    .map((t) => `cast$${t}`)
    .includes(f.function_name);

export { shouldRenderDateLikeLiteral, EditDateLikeLiteral };
