import I18n from 'common/i18n';
import { SoQLStringLiteral, TypedSoQLStringLiteral, Expr, UnAnalyzedAst, BinaryTree } from 'common/types/soql';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import { getSuggestions, Suggester } from '../../lib/data-helpers';
import { getUnAnalyzedAst } from '../../lib/selectors';
import { AppState } from '../../redux/store';
import _ from 'lodash';
import { Option } from 'ts-option';
import { default as React } from 'react';
import { connect } from 'react-redux';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import { withSubExprUpdates } from './Argument';
import { renderKebabButton } from './EditLiteral';
import { PhxChannel } from 'common/types/dsmapi';
import { ForgeAutocomplete, ForgeTextField, ForgeIcon } from '@tylertech/forge-react';
import { IAutocompleteOption } from '@tylertech/forge';
import { usingNewAnalysisEndpoint } from 'common/explore_grid/lib/feature-flag-helpers';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.string_literal_with_suggestions' });

type ExternalProps = ExprProps<SoQLStringLiteral, TypedSoQLStringLiteral> & {
  suggestionTarget: Expr;
};
type Props = ExternalProps & {
  tree: Option<BinaryTree<UnAnalyzedAst>>;
  channel: PhxChannel;
  fourfour: string
};

class StringLiteralWithSuggestions extends React.Component<Props> {
  suggester: Option<Suggester>;

  constructor(props: Props) {
    super(props);
    this.buildSuggester(props);
  }

  buildSuggester = (props: Props) => {
    const { fourfour, scope, channel, suggestionTarget } = props;

    const provider = new SoqlDataProvider({
      domain: window.location.host,
      datasetUid: fourfour,
    });

    this.suggester = this.props.tree.map(tree => {
      return getSuggestions(provider, suggestionTarget, scope, tree, channel);
    });
  };

  componentDidUpdate(newProps: Props) {
    if (!_.isEqual(newProps.suggestionTarget, this.props.suggestionTarget)) {
      this.buildSuggester(newProps);
    }
  }


  onRemove = () => {
    this.props.remove();
  };

  onUpdate = (newValue: string) => {
    const candidate = this.props.eexpr.mapBoth(
      eexpr => ({ ...eexpr.untyped, value: newValue }),
      eexpr => ({ ...eexpr.expr, value: newValue })
    );
    if (usingNewAnalysisEndpoint()) { // This could probably be smarter, but also, it'll get collapsed when we transition off the feature flag anyways.
      if (newValue !== this.props.eexpr.right.expr.value) {
        this.props.update(candidate);
      }
    } else {
      if (newValue !== this.props.eexpr.left.untyped.value) {
        this.props.update(candidate);
      }
    }
  };

  fetchSuggestions = (filterText: string): Promise<IAutocompleteOption<string>[]> => {
    return this.suggester
    .map(suggest =>
      suggest(filterText, 0, true)
      .then(
        suggestions => suggestions.results
        .map(
          res => ({label: res.title, value: res.title})
        ))
    )
    .getOrElseValue(Promise.resolve([]));
  };

  renderAutocomplete() {
    const expr = this.props.eexpr.fold(
      eexpr => eexpr.untyped,
      eexpr => eexpr.expr
    );

    return (
      <ForgeAutocomplete
        mode="stateless"
        allowUnmatched={true}
        filterFocusFirst={false}
        filterOnFocus={true}
        filter={this.fetchSuggestions}
        value={expr.value || ''}
        on-forge-autocomplete-select={(evt: CustomEvent) => this.onUpdate(evt.detail.value)}
        data-testid="string-literal-with-suggestions-autocomplete"
      >
        <ForgeTextField>
          <ForgeIcon slot="leading" name="search" />
          <input
            type="text"
            id={'string-with-literal-suggestions-input'}
            data-testid={'string-with-literal-suggestions-input'}
          />
          <label htmlFor='string-with-literal-suggestions-input'>{t('text_value')}</label>
        </ForgeTextField>
      </ForgeAutocomplete>
    );
  }

  render() {
    const { showKebab, showRemove } = this.props;
    const changeableElement = showKebab ? 1 : undefined;
    const removable = showRemove !== undefined && showRemove;
    return (
      <AstNode {...this.props} className="literal string-literal-with-suggestions" changeableElement={changeableElement} removable={removable}>
        {this.renderAutocomplete()}
        {renderKebabButton(showKebab)}
      </AstNode>
    );
  }
}

const mapStateToProps = (state: AppState, props: ExternalProps): Props => {
  return {
    ...props,
    fourfour: state.fourfourToQuery,
    tree: getUnAnalyzedAst(state.query),
    channel: state.channel
  };
};

export default withSubExprUpdates(connect(mapStateToProps)(StringLiteralWithSuggestions));
