import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import { userHasAccessLevel } from 'common/components/AccessManager/Util';
import { AccessLevelName, AccessLevelVersion, ViewUser } from 'common/types/view';

import UserList from 'common/components/AccessManager/components/UserList';

import UserCounts from './UserCounts';
import AddPublishedTo from './AddPublishedTo';

import './manage-published-to.scss';

const classNameScope =
  'common--components--AccessManager--components--AudienceScopeChooser--manage-published-to';

/**
 * This component allows the user to control which specific users/teams the asset "published to".
 *
 * "Published to" here means that the users can only see the published version of the asset, which
 * means that they get the "published viewer" access level.
 *
 * NOTE: This is not shown on domains with `strict_permissions` off (a.k.a. "publica domains")
 */
const ManagePublishedTo: FunctionComponent = () => (
  <div>
    <div
      className={`${classNameScope}--header`}
      dangerouslySetInnerHTML={{
        __html: I18n.t('shared.site_chrome.access_manager.choose_published_viewers_html')
      }}
    />

    {/* User List filtered to only show users WITH published viewer access */}
    <UserList
      hideOwner
      hideAccessLevelDropdown
      noUsersMessage={I18n.t('shared.site_chrome.access_manager.no_published_to')}
      userFilter={(user: ViewUser) =>
        userHasAccessLevel(user, AccessLevelName.Viewer, AccessLevelVersion.Published)
      }
    />

    <AddPublishedTo />
    <UserCounts />
  </div>
);

export default ManagePublishedTo;
