import { BASE_LAYERS } from '../constants';
import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';

export default {
  format: {
    type: 'visualization_interchange_format',
    version: VIF_CONSTANTS.LATEST_VERSION
  },
  configuration: {
    baseLayerUrl: BASE_LAYERS[0].legacyMapsTileUrlValue,
    baseLayerOpacity: 0.8,
    legend: {
      type: 'continuous',
      negativeColor: '#c6663d',
      zeroColor: '#ffffff',
      positiveColor: '#003747'
    },
    viewSourceDataLink: true
  },
  description: '',
  series: [
    {
      color: {},
      computedColumnName: null,
      dataSource: {
        datasetUid: null,
        dimension: {
          columnName: null,
          aggregationFunction: null
        },
        measure: {
          columnName: null,
          aggregationFunction: 'count'
        },
        type: 'socrata.soql',
        filters: []
      },
      label: null,
      shapefile: {
        geometryLabel: null,
        primaryKey: '_feature_id',
        uid: null
      },
      type: 'regionMap'
    }
  ],
  title: ''
};
