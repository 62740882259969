import React, { InputHTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import SocrataIcon from 'common/components/SocrataIcon';

import { CustomInputProps } from './types';
import RequiredStar from './RequiredStar';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & PropsWithChildren<CustomInputProps>;

/**
 * Generic Input component
 *
 * Renders with a label (and required * if it's required) and handles valid/disabled/etc.
 *
 * All props will be passed to the "real" <input /> that is rendered.
 *
 * Children will be rendered next to the label. This is useful for i.e. buttons that open modals to show more
 * info about the input.
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    children,
    label,
    id,
    name,
    valid,
    required,
    disabled,
    className,
    containerClassName,
    iconName,
    errorMessage
  } = props;

  // since we need an id to link the label, we use the name if we're not passed an id
  // Note that `id` attributes that include brackets, i.e. "input[someName]" will not work correctly when
  // associating form inputs with labels, and ARIA attributes. Avoid using `[]` symbols in `id` values.
  const inputId = id || `form-${name}`;

  // used in aria-describedby for an error message, if there is one
  const errorId = `${inputId}-error`;

  const hasIcon = !isEmpty(iconName);

  const inputClassName = cx('form-component-input text-input', className, {
    'text-input-error': !valid && !disabled,
    'text-input-with-icon': hasIcon
  });

  const formContainerClassNames = cx('form-component-input-container', {
    'form-component-input-container-with-icon': hasIcon
  });

  // omit any "non-form" props that have been passed in
  // otherwise, pass the props to the input wholesale
  const filteredProps = omit(props, [
    'key', // if rendering an array of inputs
    'children', // we put this in the label
    'containerClassName',
    'valid',
    'errorMessage',
    'iconName',
    'label'
  ]);

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...filteredProps,
    id: inputId,
    className: inputClassName,
    'aria-required': required
  };

  // we only want to add the `aria-describedby` if we have an error message
  if (!valid) {
    inputProps['aria-describedby'] = errorId;
    inputProps['aria-invalid'] = !valid;
  }

  return (
    <div className={containerClassName}>
      <div className="form-component-input-label-container">
        {label != undefined && (
          <label className="form-component-input-label" htmlFor={inputId}>
            {label} <RequiredStar required={required || false} />
            {children}
          </label>
        )}
        {!valid && (
          <div className="form-component-input-error-message" id={errorId}>
            {errorMessage}
          </div>
        )}
      </div>
      <div className={formContainerClassNames}>
        {iconName && <SocrataIcon name={iconName} />}
        <input ref={ref} {...inputProps} />
      </div>
    </div>
  );
});

Input.defaultProps = {
  containerClassName: '',
  required: false,
  valid: true,
  disabled: false,
  type: 'text'
};

Input.displayName = 'Input'; // forwardRef alters the name.

export default Input;
