import React, { FC, useEffect } from 'react';
import {
  ForgeDatePicker,
  ForgeDialog,
  ForgeOption,
  ForgeScaffold,
  ForgeSelect,
  ForgeTextField,
  ForgeTimePicker,
  ForgeToolbar,
  ForgeIconButton,
  ForgeIcon
} from '@tylertech/forge-react';

import InteractiveUser from 'common/types/users/interactiveUser';
import { fetchJsonWithParsedError } from 'common/http';
import {
  createScreenshotSubscription,
  fetchAllSubscriptionsForAsset,
  ScreenshotSubscription
} from 'common/notifications/api/ScreenshotSubscriptionsAPI';
import { CatalogInteractiveUser, UsersCatalogSearchResult } from 'common/types/users/catalogUsers';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';

import '../index.scss';
import { ReducerState } from '../reducer';
import { useWatchContext } from '../context';
import RecipientSearch from './RecipientSearch';
import { setExistingSubscriptions, setRecipients, setStateAction } from '../actions';
import RecipientList from './RecipientList';
import SaveSubscriptionButtons from './SaveSubscriptionButtons';
import DeleteSubscriptionButtons from './DeleteSubscriptionButtons';
import CreationErrors from './CreationErrors';
import { currentUserIsSiteMemberOrSuperAdmin } from 'common/current_user';
import { View } from 'common/types/view';
import { hasGrantRight } from 'common/views/has_rights';

const MIN_DAYS = 1;
const DAYS_STEP = 1;
const TIME_PICKER_STEP = 30;

export interface WatchModalProps {
  onDismiss: () => void; // TKB TODO: duplicated with showDialog state
  currentUser: InteractiveUser;
  fourfour: string;
  view: View;
}

const WatchAssetModal: FC<WatchModalProps> = (props) => {
  const {
    state: {
      time,
      startDate,
      days,
      dayError,
      recipients,
      timeZone,
      showDialog,
      existingSubscriptions,
      domainCname,
      createErrors,
      requestId
    },
    dispatch,
    t
  } = useWatchContext();

  const { fourfour, currentUser, onDismiss, view } = props;

  const hasExistingSubscriptions = existingSubscriptions.length > 0;
  const canAddOthers = currentUserIsSiteMemberOrSuperAdmin() && hasGrantRight(view);

  // First time we load the component, check if user has subscriptions already
  useEffect(() => {
    const loadExistingSubscriptions = async () => {
      try {
        const subscriptions = await fetchAllSubscriptionsForAsset(fourfour).then((res) => res.data) ?? [];
        if (subscriptions.length > 0) {
          const subscribedRecipients = await mapToRecipients(subscriptions);
          dispatch(setRecipients(subscribedRecipients));
        }
        dispatch(setExistingSubscriptions(subscriptions));
      } catch {
        dispatch(setExistingSubscriptions([]));
      }
    };

    loadExistingSubscriptions();
  }, [createErrors]);

  // ScreenshotSubscription has less info than CatalogInteractiveUser,
  // so we take the data from N&A and find their catalog users
  const mapToRecipients = async (subscriptions: ScreenshotSubscription[]) => {
    const ids = subscriptions.map((s) => s.subscriber_uid).join(',');
    const params = [
      `ids=${ids}`,
      `domain=${domainCname}`,
      'include_teams=false',
      'only=site_members',
      'disabled=false',
      'future=false',
      'rights=can_collaborate'
    ].join('&');

    const url = `/api/catalog/v1/users?${params}`;
    return fetchJsonWithParsedError(url).then(
      (r: UsersCatalogSearchResult) => r.results as CatalogInteractiveUser[]
    );
  };

  const setState = (payload: Partial<ReducerState>) => dispatch(setStateAction(payload));

  const validDays = (day = -1) => day >= MIN_DAYS;

  const validTime = () => time && time.length > 0;

  const validStartDate = () => startDate.length > 0;

  const disableSave = !(validDays(days) && validTime() && validStartDate() && !dayError);

  const onTimeChange = (newTime = '') => setState({ time: newTime });

  const onTimeZoneChange = (newTimeZone: string) => setState({ timeZone: newTimeZone });

  const onDayChange = (newDay: number) => {
    if (validDays(newDay)) {
      setState({ days: newDay, dayError: false });
    } else {
      setState({ dayError: true });
    }
  };

  const onStartDateChange = (newStartDate: any) => {
    setState({ startDate: newStartDate || '' });
  };

  // Send the first_occurance in ISO-8601, using an offset for the timezone for example
  // 2022-09-30T08:30:00-07:00. For UTC there is no offset, 2022-09-30T08:30:00.000Z.
  async function onSaveWatch() {
    // TKB TODO: before trying to create subscription, try to make viewers to assets (PermissionsService)
    // TKB TODO: break this up
    if (!days) {
      setState({ dayError: true });
      return;
    }

    let dateTime = startDate + ' ' + time;
    const dateTimeWithZone = new Date(dateTime).toLocaleTimeString(undefined, {
      timeZone: timeZone,
      timeZoneName: 'short',
      hour12: false
    });
    const localTimeZone = dateTimeWithZone.toString().split(' ')[1];
    dateTime = startDate + ' ' + time + ' ' + localTimeZone;
    const utcDateTime = new Date(dateTime).toISOString();

    const scheduleConfiguration = {
      asset_uid: fourfour,
      domain_cname: domainCname,
      interval: { days },
      first_occurrence: utcDateTime,
      subscribed_by_uid: currentUser.id,
      first_occurrence_timezone: timeZone,
      creation_timezone: timeZone
    };

    const failedSubscriberEmails: string[] = [];
    let errorRequestId;

    for (const key in recipients) {
      await createScreenshotSubscription(scheduleConfiguration, recipients[key].id).catch(
        async ({ response }) => {
          const body = await response.json();
          failedSubscriberEmails.push(...body.errors.asset_has_existing_subscriber);
          errorRequestId = response.headers.get('X-Socrata-RequestId');
        }
      );
    }

    if (failedSubscriberEmails.length > 0) {
      setState({ createErrors: failedSubscriberEmails, requestId: errorRequestId });
    } else {
      showToastNow({
        type: ToastType.SUCCESS,
        content: t('subscription_creation_success')
      });
      setState({ showDialog: false });
    }
  }

  return (
    <ForgeDialog open={showDialog} onDismiss={onDismiss} options={{ backdropClose: false }}>
      <ForgeScaffold className="watch-asset-modal">
        <div slot="header">
          <ForgeToolbar className="dialog-header tyl-typography">
            <h1 slot="start" className="forge-typography--headline5">
              {t('title')}
            </h1>
            <ForgeIconButton densityLevel={3} slot="end">
              <button type="button" onClick={onDismiss}>
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          </ForgeToolbar>
        </div>

        <CreationErrors createErrors={createErrors} requestId={requestId} />

        <div slot="body-header" className="watch-body">
          <p>{t('send')}</p>
          <div className="watch-day-time">
            <div className="watch-days-container">
              <ForgeTextField className="watch-days" invalid={dayError}>
                <input
                  type="number"
                  id="watch-day"
                  min={MIN_DAYS}
                  step={DAYS_STEP}
                  defaultValue={days}
                  disabled={hasExistingSubscriptions}
                  onChange={(event) => onDayChange(event.target.valueAsNumber)}
                  autoComplete="off"
                />
                <label htmlFor="watch-day" slot="label">
                  {t('choose_number')}
                </label>
                {dayError && <span slot="helper-text">{t('day_error')}</span>}
              </ForgeTextField>
            </div>

            <div>{t('day_at')}</div>
            <ForgeTimePicker
              step={TIME_PICKER_STEP}
              disabled={hasExistingSubscriptions}
              on-forge-time-picker-change={(event: CustomEvent) => onTimeChange(event.detail)}
              value={time}
              allowInput={false}
            >
              <ForgeTextField>
                <input type="text" id="time-picker" />
                <label htmlFor="time-picker">{t('choose_time')}</label>
              </ForgeTextField>
            </ForgeTimePicker>

            <ForgeSelect
              className="watch-time-zone"
              label=""
              value={timeZone}
              disabled={hasExistingSubscriptions}
              on-change={(event: CustomEvent) => onTimeZoneChange(event.detail)}
            >
              <ForgeOption value="America/Los_Angeles">America/Los_Angeles</ForgeOption>
              <ForgeOption value="America/Denver">America/Denver</ForgeOption>
              <ForgeOption value="America/Chicago">America/Chicago</ForgeOption>
              <ForgeOption value="America/New_York">America/New_York</ForgeOption>
              <ForgeOption value="Etc/UTC">UTC</ForgeOption>
            </ForgeSelect>
          </div>
          <p>{t('starting')}</p>
          <div className="watch-date-container">
            <ForgeDatePicker
              popup-classes="datatype-popup"
              valueMode="string"
              allowInvalidDate={false}
              disabled={hasExistingSubscriptions}
              value={startDate}
              on-forge-date-picker-change={(event: CustomEvent) => onStartDateChange(event.detail)}
            >
              <ForgeTextField>
                <input type="text" id="input-datepicker" />
                <label htmlFor="input-datepicker">{t('choose_date')}</label>
              </ForgeTextField>
            </ForgeDatePicker>
          </div>
          <p>{t('recipients')}</p>
          {hasExistingSubscriptions || !canAddOthers ? null : <RecipientSearch fourfour={fourfour} />}
        </div>

        <RecipientList recipients={recipients} canRemove={canAddOthers} />

        {hasExistingSubscriptions ? (
          <DeleteSubscriptionButtons fourfour={fourfour} onDismiss={onDismiss} />
        ) : (
          <SaveSubscriptionButtons
            onDismiss={onDismiss}
            onSaveWatch={onSaveWatch}
            disableSave={disableSave}
          />
        )}
      </ForgeScaffold>
    </ForgeDialog>
  );
};

export default WatchAssetModal;
