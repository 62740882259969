import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import reduce from 'lodash/reduce';

/**
 * Extension of _.get where it searches many paths until
 * a value is found.
 *
 * Usage:
 * object1 = { foo: { bar: 1 }}
 * object2 = { a: { b: { c: 99 }}, foo: { bar: 1 }}
 * sift(object1, 'a.b.c', 'd.e', 'foo.bar') === 1
 * sift(object2, 'a.b.c', 'd.e', 'foo.bar') === 99
 * @param object
 * @param pathsToSearch
 * @returns {*}
 */
export default (object: Record<string, any>, ...pathsToSearch: string[]) =>
  reduce(pathsToSearch, (memo, path) => (isUndefined(memo) ? get(object, path) : memo), undefined);
