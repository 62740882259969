import { createSlice } from '@reduxjs/toolkit';

const storyCopierSlice = createSlice({
  name: 'storyCopierSlice',
  initialState: {
    isCopyStoryModalOpen: false
  },
  reducers: {
    openCopyStoryModal(state) {
      state.isCopyStoryModalOpen = true;
    },
    closeCopyStoryModal(state) {
      state.isCopyStoryModalOpen = false;
    }
  }
});

const { actions, reducer } = storyCopierSlice;

// Functional React Component useSelector(CopyStoryModalSelector) return isCopyStoryModalOpen state
// Vanilla JS + jQuery + Class React Component CopyStoryModalSelector(StoryStore.getState());

export const { openCopyStoryModal, closeCopyStoryModal } = actions;
export default reducer;
