import _ from 'lodash';
import $ from 'jquery';
import Geojson2wkt from 'geojson2wkt';
import MapboxglSpiderifier from '@socrata/mapboxgl-spiderifier';

import {
  getFeaturesGroupedByBuckets,
  getLoadingSpinnerContent
} from '../contentFormatters/genericContentFormatter';

import I18n from 'common/i18n';
import MapHelper, { getPerPixelLatLngRange } from 'common/visualizations/helpers/MapHelper';
import {
  aggregateFeaturePopupDataFromSameLayers
} from 'common/visualizations/helpers/featureAggregationHelper';
import { getRangeBucket } from 'common/visualizations/helpers/BucketHelper';
import { getCharmSvgSrc } from 'common/resources/charms';
import { QUANTIFICATION_METHODS, NULL_CATEGORY_VALUE } from 'common/authoring_workflow/constants';
import { SIMPLIFICATION_CONFIGURATION } from 'common/visualizations/views/mapConstants';

// Sample Stack Feature: (Geojson object got from mapbox-gl map)
//  - Clustered many snappedAndGroupedPoints into a cluster
//    {
//      "type": "Feature",
//      "geometry": {
//        "type": "Point",
//        "coordinates": [-122.44754076004028,37.8044394394888]
//      },
//      "properties": {
//        "cluster": true
//        "cluster_id": 13
//        "count": 15489
//        "count_abbrev": "15k"
//        "count_group": "{\"Abandoned Vehicle\":2645,\"__$$other$$__\":6946,\"Street and Sidewalk Cleaning\":3109,\"Graffiti Private Property\":1027,\"Graffiti Public Property\":1424,\"SFHA Requests\":338}"
//        "point_count": 162
//        "point_count_abbreviated": 162
//        "__aggregate_by__": 97491
//        "__aggregate_by___abbrev": "97k"
//        "__aggregate_by___group": "{\"Abandoned Vehicle\":17386,\"__$$other$$__\":44145,\"Street and Sidewalk Cleaning\":18816,\"Graffiti Private Property\":6191,\"Graffiti Public Property\":8587,\"SFHA Requests\":2366}"
//      },
//      "layer": { ... }
//    }
//  - Single snappedAndGroupedPoints without cluster
//    {
//      "type":"Feature",
//      "geometry": {
//        "type": "Point",
//        "coordinates":[-122.46716380119324,37.799594712784625]
//      },
//      "properties": {
//        "__color_by_category__":"__$$other$$__",
//        "__count__":2,
//        "__resize_by__":4,
//        "__resize_by___abbrev":"4",
//        "__count___abbrev":"2"
//      },
//      "layer":{..}
//    }

// Builds html tipsy content for a stack.
export async function setPopupContentForStacks(popupParams = {}) {
  let showFlyoutClickMessage = false;
  const { element, vifs, renderOptions, features, map } = popupParams;
  const hasMultipleFeatures = features.length > 1;
  $(element).html(getLoadingSpinnerContent());
  const allPointFeatures = areAllPointFeatures(features, renderOptions);

  const featurePopupDataPromises = _.map(features, async (featureItem, index) => {
    const properties = _.get(featureItem, 'properties', {});
    const count = Number(properties[renderOptions[index].countBy]);
    const isPoint = count <= 1;
    const seriesName = hasMultipleFeatures ? vifs[index].getSeriesName() : '';
    const isLayerVisible = vifs[index].isLayerVisible();
    const vif = vifs[index];

    if (!isLayerVisible) {
      return;
    }

    if (isPoint) {
      showFlyoutClickMessage = true;
    }

    let colorByCategoriesBreakdown;
    if (_.isEmpty(vif.getColorPointsByColumn()) || allPointFeatures) {
      colorByCategoriesBreakdown = [];
    } else {
      colorByCategoriesBreakdown = await getColorByCategoriesBreakdown(
        featureItem,
        map,
        renderOptions[index],
        vif
      );
    }

    return {
      vif,
      seriesName,
      count,
      colorByCategoriesBreakdown : !_.isUndefined(colorByCategoriesBreakdown) ? colorByCategoriesBreakdown : []
    };
  });

  const featurePopupData = await Promise.all(featurePopupDataPromises);
  const layerWisePopupData = aggregateFeaturePopupDataFromSameLayers(featurePopupData);
  const layerWisePopupContent = _.map(layerWisePopupData, (popupDatum) => {
    return formatPopupDatum(popupDatum, hasMultipleFeatures);
  }).join('');

  const flyoutContent = '<div class="point-map-popup point-popup stack-content-popup">' +
    layerWisePopupContent +
    flyoutClickMessage(showFlyoutClickMessage) +
    '</div>';

  $(element).html(flyoutContent);
}


function flyoutClickMessage(showClickMessage) {
  const flyoutInfoMessage = I18n.t('shared.visualizations.charts.map.flyout_message');
  if (!showClickMessage) {
    return '';
  }

  return `<div class="popup-details">${flyoutInfoMessage}</div>`;
}

async function getColorByCategoriesBreakdown(feature, map, renderOptions, vif) {
  try {
    const snappedFeatures = await getSnappedFeaturesInCluster(feature, map);
    const retrieveDataCondition = getRetrieveDataCondition(snappedFeatures, map, vif);
    let featuresColorByBreakdown;

    const featuresGroupedByBuckets = await getFeaturesGroupedByBuckets(
      vif,
      retrieveDataCondition,
      renderOptions
    );

    if (vif.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value) {
      featuresColorByBreakdown = getFeaturesColorByRangeBreakdown(vif, featuresGroupedByBuckets, renderOptions);
    } else {
      featuresColorByBreakdown = getFeaturesColorByCategoriesBreakdown(vif, featuresGroupedByBuckets, renderOptions);
    }

    return featuresColorByBreakdown;
  } catch (e) {
    console.warn('Error while getPopupContentData', e);
  }
}

function getFeaturesColorByCategoriesBreakdown(vif, featuresGroupedByBuckets, renderOptions) {
  const { colorByBuckets, colorBy, countBy } = renderOptions;
  const categoryToBucketMap = _.keyBy(colorByBuckets, (colorForCategory) =>
    _.get(colorForCategory, 'id', colorForCategory.label)
  );
  const colorByBucketsId = _.map(colorByBuckets, 'id');
  const defaultBucket = colorByBuckets[colorByBuckets.length - 1];

  return _.chain(featuresGroupedByBuckets).
    map((featuresGroupedByBucket) => {
      const category = _.get(featuresGroupedByBucket, colorBy, NULL_CATEGORY_VALUE);
      if (_.includes(colorByBucketsId, category)) {
        return {
          category,
          count: featuresGroupedByBucket[countBy]
        };
      } else {
        return {
          category: 'Other',
          count: featuresGroupedByBucket[countBy]
        };
      }
    }).
    sortBy('count').
    groupBy('category').
    map((groupedByBuckets, key) => ({
      'category': key,
      'count': _.sumBy(groupedByBuckets, (groupedByBucket) => parseInt(groupedByBucket.count))
    })).
    map(categoryDatum => {
      const color = _.get(categoryToBucketMap, [categoryDatum.category, 'color'], defaultBucket.color);
      const categoryId = _.get(categoryToBucketMap, [categoryDatum.category, 'label'], defaultBucket.label);
      const charmName = _.get(categoryToBucketMap, [categoryDatum.category, 'charmName'], defaultBucket.charmName);
      return {
        vif,
        category: categoryId,
        count: categoryDatum.count,
        color,
        charmName
      };
    }).
    value();
}

function getFeaturesColorByRangeBreakdown(vif, featuresGroupedByBuckets, renderOptions) {
  const { colorByBuckets, colorBy, countBy } = renderOptions;

  return _.chain(featuresGroupedByBuckets).
    map((featuresGroupedByBucket) => {
      const featureValue = _.get(featuresGroupedByBucket, colorBy);
      const rangeBucket = getRangeBucket({
        buckets: colorByBuckets,
        value: featureValue,
        bucketCount: vif.getColorByBucketsCount()
      });

      const bucketRangeLabel = _.get(rangeBucket, 'label');
      const color = _.get(rangeBucket, 'color');
      const charmName = _.get(rangeBucket, 'charmName');
      const count = _.get(featuresGroupedByBucket, countBy, '');
      return { bucketRangeLabel, color, count, charmName };
    }).
    sortBy('count').
    map(({ bucketRangeLabel, color, count, charmName }) => {
      return {
        vif,
        category: bucketRangeLabel,
        count,
        color,
        charmName
      };
    }).
    value();
}

// Fetches the condition required to make the soql call
export function getRetrieveDataCondition(snappedFeatures, map, vif) {
  const boundingPolygon = getRoundedOffBoundingPolygon(snappedFeatures, map);
  const zoom = Math.floor(map.getZoom());
  const simplificationLevel = vif.getSimplificationLevel();
  const snapPrecision = SIMPLIFICATION_CONFIGURATION[simplificationLevel].snapPrecision[zoom];
  const snappedGeometryColumn = `snap_to_grid(${vif.getColumnName()},${snapPrecision})`;

  const boundingPolygonWKT = Geojson2wkt.convert(boundingPolygon.geometry);

  return [
    `within_polygon(${snappedGeometryColumn},'${boundingPolygonWKT}')`,
    `intersects(${snappedGeometryColumn},'${boundingPolygonWKT}')`
  ].join(' OR ');
}

// Background:
//    (Refer issue: https://github.com/mapbox/mapbox-gl-js/issues/5639), mapboxgl
// rounds off geometries of features that we send in for rendering. While fetching the feature that
// was clicked on, it returns the feature with a rounded of geometry based on the current zoom level.
// snappedFeatures =>
//    features grouped by snapped-point(snap_to_grid(point_column)) retrieved via soql on tile calls.
//
// On clicking of a snappedFeature or a stack(group of stacked feature), we get the snappedFeatures
// from mapbox. Since the geometries are rounded of by mapbox-glbased on current zoom level.
// We prepare a bounding polygon encompassing all the features with mapbox's rounding factor.
function getRoundedOffBoundingPolygon(pointFeatures, map) {
  const perPixelLatLngRange = getPerPixelLatLngRange(map);

  const roundedOffBoundaryPointFeatures = _.chain(pointFeatures)
    .map((snappedFeature) => {
      const lng = _.get(snappedFeature, 'geometry.coordinates[0]');
      const lat = _.get(snappedFeature, 'geometry.coordinates[1]');

      if (!_.isNumber(lat) || !_.isNumber(lng)) {
        // A rendered point will always have a geometry and coordinates.
        throw new Error(`Unexpected lat lng in feature lat: ${lat}, lng: ${lng}`);
      }

      return [
        buildGeojsonFeature([lng + perPixelLatLngRange.lngDiff / 2, lat + perPixelLatLngRange.latDiff / 2]), // NE
        buildGeojsonFeature([lng + perPixelLatLngRange.lngDiff / 2, lat - perPixelLatLngRange.latDiff / 2]), // SE
        buildGeojsonFeature([lng - perPixelLatLngRange.lngDiff / 2, lat - perPixelLatLngRange.latDiff / 2]), // SW
        buildGeojsonFeature([lng - perPixelLatLngRange.lngDiff / 2, lat + perPixelLatLngRange.latDiff / 2]) // NW
      ];
    })
    .compact()
    .flatten()
    .value();

  return MapHelper.getBoundingPolygonFor(roundedOffBoundaryPointFeatures);
}

function buildGeojsonFeature(coords) {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: coords
    }
  };
}

// Retrieves the snappedFeatures in a cluster.
export async function getSnappedFeaturesInCluster(feature, map) {
  const layerId = _.get(feature, 'layer.id');
  const featureCoordinates = _.get(feature, 'geometry.coordinates');
  const featureClusterId = _.get(feature, 'properties.cluster_id');
  const tileId = MapHelper.getTileIdForLatLng(map, featureCoordinates);
  const limit = 1000;
  const page = 0;
  const sourceId = _.get(map.getLayer(layerId), 'source');
  const zoom = Math.floor(map.getZoom());

  return new Promise((resolve, reject) => {
    if (!feature.properties.cluster) {
      return resolve([feature]);
    }

    map.getSource(sourceId).
      getLeaves(tileId, featureClusterId, limit, page, (err, leaves) => {
        if (err === null) {
          resolve(leaves);
        } else {
          reject(err);
        }
      });
  });
}

function formatPopupDatum(popupDatum, hasMultipleFeatures) {
  const { colorByCategoriesBreakdown, count, seriesName, vif } = popupDatum;
  const countUnits = vif.getUnits(count);

  let popupTitleContent = `<div class="popup-title">${count} ${countUnits}</div>`;

  if (hasMultipleFeatures) {
    popupTitleContent = '<div class="popup-name">' +
      `<div>${seriesName}</div>` +
      `<div class="title-count">${count} ${countUnits}</div>` +
    '</div>';
  }

  const breakdownContent = _.isEmpty(colorByCategoriesBreakdown) ? '' :
    '<table class="mapboxgl-popup-table">' +
      '<tbody>' +
        _.map(colorByCategoriesBreakdown, formatColorByBreakdownEntry).join('') +
      '</tbody>' +
    '</table>';

  return popupTitleContent + breakdownContent;
}

function formatColorByBreakdownEntry({ vif, category, count, color, charmName }) {
  const countUnits = vif.getUnits(count);
  const charmIcon = (!_.isEmpty(charmName) && vif.isCharmsLayerVisible()) ?
    `<img class="charm-icon" src="${getCharmSvgSrc(charmName)}"></img>` :
    '';

  return (
    '<tr class="mapboxgl-popup-row">' +
      '<td class="mapboxgl-popup-cell color-column">' +
        `<span class="indicator" style="background-color: ${color}">${charmIcon}</span>` +
      '</td>' +
      '<td class="mapboxgl-popup-cell">' +
        `<span class="category mapboxgl-overflow">${category}</span>` +
      '</td>' +
      '<td class="mapboxgl-popup-cell">' +
        `<span class="count mapboxgl-overflow">${count} ${countUnits}</span>` +
      '</td>' +
    '</tr>'
  );
}

function areAllPointFeatures(features, renderOptions) {
  const pointFeatures = _.filter(features, (featureItem, index) => {
    const count = _.get(featureItem.properties, renderOptions[index].countBy);
    return count <= 1;
  });

  return pointFeatures.length === features.length;
}
