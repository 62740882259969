import _ from 'lodash';

import {
  DEFAULT_SHAPE_FILL_OPACITY,
  DEFAULT_SHAPE_OUTLINE_COLOR,
  DEFAULT_SHAPE_OUTLINE_WIDTH
} from 'common/authoring_workflow/constants';

// Utility functions for fetching configuration related to rendering of shapes.
// These functions gets added to the vif object (see vifDecorator|views/UnifiedMap).
// So that multiple files(overlays/shapes-partials/legends/mapFactory) can
// reuse them directly.
export function getShapeColorByColumn() {
  return _.get(this, 'series[0].mapOptions.colorBoundariesBy');
}

export function getShapeFillOpacity() {
  const shapeFillOpacity = parseInt(_.get(this, 'series[0].mapOptions.shapeFillOpacity'));

  return (_.isNaN(shapeFillOpacity) ? DEFAULT_SHAPE_FILL_OPACITY : _.clamp(shapeFillOpacity, 0, 100)) / 100;
}

export function getShapeOutlineColor() {
  return _.get(this, 'series[0].mapOptions.shapeOutlineColor', DEFAULT_SHAPE_OUTLINE_COLOR);
}

export function getShapeOutlineWidth() {
  const shapeOutlineWidth = parseFloat(_.get(this, 'series[0].mapOptions.shapeOutlineWidth'));

  return _.isNaN(shapeOutlineWidth) ? DEFAULT_SHAPE_OUTLINE_WIDTH : _.clamp(shapeOutlineWidth, 0, 8);
}
