import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import type {
  CatalogInteractiveUser,
  Match,
  UsersCatalogSearchResults
} from 'common/types/users/catalogUsers';

import HighlightedResultText from './HighlightedResultText';

interface UserSearchResultProps {
  /** Result to render */
  result: UsersCatalogSearchResults;
}

const renderEmail = (
  isTeam: boolean,
  screenName: string,
  email: string,
  matches?: Match[]
) => {
  if (isTeam) {
    // team has no email
    return null;
  } else if (screenName) {
    // if we have a screen name, we have an actual user and not
    // just a share to an email address
    return <HighlightedResultText text={email} fieldName="email" matches={matches} />;
  } else {
    // else lack of screen name means this is an unregistered user
    return null;
  }
};

/**
 * Passed to the multiselect to register a single user search result.
 * Handles highlighting the matches from cetera autocomplete.
 */
const UserSearchResult: FunctionComponent<UserSearchResultProps> = ({ result }) => {
  const teamsEnabled = FeatureFlags.value('enable_teams');
  const { user, team, matches } = result;

  // the result has either a user OR a team...
  // if one is undefined, the other one will be defined
  const entity = user ? user! : team!;
  const { screen_name: screenName } = entity;
  const isTeam = !!team || false;

  const nameContent = screenName ? (
    // if we have a screen name, we have an actual user
    <HighlightedResultText text={screenName} fieldName="screen_name" matches={matches} />
  ) : (
    // else we have a share to just an email
    (entity as CatalogInteractiveUser).email
  );

  return (
    <div className="user-search-result-container">
      {/* We only show icons if teams are enabled... */}
      {teamsEnabled && <SocrataIcon name={isTeam ? IconName.Team : IconName.User} className="user-search-result-icon" />}
      <div className="user-search-result-content">
        <div className="user-search-result-name">{nameContent}</div>
        <div className="user-search-result-email">
          {renderEmail(isTeam, screenName, (entity as CatalogInteractiveUser).email!, matches)}
        </div>
      </div>
    </div>
  );
};

export default UserSearchResult;
