import { assign } from 'lodash';

import * as assertions from '../assertions';
import commaify from './commaify';
import convertToUrlComponent from './convertToUrlComponent';
import formatNumber from './formatNumber';
import formatString from './formatString';
import getCookie from './getCookie';
import getDecimalCharacter from './getDecimalCharacter';
import getGroupCharacter from './getGroupCharacter';
import getLocale from './getLocale';
import getQueryParameterByName from './getQueryParameterByName';
import getTimeZone from './getTimeZone';
import isolateScrolling from './isolateScrolling';
import mapIdsToParam from './mapIdsToParam';
import parseJsonOrEmpty from './parseJsonOrEmpty';
import sift from './sift';
import deepDifference from './deepDifference';
import addToWindow from './addToWindow';

/**
 * `format` is assigned to the String prototype at the bottom of this file.
 * @deprecated
 */
const format = function (...args) {
  return formatString(this, ...args);
};

/**
 * `CustomEvent` is assigned to the window at the bottom of this file.
 */
const CustomEvent = function (eventName, params) {
  const customEventParams = assign({ bubbles: false, cancelable: false, detail: undefined }, params);

  const customEvent = document.createEvent('CustomEvent');

  customEvent.initCustomEvent(
    eventName,
    customEventParams.bubbles,
    customEventParams.cancelable,
    customEventParams.detail
  );

  return customEvent;
};

// Caution: frontend/public/javascripts/util/util.js also overrides String.prototype.format
// In pages where both the files are included, based on the order of loading
// <string>.format calls this one or the other one
// Attach `.format()` to String.prototype.
String.prototype.format = format; // eslint-disable-line no-extend-native

// Add CustomEvent to the window.
CustomEvent.prototype = window.Event.prototype;
window.CustomEvent = CustomEvent;
addToWindow({ ...deepDifference }, 'socrata.utils');

export * from '../assertions';
export {
  commaify,
  format,
  formatNumber,
  getCookie,
  getDecimalCharacter,
  getGroupCharacter,
  getLocale,
  getQueryParameterByName,
  getTimeZone,
  mapIdsToParam,
  parseJsonOrEmpty,
  sift
};

export default {
  ...assertions,
  commaify,
  convertToUrlComponent,
  format,
  formatNumber,
  getCookie,
  getDecimalCharacter,
  getGroupCharacter,
  getLocale,
  getQueryParameterByName,
  getTimeZone,
  isolateScrolling,
  mapIdsToParam,
  parseJsonOrEmpty,
  sift
};
