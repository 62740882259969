// Vendor Imports
import d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';

// Project Imports
import { formatValuePlainText } from '../helpers/ColumnFormattingHelpers';
import LabelResizer from './LabelResizer';
import BaseVisualization from './BaseVisualization';
import { renderLegendWithLegendItems } from './BaseVisualization/Legend';
import SvgKeyboardPanning from './SvgKeyboardPanning';
import I18n from 'common/i18n';

import { findBucket, getColorByBucket } from 'common/visualizations/helpers/BucketHelper';
import { getValueLocationInScaleAsRatio, getAxisHeight } from 'common/visualizations/helpers/d3Helpers';
import { getMeasures } from 'common/visualizations/helpers/measure';
import { getMinAndMaxValue } from 'common/visualizations/helpers/RangeHelper';
import { getNumberValue } from 'common/visualizations/helpers/StringHelpers';
import {
  DEFAULT_BOOLEAN_VALUE,
  NULL_CATEGORY_VALUE,
  VIF_CONSTANTS
} from 'common/authoring_workflow/constants';

// Constants
import {
  AXIS_DEFAULT_COLOR,
  AXIS_GRID_COLOR,
  AXIS_LABEL_MARGIN,
  AXIS_TICK_COLOR,
  DEFAULT_DESKTOP_COLUMN_WIDTH,
  DEFAULT_MOBILE_COLUMN_WIDTH,
  FONT_STACK,
  MEASURE_LABELS_FONT_COLOR,
  MEASURE_LABELS_FONT_SIZE,
  SCATTER_PLOT_CIRCLE_DEFAULT_FILL_COLOR,
  SCATTER_PLOT_CIRCLE_SIZE,
  SCATTER_PLOT_CIRCLE_STROKE_COLOR,
  SCATTER_CHART_X_AXIS_SERIES_INDEX,
  SCATTER_CHART_Y_AXIS_SERIES_INDEX,
  SCATTER_CHART_COLOR_BY_SERIES_INDEX,
  SCATTER_CHART_RESIZE_BY_SERIES_INDEX,
  SCATTER_PLOT_RESIZE_BY_DEFAULT_MAX,
  SERIES_TYPE_FLYOUT,
  SERIES_TYPE_SCATTER_CHART,
  TIPSY_ANCHOR_THRESHOLD
} from './SvgConstants';
import { getAxisLabels, getShowDimensionLabels } from '../helpers/VifSelectors';
import { hideOffscreenDimensionLabels } from '../helpers/SvgHelpers';

// The MARGINS values have been eyeballed to provide enough space for axis
// labels that have been observed 'in the wild'. They may need to be adjusted
// slightly in the future, but the adjustments will likely be small in scale.
// The LEFT margin has been removed because it will be dynamically calculated.
const MARGINS = {
  LEFT: 0,
  TOP: 32,
  RIGHT: 50,
  BOTTOM: 32
};
const DEFAULT_ITEM_PER_GROUP_LENGTH = 4;
const DEFAULT_LABEL_BAND = 13;
const DEFAULT_LABEL_HEIGHT = 80;
const MAX_DATA_COUNT = 1000;

function SvgScatterChart($element, vif, options) {
  const self = this;

  // Dimension value will be in the zero'th index, so adding plus one
  const xNumericValue = (data) => getNumberValue(data[SCATTER_CHART_X_AXIS_SERIES_INDEX + 1]);
  const xCategoryValue = (data) => data[SCATTER_CHART_X_AXIS_SERIES_INDEX + 1];
  const yValue = (data) => getNumberValue(data[SCATTER_CHART_Y_AXIS_SERIES_INDEX + 1]);
  const colorByValue = (data) => data[SCATTER_CHART_COLOR_BY_SERIES_INDEX + 1];
  const resizeByValue = (data) => getNumberValue(data[SCATTER_CHART_RESIZE_BY_SERIES_INDEX + 1]);
  const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');

  let $chartElement;
  let columnDataToRender;
  let d3XScale;
  let d3YScale;
  let d3Zoom;
  let dataToRender;
  let flyoutDataToRender;
  let lastRenderedSeriesWidth = 0;
  let lastRenderedZoomTranslate = 0;
  let measures;
  let numberOfGroups;
  let numberOfItemsPerGroup;
  let panningClipPathId;
  let renderOptions;
  let xAxisSeries;
  let yAxisSeries;

  const labelResizer = new LabelResizer({
    enabled: false,
    getAxisLabels: () => getAxisLabels(self.getVif()),
    getConfiguredLabelHeight: () => {
      return _.get(self.getVif(), 'configuration.dimensionLabelAreaSize', DEFAULT_LABEL_HEIGHT);
    },
    margins: MARGINS,
    onDrag: () => {
      renderData();
      hideFlyout();
    },
    onDragEnd: (state) => {
      renderData();
      self.emitEvent('SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED', state.overriddenAreaSize);
    }
  });

  _.extend(this, new BaseVisualization($element, vif, options));

  renderTemplate();

  /**
   * Public methods
   */
  this.render = ({ newColumns, newComputedColumns, newData, newVif, newTableVif, renderOptionsArg } = {}) => {
    if (!newData && !dataToRender && !newColumns) {
      return;
    }

    if (renderOptionsArg) {
      renderOptions = renderOptionsArg;
    }

    labelResizer.resetOverride();

    this.clearError();

    if (newVif) {
      this.updateVif(newVif);
    }

    if (newData) {
      dataToRender = newData;

      self.addSeriesIndices(dataToRender);
      self.setDefaultMeasureColumnPrecision(dataToRender);

      columnDataToRender = self.getDataToRenderOfSeriesType(dataToRender, SERIES_TYPE_SCATTER_CHART);
      flyoutDataToRender = self.getDataToRenderOfSeriesType(dataToRender, SERIES_TYPE_FLYOUT);
    }

    if (newTableVif) {
      this.updateSummaryTableVif(newTableVif);
    }

    if (newColumns || newComputedColumns) {
      this.updateColumns(newColumns, newComputedColumns);
    }

    if (self.isOnlyNullValues(dataToRender)) {
      self.renderNoDataError();
      return;
    }

    labelResizer.updateOptions({ enabled: getShowDimensionLabels(self.getVif()) });

    renderData();
  };

  this.invalidateSize = () => {
    if ($chartElement && columnDataToRender) {
      renderData();
    }
  };

  this.destroy = () => {
    d3.select(self.$element[0]).select('svg').remove();

    self.$element.find('.socrata-visualization-container').remove();
  };

  /**
   * Private methods
   */
  function renderTemplate() {
    $chartElement = $('<div>', { class: 'scatter-chart chart-with-label-dragger' });
    labelResizer.renderTemplate($chartElement);

    self.$element.find('.socrata-visualization-chart-container').append($chartElement);
  }

  function getXAxisColumnDetails() {
    const xSeriesColumn = dataToRender.columns[SCATTER_CHART_X_AXIS_SERIES_INDEX + 1];
    return _.find(dataToRender.columnFormats, ['fieldName', xSeriesColumn]);
  }

  function isXAxisNumerical() {
    return _.get(getXAxisColumnDetails(), 'dataTypeName') === 'number';
  }

  function isXAxisBooleanColumn() {
    return _.get(getXAxisColumnDetails(), 'dataTypeName') === 'checkbox';
  }

  function getCircleDiameter() {
    if (renderOptions.resizeByBuckets) {
      return _.get(
        self.getVif(),
        `series[${SCATTER_CHART_RESIZE_BY_SERIES_INDEX}].chartOptions.maximumPlotSize`,
        SCATTER_PLOT_RESIZE_BY_DEFAULT_MAX
      );
    }

    return _.get(self.getVif(), 'configuration.plotSize', SCATTER_PLOT_CIRCLE_SIZE);
  }

  function renderData() {
    const columnWidth = self.isMobile() ? DEFAULT_MOBILE_COLUMN_WIDTH : DEFAULT_DESKTOP_COLUMN_WIDTH;

    const axisLabels = getAxisLabels(self.getVif());
    const dimensionLabelsHeight = getShowDimensionLabels(self.getVif())
      ? labelResizer.computeLabelHeight()
      : 0;

    const topMargin = MARGINS.TOP + (axisLabels.top ? AXIS_LABEL_MARGIN : 0);
    const bottomMargin = MARGINS.BOTTOM + (axisLabels.bottom ? AXIS_LABEL_MARGIN : 0) + dimensionLabelsHeight;
    // viewportHeight: (Used for clipping) Height of the plots plus the height of labels.
    // The height that is currently visible.
    let viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);

    xAxisSeries = _.get(self.getVif(), ['series', SCATTER_CHART_X_AXIS_SERIES_INDEX]);
    yAxisSeries = _.get(self.getVif(), ['series', SCATTER_CHART_Y_AXIS_SERIES_INDEX]);

    const leftMargin =
      self.calculateLeftOrRightMargin({
        dataToRender: columnDataToRender,
        height: viewportHeight,
        isSecondaryAxis: false,
        series: yAxisSeries
      }) + (axisLabels.left ? AXIS_LABEL_MARGIN : 0);
    const rightMargin = MARGINS.RIGHT + (axisLabels.right ? AXIS_LABEL_MARGIN : 0);
    // viewportWidth: (Used for clipping) The width that is currently visible.
    // The user can click and drag to scroll the chart left or right.
    let viewportWidth = Math.max(0, $chartElement.width() - leftMargin - rightMargin);

    // There may be instances where the chart is rendering in a hidden element. If this
    // happens, we shouldn't need to continue through this function. Allowing processing
    // to continue and calling `renderLegend()` would put the chart in an state where
    // parts of the chart don't render properly once they come into view (see EN-40617).
    if (viewportHeight <= 0 || viewportWidth <= 0) {
      return;
    }

    panningClipPathId = `column-chart-clip-path-${_.uniqueId()}`;

    const xValueRange = isXAxisNumerical()
      ? getMinAndMaxValue(_.map(columnDataToRender.rows, xNumericValue))
      : _.map(columnDataToRender.rows, xCategoryValue);
    const yValueRange = getMinAndMaxValue(_.map(columnDataToRender.rows, yValue));

    measures = getMeasures(self, dataToRender);
    lastRenderedZoomTranslate = 0;

    let chartSvg;
    let d3XAxis;
    let d3YAxis;
    let height;
    let seriesSvg;
    let viewportSvg;
    let width;
    let xAxisBound = false;
    let xAxisGridSvg;
    let xAxisSvg;
    let yAxisBound = false;
    let yAxisGridSvg;
    let yAxisSvg;
    let xAxisAndSeriesSvg;
    let xAxisPanDistance;
    let xAxisPanningEnabled;

    function bindAxisOnce(axis = 'x') {
      const isAxisBound = axis === 'x' ? xAxisBound : yAxisBound;
      const d3Axis = axis === 'x' ? d3XAxis : d3YAxis;
      const axisSvg = axis === 'x' ? xAxisSvg : yAxisSvg;
      const axisGridSvg = axis === 'x' ? xAxisGridSvg : yAxisGridSvg;

      if (!isAxisBound) {
        axisSvg.call(d3Axis);

        axisGridSvg.call(
          d3Axis
            .tickSize(axis === 'x' ? height : viewportWidth)
            // tickSize(width).
            .tickFormat('')
        );

        axis === 'x' ? (xAxisBound = true) : (yAxisBound = true);
      }
    }

    function renderAxis(axis = 'x') {
      const axisSvg = axis === 'x' ? xAxisSvg : yAxisSvg;
      const axisGridSvg = axis === 'x' ? xAxisGridSvg : yAxisGridSvg;

      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderYAxis() as idempotent.
      bindAxisOnce(axis);

      axisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');

      axisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      if (axis === 'x') {
        axisSvg
          .selectAll('text')
          .attr('font-family', FONT_STACK)
          .attr('font-size', `${MEASURE_LABELS_FONT_SIZE}px`)
          .attr('fill', MEASURE_LABELS_FONT_COLOR)
          .attr('stroke', 'none')
          .call(self.rotateDimensionLabels, {
            dataToRender: columnDataToRender,
            maxHeight: dimensionLabelsHeight,
            maxWidth: DEFAULT_LABEL_BAND
          });
        hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate });
      } else {
        axisSvg
          .selectAll('text')
          .attr('font-family', FONT_STACK)
          .attr('font-size', `${MEASURE_LABELS_FONT_SIZE}px`)
          .attr('fill', MEASURE_LABELS_FONT_COLOR)
          .attr('stroke', 'none');
      }

      axisGridSvg.selectAll('path').attr('fill', 'none').attr('stroke', 'none');

      axisGridSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_GRID_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    function renderXAxis() {
      chartSvg.select('.x.axis').attr('transform', `translate(0,${height})`);

      renderAxis('x');
    }

    function renderYAxis() {
      chartSvg.select('.y.grid').attr('transform', `translate(${viewportWidth},0)`);

      renderAxis('y');
    }

    function isColorByBooleanColumn() {
      const colorByColumn = dataToRender.columns[SCATTER_CHART_COLOR_BY_SERIES_INDEX + 1];
      const xAxisColumnDetails = _.find(dataToRender.columnFormats, ['fieldName', colorByColumn]);
      return _.get(xAxisColumnDetails, 'dataTypeName') === 'checkbox';
    }

    // Note that renderXAxis, renderYAxis and renderSeries all update the
    // elements that have been created by binding the data (which is done
    // inline in renderData below).
    function renderSeries() {
      const xMap = (data) => {
        return isXAxisNumerical() ? d3XScale(xNumericValue(data)) : d3XScale(xCategoryValue(data));
      };
      const yMap = (data) => d3YScale(yValue(data));

      const dotFillColor = _.get(
        self.getVif(),
        'series[0].color.primary',
        SCATTER_PLOT_CIRCLE_DEFAULT_FILL_COLOR
      );
      const dotDiameter = _.get(self.getVif(), 'configuration.plotSize', SCATTER_PLOT_CIRCLE_SIZE);

      seriesSvg
        .selectAll('.dot')
        .data(columnDataToRender.rows)
        .enter()
        .append('circle')
        .attr('class', 'dot')
        .attr('r', (d) => {
          let diameter;
          if (renderOptions.resizeByBuckets) {
            const resizeByBucket = findBucket(renderOptions.resizeByBuckets, resizeByValue(d));
            diameter = _.get(resizeByBucket, 'size', dotDiameter);
          } else {
            diameter = dotDiameter;
          }
          return diameter / 2;
        })
        .attr('cx', xMap)
        .attr('cy', yMap)
        .attr('data-dimension-index', (d, dimensionIndex) => dimensionIndex)
        .style('stroke', SCATTER_PLOT_CIRCLE_STROKE_COLOR)
        .style('fill', (d) => {
          if (renderOptions.colorByBuckets) {
            const value =
              isColorByBooleanColumn() && _.isNil(colorByValue(d)) ? NULL_CATEGORY_VALUE : colorByValue(d);
            const colorByBucket = getColorByBucket({
              buckets: renderOptions.colorByBuckets,
              value,
              isColorByBooleanColumn: isColorByBooleanColumn()
            });

            return _.get(colorByBucket, 'color', dotFillColor);
          } else {
            return dotFillColor;
          }
        })
        .on('mouseover', function (d) {
          // NOTE: This function depends on `this` being set by d3, so it is not
          // possible to use the () => {} syntax here.
          const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

          showHighlight({ dotElement: this });
          showFlyout({ dotElement: this, renderedRow: d, dimensionIndex });
        })
        .on('mouseout', function () {
          // NOTE: This function depends on `this` being set by d3, so it is not
          // possible to use the () => {} syntax here.
          hideHighlight({ dotElement: this });
          hideFlyout();
        });
    }

    function translateClipPath(lastRenderedZoomTranslate) {
      // We need to override d3's internal translation since it doesn't seem to
      // respect our snapping to the beginning and end of the rendered data.
      d3Zoom.translate([lastRenderedZoomTranslate, 0]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('polygon')
        .attr('transform', `translate(${-lastRenderedZoomTranslate},0)`);

      xAxisAndSeriesSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);
    }

    function handleZoom() {
      lastRenderedZoomTranslate = _.clamp(d3.event.translate[0], -xAxisPanDistance, 0);
      translateClipPath(lastRenderedZoomTranslate);

      if (self.isMobile()) {
        hideHighlight();
        hideFlyout();
      }

      hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate });
    }

    function restoreLastRenderedZoom() {
      const translateXRatio =
        lastRenderedSeriesWidth !== 0 ? Math.abs(lastRenderedZoomTranslate / lastRenderedSeriesWidth) : 0;
      const currentWidth = xAxisAndSeriesSvg.node().getBBox().width;

      lastRenderedZoomTranslate = _.clamp(-translateXRatio * currentWidth, -xAxisPanDistance, 0);

      translateClipPath(lastRenderedZoomTranslate);
    }

    /**
     * 1. Prepare the data for rendering.
     */

    // Render legend
    if (renderOptions.colorByBuckets) {
      const adjustedViewportSize = renderLegendWithLegendItems(self, {
        items: _.sortBy(renderOptions.colorByBuckets, 'index'),
        viewportSize: {
          height: viewportHeight,
          width: viewportWidth
        }
      });

      viewportHeight = adjustedViewportSize.height;
      viewportWidth = adjustedViewportSize.width;
    }

    numberOfGroups = _.uniq(xValueRange).length;
    numberOfItemsPerGroup = columnDataToRender.columns.length - DEFAULT_ITEM_PER_GROUP_LENGTH;

    // width: The width allocated to render all the plots in the chart.
    width = Math.max(viewportWidth, columnWidth * numberOfGroups * numberOfItemsPerGroup);
    height = getAxisHeight(getShowDimensionLabels(self.getVif()), viewportHeight, MARGINS.TOP);

    const circleRadius = getCircleDiameter() / 2;
    const yAxisGlyphSpaceHeight = renderOptions.resizeByBuckets ? circleRadius / 1.05 : circleRadius;

    /**
     * 2. Set up the x-scale and -axis.
     */
    const xAxisNumericalScale = d3.scale
      .linear()
      .domain(xValueRange)
      .range([0, viewportWidth - MARGINS.RIGHT])
      .clamp(true);
    const xAxisCategoricalScale = d3.scale
      .ordinal()
      .domain(xValueRange)
      .rangePoints([0, width - MARGINS.RIGHT]);
    d3XScale = isXAxisNumerical() ? xAxisNumericalScale : xAxisCategoricalScale;
    const isBooleanColumn = isXAxisBooleanColumn();
    const xAxisColumn = _.get(xAxisSeries, 'dataSource.measure.columnName');
    const showNullsAsFalse = _.get(
      self.getVif(),
      'configuration.showNullsAsFalse',
      VIF_CONSTANTS.DEFAULT_SHOW_NULLS_AS_FALSE
    );
    const formatter = (d) => {
      if (isBooleanColumn) {
        if (!showNullsAsFalse && _.isNil(d)) {
          return noValueLabel;
        }

        return d || DEFAULT_BOOLEAN_VALUE;
      } else if (_.isNil(d)) {
        return noValueLabel;
      } else {
        return formatValuePlainText(d, xAxisColumn, dataToRender, true);
      }
    };
    d3XAxis = d3.svg.axis().scale(d3XScale).orient('bottom').tickFormat(formatter);

    /**
     * 3. Set up the y-scale and -axis.
     */
    d3YScale = d3.scale.linear().domain(yValueRange).range([height, yAxisGlyphSpaceHeight]).clamp(true);
    d3YAxis = self.generateYAxis({
      dataToRender,
      height,
      isSecondaryAxis: false,
      scale: d3YScale,
      series: yAxisSeries
    });

    /**
     * 4. Clear out any existing chart.
     */
    d3.select($chartElement[0]).select('svg').remove();

    /**
     * 5. Render the chart.
     */

    // Create the top-level <svg> element first.
    chartSvg = d3
      .select($chartElement[0])
      .append('svg')
      .attr('width', width + leftMargin + rightMargin)
      .attr('height', viewportHeight + topMargin + bottomMargin);

    viewportSvg = chartSvg
      .append('g')
      .attr('class', 'viewport')
      .attr('transform', `translate(${leftMargin},${topMargin})`);

    // The overall effect is for the chart to appear to pan.
    const clipPathSvg = chartSvg.append('clipPath').attr('id', panningClipPathId);
    const maskHeight = viewportHeight + topMargin + bottomMargin;

    const points = [
      `${-circleRadius},0`, // bottom left corner
      `${-circleRadius},${maskHeight}`, // top left corner
      `${viewportWidth},${maskHeight}`, // top right corner
      `${viewportWidth},0` // bottom right corner
    ];

    clipPathSvg.append('polygon').attr('points', points.join(' '));

    // This <rect> exists to capture mouse actions on the chart, but not
    // directly on the columns or labels, that should result in a pan behavior.
    // If we set stroke and fill to none, the mouse events don't seem to get
    // picked up, so we instead set opacity to 0.
    viewportSvg
      .append('rect')
      .attr('class', 'dragger')
      .attr('width', width)
      .attr('height', viewportHeight)
      .attr('opacity', 0);

    yAxisGridSvg = viewportSvg.append('g').attr('class', 'y grid');

    // The x-axis and series are groups since they all need to conform to the
    // same clip path for the appearance of panning to be upheld.
    xAxisAndSeriesSvg = viewportSvg
      .append('g')
      .attr('class', 'x-axis-and-series')
      .attr('clip-path', `url(#${panningClipPathId})`);
    xAxisGridSvg = xAxisAndSeriesSvg.append('g').attr('class', 'x grid');
    yAxisSvg = viewportSvg.append('g').attr('class', 'y axis');
    xAxisSvg = xAxisAndSeriesSvg.append('g').attr('class', 'x axis');
    seriesSvg = xAxisAndSeriesSvg.append('g').attr('class', 'series');

    renderXAxis();
    renderSeries();

    // This is the actual rendered width (which accounts for the labels
    // extending beyond what d3 considers the right edge of the chart on
    // account of their being rotated 45 degrees.
    width = xAxisAndSeriesSvg.node().getBBox().width;

    xAxisPanDistance = width - viewportWidth;
    xAxisPanningEnabled = xAxisPanDistance > 0;
    if (xAxisPanningEnabled) {
      self.showPanningNotice($chartElement.width());

      // Note that we need to recompute height here since
      // $chartElement.height() may have changed when we showed the panning
      // notice.
      viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);
      height = getAxisHeight(getShowDimensionLabels(self.getVif()), viewportHeight, MARGINS.TOP);

      d3YScale = d3.scale.linear().domain(yValueRange).range([height, yAxisGlyphSpaceHeight]).clamp(true);
      d3YAxis = self.generateYAxis({
        dataToRender,
        height,
        isSecondaryAxis: false,
        scale: d3YScale,
        series: yAxisSeries
      });

      renderXAxis();
      renderSeries();
    } else {
      self.hidePanningNotice();
    }

    renderYAxis();

    labelResizer.update(leftMargin, topMargin + height, width);

    if (xAxisPanningEnabled) {
      d3Zoom = d3.behavior.zoom().on('zoom', handleZoom);

      viewportSvg
        .attr('cursor', 'move')
        .call(d3Zoom)
        // By default the zoom behavior seems to capture every conceivable
        // kind of zooming action; we actually just want it to zoom when
        // the user clicks and drags, so we need to immediately deregister
        // the event handlers for the other types.
        //
        // Note that although we listen for the zoom event on the zoom
        // behavior we must detach the zooming actions we do not want to
        // respond to from the element to which the zoom behavior is
        // attached.
        .on('dblclick.zoom', null)
        .on('wheel.zoom', null)
        .on('mousewheel.zoom', null)
        .on('MozMousePixelScroll.zoom', null);

      restoreLastRenderedZoom();

      SvgKeyboardPanning(d3Zoom, chartSvg, '.socrata-visualization-panning-notice');

      chartSvg.selectAll('text').attr('cursor', null);
    } else {
      chartSvg.selectAll('text').attr('cursor', 'default');
    }

    self.renderAxisLabels(chartSvg, {
      x: leftMargin,
      y: topMargin,
      width: viewportWidth,
      height: viewportHeight
    });
  }

  function showHighlight({ dotElement }) {
    // Below is the code for basic Highlighting. The design for highlighting
    // has not yet been decided, so for now, removing highlighting.
    // const selection = d3.select(dotElement);
    // selection.attr('class', 'dot highlight');
  }

  function hideHighlight({ dotElement }) {
    // const selection = d3.select(dotElement);
    // selection.attr('class', 'dot');
  }

  function showFlyout({ dotElement, renderedRow, dimensionIndex }) {
    // We are not required to show the colorBy and renderBy values in the flyouts unless they are
    // configured as additional flyout items.
    const xAndYAxisDataToRender = self.filterDataToRender(dataToRender, (_seriesItem, index) => {
      return index === SCATTER_CHART_X_AXIS_SERIES_INDEX || index === SCATTER_CHART_Y_AXIS_SERIES_INDEX;
    });
    const $content = self.getGroupFlyoutContent({
      dimensionIndex,
      dimensionValue: null,
      flyoutDataToRender,
      measures,
      nonFlyoutDataToRender: xAndYAxisDataToRender,
      title: ''
    });
    const yValueLocation = getValueLocationInScaleAsRatio(d3YScale, yValue(renderedRow));

    const payload = {
      element: dotElement,
      content: $('<div>', { class: 'svg-scatter-chart-flyout-content' }).append($content),
      rightSideHint: false,
      belowTarget: yValueLocation > TIPSY_ANCHOR_THRESHOLD,
      dark: true
    };

    self.emitEvent('SOCRATA_VISUALIZATION_SCATTER_CHART_FLYOUT', payload);
  }

  function hideFlyout() {
    self.emitEvent('SOCRATA_VISUALIZATION_SCATTER_CHART_FLYOUT', null);
  }
}

export default SvgScatterChart;
