import React, { PropsWithChildren } from 'react';
import Modal from 'react-modal';
import { isEmpty } from 'lodash';

import { isTestEnvironment } from 'common/environment';
import airbrake from 'common/airbrake';

import './responsive-modal.scss';

interface Props extends Omit<Modal.Props, 'isOpen'> {
  /** Optional test id to pass to the modal. This can be useful in i.e. cheetah for checking if a modal is being displayed. */
  'data-testid'?: string;

  /** Whether to render the modal over the whole screen. */
  fullScreen?: boolean;

  /**
   * Boolean describing if the modal should be shown or not.
   * Required in the base react-modal class, but optional for backwards compatibility with existing socrata modals.
   */
  isOpen?: boolean;

  /**
   * Function that will be run when the modal is requested
   * to be closed (either by clicking on overlay or pressing ESC).
   * Note: It is not called if isOpen is changed by other means.
   *
   * @see: onRequestClose in http://reactcommunity.org/react-modal/
   */
  onDismiss?: () => void;

  /**
   * Style overrides for the modal.
   */
  styleOverrides?: React.CSSProperties;

  /**
   * Style overrides for the content container.
   */
  stylesContentContainerOverrides?: React.CSSProperties;

  /**
   * Style overrides for the overlay container.
   */
  styleOverlayContainerOverrides?: React.CSSProperties;
}

const fullScreenContentStyles = {
  height: '100%',
  maxWidth: '100%',
  width: '100%'
};

const customStyles: Modal.Styles = {
  // we have to spread the defaultStyles into this because
  // adding a className to the Modal will cause it to not use them anymore
  ...Modal.defaultStyles,
  content: {
    ...Modal.defaultStyles.content,
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '1px',
    boxShadow: '0 0 10px -4px #555',
    width: '100%',
    maxWidth: '500px',
    minHeight: '100px',
    padding: '1rem',
    zIndex: 3000
  },
  overlay: {
    ...Modal.defaultStyles.overlay,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1000
  }
};

// Accessibility: https://reactcommunity.org/react-modal/accessibility/
// If we're in the test env, this won't exist, so we skip it.
if (!isTestEnvironment()) {
  document.addEventListener('DOMContentLoaded', () => {
    const nodes = document.querySelectorAll('[role="main"]');
    if (isEmpty(nodes)) {
      airbrake.notify({
        error: 'AccessibleModal could not find the root element with `[role="main"]`',
        context: { component: 'AccessibleModal' }
      });
    } else {
      Modal.setAppElement(nodes[0] as HTMLElement);
    }
  });
}

const ModalWrapper = React.forwardRef<ReactModal, PropsWithChildren<Props>>((props, ref) => {
  const {
    isOpen,
    fullScreen,
    children,
    className,
    onDismiss,
    styleOverrides,
    styleOverlayContainerOverrides,
    stylesContentContainerOverrides,
    ...rest
  } = props;
  const openedModal = isOpen !== false ? true : false;
  const classes = fullScreen ? `${className} modal-full` : className;
  return (
    <Modal
      className={classes}
      data-testid={props['data-testid']}
      isOpen={openedModal}
      onRequestClose={onDismiss}
      ref={ref}
      style={{
        ...customStyles,
        ...styleOverrides,
        content: {
          ...customStyles.content,
          ...(fullScreen && fullScreenContentStyles),
          ...stylesContentContainerOverrides
        },
        overlay: {
          ...customStyles.overlay,
          ...styleOverlayContainerOverrides
        }
      }}
      {...rest}
    >
      {children}
    </Modal>
  );
});

ModalWrapper.displayName = 'ModalWrapper'; // forwardRef alters the name.

export default ModalWrapper;
