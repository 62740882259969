import type { AccessManagerChangeOwnerState } from '../types';
import type ChangeOwnerActions from '../actions/ChangeOwnerActionType';
import type { CatalogUserOrTeam, UsersCatalogSearchResult } from 'common/types/users/catalogUsers';

import { ChangeOwnerActionTypes } from 'common/components/AccessManager/actions/ChangeOwnerActions';

/*
 * NOTE for this reducer:
 * I was lazy when making this and operated on the assumption
 * that we will only ever have one "true owner" of a dataset.
 * Hence a lot of this is assuming we're working with an array
 * that is always length 1.
 */

/** Called by ChangeOwnerSaga when users are fetched from the catalog  */
const ownerSearchResultsFetchSuccess = (
  state: AccessManagerChangeOwnerState,
  results: UsersCatalogSearchResult
): AccessManagerChangeOwnerState => ({
  ...state,
  results
});

/** Failed to get results from the catalog */
const ownerSearchResultsFetchFail = (
  state: AccessManagerChangeOwnerState,
  error: any
): AccessManagerChangeOwnerState => {
  console.error('Error fetching user search results', error);
  return state;
};

/** User is typing into the search box */
const ownerSearchQueryChanged = (
  state: AccessManagerChangeOwnerState,
  query: string
): AccessManagerChangeOwnerState => ({
  ...state,
  query
});

/** A user is selected from the search results */
const addSelectedOwner = (
  state: AccessManagerChangeOwnerState,
  selectedOwner: CatalogUserOrTeam
): AccessManagerChangeOwnerState => ({
  ...state,
  selectedOwner: [selectedOwner],
  query: ''
});

/** Remove the selected user */
const removeSelectedOwner = (state: AccessManagerChangeOwnerState): AccessManagerChangeOwnerState => ({
  ...state,
  selectedOwner: [],
  query: ''
});

export default (
  state: AccessManagerChangeOwnerState = {} as any,
  action: ChangeOwnerActions
): AccessManagerChangeOwnerState => {
  switch (action.type) {
    case ChangeOwnerActionTypes.OWNER_SEARCH_RESULTS_FETCH_SUCCESS:
      return ownerSearchResultsFetchSuccess(state, action.payload.results);
    case ChangeOwnerActionTypes.OWNER_SEARCH_RESULTS_FETCH_FAIL:
      return ownerSearchResultsFetchFail(state, action.payload.error);
    case ChangeOwnerActionTypes.OWNER_SEARCH_QUERY_CHANGED:
      return ownerSearchQueryChanged(state, action.payload.query);
    case ChangeOwnerActionTypes.ADD_SELECTED_OWNER:
      return addSelectedOwner(state, action.payload.owner);
    case ChangeOwnerActionTypes.REMOVE_SELECTED_OWNER:
      return removeSelectedOwner(state);
    case ChangeOwnerActionTypes.CONFIRM_SELECTED_OWNER:
      // see the AccessManagerReducer for where the owner is actually changed
      // here we're basically "resetting" the change owner component
      return removeSelectedOwner(state);
    default:
      return state;
  }
};
