/* Imports */
/* ============================================================================= */
import { Status } from 'common/core/approvals_enums';
import { WorkflowGuidance } from 'common/types/approvals';

import { SUBMISSION_KEYS_V2 } from 'common/core/approvals/guidanceHelpers';





/* Method */
/* ============================================================================= */
/**
 * Returns the expected outcome of submission to a given workflow.
 * There are 4 expected outcome possibilities:
 *  - "approved" - If the auto-approve setting is turned on for the given workflow
 *  - "pending" - If the manual approval setting is turned on for the given workflow
 *  - "rejected" - If the auto-reject setting is turned on for the given workflow
 *  - `undefined` - If no guidance object was supplied or no expected outcome could be found
 *
 * @param guidance WorkflowGuidance object
 * @returns boolean
 */
export const expectedOutcome = (guidance: WorkflowGuidance): Status | undefined => {
  let response: Status | undefined = undefined;

  if (guidance) {
    let i = 0;

    // Loop through the guidance's submission action objects until we find an expected
    // outcome value
    while (!response && SUBMISSION_KEYS_V2[i]) {
      const key = SUBMISSION_KEYS_V2[i];

      response = guidance[key]?.queueOptions[0].expectedState;
      i++;
    }
  }

  return response;
};

export default expectedOutcome;
