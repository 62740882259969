import { DomainExpectation, DomainExpectationSetting, AssessedDomainExpectation } from './types';

export const settingSatisfied = (setting: DomainExpectationSetting) =>
  setting.current_setting === setting.desired_setting;

export const assessExpectation = (expectation: DomainExpectation): AssessedDomainExpectation => {
  const { settings } = expectation;
  const allSettings = Object.values(settings);
  const satisfiedSettings = allSettings.filter(settingSatisfied);

  return {
    key: expectation.key,
    allSettings,
    satisfiedSettings,
    expectationMet: allSettings.length === satisfiedSettings.length
  };
};

