// Vendor Imports
import get from 'lodash/get';
import React from 'react';

// Project Imports
import CalendarDateParameter from './CalendarDateParameter';
import Parameter from './Parameter';
import { SoQLType } from 'common/types/soql';
import { ParameterConfiguration } from 'common/types/reportFilters';

interface ParameterValueEditorProps {
  parameter: ParameterConfiguration;
  onUpdate: (value: string) => void;
}

const  ParameterValueEditor = (props:  ParameterValueEditorProps): React.ReactElement | null => {
  const { parameter, onUpdate } = props;

  if (!parameter) {
    return null;
  }
  switch (get(parameter, 'dataType')) {
    case SoQLType.SoQLFloatingTimestampT:
    case SoQLType.SoQLFixedTimestampT:
      return <CalendarDateParameter parameter={parameter} onUpdate={onUpdate} />;
    case SoQLType.SoQLNumberT:
    case SoQLType.SoQLTextT:
      return <Parameter parameter={parameter} onUpdate={onUpdate} />;
    default:
      return null;
  }
};

export default  ParameterValueEditor;
