import _ from 'lodash';

import Lines, { LAYERS as LINE_LAYERS } from './partials/Lines';
import PartialWrapper from './partials/PartialWrapper';
import { getBasemapLayerStyles } from '../basemapStyle';

import RenderByHelper from 'common/visualizations/helpers/RenderByHelper';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { getTileUrl } from 'common/visualizations/helpers/MapHelper';

const COLOR_BY_CATEGORY_ALIAS = '__color_by_category__';
const COUNT_ALIAS = '__count__';
const ROWID_ALIAS = '__row_id__';
const WEIGH_BY_ALIAS = '__weigh_by__';

// Prepares renderOptions and renders partials.
// Refer: common/visualizations/views/map/README.md
export default class VifLineOverlay {
  constructor(map, mouseInteractionHandler) {
    const seriesId = _.uniqueId();

    this._lines = new PartialWrapper(map, new Lines(map, seriesId));
    this._mouseInteractionHandler = mouseInteractionHandler;
  }

  // Renders the partials required for this overlay and sets up required mouse interactions.
  // vif            : <object> vif got from AX/visualization
  // renderOptions  : <object> got from this overlays prepare method
  // overlayOptions : object
  // overlayOptions.renderLayersBefore : <string> renders all mapbox-gl-layers for this overlay
  //                                      before given layer-id
  render(vif, renderOptions, overlayOptions) {
    this._lines.render(vif, renderOptions, overlayOptions);

    this._mouseInteractionHandler.register(
      this._lines.getLayerId(LINE_LAYERS.LINES),
      vif,
      renderOptions, {
        highlight: true,
        popupOnMouseOver: !_.isEmpty(vif.getAllFlyoutColumns())
      }
    );
    return renderOptions;
  }

  // Makes required soql calls
  //    * getting top values for coloring by.
  //    * getting range for resizePointsBy buckets.
  // and returns the renderOptions.
  async prepare(vif, metaDataPromise) {
    let getResizeByRange = Promise.resolve(null);
    const colorByColumn = vif.getColorLinesByColumn();
    const weighByColumn = vif.getWeighLinesByColumn();

    if (!_.isNull(weighByColumn)) {
      getResizeByRange = RenderByHelper.getResizeByRange(vif, weighByColumn);
    }

    const [colorByBuckets, resizeByRange, datasetMetadata] = await Promise.all([
      RenderByHelper.getColorByBuckets(vif, colorByColumn, null, metaDataPromise, vif.isCustomColorPalette()),
      getResizeByRange,
      metaDataPromise
    ]);

    return {
      aggregateAndResizeBy: weightBy(vif),
      colorBy: COLOR_BY_CATEGORY_ALIAS,
      colorByBuckets,
      countBy: COUNT_ALIAS,
      datasetMetadata,
      dataUrl: this._getDataUrl(vif, colorByBuckets),
      idBy: ROWID_ALIAS,
      layerStyles: getBasemapLayerStyles(vif),
      legendItems: colorByBuckets,
      resizeByRange
    };
  }

  destroy() {
    this._lines.destroy();
    this._mouseInteractionHandler.unregister(
      this._lines.getLayerId(LINE_LAYERS.LINES)
    );
  }

  _getDataUrl(vif, colorByBuckets) {
    const columnName = vif.getColumnName();
    const colorByColumn = vif.getColorLinesByColumn();
    const weighByColumn = vif.getWeighLinesByColumn();
    const escapedColumnName = SoqlHelpers.escapeColumnName(columnName);

    let conditions = [`{{'${columnName}' column condition}}`];
    const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);
    if (!_.isEmpty(filters)) {
      conditions.push(filters);
    }

    let selects = [`simplify_preserve_topology(snap_to_grid(${escapedColumnName},{snap_precision}),` +
      `{simplify_precision}), min(:id) as ${ROWID_ALIAS}`];
    let groups = [`simplify_preserve_topology(snap_to_grid(${escapedColumnName},` +
      '{snap_precision}),{simplify_precision})'];

    if (_.isString(colorByColumn) && !_.isEmpty(colorByBuckets)) {
      // We are not grouping by colorByColumn. In case that column had 10K unique values,
      // then grouping by the colorByColumn and snapToGrid, will return
      // 10K * snappedToGrid location => number of results. Which will be too much.
      // Instead, we are only interesed in the top x values(colorByCategories) in the colorbyColumn.
      // So we select/group the remaining values as OTHER_COLOR_BY_CATEGORY and the top x in separate groups.

      // We are concatenating empty string to the resizeBy column to convert it to string.
      // Otherwise, depending on whether it is a numeric column or string column, we need to
      // use quotes around values(colorByCategories value) in case statement.
      selects.push(`min(${SoqlHelpers.escapeColumnName(colorByColumn)}||'') as ${COLOR_BY_CATEGORY_ALIAS}`);
    }

    if (_.isString(weighByColumn)) {
      selects.push(`sum(${SoqlHelpers.escapeColumnName(weighByColumn)}) as ${WEIGH_BY_ALIAS}`);
    }
    selects.push(`count(*) as ${COUNT_ALIAS}`);

    const query = `select ${selects.join(',')} ` +
      `where ${conditions.join(' AND ')} ` +
      `group by ${groups.join(',')} ` +
      'limit 200000 ';

    return getTileUrl(vif.getDomain(), vif.getDatasetUid(), query, vif.getSimplificationLevel());
  }
}

function weightBy(vif) {
  const weighByColumn = vif.getWeighLinesByColumn();
  if (_.isString(weighByColumn)) {
    return WEIGH_BY_ALIAS;
  }
  return COUNT_ALIAS;
}
