/* Imports */
/* ============================================================================= */
import { WorkflowGuidance } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Returns a list of domains that this asset will end up federating to if it is approved for public visibility.
 *
 * This is mainly used in the approvals queue UI to show where an asset will federate to when it is approved.
 *
 * @param guidance WorkflowGuidance object
 * @returns Array of domain strings this asset will federated to if made public.
 */
export const sitesThatWillBeFederatedToIfApprovedToPublicV2 = (guidance: WorkflowGuidance) => {
  return guidance.ifApproved?.expectedFederatedTo || [];
};

export default sitesThatWillBeFederatedToIfApprovedToPublicV2;
