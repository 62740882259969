import { each } from 'lodash';
import Actions from 'Actions';
import { createAction, createReducer } from '@reduxjs/toolkit';

export interface BlockNeedsConfirmationState {
  confirmationNeededBlocks: {
    [blockId: string]: boolean;
  };
}

const initialState = {
  confirmationNeededBlocks: {}
} as BlockNeedsConfirmationState;

/* Action Creators */
export const createBlockConfirmation = createAction<string[] | undefined>(Actions.BLOCK_CONFIRMATION_CREATE);
export const updateBlockConfirmation = createAction<string | undefined>(Actions.BLOCK_CONFIRMATION_UPDATE);

const blockRemovalConfirmationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createBlockConfirmation, (state, action) => {
      const { payload } = action;
      const blockIds = payload;
      const confirmationBlocks: Record<string, boolean> = {};

      if (blockIds) {
        each(blockIds, (blockId) => {
          confirmationBlocks[blockId] = true;
        });
        state.confirmationNeededBlocks = confirmationBlocks;
      }
    })
    .addCase(updateBlockConfirmation, (state, action) => {
      const { payload } = action;
      const blockId = payload;
      state.confirmationNeededBlocks[blockId] = true;
    });
});

export default blockRemovalConfirmationReducer;
