import _ from 'lodash';

// Input: Array of featurePopupData
// [
//   {
//     vif,
//     seriesName,
//     count,
//     colorByCategoriesBreakdown: [
//       {
//         vif,
//         category,
//         count,
//         color,
//         charmName
//       },
//       ...
//     ]
//   }
//   ...
// ]
// Output will be of the same format, but the features
export function aggregateFeaturePopupDataFromSameLayers(featurePopupData) {
  return _.chain(featurePopupData).
    groupBy((featurePopupDatum) => {
      return _.get(featurePopupDatum, 'seriesName');
    }).
    map((featurePopupDataInSameLayer, title) => {
      const allColorByBreakdownsInTheLayer = _.chain(featurePopupDataInSameLayer).
        map('colorByCategoriesBreakdown').
        flatten().
        value();

      return {
        vif: featurePopupDataInSameLayer[0].vif,
        seriesName: featurePopupDataInSameLayer[0].seriesName,
        count: _.sumBy(featurePopupDataInSameLayer, (featurePopupData) =>
          parseInt(featurePopupData.count)
        ),
        colorByCategoriesBreakdown: aggregateColorByBreakdown(allColorByBreakdownsInTheLayer)
      };
    }).
    value();
}

function aggregateColorByBreakdown(colorByCategoriesBreakdown) {
  return _.chain(colorByCategoriesBreakdown).
    groupBy((colorByCategoriesBreakdownItem) => {
      return _.get(colorByCategoriesBreakdownItem, 'category');
    }).
    map((groupedColorByCategoriesBreakdown, category) => {
      return {
        ...groupedColorByCategoriesBreakdown[0],
        count: _.sumBy(groupedColorByCategoriesBreakdown, (groupedColorByCategoriesBreakdownItem) => {
          // If count is not available, it means it is not a stack and is a single point.
          return groupedColorByCategoriesBreakdownItem.count ?
            parseInt(groupedColorByCategoriesBreakdownItem.count) :
            1;
        })
      };
    }).
    sortBy((breadkdownEntry) => -1 * breadkdownEntry.count).
    value();
}
