import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isFunction from 'lodash/fp/isFunction';
import omit from 'lodash/fp/omit';

import { SocrataIcon } from 'common/components/SocrataIcon';
import BlockLabel from 'common/components/BlockLabel';
import { SORT_DIRECTION } from 'common/Constants';

import TableColumn from './TableColumn';

const TableHeaderCell = ({ header, headerClassName, isActive, sortDirection, onSort, blockLabelDescription, ...otherProps }) => {
  const baseProps = omit(Object.keys(TableColumn.propTypes), otherProps);
  const isSorted = isFunction(onSort);
  const props = {
    onClick: isSorted ? onSort : null,
    ...baseProps
  };
  const className = cx(
    {
      active: isActive,
      sorted: isSorted,
      ascending: sortDirection === SORT_DIRECTION.ASC && isActive,
      descending: sortDirection === SORT_DIRECTION.DESC && isActive
    },
    headerClassName,
    'results-list-header-cell'
  );
  return (
    <th {...props} className={className} role="columnheader" scope="col" tabIndex="0">
      {blockLabelDescription ?
        <BlockLabel
          title={header}
          titleClassName="column-name"
          description={blockLabelDescription}
        /> :
        <span className="column-name">{header}</span>
      }
      {isSorted ? <SocrataIcon className="ascending-arrow" name="arrow-up2" /> : null}
      {isSorted ? <SocrataIcon className="descending-arrow" name="arrow-down2" /> : null}
    </th>
  );
};

TableHeaderCell.propTypes = {
  header: PropTypes.string,
  headerClassName: PropTypes.string,
  isActive: PropTypes.bool,
  onSort: PropTypes.func,
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTION))
};

TableHeaderCell.defaultProps = {
  isActive: false,
  headerClassName: ''
};

export default TableHeaderCell;
