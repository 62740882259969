import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'common/i18n';
import AlertSettingTab from './AlertSettingTab';

import { ALERT_SETTING_MODAL_TABS } from 'common/notifications/constants';
import './tabs.scss';

class Tabs extends Component {
  render() {
    const { selectedTab, onTabChange, showMyAlertsTab, showWatchlistTab } = this.props;
    const scope = 'shared.site_chrome.notifications.alert_setting_modal.tab';

    return (
      <div className="alert-settings-tabs">
        <ul className="nav">
          <AlertSettingTab
            selectedTab={selectedTab}
            onTabChange={onTabChange}
            type={ALERT_SETTING_MODAL_TABS.NOTIFICATION}
            name={I18n.t(showMyAlertsTab ? 'notification_and_alerts' : 'notification', { scope })} />
          {showMyAlertsTab && <AlertSettingTab
            selectedTab={selectedTab}
            onTabChange={onTabChange}
            type={ALERT_SETTING_MODAL_TABS.MY_ALERTS}
            name={I18n.t('my_alerts', { scope })} />
          }
          {showWatchlistTab && <AlertSettingTab
            selectedTab={selectedTab}
            onTabChange={onTabChange}
            type={ALERT_SETTING_MODAL_TABS.WATCHLIST}
            name={I18n.t('watchlist', { scope })} />
          }
        </ul>
      </div>
    );
  }
}

Tabs.propTypes = {
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  showMyAlertsTab: PropTypes.bool,
  showWatchlistTab: PropTypes.bool
};

export default Tabs;
