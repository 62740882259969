import {
  cloneDeep,
  find,
  flatten,
  forEach,
  get,
  has,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isNull,
  isObject,
  keys,
  map,
  merge,
  startsWith,
  transform,
  uniq,
  values
} from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import { FeatureFlags } from 'common/feature_flags';

import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { appToken } from 'common/http';
import { mapSoqlRowsResponseToTable } from './SoqlHelpers';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';

function SocrataViewDataProvider(userConfig) {
  const config = merge(
    // defaultConfig
    {
      timeout: 5000,
      inDatasetSearchQueryTimeoutSeconds: 30,
      domain: 'example.com'
    },
    userConfig
  );

  config.domain = config.domain || getDefaultDomain();

  /**
   * Public methods
   */

  this.query = (queryConfig) => {
    const self = this;

    return new Promise((resolve, reject) => {
      const handleError = (jqXHR) => {

        reject(
          {
            status: parseInt(jqXHR.status, 10),
            message: jqXHR.statusText,
            soqlError: jqXHR.responseJSON || jqXHR.responseText || '<No response>'
          }
        );
      };

      if (FeatureFlags.valueOrDefault('enable_new_analyzer_query', false)) {
        const datasetUid = queryConfig.currentView.id;
        const query = this.generateQuery(queryConfig);
        const soqlDataProvider = new SoqlDataProvider({ datasetUid }, true);
        soqlDataProvider.invokeSoqlQuery(() => query, []).then((response) => {
          const columnFieldNames = self.getColumnsForQuery(queryConfig).map((column) => {
            return get(column, 'fieldName');
          });
          const table = mapSoqlRowsResponseToTable(columnFieldNames, response);

          resolve(table);
        }).catch((error) => {
          handleError(error);
        });
      } else {
        const url = generateQueryUrl(queryConfig);
        const headers = {
          'Accept': 'application/json',
          'X-Socrata-Federation': 'Honey Badger',
          'X-App-Token': appToken()
        };
        $.ajax({
          url,
          headers,
          method: 'GET',
          success: (response, textStatus, jqXHR) => {
            const columnFieldNames = self.getColumnsForQuery(queryConfig).map((column) => {
              return get(column, 'fieldName');
            });
            const table = mapSoqlRowsResponseToTable(columnFieldNames, response);

            resolve(table);
          },
          error: handleError
        });
      }
    });
  };

  this.getColumnsForQuery = (queryConfig) => {
    const view = get(queryConfig, 'currentView');
    const viewColumns = cloneDeep(get(view, 'columns', []));
    const nonHiddenViewColumns = viewColumns.filter((column) => {
      return !includes(get(column, 'flags', []), 'hidden');
    });

    // EN-23725 - New grid view - aggregating a column to the left of the group by column results
    //            in the data being in the wrong column.
    //
    // The line below previously read `return nonHiddenViewColumns;`, which is fine for non-grouped
    // or -aggregated queries, but grouping and aggregation will cause the grouped column to appear
    // to the left of the aggregated column in the resulting data table. A consequence of uniformly
    // returning the nonHiddenViewColumns was that when the user grouped by a column that occurred
    // to the right of the column being aggregated, this would override the ordering informed by
    // the grouping and aggregation and cause the column labels to be swapped. In this case, we need
    // to actually make sure that all of the grouped columns are on the left and all of the
    // aggregated columns are on the right.
    return orderColumns(nonHiddenViewColumns);
  };

  /**
   * Private methods
   */

  const orderColumns = (columns) => {
    return columns.sort((colA, colB) => {
      const colAIsGrouped = !isNull(get(colA, 'format.drill_down', null));
      const colAIsAggregated = !isNull(get(colA, 'grouping_aggregate', null));
      const colBIsGrouped = !isNull(get(colB, 'format.drill_down', null));
      const colBIsAggregated = !isNull(get(colB, 'grouping_aggregate', null));
      const COL_A_BEFORE_COL_B = -1;
      const COL_B_BEFORE_COL_A = 1;
      const NO_CHANGE = 0;

      if (colAIsGrouped && colBIsGrouped) {
        return NO_CHANGE;
      } else if (colAIsGrouped) {
        return COL_A_BEFORE_COL_B;
      } else if (colBIsGrouped) {
        return COL_B_BEFORE_COL_A;
      } else if (colAIsAggregated && colBIsAggregated) {
        return NO_CHANGE;
      } else if (colAIsAggregated) {
        return COL_B_BEFORE_COL_A;
      } else if (colBIsAggregated) {
        return COL_A_BEFORE_COL_B;
      } else {

        return (get(colA, 'position', Number.MAX_SAFE_INTEGER) <= get(colB, 'position', Number.MAX_SAFE_INTEGER)) ?
          COL_A_BEFORE_COL_B :
          COL_B_BEFORE_COL_A;
      }
    });
  };

  const isDefaultView = (view) => {
    return includes(get(view, 'flags', []), 'default');
  };

  const isValidViewId = (viewId) => {
    return /^\w{4}\-\w{4}$/.test(viewId);
  };

  const getNonHiddenColumns = (view) => {
    return get(view, 'columns', []).filter((column) => !includes(get(column, 'flags', []), 'hidden'));
  };

  const getColumnByColumnId = (view, columnId) => {
    return find(get(view, 'columns', []), { id: columnId });
  };

  const getViewDifference = (currentView, savedView) => {
    // Generic 'give me a diff of two objects' implementation.
    // See: https://gist.github.com/Yimiprod/7ee176597fef230d1451
    const difference = (outerObject, outerBase) => {
      function changes(object, base) {
        return transform(object, function (result, value, key) {
          if (!isEqual(value, base[key])) {
            result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
          }
        });
      }
      return changes(outerObject, outerBase);
    };
    const getChildren = (astNode) => {
      return get(astNode, 'children', []);
    };
    const isOperator = (astNode) => {
      return get(astNode, 'type', '') == 'operator';
    };
    const shouldPrune = (node) => {
      return isEmpty(getChildren(node)) && isOperator(node);
    };
    // This function mutates its argument.
    const pruneAST = (ast) => {

      ast.children = getChildren(ast).
        filter(function (child) {

          if (shouldPrune(child)) {
            pruneAST(child);
          }

          return !shouldPrune(child);
        });

      if (isEmpty(getChildren(ast))) {
        ast = {};
      }
    };
    const removeUnusedOptionsFromFilterCondition = (filterCondition) => {
      var ast = cloneDeep(filterCondition);

      // If there are no actual children, then we can skip this.
      if (has(ast, 'children')) {
        pruneAST(ast);
      }

      // If there are no actual children after we pruned the AST, we can ignore
      // the filterCondition entirely. Setting it to an empty object will cause
      // it to be removed from the diff returned by the parent scope.
      if (isEmpty(get(ast, 'children', []))) {
        ast = {};
      }

      return ast;
    };

    const viewDifference = difference(currentView, savedView);
    const actualColumnsDifference = get(viewDifference, 'columns', []).
      filter(function (column) {
        return !isEmpty(column);
      });

    const keysWhereColumnsAreDifferent = uniq(flatten(map(actualColumnsDifference, keys)));

    if (isEmpty(actualColumnsDifference) || (keysWhereColumnsAreDifferent.length === 1 && keysWhereColumnsAreDifferent[0] === 'renderTypeName')) {
      delete viewDifference.columns;
    }

    if (has(viewDifference, 'query.filterCondition')) {
      var updatedFilterCondition = removeUnusedOptionsFromFilterCondition(get(viewDifference, 'query.filterCondition', {}));

      if (!isEmpty(updatedFilterCondition)) {
        viewDifference.query.filterCondition = updatedFilterCondition;
      } else {
        delete viewDifference.query.filterCondition;
      }
    }

    // consider null, '', and undefined for searchString to be equivalent
    if (isEmpty(currentView.searchString) && isEmpty(savedView.searchString)) {
      if (has(viewDifference, 'searchString')) {
        delete viewDifference.searchString;
      }

      if (has(viewDifference, 'metadata.inDatasetSearch')) {
        delete viewDifference.metadata.inDatasetSearch;
      }
    }

    if (isEmpty(get(viewDifference, 'query', {}))) {
      delete viewDifference.query;
    }

    if (isEmpty(get(viewDifference, 'flags', []))) {
      delete viewDifference.flags;
    }

    if (has(viewDifference, 'metadata.jsonQuery')) {
      delete viewDifference.metadata.jsonQuery;
    }

    if (has(viewDifference, 'metadata.conditionalFormatting')) {
      delete viewDifference.metadata.conditionalFormatting;
    }

    if (has(viewDifference, 'metadata') && isEmpty(get(viewDifference, 'metadata', {}))) {
      delete viewDifference.metadata;
    }

    if (has(viewDifference, 'newBackend')) {
      delete viewDifference.newBackend;
    }

    return viewDifference;
  };

  const queryCurrentViewNotParent = (currentView, savedView) => {
    // checks whether the currentView has changed in ways that require us to direct our query to the parent
    // what ways? well, basically in any way, with one exception:
    // we can chain an *added* (not modded, not removed) search string off the existing view.
    // why bother? it means searching a view will work for folks that don't have read access the the parent view
    const viewDiff = getViewDifference(currentView, savedView);
    if (isEmpty(viewDiff)) return true;
    // if there's a saved query on the view then a change in search is not additive
    if (!isEmpty(savedView.searchString)) return false;
    delete viewDiff.searchString;
    if (has(viewDiff, 'metadata')) {
      delete viewDiff.metadata.inDatasetSearch;
      if (isEmpty(viewDiff.metadata)) {
        delete viewDiff.metadata;
      }
    }

    return isEmpty(viewDiff);
  };

  /* Iterates through each layer of operands in filterCondition to rename the column field names in
   * this structure to the default view's field names using the innermost tableColumnId leaf. */
  function recursivelyRenameToDefaultColumnFieldNames(condition, tableColumnId, parentFieldNameByTableColumnId) {
    if (condition) {
      tableColumnId = get(condition, 'metadata.tableColumnId') || tableColumnId;
      renameToDefaultViewColumnFieldName(condition, tableColumnId, parentFieldNameByTableColumnId);
      forEach(condition.children, child => { // each child represents an operand
        recursivelyRenameToDefaultColumnFieldNames(child, tableColumnId, parentFieldNameByTableColumnId);
      });
    }
  }

  function renameToDefaultViewColumnFieldName(elem, tableColumnId, parentFieldNameByTableColumnId) {
    if (elem.type === 'column' && tableColumnId && elem.columnFieldName) {
      while (typeof (tableColumnId) === 'object') {
        /* Possible representations of condition.metadata.tableColumnId (according to query_json on lenses)
         * 1) tableColumnId: tableColumnId#
         * 2) tableColumnId: {publicationGroup: tableColumnId#}
         * 3) tableColumnId: {publicationGroup: {publicationGroup: tableColumnId#}}
         * 4) tableColumnId: {"undefined": tableColumnId#} */
        tableColumnId = values(tableColumnId)[0];
      }
      elem.columnFieldName = parentFieldNameByTableColumnId[tableColumnId] || elem.columnFieldName;
    }
  }

  this.getViewId = (queryConfig) => {
    const currentView = get(queryConfig, 'currentView');
    const savedView = get(queryConfig, 'savedView');
    const parentView = get(queryConfig, 'parentView');

    const queryCurrentView = queryCurrentViewNotParent(currentView, savedView);
    const currentViewIsDefaultView = isDefaultView(currentView);

    let viewId;

    if (queryCurrentView) {
      viewId = get(currentView, 'id');
    } else {
      viewId = currentViewIsDefaultView || isNull(parentView) ? get(currentView, 'id') : get(parentView, 'id');
    }

    return viewId;
  };

  this.generateQuery = (queryConfig) => {
    const currentView = get(queryConfig, 'currentView');
    const savedView = get(queryConfig, 'savedView');
    const parentView = get(queryConfig, 'parentView');
    const offset = get(queryConfig, 'offset', 0);
    const limit = get(queryConfig, 'limit');
    /**
     *                      | Saved View                       | Ephemeral View                                               |
     * | Default View       | SELECT * against Current View    | SELECT <QUERY> against Default View                          |
     * | Child View         | SELECT * against Current View    | SELECT <QUERY> against Parent View                           |
     * | Grandchild View    | SELECT * against Current View    | SELECT <QUERY> against Parent View (MLID) or SODA1 (No MLID) |
     */
    const queryCurrentView = queryCurrentViewNotParent(currentView, savedView);
    const currentViewIsDefaultView = isDefaultView(currentView);

    const viewId = this.getViewId(queryConfig);

    if (!isValidViewId(viewId)) {
      throw new Error(`the view uid "${viewId}" is not a valid four-by-four.`);
    }

    if (!queryCurrentView && !currentViewIsDefaultView && viewId === get(parentView, 'id')) {
      // EN-31621 If this an unsaved "grandchild" view, change the current view's field column names
      // to the default view's field column names, so that the query against the default view's 4x4
      // will use the correct column names.
      let parentFieldNameByTableColumnId = {};
      forEach(parentView.columns, col => { parentFieldNameByTableColumnId[col.tableColumnId] = col.fieldName; });
      forEach(currentView.columns, col => { col.fieldName = (parentFieldNameByTableColumnId[col.tableColumnId] || col.fieldName); });
      recursivelyRenameToDefaultColumnFieldNames(get(currentView, 'query.filterCondition'), false, parentFieldNameByTableColumnId);
    }

    // Default to reading the query string off the view if it's not saved yet.
    // Saved views should just do a SELECT * against the current view as noted in the comment above.
    const queryString = !queryCurrentView ? get(currentView, 'queryString', null) : null;

    let query = queryString;
    // When doing in-dataset search, the search value is set on `metadata.jsonQuery.search` and `view.searchString`
    // In those cases, even if we already have a SOQL view (i.e. queryString is not null), we
    // want to chain the search off the existing saved query
    const isSearch = !isEmpty(generateSearchClause(currentView));

    if (isEmpty(query)) {
      // EN-29371 - Migrated Views Generating Invalid SoQL
      //
      // In the case where the current view includes grouping and aggregation and is also saved,
      // generateSelectClause will generate the select parameters reflecting the grouping and
      // aggregation but groupByClause will not (since it already returns an empty string if the
      // current view is saved).
      //
      // In the case of a saved view we only want to ever generate a 'select *' anyway, so we
      // can skip generateSelectClause the same way that we skip generateGroupByClause.
      const selectClause = queryCurrentView ? 'select *, :id' : generateSelectClause(currentView);
      const whereClause = queryCurrentView ? '' : generateWhereClause(currentView);
      const groupByClause = queryCurrentView ? '' : generateGroupByClause(currentView);
      const searchClause = generateSearchClause(currentView);
      const orderByClause = generateOrderByClause(currentView);


      let selectColumnIdClause = '';

      if (currentViewIsDefaultView && queryCurrentView) {
        // If no visible columns exist, then selectClause will be 'select '.
        if (selectClause === 'select ' && groupByClause === '') {
          selectColumnIdClause = ':id';
        } else if (groupByClause === '' && !startsWith(selectClause, 'select *, :id')) {
          selectColumnIdClause = ', :id';
        }
      }

      query = `${selectClause}${selectColumnIdClause} ${whereClause} ${groupByClause} ${searchClause} ${orderByClause}`;
    } else if (isSearch) {
      const searchClause = generateSearchClause(currentView);

      query = `${query} |> select * ${searchClause}`;
    }
    const offsetClause = generateOffsetClause(offset);
    const limitClause = generateLimitClause(limit);
    query = `${query} ${offsetClause} ${limitClause}`.replace(/\s{2,}/g, ' ');

    return query;
  };

  const generateQueryUrl = (queryConfig) => {
    const currentView = get(queryConfig, 'currentView');
    const savedView = get(queryConfig, 'savedView');
    const queryCurrentView = queryCurrentViewNotParent(currentView, savedView);

    const query = this.generateQuery(queryConfig);
    let searchString = generateSearchClause(currentView);
    let qt = isEmpty(searchString) ? 30 : config.inDatasetSearchQueryTimeoutSeconds;
    const viewId = this.getViewId(queryConfig);

    // If there is a queryString on the view, we want to enforce a query timeout on the backend so
    // that extremely expensive arbitrary queries will be automatically killed.
    // cate's notes: I don't know that this actually makes sense for when to send this or not, just preserving existing logic
    const skipQueryTimeout =
      isEmpty(searchString) && (queryCurrentView || isEmpty(get(currentView, 'queryString', null)));
    const queryTimeout = skipQueryTimeout ? '' : '&$$query_timeout_seconds=' + qt;

    return `https://${config.domain}/api/id/${viewId}.json?$query=${encodeURIComponent(
      query
    )}${queryTimeout}`;
  };

  const escapeSoqlParameterSingleQuotes = (soqlParameter) => {
    return soqlParameter.replace(/'/g, "''");
  };

  const generateSelectClause = (view) => {
    const soqlDateTruncFunction = (dateTruncFunction) => {

      switch (dateTruncFunction) {
        case 'date_y': return 'date_trunc_y';
        case 'date_ym': return 'date_trunc_ym';
        case 'date_ymd': return 'date_trunc_ymd';
        default: return dateTruncFunction;
      }
    };
    const soqlGroupingAggregate = (groupingAggregate) => {

      switch (groupingAggregate) {
        case 'average': return 'avg';
        case 'minimum': return 'min';
        case 'maximum': return 'max';
        default: return groupingAggregate;
      }
    };
    const columns = getNonHiddenColumns(view);
    const groupingColumns = columns.filter((column) => {
      return !isNull(get(column, 'format.drill_down', null));
    });
    const aggregatingColumns = columns.filter((column) => {
      return !isNull(get(column, 'format.grouping_aggregate', null));
    });
    const nonGroupingOrAggregatingColumns = columns.filter((column) => {

      return (
        isNull(get(column, 'format.drill_down', null)) &&
        isNull(get(column, 'format.grouping_aggregate', null))
      );
    });

    // Return early if we're selecting all columns with no groupings or aggregations.
    if (
      (isEmpty(groupingColumns) && isEmpty(aggregatingColumns)) &&
      (columns.length === get(view, 'columns', []).length)
    ) {
      return 'select *, :id';
    }

    // We want columns that are in the group by clause to come first, then columns
    // that are being aggregated,.
    const select = groupingColumns.
      concat(aggregatingColumns).
      concat(nonGroupingOrAggregatingColumns).
      map((column) => {
        const dateTruncFunction = soqlDateTruncFunction(get(column, 'format.group_function', null));
        const groupingAggregate = soqlGroupingAggregate(get(column, 'format.grouping_aggregate', null));
        const fieldName = get(column, 'fieldName', '');

        if (!isNull(dateTruncFunction)) {
          return `${dateTruncFunction}(\`${fieldName}\`) as \`${fieldName}\``;
        }

        if (!isNull(groupingAggregate)) {
          return `${groupingAggregate}(\`${fieldName}\`) as \`${fieldName}\``;
        }

        return `\`${fieldName}\``;
      });

    return `select ${select.join(', ')}`;
  };

  const generateWhereClause = (view, includeKeyword = true) => {
    const recursivelyGenerateWhereClause = (view, condition) => {
      const type = get(condition, 'type');
      const value = get(condition, 'value');

      // Is there ever a case where 'type' is not 'operator'?
      if (type === 'operator') {

        // Joining children
        if (value === 'AND') {
          // The root node of the filterCondition tree is always an AND node which does not
          // need to be enclosed by parentheses, so just special case this.
          if (get(condition, 'children', []).length === 1) {
            return recursivelyGenerateWhereClause(view, condition.children[0]);
          } else {
            const expression = get(condition, 'children', []).
              map((child) => { return recursivelyGenerateWhereClause(view, child); }).
              filter((expression) => { return !isEmpty(expression); }).
              join(' and ');

            return (expression) ? `(${expression})` : '';
          }
        }

        // Joining children
        if (value === 'OR') {
          const expression = get(condition, 'children', []).
            map((child) => { return recursivelyGenerateWhereClause(view, child); }).
            filter((expression) => { return !isEmpty(expression); }).
            join(' or ');

          return (expression) ? `(${expression})` : '';
        }

        // Constructing an expression
        const columnCondition = find(get(condition, 'children', []), { type: 'column' });

        let columnFieldName;
        let column;

        if (has(columnCondition, 'columnFieldName')) {
          columnFieldName = get(columnCondition, 'columnFieldName');
          column = find(get(view, 'columns', []), { fieldName: columnFieldName });
        } else {
          column = getColumnByColumnId(view, get(columnCondition, 'columnId'));
          columnFieldName = get(column, 'fieldName');
        }

        const literals = get(condition, 'children', []).filter((child) => { return child.type === 'literal'; });
        const quotedColumnFieldName = `\`${columnFieldName}\``;

        const dataTypeName = get(column, 'dataTypeName');
        const isBooleanColumn = dataTypeName === 'checkbox';
        const isNumberColumn = dataTypeName === 'number';
        const isDateColumn = dataTypeName === 'date';
        const isTextColumn = dataTypeName === 'text';

        const quotedValues = literals.map((literal) => {
          if (isNumberColumn) {
            return (literal.value);
          } else if (isDateColumn) {
            var date = literal.value;
            if (moment(literal.value, 'MM-DD-YYYY hh:mm:ss a').isValid()) {
              date = moment(date, 'MM-DD-YYYY hh:mm:ss a');
            }
            return (`'${escapeSoqlParameterSingleQuotes(date.format())}'`);
          } else {
            return (`'${escapeSoqlParameterSingleQuotes(literal.value)}'`);
          }
        });

        switch (value) {
          case 'EQUALS':
            return (isTextColumn) ?
              `upper(${quotedColumnFieldName}) = upper(${quotedValues[0]})` :
              `${quotedColumnFieldName} = ${quotedValues[0]}`;
          case 'NOT_EQUALS':
            return (isTextColumn) ?
              `(upper(${quotedColumnFieldName}) != upper(${quotedValues[0]}) OR ${quotedColumnFieldName} IS NULL)` :
              `(${quotedColumnFieldName} != ${quotedValues[0]} OR ${quotedColumnFieldName} IS NULL)`;
          case 'STARTS_WITH':
            return `starts_with(upper(${quotedColumnFieldName}), upper(${quotedValues[0]}))`;
          case 'CONTAINS':
            return `contains(${quotedColumnFieldName}, ${quotedValues[0]})`;
          case 'NOT_CONTAINS':
            return `not contains(${quotedColumnFieldName}, ${quotedValues[0]})`;
          case 'CONTAINS_INSENSITIVE':
            return `contains(upper(${quotedColumnFieldName}), upper(${quotedValues[0]}))`;
          case 'NOT_CONTAINS_INSENSITIVE':
            return `not contains(upper(${quotedColumnFieldName}), upper(${quotedValues[0]}))`;
          case 'LESS_THAN':
            return `${quotedColumnFieldName} < ${quotedValues[0]}`;
          case 'LESS_THAN_OR_EQUALS':
            return `${quotedColumnFieldName} <= ${quotedValues[0]}`;
          case 'GREATER_THAN':
            return `${quotedColumnFieldName} > ${quotedValues[0]}`;
          case 'GREATER_THAN_OR_EQUALS':
            return `${quotedColumnFieldName} >= ${quotedValues[0]}`;
          case 'BETWEEN':
            return `${quotedColumnFieldName} >= ${quotedValues[0]} and ${quotedColumnFieldName} <= ${quotedValues[1]}`;
          case 'IS_BLANK':
            return (isBooleanColumn) ?
              `${quotedColumnFieldName} is null OR ${quotedColumnFieldName} = false` :
              `${quotedColumnFieldName} is null`;
          case 'IS_NOT_BLANK':
            return (isBooleanColumn) ?
              `${quotedColumnFieldName} = true` :
              `${quotedColumnFieldName} is not null`;
          default:
            return '';
        }
      }
    };

    const wheres = recursivelyGenerateWhereClause(view, get(view, 'query.filterCondition', {}));

    if (wheres) {
      return includeKeyword ? `where ${wheres}` : `${wheres}`;
    }

    return '';
  };

  const generateGroupByClause = (view) => {
    const groupBys = get(view, 'columns', []).
      filter((column) => {

        return (
          !includes(get(column, 'flags', []), 'hidden') &&
          !isNull(get(column, 'format.drill_down', null))
        );
      }).
      map((column) => {
        return `\`${get(column, 'fieldName', '')}\``;
      }).
      join(', ');

    if (groupBys) {
      return `group by ${groupBys}`;
    }

    return '';
  };

  const generateSearchClause = (view) => {
    const searchString = get(view, 'searchString', null);

    if (isEmpty(searchString)) {
      return '';
    }

    return `search '${escapeSoqlParameterSingleQuotes(searchString)}'`;
  };

  const generateOrderByClause = (view) => {
    const orderBys = get(view, 'query.orderBys', []).
      map((orderBy) => {
        const columnId = get(orderBy, 'expression.columnId');
        const column = getColumnByColumnId(view, columnId);
        const columnFieldName = get(column, 'fieldName', '');
        const direction = (get(orderBy, 'ascending', true)) ? 'asc' : 'desc';

        return `\`${columnFieldName}\` ${direction}`;
      }).
      join(', ');

    if (orderBys) {
      return `order by ${orderBys}`;
    }

    return '';
  };

  const generateOffsetClause = (offset) => {

    if (offset !== 0) {
      return `offset ${offset}`;
    }

    return '';
  };

  const generateLimitClause = (limit) => {
    if (limit) {
      return `limit ${limit}`;
    } else {
      return '';
    }
  };

  const mapViewsResponseToTable = (response) => {
    const view = get(response, 'meta.view');
    const columns = get(view, 'columns', []).filter((column) => {
      const flags = get(column, 'flags', []);

      return (
        get(column, 'fieldName[0]', '') !== ':' &&
        (
          !isArray(flags) || (isArray(flags) && flags.indexOf('hidden') === -1)
        )
      );
    });
    const columnsSortedByPosition = orderColumns(columns);
    const rows = get(response, 'data', []);
    const rowIds = rows.map((row) => {
      return String(get(row, 'id', null));
    });
    const transformedRows = rows.map(function (row) {

      return columnsSortedByPosition.map(function (column) {
        return get(row, get(column, 'id'), null);
      });
    });

    return {
      columns: columnsSortedByPosition.map((column) => get(column, 'fieldName', '')),
      rows: transformedRows,
      rowIds
    };
  };

  // for unit testing only
  this.__testonly__ = {
    'getViewDifference': getViewDifference
  };
}

export default SocrataViewDataProvider;
