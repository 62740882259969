export const ANNOTATIONS = {
  CIRCLE_COLOR: '#545b60',
  CIRCLE_RADIUS: 10,
  CIRCLE_PADDING: 2,
  TEXT_FONT_SIZE: '10px',
  TEXT_FILL_COLOR: '#ffffff',
  TEXT_COUNT_INDEX: 2,
  DATE_INDEX: 0,
  DESCRIPTION_INDEX: 1
};
export const AXIS_DEFAULT_COLOR = '#979797';
export const AXIS_GRID_COLOR = '#f1f1f1';
export const AXIS_LABEL_COLOR = '#767676';
export const AXIS_LABEL_FONT_SIZE = 16;
export const AXIS_LABEL_MARGIN = 42;
export const AXIS_LABEL_TEXT_MARGIN = 14;
export const AXIS_TICK_COLOR = '#adadad';
export const CALENDAR_DATA_RETRIEVAL_DAY_PADDING = 10;
export const CALENDAR_START_DATE_COLUMN_SERIES_INDEX = 0;
export const CALENDAR_END_DATE_COLUMN_SERIES_INDEX = 1;
export const CALENDAR_EVENT_TITLE_COLUMN_SERIES_INDEX = 2;

// These are the Forge colors
export const COLOR_PALETTES = {
  categorical: ['#a6cee3', '#5b9ec9', '#2d82af', '#7eba98', '#98d277', '#52af43', '#6f9e4c', '#dc9a88', '#f16666', '#e42022', '#f06c45', '#fdbb69', '#fe982c', '#f78620', '#d9a295', '#b294c7', '#7d54a6', '#9e8099', '#f0eb99', '#dbb466', '#969696'],
  categorical2: ['#5b9ec9', '#98d277', '#f16666', '#fdbb69', '#b294c7', '#f0eb99', '#2d82af', '#52af43', '#dc9a88', '#fe982c', '#7d54a6', '#dbb466', '#a6cee3', '#6f9e4c', '#f06c45', '#9e8099', '#7eba98', '#e42022', '#d9a295', '#f78620', '#969696'],
  categorical3: ['#3f51b5', '#e55100', '#ffb300', '#448aff', '#c2185b', '#4caf50', '#7c4eff', '#80cbc4', '#f06292', '#ff9100', '#fdd735', '#9ccc65', '#546e7a', '#9575cd', '#a5d6a7', '#00897b', '#9fa8da', '#9c27b0', '#558b2f', '#004d40', '#969696'],
  alternate1: ['#e41a1c', '#9e425a', '#596a98', '#3b87a2', '#449b75', '#4daf4a', '#6b886d', '#896191', '#ac5782', '#d56b41', '#ff7f00', '#ffb214', '#ffe528', '#eddd30', '#c9992c', '#a65628', '#c66764', '#e678a0', '#e485b7', '#be8fa8', '#969696'],
  alternate2: ['#66c2a5', '#9aaf8d', '#cf9c76', '#f68d67', '#cf948c', '#a89bb0', '#969dca', '#b596c7', '#d58ec4', '#dd95b2', '#c6b18b', '#afcc64', '#b7d84c', '#d6d83f', '#f6d832', '#f8d348', '#efcc6b', '#e6c58e', '#d5be9d', '#c4b8a8', '#969696'],
  accent: ['#7fc97f', '#96bf9e', '#adb5bd', '#c4afcb', '#dbb6af', '#f3bd92', '#fdcd8a', '#fee491', '#fefb98', '#c0d0a0', '#769aa8', '#4166ad', '#853f9b', '#c91889', '#e8106e', '#d63048', '#c45121', '#a75d2b', '#866148', '#666666', '#969696'],
  dark: ['#1b9e77', '#5d874e', '#a07125', '#d45f0a', '#b16548', '#8e6b86', '#8068ae', '#a850a0', '#d03792', '#d33b79', '#a66753', '#79932e', '#7fa718', '#aca80e', '#d9aa04', '#d69d08', '#bf8b12', '#a9781b', '#927132', '#7c6b4c', '#969696']
};

export const DATE_LABEL_TRANSLATIONS = {
  'Jan': 'january_short',
  'Feb': 'february_short',
  'Mar': 'march_short',
  'Apr': 'april_short',
  'May': 'may',
  'Jun': 'june_short',
  'Jul': 'july_short',
  'Aug': 'august_short',
  'Sep': 'september_short',
  'Oct': 'october_short',
  'Nov': 'november_short',
  'Dec': 'december_short',
  'January': 'january',
  'February': 'february',
  'March': 'march',
  'April': 'april',
  'June': 'june',
  'July': 'july',
  'August': 'august',
  'September': 'september',
  'October': 'october',
  'November': 'november',
  'December': 'december',
  'Mon': 'monday_short',
  'Tue': 'tuesday_short',
  'Wed': 'wednesday_short',
  'Thu': 'thursday_short',
  'Fri': 'friday_short',
  'Sat': 'saturday_short',
  'Sun': 'sunday_short',
  'Monday': 'monday',
  'Tuesday': 'tuesday',
  'Wednesday': 'wednesday',
  'Thursday': 'thursday',
  'Friday': 'friday',
  'Saturday': 'saturday',
  'Sunday': 'sunday'
};
export const D3_TICK_PADDING = 2;
export const D3_TICK_SIZE = 6;
export const DATA_POINT_LABELS_FONT_COLOR = '#5e5e5e';
export const DATA_POINT_LABELS_FONT_SIZE = 11;
export const DATA_POINT_LABELS_OFFSET_ABOVE = -12;
export const DATA_POINT_LABELS_OFFSET_ABOVE_MORE = -26;
export const DATA_POINT_LABELS_OFFSET_BELOW = 20;
export const DATA_POINT_LABELS_OFFSET_BELOW_MORE = 34;
export const DEFAULT_DESKTOP_COLUMN_WIDTH = 14;
export const DEFAULT_MOBILE_COLUMN_WIDTH = 28;
export const DEFAULT_HIGHLIGHT_COLOR = '#cccccc';
export const DEFAULT_EMBED_SIZE_INDEX = 3;
export const DEFAULT_LEGEND_TEXT_ATTRIBUTES = {
  fontFamily: '"Open Sans", "Helvetica", sans-serif',
  fontSize: 12,
  fill: '#818181'
};
// Embed sizes, in the order they should be presented in the UI.
export const EMBED_SIZES = [
  { name: 'small', width: 300, height: 225, className: 'small-size-embed-vertical-filter-bar' }, // 4:3
  { name: 'medium', width: 400, height: 300, className: 'medium-size-embed-vertical-filter-bar' }, // 4:3
  { name: 'large', width: 800, height: 600, className: 'large-size-embed-vertical-filter-bar' }, // 4:3
  { name: 'wide', width: 1000, height: 625, className: 'wide-size-embed-vertical-filter-bar' } // 8:5
];
export const DEFAULT_CIRCLE_HIGHLIGHT_RADIUS = 5;
export const DEFAULT_LINE_HIGHLIGHT_FILL = 'rgba(44, 44, 44, 0.18)';
export const DEFAULT_PRIMARY_COLOR = '#3f51b5';
export const DEFAULT_ROTATION_ANGLE = 90;
export const DEFAULT_ROTATION_ANGLE_BAR_CHART = 0;
export const DEFAULT_SECONDARY_COLOR = '#e55100';
export const DIMENSION_INDEX = 0;
export const DIMENSION_LABELS_DEFAULT_HEIGHT = 80;
export const DIMENSION_LABELS_DEFAULT_WIDTH = 115;
export const DIMENSION_LABELS_FIXED_HEIGHT = 88;
export const DIMENSION_LABELS_FONT_COLOR = '#5e5e5e';
export const DIMENSION_LABELS_FONT_SIZE = 14;
export const DIMENSION_LABELS_LINE_HEIGHT_EMS = 1.1;
export const DIMENSION_LABELS_MAX_CHARACTERS = 8;
export const DIMENSION_LABELS_X_TRANSLATION = 10;
export const DIMENSION_LABELS_Y_TRANSLATION = 10;
export const DRILLDOWN_ANIMATION_DURATION = 500;
export const ERROR_BARS_DEFAULT_BAR_COLOR = '#767676';
export const ERROR_BARS_MAX_END_BAR_LENGTH = 18;
export const ERROR_BARS_STROKE_WIDTH = 2;
export const FONT_STACK = '"Roboto", "Helvetica", Arial, sans-serif';
export const GLYPH_SPACE_HEIGHT = 5;
export const LABEL_PADDING_WIDTH = 15;
export const LABEL_PERCENT_WIDTH = 12;
export const LEGEND_BAR_HEIGHT = 35;
export const LEGEND_PANE_SMALL_WIDTH = 120;
export const LEGEND_PANE_WIDTH = 170;
export const LEGEND_POSITION_LEFT = 'left';
export const LEGEND_POSITION_RIGHT = 'right';
export const LEGEND_RECT_SIZE = 16;
export const LINE_DASH_ARRAY = '10, 10';
export const LINE_STYLE_POINTS_CLOSED = 'closed';
export const LINE_STYLE_POINTS_NONE = 'none';
export const DESKTOP_SCREEN_BREAKPOINT = 992;
export const TABLE_SCREEN_BREAKPOINT = 768;
export const MOBILE_SCREEN_BREAKPOINT = 420;
export const MEASURE_AXIS_SCALE_MIN_TO_MAX = 'min_to_max';
export const MEASURE_LABELS_FONT_COLOR = '#5e5e5e';
export const MEASURE_LABELS_FONT_SIZE = 14;
export const MEASURE_VALUE_TEXT_INSIDE_BAR_COLOR = '#f8f8f8';
export const MEASURE_VALUE_TEXT_OUTSIDE_BAR_COLOR = '#5e5e5e';
export const MEASURE_VALUE_TEXT_Y_SHIFT = -1;
export const MINIMUM_LABEL_WIDTH = 35;
export const MINIMUM_X_AXIS_TICK_DISTANCE = 100;
export const MINIMUM_Y_AXIS_TICK_DISTANCE = 40;
export const REFERENCE_LINES_STROKE_DASHARRAY = '6,3';
export const REFERENCE_LINES_STROKE_WIDTH = 2;
export const REFERENCE_LINES_UNDERLAY_THICKNESS = 8;
export const SCATTER_PLOT_CIRCLE_DEFAULT_FILL_COLOR = '#aaaaaa';
export const SCATTER_PLOT_CIRCLE_SIZE = 8;
export const SCATTER_PLOT_RESIZE_BY_DEFAULT_MIN = 10;
export const SCATTER_PLOT_RESIZE_BY_DEFAULT_MAX = 20;
export const SCATTER_PLOT_RESIZE_BY_DEFAULT_BUCKETS_COUNT = 4;
export const SCATTER_PLOT_CIRCLE_STROKE_COLOR = '#000000';
export const SCATTER_CHART_X_AXIS_SERIES_INDEX = 0;
export const SCATTER_CHART_Y_AXIS_SERIES_INDEX = 1;
export const SCATTER_CHART_COLOR_BY_SERIES_INDEX = 2;
export const SCATTER_CHART_RESIZE_BY_SERIES_INDEX = 3;
export const SERIES_TYPE_BAR_CHART = 'barChart';
export const SERIES_TYPE_COLUMN_CHART = 'columnChart';
export const SERIES_TYPE_COMBO_CHART_COLUMN = 'comboChart.column';
export const SERIES_TYPE_COMBO_CHART_LINE = 'comboChart.line';
export const SERIES_TYPE_DATA_TABLE = 'dataTable';
export const SERIES_TYPE_AG_GRID_TABLE = 'agTable';
export const SERIES_TYPE_FLYOUT = 'flyout';
export const SERIES_TYPE_MAP = 'map';
export const SERIES_TYPE_SCATTER_CHART = 'scatterChart';
export const SERIES_TYPE_TABLE = 'table';
export const SERIES_TYPE_PIE_CHART = 'pieChart';
export const SERIES_TYPE_TIMELINE_CHART = 'timelineChart';
export const SERIES_TYPE_HISTOGRAM = 'histogram';
export const SMALL_VIEWPORT_WIDTH = 400;
export const TEXT_ANCHOR_END = 'end';
export const TEXT_ANCHOR_START = 'start';
export const TIPSY_ANCHOR_THRESHOLD = 0.5;
export const VALUE_LABEL_FONT_SIZE = 10;
export const VALUE_LABEL_MARGIN = 3;
export const VIF_UPDATE_DEBOUNCE_MILLI_SECONDS = 100;
export const WINDOW_RESIZE_RERENDER_DELAY = 200;
