import React, { Component, ReactElement } from 'react';
import set from 'lodash/set';
import get from 'lodash/get';

import airbrake from 'common/airbrake';
import ToastNotification, { SocrataToastProps } from './index';
import { ToastType } from './ToastType';
import { ForgeToast, ForgeIcon } from '@tylertech/forge-react';
import { ForgeToastOptions } from '@tylertech/forge-react/typings/components';

/** Namespace that toasts are stored under in sessionStorage */
const SESSIONSTORAGE_NAMESPACE = 'socrata:toastmaster';

/** Default timeout if none is supplied for a given toast */
const DEFAULT_TIMEOUT = 5000;

type Toast = SocrataToastProps & {
  timeout?: number;
  content: string | ReactElement;
  forgeOptions?: ForgeToastOptions;
  hideId?: string;
};

/** This will grab all of the toasts out of sessionStorage and add some defaults */
const getToastsFromSessionStorage = () => (
  JSON.parse(window.sessionStorage.getItem(SESSIONSTORAGE_NAMESPACE) || '[]').map((toast: Toast) => (
    {
      ...toast,
      showNotification: true
    }
  ))
);

/**
 * Adds a toast to sessionStorage that will get picked up on the next page load
 * @param {object} toastProps Toast properties; should at least have "content" but anything ToastNotification accepts is fine
 */
const showToastOnPageReload = (toastProps: Toast) => {
  const currentToasts = JSON.parse(window.sessionStorage.getItem(SESSIONSTORAGE_NAMESPACE) || '[]');
  currentToasts.push(toastProps);
  window.sessionStorage.setItem(SESSIONSTORAGE_NAMESPACE, JSON.stringify(currentToasts));
};

/**
 * Show a toast right now; this actually just calls window.socrata.showToast (shhh)
 * @param {object} toastProps Toast properties; should at least have "content" but anything ToastNotification accepts is fine
 */
const showToastNow = (toastProps: Toast) => {
  const showToast = get(window, 'socrata.showToast');
  if (typeof showToast === 'function') {
    showToast(toastProps);
  } else {
    // If you see this error, make sure the Toastmaster component has been rendered on the page.
    // It's likely that your application isn't rendering a #toastmaster DIV for site-wide.js to
    // find.
    airbrake.notify({ error: 'window.socrata.showToast is not a function' });
  }
};

/**
 * If you set a 'hideId' on your toasts, you can manually dismiss it with this method.
 * Actually just calls window.socrata.hideToast
 * @param {string} hideId optional parameter that can be passed when showing a toast, used to discover which toast to hide
 */
export const hideToastById = (hideId: string) => {
  const hideToast = get(window, 'socrata.hideToast');
  if (typeof hideToast === 'function') {
    hideToast(hideId);
  } else {
    // If you see this error, make sure the Toastmaster component has been rendered on the page.
    // It's likely that your application isn't rendering a #toastmaster DIV for site-wide.js to
    // find.
    airbrake.notify({ error: 'window.socrata.hideToast is not a function' });
  }
};

/**
 * Concise helper functions
 */
export const showSuccessToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.SUCCESS, content });
export const showErrorToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.ERROR, content });
export const showDefaultToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.DEFAULT, content });
export const showWarningToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.WARNING, content });
export const showInfoToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.INFO, content });
export const showForgeDefaultToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.FORGE_DEFAULT, content });
export const showForgeErrorToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.FORGE_ERROR, content });
export const showForgeSuccessToastNow = (content: string | ReactElement) => showToastNow({ type: ToastType.FORGE_SUCCESS, content });

export const showSuccessToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.SUCCESS, content });
export const showErrorToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.ERROR, content });
export const showDefaultToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.DEFAULT, content });
export const showWarningToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.WARNING, content });
export const showInfoToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.INFO, content });
export const showForgeDefaultToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.FORGE_DEFAULT, content });
export const showForgeErrorToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.FORGE_ERROR, content });
export const showForgeSuccessToastOnPageReload = (content: string | ReactElement) => showToastOnPageReload({ type: ToastType.FORGE_SUCCESS, content });

const isForgeToast = (toast: Toast) => (
  toast.type && [ToastType.FORGE_DEFAULT, ToastType.FORGE_ERROR, ToastType.FORGE_SUCCESS].includes(toast.type)
);
interface Props {}

interface State {
  toasts: Toast[]
}
/**
 * This is intended to only be rendered once on every page and handles getting toast notifications out of
 * sessionStorage and displaying them to the user.
 *
 * This will also add a window.socrata.showToast function that can be called to show a toast for a given amount of time.
 *
 * (This component is actually rendered in common/site_wide.js)
 */
class Toastmaster extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // grab all the toasts out of sessionStorage
      toasts: getToastsFromSessionStorage()
    };

    // remove the toasts from sessionStorage so they don't show on the next page load
    window.sessionStorage.removeItem(SESSIONSTORAGE_NAMESPACE);

    // add a timeout for each toast that updates that toast's state to hide it
    this.state.toasts.forEach(this.setTimeoutForToast);
  }

  componentDidMount() {
    // create the window.socrata.showToast function that adds a toast to this component's state
    set(window, 'socrata.showToast', (toast: Toast) => {
      // we need to find this later via indexOf,
      // so we have to put the same object into the state that we hide later
      const visibleToast = {
        ...toast,
        showNotification: true
      };

      this.setState({
        toasts: [
          ...this.state.toasts,
          visibleToast
        ]
      });

      this.setTimeoutForToast(visibleToast);
    });

    set(window, 'socrata.hideToast', (hideId: string) => {
      const toasts = [...this.state.toasts];
      const toastsToHide = toasts.filter(t => t.hideId === hideId);
      toastsToHide.map(t => {
        toasts[toasts.indexOf(t)] = {
          ...t,
          showNotification: false
        };
      });
      if (toastsToHide.length > 0) {
        this.setState({
          toasts
        });
      }
    });
  }

  componentWillUnmount() {
    // "remove: the window.socrata.showToast function
    set(window, 'socrata.showToast', (toast: Toast) => { console.warn('Toastmaster is unmounted but got toast', toast); });
  }

  /**
   * Sets a timeout for toast.timeout that will update this component's state to set toast.showNotification to false.
   * If the toast does not have a configured timeout, DEFAULT_TIMEOUT is used instead.
   */
  setTimeoutForToast = (toast: Toast) => {
    // forge toasts manage their own dismissal
    if (!isForgeToast(toast)) {
      setTimeout(() => this.hideToast(toast), toast.timeout || DEFAULT_TIMEOUT);
    }
  };

  /**
   * Update this component's state to have the given toast's "showNotification" property set to false.
   *
   * Note that "indexOf" is used here which does strict equality comparison (===) so if the toast
   * being passed in is a different object than the one that was originally added, this will NOT work
   */
  hideToast = (toast: Toast) => {
    const toasts = [...this.state.toasts];

    toasts[toasts.indexOf(toast)] = {
      ...toast,
      showNotification: false
    };

    this.setState({
      toasts
    });
  };

  forgeIconForType = (toastType: ToastType | undefined) => {
    switch (toastType) {
      case ToastType.FORGE_SUCCESS:
        return <ForgeIcon name='check' className='toast-success-icon'/>;
      case ToastType.FORGE_ERROR:
        return <ForgeIcon name='error' className='toast-error-icon'/>;
      default:
        return;
    }
  };

  render() {
    const { toasts } = this.state;
    const doNothing = () => {};
    const toastItems = toasts.map(
      (toast, key) => {
        if (isForgeToast(toast)) {
          const message = toast.forgeOptions?.message || toast.content;
          const forgeOptions = {
            placement: 'top',
            ...(toast.timeout) && {duration: toast.timeout}, // if no timeout is passed, fall back to forgeOptions, or forge default
            ...toast.forgeOptions,
            id: 'socrata-forge-toast'
          };
          return (
            <ForgeToast
              key={key}
              open={toast.showNotification === undefined ? true : toast.showNotification}
              onDismiss={toast.onDismiss || doNothing}
              options={forgeOptions}
              {...toast}
            >
              <div className='message-container'>
                <span className='toast-icon'>{this.forgeIconForType(toast.type)}</span>
                <span>{message}</span>
              </div>
            </ForgeToast>
          );
        } else {
          return (
            <ToastNotification
              key={key}
              {...toast} // up here so that its onDismiss doesn't override the one below
              onDismiss={() => {
                if (toast.onDismiss) {
                  // whatever the custom onDismiss is
                  toast.onDismiss();
                }
                // since this is managed by Toastmaster, remove from Toastmaster state
                this.hideToast(toast);
              }}
              >
              <span className="toast-content">{toast.content}</span>
            </ToastNotification>
          );
        }
      }
    );
    return (
        <div aria-live="assertive">
          {toastItems}
        </div>
      );
  }
}

export { showToastOnPageReload, showToastNow, Toastmaster };
export { ToastType } from './ToastType';
