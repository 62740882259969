import { SoQLType } from 'common/types/soql';
import _ from 'lodash';
import React from 'react';
import { none, Option, some } from 'ts-option';
import { ForgeAutocomplete, ForgeTextField } from '@tylertech/forge-react';
import { getSoqlTypeForgeIconBuilder } from '../lib/forge-icon-builder';
import { IAutocompleteOption } from '@tylertech/forge';
import { fetchTranslation } from 'common/locale';
import { ForgeTooltip, ForgeIcon } from '@tylertech/forge-react';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_nodes');

export interface Pickable {
  name: string;
  dataType: SoQLType | null;
  onSelect: () => void;
  group?: string;
  subTitle?: string;
}

export interface PickerSelectedValueFormatting {
  label: string; // text that will be displayed when the option is selected
  leadingIconName?: string; // optional leading icon on the selected value
}

export interface Props {
  className?: string;
  pickables: Pickable[]
  /* Restrict pickable options shown in dropdown by soql type.
   * Only options that adhere to soql types in the
   * array will be shown to users. Undefined or empty means
   * that there are no restrictions. */
  soqlTypeConstraints?: SoQLType[];
  /* Show the picker with this prompt when nothing is selected. */
  prompt: string;
  /* Optional test id */
  dataTestId?: string;
  /* Show the picker with this label and optional icon when a selection has been made. */
  formatSelected: Option<PickerSelectedValueFormatting>;
  /* Will cause the autocomplete to be open. This is to allow for testing
   * since currently testing an async autocomplete is pretty impossible, maybe with forge 3
   * so I know this sucks but I would rather be able to test this component than not */
  autoCompleteOpen?: boolean;
}

type PickerProps = Props;

class Picker extends React.Component<PickerProps> {
  picker: HTMLButtonElement;

  onSelection = (e: CustomEvent) => {
    e.detail.onSelect();
  };

  render = () => {
    const { soqlTypeConstraints, pickables, prompt, dataTestId, autoCompleteOpen } = this.props;
    const hasSoqlTypeConstraints = soqlTypeConstraints && !_.isEmpty(soqlTypeConstraints);

    const selectedFormatting = this.props.formatSelected.getOrElseValue({label: prompt});

    const createPicklist = (groupName?: string) => {
      return pickables
        .filter(p => {
          const isAdheringConstraints = (hasSoqlTypeConstraints) ? _.includes(soqlTypeConstraints, p.dataType) : true;
          return (p?.group == groupName) && isAdheringConstraints; // this does work when there is no group
        })
        .map(p => {
          return {
            label: p.name,
            value: p,
            leadingBuilder: () => getSoqlTypeForgeIconBuilder(p.dataType || 'unknown'),
            secondaryLabel: p.subTitle
          };
        });
    };

    // extract out all the group names
    const groupNames = [...new Set(pickables.map(item => item.group))];

    const filteredOptions = (filterText: string, groupName?: string) => {
      const filteredOptionsByGroup = createPicklist(groupName);
      return filteredOptionsByGroup.filter((item) => {
        return item.value.name.toLowerCase().includes(filterText.toLowerCase());
      });
    };

    const noResults: IAutocompleteOption[] = [{
      label: t('no_results'),
      value: null,
      disabled: true
    }];

    const getFilteredGroupedOptions = (// hmmm
      filterText: string
    ) => {
      const optionGroups = groupNames.map((groupName) => {
        return {
          text: groupName,
          options: filteredOptions(filterText, groupName)
        };
      });
      // only return the groups that have options
      const found = optionGroups.filter(v => v.options?.length);
      if (found.length) return found;
      return noResults;
    };

    // with forge3 I hope to swap out the tool tip and instead have the autocomplete dynamically grow in width based on the label size, currently not possible
    return (
      <div className="node-picker" id="tooltip-host" data-testid={dataTestId ? dataTestId : undefined}>
        <ForgeAutocomplete
          on-forge-autocomplete-change={this.onSelection}
          allowUnmatched={false}
          data-testid="node-picker-autocomplete"
          filter={(filterText: string) => getFilteredGroupedOptions(filterText)}
          open={autoCompleteOpen} // used only for testing
        >
          <ForgeTextField >
            {selectedFormatting.leadingIconName && <ForgeIcon slot="leading"name={selectedFormatting.leadingIconName}></ForgeIcon>}
            <input type="text" id="picker-input" />
            <label htmlFor="picker-input">{selectedFormatting.label}</label>
            <ForgeIcon slot="trailing" data-forge-dropdown-icon name="arrow_drop_down"></ForgeIcon>
            <ForgeTooltip target="#picker-input" position={'bottom'}>{selectedFormatting.label}</ForgeTooltip>
          </ForgeTextField>
        </ForgeAutocomplete>
      </div>
    );
  };
}

export default Picker;
