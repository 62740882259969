import merge from 'lodash/merge';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import BigNumber from 'bignumber.js';

import commaify from './commaify';
import getLocale from './getLocale';
import getGroupCharacter from './getGroupCharacter';
import getDecimalCharacter from './getDecimalCharacter';

const NUMBER_FORMATTER_MAGNITUDE_SYMBOLS = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
const DEFAULT_MAX_LENGTH = 4;
const DEFAULT_DECIMAL_PLACES = -1;

export function getSmallestNumber(formatNumberOptions) {
  const {
    decimalPlaces = DEFAULT_DECIMAL_PLACES,
    maxLength = DEFAULT_MAX_LENGTH
  } = formatNumberOptions ?? {};

  // Find the smallest number able to be represented by the number of decimal places
  // allowed by the passed options
  const maxDecimalPlaces = decimalPlaces !== -1
    ? Math.min(maxLength - 1, decimalPlaces)
    : maxLength - 1;

  return Math.pow(10, (-1 * maxDecimalPlaces));
}

/**
 * Returns a human readable version of a number.
 *
 * options:
 * - {char} groupCharacter   defaults to group character of current locale (comma for en)
 * - {char} decimalCharacter defaults to decimal character of current locale (period for en)
 * - {int}  maxLength        defaults to 4, limits number of characters to show
 * - {int}  decimalPlaces    defaults to -1 (indicating unset), number of decimal places
 *                           to show. Will always display this many unless it doesn't fit
 *                           within maxLength or unless the value is very small.
 * Examples:
 *
 * formatNumber(12345);
 *   => '12.3K'
 * formatNumber(123, { decimalPlaces: 1 });
 *   => '123.0'
 * formatNumber(123.4444444, { maxLength: 5 });
 *   => '123.44'
 * formatNumber(0.004, { decimalPlaces: 2 }); // preserve very small numbers
 *   => '0.004'
 */
export default function(value, options, retainSmallDecimals = true) {

  if (!isNumber(value)) {
    throw new Error('`.formatNumber()` requires numeric input.');
  }

  const locale = getLocale(window);

  const defaultOptions = {
    groupCharacter: getGroupCharacter(locale),
    decimalCharacter: getDecimalCharacter(locale),
    maxLength: DEFAULT_MAX_LENGTH,
    decimalPlaces: DEFAULT_DECIMAL_PLACES
  };
  const formatNumberOptions = merge({}, defaultOptions, options);

  // NOTE: Extra digits don't add value for "human readable" numbers, but
  // ultimately it might be better to be able to pass this in as well
  const largeNumberMaxLength = 4;

  const val = parseFloat(value);
  const absVal = Math.abs(val);
  let newValue;
  let symbolIndex;

  const smallestNumber = getSmallestNumber(formatNumberOptions);
  if (absVal === 0 && formatNumberOptions.decimalPlaces !== -1) {

    const precision = Math.min(formatNumberOptions.maxLength - 1, formatNumberOptions.decimalPlaces);
    return absVal.toFixed(precision);

  } else if (absVal < smallestNumber && retainSmallDecimals) {
    // (If the number is this small, then it may be rounded to 0 by subsequent logic.
    return val.toString();
  } else if (absVal < 9999.5) {

    // This branch handles everything that doesn't use a magnitude suffix.
    // Thousands less than 10K are commaified.
    const parts = absVal.toString().split('.').concat('');
    let decimalPlaces = formatNumberOptions.decimalPlaces !== -1 ?
      formatNumberOptions.decimalPlaces :
      parts[1].length;
    let precision = Math.min(decimalPlaces, formatNumberOptions.maxLength - parts[0].length);

    // Safety first
    if (precision < 0) {
      precision = 0;
    }

    // EN-36428 toFixed(precision) is causing the number to not be rounded up when a number ends in 10.35 and precision is set to 1. This only happens for columns with custom column formatting.
    const roundedNum = new BigNumber(String(val)).toFixed(precision);
    return commaify(roundedNum, formatNumberOptions);

  } else if (/e/i.test(val)) {

    // This branch handles huge numbers that switch to exponent notation.
    const exponentParts = val.toString().split(/e\+?/i);
    symbolIndex = Math.floor(parseFloat(exponentParts[1]) / 3) - 1;
    newValue = exponentParts[0];

    const shiftAmount = parseFloat(exponentParts[1]) % 3;

    if (shiftAmount > 0) {

      // Adjust from e.g. 1.23e+4 to 12.3K
      newValue = newValue.replace(/^(-?\d+)(\.\d+)?$/, function(match, whole, frac) {

        frac = frac || `${formatNumberOptions.decimalCharacter}000`;

        const firstPart = whole + frac.slice(1, 1 + shiftAmount);
        const secondPart = frac.slice(shiftAmount);

        return `${firstPart}${formatNumberOptions.decimalCharacter}${secondPart}`;
      });
    }

    newValue = parseFloat(Math.abs(newValue)).toFixed(largeNumberMaxLength - shiftAmount - 1);

  } else {

    // This branch handles values that need a magnitude suffix.
    // We use commaify to determine what magnitude we're operating in.
    const magnitudeGroups = commaify(absVal.toFixed(0), formatNumberOptions).split(formatNumberOptions.groupCharacter);
    symbolIndex = magnitudeGroups.length - 2;
    newValue = parseFloat(`${magnitudeGroups[0]}.${magnitudeGroups[1]}`);
    newValue = newValue.toFixed(largeNumberMaxLength - magnitudeGroups[0].length - 1);

  }

  // The one edge case to handle is when 999.9[KMB...] rounds up, which
  // bumps us into the next magnitude.
  if (newValue === '1000') {
    newValue = '1';
    symbolIndex++;
  }

  const magnitude = NUMBER_FORMATTER_MAGNITUDE_SYMBOLS[symbolIndex];
  if (!isUndefined(magnitude)) {
    const maybeNegativeSign = val < 0 ? '-' : '';
    const value = parseFloat(newValue).toString().replace('.', formatNumberOptions.decimalCharacter);
    return `${maybeNegativeSign}${value}${magnitude}`;
  } else {
    return val.toString();
  }
}
