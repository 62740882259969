import { OrderBy as OrderByAst } from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { buildColumnRef, renderExpr } from './expr';
import { QualifiedViewColumn, isQualifiedViewColumn, isViewColumn, encodeStringAsColumnRef } from './util';

export interface OrderByDsl {
  expr: string | ViewColumn | QualifiedViewColumn;
  descending: boolean;
  nullLast: boolean;
}

/**
 * Construct a single ORDER BY object, expected to become a member of an array.
 * @param expr An expression you wish to select.
 * @param direction The full string, 'ascending' or 'descending'.
 * @param nullPosition The full string, 'nulls last' or 'nulls first'.
 */
export const buildOrderBy = (
  expr: OrderByDsl['expr'],
  direction?: 'ascending' | 'descending', // default: ascending
  nullPosition?: 'nulls first' | 'nulls last' // default when ascending: nulls last
                                              // default when descending: nulls first
): OrderByDsl => {
  direction = direction === undefined ? 'ascending' : direction;
  nullPosition = nullPosition === undefined
    ? (direction === 'ascending' ? 'nulls last' : 'nulls first')
    : nullPosition;

  return {
    expr,
    descending: direction === 'descending',
    nullLast: nullPosition === 'nulls last'
  };
};

export const convertOrderBy = (order: OrderByDsl): OrderByAst => {
  let expr;

  if (isQualifiedViewColumn(order.expr)) {
    expr = buildColumnRef(order.expr.column, order.expr.qualifier);
  } else if (isViewColumn(order.expr)) {
    expr = buildColumnRef(order.expr);
  } else {
    expr = encodeStringAsColumnRef(order.expr);
  }

  return { expr, ascending: !order.descending, null_last: order.nullLast };
};

export const renderOrderBy = (order: OrderByAst) => {
  const parts = [ renderExpr(order.expr) ];
  if (!order.ascending) {
    parts.push('DESC');

    if (order.null_last) {
      parts.push('NULL LAST');
    }
  } else if (order.ascending && order.null_last === false) {
    parts.push('NULL FIRST');
  }

  return parts.join(' ');
};
