import _ from 'lodash';
import { KeyDownHandlerProps } from './types';

export const instanceOfInteger = (object: any): object is number => {
  return Number.isInteger(object);
};

export function handleArrowDown<OptionType, SelectType>(
  event: React.KeyboardEvent<HTMLDivElement>,
  props: KeyDownHandlerProps<OptionType, SelectType>
) {
  const {
    onOptionsVisibilityChanged,
    onSelectedOptionIndexChange,
    options,
    optionsVisible,
    selectedOptionIndex,
    setUsingMouse
  } = props;

  if (optionsVisible && options) {
    // if options are visible and we aren't at the end of the list, move the index down by one
    if (selectedOptionIndex !== options.length - 1) {
      onSelectedOptionIndexChange(instanceOfInteger(selectedOptionIndex) ? selectedOptionIndex + 1 : 0);

      // we also set using mouse here to false,
      // which tells the option to scroll into view when it receives its new props
      setUsingMouse(false);
    }
  } else {
    // if the options aren't visible, make them so
    // current index gets set to undefined here so we're at the top of the list
    onSelectedOptionIndexChange(undefined);
    onOptionsVisibilityChanged(true);
  }
  event.preventDefault();
}

export function handleArrowUp<OptionType, SelectType>(
  event: React.KeyboardEvent<HTMLDivElement>,
  props: KeyDownHandlerProps<OptionType, SelectType>
) {
  const { onSelectedOptionIndexChange, optionsVisible, selectedOptionIndex, setUsingMouse } = props;

  if (optionsVisible && instanceOfInteger(selectedOptionIndex)) {
    // if we're at the top of the list, set the index to null to indicate we have nothing selected
    // otherwise, move up by one result
    onSelectedOptionIndexChange(selectedOptionIndex === 0 ? undefined : selectedOptionIndex - 1);
    setUsingMouse(false);
    event.preventDefault();
  }
}

export function handleEnter<OptionType, SelectType>(
  event: React.KeyboardEvent<HTMLDivElement>,
  props: KeyDownHandlerProps<OptionType, SelectType>
) {
  const { allowCustomInput, onAddSelectedOption, options, optionsVisible, selectedOptionIndex } = props;

  // if options are visible and we have a selected item, call the callback to add it
  if (optionsVisible && options && instanceOfInteger(selectedOptionIndex)) {
    // if the selected option somehow doesn't exist, do nothing
    if (_.isEmpty(options) || !options[selectedOptionIndex]) {
      return;
    }

    onAddSelectedOption(options[selectedOptionIndex]);
    event.preventDefault();
  }

  if (allowCustomInput && !instanceOfInteger(selectedOptionIndex)) {
    onAddSelectedOption();
    event.preventDefault();
  }
}

export function handleEscape<OptionType, SelectType>(
  event: React.KeyboardEvent<HTMLDivElement>,
  props: KeyDownHandlerProps<OptionType, SelectType>
) {
  const { onOptionsVisibilityChanged, optionsVisible } = props;

  // just hide the options
  if (optionsVisible) {
    onOptionsVisibilityChanged(false);
    event.preventDefault();
  }
}

export function handleBackspace<OptionType, SelectType>(
  event: React.KeyboardEvent<HTMLDivElement>,
  props: KeyDownHandlerProps<OptionType, SelectType>
) {
  const { currentQuery, onRemoveSelectedOption, selectedOptions, preventBackspaceClearsSelectedUsers } =
    props;

  // if we have any select options and the query is empty,
  // we remove the last option in the list
  if (
    selectedOptions &&
    selectedOptions.length > 0 &&
    currentQuery === '' &&
    !preventBackspaceClearsSelectedUsers
  ) {
    onRemoveSelectedOption(selectedOptions[selectedOptions.length - 1]);
    event.preventDefault();
  }
}

const keyDownEventHandlers = {
  ArrowDown: handleArrowDown,
  ArrowUp: handleArrowUp,
  Enter: handleEnter,
  Escape: handleEscape,
  Backspace: handleBackspace
};

function MultiSelectKeyDownHandler<OptionType, SelectType>(
  event: React.KeyboardEvent<HTMLDivElement>,
  props: KeyDownHandlerProps<OptionType, SelectType>
) {
  const handler = keyDownEventHandlers[event.key];
  if (handler) {
    handler(event, props);
  }
}

export default MultiSelectKeyDownHandler;
