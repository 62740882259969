import { NUMBER_OF_ROWS } from './Constants';
import _ from 'lodash';

/**
 * Adds a LIMIT clause to the given query
 * note that if the query already specifies a limit it won't be changed
 * (we might want to make this smarter in the future)
 * @param {string} query Query to add limit to
 * @param {number} limit Limit to add
 */
export const addLimitToQuery = (query, limit = NUMBER_OF_ROWS) => {
  let limitQuery = query;

  const limitRegex = RegExp('.*(limit|LIMIT).*', 'g');
  if (!limitRegex.test(limitQuery)) {
    // cut off a ; if it exists
    if (limitQuery.endsWith(';')) {
      limitQuery = limitQuery.substring(0, limitQuery.length - 1);
    }

    limitQuery += ` LIMIT ${limit}`;
  }

  return limitQuery;
};

/**
 * Generates a SELECT statement that includes all of the columns in the given view,
 * separated by commas (and newlines, and tabs if there are more than 5 columns)
 *
 * Because there can be issues with "SELECT *" this is useful for a default query if one isn't supplied
 * @param {object} view View to generate select statement for
 */
export const generateSelectAllColumnsQuery = (view) => {
  // if we have more than 5 columns, put them on newlines
  const split = view.columns.length > 5 ? '\n  ' : '';
  const columns = filterLegacyAndHiddenColumns(view.columns);

  return `SELECT ${split}${columns.map(col => col.fieldName).join(`,${split}`)}`;
};

export const filterLegacyAndHiddenColumns = (columns) => {
  const legacyTypes = ['location'];

  return _.filter(columns, (col) => {
    return !_.includes(legacyTypes, col.dataTypeName) && !_.includes('hidden', col.flags);
  });
};
