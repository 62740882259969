import React from 'react';

import I18n from 'common/i18n';

interface FederationCnameLabelProps {
  /** CNAME of a federation source or target domain */
  cname: string;

  /** Path to go to; appended to the URL that the anchor tag goes to */
  path?: string;
}

/**
 * Given a domain, returns display text for the federation table, conditionally
 * wrapped in an anchor tag.
 *
 *  If the domain is the current domain, returns a localized text (english:
 * "This Site (<domain)").
 *
 * If the domain is some other domain, returns that domain's cname, wrapped in
 * an anchor tag that links to the domains home page.
 */
const FederationCnameLabel = ({ cname, path = '' }: FederationCnameLabelProps) => {
  if (document.domain === cname) {
    return (
      <span data-testid={`cname-label-${cname}`}>
        {I18n.t('shared.federations.this_site', { domain: cname })}
      </span>
    );
  }

  return (
    <a data-testid={`cname-label-${cname}`} href={`https://${cname}${path}`}>
      {cname}
    </a>
  );
};

export default FederationCnameLabel;
