import url from 'url';
import isEmpty from 'lodash/isEmpty';
import I18n from 'common/i18n';

let parsedCurrentUrl = null;

function parseCurrentUrl() {
  if (isEmpty(parsedCurrentUrl)) {
    parsedCurrentUrl = url.parse(window.location.href, true);
  }

  return parsedCurrentUrl;
}

/** Get the URL for performing the actual catalog search */
export function getQueryFromBrowseUrl(searchTerm) {
  const currentUrl = parseCurrentUrl();
  const currentUrlIsBrowse =
    currentUrl.pathname.match(new RegExp(`(\/${I18n.locale})?\/browse`));

  if (!currentUrlIsBrowse) {
    const shouldPrefixLocale = new RegExp(`^\/(${I18n.locale})`).test(currentUrl.pathname);
    currentUrl.pathname = shouldPrefixLocale ? `${`/${I18n.locale}`}/browse` : '/browse';
    currentUrl.query = { };
  }

  currentUrl.query.q = searchTerm;
  currentUrl.query.sortBy = 'relevance';

  // New search means we should return to the first page
  delete currentUrl.query.page;

  // have to blank this out to make the 'query' object get used instead
  currentUrl.search = null;

  return url.format(currentUrl);
}
