/* Imports */
/* ============================================================================= */
import { forEach } from 'lodash';

import { GuidanceSummaryV2 } from 'common/types/approvals';

import { GuidanceCheckSummary, WorkflowGuidanceCheckMethod, WorkflowGuidanceCheckMethods } from 'common/core/approvals/Types';
import { createDefaultGuidanceCheckSummary } from '.';





/* Method */
/* ============================================================================= */
/**
 * Creates a method that combines the provided check methods from each workflow (if they exist)
 * @param guidance GuidanceSummaryV2 object
 * @param methods WorkflowGuidanceMethodsObject continaining the methods from each workflow to run checks with (if the workflow exists)
 * @param defaultValue The default value to use if a check method does not return a truthy value
 * @returns GuidanceCheckSummary object with attribute values representing the result of running the provided check method for each workflow.
 */
export const summarizeGuidanceCheckMethod = <T, D = T | undefined>(
  guidance: GuidanceSummaryV2,
  methods: WorkflowGuidanceCheckMethods<T>,
  defaultValue: D
): GuidanceCheckSummary<T | D> => {
  const response = createDefaultGuidanceCheckSummary(defaultValue);

  // Run checks against each workflow for which we have a check method
  forEach(methods, (method: WorkflowGuidanceCheckMethod<T>, workflow) => {
      const result = method(guidance[workflow], workflow);

      if (result) {
        response[workflow] = result;
        response.length++;
      }
  });

  return response;
};

export default summarizeGuidanceCheckMethod;
