import _ from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';
import { getLinearBucketValueForFeature } from 'common/visualizations/helpers/RangeHelper';
import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';

export const LAYERS = Object.freeze({
  LINES: 'lines-layer'
});

export const SOURCES = Object.freeze({
  LINES: 'lines-source'
});

// Renders lines retrieved by the tile data call and updates their rendering properties based on vif.
export default class Lines {
  constructor(map, seriesId) {
    this._map = map;

    this.layerIds = MapHelper.getNameToIdMap(_.values(LAYERS), seriesId);
    this.sourceIds = MapHelper.getNameToIdMap(_.values(SOURCES), seriesId);
  }

  alreadySetup() {
    return this._map.getSource(this.sourceIds[SOURCES.LINES]);
  }

  setup(vif, renderOptions, overlayOptions) {
    this._map.addSource(this.sourceIds[SOURCES.LINES], this.sourceOptions(vif, renderOptions));

    this._map.addLayer({
      'id': this.layerIds[LAYERS.LINES],
      'type': 'line',
      'source': this.sourceIds[SOURCES.LINES],
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': vif.isLayerVisible() ? 'visible' : 'none'
      },
      'paint': {
        'line-color': vif.getFeatureColor(renderOptions),
        'line-opacity': vif.getLineColorOpacity(),
        'line-width': vif.getLineWidthPaintProperty(renderOptions.aggregateAndResizeBy, renderOptions.resizeByRange)
      }
    }, overlayOptions.renderLayersBefore);
  }

  update(vif, renderOptions) {
    this._map.setLayoutProperty(this.layerIds[LAYERS.LINES],
      'visibility',
      vif.isLayerVisible() ? 'visible' : 'none'
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.LINES],
      'line-color',
      vif.getFeatureColor(renderOptions));
    this._map.setPaintProperty(this.layerIds[LAYERS.LINES],
      'line-opacity',
      vif.getLineColorOpacity());
    this._map.setPaintProperty(this.layerIds[LAYERS.LINES],
      'line-width',
      vif.getLineWidthPaintProperty(renderOptions.aggregateAndResizeBy, renderOptions.resizeByRange));
  }

  sourceOptions(vif, renderOptions) {
    return {
      'type': 'vector',
      'geojsonTile': true,
      'tiles': [renderOptions.dataUrl]
    };
  }

  destroy() {
    _.each(this.layerIds, (layerId) => {
      this._map.removeLayer(layerId);
    });
    _.each(this.sourceIds, (sourceId) => {
      this._map.removeSource(sourceId);
    });
  }
}

export function getFeatureWidth(feature, resizeByRange, aggregateAndResizeBy, vif) {
  if (!_.isString(vif.getWeighLinesByColumn())) {
    return _.get(vif, 'series[0].mapOptions.lineWeight', VIF_CONSTANTS.LINE_WEIGHT.DEFAULT);
  }

  const { minValue, maxValue, dataClasses } = vif.getResizeByRangeOptions();
  const featureValue = _.get(feature.properties, aggregateAndResizeBy, null);

  const rangeOptions = {
    dataClasses,
    featureValue,
    maxValue,
    minValue,
    resizeByRange
  };

  return getLinearBucketValueForFeature(rangeOptions);

}
