import { createSlice } from '@reduxjs/toolkit';
import { StorytellerState } from '../StorytellerReduxStore';
import { removeEditStoryUrl } from '../../lib/LinkUtils.js';
import Environment from '../../StorytellerEnvironment';
import { RectObject } from '../../types';

type InputState = {
  text: string;
  link: string;
  openInNewWindow: boolean;
};

type SliceState = {
  visible: boolean;
  remove: boolean;
  inputs: InputState | null;
  boundingClientRect: RectObject | null;
  editorId: string | null;
};

const initialState: SliceState = {
  visible: false,
  remove: false,
  inputs: null,
  boundingClientRect: null,
  editorId: null
};

const linkTipSlice = createSlice({
  name: 'linkTipSlice',
  initialState,
  reducers: {
    openLinkTip(state, action) {
      const { payload } = action;
      payload.link = removeEditStoryUrl(payload.link, Environment.STORY_UID);
      state.visible = true;
      state.editorId = payload.editorId;

      state.inputs = {
        text: payload.text,
        link: payload.link,
        openInNewWindow: payload.openInNewWindow
      };

      state.boundingClientRect = payload.boundingClientRect;
    },
    closeLinkTip(state) {
      state.visible = state.remove = false;
      state.inputs = state.boundingClientRect = state.editorId = null;
    },
    removeLinkTip(state) {
      state.remove = true;
    },
    acceptLinkModal(state, action) {
      const { payload } = action;
      payload.link = removeEditStoryUrl(payload.link, Environment.STORY_UID);

      state.inputs = {
        text: payload.text,
        link: payload.link,
        openInNewWindow: payload.openInNewWindow
      };
    }
  }
});

const { actions, reducer } = linkTipSlice;

export const { openLinkTip, closeLinkTip, removeLinkTip, acceptLinkModal } = actions;
export default reducer;
