/**
 * Gets the value of a cookie by name.
 *
 * @param {String} cookieName
 */
export function getCookie(cookieName) {
  const name = cookieName + '=';
  const cookies = document.cookie.split(/;\s*/);

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];

    if (cookie.indexOf(name) === 0) {
      return cookie.replace(name, '');
    }
  }
}

export default getCookie;
