import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import { FeatureFlags } from 'common/feature_flags';

import { MeasureTitle } from './MeasureTitle';
import { RecentTargetsInfo } from './MeasureResultCard/RecentTargetsInfo';
import StatusBanner from './MeasureResultCard/StatusBanner';
import withComputedMeasure from './withComputedMeasure';
import { PeriodTypes, PeriodSizes } from '../lib/constants';
import computedMeasurePropType from '../propTypes/computedMeasurePropType';
import * as ReportingPeriods from 'common/performance_measures/lib/reportingPeriods';
import {
  formatMeasureResultBigNumber,
  hasMeasureEnded
} from '../lib/measureHelpers';
import measurePropType from '../propTypes/measurePropType';

import './measure-result-card.scss';

// Calculates and displays a measure as a tile
const scope = 'shared.performance_measures';
export class MeasureResultCard extends Component {

  getSubtitle() {
    const { measure, computedMeasure } = this.props;
    const { result } = computedMeasure;

    const {
      calculationNotConfigured,
      dataSourceNotConfigured,
      dividingByZero,
      noRecentValue,
      noReportingPeriodAvailable,
      noReportingPeriodConfigured,
      notEnoughData,
      dataSourcePermissionDenied
    } = computedMeasure.errors;

    const dataSourceLensUid = _.get(measure, 'dataSourceLensUid');
    const singularUnitLabel = _.get(measure, 'metricConfig.display.label', '');
    const pluralUnitLabel = _.get(measure, 'metricConfig.display.pluralLabel', '');

    // NOTE: The order of these error states is important
    if (dataSourceNotConfigured || !dataSourceLensUid) {
      return I18n.t('no_dataset', { scope });
    } else if (noReportingPeriodConfigured) {
      return I18n.t('no_reporting_period', { scope });
    } else if (calculationNotConfigured) {
      return I18n.t('no_calculation', { scope });
    } else if (noReportingPeriodAvailable || notEnoughData) {
      return I18n.t('not_enough_data', { scope });
    } else if (noRecentValue) {
      return I18n.t('no_recent_value', { scope });
    } else if (dividingByZero) {
      return I18n.t('measure.dividing_by_zero', { scope });
    } else if (dataSourcePermissionDenied) {
      return I18n.t('no_visualization', { scope });
    } else {
      return Number(result.value) === 1 ? singularUnitLabel : pluralUnitLabel;
    }
  }

  renderTitle() {
    const { showMetadata, lens, measure } = this.props;

    if (!showMetadata) {
      return null;
    }

    return <MeasureTitle lens={lens} measure={measure} />;
  }

  renderResult() {
    const { computedMeasure, measure, showStatus } = this.props;
    const { calculationColumns } = computedMeasure;
    const result = _.get(computedMeasure, 'result.value');

    if (!result.isFinite()) {
      // TODO: Decide if we want to use 'warning' for the icon, instead of the default 'number'
      return this.renderPlaceholder();
    }
    const formattedValue = formatMeasureResultBigNumber(result, measure, calculationColumns);

    const isDateRange = _.get(measure, 'metricConfig.display.shouldDisplayDateRange', true);
    return (
      <div className="measure-result-value">
        {hasMeasureEnded(measure) ? this.renderMeasureEndBanner() : null}
        <div className="measure-result-value-top">
          <div className="measure-result-big-number">{formattedValue}</div>
          <div className="measure-result-subtitle">
            {this.getSubtitle()}
          </div>
        </div>
        <div className="measure-result-value-bottom">
          {isDateRange ? this.renderPeriod() : null}
          {showStatus && this.renderStatusBanner()}
          {this.renderTargets()}
        </div>
      </div>
    );
  }

  renderMeasureEndBanner() {
    return (
      <div className="measure-end-date-banner">
        <span>{I18n.t('measure.ended', { scope })}</span>
      </div>
    );
  }

  renderStatusBanner() {
    const { measure, computedMeasure } = this.props;

    const status = _.get(measure, 'metricConfig.status') || {};
    const { labels, hasMeasureEndStatusOverride } = status;

    const ended = hasMeasureEnded(measure) && hasMeasureEndStatusOverride;

    const activeStatus = _.get(computedMeasure, 'result.status');

    return <StatusBanner ended={ended} value={activeStatus} labels={labels} />;
  }

  renderTargets() {
    const { computedMeasure, measure } = this.props;
    const { calculationColumns, date } = computedMeasure;
    const { reportingPeriod, targets } = _.get(measure, 'metricConfig') || {};

    if (_.isEmpty(reportingPeriod) || _.isEmpty(targets)) {
      return null;
    }

    return (
      <RecentTargetsInfo
        calculationColumns={calculationColumns}
        date={date}
        measure={measure} />
    );
  }

  renderViewMeasureLink() {
    const {
      lens,
      measure,
      showViewMeasureLink,
      isCustomLinkEnabled,
      isInsituMeasure,
      customLink,
      measureDomain
    } = this.props;
    const lensId = isInsituMeasure ? _.get(measure, 'dataSourceLensUid') : _.get(lens, 'id');

    let measureLink = `/d/${lensId}`;
    if (measureDomain) {
      measureLink = `https://${measureDomain}${measureLink}`;
    }
    let measureText = isInsituMeasure ? I18n.t('shared.visualizations.charts.common.view_source_data') : I18n.t('measure.view_measure_link', { scope });

    if (!showViewMeasureLink || !lensId) {
      return null;
    }

    if (isCustomLinkEnabled && customLink) {
      measureLink = customLink.href;
      measureText = customLink.text;
    }

    return (
      <div className="view-measure-link">
        <a href={measureLink} target="_blank" rel="noopener noreferrer">
          <span>{measureText} </span>
          <span className="socrata-icon-external"></span>
        </a>
      </div>
    );
  }

  renderError() {
    const { dataSourceNotConfigured, dataSourcePermissionDenied } = this.props.computedMeasure.errors;

    if (dataSourceNotConfigured || dataSourcePermissionDenied) {
      return this.renderPlaceholder();
    }

    return (
      <div className="measure-result-value">
        <div className="measure-result-error measure-result-subtitle">
          {this.getSubtitle()}
        </div>
      </div>
    );
  }

  renderPlaceholder(icon = 'number') {
    return (
      <div className="measure-result-value placeholder">
        <SocrataIcon name={icon} />
        <div className="measure-result-placeholder-text">
          {this.getSubtitle()}
        </div>
      </div>
    );
  }

  /**
   * Generates the start date message based upon all the different variations
   * of measures. In some scenarios, a blank string will be returned if there
   * is only one date/message. The getEndDateMessage will handle the message
   * in this case.
   */
  getStartDateMessage() {
    const { measure, computedMeasure } = this.props;
    const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
    const startDate = _.get(reportingPeriod, 'startDateConfig.date');
    const cumulativeStartDate = _.get(measure, 'metricConfig.arguments.cumulativeMathStartDate', startDate);
    const { date } = computedMeasure;

    if (isCumulativeMath) {
      return moment(cumulativeStartDate).format('M/D/YY');
    } else {
      if (reportingPeriod.size !== PeriodSizes.DAY) {
        return moment(date).format('M/D/YY');
      }
    }
    return '';
  }

  getDashMessage() {
    const { measure } = this.props;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
    const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);

    if (reportingPeriod.size === PeriodSizes.DAY) {
      if ((isCumulativeMath && hasMeasureEnded(measure)) || (isCumulativeMath && reportingPeriod.type === PeriodTypes.LAST_REPORTED)) {
        return ' - ';
      } else {
        return ' ';
      }
    } else {
      if (!hasMeasureEnded(measure) && reportingPeriod.type === PeriodTypes.OPEN) {
        return ' ';
      } else {
        return ' - ';
      }
    }
  }

  /**
  * Generates the end date message based upon all the different variations
  * of measures
  */
  getEndDateMessage() {
    const { measure, computedMeasure } = this.props;
    const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
    const { date } = computedMeasure;

    if (reportingPeriod.size === PeriodSizes.DAY) {
      switch (reportingPeriod.type) {
        case PeriodTypes.OPEN:
          return isCumulativeMath ? I18n.t('measure.today', { scope }) : I18n.t('measure.daily_open_reporting_period', { scope });
        case PeriodTypes.CLOSED:
          return isCumulativeMath ? I18n.t('measure.yesterday', { scope }) : I18n.t('measure.daily_closed_reporting_period', { scope });
        case PeriodTypes.LAST_REPORTED:
          return moment(date).format('M/D/YY');
        default:
          throw new Error(`Cannot calculate start date for ${reportingPeriod.type} type.`);
      }
    } else {
      switch (reportingPeriod.type) {
        case PeriodTypes.OPEN:
          if (hasMeasureEnded(measure)) {
            return moment(date)
            .add(1, reportingPeriod.size)
            .subtract(1, 'day')
            .format('M/D/YY');
          } else {
            return I18n.t('measure.today', { scope });
          }
        case PeriodTypes.CLOSED:
        case PeriodTypes.LAST_REPORTED:
          return moment(date)
            .add(1, reportingPeriod.size)
            .subtract(1, 'day')
            .format('M/D/YY');
        default:
          throw new Error(`Cannot calculate start date for ${reportingPeriod.type} type.`);
      }
    }
  }

  renderPeriod() {
    // Start of displayed date range should be:
    // - start of calculation date (cumulative math start date or start of last reporting period)
    const startOfCalculationDate = this.getStartDateMessage();
    const endOfCalculationDate = this.getEndDateMessage();
    const dash = this.getDashMessage();

    const latestReportingPeriodMessage = `${startOfCalculationDate}${dash}${endOfCalculationDate}`;


    return (
      <div className="reporting-period-type">
        <div className="reporting-period-latest">
          {latestReportingPeriodMessage}
        </div>
      </div>
    );
  }

  render() {
    const {
      measure,
      computedMeasure,
      dataRequestInFlight,
      showMetadata,
      showStatus
    } = this.props;
    const { result } = computedMeasure;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});

    const isTylerForgeVizLayoutEnabled = FeatureFlags.available() && FeatureFlags.value('enable_forge_layout_for_viz');

    const spinner = (
      <div className="measure-result-spinner-container">
        {/*
            This used to be a real spinner, but we ran into baffling IE behavior at the last minute
            (EN-22336). Due to time pressure, we replaced the spinner with static text. EN-22374 tracks
            the real fix.
         */}
        <div>{I18n.t('calculating', { scope })}</div>
      </div>
    );

    const activeStatus = _.get(computedMeasure, 'result.status');

    const busy = dataRequestInFlight || !measure;
    const statusClass = `status-${activeStatus}`;

    const rootClasses = classNames(
      'measure-result-card',
      {
        'with-metadata': showMetadata,
        'status': showStatus && activeStatus,
        'measure-ended': measure && hasMeasureEnded(measure),
        [statusClass]: !!activeStatus,
        'forge-styling': isTylerForgeVizLayoutEnabled
      }
    );

    const title = busy ? null : this.renderTitle();

    // Checks to see if there is a current reporting period. If there is not, don't render the
    // card. Solves issue with trying to reportingPeriod start in the future.
    const isStartDateValid = ReportingPeriods.isStartDateValid(reportingPeriod);

    let content = result && result.value && isStartDateValid ?
      this.renderResult() :
      this.renderError();
    content = busy ? spinner : content;
    const viewMeasureLink = busy ? null : this.renderViewMeasureLink();

    return (
      <div className={rootClasses}>
        {title}
        <div className="measure-result-card-content">
          {content}
          {viewMeasureLink}
        </div>
      </div>
    );
  }
}

MeasureResultCard.defaultProps = {
  computedMeasure: {
    result: {},
    errors: {}
  },
  showMetadata: false,
  showStatus: true,
  showViewMeasureLink: false,
  isCustomLinkEnabled: false,
  isInsituMeasure: false
};

MeasureResultCard.propTypes = {
  measure: measurePropType,
  computedMeasure: computedMeasurePropType,
  dataRequestInFlight: PropTypes.bool,

  // NOTE! Ideally we'd refactor withComputedMeasure to optionally
  // take a measure UID which it would use to automatically fetch
  // both the lens and the computedMeasure props.
  // For now, all usages of MeasureResultCard either don't need
  // lens info, or already have the lens data anyway.
  lens: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  }),
  showMetadata: PropTypes.bool, // Metadata included: Title.
  showStatus: PropTypes.bool, // Whether or not to show the status banner
  showViewMeasureLink: PropTypes.bool, // Primarily used for embeds; sends user back to Measure page itself
  isCustomLinkEnabled: PropTypes.bool, // Primarily used for stories to see if we are overriding the default links
  isInsituMeasure: PropTypes.bool,
  customLink: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string
  }),
  measureDomain: PropTypes.string // Domain for "view measure" link. If null, will use a relative link.
};

// because we're only displaying the last period, lastPeriodOnly tells
// withComputedMeasure not to bother calculating the previous periods
export default withComputedMeasure({ lastPeriodOnly: true })(MeasureResultCard);
