import isEmpty from 'lodash/isEmpty';

import type { View } from 'common/types/view';
import { hasGrantRight } from 'common/views/has_rights';
import { currentUserIsCommunity } from 'common/current_user';

/**
 * Returns whether or not the current user can manage collaborators for the given view.
 *
 * This is _mostly_ keyed off of the current user having the `grant` right on the view,
 * which lets users add other users as collaborators.
 *
 * Users will get `grant` if...
 * - They are the "true owner" of the asset (this is sometimes called "author", ViewPermissions calls this `current_owner`)
 * - They are a co-owner of an asset via an explicit grant
 * - They have the `edit_others_datasets` on the domain, in which case they will have `grant` on _every_ asset
 *   (usually, only site administrators have this)
 *
 * Community users are not allowed to add collaborators to assets.
 *
 * @param view View to check for managing collaborators on
 */
const currentUserCanManageCollaborators = (view: View) =>
  !view.locked && hasGrantRight(view) && !currentUserIsCommunity();

export default currentUserCanManageCollaborators;
