import React, { FunctionComponent } from 'react';
import noop from 'lodash/noop';
import classNames from 'classnames';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Button from 'common/components/Button';
import './index.scss';

interface ChooserRadioButtonProps {
  /** Whether to render with borders */
  borders?: boolean;
  /** This will render inside the big square. */
  children?: React.ReactElement | React.ReactElement[];
  disabled?: boolean;
  /** This will be rendered below the big square. */
  label?: React.ReactElement | React.ReactElement[];
  name?: string;
  /** This will be passed as the big square's `title` attribute. */
  hoverMessage: string;
  onSelection: () => void;
  selected: boolean;
}

const ChooserRadioButton: FunctionComponent<ChooserRadioButtonProps> = ({
  borders,
  children,
  disabled,
  label,
  hoverMessage,
  onSelection,
  selected
}) => {
  const renderCheckMark = () => (
    <div className="chooser-checkmark-icon-container">
      <SocrataIcon name={IconName.Checkmark3} className="chooser-button-checkmark-icon" />
    </div>
  );

  const buttonClassNames = classNames({
    'chooser-button-checked': selected,
    'chooser-button-unchecked': !selected,
    'chooser-button-borders': borders
  });

  return (
    <div>
      <Button
        type="button"
        disabled={disabled}
        title={hoverMessage}
        onClick={disabled ? noop : onSelection}
        className={buttonClassNames}
      >
        {selected && !disabled && renderCheckMark()}
        {children}
      </Button>
      {label}
    </div>
  );
};

export default ChooserRadioButton;
