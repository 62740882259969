// NOTE: this React propType needs to match the TypeScript type defined in
// common/performance_measures/types.ts
// TODO: convert our React components to TS and deprecate this file

import PropTypes from 'prop-types';
import values from 'lodash/fp/values';
import fromPairs from 'lodash/fp/fromPairs';
import {
  AxisScalingTypes,
  CalculationTypes,
  LatestSchemaVersion,
  StatusFunctions,
  StatusValues,
  TargetTypes,
  TimelineScopes
} from 'common/performance_measures/lib/constants';

import reportingPeriodPropType from './reportingPeriodPropType';

/**
 * Describes a Measure object from core's API.
 */
export default PropTypes.shape({
  id: PropTypes.number,
  lens_id: PropTypes.number,
  data_source_lens_uid: PropTypes.string,
  domain: PropTypes.string, // Optional, used for demo page. Defaults to page domain.
  // Should always be the latest version.
  // See https://github.com/socrata/platform-ui/blob/main/common/performance_measures/lib/measureSchemaUpgrader/README.md
  version: PropTypes.oneOf([LatestSchemaVersion]).isRequired,
  metadata: PropTypes.shape({
    methods: PropTypes.string,
    analysis: PropTypes.string
  }),
  metricConfig: PropTypes.shape({
    type: PropTypes.oneOf(values(CalculationTypes)),
    dateColumn: PropTypes.string,
    arguments: PropTypes.shape({
      valueColumn: PropTypes.string, // Only used for measure.metricConfig.type = recent value
      column: PropTypes.string,
      isCumulativeMath: PropTypes.bool, // determines if a measure is cumulative or not
      cumulativeMathStartDate: PropTypes.string, // As YYYY-MM-DD

      // The following below are only used when the measure.metricConfig.type = rate
      aggregationType: PropTypes.string, // count | sum
      denominatorColumn: PropTypes.string, // [not provided if `fixedDenominator`is set]
      denominatorIncludeNullValues: PropTypes.bool, // deprecated
      denominatorFilters: PropTypes.arrayOf(PropTypes.object), // List of Filter objects to be consumed by common FilterBar component
      fixedDenominator: PropTypes.string,  // should be parsable number (it's a string because of floating point issues).
      numeratorColumn: PropTypes.string,
      numeratorFilters: PropTypes.arrayOf(PropTypes.object) // List of Filter objects to be consumed by common FilterBar component
    }),
    display: PropTypes.shape({
      label: PropTypes.string, // used as unit label for card and chart when value is 1
      decimalPlaces: PropTypes.number,
      shouldDisplayDateRange: PropTypes.bool, // used to optionally hide the date range from the meaure card
      asPercent: PropTypes.bool,
      pluralLabel: PropTypes.string, // used as unit label for card and chart when value is something other than 1
      targetTerminology: PropTypes.string,
      timelineScope: PropTypes.oneOf(values(TimelineScopes)),
      yAxis: PropTypes.shape({
        scaling: PropTypes.oneOf(values(AxisScalingTypes)),
        customMax: PropTypes.string,
        customMin: PropTypes.string
      })
    }),
    targets: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(values(TargetTypes)),
      startDate: PropTypes.string, // Only applies to periodic targets
      value: PropTypes.string, // Should be a parsable number
      label: PropTypes.string
    })),
    reportingPeriod: reportingPeriodPropType,
    status: PropTypes.shape({
      type: PropTypes.oneOf(values(StatusFunctions)),
      hasMeasureEndStatusOverride: PropTypes.bool,
      manual: PropTypes.oneOf(values(StatusValues)),
      proximity: PropTypes.shape({
        on_track: PropTypes.string, // stores the units from target
        near_target: PropTypes.string // stores the units from on_track
      }),
      above_below: PropTypes.shape({
        direction: PropTypes.string, // stores the direction of above or below
        include_target: PropTypes.bool, // whether or not green is inclusive of the target
        tolerance: PropTypes.string // stores the units from near target
      }),
      labels: PropTypes.shape({
        ended: PropTypes.string, // This is entered as part of measure end date
        // These keys exist based on whether user has entered a label override
        ...fromPairs(values(StatusValues).map((value) => [value, PropTypes.string]))
      })
    })
  })
});

 /**
  * The Measure object in metadb has the following schema:
  *
  *         Column        |           Type           |                       Modifiers
  *----------------------+--------------------------+-------------------------------------------------------
  * id                   | integer                  | not null default nextval('measures_id_seq'::regclass)
  * lens_id              | integer                  | not null
  * data_source_lens_uid | character(9)             |
  * metadata             | jsonb                    |
  * metric_config        | jsonb                    |
  * created_at           | timestamp with time zone |
  * created_meta         | character varying(255)   |
  * created_meta_type    | character varying(255)   |
  * updated_at           | timestamp with time zone |
  * updated_meta         | character varying(255)   |
  * updated_meta_type    | character varying(255)   |
  * deleted_at           | timestamp with time zone |
  * deleted_meta         | character varying(255)   |
  * deleted_meta_type    | character varying(255)   |
  *Indexes:
  *    "pk_measures" PRIMARY KEY, btree (id)
  *    "measures_lens_id_key" UNIQUE CONSTRAINT, btree (lens_id)
  *    "measures_data_source_lens_idx" btree (data_source_lens_uid)
  *    "measures_lens_idx" btree (lens_id)
  *Foreign-key constraints:
  *    "measures_lens_id_fkey" FOREIGN KEY (lens_id) REFERENCES lenses(id)
  */
