// Vendor Imports
import d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';

// Project Imports
import { getMeasures } from '../helpers/measure';
import LabelResizer from './LabelResizer';
import BaseVisualization from 'common/visualizations/views/BaseVisualization';
import { onDrilldown, renderDrilldownPane } from './BaseVisualization/DrilldownContainer';
import { renderLegend } from './BaseVisualization/Legend';
import SvgKeyboardPanning from './SvgKeyboardPanning';
import I18n from 'common/i18n';
import {
  getAxisLabels,
  getCurrentDrilldownColumnName,
  getMeasureAxisMaxValue,
  getMeasureAxisMinValue,
  getReferenceLinesWithValues,
  getShowDimensionLabels,
  getShowValueLabels,
  getYAxisScalingMode,
  isGroupingOrHasMultipleNonFlyoutSeries,
  isVifStacked,
  isOneHundredStacked,
  shouldAnimateColumnOrBar,
  shouldRenderDrillDown
} from 'common/visualizations/helpers/VifSelectors';
// Constants
import {
  AXIS_DEFAULT_COLOR,
  AXIS_GRID_COLOR,
  AXIS_LABEL_MARGIN,
  AXIS_TICK_COLOR,
  D3_TICK_SIZE,
  DEFAULT_DESKTOP_COLUMN_WIDTH,
  DEFAULT_LINE_HIGHLIGHT_FILL,
  DEFAULT_MOBILE_COLUMN_WIDTH,
  DIMENSION_LABELS_DEFAULT_HEIGHT,
  DIMENSION_LABELS_FONT_COLOR,
  DIMENSION_LABELS_FONT_SIZE,
  DRILLDOWN_ANIMATION_DURATION,
  ERROR_BARS_DEFAULT_BAR_COLOR,
  ERROR_BARS_MAX_END_BAR_LENGTH,
  ERROR_BARS_STROKE_WIDTH,
  FONT_STACK,
  MEASURE_LABELS_FONT_COLOR,
  MEASURE_LABELS_FONT_SIZE,
  MEASURE_VALUE_TEXT_INSIDE_BAR_COLOR,
  MEASURE_VALUE_TEXT_OUTSIDE_BAR_COLOR,
  REFERENCE_LINES_STROKE_DASHARRAY,
  REFERENCE_LINES_STROKE_WIDTH,
  REFERENCE_LINES_UNDERLAY_THICKNESS,
  TEXT_ANCHOR_END,
  TEXT_ANCHOR_START,
  VALUE_LABEL_FONT_SIZE,
  VALUE_LABEL_MARGIN
} from './SvgConstants';
import { hideOffscreenDimensionLabels } from '../helpers/SvgHelpers';

// The MARGINS values have been eyeballed to provide enough space for axis
// labels that have been observed 'in the wild'. They may need to be adjusted
// slightly in the future, but the adjustments will likely be small in scale.
// The LEFT margin has been removed because it will be dynamically calculated.
const MARGINS = {
  TOP: 32,
  RIGHT: 50,
  BOTTOM: 32
};

function SvgColumnChart($element, vif, options) {
  const self = this;

  let $chartElement;
  let columnDataToRender;
  let d3DimensionXScale;
  let d3GroupingXScale;
  let d3YScale;
  let firstNonFlyoutSeries;
  let flyoutDataToRender;
  let lastRenderedSeriesWidth = 0;
  let lastRenderedZoomTranslate = 0;
  let measures;
  let referenceLines;
  // To animate the columns, set the below variable to
  // {
  //    height: ..., // in pixels
  //    width: ..., // in pixels
  //    transform: ..., // in pixels
  // }
  // When drilling down, the clicked on columns coordinates are set here.
  // When the new data is rendered, they will be animated from this clicked
  // column's position
  let animateColumnsFrom = null;
  let isDrilldownEnabled = true; // this.shouldRenderDrillDown();

  const labelResizer = new LabelResizer({
    enabled: false,
    getAxisLabels: () => getAxisLabels(self.getVif()),
    getConfiguredLabelHeight: () => {
      return _.get(self.getVif(), 'configuration.dimensionLabelAreaSize', DIMENSION_LABELS_DEFAULT_HEIGHT);
    },
    margins: MARGINS,
    onDrag: () => {
      renderData();
      hideFlyout();
    },
    onDragEnd: (state) => {
      renderData();
      self.emitEvent('SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED', state.overriddenAreaSize);
    }
  });
  const panningClipPathId = `column-chart-clip-path-${_.uniqueId()}`;

  _.extend(this, new BaseVisualization($element, vif, options));

  renderTemplate();
  renderSvgTemplate();

  /**
   * Public methods
   */

  this.render = ({ newColumns, newComputedColumns, newData, newFlyoutData, newVif, newTableVif } = {}) => {
    labelResizer.resetOverride();

    this.clearError();

    self._previouslyTouchedColumnValue = null;
    self._hideColumnChartFlyoutContent = false;
    isDrilldownEnabled = shouldRenderDrillDown(newVif);

    if (newVif) {
      if (!_.isEqual(this.getVif().series, newVif.series)) {
        lastRenderedZoomTranslate = 0;
      }

      this.updateVif(newVif);
    }

    if (newColumns || newComputedColumns) {
      this.updateColumns(newColumns, newComputedColumns);
    }

    if (newData) {
      if (!_.isEqual(columnDataToRender, newData)) {
        renderSvgTemplate();
      }

      columnDataToRender = newData;
      self.setDefaultMeasureColumnPrecision(columnDataToRender);
    }

    if (newTableVif) {
      this.updateSummaryTableVif(newTableVif);
    }

    if (newFlyoutData) {
      flyoutDataToRender = newFlyoutData;
      self.setDefaultMeasureColumnPrecision(flyoutDataToRender);
    }

    if (self.isOnlyNullValues(columnDataToRender)) {
      self.renderNoDataError();
      return;
    }

    renderDrilldownPane(self);
    labelResizer.updateOptions({ enabled: getShowDimensionLabels(self.getVif()) });

    // When drilling down, the first render will render all the columns at the parent column's
    // position and size using the `animateColumnsFrom`. The second render will render all the
    // columns in their respective position/size. While doing that the css transitions will
    // kick in and animate the bars from the parent columns location to the new locations.
    renderData();

    if (animateColumnsFrom) {
      animateColumnsFrom = null;

      // Background: For rendering columns, we create and append rects first.
      // At this stage, all the rects default to 0 for x,y,height,width
      // Then we add the x,y,heigh,width based on the data.
      //
      // The transition should be added after the initial rendering only.
      // Otherwise, while setting the x,y,height,width initially all those rects
      // will animate from
      // x: 0,y: 0,height: 0,width: 0 -> x: <initial_value>,y: <initial_value>,...
      d3.select($chartElement[0])
        .select('svg')
        .selectAll('rect.column')
        .attr(
          'style',
          `transition: ${DRILLDOWN_ANIMATION_DURATION / 1000}s ease-in-out;` +
            'transition-property: height,width,x,y'
        );

      renderData();
    }
  };

  this.invalidateSize = () => {
    if ($chartElement && columnDataToRender) {
      renderData();
    }
  };

  this.destroy = () => {
    d3.select(self.$element[0]).select('svg').remove();

    self.$element.find('.socrata-visualization-container').remove();
  };

  /**
   * Private methods
   */

  function renderTemplate() {
    $chartElement = $('<div>', { class: 'column-chart chart-with-label-dragger' });
    labelResizer.renderTemplate($chartElement);

    self.$element.find('.socrata-visualization-chart-container').append($chartElement);
  }

  function renderSvgTemplate() {
    d3.select($chartElement[0]).select('svg').remove();

    const chartSvg = d3.select($chartElement[0]).append('svg');

    // The viewport represents the area within the chart's container that can
    // be used to draw the x-axis, y-axis and chart marks.
    const viewportSvg = chartSvg.append('g').attr('class', 'viewport');

    viewportSvg.append('g').attr('class', 'y axis');
    viewportSvg.append('g').attr('class', 'y grid');
    viewportSvg.append('rect').attr('class', 'dragger');

    const xAxisAndSeriesSvg = viewportSvg
      .append('g')
      .attr('class', 'x-axis-and-series')
      // The x-axis and series are groups since they all need to conform to the
      // same clip path for the appearance of panning to be upheld.
      .attr('clip-path', `url(#${panningClipPathId})`);

    xAxisAndSeriesSvg.append('g').attr('class', 'series');
    xAxisAndSeriesSvg.append('g').attr('class', 'x axis');
    xAxisAndSeriesSvg.append('g').attr('class', 'x axis baseline');

    // The clip path is used as a mask. It is attached to another svg element,
    // at which time all children of that svg element that would be drawn
    // outside of the clip path's bounds will not be rendered. The clip path
    // is used in this implementation to hide the extent of the chart that lies
    // outside of the viewport when the chart is wider than the viewport.
    //
    // The overall effect is for the chart to appear to pan.
    const clipPathSvg = chartSvg.append('clipPath').attr('id', panningClipPathId);
    clipPathSvg.append('polygon');
  }

  function renderData() {
    const columnWidth = self.isMobile() ? DEFAULT_MOBILE_COLUMN_WIDTH : DEFAULT_DESKTOP_COLUMN_WIDTH;

    const dimensionLabelsHeight = getShowDimensionLabels(self.getVif())
      ? labelResizer.computeLabelHeight()
      : 0;

    const axisLabels = getAxisLabels(self.getVif());
    const rightMargin = MARGINS.RIGHT + (axisLabels.right ? AXIS_LABEL_MARGIN : 0);
    const topMargin = MARGINS.TOP + (axisLabels.top ? AXIS_LABEL_MARGIN : 0);
    const bottomMargin = MARGINS.BOTTOM + (axisLabels.bottom ? AXIS_LABEL_MARGIN : 0) + dimensionLabelsHeight;

    let viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);

    firstNonFlyoutSeries = self.getFirstSeriesOfType(['columnChart']);

    const leftMargin =
      self.calculateLeftOrRightMargin({
        dataToRender: columnDataToRender,
        height: viewportHeight,
        isSecondaryAxis: false,
        series: firstNonFlyoutSeries
      }) + (axisLabels.left ? AXIS_LABEL_MARGIN : 0);

    let viewportWidth = Math.max(0, $chartElement.width() - leftMargin - rightMargin);

    // There may be instances where the chart is rendering in a hidden element. If this
    // happens, we shouldn't need to continue through this function. Allowing processing
    // to continue and calling `renderLegend()` would put the chart in an state where
    // parts of the chart don't render properly once they come into view (see EN-40617).
    if (viewportHeight <= 0 || viewportWidth <= 0) {
      return;
    }

    const dataTableDimensionIndex = columnDataToRender.columns.indexOf('dimension');
    const dimensionValues = columnDataToRender.rows.map((row) => row[dataTableDimensionIndex]);

    lastRenderedZoomTranslate = 0;
    measures = getMeasures(self, columnDataToRender);
    referenceLines = getReferenceLinesWithValues(self.getVif());

    let width;
    let height;
    let numberOfGroups;
    let numberOfItemsPerGroup;
    let minYValue;
    let maxYValue;
    let positions;
    let d3XAxis;
    let d3YAxis;
    let d3Zoom;
    let chartSvg;
    let clipPathSvg;
    let viewportSvg;
    let xAxisAndSeriesSvg;
    let seriesSvg;
    let dimensionGroupSvgs;
    let referenceLineSvgs;
    let referenceLineUnderlaySvgs;
    let xAxisBound = false;
    let yAxisBound = false;
    let xAxisPanDistance;
    let xAxisPanningEnabled;

    /**
     * Functions defined inside the scope of renderData() are stateful enough
     * to benefit from sharing variables within a single render cycle.
     */

    // See comment in renderXAxis() for an explanation as to why this is
    // separate.
    function bindXAxisOnce() {
      if (!xAxisBound) {
        let xAxisFormatter;

        if (getShowDimensionLabels(self.getVif())) {
          xAxisFormatter = d3XAxis;
        } else {
          xAxisFormatter = d3XAxis.tickFormat('').tickSize(0);
        }

        xAxisAndSeriesSvg.select('g.x.axis:not(.baseline)').call(xAxisFormatter);

        xAxisAndSeriesSvg.select('g.x.axis.baseline').call(d3XAxis.tickFormat('').tickSize(0));

        // Bind the chart data to the x-axis tick labels so that when the user
        // hovers over them we have enough information to distinctly identify
        // the column which should be highlighted and show the flyout.
        chartSvg.selectAll('.x.axis .tick text').data(columnDataToRender.rows);

        xAxisBound = true;
      }
    }

    function renderXAxis() {
      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderXAxis() as idempotent.
      bindXAxisOnce();

      const xAxisSvg = viewportSvg.select('.x.axis');
      const xBaselineSvg = viewportSvg.select('.x.axis.baseline');

      xAxisSvg.attr('transform', `translate(0,${height})`);

      xAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_DEFAULT_COLOR);

      xAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_TICK_COLOR);

      xAxisSvg
        .selectAll('text')
        .attr('fill', DIMENSION_LABELS_FONT_COLOR)
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${DIMENSION_LABELS_FONT_SIZE}px`)
        .attr('stroke', 'none')
        .attr('data-row-index', (label, rowIndex) => rowIndex)
        .call(self.rotateDimensionLabels, {
          dataToRender: columnDataToRender,
          maxHeight: dimensionLabelsHeight,
          maxWidth: d3DimensionXScale.rangeBand()
        });

      hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate });

      let baselineValue;

      if (minYValue > 0) {
        baselineValue = minYValue;
      } else if (maxYValue < 0) {
        baselineValue = maxYValue;
      } else {
        baselineValue = 0;
      }

      xBaselineSvg.attr('transform', `translate(0,${d3YScale(baselineValue)})`);

      xBaselineSvg
        .selectAll('line, path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    // See comment in renderYAxis() for an explanation as to why this is
    // separate.
    function bindYAxisOnce() {
      if (!yAxisBound) {
        chartSvg.select('.y.axis').call(d3YAxis);

        chartSvg.select('.y.grid').call(d3YAxis.tickSize(viewportWidth).tickFormat(''));

        yAxisBound = true;
      }
    }

    function renderYAxis() {
      const yAxisSvg = chartSvg.select('.y.axis');
      const yGridSvg = chartSvg.select('.y.grid');

      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderYAxis() as idempotent.
      bindYAxisOnce();

      yAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('text')
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${MEASURE_LABELS_FONT_SIZE}px`)
        .attr('fill', MEASURE_LABELS_FONT_COLOR)
        .attr('stroke', 'none');

      yGridSvg.attr('transform', `translate(${viewportWidth},0)`);

      yGridSvg.selectAll('path').attr('fill', 'none').attr('stroke', 'none');

      yGridSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_GRID_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    function renderReferenceLines() {
      const getYPosition = (referenceLine) => d3YScale(referenceLine.value);
      const getLineThickness = (referenceLine) => {
        const value = isOneHundredPercentStacked ? referenceLine.value / 100 : referenceLine.value;
        return self.isInRange(value, minYValue, maxYValue) ? REFERENCE_LINES_STROKE_WIDTH : 0;
      };

      const getUnderlayThickness = (referenceLine) => {
        const value = isOneHundredPercentStacked ? referenceLine.value / 100 : referenceLine.value;
        return self.isInRange(value, minYValue, maxYValue) ? REFERENCE_LINES_UNDERLAY_THICKNESS : 0;
      };

      // This places the underlay half above the line and half below the line.
      const underlayUpwardShift = REFERENCE_LINES_UNDERLAY_THICKNESS / 2;

      referenceLineUnderlaySvgs
        .attr('data-reference-line-index', (referenceLine, index) => index)
        .attr('fill', DEFAULT_LINE_HIGHLIGHT_FILL)
        .attr('fill-opacity', 0)
        .attr('x', 0)
        .attr('y', (referenceLine) => getYPosition(referenceLine) - underlayUpwardShift)
        .attr('width', width)
        .attr('height', getUnderlayThickness);

      referenceLineSvgs
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', (referenceLine) => referenceLine.color)
        .attr('stroke-dasharray', REFERENCE_LINES_STROKE_DASHARRAY)
        .attr('stroke-width', getLineThickness)
        .attr('x1', 0)
        .attr('y1', getYPosition)
        .attr('x2', width)
        .attr('y2', getYPosition);
    }

    function renderErrorBars() {
      if (_.isUndefined(columnDataToRender.errorBars)) {
        return;
      }

      const columnWidth = d3GroupingXScale.rangeBand() - 1;
      const errorBarWidth = Math.min(columnWidth, ERROR_BARS_MAX_END_BAR_LENGTH);
      const color = _.get(self.getVif(), 'series[0].errorBars.barColor', ERROR_BARS_DEFAULT_BAR_COLOR);

      const getMinErrorBarYPosition = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        const minValue = _.clamp(d3.min(errorBarValues), minYValue, maxYValue);
        return d3YScale(minValue);
      };

      const getMaxErrorBarYPosition = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        const maxValue = _.clamp(d3.max(errorBarValues), minYValue, maxYValue);
        return d3YScale(maxValue);
      };

      const getMinErrorBarWidth = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        return self.isInRange(d3.min(errorBarValues), minYValue, maxYValue) ? ERROR_BARS_STROKE_WIDTH : 0;
      };

      const getMaxErrorBarWidth = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        return self.isInRange(d3.max(errorBarValues), minYValue, maxYValue) ? ERROR_BARS_STROKE_WIDTH : 0;
      };

      const getErrorBarXPosition = (d, measureIndex) => {
        return (columnWidth - errorBarWidth) / 2 + d3GroupingXScale(measureIndex);
      };

      dimensionGroupSvgs
        .selectAll('.error-bar-bottom')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', getMinErrorBarWidth)
        .attr('x1', getErrorBarXPosition)
        .attr('y1', getMinErrorBarYPosition)
        .attr('x2', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth)
        .attr('y2', getMinErrorBarYPosition);

      dimensionGroupSvgs
        .selectAll('.error-bar-top')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', getMaxErrorBarWidth)
        .attr('x1', getErrorBarXPosition)
        .attr('y1', getMaxErrorBarYPosition)
        .attr('x2', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth)
        .attr('y2', getMaxErrorBarYPosition);

      dimensionGroupSvgs
        .selectAll('.error-bar-middle')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', ERROR_BARS_STROKE_WIDTH)
        .attr('x1', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth / 2)
        .attr('y1', getMinErrorBarYPosition)
        .attr('x2', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth / 2)
        .attr('y2', getMaxErrorBarYPosition);
    }

    // Note that renderXAxis(), renderYAxis() and renderSeries() all update the
    // elements that have been created by binding the data (which is done
    // inline in this function below).
    function renderSeries() {
      const getFill = (d, measureIndex) => measures[measureIndex].getColor();
      const firstSeriesTotal = columnDataToRender.rows.map((d) => d[1]).reduce((acc, d) => acc + d, 0);
      const getPercent = (d, measureIndex, dimensionIndex) => {
        if (isOneHundredPercentStacked) {
          return positions[dimensionIndex][measureIndex].percent;
        } else if (self.shouldShowValueLabelsAsPercent()) {
          return firstSeriesTotal !== 0 ? (100 * d) / firstSeriesTotal : null;
        } else {
          return null;
        }
      };

      const underlaySvgs = dimensionGroupSvgs
        .selectAll('rect.column-underlay')
        .attr('data-percent', getPercent);

      if (!isStacked) {
        underlaySvgs
          .attr('x', (d, measureIndex) => d3GroupingXScale(measureIndex))
          .attr('y', 0)
          .attr('width', Math.max(d3GroupingXScale.rangeBand() - 1, 0))
          .attr('height', height)
          .attr('stroke', 'none')
          .attr('fill', 'transparent')
          .attr('data-default-fill', getFill);
      }

      const columns = dimensionGroupSvgs
        .selectAll('rect.column')
        .attr(
          'y',
          animateColumnsFrom
            ? animateColumnsFrom.y
            : (d, measureIndex, dimensionIndex) => {
                const position = positions[dimensionIndex][measureIndex];
                return d3YScale(position.end);
              }
        )
        .attr(
          'height',
          animateColumnsFrom
            ? animateColumnsFrom.height
            : (d, measureIndex, dimensionIndex) => {
                const position = positions[dimensionIndex][measureIndex];
                const value = position.end - position.start;
                return Math.max(d3YScale(0) - d3YScale(value), 0);
              }
        )
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', 'none')
        .attr('fill', getFill)
        .attr('data-default-fill', getFill)
        .attr('data-percent', getPercent);

      if (isStacked) {
        columns
          .attr('x', animateColumnsFrom ? animateColumnsFrom.x : 0)
          .attr('width', Math.max(d3DimensionXScale.rangeBand() - 1, 0));
      } else {
        columns
          .attr(
            'x',
            animateColumnsFrom ? animateColumnsFrom.x : (d, measureIndex) => d3GroupingXScale(measureIndex)
          )
          .attr(
            'width',
            animateColumnsFrom ? animateColumnsFrom.width : Math.max(d3GroupingXScale.rangeBand() - 1, 0)
          );
      }

      lastRenderedSeriesWidth = xAxisAndSeriesSvg.node().getBBox().width;

      if (getShowValueLabels(self.getVif()) && _.isUndefined(columnDataToRender.errorBars)) {
        const isLabelInside = (d, measureIndex, dimensionIndex) => {
          return self.shouldShowValueLabelsAsPercent()
            ? self.isPercentLabelInside({
                dimensionIndex,
                measureIndex,
                percent: getPercent(d, measureIndex, dimensionIndex),
                positions,
                scale: d3YScale
              })
            : self.isValueLabelInside({
                d,
                dataToRender: columnDataToRender,
                dimensionIndex,
                measureIndex,
                positions,
                scale: d3YScale
              });
        };
        const getText = (d, measureIndex, dimensionIndex) => {
          if (isLabelInside(d, measureIndex, dimensionIndex)) {
            if (self.shouldShowValueLabelsAsPercent()) {
              return self.getPercentFormattedValueText({
                percent: getPercent(d, measureIndex, dimensionIndex)
              });
            } else if (getShowValueLabels(self.getVif())) {
              return self.getMeasureColumnFormattedValueText({
                dataToRender: columnDataToRender,
                measureIndex,
                value: d
              });
            }
          }
          return null;
        };

        dimensionGroupSvgs
          .selectAll('text')
          .attr('font-family', FONT_STACK)
          .attr('font-size', `${VALUE_LABEL_FONT_SIZE}px`)
          .attr('stroke', 'none')
          .attr('transform', 'translate(0,0) rotate(-90)')
          .attr('x', (d, measureIndex, dimensionIndex) => {
            const position = self.getPosition(positions, measureIndex, dimensionIndex);
            let x;

            if (self.isBelowMeasureAxisMinValue(d)) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3YScale(position.start) - VALUE_LABEL_MARGIN
                : d3YScale(position.end) - VALUE_LABEL_MARGIN;
            } else if (self.isAboveMeasureAxisMaxValue(d)) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3YScale(position.end) + VALUE_LABEL_MARGIN
                : d3YScale(position.start) + VALUE_LABEL_MARGIN;
            } else if (d >= 0) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3YScale(position.end) + VALUE_LABEL_MARGIN
                : d3YScale(position.end) - VALUE_LABEL_MARGIN;
            } else {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3YScale(position.start) - VALUE_LABEL_MARGIN
                : d3YScale(position.start) + VALUE_LABEL_MARGIN;
            }

            return -x;
          })
          .attr('y', (d, measureIndex) =>
            self.getValueLabelY({
              dimensionScale: d3DimensionXScale,
              groupingScale: d3GroupingXScale,
              measureIndex,
              isStacked
            })
          )
          .attr('fill', (d, measureIndex, dimensionIndex) =>
            isLabelInside(d, measureIndex, dimensionIndex)
              ? MEASURE_VALUE_TEXT_INSIDE_BAR_COLOR
              : MEASURE_VALUE_TEXT_OUTSIDE_BAR_COLOR
          )
          .attr('text-anchor', (d, measureIndex, dimensionIndex) => {
            if (self.isBelowMeasureAxisMinValue(d)) {
              return TEXT_ANCHOR_START;
            } else if (self.isAboveMeasureAxisMaxValue(d)) {
              return TEXT_ANCHOR_END;
            } else if (d >= 0) {
              return isLabelInside(d, measureIndex, dimensionIndex) ? TEXT_ANCHOR_END : TEXT_ANCHOR_START;
            } else {
              return isLabelInside(d, measureIndex, dimensionIndex) ? TEXT_ANCHOR_START : TEXT_ANCHOR_END;
            }
          })
          .text(getText);
      }
    }

    function handleZoom() {
      lastRenderedZoomTranslate = _.clamp(d3.event.translate[0], -xAxisPanDistance, 0);

      // We need to override d3's internal translation since it doesn't seem to
      // respect our snapping to the beginning and end of the rendered data.
      d3Zoom.translate([lastRenderedZoomTranslate, 0]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('polygon')
        .attr('transform', `translate(${-lastRenderedZoomTranslate},0)`);

      xAxisAndSeriesSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);

      if (self.isMobile()) {
        hideHighlight();
        hideFlyout();
      }

      hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate });
    }

    function restoreLastRenderedZoom() {
      const translateXRatio =
        lastRenderedSeriesWidth !== 0 ? Math.abs(lastRenderedZoomTranslate / lastRenderedSeriesWidth) : 0;
      const currentWidth = xAxisAndSeriesSvg.node().getBBox().width;

      lastRenderedZoomTranslate = _.clamp(-translateXRatio * currentWidth, -xAxisPanDistance, 0);

      d3Zoom.translate([lastRenderedZoomTranslate, 0]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('polygon')
        .attr('transform', `translate(${-lastRenderedZoomTranslate},0)`);

      xAxisAndSeriesSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);
    }

    function setAnimateColumnsFrom(clickedColumn, clickedGroup) {
      if (isDrilldownEnabled) {
        animateColumnsFrom = {
          x: Number(clickedColumn.attr('x')),
          y: Number(clickedColumn.attr('y')),
          height: Number(clickedColumn.attr('height')),
          width: Number(clickedColumn.attr('width')),
          transform: clickedGroup.attr('transform')
        };
      }
    }

    function shouldAnimateColumn(dimensionValue) {
      return shouldAnimateColumnOrBar({
        vif: self.getVif(),
        columnData: columnDataToRender,
        dimensionValue
      });
    }

    // Just extracted the on('click', <fn>) and on('touchstart', <fn>) and applied the same to rect underlay,
    // so that drill down happens when clicking above the bar.
    // (This helps in small screen and if the bars are very small.)
    function onClickDrilldownForColumn() {
      if (self._isTouchstartEventEnabled || self._lastRenderedZoomTranslate !== lastRenderedZoomTranslate) {
        return;
      }
      const clickedColumn = d3.select(this);
      const clickedGroup = d3.select(this.parentNode);
      const row = clickedGroup.datum();
      const dimensionValue = _.get(row, '[0]');

      if (shouldAnimateColumn(dimensionValue)) {
        setAnimateColumnsFrom(clickedColumn, clickedGroup);
      }

      onDrilldown(self, dimensionValue, columnDataToRender);
    }

    function onTouchDrilldownForColumn() {
      const clickedColumn = d3.select(this);
      const clickedGroup = d3.select(this.parentNode);
      const row = clickedGroup.datum();
      const dimensionValue = _.get(row, '[0]');

      if (self._previouslyTouchedColumnValue === dimensionValue) {
        self._hideColumnChartFlyoutContent = true;
        hideFlyout();

        if (shouldAnimateColumn(dimensionValue)) {
          setAnimateColumnsFrom(clickedColumn, clickedGroup);
        }

        onDrilldown(self, dimensionValue, columnDataToRender);
        self._previouslyTouchedColumnValue = null;
      } else {
        self._hideColumnChartFlyoutContent = false;
        self._previouslyTouchedColumnValue = dimensionValue;
      }

      self._isTouchstartEventEnabled = true;
    }

    function onMousedownForColumn() {
      self._lastRenderedZoomTranslate = lastRenderedZoomTranslate;
    }

    /**
     * 1. Prepare the data for rendering (unfortunately we need to do grouping
     *    on the client at the moment).
     */
    const adjustedViewportSize = renderLegend(self, {
      measures,
      referenceLines,
      viewportSize: {
        height: viewportHeight,
        width: viewportWidth
      }
    });

    viewportHeight = adjustedViewportSize.height;
    viewportWidth = adjustedViewportSize.width;

    const isStacked = isVifStacked(self.getVif());
    const isOneHundredPercentStacked = isOneHundredStacked(self.getVif());

    numberOfGroups = columnDataToRender.rows.length;
    numberOfItemsPerGroup = isStacked ? 1 : columnDataToRender.columns.length - 1;

    // When we do allow panning we get a little more sophisticated; primarily
    // we will attempt to adjust the width we give to d3 to account for the
    // width of the labels, which will extend past the edge of the chart
    // since they are rotated by 45 degrees.
    //
    // Since we know the maximum number of items in a group and the total
    // number of groups we can estimate the total width of the chart (this
    // will necessarily be incorrect because we won't actually know the width
    // of the last label until we render it, at which time we will
    // re-measure. This estimate will be sufficient to get d3 to render the
    // columns at widths that are in line with our expectations, however.
    width = Math.max(viewportWidth, columnWidth * numberOfGroups * numberOfItemsPerGroup);

    // Compute height based on the presence or absence of x-axis data labels.
    if (getShowDimensionLabels(self.getVif())) {
      height = viewportHeight;
    } else {
      // In this case we want to mirror the top margin on the bottom so
      // that the chart is visually centered (column charts have no bottom
      // margin by default).
      height = Math.max(0, viewportHeight - MARGINS.TOP);
    }

    /**
     * 2. Set up the x-scale and -axis.
     */

    // This scale is used for dimension categories.
    d3DimensionXScale = generateXScale(
      dimensionValues,
      width,
      isGroupingOrHasMultipleNonFlyoutSeries(self.getVif())
    );

    // This scale is used for groupings of columns under a single dimension
    // category.
    d3GroupingXScale = generateXGroupScale(
      _.range(0, columnDataToRender.columns.length - 1),
      d3DimensionXScale
    );

    d3XAxis = generateXAxis(d3DimensionXScale);

    /**
     * 3. Set up the y-scale and -axis.
     */

    try {
      const measureAxisMinValue = getMeasureAxisMinValue(self.getVif());
      const measureAxisMaxValue = getMeasureAxisMaxValue(self.getVif());

      if (measureAxisMinValue && measureAxisMaxValue && measureAxisMinValue >= measureAxisMaxValue) {
        self.renderError(
          I18n.t(
            'shared.visualizations.charts.common.validation.errors.' +
              'measure_axis_min_should_be_lesser_then_max'
          )
        );
        return;
      }

      if (isOneHundredPercentStacked) {
        positions = self.getStackedPositions({
          isOneHundredPercentStacked: true,
          rows: columnDataToRender.rows
        }); // measure axes do not change for 100% stacked

        minYValue = self.getMinOneHundredPercentStackedValue(positions);
        maxYValue = self.getMaxOneHundredPercentStackedValue(positions);
      } else if (isStacked) {
        const extent = self.getRowValueSummedExtent({
          columnOrBarRows: columnDataToRender.rows,
          dimensionIndex: dataTableDimensionIndex,
          referenceLines
        });

        if (getYAxisScalingMode(self.getVif()) === 'showZero' && !measureAxisMinValue) {
          minYValue = Math.min(extent.min, 0);
        } else if (measureAxisMinValue) {
          minYValue = measureAxisMinValue;
        } else {
          minYValue = extent.min;
        }

        if (getYAxisScalingMode(self.getVif()) === 'showZero' && !measureAxisMaxValue) {
          maxYValue = Math.max(extent.max, 0);
        } else if (measureAxisMaxValue) {
          maxYValue = measureAxisMaxValue;
        } else {
          maxYValue = extent.max;
        }

        positions = self.getStackedPositionsForRange(columnDataToRender.rows, minYValue, maxYValue);
      } else {
        const rowValues = _.flatMap(columnDataToRender.rows, (row) => row.slice(dataTableDimensionIndex + 1));

        const errorBarValues = _.flatMapDeep(columnDataToRender.errorBars, (row) =>
          row.slice(dataTableDimensionIndex + 1)
        );

        const extent = self.getRowValueExtent({
          errorBarValues,
          referenceLines,
          rowValues
        });

        if (getYAxisScalingMode(self.getVif()) === 'showZero' && !measureAxisMinValue) {
          minYValue = Math.min(extent.min, 0);
        } else if (measureAxisMinValue) {
          minYValue = measureAxisMinValue;
        } else {
          minYValue = extent.min;
        }

        if (getYAxisScalingMode(self.getVif()) === 'showZero' && !measureAxisMaxValue) {
          maxYValue = Math.max(extent.max, 0);
        } else if (measureAxisMaxValue) {
          maxYValue = measureAxisMaxValue;
        } else {
          maxYValue = extent.max;
        }

        positions = self.getPositionsForRange(columnDataToRender.rows, minYValue, maxYValue);
      }

      if (minYValue > maxYValue) {
        self.renderError(
          I18n.t(
            'shared.visualizations.charts.common.validation.errors.' +
              'measure_axis_biggest_value_should_be_more_than_min_limit'
          )
        );
        return;
      }
    } catch (error) {
      self.renderError(error.message);
      return;
    }

    d3YScale = self.generateYScale(minYValue, maxYValue, height);
    d3YAxis = self.generateYAxis({
      dataToRender: columnDataToRender,
      height,
      isSecondaryAxis: false,
      scale: d3YScale,
      series: firstNonFlyoutSeries
    });

    /**
     * 4. Clear out any existing chart.
     */

    // d3.select($chartElement[0]).select('svg').
    //   remove();

    chartSvg = d3.select($chartElement[0]).select('svg');
    clipPathSvg = chartSvg.select('clipPath');
    viewportSvg = chartSvg.select('g.viewport');
    xAxisAndSeriesSvg = viewportSvg.select('g.x-axis-and-series');

    /**
     * 5. Render the chart.
     */

    // Create the top-level <svg> element first.
    chartSvg
      .attr('width', width + leftMargin + rightMargin)
      .attr('height', viewportHeight + topMargin + bottomMargin);

    viewportSvg.attr('transform', `translate(${leftMargin},${topMargin})`);

    // This <rect> exists to capture mouse actions on the chart, but not
    // directly on the columns or labels, that should result in a pan behavior.
    // If we set stroke and fill to none, the mouse events don't seem to get
    // picked up, so we instead set opacity to 0.
    viewportSvg.select('rect.dragger').attr('width', width).attr('height', viewportHeight).attr('opacity', 0);

    seriesSvg = xAxisAndSeriesSvg.select('.series');

    dimensionGroupSvgs = seriesSvg
      .selectAll('.dimension-group')
      .data(columnDataToRender.rows)
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('g').attr('class', 'dimension-group'));

    dimensionGroupSvgs
      .attr('style', `transition: transform ${DRILLDOWN_ANIMATION_DURATION / 1000}s ease-in-out;`)
      .attr('data-dimension-index', (d, dimensionIndex) => dimensionIndex)
      .attr(
        'transform',
        animateColumnsFrom ? animateColumnsFrom.transform : (d) => `translate(${d3DimensionXScale(d[0])},0)`
      );

    if (!isStacked) {
      dimensionGroupSvgs
        .selectAll('rect.column-underlay')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('rect').attr('class', 'column-underlay'))
        .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
        .attr('data-measure-index', (d, measureIndex) => measureIndex);
    }

    dimensionGroupSvgs
      .selectAll('rect.column')
      .data((d) => d.slice(1))
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('rect').attr('class', 'column'))
      .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
      .attr('data-measure-index', (d, measureIndex) => measureIndex);

    referenceLineSvgs = seriesSvg
      .selectAll('line.reference-line')
      .data(referenceLines)
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('line').attr('class', 'reference-line'));

    // Render an invisible rect on top of the reference line that is thicker than the line.  This shape will handle
    // the mouse events to show the flyout.  This just makes a bigger mouse target.
    referenceLineUnderlaySvgs = seriesSvg
      .selectAll('rect.reference-line-underlay')
      .data(referenceLines)
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('rect').attr('class', 'reference-line-underlay'));

    if (!_.isUndefined(columnDataToRender.errorBars)) {
      dimensionGroupSvgs
        .selectAll('line.error-bar-top')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('line').attr('class', 'error-bar-top'));

      dimensionGroupSvgs
        .selectAll('line.error-bar-middle')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('line').attr('class', 'error-bar-middle'));

      dimensionGroupSvgs
        .selectAll('line.error-bar-bottom')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('line').attr('class', 'error-bar-bottom'));
    }

    if (getShowValueLabels(self.getVif()) && _.isUndefined(columnDataToRender.errorBars)) {
      dimensionGroupSvgs
        .selectAll('text')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('text'))
        .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
        .attr('data-measure-index', (d, measureIndex) => measureIndex);
    } else {
      dimensionGroupSvgs.selectAll('text').remove();
    }

    // Unfortunately, we need to render the x-axis and the series before we
    // can measure whether or not the chart will pan. Since showing the
    // panning notice also affects the height available to the chart, that
    // means that we need to render once to measure if the chart to pan and if
    // it does, show the panning notice and then re-render the x-axis and the
    // series at the new (smaller) height to accommodate the notice.
    //
    // Also note that we must render the x-axis before setting up the event
    // handlers for flyouts below, since it attempts to bind data to elements
    // that will not exist before the x-axis has been rendered.
    renderXAxis();
    renderSeries();

    // This is the actual rendered width (which accounts for the labels
    // extending beyond what d3 considers the right edge of the chart on
    // account of their being rotated 45 degrees.
    width = xAxisAndSeriesSvg.node().getBBox().width;

    // The width was being calculated slightly off (by .0001) for small chart sizes, so using fixed precision to calculate.
    // This issue was previously not uncovered because we were not rendering the panning notice for small chart sizes
    xAxisPanDistance = width.toFixed(3) - viewportWidth;
    xAxisPanningEnabled = xAxisPanDistance > 0;

    if (xAxisPanningEnabled) {
      self.showPanningNotice();

      // Note that we need to recompute height here since
      // $chartElement.height() changed when we showed the panning
      // notice.
      viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);

      if (getShowDimensionLabels(self.getVif())) {
        height = viewportHeight;
      } else {
        height = Math.max(0, viewportHeight - MARGINS.TOP);
      }
      d3YScale = self.generateYScale(minYValue, maxYValue, height);
      d3YAxis = self.generateYAxis({
        dataToRender: columnDataToRender,
        height,
        isSecondaryAxis: false,
        scale: d3YScale,
        series: firstNonFlyoutSeries
      });

      renderXAxis();
      renderSeries();
    } else {
      self.hidePanningNotice();
    }

    // NOTE: We specifically measure out the clip path _after_ deciding whether to show
    // the panning notice as the panning notice can affect the height of the chart.
    const maskWidth = viewportWidth + leftMargin + rightMargin;
    const maskHeight = viewportHeight + topMargin + bottomMargin;
    const yAxisHeightAndTicks = height + D3_TICK_SIZE;

    const points = [
      '0,0', // top of Y-axis
      `0,${yAxisHeightAndTicks}`, // just below chart origin on Y-axis
      `${-leftMargin},${yAxisHeightAndTicks}`, // jut out to the left edge
      `${-leftMargin},${maskHeight}`, // continue to the bottom left corner
      `${maskWidth},${maskHeight}`, // bottom right corner
      `${maskWidth},0` // top right corner
    ];

    clipPathSvg.select('polygon').attr('points', points.join(' '));

    // We only have to render the y-axis once, after we have decided whether
    // we will show or hide the panning notice.
    renderYAxis();
    renderReferenceLines();
    renderErrorBars();

    labelResizer.update(leftMargin, topMargin + height, width);

    /**
     * 6. Set up event handlers for mouse interactions.
     */
    if (!isStacked && referenceLines.length == 0) {
      dimensionGroupSvgs
        .selectAll('rect.column-underlay')
        // NOTE: The below function depends on this being set by d3, so it is
        // not possible to use the () => {} syntax here.
        .on('mousemove', function () {
          if (!isCurrentlyPanning()) {
            const measureIndex = parseInt(this.getAttribute('data-measure-index'), 10);
            const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

            const dimensionGroup = this.parentNode;
            const siblingColumn = d3
              .select(dimensionGroup)
              .select(`rect.column[data-measure-index="${measureIndex}"]`)[0][0];

            // d3's .datum() method gives us the entire row, whereas everywhere
            // else measureIndex refers only to measure values. We therefore
            // add one to measure index to get the actual measure value from
            // the raw row data provided by d3 (the value at element 0 of the
            // array returned by .datum() is the dimension value).
            const row = d3.select(this.parentNode).datum();
            const dimensionValue = row[0];
            const value = row[measureIndex + 1];
            const percent = parseFloat(this.getAttribute('data-percent'));

            showColumnHighlight(siblingColumn);
            showColumnFlyout({
              dimensionIndex,
              dimensionValue,
              element: siblingColumn,
              measureIndex,
              percent,
              value
            });
          }
        })
        .on('mouseleave', () => {
          if (!isCurrentlyPanning()) {
            hideHighlight();
            hideFlyout();
          }
        });
    }

    dimensionGroupSvgs
      .selectAll('rect.column-underlay')
      .on('click', onClickDrilldownForColumn)
      .on('mousedown', onMousedownForColumn)
      .on('touchstart', onTouchDrilldownForColumn);

    dimensionGroupSvgs
      .selectAll('rect.column')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (self._isTouchstartEventEnabled && self._hideColumnChartFlyoutContent) {
          return;
        }

        if (!isCurrentlyPanning()) {
          const measureIndex = parseInt(this.getAttribute('data-measure-index'), 10);
          const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

          // d3's .datum() method gives us the entire row, whereas everywhere
          // else measureIndex refers only to measure values. We therefore
          // add one to measure index to get the actual measure value from
          // the raw row data provided by d3 (the value at element 0 of the
          // array returned by .datum() is the dimension value).
          const row = d3.select(this.parentNode).datum();
          const dimensionValue = row[0];
          const value = row[measureIndex + 1];
          const percent = parseFloat(this.getAttribute('data-percent'));

          showColumnHighlight(this);
          showColumnFlyout({
            dimensionIndex,
            dimensionValue,
            element: this,
            measureIndex,
            percent,
            value
          });
        }
      })
      .on('mousedown', onMousedownForColumn)
      .on('click', onClickDrilldownForColumn)
      .on('touchstart', onTouchDrilldownForColumn)
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideHighlight();
          hideFlyout();
        }
      });

    chartSvg
      .selectAll('.x.axis .tick text')
      .on('mousemove', (d, dimensionIndex) => {
        if (!isCurrentlyPanning()) {
          const dimensionValue = d[0];
          // We need to find nodes with a data-dimension-index attribute matching dimensionIndex.
          // We can't easily use a CSS selector because we lack a simple API to apply CSS-string escaping
          // rules.
          // There's a working draft for a CSS.escape and jQuery >= 3.0 has a $.escapeSelector,
          // but both of those are out of reach for us at the moment.
          const groupElement = d3.select(
            _(xAxisAndSeriesSvg.node().querySelectorAll('g.dimension-group[data-dimension-index]'))
              .filter((group) => group.getAttribute('data-dimension-index') === String(dimensionIndex))
              .first()
          );

          if (groupElement.empty()) {
            return;
          }

          showGroupFlyout({
            dimensionIndex,
            dimensionValue,
            groupElement
          });
        }
      })
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideFlyout();
        }
      });

    seriesSvg
      .selectAll('.reference-line-underlay')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (!isCurrentlyPanning()) {
          const underlayHeight = parseInt($(this).attr('height'), 10);
          const flyoutOffset = {
            left: d3.event.clientX,
            top: $(this).offset().top + underlayHeight / 2 - window.pageYOffset
          };

          self.showReferenceLineFlyout({
            dataToRender: columnDataToRender,
            element: this,
            flyoutOffset,
            referenceLines
          });

          $(this).attr('fill-opacity', 1);
        }
      })
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mouseleave', function () {
        if (!isCurrentlyPanning()) {
          hideFlyout();
          $(this).attr('fill-opacity', 0);
        }
      });

    /**
     * 7. Conditionally set up the zoom behavior, which is actually used for
     *    panning the chart along the x-axis if panning is enabled.
     */

    if (xAxisPanningEnabled) {
      d3Zoom = d3.behavior.zoom().on('zoom', handleZoom);

      viewportSvg
        .attr('cursor', 'move')
        .call(d3Zoom)
        // By default the zoom behavior seems to capture every conceivable
        // kind of zooming action; we actually just want it to zoom when
        // the user clicks and drags, so we need to immediately deregister
        // the event handlers for the other types.
        //
        // Note that although we listen for the zoom event on the zoom
        // behavior we must detach the zooming actions we do not want to
        // respond to from the element to which the zoom behavior is
        // attached.
        .on('dblclick.zoom', null)
        .on('wheel.zoom', null)
        .on('mousewheel.zoom', null)
        .on('MozMousePixelScroll.zoom', null);

      restoreLastRenderedZoom();

      SvgKeyboardPanning(d3Zoom, chartSvg, '.socrata-visualization-panning-notice');

      chartSvg.selectAll('text').attr('cursor', null);
    } else {
      chartSvg.selectAll('text').attr('cursor', 'default');
    }

    self.renderAxisLabels(chartSvg, {
      x: leftMargin,
      y: topMargin,
      width: viewportWidth,
      height: viewportHeight
    });
  }

  function generateXScale(domain, width, hasMultipleNonFlyoutSeries) {
    const padding = hasMultipleNonFlyoutSeries ? 0.3 : 0.1;

    return (
      d3.scale
        .ordinal()
        .domain(domain)
        // .rangeRoundBands(<interval>, <padding>, <outer padding>)
        //
        // From the documentation:
        //
        // ---
        //
        // Note that rounding necessarily introduces additional outer padding
        // which is, on average, proportional to the length of the domain.
        // For example, for a domain of size 50, an additional 25px of outer
        // padding on either side may be required. Modifying the range extent to
        // be closer to a multiple of the domain length may reduce the additional
        // padding.
        //
        // ---
        // The outer padding looks pretty funny for our use cases, so we
        // override it to be zero, which looks like what we expect.
        .rangeRoundBands([0, width], padding, 0.05)
    );
  }

  function generateXGroupScale(domain, xScale) {
    return d3.scale.ordinal().domain(domain).rangeRoundBands([0, xScale.rangeBand()]);
  }

  function generateXAxis(xScale) {
    let columnName = _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');
    const currentDrilldownColumnName = getCurrentDrilldownColumnName(self.getVif());
    if (shouldRenderDrillDown(self.getVif()) && currentDrilldownColumnName) {
      columnName = currentDrilldownColumnName;
    }

    return d3.svg
      .axis()
      .scale(xScale)
      .orient('bottom')
      .tickFormat((d) =>
        self.getColumnFormattedValueText({
          columnName,
          dataToRender: columnDataToRender,
          value: d
        })
      )
      .outerTickSize(0);
  }

  function isCurrentlyPanning() {
    // EN-10810 - Bar Chart flyouts do not appear in Safari
    //
    // Internet Explorer will apparently always return a non-zero value for
    // d3.event.which and even d3.event.button, so we need to check
    // d3.event.buttons for a non-zero value (which indicates that a button is
    // being pressed).
    //
    // Safari apparently does not support d3.event.buttons, however, so if it
    // is not a number then we will fall back to d3.event.which to check for a
    // non-zero value there instead.
    //
    // Chrome appears to support both cases, and in the conditional below
    // Chrome will check d3.event.buttons for a non-zero value.
    return _.isNumber(d3.event.buttons) ? d3.event.buttons !== 0 : d3.event.which !== 0;
  }

  function showColumnHighlight(columnElement) {
    const selection = d3.select(columnElement);

    // NOTE: The below function depends on this being set by d3, so it is not
    // possible to use the () => {} syntax here.
    selection.attr('fill', function () {
      const seriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(
        parseInt(this.getAttribute('data-measure-index'), 10)
      );
      const highlightColor = self.getHighlightColorBySeriesIndex(seriesIndex);

      return highlightColor !== null ? highlightColor : selection.attr('fill');
    });
  }

  function hideHighlight() {
    // NOTE: The below function depends on this being set by d3, so it is not
    // possible to use the () => {} syntax here.
    d3.selectAll('rect.column').each(function () {
      const selection = d3.select(this);
      selection.attr('fill', selection.attr('data-default-fill'));
    });
  }

  function showGroupFlyout({ dimensionIndex, dimensionValue, groupElement }) {
    const $content = self.getGroupFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measures,
      nonFlyoutDataToRender: columnDataToRender
    });

    // Payload
    const payload = {
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    // If there is only one bar in the group then we can position the flyout
    // over the bar itself, not the bar group.
    if (groupElement.selectAll('rect.column')[0].length === 1) {
      _.set(payload, 'element', groupElement[0][0].childNodes[1]);
    } else {
      // Calculate the offsets from screen (0, 0) to the top of the tallest
      // column (where at least one value in the group is > 0) or 0 on the
      // y-axis (where all values in the group are <= 0) and the horizontal
      // center of the group in question.
      const flyoutElementBoundingClientRect = groupElement[0][0].getBoundingClientRect();
      const flyoutElementLeftOffset = flyoutElementBoundingClientRect.left;
      const flyoutElementBottomOffset = flyoutElementBoundingClientRect.bottom;
      const halfGroupWidth = Math.ceil(flyoutElementBoundingClientRect.width / 2.0) + 1;

      _.set(payload, 'flyoutOffset', {
        top: flyoutElementBottomOffset,
        left: flyoutElementLeftOffset + halfGroupWidth
      });
    }

    self.emitEvent('SOCRATA_VISUALIZATION_COLUMN_CHART_FLYOUT', payload);
  }

  function showColumnFlyout({ dimensionIndex, dimensionValue, element, measureIndex, percent, value }) {
    if (value === null && self.shouldDropNullFlyouts()) return;
    const $content = self.getFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measureIndex,
      measures,
      nonFlyoutDataToRender: columnDataToRender,
      percent,
      value
    });

    const payload = {
      element,
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    self.emitEvent('SOCRATA_VISUALIZATION_COLUMN_CHART_FLYOUT', payload);
  }

  function hideFlyout() {
    self.emitEvent('SOCRATA_VISUALIZATION_COLUMN_CHART_FLYOUT', null);
  }
}

export default SvgColumnChart;
