// Vendor Imports
import { bindAll, each, get, head, isEqual, isUndefined, map, range, set, uniqueId } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import {
  ForgeDatePicker,
  ForgeDateRangePicker,
  ForgeRadio,
  ForgeTextField,
  ForgeSelect
} from '@tylertech/forge-react';

// Project Imports
import Picklist, { PicklistOption, PicklistSizes } from 'common/components/Picklist';
import FilterFooter from '../FilterFooter';
import * as CalendarDateFilter from '../lib/Filters/CalendarDateFilter';
import { addPopupListener } from './InputFocus';
import { Key } from 'common/types/keyboard/key';
import I18n from 'common/i18n';

// Constants
import {
  DATE_FORMAT,
  FILTER_TYPES,
  RELATIVE_FILTERS,
  RELATIVE_FILTER_VALUES,
  SINGLE_SELECT_BY,
  getFiscalYearStartMoment,
  getFiscalEndYear,
  getSelectByYearStartAndEndDates,
  getSelectByFiscalYearStartAndEndDates
} from 'common/dates';
import { FilterEditorProps } from '../types';
import * as BaseFilter from '../lib/Filters/BaseFilter';

const CUSTOM_PERIOD_INPUT_FIELD = { MIN: 1, STEP: 1 };
const scope = 'shared.components.filter_bar.calendar_date_filter';

export interface CalendarDateFilterEditorProps extends FilterEditorProps {
  appliedFilter: CalendarDateFilter.CalendarDateFilterConfig;
}

interface CalendarDateFilterEditorState {
  dirtyFilter: CalendarDateFilter.CalendarDateFilterConfig;
}

class CalendarDateFilterEditor extends Component<
  CalendarDateFilterEditorProps,
  CalendarDateFilterEditorState
> {
  inputs: NodeListOf<HTMLInputElement> | null;
  dateFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: CalendarDateFilterEditorProps) {
    super(props);

    bindAll(this, [
      'applyFilter',
      'getInitialState',
      'onChangeSingleSelectDay',
      'onChangeSingleSelectMonthMonth',
      'onChangeSingleSelectMonthYear',
      'onChangeSingleSelectYear',
      'onEnterUpdateFilter',
      'resetFilter',
      'isDirty'
    ]);

    this.state = this.getInitialState(props);
  }

  getInitialState(props: CalendarDateFilterEditorProps) {
    const { appliedFilter, columns, isReadOnly, relativeDateOptions } = props;

    const filterArgs = appliedFilter.arguments;
    let calendarDateFilterType = filterArgs?.calendarDateFilterType ?? FILTER_TYPES.RANGE;
    const rangeMinStartDay = CalendarDateFilter.getRangeMinStartDay(columns);
    const rangeMaxEndDay = CalendarDateFilter.getRangeMaxEndDay(columns);

    const firstRelativeDateOption = head(relativeDateOptions);
    let start = CalendarDateFilter.getDate(filterArgs?.start ?? rangeMinStartDay);
    let end = CalendarDateFilter.getDate(filterArgs?.end ?? rangeMaxEndDay);
    const firstRelativeDateOptionValue = firstRelativeDateOption?.value ?? RELATIVE_FILTERS.TODAY;
    const defaultFilterOption = RELATIVE_FILTER_VALUES[firstRelativeDateOptionValue];

    const relativeDatePeriod = {
      period: filterArgs?.period ?? defaultFilterOption.period,
      value: filterArgs?.value ?? defaultFilterOption.value,
      type: filterArgs?.type ?? defaultFilterOption.type
    };

    const singleSelectBy = typeof appliedFilter.singleSelect === 'object' && appliedFilter.singleSelect.by;

    // If we are in single select mode, modify the state to ensure
    // the end dates are set properly. When date filters are converted to queries,
    // a day is added to the end date, and the query is of the form ">= START and < END",
    // effectively making the query end-date-inclusive. For that reason, END should be
    // set to the last date we actually want included in the filter (which means subtracting
    // one day from the SINGLE_SELECT options).
    if (singleSelectBy === SINGLE_SELECT_BY.YEAR) {
      const startMoment = this.getMoment(start);
      [start, end] = getSelectByYearStartAndEndDates(startMoment);
    } else if (singleSelectBy === SINGLE_SELECT_BY.FISCAL_YEAR) {
      const endMoment = this.getMoment(end);
      [start, end] = getSelectByFiscalYearStartAndEndDates(endMoment);
    } else if (singleSelectBy === SINGLE_SELECT_BY.MONTH) {
      const startMoment = this.getMoment(start).date(1);
      start = startMoment.format(DATE_FORMAT);
      end = startMoment.add(1, 'month').subtract(1, 'day').format(DATE_FORMAT);
    } else if (singleSelectBy === SINGLE_SELECT_BY.DAY) {
      end = CalendarDateFilter.getDate(start);

      // Set calendarDateFilterType by prop since this handle both
      // a single select day and relative "Today" filter
      const existingCalendarDateFilterType = filterArgs?.calendarDateFilterType ?? FILTER_TYPES.RANGE;

      // Combination of SINGLE_SELECT_BY.DAY and FILTER_TYPES.RELATIVE
      // means this is a relative "Today" filter.
      // In View mode only, treat it as if this is a normal SINGLE_SELECT_BY.DAY
      // filter but with current date selected.
      if (isReadOnly && existingCalendarDateFilterType === FILTER_TYPES.RELATIVE) {
        start = this.getMoment(RELATIVE_FILTERS.TODAY).format(DATE_FORMAT);
        end = start;
        calendarDateFilterType = FILTER_TYPES.RANGE;
      }
    }

    return {
      dirtyFilter: CalendarDateFilter.getCalendarDateRangeFilter(appliedFilter, {
        ...relativeDatePeriod,
        start,
        end,
        calendarDateFilterType
      })
    };
  }

  componentDidMount() {
    const { popupRef } = this.props;

    if (this.dateFilter) {
      this.inputs = this.dateFilter.querySelectorAll('.date-picker-input');
      each(this.inputs, (input) => input.addEventListener('keyup', this.onEnterUpdateFilter));

      this.removePopupListener = addPopupListener(popupRef, this.dateFilter, () => {
        this.popupListenerRemoved = true;
      });
    }
  }

  componentWillUnmount() {
    const { popupRef } = this.props;

    if (this.inputs) {
      each(this.inputs, (input) => input.removeEventListener('keyup', this.onEnterUpdateFilter));
    }

    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  onChangeSingleSelectDay(date?: string) {
    const { dirtyFilter } = this.state;
    if (!date) return;
    const value = {
      start: CalendarDateFilter.getDate(date),
      end: CalendarDateFilter.getDate(date)
    };

    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, value);

    this.setState({ dirtyFilter: updatedFilter });
  }

  onChangeSingleSelectMonthMonth(option: PicklistOption) {
    const { dirtyFilter } = this.state;
    const startMoment = moment(dirtyFilter.arguments?.start).month(option.value);
    const start = startMoment.format(DATE_FORMAT);
    const end = startMoment.add(1, 'month').subtract(1, 'day').format(DATE_FORMAT);

    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, { start, end });

    this.setState({ dirtyFilter: updatedFilter });
  }

  onChangeSingleSelectMonthYear(option: PicklistOption) {
    const { dirtyFilter } = this.state;
    const startMoment = moment(dirtyFilter.arguments?.start).year(option.value);
    const start = startMoment.format(DATE_FORMAT);
    const end = startMoment.add(1, 'month').subtract(1, 'day').format(DATE_FORMAT);

    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, { start, end });

    this.setState({ dirtyFilter: updatedFilter });
  }

  onChangeSingleSelectYear(option?: PicklistOption | null) {
    if (!option) return;
    const { dirtyFilter } = this.state;

    let start;
    if (get(dirtyFilter, 'singleSelect.by') === SINGLE_SELECT_BY.FISCAL_YEAR) {
      // The start of a fiscal year is actually the previous calendar year
      // e.g. 2018-09-01 is the start of the 2019 govt fiscal year
      start = getFiscalYearStartMoment()
        .year(option.value - 1)
        .format(DATE_FORMAT);
    } else {
      start = CalendarDateFilter.getDate(`${option.value}-01-01`);
    }
    const end = moment(start, DATE_FORMAT).add(1, 'year').subtract(1, 'day').format(DATE_FORMAT);

    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, { start, end });

    this.setState({ dirtyFilter: updatedFilter }, this.applyFilter);
  }

  onChangeDateRangeToToday(detail: string) {
    const { dirtyFilter } = this.state;
    const startMoment = this.getMoment(detail);
    const today = moment();

    if (startMoment === null || !startMoment.isBefore(today)) {
      return;
    }
    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, {
      start: CalendarDateFilter.getDate(detail),
      end: 'today'
    });

    this.setState({ dirtyFilter: updatedFilter });
  }

  onChangeDateRange(detail: { from: string; to: string }) {
    const startMoment = this.getMoment(detail.from);
    const endMoment = this.getMoment(detail.to);

    if (startMoment === null || endMoment === null || !startMoment.isSameOrBefore(endMoment)) {
      return;
    }

    const { dirtyFilter } = this.state;

    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, {
      start: CalendarDateFilter.getDate(detail.from),
      end: CalendarDateFilter.getDate(detail.to)
    });

    this.setState({ dirtyFilter: updatedFilter });
  }

  onEnterUpdateFilter(event: KeyboardEvent) {
    if (event.key === Key.Enter) {
      event.stopPropagation();
      event.preventDefault();

      if (this.isDirty()) {
        this.applyFilter();
      }
    }
  }

  setCalenderDateFilterType = (calendarDateFilterType: FILTER_TYPES) => {
    const { dirtyFilter } = this.state;

    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, {
      calendarDateFilterType
    });
    this.setState({ dirtyFilter: updatedFilter });
  };

  getMoment(value?: string) {
    if (value === RELATIVE_FILTERS.TODAY) {
      return moment().startOf('day');
    } else if (value === RELATIVE_FILTERS.YESTERDAY) {
      return moment().subtract(1, 'days').startOf('day');
    } else {
      return moment(value);
    }
  }

  isDirty() {
    const { appliedFilter } = this.props;
    const { dirtyFilter } = this.state;

    return !isEqual(dirtyFilter, appliedFilter);
  }

  resetFilter() {
    const { columns, onUpdate } = this.props;
    const { dirtyFilter } = this.state;

    const updatedFilter = BaseFilter.reset(dirtyFilter, columns);

    onUpdate(updatedFilter);
  }

  applyFilter() {
    const { onUpdate } = this.props;
    const { dirtyFilter } = this.state;

    onUpdate(dirtyFilter);
  }

  onCustomDateInputChange = (value: string) => {
    const { dirtyFilter } = this.state;
    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, { value });
    this.setState({ dirtyFilter: updatedFilter });
  };

  onRelativeDatePeriodChange = (option: PicklistOption) => {
    const { dirtyFilter } = this.state;
    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, {
      type: option.value,
      calendarDateFilterType: FILTER_TYPES.RELATIVE
    });
    this.setState({ dirtyFilter: updatedFilter });
  };

  onCustomDatePeriodChange = (option: PicklistOption) => {
    const { dirtyFilter } = this.state;

    const updatedFilter = CalendarDateFilter.getCalendarDateRangeFilter(dirtyFilter, {
      period: option.value
    });
    this.setState({ dirtyFilter: updatedFilter });
  };

  renderSingleSelectDayPicker() {
    const { isReadOnly, columns } = this.props;
    const { dirtyFilter } = this.state;
    const calendarDateFilterType = dirtyFilter.arguments?.calendarDateFilterType ?? FILTER_TYPES.RANGE;

    const startMoment = this.getMoment(
      dirtyFilter.arguments?.start ?? CalendarDateFilter.getMinValue(columns)
    );
    const date = startMoment.format(DATE_FORMAT);
    // combination of dataset ID and column name should be unique in a global filter bar
    // this ID is used to associate input and label elements for accessibility
    const fieldId = `gfb-filter--${BaseFilter.getFilterName(dirtyFilter, columns)}--${uniqueId()}`;

    // common date picker element props
    const datePickerProps = {
      value: date,
      'on-forge-date-picker-change': (event: CustomEvent) => {
        this.onChangeSingleSelectDay(event.detail);
      },
      // show Today button only in View mode
      showToday: isReadOnly,
      valueMode: 'iso-string',
      // disable in edit mode only if the other radio button is selected
      disabled: !isReadOnly && calendarDateFilterType === FILTER_TYPES.RELATIVE
    };
    const datePickerElement = (
      <ForgeDatePicker {...datePickerProps}>
        <ForgeTextField>
          <input type="text" id={fieldId} />
          <label htmlFor={fieldId}>{BaseFilter.getFilterName(dirtyFilter, columns)}</label>
        </ForgeTextField>
      </ForgeDatePicker>
    );

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setCalenderDateFilterType(event.target.value as FILTER_TYPES);
    };

    // in view mode, only display the date picker.
    // in edit mode, display the date picker and an option for SoQL literal `today` value
    if (isReadOnly) {
      return <div className="range-filter-container">{datePickerElement}</div>;
    } else {
      return (
        <div className="range-filter-container">
          <div
            className="radio-button-group"
            role="radiogroup"
            aria-label={I18n.t('shared.components.filter_bar.config.filter_selection')}
          >
            <ForgeRadio>
              <input
                type="radio"
                id={`${fieldId}-date`}
                onChange={handleRadioChange}
                checked={calendarDateFilterType !== FILTER_TYPES.RELATIVE}
                name={`${fieldId}-selector`}
                value={FILTER_TYPES.RANGE}
              />
              <label htmlFor={`${fieldId}-date`}>{datePickerElement}</label>
            </ForgeRadio>
            <ForgeRadio>
              <input
                type="radio"
                id={`${fieldId}-today`}
                onChange={handleRadioChange}
                checked={calendarDateFilterType === FILTER_TYPES.RELATIVE}
                name={`${fieldId}-selector`}
                value={FILTER_TYPES.RELATIVE}
              />
              <label htmlFor={`${fieldId}-today`}>{I18n.t('relative_periods.today', { scope })}</label>
            </ForgeRadio>
          </div>
        </div>
      );
    }
  }

  renderSingleSelectMonthPicker() {
    const startMoment = moment(this.state.dirtyFilter.arguments?.start);
    const selectedMonth = startMoment.month();
    const selectedYear = startMoment.year();

    // converts return object from getMonthOptions/getYearOptions { title, value } to { label, value }
    function convertOptions(options: { title: any; value: number }[]) {
      return options.map((option) => ({ label: option.title, value: option.value.toString() }));
    }

    const monthDropdownProps = {
      label: I18n.t('select_month', { scope }),
      value: selectedMonth.toString(),
      'on-change': (event: CustomEvent) => {
        this.onChangeSingleSelectMonthMonth({ value: Number.parseInt(event.detail) });
      },
      options: convertOptions(this.getMonthOptions()),
      className: 'single-select-month-dropdown-month',
      popupClasses: 'forge-select__popup'
    };

    const yearDropdownProps = {
      label: I18n.t('select_year', { scope }),
      value: selectedYear.toString(),
      'on-change': (event: CustomEvent) => {
        this.onChangeSingleSelectMonthYear({ value: Number.parseInt(event.detail) });
      },
      options: convertOptions(this.getYearOptions()),
      className: 'single-select-month-dropdown-year',
      popupClasses: 'forge-select__popup'
    };

    return (
      <div className="range-filter-container">
        <div className="single-select-month-picker">
          <div>
            <ForgeSelect {...monthDropdownProps} />
          </div>
          <div>
            <ForgeSelect {...yearDropdownProps} />
          </div>
        </div>
      </div>
    );
  }

  renderSingleSelectYearPicker() {
    const options = this.getYearOptions();
    const { dirtyFilter } = this.state;
    const singleSelectBy = get(dirtyFilter, 'singleSelect.by');
    let selectedYear;
    if (singleSelectBy === SINGLE_SELECT_BY.FISCAL_YEAR) {
      selectedYear = getFiscalEndYear(dirtyFilter.arguments?.end);
    } else {
      selectedYear = moment(dirtyFilter.arguments?.start).year();
    }

    const picklistProps = {
      onChange: this.onChangeSingleSelectYear,
      onSelection: this.onChangeSingleSelectYear,
      options,
      size: PicklistSizes.SMALL,
      enableForgeStyle: true,
      value: selectedYear
    };

    return (
      <div className="picklist-options-container">
        <Picklist {...picklistProps} />
      </div>
    );
  }

  getMonthOptions() {
    const months = range(0, 12);
    return map(months, (month) => ({
      title: I18n.t(`month_${month}`, { scope }),
      value: month
    }));
  }

  getYearOptions() {
    const { columns } = this.props;
    const { dirtyFilter } = this.state;
    const rangeMinMoment = moment(CalendarDateFilter.getMinValue(columns), DATE_FORMAT);
    const rangeMaxMoment = moment(CalendarDateFilter.getMaxValue(columns), DATE_FORMAT);
    let rangeMinYear = rangeMinMoment.year();
    let rangeMaxYear = rangeMaxMoment.year();

    if (get(dirtyFilter, 'singleSelect.by') === SINGLE_SELECT_BY.FISCAL_YEAR) {
      const fiscalYearMoment = getFiscalYearStartMoment();
      // If a data point occurs after the FY month, its actually in the next fiscal year, so add 1.
      // eg. Government fiscal year runs October to September, so 2018-11-15 is in govt FY 2019.
      rangeMinYear += rangeMinMoment.dayOfYear() >= fiscalYearMoment.dayOfYear() ? 1 : 0;
      rangeMaxYear += rangeMaxMoment.dayOfYear() >= fiscalYearMoment.dayOfYear() ? 1 : 0;
    }
    const years = range(rangeMinYear, rangeMaxYear + 1);

    return map(years, (year) => ({
      title: `${year}`,
      value: year
    }));
  }

  onKeyDownInputBox = (event: React.KeyboardEvent) => {
    if (event.key === '.') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  renderDateRangePicker = () => {
    const { isReadOnly, columns, dataProvider } = this.props;
    const { dirtyFilter } = this.state;

    // combination of dataset ID and column name should be unique in a global filter bar
    // this ID is used to associate input and label elements for accessibility
    const fieldId = `gfb-filter--${BaseFilter.getFilterName(dirtyFilter, columns)}--${
      dataProvider[0]?.datasetUid
    }`;

    // Only provide the start and end dates initially when the component is first rendered.
    // Previously, continuous setStates were occurring with each key press while the user typed
    // in the input fields. This caused the inputs to rerender with new dates that were valid
    // but not what the user intended
    const previousStartValue = (document.getElementById(`${fieldId}-start`) as HTMLInputElement)?.value;
    const previousEndValue = (document.getElementById(`${fieldId}-end`) as HTMLInputElement)?.value;
    const startMoment = this.getMoment(
      this.state.dirtyFilter.arguments?.start ?? CalendarDateFilter.getMinValue(columns)
    );
    const endMoment = this.getMoment(
      this.state.dirtyFilter.arguments?.end ?? CalendarDateFilter.getMaxValue(columns)
    );
    const startDate = isUndefined(previousStartValue) ? startMoment.format(DATE_FORMAT) : undefined;
    const endDate = isUndefined(previousEndValue) ? endMoment.format(DATE_FORMAT) : undefined;

    // common date picker element props
    const datePickerProps = {
      ...(startDate ? { from: startDate } : {}), // if startDate is undefined, the from key will not be added to the object
      ...(endDate ? { to: endDate } : {}),
      'on-forge-date-range-picker-change': (event: CustomEvent) => {
        this.onChangeDateRange(event.detail);
      },
      // show Today button only in View mode
      showToday: isReadOnly,
      valueMode: 'iso-string',
      disabled: CalendarDateFilter.isRelative(dirtyFilter)
    };

    return (
      <ForgeDateRangePicker {...datePickerProps}>
        <ForgeTextField>
          <input type="text" id={`${fieldId}-start`} placeholder="mm/dd/yyyy" />
          <input type="text" id={`${fieldId}-end`} placeholder="mm/dd/yyyy" />
          <label htmlFor={fieldId}>{BaseFilter.getFilterName(dirtyFilter, columns)}</label>
        </ForgeTextField>
      </ForgeDateRangePicker>
    );
  };

  renderRelativeTimePeriod = () => {
    const { popupRef, relativeDateOptions } = this.props;
    const { dirtyFilter } = this.state;
    const defaultFilterOption = get(RELATIVE_FILTER_VALUES, RELATIVE_FILTERS.TODAY);
    const relativePeriodType = dirtyFilter.arguments?.type ?? defaultFilterOption.type;

    if (!relativeDateOptions) {
      return null;
    }

    const dropdownProps = {
      label: I18n.t('relative_date_label', { scope }),
      value: relativePeriodType,
      'on-change': (event: CustomEvent) => {
        // trigger popup repositioning logic
        if (typeof popupRef?.current?.position === 'function') {
          popupRef.current.position();
        }
        this.onRelativeDatePeriodChange({ value: event.detail });
      },
      options: relativeDateOptions,
      popupClasses: 'forge-select__popup',
      disabled: !CalendarDateFilter.isRelative(dirtyFilter)
    };

    return (
      <div>
        <div className="relative-date-container">
          <div className="relative-period">
            <ForgeSelect {...dropdownProps} />
          </div>
        </div>
      </div>
    );
  };

  renderCustomTimePeriod = () => {
    const { dirtyFilter } = this.state;
    const calendarDateFilterType = dirtyFilter.arguments?.calendarDateFilterType ?? FILTER_TYPES.RANGE;
    const relativeDateType = dirtyFilter.arguments?.type;
    const relativeDateValue = dirtyFilter.arguments?.value;
    const relativeDatePeriod = dirtyFilter.arguments?.period;

    if (relativeDateType !== RELATIVE_FILTERS.CUSTOM) {
      return null;
    }

    // This is used to determine pluralization of labels only. It's ok if it's NaN.
    const count = Number.parseInt(relativeDateValue as string, 10);

    const fyFiltersEnabled = get(window, 'socrata.fiscalYearConfig.fy_filters_enabled', false);
    const options = [
      { label: I18n.t('duration_periods.day', { scope, count }), value: 'day' },
      { label: I18n.t('duration_periods.month', { scope, count }), value: 'month' },
      { label: I18n.t('duration_periods.quarter', { scope, count }), value: 'quarter' }
    ];

    // append appropriate "Year" options based on whether FY Filters are enabled
    if (fyFiltersEnabled) {
      options.push(
        { label: I18n.t('duration_periods.calendar_year', { scope, count }), value: 'calendar_year' },
        { label: I18n.t('duration_periods.fiscal_year', { scope, count }), value: 'fiscal_year' }
      );
    } else {
      options.push({ label: I18n.t('duration_periods.year', { scope, count }), value: 'year' });
    }

    const dropdownProps = {
      'aria-label': I18n.t('time_unit', { scope }),
      disabled: calendarDateFilterType !== FILTER_TYPES.RELATIVE,
      'on-change': (event: CustomEvent) => {
        this.onCustomDatePeriodChange({ value: event.detail });
      },
      options,
      popupClasses: 'forge-select__popup',
      value: relativeDatePeriod
    };

    return (
      <>
        <div className="relative-last-value">
          <ForgeTextField>
            <input
              id="custom-period-value"
              min={CUSTOM_PERIOD_INPUT_FIELD.MIN}
              disabled={calendarDateFilterType !== FILTER_TYPES.RELATIVE}
              step={CUSTOM_PERIOD_INPUT_FIELD.STEP}
              onKeyDown={this.onKeyDownInputBox}
              onChange={(event) => this.onCustomDateInputChange(event.target.value)}
              type="number"
              value={relativeDateValue}
            />
          </ForgeTextField>
          {/* this label is placed outside ForgeTextField to be not displayed */}
          <label className="screenreader-only" htmlFor="custom-period-value">
            {I18n.t('last_field_value_label', { scope })}
          </label>
        </div>
        <div className="relative-last-unit">
          <ForgeSelect {...dropdownProps} />
        </div>
      </>
    );
  };

  renderDateToTodayDatePicker() {
    const { isReadOnly, columns } = this.props;
    const { dirtyFilter } = this.state;

    const relativeDateType = dirtyFilter.arguments?.type;

    if (relativeDateType !== RELATIVE_FILTERS.DATE_TO_TODAY) {
      return null;
    }

    const startMoment = this.getMoment(
      dirtyFilter.arguments?.start ?? CalendarDateFilter.getMinValue(columns)
    );
    const date = startMoment.format(DATE_FORMAT);
    // combination of dataset ID and column name should be unique in a global filter bar
    // this ID is used to associate input and label elements for accessibility
    const fieldId = `gfb-filter--${BaseFilter.getFilterName(dirtyFilter, columns)}--${uniqueId()}`;

    // common date picker element props
    const datePickerProps = {
      value: date,
      'on-forge-date-picker-change': (event: CustomEvent) => {
        this.onChangeDateRangeToToday(event.detail);
      },
      // show Today button only in View mode
      showToday: isReadOnly,
      valueMode: 'iso-string'
    };

    return (
      <div className="range-filter-date-to-today">
        <div>
          <ForgeDatePicker {...datePickerProps}>
            <ForgeTextField>
              <input type="text" id={fieldId} />
              <label htmlFor={fieldId}>{I18n.t('start_date', { scope })}</label>
            </ForgeTextField>
          </ForgeDatePicker>
        </div>
        <div>&ndash;</div>
        <div>{I18n.t('relative_periods.today', { scope })}</div>
      </div>
    );
  }

  renderDateRangeAndRelativeTimePeriod = () => {
    const { dirtyFilter } = this.state;
    const { relativeDateOptions, columns } = this.props;
    const showCustomTimePeriod = dirtyFilter.arguments?.type === RELATIVE_FILTERS.CUSTOM;

    const fieldId = `gfb-filter--${BaseFilter.getFilterName(dirtyFilter, columns)}--${uniqueId()}-filter`;

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setCalenderDateFilterType(event.target.value as FILTER_TYPES);
    };

    return (
      <div className="range-filter-container">
        <form className="filter-options">
          <div
            className="radio-button-group"
            role="radiogroup"
            aria-label={I18n.t('shared.components.filter_bar.config.filter_selection')}
          >
            <ForgeRadio>
              <input
                type="radio"
                id={`${fieldId}-range`}
                onChange={handleRadioChange}
                checked={!CalendarDateFilter.isRelative(dirtyFilter)}
                name={`${fieldId}-selector`}
                value={FILTER_TYPES.RANGE}
                aria-label={I18n.t('date_range_label', { scope })}
              />
              <div slot="label">{this.renderDateRangePicker()}</div>
            </ForgeRadio>
            {(relativeDateOptions || showCustomTimePeriod) && (
              <ForgeRadio>
                <input
                  type="radio"
                  id={`${fieldId}-relative`}
                  onChange={handleRadioChange}
                  checked={CalendarDateFilter.isRelative(dirtyFilter)}
                  name={`${fieldId}-selector`}
                  value={FILTER_TYPES.RELATIVE}
                  aria-label={I18n.t('relative_date_label', { scope })}
                />
                <div slot="label" className="range-filter-relative">
                  {this.renderRelativeTimePeriod()}
                  {this.renderCustomTimePeriod()}
                </div>
              </ForgeRadio>
            )}
          </div>
          {this.renderDateToTodayDatePicker()}
        </form>
      </div>
    );
  };

  render() {
    const { isReadOnly, onRemove, showRemoveButtonInFooter = false } = this.props;
    const { dirtyFilter } = this.state;
    const footerProps = {
      disableApplyFilter: !this.isDirty(),
      isDrilldown: dirtyFilter.isDrilldown,
      isReadOnly,
      onClickApply: this.applyFilter,
      onClickRemove: onRemove,
      onClickReset: this.resetFilter,
      showRemoveButton: showRemoveButtonInFooter
    };

    const singleSelectBy = typeof dirtyFilter.singleSelect === 'object' && dirtyFilter.singleSelect.by;
    let controls;

    switch (singleSelectBy) {
      case SINGLE_SELECT_BY.YEAR:
      case SINGLE_SELECT_BY.FISCAL_YEAR: // Fiscal year has the exact same picker style as year
        set(footerProps, 'showApplyButton', false);
        controls = this.renderSingleSelectYearPicker();
        break;
      case SINGLE_SELECT_BY.MONTH:
        controls = this.renderSingleSelectMonthPicker();
        break;
      case SINGLE_SELECT_BY.DAY:
        controls = this.renderSingleSelectDayPicker();
        break;
      default:
        controls = this.renderDateRangeAndRelativeTimePeriod();
    }

    const containerClasses = 'filter-controls calendar-date-filter';

    const fiscalYearLabel = (
      <span className="single-select-fiscal-year-label">
        {I18n.t('shared.components.filter_bar.config.fiscal_year_label')}
      </span>
    );

    return (
      <div className={containerClasses} ref={(el: HTMLDivElement) => (this.dateFilter = el)}>
        {singleSelectBy === SINGLE_SELECT_BY.FISCAL_YEAR && fiscalYearLabel}
        {controls}
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default CalendarDateFilterEditor;
