/* Imports */
/* ============================================================================= */
import { isFunction } from 'lodash';

import { GuidanceSummaryV2 } from 'common/types/approvals';

import {
  GuidanceBooleanCheckSummary,
  CheckMethod,
  CheckMethodsObject
} from 'common/core/approvals/Types';
import { getOnlyMatchingWorkflow } from '.';





/* Method */
/* ============================================================================= */
/**
 * Wrap the approve, reject, or withdraw methods in logic to determine which workflow to call, then run the method for that workflow
 * @param guidance GuidanceSummaryV2 object
 * @param workflows GuidanceBooleanCheckSummary from a `summarize*` method.
 * @param methods WorkflowGuidancePromiseMethods object of methods to call if the a workflow has a pending approval
 * @param args (optional) Any additional arguments will be passed along to the invoked method
 * @returns Promise
 */
export const runWrappedApprovalActionMethod = (
  guidance: GuidanceSummaryV2,
  workflows: GuidanceBooleanCheckSummary,
  methods: CheckMethodsObject<Promise<Response>>,
  ...args: any[]
): Promise<Response> => {
  let response: Promise<Response>;

  // The workflows param is the result of running the summarizeIsPending() method
  if (workflows.length > 1) {
    throw new Error('Asset has more than one pending approval request');
  } else if (workflows.length === 1) {
    const method = methods[getOnlyMatchingWorkflow(workflows)] as CheckMethod<Promise<Response>>;

    // If we have 1 workflow with a pending approval and a handler method for that workflow, run it with the provided args
    response = isFunction(method)
      ? method(...args)
      : Promise.reject('No action (approve, reject, or withdraw) handler method available for the workflow with a pending approval');
  } else {
    response = Promise.reject('Asset approval action (approve, reject, or withdraw) attempted on an asset with no pending approvals');
  }

  return response;
};

export default runWrappedApprovalActionMethod;
