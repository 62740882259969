import _ from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';
import { getClusterProperties, getPointCountAbbreviationExpression } from './PointsAndStacks';

export const SOURCES = Object.freeze({
  CLUSTERS: 'clusters-source'
});

export const LAYERS = Object.freeze({
  CLUSTERS_CIRCLE: 'clusters-circle-layer',
  CLUSTERS_LABEL: 'clusters-label-layer'
});

// Renders cluster circles and updates cluster rendering based on vif.
export default class Clusters {
  constructor(map, seriesId) {
    this._map = map;
    this.layerIds = MapHelper.getNameToIdMap(_.values(LAYERS), seriesId);
    this.sourceIds = MapHelper.getNameToIdMap(_.values(SOURCES), seriesId);
  }

  alreadySetup() {
    return this._map.getSource(this.sourceIds[SOURCES.CLUSTERS]);
  }

  setup(vif, renderOptions, overlayOptions) {
    const isMultiPointColumn = vif.isMultiPointColumn(renderOptions.datasetMetadata);
    const layerVisibility = vif.isLayerVisible() && !isMultiPointColumn ? 'visible' : 'none';
    const clusterCircleRadius = vif.getClusterCircleRadiusPaintProperty(
      renderOptions.resizeByRange,
      renderOptions.aggregateAndResizeBy
    );

    this._map.addSource(this.sourceIds[SOURCES.CLUSTERS], this.sourceOptions(vif, renderOptions));

    this._map.addLayer({
      'id': this.layerIds[LAYERS.CLUSTERS_CIRCLE],
      'type': 'circle',
      'source': this.sourceIds[SOURCES.CLUSTERS],
      'source-layer': '_geojsonTileLayer',
      'maxzoom': vif.getMaxClusteringZoomLevel(),
      'layout': {
        'visibility': layerVisibility
      },
      'paint': {
        'circle-radius': clusterCircleRadius,
        'circle-color': renderOptions.layerStyles.CLUSTER_COLOR,
        'circle-stroke-width': renderOptions.layerStyles.CLUSTER_BORDER_SIZE,
        'circle-stroke-color': renderOptions.layerStyles.CLUSTER_BORDER_COLOR,
        'circle-stroke-opacity': renderOptions.layerStyles.CLUSTER_BORDER_OPACITY
      }
    }, overlayOptions.renderLayersBefore);

    this._map.addLayer({
      'id': this.layerIds[LAYERS.CLUSTERS_LABEL],
      'type': 'symbol',
      'source': this.sourceIds[SOURCES.CLUSTERS],
      'source-layer': '_geojsonTileLayer',
      'maxzoom': vif.getMaxClusteringZoomLevel(),
      'layout': {
        'text-field': getPointCountAbbreviationExpression(renderOptions.countBy),
        'text-size': 12,
        'text-allow-overlap': true,
        'visibility': layerVisibility
      },
      'paint': {
        'text-color': renderOptions.layerStyles.CLUSTER_TEXT_COLOR
      }
    }, overlayOptions.renderLayersBefore);
  }

  update(vif, renderOptions) {
    const isMultiPointColumn = vif.isMultiPointColumn(renderOptions.datasetMetadata);
    const layerVisibility = vif.isLayerVisible() && !isMultiPointColumn ? 'visible' : 'none';

    // Updating cluster look and feel based on new base-map-style in vif
    this._map.setLayoutProperty(this.layerIds[LAYERS.CLUSTERS_CIRCLE],
      'visibility',
      layerVisibility
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.CLUSTERS_CIRCLE],
      'circle-radius',
      vif.getClusterCircleRadiusPaintProperty(renderOptions.resizeByRange, renderOptions.aggregateAndResizeBy));
    this._map.setPaintProperty(this.layerIds[LAYERS.CLUSTERS_CIRCLE],
      'circle-color',
      renderOptions.layerStyles.CLUSTER_COLOR);
    this._map.setPaintProperty(this.layerIds[LAYERS.CLUSTERS_CIRCLE],
      'circle-stroke-width',
      renderOptions.layerStyles.CLUSTER_BORDER_SIZE);
    this._map.setPaintProperty(this.layerIds[LAYERS.CLUSTERS_CIRCLE],
      'circle-stroke-color',
      renderOptions.layerStyles.CLUSTER_BORDER_COLOR);
    this._map.setPaintProperty(this.layerIds[LAYERS.CLUSTERS_CIRCLE],
      'circle-stroke-opacity',
      renderOptions.layerStyles.CLUSTER_BORDER_OPACITY);
    this._map.setLayoutProperty(this.layerIds[LAYERS.CLUSTERS_LABEL],
      'visibility',
      layerVisibility
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.CLUSTERS_LABEL],
      'text-color',
      renderOptions.layerStyles.CLUSTER_TEXT_COLOR);
  }

  sourceOptions(vif, renderOptions) {
    const tileUrl = vif.isMultiPointColumn(renderOptions.datasetMetadata) ? '' : renderOptions.dataUrl;

    return {
      'type': 'vector',
      'geojsonTile': true,
      'cluster': true,
      'clusterRadius': vif.getClusterRadius(),
      'clusterProperties': getClusterProperties(renderOptions),
      'tiles': [tileUrl],
      'maxzoom': vif.getMaxClusteringZoomLevel()
    };
  }

  destroy() {
    _.each(this.layerIds, (layerId) => {
      this._map.removeLayer(layerId);
    });
    _.each(this.sourceIds, (sourceId) => {
      this._map.removeSource(sourceId);
    });
  }
}
