import _ from 'lodash';
import moment from 'moment';

import I18n from 'common/i18n';

import {
  TargetTypes,
  TimelineScopes,
  TIMESTAMP_WITHOUT_ZONE
} from '../../lib/constants';
import { Measure, Target } from 'common/performance_measures/types';
import { DataRow, InlineDataRows, Vif } from 'common/visualizations/vif';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';
import { formatChartRowForSummaryTable, formatChartRowsForSummaryTable } from './summaryTable';

/*
 * The different target types (ongoing vs periodic) have different criteria
 * for what it means to be fully configured.
 * Periodic targets require 'startDate' AND 'value' whereas Ongoing only requires 'value'
 *
 * It is useful to filter out unconfigured targets since it is possible in the UI
 * to add many empty targets, which should be excluded for all the data-munging and display logic
 */
export const getConfiguredTargets = (targets: Target[], targetType: TargetTypes) => {
  switch (targetType) {
    case TargetTypes.PERIODIC:
      return _.filter(targets, (target) => {
        return target.type === TargetTypes.PERIODIC && !_.isNil(target.startDate) && !_.isNil(target.value);
      });
    case TargetTypes.ONGOING:
      return _.filter(targets, (target) => {
        return target.type === TargetTypes.ONGOING && !_.isNil(target.value);
      });
    default:
      throw new Error(`Unknown target type provided: ${targetType}`);
  }
};

/**
 *  Target lines should only be drawn if targets exist AND if all reporting periods are shown (not zoomed)
 */
export const shouldShowTargets = (measure: Measure, timelineScope: TimelineScopes) => {
  const targets = _.get(measure, 'metricConfig.targets', []);
  const hasTargets = !_(targets).map('value').compact().isEmpty();

  return timelineScope === TimelineScopes.ALL && hasTargets;
};

/**
 *  In order to draw a periodic target line on the timeline chart, the target's value needs to be recorded
 *  on the series data at both the target's startDate, as well as one reporting period after that startDate
 *
 *  e.g Given:
 *        target = { startDate: '01-01-2018', value: '250' }
 *        reportingPeriodSize = 'month'
 *
 *      The series data would look something like:
 *        [
 *          ...,
 *          ['01-01-2018', <someMeasureValue>, 250],
 *          ['02-01-2018', <someMeasureValue>, 250], <-- this extra period completes the line segment
 *          ...
 *        ]
 *
 *  The business rules for which targets are displayed on the timeline chart are as follows:
 *    - Display all targets that are within a measure's startDate --> current reporting period
 *    - Also, display targets if they are only 1 reporting period further than the current reporting period
 *    - All other targets outside the criteria above are not displayed
 *
 *  With that being said, if there are any targets that fall on the current reporting period, the series
 *  data needs to be extended by 1 reporting period, in order to complete the line.
 *  If there are targets that fall on the reporting period after the current reporting period, then
 *  the series data needs to be extended by 2 reporting periods in order to draw a complete line.
 */
export const extendSeriesForTargets = (measure: Measure, vif: Vif): Vif => {
  const chartRows = _.get(vif, 'series[0].dataSource.rows', []);
  const summaryTableRows = _.get(vif, 'series[0].dataSource.summaryTable.rows', []);

  if (_.isEmpty(chartRows) || _.isEmpty(summaryTableRows) || chartRows.length !== summaryTableRows.length) {
    return vif;
  }

  const newChartRows: InlineDataRows = _.cloneDeep(chartRows);
  const newSummaryTableRows: InlineDataRows = _.cloneDeep(summaryTableRows);
  const reportingPeriodSize = _.get(measure, 'metricConfig.reportingPeriod.size');
  const lastSeriesStartDate = _.last(newChartRows)![0];

  const targets = _.get(measure, 'metricConfig.targets', []);
  const configuredPeriodicTargets = getConfiguredTargets(targets, TargetTypes.PERIODIC);

  // 'upcoming' refers to the reporting period immediately after the last period in the series
  const upcomingStartDate = moment(lastSeriesStartDate!).
    add(1, reportingPeriodSize).
    format(TIMESTAMP_WITHOUT_ZONE);


  const hasTargetInUpcomingReportingPeriod = _.some(configuredPeriodicTargets, (target) => {
    return moment(target.startDate).format(TIMESTAMP_WITHOUT_ZONE) === upcomingStartDate;
  });

  const hasTargetInLastReportingPeriod = _.some(configuredPeriodicTargets, (target) => {
    return moment(target.startDate).format(TIMESTAMP_WITHOUT_ZONE) === lastSeriesStartDate;
  });

  let numPeriodsToExtend = 0;

  if (hasTargetInUpcomingReportingPeriod) {
    numPeriodsToExtend = 2;
  } else if (hasTargetInLastReportingPeriod) {
    numPeriodsToExtend = 1;
  }

  _.times(numPeriodsToExtend, () => {
    const nextReportingPeriodStart = moment(_.last(newChartRows)![0]!).
      add(1, reportingPeriodSize).
      format(TIMESTAMP_WITHOUT_ZONE);
    const nextChartRow = [nextReportingPeriodStart, null];

    const nextSummaryTableRow = formatChartRowForSummaryTable(measure, nextChartRow);

    newChartRows.push(nextChartRow);
    newSummaryTableRows.push(nextSummaryTableRow);
  });

  const newVif = _.cloneDeep(vif);
  _.set(newVif, 'series[0].dataSource.rows', newChartRows);
  _.set(newVif, 'series[0].dataSource.summaryTable.rows', newSummaryTableRows);

  _.set(vif, 'configuration.dimensionAxisMaxValue', _.last(newChartRows)![0]);

  return newVif;
};

interface TargetWithIndex extends Target {
  index: number;
}

const convertTargetsToMap = (
  targets: Target[]
): { [targetStartDate: string]: TargetWithIndex[] } => {
  return targets.reduce((targetsMap, target, index) => {
    const targetStartDate = moment(target.startDate).format(TIMESTAMP_WITHOUT_ZONE);
    if (targetsMap[targetStartDate] === undefined) {
      targetsMap[targetStartDate] = [];
    }

    // Preserving the target's index in this array should preserve user entered order
    // for targets that correspond to the same reporting period
    targetsMap[targetStartDate].push({ ...target, index });
    return targetsMap;
  }, {});
};

/**
 *  Appends target values at the corresponding reporting periods in the chart rows and
 *  the summary table rows. See #extendSeriesForTargets for why the target value is duplicated
 *  in an extra reporting period for the chart rows.
 *
 *  Given:
 *    vif = {
 *      series: [{
 *        dataSource: {
 *          rows: [
 *            ['01-01-2018', <someMeasureValue>],
 *            ['02-01-2018', <someMeasureValue>],
 *            ['03-01-2018', <someMeasureValue>]
 *          ],
 *          summaryTable: {
 *            rows: [
 *              ['1/1/2018-', <someMeasureValue>],
 *              ['02-01-2018', <someMeasureValue>]
 *            ]
 *          }
 *        }
 *       }]
 *     }
 *
 *    measure.metricConfig.targets = [{ type: 'periodic', startDate: '02-01-2018', value: '50' }]
 *
 *  addTargetDataToSeriesRows(measure, vif) => {
 *    series: [{
 *      dataSource: {
 *        rows: [
 *          ['01-01-2018', <someMeasureValue>, null],
 *          ['02-01-2018', <someMeasureValue>, 50],
 *          ['03-01-2018', <someMeasureValue>, 50]
 *        ],
 *        summaryTable: {
 *          rows: [
 *            ['1/1/2018-', <someMeasureValue>, null],
 *            ['02-01-2018', <someMeasureValue>, 50]
 *          ]
 *        }
 *      }
 *     }]
 *   }
 */
export const addTargetDataToSeriesRows = (measure: Measure, vif: Vif) => {

  const chartRows = _.get(vif, 'series[0].dataSource.rows', []);
  const summaryTableRows = _.get(vif, 'series[0].dataSource.summaryTable.rows', []);

  if (_.isEmpty(chartRows) || _.isEmpty(summaryTableRows) || chartRows.length !== summaryTableRows.length) {
    return vif;
  }

  const newChartRows: InlineDataRows = [];
  const newSummaryTableRows: InlineDataRows = [];
  const targets = _.get(measure, 'metricConfig.targets', []);
  const configuredPeriodicTargets = getConfiguredTargets(targets, TargetTypes.PERIODIC);
  const targetsMap = convertTargetsToMap(configuredPeriodicTargets);

  chartRows.forEach((row: DataRow, index: number) => {
    const targetValues: (number|null)[] = _.times(configuredPeriodicTargets.length, _.constant(null));
    const startDate = row[0];

    // Add targets if this isn't the buffer reporting period and there are any
    // targets that correspond with this reporting period
    if (index < (chartRows.length - 1) && !_.isEmpty(targetsMap[startDate!])) {
      targetsMap[startDate!].forEach((target) => {
        targetValues[target.index] = parseFloat(target.value!);
      });

      newChartRows.push(row.concat(targetValues));
      newSummaryTableRows.push(summaryTableRows[index].concat(targetValues));

      // Insert a point one second before the next reporting period for the end
      // of the targets in this reporting period
      const nextRow = chartRows[index + 1];
      const [targetEndDate, measureValue] = nextRow;
      const formattedTargetEndDate = moment(targetEndDate).subtract(1, 'second').format(TIMESTAMP_WITHOUT_ZONE);
      const targetEndRow = [formattedTargetEndDate, measureValue, ...targetValues];

      newChartRows.push(targetEndRow);
    } else {
      newChartRows.push(row.concat(targetValues));
      newSummaryTableRows.push(summaryTableRows[index].concat(targetValues));
    }
  });

  const newVif = _.cloneDeep(vif);
  _.set(newVif, 'series[0].dataSource.rows', newChartRows);
  _.set(newVif, 'series[0].dataSource.summaryTable.rows', newSummaryTableRows);

  return newVif;
};

export const generatePeriodicTargetVifs = (measure: Measure, columnFormat: ViewColumn) => {
  const { metricConfig } = measure;

  const dateColumn = _.get(metricConfig, 'dateColumn');
  const reportingPeriodSize = _.get(metricConfig, 'reportingPeriod.size');
  const targets = _.get(metricConfig, 'targets', []);
  const configuredPeriodicTargets = getConfiguredTargets(targets, TargetTypes.PERIODIC);
  const unitLabel = _.get(metricConfig, 'display.label');
  const pluralUnitLabel = _.get(metricConfig, 'display.pluralLabel');

  const getFixedTargetVifAttributes = () => ({
    lineStyle: {
      horizontalAlignment: 'left'
    },
    color: {
      primary: '#adadad' // $light-grey-2
    },
    dataSource: {
      precision: reportingPeriodSize.toUpperCase(),
      dimension: {
        columnName: dateColumn,
        aggregationFunction: null
      },
      measure: {},
      type: 'socrata.inline'
    },
    type: 'timelineChart',
    unit: {
      one: unitLabel || pluralUnitLabel,
      other: pluralUnitLabel || unitLabel
    }
  });

  return configuredPeriodicTargets.map((target, index) => {
    const measureFieldName = `${target.label}_${index}`;
    const measureName = getPeriodicTargetLabel(target, measure);

    return _.merge(getFixedTargetVifAttributes(), {
      dataSource: {
        measure: {
          columnName: measureFieldName,
          columnFormat: {
            ...columnFormat,
            fieldName: measureFieldName
          }
        }
      },
      label: measureName
    });
  });
};

export const generateOngoingTargetReferenceLines = (measure: Measure, format: ColumnFormat) => {
  const { metricConfig } = measure;
  const targets = _.get(metricConfig, 'targets', []);
  const configuredOngoingTargets = getConfiguredTargets(targets, TargetTypes.ONGOING);
  const unitLabel = _.get(metricConfig, 'display.label');
  const pluralUnitLabel = _.get(metricConfig, 'display.pluralLabel');

  return configuredOngoingTargets.map((target, index) => ({
    color: '#adadad', // $light-grey-2
    format,
    label: getOngoingTargetLabel(target, measure),
    uId: `ongoing-target-${index}`,
    unit: {
      one: unitLabel || pluralUnitLabel,
      other: pluralUnitLabel || unitLabel
    },
    value: parseFloat(target.value!)
  }));
};

/**
 * @param {Object} target must be ongoing
 * @param {Object} measure
 * @return {string} flyout label
 */
const getOngoingTargetLabel = (target: Target, measure: Measure) => {
  const targetTerminology = _.get(measure, 'metricConfig.display.targetTerminology');
  const scope = 'shared.performance_measures.chart.target_labels.ongoing';
  return target.label || targetTerminology || I18n.t('default', { scope });
};

/**
 * @param {Object} target must be periodic
 * @param {Object} measure
 * @return {string} flyout label
 */
const getPeriodicTargetLabel = (target: Target, measure: Measure) => {
  const targetTerminology = _.get(measure, 'metricConfig.display.targetTerminology');
  const { startDate, label } = target;
  const scope = 'shared.performance_measures.chart.target_labels.periodic';
  return label ||
    (targetTerminology && I18n.t('custom', { scope, date: startDate, targetTerminology })) ||
    I18n.t('default', { scope, date: startDate });
};


export const modifyVifForTargets = (
  vif: Vif,
  measure: Measure,
  series: InlineDataRows,
  columnFormat: ViewColumn
): Vif => {
  // First, extend both the chart and summary table rows so that they can accommodate
  // periodic targets.This must happen _before_ the periodic target values are added.
  vif = extendSeriesForTargets(measure, vif);

  // Add periodic target _values_ to the chart and summary table data.
  vif = addTargetDataToSeriesRows(measure, vif) as Vif;

  // Add ongoing targets as reference lines. This controls both the formatting and
  // the values of ongoing targets.
  const { format } = columnFormat;
  const vifReferenceLinesForOngoingTargets = generateOngoingTargetReferenceLines(measure, format);
  _.set(vif, 'referenceLines', vifReferenceLinesForOngoingTargets);

  // Add periodic target metadata (formats the flyout and line on the chart)
  const vifMetadataForPeriodicTargets = generatePeriodicTargetVifs(measure, columnFormat);
  Array.prototype.push.apply(vif.series, vifMetadataForPeriodicTargets);

  return vif;
};
