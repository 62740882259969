import _ from 'lodash';
import d3 from 'd3';

/**
 * SvgKeyboardPanning
 * Attaches the zoom panning functionality to the left and right arrow keys
 *
 * @param {object} d3Zoom - D3 object after zoom behavior has been created
 * @param {object} chartSvg - D3 chart SVG object
 * @param {string} selector - css selector to bind keyboard event
 *
 * @return nothing, this function has side effects
 *
 * @example
 *
 *     SvgKeyboardPanning(d3Zoom, chartSvg, 'body)
 */
export const SvgKeyboardPanning = (d3Zoom, chartSvg, selector) => {
  d3.selectAll(selector).
    on('keydown', () => {

      const step = 200;
      const key = d3.event.key || d3.event.keyCode; // keyCode needed for safari browser

      switch (key) {
        case 'Left':
        case 'ArrowLeft': // key for chrome
        case 37:
          d3.event.preventDefault();
          d3Zoom.
            translate([d3Zoom.translate()[0] + step, d3Zoom.translate()[1]]).
            event(chartSvg.transition());
            break;
        case 'Right':
        case 'ArrowRight': // key for chrome
        case 39:
          d3.event.preventDefault();
          d3Zoom.
            translate([d3Zoom.translate()[0] - step, d3Zoom.translate()[1]]).
            event(chartSvg.transition());
            break;
        case 'Up':
        case 'ArrowUp': // key for chrome
        case 38:
          d3.event.preventDefault();
          d3Zoom.
            translate([d3Zoom.translate()[0], d3Zoom.translate()[1] + step]).
            event(chartSvg.transition());
            break;
        case 'Down':
        case 'ArrowDown': // key for chrome
          case 40:
            d3.event.preventDefault();
            d3Zoom.
              translate([d3Zoom.translate()[0], d3Zoom.translate()[1] - step]).
              event(chartSvg.transition());
              break;
        default:
          break;
      }
  });
};

export default SvgKeyboardPanning;
