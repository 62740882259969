import _ from 'lodash';
import $ from 'jquery';
import assertInstanceOfAny from 'common/assertions/assertInstanceOfAny';

import I18n from 'common/i18n';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';

// import these to make sure the jquery plugins have been initialized
import './AgTable';
import './SvgScatterChart';
import './SvgBarChart';
import './Calendar';
import './SvgColumnChart';
import './SvgComboChart';
import './SvgFeatureMap';
import './SvgHistogram';
import './SvgPieChart';
import './SvgRegionMap';
import './SvgTimelineChart';
import './UnifiedMap';
import './Table';

import { RowInspector, FlyoutRenderer } from './views';
// Constants
import { VIF_UPDATE_DEBOUNCE_MILLI_SECONDS } from './views/SvgConstants';

/**
 * Instantiates a Socrata Visualization from the `visualizations` package,
 * based on the visualization type indicated in the VIF.
 *
 * @param vif - see /VIF.md
 * @param element - element to render the visualization inside of
 * @param options - Options hash, optional. Keys:
 *   - flyoutRenderer: Flyout renderer to use instead of default.
 */
export const VisualizationRenderer = function (vif, element, options = {}) {
  assertInstanceOfAny(element, HTMLElement, $);
  const $element = $(element);
  $element.addClass('socrata-visualization-renderer');

  const mergeOptionDefaults = (opts) => {
    return _.merge(
      {
        flyoutRenderer: new FlyoutRenderer()
      },
      opts
    );
  };

  options = mergeOptionDefaults(options);
  const flyoutRenderer = options.flyoutRenderer;

  this.vif = vif;

  const onFlyout = (event) => {
    const payload = event.originalEvent.detail;

    if (_.isUndefined(flyoutRenderer)) {
      return;
    }

    if (_.isNull(payload)) {
      flyoutRenderer.clear();
    } else {
      flyoutRenderer.render(payload);
    }
  };

  const initializeVisualization = () => {
    const visualizationType = _.get(this.vif, 'series[0].type', '').split('.')[0];
    switch (visualizationType) {
      case 'agTable':
        $element.socrataAgTable(this.vif, options);
        break;

      case 'barChart':
        $element.socrataSvgBarChart(this.vif, options);
        break;

      case 'calendar':
        $element.socrataCalendar(this.vif, options);
        break;

      case 'columnChart':
        $element.socrataSvgColumnChart(this.vif, options);
        break;

      case 'comboChart':
        $element.socrataSvgComboChart(this.vif, options);
        break;

      case 'featureMap':
        $element.socrataSvgFeatureMap(this.vif, options);
        // RowInspector is the detailed flannel handler for feature maps
        RowInspector.setup();
        break;

      case 'histogram':
        $element.socrataSvgHistogram(this.vif, options);
        break;

      case 'pieChart':
        $element.socrataSvgPieChart(this.vif, options);
        break;

      case 'regionMap':
        $element.socrataSvgRegionMap(this.vif, options);
        break;

      case 'map':
        $element.socrataUnifiedMap(this.vif, options);
        break;

      case 'scatterChart':
        $element.socrataSvgScatterChart(this.vif, options);
        break;

      case 'table':
      case 'dataTable':
        // Passing options.locale is a temporary workaround to localize the Table & Pager
        $element.socrataTable(this.vif, options);
        break;

      case 'timelineChart':
        $element.socrataSvgTimelineChart(this.vif, options);
        break;

      case 'timelineChart.line':
        $element.socrataSvgTimelineChart(this.vif, options);
        break;

      default:
        // Something is terribly wrong with the VIF, render an error message
        return renderVifError();
    }

    // FlyoutRenderer (via onFlyout) is used by all visualizations
    $element.on('SOCRATA_VISUALIZATION_FLYOUT', onFlyout);
  };

  const updateVisualization = () => {
    const renderVifEvent = new $.Event('SOCRATA_VISUALIZATION_RENDER_VIF');
    renderVifEvent.originalEvent = {
      detail: this.vif
    };

    $element.trigger(renderVifEvent);
  };

  const renderVifError = () => {
    const $errorMessage = $(
      `<div class="alert error"><span>${I18n.t(
        'shared.visualizations.charts.common.error_generic'
      )}</span></div>`
    );

    $element.append($errorMessage);
  };

  const render = () => {
    if (_.isEmpty(this.vif)) {
      return renderVifError();
    } else {
      this.vif = migrateVif(this.vif);
    }

    if ($element.children().length > 0) {
      updateVisualization();
    } else {
      initializeVisualization();
    }
  };

  // Some actions like drilling down by clicking on a bar in a bar chart,
  // multiple redux actions gets dispatched and without debounce,
  // the visualization will be updated multiple times in quick succession.
  this.update = _.debounce((newVif, newOptions = {}) => {
    const currentType = _.get(this.vif, 'series[0].type', null);
    const newType = _.get(newVif, 'series[0].type', null);
    const changes = _.reduce(
      this.vif,
      function (result, value, key) {
        return _.isEqual(value, newVif[key]) ? result : result.concat(key);
      },
      []
    );

    const optionKeysIgnoredForChange = ['flyoutRenderer'];
    const newOptionsKeys = newOptions ? Object.keys(newOptions) : [];
    const allOptionKeys = _.uniq(newOptionsKeys.concat(Object.keys(options)));
    const optionsChanged = allOptionKeys.some(
      (k) => !optionKeysIgnoredForChange.includes(k) && !_.isEqual(newOptions[k], options[k])
    );

    if (currentType !== newType || optionsChanged) {
      this.destroy();
    }

    if (changes.length == 1 && changes[0] === 'format' && !optionsChanged) {
      return;
    }

    if (!_.isEqual(this.vif, newVif) || optionsChanged) {
      this.vif = newVif;
      options = newOptions;
      render();
    }
  }, VIF_UPDATE_DEBOUNCE_MILLI_SECONDS);

  this.destroy = () => {
    $element.trigger('SOCRATA_VISUALIZATION_DESTROY').off('SOCRATA_VISUALIZATION_FLYOUT', onFlyout).empty();
  };

  // Do initial render
  render();
};

export default VisualizationRenderer;
