import _ from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';
import { HIGHLIGHT, MAP_TYPES } from 'common/visualizations/views/mapConstants';

const LAYERS = Object.freeze({
  POINT_HIGHLIGHT: 'point-highlight-layer',
  SHAPE_OR_LINE_HIGHLIGHT: 'shape-or-line-highlight-layer'
});

const SOURCES = Object.freeze({
  HIGHLIGHT_FOR_POINT_MAP: 'highlight-pointmap-source',
  HIGHLIGHT_FOR_LINE_OR_SHAPE_MAP: 'highlight-line-or-shape-map-source'
});

export default class Highlight {
  constructor(map) {
    this._map = map;
  }

  initialize = async () => {
    await MapHelper.afterMapLoad(this._map);

    this._map.addSource(SOURCES.HIGHLIGHT_FOR_POINT_MAP, {
      type: 'geojson',
      data: getGeojson()
    });

    this._map.addSource(SOURCES.HIGHLIGHT_FOR_LINE_OR_SHAPE_MAP, {
      type: 'geojson',
      data: getGeojson()
    });

    this._map.addLayer({
      'id': LAYERS.POINT_HIGHLIGHT,
      'type': 'circle',
      'source': SOURCES.HIGHLIGHT_FOR_POINT_MAP,
      'paint': {
        'circle-color': HIGHLIGHT.COLOR,
        'circle-opacity': HIGHLIGHT.OPACITY.DEFAULT,
        'circle-radius-transition': { duration: 0, delay: 0 },
        'circle-color-transition': { duration: 0, delay: 0 }
      }
    });

    this._map.addLayer({
      'id': LAYERS.SHAPE_OR_LINE_HIGHLIGHT,
      'type': 'line',
      'source': SOURCES.HIGHLIGHT_FOR_LINE_OR_SHAPE_MAP,
      'paint': {
        'line-color': HIGHLIGHT.COLOR,
        'line-color-transition': { duration: 0, delay: 0 },
        'line-width-transition': { duration: 0, delay: 0 }
      }
    });
  };

  highlightPoint(feature, circleRadius, highlightLayerId) {
    this._map.getSource(SOURCES.HIGHLIGHT_FOR_POINT_MAP).setData(getGeojson(feature));

    this._map.moveLayer(LAYERS.POINT_HIGHLIGHT, highlightLayerId);
    this._map.setLayoutProperty(LAYERS.POINT_HIGHLIGHT, 'visibility', 'visible');
    this._map.setPaintProperty(LAYERS.POINT_HIGHLIGHT, 'circle-radius', (circleRadius / 2) + HIGHLIGHT.POINT_RADIUS);
  }

  highlightLineOrShape(feature, lineWidth, mapType, highlightLayerId) {
    this._map.getSource(SOURCES.HIGHLIGHT_FOR_LINE_OR_SHAPE_MAP).setData(getGeojson(feature));
    let highlightWidth = HIGHLIGHT.SHAPE_WIDTH;
    let highlightLineOpacity = HIGHLIGHT.OPACITY.MAX;

    if (mapType === MAP_TYPES.LINE_MAP) {
      highlightWidth = HIGHLIGHT.LINE_WIDTH;
      highlightLineOpacity = HIGHLIGHT.OPACITY.DEFAULT;
      this._map.moveLayer(LAYERS.SHAPE_OR_LINE_HIGHLIGHT, highlightLayerId);
      this._map.setLayoutProperty(LAYERS.SHAPE_OR_LINE_HIGHLIGHT, 'line-cap', HIGHLIGHT.LINE_CAP);
    } else {
      this._map.moveLayer(LAYERS.SHAPE_OR_LINE_HIGHLIGHT);
      this._map.setPaintProperty(LAYERS.SHAPE_OR_LINE_HIGHLIGHT, 'line-blur', HIGHLIGHT.LINE_BLUR);
    }

    this._map.setPaintProperty(LAYERS.SHAPE_OR_LINE_HIGHLIGHT, 'line-opacity', highlightLineOpacity);
    this._map.setPaintProperty(LAYERS.SHAPE_OR_LINE_HIGHLIGHT, 'line-width', lineWidth + highlightWidth);
  }

  hideHighlightPointLayer() {
    this._map.setLayoutProperty(LAYERS.POINT_HIGHLIGHT, 'visibility', 'none');
  }

  destroy() {
    _.each(SOURCES, (sourceId) => {
      const source = this._map.getSource(sourceId);
      if (!_.isUndefined(source)) {
        source.setData(getGeojson());
      }
    });
  }
}

function getGeojson(feature = null) {
  let highlightFeatures = [];

  if (!_.isNil(feature)) {
    highlightFeatures = [
      {
        type: 'Feature',
        geometry: feature.geometry
      }
    ];
  }

  return {
    type: 'FeatureCollection',
    features: highlightFeatures
  };
}

