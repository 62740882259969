import { Expr, FunCall, isFunCall, isTypedFunCall, TypedExpr, TypedSoQLFunCall, SoQLType } from 'common/types/soql';
import { replaceAt, splice } from 'common/util';
import { initialFilter, additionalFilter, additionalFilterNA, initialFilterNA } from '../../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import ExpressionEditor, { AstNode, ExprProps, matchEexpr, matchEexprNA } from '../VisualExpressionEditor';
import AddFilter from '../AddFilter';
import BooleanDivider from './BooleanDivider';
import { Operator } from './Types';
import { Eexpr, EexprNA } from 'common/explore_grid/types';
import { factorArray, Either } from 'common/either';
import { whichAnalyzer } from '../../lib/feature-flag-helpers';

export const zipArgs = (eexpr: Eexpr<Expr, TypedExpr>): Eexpr<Expr, TypedExpr>[] => {
  return matchEexpr(
    eexpr,
    ({ untyped: expr, typed}) => {
      if (isFunCall(expr) && isTypedFunCall(typed)) {
        return expr.args.map((arg, i) => {
          return { untyped: arg, typed: typed.args[i] };
        });
      }
      return [];
    },
    ({ untyped, error }) => {
      if (isFunCall(untyped)) {
        return untyped.args.map((arg, i) => {
          return { untyped: arg, error };
        });
      }
      return [];
    }
  );
};
export const zipArgsNA = (eexpr: EexprNA<TypedExpr>): EexprNA<TypedExpr>[] => {
  return matchEexprNA(
    eexpr,
    ({ expr }) => {
      if (isTypedFunCall(expr)) {
        return expr.args.map((arg, i) => {
          return { expr: expr.args[i] };
        });
      }
      return [];
    },
    ({ expr, error }) => {
      if (isTypedFunCall(expr)) {
        return expr.args.map((arg, i) => {
          return { expr: arg, error };
        });
      }
      return [];
    }
  );
};

interface BooleanCombinatorArgProps extends ExprProps<Expr, TypedExpr> {
  defaultOperator: Operator;
}
interface BooleanCombinatorArgState {
  addWithOperator: Operator;
}

class BooleanCombinatorArg extends React.Component<BooleanCombinatorArgProps, BooleanCombinatorArgState> {
  constructor(props: BooleanCombinatorArgProps) {
    super(props);
    this.state = {
      addWithOperator: props.defaultOperator
    };
  }

  addExpr = (newExpr: Either<Expr, TypedExpr>, soqlType: SoQLType | null) => {
    this.props.update(this.props.eexpr.mapBoth(
      eexpr => additionalFilter(eexpr.untyped, newExpr.left, soqlType, this.state.addWithOperator),
      eexpr => additionalFilterNA(eexpr.expr, newExpr.right, soqlType, this.state.addWithOperator)
    ));
  };

  render() {
    const {
      addFilterType,
      columns,
      eexpr,
      layer,
      layerCount,
      parameters,
      projectionInfo,
      querySucceeded,
      remove,
      scope,
      showAddExpr,
      showKebab,
      showRemove,
      update
    } = this.props;
    const functionName = _.get(eexpr, 'untyped.function_name', '');
    const isBooleanCombinator = (functionName === Operator.AND || functionName === Operator.OR);
    const showAddExprAfter = layerCount === 2 && layer === layerCount && !isBooleanCombinator;

    let layerClass = 'vee-no-bg';
    const hasLayerAndCount = layer !== undefined && layerCount !== undefined;
    if (isBooleanCombinator || (hasLayerAndCount && layer! <= layerCount!)) {
      layerClass = hasLayerAndCount && layer! % 2 === layerCount! % 2 ?
        'vee-gray-bg' : 'vee-white-bg';
    }

    return (<div className={`vee-expr-container vee-boolean-arg ${layerClass}`}>
      <ExpressionEditor
        eexpr={eexpr}
        update={update}
        remove={remove}
        columns={columns}
        parameters={parameters}
        scope={scope}
        isTypeAllowed={(st: SoQLType) => st === SoQLType.SoQLBooleanT}
        showAddExpr={showAddExpr}
        addFilterType={addFilterType}
        layer={layer}
        layerCount={layerCount}
        projectionInfo={projectionInfo}
        querySucceeded={querySucceeded}
        showKebab={showKebab}
        showRemove={showRemove}
      />
      {showAddExprAfter && <AddFilter
        addExpr={this.addExpr}
        className={layerClass.replace('vee', 'add-expr')}
        columns={columns}
        addFilterType={addFilterType}
        addWithOperator={this.state.addWithOperator}
        showOperatorSelector={true}
        updateOperator={(addWithOperator: Operator) => this.setState({ addWithOperator })}
        removable={true}
      />}
    </div>);
  }
}

// The goal here is to flatten out big nested conditions into a list of expressions
// that is easier for the user to read and understand.
//
// When you have something like (a = 1 AND (b = 2 AND (c = 3)))
// we want it to render as
//
// AND
//   a = 1
//   b = 2
//   c = 3
//
// rather than naively rendering it as
// AND
//   a = 1
//   AND
//     b = 2
//     c = 3
//
//
// (a = 1 AND (b = 2 AND (c = 3))) turns into
// [a = 1, b = 2, c = 3]
export const flattenArgs = (subExprs: Eexpr<Expr, TypedExpr>[], expr: Expr): Eexpr<Expr, TypedExpr>[] => {
  return _.flatMap(subExprs, subExpr => {
    if (isFunCall(subExpr.untyped) && subExpr.untyped.function_name === _.get(expr, 'function_name', '')) {
      return flattenArgs(zipArgs(subExpr), expr);
    } else {
      return [subExpr];
    }
  });
};
export const flattenArgsNA = (subExprs: EexprNA<TypedExpr>[], expr: Expr): EexprNA<TypedExpr>[] => {
  return _.flatMap(subExprs, subExpr => {
    if (isTypedFunCall(subExpr.expr) && subExpr.expr.function_name === _.get(expr, 'function_name', '')) {
      return flattenArgsNA(zipArgsNA(subExpr), expr);
    } else {
      return [subExpr];
    }
  });
};

// [a = 1, b = 2, c = 3] turns into
// (a = 1 AND (b = 2 AND (c = 3)))
export const unflatten = (exprs: Expr[], expr: Expr): Expr => {
  if (exprs.length === 1) return exprs[0];

  const [head, ...rest] = exprs;
  const subExpr: FunCall = {
    type: 'funcall',
    function_name: _.get(expr, 'function_name', ''),
    args: [
      head,
      unflatten(rest, expr)
    ],
    window: null
  };
  return subExpr;
};
// [a = 1, b = 2, c = 3] turns into
// (a = 1 AND (b = 2 AND (c = 3)))
export const unflattenNA = (exprs: TypedExpr[], expr: TypedSoQLFunCall): TypedExpr => {
  if (exprs.length === 1) return exprs[0];

  const [head, ...rest] = exprs;
  const subExpr: TypedSoQLFunCall = {
    type: 'funcall',
    function_name: _.get(expr, 'function_name', ''),
    args: [
      head,
      unflattenNA(rest, expr)
    ],
    window: null,
    soql_type: expr.soql_type
  };
  return subExpr;
};

function EditBooleanCombinator(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  const { update, layer, layerCount } = props;
  const expr = props.eexpr.fold(eexpr => eexpr.untyped, eexpr => eexpr.expr);

  const flatEExprs = props.eexpr.mapBoth(
    eexpr => flattenArgs(zipArgs(eexpr), expr),
    eexpr => flattenArgsNA(zipArgsNA(eexpr), expr)
  );

  const exprs = flatEExprs.mapBoth(
    eexprs => eexprs.map(eexpr => eexpr.untyped),
    eexprs => eexprs.map(eexpr => eexpr.expr)
  );

  let operatorClassName;
  if (expr.function_name === Operator.AND) {
    operatorClassName = 'operator-and';
  } else if (expr.function_name === Operator.OR) {
    operatorClassName = 'operator-or';
  }

  const addExpr = (newExprEither: Either<Expr, TypedExpr>, soqlType: SoQLType | null) => {
    const forUpdate = newExprEither.mapBoth(
      (newExpr) =>
        unflatten(splice(exprs.left, initialFilter(newExpr, soqlType), exprs.left.length), props.eexpr.left.untyped),
      (newExpr) =>
        unflattenNA(splice(exprs.right, initialFilterNA(newExpr, soqlType), exprs.right.length), props.eexpr.right.expr)
    );
    update(forUpdate);
  };

  const updateOperator = (operator: Operator) => {
    update(whichAnalyzer(unflatten, unflattenNA)(exprs, { ...expr, function_name: operator }));
  };
  const renderedExprs: JSX.Element[] = [];
  // Couldn't figure out how to explain go the compiler that it's a FlaggedExpr[], so, I'm just "coercing" it.
  type FlaggedEexpr = Either<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>;
  (factorArray(flatEExprs) as FlaggedEexpr[]).forEach((subExpr, i) => {
    const subExprUpdate = (newSubExpr: Either<Expr, TypedExpr>) => {
      // "it's not great, but it's there" -- mchui 09/12/2024
      // this message endorsed by catstavi
      update(flatEExprs.mapBoth(
        (eexprs) => unflatten(replaceAt(eexprs.map(eexpr => eexpr.untyped), newSubExpr.left, i), props.eexpr.left.untyped),
        (eexprs) => unflattenNA(replaceAt(eexprs.map(eexpr => eexpr.expr), newSubExpr.right, i), props.eexpr.right.expr)
      ));
    };

    const subExprRemove = () => {
      const forUpdate = exprs.mapBoth(
        (e) =>
          unflatten(e.filter((unused, offset) => offset !== i), props.eexpr.left.untyped),
        (e) =>
          unflattenNA(e.filter((unused, offset) => offset !== i), props.eexpr.right.expr)
      );
      update(forUpdate);
    };


    renderedExprs.push(<BooleanCombinatorArg
      key={i}
      {...props}
      layer={(props.layer || 0) + 1}
      eexpr={subExpr}
      update={subExprUpdate}
      remove={subExprRemove}
      defaultOperator={expr.function_name === Operator.AND ? Operator.OR : Operator.AND}/>);

    if (i < exprs.foldEither(e => e.length) - 1) {
      renderedExprs.push(
        <BooleanDivider
          key={i + 'divider'}
          selectedOperator={expr.function_name as Operator}
          onUpdate={updateOperator}
        />
      );
    }
  });

  const layerClass = layer !== undefined && layerCount !== undefined && layer % 2 === layerCount % 2 ?
    'add-expr-gray-bg' : 'add-expr-white-bg';
  const hideRemove = layer === 1 && (layerCount || 0) >= 2;

  return (
    <AstNode {...props} className={`funcall block-level-function-change-icon ${operatorClassName}`} removable={!hideRemove}>
      {renderedExprs}
      {props.showAddExpr && <AddFilter
        addExpr={addExpr}
        columns={props.columns}
        className={layerClass}
        addFilterType={props.addFilterType}
        addWithOperator={expr.function_name as Operator}
        showOperatorSelector={true}
        updateOperator={updateOperator}
        removable={true}
      />}
    </AstNode>
  );
}


const BooleanCombinators = ['op$AND', 'op$OR'];
const shouldRenderAsBooleanCombinator = (f: FunCall) => _.includes(BooleanCombinators, f.function_name);

export { EditBooleanCombinator, shouldRenderAsBooleanCombinator };
