import _ from 'lodash';

import assert from 'common/assertions/assert';


// General Table helpers; used in /visualizations/Table.js
export const MAX_COLUMN_COUNT = 64;
export const ERROR_COLUMN_MISMATCH = 'Column mismatch detected';

/**
 * Trims the amount of rows to fit the page size.
 * @param {Object[]} rows - Rows in a dataset
 * @param {Number} pageSize - Number of rows to be visible on a page
 * @return A new array of rows
 */
export const padOrTrimRowsToFitPageSize = (rows, pageSize) => {
  // Adds clonedRows to make this into a pure function.
  const clonedRows = _.cloneDeep(rows);

  if (clonedRows.length >= pageSize) {
    clonedRows.length = pageSize;
  } else {

    let numberOfRowsToPad = (
      pageSize - clonedRows.length
    );

    for (let i = 0; i < numberOfRowsToPad; i++) {
      clonedRows.push(null);
    }
  }

  return clonedRows;
};

export const assertCellsCountInRows = (rows, columns) => {
  assert(// Rows can either be undefined OR of the exact length of the
  // displayableColumns OR MAX_COLUMN_COUNT, if the
  // displayableColumns has more than MAX_COLUMN_COUNT items.
  _.every(
    rows,
    (row) =>
      !row ||
      row.length === columns.length ||
      row.length === MAX_COLUMN_COUNT
  ), 'Encountered row with length other than view column count or ' +
  `MAX_COLUMN_COUNT (${MAX_COLUMN_COUNT}).`);
};

/**
 * @param {Object} renderState The current rendering state of the table viz in question.
 * @param {Object} renderState.vif The vif for the current table.
 * @param {boolean} renderState.error Whether there are errors rendering the table or not.
 * @param {Object} renderState.fetchedData Holds result of last successful data fetch, plus the metadata regarding that request (start index, order, etc).
 * @param {number} renderState.datasetRowCount Count of rows.
 * @param {boolean} renderState.fetchingRowCount Whether it is still fetching rows or complete.
 * @param {boolean} renderState.collocating Whether collcation is processing.
 * @return options for displaying the pager
 */
export function computePagerOptions(renderState) {
  // The default state is no fetched data. Render placeholders so that we can
  // determine pager heights.
  const pagerOptions = {
    unit: _.get(renderState.vif, 'series[0].unit'),
    startIndex: 0,
    endIndex: 0,
    datasetRowCount: 0,
    disabled: true,
    fetchingRowCount: renderState.fetchingRowCount
  };

  // If we have fetched data, augment the default state with the appropriate
  // details.
  //
  // Note that we can't just check for the truthiness of renderState.datasetRowCount
  // because it is occasionally NaN, which is a state we can actually handle. In this
  // case we still want to re-render the pager even though we will say 'Row count
  // unavailable', because otherwise the lack of a pager re-render causes the pager
  // to be rendered above, not below, the table rows.
  if (renderState.fetchedData && !_.isNull(renderState.datasetRowCount)) {

    pagerOptions.startIndex = renderState.fetchedData.startIndex;
    pagerOptions.endIndex = Math.min(
      (
        renderState.fetchedData.startIndex +
        renderState.fetchedData.rows.length
      ),
      renderState.datasetRowCount
    );
    pagerOptions.datasetRowCount = renderState.datasetRowCount;
    pagerOptions.disabled = (
      renderState.busy ||
      !_.isFinite(renderState.datasetRowCount)
    );
  }

  if (_.isNull(renderState.datasetRowCount)) {
    pagerOptions.datasetRowCount = null;
  }

  return pagerOptions;
}

/**
 * @param {Element} $element JQuery object of the current table element.
 * @param {Object} renderState The current rendering state of the table viz in question. (See computePagerOptions() for details).
 * @return options for displaying the pager
 */
export function computePageSize($element, renderState) {

  if (renderState.error) {
    return 0;
  }

  const overallHeight = $element.find('.socrata-visualization-container').height();

  const pagerHeight = $element.find('.socrata-pager').outerHeight();
  const heightRemaining = overallHeight - pagerHeight;

  return $element.data('visualization').howManyRowsCanFitInHeight(heightRemaining);
}

// Table layout helpers; used in /visualizations/views/Table.js

/**
 * NOTE: [EN-17490] Geometry, document, and blob columns are not sortable in the API.
 *
 * @param {ViewColumn} column The column that would be sorted.
 * @param {boolean} isSummaryTable Whether this column is part of a summary table.
 * @return whether a column can be sorted
 */
export function isUnsortableColumnType(column, isSummaryTable) {
  // Summary tables are never sortable
  if (isSummaryTable) {
    return true;
  }

  return _.includes(
    [
      'point',
      'multipoint',
      'line',
      'multiline',
      'polygon',
      'multipolygon',
      'location',
      'phone',
      'document',
      'blob',
      'photo'
    ],
    column.renderTypeName
  );
}
