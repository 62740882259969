import BigNumber from 'bignumber.js';

import { setupSoqlDataProvider } from './helpers';

import { calculateCountMeasure } from './count';
import { calculateSumMeasure } from './sum';


export const calculateAverageMeasure = async (
  errors,
  measure,
  dateRangeWhereClause,
  dataProvider = setupSoqlDataProvider(measure) // For test injection
) => {
  const sumResponse = await calculateSumMeasure(errors, measure, dateRangeWhereClause, dataProvider);
  const countResponse = await calculateCountMeasure(errors, measure, dateRangeWhereClause, dataProvider);

  const sumValue = new BigNumber(sumResponse.result.value);
  const countValue = new BigNumber(countResponse.result.value);

  let value = null;

  if (countValue.isZero() || countValue.isNaN() || sumValue.isNaN()) {
    errors.notEnoughData = true; // eslint-disable-line require-atomic-updates
  } else {
    value = sumValue.dividedBy(countValue);
  }

  return { errors, result: { value } };
};
