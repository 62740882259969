import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import ChooserRadioButton from 'common/components/ChooserRadioButton';
import * as permissionsActions from 'common/components/AccessManager/actions/PermissionsActions';
import type { FederatedSite } from 'common/types/approvals';

import SitesFederatedToList from 'common/components/Federation/SitesFederatedToList';
import type { View } from 'common/types/view';
import { AudienceScope } from 'common/types/view';

import AudienceScopeLabel from '../AudienceScopeLabel';
import type { AccessManagerState } from 'common/components/AccessManager/types';

/** Props from redux state */
interface StateProps {
  /** The currently selected scope; used to determine if this button is active */
  currentScope: AudienceScope | null;

  /** List of sites that will be federated to if this scope is chosen */
  sitesFederatedTo: FederatedSite[];
}

/** Props from redux dispatch */
interface DispatchProps {
  /** Function to change the currently selected scope in the state */
  changeScope: (scope: AudienceScope) => void;
}

/** Props passed in to the component */
interface OwnProps {
  /** The scope that will be chosen if this button is clicked */
  scope: AudienceScope;

  /** Whether or not this button is disabled  */
  disabled: boolean;

  /** Title to put on <button /> for when you hover over it */
  hoverMessage: string;

  view: Partial<View>;
}

type AudienceScopeChooserRadioButtonProps = StateProps & DispatchProps & OwnProps;

/**
 * Radio button with an audience label that will update the state with
 * its selected option.
 *
 * The "scope" passed in as props is used to determine the label to show,
 * and is used when updating the state onChange.
 */
class AudienceScopeChooserRadioButton extends Component<AudienceScopeChooserRadioButtonProps> {
  static defaultProps = {
    currentScope: null
  };

  render() {
    const { changeScope, currentScope, disabled, hoverMessage, scope, sitesFederatedTo, view } = this.props;

    // whether or not this button is for the current scope
    const checked = currentScope === scope;

    const label = <SitesFederatedToList siteList={sitesFederatedTo} view={view} />;
    return (
      <div>
        <ChooserRadioButton
          disabled={disabled}
          label={label}
          hoverMessage={hoverMessage}
          onSelection={() => changeScope(scope)}
          selected={checked}
        >
          <AudienceScopeLabel scope={scope} />
        </ChooserRadioButton>
      </div>
    );
  }
}

const mapStateToProps = (state: AccessManagerState, ownProps: OwnProps): StateProps => ({
  currentScope: state.permissions.permissions?.scope || null,
  sitesFederatedTo: (ownProps.scope === AudienceScope.Public && state.ui?.sitesFederatedToIfMadePublic) || []
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  changeScope: (scope: AudienceScope) => dispatch(permissionsActions.changeAudienceScope(scope))
});

export default connect(mapStateToProps, mapDispatchToProps)(AudienceScopeChooserRadioButton);
