import React, { ChangeEvent, FunctionComponent } from 'react';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import UserSearch from 'common/components/UserSearch';

import type { CatalogUserOrTeam, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import { AccessLevel } from 'common/types/view';

import AccessLevelDropdown, {
  DropdownOption
} from 'common/components/AccessManager/components/AccessLevelDropdown';

import { MODES } from 'common/components/AccessManager/Constants';

import './add-user.scss';
const classNameScope = 'common-components-AccessManager--components-AddUser';

export interface AddUserProps {
  /** Called when a user is selected in the multiselect */
  addToSelectedUsers: (user?: UsersCatalogSearchResults) => void;

  /**
   * Called when removing a selected user from the multiselect
   * (either clicking the remove button or hitting backspace)
   */
  removeFromSelectedUsers: (user?: CatalogUserOrTeam, index?: number) => void;

  /** The current search query entered into the mutliselect */
  currentSearchQuery: string;

  /** Called when the access level dropdown changes. */
  onAccessLevelDropdownChanged?: (value: DropdownOption) => boolean | undefined | void;

  /**
   * Whether or not to hide the access level dropdown.
   * The dropdown will only really show if `strict_permissions` is OFF and mode is `manage_collaborators`.
   */
  hideAccessLevelDropdown?: boolean;

  /** The currently selected access level in the dropdown */
  selectedAccessLevel: AccessLevel;

  /** Current list of results from users search  */
  searchResults?: UsersCatalogSearchResults[];

  /**
   * Current list of users who have been selected.
   * This list will be passed to "addUsers" when the "Add" button is clicked.
   */
  selectedUsers: CatalogUserOrTeam[];

  /** Called whenever the search query changes */
  userSearchQueryChanged: (e: ChangeEvent<HTMLInputElement>) => void;

  /** Called to clear the search query */
  userSearchClearQuery?: () => void;

  /**
   * Called when the "Add" button is clicked.
   * Will be called with the list of selected users, as well as the selected access level and the current access manager mode.
   */
  addUsers: (selectedUsers: CatalogUserOrTeam[], selectedAccessLevel: AccessLevel, mode: MODES) => void;

  /** Optional function that is called before adding users. */
  validateForm?: (selectedUsers: CatalogUserOrTeam[], selectedAccessLevel: AccessLevel) => void;

  /** The current access manager mode */
  mode: MODES;
}

/**
 * Contains the search box to select users to add,
 * a dropdown to select their access level,
 * and a button to confirm them and add them to the list of users.
 */
const AddUser: FunctionComponent<AddUserProps> = ({
  addToSelectedUsers,
  addUsers,
  currentSearchQuery = '',
  onAccessLevelDropdownChanged = () => {},
  hideAccessLevelDropdown = false,
  removeFromSelectedUsers,
  searchResults,
  selectedAccessLevel,
  selectedUsers,
  userSearchQueryChanged,
  userSearchClearQuery,
  validateForm = () => {},
  mode
}) => {
  const confirmSelectedUsers = () => {
    validateForm(selectedUsers, selectedAccessLevel);

    if (selectedUsers && selectedUsers.length > 0 && selectedAccessLevel) {
      addUsers(selectedUsers, selectedAccessLevel, mode);
    }
  };

  return (
    <div
      className={
        hideAccessLevelDropdown ? `${classNameScope}--container-no-dropdown` : `${classNameScope}--container`
      }
    >
      <UserSearch
        addToSelectedUsers={addToSelectedUsers}
        currentQuery={currentSearchQuery}
        results={searchResults}
        removeFromSelectedUsers={removeFromSelectedUsers}
        selectedUsers={selectedUsers}
        userSearchQueryChanged={userSearchQueryChanged}
        userSearchClearQuery={userSearchClearQuery}
      />
      {hideAccessLevelDropdown === false && (
        <AccessLevelDropdown onSelection={onAccessLevelDropdownChanged} value={selectedAccessLevel} />
      )}
      <Button onClick={confirmSelectedUsers} className={`${classNameScope}--add-user-button`}>
        {I18n.t('shared.site_chrome.access_manager.add')}
      </Button>
    </div>
  );
};

export default AddUser;
