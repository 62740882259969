import _ from 'lodash';

import I18n from 'common/i18n';
import MapHelper from 'common/visualizations/helpers/MapHelper';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import DataTypeFormatter from 'common/DataTypeFormatter';
import {
  QUANTIFICATION_METHODS,
  EMPTY_TRANSPARENT_COLOR,
  EMPTY_BUCKET_VALUE,
  POINT_AGGREGATIONS
} from 'common/authoring_workflow/constants';
import { MAP_TYPES } from 'common/visualizations/views/mapConstants';
import { OTHER_COLOR_BY_CATEGORY } from 'common/visualizations/views/map/vifOverlays/VifPointOverlay';

// Used for wrapping Vif___Overlays. It calls the prepare in the overlays,
// get the render options, makes sure the map is ready and calls the render
// method in the underlying overlay.
//  - While the underlying overlay is preparing with soql calls,
//    if the overlay is destroyed. On completion of the preparation,
//    the rendering should be skipped.
//  - If loadVif is called while the previous loadVif call's prepare is
//    in progress, rendering for the completion of the first prepare should
//    be skipped in favor of the second/most recent vif.
export default class VifOverlayWrapper {
  constructor(map, overlay) {
    this._map = map;
    this._overlay = overlay;

    this._destroyed = false;
    this._renderOptions = null;
    this._vif = null;
    this.metadataResponse = {};
  }

  async loadVif(vif, overlayOptions) {
    if (this._destroyed) {
      return;
    }

    this._vif = vif;

    const metaDataPromise = vif.getDatasetMetadata().
      then((metadata) => {
        this.metadataResponse = { success: true };
        return metadata;
      }).
      catch((err) => {
        this.metadataResponse = { success: err.response.ok, status: err.response.status };
      });

    const [renderOptions, mapLoaded] = await Promise.all([
      this._overlay.prepare(vif, metaDataPromise),
      MapHelper.afterMapLoad(this._map)
    ]);

    if (this._vif !== vif) {
      console.info('Vif updated, cancelling previous setup/update');
      return;
    }

    if (this._destroyed) {
      console.info('Overlay destroyed, cancelling previous setup/updated');
      return;
    }

    this._overlay.render(vif, renderOptions, overlayOptions);
    this._renderOptions = renderOptions;
  }

  getLegendItems() {
    let legendItems = _.chain(this._renderOptions).
      get('legendItems').
      filter((legendItem) => legendItem.id !== EMPTY_BUCKET_VALUE || legendItem.color !== EMPTY_TRANSPARENT_COLOR).
      value();

    if (this._destroyed || !this._vif.isLayerVisible()) {
      return;
    }
    const isPointMap = this._vif.getMapType() === MAP_TYPES.POINT_MAP;
    const pointAggregation = this._vif.getPointAggregation();
    const isPointMapAggregation = _.isNil(pointAggregation ) || pointAggregation === POINT_AGGREGATIONS.NONE;
    let newLegendItems = {
      items: [],
      showCharm: this._vif.isCharmsLayerVisible(),
      title: this._vif.getSeriesName()
    };

    if (this._vif.shouldShowLegendItems()) {
      if (!_.isEmpty(legendItems)) {
        newLegendItems.items = _.sortBy(legendItems, 'index');
      }

      if (this._vif.getShowMultiplePointsSymbolInLegend() && isPointMap && isPointMapAggregation) {
        const stackOutlineColor = this._vif.getStackOutlineColor(
          this._renderOptions.layerStyles,
          this._renderOptions.colorByBuckets,
        );
        const stackCircleLegendItem = {
          color: this._renderOptions.layerStyles.STACK_COLOR,
          dashed: false,
          showStackInLegend: true,
          outlineColor: stackOutlineColor,
          label: I18n.t('shared.visualizations.charts.map.multiple_points_in_single_location')
        };

        newLegendItems.items.push(stackCircleLegendItem);
      }
    }
    return _.isEmpty(newLegendItems.items) ? null : newLegendItems;
  }

  destroy() {
    this._overlay.destroy();
    this._overlay = null;
    this._destroyed = true;
  }
}
