import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import Spinner from 'common/components/Spinner';

import './index.scss';
import SoqlBuilder from '../components/SoqlBuilder';

/**
  CustomAlert => Alert created by selecting conditions/groupbys/aggregations using form
  fields (opposite to AdvancedAlert where you give the entire soql query as input).

  CreateCustomAlert has three setps
  1.) AlertType:
      a.) entire-data
      b.) new-rows-only
  2.) Parameters:
      Construct the soql query for triggering the alert using form fields(SoqlBuilder)
  3.) Trigger: (select the trigger type for the alert.)
     PUSH: trigger the alert(send notification/email), as soon as the alert threshold crosses.
       a.) rolling: send alert on every update if threshold exceeds
       b.) one-and-done: send alert once on update and mute
     PERIODICAL:
       a.) periodic: check and trigger the alert on a duration basis (daily|weekly|monthly)
*/
class CreateCustomAlert extends Component {
  state = {
    customAlert: [{}],
    isDataLoading: false
  };

  // updating custom alert values
  onCustomAlertChange = (soqlSlices) => {
    const { onCustomAlertChange } = this.props;

    this.setState({ customAlert: soqlSlices });
    onCustomAlertChange(soqlSlices);
  };

  translationScope = 'shared.components.create_alert_modal';

  renderAlertParametersPage() {
    const { customAlert, onValidateClick, viewId } = this.props;

    return (
      <SoqlBuilder
        viewId={viewId}
        onValidateClick={onValidateClick}
        onSoqlChange={this.onCustomAlertChange}
        soqlSlices={customAlert}
      />
    );
  }

  render() {
    const { isDataLoading } = this.state;

    return (
      <div className="custom-alert">
        <div className="custom-alert-head">
          {I18n.t('custom_alert.parameter_page_title', { scope: this.translationScope })}
        </div>
        <div className="custom-alert-data">
          {isDataLoading ? <Spinner /> : this.renderAlertParametersPage()}{' '}
        </div>
      </div>
    );
  }
}

CreateCustomAlert.propTypes = {
  customAlert: PropTypes.array,
  viewId: PropTypes.string.isRequired,
  onCustomAlertChange: PropTypes.func
};

export default CreateCustomAlert;
