import _ from 'lodash';
import {
  StatusFunctions,
  StatusValues
} from '../constants';

// Converts Existing In Progress Status to Near Target while retaining
// the existing label for previous measures. If an user has a measure
// that had the In Progres label, the measure will not use the Near
// Target label with an In Progress override label. If you were to
// edit an existing measure, the conversion would not be required.
export function inProgressToNearTarget(status) {
  // if status doesn't exist, do not convert to newer version
  if (_.isEmpty(status)) { return {}; }

  const updatedStatus = _.cloneDeep(status);
  const { labels, value } = updatedStatus;
  const inProgressLabel = _.get(labels, 'in_progess', '');

  if (value === 'in_progress') {
    if (inProgressLabel) {
      _.set(updatedStatus, `labels.${StatusValues.NEAR_TARGET}`, inProgressLabel);
    } else {
      _.set(updatedStatus, `labels.${StatusValues.NEAR_TARGET}`, 'In Progress');
    }
    _.set(updatedStatus, 'value', StatusValues.NEAR_TARGET);
  }
  return updatedStatus;
}

// Converts old manual status in new smart status manual status format
// old format:
// {
//   enabled: true,
//   value: 'on_going',
//   labels: ...
// }
//
// new format:
// {
//   manual: 'on_going',
//   labels: ...
// }
export function manualStatusToNewManual(status) {
  // if status doesn't exist, do not convert to newer version
  if (_.isEmpty(status)) { return {}; }

  const updatedStatus = _.cloneDeep(status);
  const { enabled, value, type } = updatedStatus;
  const manualStatus = _.get(updatedStatus, 'manual');

  if (!manualStatus && type === StatusFunctions.MANUAL || enabled) {
    if (!type) {
      _.set(updatedStatus, 'type', StatusFunctions.MANUAL);
    }
    _.set(updatedStatus, 'manual', value);
    _.unset(updatedStatus, 'enabled');
    _.unset(updatedStatus, 'value');
  }

  return updatedStatus;
}

export default function convertToVersion1(measure) {
  let updatedStatus;
  const measureV1 = _.cloneDeep(measure);
  const status = _.get(measureV1, 'metricConfig.status', {});
  updatedStatus = inProgressToNearTarget(status);
  updatedStatus = manualStatusToNewManual(updatedStatus);
  _.set(measureV1, 'metricConfig.status', updatedStatus);

  _.set(measureV1, 'version', 1);
  return measureV1;
}
