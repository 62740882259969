/* Imports */
/* ============================================================================= */
import { pick } from 'lodash';

import { GuidanceCheckSummary } from 'common/core/approvals/Types';
import { WORKFLOWS } from '.';





/* Method */
/* ============================================================================= */

/**
 * Given a GuidanceCheckSummary object, extract only the workflow specific attributes and values
 * @param workflows GuidanceCheckSummary
 * @returns Array of the values for all WorkflowTargetAudience keyed attributes within workflows
 */
export const getWorkflowValues = (workflows: GuidanceCheckSummary<any>): any[] => {
  return Object.values(pick(workflows, WORKFLOWS));
};

export default getWorkflowValues;
