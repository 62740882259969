import $ from 'jquery';
import _ from 'lodash';
import { Dispatcher } from 'flux';
import Store from 'Store';
import Actions from 'Actions';

import {
  _onAjaxError,
  _loginButtonClick,
  _onCookieChange,
  debounceCheckUserSession
} from './UserSessionStoreHelper';
import type { UserSessionState } from './UserSessionStoreHelper';
import { FluxPayload } from 'types';
/**
 * Options (optional):
 *   - sessionCheckDebounceMilliseconds: Minimum amount of time between user session checks.
 *      Note that user session checks are not on a timer - they are triggered by AJAX failures,
 *      cookie changes, etc.
 *   - cookieCheckIntervalMilliseconds: Polling interval for cookie changes.
 *      NOTE! Core will often set new cookies for /api/users/current.json, causing
 *      an immediate cookie change. Having a large value here prevents rapid-fire
 *      requests, as changing cookies triggers a GET to /api/users/current.json.
 */
export const userSessionStore = new (UserSessionStore as any)();
export default function UserSessionStore(
  options?: UserSessionState['options'],
  dispatcher?: Dispatcher<any>
) {
  options = _.defaults(options || {}, {
    sessionCheckDebounceMilliseconds: 250,
    cookieCheckIntervalMilliseconds: 5000
  });

  _.extend(this, new Store(dispatcher));

  this.state = <UserSessionState>{
    isSessionValid: true,
    loginInProgress: false,
    cookieCheckInterval: null,
    checkUserSession: () => {},
    options
  };

  this.register((payload: FluxPayload) => {
    switch (payload.action) {
      case Actions.LOGIN_BUTTON_CLICK:
        _loginButtonClick(this.state);
        this._emitChange();
        break;
      default:
        break;
    }
  });

  this.isSessionValid = () => {
    return this.state.isSessionValid;
  };

  this.loginInProgress = () => {
    return this.state.loginInProgress;
  };

  const ajaxError = (event: any, jqXhr: any) => {
    _onAjaxError(this.state, event, jqXhr);
  };

  $(document).ajaxError(ajaxError);

  // Release all resources held by this store. Intended for testing.
  this._destroy = () => {
    $(document).off('ajaxError', ajaxError);
    if (this.state.cookieCheckInterval) {
      clearInterval(this.state.cookieCheckInterval);
    }
    this.state = <UserSessionState>{
      isSessionValid: true,
      loginInProgress: false,
      cookieCheckInterval: null,
      checkUserSession: () => {},
      options
    };
  };
  const emitChange = () => {
    this._emitChange();
  };

  this.state.checkUserSession = debounceCheckUserSession(this.state, emitChange);
  _onCookieChange(this.state);
}
