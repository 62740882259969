import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'common/components/Modal';

import { MODES } from 'common/components/AccessManager/Constants';
import {
  getErrors,
  getErrorIntro,
  getUiMode,
  getModalTransition,
  getPermissions,
  getApprovalsGuidance,
  getOnDismiss
} from 'common/components/AccessManager/sagas/Selectors';

import Header from './Header';
import Footer from './Footer';
import Errors from './Errors';
import ManageCollaborators from './ManageCollaborators';
import AudienceScopeChooser from './AudienceScopeChooser';
import ChangeOwner from './ChangeOwner';
import ConfirmDestructiveAudienceChange from './ConfirmDestructiveAudienceChange';

import './access-manager.scss';

const classNameScope = 'common--components--AccessManager--access-manager';

const renderCurrentModeOrSpinner = (mode?: MODES, errors?: any[], arePrerequisitesAvailable?: boolean) => {
  const noErrors = !errors || errors.length === 0;
  if (!arePrerequisitesAvailable && noErrors) {
    // no errors and prerequisites (permissions, approvals guidance) are missing; waiting for api call to finish
    return (
      <div className={`${classNameScope}--spinner-container`}>
        <div className="spinner-default spinner-large" />
      </div>
    );
  } else if (arePrerequisitesAvailable) {
    switch (mode) {
      case MODES.CHANGE_OWNER:
        return <ChangeOwner />;
      case MODES.PUBLISH:
      case MODES.CHANGE_AUDIENCE:
        return <AudienceScopeChooser />;
      case MODES.MANAGE_COLLABORATORS:
      case MODES.MANAGE_PLUGIN:
        return <ManageCollaborators />;
      case MODES.CONFIRM_DESTRUCTIVE_AUDIENCE_CHANGE:
        return <ConfirmDestructiveAudienceChange />;
      default:
        return null;
    }
  } else {
    // Failed to fetch prerequisites. Error will be rendered elsewhere in this component, but we
    // shouldn't try to render any additional content.
    return null;
  }
};

/**
 * This will return the className to use for the overall modal based on its current transition state.
 * @param modalTransition The modal's current transition state
 */
const getModalClassName = (modalTransition?: 'in' | 'out') => {
  if (modalTransition === 'in') {
    return `${classNameScope}--modal-in`;
  } else if (modalTransition === 'out') {
    return `${classNameScope}--modal-out`;
  } else {
    return `${classNameScope}--modal`;
  }
};

/**
 * This renders the header, any existing errors, and a different component depending on the "mode"
 */
const AccessManagerModal: FunctionComponent = () => {
  const errors = useSelector(getErrors);
  const errorIntro = useSelector(getErrorIntro);
  const mode = useSelector(getUiMode);
  const modalTransition = useSelector(getModalTransition);
  const permissions = useSelector(getPermissions);
  const approvalsGuidance = useSelector(getApprovalsGuidance);
  const onDismiss = useSelector(getOnDismiss);

  // Checks if approvals guidance and permissions are available. If not,
  // we should render a spinner only.
  const arePrerequisitesAvailable = !!(permissions && approvalsGuidance);

  return (
    <Modal
      className={getModalClassName(modalTransition)}
      onDismiss={onDismiss}
      dismissOnOutsideClick={false}
      data-testid="access-manager-modal"
    >
      <div className={`${classNameScope}--modal-content`}>
        <Header />
        <section>
          <Errors errors={errors} errorIntro={errorIntro} />
          {renderCurrentModeOrSpinner(mode, errors, arePrerequisitesAvailable)}
        </section>
      </div>
      <Footer prerequisitesAvailable={arePrerequisitesAvailable} />
    </Modal>
  );
};

export default AccessManagerModal;
