import momentTimezone from 'moment-timezone';

export const AGGREGATION_TYPES = ['SUM', 'AVG', 'MIN', 'MAX'];
export const BOOLEAN_COLUMN_TYPES = ['checkbox'];
export const DATE_COLUMN_TYPES = ['floating_timestamp', 'calendar_date', 'date'];
export const LOCATION_COLUMN_TYPES = ['location', 'point', 'line', 'multipolygon', 'polygon', 'multipoint'];
export const NUMBER_COLUMN_TYPES = ['amount', 'number', 'money', 'double'];
export const STRING_COLUMN_TYPES = ['string', 'text'];

export const DEFAULT_TIME_BASED_ALERT_SENT_TIME = '8:00';
export const DEFAULT_ALERT_TIME_ZONE = momentTimezone.tz.guess();
export const DEFAULT_TIME_BASED_ALERT_SENT_DAYS = ['1'];

export const CUSTOM_ALERT_TRIGGER_TYPE = {
  ROLLING: 'infinite',
  ONE_AND_DONE: 'one_and_done'
};

// Threshold: Alert will check entire data in dataset for alert condition
// Incident: Alert will check newly added & updated data in dataset for alert condition
export const CUSTOM_ALERT_TYPES = {
  THRESHOLD: 'entire_data',
  INCIDENT: 'new_data',
  TIME_BASED: 'time_based'
};

export const ALERT_MODAL_PAGES = {
  ALERT_TYPE_PAGE: 'alertTypePage',
  PARAMETERS_PAGE: 'alertParameterPage',
  META_PAGE: 'alertMetaPage',
  TRIGGER_PAGE: 'alertTriggerPage'
};

export const ALERT_TYPES = {
  ADVANCED_ALERT: 'advancedAlert',
  CUSTOM_ALERT: 'customAlert'
};

export const ALERT_QUERY_TYPE = {
  ADVANCED_ALERT: 'raw',
  CUSTOM_ALERT: 'abstract'
};

export const FREQUENCY_OPTIONS = {
  DAILY: 'daily',
  MONTHLY: 'monthly'
};

export const PERIODIC_TYPE = {
  TIME_BASED: 'periodic',
  ALERT_BASED: 'real_time'
};

export const ALERT_SNOOZE_PERIODIC = {
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
};
