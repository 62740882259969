import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { defaultHeaders } from 'common/http/index';
import { fetchJson } from 'common/http';

/**
 * Helpers for working with Core's ViewsService API (/api/views)
 */

// NOTE: partial updates accepted, but updates are SHALLOW MERGED.
// i.e. if your updated value is an object, pass the whole thing.
export const update = (updatedView) => {
  if (isNil(updatedView) || isEmpty(updatedView)) {
    throw new Error('no view passed to views_service.update');
  }

  const apiPath = `/api/views/${updatedView.id}`;
  const options = {
    body: JSON.stringify(updatedView),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetchJson(apiPath, options);
};

export const setHideFromPublicCatalogs = (viewId, value) => {
  if (isNil(viewId) || isNil(value)) {
    throw new Error(`setHideFromCatalog requires viewId and value. Tried ${viewId}, ${value}`);
  }

  const apiPath = `/api/views/${viewId}?method=setHideFromPublicCatalogs&value=${value}`;
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetchJson(apiPath, options);
};

/**
 * Gets a view's metadata.
 */
export const getView = (uid) => {
  if (isEmpty(uid)) {
    throw new Error('no uid passed to views_service.getView');
  }

  if (uid.length !== 9) {
    throw new Error(`'views_service.getView passed something that does not look like a UID: ${uid}`);
  }

  const apiPath = `/api/views/${uid}`;
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'GET'
  };

  return fetchJson(apiPath, options);
};

