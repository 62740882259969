import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import Spinner from 'common/components/Spinner';

import datasetApi from 'common/components/CreateAlertModal/api/datasetApi';
import SoqlSliceBuilder from 'common/components/CreateAlertModal/components/SoqlBuilder/SoqlSliceBuilder';
import './index.scss';

/**
  SoqlBuilder renders form fields(using SoqlSliceBuilder) for soql query. On a change,
  it will propagate back the soql slices back to the parent. Also renders add/delete buttons,
  for adding/removing soql slices.

  SoqlSlice: A soql slice can be a
    - group by or a
    - a condition
*/
class SoqlBuilder extends Component {
  state = {
    datasetColumns: [],
    isDataLoading: false
  };

  UNSAFE_componentWillMount() {
    const { viewId } = this.props;
    const groupByText = I18n.t('column.group_by', { scope: this.translationScope });
    const rowCountText = I18n.t('column.row_count', { scope: this.translationScope });

    this.setState({ isDataLoading: true });
    datasetApi.getColumns({ viewId }).then((datasetColumns) => {
      // adding group_by, row count option in column list
      datasetColumns.push({ title: groupByText, value: 'group_by', column_type: 'groupBy' });
      datasetColumns.push({ title: rowCountText, value: 'COUNT(*)', column_type: 'row_identifier' });
      this.setState({
        datasetColumns,
        isDataLoading: false
      });
    }).catch(() => {
      this.setState({ datasetColumns: [], isDataLoading: false });
    });
  }

  onSoqlSliceChange = (alert, index) => {
    const { onSoqlChange, soqlSlices } = this.props;

    soqlSlices[index] = alert;
    onSoqlChange(soqlSlices);
  };

  addSoqlSlice = () => {
    const { onSoqlChange, soqlSlices } = this.props;

    if (!_.isEmpty(soqlSlices)) {
      soqlSlices.push({ logical_operator: 'and' });
    } else {
      soqlSlices.push({ column: '', operator: '=', value: '' });
    }
    onSoqlChange(soqlSlices);
  };

  translationScope = 'shared.components.create_alert_modal.custom_alert';

  removeSoqlSlice = (index) => {
    const { onSoqlChange, soqlSlices } = this.props;

    soqlSlices.splice(index, 1);
    onSoqlChange(soqlSlices);
  };

  renderValidateButton() {
    const { onValidateClick } = this.props;

    return (
      <button className="validate-button btn btn-primary" onClick={onValidateClick}>
        {I18n.t('button.validate', { scope: 'shared.components.create_alert_modal' })}
      </button>
    );
  }

  renderBuilder() {
    const { datasetColumns } = this.state;
    const { soqlSlices, viewId } = this.props;

    const slicesContent = soqlSlices.map((slice, index) => (
      <SoqlSliceBuilder
        datasetColumns={datasetColumns}
        key={index}
        slice={slice}
        sliceIndex={index}
        viewId={viewId}
        onSliceValueChange={this.onSoqlSliceChange}
        removeSliceEntry={this.removeSoqlSlice}
      />
    ));

    return (
      <div className="soql-slices-section">
        <div>{slicesContent}</div>
        <button
          className="m-r-10 btn btn-primary btn-inverse add-parameter-button"
          onClick={this.addSoqlSlice}
        >
          + {I18n.t('add_params', { scope: 'shared.components.create_alert_modal.button' })}
        </button>
        {this.renderValidateButton()}
      </div>
    );
  }

  render() {
    const { isDataLoading } = this.state;

    return <div className="soql-builder">{isDataLoading ? <Spinner /> : this.renderBuilder()}</div>;
  }
}

SoqlBuilder.defaultProps = {
  soqlSlices: []
};

SoqlBuilder.propTypes = {
  soqlSlices: PropTypes.array,
  viewId: PropTypes.string,
  onSoqlChange: PropTypes.func
};

export default SoqlBuilder;
