import FeatureFlags from 'common/feature_flags';
import {
  VIF_CONSTANTS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR
} from 'common/authoring_workflow/constants';

export default {
  format: {
    type: 'visualization_interchange_format',
    version: VIF_CONSTANTS.LATEST_VERSION
  },
  configuration: {
    viewSourceDataLink: true,
    showValueLabels: true,
    showValueLabelsAsPercent: false,
    showOtherCategory: true
  },
  description: '',
  series: [
    {
      color: {
        primary: DEFAULT_PRIMARY_COLOR,
        secondary: DEFAULT_SECONDARY_COLOR,
        highlight: '#cccccc'
      },
      dataSource: {
        datasetUid: null,
        dimension: {
          columnName: null,
          aggregationFunction: null
        },
        measure: {
          columnName: null,
          aggregationFunction: 'count'
        },
        orderBy: {
          parameter: 'measure',
          sort: 'desc'
        },
        type: 'socrata.soql',
        filters: [],
        limit: 12
      },
      label: null,
      type: 'pieChart'
    }
  ],
  title: ''
};
