/**
 * This function attempts to parse a JSON string. If it fails, it will return
 * an empty object. Note, this function doesn't do any parameter checking.
 */
export default function parseJsonOrEmpty(json) {
  try {
    return JSON.parse(json);
  } catch (error) {
    return {};
  }
}
