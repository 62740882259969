export interface Newtype<X, A> {
  _TAG: X;
  _VAL: A;
}

type CarrierOf<N extends Newtype<any, any>> = N['_VAL'];

function unsafeCoerce<S, T>(s: S): T {
  return s as any;
}

class Iso<S, A> {
  constructor(readonly unwrap: (s: S) => A, readonly wrap: (a: A) => S) {} // eslint-disable-line no-empty-function, no-useless-constructor
}

export function makeNewtype<T extends Newtype<any, any>>(): Iso<T, CarrierOf<T>> {
  return new Iso(unsafeCoerce, unsafeCoerce);
}

// how to use?
// 1. declare your types
//   interface EUR extends Newtype<{ readonly EUR: unique symbol }, number> {}
//   interface USD extends Newtype<{ readonly USD: unique symbol }, number> {}

// 2. put values in, take them out
//   const money = makeNewtype<EUR>();
//   const wrapped: EUR = money.wrap(44);
//   const unwrapped: number = money.unwrap(wrapped);

// 3. the point is that you can now write a function that distinguishes between
// EUR and USD even though they are both type aliases for number:
//   function withdraw(amt: USD) { ... }
//   withdraw(wrapped) doesn't compile
