import _ from 'lodash';

import { getCharmCode } from 'common/resources/charms';
import { CLUSTER_BUCKETS, RADIUS_TO_CHARM_FONT_SIZE } from 'common/visualizations/views/mapConstants';
import { VIF_CONSTANTS, QUANTIFICATION_METHODS, EMPTY_BUCKET_VALUE } from 'common/authoring_workflow/constants';
import { OTHER_COLOR_BY_CATEGORY } from 'common/visualizations/views/map/vifOverlays/VifPointOverlay';

// Returns the paint property (either literal value or data-driven stops/expression)
// that defines the size of each charm inside a circle.
// We calculate the charm size based on the existing circle-radius-paint-property function.
export function getCharmSizePaintProperty(resizeByRange, aggregateAndResizeBy) {
  return this.getPointAndStackCircleRadiusPaintProperty(
    resizeByRange,
    aggregateAndResizeBy,
    RADIUS_TO_CHARM_FONT_SIZE
  );
}
// Returns
//  number (or)
//  object that styles clusters based on the count of points in the cluster(data-driven styling).
export function getClusterCircleRadiusPaintProperty(resizeByRange, aggregateAndResizeBy) {
  if (_.isNull(resizeByRange)) {
    return _.get(this, 'configuration.basemapOptions.maxClusterSize', VIF_CONSTANTS.CLUSTER_SIZE.DEFAULT) / 2;
  }

  const minRadius = VIF_CONSTANTS.CLUSTER_SIZE.MIN / 2;
  const maxRadius = _.get(this, 'configuration.basemapOptions.maxClusterSize', VIF_CONSTANTS.CLUSTER_SIZE.DEFAULT) / 2;

  if (minRadius === maxRadius) {
    return minRadius;
  }

  const valueStops = _.sortBy([
    CLUSTER_BUCKETS.SMALL * resizeByRange.avg,
    CLUSTER_BUCKETS.MEDIUM * resizeByRange.avg,
    CLUSTER_BUCKETS.LARGE * resizeByRange.avg
  ]);
  const radiusValue = [minRadius, (minRadius + maxRadius) / 2, maxRadius];
  const stops = _.zip(valueStops, radiusValue);

  return {
    type: 'interval',
    property: aggregateAndResizeBy,
    stops: stops,
    'default': minRadius
  };
}

export function getClusterRadius() {
  return _.get(this, 'configuration.basemapOptions.clusterRadius', VIF_CONSTANTS.CLUSTER_RADIUS.DEFAULT);
}

export function getColorPointsByColumn() {
  return _.get(this, 'series[0].mapOptions.colorPointsBy');
}

export function isCharmsLayerVisible() {
  if (_.isNil(this.getColorPointsByColumn())) {
    return !_.isEmpty(this.getPointCharmName());
  }

  return _.isEqual(this.getColorPaletteId(), 'custom');
}

// Returns the paint property (either literal value or data-driven stops/expression)
// that defines the size of each point circle.
export function getPaintPropertyForCharm(renderOptions) {
  const { colorBy, colorByBuckets, datasetMetadata } = renderOptions;
  if (!_.isNil(colorByBuckets) && _.isEqual(this.getColorPaletteId(), 'custom')) {
    const isColorByBooleanColumn = this.isColorByBooleanColumn(datasetMetadata);
    const otherColorByBucket = this.getOtherColorByBucket(colorByBuckets, isColorByBooleanColumn);

    if (this.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value) {
      return getPaintPropertyForCharmByRange(colorBy, colorByBuckets, this.getColorByBucketsCount());
    }

    return getPaintPropertyForCharmByCategory(colorBy, colorByBuckets, otherColorByBucket);
  }

  return getCharmCode(this.getPointCharmName());
}

export function getPointCharmName() {
  return _.get(this, 'series[0].mapOptions.charmName', '');
}

export function getMaxClusteringZoomLevel() {
  return _.get(this, 'configuration.basemapOptions.maxClusteringZoomLevel', VIF_CONSTANTS.CLUSTERING_ZOOM.DEFAULT);
}

export function getNumberOfDataClasses() {
  return _.get(this, 'series[0].mapOptions.numberOfDataClasses', VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.DEFAULT);
}

export function getPointAndStackCircleRadiusPaintProperty(resizeByRange, aggregateAndResizeBy, multiplier = 1) {
  if (!_.isString(this.getResizePointsByColumn())) {
    let pointSize = _.get(this, 'series[0].mapOptions.pointSize', VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT);
    if (this.isCharmsLayerVisible()) {
      pointSize = pointSize < VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT ?
        VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT : pointSize;
    }
    return (pointSize / 2) * multiplier;
  }
  const minRadius = _.get(this, 'series[0].mapOptions.minimumPointSize', VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.DEFAULT) / 2;
  const maxRadius = _.get(this, 'series[0].mapOptions.maximumPointSize', VIF_CONSTANTS.POINT_MAP_MAX_POINT_SIZE.DEFAULT) / 2;
  const dataClasses = _.get(this, 'series[0].mapOptions.numberOfDataClasses', VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.DEFAULT);

  return this.getResizeByRangePaintProperty(
    aggregateAndResizeBy,
    resizeByRange,
    minRadius * multiplier,
    maxRadius * multiplier,
    dataClasses,
    'interval'
  );
}

export function getPointOpacity() {
// Point opacity in vif has a range of 0 to 100.
// Converting it to 0-1 for using in the paint property
  return _.get(this, 'series[0].mapOptions.pointOpacity', VIF_CONSTANTS.POINT_OPACITY.DEFAULT) / 100;
}

export function getResizePointsByColumn() {
  return _.get(this, 'series[0].mapOptions.resizePointsBy');
}

export function getStackOutlineColor(layerStyles, colorByBuckets) {
  if (_.isNull(colorByBuckets) || _.isEmpty(colorByBuckets)) {
    return _.get(this, 'series[0].color.primary', VIF_CONSTANTS.FEATURE_COLOR);
  }

  return layerStyles.STACK_BORDER_COLOR;
}

export function getStackRadius() {
  return _.get(this, 'configuration.basemapOptions.stackRadius', VIF_CONSTANTS.STACK_RADIUS.DEFAULT);
}

// If not resizing point by value, and point size is less than 14 do not show the count in stacks
export function shouldShowStackCount() {
  if (!_.isString(this.getResizePointsByColumn())) {
    const pointSize = _.get(this, 'series[0].mapOptions.pointSize', VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT) / 2;
    if (pointSize < VIF_CONSTANTS.MIN_STACK_SIZE_TO_SHOW_LABEL) {
      return false;
    }
  }
  return true;
}

export function getShowMultiplePointsSymbolInLegend() {
  return _.get(
    this,
    'configuration.showMultiplePointsSymbolInLegend',
    VIF_CONSTANTS.DEFAULT_SHOW_MULTIPLE_POINTS_SYMBOL_IN_LEGEND
  );
}

// Returns the paint property (data-driven stops/expression) selecting charm icon for
// points depending on the category they fall in.
function getPaintPropertyForCharmByCategory(colorByColumnAlias, colorByBuckets, otherColorByBucket) {
  let paintProperty = ['match', ['to-string', ['get', colorByColumnAlias]]];
  if (_.isEmpty(colorByBuckets)) {
    return '';
  }

  _.each(colorByBuckets, (colorByBucket) => {
    paintProperty.push(colorByBucket.id, getCharmCode(colorByBucket.charmName));
  });

  const otherBucketCharmCode = otherColorByBucket.charmName ?
    getCharmCode(otherColorByBucket.charmName) :
    '';
  paintProperty.push(otherBucketCharmCode);

  return paintProperty;
}

// Returns the paint property (data-driven stops/expression) selecting charm icon for
// points depending on the numerical range bucket they fall in.
function getPaintPropertyForCharmByRange(colorByColumnAlias, colorByBuckets, numberOfBuckets) {
  let paintProperty = ['step', ['to-number', ['get', colorByColumnAlias], -1, -1]];

  // default charm
  let defaultCharm = _.chain(colorByBuckets).
    find(['id', EMPTY_BUCKET_VALUE]).
    get('charmName').
    value();

  defaultCharm = defaultCharm ? getCharmCode(defaultCharm) : '';

  paintProperty.push(defaultCharm);

  _.chain(colorByBuckets).
    take(numberOfBuckets).
    each((colorByBucket) => {
      paintProperty.push(colorByBucket.id, getCharmCode(colorByBucket.charmName));
    }).
    value();

  return paintProperty;
}
