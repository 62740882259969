import _ from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';

// Adds mapbox-gl sources/layers for rendering heatClusters.
// On vif change, updates the layers or destroys and recreate
// if change required in the source.
const LAYERS = Object.freeze({
  HEAT_CLUSTERS: 'heat-clusters-layer'
});

const SOURCES = Object.freeze({
  HEAT_CLUSTERS: 'heat-clusters-source'
});

// Renders heat cluster using mapbox's heat-weight paint property. The heat clusters
// radius and intensity(color) varies based on the number of points contributing to the
// heat cluster.
export default class HeatClusters {
  constructor(map, seriesId) {
    this._map = map;

    this.layerIds = MapHelper.getNameToIdMap(_.values(LAYERS), seriesId);
    this.sourceIds = MapHelper.getNameToIdMap(_.values(SOURCES), seriesId);
  }

  alreadySetup() {
    return this._map.getSource(this.sourceIds[SOURCES.HEAT_CLUSTERS]);
  }

  setup(vif, renderOptions, overlayOptions) {
    this._map.addSource(this.sourceIds[SOURCES.HEAT_CLUSTERS], this.sourceOptions(vif, renderOptions));

    this._map.addLayer({
      'id': this.layerIds[LAYERS.HEAT_CLUSTERS],
      'type': 'heatmap',
      'source': this.sourceIds[SOURCES.HEAT_CLUSTERS],
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': vif.isLayerVisible() ? 'visible' : 'none'
      },
      'paint': {
        // Increase the heatmap weight based on number of points
        'heatmap-weight': {
          'property': 'count',
          'type': 'exponential',
          'stops': [
          [0, 0.2],
          [10, 0.5],
          [150, 1]
          ]
        },
        // Increase the heatmap color intensity by zoom level
        // (heatmap-intensity is a multiplier on top of heatmap-weight)
        'heatmap-intensity': {
          'stops': [
          [0, 0.5],
          [9, 1]
          ]
        },
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
          'interpolate',
        ['linear'],
        ['heatmap-density'],
          0, 'rgba(33,102,172,0)',
          0.2, 'rgb(103,169,207)',
          0.4, 'rgb(209,229,240)',
          0.6, 'rgb(253,219,199)',
          0.8, 'rgb(239,138,98)',
          1, 'rgb(178,24,43)'
        ],
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': {
          'stops': [
          [0, 20],
          [9, 15]
          ]
        }
      }
    }, overlayOptions.renderLayersBefore);
  }

  update(vif, renderOptions) {
    this._map.setLayoutProperty(this.layerIds[LAYERS.HEAT_CLUSTERS],
      'visibility',
      vif.isLayerVisible() ? 'visible' : 'none'
    );
  }

  sourceOptions(vif, renderOptions) {
    return {
      'type': 'vector',
      'geojsonTile': true,
      'tiles': [renderOptions.dataUrl]
    };
  }

  destroy() {
    _.each(this.layerIds, (layerId) => {
      this._map.removeLayer(layerId);
    });
    _.each(this.sourceIds, (sourceId) => {
      this._map.removeSource(sourceId);
    });
  }
}
