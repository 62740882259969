// Vendor Imports
import _ from 'lodash';
import $ from 'jquery';

// Project Imports
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import { FeatureFlags } from 'common/feature_flags';

import MetadataProvider, {
  getComputedColumns,
  getDisplayableColumns
} from './dataProviders/MetadataProvider';
import { getData, MAX_ROW_COUNT } from './dataProviders/MeasuresOnlyDataManager';
import SoqlDataProvider from './dataProviders/SoqlDataProvider';
import { getSoqlVifValidator } from './dataProviders/SoqlVifValidator';

import { categoriesToColorByBuckets, rangeToSizeByBuckets } from './helpers/BucketHelper';
import { getColumnFormats } from './helpers/ColumnFormattingHelpers';
import Palette, { COLOR_VARY_BY } from './helpers/palettes';
import { getLinearBuckets } from './helpers/RangeHelper';
import RenderByHelper, {
  valueToLabelFormatter,
  getBucketsWithConfiguredCustomPaletteColors
} from './helpers/RenderByHelper';
import * as VifHelpers from './helpers/VifHelpers';
import { migrateVif } from './helpers/migrateVif';
import { getParameterOverrides } from './helpers/VifSelectors';

import { generateSummaryTableVif } from './VisualizationCommon';

import SvgScatterChart from './views/SvgScatterChart';

// Constants
import { COLOR_BY_BUCKETS_COUNT, VIF_CONSTANTS } from 'common/authoring_workflow/constants';
import {
  SCATTER_CHART_COLOR_BY_SERIES_INDEX,
  SCATTER_CHART_RESIZE_BY_SERIES_INDEX,
  SCATTER_PLOT_RESIZE_BY_DEFAULT_MIN,
  SCATTER_PLOT_RESIZE_BY_DEFAULT_MAX,
  SCATTER_PLOT_RESIZE_BY_DEFAULT_BUCKETS_COUNT
} from './views/SvgConstants';

const WINDOW_RESIZE_RERENDER_DELAY = 200;

$.fn.socrataSvgScatterChart = function (originalVif, options) {
  originalVif = migrateVif(originalVif);
  originalVif = VifHelpers.migrateScatterChartVifWithoutRequiredSeries(originalVif);

  const $element = $(this);
  const visualization = new SvgScatterChart($element, originalVif, options);

  let rerenderOnResizeTimeout;

  /**
   * Event handling
   */
  function attachApiEvents() {
    // Destroy on (only the first) 'SOCRATA_VISUALIZATION_DESTROY' event.
    $element.one('SOCRATA_VISUALIZATION_DESTROY', () => {
      clearTimeout(rerenderOnResizeTimeout);
      visualization.destroy();
      detachInteractionEvents();
      detachApiEvents();
    });

    $(window).on('resize', handleWindowResize);
    $element.on('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.on('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function attachInteractionEvents() {
    $element.on('SOCRATA_VISUALIZATION_SCATTER_CHART_FLYOUT', handleFlyout);
  }

  function detachApiEvents() {
    $(window).off('resize', handleWindowResize);

    $element.off('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.off('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function detachInteractionEvents() {
    $element.off('SOCRATA_VISUALIZATION_SCATTER_CHART_FLYOUT', handleFlyout);
  }

  function handleWindowResize() {
    clearTimeout(rerenderOnResizeTimeout);

    rerenderOnResizeTimeout = setTimeout(
      visualization.render(),
      // Add some jitter in order to make sure multiple visualizations are
      // unlikely to all attempt to rerender themselves at the exact same
      // moment.
      WINDOW_RESIZE_RERENDER_DELAY + Math.floor(Math.random() * 10)
    );
  }

  function handleFlyout(event) {
    const payload = event.originalEvent.detail;
    const customEvent = new window.CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
      detail: payload,
      bubbles: true
    });

    $element[0].dispatchEvent(customEvent);
  }

  function handleRenderVif(event) {
    const newVif = event.originalEvent.detail;

    updateData(migrateVif(newVif));
  }

  function handleError(error) {
    let messages;

    if (window.console && console.error) {
      console.error(error);
    }

    if (error.vifValidatorErrors) {
      messages = error.vifValidatorErrors;
    } else if (error.soqlError) {
      const errorCode = _.get(error, 'soqlError.errorCode');

      messages = errorCode
        ? I18n.t(`shared.visualizations.charts.common.soql_error.${errorCode}`)
        : I18n.t('shared.visualizations.charts.common.error_generic');
    } else {
      return visualization.renderGenericError(
        I18n.t('shared.errors.private_or_deleted_asset.message'),
        'privateOrDeletedAsset'
      );
    }

    if (error.useGenericErrorViz) {
      visualization.renderGenericError(messages, 'scatter-chart');
    } else {
      visualization.renderError(messages);
    }
  }

  function updateData(newVif) {
    const colorByColumn = _.get(
      newVif,
      `series[${SCATTER_CHART_COLOR_BY_SERIES_INDEX}].dataSource.measure.columnName`
    );

    const domain = _.get(newVif, 'series[0].dataSource.domain');
    const datasetUid = _.get(newVif, 'series[0].dataSource.datasetUid');
    const clientContextVariables = getParameterOverrides(newVif);
    const datasetMetadataProvider = new MetadataProvider({ domain, datasetUid }, true);
    const datasetSoqlDataProvider = new SoqlDataProvider({ domain, datasetUid, clientContextVariables }, true);
    const resizeByColumn = _.get(
      newVif,
      `series[${SCATTER_CHART_RESIZE_BY_SERIES_INDEX}].dataSource.measure.columnName`
    );

    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_START');
    visualization.showBusyIndicator();
    detachInteractionEvents();

    $.fn.socrataSvgScatterChart
      .validateVif(newVif)
      .then(() => datasetMetadataProvider.getDatasetMetadata())
      .then((datasetMetadata) => {
        const displayableFilterableColumnsPromise = visualization.shouldDisplayFilterBar()
          ? datasetMetadataProvider.getDisplayableFilterableColumns({
              datasetMetadata,
              shouldGetColumnStats: false
            })
          : null;

        const colorByCategoriesPromise = colorByColumn
          ? RenderByHelper.getColorByCategories(newVif, colorByColumn, datasetSoqlDataProvider)
          : null;
        const resizeByRangePromise = resizeByColumn
          ? RenderByHelper.getResizeByRange(newVif, resizeByColumn, datasetSoqlDataProvider)
          : null;

        const getFormattedComputedRegionColumnsPromise = FeatureFlags.value('computed_region_system_columns')
          ? datasetMetadataProvider.getFormattedComputedRegionColumns()
          : null;

        return Promise.all([
          colorByCategoriesPromise,
          displayableFilterableColumnsPromise,
          resizeByRangePromise,
          getData(newVif),
          datasetMetadata,
          getFormattedComputedRegionColumnsPromise
        ]);
      })
      .then((resolutions) => {
        const [
          newColorByCategories,
          newColumns,
          newResizeByRange,
          newData,
          datasetMetadata,
          newComputedColumns // will only be populated if ff computed_region_system_columns is true
        ] = resolutions;

        const colorByColumnDetails = _.find(datasetMetadata.columns, (column) => {
          return column.fieldName === colorByColumn;
        });

        let returnNewComputedColumns;
        if (FeatureFlags.value('computed_region_system_columns')) {
          returnNewComputedColumns = newComputedColumns; // gotten from the promise
        } else {
          returnNewComputedColumns = getComputedColumns(datasetMetadata);
        }

        const displayableColumns = getDisplayableColumns(datasetMetadata);
        newData.columnFormats = getColumnFormats(displayableColumns);

        const newTableVif = generateScatterChartSummaryTableVif(newVif, newData, displayableColumns);

        const renderOptionsArg = {
          colorByBuckets: newColorByCategories
            ? getColorByBuckets(newVif, colorByColumnDetails, newColorByCategories)
            : null,
          resizeByBuckets: newResizeByRange ? getResizeByBuckets(newVif, newResizeByRange) : null
        };
        renderVisualization({
          newColumns,
          returnNewComputedColumns,
          newData,
          newVif,
          newTableVif,
          renderOptionsArg
        });
      })
      .catch(handleError);
  }

  function renderVisualization({
    newColumns,
    newComputedColumns,
    newData,
    newVif,
    newTableVif,
    renderOptionsArg
  }) {
    const overMaxRowCount = newData.rows.length > MAX_ROW_COUNT;
    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE');
    visualization.hideBusyIndicator();

    if (overMaxRowCount) {
      const error = formatString(
        I18n.t('shared.visualizations.charts.scatter_chart.error_exceeded_max_row_count'),
        MAX_ROW_COUNT
      );
      visualization.renderGenericError(error, 'exceededMaxRowCount');
    } else {
      attachInteractionEvents();
      visualization.render({
        newColumns,
        newComputedColumns,
        newData,
        newVif,
        newTableVif,
        renderOptionsArg
      });
    }
  }

  /**
   * Generates a summary table VIF for ScatterCharts
   *
   * @param {*} newVif
   * @param {*} newData
   * @param {*} displayableColumns
   */
  function generateScatterChartSummaryTableVif(newVif, newData, displayableColumns) {
    const tableData = _.cloneDeep(newData);
    const newVifClone = _.cloneDeep(newVif);

    // Indices that will be removed before generating table VIF
    const columnNullIndices = [];

    // First element in each row is null (alloted for dimension)
    // Remove initial null value
    const rowNullIndices = [0];

    // Track unconfigured measures from series
    // such as Color by Value or Resize by Value
    _.each(newVifClone.series, (series, i) => {
      if (_.isUndefined(series.dataSource.measure) || _.isNull(series.dataSource.measure.columnName)) {
        columnNullIndices.push(i);
        rowNullIndices.push(i + 1);
        return;
      }

      // TODO: The default aggregationFunction is set to count when addSeries
      // is called. Scatter chart points need to default to null however there is no
      // good way to set that in authoring_workflow/helpers as the state of type
      // scatterChart is returning type barChart
      if (series.dataSource.measure.aggregationFunction === 'count') {
        _.set(series, 'dataSource.measure.aggregationFunction', null);
      }
    });

    // Updates columns that will be used for table
    _.pullAt(newVifClone.series, columnNullIndices);

    // Remove initial null value and any values belonging to unconfigured measures
    _.each(tableData.rows, (row) => {
      _.pullAt(row, rowNullIndices);
    });

    return generateSummaryTableVif(newVifClone, displayableColumns, tableData, null, null);
  }

  /**
   * Actual execution starts here
   */

  attachApiEvents();
  updateData(originalVif);

  return this;
};

function getColorByBuckets(newVif, colorByColumnDetails, newColorByCategories) {
  const domain = _.get(newVif, 'series[0].dataSource.domain');
  const datasetUid = _.get(newVif, 'series[0].dataSource.datasetUid');
  const showNullsAsFalse = _.get(
    newVif,
    'configuration.showNullsAsFalse',
    VIF_CONSTANTS.DEFAULT_CAST_NULL_AS_FALSE
  );
  const colorByColumn = _.get(colorByColumnDetails, 'fieldName');
  const isColorByBooleanColumn = _.get(colorByColumnDetails, 'renderTypeName') === 'checkbox';
  const isCustomColorPalette = _.get(newVif, 'series[0].color.palette') === 'custom';
  const palette = new Palette({
    vif: newVif,
    seriesIndex: SCATTER_CHART_COLOR_BY_SERIES_INDEX,
    colorVaryBy: COLOR_VARY_BY.DIMENSION
  });

  const buckets = categoriesToColorByBuckets({
    bucketsCount: COLOR_BY_BUCKETS_COUNT,
    colorByColumnDetails,
    colorByCategories: newColorByCategories,
    getColor: (index, value) => palette.getColor(null, index, value),
    isCastNullAsFalseInSeries: showNullsAsFalse,
    valueToLabelFormatter: (value) =>
      valueToLabelFormatter({ value, columnDetails: colorByColumnDetails, domain, datasetUid })
  });

  return isCustomColorPalette
    ? getBucketsWithConfiguredCustomPaletteColors({
        buckets,
        vif: newVif,
        colorByColumn,
        isColorByBooleanColumn
      })
    : buckets;
}

function getResizeByBuckets(newVif, newResizeByRange) {
  const resizeByBucketsCount = Number(
    _.get(newVif, 'series[0].chartOptions.numberOfDataClasses', SCATTER_PLOT_RESIZE_BY_DEFAULT_BUCKETS_COUNT)
  );
  const diameterIntervals = getLinearBuckets(
    _.get(newVif, 'series[0].chartOptions.minimumPlotSize', SCATTER_PLOT_RESIZE_BY_DEFAULT_MIN),
    _.get(newVif, 'series[0].chartOptions.maximumPlotSize', SCATTER_PLOT_RESIZE_BY_DEFAULT_MAX),
    resizeByBucketsCount,
    { roundOffDiff: false }
  );

  return rangeToSizeByBuckets({
    sizeByRange: newResizeByRange,
    bucketsCount: resizeByBucketsCount,
    getSize: (index) => diameterIntervals[index]
  });
}

/**
 * Checks a VIF for compatibility with this visualization. The intent of this
 * function is to provide feedback while authoring a visualization, not to
 * provide feedback to a developer. As such, messages returned are worded to
 * make sense to a user.
 *
 * Returns a Promise.
 *
 * If the VIF is usable, the promise will resolve.
 * If the VIF is not usable, the promise will reject with an object:
 * {
 *   ok: false,
 *   vifValidatorErrors: Array<String>
 * }
 */
$.fn.socrataSvgScatterChart.validateVif = (vif) =>
  getSoqlVifValidator(vif).then((validator) =>
    validator.requireXAndYMeasureColumn().requireAllSeriesFromSameDomain().toPromise()
  );

export default $.fn.socrataSvgScatterChart;
