import { Children } from 'react';
import compact from 'lodash/fp/compact';
import curry from 'lodash/fp/curry';

/**
 * Given a React `children` opaque object, conditionally map over the children that satisfy the predicate function
 *
 * Curried to allow for incrementally defining the filter predicate and mapping function.
 *
 * @example
 * conditionallyMapChildren((child) => child.props.isVisible, (child) => <b>{child.props.value}</b>, children)
 *
 * @param predicate {function} function that will filter children
 * @param mapFn {function} function that will map children
 * @param children {object} React `children` opaque object
 */
export const conditionallyMapChildren = curry((predicate, mapFn, children) =>
  compact(Children.map(children, (child) => predicate(child) && mapFn(child)))
);
