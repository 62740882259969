import React from 'react';
import { fetchTranslation } from 'common/locale';
import { Expr, Scope, SoQLType, TypedExpr } from 'common/types/soql';
import {
  getLastUnAnalyzedAst, getCompilationProjectionInfo,
  ProjectionInfo, ViewColumnColumnRef,
  ProjectionInfoNA,
  getAnalysisProjectionInfo
} from '../lib/selectors';
import { PickableColumn, ProjectionExpr, matchPicked, isPickableColumn } from '../lib/column-picker-helpers';
import { connect } from 'react-redux';
import { AppState } from '../redux/store';
import BooleanDivider from './visualNodes/BooleanDivider';
import { hasGroupOrAggregate } from '../lib/soql-helpers';
import AddExpr from './AddExpr';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { ForgeButton, ForgeMenu, ForgeIcon } from '@tylertech/forge-react';
import { ForgeIconButton } from '@tylertech/forge-react';
import { Operator } from './visualNodes/Types';
import { FilterType } from '../types';
import { getColumnSubset } from '../lib/filter-helpers';
import { Either } from 'common/either';
import { whichAnalyzer } from '../lib/feature-flag-helpers';

const t = (k: string, scope = 'shared.explore_grid.visual_filter_editor') => fetchTranslation(k, scope);

interface ExternalProps {
  addExpr: (expr: Either<Expr, TypedExpr>, soqlType: SoQLType | null, operator: Operator, filterType?: FilterType) => void;
  className?: string;
  columns: ViewColumnColumnRef[];
  addFilterType?: FilterType;
  addWithOperator: Operator;
  showOperatorSelector?: boolean;
  updateOperator?: (operator: Operator) => void;
  removable?: boolean;
  showDivider?: boolean;
}

interface StateProps {
  parameters: ClientContextVariable[];
  hasGroupOrAggregate: boolean;
  projectionInfo: Either<ProjectionInfo, ProjectionInfoNA>;
  scope: Scope;
}

interface AddFilterState {
  showColumnPicker: boolean;
}

type AddFilterProps = ExternalProps & StateProps;
export class AddFilter extends React.Component<AddFilterProps, AddFilterState> {
  static defaultProps = {
    addWithOperator: 'op$AND' as Operator // not using Operator.AND to avoid recursive file processing :(
  };

  constructor(props: AddFilterProps) {
    super(props);
    this.state = {
      showColumnPicker: !props.showOperatorSelector
    };
  }

  onChangeOperator = (value: Operator) => {
    if (this.props.updateOperator) this.props.updateOperator(value);
  };

  onSelectColumn = (picked: PickableColumn) => {
    const { addExpr, addWithOperator } = this.props;
    matchPicked(
      picked,
      (vccr: ViewColumnColumnRef) => addExpr(whichAnalyzer(() => vccr.ref, () => vccr.typedRef)(), vccr.typedRef.soql_type, addWithOperator),
      (pexpr: ProjectionExpr) => addExpr(
        whichAnalyzer(
          () => pexpr.ref.getOrElseValue(pexpr.expr as any) as Expr,
          () => pexpr.ref.map(r => ({...r, soql_type: pexpr.typedExpr.soql_type })).getOrElseValue(pexpr.typedExpr as any) as TypedExpr
        )(), pexpr.typedExpr.soql_type as SoQLType, addWithOperator
      )
    );
  };

  onRemove = () => {
    if (this.props.removable) this.setState({ showColumnPicker: false });
  };

  onUpdate = (expr: Either<Expr, TypedExpr> | PickableColumn) => {
    if (isPickableColumn(expr)) {
      this.onSelectColumn(expr);
    } else {
      const wishesItWasAType = expr.fold(
        (e) => e.type as SoQLType, // yes this is wrong but that's how it's been
        (e) => e.soql_type
      );
      this.props.addExpr(expr, wishesItWasAType, this.props.addWithOperator);
    }
  };

  render() {
    const {
      addWithOperator,
      addFilterType,
      className,
      columns,
      parameters,
      projectionInfo,
      removable,
      scope,
      showOperatorSelector,
    } = this.props;
    const { showColumnPicker }  = this.state;
    const operatorKey = addWithOperator === Operator.OR ? 'op$or' : 'op$and';
    const operatorText = t(operatorKey, 'shared.explore_grid.functions');

    const forgeDropdownOptions = [
      { label: t('op$and', 'shared.explore_grid.functions'), value: Operator.AND },
      { label: t('op$or', 'shared.explore_grid.functions'), value: Operator.OR }
    ];

    return (
      <div className={`add-expr ${className}`}>
        {showColumnPicker && (<div>
          {showOperatorSelector && <BooleanDivider
            selectedOperator={addWithOperator || Operator.AND}
            onUpdate={this.onChangeOperator} />}
          <AddExpr
            columns={columns}
            parameters={parameters}
            columnSubset={getColumnSubset(addFilterType, this.props.hasGroupOrAggregate)}
            hasGroupOrAggregate={this.props.hasGroupOrAggregate}
            onRemove={this.onRemove}
            onUpdate={this.onUpdate}
            placeholderText={t('add_initial')}
            projectionInfo={projectionInfo}
            removable={removable}
            scope={scope}
          />
        </div>)}
        {showOperatorSelector && <div className="add-expr-btn-container">
          <ForgeIconButton >
            <button
              type='button'
              onClick={() => this.setState({ showColumnPicker: true })}
              aria-label={t('add_expression')}
              data-testid={'add-expression-icon-button'}
            >
              <ForgeIcon name="add" className="add-expr-icon"/>
            </button>
          </ForgeIconButton>
          <ForgeMenu
            options={forgeDropdownOptions}
            placement='bottom'
            dense={true}
            on-forge-menu-select={(v: CustomEvent) => this.onChangeOperator(v.detail.value)}
          >
            <ForgeButton>
              <button type='button' data-testid={'add-expression-operator-selector'}>
                {operatorText}
                <ForgeIcon name="keyboard_arrow_down" />
              </button>
            </ForgeButton>
          </ForgeMenu>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    projectionInfo: whichAnalyzer(getCompilationProjectionInfo, getAnalysisProjectionInfo)(state.query),
    hasGroupOrAggregate: hasGroupOrAggregate(
      getLastUnAnalyzedAst(state.query),
      state.scope.getOrElseValue([])
    ),
    scope: state.scope.getOrElseValue([]),
    parameters: state.clientContextInfo.variables
  };
};

export default connect(mapStateToProps)(AddFilter);
