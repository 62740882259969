import _ from 'lodash';

export const TOGGLE_USER_NOTIFICATIONS_LOADING = 'TOGGLE_USER_NOTIFICATIONS_LOADING';
export const toggleUserNotificationLoading = (toggle) => ({
  type: TOGGLE_USER_NOTIFICATIONS_LOADING,
  loading: toggle
});

export const UPDATE_USER_NOTIFICATIONS_BY_TYPE = 'UPDATE_USER_NOTIFICATIONS_BY_TYPE';
export const updateUserNotificationsByType = (notificationConfigs, notificationType) => ({
  type: UPDATE_USER_NOTIFICATIONS_BY_TYPE,
  newNotifications: notificationConfigs.notifications,
  notificationConfigs: _.omit(notificationConfigs, ['notifications']),
  notificationType
});

export const UPDATE_TRANSIENT_NOTIFICATIONS = 'UPDATE_TRANSIENT_NOTIFICATIONS';
export const updateTransientNotifications = (transientNotifications) => ({
  type: UPDATE_TRANSIENT_NOTIFICATIONS,
  transientNotifications
});

export const UPDATE_USER_NOTIFICATIONS = 'UPDATE_USER_NOTIFICATIONS';
export const updateUserNotifications = (userNotifications) => ({
  type: UPDATE_USER_NOTIFICATIONS,
  userNotifications
});

export const UPDATE_ENQUEUED_NOTIFICATIONS = 'UPDATE_ENQUEUED_NOTIFICATIONS';
export const updateEnqueuedNotifications = (enqueuedNotifications) => ({
  type: UPDATE_ENQUEUED_NOTIFICATIONS,
  enqueuedNotifications
});

export const UPDATE_NEW_USER_NOTIFICATION_COUNT = 'UPDATE_NEW_USER_NOTIFICATION_COUNT';
export const increaseNewNotificationCount = (notificationType, count) => ({
  type: UPDATE_NEW_USER_NOTIFICATION_COUNT,
  count,
  notificationType
});

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const loadNotifications = (notificationType, offset) => ({
  type: LOAD_NOTIFICATIONS,
  notificationType,
  offset
});

export const LOAD_SOCKET_CONNECTION = 'LOAD_SOCKET_CONNECTION';
export const loadSocketConnection = (userId, options) => {
  return {
    type: LOAD_SOCKET_CONNECTION,
    userId,
    options
  };
};

export const LOAD_NEW_NOTIFICATIONS = 'LOAD_NEW_NOTIFICATIONS';
export const loadNewNotification = (response) => {
  return {
    type: LOAD_NEW_NOTIFICATIONS,
    response
  };
};

export const ON_DELETE_USER_NOTIFICATION = 'ON_DELETE_USER_NOTIFICATION';
export const onDeleteUserNotification = (response) => {
  return {
    type: ON_DELETE_USER_NOTIFICATION,
    response
  };
};

export const ON_DELETE_ALL_USER_NOTIFICATION = 'ON_DELETE_ALL_USER_NOTIFICATION';
export const onDeleteAllNotifications = () => {
  return {
    type: ON_DELETE_ALL_USER_NOTIFICATION
  };
};

export const ON_USER_NOTIFICATION_READ_STATE_CHANGE = 'ON_USER_NOTIFICATION_READ_STATE_CHANGE';
export const onNotificationReadStateChange = (response, readState) => {
  return {
    type: ON_USER_NOTIFICATION_READ_STATE_CHANGE,
    response,
    readState
  };
};

export const SEE_NEW_USER_NOTIFICATION = 'SEE_NEW_USER_NOTIFICATION';
export const seeNewNotifications = (notificationType) => {
  return {
    type: SEE_NEW_USER_NOTIFICATION,
    notificationType
  };
};

export const MOVE_TRANSIENT_NOTIFICATION = 'MOVE_TRANSIENT_NOTIFICATION';
export const moveTransientNotificationIntoPanel = (notificationId) => {
  return {
    type: MOVE_TRANSIENT_NOTIFICATION,
    notificationId
  };
};

export const REMOVE_TRANSIENT_NOTIFICATION = 'REMOVE_TRANSIENT_NOTIFICATION';
export const removeTransientNotification = (notificationId) => {
  return {
    type: REMOVE_TRANSIENT_NOTIFICATION,
    notificationId
  };
};

