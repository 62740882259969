import { fetchJsonWithDefaultHeaders, fetchWithDefaultHeaders } from 'common/http';

export function createScreenshotSubscription(partialSub: ScreenshotSubscriptionSansSubscriber,
  subscriberUid: string): SubscriptionResponse {
  const subscription = { ...partialSub, subscriber_uid: subscriberUid };
  return fetchWithDefaultHeaders('/api/notifications_and_alerts/screenshot_subscriptions', {
    method: 'POST',
    body: JSON.stringify({ subscription })
  } as RequestInit);
}

export function fetchAllSubscriptionsForAsset(assetUid: string): SubscriptionListResponse {
  return fetchJsonWithDefaultHeaders(`/api/notifications_and_alerts/screenshot_subscriptions/${assetUid}`);
}

export function deleteAllForAsset(assetUid: string): EmptyResponse {
  return fetchWithDefaultHeaders(`/api/notifications_and_alerts/screenshot_subscriptions/${assetUid}`, {
    method: 'DELETE',
    credentials: 'same-origin'
  });
}

export function fetchAllSubscriptionsForUser(watching: boolean): SubscriptionListResponse {
  return fetchJsonWithDefaultHeaders(`/api/notifications_and_alerts/screenshot_subscriptions/?is_user_watching=${watching}`);
}

type SubscriptionListResponse = Promise<SubscriptionListPayload>;
type SubscriptionResponse = Promise<SingleSubscriptionPayload>;
type EmptyResponse = Promise<Response>;

interface SubscriptionListPayload extends Response {
  data?: ScreenshotSubscription[];
}

interface SingleSubscriptionPayload extends Response {
  data?: ScreenshotSubscription;
}

export interface ScreenshotSubscription extends ScreenshotSubscriptionSansSubscriber {
  subscriber_uid: string;
}

interface ScreenshotSubscriptionSansSubscriber {
  asset_uid: string;
  domain_cname: string;
  first_occurrence: string; // Date formatted as ISO string
  interval: Interval;
  subscribed_by_uid: string;
  first_occurrence_timezone?: string;
  creation_timezone?: string;
}

interface Interval {
  days: number;
}
