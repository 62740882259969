// Vendor Imports
import d3 from 'd3';
import _ from 'lodash';
import $ from 'jquery';

import { AXIS_LABEL_MARGIN } from './SvgConstants';

const DEFAULT_OPTIONS = {
  margins: { TOP: 0, BOTTOM: 0 },
  enabled: false,
  onDrag: _.noop,
  onDragEnd: _.noop,
  getConfiguredLabelHeight: _.noop,
  getAxisLabels: _.noop
};

export default class LabelResizer {
  constructor(options = {}) {
    this.id = _.uniqueId();
    this.state = {
      // True during interactive resize, false otherwise.
      dragging: false,

      // Controls how much horizontal space the labels take up.
      // The override persists until cleared by a VIF update.
      // The override is active if this value is defined.
      // Otherwise, the chart falls back to the space
      // configured in the VIF or the default.
      overriddenAreaSize: undefined
    };

    this.options = _.merge({}, DEFAULT_OPTIONS, options);
    this.draggerElement = null;
    this.$chartElement = null;
  }

  computeLabelHeight = () => {
    const height = _.isFinite(this.state.overriddenAreaSize) ?
      this.state.overriddenAreaSize :
      this.options.getConfiguredLabelHeight();

    const axisLabels = this.options.getAxisLabels();
    const topMargin = this.options.margins.TOP;
    const bottomMargin = this.options.margins.BOTTOM + (axisLabels.bottom ? AXIS_LABEL_MARGIN : 0);

    return _.clamp(height, 0, this.$chartElement.height() - (topMargin + bottomMargin));
  };

  update = (leftOffset, topOffset, width) => {
    // Only move if not dragging. Otherwise, d3's dragger becomes confused.
    if (!this.state.dragging && this.draggerElement) {
      $(this.draggerElement).css({
        left: `${leftOffset}px`,
        top: `${topOffset}px`,
        width: `${width}px`
      });
    }
  };

  updateOptions = (options) => {
    _.merge(this.options, options);

    if (!this.draggerElement) {
      return;
    }

    $(this.draggerElement).toggleClass('enabled', this.options.enabled);
  };

  renderTemplate = ($chartElement) => {
    this.$chartElement = $chartElement;
    this.draggerElement = document.createElement('div');

    this.draggerElement.setAttribute(
      'class',
      'label-width-dragger ' + (this.options.enabled ? 'enabled' : '')
    );

    d3.select(this.draggerElement).call(d3.behavior.drag().
      on('dragstart', () => {
        this.state.dragging = true;
        this.state.overriddenAreaSize = this.computeLabelHeight();
        this.$chartElement.addClass('dragging-label-width-dragger');
      }).
      on('drag', () => {
        this.state.overriddenAreaSize -= d3.event.dy;
        this.options.onDrag(this.state);
      }).
      on('dragend', () => {
        this.state.dragging = false;
        this.$chartElement.removeClass('dragging-label-width-dragger');
        this.options.onDragEnd(this.state);
      })
    );

    $chartElement.append(this.draggerElement);
  };

  resetOverride = () => {
    // Forget the label area size the user set - we're
    // loading a brand new vif.
    this.state.overriddenAreaSize = undefined;
  };
}
