import { cloneDeep } from 'lodash';

import { SoqlFilter } from '../SoqlFilter';
import { FilterDataType } from '../types';

import * as CalendarDateFilter from './Filters/CalendarDateFilter';
import * as NumberFilter from './Filters/NumberFilter';
import * as TextFilter from './Filters/TextFilter';
import { FilterColumnsMap, getFilterDataType } from './Filters/BaseFilter';

const migrateFilter = (filter: SoqlFilter, columns: FilterColumnsMap) => {
  const migratedFilter = cloneDeep(filter);

  // In order to make our vif migration not asynchronous we've removed the requirement for dataTypeName and displayName
  // on the SoqlFilter. This means we need to infer them for the soql filter here since we have column information
  // available.
  if (!migratedFilter.dataTypeName) {
    const column = columns[filter.columns[0].datasetUid];
    migratedFilter.dataTypeName = getFilterDataType(column);
  }

  switch (migratedFilter.dataTypeName) {
    case FilterDataType.CHECKBOX:
      return migratedFilter;
    case FilterDataType.COMPUTED:
      return migratedFilter;
    case FilterDataType.DATE:
      return CalendarDateFilter.migrateFilter(migratedFilter as CalendarDateFilter.CalendarDateFilterConfig);
    case FilterDataType.NUMBER:
      return NumberFilter.migrateFilter(migratedFilter, columns);
    case FilterDataType.RADIUS:
      return migratedFilter;
    case FilterDataType.TEXT:
      return TextFilter.migrateFilter(migratedFilter as TextFilter.TextSoqlFilter, columns);
    default:
      return migratedFilter;
  }
};

export default migrateFilter;
