import React, { FunctionComponent } from 'react';

import { Federation } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import { View } from 'common/types/view';
import ResultsTable from 'common/components/ResultsTable';
import {
  FederationTypeColumnWithoutTooltip,
  TargetDomainColumn,
  ContactColumn,
  StatusColumn
} from 'common/components/Federation/FederationTableColumns';

import AssetFederationTableActionCell from './asset_federation_table_action_cell';

interface FederationTableProps {
  /** View that we're showing federations for */
  view: View;

  /** List of all federations */
  federations: Federation[];

  /** Function to call to re-fetch federations for the view */
  refetchFederationRelationships: () => void;
}

/** This is basically the same table that shows up for frontend's `federations` page (`/admin/federations`) */
const FederationTable: FunctionComponent<FederationTableProps> = ({
  federations,
  view,
  refetchFederationRelationships
}) => {
  return (
    <ResultsTable
      data={federations}
      rowKey="id"
      noResultsMessage={I18n.t('shared.federations.no_results')}
      id="federations-table"
    >
      {TargetDomainColumn}
      {FederationTypeColumnWithoutTooltip}
      {ContactColumn}
      {StatusColumn}
      <ResultsTable.Column
        header={I18n.t('shared.federations.headers.actions')}
        headerClassName="actions"
        dataClassName="actions"
        dataIndex="actions"
      >
        {(ignored: unknown, rowData: Federation) => (
          <AssetFederationTableActionCell
            federation={rowData}
            view={view}
            refetchFederationRelationships={refetchFederationRelationships}
          />
        )}
      </ResultsTable.Column>
    </ResultsTable>
  );
};

export default FederationTable;
