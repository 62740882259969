import { ViewType, DisplayType } from 'common/types/view';

/**
 * Determines whether a given view's viewType and displayType are supported
 * by the AssetActionBar (AAB).
 *
 * For assets that this returns true for, the AAB will not be shown at all.
 * For assets that this returns false for, the AAB will show if the user has available actions
 * (see primary_action and secondary_actions)
 */
export const assetIsUnsupportedByAssetActionBar = ({
  viewType,
  displayType
}: {
  viewType: ViewType;
  displayType: DisplayType;
}) => {
  switch (displayType) {
    // forms and calendars are out
    case DisplayType.Form:
    case DisplayType.Calendar:
      return true;

    // "tabular maps" are maps based off of datasets or that are from ESRI
    // "tabular charts" are old charts that don't work with AAB
    case DisplayType.Map:
    case DisplayType.Chart:
      return viewType === ViewType.Tabular;

    // by default, all other asset types are NOT unsupported
    // (they are supported)
    default:
      return false;
  }
};

export default assetIsUnsupportedByAssetActionBar;
