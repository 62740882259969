import _ from 'lodash';
import $ from 'jquery';
import { View } from 'common/types/view';
import { isGeospatial, SoQLType } from 'common/types/soql';
import { FeatureFlags } from 'common/feature_flags';

// These are helpers for creating all the download links for a dataset
// they can be used with an existing list of exports, or you can use getExportFormats to generate the list

// getMimeType and getDownloadLink are duplicated in Ruby in frontend/app/helpers/schema_dot_org_metadata_helper.rb
// this is because we need to generate this metadata server-side for Google indexing
// if you update these, please also update frontend/app/helpers/schema_dot_org_metadata_helper.rb

// getExportFormats and supportsGeoExport are duplicated in dataset_landing_page.rb and view.rb respectively
// this is because we want to encapsulate the export link logic into the ExportModal, instead of each
// modal user having to pipe it through from ruby-land

export type ExportFormat =
  | 'csv'
  | 'json'
  | 'rdf'
  | 'rss'
  | 'xml'
  | 'csv_for_excel'
  | 'csv_for_excel_europe'
  | 'tsv_for_excel'
  | 'kml'
  | 'kmz'
  | 'shapefile'
  | 'geojson'
  | 'xlsx';

export const regularFormats: ExportFormat[] = [
  'csv',
  'rdf',
  'rss',
  'xml',
  'csv_for_excel',
  'csv_for_excel_europe',
  'tsv_for_excel',
  'xlsx'
];

export const IGNORED_FORMATS_FOR_DATE = ['rdf', 'rss', 'xml'];

export const geospatialFormats: ExportFormat[] = ['kml', 'kmz', 'shapefile', 'geojson'];

const geospatialFormatsMap = Object.freeze({
  kml: 'KML',
  kmz: 'KMZ',
  shapefile: 'Shapefile',
  geojson: 'GeoJSON'
});

export const getMimeType = (format: ExportFormat) => {
  switch (format) {
    case 'csv':
    case 'csv_for_excel':
    case 'csv_for_excel_europe':
      return 'text/csv';
    case 'json':
      return 'application/json';
    case 'rdf':
      return 'application/rdfxml';
    case 'rss':
      return 'application/rss+xml';
    case 'tsv_for_excel':
      return 'text/tab-separated-values';
    case 'xml':
      return 'application/xml';
    case 'shapefile':
      // we download shapefiles as zip archives
      return 'application/zip';
    case 'geojson':
      return 'application/vnd.geo+json';
    case 'kmz':
      return 'application/vnd.google-earth.kmz';
    case 'kml':
      return 'application/vnd.google-earth.kml+xml';
    default:
      return 'text/csv'; // ¯\_(ツ)_/¯
  }
};

export const getDownloadLink = (viewUid: string, options = {}) => {
  // Ensure that all options are initialized, then destructure into local
  // variables and provide further defaults.
  const { cname, format, protocol, queryParams } = _.defaultsDeep({}, options, {
    cname: null,
    format: 'csv',
    protocol: 'https',
    queryParams: {}
  });

  let extension = format;
  queryParams.accessType = 'DOWNLOAD';

  // Handle special Excel cases.
  switch (format) {
    case 'csv_for_excel':
      extension = 'csv';
      queryParams.bom = 'true';
      queryParams.format = 'true';
      break;
    case 'csv_for_excel_europe':
      extension = 'csv';
      queryParams.bom = 'true';
      queryParams.format = 'true';
      queryParams.delimiter = ';';
      break;
    case 'tsv_for_excel':
      extension = 'tsv';
      queryParams.bom = 'true';
      break;
  }

  const sort = _.get(window, 'socrata.featureFlags.sort_dataset_on_export', false) ? '&sorting=true' : '';

  let pathAndQuery;

  if (geospatialFormatsMap[format]) {
    const params = {
      ...queryParams,
      method: 'export',
      format: geospatialFormatsMap[format]
    };

    const queryString = $.param(params);

    pathAndQuery = `/api/geospatial/${viewUid}?${queryString}`;
  } else {
    const queryString = $.param(queryParams);
    pathAndQuery = `/api/views/${viewUid}/rows.${extension}?${queryString}${sort}`;
  }

  return _.isEmpty(cname) ? pathAndQuery : `${protocol}://${cname}${pathAndQuery}`;
};

export const getDownloadType = (format: ExportFormat) => {
  switch (format) {
    case 'csv_for_excel':
      return 'CSV for Excel';
    case 'csv_for_excel_europe':
      return 'CSV for Excel (Europe)';
    case 'tsv_for_excel':
      return 'TSV for Excel';
    default:
      return format.toUpperCase();
  }
};

export const supportsGeoExport = (view: View, querySelectTypes?: SoQLType[]) => {
  if (querySelectTypes) {
    return querySelectTypes.filter((dt) => isGeospatial(dt)).length === 1;
  } else {
    return view.columns.filter((col) => isGeospatial(col.dataTypeName)).length === 1;
  }
};

// porting this logic from dataset_landing_page.rb to javascript because the ExportModal should be in charge of the logic, tbh
// just pass it a view and it'll do the rest
export const getExportFormats = (
  view: View,
  isTableViz: boolean,
  invalidSelectForXML = false,
  querySelectTypes?: SoQLType[]
) => {
  let formats = regularFormats;

  if (FeatureFlags.value('hide_csv_for_excel_download')) {
    formats = formats.filter((v) => v !== 'csv_for_excel' && v !== 'csv_for_excel_europe');
  }

  if (!FeatureFlags.valueOrDefault('enable_table_formatted_exports', false) || !isTableViz) {
    formats = formats.filter((format) => format !== 'xlsx');
  }

  if (invalidSelectForXML) {
    formats = formats.filter((format) => format !== 'xml');
  }

  if (supportsGeoExport(view, querySelectTypes)) {
    formats = [...formats, ...geospatialFormats];
  }
  return formats.sort();
};
