import { cloneDeep, get } from 'lodash';

import convertToVersion1 from './version1.js';
import convertToVersion2 from './version2.js';
import convertToVersion3 from './version3.js';
import convertToVersion4 from './version4.js';
import convertToVersion5, { MeasureV4 } from './version5';
import backfillCumulativeMathStartDate from './backfillCumulativeMathStartDate';
import { Measure } from 'common/performance_measures/types';
import { IntRange } from 'common/types/baseTypeExtensions';
import { LatestSchemaVersion } from 'common/performance_measures/lib/constants';

export function toLatestSchemaVersion(measure: Measure) {
  let updatedMeasure = cloneDeep(measure);

  const version = (m: Measure): IntRange<0, typeof LatestSchemaVersion> => get(m, 'version', 0);

  // Apply each version upgrade in order
  if (version(updatedMeasure) === 0) {
    updatedMeasure = convertToVersion1(updatedMeasure);
  }

  if (version(updatedMeasure) === 1) {
    updatedMeasure = convertToVersion2(updatedMeasure);
  }

  if (version(updatedMeasure) === 2) {
    updatedMeasure = convertToVersion3(updatedMeasure);
  }

  if (version(updatedMeasure) === 3) {
    updatedMeasure = convertToVersion4(updatedMeasure);
  }

  if (version(updatedMeasure) === 4) {
    updatedMeasure = convertToVersion5(updatedMeasure as unknown as MeasureV4);
  }

  updatedMeasure = backfillCumulativeMathStartDate(updatedMeasure);

  return updatedMeasure;
}
