// Imports
// --------------------------------------------------------------------------------
import React from 'react';
import ReactDOM from 'react-dom';




// Class Definition
// --------------------------------------------------------------------------------
export default class JsxToHtmlElementService {

  // Setup
  // --------------------------------------------------------------------
  cache: Map<string, HTMLElement>;

  constructor(cache?: Map<string, HTMLElement>) {
    this.setCache(cache || this.createCache());
  }



  // Instance Methods
  // --------------------------------------------------------------------

  /**
   * Wrap JSX into an HTML Element and add it to the cache
   * @param jsx React.ReactElement
   * @param key string
   * @param elementType string [optional: Default is 'div']
   * @returns HTMLElement
   */
  wrapJsx = (jsx: React.ReactElement, key: string, elementType?: string) => {
    const el = document.createElement(elementType || 'div');

    ReactDOM.render(jsx, el);
    this.set(key, el);

    return el as HTMLElement;
  };


  // Cache Specific
  // ----------------------------------------------------------

  /**
   * Create and return a new cache Map.
   * @returns Map<string, HTMLElement>
   */
  createCache = () => {
    return new Map<string, HTMLElement>();
  };

  /**
   * Return the existing cache.
   * @returns Map<string, HTMLElement>
   */
  getCache = () => {
    return this.cache as  Map<string, HTMLElement>;
  };

  /**
   * Set the instance's cache to the one provided only if there is no current instance cache.
   * NOTE: There should only ever be two scenarios where there is no instance cache:
   *   1) When the instance is first being instantiated.
   *   2) As part of the overwriteCache method executing (a new cache is immediately set after the current is removed).
   * @param cache Map<string, HTMLElement>
   * @returns JsxToHtmlElementService
   */
  setCache = (cache: Map<string, HTMLElement>) => {
    if (!this.cache) {
      this.cache = cache;
    }

    return this;
  };

  /**
   * Set the instance's cache to the one provided or create a new one.
   * Unmounts and deletes entries in the current cache beforehand unless the override is truthy.
   * @param cache Map<string, HTMLElement> [optional]
   * @param overrideDelete boolean [optional] Default: false
   * @returns JsxToHtmlElementService
   */
  overwriteCache = (cache?: Map<string, HTMLElement>, overrideDelete = false) => {
    // Unmount and delete any elements in the current cache unless overridden.
    if (!overrideDelete) {
      this.deleteAll();
    }

    // Remove the existing cache
    Reflect.deleteProperty(this, 'cache');

    // Set the provided cache or create a new one.
    return this.setCache(cache || this.createCache());
  };


  // Get/Set
  // ----------------------------------------------------------
  /**
   * Get a specific element within the instance cache by its key
   * @param key string
   * @returns HTMLElement | undefined
   */
  get = (key: string) => {
    return this.cache.get(key);
  };


  /**
   * Set a specific key/element pair within the instance cache
   * @param key string
   * @param el HTMLElement
   * @returns JsxToHtmlElementService
   */
  set = (key: string, el: HTMLElement) => {
    // Be sure to unmount any existing element for that key
    this.unmount(key);

    // Set the new element
    this.cache.set(key, el);

    return this;
  };


  // Unmount - Remove Element from DOM but not the cache
  // ----------------------------------------------------------
  /**
   * Unmount a specific key within the instance cache
   * @param key string
   * @returns JsxToHtmlElementService
   */
  unmount = (key: string) => {
    if (this.cache.has(key)) {
      ReactDOM.unmountComponentAtNode(this.cache.get(key) as HTMLElement);
    }

    return this;
  };

  /**
   * Unmount a selection of keys within the instance cache
   * @param keys Array<string>
   * @returns JsxToHtmlElementService
   */
  unmountSelection = (keys: Array<string>) => {
    if (keys.length) {
      keys.forEach((key: string) => {
        this.unmount(key);
      }, this);
    }

    return this;
  };

  /**
   * Unmount all keys within the instance cache
   * @returns JsxToHtmlElementService
   */
  unmountAll = () => {
    if (this.cache.size) {
      this.unmountSelection(Array.from(this.cache.keys()));
    }

    return this;
  };


  // Delete - Remove Element from DOM and the cache
  // ----------------------------------------------------------

  /**
   * Delete a specific key within the instance cache, making sure it is unmounted first
   * @param key string
   * @returns JsxToHtmlElementService
   */
  delete = (key: string) => {
    if (this.cache.has(key)) {
      // Be sure to unmount an existing element for the key
      this.unmount(key);

      // Delete the element for the key
      this.cache.delete(key);
    }

    return this;
  };

  /**
   * Delete a selection of keys within the instance cache, making sure they are unmounted first
   * @param keys Array<string>
   * @returns JsxToHtmlElementService
   */
  deleteSelection = (keys: Array<string>) => {
    if (keys.length) {
      keys.forEach((key: string) => {
        this.delete(key);
      }, this);
    }

    return this;
  };

  /**
   * Delete all keys within the instance cache, making sure they are unmounted first
   * @returns JsxToHtmlElementService
   */
  deleteAll = () => {
    // Make sure all elements are unmounted first
    this.unmountAll();

    // Clear the cache
    this.cache.clear();

    return this;
  };
}
