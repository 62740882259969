/* Imports */
/* ============================================================================= */
import { WorkflowGuidance } from 'common/types/approvals';

import { executeApprovalsRequestV2 } from 'common/core/approvals/guidanceLogic';





/* Method */
/* ============================================================================= */
/**
 * Executes a change audience request using the given WorkflowGuidance object's `toChangeAudience` guidance
 *
 * @param guidance WorkflowGuidance object
 * @returns A Promise object
 */
export const changeAudienceRequestV2 = (guidance: WorkflowGuidance) => {
  if (!guidance.toChangeAudience) {
    return Promise.reject("key 'toChangeAudience' not found in guidance response");
  }

  return executeApprovalsRequestV2(guidance, guidance.toChangeAudience);
};

export default changeAudienceRequestV2;
