import FeatureFlags from 'common/feature_flags';
import {
  VIF_CONSTANTS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR
} from 'common/authoring_workflow/constants';

export default {
  format: {
    type: 'visualization_interchange_format',
    version: VIF_CONSTANTS.LATEST_VERSION
  },
  description: '',
  configuration: {
    bucketType: 'linear',
    viewSourceDataLink: true
  },
  series: [
    {
      color: {
        primary: DEFAULT_PRIMARY_COLOR,
        secondary: DEFAULT_SECONDARY_COLOR,
        highlight: '#cccccc'
      },
      dataSource: {
        datasetUid: null,
        dimension: {
          columnName: null,
          aggregationFunction: null
        },
        measure: {
          columnName: null,
          aggregationFunction: 'count'
        },
        type: 'socrata.soql',
        filters: []
      },
      label: null,
      type: 'histogram'
    }
  ],
  title: ''
};
