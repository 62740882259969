import type { View } from 'common/types/view';

import I18n from 'common/i18n';
import DeleteAssetModal from 'common/components/DeleteAssetModal';
import { assign } from 'common/window_location';
import { openModal, closeModal } from 'common/components/AssetActionBar/lib/modal_helper';
import { showToastOnPageReload, ToastType } from 'common/components/ToastNotification/Toastmaster';
import { isStoryDraft } from 'common/views/view_types';
import { assetIsDraft } from 'common/views/helpers';

/**
 * Opens the DeleteAssetModal, confirms that the user actually wants to delete, and then redirects
 * somewhere.
 *
 * @param view View to delete
 * @param redirectUrlAfterDelete Function that returns string of where to redirect to after deletion.
 *  Defaults to redirecting to /profile
 * @param revisionSeq DSMAPI revision sequence, if present (this will cause DeleteAssetModal to delete the revision)
 */
export const launchDeleteAssetModal = (
  view: View,
  redirectUrlAfterDelete = () => '/profile',
  revisionSeq?: number
) => {
  const assetIsStoryDraft = isStoryDraft(view);

  const isDraft = assetIsDraft({
    coreView: view,
    isRevision: revisionSeq !== undefined,
    isDraftStory: assetIsStoryDraft
  });

  const onDeleted = () => {
    showToastOnPageReload({
      type: ToastType.SUCCESS,
      content: I18n.t(isDraft ? 'delete_draft_success' : 'delete_success', {
        name: view.name,
        scope: 'shared.components.asset_action_bar.publication_action'
      })
    });
    assign(redirectUrlAfterDelete());
  };

  openModal(DeleteAssetModal, {
    view,
    assetIsStoryDraft,
    revisionSeq,

    onDeleted,
    onDismiss: closeModal
  });
};
