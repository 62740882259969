// eslint doesn't understand indentation for curried functions
/* eslint-disable */
import _ from 'lodash';
import {
  BinaryTree,
  Expr,
  isColumnRef,
  isFunCall,
  isJoinByFromTable,
  isJoinBySubSelect,
  NoPosition,
  TableQualifier,
  UnAnalyzedAst,
  UnAnalyzedJoin,
  UnAnalyzedSelection,
  Scope } from "common/types/soql";
import { containsAggregate, pluckColumnRefs, expandExplicitlyAliasedColumnRefs } from '../lib/soql-helpers';
import { lastInChain, replaceLastInChain } from '../lib/selectors';
import { PhxChannel } from 'common/types/dsmapi';
import { some } from 'ts-option';
import { CompilerResponse } from 'common/soql/compiler-api';


const AUTOCOMPLETE_LIMIT = 10;
const AUTOALIAS = "__auto_alias_abcdef"

export interface Suggestion {
  title: string;
  matches: never;
}
export interface SuggestionResults {
  results: Suggestion[];
}
export type Suggester = (searchTerm: string, offset: number, isLazyLoading: boolean) => Promise<SuggestionResults>;

export const getSuggestions =
  (soqlDataProvider: any, forExpr: Expr, scope: Scope, tree: BinaryTree<UnAnalyzedAst>, channel: PhxChannel) =>
  (searchTerm: string, offset: number, isLazyLoading: boolean): Promise<SuggestionResults> => {

    const ast = lastInChain(tree);
    const expandedExpr = expandExplicitlyAliasedColumnRefs(forExpr, ast);

    // position here is made up, since this is a behind-the-scenes query, we're not going to be displaying any compilation errors
    const baseQuerySelections: UnAnalyzedSelection = {
      all_system_except: null,
      all_user_except: [],
      exprs: [{
        expr: expandedExpr,
        name: {
          name: AUTOALIAS,
          position: NoPosition
        }
      }]
    }

    const groupBys = ast.group_bys;
    const baseQueryGroupBys = (containsAggregate(scope, expandedExpr) && groupBys.length > 0) ? groupBys : [];

    const joins = ast.joins;
    const involvedViewQualifiers: TableQualifier[] = pluckColumnRefs(expandedExpr).map((cref) => { return cref.qualifier })
    const baseQueryJoins = joins.filter(join => {
      if (isJoinByFromTable(join.from)) {
        return involvedViewQualifiers.includes(join.from.from_table.alias) || involvedViewQualifiers.includes(join.from.from_table.name);
      } else if (isJoinBySubSelect(join.from)) {
        return involvedViewQualifiers.includes(join.from.sub_select.alias);
      }
    });

    const baseQueryAst: UnAnalyzedAst = {
      selection: baseQuerySelections,
      hints: [],
      from: null,
      where: null,
      group_bys: baseQueryGroupBys,
      order_bys: [],
      joins: baseQueryJoins,
      search: null,
      having: null,
      distinct: 'indistinct',
      limit: null,
      offset: null
  }

  const baseQueryTree = replaceLastInChain(tree, baseQueryAst);

  return new Promise((resolve, reject) => {
    // ref is deprecated, but need to remove it from dsmapi first
    const ref = '';
    channel.push('compile_ast', {ast: baseQueryTree, ref, pageable: false})
      .receive('ok', (res: CompilerResponse) => {
        const baseQuery = res.rendered;
        soqlDataProvider.searchInColumn({
          columnName: AUTOALIAS,
          filters: [],
          limit: AUTOCOMPLETE_LIMIT,
          isLazyLoading,
          searchTerm,
          offset,
          startingQuery: some(baseQuery)
        })
        .then((suggestions: string[]) => {
          const results = _.chain(suggestions).
            map((suggestion) => ({ title: suggestion, matches: [] })).
            compact().
            take(AUTOCOMPLETE_LIMIT).
            value() as Suggestion[]
          resolve({ results });
        })
      })
      .receive('error', (e) => reject(e))
  }).catch(e => console.log(e)) as Promise<SuggestionResults>
}

// the UX leads you down the path of making a join condition in the shape of
// base_dataset.column >boolean returning function< joined_dataset.column
// this function detects whether a given join clause matches that shape
// USED FOR SPECIAL SNOWFLAKE NICE UX BEHAVIOR CHANGES <3
export function hasDefaultJoinConditionShape(unAnalyzedJoin?: UnAnalyzedJoin) {
  if (!unAnalyzedJoin) return false;
  if (!isJoinByFromTable(unAnalyzedJoin.from)) return false;
  const { on } = unAnalyzedJoin;

  return isFunCall(on) &&
    on.args.length === 2 &&
    isColumnRef(on.args[0]) &&
    on.args[0].qualifier === null &&
    isColumnRef(on.args[1]) &&
    on.args[1].qualifier !== null;
}
