import React from 'react';
import type { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';

export enum VARIANTS {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
  PRIMARY = 'primary',
  ALTERNATE_2 = 'alternate-2',
  ALTERNATE_3 = 'alternate-3',
  SIMPLE = 'simple',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  LINK = 'link'
}

export enum SIZES {
  LARGE = 'lg',
  DEFAULT = 'default',
  SMALL = 'sm',
  X_SMALL = 'xs'
}

interface CustomButtonProps {
  /**
   * Replaces the content with a spinner.
   * Note that the width of the button is not fixed - the spinner
   * is probably smaller than your original content. Perhaps
   * we want to make the button preserve its width in the future.
   *
   * Default: false
   */
  busy?: boolean;

  /**
   * Optional additional class to apply when the button is disabled.
   * The class applied is:
   * btn-${buttonDisabledStyle}
   */
  buttonDisabledStyle?: string;

  /**
   * If present, the button becomes an anchor tag with the provided
   * href. If the prop is blank, the button is an actual button.
   */
  href?: string;

  /**
   * Controls the visual size of the button.
   *
   * Default: SIZES.DEFAULT
   */
  size?: SIZES;

  /**
   * Controls how the variant colors are assigned to the button.
   * false: Solid colored background.
   * true: Light background.
   *
   * Default: false
   */
  inverse?: boolean;

  /**
   * Applies a theme suitable for darker backgrounds. Takes precedence over `inverse`.
   *
   * Default: false
   */
  dark?: boolean;

  /**
   * Applies a preset visual style to the button.
   *
   * Default: VARIANTS.DEFAULT
   */
  variant?: VARIANTS;

  /** Whether or not this button is transparent */
  transparent?: boolean;
}

// merge our custom button props with the usual HTML button props (we pass these down to the actual button)
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & CustomButtonProps;

// Forward ref so folks can directly access the underlying <button> or <a>.
// https://reactjs.org/docs/forwarding-refs.html
// https://www.selbekk.io/blog/2020/05/forwarding-refs-in-typescript/
type ButtonRefType = HTMLButtonElement & HTMLAnchorElement;

const Button = React.forwardRef<ButtonRefType, PropsWithChildren<ButtonProps>>(({
  busy,
  buttonDisabledStyle,
  children,
  className,
  dark,
  disabled,
  inverse,
  onClick,
  size,
  transparent,
  variant,
  title,
  href,
  ...restOfProps
}, ref) => {
  const onClickFiltered = function(...args: any) {
    // Note that the inner <button> automatically handles the disabled case.
    if (!busy && onClick) {
      onClick.apply(this, args);
    }
  };

  const sizeClass = size === SIZES.DEFAULT ? null : `btn-${size}`;
  const disabledStyle = buttonDisabledStyle && disabled ? `btn-disabled-${buttonDisabledStyle}` : null;

  const classes = classNames('btn', `btn-${variant}`, sizeClass, className, disabledStyle, {
    'btn-dark': dark === true,
    'btn-inverse': inverse === true && !dark,
    'btn-transparent': transparent === true,
    'btn-busy': busy === true
  });

  const spinnerClasses = classNames('spinner-default', `spinner-btn-${variant}`, {
    'spinner-dark': dark === true
  });

  // types are a little funny here; since this can render either an <a> or a <button> the props we
  // pass are, like, "almost a button, almost an anchor tag"
  // "href" is only valid for the anchor tag but does not exist on an HTML button...
  // (we should really have a separate component for anchor tags like this)
  const innerProps: ButtonHTMLAttributes<HTMLButtonElement> & { href?: string; ref: typeof ref } = {
    type: 'button',
    disabled,
    className: classes,
    onClick: onClickFiltered,
    title,
    href,
    ref,
    ...restOfProps
  };

  return (
    <span className="btn-wrapper">
      {
        // Dropping out of JSX for this because there's no easy way to switch between <a> and <button>
        // in JSX (since these two are tags, not components).
        React.createElement(
          href ? 'a' : 'button',
          innerProps,
          <div className="btn-content" style={{visibility: busy ? 'hidden' : 'visible'}}>
            {children}
          </div>,
          busy && (
            <div className="spinner-container">
              <span className={spinnerClasses} />
            </div>
          )
        )
      }
    </span>
  );
});

Button.defaultProps = {
  busy: false,
  dark: false,
  disabled: false,
  inverse: false,
  size: SIZES.DEFAULT,
  transparent: false,
  variant: VARIANTS.DEFAULT
};

Button.displayName = 'Button'; // forwardRef alters the name.

export default Button;
