import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SocrataIcon from 'common/components/SocrataIcon';
import Button from 'common/components/Button';
import { LAYER_TOGGLING_THROTTLE_WAIT, MAP_EVENTS } from 'common/visualizations/views/mapConstants';

// When we hit enter on the toggle map layer button to turn off a map layer,
// the screen reader picks up the button label even before React changes the state of the button,
// so it reads the old state label, i.e button pressed instead of button not pressed.
// This is because of the delay in the state change of the component
// which only happens after the store is updated.
// So, to avoid this delay, for the click/enter event alone we update the
// component state internally while updating the store.
// In the AX when we reorder/rename a map layer, the existing control is removed and a new one is created.
export class LayerToggleSelector extends Component {
  constructor(props) {
    super(props);

   this.state = {
      isMediumView: false,
      layers: props.layers
    };
  }

  onClick = _.throttle((options) => {
    if (_.isEmpty(this._existingLayerOptions)) {
      this.setNewToggleLayers(options);
    }
    this._existingLayerOptions = null;
  }, LAYER_TOGGLING_THROTTLE_WAIT, { leading: true });

  setNewToggleLayers = (options) => {
    const { layers } = this.state;
    const newLayers = _.cloneDeep(layers);
    newLayers[options.relativeIndex].visible = options.visible;
    this.setState({ layers: newLayers });
    this.props.map.fire(MAP_EVENTS.TOGGLE_MAP_LAYER, options);
  };

  onLayerKeyDown = (event, options) => {
    this._existingLayerOptions = options;
    if (event.key === 'Enter') {
      this.setNewToggleLayers(options);
    }
  };

  onToggleButtonClick = () => {
    this.setState({ isMediumView: !this.state.isMediumView });
  };

  renderToggleLayer = (layer) => {
    const { name, visible, index, layerNameWithIndex } = layer;
    if (_.isEmpty(name)) {
      return null;
    }
    const { isMediumView } = this.state;
    // These are the options passed into UnifiedMap._emitToggleMapLayer
    const options = { relativeIndex: index, visible: !visible };
    const layerClasses = classNames('layer-item',
      { 'active': visible },
      { 'layer-show': isMediumView }
    );
    const layerAttributes = {
      id: index,
      'aria-label': name,
      'aria-pressed': visible,
      title: name,
      variant: 'primary',
      className: layerClasses,
      key: layerNameWithIndex,
      onClick: () => this.onClick(options),
      onKeyDown: (event) => this.onLayerKeyDown(event, options)
    };

    return (<Button {...layerAttributes}>{name}</Button>);
  };

  renderToggleIcon() {
    const toggleButtonAttributes = {
      className: 'mobile-view-btn toggle-button',
      key: 'mobile-view-button',
      onClick: this.onToggleButtonClick
    };

    return (
      <div {...toggleButtonAttributes}>
        <span className="layers-icon">
          <SocrataIcon name="layers" />
        </span>
      </div>
    );
  }

  render() {
    const { layers } = this.state;
    const toggleLayersContent = _.chain(layers).map(this.renderToggleLayer).value();

    return (
      <div>
        {this.renderToggleIcon()}
        {toggleLayersContent}
      </div>
    );
  }
}

LayerToggleSelector.propTypes = {
  map: PropTypes.object,
  layers: PropTypes.array
};

LayerToggleSelector.defaultProps = {
  layers: []
};

export default LayerToggleSelector;
