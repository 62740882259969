import { cloneDeep, forEach, get, set, unset } from 'lodash';
import { Vif } from 'common/visualizations/vif';
import { QUANTIFICATION_METHODS } from 'common/authoring_workflow/constants';
import { isMapVisualization } from 'common/visualizations/helpers/VifSelectors';

export function migrateVif4ToVif5(vifToMigrate: any): Vif {
  const migratedVif = defaultQuantificationMethodForCustomPalettes(vifToMigrate);
  migratedVif.format.version = 5;
  return migratedVif;
}

function defaultQuantificationMethodForCustomPalettes(vif: Vif) {
  const modifiedVif = cloneDeep(vif);

  if (!isMapVisualization(modifiedVif)) {
    return modifiedVif;
  }

  const series = get(modifiedVif, 'series');
  const modifiedSeries = series.map((seriesItem) => {
    let quantificationMethod = get(seriesItem, 'mapOptions.colorByQuantificationMethod');
    const quantificationArray = [QUANTIFICATION_METHODS.linear.value, QUANTIFICATION_METHODS.category.value];
    const colorBoundariesBy = get(seriesItem, 'mapOptions.colorBoundariesBy');

    if (!(quantificationArray.includes(quantificationMethod))) {
      // We would like to ensure that quantification method is _always_ set,
      // so we can guarantee the shape of color palettes.
      quantificationMethod = QUANTIFICATION_METHODS.category.value;
      set(seriesItem, 'mapOptions.colorByQuantificationMethod', QUANTIFICATION_METHODS.category.value);
    }
    const customPalette = seriesItem.color?.customPalette ?? {};

    forEach(customPalette, (colorPalette, columnName) => {
      // !!! We need to somehow check if the column is a non-numeric column. If its a non-numerical column, we should default to category
      // Currently we don't have access to this information in the vif, so we can't do this check.
      // Calling metadb for this information every time this migration runs is going to be slow
      // Is there an alternative?

      if (colorPalette.hasOwnProperty(QUANTIFICATION_METHODS.linear.value) || colorPalette.hasOwnProperty(QUANTIFICATION_METHODS.category.value)) {
        // The color palette is already stored with a quantification method
        return;
      }

      if (quantificationMethod === QUANTIFICATION_METHODS.linear.value && colorBoundariesBy === columnName) {
        const modifiedCustomPalette = cloneDeep(colorPalette);
        unset(seriesItem, `color.customPalette.${columnName}`);
        set(seriesItem, `color.customPalette.${columnName}.${quantificationMethod}`, modifiedCustomPalette);
      } else if (quantificationMethod === QUANTIFICATION_METHODS.linear.value && colorBoundariesBy !== columnName && colorPalette.hasOwnProperty(QUANTIFICATION_METHODS.linear.value)) {
        const modifiedCustomPalette = cloneDeep(colorPalette[columnName][QUANTIFICATION_METHODS.linear.value]);
        unset(seriesItem, `color.customPalette.${columnName}`);
        set(seriesItem, `color.customPalette.${columnName}.${QUANTIFICATION_METHODS.category.value}`, modifiedCustomPalette);
      } else {
        const modifiedCustomPalette = cloneDeep(colorPalette);
        unset(seriesItem, `color.customPalette.${columnName}`);
        set(seriesItem, `color.customPalette.${columnName}.${QUANTIFICATION_METHODS.category.value}`, modifiedCustomPalette);
      }
    });

    return seriesItem;
  });

  set(modifiedVif, 'series', modifiedSeries);

  return modifiedVif;
}
