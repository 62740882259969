import {
  ComponentProps,
  ClassicVisualizationProps,
  EmbeddedHTMLProps,
  FeatureMapProps,
  GlobalFilterProps,
  HeroProps,
  HTMLProps,
  InSituVisualizationProps,
  MapProps,
  MeasureProps,
  RegionMapProps,
  StoryTileProps,
  TableProps,
  VisualizationCanvasProps
} from 'lib/components/block-component-renderers/types';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';

import { ComponentType } from 'types';

export interface RenderStoryComponentArgs {
  $element: JQuery<HTMLElement>;
  elementProps:
    | ComponentProps
    | ClassicVisualizationProps
    | EmbeddedHTMLProps
    | FeatureMapProps
    | GlobalFilterProps
    | HeroProps
    | HTMLProps
    | InSituVisualizationProps
    | MeasureProps
    | MapProps
    | RegionMapProps
    | StoryTileProps
    | TableProps
    | VisualizationCanvasProps;
}

export const renderStoryComponent = (args: RenderStoryComponentArgs) => {
  const { $element, elementProps } = args;

  switch (elementProps.componentData.type) {
    case ComponentType.HERO:
      $element.componentHero(elementProps as HeroProps);
      break;

    case ComponentType.STORY_TILE:
    case ComponentType.STORY_WIDGET:
      $element.componentStoryTile(elementProps as StoryTileProps);
      break;

    case ComponentType.MEASURE_CARD:
    case ComponentType.MEASURE_CHART:
      $element.componentMeasure(elementProps as MeasureProps);
      break;

    case ComponentType.CLASSIC_VISUALIZATION:
      $element.componentSocrataVisualizationClassic(elementProps as ClassicVisualizationProps);
      break;

    case ComponentType.REGION_MAP:
    case ComponentType.CHOROPLETH_MAP:
      $element.componentSocrataVisualizationRegionMap(elementProps as RegionMapProps);
      break;

    case ComponentType.BAR_CHART:
    case ComponentType.CALENDAR:
    case ComponentType.COLUMN_CHART:
    case ComponentType.COMBO_CHART:
    case ComponentType.HISTOGRAM:
    case ComponentType.SCATTER_CHART:
    case ComponentType.PIE_CHART:
    case ComponentType.TIMELINE_CHART:
      $element.componentSocrataVisualizationInSitu(elementProps as InSituVisualizationProps);
      break;

    case ComponentType.AG_TABLE:
    case ComponentType.TABLE:
      $element.componentSocrataVisualizationTable(elementProps as TableProps);
      break;

    case ComponentType.FEATURE_MAP:
      $element.componentSocrataVisualizationFeatureMap(elementProps as FeatureMapProps);
      break;

    case ComponentType.MAP:
      $element.componentSocrataVisualizationMap(elementProps as MapProps);
      break;

    case ComponentType.VIZ_CANVAS:
      $element.componentSocrataVisualizationVizCanvas(elementProps as VisualizationCanvasProps);
      break;

    case ComponentType.EMBEDDED_HTML:
      $element.componentEmbeddedHtml(elementProps as EmbeddedHTMLProps);
      break;

    case ComponentType.IMAGE:
      $element.componentImage(elementProps);
      break;

    case ComponentType.GLOBAL_FILTER:
      $element.componentGlobalFilter(elementProps as GlobalFilterProps);
      break;

    case ComponentType.HTML:
      // For flexible layouts, we render componentHTML (aka text components)
      // through the jquery plugin instead of Rails.
      if (isFlexibleStory()) {
        $element.componentHTML(elementProps as HTMLProps);
      }
      break;

    default:
      $element.componentBase(elementProps);
      break;
  }
};
