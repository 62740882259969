import React, { FunctionComponent } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';

import type { View } from 'common/types/view';

import I18n from 'common/i18n';
import airbrake from 'common/airbrake';
import ceteraUtils from 'common/cetera/utils';
import Button, { VARIANTS } from 'common/components/Button';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/AccessibleModal';
import {
  deleteView,
  deleteRevision,
  deleteStoryDraft,
  fetchViewsThatNeedToBeDeletedToDeleteView
} from 'common/views/delete_helpers';
import { isDataset } from 'common/views/view_types';
import { assetIsDraft } from 'common/views/helpers';

import DeleteAssetModalContent from './DeleteAssetModalContent';

import './index.scss';

export const translationScope = 'shared.asset_browser.result_list_table.action_modal.delete_asset';

export interface DeleteAssetModalProps {
  /** View to delete */
  view: View;

  /**
   * DSMAPI revision sequence.
   * If this is provided, then the revision is what will be deleted by this modal.
   * If this is NOT provided, and `assetIsStoryDraft` is `false`, then the base view is what is deleted.
   */
  revisionSeq?: number;

  /**
   * Whether or not we're deleting a story draft.
   * If this is `true`, then instead of deleting the view from core we hit storyteller to tell it to delete the draft.
   */
  assetIsStoryDraft: boolean;

  /**
   * Called when the user clicks Cancel (or presses ESC, or clicks outside modal).
   */
  onDismiss: () => any;

  /**
   * When an asset was deleted, we call back on this.
   * It's up to the user of the component to decide
   * what to do.
   */
  onDeleted: () => any;
}

/**
 * If the asset is a dataset (`viewType` is `tabular` and `displayType` is `table`)
 * then we hit the catalog to see how many assets are derived off of it that will ALSO
 * be deleted when it is deleted...
 *
 * Note that this is not an exact science. Some assets, like stories, embed datasets but
 * the catalog is not aware of this. The stories themselves will still work, but the embedded
 * datasets will not!
 *
 * @param view View to get derived view count for
 * @param revisionSeq Revision sequence... if this is present, then this function will return undefined
 */
export const fetchNumberOfDerivedViews = async (
  view: View,
  revisionSeq?: number
): Promise<number | undefined> => {
  // if we're looking at something that's not a dataset or something that's a draft,
  // don't fetch this number... this number is only for warning when children are also going to be deleted,
  // which currently doesn't actually happen for non-dataset assets and for draft-like assets
  if (!isDataset(view) || assetIsDraft({ coreView: view, isRevision: revisionSeq !== undefined })) {
    return Promise.resolve(undefined);
  }

  const childAssetsResponse = await ceteraUtils.query({ derivedFrom: view.id });

  if (childAssetsResponse?.resultSetSize === undefined) {
    airbrake.notify({
      error: `DeleteAssetModal could not find get assets derived from ${view.id}`,
      context: { component: 'DeleteAssetModal' }
    });
    return Promise.reject(undefined);
  }

  return Promise.resolve(childAssetsResponse.resultSetSize);
};

/**
 * Delete an asset (a view in core), a revision (a draft-like asset in DSMAPI), or a story draft (in storyteller)
 *
 * @param id UID (4x4) of the asset to delete
 * @param revisionSeq Revision sequence... if this is present, then DSMAPI will be hit to delete the revision
 * @param assetIsStoryDraft Whether or not this is a story draft... if this is true, then storyteller will be hit to delete the draft
 */
export const deleteAsset = async (id: string, revisionSeq?: number, assetIsStoryDraft?: boolean) => {
  try {
    // revisionSeq can be 0 which will evaluate to false :)
    if (revisionSeq !== undefined) {
      await deleteRevision(id, revisionSeq);
    } else if (assetIsStoryDraft) {
      await deleteStoryDraft(id);
    } else {
      await deleteView(id);
    }
  } catch (e) {
    airbrake.notify({
      error: `DeleteAssetModal could not delete ${id} (revisionSeq: ${revisionSeq}, assetIsStoryDraft: ${assetIsStoryDraft})`,
      context: { component: 'DeleteAssetModal' }
    });
  }
};

/**
 * This modal does many things.
 *
 * It...
 * - Fetches the number of views that the given view has derived off of it to let the user know how many things they're deleting
 * - Checks if any other views are blocking deletion of the view (i.e. data federated derived views)
 * - Talks to DSMAPI to delete revisions
 * - Talks to storyteller to delete story drafts
 * - Talks to core to delete everything else :)
 */
const DeleteAssetModal: FunctionComponent<DeleteAssetModalProps> = ({
  view,
  revisionSeq,
  assetIsStoryDraft,
  onDismiss,
  onDeleted
}) => {
  // this will fetch the number of derived views that the asset has
  // this is used to warn the user of other assets that will be deleted
  const {
    isLoading: isLoadingDerivedViews,
    isError: isErrorDerivedViews,
    data: derivedViewCount
  } = useQuery(
    `derivedViews-${view.id}-${revisionSeq}-${assetIsStoryDraft}`,
    async () => await fetchNumberOfDerivedViews(view, revisionSeq),
    {
      // keep the value cached for 30 seconds
      staleTime: 30 * 1000
    }
  );

  // this will check if there are any other views that are blocking deletion of this view
  // this is mainly when we are trying to delete a federated asset that has assets based off of it on another domain
  const {
    isLoading: isLoadingViewsBlockingDeletion,
    isError: isErrorViewsBlockingDeletion,
    data: viewsBlockingDeletion
  } = useQuery(
    `viewsBlockingDeletion-${view.id}-${revisionSeq}-${assetIsStoryDraft}`,
    async () => await fetchViewsThatNeedToBeDeletedToDeleteView(view.id, revisionSeq, assetIsStoryDraft),
    {
      // keep the value cached for 30 seconds
      staleTime: 30 * 1000
    }
  );

  // this is the query to _actually_ delete the asset!
  const {
    isLoading: busyDeleting,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    refetch: runDeleteAssetQuery
  } = useQuery(
    `deleteAsset-${view.id}`,
    async () => await deleteAsset(view.id, revisionSeq, assetIsStoryDraft),
    {
      // so that this is run by calling the `refetch` function instead of when the component mounts
      enabled: false,

      // don't retry if this fails, and don't cache the response
      retry: false,
      cacheTime: 0,

      onSuccess: onDeleted
    }
  );

  // busy fetching _some sort_ of data
  // note that we also check `isDeleteSuccess` here so the spinner keeps spinning while the page refreshes... yay!
  const busy = isLoadingDerivedViews || isLoadingViewsBlockingDeletion || busyDeleting || isDeleteSuccess;

  // true if there is _some sort_ of error that happened in one of the async calls this component makes
  const hasError = isErrorDerivedViews || isErrorViewsBlockingDeletion || isDeleteError;

  // whether or not there are views that are blocking deletion
  const hasViewsBlockingDeletion = !!viewsBlockingDeletion?.hasViewsBlockingDeletion;

  const dismissModal = busy ? () => {} : onDismiss;

  const modalHeaderTitle = hasViewsBlockingDeletion
    ? I18n.t(`${translationScope}.delete_blocked_by_child_views.title`)
    : I18n.t(`${translationScope}.title`);

  return (
    <div className="delete-asset">
      <Modal onRequestClose={dismissModal} contentLabel={I18n.t(`${translationScope}.title`)}>
        <ModalHeader title={modalHeaderTitle} onDismiss={dismissModal} />

        <ModalContent>
          <DeleteAssetModalContent
            view={view}
            busy={isLoadingDerivedViews || isLoadingViewsBlockingDeletion}
            hasError={hasError}
            revisionSeq={revisionSeq}
            assetIsStoryDraft={assetIsStoryDraft}
            derivedViewCount={derivedViewCount}
            viewsBlockingDeletion={viewsBlockingDeletion}
          />
        </ModalContent>

        <ModalFooter>
          <Button data-testid="cancel-delete-asset-button" onClick={onDismiss} disabled={busy}>
            {I18n.t(`${translationScope}.cancel`)}
          </Button>
          {!hasError && !hasViewsBlockingDeletion && (
            <Button
              data-testid="delete-asset-button"
              onClick={() => runDeleteAssetQuery()}
              variant={VARIANTS.ERROR}
              busy={busy}
              disabled={busy || hasError}
            >
              {I18n.t(`${translationScope}.delete`)}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

const queryClient = new QueryClient();

const QueryClientWrappedDeleteAsset = (props: DeleteAssetModalProps) => (
  <QueryClientProvider client={queryClient}>
    <DeleteAssetModal {...props} />
  </QueryClientProvider>
);

export default QueryClientWrappedDeleteAsset;
