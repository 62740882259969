import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { userHasAccessLevel } from 'common/components/AccessManager/Util';
import { getAddedUsers } from 'common/components/AccessManager/sagas/Selectors';
import { AccessLevelName, ViewUser } from 'common/types/view';

import OwnerUserDetails from './OwnerUserDetails';
import UserDetailsWithAccessLevel from './UserDetailsWithAccessLevel';

import './user-list.scss';

const classNameScope = 'common--components--AccessManager--components--UserList--user-list';

type UserFilter = (user: ViewUser) => boolean;

interface UserListProps {
  /** Optional filter to apply to the list of users being displayed */
  userFilter?: UserFilter;

  /** Whether or not to hide the asset's owner when displaying the list */
  hideOwner: boolean;

  /** Whether or not to show the access level dropdown */
  hideAccessLevelDropdown?: boolean;

  /** Message to show when there are no users */
  noUsersMessage: string;
}

/**
 * Get the list of users, filtering out the owner.
 * This will also filter out disabled users.
 *
 * @param users List of users
 * @param userFilter Optional filter to apply to user list
 */
const getAllUserExceptOwner = (users: ViewUser[], userFilter?: UserFilter) =>
  users
    .filter((user) => !userHasAccessLevel(user, AccessLevelName.CurrentOwner))
    .filter((user) => !((user as any).flags || []).includes('disabled')) // don't even show disabled users
    .filter(userFilter || (() => true));

/**
 * This will return true if the list of users is empty and we should show a "No users" message.
 * This checks if the owner is being shown or not and filters them out of the list,
 * it also applies the user filter that the component was given.
 *
 * @param users List of users with permissions on the asset
 * @param hideOwner Whether or not to hide the current owner
 * @param userFilter Optional filter to apply to user list
 * @returns Whether or not the no users message should be rendered
 */
const shouldRenderNoUsersMessage = (users: ViewUser[], hideOwner?: boolean, userFilter?: UserFilter) => {
  // render message if the only user we have is the owner, and we're supposed to show the owner
  return (
    isEmpty(getAllUserExceptOwner(users, userFilter)) ||
    (hideOwner === false &&
      isEmpty(users.filter((user) => !userHasAccessLevel(user, AccessLevelName.CurrentOwner))))
  );
};

const renderNoUsersMessage = (noUsersMessage: string) => (
  <div className={`user-list-no-users-message ${classNameScope}--no-users-message`}>
    <em>{noUsersMessage}</em>
  </div>
);

/**
 * Render a special user details for the owner of an asset
 * @param owner Owner of the asset
 */
const renderOwner = (owner: ViewUser) => (
  // current owner can potentially appear in the list twice, so we need to give them a special key
  <div key={`current_owner-${owner.id || owner.email}`}>
    <OwnerUserDetails user={owner} />
    <hr />
  </div>
);

/**
 * Render a specific user
 * @param user User to render
 * @param hideAccessLevelDropdown Whether or not to show the access level dropdown
 */
const renderUser = (user: ViewUser, hideAccessLevelDropdown?: boolean) => {
  const { accessLevels, email, id } = user;

  // just using the first accessLevel in the list for now; if/when we want to add multiple in the future,
  // this will have to be changed (along with the dropdown)
  const accessLevel = accessLevels[0];

  return (
    <div key={id || email}>
      <UserDetailsWithAccessLevel
        user={user}
        accessLevel={accessLevel}
        hideAccessLevelDropdown={hideAccessLevelDropdown || false}
      />
      <hr />
    </div>
  );
};

/**
 * Display a list of all the users who have acess to a dataset,
 * including the owner, along with actions that can be taken on them.
 */
const UserList: FunctionComponent<UserListProps> = ({
  userFilter,
  noUsersMessage,
  hideAccessLevelDropdown = false,
  hideOwner
}) => {
  const users = useSelector(getAddedUsers);
  const owner = users.find((user) => userHasAccessLevel(user, AccessLevelName.CurrentOwner));
  const filteredUsers = getAllUserExceptOwner(users, userFilter);

  return (
    <div className={`${classNameScope}--container`}>
      <hr className={`${classNameScope}--user-list-top-border`} />
      {hideOwner === false && owner && renderOwner(owner)}
      {shouldRenderNoUsersMessage(users, hideOwner)
        ? renderNoUsersMessage(noUsersMessage)
        : filteredUsers.map((user) => renderUser(user, hideAccessLevelDropdown))}
    </div>
  );
};

export default UserList;
