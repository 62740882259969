/* Imports */
/* ============================================================================= */
import { defaultHeaders } from 'common/http/index';
import { WorkflowGuidance } from 'common/types/approvals';

import * as Types from 'common/core/approvals/Types';





/* Method */
/* ============================================================================= */
/**
 * Update an asset's approval status.
 * This can be used to either approve or reject assets.
 *
 * See also: `approveAsset` and `rejectAsset`
 *
 * @param guidance WorkflowGuidance object
 * @param putBody Parameters to use to update an asset's approval status
 * @returns A Promise object
 */
export const updateApprovalV2 = (guidance: WorkflowGuidance, putBody: Types.UpdateApprovalRequestBody) => {
  if (!guidance.updateUrl)
    return Promise.reject('updateUrl not found in WorkflowGuidance');

  const apiPath = guidance.updateUrl;
  const options: RequestInit = {
    body: JSON.stringify(putBody),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetch(apiPath, options);
};

export default updateApprovalV2;
