import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import I18n from 'common/i18n';

import CreateAlertAPI from 'common/components/CreateAlertModal/api/CreateAlertApi';
import MyAlertsApi from 'common/notifications/api/MyAlertsApi';
import MyAlertsInfo from 'common/notifications/components/AlertSettingModal/MyAlerts/MyAlertsInfo';
import AlertShareButton from 'common/notifications/components/AlertSettingModal/MyAlerts/AlertShareButton';
import Spinner from 'common/notifications/components/Spinner';
import Checkbox from 'common/components/Checkbox';
import Button from 'common/components/Button';
import DeleteAlertModal from 'common/components/DeleteAlertModal/DeleteAlertModal';
import ThreeStateCheckbox, { INDETERMINATE } from 'common/components/ThreeStateCheckbox';

import './myAlerts.scss';

const scope = 'shared.site_chrome.notifications.alert_setting_modal.my_alert_tab';
const ALERT_SELECT_STATES = {
  ALL_SELECTED: true,
  NONE_SELECTED: false,
  SOME_SELECTED: INDETERMINATE
};

export class MyAlerts extends Component {

  state = {
    currentSelectedAlert: {},
    isAlertsLoading: false,
    isSelectAllAlerts: false,
    myAlerts: [],
    pauseButtonState: false,
    selectedAlertIds: [],
    showAlertDeletedMessage: false,
    showDeleteAlertModal: false,
    showEmptyMessage: false,
    showErrorMessage: false
  };

  UNSAFE_componentWillMount() {
    this.fetchMyAlerts();
    const { currentSelectedAlert, showAlertDeletedMessage } = this.props;
    this.setState({ currentSelectedAlert, showAlertDeletedMessage });
  }

  changePauseButtonState = () => {
    const { myAlerts, selectedAlertIds } = this.state;
    const currentSelectedAlerts = _.filter(myAlerts, (alert) => _.includes(selectedAlertIds, alert.id));
    const pauseButtonState = _.isEmpty(selectedAlertIds) ? false : _.every(currentSelectedAlerts, ['paused', true]);
    this.setState({ pauseButtonState });
  };

  onDeleteSuccess = () => {
    this.setState({ selectedAlertIds: [], isSelectAllAlerts: false });
    this.fetchMyAlerts();
    this.onDeleteModalClose({ isDeleted: true });
  };

  isSelectedAlert = (alert) => {
    const { selectedAlertIds } = this.state;
    return _.includes(selectedAlertIds, alert.id);
  };

  isSelectedAlertEmpty = () => {
    const { selectedAlertIds } = this.state;
    return _.isEmpty(selectedAlertIds);
  };

  onAlertSelected = (alert, event) => {
    const { myAlerts, selectedAlertIds } = this.state;
    const updatedSelectedAlerts = (() => {
      if (event.target.checked) {
        return [...selectedAlertIds, alert.id];
      } else {
        return _.without(selectedAlertIds, alert.id);
      }
    })();
    this.setState({
      myAlerts,
      selectedAlertIds: updatedSelectedAlerts
    }, () => {
      this.updateSelectAllAlert();
      this.changePauseButtonState();
    });
  };

  onDeleteModalClose = (options) => {
    this.setState({
      showDeleteAlertModal: false,
      showAlertDeletedMessage: _.get(options, 'isDeleted', false)
    });
  };

  onSelectAllAlertsCheckboxChange = (event) => {
    const { myAlerts } = this.state;
    const isSelectAllAlerts = event.target.checked;
    const selectedAlertIds = isSelectAllAlerts ? _.map(myAlerts, 'id') : [];
    this.setState({ isSelectAllAlerts, selectedAlertIds }, () => {
      this.changePauseButtonState();
      this.updateSelectAllAlert();
    });
  };

  onEditAlertClick = (alert) => {
    const { onEditAlertClick } = this.props;

    onEditAlertClick(alert);
    this.setState({ currentSelectedAlert: alert, selectedAlertIds: [alert.id] });
  };

  onDeleteButtonClick = () => {
    this.setState({ showDeleteAlertModal: true });
  };

  onPauseButtonClick = () => {
    const { pauseButtonState, selectedAlertIds } = this.state;
    this.setState({ isAlertsLoading: true });
    CreateAlertAPI.bulkUpdate(selectedAlertIds, { column: 'paused', value: !pauseButtonState }).then(() => {
      this.setState({ showErrorMessage: false, pauseButtonState: !pauseButtonState });
      this.fetchMyAlerts();
    }).
    catch(() => {
      this.setState({ isAlertsLoading: false, showErrorMessage: true });
    });
  };

  fetchMyAlerts = () => {
    let myAlerts;
    let { showEmptyMessage, isAlertsLoading } = this.state;
    this.setState({ isAlertsLoading: true });
    MyAlertsApi.get().then((response) => {
      myAlerts = response;
      showEmptyMessage = _.isEmpty(response);
      isAlertsLoading = false;
      this.setState({ myAlerts, showEmptyMessage, isAlertsLoading });
    }).
    catch(() => {
      this.setState({ isAlertsLoading: false });
    });
  };

  formatDatasetName = (datasetName) => {
    // sometimes datasetName may be null
    let name = (datasetName || '');
    name = name.replace(/[\W]/g, '-').replace(/\-+/g, '-');

    if (name.length < 1) {
      name = '-';
    }
    return name.slice(0, 50);
  };

  domainUrl = (alert) => {
    const domainName = _.get(alert, 'domain', '');
    const datasetId = _.get(alert, 'datasetUid', '');
    const datasetName = _.get(alert, 'datasetName', '');

    return `//${domainName}/dataset/${this.formatDatasetName(datasetName)}/${datasetId}`;
  };

  updateSelectAllAlert = () => {
    const { myAlerts, selectedAlertIds } = this.state;
    const allAlertIdsSorted = _.map(myAlerts, 'id').sort();
    const selectedAlertIdsSorted = selectedAlertIds.sort();
    const isSelectAllAlerts = (() => {
      if (_.isEmpty(selectedAlertIdsSorted)) {
        return ALERT_SELECT_STATES.NONE_SELECTED;
      } else if (_.isEqual(allAlertIdsSorted, selectedAlertIdsSorted)) {
        return ALERT_SELECT_STATES.ALL_SELECTED;
      } else {
        return ALERT_SELECT_STATES.SOME_SELECTED;
      }
    })();
    this.setState({ isSelectAllAlerts });
  };

  renderDeleteAlertModal() {
    const { showDeleteAlertModal, selectedAlertIds } = this.state;

    if (showDeleteAlertModal) {
      return (
        <DeleteAlertModal
          onCancel={this.onDeleteModalClose}
          onDismiss={this.onDeleteModalClose}
          onDeleteSuccess={this.onDeleteSuccess}
          selectedAlertIds={selectedAlertIds} />
      );
    }

    return null;
  }

  renderPauseButton() {
    const pauseText = this.state.pauseButtonState ? I18n.t('unpause', { scope }) : I18n.t('pause', { scope });

    return (
      <Button
        className="pause-button"
        disabled={this.isSelectedAlertEmpty()}
        onClick={this.onPauseButtonClick}
        variant="primary"
        title="Pause">{pauseText}
      </Button>
    );
  }

  renderShareButton(alert) {
    const { haveOtherDatasetEditRights, onShareAlertClick } = this.props;

    if (haveOtherDatasetEditRights) {
      return (
        <AlertShareButton alert={alert} onShareAlertClick={onShareAlertClick} />
      );
    }

    return null;
  }

  renderMyAlertList() {
    const { myAlerts } = this.state;

    return myAlerts.map((alert) => {
      const alertId = _.uniqueId();
      const myAlertClassNames = classNames({
        'my-alerts-paused': alert.paused
      });

      return (
        <tr className={myAlertClassNames} key={alert.id}>
          <td>
            <Checkbox
              checked={this.isSelectedAlert(alert)}
              id={alertId}
              onChange={(e) => {
                this.onAlertSelected(alert, e);
              }} />
          </td>
          <td>
            <div className="alert-container paused">
              {(alert.paused) &&
                <div className="paused-title">Paused</div>
              }
              <div className="alert-name">{alert.name}</div>
              {this.renderShareButton(alert)}
              <div className="alert-detail clearfix">
                <a
                  className="alert-dataset"
                  href={this.domainUrl(alert)}
                  target="_blank" rel="noreferrer">
                  {alert.datasetName}
                </a>
              </div>
              <div className="alert-detail clearfix">
                <a
                  className="alert-query"
                  href="#"
                  onClick={() => this.onEditAlertClick(alert)}>
                  {alert.queryString}
                </a>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  }

  renderInfo() {
    const { currentSelectedAlert, showAlertDeletedMessage, showEmptyMessage, showErrorMessage } = this.state;
    const { showAlertSharedMessage } = this.props;

    return (
      <MyAlertsInfo
        alert={currentSelectedAlert}
        showDeleteMessage={showAlertDeletedMessage}
        showEmptyMessage={showEmptyMessage}
        showErrorMessage={showErrorMessage}
        showShareMessage={showAlertSharedMessage} />
    );
  }

  renderAlertsHeader() {
    const { isSelectAllAlerts } = this.state;

    return (
      <form>
        <div className="table-wrapper">
          <table className="myalert-table">
            <thead>
              <tr>
                <th>
                  <ThreeStateCheckbox
                    checked={isSelectAllAlerts}
                    className="notification-alerts-checkbox icon-minus"
                    id="select all"
                    onChange={(e) => this.onSelectAllAlertsCheckboxChange(e)} />
                </th>
                <th>
                  <div className="pull-right">
                    {this.renderPauseButton()}

                    <Button
                      className="delete-button"
                      disabled={this.isSelectedAlertEmpty()}
                      onClick={() => this.onDeleteButtonClick()}
                      variant="default"
                      title="Delete">{I18n.t('delete', { scope })}
                    </Button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
            {this.renderMyAlertList()}
            </tbody>
          </table>
        </div>
        <span className="myalert-footer">{I18n.t('alert_create_info', { scope })}</span>
      </form>);
  }

  render() {
    const { isAlertsLoading, myAlerts } = this.state;

    return (
      <div className="my-alerts-content">
        {isAlertsLoading && <Spinner />}
        {this.renderInfo()}
        {_.isEmpty(myAlerts) || this.renderAlertsHeader()}
        {this.renderDeleteAlertModal()}
      </div>
    );
  }
}

MyAlerts.defaultProps = {
  showAlertDeletedMessage: false
};

MyAlerts.propTypes = {
  currentSelectedAlert: PropTypes.object,
  haveOtherDatasetEditRights: PropTypes.bool,
  onEditAlertClick: PropTypes.func.isRequired,
  showAlertDeletedMessage: PropTypes.bool,
  showAlertSharedMessage: PropTypes.bool
};

const mapStateToProps = state => ({
  haveOtherDatasetEditRights: state.configurations.haveOtherDatasetEditRights
});

export default connect(mapStateToProps)(MyAlerts);
