import _ from 'lodash';
import $ from 'jquery';
import I18n from 'common/i18n';

import {
  formatDatasetValue,
  getColumnFormatWithPrecision
 } from 'common/visualizations/helpers/ColumnFormattingHelpers';
import { FLYOUT_COLUMN_AND_AGGREGATION_ALIAS } from 'common/visualizations/helpers/RenderByHelper';
import { getDefaultColor } from 'common/visualizations/views/map/vifOverlays/partials/Regions';


// Sample region map Feature: (Geojson object got from mapbox-gl map)
// {
//  "type": "Feature",
//  "geometry": {
//    "type": "Polygon",
//    "coordinates": [[[-67.13734351262877, 45.137451890638886],
//    [-66.96466, 44.8097],
//    [-68.03252, 44.3252],
//    [-70.64573401557249, 43.090083319667144],
//    [-67.13734351262877, 45.137451890638886]]]
//  },
//  "properties": {
//    "_feature_id": "36",
//    "zip": "60618"
//  },
//  "layer": { ... }
// }

// Builds html tipsy content for a region map.
export function setPopupContentForRegions(popupParams = {}) {
  const { element, features, renderOptions, vifs } = popupParams;
  // When mouse is hovered over a point where multiple shapes are there.
  // We only show the popup for the topmost shape which is visible to the user. So taking just the
  // first feature/vif/renderOption and ignoring the rest.
  // (NOTE: This is not the same case for points).
  const properties = _.get(features[0], 'properties', {});
  let geometryLabelValue = '';

  if (!_.isNull(vifs[0].getShapeGeometryLabelColumn())) {
    geometryLabelValue = properties[vifs[0].getShapeGeometryLabelColumn()];
  }

  const regionPopupHtml = '<div class="point-map-popup point-popup">' +
    `<div class="popup-title">${geometryLabelValue}</div>` +
    getMeasureColumnValue(vifs[0], renderOptions[0], properties) +
    getRegionMapFlyoutDetails(vifs[0], renderOptions[0], properties) +
  '</div>';

  $(element).html(regionPopupHtml);
  return regionPopupHtml;
}

// Builds html content for measure value ->count breakdown for the given shape feature.
function getMeasureColumnValue(vif, renderOptions, shapeProperties) {
  if (_.isNil(renderOptions.measures)) {
    return '';
  }

  const measureColumnName = vif.getMeasureColumn();
  const measureValue = getShapeDetails(renderOptions, shapeProperties, 'value');
  let measureColor = getShapeDetails(renderOptions, shapeProperties, 'color');
  const defaultColor = getDefaultColor(renderOptions.buckets);
  const { measureUnits, formattedMeasureValue, measureName } = getFormattedMeasureDetails(
    measureValue,
    vif,
    renderOptions,
    measureColumnName
  );
  measureColor = _.isNil(measureColor) ? defaultColor : measureColor;
  const measureNameNotPresent = (measureName) => _.isNil(measureName) || measureName === '*';

  return (
    '<table class="mapboxgl-popup-table">' +
      '<tr class="mapboxgl-popup-row">' +
        '<td class="mapboxgl-popup-cell color-column">' +
          `<span class="indicator" style="background-color: ${measureColor}"></span>` +
        '</td>' +
        '<td class="mapboxgl-popup-cell">' +
          '<span class="category mapboxgl-overflow">' +
            (measureNameNotPresent(measureName) ? '' : measureName) +
          '</span>' +
        '</td>' +
        '<td class="mapboxgl-popup-cell">' +
          `<span class="count mapboxgl-overflow">${formattedMeasureValue} ${measureUnits}</span>` +
        '</td>' +
      '</tr>' +
    '</table>'
  );
}

function getRegionMapFlyoutDetails(vif, renderOptions, shapeProperties) {
  const regionMapFlyoutColumnAndAggregations = vif.getRegionMapFlyoutColumnAndAggregations();

  if (_.isEmpty(regionMapFlyoutColumnAndAggregations)) {
    return '';
  }

  const tbodyContent = _.map(regionMapFlyoutColumnAndAggregations, (columnAndAggregation, index) => {
    const { column, aggregation } = columnAndAggregation;
    const measureValue = getShapeDetails(
      renderOptions,
      shapeProperties,
      FLYOUT_COLUMN_AND_AGGREGATION_ALIAS + index
    );
    const { measureUnits, formattedMeasureValue, measureName } = getFormattedMeasureDetails(
      measureValue,
      vif,
      renderOptions,
      column
    );

    return (
      '<tr class="mapboxgl-flyout-row">' +
        `<td class="mapboxgl-flyout-cell">${getMeasureName(measureName)}</td>` +
        `<td class="mapboxgl-flyout-cell">${formattedMeasureValue}</td>` +
      '</tr>'
    );

  }).join('');

  return `<table class="mapboxgl-flyout-measures-table">${tbodyContent}</table>`;
}

function getShapeDetails(renderOptions, shapeProperties, type) {
  const shapeDetails = type === 'color' ? renderOptions.shapeColorConfigs : renderOptions.measures;

  return _.chain(shapeDetails).
    find((shapeConfig) => shapeConfig.shapeId === shapeProperties[renderOptions.shapePrimaryKey]).
    result(type).
    value();
}

function getFormattedMeasureDetails(measureValue, vif, renderOptions, measureColumnName) {
  let measureUnits;
  let formattedMeasureValue;
  const columnDetails = _.find(renderOptions.datasetMetadata.columns, ['fieldName', measureColumnName]);
  const measureName = _.get(columnDetails, 'name');

  if (_.isNil(measureValue)) {
    measureUnits = '';
    formattedMeasureValue = I18n.t('shared.visualizations.charts.common.no_value');
  } else {
    const mapFlyoutPrecision = vif.getMapFlyoutPrecision();
    const columnFormatWithPrecision = getColumnFormatWithPrecision(columnDetails, mapFlyoutPrecision);
    measureUnits = vif.getUnits(measureValue, vif.getMeasureAggregation());
    formattedMeasureValue = formatDatasetValue(
      measureValue || 0,
      columnFormatWithPrecision,
      vif.getDomain(),
      vif.getDatasetUid()
    );
  }

  return { measureUnits, formattedMeasureValue, measureName };
}

function getMeasureName(measureName) {
  return _.isNil(measureName) || measureName === '*' ?
    I18n.translate('shared.visualizations.panes.data.fields.measure.no_value') :
    measureName;
}
