import { connect } from 'react-redux';
import { Parameter, SoQLType, TypedSoQLParameter } from 'common/types/soql';
import { AppState } from '../redux/store';
import React from 'react';
import { Option, option } from 'ts-option';
import { View } from 'common/types/view';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { clientContextVariableToParameter } from 'common/core/client_context_variables';
import Picker, { Pickable, PickerSelectedValueFormatting } from './Picker';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';
import { Either } from 'common/either';
import { whichAnalyzer } from '../lib/feature-flag-helpers';

interface StateProps {
  view: View;
}

export interface Props {
  className?: string;
  /* Available parameters for this view. */
  parameters: ClientContextVariable[];
  /* Restrict pickable parameters shown in dropdown by soql type.
   * Only parameters that adhere to soql types in the
   * array will be shown to users. Undefined or empty means
   * that there are no restrictions. */
  soqlTypeConstraints?: SoQLType[];
  /* Show param picker with this prompt when nothing is selected. */
  prompt: string;
  /* Show param picker with this object when a selection has been made. */
  selected: Option<Parameter>;
  onSelect: (picked: Either<Parameter, TypedSoQLParameter>) => void;
  /* Will cause the picker autocomplete to be open. This is to allow for testing
  * since currently testing an async autocomplete is pretty impossible, maybe with forge 3
  * so I know this sucks but I would rather be able to test this component than not */
  autoCompleteOpen?: boolean;
}

type ParameterPickerProps = StateProps & Props;

interface State {
  showPicker: boolean;
  filter: Option<string>;
}

class ParameterPicker extends React.Component<ParameterPickerProps, State> {
  render() {
    const { parameters, className, soqlTypeConstraints, prompt, autoCompleteOpen } = this.props;

    const options: Pickable[] = parameters
      .map(ccv => {
        return {
          name: ccv.displayName,
          dataType: ccv.dataType,
          onSelect: () => {
            const param = clientContextVariableToParameter(ccv);
            const paramEither = whichAnalyzer<Parameter, TypedSoQLParameter>(
              () => param,
              () => ({...param, soql_type: ccv.dataType})
            )();
            this.props.onSelect(paramEither);
          }
        };
      });

    const formatSelected: Option<PickerSelectedValueFormatting> = this.props.selected.flatMap(param => {
      return option(parameters.find(ccv => (ccv.name === param.name))).map(selectedCcv => {
        return {
          label: selectedCcv.displayName,
          leadingIconName: getForgeIconNameForDataType(selectedCcv.dataType)
        };
      });
    });

    return (
      <Picker
        className={className}
        soqlTypeConstraints={soqlTypeConstraints}
        prompt={prompt}
        pickables={options}
        formatSelected={formatSelected}
        autoCompleteOpen={autoCompleteOpen} // for testing only
      />
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    view: state.view
  } as StateProps;
};

export default connect(mapStateToProps)(ParameterPicker);
