import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { AudienceScope, View } from 'common/types/view';
import I18n from 'common/i18n';

import {
  internalSharingEnabled,
  viewIsVizCanWithNonPublicDataSource,
  getParentScope
} from 'common/components/AccessManager/Util';
import { getApprovalOutcomeMessages, getCurrentScope } from 'common/components/AccessManager/sagas/Selectors';

import { classNameScope } from './index';
import ManagePublishedTo from './ManagePublishedTo';
import { ApprovalOutcomeMessages } from '../../types';
import { mapPermissionScopeToTargetAudience } from 'common/core/approvals/index_new';

interface BottomSectionProps {
  view: Partial<View>;

  visualizationCanvasHasPublicDataSource?: boolean;
}

const getClassNames = (outcomes: ApprovalOutcomeMessages, proposedScope: AudienceScope | null): string => {
  let response = `${classNameScope}--warning-message`;

  if (proposedScope && proposedScope !== AudienceScope.Private) {
    // Only make it visible if we are showing a message
    if (outcomes.showApprovalMessage) {
      response = response + ' info';
    } else if (outcomes.showRejectedMessage) {
      response = response + ' error';
    } else {
      response = response + '-placeholder';
    }
  } else {
    response = response + '-placeholder';
  }

  return response;
};

const getText = (outcomes: ApprovalOutcomeMessages, proposedScope: AudienceScope | null): string => {
  let response = '';
  const scope = 'shared.site_chrome.access_manager.audience';

  if (proposedScope && proposedScope !== AudienceScope.Private) {
    if (outcomes.showApprovalMessage) {
      const targetAudience = mapPermissionScopeToTargetAudience(proposedScope);
      response = I18n.t(`${targetAudience}_approval_notice`, { scope });
    } else if (outcomes.showRejectedMessage) {
      response = I18n.t('submission_auto_rejection_notice', { scope });
    }
  }

  return response;
};

const BottomSection: FunctionComponent<BottomSectionProps> = ({
  view,
  visualizationCanvasHasPublicDataSource
}) => {
  const outcomes = useSelector(getApprovalOutcomeMessages);
  const proposedScope = useSelector(getCurrentScope);
  const isInternalSharingEnabled = internalSharingEnabled();
  const nonPublicDataViz = viewIsVizCanWithNonPublicDataSource(view, visualizationCanvasHasPublicDataSource);

  return (
    <>
      {isInternalSharingEnabled && (
        <>
          {/* this lists users with the "published viewer" grant, which only exists when strict_permissions is on */}
          <ManagePublishedTo />
        </>
      )}
      {!nonPublicDataViz && (
        <>
          <div className={`alert ${getClassNames(outcomes, proposedScope)}`}>
            {getText(outcomes, proposedScope)}
          </div>
        </>
      )}
      {nonPublicDataViz && (
        <div
          className={`alert warning ${classNameScope}--warning-message`}
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              `shared.site_chrome.access_manager.audience.warning_about_parent.${getParentScope(view)}`
            )
          }}
        />
      )}
    </>
  );
};

export default BottomSection;
