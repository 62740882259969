import get from 'lodash/get';

const dataTypeMetadata = {
  blob: {
    forgeIconName: 'attach_file',
    icon: 'icon-data',
    iconName: 'data',
    sodaType: null
  },
  boolean: {
    forgeIconName: 'checkbox_marked_circle_outline',
    icon: 'icon-check',
    iconName: 'check',
    sodaType: 'checkbox'
  },
  calendar_date: {
    forgeIconName: 'calendar_clock',
    icon: 'icon-date',
    iconName: 'date',
    sodaType: 'floating_timestamp'
  },
  checkbox: {
    forgeIconName: 'checkbox_marked_circle_outline',
    icon: 'icon-check',
    iconName: 'check',
    sodaType: 'checkbox'
  },
  computed_column: {
    forgeIconName: 'map',
    icon: 'icon-map',
    iconName: 'map',
    sodaType: null
  },
  dataset_link: {
    forgeIconName: 'link',
    icon: 'icon-link',
    iconName: 'link',
    sodaType: null
  },
  date: {
    forgeIconName: 'calendar_clock',
    icon: 'icon-date',
    iconName: 'date',
    sodaType: 'fixed_timestamp'
  },
  document: {
    forgeIconName: 'file_document',
    icon: 'icon-copy-document',
    iconName: 'copy-document',
    sodaType: null
  },
  drop_down_list: {
    forgeIconName: 'list',
    icon: 'icon-list-2',
    iconName: 'list-2',
    sodaType: null
  },
  email: {
    forgeIconName: 'email',
    icon: 'icon-email',
    iconName: 'email',
    sodaType: 'text'
  },
  fixed_timestamp: {
    forgeIconName: 'calendar_clock',
    icon: 'icon-date',
    iconName: 'date',
    sodaType: null
  },
  flag: {
    forgeIconName: 'flag',
    icon: 'icon-region',
    iconName: 'region',
    sodaType: 'text'
  },
  floating_timestamp: {
    forgeIconName: 'calendar_clock',
    icon: 'icon-date',
    iconName: 'date',
    sodaType: 'floating_timestamp'
  },
  geospatial: {
    forgeIconName: 'map_marker',
    icon: 'icon-geo',
    iconName: 'geo',
    sodaType: null
  },
  html: {
    forgeIconName: 'web',
    icon: 'icon-clear-formatting',
    iconName: 'clear-formatting',
    sodaType: 'text'
  },
  line: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-geo',
    iconName: 'geo',
    sodaType: 'line'
  },
  link: {
    forgeIconName: 'link',
    icon: 'icon-link',
    iconName: 'link',
    sodaType: 'text'
  },
  list: {
    forgeIconName: 'list',
    icon: 'icon-list-numbered',
    iconName: 'list-numbered',
    sodaType: null
  },
  location: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-map',
    iconName: 'map',
    sodaType: 'location'
  },
  money: {
    forgeIconName: 'attach_money',
    icon: 'icon-number',
    iconName: 'number',
    sodaType: 'money'
  },
  multiline: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-geo',
    iconName: 'geo',
    sodaType: 'multiline'
  },
  multipoint: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-geo',
    iconName: 'geo',
    sodaType: 'multipoint'
  },
  multipolygon: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-geo',
    iconName: 'geo',
    sodaType: 'multipolygon'
  },
  number: {
    forgeIconName: 'pound',
    icon: 'icon-number',
    iconName: 'number',
    sodaType: 'number'
  },
  object: {
    forgeIconName: 'database',
    icon: 'icon-data',
    iconName: 'data',
    sodaType: null
  },
  percent: {
    forgeIconName: 'percent',
    icon: 'icon-number',
    iconName: 'number',
    sodaType: 'number'
  },
  photo: {
    forgeIconName: 'insert_photo',
    icon: 'icon-chart',
    iconName: 'chart',
    sodaType: null
  },
  point: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-map',
    iconName: 'map',
    sodaType: 'point'
  },
  polygon: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-geo',
    iconName: 'geo',
    sodaType: 'polygon'
  },
  radius: {
    forgeIconName: 'map_marker_outline',
    icon: 'icon-map',
    iconName: 'map',
    sodaType: null
  },
  stars: {
    forgeIconName: 'star_outline',
    icon: null,
    iconName: 'question',
    sodaType: 'number'
  },
  text: {
    forgeIconName: 'text_fields',
    icon: 'icon-text',
    iconName: 'text',
    sodaType: 'text'
  },
  url: {
    forgeIconName: 'link_variant',
    icon: 'icon-link',
    iconName: 'link',
    sodaType: 'text'
  }
};

export const getIconClassForDataType = (dataType) => {
  const icon = get(dataTypeMetadata, `${dataType}.icon`);
  return icon ? `icon icon-data-type ${icon}` : '';
};

export const getIconNameForDataType = (dataType) =>
  get(dataTypeMetadata, `${dataType}.iconName`, 'question-inverse');

export const getForgeIconNameForDataType = (dataType) =>
  get(dataTypeMetadata, `${dataType}.forgeIconName`, 'file_question_outline');

export const getDocumentationLinkForDataType = (dataType) => {
  const sodaType = get(dataTypeMetadata, `${dataType}.sodaType`);
  return sodaType ? `https://dev.socrata.com/docs/datatypes/${sodaType}.html` : null;
};
