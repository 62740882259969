/**
 * Returns grouping character for given locale for formatting a number, defaults to comma
 */
export default function(locale) {
  switch (locale) {
    case 'en':
    case 'zh':
      return ',';

    case 'ca':
    case 'es':
    case 'it':
    case 'nl':
      return '.';

    case 'fr':
      return '\u00A0';

    default:
      // use the browser's toLocaleString method and grab the separator from that
      // (assuming we're given a locale and the browser knows how to format numbers)
      if (locale && !!(typeof Intl === 'object' && Intl && typeof Intl.NumberFormat === 'function')) {
        const localized = Number(1234).toLocaleString(locale);
        return localized.match(/1(.*)234/)[1];
      }

      return ',';
  }
}
