import React, { Component } from 'react';
import { handleTab } from 'common/dom_helpers/keyPressHelpers';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

export interface PanelHeaderProps {
  onClosePanel: () => void;
  panelHeaderText: string;
}

class PanelHeader extends Component<PanelHeaderProps> {
  focusSettingsButton = () => {
    const settingButton = document.querySelector('.setting-button') as HTMLElement;
    if (settingButton) {
      settingButton.focus();
    }
  };

  focusSelectedNotificationTab = () => {
    const notificationTab = document.querySelector('.notification-tab') as HTMLElement;
    if (notificationTab) {
      notificationTab.focus();
    }
  };

  render() {
    const { onClosePanel, panelHeaderText } = this.props;

    return (
      <div className="notification-panel-header">
        <h3 className="header-text">{panelHeaderText}</h3>
        <ForgeIconButton
          className="close-panel-link close-notifications-panel-link"
          onClick={onClosePanel}
          onKeyDown={handleTab(this.focusSelectedNotificationTab, this.focusSettingsButton, true)}>
          <button type="button" aria-label="Close Notification">
            <ForgeIcon name="close"></ForgeIcon>
          </button>
        </ForgeIconButton>
      </div>
    );
  }
}

export default PanelHeader;
