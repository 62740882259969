import { StorytellerState } from 'store/StorytellerReduxStore';
import { storySaveStatusSelector } from 'store/TopLevelSelector';
import { StorySerialized } from 'types';

// This is currently not used due to errors I wasn't able to explore.
// Instead using the selectors exported from the reducer.
interface StorySaveStatusReducerState {
  saveInProgress: boolean;
  poisonedWithSaveConflictForever: boolean;
  lastSerializedStory: StorySerialized | null;
  lastSaveError: string | null;
  userCausingConflict: {
    id: number;
    displayName: string;
  } | null;
  hasBeenPublished: boolean;
  errorMessage?: string;
}

/* Selectors */
export const getUserCausingConflict = (state: StorytellerState) =>
  storySaveStatusSelector(state).userCausingConflict;
export const isSaveImpossibleDueToConflict = (state: StorytellerState) =>
  storySaveStatusSelector(state).poisonedWithSaveConflictForever;
export const isStorySaveInProgress = (state: StorytellerState) =>
  storySaveStatusSelector(state).saveInProgress;
export const getLastSaveError = (state: StorytellerState) => storySaveStatusSelector(state).lastSaveError;
export const getErrorMessage = (state: StorytellerState) => storySaveStatusSelector(state).errorMessage;
export const getHasBeenPublished = (state: StorytellerState) =>
  storySaveStatusSelector(state).hasBeenPublished;
export const getLastSerializedStory = (state: StorytellerState) =>
  storySaveStatusSelector(state).lastSerializedStory;
