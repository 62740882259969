import React, { Component } from 'react';
import has from 'lodash/fp/has';
import get from 'lodash/get';
import isEmpty from 'lodash/fp/isEmpty';

import I18n from 'common/i18n';

import './error-list.scss';

// We don't use `app_name` or `displayName` here. However there are errors that
// come in with those keys. Since I'm aware of them, I may as well document them
interface BasicError {
  translationKey: string;
}

interface SagasError extends BasicError {
  displayName: string; // frontend/public/javascripts/adminUsersV2/teams/sagas.ts
}

interface AppTokensTableError extends BasicError {
  app_name: string; // frontend/public/javascripts/account/edit/components/DeveloperSettingsPane/AppTokensTable/index.js
}

type ErrorListError = BasicError | SagasError | AppTokensTableError | string;

// An error might be a string or it might be an object. At least, that is what
// the code in `renderError` seems to imply. I don't know if an error ever is just
// a string. Every thing that calls ErrorList seems to derive each error in a minimal
// form of `{ translationKey: "the.translation.key" }` and thus I'm not sure if the
// `if(has('translationKey'...` is really necessary, but I don't want to rip it
// out and find out I'm wrong
interface ErrorListProps {
  errors: ErrorListError[];
}

export class ErrorList extends Component<ErrorListProps> {

  renderError = (error: ErrorListError, key: number) => {
    if (has('translationKey', error)) {
      const translationKey = get(error, 'translationKey', '');
      const message = I18n.t(translationKey, error);
      if (translationKey.endsWith('_html')) {
        return <li dangerouslySetInnerHTML={{ __html: message }} key={key} />;
      } else {
        return <li key={key}>{message}</li>;
      }
    } else {
      return <li key={key}>{error}</li>;
    }
  };

  render() {
    const { errors } = this.props;

    if (isEmpty(errors)) {
      return null;
    }

    return (
      <div className="error-list alert error">
        <ul className="alert-list">{errors.map(this.renderError)}</ul>
      </div>
    );
  }
}

export default ErrorList;
