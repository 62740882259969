import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './error-message.scss';

class ErrorMessage extends PureComponent {
  render() {
    const { text } = this.props;

    return (
      <div
        className="alert error common-notifications-error-message"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: text }} />
    );
  }
}

ErrorMessage.propTypes = {
  text: PropTypes.string.isRequired
};

export default ErrorMessage;
