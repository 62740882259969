import _ from 'lodash';

import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';

// Utility functions for fetching configuration related to rendering of lines.
// These functions gets added to the vif object (see vifDecorator|views/UnifiedMap).
// So that multiple files(overlays/lines-partials/legends/mapFactory) can
// reuse them directly.

export function getColorLinesByColumn() {
  return _.get(this, 'series[0].mapOptions.colorLinesBy');
}

export function getLineColorOpacity() {
  const lineColorOpacity = parseInt(_.get(this, 'series[0].mapOptions.lineColorOpacity'));

  return (_.isNaN(lineColorOpacity) ?
    VIF_CONSTANTS.LINE_COLOR_OPACITY.DEFAULT :
    _.clamp(lineColorOpacity, VIF_CONSTANTS.LINE_COLOR_OPACITY.MIN, VIF_CONSTANTS.LINE_COLOR_OPACITY.MAX)) / 100;
}

export function getLineWidthPaintProperty(aggregateAndResizeBy, resizeByRange) {
  if (!_.isString(this.getWeighLinesByColumn())) {
    return _.get(this, 'series[0].mapOptions.lineWeight', VIF_CONSTANTS.LINE_WEIGHT.DEFAULT);
  }

  const minWidth = _.get(this, 'series[0].mapOptions.minimumLineWeight', VIF_CONSTANTS.LINE_MAP_MIN_LINE_WEIGHT.DEFAULT);
  const maxWidth = _.get(this, 'series[0].mapOptions.maximumLineWeight', VIF_CONSTANTS.LINE_MAP_MAX_LINE_WEIGHT.DEFAULT);
  const dataClasses = _.get(this, 'series[0].mapOptions.numberOfDataClasses', VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.DEFAULT);

  return this.getResizeByRangePaintProperty(aggregateAndResizeBy, resizeByRange,
    minWidth, maxWidth, dataClasses, 'interval');
}

export function getWeighLinesByColumn() {
  return _.get(this, 'series[0].mapOptions.weighLinesBy');
}
