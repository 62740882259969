import { assign, isEmpty, isNull, map, size, first, isBoolean } from 'lodash';

import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import * as BaseFilter from './BaseFilter';
import {
  BinaryOperator,
  FilterValue,
  FILTER_FUNCTION,
  OPERATOR,
  NoopFilter,
  JOIN_FUNCTION
} from '../../SoqlFilter';

export function getCheckboxFilter(
  config: BinaryOperator | NoopFilter,
  columns: BaseFilter.FilterColumnsMap,
  values: FilterValue[]
) {
  if (isEmpty(values)) {
    return BaseFilter.reset(config, columns);
  } else {
    const toArgument = (value: FilterValue) => {
      if (isNull(value)) {
        return {
          operator: OPERATOR.NULL
        };
      } else {
        return {
          operator: OPERATOR.EQUALS,
          operand: value
        };
      }
    };

    const updatedConfig = assign({}, config, {
      function: FILTER_FUNCTION.BINARY_OPERATOR,
      joinOn: JOIN_FUNCTION.OR,
      arguments: map(values, toArgument)
    });

    return updatedConfig;
  }
}

export function getCheckboxFilterHumanText(filter: BinaryOperator, showNullsAsFalse?: boolean): string {
  const values = map(filter.arguments, 'operand');
  const valueCount = size(values);
  const firstValue = first(values);
  const scope = 'shared.components.filter_bar.checkbox_filter';

  if (valueCount > 1) {
    return formatString(I18n.t('n_values', { scope }), valueCount);
  } else if (isBoolean(firstValue)) {
    return boolToString(firstValue);
  } else {
    return showNullsAsFalse ? I18n.t('false_value', { scope }) : I18n.t('no_value', { scope });
  }
}

function boolToString(value: any): string {
  const scope = 'shared.components.filter_bar.checkbox_filter';
  if (value === true) {
    return I18n.t('true_value', { scope });
  } else if (value === false) {
    return I18n.t('false_value', { scope });
  } else {
    return I18n.t('no_value', { scope });
  }
}
