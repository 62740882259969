import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { resultVisibilityChanged, focusChanged } from '../actions';
import CollapsedIcon from './CollapsedIcon';
import SearchBox from './SearchBox/SearchBox';
import Results from './Results/Results';

import './autocomplete.scss';

const classNameScope = 'common--autocomplete--components';

class Autocomplete extends React.Component {
  static incrementor = 0;
  instanceId = 1;
  constructor(props) {
    super(props);

    this.state = { autocomplete: { query: props.currentQuery } };

    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.rootRef = React.createRef();
    this.instanceId = Autocomplete.incrementor++;
  }

  isOwnChild(node) {
    while (node) {
      if (node === this.rootRef.current) {
        return true;
      }
      node = node.parentElement;
    }

    return false;
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event) {
    // hide results on escape
    if (event.keyCode === 27) {
      this.props.onResultVisibilityChanged(false);
    }
  }

  render() {
    const {
      animate,
      anonymous,
      className,
      collapsed,
      collapsible,
      currentQuery,
      disabled,
      getSearchResults,
      millisecondsBeforeSearch,
      mobile,
      onChooseResult,
      onClearSearch,
      onResultVisibilityChanged,
      onFocusChange,
      renderResult
    } = this.props;

    if (collapsible && collapsed) {
      return <CollapsedIcon />;
    }

    return (
      <div
        className={classNames('expanded', `${classNameScope}--container`, className)}
        tabIndex="-1"
        onFocus={() => {
          onResultVisibilityChanged(true);
          onFocusChange(true);
        }}
        onBlur={(e) => {
          // IE doesn't have relatedTarget on blur. Use document.activeElement instead.
          const newTarget = e.relatedTarget || document.activeElement;
          if (!this.isOwnChild(newTarget)) {
            // We don't want to hide results when we tab into them
            onResultVisibilityChanged(false);
          }
          onFocusChange(false);
        }}
        ref={this.rootRef}
      >
        <SearchBox
          animate={animate}
          anonymous={anonymous}
          collapsible={collapsible}
          disabled={disabled}
          getSearchResults={getSearchResults}
          millisecondsBeforeSearch={millisecondsBeforeSearch}
          mobile={mobile}
          onChooseResult={onChooseResult}
          onClearSearch={onClearSearch}
          query={currentQuery}
          listboxId={'results-listbox-' + this.instanceId}
        />
        <div id={'results-listbox-' + this.instanceId}>
          <Results collapsible={collapsible} onChooseResult={onChooseResult} renderResult={renderResult} />
        </div>
      </div>
    );
  }
}

Autocomplete.propTypes = {
  animate: PropTypes.bool,
  anonymous: PropTypes.bool,
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
  currentQuery: PropTypes.string,
  disabled: PropTypes.bool,
  getSearchResults: PropTypes.func.isRequired,
  millisecondsBeforeSearch: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
  onChooseResult: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func,
  onFocusChange: PropTypes.func.isRequired,
  onResultVisibilityChanged: PropTypes.func.isRequired,
  renderResult: PropTypes.func
};

Autocomplete.defaultProps = {
  collapsible: false,
  collapsed: false,
  disabled: false
};

const mapStateToProps = (state) => ({ collapsed: state.autocomplete.collapsed });

const mapDispatchToProps = (dispatch) => ({
  onResultVisibilityChanged: (visible) => {
    dispatch(resultVisibilityChanged(visible));
  },
  onFocusChange: (isFocused) => {
    dispatch(focusChanged(isFocused));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Autocomplete);

// Used by platform-ui/common/spec/autocomplete/components/Autocomplete.spec.js
export const AutocompleteClass = Autocomplete;
