/* eslint require-atomic-updates:0 */
// Our current version of eslint (6.6.0) incorrectly flags parts of this file due to bugs in the
// require-atomic-updates rule implementation; the rule is fixed in v7+ of eslint and no longer
// part of the recommended ruleset

// Vendor Imports
import _ from 'lodash';

import FeatureFlags from 'common/feature_flags';
import assertHasProperties from 'common/assertions/assertHasProperties';
import SoqlDataProvider from './SoqlDataProvider';
import SoqlHelpers from './SoqlHelpers';
import I18n from 'common/i18n';
import makeSocrataCategoricalDataRequest from './makeSocrataCategoricalDataRequest';
import { applyOrderBy } from 'common/visualizations/helpers/SortHelpers';
import { isMeasureCountOfRows } from 'common/visualizations/helpers/VifSelectors';
import { JOIN_FUNCTION } from 'common/components/FilterBar/SoqlFilter';
import { getParameterOverrides } from '../helpers/VifSelectors';

// Constants
export const MAX_GROUP_COUNT = 20;

const nullConverterFn = (x) => (x === 'null' ? null : x);

const getBinaryOperatorFilterArguments = (operand, operator = '=') => {
  return _.isNull(operand)
    ? { operator: operator === '=' ? 'IS NULL' : 'IS NOT NULL' }
    : { operator, operand };
};

const generateGroupingVifWithFilters = (templateVif, filtersForGroupingVif, extras = {}) => {
  const groupingVif = _.cloneDeep(templateVif);

  _.unset(groupingVif, 'configuration.showOtherCategory');
  _.unset(groupingVif, 'series[0].dataSource.limit');
  _.unset(groupingVif, 'series[0].dataSource.orderBy');
  _.set(groupingVif, 'series[0].dataSource.filters', filtersForGroupingVif);

  return Object.assign(groupingVif, extras);
};

const getColumnForFilter = (fieldName, vif) => {
  const columnsForFilter = {
    columns: [
      {
        fieldName: fieldName,
        datasetUid: _.get(vif, 'series[0].dataSource.datasetUid')
      }
    ]
  };

  return columnsForFilter;
};

/**
 * The purpose of this function is to ask for up to LIMIT + 1 unique dimension
 * values and add them to the state that gets passed to each successive
 * function so that we know how many dimension values will require grouping
 * queries, and also if we need to show a dimension '(Other)' category.
 */
const addDimensionValuesToState = async (state) => {
  assertHasProperties(state, 'vif', 'columnName', 'soqlDataProvider');

  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(state.vif, 0);
  const whereClause = whereClauseComponents.length > 0 ? `WHERE ${whereClauseComponents}` : '';

  const orderByParameter = _.get(state.vif, 'series[0].dataSource.orderBy.parameter', 'dimension');
  const aliasForOrderByParameter =
    orderByParameter === 'dimension' ? SoqlHelpers.dimensionAlias() : SoqlHelpers.measureAlias();
  const orderBySort = _.get(state.vif, 'series[0].dataSource.orderBy.sort', 'ASC').toUpperCase();
  const limitFromVif = _.get(state.vif, 'series[0].dataSource.limit', null);
  const limit = _.isNumber(limitFromVif) ? parseInt(limitFromVif, 10) : state.options.MAX_ROW_COUNT;
  const dimensionQueryString = [
    'SELECT',
    `\`${state.columnName}\` AS ${SoqlHelpers.dimensionAlias()},`,
    `${SoqlHelpers.aggregationClause(state.vif, 0, 'measure')} AS ${SoqlHelpers.measureAlias()}`,
    whereClause,
    `GROUP BY ${SoqlHelpers.groupByClause(state.vif, 0, 'measure')}`,
    `ORDER BY ${aliasForOrderByParameter} ${orderBySort}`,
    'NULL LAST',
    `LIMIT ${limit + 1}`
  ].join(' ');

  const dimensionValues = await state.soqlDataProvider.query(
    dimensionQueryString,
    SoqlHelpers.dimensionAlias(),
    SoqlHelpers.measureAlias()
  );

  // If the 'showOtherCategory' property is enabled in the vif AND there
  // are more than 'limit' unique dimension values, then we
  // need to make '(Other)' category queries for the dimension column.
  state.dimensionRequiresOtherCategory =
    _.get(state.vif, 'configuration.showOtherCategory', false) && dimensionValues.rows.length > limit;

  // We asked for one more dimension value than we actually want to test
  // if we need to show an '(Other)' category for dimension values, so
  // we need to take one fewer than the total when actually recording
  // dimension values for future use.
  state.rawDimensionValues = dimensionValues.rows
    .slice(0, limit)
    .map(([dimension, measure]) => [_.isUndefined(dimension) ? null : dimension, measure]);

  state.dimensionValues = state.rawDimensionValues.map(([dimension]) => dimension);

  return state;
};

/**
 * The purpose of this function is to ask for up to LIMIT + 1 unique grouping
 * values and add them to the state that gets passed to each successive
 * function so that we know how many and which grouping queries to make for
 * each dimension value, and also if we need to show a grouping '(Other)'
 * category.
 */
const addGroupingValuesToState = async (state) => {
  assertHasProperties(state, 'vif', 'groupingColumnName', 'soqlDataProvider');

  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(state.vif, 0);
  const whereClause = whereClauseComponents.length > 0 ? `WHERE ${whereClauseComponents}` : '';

  let groupingOrderBy = _.get(state.vif, 'series[0].dataSource.dimension.grouping.orderBy');

  if (_.isNil(groupingOrderBy)) {
    groupingOrderBy = _.get(state.vif, 'series[0].dataSource.orderBy', {
      parameter: 'dimension',
      sort: 'asc'
    });
  }

  const useMeasureAggregation = groupingOrderBy.parameter === 'measure';

  // Note about default aggregation functions: I've set measure agg to 'sum', because for the rare case
  // that we want a grouping column but no aggregation, we will usually just error with duplicate values
  // UNLESS the dimension is unique anyway. 'sum' orders the groups correctly if we don't error out.
  // I have no idea why 'count' is dimension or if that code is even reachable.
  const aggregatedColumn = useMeasureAggregation
    ? SoqlHelpers.measure(state.vif, 0, { defaultAggregationFunction: 'sum' })
    : SoqlHelpers.dimension(state.vif, 0, { defaultAggregationFunction: 'count' });

  const orderByParameter = useMeasureAggregation ? SoqlHelpers.measureAlias() : SoqlHelpers.dimensionAlias();

  const orderBySort = groupingOrderBy.sort.toUpperCase();

  const queryString = [
    'SELECT',
    `\`${state.groupingColumnName}\` AS ${SoqlHelpers.dimensionAlias()}, ` +
    `${aggregatedColumn} AS ${SoqlHelpers.measureAlias()}`,
    whereClause,
    `GROUP BY \`${state.groupingColumnName}\``,
    `ORDER BY ${orderByParameter} ${orderBySort}`,
    `LIMIT ${state.options.MAX_GROUP_COUNT + 1}`
  ].join(' ');

  const groupingValues = await state.soqlDataProvider.query(
    queryString,
    SoqlHelpers.dimensionAlias(),
    SoqlHelpers.measureAlias()
  );
  // If there are more than MAX_GROUP_COUNT distinct values in the
  // grouping_column, then we need to make '(Other)' category queries for
  // the grouping column.
  state.groupingRequiresOtherCategory = groupingValues.rows.length > state.options.MAX_GROUP_COUNT;

  state.groupingValues = groupingValues.rows
    // We asked for one more grouping value than we actually want to test
    // if we need to show an '(Other)' category for grouping values, so we
    // need to take one fewer than the total when actually recording
    // grouping values for future use.
    .slice(0, state.options.MAX_GROUP_COUNT)
    .map(([dimension]) => (_.isUndefined(dimension) ? null : dimension));

  return state;
};

/**
 * This function is a real doozy. In the simplest case, we need to break up
 * the total number of rows for each distinct value in the dimension column
 * by their distinct values in the grouping column.
 *
 * Moreover, because there are two different cases in which we might need to
 * make '(Other)' category requests, we end up generating a lot of vifs which
 * are then passed to the default 'makeSocrataCategoricalDataRequest'
 * mechanism, the results of which queries are then merged back into a single
 * data table object by the following
 * 'mapGroupingDataResponsesToMultiSeriesTable' function.
 *
 * The two types of '(Other)' category that we need to accommodate are:
 *
 *   1. If the user has enabled the 'showOtherCategory' property in the vif,
 *      then we need to generate a dimension value (the groups rendered along
 *      the dimension axis, same as with non-grouped charts) that represents
 *      the inverse of the dimension values that are being drawn.
 *
 *   2. If there are more distinct values in the grouping column than
 *      MAX_GROUP_COUNT, we also need to construct an '(Other)' category for
 *      each dimension value that represents the inverse of the grouping
 *      values being drawn FOR EACH DIMENSION VALUE, as well as for the
 *      dimension '(Other)' category as described in point 1 if the user has
 *      enabled the 'showOtherCategory' property in the vif.
 *
 * Finally, because the types of requests we need to build to get all these
 * values differ slightly to how the '(Other)' category request of type 1 is
 * constructed by the underlying 'makeSocrataCategoricalDataRequest' in the
 * non-grouping case, we explicitly disable the 'showOtherCategory' property
 * in the vifs we construct for grouping purposes in order to avoid the
 * automatic '(Other)' category functionality in
 * 'makeSocrataCategoricalDataRequest' because it will generate subtle-ly
 * incorrect results. It may be possible to reconcile these two places that
 * make '(Other)' category requests or generate more complex queries in order
 * to make fewer independent requests, but for the sake of clarity and
 * correctness no work has been done on this yet.
 *
 * The queries we need to generate should, if the 'showOtherCategory' property
 * is enabled in the vif, result in all rows being represented on the chart.
 *
 * For example, consider the following data with the artificially-low limits
 * of 2 for both the 'limit' property in the vif and MAX_GROUP_COUNT:
 *
 * dimension_column as d      grouping_column as g
 * =====================      ====================
 *      a                          x
 *      b                          y
 *      c                          z
 *
 * We need to generate the following queries. In order to refer to the various
 * types in comments in the code, and to disambiguate these types from the
 * example values in dimension_column and grouping_column above, I have named
 * the types of queries after animals:
 *
 *            | Anteater query:  ...WHERE d = 'a' AND g = 'x'
 *       'a' -| Anteater query:  ...WHERE d = 'a' AND g = 'y'
 *            | Beaver query:    ...WHERE d = 'a' AND (g != 'x' AND g != 'y')
 *
 *            | Anteater query:  ...WHERE d = 'b' AND g = 'x'
 *       'b' -| Anteater query:  ...WHERE d = 'b' AND g = 'y'
 *            | Beaver query:    ...WHERE d = 'b' AND (g != 'x' AND g != 'y')
 *
 *            | Chincilla query: ...WHERE (d != 'a' AND d != 'b') AND g = 'x'
 * '(Other)' -| Chincilla query: ...WHERE (d != 'a' AND d != 'b') AND g = 'y'
 *            | Dingo query:     ...WHERE (d != 'a' AND d != 'b') AND
 *                                        (g != 'x' AND g != 'y')
 *
 * Note that in this idealized example world, selecting 'd != "a"' will result
 * in all rows where d is not 'a' or d is null; this is not how SoQL works
 * however, so in actually generating these idealized queries we also need to
 * add an 'OR d IS NULL' or 'OR d IS NOT NULL' to all queries using the '!='
 * operator on dimension_column based on whether one of the distinct dimension
 * values being drawn on the chart (e.g. ['a', 'b'] in the example above) is
 * null.
 *
 * We must also do the same thing for queries using the '!=' operator on
 * grouping_column based on whether one of the distinct grouping values being
 * drawn on the chart (e.g. ['x', 'y'] in the example above is null.
 */
const makeGroupingDataRequests = async (state) => {
  const filtersFromVif = _.get(state.vif, 'series[0].dataSource.filters', []);
  const groupingValuesIncludeNull = _.includes(state.groupingValues, null);
  const dimensionValuesIncludeNull = _.includes(state.dimensionValues, null);

  const groupingData = [];

  const otherCategoryName = I18n.t('shared.visualizations.charts.common.other_category');

  const nonNullDimensionValuesFilter = {
    function: 'in',
    ...getColumnForFilter(state.columnName, state.vif),
    arguments: _.without(state.dimensionValues, null)
  };

  const nullDimensionFilter = {
    function: 'binaryOperator',
    ...getColumnForFilter(state.columnName, state.vif),
    arguments: getBinaryOperatorFilterArguments(null)
  };

  const nonNullGroupingValuesFilter = {
    function: 'in',
    ...getColumnForFilter(state.groupingColumnName, state.vif),
    arguments: _.without(state.groupingValues, null)
  };

  const invertedNonNullGroupingValuesFilter = {
    function: 'not in',
    ...getColumnForFilter(state.groupingColumnName, state.vif),
    arguments: _.without(state.groupingValues, null)
  };

  const nullGroupingFilter = {
    function: 'binaryOperator',
    ...getColumnForFilter(state.groupingColumnName, state.vif),
    arguments: getBinaryOperatorFilterArguments(null)
  };

  const nonNullGroupingFilter = {
    function: 'binaryOperator',
    ...getColumnForFilter(state.groupingColumnName, state.vif),
    arguments: getBinaryOperatorFilterArguments(null, '!=')
  };

  // For these Soql 'in ([args])' conditions, an empty arguments list
  // will result in a Soql error.  If the arguments list is empty,
  // the query should logically select nothing.  So in this case,
  // there is no point in running the query, hence these flags.
  const hasNonNullGroupingValues = nonNullGroupingValuesFilter.arguments.length > 0;
  const hasInvertedNonNullGroupingValuesFilter = invertedNonNullGroupingValuesFilter.arguments.length > 0;
  const hasNonNullDimensionValues = nonNullDimensionValuesFilter.arguments.length > 0;

  /**
   * Anteater queries
   */

  if (hasNonNullDimensionValues && hasNonNullGroupingValues) {
    groupingData.push({
      vif: generateGroupingVifWithFilters(
        state.vif,
        _.cloneDeep(filtersFromVif).concat([nonNullDimensionValuesFilter, nonNullGroupingValuesFilter]),
        {
          query: 'Anteater 1',
          requireGroupingInSelect: true
        }
      )
    });
  }

  // XXX: NULL values are handled differently in SOQL when used in = clauses
  // and IN clauses, no clue why. This means we need extra queries to retrieve
  // those results.
  if (dimensionValuesIncludeNull && hasNonNullGroupingValues) {
    groupingData.push({
      vif: generateGroupingVifWithFilters(
        state.vif,
        _.cloneDeep(filtersFromVif).concat([nullDimensionFilter, nonNullGroupingValuesFilter]),
        {
          query: 'Anteater 2',
          requireGroupingInSelect: true
        }
      )
    });
  }

  if (groupingValuesIncludeNull && hasNonNullDimensionValues) {
    groupingData.push({
      vif: generateGroupingVifWithFilters(
        state.vif,
        _.cloneDeep(filtersFromVif).concat([nonNullDimensionValuesFilter, nullGroupingFilter]),
        {
          query: 'Anteater 3',
          requireGroupingInSelect: true
        }
      )
    });
  }

  if (dimensionValuesIncludeNull && groupingValuesIncludeNull) {
    groupingData.push({
      vif: generateGroupingVifWithFilters(
        state.vif,
        _.cloneDeep(filtersFromVif).concat([nullDimensionFilter, nullGroupingFilter]),
        {
          query: 'Anteater 4',
          requireGroupingInSelect: true
        }
      )
    });
  }

  /**
   * Beaver queries (only necessary when the measure is not count of rows)
   */
  if (!isMeasureCountOfRows(state.vif)) {
    if (hasNonNullDimensionValues && hasInvertedNonNullGroupingValuesFilter) {
      groupingData.push({
        vif: generateGroupingVifWithFilters(
          state.vif,
          _.cloneDeep(filtersFromVif).concat([
            nonNullDimensionValuesFilter,
            nonNullGroupingFilter,
            invertedNonNullGroupingValuesFilter
          ]),
          {
            query: 'Beaver 1',
            requireGroupingInSelect: false
          }
        )
      });
    }

    if (dimensionValuesIncludeNull && hasInvertedNonNullGroupingValuesFilter) {
      groupingData.push({
        vif: generateGroupingVifWithFilters(
          state.vif,
          _.cloneDeep(filtersFromVif).concat([
            nullDimensionFilter,
            nonNullGroupingFilter,
            invertedNonNullGroupingValuesFilter
          ]),
          {
            query: 'Beaver 2',
            requireGroupingInSelect: false
          }
        )
      });
    }
  }

  /**
   * Chinchilla queries
   */

  // Third, do the same inversion we did for grouping values but this time for
  // dimension values, in order to generate the '(Other)' dimension category
  // (except for the '(Other) (Other)' case, which is handled by the Dingo
  // query.
  if (state.dimensionRequiresOtherCategory) {
    state.groupingValues.forEach((groupingValue) => {
      const invertedDimensionValuesFilters = _.cloneDeep(filtersFromVif);

      invertedDimensionValuesFilters.push({
        function: 'binaryOperator',
        ...getColumnForFilter(state.groupingColumnName, state.vif),
        arguments: getBinaryOperatorFilterArguments(groupingValue)
      });

      if (dimensionValuesIncludeNull) {
        const invertedDimensionValuesFilterArguments = state.dimensionValues.map((dimensionValue) => {
          return getBinaryOperatorFilterArguments(dimensionValue, '!=');
        });

        invertedDimensionValuesFilters.push({
          function: 'binaryOperator',
          ...getColumnForFilter(state.columnName, state.vif),
          arguments: invertedDimensionValuesFilterArguments,
          joinOn: JOIN_FUNCTION.AND
        });
      } else {
        state.dimensionValues.forEach((dimensionValue) => {
          invertedDimensionValuesFilters.push({
            function: 'binaryOperator',
            ...getColumnForFilter(state.columnName, state.vif),
            arguments: [getBinaryOperatorFilterArguments(dimensionValue, '!='), { operator: 'IS NULL' }],
            joinOn: JOIN_FUNCTION.OR
          });
        });
      }

      groupingData.push({
        query: 'Chinchilla',
        vif: generateGroupingVifWithFilters(state.vif, invertedDimensionValuesFilters, {
          query: 'Chinchilla',
          requireGroupingInSelect: false
        }),
        dimensionValue: otherCategoryName, // XXX: critical
        groupingValue
      });
    });
  }

  /**
   * Dingo query
   */

  // Finally, if both grouping and dimension values need an '(Other)' category
  // then we need to make a final query that excludes all extant dimension and
  // all extant grouping values, which is the '(Other) (Other)' category.
  if (state.groupingRequiresOtherCategory && state.dimensionRequiresOtherCategory) {
    const invertedEverythingValuesFilters = _.cloneDeep(filtersFromVif);

    if (groupingValuesIncludeNull) {
      const invertedGroupingValuesFilterArguments = state.groupingValues.map((groupingValue) => {
        return getBinaryOperatorFilterArguments(groupingValue, '!=');
      });

      invertedEverythingValuesFilters.push({
        function: 'binaryOperator',
        ...getColumnForFilter(state.groupingColumnName, state.vif),
        arguments: invertedGroupingValuesFilterArguments,
        joinOn: JOIN_FUNCTION.AND
      });
    } else {
      state.groupingValues.forEach((groupingValue) => {
        invertedEverythingValuesFilters.push({
          function: 'binaryOperator',
          ...getColumnForFilter(state.groupingColumnName, state.vif),
          arguments: [getBinaryOperatorFilterArguments(groupingValue, '!='), { operator: 'IS NULL' }],
          joinOn: JOIN_FUNCTION.OR
        });
      });
    }

    if (dimensionValuesIncludeNull) {
      const invertedDimensionValuesFilterArguments = state.dimensionValues.map((dimensionValue) => {
        return getBinaryOperatorFilterArguments(dimensionValue, '!=');
      });

      invertedEverythingValuesFilters.push({
        function: 'binaryOperator',
        ...getColumnForFilter(state.columnName, state.vif),
        arguments: invertedDimensionValuesFilterArguments,
        joinOn: JOIN_FUNCTION.AND
      });
    } else {
      state.dimensionValues.forEach((dimensionValue) => {
        invertedEverythingValuesFilters.push({
          function: 'binaryOperator',
          ...getColumnForFilter(state.columnName, state.vif),
          arguments: [getBinaryOperatorFilterArguments(dimensionValue, '!='), { operator: 'IS NULL' }],
          joinOn: JOIN_FUNCTION.OR
        });
      });
    }

    groupingData.push({
      vif: generateGroupingVifWithFilters(state.vif, invertedEverythingValuesFilters, {
        query: 'Dingo',
        requireGroupingInSelect: false
      }),
      dimensionValue: otherCategoryName,
      groupingValue: otherCategoryName
    });
  }

  const groupingRequests = groupingData.map(({ vif }) =>
    makeSocrataCategoricalDataRequest(vif, 0, state.options.MAX_ROW_COUNT)
  );
  const groupingResponses = await Promise.all(groupingRequests);

  groupingData.forEach((groupingDatum, i) => {
    if (groupingDatum.vif.query === 'Dingo') {
      const measureIndex = groupingResponses[i].columns.indexOf('measure');
      const sumOfRows = _.sumBy(groupingResponses[i].rows, measureIndex);
      groupingDatum.data = {
        columns: groupingResponses[i].columns,
        rows: [[groupingDatum.dimensionValue, sumOfRows]]
      };
    } else {
      // everything else
      groupingDatum.data = groupingResponses[i];
    }
  });

  state.groupingData = groupingData;

  return state;
};

/**
 * The purpose of this function is to take a collection of query responses
 * and rationalize them into a single correct data table object.
 */
const mapGroupingDataResponsesToMultiSeriesTable = async (state) => {
  assertHasProperties(state, 'groupingValues', 'groupingRequiresOtherCategory', 'groupingData');

  const dimensionColumn = 'dimension';
  const dimensionLookupTable = new Set(state.dimensionValues);

  const dataToRenderColumns = [dimensionColumn].concat(state.groupingValues);
  const treatNullValuesAsZero = _.get(state.vif, 'configuration.treatNullValuesAsZero', false);

  const otherCategoryName = I18n.t('shared.visualizations.charts.common.other_category');
  if (state.groupingRequiresOtherCategory) {
    dataToRenderColumns.push(otherCategoryName);
  }

  // state.groupingData is an array of objects. Each of those objects has a
  // data field. That field must be processed cleanly and return something
  // that matches the expected table, one meant for rendering.
  const table = {};
  state.dimensionValues.forEach((dim) => (table[dim] = {}));

  state.groupingData.forEach((datum) => {
    if (datum.vif.requireGroupingInSelect) {
      // process 3-column results
      datum.data.rows.forEach((row) => {
        const [dimension, grouping, measure] = row;
        const standardizedDimension = _.isUndefined(dimension) ? null : dimension;
        let path;
        if (dimensionLookupTable.has(standardizedDimension)) {
          path = [standardizedDimension, grouping];
        } else {
          path = [otherCategoryName, grouping];
        }
        const existing = _.get(table, path, null);
        let value;

        if (_.isFinite(existing)) {
          value = _.isFinite(measure) ? existing + measure : existing;
        } else {
          value = measure;
        }

        _.setWith(table, path, value, Object);
      });
    } else {
      // process 2-column results
      datum.data.rows.forEach((row) => {
        const [dimension, measure] = row;
        const standardizedDimension = _.isUndefined(dimension) ? null : dimension;
        let path;
        if (dimensionLookupTable.has(standardizedDimension)) {
          path = [standardizedDimension, datum.groupingValue];
        } else {
          path = [otherCategoryName, datum.groupingValue];
        }
        const existing = _.get(table, path, 0);
        _.setWith(table, path, existing + measure, Object);
      });
    }
  });

  // Convert the table (a map data structure) into an array of rows suitable
  // for rendering, with entries ordered as specified by dataToRenderColumns:
  const otherIndex = _.findIndex(dataToRenderColumns, (x) => x === otherCategoryName);
  const dataToRenderRows = _.map(table, (rowData, dimension) => {
    const realDimension = nullConverterFn(dimension);
    const row = [realDimension];
    const otherColumns = _.difference(_.map(_.keys(rowData), nullConverterFn), dataToRenderColumns);
    dataToRenderColumns.forEach((col) => {
      if (col !== dimensionColumn) {
        let value = _.get(rowData, col, null);
        if (treatNullValuesAsZero && _.isNil(value) && col !== otherCategoryName) {
          value = 0;
        }

        row.push(value);
      }
    });
    // deal with "(Other)" columns
    if (state.groupingRequiresOtherCategory && _.isNull(row[otherIndex])) {
      if (isMeasureCountOfRows(state.vif)) {
        // the Other grouping is easy to calculate if the measure is count of rows; we can subtract the sum
        // of the currently displayed groupings from the total number of rows returned when the dimension isn't grouped
        const [_, measureOfDimensionWithoutGrouping] = state.rawDimensionValues.find(
          ([dim]) => dim === realDimension
        );
        const measureOfRenderedGroupings = row.reduce(
          (sum, row, idx) => sum + (idx === otherIndex || idx === 0 ? 0 : row),
          0
        );
        row[otherIndex] = measureOfDimensionWithoutGrouping - measureOfRenderedGroupings;
      } else {
        // find all rowData entries for columns /other/ than the ones requested
        row[otherIndex] = 0;
        otherColumns.forEach((col) => {
          row[otherIndex] += _.get(rowData, col, 0);
        });
      }
    }
    return row;
  });

  const data = {
    columns: dataToRenderColumns,
    rows: dataToRenderRows
  };

  // Apply the date display format if specified
  const dateDisplayFormat = _.get(state.vif, 'series[0].dataSource.dateDisplayFormat');

  if (!_.isNil(dateDisplayFormat)) {
    data.dateDisplayFormat = dateDisplayFormat;
  }

  return data;
};

export async function getData(vif, options) {
  options.MAX_GROUP_COUNT = options.MAX_GROUP_COUNT || MAX_GROUP_COUNT;

  const initialState = {
    vif,
    options,
    columnName: _.get(vif, 'series[0].dataSource.dimension.columnName', null),
    // Grouping is only valid on the first defined series, and will
    // override any additional series.
    groupingColumnName: _.get(vif, 'series[0].dataSource.dimension.grouping.columnName', null),
    groupingValues: null,
    groupingVifs: null,
    soqlDataProvider: new SoqlDataProvider(
      {
        datasetUid: _.get(vif, 'series[0].dataSource.datasetUid', null),
        domain: _.get(vif, 'series[0].dataSource.domain', null),
        clientContextVariables: getParameterOverrides(vif)
      },
      true
    )
  };

  // If there is no grouping column name we shouldn't have gotten to this point
  // in the first place, but we can just quit early with here as a backup.
  if (initialState.columnName === null || initialState.groupingColumnName === null) {
    return { columns: [], rows: [] };
  }

  return Promise.resolve(initialState)
    .then(addDimensionValuesToState)
    .then(addGroupingValuesToState)
    .then(makeGroupingDataRequests)
    .then(mapGroupingDataResponsesToMultiSeriesTable)
    .then((dataTable) => applyOrderBy(dataTable, vif));
}

export default {
  MAX_GROUP_COUNT,
  getData
};
