import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Button, { VARIANTS } from 'common/components/Button';
import { ALERT_SETTING_MODAL_TABS } from 'common/notifications/constants';

import './tabs.scss';

export interface AlertSettingTabProps {
  selectedTab: ALERT_SETTING_MODAL_TABS;
  onTabChange: (ALERT_SETTING_MODAL_TABS: string) => void;
  type: ALERT_SETTING_MODAL_TABS;
  name: string;
}

const AlertSettingTab: FunctionComponent<AlertSettingTabProps> = (props) => {
  const { selectedTab, onTabChange, type, name } = props;

  return (
    <li
      className={classNames({ 'active': selectedTab === type })}>
      <Button variant={VARIANTS.LINK} className="nav-link" onClick={() => onTabChange(type)} >
        {name}
      </Button>
    </li>
  );
};

export default AlertSettingTab;
