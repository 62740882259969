import _ from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';

export const SOURCES = Object.freeze({
  SHAPES: 'shapes-source'
});

export const LAYERS = Object.freeze({
  SHAPES_OUTLINE: 'shapes-line-layer',
  SHAPES_FILL: 'shapes-fill-layer'
});

// Renders shapes retrieved by the tile data call. On vif update, it updates
// the render/layout properties of the shapes.
export default class Shapes {
  constructor(map, seriesId) {
    this._map = map;

    this.layerIds = MapHelper.getNameToIdMap(_.values(LAYERS), seriesId);
    this.sourceIds = MapHelper.getNameToIdMap(_.values(SOURCES), seriesId);
  }

  alreadySetup() {
    return this._map.getSource(this.sourceIds[SOURCES.SHAPES]);
  }

  setup(vif, renderOptions, overlayOptions) {
    this._map.addSource(this.sourceIds[SOURCES.SHAPES], this.sourceOptions(vif, renderOptions));

    this._map.addLayer({
      'id': this.layerIds[LAYERS.SHAPES_FILL],
      'type': 'fill',
      'source': this.sourceIds[SOURCES.SHAPES],
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': vif.isLayerVisible() ? 'visible' : 'none'
      },
      'paint': {
        'fill-color': vif.getFeatureColor(renderOptions),
        'fill-opacity': vif.getShapeFillOpacity()
      }
    }, overlayOptions.renderLayersBefore);

    this._map.addLayer({
      'id': this.layerIds[LAYERS.SHAPES_OUTLINE],
      'type': 'line',
      'source': this.sourceIds[SOURCES.SHAPES],
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': vif.isLayerVisible() ? 'visible' : 'none'
      },
      'paint': {
        'line-color': vif.getShapeOutlineColor(),
        'line-width': vif.getShapeOutlineWidth()
      }
    }, overlayOptions.renderLayersBefore);
  }

  update(vif, renderOptions) {
    this._map.setLayoutProperty(this.layerIds[LAYERS.SHAPES_FILL],
      'visibility',
      vif.isLayerVisible() ? 'visible' : 'none'
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.SHAPES_FILL],
      'fill-color',
      vif.getFeatureColor(renderOptions)
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.SHAPES_FILL],
      'fill-opacity',
      vif.getShapeFillOpacity()
    );
    this._map.setLayoutProperty(this.layerIds[LAYERS.SHAPES_OUTLINE],
      'visibility',
      vif.isLayerVisible() ? 'visible' : 'none'
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.SHAPES_OUTLINE],
      'line-color',
      vif.getShapeOutlineColor()
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.SHAPES_OUTLINE],
      'line-width',
      vif.getShapeOutlineWidth()
    );
  }

  sourceOptions(vif, renderOptions) {
    return {
      'type': 'vector',
      'geojsonTile': true,
      'tiles': [renderOptions.dataUrl]
    };
  }

  destroy() {
    _.each(this.layerIds, (layerId) => {
      this._map.removeLayer(layerId);
    });
    _.each(this.sourceIds, (sourceId) => {
      this._map.removeSource(sourceId);
    });
  }
}
