import { combineReducers } from '@reduxjs/toolkit';
import undoable, { excludeAction } from 'redux-undo';
import storyReducer from 'store/reducers/StoryReducer';
import dataSourceReducer, { populateDatasetMetadata, storySavedAfterNewSourcesAdded } from 'store/reducers/DataSourceReducer';
import { setPublishedStory, resetStoryStore, updateStory } from 'store/reducers/StoryReducer';
import { createBlockConfirmation, updateBlockConfirmation } from 'store/reducers/BlockRemovalConfirmationReducer';
import { storySaveStarted } from 'store/reducers/StorySaveStatusReducer';
import Actions from 'Actions';
import { saveStory, refreshRequiredDataSources } from '../TopLevelActions';

const NOT_UNDOABLE_ACTIONS: string[] = [
  resetStoryStore?.type,
  saveStory?.type,
  updateStory?.type,
  setPublishedStory?.type,
  createBlockConfirmation?.type,
  updateBlockConfirmation?.type,
  storySavedAfterNewSourcesAdded?.type,
  storySaveStarted?.type,
  // These are undefined in tests
  populateDatasetMetadata?.fulfilled.type,
  populateDatasetMetadata?.pending.type,
  populateDatasetMetadata?.rejected.type,
  refreshRequiredDataSources?.fulfilled.type,
  refreshRequiredDataSources?.pending.type,
  refreshRequiredDataSources?.rejected.type
];

const INITIALIZATION_ACTIONS: string[] = [Actions.STORY_CREATE, Actions.POPULATE_GLOBAL_FILTER];

const storyWithHistoryReducer = undoable(
  combineReducers({
    dataSourceReducer: dataSourceReducer,
    storyReducer: storyReducer
  }),
  {
    filter: excludeAction(NOT_UNDOABLE_ACTIONS),
    groupBy: (action) => (INITIALIZATION_ACTIONS.indexOf(action.type) >= 0 ? 'INITIALIZATION_ACTIONS' : null)
  }
);

export default storyWithHistoryReducer;
