import ThreeStateCheckbox, { INDETERMINATE, ThreeStateValue } from 'common/components/ThreeStateCheckbox';
import I18n from 'common/i18n';
import { OnAlertNotificationChange } from 'common/notifications/components/AlertSettingModal/PreferenceTable';
import PreferenceTableRowWithCheckBox from 'common/notifications/components/AlertSettingModal/PreferenceTableRowWithCheckBox';
import { NotificationsState, Preferences } from 'common/notifications/types';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';


export type MatchParamResult<T> = {type: 'error', message: string} | { type: 'ok', value: T };

export interface MatchParam<T> {
  name: string;
  type: T extends number ? 'integer' : 'list';
  default: T;
  required: boolean;
  placeholder?: string;
  key: string;
  intoString: (v: T) => string;
  validate: (s: string) => MatchParamResult<T>;
}


export interface PreferenceRow {
  preferenceKey: string;
  description: string;
  matchParams?: MatchParam<any>[];
}

interface PreferenceSectionProps {
  description?: string;
  onAlertNotificationChange: OnAlertNotificationChange;
  onSelectAllPreferenceTypeChange: (notificationKey: string, preferencesInSection: Array<string>, value: ThreeStateValue) => any;
  preferences: Preferences;
  rows: PreferenceRow[];
  title: string;
}


const scope = 'shared.site_chrome.notifications.alert_setting_modal';
const emailPreferenceKey = 'enable_email_notification';
const productPreferenceKey = 'enable_product_notification';
const productTitle = I18n.t('table_header.product_alerts', { scope });
const emailTitle = I18n.t('table_header.email_notifications', { scope });

export class PreferenceSection extends React.Component<PreferenceSectionProps> {
  render() {
    const { rows } = this.props;
    const hasMultipleRows = _.size(rows) > 1;
    return [
      this.renderDescriptionRow(),
      ...hasMultipleRows ? [
        this.renderGroupSettingsHeaderRow(),
        this.renderGroupSettingsRow(),
        this.renderSpacerRow(),
      ] : [],
      ...rows.map((row) => this.renderTableRowWithCheckbox(row))
    ];
  }

  /**
   * Get a key unique to this PreferenceSection. It is vitally important that
   * we have stable keys, otherwise keyboard focus will become very unstable
   * as React arbitrarily recycles our DOM nodes.
   *
   * Recall that this section is adding rows to a <div> shared by many other
   * PreferenceSections, and React will complain about duplicate keys.
   */
  getSectionUniqueKey(postfix: string) {
    const { title } = this.props;
    return `ps-${title}-${postfix}`;
  }


  renderDescriptionRow() {
    const { title, description } = this.props;
    const uniqueKey = this.getSectionUniqueKey('description');

    return (
      <div className="alert-preference-description-row" key={uniqueKey}>
        <div className="preference-title">{title}</div>
        <div className="description">{description}</div>
      </div>
    );
  }

  renderSpacerRow() {
    const uniqueKey = this.getSectionUniqueKey('spacer');

    return <hr key={uniqueKey} />;
  }

  renderTableRowWithCheckbox({preferenceKey, description, matchParams}: { preferenceKey: string; description: string; matchParams?: MatchParam<any>[]}) {
    const { preferences, onAlertNotificationChange } = this.props;
    const uniqueKey = this.getSectionUniqueKey(`checkboxes-${preferenceKey}`);

    return (<PreferenceTableRowWithCheckBox
      key={uniqueKey}
      preferences={preferences}
      onAlertNotificationChange={onAlertNotificationChange}
      preferenceKey={preferenceKey}
      description={description}
      matchParamSpec={matchParams}
      descriptionTextId={this.getRowDescriptionId(preferenceKey)}
    />);
  }

  renderGroupSettingsHeaderRow() {
    const uniqueKey = this.getSectionUniqueKey('group-settings-header');

    return (
      <div className="alert-preference-group-settings-header-row" key={uniqueKey}>
        <div className="alert-preferences-group-settings-column-header">
          {I18n.t('table_header.feature', { scope })}
        </div>
        <div className="alert-preferences-group-settings-column-header">
          {I18n.t('table_header.notify_by', { scope })}
        </div>
      </div>
    );
  }

  renderGroupSettingsRow() {
    const uniqueKey = this.getSectionUniqueKey('group-settings');

    return (
      <div className="alert-preference-group-settings-row" key={uniqueKey}>
        <div /> {/* Placeholder for first column */}
        <div className="alert-preference-options">
          {this.renderThreeStateCheckBox(productTitle, productPreferenceKey)}
          {this.renderThreeStateCheckBox(emailTitle, emailPreferenceKey)}
        </div>
      </div>
    );
  }

  renderThreeStateCheckBox(label: string, notificationKey: string) {
    const { onSelectAllPreferenceTypeChange, rows, title } = this.props;
    const preferencesInSection = _.map(rows, 'preferenceKey');
    const checkboxId = _.uniqueId('preference_checkbox_');
    const value = this.getGroupPreferenceState(preferencesInSection, notificationKey);

    // ARIA label wiring.
    const preferenceDescriptionIds = _.map(preferencesInSection, this.getRowDescriptionId);

    return (
      <div className="preferences-checkbox">
        <ThreeStateCheckbox
          id={checkboxId}
          ariaLabel={I18n.t('group_checkbox', { group: title, method: label, scope })}
          ariaLabelledBy={`${checkboxId} ${preferenceDescriptionIds.join(' ')}`}
          checked={value}
          onChange={() => onSelectAllPreferenceTypeChange(notificationKey, preferencesInSection, value)}>
          {label}
        </ThreeStateCheckbox>
      </div>
    );
  }

  getGroupPreferenceState(preferencesInSection: Array<string>, preferenceKey: string) {
    const { preferences } = this.props;
    const preferenceValues = _.map(_.pick(preferences, preferencesInSection), preferenceKey);
    if (_.every(preferenceValues)) {
      return true;
    } else if (_.some(preferenceValues)) {
      return INDETERMINATE;
    } else {
      return false;
    }
  }

  /**
   * In order to hook up aria labels between the group checkboxes and the rows they control, we need a predictable row description ID.
   */
  getRowDescriptionId(preferenceKey: string) {
    return `alert-preference-row-description-${preferenceKey}`;
  }
}

const mapStateToProps = (state: NotificationsState) => ({
  configurations: state.configurations,
  showMyAlertPreference: state.configurations.showMyAlertPreference
});

export default connect(mapStateToProps)(PreferenceSection);
