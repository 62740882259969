import { assign, compact, get, isEmpty, isUndefined } from 'lodash';

import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import { FILTER_FUNCTION, NoopFilter, RadiusSoqlFilter } from '../../SoqlFilter';
import * as BaseFilter from './BaseFilter';

export function getRadiusFilter(
  config: RadiusSoqlFilter | NoopFilter,
  columns: BaseFilter.FilterColumnsMap,
  center: number[],
  radius: number,
  humanReadableLocation: unknown
) {
  if (isUndefined(center) || isEmpty(center)) {
    return BaseFilter.reset(config, columns);
  } else {
    return assign({}, config, {
      function: FILTER_FUNCTION.WITHIN_CIRCLE,
      arguments: [
        {
          center,
          humanReadableLocation,
          radius,
          units: 'miles'
        }
      ]
    });
  }
}

export function getRadiusFilterHumanText(filter: RadiusSoqlFilter) {
  const center = get(filter, 'arguments[0].center');
  const humanReadableLocation = get(filter, 'arguments[0].humanReadableLocation');
  const radius = get(filter, 'arguments[0].radius');
  const units = get(filter, 'arguments[0].units');

  const allArgumentsDefined = compact([center, humanReadableLocation, radius, units]).length === 4;

  if (allArgumentsDefined) {
    return formatString(
      I18n.t('shared.components.filter_bar.radius_filter.filter_text'),
      radius,
      units,
      humanReadableLocation
    );
  }
  return I18n.t('shared.components.filter_bar.radius_filter.no_value');
}
