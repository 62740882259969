/* Imports */
/* ============================================================================= */
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { GuidanceCheckSummary } from 'common/core/approvals/Types';
import { WORKFLOWS } from 'common/core/approvals/guidanceHelpers';





/* Method */
/* ============================================================================= */

/**
 * Check a GuidanceCheckSummary object for the workflows with values that pass a matchBy function, and return them.
 * @param workflows A GuidanceCheckSummary object
 * @param matchBy (Optional, Default: (setValue) => !!setValue) Method to use for value matching
 * @returns An array of WorkflowTargetAudience values for the workflows for which matchBy returned true.
 */
export const getMatchingWorkflows = (
  workflows: GuidanceCheckSummary<any>,
  matchBy: (setValue: any) => boolean = (setValue) => !!setValue
): WorkflowTargetAudience[] => {
  return WORKFLOWS.filter((workflow) => matchBy(workflows[workflow]));
};

export default getMatchingWorkflows;
