import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { ExpandCollapseButtons } from 'common/components/ExpandCollapseButtons';
import { isTableVisualization } from 'common/visualizations/helpers/VifSelectors';
import { FeatureFlags } from 'common/feature_flags';
import { Vif, Hierarchy } from 'common/visualizations/vif';

/**
 * - Render the Group Bar UI
 * - Various helpers to manage Group Bar state and UI
 */

type UpdateVifCallback = (newVif: Vif) => void;

export const template = (): JQuery => {
  return $('<div>', { class: 'socrata-visualization-group-bar-container' });
};

const getHierarchyPathFromVif = (self: Vif) => {
  const hierarchies: Hierarchy[] = _.get(self, 'series[0].dataSource.hierarchies', []);
  const activeHierarchyId: string | undefined = _.get(self, 'series[0].dataSource.activeHierarchyId');
  const activeHierarchyIndex: number | null = hierarchies?.findIndex(
    (h: Hierarchy) => h.id == activeHierarchyId
  );

  return `series[0].dataSource.hierarchies[${activeHierarchyIndex}]`;
};

const getNodeLevelPathFromVif = (self: Vif) => {
  const path = getHierarchyPathFromVif(self);
  return `${path}.agGridOpenNodeLevel`;
};

const getOpenNodeLevel = (self: Vif): number => {
  const path = getNodeLevelPathFromVif(self);
  return _.get(self, path);
};

export const renderExpandCollapse = (
  container: JQuery<HTMLElement>,
  self: Vif,
  onVifUpdated: UpdateVifCallback
) => {
  const $expandCollapseContainer = container.find('.socrata-visualization-group-bar-container');
  const isTable = isTableVisualization(self);
  const hierarchyPath = getHierarchyPathFromVif(self);
  const vifHierarchy: Hierarchy = hierarchyPath ? _.get(self, hierarchyPath) : null;
  const hasGroupingDisabled =
    vifHierarchy == null || vifHierarchy.columnConfigurations?.every((c) => c.isGrouping == false);

  if (!isTable || hasGroupingDisabled) {
    if ($expandCollapseContainer[0]) {
      ReactDOM.unmountComponentAtNode($expandCollapseContainer[0]);
    }
    return;
  }

  const level = getOpenNodeLevel(self);

  const props = {
    level,
    onNodeLevelChange: (levelArg?: number) => {
      handleExpandCollapse(self, onVifUpdated, levelArg);
    }
  };

  ReactDOM.render(React.createElement(ExpandCollapseButtons, props), $expandCollapseContainer[0]);
};

const handleExpandCollapse = (self: Vif, updateVif: UpdateVifCallback, level?: number) => {
  const updatePath = getNodeLevelPathFromVif(self);

  const newVif = _.cloneDeep(self);
  _.set(newVif, updatePath, level);
  updateVif(newVif);
};
