import isEmpty from 'lodash/isEmpty';

/**
 * Place for various authorization and authentication helpers that correspond with Cores logic.
 */
// Core's regex that defines what is a valid email
// eslint-disable-next-line max-len
export const CORE_EMAIL_REGEX =
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";

export const getUserProfileLink = (userId, domainCname = null) => {
  if (isEmpty(userId)) {
    return null;
  }

  const path = `/profile/${userId}`;

  if (!isEmpty(domainCname)) {
    return `//${domainCname}${path}`;
  }
  return path;
};
