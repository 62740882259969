import _ from 'lodash';
import charms from 'common/resources/charms/charms.json'; // See README.md for how to update.

// When getting all available charms, these priority charms will be in the beginning of the
// array. Thereby, these priority charms gets displayed first in the charmBuckets unless
// the user makes a search for charm in colorAndCharmPicker.
const PRIORITY_CHARMS = Object.freeze([
  'map-marker-alt',
  'fire',
  'fire-extinguisher',
  'hospital',
  'hospital-symbol',
  'ambulance',
  'car-crash',
  'subway',
  'home',
  'school',
  'university',
  'church',
  'monument',
  'dollar-sign',
  'minus-circle',
  'exclamation-triangle',
  'traffic-light',
  'phone',
  'info',
  'tree',
  'recycle',
  'utensils',
  'user',
  'male',
  'female',
  'wheelchair',
  'walking'
]);

export const getAvailableCharms = () => {
  const normalCharms = _.chain(charms).keys().difference(PRIORITY_CHARMS).value();
  return PRIORITY_CHARMS.concat(normalCharms);
};

// Returns string with a single character and the character maps to the icon in the font file.

export const getCharmCode = (charmName) => {
  return validateCharm(charmName) ? String.fromCharCode(charms[charmName]) : '';
};

// Gets a path to the given icon as an SVG. Note that `domain` must be set to a Socrata-hosted domain.
// The default of `window.location.hostname` will work in most cases, but visualization code will need
// to pass in the VIF data source domain as visualizations may be embedded on 3rd party sites.
export const getCharmSvgSrc = (charmName, domain = window.location.hostname) => {
  return validateCharm(charmName) ? `//${domain}/images/charms/svg/${charmName}.svg` : '';
};

function validateCharm(charmName) {
  if (_.isUndefined(charms[charmName])) {
    console.warn(`Charm not found: ${charmName}`);
    return false;
  }
  return true;
}

const CHARMS_FONT_NAME = 'socrata-charms-v011';
export const MAPBOX_GL_CHARMS_FONT_NAME = `${CHARMS_FONT_NAME} Regular`;
