import every from 'lodash/every';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Button, { SIZES } from 'common/components/Button';
import { fetchDomainExpectations } from 'common/core/domains';
import { getCurrentDomain } from 'common/currentDomain';
import { auditDomainExpectation } from 'common/frontend/internal';
import { assessExpectation } from 'common/core/domain_expectations';
import { AssessedDomainExpectation, DomainExpectation } from 'common/core/domain_expectations/types';
import getCookie from 'common/js_utils/getCookie';

import './config-expectation-banner.scss';

const COOKIE_NAME = 'domain-expectation-banner-hidden';

const disabledViaCookie = getCookie(COOKIE_NAME) === 'true';

const ConfigExpectationBanner: FunctionComponent = () => {
  const [expectations, setExpectations] = useState<Array<AssessedDomainExpectation>>([]);
  const [userDismissed, setUserDismissed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (disabledViaCookie) return;
    async function fetchData() {
      try {
        const domainExpectations = await fetchDomainExpectations(getCurrentDomain());
        const audited: DomainExpectation[] = await Promise.all(
          domainExpectations.map(async (e: any) => {
            const settings = await auditDomainExpectation(getCurrentDomain(), e.contractKey);
            return {
              key: e.contractKey,
              settings
            };
          })
        );

        setExpectations(audited.map(assessExpectation));
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setHasError(true);
        console.error(e);
      }
    }

    fetchData();
  }, []);

  const onHideClicked = () => {
    alert('You can get it back by clearing your cookies.');
    document.cookie = `${COOKIE_NAME}=true; path=/`;
    setUserDismissed(true);
  };

  const allExpectationsMet = every(expectations, 'expectationMet');

  if (userDismissed || isLoading || (allExpectationsMet && !hasError) || disabledViaCookie) {
    return null;
  }

  return (
    <div className="config-expectation-banner-content">
      Domain Configuration Expectations
      (<a href="/internal/domains/current/config_contracts">configure</a>)
      {hasError && <div>Error fetching expectations. Check browser console.</div>}
      <ul>
        {expectations.map(e => {
          const msg = `${e.key} (${e.satisfiedSettings.length} out of ${e.allSettings.length} correctly set)`;
          return <li key={e.key}>{msg}</li>;
        })
        }
      </ul>
      <Button size={SIZES.X_SMALL} onClick={onHideClicked}>Hide for this session</Button>
    </div>
  );
};

export default ConfigExpectationBanner;
