import _ from 'lodash';
import $ from 'jquery';

import { formatValuePlainText, formatValueHTML } from '../helpers/ColumnFormattingHelpers';
import { CALENDAR } from 'common/authoring_workflow/constants';
import I18n from 'common/i18n';

// Returns the Calendar fly-out content.
// Arguments:
//   event: calendar event details <object>
//      {
//        extendedProps: {
//          row : ["test", "2019-08-07", "2019-08-09"]
//        }
//      }
//   dataToRender: <object>
//     {
//       columns: ['test', 'test1'],
//       columnFormats: {},      ,
//       rows: [
//         ['Test', '2019-01-01T09:25:14.000']
//       ]
//     };
export const getFlyoutContentForCalendarEvent = (event, dataToRender) => {
  const endDateValue = _.get(event, 'extendedProps.row[2]');
  const endDateColumnName = _.get(dataToRender, 'columns[2]');
  const startDateValue = _.get(event, 'extendedProps.row[1]');
  const startDateColumnName = _.get(dataToRender, 'columns[1]');
  const titleValue = _.get(event, 'extendedProps.row[0]');
  const titleColumnName = _.get(dataToRender, 'columns[0]');
  const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');

  const endDateString = endDateValue ?
    formatValuePlainText(endDateValue, endDateColumnName, dataToRender) :
    null;
  const startDateString = startDateValue ?
    formatValuePlainText(startDateValue, startDateColumnName, dataToRender) :
    null;
  let titleString;
  if (_.isEmpty(titleColumnName)) {
    titleString = null;
  } else if (_.isNil(titleValue)) {
    titleString = noValueLabel;
  } else {
    titleString = formatValuePlainText(titleValue, titleColumnName, dataToRender);
  }

  const $flyoutContent = $('<div>');

  if (!_.isEmpty(titleString)) {
    const $titleDiv = $('<div>', { 'class': 'socrata-flyout-title' }).text(titleString);
    $flyoutContent.append($titleDiv);
  }

  let $dateContent = $('<div>', { 'class': 'socrata-flyout-event-date' });
  const $formattedAdditionalColumnContent = formatAdditionalColumnContent(event.extendedProps, dataToRender);

  $flyoutContent.append($dateContent);
  $flyoutContent.append($formattedAdditionalColumnContent);
  if (!_.isEmpty(startDateString) && !_.isEmpty(endDateString)) {
    $dateContent.text(`${startDateString} - ${endDateString}`);
  } else {
    $dateContent.text(`${startDateString || endDateString}`);
  }

  return $flyoutContent;
};

function formatAdditionalColumnContent(extendedProps, dataToRender) {
  const tbodyContent = _.map(extendedProps.row, (extendedProp, index) => {
    const columnName = _.get(dataToRender.columns, [index]);
    const columnFormat = _.get(dataToRender.columnFormats, [columnName]);
    const columnValue = _.get(extendedProps.row, [index]);
    const columnType = _.get(columnFormat, 'dataTypeName');
    const columnDisplayStyle = _.get(columnFormat, 'format.displayStyle', '');
    const isUrlColumnType = (columnType === 'url') || (columnDisplayStyle === 'url');
    let columnValueContent;

    if (!_.isNumber(columnValue) && _.isEmpty(columnValue)) {
      columnValueContent = I18n.t('shared.visualizations.charts.common.no_value');
    } else {
      columnValueContent = formatValuePlainText(columnValue, columnName, dataToRender);
      if (isUrlColumnType) {
        columnValueContent = formatValueHTML(columnValue, columnName, dataToRender);
      }
    }

    if (index > CALENDAR.DEFAULT_VIF_SERIES_LENGTH && !_.isEmpty(columnFormat)) {
      return '<tr class="socrata-flyout-row">' +
        `<td class="socrata-flyout-cell">${columnFormat.name}</td>` +
        `<td class="socrata-flyout-cell calendar-cell-content">${columnValueContent}</td>` +
      '</tr>';
    }
  }).join('');

  return _.isEmpty(tbodyContent) ?
    '' : `<table class="socrata-flyout-flyout-measures-table">${tbodyContent}</table>`;
}
