import { Option } from 'ts-option';
import _ from 'lodash';
import { Expr, TypedExpr } from 'common/types/soql';
import { flattenArgs, flattenArgsNA, zipArgs, zipArgsNA } from '../components/visualNodes/EditBooleanCombinator';
import { ColumnSubset } from '../components/ColumnPicker';
import { Operator } from '../components/visualNodes/Types';
import { Eexpr, EexprNA, FilterType } from '../types';

export const getColumnSubset = (filterType?: FilterType, hasGroupOrAggregate?: boolean): ColumnSubset | undefined => {
  if (filterType === FilterType.HAVING) return ColumnSubset.AggregatedOrGrouped;
  if (filterType === FilterType.WHERE && hasGroupOrAggregate) return ColumnSubset.NotAggregatedOrGrouped;
};

export const getFilterCount = (expr: Expr): number =>  {
  // If current expr is an AND/OR combinator
  //   We iterate through its arguments and get the sum of their counts
  // If expr is a funcall that's NOT an AND/OR combinator
  //   We count it as 1 filter and return
  // Otherwise
  //   Return zero, because we're on something like a column ref
  if (expr && expr.type === 'funcall') {
    if (expr.function_name === Operator.AND || expr.function_name === Operator.OR) {
      return expr.args.reduce((acc: number, arg: Expr) => acc + getFilterCount(arg), 0);
    }
    return 1;
  }
  return 0;
};

export const getLayerCount = (eexpr: Eexpr<Expr, TypedExpr>): number =>  {
  // Finds the max depth of nested AND/ORs that we have:
  //
  // If current expr is an AND/OR combinator
  //   We iterate through its arguments and get the max count any of them has
  //   And return that + 1 (which counts our current layer)
  // Otherwise
  //   Return zero, because we only count AND/OR combinators as layers
  const expr = eexpr.untyped;
  if (expr && expr.type === 'funcall') {
    if (expr.function_name === Operator.AND || expr.function_name === Operator.OR) {
      const args = flattenArgs(zipArgs(eexpr), expr);
      return 1 + args.reduce((acc: number, arg: Eexpr<Expr, TypedExpr>) => Math.max(acc, getLayerCount(arg)), 0);
    }
  }
  return 0;
};
export const getLayerCountNA = (eexpr: EexprNA<TypedExpr>): number =>  {
  // Finds the max depth of nested AND/ORs that we have:
  //
  // If current expr is an AND/OR combinator
  //   We iterate through its arguments and get the max count any of them has
  //   And return that + 1 (which counts our current layer)
  // Otherwise
  //   Return zero, because we only count AND/OR combinators as layers
  const expr = eexpr.expr;
  if (expr && expr.type === 'funcall') {
    if (expr.function_name === Operator.AND || expr.function_name === Operator.OR) {
      const args = flattenArgsNA(zipArgsNA(eexpr), expr);
      return 1 + args.reduce((acc: number, arg: EexprNA<TypedExpr>) => Math.max(acc, getLayerCountNA(arg)), 0);
    }
  }
  return 0;
};

export const getOutermostCombinator = (eexprOption: Option<Eexpr<Expr, TypedExpr>>): Operator | undefined => {
  return eexprOption.match(({
    some: (eexpr) => {
      const outermostFn = _.get(eexpr, 'untyped.function_name');
      if (Object.values(Operator).includes(outermostFn)) {
        return outermostFn;
      }
      return undefined;
    },
    none: () => undefined
  }));
};
export const getOutermostCombinatorNA = (eexprOption: Option<EexprNA<TypedExpr>>): Operator | undefined => {
  return eexprOption.match(({
    some: (eexpr) => {
      const outermostFn = _.get(eexpr, 'expr.function_name');
      if (Object.values(Operator).includes(outermostFn)) {
        return outermostFn;
      }
      return undefined;
    },
    none: () => undefined
  }));
};
