// Vendor Imports
import PropTypes from 'prop-types';
import React from 'react';

export interface FilterHeaderProps {
  children?: React.ReactNode;
  name: string;
}

export default function FilterHeader({ children, name }: FilterHeaderProps) {
  return (
    <div className="filter-control-title">
      <h3>{name}</h3>
      {children}
    </div>
  );
}

FilterHeader.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string
};
