import { Expr } from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { buildColumnRef } from './expr';
import { QualifiedViewColumn, isString, isViewColumn, isQualifiedViewColumn, encodeStringAsColumnRef } from './util';

export const convertGroupBy = (group: QualifiedViewColumn | ViewColumn | Expr | string): Expr => {
  if (isQualifiedViewColumn(group)) {
    return buildColumnRef(group.column, group.qualifier);
  } else if (isViewColumn(group)) {
    return buildColumnRef(group);
  } else if (isString(group)) {
    return encodeStringAsColumnRef(group);
  } else {
    return group;
  }
};
