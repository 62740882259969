import $ from 'jquery';
import I18n from 'common/i18n';

export const template = (): JQuery => {
  return $('<div>', { 'class': 'socrata-visualization-panning-notice' }).text(
    I18n.t('shared.visualizations.charts.common.panning_notice')
  );
};

export const show = ($container: JQuery) => {
  $container.addClass('socrata-visualization-panning-notice');
  const $chartContainer = $container.find('.socrata-visualization-chart-container');
  $chartContainer.attr('tabindex', 0);
  $chartContainer.attr('aria-label', I18n.t('shared.visualizations.charts.common.keyboard_panning_notice'));
};

export const hide = ($container: JQuery) => {
  $container.removeClass('socrata-visualization-panning-notice');
  const $chartContainer = $container.find('.socrata-visualization-chart-container');
  $chartContainer.removeAttr('tabindex');
  $chartContainer.removeAttr('aria-label');
};
