import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import './index.scss';

import {
  ForgeAvatar,
  ForgeDivider,
  ForgeIcon,
  ForgeIconButton,
  ForgeList,
  ForgeListItem,
  ForgeExpansionPanel,
  ForgeOpenIcon,
} from '@tylertech/forge-react';
import DomainRights from 'common/types/domainRights';
import MobileCreateAssetsDialog from '../mobile_create_assets_drawer';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import optionallyLocalizeUrls from '../../utils/optionally_localize_urls';

interface MobileActionPageProps {
  open: boolean;
  usersRights: DomainRights;
  closeCallback: () => void;
}

interface Locale {
  url: string;
  text: string;
}

const MobileActionPage: FunctionComponent<MobileActionPageProps> = ({
  open,
  usersRights,
  closeCallback
}) => {
  const availableLocales = _.get(window, 'socrata.availableLocales', []);
  const currentUser = _.get(window, 'socrata.currentUser', {});
  const currentLocale = _.get(window, 'socrata.locale');
  const scope = 'shared.site_chrome.forge_omnibar';
  const { email, profile_image, displayName } = currentUser;
  const [showDialog, setShowDialog] = useState(false);
  const canCreateDatasets = _.includes(usersRights, DomainRights.create_datasets);
  const canCreateStories = _.includes(usersRights, DomainRights.create_story) && FeatureFlags.value('stories_enabled');
  const canCreateMeasures = _.includes(usersRights, DomainRights.create_measures);
  const createAssetsDrawer = () => {
    setShowDialog(true);
    closeCallback();
  };

  const localeList = availableLocales.map((locale: Locale, i: number) => {
    return (
      <ForgeListItem indented onClick={() => location.href = locale.url} key={i}>
        <ForgeIcon name="translate" slot="leading" />
        {locale.text}
      </ForgeListItem>
    );
  });

  return (
    <>
      {open ? (
        <div className='more-action-page'>
          <div className='more-action-page-header'>
            <ForgeIconButton id='close-more-action-page'>
              <button
                type="button"
                aria-label="View user profile"
                className="tyler-icons"
                onClick={() => closeCallback()}>
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          </div>
          <ForgeList>
            <div className='more-action-page-profile'>
              <ForgeAvatar image-url={profile_image ? profile_image : null} text={displayName}></ForgeAvatar>
              <span className='forge-typography--subtitle1'>{displayName}</span>
              <span className='forge-typography--subtitle2'>{email}</span>
            </div>
            <ForgeDivider />
            {canCreateDatasets || canCreateStories || canCreateMeasures ? (
              <>
                <ForgeListItem onClick={() => createAssetsDrawer()}>
                  <ForgeIcon name="add_circle_outline" slot="leading"></ForgeIcon>
                  <span>{I18n.t(`${scope}.create_assets.title`)}</span>
                  <ForgeIcon name="keyboard_arrow_right" slot="trailing"></ForgeIcon>
                </ForgeListItem>
                <ForgeDivider />
              </>
            ) : null}
            {availableLocales.length > 0 ? (
              <ForgeExpansionPanel>
                <ForgeListItem slot="header">
                  <ForgeIcon name="translate" slot="leading" />
                  {I18n.t(`${scope}.language`)} {`(${currentLocale.toUpperCase()})`}
                  <ForgeOpenIcon slot="trailing" />
                </ForgeListItem>
                <ForgeList>
                  {localeList}
                </ForgeList>
              </ForgeExpansionPanel>
            ) : null}
            <ForgeExpansionPanel open>
              <ForgeListItem slot="header" id="site-chrome-internal-menu-title">
                <ForgeIcon name="help" slot="leading" />
                {I18n.t(`${scope}.help_menu.title`)}
                <ForgeOpenIcon slot="trailing" />
              </ForgeListItem>
              <ForgeList>
                <ForgeListItem indented onClick={() => location.href = 'https://support.socrata.com/hc/en-us/requests/new'}>
                  <ForgeIcon name="help_outline" slot="leading" />
                  {I18n.t(`${scope}.help_menu.help_link`)}
                </ForgeListItem>
                <ForgeListItem indented onClick={() => location.href = 'https://support.socrata.com/hc/en-us'}>
                  <ForgeIcon name="menu_book" slot="leading" />
                  {I18n.t(`${scope}.help_menu.knowledge`)}
                </ForgeListItem>
                <ForgeListItem indented onClick={() => location.href = 'http://learn.socrata.com'}>
                  <ForgeIcon name="school" slot="leading" />
                  {I18n.t(`${scope}.help_menu.education`)}
                </ForgeListItem>
                <ForgeListItem indented onClick={() => location.href = '/videos'}>
                  <ForgeIcon name="video_library" slot="leading" />
                  {I18n.t(`${scope}.help_menu.quick_start_videos`)}
                </ForgeListItem>
                <ForgeListItem indented onClick={() => location.href = 'https://support.socrata.com/hc/en-us/categories/202630927'}>
                  <ForgeIcon name="campaign" slot="leading" />
                  {I18n.t(`${scope}.help_menu.release_notes`)}
                </ForgeListItem>
                <ForgeListItem indented>
                  <ForgeIcon name="code" slot="leading" onClick={() => location.href = 'https://dev.socrata.com'} />
                  {I18n.t(`${scope}.help_menu.developer_resources`)}
                </ForgeListItem>
              </ForgeList>
            </ForgeExpansionPanel>
            <ForgeDivider />
            <ForgeListItem onClick={() => location.href = optionallyLocalizeUrls('/logout')}>
              <ForgeIcon name="logout" slot="leading" />
              {I18n.t(`${scope}.navigation.sign_out`)}
            </ForgeListItem>
          </ForgeList>
        </div>
      ) : null}
      <MobileCreateAssetsDialog
        open={showDialog}
        closeDrawerCallback={() => setShowDialog(false)} />
    </>
  );
};

export default MobileActionPage;
