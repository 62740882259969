/* Imports */
/* ============================================================================= */
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { GuidanceCheckSummary } from 'common/core/approvals/Types';
import { getWorkflowValues } from '.';





/* Method */
/* ============================================================================= */
/**
 * Check that a GuidanceCheckSummary object has at least 1 workflow value which matches the `value` parameter.
 * If a specific WorkflowTargetAudience value was passed,
 * check that the GuidanceCheckSummary has a matching value for that specific WorkflowTargetAudience attribute
 * @param workflows GuidanceCheckSummary object
 * @param value The value to match WorkflowTargetAudience attributes against
 * @param workflow (optional) A specific WorkflowTargetAudience value to check for a truthy value in the GuidanceBooleanCheckSummary object
 * @returns boolean
 */
export const checkForWorkflowValueMatch = (workflows: GuidanceCheckSummary<any>, value: any, workflow?: WorkflowTargetAudience): boolean => {
  return (
    // Check `value` against the specific workflow value or that it exists in the workflow values
    workflow
      ? workflows[workflow] === value
      : getWorkflowValues(workflows).indexOf(value) !== -1
  );
};

export default checkForWorkflowValueMatch;
