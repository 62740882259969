import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { assert, assertHasProperty } from 'common/assertions';
import { getVifSeriesDatasets } from 'common/visualizations/helpers/VifHelpers';

import Constants from 'lib/Constants';
import { flyoutRenderer } from 'lib/components/FlyoutRenderer';
import StorytellerUtils from 'lib/StorytellerUtils';

import { InSituVisualizationProps, FlyoutEvent } from './types';
import './shared/componentBase';
import StoryVisualization from 'lib/components/StoryVisualization';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';

$.fn.componentSocrataVisualizationInSitu = componentSocrataVisualizationInSitu;

/*
  Component format:
  {
   type: "socrata.visualization.barChart",
   value: {
     vif: <vif object>
   }
  }
*/

export default function componentSocrataVisualizationInSitu(props: InSituVisualizationProps) {
  props = _.extend({}, props, {
    isFilterableAsset: true,
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    }
  });

  if (props.componentData.type === 'socrata.visualization.pieChart') {
    props.resizeOptions.minHeight = Constants.MINIMUM_COMPONENT_HEIGHTS_PX.PIE_CHART;
    props.defaultHeight = Constants.DEFAULT_PIE_CHART_HEIGHT;
  }

  const $this = $(this);
  const { componentData, editMode } = props;

  const supportedComponentDataTypes = [
    'socrata.visualization.barChart',
    'socrata.visualization.columnChart',
    'socrata.visualization.calendar',
    'socrata.visualization.comboChart',
    'socrata.visualization.histogram',
    'socrata.visualization.pieChart',
    'socrata.visualization.scatterChart',
    'socrata.visualization.timelineChart'
  ];

  assertHasProperty(props, 'componentData.type');
  assert(
    _.includes(supportedComponentDataTypes, componentData.type),
    `componentSocrataVisualizationInSitu: Unsupported component type ${componentData.type}`
  );

  if ($this.children().length === 0) {
    _renderTemplate($this, props);
  }

  _updateVisualization($this, props);
  $this.componentBase(props);

  return $this;
}

function _renderTemplate($element: JQuery, props: InSituVisualizationProps) {
  const { componentData } = props;
  const classes = StorytellerUtils.typeToClassesForComponentType(componentData.type);
  const $componentContent = $('<div>', { class: 'component-content' });
  const flyoutEvent = 'SOCRATA_VISUALIZATION_FLYOUT';

  assertHasProperty(componentData, 'type');

  $element
    .addClass(classes)
    .on('destroy', () => {
      $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');
    })
    .on(flyoutEvent, (e: JQuery.Event) => {
      const event = e as FlyoutEvent;
      const payload = event.originalEvent.detail;

      if (payload !== null) {
        flyoutRenderer.render(payload);
      } else {
        flyoutRenderer.clear();
      }
    });

  $element.append($componentContent);
}

function _updateVisualization($element: JQuery, props: InSituVisualizationProps) {
  assertHasProperty(props, 'componentData.value.vif');
  const $componentContent = $element.find('.component-content');

  const vif = props.componentData.value.vif;
  props.dataSources = getVifSeriesDatasets(vif);

  ReactDOM.render(
    <Provider store={StorytellerReduxStore}>
      <StoryVisualization storyVif={vif} />
    </Provider>,
    $componentContent[0]
  );
}
