import $ from 'jquery';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import I18n from 'common/i18n';
import {
  RELATIVE_FILTERS,
} from 'common/dates';

// The required padding between filter bar's end and the page end.
const FILTER_BAR_MIN_PADDING_FROM_PAGE_END = 200;
// If there is not enough padding or if the filter bar is cut off from
// the page, we add padding to this parent container class.
const FILTER_BAR_PADDING_CONTAINER_SELECTOR = '.user-story-container';

export const hasClickedOnAnotherFilterBarsToggleButton = (verticalFilterBarRef: HTMLDivElement, event: Event) => {
  if (verticalFilterBarRef) {
    const isClickedWithinCurrentComponent = verticalFilterBarRef.contains(event.target as Element);
    const isClickedOnFilterBarToggleButton = $(event.target as Element).hasClass('vertical-filter-bar-toggle-button');
    const isClickedOnFilterBarButton =
      $(event.target as Element).closest('.vertical-filter-bar-toggle-button').length > 0;
    const isClickedWithinStoryContainer =
      $(event.target as Element).closest(FILTER_BAR_PADDING_CONTAINER_SELECTOR).length > 0;

    return (
      !isClickedWithinCurrentComponent
      && (isClickedOnFilterBarToggleButton || isClickedOnFilterBarButton)
      && isClickedWithinStoryContainer
    );
  }
};

export const addPaddingIfElementOverflowPage = (verticalFilterBarRef: HTMLDivElement, showFilterBar: boolean) => {
  const $paddingContainer = $(verticalFilterBarRef).parents(FILTER_BAR_PADDING_CONTAINER_SELECTOR);
  const $filterBarContainerElem = $(verticalFilterBarRef).find('.filter-bar-container');

  if (!$paddingContainer[0] || !$filterBarContainerElem[0]) {
    return;
  }

  const filterBarContainerBoundingRect = $filterBarContainerElem[0].getBoundingClientRect();
  const filterBarEndingY = filterBarContainerBoundingRect.y + window.scrollY + filterBarContainerBoundingRect.height;

  const totalBodyHeight = document.body.offsetHeight;
  const requiredPadding = filterBarEndingY + FILTER_BAR_MIN_PADDING_FROM_PAGE_END - totalBodyHeight;

  if (showFilterBar && requiredPadding > 0) {
    $paddingContainer.css({ marginBottom: `${requiredPadding}px` });
  } else {
    $paddingContainer.css({ marginBottom: '0px' });
  }
};

export const isInsideFlannels = (e: Event, flannelElements: any[]) => {
  // Are there any flannelElements that contain event.target?
  const insideFlannels = _.chain(flannelElements)
    .compact()
    .map(ReactDOM.findDOMNode)
    .invokeMap('contains', e.target)
    .some()
    .value();

  /*
    the third-party library used for DateRangePicker
    adds the calendar element on the page body but not within the CalendarDateFilter div element.
    As a result, clicking on the calendar is considered to be outside of the CalendarDateFilter div element
    and dismisses it.
  */
  const calendarFilterElement = document.querySelector('.calendar-date-filter');
  const datePickerElement = document.querySelector('.react-datepicker__tab-loop');
  const isInsideDatePicker = datePickerElement && datePickerElement.contains(e.target as Element);
  const isClickedOnDatePickerDay = calendarFilterElement
    && _.isString((e.target as Element).className)
    && (e.target as Element).className.includes('react-datepicker__day');

  // adds a similar check for ForgeDatePicker and ForgeDateRangePicker component
  const forgeDatepickerElement = document.querySelector('.forge-calendar-dropdown__popup');
  const isInsideForgeDatePicker = forgeDatepickerElement && forgeDatepickerElement.contains(e.target as Element);

  // adds a similar check for ForgeSelect component
  // make sure to add custom popup class by adding this custom css class as popupClasses prop to ForgeSelect
  const forgeSelectPopupElement = document.querySelector('.forge-select__popup');
  // By the time this check is run, the event target element (forge-list-item) have (or likely to have) changed,
  // so popupElement.contains may return false. To workaround this issue, we get the target element by ID.
  const targetElementId = (e.target as Element).id || '';
  const targetElementById = targetElementId !== '' ? document.getElementById(targetElementId) : null;
  const isInsideForgeSelectPopup = forgeSelectPopupElement
    && targetElementById
    && forgeSelectPopupElement.contains(targetElementById);

  // If none of the flannelElements contain event.target, click event is outside flannels
  if (
    !insideFlannels
    && !isInsideDatePicker
    && !isClickedOnDatePickerDay
    && !isInsideForgeDatePicker
    && !isInsideForgeSelectPopup
  ) {
    return false;
  } else {
    return true;
  }
};

export const defaultRelativeDateOptions = () => {
  const scope = 'shared.components.filter_bar.calendar_date_filter';
  const fyFiltersEnabled = _.get(window, 'socrata.fiscalYearConfig.fy_filters_enabled', false);
  const options = [
    { label: I18n.t('relative_periods.today', { scope }), value: RELATIVE_FILTERS.TODAY },
    { label: I18n.t('relative_periods.yesterday', { scope }), value: RELATIVE_FILTERS.YESTERDAY },
    { label: I18n.t('relative_periods.this_week', { scope }), value: RELATIVE_FILTERS.THIS_WEEK },
    { label: I18n.t('relative_periods.this_month', { scope }), value: RELATIVE_FILTERS.THIS_MONTH },
    { label: I18n.t('relative_periods.this_quarter', { scope }), value: RELATIVE_FILTERS.THIS_QUARTER },
    // "This Year" options get added at this position
    { label: I18n.t('relative_periods.date_to_today', { scope }), value: RELATIVE_FILTERS.DATE_TO_TODAY },
    // "Last..." used to be called "Custom".
    // Label was updated but internal value was kept as is since it is used elsewhere.
    { label: I18n.t('relative_periods.last', { scope }), value: RELATIVE_FILTERS.CUSTOM }
  ];

  // insert appropriate "This Year" options based on whether FY Filters are enabled
  if (fyFiltersEnabled) {
    options.splice(-2, 0,
      { label: I18n.t('relative_periods.this_calendar_year', { scope }), value: RELATIVE_FILTERS.THIS_CALENDAR_YEAR },
      { label: I18n.t('relative_periods.this_fiscal_year', { scope }), value: RELATIVE_FILTERS.THIS_FISCAL_YEAR }
    );
  } else {
    options.splice(-2, 0, { label: I18n.t('relative_periods.this_year', { scope }), value: RELATIVE_FILTERS.THIS_YEAR });
  }

  return options;
};


