/* Imports */
/* ============================================================================= */
import { WorkflowGuidance } from 'common/types/approvals';

import { executeApprovalsRequestV2 } from 'common/core/approvals/guidanceLogic';





/* Method */
/* ============================================================================= */
/**
 * Executes an update published asset request using the given WorkflowGuidance object's `toUpdatePublishedAsset` guidance
 *
 * @param guidance WorkflowGuidance object
 * @returns A Promise object
 */
export const updatedPublishedAssetRequestV2 = (guidance: WorkflowGuidance) => {
  if (!guidance.toUpdatePublishedAsset) {
    return Promise.reject("key 'toUpdatePublishedAsset' not found in guidance response");
  }

  return executeApprovalsRequestV2(guidance, guidance.toUpdatePublishedAsset);
};

export default updatedPublishedAssetRequestV2;
