import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import DisablePreview from './disable_preview';
import StyleguideDropdown from './styleguide-dropdown';
import StatefulAutocomplete from 'common/autocomplete/components/StatefulAutocomplete';
import { getBrowseOrSIAMUrl } from 'common/autocomplete/Util';
import { Key } from 'common/types/keyboard/key';

export default function SiteChrome() {
  let siteChromeTemplate;
  let $siteChromeContainer;
  let $siteChromeHeader;
  let $siteChromeHeaderDesktopNav;
  let $siteChromeHeaderMobileNav;
  let $siteChromeMobileMenu;
  let $siteChromeMobileMenuToggle;
  let $collapsibleSearchToggle;
  let $vanishingSearchboxInput;
  let navLinkFullWidth;
  let navbarRightWidth;
  let initialBodyOverflowY;
  let $siteChromeAdminHeader;
  let $siteChromeHeaderAndAdminHeader;
  let $siteChromeMobileMenuCloseBtn;

  $(function() {
    DisablePreview();
    StyleguideDropdown();

    $siteChromeContainer = $('#site-chrome-container');
    $siteChromeHeader = $('#site-chrome-header');
    $siteChromeAdminHeader = $('#site-chrome-admin-header');
    $siteChromeHeaderAndAdminHeader = $siteChromeHeader.add($siteChromeAdminHeader);
    $siteChromeHeaderDesktopNav = $siteChromeHeader.find('nav.desktop');
    $siteChromeHeaderMobileNav = $siteChromeHeader.find('nav.mobile');
    $siteChromeMobileMenu = $siteChromeHeader.find('.mobile-menu');
    $siteChromeMobileMenuToggle = $siteChromeHeader.find('#site-chrome-menu-toggle-btn');
    $siteChromeMobileMenuCloseBtn = $siteChromeHeader.find('.menu-close-btn');
    // Not sure if this is supposed to be leaking onto window.
    // Ignoring for lintapalooza.
    $collapsibleSearchToggle = $siteChromeHeaderAndAdminHeader.find('.collapsible-search-toggle');
    $vanishingSearchboxInput = $siteChromeHeaderAndAdminHeader.find('.searchbox-input.vanishing');
    siteChromeTemplate = $siteChromeHeader.attr('template');
    navLinkFullWidth = $siteChromeHeaderDesktopNav.find('.site-chrome-nav-links').width();

    if (siteChromeTemplate === 'evergreen') {
      navbarRightWidth = $siteChromeHeader.find('.evergreen-link-cluster').width();
    } else if (siteChromeTemplate === 'rally') {
      navbarRightWidth = $siteChromeHeader.find('.navbar-right').width();
    }

    initialBodyOverflowY = $('body').css('overflow-y') || 'visible';

    verticallyPositionSearchbar();
    checkMobileBreakpoint();
    $(window).on('resize', checkMobileBreakpoint);
    $siteChromeMobileMenuToggle.on('click', toggleMobileMenu);
    $siteChromeMobileMenuCloseBtn.on('click', closeMobileMenu);
    $siteChromeMobileMenu.keydown((e) => {
      if (
        e.key === Key.Escape_MSIE ||
        e.key === Key.Escape
      ) {
        closeMobileMenu();
        e.preventDefault();
      }
    });
    closeMobileMenu(); // Ensure initial state is closed & hidden from a11y tree.

    $collapsibleSearchToggle.
      on('click', toggleCollapsibleSearch).
      on('keypress', toggleCollapsibleByKeypress);

    $vanishingSearchboxInput.on('keyup', toggleSearchButton);
  });

  function updateAutocomplete() {
    const container = $siteChromeMobileMenu.find('div.searchbox')[0];

    if (!container) {
      return;
    }

    const options = {
      animate: false,
      mobile: true,
      disabled: !$siteChromeMobileMenu.hasClass('active'),
      onChooseResult: (searchTerm) => {
        window.location.href = getBrowseOrSIAMUrl(searchTerm, true /* anonymous */);
      }
    };

    ReactDOM.render(<StatefulAutocomplete options={options} />, container);
  }

  function toggleMobileMenu() {
    if ($siteChromeMobileMenu.hasClass('active')) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  }

  function openMobileMenu() {
    findLinksToDisableWhenMenuClosed().each((i, element) => {
      const originalHref = element.getAttribute('data-href');
      element.removeAttribute('data-href');
      element.setAttribute('href', originalHref);
    });
    $siteChromeMobileMenuCloseBtn.prop('disabled', false);
    $siteChromeMobileMenu.addClass('active');
    $siteChromeMobileMenu.attr('aria-hidden', 'false');
    $siteChromeMobileMenuToggle.attr('aria-expanded', 'true');
    // Disable body from scrolling while menu is open
    $('body').css('overflow-y', 'hidden');
    mobileLanguageSwitcher($('.mobile-language-dropdown'));
    updateAutocomplete();
    // jQuery's focus() doesn't work consistently here for some reason.
    const firstLink = $siteChromeMobileMenu.find('.menu-content a')[0];
    if (firstLink) {
      firstLink.focus();
    }
  }

  function closeMobileMenu() {
    // It's important to disable everything that has a tab-stop, since "closing"
    // the menu just means moving it off-screen.

    $siteChromeMobileMenuToggle.attr('aria-expanded', 'false');
    $siteChromeMobileMenu.removeClass('active');
    $siteChromeMobileMenu.attr('aria-hidden', 'true');
    $('body').css('overflow-y', initialBodyOverflowY);
    // Disable all links so they can't accidentally be tabbed to.
    findLinksToDisableWhenMenuClosed().each((i, element) => {
      const originalHref = element.getAttribute('href');
      element.removeAttribute('href');
      element.setAttribute('data-href', originalHref);
    });
    // Don't immediately disable the close button. If we do, screen readers will sometimes
    // announce "Unavailable" after closing the menu, which makes it sound like closing the
    // menu is unavailable, when instead focus was temporarily on a disabled button.
    setTimeout(() => {
      $siteChromeMobileMenuCloseBtn.prop('disabled', true);
    });
    updateAutocomplete();
    $siteChromeMobileMenuToggle.focus();
  }

  /**
   * When the menu is closed, we just move it off-screen. To prevent the user accidentally tabbing
   * to the closed menu (via screenreader or just plain <tab>), we need to disable all links within.
   * There is no clean way to do this (<a> tags don't support a "disabled" attr) that guarantees
   * screen readers won't register the links, other than removing the "href" attr. Note aria-hidden
   * does *not* actually hide the links from tab order.
   */
  function findLinksToDisableWhenMenuClosed() {
    return $siteChromeMobileMenu.find('.menu-content a');
  }

  function mobileLanguageSwitcher($div) {
    $div.children('.mobile-language-dropdown-title').on('click', function() {
      $div.children('.mobile-language-dropdown-options').slideToggle('fast');
      // Scroll down as the dropdown options div appears
      $('.mobile-menu').animate({
        scrollTop: $('.mobile-language-dropdown-options').offset().top
      }, 'fast');
    });
  }

  function toggleCollapsibleSearch() {
    var $searchbox = $(this).siblings('.searchbox');
    $searchbox.toggleClass('expanded');
    $searchbox.find('input').trigger('focus');

    if ($searchbox.hasClass('expanded')) {
      $searchbox.attr('aria-expanded', 'true');
    }

    // Close searchbox on click outside of box
    $(document).on('mouseup', function(e) {
      if (!$searchbox.is(e.target) && $searchbox.has(e.target).length === 0) {
        $searchbox.removeClass('expanded');
        $searchbox.attr('aria-expanded', 'false');
      }
    });

    // Close searchbox on ESCAPE key
    $(document).on('keyup', function(e) {
      if (e.keyCode === 27) {
        $searchbox.removeClass('expanded');
        $searchbox.attr('aria-expanded', 'false');
      }
    });
  }

  function toggleCollapsibleByKeypress(event) {
    // 13 === ENTER, 32 === SPACE
    if ([13, 32].includes(event.which)) {
      var clickEvent = new Event('click', { 'bubbles': true });
      event.target.dispatchEvent(clickEvent);
    }
  }

  // Button appears only if text has been entered.
  function toggleSearchButton() {
    var $searchButton = $(this).closest('form').find('.search-button');
    if (this.value !== '') {
      $searchButton.fadeIn(50);
    } else {
      $searchButton.fadeOut(50);
    }
  }

  /**
   * Browsers like IE11 don't understand nested calc commands, which are used to position the searchbar
   * due to vertically aligning it with the dynamically sized logo.
   * Instead, we need to position it with javascript.
   */
  function verticallyPositionSearchbar() {
    var isMSIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0;
    var isSafari = navigator.userAgent.indexOf('Safari') !== -1;

    if (isMSIE || isSafari) {
      var $adminSearchbox = $siteChromeAdminHeader.find('.collapsible-search .searchbox');
      var $searchbox = $('header#site-chrome-header').find('.collapsible-search .searchbox');

      var positionTop;
      if ($adminSearchbox.is(':visible')) {
        positionTop = ($siteChromeAdminHeader.height() - $adminSearchbox.height()) / 2;

        $adminSearchbox.css('top', positionTop);
      }

      if ($searchbox.is(':visible')) {
        var $banner = $siteChromeHeader.find('.banner');
        positionTop = $banner.height() / 2 - $searchbox.height() / 2;

        $searchbox.css('top', positionTop);
      }
    }
  }

  function setupUSDSHeader() {
    const $siteChromeUSDSHeader = $siteChromeContainer.find('.usa-banner');
    const mobileBreakpoint = 800; // make sure this matches usds_header.scss
    const isMobile = $(window).width() < mobileBreakpoint;
    const isStoryView = $('#story-view').length > 0;
    const $customBanner = $('#custom-global-banner');
    const isCustomBanner = $customBanner.length > 0;

    const $body = $('#site-chrome-container.non-branded');
    if ($siteChromeUSDSHeader.length || isCustomBanner) {
      // guh this is SO gross but the admin header is fixed
      // the longer i work on this the more awful it becomes
      const pushContent = (expanded) => {
        if (!$siteChromeAdminHeader) return;

        // Beware! Magic numbers!
        // usds numbers should be checked against _usds_header.scss
        const usdsContractedSize = isMobile ? 56 : 28;
        const usdsExpandedSize = isMobile ? 302 : 146;
        const forgeNavDrawerMargin = 56; // check against _tyler_forge_omnibar.scss
        const contentPanelTop = 60; // True magic, not sure how this is calculated.

        // Getting header heights, avoids magic numbers for each header type.
        const headerHeight = $siteChromeAdminHeader.outerHeight() || 0; // has a regular, small, and forge size
        const storyEditBarHeight = $('#storyteller-edit-header').outerHeight() || 0;
        const $downtimeHeader = $('#story-save-error-bar, #downtime-notice-bar');
        const downtimeNoticeHeight = $downtimeHeader && $downtimeHeader.is(':visible') ? $downtimeHeader.outerHeight() : 0;
        const customBannerHeight = isCustomBanner ? $customBanner.outerHeight() : 0;

        const usdsHeaderSize = expanded ? usdsExpandedSize : usdsContractedSize;
        const usdsAndSiteChromeHeaderSize = headerHeight + (isCustomBanner ?  customBannerHeight : usdsHeaderSize);
        const usdsAndSiteChromeHeaderSizePx = `${usdsAndSiteChromeHeaderSize}px`;

        // i'm so sorry
        if ($body.length > 0 && !isStoryView) {
          $body.css('margin-bottom', usdsAndSiteChromeHeaderSizePx);
        }
        $siteChromeUSDSHeader.height(usdsHeaderSize);

        // this is extra awful. Storyteller is special and is the only thing that
        // uses site chrome but also renders its own *fixed* elements. And not just one, but many.
        // That means that just setting the margin on the site-chrome container is insufficient to push
        // it to the right part of the page when opening/closing the USDS header.
        [
          ['#storyteller-edit-header', usdsAndSiteChromeHeaderSize],
          ['#story-save-error-bar, #downtime-notice-bar', storyEditBarHeight],
          ['#story-edit #rich-text-editor-toolbar-wrapper', usdsAndSiteChromeHeaderSize + downtimeNoticeHeight],
          ['#forge-nav-drawer>forge-list:first-child', usdsHeaderSize + forgeNavDrawerMargin],
        ].forEach(([selector, top]) => {
          const $el = $(selector);
          if ($el.length > 0) {
            $el.css('margin-top', top);
          }
        });

        // Additional pushing for fixed top elements.
        $('#story-save-error-bar, #downtime-notice-bar').css('top', 'unset');
        $('#story-edit .content-panel').css('top', usdsAndSiteChromeHeaderSize + contentPanelTop);
      };

      var $expand = $siteChromeUSDSHeader.find('#expand-usds-header');
      const isCurrentlyOpen = () => $expand.attr('aria-expanded') === 'true';
      pushContent(isCurrentlyOpen());

      if ($expand) {
        $expand.off('click').click(function() {
          var isOpen = isCurrentlyOpen();
          pushContent(!isOpen);
          if (isOpen) {
            $siteChromeUSDSHeader.removeClass('expanded');
            $expand.attr('aria-expanded', 'false');
          } else {
            $siteChromeUSDSHeader.addClass('expanded');
            $expand.attr('aria-expanded', 'true');
          }
        });
      }
    }
  }

  /**
   * Check if the header should enter mobile mode based on the width of the navLinks
   * and the available width of the navbar based on the user's window size.
   */
  function checkMobileBreakpoint() {
    var roomForNavLinks;
    if (siteChromeTemplate === 'evergreen') {
      var logoWidth = $siteChromeHeader.find('a.logo').width();
      var headerContentWidth = $siteChromeHeader.find('.header-content').width();
      var headerPadding = 26; // px
      roomForNavLinks = headerContentWidth - logoWidth - navbarRightWidth - headerPadding;

      const isMobile = navLinkFullWidth > roomForNavLinks;

      if (isMobile) {
        showMobileHeaderNav();
      } else {
        showDesktopHeaderNav();
      }
    } else if (siteChromeTemplate === 'rally') {
      var rallyBottomWidth = $siteChromeHeader.find('.rally-bottom').width();
      roomForNavLinks = rallyBottomWidth - navbarRightWidth;

      var $rallyTop = $siteChromeHeader.find('.rally-top');
      var roomForRallyTopContent = $rallyTop.width();
      var rallyTopContentWidth =
        $rallyTop.find('a.logo').width() +
        $rallyTop.find('div.searchbox').width() +
        16; // padding

      if (navLinkFullWidth > roomForNavLinks || rallyTopContentWidth > roomForRallyTopContent) {
        showMobileHeaderNav();
      } else {
        showDesktopHeaderNav();
      }

    }

    setupUSDSHeader();
    // Undo initial hidden styling to hide searchbox during width calculations.
    // Prevents "flashing" of non-mobile search when on mobile.
    $siteChromeHeader.find('div.searchbox.hidden').removeClass('hidden');
  }

  function showDesktopHeaderNav() {
    // Hide mobile nav
    $siteChromeHeaderMobileNav.css('display', 'none');
    $siteChromeHeaderMobileNav.attr('aria-hidden', 'true');
    $siteChromeHeader.find('.mobile-menu').attr('aria-hidden', 'true').attr('hidden', 'true');
    // Close mobile menu if it is open
    if ($siteChromeHeader.find('.mobile-menu').hasClass('active')) {
      closeMobileMenu();
    }
    // Show desktop nav
    $siteChromeHeaderDesktopNav.css('display', 'block');
    $siteChromeHeader.find('.rally-top .searchbox').show();
    $siteChromeHeaderDesktopNav.attr('aria-hidden', 'false');
  }

  function showMobileHeaderNav() {
    // Hide desktop nav
    $siteChromeHeaderDesktopNav.css('display', 'none');
    $siteChromeHeaderDesktopNav.attr('aria-hidden', 'true');
    // Show mobile nav
    $siteChromeHeaderMobileNav.css('display', 'block');
    $siteChromeHeader.find('.rally-top .searchbox').hide();
    $siteChromeHeaderMobileNav.attr('aria-hidden', 'false');
    $siteChromeHeader.find('.mobile-menu').attr('aria-hidden', 'false').prop('hidden', false);
  }
}
