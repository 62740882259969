import React, { FunctionComponent } from 'react';

import { isPrivateAudienceDisabled } from 'common/components/AccessManager/Util';

import I18n from 'common/i18n';

import AudienceScopeChooserRadioButton from '.';

import { AudienceScope } from 'common/types/view';
import type { View } from 'common/types/view';

interface PrivateButtonProps {
  view: Partial<View>;
}

const PrivateButton: FunctionComponent<PrivateButtonProps> = ({ view }) => {
  const privateAudienceDisabled = isPrivateAudienceDisabled(view);
  const privateAudienceDisabledMessage = privateAudienceDisabled
    ? I18n.t('shared.site_chrome.access_manager.audience.cannot_make_asset_private')
    : null;

  return (
    <AudienceScopeChooserRadioButton
      view={view}
      disabled={privateAudienceDisabled}
      hoverMessage={privateAudienceDisabledMessage}
      scope={AudienceScope.Private}
    />
  );
};

export default PrivateButton;
