import _ from 'lodash';

import * as genericContentFormatter from './genericContentFormatter';
import * as mapConstants from 'common/visualizations/views/mapConstants';

// Sample Line Feature: (Geojson object got from mapbox-gl map)
// {
//  "type": "Feature",
//  "geometry": {
//    "type": "LineString",
//    "coordinates": [
//    [-122.44754076004028, 37.8044394394888],
//    [-122.44754076004029, 37.8044394394887],
//    ]
//  },
//  "properties": {
//    "__count_by__": 245690
//    "min_id": row-skje.is7r_6t96
//  },
//  "layer": { ... }
// }

// Builds html tipsy content for a point.
export async function setPopupContentForLines(popupParams = {}) {
  const { element, features, renderOptions, vifs } = popupParams;
  // When mouse is hovered over a point where multiple lines are there.
  // We only show the popup for the topmost line which is visible to the user. So taking just the
  // first feature/vif/renderOption and ignoring the rest.
  // (NOTE: This is not the same case for points).
  const rowId = _.get(features[0].properties, renderOptions[0].idBy);

  if (!_.isUndefined(rowId)) {
    const retrieveDataConditions = [`:id ="${rowId}"`];

    return await genericContentFormatter.setPopupContent(
      element,
      vifs,
      renderOptions,
      retrieveDataConditions
    );
  }
}
