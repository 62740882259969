// Vendor Imports
import _ from 'lodash';

// Project Imports
import I18n from 'common/i18n';
import makeSocrataTimeDataRequest from './makeSocrataTimeDataRequest';
import SoqlDataProvider from './SoqlDataProvider';
import SoqlHelpers from './SoqlHelpers';
import * as ArrayHelpers from 'common/visualizations/helpers/ArrayHelpers';
import { getDefaultDateDisplayFormat } from 'common/visualizations/helpers/DateHelpers';
import { applyOrderBy } from 'common/visualizations/helpers/SortHelpers';
import { getParameterOverrides } from '../helpers/VifSelectors';

// Constants
export const MAX_GROUP_COUNT = 20;
const VALID_SORTS = ['asc', 'desc'];
const DEFAULT_SORT = 'asc';

export function getData(vif, options) {
  const isTimelineChart = (_.get(vif, 'series[0].type') === 'timelineChart');

  function addPrecisionToState(state) {
    // For grouping, we can assume that the first series is the only one.
    const seriesIndex = 0;

    return options.getPrecisionBySeriesIndex(state.vif, seriesIndex).
      then((precision) => {
        const dateDisplayFormat = _.get(vif, 'series[0].dataSource.dateDisplayFormat');

        if (_.isNil(dateDisplayFormat)) {
          state.dateDisplayFormat = getDefaultDateDisplayFormat(precision);
        } else {
          state.dateDisplayFormat = dateDisplayFormat;
        }

        state.precision = precision;

        return state;
      });
  }

  function addDateTruncFunctionToState(state) {

    state.dateTruncFunction = options.mapPrecisionToDateTruncFunction(
      state.precision
    );

    return Promise.resolve(state);
  }

  function addGroupingValuesToState(state) {
    const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(
      state.vif,
      0
    );
    const whereClause = (whereClauseComponents.length > 0) ?
      `WHERE ${whereClauseComponents}` :
      '';

    let groupingOrderBy = _.get(
      state.vif,
      'series[0].dataSource.dimension.grouping.orderBy');

    if (_.isNil(groupingOrderBy)) {
      groupingOrderBy = _.get(
        state.vif,
        'series[0].dataSource.orderBy',
        { parameter: 'dimension', sort: 'asc' });
    }

    const useMeasureAggregation = (groupingOrderBy.parameter === 'measure');

    const aggregatedColumn = useMeasureAggregation ?
      SoqlHelpers.measure(state.vif, 0, { defaultAggregationFunction: 'count' }) :
      SoqlHelpers.dimension(state.vif, 0, { defaultAggregationFunction: 'count' });

    const orderByParameter = useMeasureAggregation ?
      SoqlHelpers.measureAlias() :
      SoqlHelpers.dimensionAlias();

    const orderBySort = groupingOrderBy.sort.toUpperCase();

    // EN-13888 - Multi-series timeline chart errors when adding grouping
    //
    // While it seems like it should work, using the GROUP BY with a columm
    // alias and not the column name results in a 500 response from the backend.
    //
    // EN-13909 has been filed to investigate whether or not this is expected
    // and, if not, if it can be fixed, but in the meantime we are explicitly
    // repeating the grouping column name in the GROUP BY clause.
    const queryString = [
      'SELECT',
      `\`${state.groupingColumnName}\` AS ${SoqlHelpers.dimensionAlias()}, ` +
      `${aggregatedColumn} AS ${SoqlHelpers.measureAlias()}`,
      whereClause,
      `GROUP BY \`${state.groupingColumnName}\``,
      `ORDER BY ${orderByParameter} ${orderBySort}`,
      `LIMIT ${MAX_GROUP_COUNT}`
    ].join(' ');

    return state.soqlDataProvider.query(
      queryString,
      SoqlHelpers.dimensionAlias(),
      SoqlHelpers.measureAlias()
    ).
      then((groups) => {

        state.groupingValues = groups.rows.map((row) => {

          return _.isUndefined(row[0]) ?
            null :
            row[0];
        });

        state.groupingRequiresOtherCategory = (
          state.groupingValues.length >= MAX_GROUP_COUNT
        );

        return state;
      });
  }

  function buildGroupingVifs(state) {
    state.groupingVifs = [];

    const makeGroupingVif = (filters, extras = {}) => {
      const groupingVif = _.cloneDeep(state.vif);
      const filterPath = ['series', 0, 'dataSource', 'filters'];
      const currentFilters = _.get(groupingVif, filterPath, []);
      const newFilters = currentFilters.concat(filters);
      _.set(groupingVif, filterPath, newFilters);
      return Object.assign(groupingVif, extras);
    };

    const groupingColumn = {
      columns: [{
        fieldName: state.groupingColumnName,
        datasetUid: state.vif.series[0].dataSource.datasetUid
      }],
    };

    const groupingValuesIncludeNull = _.includes(state.groupingValues, null);

    if (groupingValuesIncludeNull) {
      state.groupingVifs.push(
        makeGroupingVif([{
          function: 'isNull',
          ...groupingColumn,
          arguments: {
            isNull: true
          }
        }])
      );
    }

    const nonNullGroupingValues = _.without(state.groupingValues, null);
    const chunkedNonNullGroupingValues = ArrayHelpers.chunkArrayByLength(nonNullGroupingValues);

    chunkedNonNullGroupingValues.forEach((groupingValuesCurrentChunk) => {
      if (!_.isEmpty(groupingValuesCurrentChunk)) {
        state.groupingVifs.push(
          makeGroupingVif(
            [{
              function: 'in',
              ...groupingColumn,
              arguments: groupingValuesCurrentChunk
            }],
            {
              requireGroupingInSelect: true,
              groupingColumnName: state.groupingColumnName
            })
        );
      }

    });

    return state;
  }

  function makeGroupingDataRequests(state) {
    const dataRequestOptions = {
      dateTruncFunction: state.dateTruncFunction,
      precision: state.precision,
      maxRowCount: options.MAX_ROW_COUNT
    };
    const groupingDataRequests = state.groupingVifs.map((groupingVif) => {

      return makeSocrataTimeDataRequest(
        groupingVif,
        0,
        dataRequestOptions
      );
    });

    return Promise.all(groupingDataRequests).
      then((groupingDataResponses) => {

        let groupTable = _.reduce(
          groupingDataResponses,
          (result, response) => {
            const idxDimension = _.findIndex(response.columns, (x) => x === 'dimension');
            const idxGrouping = _.findIndex(response.columns, (x) => x === 'grouping');
            const idxMeasure = _.findIndex(response.columns, (x) => x === 'measure');
            response.rows.forEach((row) => {
              const dimension = row[idxDimension];
              const grouping = _.isNil(row[idxGrouping]) ? null : row[idxGrouping];
              const measure = row[idxMeasure];
              if (_.isUndefined(result[grouping])) {
                result[grouping] = [];
              }
              result[grouping].push([dimension, measure]);
            });
            return result;
          },
          {}
        );

        // Keys on this groupTable object are converted to strings in the
        // mapping iterator, which is OK, except that it converts null to
        // 'null'.  For this case, we want to keep it as null to keep it
        // the same as the values in state.groupingValues, which are strings
        // or null.
        state.groupingData = _.map(groupTable, (row, groupingValue) => {
          return {
            group: (groupingValue === 'null') ? null : groupingValue,
            data: {
              columns: ['dimension', 'measure'],
              rows: row
            }
          };
        });
        // XXX: Subsequent code relies on state.groupingData being ordered the
        // same as state.groupingValues, so sort it that way.
        state.groupingData.sort((a, b) => {
          return _.indexOf(state.groupingValues, a.group) - _.indexOf(state.groupingValues, b.group);
        });

        return state;
      });
  }

  function buildGroupingOtherCategoryQueryString(state) {

    // Note that this is not the same thing as an 'other' category in dimension
    // values (which is what the 'showOtherCategory' configuration flag
    // controls. Rather, 'groupingRequiresOtherCategory' indicates that there
    // are more unique groups than the maximum we allow, which maximum is
    // assigned to the MAX_GROUP_COUNT constant.
    if (!state.groupingRequiresOtherCategory) {
      return state;
    }

    const dimension = SoqlHelpers.dimension(state.vif, 0);
    const measure = SoqlHelpers.measure(state.vif, 0);
    const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(
      state.vif,
      0
    );
    const additionalGroupingWhereClauseComponents = state.groupingValues.map(
      (groupingValue) => {

        if (groupingValue === null) {
          return `(${state.groupingColumnName} IS NOT NULL)`;
        } else {
          // EN-13772 - Multiseries Timeline Error
          //
          // Some categorical values can include single quotes, which causes the
          // SoQL parser to get confused about what the parameter for the where
          // clause component is. SoQL allows for these parameters to include
          // single quotes by treating two consecutive single quotes as an
          // escaped single quote post-parse. We actually were already doing
          // this correctly in the code that was mapping filter arrays to where
          // clauses, but it's not currently flexible enough for us to simply
          // do that here as well. Ideally, the way we represent queries will at
          // some point in the future will be flexible enough to accomplish what
          // we are trying to accomplish here as well, and we can get back to
          // having a single place to worry about stuff like this.
          const encodedGroupingValue = SoqlHelpers.soqlEncodeValue(
            groupingValue
          );

          return `(
            ${state.groupingColumnName} != ${encodedGroupingValue} OR
            ${state.groupingColumnName} IS NULL
          )`;
        }
      }
    ).join(' AND ');
    const whereClause = (whereClauseComponents.length > 0) ?
      `WHERE
        ${whereClauseComponents} AND
        ${additionalGroupingWhereClauseComponents}` :
      `WHERE ${additionalGroupingWhereClauseComponents}`;
    const groupByClause = SoqlHelpers.aggregationClause(
      state.vif,
      0,
      'dimension'
    );
    const orderByClause = SoqlHelpers.orderByClauseFromSeries(
      state.vif,
      0
    );
    const queryString = [
      'SELECT',
      `${state.dateTruncFunction}(${dimension}) AS ${SoqlHelpers.dimensionAlias()},`,
      `${measure} AS ${SoqlHelpers.measureAlias()}`,
      whereClause,
      `GROUP BY ${state.dateTruncFunction}(${groupByClause})`,
      `ORDER BY ${orderByClause}`,
      `LIMIT ${options.MAX_ROW_COUNT}`
    ].join(' ');

    state.groupingOtherCategoryQueryString = queryString;

    return state;
  }

  function makeGroupingOtherCategoryRequest(state) {

    if (!state.groupingRequiresOtherCategory) {
      return state;
    }

    return state.soqlDataProvider.
      query(
        state.groupingOtherCategoryQueryString,
        SoqlHelpers.dimensionAlias(),
        SoqlHelpers.measureAlias()
      ).
      then((queryResponse) => {
        const measureIndex = 1;
        const treatNullValuesAsZero = _.get(
          state.vif,
          'configuration.treatNullValuesAsZero',
          false
        );

        let valueAsNumber;

        queryResponse.rows.
          forEach((row) => {
            const value = row[measureIndex];

            try {

              if (_.isUndefined(value)) {
                valueAsNumber = (treatNullValuesAsZero) ? 0 : null;
              } else {
                valueAsNumber = Number(value);
              }
            } catch (error) {

              console.error(
                `Could not convert measure value to number: ${value}`
              );

              valueAsNumber = null;
            }

            row[measureIndex] = valueAsNumber;
          });

        state.groupingOtherCategoryData = queryResponse;

        return state;
      });
  }

  function mapGroupedDataResponsesToMultiSeriesTable(state) {
    const dimensionIndex = 0;
    const measureIndex = 1;

    const uniqueDimensionValues = _.uniq(
      _.flatMap(
        _.map(state.groupingData, (groupingData) => {
          return _.map(groupingData.data.rows, (row) => row[dimensionIndex]);
        })
      )
    );

    // Time data on the timeline chart must only be sorted by the time,
    // and not some other measure.
    if (isTimelineChart) {
      const sortFromVif = _.toLower(
        _.get(state.vif, 'series[0].dataSource.orderBy.sort')
      );
      const sortFromVifOrDefault = (_.includes(VALID_SORTS, sortFromVif)) ?
        sortFromVif :
        DEFAULT_SORT;
      const ascendingComparator = (a, b) => (a >= b) ? 1 : -1;
      const descendingComparator = (a, b) => (a <= b) ? 1 : -1;
      const comparator = (sortFromVifOrDefault === 'asc') ?
        ascendingComparator :
        descendingComparator;
      uniqueDimensionValues.sort(comparator);
    }

    const treatNullValuesAsZero = _.get(
      vif,
      'configuration.treatNullValuesAsZero',
      false
    );

    const dataToRenderColumns = ['dimension'].concat(state.groupingValues);

    if (state.groupingRequiresOtherCategory) {

      const otherCategoryName = I18n.t(
        'shared.visualizations.charts.common.other_category'
      );

      dataToRenderColumns.push(otherCategoryName);
    }

    const dataToRenderRows = uniqueDimensionValues.map(
      (uniqueDimensionValue) => {
        const row = [uniqueDimensionValue];

        // The groupingData may not include keys for all the groupingValues.
        // In certain situations, null (or potentially other grouping values)
        // may not return data in the groupingData.  So, we loop over the
        // groupingValues to ensure the dataToRenderRows contains a column for
        // each groupingValue.  If the groupingValue is not a key in the
        // groupingData, we insert (null or 0) in that column of the row array.
        state.groupingValues.forEach((groupingValue) => {
          const groupingData = _.find(
            state.groupingData,
            (groupingDatum) => groupingDatum.group === groupingValue);

          if (_.isUndefined(groupingData)) {
            row.push((treatNullValuesAsZero) ? 0 : null);
          } else {
            const groupingRowForDimension = _.find(
              groupingData.data.rows,
              (groupingRow) => {
                return groupingRow[dimensionIndex] === uniqueDimensionValue;
              }
            );

            // The measure value is null (or zero, if the treatNullValuesAsZero
            // configuration property on the vif is set to true) if a
            // corresponding dimension value is not found in the response from the
            // backend.
            if (_.isUndefined(groupingRowForDimension)) {
              row.push((treatNullValuesAsZero) ? 0 : null);
              // Otherwise, it is the measure value from the row that corresponds to
              // the dimension value in question.
            } else {
              row.push(groupingRowForDimension[measureIndex]);
            }
          }
        });

        return row;
      }
    );

    if (state.groupingRequiresOtherCategory) {

      dataToRenderRows.forEach((dataToRenderRow) => {
        const dimensionValue = dataToRenderRow[dimensionIndex];
        const otherCategoryRow = _.find(
          state.groupingOtherCategoryData.rows,
          (groupingOtherCategoryRow) => {
            return groupingOtherCategoryRow[dimensionIndex] === dimensionValue;
          }
        );

        // The measure value is null (or zero, if the treatNullValuesAsZero
        // configuration property on the vif is set to true) if a corresponding
        // dimension value is not found in the response from the backend.
        if (_.isUndefined(otherCategoryRow)) {
          dataToRenderRow.push((treatNullValuesAsZero) ? 0 : null);
          // Otherwise, it is the measure value from the row that corresponds to
          // the dimension value in question.
        } else {
          dataToRenderRow.push(otherCategoryRow[measureIndex]);
        }
      });
    }

    state.dataToRender = {
      columns: dataToRenderColumns,
      rows: dataToRenderRows,
      precision: state.precision,
      dateDisplayFormat: state.dateDisplayFormat
    };

    return state;
  }

  const initialState = {
    columnName: _.get(vif, 'series[0].dataSource.dimension.columnName', null),
    // Grouping is only valid on the first defined series, and will override any
    // additional series.
    groupingColumnName: _.get(
      vif,
      'series[0].dataSource.dimension.grouping.columnName',
      null
    ),
    groupingValues: null,
    groupingVifs: null,
    groupingOtherCategoryUriEncodedQueryString: null,
    soqlDataProvider: new SoqlDataProvider({
      datasetUid: _.get(vif, 'series[0].dataSource.datasetUid', null),
      domain: _.get(vif, 'series[0].dataSource.domain', null),
      clientContextVariables: getParameterOverrides(vif)
    }),
    vif
  };

  // If there is no grouping column name we shouldn't have gotten to this point
  // in the first place, but we can just quit early with here as a backup.
  if (
    initialState.columnName === null || initialState.groupingColumnName === null
  ) {
    return Promise.resolve({ columns: [], rows: [] });
  }

  const promise = Promise.resolve(initialState).
    then(addPrecisionToState).
    then(addDateTruncFunctionToState).
    then(addGroupingValuesToState).
    then(buildGroupingVifs).
    then(makeGroupingDataRequests).
    then(buildGroupingOtherCategoryQueryString).
    then(makeGroupingOtherCategoryRequest).
    then(mapGroupedDataResponsesToMultiSeriesTable).
    then((state) => state.dataToRender);

  if (!isTimelineChart) {
    promise.then((dataToRender) => applyOrderBy(dataToRender, vif));
  }

  return promise;
}

export default {
  MAX_GROUP_COUNT,
  getData
};
