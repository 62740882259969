import Actions from 'Actions';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { collaboratorsReducerSelector } from 'store/TopLevelSelector';
import { MODAL_OPEN_STATE } from 'lib/Constants';
import { createAction, createReducer } from '@reduxjs/toolkit';

interface CollaboratorsReducerState {
  openStatus: string;
}

const initialState = {
  openStatus: MODAL_OPEN_STATE.CLOSED
} as CollaboratorsReducerState;

/* Action Creators */
export const openCollaboratorsModal = createAction(Actions.COLLABORATORS_MODAL_OPEN);
export const closeCollaboratorsModal = createAction(Actions.COLLABORATORS_MODAL_CLOSE);

/**
 * ENGINEER NOTE: This reducer should be remove. We should not be store component state in
 * redux. Once we convert the setting panel to React, we should remove this reducer.
 */

const collaboratorsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openCollaboratorsModal, (state) => {
      state.openStatus = MODAL_OPEN_STATE.ACCESS_MANAGER_OPEN;
    })
    .addCase(closeCollaboratorsModal, (state) => {
      state.openStatus = MODAL_OPEN_STATE.CLOSED;
    });
});

export default collaboratorsReducer;
