import React, { FormHTMLAttributes, PropsWithChildren, useRef, useEffect } from 'react';
import cx from 'classnames';

import getCsrfToken from 'common/js_utils/getCsrfToken';

type FormProps = PropsWithChildren<FormHTMLAttributes<HTMLFormElement>>;

/**
 * Generic Form component
 *
 * This will grab the CSRF/authenticity token from the page automatically.
 *
 * By default "novalidate" will be set to true, disabling in-browser validation
 * (in-browser validation is inconsistent across browsers and operating systems)
 *
 * All props will be passed down the the form element that is created.
 */
const Form = React.forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  const { children, className, onSubmit, ...formProps } = props;

  const formClassName = cx('form-component', className);

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    // if any inputs have "aria-invalid" set to true, we focus on them and prevent submitting the form
    // this is so that if the user is using a screen reader, it will read out any errors.
    //
    // The `Input` component will automatically show an error message and set aria-describedby so that
    // the screen reader knows the error message to be shown.
    //
    // For this to work properly, the `Input` must be passed `valid={false}` and `errorMessage="Some error"` props
    // when it is invalid.
    const invalidInputs = event.currentTarget.querySelectorAll<HTMLElement>('input[aria-invalid="true"]');
    if (invalidInputs.length !== 0) {
      invalidInputs[0].focus();
      event.preventDefault();
      return;
    }

    if (onSubmit) {
      onSubmit(event);
    }
  };

  return (
    <form
      {...formProps}
      className={formClassName}
      acceptCharset="UTF-8"
      encType="multipart/form-data"
      onSubmit={onFormSubmit}
      ref={ref}
    >
      <input name="utf8" type="hidden" value="✓" />
      { /* empty string for csrf token is just to appease tests. otherwise there's a warning about value being undefined */ }
      <input name="authenticity_token" type="hidden" value={getCsrfToken() || ''} />
      {children}
    </form>
  );
});

Form.defaultProps = {
  noValidate: true,
  method: 'post',
  action: ''
};

Form.displayName = 'Form'; // forwardRef changes the displayName

export default Form;
