import { get, isEmpty, isNull, includes } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import { IconName, SocrataIcon } from 'common/components/SocrataIcon';
import { ForgeBadge } from '@tylertech/forge-react';

import {
  FADE_TRANSIENT_NOTIFICATION_AFTER_MILLISECONDS,
  USER_ACTIVITY_TYPES,
  DATA_UPDATE_TYPES,
  DETAILS_STATII,
  SCHEDULE_FAILED,
  AGENT_ACTIVITY_TYPES
} from 'common/notifications/constants';

const scope = 'shared.site_chrome.notifications';

interface UserNotificationProps {
  isTransientNotification: boolean;
  notification: Notification
  onClearUserNotification: (notificationId: number) => void;
  onToggleReadUserNotification: (notificationId: number, read: boolean) => void;
  onClickAlertViewData?: (notification: any) => void;
  moveTransientNotificationIntoPanel?: (notificationId: number) => void;
  removeTransientNotification?: (notificationId: number) => void;
}

interface Notification {
  activityType: string;
  alertDomain: string;
  activityDetails: {
    newRole: string;
    oldRole: string;
    role: string;
    reason: string;
    offlineAt: Date;
    info: {
      badRowsPath: string;
    };
    agent_name: string;
    agent_uid: string;
  };
  activityUniqueKey: string;
  alertName: string;
  assetType: string;
  createdAt: Date;
  domainNameLink: string;
  domainCname: string;
  isSnoozedAlert: boolean;
  id: number;
  link: string;
  messageBody: string;
  read: boolean;
  type: string;
  userName: string;
  userProfileLink: string;
}

function UserActivityMessageBody({ notification }: { notification: Notification }) {
  const activityDetails = get(notification, 'activityDetails');
  const activityType = get(notification, 'activityType');
  const domainName = get(notification, 'domainName');
  const newRoleOfUser = get(activityDetails, 'newRole', '');
  const oldRoleOfUser = get(activityDetails, 'oldRole', '');
  const roleOfUser = get(activityDetails, 'role', '');
  const targetUserName = get(notification, 'targetUserName', '');
  const userAddedAction = I18n.t('user_added_action', { scope });
  const userRemovedAction = I18n.t('user_removed_action', { scope });
  const userRoleDescription = (isEmpty(roleOfUser) || isNull(roleOfUser)) ? '' : I18n.t('user_role_description', { scope, roleOfUser });
  const userAddedWithDomain = I18n.t('user_added_with_domain', {
    scope,
    addedAction: userAddedAction,
    domain: I18n.t('to_domain', { scope, domainName }),
    userRoleDescription
  });

  const userRemovedWithDomain = I18n.t('user_removed_with_domain', {
    scope,
    removedAction: userRemovedAction,
    domain: I18n.t('from_domain', { scope, domainName })
  });

  const userRoleChangedAction = I18n.t('user_role_changed_action', { scope });
  const userActivityAction = {
    UserAdded: userAddedWithDomain,
    UserRemoved: userRemovedWithDomain,
    UserRoleChanged: userRoleChangedAction
  };

  let translationKey = 'user_added_or_removed_description';

  if (activityType === 'UserRoleChanged') {
    const hasOldAndNewRoles =
      !isEmpty(oldRoleOfUser) && !isEmpty(newRoleOfUser) &&
      oldRoleOfUser !== 'null' && newRoleOfUser !== 'null';

    translationKey = hasOldAndNewRoles ? 'user_role_changed_with_roles_in_domain' : 'user_role_changed_in_domain';
  }

  const activityDescription = I18n.t(translationKey, {
    scope,
    name: targetUserName,
    action: userActivityAction[activityType],
    oldRole: oldRoleOfUser,
    newRole: newRoleOfUser,
    domainName
  });

  return activityDescription;
}

function SuccessWithErrorsMessageBody({ notification }: { notification: Notification }) {
  return (
    <div>
      <p>
        {I18n.t('success_with_errors', { ...notification.activityDetails.info, scope })}
      </p>
      {get(notification, 'activityDetails.info.badRowsPath') ?
        <a href={notification.activityDetails.info.badRowsPath} target="_blank" rel="noreferrer">Export these errors</a> :
        null
      }
    </div>
  );
}

function ScheduleFailedMessageBody({ notification }: { notification: Notification }) {
  return (
    <div>
      <p>{get(notification, 'activityDetails.reason')}</p>
    </div>
  );
}

function AgentChangeMessageBody({ notification }: { notification: Notification }) {
  let body = null;
  const { agent_name: agentName, agent_uid: agentUid } = notification.activityDetails;
  const delta = (time: Date) => moment.utc(time).locale(I18n.locale).fromNow(true);
  const link = `/admin/gateway/${agentUid}`;

  if (notification.activityType === 'AgentOffline') {
    const offlineAt = delta(notification.activityDetails.offlineAt);
    const reason = notification.activityDetails.reason;
    body = (
      <a href={link} target="_blank" rel="noreferrer">
        {I18n.t('agent_offline_body', { scope, agentName, offlineAt, reason })}
        <br />
        {reason && I18n.t('agent_offline_reason', { scope, reason})}
      </a>
    );
} else if (notification.activityType === 'AgentOnline') {
    const onlineAt = delta(notification.createdAt);
    body = (
      <a href={link} target="_blank" rel="noreferrer">
        {I18n.t('agent_online_body', { scope, agentName, onlineAt })}
      </a>
    );
  }

  return (
    <div>
      {body}
    </div>
  );
}



// i'm fine with pulling this out into a module when it gets obnoxious, but
// right now things are needlessly moduley
function MessageBody({ notification, link }: { notification: Notification, link: string }) {
  if (includes(USER_ACTIVITY_TYPES, notification.activityType)) {
    return <UserActivityMessageBody notification={notification} />;
  }
  if (includes(DATA_UPDATE_TYPES, notification.activityType)) {
    if (get(notification, 'activityDetails.status') === DETAILS_STATII.SuccessWithErrors)
    return <SuccessWithErrorsMessageBody notification={notification} />;
  }
  if (notification.activityType === SCHEDULE_FAILED) {
    return <ScheduleFailedMessageBody notification={notification} />;
  }
  if (includes(AGENT_ACTIVITY_TYPES, notification.activityType)) {
    return <AgentChangeMessageBody notification={notification} />;
  }


  if (link) {
    return <a href={link} target="_blank" rel="noreferrer">{notification.messageBody}</a>;
  } else {
    return <span>{notification.messageBody}</span>;
  }
}

class UserNotification extends Component<UserNotificationProps> {
  state = { fadeAwayTimeoutId: null };

  UNSAFE_componentWillMount() {
    const { isTransientNotification } = this.props;

    if (isTransientNotification) {
      const { moveTransientNotificationIntoPanel, notification, removeTransientNotification } = this.props;
      if (moveTransientNotificationIntoPanel) { // Add null check
        moveTransientNotificationIntoPanel(notification.id);
      }
      const fadeAwayTimeoutId = setTimeout(() => {
        if (removeTransientNotification) { // Add null check
          removeTransientNotification(notification.id);
        }
      }, FADE_TRANSIENT_NOTIFICATION_AFTER_MILLISECONDS);

      this.setState({ fadeAwayTimeoutId });
    }
  }

  componentWillUnmount() {
    const { isTransientNotification } = this.props;
    let { fadeAwayTimeoutId } = this.state;

    if (isTransientNotification && !isNull(fadeAwayTimeoutId)) {
      clearTimeout(fadeAwayTimeoutId);
      fadeAwayTimeoutId = null;

      this.setState({ fadeAwayTimeoutId });
    }
  }

  getUserActivityMessageBody = () => {
    const { notification } = this.props;
    const activityDetails = get(notification, 'activityDetails');
    const activityType = get(notification, 'activityType');
    const domainName = get(notification, 'domainName');
    const newRoleOfUser = get(activityDetails, 'newRole', '');
    const oldRoleOfUser = get(activityDetails, 'oldRole', '');
    const roleOfUser = get(activityDetails, 'role', '');
    const targetUserName = get(notification, 'targetUserName', '');
    const userAddedAction = I18n.t('user_added_action', { scope });
    const userRemovedAction = I18n.t('user_removed_action', { scope });
    const userRoleDescription = (isEmpty(roleOfUser) || isNull(roleOfUser)) ? '' : I18n.t('user_role_description', { scope, roleOfUser });
    const userAddedWithDomain = I18n.t('user_added_with_domain', {
      scope,
      addedAction: userAddedAction,
      domain: I18n.t('to_domain', { scope, domainName }),
      userRoleDescription
    });

    const userRemovedWithDomain = I18n.t('user_removed_with_domain', {
      scope,
      removedAction: userRemovedAction,
      domain: I18n.t('from_domain', { scope, domainName })
    });

    const userRoleChangedAction = I18n.t('user_role_changed_action', { scope });
    const userActivityAction = {
      UserAdded: userAddedWithDomain,
      UserRemoved: userRemovedWithDomain,
      UserRoleChanged: userRoleChangedAction
    };

    let translationKey = 'user_added_or_removed_description';

    if (activityType === 'UserRoleChanged') {
      const hasOldAndNewRoles =
        !isEmpty(oldRoleOfUser) && !isEmpty(newRoleOfUser) &&
        oldRoleOfUser !== 'null' && newRoleOfUser !== 'null';

      translationKey = hasOldAndNewRoles ? 'user_role_changed_with_roles_in_domain' : 'user_role_changed_in_domain';
    }

    const activityDescription = I18n.t(translationKey, {
      scope,
      name: targetUserName,
      action: userActivityAction[activityType],
      oldRole: oldRoleOfUser,
      newRole: newRoleOfUser,
      domainName
    });

    return activityDescription;
  };

  getSnoozedAlertInfo = () => {
    const { isSnoozedAlert, type } = this.props.notification;
    if (isSnoozedAlert && type == 'alert') {
      return (
        <span className="snoozed-info">{I18n.t('snoozed_alert_info', { scope })}</span>
      );
    }
  };

  renderAlertLabel = () => {
    const { notification } = this.props;

    if (notification.type === 'alert') {
      return <ForgeBadge theme="info-primary">{I18n.t('filter_alert_notifications_tab_text', { scope })}</ForgeBadge>;
    }

    return null;
  };

  renderUnreadIcon = () => {
    const { notification, onToggleReadUserNotification } = this.props;
    const { id, read } = notification;

    // title text indicates the action which will follow on icon click
    const linkTitleI18nKey = read ? 'mark_as_unread' : 'mark_as_read';

    return (
      <span
        tabIndex={0}
        className="toggle-notification-read-state link-icon"
        role="button"
        title={I18n.t(linkTitleI18nKey, { scope })}
        onClick={() => onToggleReadUserNotification(id, !read)}>
        <SocrataIcon name={IconName.Checkmark3} />
      </span>
    );
  };

  renderClearIcon = () => {
    const { notification, onClearUserNotification } = this.props;

    return (
      <span
        tabIndex={0}
        className="user-notification-clear-icon link-icon"
        role="button"
        title={I18n.t('clear_notification_text', { scope })}
        onClick={() => onClearUserNotification(notification.id)}>
        <SocrataIcon name={IconName.Close2} />
      </span>
    );
  };

  renderNotificationMessage = () => {
    const { notification } = this.props;
    const {
      activityUniqueKey,
      alertName,
      assetType,
      link,
      type
    } = notification;
    let alertOrNotificationTitle = alertName;
    if (type !== 'alert') {
      if (assetType === 'data_asset') {
        alertOrNotificationTitle = I18n.t(activityUniqueKey, { scope: `${scope}.usaid` });
      } else {
        alertOrNotificationTitle = I18n.t(activityUniqueKey, { scope });
      }
    }

    let title;
    if (isNull(link)) {
      title = <strong className="user-notification-title">{alertOrNotificationTitle}</strong>;
    } else {
      title = (
        <a href={link} target="_blank" rel="noreferrer">
          <strong className="user-notification-title">
            {this.renderAlertLabel()}
            {alertOrNotificationTitle}
          </strong>
        </a>
      );
    }

    return (
      <div className="title">
        {title}

        <span className="notification-body">
          <MessageBody notification={notification} link={link} />
        </span>
        {this.getSnoozedAlertInfo()}
      </div>
    );
  };

  renderDomainName = (domainName: string, domainNameLink: string) => {
    // We are showing Domain Name for UserNotification and not for Transient Notification
    const { isTransientNotification } = this.props;
    if (!isTransientNotification) {
      return <a className="domain-name" href={domainNameLink} target="_blank" rel="noreferrer">{domainName}</a>;
    }
  };

  renderDetailsLink = () => {
    const { isTransientNotification, notification, onClickAlertViewData } = this.props;

    if (!isTransientNotification) {
      return (
        <span
          className="alert-data-details alert-details"
          onClick={() => { onClickAlertViewData && onClickAlertViewData(notification); }}>
          {I18n.t('details_label', { scope })}
        </span>
      );
    }
  };

  renderNotificationDetails = () => {
    const { notification } = this.props;
    const { userName, userProfileLink, createdAt, domainNameLink } = notification;

    let details = null;
    let domainName;

    if (notification.type === 'alert') {
      domainName = notification.alertDomain;
      details = this.renderDetailsLink();
    } else if (userName) {
      const userProfileLinkElem = isNull(userProfileLink) ?
        <span className="user-name">{userName}</span> :
        <a className="user-name" href={userProfileLink} target="_blank" rel="noreferrer">{userName}</a>;

      domainName = notification.domainCname;
      details = (
        <span className="activity-details">
          <span>{I18n.t('by_label', { scope })}</span> {userProfileLinkElem}
        </span>
      );
    } else {
      return null;
    }

    return (
      <div className="notification-details">
        <div className="details">
          <span className="timestamp">{moment.utc(createdAt).locale(I18n.locale).fromNow()}</span>
          {details}
        </div>
          {this.renderDomainName(domainName, domainNameLink)}
      </div>
    );
  };

  render() {
    const { isTransientNotification, notification } = this.props;
    const { id, read, type } = notification;
    const isUnread = !read;

    return (
      <li
        data-notification-id={id}
        className={classNames('user-notification-item', type, {
          'unread': isUnread,
          'transient': isTransientNotification
        })}>

        <div className="clearfix notification-wrapper">
          <div className="notification-info">
            {this.renderNotificationMessage()}
            {this.renderNotificationDetails()}
          </div>
          <div className="actions-wrapper">
            {this.renderUnreadIcon()}
            {this.renderClearIcon()}
          </div>
        </div>
      </li>
    );
  }
}

export default UserNotification;
