import { ToastType } from 'common/components/ToastNotification';
import { showToastNow } from 'common/components/ToastNotification/Toastmaster';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { requireApprovalRequestWithdrawalModal } from '../confirmation_dialog/utils';
import { checkStatus } from 'common/http';
import I18n from 'common/i18n';

export const handlePostConfirmationError = () => {
  showToastNow({
    type: ToastType.ERROR,
    content: I18n.t('shared.components.asset_action_bar.publication_action.unknown_error')
  });
};

/**
 * Prompt the user to withdraw approval request if necessary;
 *  returns true if we're OK to proceed, false otherwise
 * @param approvalsGuidance The guidance data for a given asset
 * @returns Promise<boolean>
 */
export const requireApprovalRequestWithdrawal = async (approvalsGuidance: GuidanceSummaryV2) => {
  const approvalsHelper = withGuidanceV2(approvalsGuidance);
  if (approvalsHelper.isPending()) {
    const confirmResolution = await requireApprovalRequestWithdrawalModal();
    if (!confirmResolution.confirmed) {
      return false;
    }

    try {
      await approvalsHelper.withdraw().then(checkStatus);
    } catch (error) {
      handlePostConfirmationError();
    }
  }

  return true;
};
