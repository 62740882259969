import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { InputProps } from './types';

/**
 * Input box for searching the list of options
 */
function MultiSelectInput<OptionType, SelectType>({
  currentQuery,
  disableInput,
  inputPlaceholder,
  setInputRef,
  maxSelectedOptions,
  maxSelectedOptionsPlaceholder,
  onCurrentQueryChanged,
  onOptionsVisibilityChanged,
  onSelectedOptionIndexChange,
  onlyShowPlaceholderWhenNoSelectedOptions,
  selectedOptions,
  showPlaceholderWhenQueryEmpty
}: InputProps<OptionType, SelectType>) {
  const [showingPlaceholder, setShowingPlaceholder] = useState(true);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputRef(ref);
  }, [ref]);

  // This is a non-default behavior. This makes it so that if the query text is currently empty,
  // we show the placeholder regardless of whether or not the user has typed into the search box
  // previously.
  if (showPlaceholderWhenQueryEmpty && !currentQuery && !showingPlaceholder) {
    setShowingPlaceholder(true);
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onCurrentQueryChanged(event);

    // blank out the selected index so we go to the top of the list
    // (query changing implies that options list has changed)
    onSelectedOptionIndexChange(undefined);

    // show the options if they've been hidden (i.e. by hitting "Escape")
    onOptionsVisibilityChanged(true);

    // Only show the placeholder if the box hasn't been typed in for the first time yet;
    // this is because initially we want to show the whole placeholder text (which can be pretty long)
    // but when the placeholder isn't showing, the input grows with the number of characters in it
    // (note that there is an experimental :placeholder-shown pseudo-class but it's not supported by IE)
    setShowingPlaceholder(false);
  };

  const style = {
    // this will actually grow the input box with the text being entered
    // +2 so that there's at least some space if the letters don't fit
    width: `${currentQuery.length + 3}ch`
  };

  let placeholder = inputPlaceholder;
  if (
    maxSelectedOptionsPlaceholder !== undefined &&
    maxSelectedOptions !== undefined &&
    selectedOptions !== undefined &&
    selectedOptions.length >= maxSelectedOptions
  ) {
    placeholder = maxSelectedOptionsPlaceholder;
  } else if (
    onlyShowPlaceholderWhenNoSelectedOptions &&
    selectedOptions !== undefined &&
    selectedOptions.length > 0 &&
    showingPlaceholder
  ) {
    setShowingPlaceholder(false);
  }

  // hide the input off-screen if it's disabled
  const hideOffScreen = {
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
    width: '1px',
    height: '1px'
  };

  return (
    <>
      <label style={hideOffScreen} htmlFor="multiselect-input">
        {placeholder}
      </label>
      <input
        id="multiselect-input"
        style={style}
        placeholder={showingPlaceholder ? placeholder : ''}
        ref={ref}
        className={`text-input ${showingPlaceholder ? 'multiselect-input-placeholder' : 'multiselect-input'}`}
        value={currentQuery}
        disabled={disableInput}
        onChange={onInputChange}
      />
    </>
  );
}

export default MultiSelectInput;
