import {
  FederationsApi,
  AssetFederationApi as AssetFederationApiClient,
  Configuration,
  ErrorResponse,
  ErrorResponseCodeEnum
} from '@socrata/core-federations-api';
import { defaultHeaders } from 'common/http';

const configuration = new Configuration({
  credentials: 'same-origin',
  basePath: window.location.origin,
  headers: defaultHeaders
});

/**
 * Core Federations API Client
 *
 * Generated from OpenAPI Specification that lives at:
 * https://github.com/socrata/apis/blob/main/specs/federations/federations.yaml
 */
export default new FederationsApi(configuration);

/**
 * Core Asset Federations API Client
 */
export const AssetFederationApi = new AssetFederationApiClient(configuration);

/**
 * Returns a promise that can be used to parse the JSON out of an OpenAPI error response
 * @param error error to parse
 */
export const parseError = (error: Response): Promise<ErrorResponse> =>
  new Promise((resolve, reject) => {
    error
      .json()
      .then((parsed: any) => resolve(parsed))
      .catch((e: any) => reject(`Error parsing error JSON: ${e}`));
  });

/*
 * Given an error code from core, translates it to a message
 *
 * NOTE: These are actually translation keys under the "shared.federations.add_federation.data_federation" scope
 * in the translation file
 *
 * @param errorCode Error code to get translation key for
 */
export const coreErrorCodeToTranslationString = (errorCode?: ErrorResponseCodeEnum): string => {
  const scope = 'shared.federations.add_federation.errors';

  switch (errorCode) {
    case ErrorResponseCodeEnum.DUPLICATERECORD:
      return `${scope}.data_federation.duplicate_record`;
    case ErrorResponseCodeEnum.INSUFFICIENTPRIVILEGES:
      // theoretically users should never get here since they won't be able to see this page
      return `${scope}.insufficient_privileges`;
    case ErrorResponseCodeEnum.INVALIDSOURCE:
    case ErrorResponseCodeEnum.INVALIDTARGET: // both of these result in the same error message
      return `${scope}.cname_invalid`;
    case ErrorResponseCodeEnum.INVALIDINTERNALTOPUBLICFEDERATION:
      return `${scope}.data_federation.invalid_internal_to_public_federation`;
    default:
      return `${scope}.connection`;
  }
};
