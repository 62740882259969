import React, { Component } from 'react';

import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { AudienceScope } from 'common/types/view';
import { strictPermissionsEnabled } from 'common/components/AccessManager/Util';

import './audience-label.scss';

const classNameScope = 'common-components-AccessManager--components--AudienceScopeChooser';

interface AudienceScopeLabelProps {
  scope: AudienceScope;
}

/**
 * A label with an icon, a title, and a subtitle that describes a "scope"
 * of audience (public, private, etc.)
 *
 * Assumes that the following translation keys exist:
 *  - shared.site_chrome.access_manager.audience.${scope}.title
 *  - shared.site_chrome.access_manager.audience.${scope}.subtitle
 * where ${scope} is what is passed in to the component's props.
 *
 * Icons are stored in a static object in the class, for now.
 */
class AudienceScopeLabel extends Component<AudienceScopeLabelProps> {
  // this is *probably* fine right here for now
  // since these won't be changing frequently, if at all
  static iconForScope: { [key in AudienceScope]: IconName } = {
    private: IconName.Private,
    site: IconName.MyOrg,
    public: IconName.PublicOpen
  };

  render() {
    const { scope } = this.props;
    const permissions = strictPermissionsEnabled() ? 'strict_permissions' : 'legacy_permissions';

    return (
      <div className={`${classNameScope}--audience-label-container`}>
        <span className={`${classNameScope}--audience-label-title`}>
          {I18n.t(`shared.site_chrome.access_manager.audience.${permissions}.${scope}.title`)}
        </span>
        <SocrataIcon
          name={AudienceScopeLabel.iconForScope[scope]}
          className={`${classNameScope}--audience-scope-label-icon`}
        />
        <span className={`${classNameScope}--audience-label-subtitle`}>
          {I18n.t(`shared.site_chrome.access_manager.audience.${permissions}.${scope}.subtitle`)}
        </span>
      </div>
    );
  }
}

export default AudienceScopeLabel;
