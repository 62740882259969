import { assert } from 'common/assertions';
import { ComponentType, LayoutMap } from '../../types';
import type { Layout } from 'react-grid-layout';

// Fetches layouts from window object placed there by
// storyteller/app/views/stories/partials/_storyteller_environment.html.erb
// This keeps the source of truth inside Rails.
// You will still need to update the LayoutMap interface when a new viz type
// is created.
// Default layout values can be found in storyteller/app/views/stories/react_grid_layouts.json

// @ts-expect-error TS(2339) FIXME: Property 'DEFAULT_REACT_GRID_LAYOUTS' does not exi... Remove this comment to see the full error message
const DEFAULT_REACT_GRID_LAYOUTS: LayoutMap = window.DEFAULT_REACT_GRID_LAYOUTS;

export const getDefaultLayout = (componentType: ComponentType): Layout => {
  assert(componentType !== undefined, 'The componentType can not be undefined');
  assert(componentType !== null, 'The componentType can not be null');

  const defaultLayout = DEFAULT_REACT_GRID_LAYOUTS[componentType];
  assert(defaultLayout !== undefined, `The default layout is undefined. The component type ${componentType} does not exist`);

  return defaultLayout;
};
