import React from 'react';
import FlannelDismissButton from './DismissButton';

interface FlannelHeaderProps {
  title: string;
  onDismiss: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FlannelHeader = ({ title, onDismiss }: FlannelHeaderProps) => (
  <header className="socrata-flannel-header">
    <span className="socrata-flannel-header-title">{title}</span>
    <FlannelDismissButton onDismiss={onDismiss} />
  </header>
);

export default FlannelHeader;
