import React, { Component } from 'react';
import I18n from 'common/i18n';

import Button, { VARIANTS } from 'common/components/Button';
import { Flannel, FlannelContent, FlannelDismissButton } from 'common/components/Flannel';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { View } from 'common/types/view';
import { ForgeIcon } from '@tylertech/forge-react';

interface FederationTargetsProps {
  view: View;
}

interface FederationTargetsState {
  flannelOpen: boolean;
}

const scope = 'shared.components.asset_action_bar.also_published_on';

/**
 * Note about domains that this asset will be published to. For multiple
 * federation targets, this shows a flannel popup listing all of the domains.
 */
class FederationTargets extends Component<FederationTargetsProps, FederationTargetsState> {
  state = {
    flannelOpen: false
  };

  renderFlannelTrigger(count: number) {
    return (
      <Button
        id="other-sites"
        className="federated-to-link"
        variant={VARIANTS.LINK}
        onClick={() => {
          this.setState({ flannelOpen: true });
        }}
      >
        {I18n.t('many_sites', { count, scope })}
      </Button>
    );
  }

  renderFlannel(federatedTo: string[]) {
    if (!this.state.flannelOpen) {
      return null;
    }

    const onDismiss = () => this.setState({ flannelOpen: false });
    // We show a link to a flannel dropdown which lists all sites
    const flannelProps = {
      className: 'federated-to-domain-flannel',
      onDismiss,
      target: () => document.querySelector('#other-sites'),
      title: I18n.t('flannel.title', { scope })
    };

    return (
      <Flannel {...flannelProps}>
        <FlannelContent>
          <ul className="federated-to-domain-list">
            {federatedTo.map((domain) => (
              <li key={domain}>
                <SocrataIcon className="federation-target-icon" name={IconName.ExternalSquare} />
                <a href={`https://${domain}`} className="federation-target-link" target="_blank">
                  {domain}
                </a>
              </li>
            ))}
          </ul>
        </FlannelContent>
        <FlannelDismissButton onDismiss={onDismiss} />
      </Flannel>
    );
  }

  renderManySites(federatedTo: string[]) {
    return (
      <span>
        {this.renderFlannelTrigger(federatedTo.length)}
        {this.renderFlannel(federatedTo)}
      </span>
    );
  }

  renderOneSite(federatedToDomain: string) {
    const otherSite = `<a class="federated-to-link" href="https://${federatedToDomain}" target="_blank">${federatedToDomain}</a>`;
    return (
      <span
        id="federated-notice"
        dangerouslySetInnerHTML={{
          __html: I18n.t('one_site', { otherSite, scope })
        }}
      ></span>
    );
  }

  renderNotDataFederated() {
    return (
      <span
        id="not-data-federated-notice"
        dangerouslySetInnerHTML={{
          __html: I18n.t('not_data_federated', { scope })
        }}
      ></span>
    );
  }

  render() {
    const { view } = this.props;
    const federatedTo = view.federatedTo || [];
    const count = federatedTo.length;
    return (
      <span className="asset-action-bar-item">
        <ForgeIcon name="set_none" external external-type="extended" className="federation-icon" />
        {count === 1 ? this.renderOneSite(federatedTo[0]) : this.renderManySites(federatedTo)}
      </span>
    );
  }
}

export default FederationTargets;
