import React, { FunctionComponent } from 'react';
import { ForgeButton } from '@tylertech/forge-react';

import connectFlux from 'connectFlux';
import Actions from 'Actions';
import { dispatcher } from 'Dispatcher';
import I18n from 'common/i18n';

export type SelectDatasetButtonProps = PassedProps & StoreProps & DispatchProps;
interface PassedProps {
  blockId: string;
  componentIndex: number;
}

interface StoreProps {}

interface DispatchProps {
  launchDatasetSelector: (blockId: string, componentIndex: number) => void;
}

const SelectDatasetButton: FunctionComponent<SelectDatasetButtonProps> = ({
  blockId,
  componentIndex,
  launchDatasetSelector
}) => {
  return (
    <ForgeButton type="outlined">
      <button type="button" onClick={() => launchDatasetSelector(blockId, componentIndex)}>
        <span>{I18n.t('editor.data_and_filtering_panel.add_data_source')}</span>
      </button>
    </ForgeButton>
  );
};

export { SelectDatasetButton };
export default connectFlux<PassedProps, StoreProps, DispatchProps>(
  {},
  () => ({}),
  () => ({
    launchDatasetSelector: (blockId: string, componentIndex: number) => {
      dispatcher.dispatch({
        action: Actions.ASSET_SELECTOR_SELECT_ASSET_FOR_COMPONENT,
        blockId,
        componentIndex
      });

      dispatcher.dispatch({
        action: Actions.ASSET_SELECTOR_PROVIDER_CHOSEN,
        provider: 'GLOBAL_FILTER'
      });
    }
  })
)((props: SelectDatasetButtonProps) => <SelectDatasetButton {...props} />);
