import $ from 'jquery';
import _ from 'lodash';

import getCurrency from 'common/js_utils/getCurrency';
import { assertHasProperty, assertIsOneOfTypes } from 'common/assertions';
import getLocale from 'common/js_utils/getLocale';
import getGroupCharacter from 'common/js_utils/getGroupCharacter';
import getDecimalCharacter from 'common/js_utils/getDecimalCharacter';
import formatNumber from 'common/js_utils/formatNumber';
import commaify from 'common/js_utils/commaify';
import moment from 'moment';
import BigNumber from 'bignumber.js';
// Converts GeoJSON formats to text
import wkt from 'wellknown';
import I18n from 'common/i18n';
import { formatForDisplay } from 'common/formatBoolean';
const t = (k) => I18n.t(k, { scope: 'shared.data_type_formatter' });

BigNumber.config({ ERRORS: false });

// IMPORTANT NOTE: This module fails to localize some things correctly. It began
// as a port of Data Lens code, which took some serious shortcuts, and there has
// been insufficient motivation to redo formatting properly. For a clearer idea
// of what "properly" means, please see http://cldr.unicode.org for details.

export const CURRENCY_SYMBOLS = {
  AFN: '؋',
  ALL: 'Lek',
  ANG: 'ƒ',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: 'ман',
  BAM: 'KM',
  BBD: '$',
  BGN: 'лв',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BRL: 'R$',
  BSD: '$',
  BWP: 'P',
  BYR: 'p.',
  BZD: 'BZ$',
  CAD: '$',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  EEK: 'kr',
  EGP: '£',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GGP: '£',
  GHC: '¢',
  GIP: '£',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HUF: 'Ft',
  INR: 'Rp',
  ILS: '₪',
  IMP: '£',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KPW: '₩',
  KRW: '₩',
  KYD: '$',
  KZT: 'лв',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LTL: 'Lt',
  LVL: 'Ls',
  MKD: 'ден',
  MNT: '₮',
  MUR: '₨',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: 'Php',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: 'руб',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SOS: 'S',
  SRD: '$',
  SVC: '$',
  SYP: '£',
  THB: '฿',
  TRL: '₤',
  TRY: 'TL',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  UAH: '₴',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  XCD: '$',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$'
};

export const TIME_FORMATS = {
  date_time_24h: 'MM/DD/YYYY HH:mm:ss',
  date_time: 'MM/DD/YYYY hh:mm:ss A',
  date: 'MM/DD/YYYY',
  date_dmy: 'DD/MM/YYYY',
  date_dmy_time_24h: 'DD/MM/YYYY HH:mm:ss',
  date_dmy_time: 'DD/MM/YYYY hh:mm:ss A',
  date_ymd: 'YYYY/MM/DD',
  date_ymd_time_24h: 'YYYY/MM/DD HH:mm:ss',
  date_ymd_time: 'YYYY/MM/DD hh:mm:ss A',
  date_monthdy: 'MMMM DD, YYYY',
  date_monthdy_shorttime_24h: 'MMMM DD, YYYY HH:mm',
  date_monthdy_shorttime: 'MMMM DD, YYYY hh:mm A',
  date_shortmonthdy: 'MMM DD, YYYY',
  date_monthdy_time_24h: 'MMMM DD, YYYY HH:mm:ss',
  date_monthdy_time: 'MMMM DD, YYYY hh:mm:ss A',
  date_dmonthy: 'DD MMMM YYYY',
  date_dmonthy_time_24h: 'DD MMMM YYYY HH:mm:ss',
  date_dmonthy_time: 'DD MMMM YYYY hh:mm:ss A',
  date_shortmonthdy_shorttime_24h: 'MMM DD, YYYY HH:mm',
  date_shortmonthdy_shorttime: 'MMM DD, YYYY hh:mm A',
  date_ymonthd: 'YYYY MMMM DD',
  date_ymonthd_time_24h: 'YYYY MMMM DD HH:mm:ss',
  date_ymonthd_time: 'YYYY MMMM DD hh:mm:ss A',
  date_my: 'MM/YYYY',
  date_ym: 'YYYY/MM',
  date_shortmonthy: 'MMM YYYY',
  date_yshortmonth: 'YYYY MMM',
  date_monthy: 'MMMM YYYY',
  date_ymonth: 'YYYY MMMM',
  date_y: 'YYYY',
  // The following two formats are our default formats,
  // which match none of the existing custom formats.
  default_date_time: 'YYYY MMM DD hh:mm:ss A',
  default_date: 'YYYY MMM DD',
  iso_8601_date_time: 'YYYY-MM-DDTHH:mm:ssZZ',
  iso_8601_date_time_utc: 'YYYY-MM-DDTHH:mm:ssZZ',
  iso_8601_date: 'YYYY-MM-DD',
  iso_8601_date_utc: 'YYYY-MM-DD'
};

export const TIME_FORMAT_TITLES = [
  { value: null, title: t('use_default') },
  { value: 'date_time', title: '09/23/2017 01:45:31 PM' },
  { value: 'date', title: '09/23/2017' },
  { value: 'date_dmy_time', title: '23/09/2017 01:45:31 PM' },
  { value: 'date_dmy', title: '23/09/2017' },
  { value: 'date_ymd_time', title: '2017/09/23 01:45:31 PM' },
  { value: 'date_ymd', title: '2017/09/23' },
  { value: 'date_monthdy_shorttime', title: 'September 23, 2017 01:45 PM' },
  { value: 'date_monthdy', title: 'September 23, 2017' },
  { value: 'date_shortmonthdy', title: 'Sep 23, 2017' },
  { value: 'date_monthdy_time', title: 'September 23, 2017 01:45:31 PM' },
  { value: 'date_dmonthy', title: '23 September 2017' },
  { value: 'date_shortmonthdy_shorttime', title: 'Sep 23, 2017 01:45 PM' },
  { value: 'date_ymonthd', title: '2017 September 23' },
  { value: 'date_ymonthd_time', title: '2017 September 23 01:45:31 PM' },
  { value: 'date_my', title: '09/2017' },
  { value: 'date_ym', title: '2017/09' },
  { value: 'date_shortmonthy', title: 'Sep 2017' },
  { value: 'date_yshortmonth', title: '2017 Sep' },
  { value: 'date_monthy', title: 'September 2017' },
  { value: 'date_ymonth', title: '2017 September' },
  { value: 'date_y', title: '2017' },
  { value: 'date_time_24h', title: '09/23/2017 13:45:31' },
  { value: 'date_dmy_time_24h', title: '23/09/2017 13:45:31' },
  { value: 'date_ymd_time_24h', title: '2017/09/23 13:45:31' },
  { value: 'date_monthdy_shorttime_24h', title: 'September 23, 2017 13:45' },
  { value: 'date_monthdy_time_24h', title: 'September 23, 2017 13:45:31' },
  { value: 'date_dmonthy_time_24h', title: '23 September 2021 13:45:31' },
  { value: 'date_shortmonthdy_shorttime_24h', title: 'Sep 23, 2017 13:45' },
  { value: 'date_ymonthd_time_24h', title: '2017 September 23 13:45:31' },
  { value: 'iso_8601_date', title: '2017-09-23' },
  { value: 'iso_8601_date_time', title: '2017-09-23T13:45:31-07:00' },
  { value: 'iso_8601_date_utc', title: '2017-09-23' },
  { value: 'iso_8601_date_time_utc', title: '2017-09-23T13:45:31-07:00' }
];

// Please note: Functions whose name ends with HTML
// will return HTML ready to place into the DOM. Do
// not process further.
//
// SECURITY NOTE: If you modify any of these methods, ensure
// you don't introduce any XSS vulnerabilities. Write tests.
//
// SECURITY NOTE: Functions whose name ends with UnsafePlainText
// return unsafe strings. Do not place them into the DOM
// directly. Consider using an *HTML renderer.
export {
  getCurrencySymbol,
  // Before exporting any *UnsafePlainText functions, think
  // carefully about the potential security impact.
  renderCellHTML,
  renderBooleanCellHTML,
  renderNumberCellHTML,
  renderGeoCellHTML,
  renderMoneyCellHTML,
  renderUrlCellHTML,
  renderEmailCellHTML,
  renderPhoneCellHTML,
  renderBlobCellHTML,
  renderPhotoCellHTML,
  renderDocumentCellHTML,
  renderMultipleChoiceCellHTML,
  renderTimestampCellHTML,
  renderObeLocationHTML,
  renderFormattedTextHTML,
  getCellAlignment,
  getRowConditionalFormattingStyles,
  maybeRenderTextFormattingHTML
};

export default {
  getCurrencySymbol,
  // Before exporting any *UnsafePlainText functions, think
  // carefully about the potential security impact.
  renderCellHTML,
  renderBooleanCellHTML,
  renderNumberCellHTML,
  renderGeoCellHTML,
  renderMoneyCellHTML,
  renderUrlCellHTML,
  renderEmailCellHTML,
  renderPhoneCellHTML,
  renderBlobCellHTML,
  renderPhotoCellHTML,
  renderDocumentCellHTML,
  renderMultipleChoiceCellHTML,
  renderTimestampCellHTML,
  renderObeLocationHTML,
  renderFormattedTextHTML,
  getCellAlignment,
  getRowConditionalFormattingStyles,
  maybeRenderTextFormattingHTML
};

function renderCellHTML(cellContent, column, domain, datasetUid, options = {}) {
  // SECURITY NOTE: Only return safe HTML from this function!
  let cellHTML;

  assertIsOneOfTypes(column, 'object');
  assertHasProperty(column, 'renderTypeName');

  if (_.isUndefined(cellContent)) {
    return '';
  }

  try {
    switch (column.renderTypeName) {
      case 'checkbox':
        cellHTML = renderBooleanCellHTML(cellContent, column);
        break;
      case 'number':
        cellHTML = renderNumberCellHTML(cellContent, column, options);
        break;
      case 'geo_entity':
        cellHTML = renderGeoCellHTML(cellContent);
        break;
      case 'point':
      case 'line':
      case 'polygon':
      case 'multipoint':
      case 'multiline':
      case 'multipolygon':
        cellHTML = renderWKTCellHTML(cellContent);
        break;
      case 'calendar_date':
        cellHTML = renderTimestampCellHTML(cellContent, column);
        break;
      case 'blob':
        cellHTML = renderBlobCellHTML(cellContent, domain, datasetUid);
        break;
      // OBE types that are deprecated on new datasets, but are supported on migrated datasets:
      case 'email':
        cellHTML = renderEmailCellHTML(cellContent);
        break;
      case 'money':
        cellHTML = renderMoneyCellHTML(cellContent, column);
        break;
      // OBE location columns are actually objects with latitude and longitude
      // keys or a coordinates key, so we need to handle them as a special case.
      case 'location':
        cellHTML = renderObeLocationHTML(cellContent, column);
        break;
      // EN-3548 - Note that only OBE datasets can have a column renderTypeName
      // of 'percent'. Corresponding NBE datasets will have a column
      // renderTypeName of 'number'. In order to keep that sort of logic somewhat
      // contained, inside of the implementation of `renderNumberCellUnsafePlainText()` we do a
      // few tests to figure out if we should be formatting the resulting value
      // as a percentage.
      case 'percent':
        cellHTML = renderNumberCellHTML(cellContent, column);
        break;
      case 'phone':
        cellHTML = renderPhoneCellHTML(cellContent);
        break;
      case 'url':
        cellHTML = renderUrlCellHTML(cellContent);
        break;

      // TODO: Remove these types once we no longer support OBE datasets
      // OBE types that are deprecated post-NBE migration:
      case 'stars':
        cellHTML = renderNumberCellHTML(cellContent, column);
        break;
      case 'date':
        cellHTML = renderTimestampCellHTML(cellContent, column);
        break;
      case 'document':
        cellHTML = renderDocumentCellHTML(cellContent, domain, datasetUid);
        break;
      case 'drop_down_list':
        cellHTML = renderMultipleChoiceCellHTML(cellContent, column);
        break;
      case 'html': // Formatted Text
        cellHTML = renderFormattedTextHTML(cellContent);
        break;
      case 'photo':
        cellHTML = renderPhotoCellHTML(cellContent, domain, datasetUid);
        break;
      case 'json':
        cellHTML = renderJsonCell(cellContent);
        break;
      default:
        cellHTML = maybeRenderTextFormattingHTML(cellContent, column, options);
        break;
    }
  } catch (e) {
    console.error(`Error rendering ${cellContent} as type ${column.renderTypeName}:`);
    console.error(e);
  }

  return cellHTML;
}

function renderJsonCell(cellContent) {
  return JSON.stringify(cellContent);
}

/**
 * Applies formatting to text columns (e.g. email addresses)
 */
function maybeRenderTextFormattingHTML(cellContent, column, options) {
  if (!_.isString(cellContent) || _.isEmpty(cellContent)) {
    return '';
  }

  const openUrlInNewTab = _.get(options, 'openUrlInNewTab', false);
  const escapedCellContent = _.escape(cellContent);
  let cellHTML = '';

  switch (_.get(column, 'format.displayStyle', '').toLowerCase()) {
    case 'email':
      cellHTML = `<a href="mailto:${escapedCellContent}" title="${escapedCellContent}">${escapedCellContent}</a>`;
      break;

    case 'url':
      const protocol = cellContent.match(/^([a-z]+:\/\/)/i);

      // If there is no protocol it won't be a valid link anyway, so just render it as a string.
      if (_.isNull(protocol)) {
        cellHTML = escapedCellContent;
      } else {
        cellHTML =
          `<a href="${escapedCellContent}" target="${
            openUrlInNewTab ? '_blank' : '_self'
          }" title="${escapedCellContent}">` +
          escapedCellContent +
          '</a>';
      }
      break;

    default:
      cellHTML = escapedCellContent;
      break;
  }

  return cellHTML;
}

/**
 * Renders a formatted text column (only preserves plain text content).
 */
function renderFormattedTextHTML(cellContent) {
  return _.isNull(cellContent) ? '' : _.escape($(`<p>${cellContent}</p>`).text());
}

/**
 * Renders a boolean value in checkbox format
 */
function renderBooleanCellHTML(cellContent, column) {
  const displayStyle = _.get(column, 'format.displayStyle');

  let value;

  try {
    value = JSON.parse(cellContent);
  } catch (error) {
    value = null;
  }

  return _.escape(formatForDisplay(displayStyle, value));
}

function getCurrencySymbol(format) {
  // For the purposes of getting a currency symbol, we NEVER want it to return undefined.
  // Not sure if we should default the symbol to $, but it's likely to end up that way from I18n.
  let localeDefault = I18n.t('shared.visualizations.charts.common.currency_symbol', { defaultValue: '' });
  if (!format) {
    return localeDefault;
  }
  // Direction from Chris L. is to use currencyStyle as primary field, fallback to currency, then locale default.
  const currency = format.currencyStyle || format.currency;
  return CURRENCY_SYMBOLS[currency] || localeDefault;
}

/**
 * Render a number based on column specified formatting.
 * This has lots of possible options, so we delegate to helpers.
 */
function renderNumberCellUnsafePlainText(input, column, options = {}) {
  const amount = parseFloat(input);
  const { retainSmallDecimals = true, returnParsableNumber = false } = options;

  // EN-19953 - Data Inconsistencies in Grid View Refresh
  //
  // Although we were able to mostly pretend that invalid data did not
  // exist when we were working on Stories and VizCan, it turns out that
  // quite a few customer datasets have invalid data that they expect
  // to be rendered as an empty cell and not, e.g., 'NaN'.
  //
  // In this case we will avoid rendering 'NaN' to table cells by only
  // actually trying to render the number if the column we are rendering
  // is of type number but the value we are looking is not finite by
  // to lodash's determination.
  if (!_.isFinite(amount)) {
    return '';
  }

  // Initialize BigNumber with string to deal with a limitation of
  // 15 significant digits. https://github.com/MikeMcl/bignumber.js/issues/11
  const safeAmount = new BigNumber(String(input));
  const locale = getLocale(window);
  const format = _.extend(
    {
      precisionStyle: 'standard',
      precision: undefined,
      forceHumane: false, // NOTE: only used internally, cannot be set on columns
      noCommas: false,
      decimalSeparator: getDecimalCharacter(locale),
      groupSeparator: getGroupCharacter(locale),
      mask: null
    },
    column.format || {}
  );

  format.commaifyOptions = {
    decimalCharacter: _.escape(format.decimalSeparator),
    groupCharacter: _.escape(format.groupSeparator)
  };

  if (format.mask) {
    return _renderMaskedNumber(amount, format);
  } else {
    switch (format.precisionStyle) {
      case 'percentage':
        return _renderPercentageNumber(safeAmount, format, retainSmallDecimals, returnParsableNumber);
      case 'scientific':
        return _renderScientificNumber(amount, format);
      case 'currency':
        return _renderCurrencyNumber(amount, format, returnParsableNumber);
      case 'financial':
        return _renderFinancialNumber(amount, format, returnParsableNumber);
      case 'standard':
      default:
        return _renderStandardNumber(amount, format, retainSmallDecimals, returnParsableNumber);
    }
  }
}

/**
 * Render the number cell with the proper formatting based on configurations.
 * @param {string | number} input - The cell's value to be rendered.
 * @param {TableColumnFormat | RowFormat | ViewColumn} column - Object that contains the formatting configurations.
 * @param {{ retainSmallDecimals?: boolean, returnParsableNumber?: boolean } | undefined} [options] - Additional configurations if need be
 * @returns
 */
function renderNumberCellHTML(input, column, options = {}) {
  return renderNumberCellUnsafePlainText(input, column, options);
}

/**
 * Renders an OBE-style location (an object with latitude and longitude
 * properties).
 */
function renderObeLocationUnsafePlainText(cellContent, column) {
  if (_.isNull(cellContent)) {
    return '';
  }

  const format = _.get(column, 'format.view', 'address_coords');
  const renderAddress = !_.isNull(format.match(/address/));
  const renderCoords = !_.isNull(format.match(/coords/));

  let humanAddress = '';
  let coords = '';

  if (renderAddress && cellContent.human_address) {
    try {
      const addressData = JSON.parse(cellContent.human_address);
      const addressParts = [addressData.address, addressData.city, addressData.state, addressData.zip];

      humanAddress = addressParts.filter(_.identity).join(' ');

      // EN-25497 - Socrata Replacing "&" with "&amp;"
      //
      // As far as I can tell, the OBE location column's human_address subtype either escapes ampersands
      // on ingress and persists the change, or it always escapes ampersands on egress. Either way, this
      // means that we are functionally changing the customer's data.
      //
      // This was not previously an issue because the old grid view apparently would set innerHTML with
      // the location column's human_address subtype values (which was probably an XSS vector of which
      // we were not aware), but it has become an issue now since the Table component used by the grid
      // view writes what the API gives back as an exact string, since it expects all API responses to
      // be text, not HTML.
      //
      // Since this appears to be a legacy issue with a legacy type, a backfill to replace instances of
      // '&amp;' in column values is risky and probably not worth the effort. Instead, it seems more
      // likely to succeed to just unescape the ampersands before we escape the entire string in this
      // function's caller (renderObeLocationHTML, which can be found just below).
      if (humanAddress.match(/&amp;/i)) {
        humanAddress = humanAddress.replace(/&amp;/gi, '&');
      }
    } catch (e) {
      // pass
    }
  }

  if (renderCoords) {
    let latitude;
    let longitude;

    if (cellContent.hasOwnProperty('latitude') && cellContent.hasOwnProperty('longitude')) {
      latitude = cellContent.latitude;
      longitude = cellContent.longitude;
    } else if (cellContent.hasOwnProperty('coordinates')) {
      latitude = cellContent.coordinates[1];
      longitude = cellContent.coordinates[0];
    }

    if (!_.isUndefined(latitude) && !_.isUndefined(longitude)) {
      coords = `(${latitude}°, ${longitude}°)`;
    }
  }

  if (renderAddress && renderCoords) {
    return `${humanAddress} ${coords}`.trim();
  } else if (renderAddress) {
    return humanAddress;
  } else if (renderCoords) {
    return coords;
  } else {
    return '';
  }
}

/**
 * Renders an OBE-style location (an object with latitude and longitude
 * properties).
 */

function renderObeLocationHTML(cellContent, column) {
  return _.escape(renderObeLocationUnsafePlainText(cellContent, column));
}

/**
 * Renders a Point wrapped in an HTML span element
 *
 * Parameters:
 * - cellContent: data for the cell (from soda fountain).
 */
function renderGeoCellHTML(cellContent) {
  const latitudeIndex = 1;
  const longitudeIndex = 0;
  const coordinates = _cellCoordinates(cellContent);

  if (!coordinates) {
    return '';
  }

  const latitudeTitle = I18n.t('shared.visualizations.charts.common.latitude');
  const longitudeTitle = I18n.t('shared.visualizations.charts.common.longitude');

  const latitudeText = _.escape(`${coordinates[latitudeIndex]}`);
  const longitudeText = _.escape(`${coordinates[longitudeIndex]}`);

  const latitude = `<span title="${latitudeTitle}">${latitudeText}°</span>`;
  const longitude = `<span title="${longitudeTitle}">${longitudeText}°</span>`;

  return `(${latitude}, ${longitude})`;
}

/**
 * Renders any GeoJSON column by serializing to Well Known Text.
 */
function renderWKTCellHTML(cellContent) {
  if (_.isEmpty(cellContent)) {
    return '';
  } else if (_.isString(cellContent)) {
    return _.escape(cellContent);
  } else {
    return _.escape(wkt.stringify(cellContent));
  }
}

/**
 * Render a blob cell.
 */
function renderBlobCellHTML(cellContent, domain, datasetUid) {
  if (_.isEmpty(cellContent)) {
    return '';
  }

  const href = `https://${domain}/views/${datasetUid}/files/${cellContent}`;
  return `<a href="${href}" target="_blank" rel="external">${_.escape(cellContent)}</a>`;
}

/**
 * Render a numeric value as currency
 */
function renderMoneyCellHTML(cellContent, column) {
  const locale = getLocale(window);
  const format = _.extend(
    {
      currency: getCurrency(locale),
      decimalSeparator: getDecimalCharacter(locale),
      groupSeparator: getGroupCharacter(locale),
      humane: 'false',
      precision: 2
    },
    column.format || {}
  );
  const getHumaneProperty = (formatToCheck) => {
    // So, the 'true/false'-ness of the humane property is actually serialized
    // as the string literals 'true' and 'false', not by actual boolean values
    // in the JSON response from the /api/views endpoint.
    //
    // Accordingly, we need to actually compare strings when deciding whether
    // or not to use 'humane' numbers as opposed to simply reading the value
    // out of the column format blob.
    //
    // Although this is expressed below as a not-equals comparison, the intent
    // is basically just to return the value false if the string matches
    // 'false' and the value true if it does not.
    return _.get(formatToCheck, 'humane', 'false').toLowerCase() !== 'false';
  };
  const currencySymbol = getCurrencySymbol(format);
  const amount = parseFloat(cellContent);

  if (_.isFinite(amount)) {
    const isNegative = amount < 0;

    if (getHumaneProperty(format) || format.forceHumane) {
      // We can't use formatNumber here because this use case is
      // slightly different — we want to enforce a certain precision,
      // whereas the normal humane numbers want to use the fewest
      // digits possible at all times.
      // The handling on thousands-scale numbers is also different,
      // because humane currency will always be expressed with the K
      // scale suffix, whereas our normal humane numbers allow four-
      // digit thousands output.
      const absVal = Math.abs(amount);

      if (absVal < 1000) {
        cellContent = absVal.toFixed(format.precision).replace('.', format.decimalSeparator);
      } else {
        // At this point, we know that we're going to use a suffix for
        // scale, so we lean on commaify to split up the scale groups.
        // The number of groups can be used to select the correct
        // scale suffix, and we can do precision-related formatting
        // by taking the first two scale groups and treating them
        // as a float.
        // For instance, "12,345,678" will become an array of three
        // substrings, and the first two will combine into "12.345"
        // so that our toFixed call can work its magic.
        const scaleGroupedVal = commaify(Math.floor(absVal)).split(format.groupSeparator);
        const symbols = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
        let symbolIndex = scaleGroupedVal.length - 2;

        let value = parseFloat(scaleGroupedVal[0] + '.' + scaleGroupedVal[1]);

        value = value.toFixed(format.precision);

        if (parseFloat(value) === 1000) {
          // The only edge case is when rounding takes us into the
          // next scale group: 999,999 should be 1M not 1000K.
          value = '1';

          if (format.precision > 0) {
            value += '.' + _.repeat('0', format.precision);
          }

          symbolIndex++;
        }

        cellContent = value.replace('.', format.decimalSeparator) + symbols[symbolIndex];
      }
    } else {
      // Normal formatting without abbreviation.
      const commaifyOptions = {
        groupCharacter: format.groupSeparator,
        decimalCharacter: format.decimalSeparator
      };

      cellContent = commaify(Math.abs(amount).toFixed(format.precision), commaifyOptions);
    }

    const sign = isNegative ? '-' : '';

    cellContent = `${currencySymbol}${sign}${cellContent}`;
  }

  return _.escape(cellContent);
}

/**
 * Render a url cell.
 */
function renderUrlCellHTML(cellContent) {
  if (_.isEmpty(cellContent)) {
    return '';
  }

  if (_.isString(cellContent)) {
    return `<a href="${_.escape(cellContent)}" target="_blank" rel="external">${_.escape(cellContent)}</a>`;
  } else {
    const { url, description } = cellContent;
    const text = _.escape(description || url);
    return `<a href="${url}" target="_blank" rel="external">${text}</a>`;
  }
}

/**
 * Render an email cell.
 */
function renderEmailCellHTML(cellContent) {
  if (_.isEmpty(cellContent)) {
    return '';
  }

  return `<a href="mailto:${cellContent}" target="_blank" rel="external">${_.escape(cellContent)}</a>`;
}

/**
 * Render a phone cell.
 */
function renderPhoneCellHTML(cellContent) {
  if (_.isEmpty(cellContent)) {
    return '';
  }

  // Only permit digits, spaces, and selected punctuation.
  // This is *NOT* validated on the backend; XSS mitigation for EN-18885.
  const filterDisallowed = (raw) => {
    return (raw || '').replace(/[^\d\s(),.+*#-]/g, '').trim();
  };

  if (_.isString(cellContent)) {
    return `<a href="tel:${filterDisallowed(cellContent)}" target="_blank" rel="external">${_.escape(
      cellContent
    )}</a>`;
  } else {
    const phoneNumber = _.get(cellContent, 'phone_number', '');
    return `<a href="tel:${filterDisallowed(phoneNumber)}" target="_blank" rel="external">${_.escape(
      phoneNumber
    )}</a>`;
  }
}

/**
 * Render a photo cell.
 *
 * TODO: Remove this function once we don't need to support OBE datasets
 */
function renderPhotoCellHTML(cellContent, domain, datasetUid) {
  if (_.isEmpty(cellContent)) {
    return '';
  }

  const href = `https://${domain}/views/${datasetUid}/files/${cellContent}`;
  return `<a href="${href}" target="_blank" rel="external">${_.escape(cellContent)}</a>`;
}

/**
 * Render a document cell.
 *
 * TODO: Remove this function once we don't need to support OBE datasets
 */
function renderDocumentCellHTML(cellContent, domain, datasetUid) {
  if (_.isEmpty(cellContent)) {
    return '';
  }

  const filename = _.get(cellContent, 'filename', null);
  const filenameParam = _.isNull(filename) ? '' : `filename=${encodeURIComponent(filename)}`;
  const contentType = _.get(cellContent, 'content_type', null);
  const contentTypeParam = _.isNull(contentType) ? '' : `content_type=${encodeURIComponent(contentType)}`;
  const maybeQuestionMark = _.isEmpty(filenameParam) && _.isEmpty(contentTypeParam) ? '' : '?';
  const maybeAmpersand = _.isEmpty(filenameParam) && _.isEmpty(contentTypeParam) ? '' : '&';
  const params = `${maybeQuestionMark}${filenameParam}${maybeAmpersand}${contentTypeParam}`;
  const href = `https://${domain}/views/${datasetUid}/files/${cellContent.file_id}${params}`;

  return `<a href="${href}" target="_blank" rel="external">${_.escape(cellContent.filename)}</a>`;
}

/**
 * Render a multiple choice cell.
 *
 * TODO: Remove this function once we don't need to support OBE datasets
 */
function renderMultipleChoiceCellHTML(cellContent, column) {
  if (_.isEmpty(cellContent)) {
    return '';
  }

  const selectedOption = _.find(_.get(column, 'dropDownList.values', []), function (option) {
    return _.isEqual(option.id, cellContent);
  });

  return _.escape(selectedOption ? selectedOption.description : '');
}

/**
 * Render a date or timestamp following column formatting, otherwise following defaults.
 */

function renderTimestampCellUnsafePlainText(cellContent, column) {
  if (!_.isString(cellContent) && !_.isNumber(cellContent)) {
    return '';
  }

  if (_.isNumber(cellContent)) {
    // If we receive seconds, convert to milliseconds.
    cellContent = cellContent * 1000;
  }

  let time;

  // EN-18426 - Grouping on year in an FCC dataset returns unexpected results
  //
  // When we do aggregations involving a date_trunc or whatever we return timestamp
  // dates that are equivalent to "YYYY-01-01T00:00:00Z". This causes ther timestamp
  // renderer to display results in the browser's timezone that are incorrect (e.g.
  // an aggregation on year will always show the actual grouping year - 1 for users
  // that are on the negative side of zulu time. This is because all dates are
  // converted to UTC by the backend in order to do the aggregation.
  //
  // One fix for this, which is implemented below, is to treat the value as UTC if
  // we know that the column is aggregated, which will cause the rendering to show
  // the correct grouping value instead of the one represented in the browser's
  // local timezone.
  const treatAggregatedDatesAsUTC = _.get(window, 'blist.feature_flags.treat_aggregated_dates_as_utc', false);
  const columnIsAggregated = _.get(column, 'format.group_function', false);

  if (treatAggregatedDatesAsUTC && columnIsAggregated) {
    time = moment(cellContent).utc();
  } else {
    time = moment(cellContent);
  }

  if (!time.isValid()) {
    return '';
  }

  const formatString = _.get(column, 'format.formatString');
  const formatStyle = _.get(column, 'format.view');

  if (formatString) {
    // Option A: format using user-specified format string
    return time.format(formatString);
  } else if (formatStyle) {
    // Option B: format using preferred builtin style
    const fallbackFormat = TIME_FORMATS.date_time;
    return time.format(TIME_FORMATS[formatStyle] || fallbackFormat);
  } else {
    // Option C: use date-with-time format
    return time.format(TIME_FORMATS.default_date_time);
  }
}

/**
 * Render a date or timestamp following column formatting, otherwise following defaults.
 */

function renderTimestampCellHTML(cellContent, column) {
  return _.escape(renderTimestampCellUnsafePlainText(cellContent, column));
}

function getCellAlignment(column) {
  const alignment = _.get(column, 'format.align');
  // EN-40614 - Prevent XSS via column formatting
  // Since there are only three allowed values, check if the saved value
  // is in the allowed list. If not, use column default.
  const allowedAlignments = ['left', 'right', 'center'];
  if (allowedAlignments.includes(alignment)) {
    return alignment;
  }

  switch (column.renderTypeName) {
    case 'number':
    case 'money':
    case 'percent':
    case 'stars':
      return 'right';

    case 'checkbox':
      return 'center';

    default:
      return 'left';
  }
}

function getRowConditionalFormattingStyles(row, conditionalFormattingRules) {
  const getStylesForRule = (rule) => {
    return `background-color:${_.get(rule, 'color', '#fff')};`;
  };

  if (!_.isArray(conditionalFormattingRules)) {
    return [];
  }

  const styles = conditionalFormattingRules.map((rule) => {
    // A single, global rule.
    if (rule.condition === true && _.isString(rule.color)) {
      return getStylesForRule(rule);
    }

    const ruleOperator = _.get(rule, 'condition.operator', null);

    if (!_.get(rule, 'condition', {}).hasOwnProperty('children')) {
      _.set(rule, 'condition.children', [
        {
          operator: _.get(rule, 'condition.operator', ''),
          tableColumnId: _.get(rule, 'condition.tableColumnId', -1),
          value: _.get(rule, 'condition.value', '')
        }
      ]);
    }

    // Map across all the rules for this particular condition
    const matches = _.get(rule, 'condition.children', []).map(
      ({ tableColumnId: conditionColumn, value: conditionValue, subcolumn, operator: conditionOperator }) => {
        // Get the cell of the referred column
        const dataCell = _.find(row, ({ tableColumnId }) => {
          return tableColumnId == conditionColumn;
        });

        if (_.isEmpty(dataCell)) {
          return false;
        }

        let cellValue;

        switch (dataCell.dataType) {
          case 'number':
          case 'money':
          case 'percent':
          case 'date':
          case 'stars':
            cellValue = parseFloat(dataCell.cellContent);
            break;

          default:
            cellValue = dataCell.cellContent;
            break;
        }

        // Eh?
        if (_.isString(subcolumn)) {
          cellValue = _.get(dataCell.cellContent, subcolumn, null);
        }

        // Test the column
        switch (conditionOperator) {
          case 'EQUALS':
            return _.isEqual(conditionValue, cellValue);
          case 'NOT_EQUALS':
            return !_.isEqual(conditionValue, cellValue);
          case 'STARTS_WITH':
            return new RegExp('^' + _.escapeRegExp(conditionValue)).test(cellValue);
          case 'CONTAINS':
            return new RegExp(_.escapeRegExp(conditionValue)).test(cellValue);
          case 'NOT_CONTAINS':
            return !new RegExp(_.escapeRegExp(conditionValue)).test(cellValue);
          case 'LESS_THAN':
            return cellValue < conditionValue;
          case 'LESS_THAN_OR_EQUALS':
            return cellValue <= conditionValue;
          case 'GREATER_THAN':
            return cellValue > conditionValue;
          case 'GREATER_THAN_OR_EQUALS':
            return cellValue >= conditionValue;
          case 'BETWEEN':
            return cellValue > conditionValue[0] && cellValue < conditionValue[1];
          case 'IS_BLANK':
            return _.isNull(cellValue) || cellValue === false;
          case 'IS_NOT_BLANK':
            return !_.isNull(cellValue) && cellValue !== false;
          default:
            return false;
        }
      }
    );

    if (ruleOperator === 'and' && !matches.includes(false)) {
      return getStylesForRule(rule);
    } else if (ruleOperator !== 'and' && matches.includes(true)) {
      return getStylesForRule(rule);
    } else {
      return null;
    }
  });

  return _.reject(styles, _.isNull);
}

/**
 * hoisted helper methods below
 * (must belong to this scope in order to access $window)
 */

function _applyNumberFormat(amount, format, retainSmallDecimals = true, returnParsableNumber = false) {
  let value = amount;

  if (format.forceHumane) {
    value = formatNumber(value, format, retainSmallDecimals);
  } else {
    if (format.precision >= 0) {
      value = BigNumber(String(value)).toFixed(format.precision);
    }

    if (returnParsableNumber) return value;

    value = commaify(value, format.commaifyOptions);

    // If the group separator is an empty string, removing the group separator wouldn't change anything.
    if (format.groupSeparator !== '' && (format.noCommas === true || format.noCommas === 'true')) {
      value = value.replace(new RegExp('\\' + format.groupSeparator, 'g'), '');
    }
  }

  return value;
}

function _renderCurrencyNumber(amount, format, returnParsableNumber = false) {
  const isNegative = amount < 0;

  let value = Math.abs(amount);
  value = _applyNumberFormat(value, format, true, returnParsableNumber);

  const sign = isNegative ? '-' : '';

  if (returnParsableNumber) return `${sign}${value}`;

  const currencySymbol = getCurrencySymbol(format);
  return `${currencySymbol}${sign}${value}`;
}

function _renderFinancialNumber(amount, format, returnParsableNumber = false) {
  const isNegative = amount < 0;
  let value = Math.abs(amount);

  value = _applyNumberFormat(value, format, true, returnParsableNumber);

  if (returnParsableNumber) {
    return isNegative ? `-${value}` : value;
  }

  return isNegative ? `(${value})` : String(value);
}

function _renderScientificNumber(amount, format) {
  const value = amount.toExponential(format.precision);

  // no groups, so we can skip groupSeparator and commaify and noCommas
  return value.replace('.', format.decimalSeparator);
}

/**
 * @param  {BigNumber} safeAmount
 * @param  {object} format view format object
 * @return {string} formatted percent
 */
function _renderPercentageNumber(
  safeAmount,
  format,
  retainSmallDecimals = true,
  returnParsableNumber = false
) {
  const { percentScale } = format;
  const percentAmount = percentScale === '1' ? safeAmount.times(100).toNumber() : safeAmount.toNumber();
  const value = _applyNumberFormat(percentAmount, format, retainSmallDecimals, returnParsableNumber);

  if (returnParsableNumber) return value;

  return value + '%';
}

function _renderStandardNumber(amount, format, retainSmallDecimals = true, returnParsableNumber = false) {
  return _applyNumberFormat(amount, format, retainSmallDecimals, returnParsableNumber);
}

// NOTE: In the dataset view, a mask can lead to some really strange output.
// We're going to start with a simple approach and refine as we go on.
function _renderMaskedNumber(amount, format) {
  const maskChar = '#';
  let amountChars = String(amount).split('');
  let output = format.mask.slice(0, amountChars.length);

  while (output.indexOf(maskChar) > -1) {
    output = output.replace(maskChar, amountChars.shift());
  }
  output += amountChars.join('');

  return output;
}

function _cellCoordinates(cellContent) {
  const coordinates = _.get(cellContent, 'value.coordinates', cellContent.coordinates);
  return _.isArray(coordinates) ? coordinates : null;
}
