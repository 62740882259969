import React, { FunctionComponent, useEffect, useState } from 'react';

import I18n from 'common/i18n';
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import Button, { VARIANTS } from 'common/components/Button';
import { getCurrentUser } from 'common/current_user';

import { getExpireIfIdle, redirectToSignedOut, renewSession } from './Util';

import './styles.scss';

/** When the session has this many seconds left, we show the modal */
export const SecondsToCountdownFrom = 60;

/**
 * Gets the current session's expiration time and, if it's less than one minute,
 * sets the countdown seconds.
 *
 * @param setCountdownSeconds Function to start the seconds countdown timer
 */
const checkExpireIfIdle = async (
  setCountdownSeconds: React.Dispatch<React.SetStateAction<number | undefined>>
) => {
  const expireIfIdleResponse = await getExpireIfIdle();

  // if it's already expired, redirect the user to `/signed_out` to perform a cleanup
  if (expireIfIdleResponse.expired === 'expired' || expireIfIdleResponse.noSession === 'noSession') {
    redirectToSignedOut();
    return;
  }

  // if we're within a minute of expiring, start the countdown!
  // this is what actually shows the modal
  if (expireIfIdleResponse.seconds) {
    setCountdownSeconds(expireIfIdleResponse.seconds);

    // we check the timeout again 5 seconds before we'd show the modal
    // this is mainly for the case that the user leaves one tab open for a long time, but they're active somewhere else,
    // which results in a renewed session meaning we don't want to show the modal.
    const secondsUntilWeShouldCheckAgain = expireIfIdleResponse.seconds - SecondsToCountdownFrom - 2;
    if (secondsUntilWeShouldCheckAgain > 0) {
      setTimeout(() => checkExpireIfIdle(setCountdownSeconds), secondsUntilWeShouldCheckAgain * 1000);
    }
  }
};

const SessionTimeoutModal: FunctionComponent = () => {
  // used to countdown how long the user has to take an action before automatically being logged out
  const [countdownSeconds, setCountdownSeconds] = useState<number | undefined>(undefined);

  // timeout from setTimeout that decrements countdownSeconds
  const [countDownSecondsTimeout, setCountdownSecondsTimeout] = useState<NodeJS.Timeout | undefined>(
    undefined
  );

  const currentUser = getCurrentUser();

  // this effect is only run when the component mounts, and only if we have a current user
  // this kicks off the call to the session expiration service to check how many seconds we have left
  useEffect(() => {
    if (currentUser) {
      checkExpireIfIdle(setCountdownSeconds);
    }
  }, []);

  // this effect is run every time `countdownSeconds` changes
  // it sets a timeout of 1 second when it's done running, which will cause the effect to run again after a second has passed
  useEffect(() => {
    if (countDownSecondsTimeout) {
      clearTimeout(countDownSecondsTimeout);
      setCountdownSecondsTimeout(undefined);
    }

    (async () => {
      // NOTE: Have to compare with undefined here since `if(countdownSeconds)` evaluates to true if countdownSeconds is 0
      if (countdownSeconds !== undefined) {
        if (countdownSeconds <= 0) {
          if (countDownSecondsTimeout) {
            clearTimeout(countDownSecondsTimeout);
          }

          // we hit the endpoint again here to get their session in case they extended it somewhere (like in another tab)
          // if the session has _actually_ expired, this will redirect to `/signed_out`
          // otherwise, it will set `countdownSeconds` to what's left in the session
          await checkExpireIfIdle(setCountdownSeconds);
        } else {
          // this will decrement countdownSeconds in one second, which causes this effect to run again
          setCountdownSecondsTimeout(setTimeout(() => setCountdownSeconds(countdownSeconds - 1), 1000));
        }
      }
    })();
  }, [countdownSeconds]);

  const onRenewSession = async () => {
    // stop our countdown timer if we have one
    if (countDownSecondsTimeout) {
      clearTimeout(countDownSecondsTimeout);
      setCountdownSecondsTimeout(undefined);
    }

    // clear out the countdown seconds; this hides the modal
    setCountdownSeconds(undefined);

    // actually renew the session and re-fetch the time until expiration
    await renewSession();
    await checkExpireIfIdle(setCountdownSeconds);
  };

  // we only show the modal if:
  // - we have a current user
  // - we have a countdown timer going
  // - the countdown is below our "seconds to countdown from"
  if (!currentUser || countdownSeconds === undefined || countdownSeconds > SecondsToCountdownFrom) {
    return null;
  }

  return (
    <Modal onDismiss={() => onRenewSession()}>
      <ModalHeader title={I18n.t('common.session_timeout_modal.title')} showCloseButton={false} />

      <ModalContent>
        <div className="session-timeout-modal__content">
          {I18n.t('common.session_timeout_modal.message', { seconds: countdownSeconds })}
        </div>
      </ModalContent>

      <ModalFooter>
        {/* Sign Out button */}
        <Button className="session-timeout-modal__sign-out-button" onClick={redirectToSignedOut}>
          {I18n.t('common.session_timeout_modal.sign_out')}
        </Button>

        {/* Stay Signed In button */}
        <Button
          variant={VARIANTS.PRIMARY}
          className="session-timeout-modal__renew-session-button"
          onClick={onRenewSession}
        >
          {I18n.t('common.session_timeout_modal.stay_signed_in')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SessionTimeoutModal;
