import React, { FunctionComponent } from 'react';
import { useMutation } from 'react-query';

import { Federation } from '@socrata/core-federations-api';

import { View } from 'common/types/view';
import { AssetFederationApi, parseError } from 'common/core/federations';
import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';

interface AssetFederationTableActionCellProps {
  /** View being acted upon */
  view: View;

  /** Federation that we're taking action on */
  federation: Federation;

  /**
   * Function to call to re-fetch list of all federations for the current asset.
   * Will be called after successfully deleting a federation.
   */
  refetchFederationRelationships: () => void;
}

/** Table cell containing per-federation actions */
const AssetFederationTableActionCell: FunctionComponent<AssetFederationTableActionCellProps> = ({
  view,
  federation,
  refetchFederationRelationships
}) => {
  const { mutate: deleteFederationRelationship, isLoading } = useMutation(
    async (cnameToDelete: string) => {
      try {
        await AssetFederationApi.deleteAssetFederation({ viewId: view.id, targetDomainCName: cnameToDelete });
      } catch (error) {
        console.error(error);
        throw await parseError(error);
      }
    },
    {
      onSuccess: refetchFederationRelationships
    }
  );

  // currently, this will only ever show a "Terminate" link
  // in the future, you will probably have to accept federations on the target domain so this will
  // need to be updated to look more like the frontend federation table actions
  return (
    <Button
      variant={VARIANTS.LINK}
      className="federate-to--terminate-link"
      onClick={() => {
        if (
          confirm(
            I18n.t('shared.components.asset_action_bar.publication_action.federate_modal.confirm_removal', {
              domain: federation.targetDomainCName
            })
          )
        ) {
          deleteFederationRelationship(federation.targetDomainCName!);
        }
      }}
      disabled={isLoading}
    >
      {I18n.t('shared.federations.actions.terminate')}
    </Button>
  );
};

export default AssetFederationTableActionCell;
