/* Imports */
/* ============================================================================= */
import { isEmpty } from 'lodash';

import { WorkflowGuidance } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Verify that the provided guidance value is a WorkflowGuidance object
 * @param guidance The value to test
 * @returns boolean
 */
export const isWorkflowGuidance = (guidance: any): guidance is WorkflowGuidance => {
  return (
    // Guidance is not empty
    !isEmpty(guidance) &&

    // Guidance has the minimum attribute present and set
    'currentState' in guidance && !!(guidance.currentState)
  );
};

export default isWorkflowGuidance;
