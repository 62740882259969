import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';

import type { AccessLevel } from 'common/types/view';

import { PUBLISHED_VIEWER_ACCESS_LEVEL } from 'common/components/AccessManager/Constants';
import { accessLevelsEqual, accessLevelKey } from 'common/components/AccessManager/Util';

import { getAvailableAccessLevels } from 'common/components/AccessManager/sagas/Selectors';

import './dropdown.scss';

const classNameScope = 'common--components--AccessManager--components--AccessLevelDropdown';

export interface DropdownOption {
  title: string;
  value: AccessLevel;
}

interface AccessLevelDropdownProps {
  onSelection: (value: DropdownOption) => boolean | undefined | void;
  value: AccessLevel;
}

/**
 * Since "viewer" of the "published" version is actually controlled
 * by the AudienceScopeChooser, we don't want to display it here.
 */
const shouldIncludeLevel = (level: AccessLevel) => !accessLevelsEqual(level, PUBLISHED_VIEWER_ACCESS_LEVEL);

/**
 * Given a list of access levels, translates them to options for the dropdowns
 * (that is, their translated name as the title)
 */
const accessLevelsToOptions = (levels: AccessLevel[]): DropdownOption[] =>
  levels.filter(shouldIncludeLevel).map((level) => ({
    title: I18n.t(accessLevelKey(level.name)),
    value: level
  }));

/**
 * Dropdown for choosing the "Access Level" for a user
 * (that is, basically, their view-level role)
 */
const AccessLevelDropdown: FunctionComponent<AccessLevelDropdownProps> = ({ onSelection, value }) => {
  const options = useSelector(getAvailableAccessLevels);

  if (!options) {
    return null;
  }

  return (
    <div className={`${classNameScope}--dropdown`}>
      <Dropdown
        picklistSizingStrategy={picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM}
        onSelection={onSelection}
        options={accessLevelsToOptions(options)}
        value={value}
        placeholder={null}
      />
    </div>
  );
};

export default AccessLevelDropdown;
