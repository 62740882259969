import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import {
  GridApi,
  RowGroupOpenedEvent,
  IRowNode,
  IsServerSideGroupOpenByDefaultParams
} from '@ag-grid-community/core';

const groupNodeId = (node: IRowNode): string => {
  const route = node.getRoute();
  if (!_.isEmpty(route)) {
    return JSON.stringify(route);
  } else {
    return '';
  }
};

export const useGroupStateRestore = () => {
  const [expandedRoutesMap] = useState({});
  const isServerSideGroupOpenByDefault = useCallback(
    (params: IsServerSideGroupOpenByDefaultParams) => {
      return groupNodeId(params.rowNode) in expandedRoutesMap;
    },
    [expandedRoutesMap]
  );

  const onRowGroupOpened = useCallback(
    ({ node }: RowGroupOpenedEvent) => {
      const id = groupNodeId(node);
      if (node.expanded) {
        expandedRoutesMap[id] = true;
      } else {
        delete expandedRoutesMap[id];
      }
    },
    [expandedRoutesMap]
  );

  return { isServerSideGroupOpenByDefault, onRowGroupOpened };
};
