/* Imports */
/* ============================================================================= */
import { WorkflowGuidance } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Returns whether or not an asset can be submitted to be published.
 * This is only relevant for assets that have already been published and are being updated.
 *
 * NOTE: If this is not passed a guidance summary, it will throw an error!
 *
 * @param guidance WorkflowGuidance object
 * @returns boolean
 */
export const canSubmitUpdatePublishedV2 = (guidance: WorkflowGuidance) => {
  return !!guidance.toUpdatePublishedAsset;
};

export default canSubmitUpdatePublishedV2;
