import React, { ChangeEvent, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AccessLevel } from 'common/types/view';
import { CatalogUserOrTeam, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';

import * as addCollaboratorsActions from 'common/components/AccessManager/actions/AddCollaboratorsActions';
import * as permissionsActions from 'common/components/AccessManager/actions/PermissionsActions';
import AddUser from 'common/components/AccessManager/components/AddUser';
import { strictPermissionsEnabled } from 'common/components/AccessManager/Util';
import { MODES } from 'common/components/AccessManager/Constants';
import {
  getAccessLevel,
  getUiMode,
  getAddCollaboratorsSearchResults,
  getAddCollaboratorsSearchQuery,
  getAddCollaboratorsSelectedUsers
} from 'common/components/AccessManager/sagas/Selectors';
import { DropdownOption } from 'common/components/AccessManager/components/AccessLevelDropdown';

/**
 * Basically just wraps the <AddUsers /> component into a bunch of redux stuff
 * for adding collaborators
 */
const AddCollaborators: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onAccessLevelDropdownChanged = (level: DropdownOption) => {
    dispatch(addCollaboratorsActions.selectedCollaboratorAccessLevelChanged(level));
  };

  const addUsers = (users: CatalogUserOrTeam[], accessLevel: AccessLevel, mode: MODES) =>
    dispatch(permissionsActions.addUsers(users, accessLevel, mode));

  const validateForm = (selectedUsers: CatalogUserOrTeam[], selectedAccessLevel: AccessLevel) =>
    dispatch(addCollaboratorsActions.validateUserInput(selectedUsers, selectedAccessLevel));

  const addSelectedCollaborator = (searchResult?: UsersCatalogSearchResults) =>
    searchResult &&
    dispatch(addCollaboratorsActions.addSelectedCollaborator(searchResult.user || searchResult.team!));

  const removeSelectedCollaborator = (user?: CatalogUserOrTeam) =>
    user && dispatch(addCollaboratorsActions.removeSelectedCollaborator(user));

  const collaboratorsSearchQueryChanged = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(addCollaboratorsActions.collaboratorsSearchQueryChanged(event.target.value));

  const selectedAccessLevel = useSelector(getAccessLevel);
  const mode = useSelector(getUiMode);
  const searchResults = useSelector(getAddCollaboratorsSearchResults);
  const currentSearchQuery = useSelector(getAddCollaboratorsSearchQuery);
  const selectedUsers = useSelector(getAddCollaboratorsSelectedUsers);

  return (
    <AddUser
      addToSelectedUsers={addSelectedCollaborator}
      addUsers={addUsers}
      currentSearchQuery={currentSearchQuery}
      hideAccessLevelDropdown={strictPermissionsEnabled() || mode === MODES.MANAGE_PLUGIN}
      onAccessLevelDropdownChanged={onAccessLevelDropdownChanged}
      removeFromSelectedUsers={removeSelectedCollaborator}
      selectedAccessLevel={selectedAccessLevel!}
      searchResults={searchResults}
      selectedUsers={selectedUsers}
      userSearchQueryChanged={(event) => collaboratorsSearchQueryChanged(event)}
      validateForm={validateForm}
      mode={mode}
    />
  );
};

export default AddCollaborators;
