import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import ConfigurationsPropType from 'common/notifications/PropTypes/ConfigurationsPropType';
import EmailSettings from './EmailSettings';
import TransientNotificationSettings from './TransientNotificationSettings';
import PreferenceTable from './PreferenceTable';
import './preference.scss';

export class PreferenceContent extends Component {
  renderEmailSettings() {
    const { settings, onSettingsChange, showMyAlertPreference } = this.props;
    return (
      <EmailSettings
        settings={settings}
        onSettingsChange={onSettingsChange}
        showAlertSetting={showMyAlertPreference} />
    );
  }

  renderTransientNotificationSettings() {
    const { settings, onSettingsChange } = this.props;

    return (
      <TransientNotificationSettings
        settings={settings}
        onSettingsChange={onSettingsChange} />
    );
  }

  render() {
    const { onSelectAllPreferenceTypeChange, onAlertNotificationChange, preferences } = this.props;

    return (
      <div className="preferences-content">
        <form>
          <PreferenceTable
            preferences={preferences}
            onSelectAllPreferenceTypeChange={onSelectAllPreferenceTypeChange}
            onAlertNotificationChange={onAlertNotificationChange}
          />
          {this.renderEmailSettings()}
          {this.renderTransientNotificationSettings()}
        </form>
      </div>
    );
  }
}

// See TypeScript interfaces in common/notifications/types for better type info.
PreferenceContent.propTypes = {
  configurations: ConfigurationsPropType,
  /**
   * Individual alert preferences (Approvals changes, data updates, etc).
   */
  preferences: PropTypes.object.isRequired,
  /**
   * Global alert settings (frequency, individual vs. digest, transient messaging).
   */
  settings: PropTypes.object.isRequired,
  onAlertNotificationChange: PropTypes.func,
  onSelectAllPreferenceTypeChange: PropTypes.func,
  onSettingsChange: PropTypes.func
};

const mapStateToProps = state => ({
  configurations: state.configurations,
  showMyAlertPreference: state.configurations.showMyAlertPreference
});


export default connect(mapStateToProps)(PreferenceContent);
