/* Imports */
/* ============================================================================= */
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { BaseGuidanceSummaryV2Helper, GuidanceBooleanCheckSummary, WorkflowGuidanceCheckMethods } from 'common/core/approvals/Types';
import { summarizeGuidanceCheckMethod, createWorkflowGuidanceHelperMethodsObject } from '.';





/* Method */
/* ============================================================================= */
/**
 * Check for which workflow has a pending approval request. Throws an error if more than 1 is found
 * @param guidance GuidanceSummaryV2 object
 * @param guidanceHelper BaseGuidanceSummaryV2Helper object
 * @returns GuidanceBooleanCheckSummary object
 */
export const summarizeIsPending = (guidance: GuidanceSummaryV2, guidanceHelper: BaseGuidanceSummaryV2Helper): GuidanceBooleanCheckSummary => {
  const response = summarizeGuidanceCheckMethod<boolean, false>(
    guidance,
    createWorkflowGuidanceHelperMethodsObject<WorkflowGuidanceCheckMethods<boolean>>(guidanceHelper, 'isPending'),
    false
  );

  if (response.length > 1)
    throw new Error('Invalid asset state detected - withGuidanceV2.summarizeIsPending');

  return response;
};

export default summarizeIsPending;
