
import { FunCall, SoQLFunCall, TypedSoQLFunCall } from 'common/types/soql';
import React from 'react';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import ChangeFunction from './ChangeFunction';
import { renderArgs } from './EditFunction';
import FunctionBadge from './FunctionBadge';

function EditBetween(props: ExprProps<FunCall, TypedSoQLFunCall>) {
  const [subject, lowerBound, upperBound] = renderArgs(props);

  return (
    <AstNode {...props} className="funcall block-level-change-icon operator" removable showSuccess={props.querySucceeded}>
      {subject}
      <ChangeFunction {...props} scope={props.scope}>
        <FunctionBadge fun={props.eexpr.fold(eexpr => eexpr.untyped, eexpr => eexpr.expr)} />
      </ChangeFunction>
      {lowerBound}
      <span>
        AND
      </span>
      {upperBound}
    </AstNode>
  );
}

const shouldRenderAsBetween = (f: FunCall) => (
  f.function_name === SoQLFunCall.Between || f.function_name === SoQLFunCall.NotBetween
);

export { EditBetween, shouldRenderAsBetween };

