import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment-timezone';
import jstz from 'jstz';

import I18n from 'common/i18n';
import _getLocale from 'common/js_utils/getLocale';
import 'common/types/windowSocrata';

/** Expects serverConfig.localePrefix  */
export const localizeLink = (href: string) => {
  const config = window.serverConfig;

  if (config?.hasOwnProperty('localePrefix')) {
    return `${config!.localePrefix}${href}`;
  }

  console.warn('Expected serverConfig to contain a localePrefix property. Your links will not be localized');

  return href;
};

export const fetchTranslation = (key: string, scope: string, defaultValue?: string, options = {}) => {
  return scope ? I18n.t(key, { ...options, scope, defaultValue }) : I18n.t(key, { ...options, defaultValue });
};

export const hasTranslation = (key: string, scope: string) => {
  // hahaha this is such a hack but there's not a better way i see
  return !!_.get(I18n.translations[I18n.locale], scope + '.' + key, false);
};

/**
 * See also platform-ui/frontend/public/javascripts/screens/all-screens.js
 * See also platform-ui/frontend/public/javascripts/screens/site-appearance.js
 * See also platform-ui/common/js_utils/getLocale.js
 */
export const getLocale = () => _getLocale(window);

const getTimezone = () =>
  _.get(window.blist, 'configuration.userTimeZoneName', jstz.determine().name()) as string;

/**
 * If any of this logic changes, please make corresponding changes to:
 * frontend/public/javascripts/screens/all-screens.js
 * On or about ~L230:$('.dateLocalize').each(function() { ...
 * Also formatDateWithLocale in common/dates.js.
 */
export const dateLocalize = (element: HTMLElement) => {
  const $dateSpan = $(element);
  const format = $dateSpan.data('format');
  const rawdate = $dateSpan.data('rawdatetime') * 1000;

  $dateSpan.text(moment(rawdate).tz(getTimezone()).locale(getLocale()).format(format));
};
