import $ from 'jquery';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18n from 'common/i18n';

import { THROTTLE_LAZY_LOADING_FOR } from 'common/notifications/constants';

import AlertDataGridModal from 'common/notifications/components/UserNotifications/AlertDataGridModal';
import UserNotification from 'common/notifications/components/UserNotifications/UserNotification';

const scope = 'shared.site_chrome.notifications';

export class UserNotificationList extends Component {
  state = {
    showDataGridModal: false,
    dataGridModalForNotification: null
  };

  componentDidMount() {
    this.lazyLoadUserNotifications();
  }

  lazyLoadUserNotifications = () => {
    const { onLoadMoreUserNotifications } = this.props;
    const self = this;

    $('#activity-notification-items, #alert-notification-items').on('scroll', _.throttle(() => {
      const $loadMoreNotificationsWrapper = $('#load-more-notifications-wrapper');

      if ($loadMoreNotificationsWrapper.find('.load-more-user-notifications-link').is(':visible')) {
        if (self.isElementInViewport($loadMoreNotificationsWrapper.get(0))) {
          onLoadMoreUserNotifications($loadMoreNotificationsWrapper.data('notifications-type'));
        }
      }
    }, THROTTLE_LAZY_LOADING_FOR));
  };

  isElementInViewport = (element) => {
    const elementBounding = element.getBoundingClientRect();

    return elementBounding.top >= 0 &&
      elementBounding.left >= 0 &&
      elementBounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      elementBounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
  };

  renderAlertDataGridModal = () => {
    const { showDataGridModal, dataGridModalForNotification } = this.state;

    if (!showDataGridModal || !dataGridModalForNotification) {
      return null;
    }

    const {
      alertDomain,
      alertDatasetUid,
      alertDescription,
      alertName,
      alertQueryString,
      datasetName,
      downloadLink
    } = dataGridModalForNotification;

    const alertDataGridModalProps = {
      datasetName,
      datasetUid: alertDatasetUid,
      datasetDownloadLink: downloadLink,
      description: alertDescription,
      domain: alertDomain,
      onClose: () => this.setState({ showDataGridModal: false }),
      soqlQuery: alertQueryString,
      alertName
    };

    return (<AlertDataGridModal {...alertDataGridModalProps} />);
  };

  renderEmptyNotificationsMessage = () => {
    const {
      filterNotificationsBy
    } = this.props;
    let emptyNotificationsI18nKey = 'no_notifications_to_show';

    switch (filterNotificationsBy) {
      case 'activity':
        emptyNotificationsI18nKey = 'no_activities_to_show';
        break;
      case 'alert':
        emptyNotificationsI18nKey = 'no_alerts_to_show';
        break;
    }
    const className = classNames('no-user-notifications-message no-notifications-message', `of-type-${filterNotificationsBy}`);

    return (
      <div
        className={className}>
        <h3>{I18n.t(emptyNotificationsI18nKey, { scope })}</h3>
      </div>
    );
  };

  renderAlertsAndNotifications = () => {
    const {
      filterNotificationsBy,
      onClearUserNotification,
      onToggleReadUserNotification,
      userNotifications,
      showUserNotifications,
    } = this.props;

    if (showUserNotifications) {
      const notifications = _.get(userNotifications[filterNotificationsBy], 'notifications', []);

      const onClickAlertViewData = (notification) => {
        this.setState({
          showDataGridModal: true,
          dataGridModalForNotification: notification
        });
      };

      if (_.isEmpty(notifications)) {
        return this.renderEmptyNotificationsMessage();
      }

      return notifications.map((notification) =>
        <UserNotification
          isTransientNotification={false}
          key={notification.id}
          notification={notification}
          onClickAlertViewData={onClickAlertViewData}
          onClearUserNotification={onClearUserNotification}
          onToggleReadUserNotification={onToggleReadUserNotification} />
      );
    }
  };

  renderLoadMoreUserNotificationLink = () => {
    const { userNotifications, filterNotificationsBy } = this.props;
    const hasMoreNotifications = _.get(userNotifications[filterNotificationsBy], 'hasMoreNotifications', false);

    if (hasMoreNotifications) {
      const { onLoadMoreUserNotifications } = this.props;
      const loadingNotifications = _.get(userNotifications[filterNotificationsBy], 'loading', false);
      const loadingSpinner = (
        <div className="notifications-loading">
          <span className="spinner-default" />
          <span className="notifications-loading-text">{I18n.t('loading', { scope })}</span>
        </div>
      );
      const loadMoreLink = (
        <a
          className="load-more-user-notifications-link"
          onClick={() => { onLoadMoreUserNotifications(filterNotificationsBy); }}>
          {I18n.t('load_more_items', { scope })}
        </a>
      );

      return (
        <div
          className="load-more-user-notifications load-more-user-notifications-link-wrapper"
          data-notifications-type={filterNotificationsBy}
          id="load-more-notifications-wrapper">
          {loadingNotifications ? loadingSpinner : loadMoreLink}
        </div>
      );
    }
  };

  renderSeeMoreNotificationsLink = () => {
    const { filterNotificationsBy, hasEnqueuedUserNotifications } = this.props;

    if (hasEnqueuedUserNotifications(filterNotificationsBy)) {
      const { onSeeNewUserNotifications } = this.props;

      return (
        <div
          className="see-new-user-notifications see-new-user-notifications-link-wrapper">
          <button className="see-new-user-notifications-link" onClick={() => { onSeeNewUserNotifications(filterNotificationsBy); }}>
            {I18n.t('see_new_notifications', { scope })}
          </button>
        </div>
      );
    }
  };

  render() {

    return (
      <div>
        <div className="notification-list-wrapper has-secondary-panel">
          {this.renderSeeMoreNotificationsLink()}
          <ul className="socrata-user-notification-list">{this.renderAlertsAndNotifications()}</ul>
          {this.renderAlertDataGridModal()}
          {this.renderLoadMoreUserNotificationLink()}
        </div>
      </div>
    );
  }
}

UserNotificationList.propTypes = {
  filterNotificationsBy: PropTypes.string.isRequired,
  hasEnqueuedUserNotifications: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired
};

UserNotificationList.defaultProps = {
  userNotifications: null
};

export default UserNotificationList;
