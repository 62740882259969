import React, { useEffect } from 'react';

import UserSearch from 'common/components/UserSearch';
import { fetchJsonWithParsedError } from 'common/http';

import { useWatchContext } from '../context';
import { addToRecipients, recipientSearchFail, recipientSearchSuccess, setRecipientQuery } from '../actions';
import { strictPermissionsEnabled, userAndTeamAutocompleteUrl, permissionsUrl } from 'common/components/AccessManager/Util';
import DomainRights from 'common/types/domainRights';
import { UsersCatalogSearchResult, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import { AudienceScope } from 'common/types/view';
import type { CatalogInteractiveUser } from 'common/types/users/catalogUsers';
import type { ViewPermissions } from 'common/types/view';

const filterUsersWithCorrectPermissions = (
  response: UsersCatalogSearchResult,
  usersWithGrantedPermissions: ViewPermissions,
  usersWithDomainRights: UsersCatalogSearchResult
) => {
  const recipientsWithGrantedPermissionsIds = usersWithGrantedPermissions?.users?.map((r) => r?.id);
  const recipientsWithDomainRightsIds = usersWithDomainRights?.results?.map((r) => r?.user?.id);
  const allowedRecipients = recipientsWithGrantedPermissionsIds?.concat(recipientsWithDomainRightsIds);
  return response?.results.filter((r) => allowedRecipients?.includes(r.user?.id || ''));
};

const filterOutExistingRecipients = (
  response: UsersCatalogSearchResult,
  recipients: CatalogInteractiveUser[]
) => {
  const existingRecipientIds = recipients.map((r) => r.id);
  return response?.results.filter((r) => !existingRecipientIds.includes(r.user?.id || ''));
};

interface RecipientSearchProps {
  fourfour: string;
}

const RecipientSearch = (props: RecipientSearchProps) => {
  const {
    state: { query, recipients, results, domainCname },
    t,
    dispatch
  } = useWatchContext();
  const { fourfour } = props;
  const disabled = recipients.length === 0;

  const filters = {
    rights: strictPermissionsEnabled() ? [DomainRights.can_collaborate] : [],
    includeTeams: false
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (!query) return;

      try {
        const response: UsersCatalogSearchResult = await fetchJsonWithParsedError(
          userAndTeamAutocompleteUrl(query, domainCname, filters)
        );
        const permissions: ViewPermissions = await fetchJsonWithParsedError(
          permissionsUrl(fourfour)
        );
        // Allow all users for a public asset, otherwise allow users with granted permissions or domain rights
        if (permissions?.scope != AudienceScope.Public) {
          const catalogFilters = {
            rights: permissions.scope == AudienceScope.Private ? [DomainRights.view_others_datasets] : [DomainRights.can_view_internal_data],
            includeTeams: false
          };
          const usersWithRights: UsersCatalogSearchResult = await fetchJsonWithParsedError(
            userAndTeamAutocompleteUrl(query, domainCname, catalogFilters)
          );
          response.results = filterUsersWithCorrectPermissions(response, permissions, usersWithRights);
        }

        response.results = filterOutExistingRecipients(response, recipients);
        dispatch(recipientSearchSuccess(response));
      } catch {
        dispatch(recipientSearchFail());
      }
    };

    fetchUsers();
  }, [query]);

  const addToSelectedUsers = (result?: UsersCatalogSearchResults) => {
    dispatch(addToRecipients(result?.user));
  };

  return (
    <div className="watch-search">
      <UserSearch
        addToSelectedUsers={addToSelectedUsers}
        currentQuery={query}
        includeTeamsInHelpText={true}
        placeHolderText={t('enter_names')}
        results={results || []}
        removeFromSelectedUsers={() => {}}
        selectedUsers={[]}
        renderSelectedUserPills={true}
        userSearchQueryChanged={(event) => dispatch(setRecipientQuery(event.target.value))}
      />
      <label className={`userSearchLabel${disabled ? ' disabled' : ''}`}>
        {disabled ? t('add_recipiant') : t('users')}
      </label>
    </div>
  );
};

export default RecipientSearch;
