import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { purify } from 'common/purify';
import { assertIsNotNil } from 'common/assertions';
import { StorytellerState } from '../StorytellerReduxStore';
import { removeEditStoryUrl } from '../../lib/LinkUtils.js';
import Environment from '../../StorytellerEnvironment';

interface ILinkModalPayload {
  editorId?: string;
  inputs: {
    text: string;
    link: string;
    openInNewWindow: boolean;
  };
  valid: boolean;
  urlValidity: boolean;
}

interface ILinkModalInputsPayload {
  editorId?: string;
  text: string;
  link: string;
  openInNewWindow: boolean;
}

export const LINK_MODAL_SLICE_NAME = 'linkModalSlice';

// helper function to format the payload
function prepareLinkModalInputs(payload: ILinkModalInputsPayload) {
  const { editorId, text, link, openInNewWindow } = payload;
  assertIsNotNil(text);
  assertIsNotNil(link);
  assertIsNotNil(openInNewWindow);

  const urlRegex = /^https?:\/\/.+\../;
  const emailRegex = /^(mailto:)?.+@./;

  const safeLink = removeEditStoryUrl(link, Environment.STORY_UID);

  const inputs = {
    text: purify(text),
    link: purify(safeLink),
    openInNewWindow: openInNewWindow
  };

  const valid = _.isString(inputs.text) && _.isString(inputs.link) && inputs.link.length > 0;

  const urlValidity = urlRegex.test(inputs.link) || emailRegex.test(inputs.link);

  // If we have an email address without a protocol, inject the protocol.
  if (emailRegex.test(inputs.link) && !_.startsWith(inputs.link, 'mailto:')) {
    inputs.link = `mailto:${inputs.link}`;
  }

  return {
    payload: {
      editorId,
      inputs,
      valid,
      urlValidity
    }
  };
}

// reducer
const linkModalSlice = createSlice({
  name: LINK_MODAL_SLICE_NAME,
  initialState: {
    inputs: null,
    visible: false,
    editorId: null,
    valid: false,
    urlValidity: false,
    accepted: false
  },
  reducers: {
    openLinkModal: {
      reducer: (state, action: PayloadAction<ILinkModalPayload>) => {
        const { editorId, inputs, valid, urlValidity } = action.payload;
        assertIsNotNil(editorId);

        state.editorId = editorId;
        state.inputs = inputs;
        state.valid = valid;
        state.urlValidity = urlValidity;

        state.visible = true;
      },
      prepare: (payload: ILinkModalInputsPayload) => {
        return prepareLinkModalInputs(payload);
      }
    },
    closeLinkModal: (state) => {
      state.editorId = null;
      state.visible = false;
      state.inputs = null;
      state.valid = false;
      state.urlValidity = false;
      state.accepted = false;
    },
    setLinkModalInputs: {
      reducer: (state, action: PayloadAction<ILinkModalPayload>) => {
        const { inputs, valid, urlValidity } = action.payload;
        state.inputs = inputs;
        state.valid = valid;
        state.urlValidity = urlValidity;
      },
      prepare: (payload: ILinkModalInputsPayload) => {
        return prepareLinkModalInputs(payload);
      }
    },
    setLinkModalAccepted: {
      reducer: (state, action: PayloadAction<ILinkModalPayload>) => {
        const { inputs, valid, urlValidity } = action.payload;
        state.inputs = inputs;
        state.valid = valid;
        state.urlValidity = urlValidity;

        state.accepted = true;
      },
      prepare: (payload: ILinkModalInputsPayload) => {
        return prepareLinkModalInputs(payload);
      }
    }
  }
});

const { actions, reducer } = linkModalSlice;

export const { openLinkModal, closeLinkModal, setLinkModalInputs, setLinkModalAccepted } = actions;
export default reducer;
