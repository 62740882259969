import Flux from 'flux';
import StorytellerUtils from './lib/StorytellerUtils';

// The following code is useful for debugging Flux Store issues
// It prints the flux store messages
// const getDispatcher = ():  Flux.Dispatcher<any> => {
//   const dispatcher = new Flux.Dispatcher();
//   const dispatch = dispatcher.dispatch;

//   dispatcher.dispatch = function(payload: any) {
//     const { action, ...rest } = payload;
//     console.debug(action, rest);
//     dispatch.call(this, payload);
//   };

//   return dispatcher;
// };

// Type param is the type of the action's payload
export const dispatcher: Flux.Dispatcher<any> = StorytellerUtils.export(
  // When debugging the Flux Store Actions replace `new Flux.Dispatcher()` with getDispatcher()
  new Flux.Dispatcher(),
  'storyteller.dispatcher'
);
export default Flux.Dispatcher;
