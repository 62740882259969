/*
 * This is analogous to react-redux's connect(), except
 * it works with one or more Flux stores.
 * Example:
 *  import SuperSweetComponent from 'component/path/SuperSweetComponent';
 *  import assetSelectorStore from 'edit/stores/AssetSelectorStore';
 *  import storyStore from 'edit/stores/StoryStore';
 *
 *  const MyConnectedComponent = connectFlux(
 *    { assetSelectorStore, storyStore }, // stores to subscribe to
 *    () => {
 *      // This function is called every time the store(s) emit(s) a change.
 *      // The returned object gets applied as props to MyComponent.
 *      // NOTE: the props are applied using setState, which means that
 *      // components will re-render even if those props have not changed.
 *      // This may result in far more re-renders than expected.
 *      // You can alleviate this by using a predicate function to determine
        // whether or not to actually update the props.
 *      return {
 *        componentType: assetSelectorStore.getComponentType(),
 *        story: storyStore.getStory()
 *      };
 *    },
 *    (dispatch) => ({
 *      onClose: () => {
 *        dispatch({
 *          action: Actions.MY_ACTION_CLOSE,
 *          type
 *        });
 *      }
 *    })
 *  )(SuperSweetComponent);
 */

import _ from 'lodash';
import React, { Component } from 'react';
import { dispatcher } from './Dispatcher';

export interface Stores {
  actionComponentStore?: any;
  blockRemovalConfirmationStore?: any;
  storySaveStatusStore?: any;
  storyStore?: any;
}

type DispatcherType = typeof dispatcher;

function connectFlux<PassedProps, StoreProps, DispatchProps>(
  stores: Stores,
  mapStoresToProps: (stores: Stores, props: PassedProps) => StoreProps,
  mapDispatchToProps: (dispatcher: DispatcherType) => DispatchProps,
  predicate?: (oldState: StoreProps, newState: StoreProps) => boolean
) {
  interface ConnectedComponentState {
    propsForWrapped: StoreProps & DispatchProps;
  }

  return (WrappedComponent: React.FC<PassedProps & StoreProps & DispatchProps>) => {
    return class extends Component<PassedProps, ConnectedComponentState> {
      constructor(props: PassedProps) {
        super(props);
        this.state = { propsForWrapped: this.getPropsForWrapped() };
      }

      getPropsForWrapped = (): StoreProps & DispatchProps => {
        const fromDispatch: DispatchProps = mapDispatchToProps
          ? mapDispatchToProps(dispatcher.dispatch.bind(dispatcher))
          : ({} as DispatchProps);

        return {
          ...fromDispatch,
          ...mapStoresToProps(stores, this.props)
        };
      };

      componentDidMount() {
        _.forOwn(stores, (store) => store.addChangeListener(this.onStoreChange));
      }

      componentWillUnmount() {
        _.forOwn(stores, (store) => store.removeChangeListener(this.onStoreChange));
      }

      onStoreChange = () => {
        /*
         * NOTE: because we use setState, this will *always* result in a re-render,
         * even if the actual props sent to the wrapped component are the same.
         * Because the store changes, child will always re-render.
         * This may be VERY different from what you would expect.
         * You can alleviate this by using a predicate function to determine
         * whether or not to actually update the props.
         */
        const newProps = this.getPropsForWrapped();
        if (!predicate || predicate(this.state.propsForWrapped, newProps)) {
          this.setState({ propsForWrapped: newProps });
        }
      };

      render() {
        const props = {
          ...this.props,
          ...this.state.propsForWrapped
        };

        return <WrappedComponent {...props} />;
      }
    };
  };
}

export default connectFlux;
