import { find, filter as _filter, get, includes, indexOf, last, map, reject, set, some } from 'lodash';

import * as BaseFilter from './Filters/BaseFilter';
import { FilterBarColumn, FilterDataType } from '../types';
import { FeatureFlags } from 'common/feature_flags';

import {
  BinaryOperator,
  FilterValue,
  NumberSoqlFilter,
  RelativeDateFilter,
  SoqlFilter,
  TimeRangeFilter
} from '../SoqlFilter';
import { NUMERIC_COLUMN_TYPES } from 'common/authoring_workflow/constants';
import { getNumberFilterForDrilldowns } from './Filters/NumberFilter';
import { getCheckboxFilter } from './Filters/CheckboxFilter';
import { getCalendarDateRangeFilter } from './Filters/CalendarDateFilter';
import { DATE_FORMAT, FILTER_TYPES } from 'common/dates';
import moment from 'moment';

/**
 * @param dimensionAndDrilldownColumns Array of column field names
 * @param existingFilters
 */
export function getNewFilters(
  dimensionAndDrilldownColumns: string[],
  existingFilters: SoqlFilter[],
  datasetUid: string,
  columns: FilterBarColumn[]
): SoqlFilter[] {
  const dimensionAndDrilldownFilters = map(dimensionAndDrilldownColumns, (column) => {
    const existingFilter = find(existingFilters, (filter) => {
      return filter.columns[0].fieldName === column;
    }) as SoqlFilter;
    if (existingFilter) {
      set(existingFilter, 'isDrilldown', true);
      return existingFilter;
    } else {
      const columnDetails = find(columns, ['fieldName', column]);
      return BaseFilter.getNoopFilter({ [datasetUid]: columnDetails! }, true);
    }
  });

  const otherFilters = reject(existingFilters, (filter: SoqlFilter) => {
    return filter.isDrilldown;
  }) as SoqlFilter[];

  return [...dimensionAndDrilldownFilters, ...otherFilters];
}

export function atLeastOneDrilldownFilter(filters: SoqlFilter[]) {
  return some(filters, ['isDrilldown', true]);
}

/**
 * @param filters - all filters currently rendered on visualization
 * @param drilldowns - all drilldowns currently rendered on visualization
 * @param dimensionColumnName - the column name of the dimension
 * @param removeDrilldownColumnName - the column name of the drilldown to remove
 */
export function getFiltersWithoutRemovedDrilldown(
  filters: SoqlFilter[],
  drilldowns: string[],
  dimensionColumnName: string,
  removeDrilldownColumnName: string
) {
  return _filter(filters, (filterItem) => {
    const { columns } = filterItem;
    const columnFieldName = columns[0].fieldName;
    // When the last drilldown is removed (no drilldown dimensions configured),
    // we should remove the empty filters added for (dimension.column + dimension.drilldowns.columns)
    // for handling drilldown.
    if (drilldowns.length === 1) {
      return columnFieldName !== dimensionColumnName && columnFieldName !== removeDrilldownColumnName;
    }
    return columnFieldName !== removeDrilldownColumnName;
  });
}

/**
 *
 * @param drilldownDimensions Array of all column field names used as dimensions for drilldowns
 * @param currentDrilldownDimension The column field name for the current drilldown dimension
 * @returns The column field name for the next drilldown dimension to be used
 */
export function getNextDrilldownDimensionColumnName(
  drilldownDimensions: string[],
  currentDrilldownDimension: string
) {
  const currentDrilldownIndex = indexOf(drilldownDimensions, currentDrilldownDimension);

  return get(drilldownDimensions, [currentDrilldownIndex + 1], last(drilldownDimensions));
}

/**
 * Given an array of filters, this function returns filters with
 * the filter object of the given column filtering on the given value.
 */
export function applyFilterForColumnNameAndGetFilters({
  columnDetails,
  columnName,
  columnValue,
  filters,
  period
}: {
  columnDetails: FilterBarColumn;
  columnName: string;
  columnValue: FilterValue;
  filters: SoqlFilter[];
  period: moment.unitOfTime.StartOf;
}) {
  return map(filters, (filterItem) => {
    if (filterItem.columns[0].fieldName === columnName) {
      if (includes(NUMERIC_COLUMN_TYPES, columnDetails.renderTypeName)) {
        return getNumberFilterForDrilldowns(filterItem as NumberSoqlFilter, columnValue);
      } else if (includes(['calendar_date', 'date'], columnDetails.renderTypeName)) {
        return getCalendarDateRangeFilter(filterItem as RelativeDateFilter | TimeRangeFilter, {
          calendarDateFilterType: FILTER_TYPES.RANGE,
          start: moment(columnValue as string, DATE_FORMAT)
            .startOf(period)
            .format(DATE_FORMAT),
          end: moment(columnValue as string, DATE_FORMAT)
            .endOf(period)
            .format(DATE_FORMAT)
        });
      } else {
        return getCheckboxFilter(
          filterItem as BinaryOperator,
          { [filterItem.columns[0].datasetUid]: columnDetails },
          [columnValue]
        );
      }
    }
    return filterItem;
  });
}
