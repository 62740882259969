import _ from 'lodash';

export default function convertToVersion4(measure) {
  let measureV4 = _.cloneDeep(measure);
  const calculationFilters = _.get(measureV4, 'metricConfig.arguments.calculationFilters', []);

  if (calculationFilters.length > 0) {
    const datasetUid = _.get(measureV4, 'dataSourceLensUid');
    const newFilters = calculationFilters.map((filter) => {
      const { columnName } = filter;
      return { ...filter, columns: [{ fieldName: columnName, datasetUid: datasetUid }] };
    });
    _.set(measureV4, 'metricConfig.arguments.calculationFilters', newFilters);
  }

  _.set(measureV4, 'version', 4);
  return measureV4;
}
