import _ from 'lodash';
import classNames from 'classnames';
import React, { Component } from 'react';

import { ForgeBadge, ForgeIconButton, ForgeTooltip, ForgeIcon } from '@tylertech/forge-react';

import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import FilterBar, { FilterBarProps } from './';
import I18n from 'common/i18n';
import { Filter } from './types';
import { getNonHiddenFilters } from './lib/allFilters';
import { hasClickedOnAnotherFilterBarsToggleButton, addPaddingIfElementOverflowPage } from './lib/helpers';
import { setLegendMenuVisibility } from '../CommonFilterHelpers';
import { SoqlFilter } from './SoqlFilter';
import { FilterItemType } from 'common/types/reportFilters';

export interface VerticalFilterBarProps extends FilterBarProps {
  /** Specific to VerticalFilterBar, function to call on toggling open or closed */
  onToggle: (showFilterBar: boolean) => void;
  /** Specific to VerticalFilterBar, initializes whether to show filter bar open or closed */
  showFilterBar?: boolean;
  /** Specific to VerticalFilterBar, whether to use the smaller Forge button for the toggle */
  useForgeStyle?: boolean;
}

interface VerticalFilterBarState {
  showFilterBar: boolean;
}

export class VerticalFilterBar extends Component<VerticalFilterBarProps, VerticalFilterBarState> {
  static defaultProps = { onToggle: _.noop, isReadOnly: true, showFilterBar: false, useForgeStyle: true };

  bodyClickHandler: (event: MouseEvent) => void;
  _verticalFilterBarContainer: HTMLDivElement;

  constructor(props: VerticalFilterBarProps) {
    super(props);

    this.state = {
      showFilterBar: !!this.props.showFilterBar
    };
  }

  componentDidMount() {
    this.bodyClickHandler = (event: MouseEvent) => {
      if (
        hasClickedOnAnotherFilterBarsToggleButton(this._verticalFilterBarContainer, event) &&
        this.state.showFilterBar
      ) {
        this.setShowFilterBar(false);
      }
    };

    document.body.addEventListener('click', this.bodyClickHandler);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.bodyClickHandler);
  }

  onToggleFilterBar = () => {
    const showFilterBar = !this.state.showFilterBar;
    this.setShowFilterBar(showFilterBar);
  };

  setShowFilterBar = (showFilterBar: boolean) => {
    const { onToggle } = this.props;

    this.setState({ showFilterBar }, () => {
      addPaddingIfElementOverflowPage(this._verticalFilterBarContainer, showFilterBar);
    });

    setLegendMenuVisibility({
      showFilterBar,
      verticalFilterBarContainer: this._verticalFilterBarContainer
    });
    onToggle(showFilterBar);
  };

  renderToggleButton = (filterItems: Filter[]) => {
    const filtersWithArgumentsCount = _.chain(filterItems)
      .filter((filterItem) => !_.isEmpty(filterItem.arguments))
      .size()
      .value();
    const filtersCount = filtersWithArgumentsCount === 0 ? '' : `(${filtersWithArgumentsCount})`;

    if (this.props.useForgeStyle && !this.state.showFilterBar) {
      const filterButtonText = I18n.t('shared.visualizations.charts.common.toolbar_options.filter');
      return (
        <ForgeIconButton
          className={filtersWithArgumentsCount ? 'active-filter' : ''}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.keyCode === 13) {
              // keyCode 13 is "Enter"
              this.onToggleFilterBar();
            }
          }}
        >
          <button
            type="button"
            data-test-id="filter-bar-toggle-btn"
            aria-label={filterButtonText}
            className="tyler-icons"
            onClick={this.onToggleFilterBar}
          >
            <ForgeIcon name="filter_list" />
          </button>
          <ForgeTooltip position="bottom">{filterButtonText}</ForgeTooltip>
          {filtersWithArgumentsCount >= 1 && (
            <ForgeBadge theme="info-primary" strong positioned role="status">
              {filtersWithArgumentsCount}
            </ForgeBadge>
          )}
        </ForgeIconButton>
      );
    }

    return (
      <Button
        className="filter-bar-btn vertical-filter-bar-toggle-button"
        variant={VARIANTS.SIMPLE}
        data-test-id="filter-bar-toggle-btn"
        onClick={this.onToggleFilterBar}
      >
        <SocrataIcon name={IconName.Filter} /> {I18n.t('shared.components.filter_bar.title')} {filtersCount}
        <span className="filter-bar-close-icon">
          <SocrataIcon name={IconName.Close2} />
        </span>
        <span className="filter-bar-arrow-icon">
          <SocrataIcon name={IconName.ArrowDown} />
        </span>
      </Button>
    );
  };

  render() {
    const { isReadOnly, filterParameterConfigurations } = this.props;

    const filterBarProps = _.chain(this.props).omit('onToggle').merge({ showAllFilters: true }).value();

    const filterBarClassNames = classNames('vertical-filter-bar', {
      'filter-bar-expanded': this.state.showFilterBar
    });

    const filterItems =
      filterParameterConfigurations?.filter((item) => item.type === FilterItemType.FILTER) ?? [];
    const onlySoqlFilters: SoqlFilter[] = filterItems.map((filter) => filter.config as SoqlFilter) || [];

    const nonHiddenFilters = getNonHiddenFilters(onlySoqlFilters, isReadOnly);

    if (isReadOnly && _.isEmpty(nonHiddenFilters)) {
      return null;
    }

    return (
      <div
        className={filterBarClassNames}
        ref={(ref: HTMLDivElement) => (this._verticalFilterBarContainer = ref)}
      >
        {this.renderToggleButton(nonHiddenFilters)}
        <div className="vertical-filter-wrapper">
          <FilterBar {...filterBarProps} />
        </div>
      </div>
    );
  }
}

export default VerticalFilterBar;
