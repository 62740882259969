import { TABLE_SCREEN_BREAKPOINT, MOBILE_SCREEN_BREAKPOINT, DESKTOP_SCREEN_BREAKPOINT } from 'common/visualizations/views/SvgConstants';

export const isLargeDesktop = () => {
  return window.innerWidth >= DESKTOP_SCREEN_BREAKPOINT;
};

export const isDeskTop = () => {
  return window.innerWidth > TABLE_SCREEN_BREAKPOINT;
};

export const isMobileOrTablet = () => {
  return window.innerWidth <= TABLE_SCREEN_BREAKPOINT;
};

export const isTabletOrDeskTop = () => {
  return window.innerWidth > MOBILE_SCREEN_BREAKPOINT;
};

export const isMobile = () => {
  return window.innerWidth <= MOBILE_SCREEN_BREAKPOINT;
};
