import _ from 'lodash';

export interface DataProviderConfig {
  timeout?: number;
}

class DataProvider {
  private static instanceCache: { [key: string]: DataProvider[] } = {};

  config: DataProviderConfig;

  constructor(config: DataProviderConfig) {
    const defaultConfig = {
      timeout: 5000
    };
    this.config = _.merge(defaultConfig, config);

    // because other data providers are using lodash _extend,
    // we need to make these base class functions "visible"
    // TODO remove when the other data providers are written in TS
    this.getConfigurationProperty = DataProvider.prototype.getConfigurationProperty;
    this.getOptionalConfigurationProperty = DataProvider.prototype.getOptionalConfigurationProperty;
    this.parseHeaders = DataProvider.prototype.parseHeaders;
    this.cachedInstance = DataProvider.prototype.cachedInstance;
  }

  static clearInstanceCache() {
    DataProvider.instanceCache = {};
  }

  /**
   * @param {String} property - The desired configuration property key.
   *
   * @throws if the property key is not found.
   *
   * @return {*} - The configuration property value that was passed in
   *   at instantiation.
   */
  getConfigurationProperty(property: string): any {
    if (!_.has(this.config, property)) {
      throw new Error(`Configuration property \`${property}\` does not exist.`);
    }
    return this.config[property];
  }

  /**
   * @param {String} property - The desired configuration property key.
   * @param {*} default - The value to return if the configuration is not set.
   *
   * @return {*} - The configuration property value that was passed in
   *   at instantiation, or the default value if the value was not configured.
   */
  getOptionalConfigurationProperty(property: string, defaultValue: any): any {
    if (!_.has(this.config, property)) {
      return defaultValue;
    }

    return this.config[property];
  }

  /**
   * Parse headers into a key => value mapping.
   *
   * @param {string} headers - Raw headers as a string.
   *
   * @return {Object} Parsed headers as key value object.
   */
  parseHeaders(headers?: string): { [key: string]: string } {
    if (!headers) {
      return {};
    }

    return headers.split('\n').reduce((parsed: { [key: string]: string }, line: string) => {
      const colonIndex = line.indexOf(':');
      const key = line.substr(0, colonIndex).trim().toLowerCase();
      const val = line.substr(colonIndex + 1).trim();

      if (key) {
        parsed[key] = parsed[key] ? `${parsed[key]}, ${val}` : val;
      }
      return parsed;
    }, {});
  }

  /**
   * @param {string} namespace for disambiguating data providers with the same configurations
   *
   * @return {Object} a DataProvider instance or undefined in case of a cache miss
   */
  cachedInstance(namespace: string): DataProvider | undefined {
    let cachedInstances = DataProvider.instanceCache[namespace];
    if (!cachedInstances) {
      DataProvider.instanceCache[namespace] = [];
      cachedInstances = DataProvider.instanceCache[namespace];
    }

    const instance = cachedInstances.find((provider: DataProvider) => {
      return _.isMatch(provider.config, this.config);
    });

    if (!instance) {
      cachedInstances.push(this);
    }

    return instance;
  }
}

export default DataProvider;
