import _ from 'lodash';
import { StartDateTypes } from '../constants';

export default function convertToVersion3(measure) {
  let measureV3 = _.cloneDeep(measure);
  const startDate = _.get(measureV3, 'metricConfig.reportingPeriod.startDate');
  _.set(measureV3, 'metricConfig.reportingPeriod.startDateConfig.type', StartDateTypes.FIXED);
  _.set(measureV3, 'metricConfig.reportingPeriod.startDateConfig.date', startDate);
  _.unset(measureV3, 'metricConfig.reportingPeriod.startDate');

  _.set(measureV3, 'version', 3);
  return measureV3;
}
