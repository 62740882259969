import { action } from 'typesafe-actions';

import type { UsersCatalogSearchResult, CatalogUserOrTeam } from 'common/types/users/catalogUsers';
import type { AccessLevel } from 'common/types/view';

export enum AddCollaboratorsActionsTypes {
  /** Called whenever the user types into the search box */
  COLLABORATORS_SEARCH_QUERY_CHANGED = 'COLLABORATORS_SEARCH_QUERY_CHANGED',

  /** Called whenever the user types into the search box */
  COLLABORATORS_SEARCH_CLEAR_QUERY = 'COLLABORATORS_SEARCH_CLEAR_QUERY',

  /** Called when users are received from the catalog */
  COLLABORATORS_SEARCH_RESULTS_FETCH_SUCCESS = 'COLLABORATORS_SEARCH_RESULTS_FETCH_SUCCESS',

  /** Called when fecthing users from teh catalog fails */
  COLLABORATORS_SEARCH_RESULTS_FETCH_FAIL = 'COLLABORATORS_SEARCH_RESULTS_FETCH_FAIL',

  /** Adds a user to the selected users*/
  ADD_SELECTED_COLLABORATOR = 'ADD_SELECTED_COLLABORATOR',

  /** Removes a user from teh selected users */
  REMOVE_SELECTED_COLLABORATOR = 'REMOVE_SELECTED_COLLABORATOR',

  /** Called when the access level dropdown changes */
  SELECTED_COLLABORATOR_ACCESS_LEVEL_CHANGED = 'SELECTED_COLLABORATOR_ACCESS_LEVEL_CHANGED',

  /** Validates the user's input? */
  VALIDATE_USER_INPUT = 'VALIDATE_USER_INPUT',

  /** Called once validation is finished */
  VALIDATED_USER_INPUT = ' VALIDATED_USER_INPUT'
}

export const collaboratorsSearchQueryChanged = (query: string) =>
  action(AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_QUERY_CHANGED, { query });

export const collaboratorsSearchClearQuery = () =>
  action(AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_CLEAR_QUERY, { query: '' });

/** Getting results back from the catalog */
export const collaboratorsSearchResultsFetchSuccess = (
  results: UsersCatalogSearchResult,
  existingUsers: CatalogUserOrTeam[]
) =>
  action(AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_RESULTS_FETCH_SUCCESS, { results, existingUsers });

/** Failed to get results from catalog */
export const collaboratorsSearchResultsFetchFail = (error: any) =>
  action(AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_RESULTS_FETCH_FAIL, { error });

/** Add a user to the combobox */
export const addSelectedCollaborator = (user: CatalogUserOrTeam) =>
  action(AddCollaboratorsActionsTypes.ADD_SELECTED_COLLABORATOR, { user });

/** Remove a user from the combobox */
export const removeSelectedCollaborator = (user: CatalogUserOrTeam) =>
  action(AddCollaboratorsActionsTypes.REMOVE_SELECTED_COLLABORATOR, { user });

/** Desired access level for the users that have been selected has changed */
export const selectedCollaboratorAccessLevelChanged = (accessLevel: { value: AccessLevel }) =>
  action(AddCollaboratorsActionsTypes.SELECTED_COLLABORATOR_ACCESS_LEVEL_CHANGED, { accessLevel });

/** Getting error feedback in modal */
export const validateUserInput = (selectedUsers: CatalogUserOrTeam[], selectedAccessLevel: AccessLevel) =>
  action(AddCollaboratorsActionsTypes.VALIDATE_USER_INPUT, { selectedUsers, selectedAccessLevel });

export const validatedUserInput = (validationErrors: { translationKey: string }[]) =>
  action(AddCollaboratorsActionsTypes.VALIDATED_USER_INPUT, { validationErrors });
