import { cloneDeep, get, set } from 'lodash';
import { Measure } from 'common/performance_measures/types';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';

// Exporting these mainly for tests
export const DENOMINATOR_FILTERS = 'metricConfig.arguments.denominatorFilters';
export const NUMERATOR_FILTERS = 'metricConfig.arguments.numeratorFilters';
export interface MeasureV4 extends Omit<Measure, 'version'> {
  version: 4;
}

export default function convertToVersion5(measure: MeasureV4): Measure {
  const measureV5 = cloneDeep(measure) as Omit<Measure, 'version'>;
  const denominatorFilters = get(measureV5, DENOMINATOR_FILTERS, []);
  const numeratorFilters = get(measureV5, NUMERATOR_FILTERS, []);
  const datasetUid = get(measureV5, 'dataSourceLensUid');

  if (datasetUid) {
    if (denominatorFilters.length > 0) {
      set(measureV5, DENOMINATOR_FILTERS, migrateFilters(denominatorFilters, datasetUid));
    }

    if (numeratorFilters.length > 0) {
      set(measureV5, NUMERATOR_FILTERS, migrateFilters(numeratorFilters, datasetUid));
    }
  }

  set(measureV5, 'version', 5);
  // Coerce to latest Measure type now that version is set.
  return measureV5 as Measure;
}

function migrateFilters(filters: any, datasetUid: string): SoqlFilter[] {
  // Version 4 measures had SingleSourceSoqlFilters. Convert the filter to a new SoqlFilter,
  // replacing columnName with columns.
  return filters.map((filter: any) => {
    const { columnName } = filter;
    return { ...filter, columns: [{ fieldName: columnName, datasetUid }] } as SoqlFilter;
  });
}
