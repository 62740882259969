import type { GuidanceSummaryV2 } from 'common/types/approvals';

import { View, ViewRight } from 'common/types/view';
import { isStory } from 'common/views/view_types';
import { hasRights } from 'common/views/has_rights';
import { AssetStatus, assetStatusFor } from 'common/views/asset_status';
import FeatureFlags from 'common/feature_flags';

/**
 * Determines Whether or not the current user can watch the published story.
 *
 *
 * @param view View to check for Watch privileges
 * @param approvalsGuidance Approvals guidance summary
 * @returns Whether or not the current user can watch the published story
 */
const currentUserCanWatchView = (view: View, approvalsGuidance: GuidanceSummaryV2) => {
  // Stories that are published story can be watched by the user who have read access to the story.
  return (
    isStory(view) &&
    assetStatusFor(view, approvalsGuidance) === AssetStatus.Published &&
    FeatureFlags.value('enable_screenshot_subscriptions') &&
    hasRights(view, ViewRight.Read)
  );
};

export default currentUserCanWatchView;
