import SimpleEventEmitter from './SimpleEventEmitter.js';
import Dispatcher, { dispatcher as globalDispatcher } from './Dispatcher';

/*
 * Store base class. Example of usage:
 *
 * old way:
 * function SpecificStore() {
 *   _.extend(this, new storyteller.Store());
 *
 *   this.getSpecificValue = function() { ... };
 * }
 *
 * new way:
 * class SpecificStore extends storyteller.Store {
 *   getSpecificValue() { ... }
 * }
 */
export default class Store {
  dispatcher: Dispatcher<any>;

  private dispatcherToken: string;
  private emitter: any = new SimpleEventEmitter();

  constructor(dispatcher = globalDispatcher) {
    this.dispatcher = dispatcher;

    // Due to some other stores using lodash _.extend on this class,
    // we need to manually expose this class's methods.
    this.register = Store.prototype.register;
    this.getDispatcherToken = Store.prototype.getDispatcherToken;
    this.addChangeListener = Store.prototype.addChangeListener;
    this.removeChangeListener = Store.prototype.removeChangeListener;
    this._emitChange = Store.prototype._emitChange.bind(this);
  }

  register(callback: (payload: any) => void): void {
    this.dispatcherToken = this.dispatcher.register(callback);
  }

  getDispatcherToken(): string {
    return this.dispatcherToken;
  }

  addChangeListener(callback: () => void): void {
    this.emitter.addListener(callback);
  }

  removeChangeListener(callback: () => void): void {
    this.emitter.removeListener(callback);
  }

  _emitChange(...args: any[]): void {
    this.emitter.emit(...args);
  }
}
