import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import I18n from 'common/i18n';

import ViewTabs from './ViewTabs';
import { BaseVisualization } from './types';
import { isTableVisualization } from 'common/visualizations/helpers/VifSelectors';

/**
 * This is the jquery template where we render the ViewTabs React component.
 */
export const template = (self: BaseVisualization) => {
  let tabsContainer;

  if (!isTableVisualization(self.getVif())) {
    tabsContainer = $('<div>', { 'class': 'socrata-visualization-view-tabs', 'role': 'tablist' });
    self.viewTabsVisible = true;
  } else {
    tabsContainer = null;
    self.viewTabsVisible = false;
  }

  return tabsContainer;
};

export const renderReact = (
  $element: JQuery,
  isMap: boolean,
  onClickTableTab: () => void,
  onClickVisualizationTab: () => void,
  shouldShowTable: boolean,
  visualizationId: string,
  newVizCardLayoutEnabled: boolean
) => {
  if ($element.find('.socrata-visualization-view-tabs').length == 1) {
    const viewTabsProps = { isMap, onClickTableTab, onClickVisualizationTab, shouldShowTable, visualizationId, newVizCardLayoutEnabled };
    ReactDOM.render(
      <ViewTabs {...viewTabsProps} />,
      $element.find('.socrata-visualization-view-tabs')[0]
    );
  }
};

/*
 * Some helpers for dealing with the tab panels
 */
const panelId = (tabName: string, visualizationId: string) => `${tabName}-panel-${visualizationId}`;

/**
 * Generate the markup for a tab panel. Designed to be controled by ViewTabs React component.
 */
export const tabPanelTemplate = (
  /** Whether the tabPanel is hidden */
  hidden: boolean,
  /** Content to append within the tab panel */
  $panelContent: JQuery,
  /** Name of the tab. */
  tabName: 'visualization' | 'summary-table',
  /** Unique identifier for the visualization, set in BaseVisualization. */
  visualizationId: string,
  /** Used to provide more useful label text. */
  visualizationType: 'map' | 'chart' | 'table'
) => {
  const scope = 'shared.visualizations.charts.common.view_tabs.aria_label';
  return $('<div>', {
    'class': hidden ? 'tab-panel hidden' : 'tab-panel',
    'id': panelId(tabName, visualizationId),
    'role': 'tabpanel'
  }).append([
    $('<label>', {
      'class': 'screenreader-only',
      'for': panelId(tabName, visualizationId),
      'tabindex': -1
    }).text(I18n.t(visualizationType, { scope })),
    $panelContent
  ]);
};

/**
 * Switch a tab from hidden to shown or vice versa.
 */
export const toggleTab = (
  /** Container of the entire BaseVisualization. */
  $container: JQuery,
  /** Name of the tab. */
  tabName: 'visualization' | 'summary-table',
  /** Unique identifier for the visualization. Set in BaseVisualization. */
  visualizationId: string,
  /** Whether the tab should be hidden. */
  hidden: boolean
) => {
  $container.find(`#${panelId(tabName, visualizationId)}`)
    .toggleClass('hidden', hidden);
};
