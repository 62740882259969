import { isUndefined, isEmpty } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';

import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';
import MeasureChart from 'common/performance_measures/components/MeasureChart';
import { toLatestSchemaVersion } from 'common/performance_measures/lib/measureSchemaUpgrader';
import MeasureResultCard from 'common/performance_measures/components/MeasureResultCard';

import { MeasureProps } from '../types';
import { Lens, Measure } from 'common/performance_measures/types';
import {
  AnyMeasureBlockComponent,
  ComponentType,
  InSituMeasureBlockComponent,
  PlatformMeasureBlockComponent
} from 'types';

import useMeasureConfigWithGlobalFilterParameters from './useMeasureConfigWithGlobalFilterParameters';

export interface MeasurePropsReact extends MeasureProps {
  jQueryErrorCallback: (error: boolean) => void;
}

export interface InSituMeasureProps extends MeasurePropsReact {
  componentData: InSituMeasureBlockComponent;
}

export interface PlatformMeasureProps extends MeasurePropsReact {
  componentData: PlatformMeasureBlockComponent;
}

interface CustomLinkStuff {
  isCustomLinkEnabled: boolean;
  customLink: {
    href: string | null;
    text: string | null;
  };
  hasCustomLink: boolean;
}

const getCustomLinkStuff = (componentData: AnyMeasureBlockComponent): CustomLinkStuff => {
  const isCustomLinkEnabled = !!componentData?.value?.measure?.use_custom_link;
  const customLink = componentData?.value?.measure?.custom_link || { href: null, text: null };
  const hasCustomLink = isCustomLinkEnabled && !!(customLink.href || customLink.text);
  return { isCustomLinkEnabled, customLink, hasCustomLink };
};

/**
 * Recalculates isInsitu for a measure block component, even if its undefined
 * @param componentData - TS AnyMeasureBlockComponent to check.
 * @returns {boolean} - isInsitu, even if the original value was undefined
 */
export const getIsInsitu = (componentData: AnyMeasureBlockComponent) => {
  if (isUndefined(componentData.isInsitu)) {
    const m = componentData.value.measure;
    return !('uid' in m) && !isEmpty(m);
  }
  return componentData.isInsitu;
};

const ComponentMeasure = (props: MeasurePropsReact) => {
  if (getIsInsitu(props.componentData)) {
    // TS should already know the type, but discriminated unions don't work when nested, so we have to cast.
    // componentData.isInsitu works, props.componentData.isInsitu doesn't
    return <InSituMeasure {...(props as InSituMeasureProps)} />;
  } else {
    return <PlatformMeasure {...(props as PlatformMeasureProps)} />;
  }
};

const InSituMeasure = (props: InSituMeasureProps) => {
  const { componentData } = props;
  // TODO: I suspect uid can be undefined depending on measures_editor state.
  // This was not handled in the original code, I'm leaving it for another PR.
  // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  const uid: string = componentData.value.measure.dataSourceLensUid;
  const attributionDomain = useAttributionDomain(uid);
  const measure = toLatestSchemaVersion(componentData.value.measure);
  const reportMeasure = useMeasureConfigWithGlobalFilterParameters(measure);

  if (componentData.type === ComponentType.MEASURE_CHART) {
    return (
      <MeasureChart
        measure={reportMeasure}
        isInsituMeasure={true}
        showChartTitle={true}
        showMetadata={true}
      />
    );
  } else {
    const { isCustomLinkEnabled, customLink, hasCustomLink } = getCustomLinkStuff(componentData);
    return (
      <MeasureResultCard
        // lens={lens} inSitu Cards never need the lens, they will always have measure.metadata.shortname
        measure={reportMeasure}
        measureDomain={attributionDomain}
        customLink={customLink}
        isCustomLinkEnabled={isCustomLinkEnabled}
        isInsituMeasure={true}
        showMetadata={true}
        showViewMeasureLink={!!(hasCustomLink || attributionDomain)}
      />
    );
  }
};

/**
 * Runs the .then and .catch callbacks on a promise,
 * and calls the appropriate success and error callbacks.
 * Perfect for React setState.
 * @param {Promise} promise - The promise you got from MetadataProvider
 * @param {Function} successCallback - The function to call on success
 * @param {Function} hasErrorCallback - Always called, with a boolean indicating if there was an error
 * @return {void}
 */
const handlePromiseCallbacks = (
  promise: Promise<any>,
  successCallback: (data: any) => void,
  hasErrorCallback: (error: boolean) => void = () => {}
) => {
  promise
    .then((data: any) => {
      hasErrorCallback(false);
      successCallback(data);
    })
    .catch(() => {
      hasErrorCallback(true);
    });
};

const PlatformMeasure = (props: PlatformMeasureProps) => {
  const { componentData, jQueryErrorCallback } = props;
  const uid = componentData.value.measure.uid;
  const metadataProvider = useMemo(() => new MetadataProvider({ datasetUid: uid }, true), [uid]);

  const [measure, setMeasure] = useState<Measure | null>(null);
  const [lens, setLens] = useState<Lens>();
  const attributionDomain = useAttributionDomain(uid);
  const reportMeasure = useMeasureConfigWithGlobalFilterParameters(measure);

  useEffect(() => {
    handlePromiseCallbacks(
      metadataProvider.getPlatformMeasure(),
      (data: Measure) => setMeasure(toLatestSchemaVersion(data)),
      jQueryErrorCallback
    );
    handlePromiseCallbacks(metadataProvider.getDatasetMetadata(), setLens, jQueryErrorCallback);
  }, [metadataProvider, jQueryErrorCallback]);

  if (componentData.type === ComponentType.MEASURE_CHART) {
    return (
      <MeasureChart
        lens={lens}
        measure={reportMeasure}
        isInsituMeasure={false}
        showChartTitle={true}
        showMetadata={true}
      />
    );
  } else {
    const { isCustomLinkEnabled, customLink, hasCustomLink } = getCustomLinkStuff(componentData);
    return (
      <MeasureResultCard
        lens={lens}
        measure={reportMeasure}
        measureDomain={attributionDomain}
        customLink={customLink}
        isCustomLinkEnabled={isCustomLinkEnabled}
        isInsituMeasure={false}
        showMetadata={true}
        showViewMeasureLink={!!(hasCustomLink || attributionDomain)}
      />
    );
  }
};

// TODO: I'm pretty sure the right way to do this would be to overhaul the dataProviders
// to be hook friendly, or just switch to `react-query`, which includes caching.
const useAttributionDomain = (datasetUid: string) => {
  const [attributionDomain, setAttributionDomain] = useState('');
  useEffect(() => {
    // You can set an insitu measure without a dataSource attached to it
    // if it doesn't have a dataSource, we don't need to fetch the attribution domain
    if (!datasetUid) return;

    const metadataProvider = new MetadataProvider({ datasetUid }, true);
    handlePromiseCallbacks(metadataProvider.getAttributionDomain(), setAttributionDomain);
  }, [datasetUid]);

  return attributionDomain;
};

export default ComponentMeasure;
