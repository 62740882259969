import React, { Component } from 'react';
import { Provider } from 'react-redux';
import I18n from 'common/i18n';

import type { View, ViewPermissions } from 'common/types/view';
import type { GuidanceSummaryV2 } from 'common/types/approvals';
import { getCurrentUser } from 'common/current_user';

import AccessManagerModal from './components/AccessManagerModal';
import createStore from './AccessManagerStore';
import { confirmButtonBusyByDefault, confirmButtonDisabledByDefault, confirmButtonShown } from './Util';
import { fetchPermissions } from './actions/PermissionsActions';
import { MODES } from './Constants';

// Note: This component has a README.md. Check it out.

export interface AccessManagerProps {
  /** View to manage access for */
  view: View;

  /**
   * Approvals guidance for the view.
   * NOTE: If this is not passed in, it will automatically be fetched.
   */
  approvalsGuidance?: GuidanceSummaryV2;

  /** Mode to show the access manager in */
  mode: MODES;

  /** Called when the "Cancel" button in the footer is clicked */
  onDismiss?: () => void;

  /**
   * Called when the "Confirm" button in the footer is clicked; should return a promise that will then be yielded
   * If the Promise is rejected, the error message it returns will be displayed in the modal
   */
  onConfirm?: (mode: MODES, assetUid: string, permissions: ViewPermissions) => Promise<void>;

  /** If true, will hide the button to switch modes to show collaborators */
  hideCollaboratorsToggle?: boolean;
}

class AccessManager extends Component<AccessManagerProps> {
  store: ReturnType<typeof createStore>;

  constructor(props: AccessManagerProps) {
    super(props);

    const currentUser = getCurrentUser();
    if (!currentUser) {
      throw new Error(`Access manager could not find current user!:  ${JSON.stringify(currentUser)}`);
    }

    const { view, mode, onConfirm, onDismiss, hideCollaboratorsToggle = false, approvalsGuidance } = props;

    this.store = createStore({
      ui: {
        errors: [],
        // Show a different intro message before the API error depending on which API
        // call failed.
        errorIntro: I18n.t('shared.site_chrome.access_manager.manage_collaborators.save_failed'),
        footer: {
          confirmButtonDisabled: confirmButtonDisabledByDefault(mode),
          confirmButtonBusy: confirmButtonBusyByDefault(mode),
          showConfirmButton: confirmButtonShown(mode, view),
          hideCollaboratorsToggle
        },
        mode,
        onConfirm,
        onDismiss,
        showApprovalMessage: false,
        showRejectedMessage: false
      },
      permissions: {
        approvalsGuidance,
        currentUser,
        originalPermissions: null,
        permissions: null,
        scheduleCounts: {},
        view,
        visualizationCanvasHasPublicDataSource: false
      },
      addCollaborators: {
        mode,
        selectedUsers: [],
        query: '',

        // this is defaulted to null and is set to the first access level that we get back
        // from the permissions API, since it should default differently depending on asset type
        accessLevel: null
      },
      publishedTo: {
        mode,
        selectedUsers: []
      },
      changeOwner: {
        query: '',
        selectedOwner: []
      }
    });
  }

  componentDidMount() {
    // dispatching this action will cause the PermissionsSagas to go and fetch the permissions for the view
    const { view } = this.props;
    this.store.dispatch(fetchPermissions(view.id));
  }

  render() {
    return (
      <Provider store={this.store}>
        <AccessManagerModal />
      </Provider>
    );
  }
}

export default AccessManager;
