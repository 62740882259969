import { AssetType, View, ViewRight } from 'common/types/view';

/**
 * @param view View to check rights on
 * @param rights List of rights to check
 * @returns True if the user has ALL of the given rights
 */
export const hasRights = (view: Partial<View>, ...rights: ViewRight[]) =>
  !!view && (rights || []).every((right) => (view.rights || []).includes(right));

/**
 * @param view View to check rights on
 * @param rights List of rights to check
 * @returns True if the user has ANY of the given rights
 */
export const hasOneOfRights = (view: View, ...rights: ViewRight[]) =>
  !!view && (view.rights || []).some((right) => (rights || []).includes(right));

/**
 * This will generally be true if the user...
 * - Has the `edit_others_datasets` domain right (in which case they will get this on every asset on the domain)
 * - Is the "true owner" of the view
 * - Has been given a "co-owner" (a.k.a. `owner`) or a `contributor` grant on the view
 *
 * @param view View to check for update right
 * @returns Whether or not the user has the `update_view` right
 */
export const hasUpdateViewRight = (view: View) => hasRights(view, ViewRight.UpdateView);

/**
 * This will generally be true if the user...
 * - Has the `edit_others_datasets` domain right (in which case they will get this on every asset on the domain)
 * - Is the "true owner" of the view
 * - Has been given a "co-owner" (a.k.a. `owner`) grant on the view
 *
 * @param view View to check for grant right
 * @returns Whether or not the user has the `grant` right
 */
export const hasGrantRight = (view: View) => hasRights(view, ViewRight.Grant);

/**
 * "edit" here is meant to precisely mirror core's View#canEdit, minus the part
 * that checks for view type (nobody can update rows on a grouped view, for instance).
 *
 * This will generally be true if the user...
 * - Has the `edit_others_datasets` domain right (in which case they will get these on every asset on the domain)
 * - Is the "true owner" of the view
 * - Has been given a "co-owner" (a.k.a. `owner`) or a `contributor` grant on the view
 *
 * @param view View to check rights on
 */
export const hasRowEditRights = (view: View) =>
  hasOneOfRights(view, ViewRight.Add, ViewRight.Delete, ViewRight.Write);

/**
 * Meant to mirror dsmapi's `has_owner_like_rights? `function in `web/plugs/view_auth.ex`
 *
 * Note that this is also almost a mirror of `OwnerPermissions` in core's `Permission.java`.
 *
 * Also note that in real life, just checking `grant` is usually enough here but I'm not going to
 * go changing this implementation right now :)
 *
 * This will generally be true if the user...
 * - Has the `edit_others_datasets` domain right (in which case they will get these on every asset on the domain)
 * - Is the "true owner" of the view
 * - Has been given a "co-owner" (a.k.a. `owner`) grant on the view
 *
 * @param view View to check rights on
 * @returns True if the current user is owner-like on the view.
 */
export const hasOwnerLikeRights = (view: View) => {
  const expectedRightsOnDefaultDataset = [
    ViewRight.Write,
    ViewRight.UpdateView,
    ViewRight.RemoveColumn,
    ViewRight.AddColumn,
    ViewRight.UpdateColumn
  ];
  const expectedRightsOnNondefaultDataset = [
    ViewRight.UpdateView,
    ViewRight.DeleteView
  ];
  const expectedRights = view.assetType === AssetType.Dataset
    ? expectedRightsOnDefaultDataset
    : expectedRightsOnNondefaultDataset;
  return hasRights(view, ...expectedRights);
};

/**
 * This will only be true if the user is looking at a public asset, or if they have been given only a `viewer` or a `published_viewer`
 * grant on the asset.
 *
 * @returns True if the user has the `read` right but not the `write` write (they can see but not edit the view)
 * @param view View to check rights on
 */
export const hasReadOnlyRights = (view: View) =>
  hasRights(view, ViewRight.Read) && !hasRights(view, ViewRight.Write);
