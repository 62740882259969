/* eslint-disable camelcase */
// snake_casing these constants to try to remain consistent with core


// NOTE:
// This is a list of VIEW rights granted to users
// This needs to reflect:
//  - ALL_RIGHTS in frontend/lib/view_rights.rb
//  - Right enum in core at unobtainium/src/main/java/com/blist/models/views/Permission.java

// namespaced these constants with view_rights_ to avoid having a 'delete' since it is reserved in JS

export const view_rights_add = 'add';
export const view_rights_add_column = 'add_column';
export const view_rights_delete = 'delete';
export const view_rights_delete_view = 'delete_view';
export const view_rights_grant = 'grant';
export const view_rights_read = 'read';
export const view_rights_remove_column = 'remove_column';
export const view_rights_update_column = 'update_column';
export const view_rights_update_view = 'update_view';
export const view_rights_write = 'write';

export const mutation_rights = [
  view_rights_add,
  view_rights_delete,
  view_rights_update_view,
  view_rights_write
];

export const publishingRights = [
  view_rights_write,
  view_rights_update_view,
  view_rights_remove_column,
  view_rights_add_column,
  view_rights_update_column
];

// NOTE: enum for values of the publicationStage key of a view
export const PUBLICATION_STAGE = Object.freeze({
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished'
});

// Code in public/javascripts/controls/panes/download-dataset.js relies on the values here. If these ever
// get localized correctly, make sure to update that code as well.
export const NORMAL_DATASET_DOWNLOAD_TYPES = Object.freeze(['CSV', 'CSV for Excel', 'CSV for Excel (Europe)', 'JSON', 'RDF', 'RSS', 'TSV for Excel', 'XML']);
