import $ from 'jquery';
import _ from 'lodash';

import PopupFactory from '../PopupFactory';
import MapHelper from 'common/visualizations/helpers/MapHelper';

import { formatFeaturePopupContent } from '../contentFormatters/genericContentFormatter';
import { setPopupContentForLines } from '../contentFormatters/lineContentFormatter';
import { setPopupContentForPoints } from '../contentFormatters/pointContentFormatter';
import { setPopupContentForRegions } from '../contentFormatters/regionContentFormatter';
import { setPopupContentForShapes } from '../contentFormatters/shapeContentFormatter';
import { setPopupContentForStacks } from '../contentFormatters/stackContentFormatter';

import { LAYERS as CLUSTER_LAYERS } from '../vifOverlays/partials/Clusters';
import { LAYERS as LINE_LAYERS } from '../vifOverlays/partials/Lines';
import { LAYERS as POINT_AND_STACK_LAYERS } from '../vifOverlays/partials/PointsAndStacks';
import { LAYERS as SHAPE_LAYERS } from '../vifOverlays/partials/Shapes';
import { LAYERS as REGION_LAYERS } from '../vifOverlays/partials/Regions';

// Shows/Initializes popup for
//    - features (features are drawn on canvas with paint properties)
//    - spidered markers (markers are dom elements added on top of the canvas)
// FeaturePopup is reused for all features. On hovering over from feature A
// to feature B. FeaturePopup is repositioned(lat/lng) and the content is replaced
// with content for feature B.
// A seperate Popup is created for every spider marker. We dont reuse popup for spider markers
// as each marker in a spiders get different offset, so that they end up in a spiral/circle.
// So we need to create a new popup with different offsets for each spider marker.
export default class PopupHandler {
  constructor(map) {
    this._map = map;

    this._featurePopup = PopupFactory.create();
    this._featurePopupGeometries = null;
    this._spiderMarkerPersistPopup = null;
  }

  // Initializes and attaches popup to markers in spider legs. On mouseover
  // shows popup and on mouse leave hides the popup.
  initializePopupForSpideredMarker(spiderOptions) {
    const { spiderLeg, renderOptions, vif } = spiderOptions;
    const popupContent = formatFeaturePopupContent(spiderLeg.feature, vif, renderOptions);

    if (_.isEmpty(popupContent)) {
      return;
    }

    const spiderMarkerPopup = PopupFactory.create(spiderLeg.param.x, spiderLeg.param.y);

    $(spiderLeg.elements.container).
      on('mouseenter', () => {
        spiderMarkerPopup.addTo(this._map);
      }).
      on('mouseleave', () => {
        if (this._spiderMarkerPersistPopup !== spiderMarkerPopup) {
          spiderMarkerPopup.remove();
        }
      }).
      on('click', (event) => {
        if (!_.isNil(this._spiderMarkerPersistPopup)) {
          this._spiderMarkerPersistPopup.remove();
          this._spiderMarkerPersistPopup = null;
        }

        spiderMarkerPopup.addTo(this._map);
        this._spiderMarkerPersistPopup = spiderMarkerPopup;
        event.stopPropagation();
      });

    spiderLeg.mapboxMarker.setPopup(spiderMarkerPopup);

    spiderMarkerPopup.setHTML(popupContent);
  }

  // Displays tipsy for the given feature in the map.
  //  popupOptions:
  //    - feature       : geojson object got back from mapboxgl map for which to show the tipsy
  //    - event         : mouse event for which we are showing the popup
  //    - vif           : vif object
  //    - renderOptions : render options object.
  async showFeaturePopup(popupOptions = {}) {
    const { event, features, renderOptions, vifs } = popupOptions;
    const featuresGeometry = _.map(features, 'geometry');
    // All the features in the array are on the same location (or overlapping locations.)
    // The popup should be anchored to the top most point(zero th index).
    // So taking the first feature's coordinates and anchoring the popup to it.
    const coordinates = _.get(features[0], 'geometry.coordinates');
    const layerId = _.get(features[0], 'layer.id');
    const layerName = MapHelper.getName(layerId);
    const popupContentElement = document.createElement('div');
    let popupParams = {
      features,
      renderOptions,
      element: popupContentElement,
      featureRenderedOnZoom: Math.floor(this._map.getZoom()),
      map: this._map,
      vifs
    };

    if (
      (layerName === POINT_AND_STACK_LAYERS.POINTS_CIRCLE ||
      layerName === POINT_AND_STACK_LAYERS.STACKS_CIRCLE) &&
      !(vifs[0].isMultiPointColumn(renderOptions[0].datasetMetadata))
    ) {
      this._featurePopup.setLngLat(coordinates);
    } else {
      this._featurePopup.setLngLat([event.lngLat.lng, event.lngLat.lat]);
    }

    // To prevent recalculating and showing popup, when a popup is already shown for the
    // given feature.
    if (_.isEqual(this._featurePopupGeometries, featuresGeometry)) {
      return;
    }

    this._featurePopup.setDOMContent(popupContentElement);
    this._showFeaturePopup(features);

    if (layerName === POINT_AND_STACK_LAYERS.POINTS_CIRCLE) {
      if (features.length > 1) {
        return await setPopupContentForStacks(popupParams);
      }
      return await setPopupContentForPoints(popupParams);
    } else if (layerName === POINT_AND_STACK_LAYERS.STACKS_CIRCLE) {
      return await setPopupContentForStacks(popupParams);
    } else if (layerName === LINE_LAYERS.LINES) {
      return await setPopupContentForLines(popupParams);
    } else if (layerName === SHAPE_LAYERS.SHAPES_FILL) {
      return await setPopupContentForShapes(popupParams);
    } else if (layerName === REGION_LAYERS.REGIONS_FILL) {
      return await setPopupContentForRegions(popupParams);
    } else {
      throw new Error(`Unknown layer mouseover on ${layerId}`);
    }
  }

  spiderPersistPopupRemove() {
    if (!_.isNil(this._spiderMarkerPersistPopup)) {
      this._spiderMarkerPersistPopup.remove();
      this._spiderMarkerPersistPopup = null;
    }
  }

  // Removes the popup(spiderMakerPopup/featurePopup) from the map.
  removeFeaturePopup() {
    this._featurePopupGeometries = null;
    this._featurePopup.remove();
  }

  _showFeaturePopup(features) {
    this._featurePopup.addTo(this._map);
    this._featurePopupGeometries = _.map(features, 'geometry');
  }
}
