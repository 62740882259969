import some from 'lodash/some';

import assert from './assert';

/**
 * Asserts that an object is instanceof at least one of the provided instantiators.
 *
 * @param {object} instance - The instance to check.
 * @param {function[]} [instantiators] - List of acceptable instantiators
 */
export default (instance, ...instantiators) =>
  assert(
    some(instantiators, function(instantiator) {
      if (instantiator.toString().indexOf('function Array') > -1) {
        return Array.isArray(instance);
      } else {
        return instance instanceof instantiator;
      }
    }),
    `Value must be one of [${instantiators.join(', ')}] (instance: ${instance}).`
  );
