import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import CollapsedIcon from './CollapsedIcon';
import I18n from 'common/i18n';
import Results from './Results/Results';
import SearchBox from './SearchBox/SearchBox';
import { getQueryFromBrowseUrl } from '../Util';

import './autocomplete.scss';

const ESCAPE_KEY = 27;
const ENTER_KEY = 13;

const scope = 'shared.components.autocomplete';

export const classNameScope = 'common--components--Autocomplete--autocomplete';

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      focusedResult: this.getDefaultFocusedResults(),
      isLoading: false,
      query: this.props.query,
      resultsVisible: false || props.shouldRenderResultsWhenQueryIsEmpty,
      results: [],
      retainFocus: false
    };
    this.debouncedFetchResults = debounce(this.fetchResults, this.props.millisecondsBeforeSearch);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  getDefaultFocusedResults() {
    return this.props.focusFirstResult ? 0 : undefined;
  }

  handleKeyDown = (event) => {
    if (event.keyCode === ESCAPE_KEY) {
      this.handleOnResultsVisibilityChanged(false);
    }
    if (event.keyCode === ENTER_KEY && this.props.allowCustomInput) {
      this.handleOnResultsVisibilityChanged(false);
    }
  };

  handleOnCollapsedChange = (collapsed) => this.setState({ collapsed });


  handleOnResultsVisibilityChanged = (isVisible) => {
    this.setState({
      collapsed: !isVisible,
      resultsVisible: isVisible,
      focusedResult: isVisible ? this.state.focusedResult : this.getDefaultFocusedResults()
    });

    if (!isVisible && this.props.allowCustomInput) {
      this.props.onChooseResult(this.state.query);
    }
  };

  handleOnQueryChanged = (query) => {
    if (isEmpty(query) && !this.props.shouldRenderResultsWhenQueryIsEmpty) {
      this.setState({ isLoading: false, results: [], query });
      return;
    }

    this.setState({
      isLoading: true,
      resultsVisible: true,
      query
    });

    this.debouncedFetchResults(query);
  };

  onLoadMore = () => {
    if (this.state.isLoading || !this.props.isLazyLoading) {
      return;
    }

    this.setState({ isLoading: true });
    this.fetchResults(this.state.query, true);
  };

  fetchResults = (query, appendToExistingResults = false) => {
    const { anonymous, getSearchResults } = this.props;
    const { results } = this.state;
    const offset = results.length;

    getSearchResults(query, anonymous, offset, appendToExistingResults).
      then(({ results: newSearchResults }) => {
        if (this.state.query === query) {
          // In many cases based on user continuously typing, multiple queries would have been fired.
          // State should be updated only when the request for the current query is complete. Other requests
          // when they resolve, those should be ignored.
          // Set results in state, only if currentQuery is same as the query for which we have results.
          this.setState({
            results: appendToExistingResults ? [...results, ...newSearchResults] : newSearchResults
          });
        }
      }).
      catch(() => {
        // Do nothing, because this isn't a fatal issue. The user just won't get autocomplete.
        // However, if we let the error through uncaught, it will cause consolespam at best
        // or break unrelated things at worst.
      }).
      finally(() => {
        if (this.state.query === query) {
          // Set isLoading to false, only if currentQuery has completed.
          this.setState({ isLoading: false });
        }
      });
  };

  handleOnFocusedResultChanged = (focus) => {
    const { results } = this.state;
    const resultsEmpty = isEmpty(results);

    const focusedResult = resultsEmpty || focus < 0 ?
      this.getDefaultFocusedResults() :
      Math.min(focus, results.length - 1);
    this.setState({ focusedResult });

    // load more on focus of the last item
    // loadMore is also triggered on scrolling. but that
    // means loading more functionality is dependent on CSS which
    // may or may not be applied. that is kind of strange and
    // doesn't work in all cases.
    if ((focus + 1) === results.length) {
      this.onLoadMore();
    }
  };

  handleResultSelection = (title, result) => {
    const { onChooseResult, onSelectSetSelectionAsQuery } = this.props;

    // focus on the search box when a result was clicked so focus does not get lost
    this.setRetainFocus(true);

    if (onSelectSetSelectionAsQuery) {
      this.setState({ query: title });
    } else {
      this.setState({ query: '', results: [] });
    }
    onChooseResult(title, result);
  };

  handleSearchBoxFocused = (focus) => {
    if (focus && isEmpty(this.state.query)) {
      this.handleOnQueryChanged(this.state.query);
    }
  };

  setRetainFocus = (retainFocus) => {
    this.setState({ retainFocus });
  };

  render() {
    const {
      animate,
      anonymous,
      className,
      collapsible,
      disabled,
      isLazyLoading,
      mobile,
      onClearSearch,
      placeholder,
      renderResult,
      showLoadingSpinner,
      suppressNoResultsMessage
    } = this.props;

    const { collapsed, focusedResult, isLoading, query, results, resultsVisible, retainFocus } = this.state;

    const noResultsFound = !isLoading && !isEmpty(query) && isEmpty(results);

    const resultsProps = {
      collapsible,
      focusedResult,
      isLoadingMore: isLoading,
      isLazyLoading,
      onChooseResult: this.handleResultSelection,
      onLoadMore: this.onLoadMore,
      onResultFocusChanged: this.handleOnFocusedResultChanged,
      onResultsVisibilityChanged: this.handleOnResultsVisibilityChanged,
      renderResult,
      results,
      visible: resultsVisible && !noResultsFound
    };
    const searchBoxProps = {
      animate,
      anonymous,
      collapsible,
      disabled,
      focusedResult,
      mobile,
      onChooseResult: this.handleResultSelection,
      onClearSearch,
      onResultVisibilityChanged: this.handleOnResultsVisibilityChanged,
      onSearchBoxChanged: this.handleOnQueryChanged,
      onSearchBoxFocused: this.handleSearchBoxFocused,
      placeholder,
      query,
      showLoadingSpinner: showLoadingSpinner && isLoading,
      retainFocus,
      setRetainFocus: this.setRetainFocus
    };

    if (collapsible && collapsed) {
      return (
        <CollapsedIcon onCollapsedChanged={this.handleOnCollapsedChange} />
      );
    }

    let noResultsMessageContent = null;

    if (noResultsFound && resultsVisible && !suppressNoResultsMessage) {
      noResultsMessageContent = (<div className={`${classNameScope}--no-results`}>
        {I18n.t('no_results', { scope })}
      </div>);
    }

    return (
      <div
        className={`${className} ${classNameScope}--container`}
        tabIndex="-1"
        onFocus={() => this.handleOnResultsVisibilityChanged(true)}
        onBlur={() => this.handleOnResultsVisibilityChanged(false)}
      >
        <SearchBox {...searchBoxProps} />
        {noResultsMessageContent}
        <Results {...resultsProps} />
      </div>
    );
  }
}

Autocomplete.propTypes = {
  animate: PropTypes.bool,
  anonymous: PropTypes.bool,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  disabled: PropTypes.bool,
  allowCustomInput: PropTypes.bool,
  focusFirstResult: PropTypes.bool,
  shouldRenderResultsWhenQueryIsEmpty: PropTypes.bool,
  suppressNoResultsMessage: PropTypes.bool,
  millisecondsBeforeSearch: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
  placeholder: PropTypes.string,
  query: PropTypes.string,
  // When true, on selecting a result, the result's title gets set as the query.
  // When false, on selecting a result, the user typed query will remain as is.
  onSelectSetSelectionAsQuery: PropTypes.bool,
  // When true, shows loading spinner on the right of the input field while data is being fetched for autocomplete
  showLoadingSpinner: PropTypes.bool,
  /**
   * Called to fetch results for user's query
   *
   * @param {String} query      - the value typed in the autocomplete textbox by the user.
   * @param {Boolean} anonymous - (inherited autocomplete component used in the header bar. not sure about it use)
   *
   * @return {Promise} that resolves to object
   *
   * @resolve {Array} results
   * @resolve {String} results[].title              - result title to be displayed in the autocomplete dropdown
   * @resolve {Array} results[].matches             - matches to be highlighted
   * @resolve {Number} results[].matches[].start    - starting of the match
   * @resolve {Number} results[].matches[].length   - length of the match
   */
  getSearchResults: PropTypes.func.isRequired,
  onChooseResult: PropTypes.func,
  onClearSearch: PropTypes.func,
  renderResult: PropTypes.func
};


Autocomplete.defaultProps = {
  onChooseResult: (name) => {
    window.location.href = getQueryFromBrowseUrl(name);
  },
  collapsible: false,
  focusFirstResult: false,
  isLazyLoading: false,
  onSelectSetSelectionAsQuery: true,
  query: ''
};

export default Autocomplete;
