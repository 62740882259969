/* Imports */
/* ============================================================================= */
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { BaseGuidanceSummaryV2Helper, GuidanceCheckSummary } from 'common/core/approvals/Types';
import { hasTruthyWorkflow, getOnlyMatchingWorkflow } from '.';





/* Method */
/* ============================================================================= */
/**
 * Get the published UID string from among the workflows in a GuidanceSummaryV2
 *  response which has been made into GuidanceSummaryV2Helper.
 * @param guidanceHelper BaseGuidanceSummaryV2Helper Object.
 * @param workflow (Optional) The WorkflowTargetAudience value of the workflow
 *  you want to specifically check for a published UID.
 *  NOTE - If this is passed and that workflow does not have a published UID,
 *  the function will return undefined even if a different workflow does have
 *  a published UID.
 * @returns The published UID string, or undefined of one is not found.
 */
export const getPublishedUid = (guidanceHelper: BaseGuidanceSummaryV2Helper, workflow?: WorkflowTargetAudience): string | undefined => {
  const workflows: GuidanceCheckSummary<string> = guidanceHelper.summarizePublishedUid();
  let response: string | undefined = undefined;

  if (hasTruthyWorkflow(workflows, workflow)) {
    // Since you can only have a published asset in one workflow,
    // workflows should only have 1 truthy entry here.
    response = workflow ? workflows[workflow] : workflows[getOnlyMatchingWorkflow(workflows)];
  }

  return response;
};

export default getPublishedUid;
