/* Imports */
/* ============================================================================= */
import { APPROVALS } from 'common/core/approvals_enums';
import { WorkflowGuidance } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Returns whether an asset is currently pending approval.
 *
 * @param guidance WorkflowGuidance object
 * @returns boolean
 */
export const assetIsPendingV2 = (guidance: WorkflowGuidance) => {
  return guidance.currentState === APPROVALS.STATUS.PENDING;
};

export default assetIsPendingV2;
