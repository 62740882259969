import { isError, isFunction, isPlainObject, get, omit } from 'lodash';
import { INotice } from '@airbrake/browser/dist/notice';

import airbrake from 'common/airbrake';
import Environment from '../StorytellerEnvironment';

type ExceptionNotifierOptions = {
  ENVIRONMENT_NAME?: string;
  PROJECT_ID?: string;
  API_KEY?: string;
};

/**
 * @class ExceptionNotifier
 *
 * @description
 * A small wrapper class for airbrake-client-js. Exposes a `.notify`
 * method for elected errors. This class will notify on unexpected
 * errors that surface through the window error event.
 */
export default class ExceptionNotifier {
  environment?: string;

  constructor(options: ExceptionNotifierOptions = {}) {
    this.environment = options.ENVIRONMENT_NAME;
    this.setupAirbrake(options);

    this.attachEvents();
  }

  /**
   * @function notify
   *
   * @description
   * Exposes an error through Airbrake, if it is available.
   * In either case, it will `console.error` the argument passed
   * into this function.
   *
   * Additionally, an exception is sent to Google Analytics for tracking.
   *
   * @param {Any} error - Anything that should be logged in Airbrake/console.
   */
  notify(errObj: XMLHttpRequest | Error | Record<string, unknown> | string): void {
    const params: { requestId?: string } = {};
    if (isPlainObject(errObj)) {
      errObj = new Error(JSON.stringify(errObj));
    } else if (!isError(errObj)) {
      errObj = new Error(errObj as string);
    }

    airbrake.notify({ error: errObj, params });

    const ga = (window as any).ga;
    if (isFunction(ga)) {
      ga('send', 'exception', {
        exDescription: `Airbrake notification: ${errObj.message}`,
        exFatal: false
      });
    }
  }

  private setupAirbrake(options: ExceptionNotifierOptions) {
    const airbrakeOptions = omit(options, 'ENVIRONMENT_NAME');

    if (!airbrakeOptions.PROJECT_ID || !airbrakeOptions.API_KEY) {
      return null;
    }

    airbrake.init(airbrakeOptions.PROJECT_ID, airbrakeOptions.API_KEY);

    airbrake.addFilter((notice: INotice) => {
      notice.context.environment = this.environment;
      return notice;
    });
  }

  private attachEvents() {
    window.onerror = (...args) => {
      // args[4] is typically the Error instance.
      this.notify(args[4] as Error);
    };
  }
}

export const exceptionNotifier = new ExceptionNotifier(Environment.AIRBRAKE);
