interface ILegendMenuProps {
  showFilterBar?: boolean;
  verticalFilterBarContainer: HTMLDivElement;
}

// Force close the legend menu since it overlaps with the filter bar when toggled.
export const setLegendMenuVisibility = ({ showFilterBar, verticalFilterBarContainer }: ILegendMenuProps) => {
  const { mainComponent, legendToggle, socrataLegendMenu } = getComponents(verticalFilterBarContainer);
  const isFilterBarExpanded = showFilterBar ?? !!mainComponent?.querySelector('.filter-bar-expanded');

  if (legendToggle && !isLegendMenuHidden(mainComponent)) {
    updateZIndex(!!isFilterBarExpanded, socrataLegendMenu);
  } else if (socrataLegendMenu) {
    // Only right side panel encounters overlap issue.
    const isRightLegendMenu = mainComponent.querySelector('.socrata-visualization-legend-pane-right');
    const shouldUpdate = isFilterBarExpanded && isRightLegendMenu;
    updateZIndex(!!shouldUpdate, socrataLegendMenu);
  }
};

const updateZIndex = (shouldUpdateZIndex: boolean, socrataLegendMenu: HTMLElement) => {
  socrataLegendMenu.style.zIndex = shouldUpdateZIndex ? '-1' : '1';
};

const getComponents = (verticalFilterBarContainer: HTMLDivElement) => {
  const mainComponent = getMainComponent(verticalFilterBarContainer);
  return {
    mainComponent,
    legendToggle: mainComponent?.querySelector('button.socrata-legend-button') as HTMLElement,
    socrataLegendMenu: mainComponent?.querySelector('.socrata-legend-menu') as HTMLElement
  };
};

const isLegendMenuHidden = (mainComponent: HTMLElement) => {
  const socrataLegendMenu = mainComponent?.querySelector('div.socrata-legend-menu') as HTMLElement;
  return ['', 'none'].includes(socrataLegendMenu?.style.display);
};

const getMainComponent = (verticalFilterBarContainer: HTMLDivElement) => {
  const flexClassicBlockClass = '.component-container';
  const modalBlockClass = '.component-content';

  return (verticalFilterBarContainer?.closest(flexClassicBlockClass) ||
    verticalFilterBarContainer?.closest(modalBlockClass)) as HTMLElement;
};
