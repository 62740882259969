import FeatureFlags from 'common/feature_flags';
import {
  VIF_CONSTANTS,
  DEFAULT_PRIMARY_COLOR
} from 'common/authoring_workflow/constants';

export default {
  format: {
    type: 'visualization_interchange_format',
    version: VIF_CONSTANTS.LATEST_VERSION
  },
  configuration: {
    viewSourceDataLink: true,
    defaultDisplayDate: null,
  },
  description: '',
  series: [
    {
      color: {
        primary: DEFAULT_PRIMARY_COLOR
      },
      dataSource: {
        datasetUid: null,
        dimension: { columnName: '' },
        domain: null,
        measure: {
          columnName: null,
          aggregationFunction: null
        },
        type: 'socrata.soql',
        filters: []
      },
      label: null,
      type: 'calendar'
    },
    {
      color: {
        primary: DEFAULT_PRIMARY_COLOR
      },
      dataSource: {
        datasetUid: null,
        dimension: { columnName: '' },
        domain: null,
        measure: {
          columnName: null,
          aggregationFunction: null
        },
        type: 'socrata.soql',
        filters: []
      },
      label: null,
      type: 'calendar'
    },
    {
      color: {
        primary: DEFAULT_PRIMARY_COLOR
      },
      dataSource: {
        datasetUid: null,
        dimension: { columnName: '' },
        domain: null,
        measure: {
          columnName: null,
          aggregationFunction: null
        },
        type: 'socrata.soql',
        filters: []
      },
      label: null,
      type: 'calendar'
    }
  ],
  title: ''
};
