/* Imports */
/* ============================================================================= */
import { WorkflowGuidance } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Returns whether or not an asset can be submitted for an audience change
 * (this is also called "visibility" or "scope", which is _not_ to be confused with the "scope" that is also used in this same file to mean "provenance").
 *
 * NOTE: If this is not passed a guidance summary, it will throw an error!
 *
 * @param guidanceWorkflowGuidance object
 * @returns boolean
 */
export const canSubmitChangeAudienceV2 = (guidance: WorkflowGuidance) => {
  return !!guidance.toChangeAudience;
};

export default canSubmitChangeAudienceV2;
