import $ from 'jquery';
import { Key } from 'common/types/keyboard/key';

const Dropdown = function(element) {
  this.dd = element;
  this.orientation = this.dd.getAttribute('data-orientation') || 'bottom';
  this.selectable = this.dd.hasAttribute('data-selectable');

  this.dd.classList.add(`dropdown-orientation-${this.orientation}`);

  // Set the 'role' and 'aria-expanded' attributes for better ADA/508 compliance.
  this.dd.setAttribute('role', 'button');
  this.dd.removeAttribute('aria-expanded');
  this.dd.setAttribute('aria-haspopup', 'listbox');

  if (!this.dd.hasAttribute('tabIndex')) {
    this.dd.setAttribute('tabIndex', 0);
  }

  this.placeholder = this.dd.querySelector('span');
  this.opts = Array.prototype.slice.call(this.dd.querySelectorAll('.dropdown-options > li'));
  this.dropdownOptionsContainer = this.dd.querySelector('.dropdown-options');

  this.dd.dataset.value = '';
  this.dd.dataset.index = -1;

  this.initEvents();
};

// We need to remember the modality of the last interaction so we can display
// unambiguous hover/focus UI. If the user last interacted with this dropdown
// via the keyboard, we should hide pointer-hover effects. This is because hover
// and focus effects are visually identical, and thus it is very confusing if we
// show two elements with the same hover/focus effects (keeping in mind focus and
// hover can be in different places).
// Please keep these up to date with common/components/legacy/dropdown-variables.scss.
const INPUT_MODALITY_KEYBOARD = 'keyboard';
const INPUT_MODALITY_POINTER = 'pointer';
// Data attribute used to store the last input modality.
// This is used by site chrome to implement better focus UX.
// Please keep this up to date with common/components/legacy/dropdown-variables.scss.
const INPUT_MODALITY_DATA_ATTRIBUTE = 'data-last-input-modality';

Dropdown.prototype = {
  initEvents: function() {
    const obj = this;
    // Reposition dropdown if it's near the edge of the window to avoid
    // the dropdown making the window larger than we wanted
    positionDropdown();

    const openDropdown = () => {
      positionDropdown();
      obj.dd.setAttribute('tabIndex', -1);
      obj.dd.classList.add('active');

      // MS Edge has an a11y bug where it won't expose link text to screenreaders
      // when they're within a parent of role=button alongside other links.
      // Work around that by removing the button role while the dropdown is open.
      obj.dd.removeAttribute('role');

      obj.dropdownOptionsContainer.setAttribute('aria-hidden', 'false');
      obj.opts.forEach((opt) => {
        const focusTarget = getOptionFocusTarget(opt);
        focusTarget.setAttribute('tabIndex', 0);
      });

      obj.dd.setAttribute('aria-expanded', 'true');

      obj.dd.removeAttribute(INPUT_MODALITY_DATA_ATTRIBUTE);
    };

    const closeDropdown = () => {
      // See Edge workaround in openDropdown.
      // If we restore the button role right on focus change, the
      // screen reader might miss the next element being focused
      // and not read it aloud. Workaround is to restore the role
      // on the next frame.
      setTimeout(() => {
        obj.dd.setAttribute('role', 'button');
      });
      obj.dd.setAttribute('tabIndex', 0);

      // Aria-hide all options while the dropdown is closed.
      // We must also remove tabIndex to prevent <tab> from focusing
      // each option (aria-hidden does not affect tab order).
      obj.dropdownOptionsContainer.setAttribute('aria-hidden', 'true');
      obj.opts.forEach((opt, i) => {
        const focusTarget = getOptionFocusTarget(opt);
        focusTarget.setAttribute('tabIndex', -1);
      });
      obj.dd.classList.remove('active');
      obj.dd.removeAttribute('aria-expanded');
    };

    const isOpen = () => $(obj.dd).hasClass('active');

    obj.dd.addEventListener('click', function() {
      openDropdown();
      focusFirstElement(INPUT_MODALITY_POINTER);
      return false;
    });

    // By default focus goes to the option element, but this can be overriden by adding a class of
    // 'dropdown-focus-target' to the appropriate element within each option.

    const getOptionFocusTarget = (opt) => opt.querySelector('.dropdown-focus-target') || opt;

    const focusNthElement = (idx, inputModality) => {
      obj.dd.setAttribute(INPUT_MODALITY_DATA_ATTRIBUTE, inputModality);
      obj.opts.forEach((opt, i) => {
        if (i === idx) {
          getOptionFocusTarget(opt).focus();
        }
      });
    };

    const focusFirstElement = (inputModality) => {
      focusNthElement(0, inputModality);
    };

    const focusLastElement = (inputModality) => {
      if (this.opts.length == 0) { return; }
      focusNthElement(this.opts.length - 1, inputModality);
    };

    // Focuses the option equal to or containing the given child node.
    // If the given node is not present in the dropdown, this function
    // does nothing.
    const focusElementContaining = (childOrSelf, inputModality) => {
      if (childOrSelf && nodeIsPartOfThisDropdown(childOrSelf)) {
        const matchedIdx = Array.from(obj.opts).findIndex((option) => $.contains(option, childOrSelf));
        if (matchedIdx >= 0) {
          focusNthElement(matchedIdx, inputModality);
        }
      }
    };

    obj.opts.forEach((opt, i) => {
      opt.addEventListener('keydown', function(e) {
        if (
          e.key === Key.Down_MSIE ||
          e.key === Key.ArrowDown
        ) {
          focusNthElement((i + 1) % obj.opts.length, INPUT_MODALITY_KEYBOARD);
          e.preventDefault();
          e.stopPropagation(); // Stop the dropdown from opening/closing.
        }
        if (
          e.key === Key.Up_MSIE ||
          e.key === Key.ArrowUp
        ) {
          if (i === 0) {
            focusNthElement(obj.opts.length - 1, INPUT_MODALITY_KEYBOARD);
          } else {
            focusNthElement(i - 1, INPUT_MODALITY_KEYBOARD);
          }
          e.preventDefault();
          e.stopPropagation(); // Stop the dropdown from opening/closing.
        }
      });
    });

    obj.dd.addEventListener('keydown', function(e) {
      if (
        e.key === Key.Down_MSIE ||
        e.key === Key.ArrowDown ||
        e.key === ' ' ||
        (e.key === Key.Enter && e.target === obj.dd) // Allow <Enter> to work on children.
      ) {
        openDropdown();
        focusFirstElement(INPUT_MODALITY_KEYBOARD);
        e.preventDefault();
      } else if (
        e.key === Key.Up_MSIE ||
        e.key === Key.ArrowUp
      ) {
        openDropdown();
        focusLastElement(INPUT_MODALITY_KEYBOARD);
        e.preventDefault();
      } else if (
        e.key === Key.Escape_MSIE ||
        e.key === Key.Escape
      ) {
        closeDropdown();
        obj.dd.focus();
        e.preventDefault();
      }
    });

    obj.dd.addEventListener('mouseover', (e) => {
      if (isOpen()) {
        focusElementContaining(e.target);
      }
    });

    obj.dd.addEventListener('focusout', (e) => {
      const focusGoingTo = e.relatedTarget;

      if (!nodeIsPartOfThisDropdown(focusGoingTo)) {
        closeDropdown();
      }
    });

    // End keyboard accessibility.

    if (obj.selectable) {
      obj.opts.forEach(function(opt) {
        opt.addEventListener('click', function(event) {
          event.preventDefault();

          let node = opt.previousElementSibling;
          let index = 0;

          while (node !== null) {
            index++;
            node = node.previousElementSibling;
          }

          obj.dd.dataset.value = opt.textContent;
          obj.dd.dataset.index = index;

          obj.placeholder.innerHTML = opt.innerText.trim();

          return false;
        });
      });
    }

    const nodeIsPartOfThisDropdown = (node) => {
      while (node) {
        if (node === obj.dd) {
          return true;
        }
        node = node.parentElement;
      }

      return false;
    };

    // Close dropdown if user clicks somewhere outside the dropdown.
    document.addEventListener('click', function(event) {
      let clicked = event.target;
      if (!nodeIsPartOfThisDropdown(clicked)) {
        closeDropdown();
      }
    });

    window.addEventListener('resize', function() {
      positionDropdown();
    });

    function positionDropdown() {
      const optionsElement = obj.dd.querySelector('.dropdown-options');
      const optionsElementWidth = optionsElement.offsetWidth;
      const windowWidth = document.body.offsetWidth;

      // Get left to check if the dropdown options are hanging off the side of the page
      let node = optionsElement;
      let left = 0;

      do {
        left += node.offsetLeft;
        node = node.offsetParent;
      } while (node !== null);

      // Update dropdown options position if needed
      if (optionsElementWidth + left >= windowWidth || optionsElement.style.left) {
        const dropdownWidth = obj.dd.getBoundingClientRect().width;
        const leftOffset = -(optionsElementWidth - dropdownWidth);
        optionsElement.style.left = `${leftOffset}px`;
      }
    }

    closeDropdown();
  }
};

export default function DropdownFactory(element) {
  if (!element) { return; }
  this.dropdowns = Array.prototype.slice.call(element.querySelectorAll('[data-dropdown]'));
  this.dropdowns.forEach(function(dropdown) {
    new Dropdown(dropdown); // eslint-disable-line no-new
  });
}
