import React, { FunctionComponent } from 'react';

import Actions from 'Actions';
import Button, { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';

/**
 * NOTE: This is intended for pre-flexible layouts only.
 */

const ClassicLayoutsAssetSelectorInsertButton: FunctionComponent = () => (
  <div className="insert-button-container" data-action={Actions.ASSET_SELECTOR_SELECT_ASSET_FOR_COMPONENT}>
    <Button className="asset-selector-insert-btn" variant={VARIANTS.PRIMARY}>
      {I18n.t('editor.components.asset_selector.insert_btn')}
    </Button>
  </div>
);

export default ClassicLayoutsAssetSelectorInsertButton;
