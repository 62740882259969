/* Imports */
/* ============================================================================= */
import { APPROVALS } from 'common/core/approvals_enums';
import { WorkflowGuidance } from 'common/types/approvals';

import { updateApprovalV2 } from 'common/core/approvals/guidanceLogic';





/* Method */
/* ============================================================================= */
/**
 * Reject an asset (for visibility change, updating published version, etc.)
 *
 * @param guidance WorkflowGuidance object
 * @param notes Notes to add when rejecting.
 * @returns A Promise object
 */
export const rejectAssetV2 = (guidance: WorkflowGuidance, notes = '') => {
  const putBody = {
    state: APPROVALS.STATUS.REJECTED,
    notes
  };

  return updateApprovalV2(guidance, putBody);
};

export default rejectAssetV2;
