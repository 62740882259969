import flow from 'lodash/fp/flow';
import isPlainObject from 'lodash/fp/isPlainObject';
import keys from 'lodash/fp/keys';
import reduce from 'lodash/fp/reduce';

const formatWithObject = (string, values) =>
  flow(
    keys,
    reduce(
      (stringToFormat, key) => stringToFormat.replace(new RegExp(`\\{${key}\\}`, 'gm'), values[key]),
      string
    )
  )(values);

/**
 * Given a string and either a list of arguments or an object of key/value pairs,
 * replace instances of '{#}' or '{key}' in the string with the numeric indexed or
 * keyed value, respectively.
 *
 * @example
 * // returns 'Hello, World!'
 * formatWithObject('Hello, {what}!', { what: 'World'});
 *
 * @example
 * // returns 'Hello, World!'
 * formatWithObject('{1}, {0}!', 'World', 'Hello');
 *
 * @param {string} string - Input string
 * @param {args[]|object} args - List of arguments or object key/value pairs
 * @returns {string}
 */
export const formatString = (string, ...args) =>
  isPlainObject(args[0]) ? formatWithObject(string, args[0]) : formatWithObject(string, { ...args });

export default formatString;
