import { chain, find, forEach, map } from 'lodash';
import * as BaseFilter from './Filters/BaseFilter';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { FilterBarColumns } from 'common/components/FilterBar/index';
import { FilterItemType, FilterParameterConfiguration } from 'common/types/reportFilters';

export function getNonHiddenFilters(filters: SoqlFilter[], isReadOnly = true) {
  return chain(filters)
    .map((filterItem) => {
      if (isReadOnly && filterItem.isHidden) {
        return null;
      }
      return filterItem;
    })
    .compact()
    .value();
}

export function resetFiltersToDefaults(
  filters: SoqlFilter[],
  columns: FilterBarColumns,
  resetOnlyVisibleFilters = false
) {
  return map(filters, (filter) => {
    // We only want to pass in the columns associated to the filter so we manually map the columns for each filter
    const filterColumns = {};
    forEach(filter.columns, (column) => {
      filterColumns[column.datasetUid] = find(columns[column.datasetUid], ['fieldName', column.fieldName]);
    });
    return BaseFilter.reset(filter, filterColumns, resetOnlyVisibleFilters);
  });
}

export function resetAllFiltersToDefaults(
  filterParameterConfigurations: FilterParameterConfiguration[],
  columns: FilterBarColumns,
  resetOnlyVisibleFilters = false
): FilterParameterConfiguration[] {
  return map(filterParameterConfigurations, (filterParameterConfiguration) => {
    if (filterParameterConfiguration.type === FilterItemType.FILTER) {
      // We only want to pass in the columns associated to the filter so we manually map the columns for each filter
      const filterColumns = {};
      forEach(filterParameterConfiguration.config.columns, (column) => {
        filterColumns[column.datasetUid] = find(columns[column.datasetUid], ['fieldName', column.fieldName]);
      });
      const newFilter = BaseFilter.reset(
        filterParameterConfiguration.config,
        filterColumns,
        resetOnlyVisibleFilters
      );
      return {
        type: FilterItemType.FILTER,
        config: newFilter
      };
    } else {
      // Keep the Parameters as is. They don't get reset.
      return filterParameterConfiguration;
    }
  });
}
