import React, { FunctionComponent, useEffect, useState } from 'react';
import WatchlistItems from './WatchlistItems';
import { fetchAllSubscriptionsForUser } from 'common/notifications/api/ScreenshotSubscriptionsAPI';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import { View } from 'common/types/view';

import './watchlist.scss';

import I18n from 'common/i18n';

const scope = 'shared.site_chrome.notifications.alert_setting_modal.watchlist_tab';

const Watchlist: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [storiesUserIsWatching, setStoriesUserIsWatching] = useState<View[]>([]);
  const [watchesUserCreated, setWatchesUserCreated] = useState<View[]>([]);

  // Get the subscriptions and then the info for each story from api/views
  async function loadStoriesUserIsWatching(watching: boolean) {
    try {
      const stories = await fetchAllSubscriptionsForUser(watching).then((res) => res.data);
      if (stories && stories.length > 0) {
        const storyIds = stories.map((story) => story.asset_uid).join('&ids=');
        const url = `/api/views?method=getByIds&ids=${storyIds}`;
        const views = await fetchJsonWithDefaultHeaders(url);
        if (watching) {
          setStoriesUserIsWatching(views);
        } else {
          setWatchesUserCreated(views);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  // First time we load the component, get the two different lists of stories
  useEffect(() => {
    // Get the list of stories the user is watching
    loadStoriesUserIsWatching(true);

    // Get the list of watches the user created, but is not watching
    loadStoriesUserIsWatching(false);

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div className="watchlist-content">
      <div className="help-text">
        {I18n.t((storiesUserIsWatching.length == 0 && watchesUserCreated.length == 0) ? 'no_watches' : 'watch_help', { scope })}
      </div>
      {storiesUserIsWatching.length == 0 ? null :
        <WatchlistItems watches={storiesUserIsWatching} title = {I18n.t('watching', { scope })} />}
      {watchesUserCreated.length == 0 ? null :
        <WatchlistItems watches={watchesUserCreated} title = {I18n.t('created', { scope })} />}
    </div>
  );
};

export default Watchlist;
