
export const scrollToPosition = (scrollSelector: string, scrollPosition: number) => {
  const element = document.querySelector(scrollSelector);
  if (element && element.parentElement) {
    const currentHeight = element.getBoundingClientRect().top || scrollPosition;
    element.parentElement.scrollBy(0, currentHeight - scrollPosition);
  } else {
    console.error('Could not scroll: element or parent not found');
  }
};

export const getScrollTop = (): number => {
  const scrollContainer = document.querySelector('.scroll-container');
    if (scrollContainer) {
      return scrollContainer.scrollTop;
    }
    return 0;
};
