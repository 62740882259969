import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import I18n from 'common/i18n';
import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';

import './preference.scss';

const scope = 'shared.site_chrome.notifications.alert_setting_modal';

class EmailSettings extends Component {

  renderCheckBox(category, value, label) {
    const { onSettingsChange } = this.props;
    const checkboxId = _.uniqueId(category);

    return (
      <div className="preferences-checkbox">
        <Checkbox
          id={checkboxId}
          checked={value}
          onChange={(event) => onSettingsChange(category, { enable: event.target.checked })}>
          {label}
        </Checkbox>
      </div>
    );
  }


  renderEmailDigestDropdown(category, value) {
    const { onSettingsChange } = this.props;

    const dropdownId = _.uniqueId(category + 'dropdown_id');
    const options = [
      { title: I18n.t('email_digest.real_time', { scope }), value: 'real_time' },
      { title: I18n.t('email_digest.hourly', { scope }), value: 'hourly' },
      { title: I18n.t('email_digest.daily', { scope }), value: 'daily' },
      { title: I18n.t('email_digest.weekly', { scope }), value: 'weekly' },
      { title: I18n.t('email_digest.monthly', { scope }), value: 'monthly' }
    ];
    return (
      <Dropdown
        id={dropdownId}
        onSelection={(option) => onSettingsChange(category, { value: option.value })}
        options={options}
        size="medium"
        value={value} />
    );
  }

  renderEmailDigestRow() {
    const { settings } = this.props;
    const category = 'email_digest';
    const categoryData = _.get(settings, category + '[0]', {});
    categoryData.value = categoryData.value || 'real_time';
    const label = I18n.t('email_digest.description', { scope });

    return (
      <div className="email-digest-option alert-preference-option-row">
        <div className="alert-preference-checkbox-description">
          {this.renderCheckBox(category, (categoryData.enable || false), label)}
        </div>
        <div className="alert-preference-options">
          {this.renderEmailDigestDropdown(category, categoryData.value)}
        </div>
      </div>
    );
  }

  renderAlertEmailSettingRow() {
    const { settings, showAlertSetting } = this.props;
    const category = 'alert_email';
    const categoryData = _.get(settings, category + '[0]', {});

    if (!showAlertSetting) {
      return null;
    }
    const label = I18n.t('alert_email', { scope });
    return (
      <div className="alert-email alert-preference-single-option-row">
        <div className="alert-preference-options">
          {this.renderCheckBox(category, (categoryData.enable || false), label)}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="email-settings preference-table">
        <div className="email-settings alert-preference-description-row">
          <div className="preference-title">
            {I18n.t('table_header.email_settings', { scope })}
          </div>
        </div>
        {this.renderEmailDigestRow()}
        {this.renderAlertEmailSettingRow()}
      </div>
    );
  }
}

EmailSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func
};

export default EmailSettings;
