import { VECTOR_BASEMAP_STYLES } from '../constants';
import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';

export default {
  format: {
    type: 'visualization_interchange_format',
    version: VIF_CONSTANTS.LATEST_VERSION
  },
  configuration: {
    datasetMetadata: false,
    locateUser: false,
    panAndZoom: true,
    viewSourceDataLink: true,
    basemapOptions: {
      basemapStyle: VECTOR_BASEMAP_STYLES.basic.value,
      geoCoderControl: true,
      geoLocateControl: true,
      navigationControl: true
    }
  },
  description: '',
  series: [
    {
      color: {
        primary: '#eb6900'
      },
      dataSource: {
        datasetUid: null,
        dimension: {
          columnName: null,
          aggregationFunction: null
        },
        measure: {
          columnName: null,
          aggregationFunction: 'count'
        },
        type: 'socrata.soql',
        filters: []
      },
      label: null,
      type: 'map',
      visible: true,
      primary: true
    }
  ],
  title: ''
};
