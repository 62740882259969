import PropTypes from 'prop-types';


export default PropTypes.shape({
  haveOtherDatasetEditRights: PropTypes.bool,
  haveOtherDatasetRights: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  mapboxAccessToken: PropTypes.string,
  shouldShowMyAssetsApprovalsPreference: PropTypes.bool,
  showApprovalPreference: PropTypes.bool,
  showMyAlertPreference: PropTypes.bool,
  showMyAssetsTeamMessage: PropTypes.bool,
  showNewAssetPublishedPreference: PropTypes.bool,
  showUserNotifications: PropTypes.bool,
  showUserPreference: PropTypes.bool
});
