/* Imports */
/* ============================================================================= */
import { isFunction } from 'lodash';

import { WorkflowTargetAudience } from 'common/core/approvals_enums';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import {
  GuidanceBooleanCheckSummary,
  CheckMethod,
  CheckMethodsObject
} from 'common/core/approvals/Types';





/* Method */
/* ============================================================================= */
/**
 * Wrap the submitForApproval, submitChangeAudienceRequest, or submitUpdatePublishedAssetRequest methods in logic to determine which workflow to call, then run the method
 * @param guidance GuidanceSummaryV2 object
 * @param workflows GuidanceBooleanCheckSummary result object from a combined boolean check method
 * @param workflow WorkflowTargetAudience value for the specific workflow to run the method
 * @param methods WorkflowGuidancePromiseMethods object of methods to call if the provided workflow can be submitted
 * @param args (optional) Any additional arguments will be passed along to the invoked method
 * @returns Promise
 */
export const runWrappedSubmitActionMethod = (
  guidance: GuidanceSummaryV2,
  workflows: GuidanceBooleanCheckSummary,
  workflow: WorkflowTargetAudience,
  methods: CheckMethodsObject<Promise<Response>>,
  ...args: any[]
): Promise<Response> => {
  let response: Promise<Response>;

  // The workflows param is the result of calling the summarizeCanSubmitForApproval() method
  if (!workflow) {
    throw new Error('Submit action initiated without a specified workflow');
  } else if (workflows[workflow]) {
    if (isFunction(methods[workflow])) {
      const method = methods[workflow] as CheckMethod<Promise<Response>>;

      response = method(...args);
    } else {
      // Pretty sure this condition shouldn't ever be hit, so throw an error if we ever do
      throw new Error(
        'runWrappedSubmitActionMethod - ' +
        'Unknown error when processing submission action: ' +
        `Submission action not available for pending workflow "${workflow}"`
      );
    }
  } else {
    response = Promise.reject('Submission action not available for guidance workflows');
  }

  return response;
};

export default runWrappedSubmitActionMethod;
