import { reduce } from 'lodash';
import { BinaryTree, CompoundOp, UnAnalyzedAst } from 'common/types/soql';
import { castToBinaryTree } from '../binaryTree';
import { QueryParts, isQueryParts } from './build';

type MaybeCompoundQueryParts = BinaryTree<QueryParts> | QueryParts;
type CompoundQueryBuilder = (
  left: MaybeCompoundQueryParts,
  right: MaybeCompoundQueryParts
) => BinaryTree<QueryParts>;

const buildCompoundQuery = (
  op: CompoundOp,
  leftArg: MaybeCompoundQueryParts,
  rightArg: MaybeCompoundQueryParts
): BinaryTree<QueryParts> => ({
  type: 'compound',
  op,
  left: castToBinaryTree<QueryParts>(leftArg, isQueryParts),
  right: castToBinaryTree<QueryParts>(rightArg, isQueryParts)
});

const curryOperator = (op: CompoundOp): CompoundQueryBuilder => (
  left: MaybeCompoundQueryParts,
  right: MaybeCompoundQueryParts
): BinaryTree<QueryParts> => buildCompoundQuery(op, left, right);

export const connectQueries = (
  connector: CompoundQueryBuilder,
  queries: (BinaryTree<QueryParts> | QueryParts)[]
) => reduce(
  queries.slice(1),
  (nested, query) => connector(nested, query),
  castToBinaryTree<QueryParts>(queries[0], isQueryParts)
);

export default {
  union: curryOperator(CompoundOp.Union),
  unionAll: curryOperator(CompoundOp.UnionAll),
  intersect: curryOperator(CompoundOp.Intersect),
  minus: curryOperator(CompoundOp.Minus),
  chain: curryOperator(CompoundOp.QueryPipe),
  pipe: curryOperator(CompoundOp.QueryPipe)
};
