import React from 'react';
import _ from 'lodash';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import Actions from 'Actions';

const AssetSelectorInsertButton = () => {
  const style = {
    '--forge-icon-font-size': '48px',
    '--mdc-ripple-fg-size': '28px',
    '--mdc-ripple-fg-scale': '1.71429',
    '--mdc-ripple-top': '10px'
  };

  const className = 'forge-icon-button mdc-ripple-upgraded--unbounded mdc-ripple-upgraded';

  return (
    <ForgeIconButton data-action={Actions.ASSET_SELECTOR_SELECT_ASSET_FOR_COMPONENT}>
      <button
        type="button"
        aria-label={I18n.t('editor.components.asset_selector.select_btn')}
        className={className}
        style={style}
      >
        <ForgeIcon name="add" />
      </button>
    </ForgeIconButton>
  );
};

export default AssetSelectorInsertButton;
