import {
  chain,
  each,
  every,
  find,
  has,
  isEmpty,
  isNil,
  isNumber,
  isString,
  merge,
  sortBy
} from 'lodash';
import I18n from 'common/i18n';

import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import {
  COLOR_BY_BUCKETS_COUNT,
  COLOR_PALETTE_VALUES,
  DEFAULT_BOOLEAN_VALUE,
  DEFAULT_COLOR_PALETTE,
  DEFAULT_POINT_AND_LINE_COLOR,
  QUANTIFICATION_METHODS,
  RANGE_BUCKET_TYPES
} from 'common/authoring_workflow/constants';
import {
  categoriesToColorByBuckets,
  rangeToColorByBuckets,
  measuresToColorByBuckets,
  hasLabelChange,
  mergeCustomPaletteAndBucket
} from 'common/visualizations/helpers/BucketHelper';
import {
  formatDatasetValue,
  getColumnFormatWithPrecision
} from 'common/visualizations/helpers/ColumnFormattingHelpers';
import { DEFAULT_PRIMARY_COLOR } from '../views/SvgConstants';
import { ColorBucket, DataValue, VifMeasure } from '../vif';
import { DecoratedVif, getDecoratedVif } from '../views/map/vifDecorators/vifDecorator';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import { getCustomColorPaletteByColumnId } from 'common/visualizations/helpers/palettes/customPaletteHelpers';

const RESIZE_BY_MIN_ALIAS = '__resize_by_min__';
export const FLYOUT_COLUMN_AND_AGGREGATION_ALIAS = '__flyout_column_and_aggregation__';
const RESIZE_BY_AVG_ALIAS = '__resize_by_avg__';
const RESIZE_BY_MAX_ALIAS = '__resize_by_max__';
const COLOR_BY_CATEGORY_ALIAS = '__color_by_category__';
const COUNT_ALIAS = '__count__';

export default class RenderByHelper {
  /**
   * Based on the vif (type of colorBy option configured), it will return color by buckets based on
   *   - categories (for text columns and numerical columns with bucket type categorical)
   *   - ranges (for numerical columns with bucket type linear)
   *   - measure (for region map with computed columns)
   */
  static async getColorByBuckets(
    vif: DecoratedVif,
    colorByColumn: string | null = null,
    measures = null,
    metaDataPromise?: Promise<View>,
    useCategoriesFromVif = false
  ) {
    // metadata request has already been started and running in parallel with the above
    // async call, here we are just waiting for its results
    const datasetMetadata = await metaDataPromise;
    const buckets = await getBucketsWithDefaultPaletteColors(vif, colorByColumn, measures, datasetMetadata);
    const isLinearQuantificationMethod =
      vif.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value;
    return useCategoriesFromVif
      ? getBucketsWithConfiguredCustomPaletteColors({
          buckets,
          vif,
          colorByColumn: colorByColumn || vif.getMapColorGroupNameByColumn(),
          isColorByBooleanColumn: datasetMetadata ? vif.isColorByBooleanColumn(datasetMetadata) : false,
          isRegionMap: vif.isRegionMap(),
          isLinearQuantificationMethod
        })
      : buckets;
  }

  /**
   * Fetches the top x(COLOR_BY_BUCKETS_COUNT) values in colorByColumn based on row count.
   * This will be later used to create colorByBuckets or create stops(mapboxgl expressions)
   * to render points of different color in the map.
   */
  static async getColorByCategories(
    vif: DecoratedVif,
    colorByColumn: string,
    datasetSoqlDataProvider = vif.getDatasetSoqlDataProvider()
  ) {
    if (!isString(colorByColumn)) {
      return null;
    }
    const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);
    const escapedColorByColumn = SoqlHelpers.escapeColumnName(colorByColumn);
    let query = `SELECT ${escapedColorByColumn}||'' as ${COLOR_BY_CATEGORY_ALIAS},count(*) as ${COUNT_ALIAS} `;

    if (!isEmpty(filters)) {
      query += ` WHERE ${filters} `;
    }

    query += `GROUP BY ${escapedColorByColumn} ` + `ORDER BY ${COUNT_ALIAS} desc `;

    // Why '+ 1'? If the selected buckets count is 12.
    // And if there are 12 unique values in the dataset, we should not show 'others' bucket.
    // If it is more than 12, we need to show 'others' bucket.
    // So to figure out if the "unique values in the color by column" > "number of buckets", we fetch + 1 buckets.
    query += `LIMIT ${COLOR_BY_BUCKETS_COUNT + 1}`;

    const results = await datasetSoqlDataProvider.rawQuery(query);

    const categories = chain(results)
      .map((result) => (result[COLOR_BY_CATEGORY_ALIAS] === undefined ? '' : result[COLOR_BY_CATEGORY_ALIAS]))
      .value();

    return categories;
  }

  /**
   * Fetches min/max/avg of the colorByColumn values based on the filters in the vif.
   * This will be later used to create colorByBuckets or create stops(mapboxgl expressions)
   * to render points of different size in the map.
   */
  static async getResizeByRange(
    vif: DecoratedVif,
    resizeByColumn: string,
    datasetSoqlDataProvider = vif.getDatasetSoqlDataProvider()
  ) {
    if (!isString(resizeByColumn)) {
      return { min: 0, avg: 1, max: 1 };
    }

    const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);
    const escapedResizeByColumn = SoqlHelpers.escapeColumnName(resizeByColumn);
    let query =
      'SELECT ' +
      `min(${escapedResizeByColumn}) as ${RESIZE_BY_MIN_ALIAS},` +
      `avg(${escapedResizeByColumn}) as ${RESIZE_BY_AVG_ALIAS},` +
      `max(${escapedResizeByColumn}) as ${RESIZE_BY_MAX_ALIAS}`;

    if (!isEmpty(filters)) {
      query += ` WHERE ${filters}`;
    }

    const results = await datasetSoqlDataProvider.rawQuery(query);
    const min = Number(results[0][RESIZE_BY_MIN_ALIAS] || 0);
    const avg = Number(results[0][RESIZE_BY_AVG_ALIAS] || 1);
    const max = Number(results[0][RESIZE_BY_MAX_ALIAS] || 1);

    return { min: min, avg: avg, max: max };
  }

  /**
   * Fetches the measure values(from the computed column in another dataset) for every region.
   * This will be later used to create colorByBuckets or create stops(mapboxgl expressions)
   * to fill regions with different color based on their aggregate measure.
   */
  static async getMeasuresForRegions(vif: DecoratedVif) {
    const domain = vif.getDomain();
    const datasetUid = vif.getDatasetUid();
    const nameColumn = vif.getMeasureForeignKey();
    const valueColumn = vif.getMeasureColumn();
    const valueFunction = vif.getMeasureAggregation();
    const nameAlias = '__shape_id__';
    const valueAlias = '__value__';
    const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);
    const requiredVifParams = [domain, datasetUid, nameColumn, valueColumn, valueFunction];
    const datasetSoqlDataProvider = vif.getDatasetSoqlDataProvider();
    const escapedValueColumn = valueColumn === '*' ? valueColumn : SoqlHelpers.escapeColumnName(valueColumn);
    const selectColumns = [`${valueFunction}(${escapedValueColumn}) as ${valueAlias}`];
    const regionMapFlyoutColumnAndAggregations = vif.getRegionMapFlyoutColumnAndAggregations();

    if (!isNil(regionMapFlyoutColumnAndAggregations)) {
      each(regionMapFlyoutColumnAndAggregations, (columnAndAggregation, index) => {
        const { aggregation, column } = columnAndAggregation;
        const columnValue = isNil(column) ? '*' : SoqlHelpers.escapeColumnName(column);
        const aggregationFunction = isNil(aggregation) ? 'count' : aggregation;
        selectColumns.push(
          `${aggregationFunction}(${columnValue}) as ${FLYOUT_COLUMN_AND_AGGREGATION_ALIAS}${index}`
        );
      });
    }

    if (!every(requiredVifParams, isString)) {
      return null;
    }

    let queryString = `SELECT ${SoqlHelpers.escapeColumnName(
      nameColumn
    )} as ${nameAlias}, ${selectColumns.join(',')}`;

    if (!isEmpty(filters)) {
      queryString += ` WHERE ${filters}`;
    }
    queryString += ` GROUP BY ${SoqlHelpers.escapeColumnName(nameColumn)} LIMIT 10000`;

    const measureResult = await datasetSoqlDataProvider.rawQuery(queryString);

    const measures = chain(measureResult)
      .map((measureResultItem) => {
        const shapeId = measureResultItem[nameAlias];
        const measureValue = measureResultItem[valueAlias];

        if (shapeId === undefined) {
          return shapeId;
        }
        return merge(
          {
            shapeId,
            value: isNil(measureValue) || measureValue === '' ? null : Number(measureValue)
          },
          measureResultItem
        );
      })
      .compact()
      .value();

    if (isEmpty(measures)) {
      return null;
    }

    return measures as VifMeasure[];
  }
}

async function getBucketsWithDefaultPaletteColors(
  vif: DecoratedVif,
  colorByColumn: string | null,
  measures: VifMeasure[] | null,
  datasetMetadata: View | undefined
) {
  const domain = vif.getDomain();
  const datasetUid = vif.getDatasetUid();
  const legendPrecision = vif.getMapLegendPrecision();
  const midpoint = vif.getMidpoint();
  if (isNil(datasetMetadata)) {
    return [];
  }

  const getColor = (index: number, colors: string[]) => {
    if (colors && index < colors.length) {
      return colors[index];
    } else if (index < COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE].length) {
      return COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE][index];
    } else {
      return DEFAULT_PRIMARY_COLOR;
    }
  };
  const bucketsCount = vif.getColorByBucketsCount();

  if (vif.isRegionMap()) {
    if (isNil(measures)) {
      measures = await RenderByHelper.getMeasuresForRegions(vif);
    }

    if (isEmpty(measures)) {
      return [];
    }
    const colors = vif.getColorPalette(bucketsCount);
    const measureColumn = vif.getMeasureColumn();
    const isEqualIntervalBucketType = vif.getRangeBucketType() === RANGE_BUCKET_TYPES.equalInterval.value;
    const measureColumnDetails = find(datasetMetadata.columns, ['fieldName', measureColumn]);

    return measuresToColorByBuckets({
      bucketsCount,
      isEqualIntervalBucketType,
      getColor: (index: number) => getColor(index, colors),
      legendPrecision,
      measures,
      measureColumnDetails,
      midpoint,
      valueToLabelFormatter: (value: DataValue) => {
        return valueToLabelFormatter({
          value,
          columnDetails: measureColumnDetails,
          domain,
          datasetUid,
          legendPrecision
        });
      }
    });
  }

  if (colorByColumn === null) {
    return null;
  }

  const colorByColumnDetails = find(datasetMetadata.columns, ['fieldName', colorByColumn]);

  if (vif.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value) {
    const colorByRange = await RenderByHelper.getResizeByRange(vif, colorByColumn);
    const colors = vif.getColorPalette(bucketsCount);

    return rangeToColorByBuckets({
      bucketsCount: vif.getColorByBucketsCount(),
      colorByRange,
      getColor: (index: number) => getColor(index, colors),
      includeNullBucket: (colorByColumnDetails?.cachedContents?.null || -1) > 0,
      legendPrecision,
      midpoint,
      nullBucketColor: DEFAULT_POINT_AND_LINE_COLOR,
      valueToLabelFormatter: (value: DataValue) => {
        return valueToLabelFormatter({
          value,
          columnDetails: colorByColumnDetails,
          domain,
          datasetUid,
          legendPrecision
        });
      }
    });
  } else {
    const colorByCategories = await RenderByHelper.getColorByCategories(vif, colorByColumn);
    const colors = vif.getColorPalette(COLOR_BY_BUCKETS_COUNT + 1);
    const isCastNullAsFalseInSeries = vif.getCastNullAsFalseInSeries();

    return categoriesToColorByBuckets({
      bucketsCount: COLOR_BY_BUCKETS_COUNT,
      colorByCategories,
      colorByColumnDetails,
      getColor: (index: number) => getColor(index, colors),
      isCastNullAsFalseInSeries,
      valueToLabelFormatter: (value: DataValue) => {
        return valueToLabelFormatter({
          value,
          columnDetails: colorByColumnDetails,
          domain,
          datasetUid,
          legendPrecision
        });
      }
    });
  }
}

interface GetBucketsWithConfiguredCustomPaletteColorsOptions {
  buckets: ColorBucket[];
  vif: DecoratedVif;
  colorByColumn: string | null;
  isColorByBooleanColumn: boolean;
  isRegionMap?: boolean;
  isLinearQuantificationMethod?: boolean;
}

export function getBucketsWithConfiguredCustomPaletteColors({
  buckets,
  vif,
  colorByColumn,
  isColorByBooleanColumn,
  isRegionMap = false,
  isLinearQuantificationMethod = false
}: GetBucketsWithConfiguredCustomPaletteColorsOptions) {
  const customPalette = vif.series[0]?.color?.customPalette;
  if (customPalette === undefined || colorByColumn === null || colorByColumn === undefined) {
    return;
  }

  const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');
  const decoratedVif = getDecoratedVif(vif).cloneWithSingleSeries(0);
  const currentCustomPalette = getCustomColorPaletteByColumnId(decoratedVif, customPalette, colorByColumn);
  const hasLabelChanged = hasLabelChange(currentCustomPalette, buckets);

  /**
   * We sort the buckets based on start value in order to properly match
   * buckets to their colors when bucket start and end change. See
   * "mergeCustomPaletteAndBucket" for more details.
   */
  const sortedBuckets = sortBy(buckets, 'start');

  if (currentCustomPalette !== undefined) {
    return chain(sortedBuckets)
      .cloneDeep()
      .map((bucket, index) => {
        const { label, id } = bucket;
        if (isRegionMap || isLinearQuantificationMethod) {
          return mergeCustomPaletteAndBucket({
            customColorPalette: currentCustomPalette,
            bucket,
            hasLabelChanged,
            bucketIndex: index
          });
        }

        if (isColorByBooleanColumn && isEmpty(id)) {
          const nullAsFalseLabel = currentCustomPalette[noValueLabel] ? noValueLabel : DEFAULT_BOOLEAN_VALUE;
          return merge({}, currentCustomPalette[nullAsFalseLabel], { id, label });
        }

        return has(currentCustomPalette, label)
          ? merge({}, currentCustomPalette[label], { label, id })
          : merge(bucket, { color: COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE][index] });
      })
      .value();
  }
}

interface ValueToLabelFormatterOptions {
  value: DataValue;
  columnDetails?: ViewColumn;
  domain: string;
  datasetUid: string;
  legendPrecision?: number;
}
export function valueToLabelFormatter({
  value,
  columnDetails,
  domain,
  datasetUid,
  legendPrecision
}: ValueToLabelFormatterOptions) {
  const isCheckbox = columnDetails?.renderTypeName === 'checkbox';

  if (!isNumber(value) && isEmpty(value)) {
    return I18n.t('shared.visualizations.charts.common.no_value');
  } else if (isCheckbox) {
    return value;
  } else {
    const columnFormatWithPrecision = getColumnFormatWithPrecision(columnDetails, legendPrecision);

    return formatDatasetValue(value, columnFormatWithPrecision, domain, datasetUid);
  }
}
