import React from 'react';
import { ForgeTab, ForgeTabBar } from '@tylertech/forge-react';
import { ITabBarActivateEventData } from '@tylertech/forge';

import { Hierarchy } from 'common/visualizations/vif';
import {
  getHierarchyHeaderText,
  shortenHeaderText
} from 'common/authoring_workflow/components/panes/DataPane/TableHierarchyGroupings/components/TableHierarchyGroupingHeader';
import { ViewColumn } from 'common/types/viewColumn';
import './index.scss';

export interface HierarchyTabsProps {
  activeHierarchyId?: string;
  onTabChange: (newTabId: string) => void;
  hierarchies: Hierarchy[];
  columns: ViewColumn[];
}

const DISPLAY_HIERARCHY_TABS_THRESHOLD = 2;

const HierarchyTabs = (props: HierarchyTabsProps): JSX.Element => {
  const { hierarchies, activeHierarchyId, onTabChange, columns } = props;

  if (hierarchies.length < DISPLAY_HIERARCHY_TABS_THRESHOLD) {
    return <div data-testid="empty-hierarchy"></div>;
  }

  const onTabClick = (index: number) => {
    const newActiveHierarcyId: string = hierarchies[index].id;
    onTabChange(newActiveHierarcyId);
  };

  const activeHierarchyIndex = hierarchies.findIndex((h: Hierarchy) => h.id === activeHierarchyId);

  return (
    <div className="hierarchy-tab-container">
      <ForgeTabBar
        underline
        layoutMode="clustered"
        activeTab={activeHierarchyIndex}
        on-forge-tab-bar-activate={(event: CustomEvent<ITabBarActivateEventData>) =>
          onTabClick(event.detail.index)
        }
      >
        {hierarchies.map((hierarchy, index) => {
          const groupingColumns = (hierarchy.columnConfigurations ?? []).filter((h) => h.isGrouping);
          const name = hierarchy.name || getHierarchyHeaderText(groupingColumns, columns);
          const shortName = shortenHeaderText(name);
          return (
            <ForgeTab
              active={hierarchy.id === activeHierarchyId}
              data-testid={`hierarchy-tab-${index}`}
              key={index}
            >
              {shortName}
            </ForgeTab>
          );
        })}
      </ForgeTabBar>
    </div>
  );
};

export default HierarchyTabs;
