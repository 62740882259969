import { compact } from 'lodash';
import { TableName } from 'common/types/soql';

/**
 * Describe the dataset from which this query is fetching.
 * @param name The four-four identifier of the dataset.
 * @param alias The qualifier you'll be referring to it as.
 */
export const buildTableName = (name: string, alias: string): TableName => ({ name, alias });

export const renderTableName = (tableName: TableName): string => {
  const { alias, name } = tableName;
  return compact([`@${name}`, alias]).join(' as ');
};
