/* Imports */
/* ============================================================================= */
import { defaultHeaders } from 'common/http/index';
import { WorkflowGuidance } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Withdraw (a.k.a. cancelWorkflowSubmission) an approval request for an asset.
 * This will remove it from the approvals queue.
 *
 * @param guidance WorkflowGuidance object
 * @returns A Promise object
 */
export const withdrawAssetV2 = (guidance: WorkflowGuidance) => {
  if (!guidance.withdrawUrl)
    return Promise.reject('withdrawUrl not found in WorkflowGuidance');

  const apiPath = guidance.withdrawUrl;
  const options: RequestInit = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'DELETE'
  };

  return fetch(apiPath, options);
};

export default withdrawAssetV2;
