// Vendor Imports
import classNames from 'classnames';
import React, { FunctionComponent, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

// Project Imports
import SocrataIcon, { IconName } from '../SocrataIcon';
import {
  COMMON_DATE_FORMATS,
  formatToInclusiveSoqlDateRange,
  formatToSoqlDate,
  RELATIVE_FILTERS
} from 'common/dates';
import I18n from 'common/i18n';
import getLocale from 'common/js_utils/getLocale';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

// Constants
const scope = 'shared.components.date_picker';

interface RDPType extends React.ReactElement {
  setOpen: (val: boolean) => void;
  cancelFocusInput: () => void;
}

interface Props {
  /**
  * Contains two values:
  * start: default value used as startDate
  * end: default value used as endDate
  */
  value: {
    start?: string;
    end?: string;
  };

  /**
  * The onChange handler is fired when a date is selected in the calendar
  */
  onChange: (value1: any, value2: any) => void;

  /**
   * Replace the date pickers with spinners and disabled the input
   */
  loading?: boolean;

  /**
   * Prop overriders for react-datepicker
   */
  datePickerOverrides?: any;
  showTodayButton?: boolean;
  showYesterdayButton?: boolean;
}

const DateRangePicker: FunctionComponent<Props> = ({
  value,
  onChange,
  datePickerOverrides,
  showTodayButton,
  showYesterdayButton,
  loading
}) => {
  const locale = getLocale(window);
  const startPickerRef = useRef<RDPType>(null);
  const endPickerRef = useRef<RDPType>(null);
  const spinner = <div className="loading-spinner"><span className="spinner-default"></span></div>;

  const onChangeStartDate = (date: string) => {
    const formattedDateRange = formatToInclusiveSoqlDateRange({
      start: date || value.start,
      end: value.end
    });

    onChange(formattedDateRange, { isStartChanged: true });
  };

  const onChangeEndDate = (date: string) => {
    const formattedDateRange = formatToInclusiveSoqlDateRange({
      start: value.start,
      end: date || value.end
    });

    onChange(formattedDateRange, { isStartChanged: false });
  };

  const onClickRelativeDateButton = (event: any, isStart: boolean, relativeDate: any) => {
    const datePicker = isStart ? startPickerRef : endPickerRef;

    event.stopPropagation();
    event.preventDefault();

    const formattedDateRange = {
      start: isStart ? relativeDate : formatToSoqlDate(value.start),
      end: isStart ? formatToSoqlDate(value.end) : relativeDate
    };

    onChange(formattedDateRange, { isStartChanged: isStart });

    datePicker.current?.setOpen(false);
    datePicker.current?.cancelFocusInput();
  };

  const renderRelativeDateButtons = (isStart: boolean) => {
    if (!showYesterdayButton && !showTodayButton) {
      return;
    }

    const yesterdayButtonProps = {
      className: classNames('btn btn-yesterday', {
        'btn-left': showYesterdayButton && showTodayButton
      }),
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => onClickRelativeDateButton(event, isStart, RELATIVE_FILTERS.YESTERDAY)
    };

    const yesterdayButton = showYesterdayButton ? (
      <button {...yesterdayButtonProps}>{I18n.t('yesterday', { scope })}</button>
    ) : null;

    const todayButtonProps = {
      className: classNames('btn btn-today', {
        'btn-right': showYesterdayButton && showTodayButton
      }),
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => onClickRelativeDateButton(event, isStart, RELATIVE_FILTERS.TODAY)
    };

    const todayButton = showTodayButton ? (
      <button {...todayButtonProps}>{I18n.t('today', { scope })}</button>
    ) : null;

    return (
      <div className="relative-date-buttons-container">
        {yesterdayButton}
        {todayButton}
      </div>
    );
  };

  const renderDatePickerStart = (startDate: Date | undefined, endDate: Date | undefined) => {
    const todayButton = renderRelativeDateButtons(true);
    const datePickerProps = Object.assign({
      ariaLabelledBy: 'start-date-input',
      className: 'text-input date-picker-input start',
      dateFormat: COMMON_DATE_FORMATS,
      dateFormatCalendar: 'LLLL yyyy',
      disabled: loading,
      endDate,
      fixedHeight: true,
      locale: locale,
      onChange: onChangeStartDate,
      placeholderText: loading ? I18n.t('loading', { scope }) : I18n.t('placeholder', { scope }),
      ref: startPickerRef,
      selected: startDate,
      selectsStart: true,
      startDate,
      todayButton
    }, datePickerOverrides);

    return (
      <div className="date-range-picker-start">
        { !loading ? <SocrataIcon name={IconName.Date} /> : spinner }
        <ReactDatePicker {...datePickerProps} />
        <label id="start-date-input" className="screenreader-only">{I18n.t('start_date_selector', { scope })}</label>
      </div>
    );
  };

  const renderDatePickerEnd = (startDate: Date | undefined, endDate: Date | undefined) => {
    const todayButton = renderRelativeDateButtons(false);
    const datePickerProps = Object.assign({
      ariaLabelledBy: 'end-date-input',
      className: 'text-input date-picker-input end',
      dateFormat: COMMON_DATE_FORMATS,
      dateFormatCalendar: 'LLLL yyyy',
      disabled: loading,
      endDate,
      fixedHeight: true,
      locale: locale,
      onChange: onChangeEndDate,
      placeholderText: loading ? I18n.t('loading', { scope }) : I18n.t('placeholder', { scope }),
      ref: endPickerRef,
      selected: endDate,
      selectsEnd: true,
      startDate,
      todayButton
    }, datePickerOverrides);

    return (
      <div className="date-range-picker-end">
        { !loading ? <SocrataIcon name={IconName.Date} /> : spinner }
        <ReactDatePicker {...datePickerProps} />
        <label id="end-date-input" className="screenreader-only">{I18n.t('end_date_selector', { scope })}</label>
      </div>
    );
  };

  // The third party library no longer requires moment objects as of v2
  const startDate = value.start ? moment(value.start).toDate() : undefined;
  const endDate = value.end ? moment(value.end).toDate() : undefined;

  return (
    <div className="date-range-picker">
      {renderDatePickerStart(startDate, endDate)}
      <span className="range-separator">-</span>
      {renderDatePickerEnd(startDate, endDate)}
    </div>
  );
};

DateRangePicker.defaultProps = {
  datePickerOverrides: {},
  loading: false
};

export default DateRangePicker;
