// EN-44386: remove this component (and CSS) after IE 11 is not supported
import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import DismissibleSessionBanner, { BannerType } from 'common/components/DismissibleSessionBanner';

import './ie-deprecation-banner.scss';

const IEDeprecationBanner: FunctionComponent = () => {
  if (!usingInternetExplorer) {
    return null;
  }

  return (
    <DismissibleSessionBanner
      sessionStorageKey={'ie-deprecation-dismissed'}
      alertWhenDismissed={false}
      bannerType={BannerType.Warning}
    >
      <div id="ie-deprecation-banner-msg">
        {translate('notice_for_ie_users')}
        <strong>{translate('socrata_ending_support_for_ie')}</strong>
        {translate('for_more_info')}
        <a href="https://support.socrata.com/hc/en-us/articles/1500002591901" target="_blank">
          {translate('support_site')}
        </a>
        {'.' /* just a lonely period */}
      </div>
    </DismissibleSessionBanner>
  );
};

const translate = (key: string) =>
  I18n.t(key, { scope: 'shared.components.internet_explorer_deprecation_banner' });
const usingInternetExplorer = navigator.userAgent.match(/(MSIE|Trident)/g);

export default IEDeprecationBanner;
