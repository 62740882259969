import { extend } from 'jquery';
import _ from 'lodash';
import I18nJS from 'i18n-js';
import pluralization from './pluralization';
import getLocale from 'common/js_utils/getLocale';
import airbrake from 'common/airbrake';

/**
 * Usage:
 *
 * - Simple:
 *
 *    I18n.t('path.to.translation_key')
 *
 * - Translation with a variable
 *
 *  Within the `en.yml`
 *    sample_key: Success! New variable "%{variableKey}" passed.
 *
 *    i18n.t('some_scope.sample_key', {variableKey: someVariable})
 *
 */

// See also platform-ui/frontend/public/javascripts/screens/all-screens.js
// See also platform-ui/frontend/public/javascripts/screens/site-appearance.js
// See also platform-ui/common/js_utils/getLocale.js
I18nJS.locale = getLocale(window);
I18nJS.pluralization[I18nJS.locale] = pluralization(I18nJS.locale);
// Fall back to English. By default Rails will do this for us, but Javascript-only chart embeds
// don't have access to Rails translation logic and must do this themselves.
// Note that falling back is not purely a safety net - localeapp will sometimes give us a null translation
// (likely to signify "this is the same as English"). So it's important to fall back like Rails does.
I18nJS.fallbacks = true;

if (!_.isUndefined(window.blistTranslations)) {
  window.blist = window.blist || {};
  window.blist.translations = window.blist.translations || {};
  _.each(window.blistTranslations, function (translation) {
    extend(true, window.blist.translations, translation());
  });
}

const translations = _.merge(
  {},
  window.translations,
  _.get(window, 'blist.translations'),
  window.TRANSLATIONS // storyteller translations
);

const overwrittenKeys = _.reject(
  _.intersection(_.keys(I18nJS.translations[I18nJS.locale]), _.keys(translations)),
  (sharedKey) => {
    return _.isEqual(I18nJS.translations[I18nJS.locale][sharedKey], translations[sharedKey]);
  }
);

if (!_.isEmpty(overwrittenKeys)) {
  console.warn(`Keys being overwritten: ${overwrittenKeys.join(', ')}`);
}

I18nJS.translations[I18nJS.locale] = _.assign({}, I18nJS.translations[I18nJS.locale], translations);

let airbrakeMissingTranslationWarningEnabled = true;
export const disableMissingTranslationWarning = () => {
  airbrakeMissingTranslationWarningEnabled = false;
};

I18nJS.missingTranslation = (key, context) => {
  if (airbrakeMissingTranslationWarningEnabled) {
    const scope = _.get(context, 'scope');
    const errorMsg = `Missing translation for key ${key}${scope ? ' in scope ' + scope : ''}.`;
    try {
      airbrake.notify({
        error: errorMsg,
        context: { component: 'I18n' }
      });
    } finally {
      console.error(errorMsg);
    }
    const locale = _.get(context, 'locale', I18nJS.currentLocale());
    return `[missing "${locale}.${scope ? scope + '.' : ''}${key}" translation]`;
  }
};

// This is equivalent to View.category_display(category) in Frontend.
export function categoryDisplay(category) {
  return _.get(window.socrata.categoryTranslations, category);
}

export function loadTestTranslations(translations) {
  _.assign(I18nJS.translations, {
    [I18nJS.locale]: translations
  });
}

export function addTestTranslations(translations) {
  _.merge(I18nJS.translations[I18nJS.locale], translations);
}

export default I18nJS;
