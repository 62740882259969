import isObject from 'lodash/isObject';

import sift from './sift';

// See also platform-ui/frontend/public/javascripts/screens/all-screens.js
// See also platform-ui/frontend/public/javascripts/screens/site-appearance.js

// Takes a global state bag – typically 'window' – and tries to find the current locale in several key paths
// listed according to precedence, finally defaulting to 'en' when no locale can be found.
export default (global) => {
  let locale = 'en';

  if (isObject(global)) {
    locale = sift(
      global,
      'blist.locale',
      'socrata.locale',
      'serverConfig.locale',
      'socrataConfig.locales.currentLocale'
    ) || 'en';
  }

  return locale;
};
