/* Imports */
/* ============================================================================= */
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { GuidanceCheckSummary } from 'common/core/approvals/Types';
import { getMatchingWorkflows } from '.';





/* Method */
/* ============================================================================= */
/**
 * Check that a GuidanceCheckSummary object has EXACTLY one matching workflow value, and return the corresponding WorkflowTargetAudience for that workflow.
 * Throws an error if there is not EXACTLY 1 workflow with a matching value.
 * @param workflows A GuidanceCheckSummary object
 * @param matchBy (Optional, Default: (setValue) => !!setValue) Method to use for value matching
 * @returns The corresponding WorkflowTargetAudience for which matchBy returned true.
 */
export const getOnlyMatchingWorkflow = (
  workflows: GuidanceCheckSummary<any>,
  matchBy?: (setValue: any) => boolean
): WorkflowTargetAudience => {
  const matches = getMatchingWorkflows(workflows, matchBy);
  let response: WorkflowTargetAudience;

  // Error out if the number of matching workflows is not exactly one.
  if (matches.length !== 1) {
    throw new Error(`${matches.length ? 'Multiple' : 'No'} matching workflows were found. Expected exactly one.`);
  } else {
    response = matches[0];
  }

  return response;
};

export default getOnlyMatchingWorkflow;
