import { DisplayType, View, ViewFlag } from 'common/types/view';
import { ViewsNeedingDeletionResponse } from 'common/views/delete_helpers';
import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import Spinner from 'common/components/Spinner';
import { assetIsDraft } from 'common/views/helpers';

import { translationScope } from './';
import ViewsBlockingDeletionInfo from './ViewsBlockingDeletionInfo';

export interface DeleteAssetModalContentProps {
  /** The view being deleted */
  view: View;

  /** Whether or not we're waiting for a request to finish */
  busy: boolean;

  /** Whether or not something went wrong, either when fetching data or when trying to delete */
  hasError: boolean;

  /** The revision that we're trying to delete; undefined if not looking at a revision */
  revisionSeq?: number;

  /** Whether or not we're trying to delete a story draft */
  assetIsStoryDraft?: boolean;

  /** The number of assets that are based off of this asset. This will only exist for datasets, and comes from the catalog. */
  derivedViewCount?: number;

  /** Response from check to see if there are any views that are blocking deletion. */
  viewsBlockingDeletion?: ViewsNeedingDeletionResponse;
}

/** Extra details to add to the description in some circumstances */
export const SubDescription: FunctionComponent<
  Pick<DeleteAssetModalContentProps, 'view' | 'derivedViewCount'>
> = ({ view: { displayType, flags }, derivedViewCount }) => {
  // if the asset has derived views, we show a message letting the user know
  // that those assets will _also_ be deleted
  if (derivedViewCount !== undefined && derivedViewCount > 0) {
    return (
      <div className="sub-description">
        {I18n.t(`${translationScope}.description_related_assets`, { count: derivedViewCount })}
      </div>
    );
  }

  // if the asset is a *non-default* chart or a map, we let them know that the asset it
  // is based on will NOT be deleted
  const isDefault = flags.includes(ViewFlag.Default);
  if (!isDefault && (displayType === DisplayType.Chart || displayType === DisplayType.Map)) {
    return (
      <div className="sub-description">
        {I18n.t(`${translationScope}.description_chart_map`, {
          assetType: I18n.t(`${translationScope}.${displayType}`)
        })}
      </div>
    );
  }

  return null;
};

/** Renders the main content of the delete asset modal. */
const DeleteAssetModalContent: FunctionComponent<DeleteAssetModalContentProps> = ({
  view,
  busy,
  hasError,
  revisionSeq,
  assetIsStoryDraft,
  derivedViewCount,
  viewsBlockingDeletion
}) => {
  if (busy) {
    return <Spinner />;
  }

  if (hasError) {
    return <div className="alert error">{I18n.t(`${translationScope}.error_loading`)}</div>;
  }

  if (viewsBlockingDeletion?.hasViewsBlockingDeletion) {
    return <ViewsBlockingDeletionInfo viewId={view.id} response={viewsBlockingDeletion} />;
  }

  const isDraft = assetIsDraft({
    coreView: view,
    isRevision: revisionSeq !== undefined,
    isDraftStory: assetIsStoryDraft
  });

  return (
    <>
      <div className="description">
        {I18n.t(`${translationScope}.${isDraft ? 'description_draft' : 'description'}`, {
          name: view.name
        })}
      </div>
      <SubDescription view={view} derivedViewCount={derivedViewCount} />
    </>
  );
};

export default DeleteAssetModalContent;
