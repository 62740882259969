import { SoQLBooleanLiteral, SoQLNullLiteral, SoQLNumberLiteral, SoQLStringLiteral, TypedSoQLBooleanLiteral, TypedSoQLNullLiteral, TypedSoQLNumberLiteral, TypedSoQLStringLiteral, SoQLType } from 'common/types/soql';
import { ChangeEvent, default as React } from 'react';
import { Option, some, none } from 'ts-option';
import { AstNode, ExprProps } from '../VisualExpressionEditor';
import * as _ from 'lodash';
import cx from 'classnames';
import I18n from 'common/i18n';
import { ForgeTextField, ForgeSelect, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';
import { ForgeButton } from '@tylertech/forge-react';
import { whichAnalyzer } from 'common/explore_grid/lib/feature-flag-helpers';

interface StatefulEditProps { className?: string; label: string; value: string; onChange: (n: string) => void }
interface StatefulEditState { tempValue: Option<string> }

interface BooleanEditProps { className?: string; label?: string; value: boolean; onChange: (n: string) => void }
interface BooleanEditState { tempValue: Option<string> }

export const renderKebabButton = (showKebab?: boolean) => (showKebab &&
  <ForgeIconButton>
    <button
      type="button"
      data-testid="edit-literal-kebab-btn"
      aria-label={I18n.t('shared.explore_grid.vee_kebab_menu.button_label')}
      className="tyler-icons">
      <ForgeIcon name="more_vert" />
    </button>
  </ForgeIconButton>
);

export class StatefulEdit extends React.Component<StatefulEditProps, StatefulEditState> {
  state: StatefulEditState = { tempValue: none };

  done = () => {
    this.props.onChange(this.state.tempValue.getOrElseValue(this.props.value));
  };
  render = () => (
    <ForgeTextField>
      <input
        type="text"
        aria-label={this.props.label}
        className={cx('text-input', this.props.className)}
        onBlur={this.done}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' ? this.done() : null}
        onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ tempValue: some(e.currentTarget.value) })}
        value={this.state.tempValue.getOrElseValue(this.props.value)} />
    </ForgeTextField>
  );
}

export class BooleanEdit extends React.Component<BooleanEditProps, BooleanEditState> {
  state: BooleanEditState = { tempValue: none };

  done = (selectionValue: any) => {
    this.props.onChange(this.state.tempValue.getOrElseValue(selectionValue));
  };

  AggregationAttributes = {
    value: this.state.tempValue.getOrElseValue(this.props.value.toString()),
    options: [{ label: I18n.t('shared.explore_grid.type_display_names.true'), value: 'true' }, { label: I18n.t('shared.explore_grid.type_display_names.false'), value: 'false' }],
    onChange: (selection: { detail: string; }) => this.done(selection.detail),
    className: 'boolean-dropdown'
  };

  render = () => (
    <ForgeSelect {...this.AggregationAttributes} />
  );
}

export function EditStringLiteral(props: ExprProps<SoQLStringLiteral, TypedSoQLStringLiteral>) {
  const { update } = props;
  const expr = props.eexpr.fold(eexpr => eexpr.untyped, eexpr => eexpr.expr);
  const onChange = (value: string) => {
    update(whichAnalyzer(
      baseStringLiteral => (baseStringLiteral),
      baseStringLiteral => ({...baseStringLiteral, soql_type: SoQLType.SoQLTextT })
    )({ ...expr, value}));
  };
  const showRemove = props.showRemove !== undefined && props.showRemove;
  const showSuccess = props.forceShowSuccess !== undefined && props.forceShowSuccess && props.querySucceeded;
  return (
    <AstNode {...props} className="literal" removable={showRemove} changeableElement={props.showKebab ? 1 : undefined} showSuccess={showSuccess}>
      <StatefulEdit value={expr.value} onChange={onChange} label={I18n.t('shared.explore_grid.literals.edit_string')} />
      {renderKebabButton(props.showKebab)}
    </AstNode>
  );
}

export function EditNumberLiteral(props: ExprProps<SoQLNumberLiteral, TypedSoQLNumberLiteral>) {
  const { update } = props;
  const expr = props.eexpr.fold(eexpr => eexpr.untyped, eexpr => eexpr.expr);
  const onChange = (value: string) => {
    const val: number = _.toNumber(value);
    if (_.isNaN(val)) {
      // if this fails to compile, then it fails to compile and will show an indicator
      update(whichAnalyzer(
        baseStringLiteral => (baseStringLiteral),
        baseStringLiteral => ({...baseStringLiteral, soql_type: SoQLType.SoQLTextT })
      )({ type: 'string_literal', value}));
    } else {
      update(whichAnalyzer(
        baseNumLiteral => (baseNumLiteral),
        baseNumLiteral => ({...baseNumLiteral, soql_type: SoQLType.SoQLNumberT })
      )({ expr, value}));
    }
  };
  const showRemove = props.showRemove !== undefined && props.showRemove;
  const showSuccess = props.forceShowSuccess !== undefined && props.forceShowSuccess && props.querySucceeded;
  return (
    <AstNode {...props} className="literal" removable={showRemove} changeableElement={props.showKebab ? 1 : undefined} showSuccess={showSuccess}>
      <StatefulEdit value={expr.value} onChange={onChange} label={I18n.t('shared.explore_grid.literals.edit_number')} />
      {renderKebabButton(props.showKebab)}
    </AstNode>
  );
}

export function EditBooleanLiteral(props: ExprProps<SoQLBooleanLiteral, TypedSoQLBooleanLiteral>) {
  const { update } = props;
  const expr = props.eexpr.fold(eexpr => eexpr.untyped, eexpr => eexpr.expr);
  const onChange = (value: string) => {
    const boolValue = value === 'true';
    update(whichAnalyzer(
      booleanExpr => booleanExpr,
      booleanExpr => ({ ...booleanExpr, soql_type: SoQLType.SoQLBooleanT })
    )({ expr, value: boolValue }));
  };
  const showRemove = props.showRemove !== undefined && props.showRemove;
  const showSuccess = props.forceShowSuccess !== undefined && props.forceShowSuccess && props.querySucceeded;
  return (
    <AstNode {...props} className="literal" removable={showRemove} changeableElement={props.showKebab ? 1 : undefined} showSuccess={showSuccess}>
      <BooleanEdit value={expr.value} onChange={onChange} />
      {renderKebabButton(props.showKebab)}
    </AstNode>
  );
}

export function EditEmptyNode(props: ExprProps<null | SoQLNullLiteral, null | TypedSoQLNullLiteral>) {
  return (
    <AstNode {...props} className="literal" changeableElement={0} removable={false}>
      <ForgeButton type="outlined">
        <button
          type="button"
          data-testid="edit-null-literal-btn"
        >
          {I18n.t('shared.explore_grid.vee_kebab_menu.select')}
        </button>
      </ForgeButton>
    </AstNode>
  );
}
