import $ from 'jquery';
import _ from 'lodash';

import Store from 'Store';
import Dispatcher from '/Dispatcher';
import {
  getAllWindowSizeClasses,
  getWindowSizeClass,
  getUnusedWindowSizeClasses,
  handleResize
} from './WindowSizeBreakpointStoreHelper';

export type WindowSizeClasses = {
  xlarge: boolean;
  large: boolean;
  medium: boolean;
  small: boolean;
};

export default class WindowSizeBreakpointStore extends Store {
  private lastWindowSizeClasses: WindowSizeClasses;

  constructor(dispatcher?: Dispatcher<any>) {
    super(dispatcher);

    $(window).on(
      'resize',
      _.debounce((e) => {
        this.lastWindowSizeClasses = handleResize(this.lastWindowSizeClasses, e);
        this._emitChange();
      }, 1)
    );
    $(window).trigger('resize');
  }

  getAllWindowSizeClasses = () => {
    return getAllWindowSizeClasses(this.lastWindowSizeClasses);
  };

  getWindowSizeClass = () => {
    return getWindowSizeClass(this.lastWindowSizeClasses);
  };

  getUnusedWindowSizeClasses = () => {
    return getUnusedWindowSizeClasses(this.lastWindowSizeClasses);
  };
}

export const windowSizeBreakpointStore = new WindowSizeBreakpointStore();
