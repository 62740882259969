import React, { createContext, Dispatch, ReactNode, useContext, useMemo, useReducer } from 'react';
import { AnyAction } from 'redux';

import I18n from 'common/i18n';

import reducer, { initialState, ReducerState } from './reducer';

interface WatchContextI {
  state: ReducerState;
  dispatch: Dispatch<AnyAction>;
  t: (k: string, params?: any) => string;
}

export const WatchContext = createContext<WatchContextI>({
  state: initialState,
  dispatch: () => initialState,
  t
});

interface ChildProps {
  children: ReactNode;
}

export const WatchProvider = ({ children }: ChildProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch, t }), [state]);
  return <WatchContext.Provider value={value}>{children}</WatchContext.Provider>;
};

// Helpers
function t(k: string, params: any = {}) {
  return I18n.t(k, { ...params, scope: 'shared.components.asset_action_bar.watch_asset' });
}

export const useWatchContext = () => {
  const context = useContext(WatchContext);
  if (!context) {
    throw new Error('useWatchContext must be used within a WatchProvider');
  }

  return context;
};
