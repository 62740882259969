// The platform pattern for modals places the modal DOM *within* the component rendering the
// modal. This can be problematic for z-index and event bubbling reasons, but is catastrophic
// if the component rendering the modal is ephemeral (like a dropdown), since the modal will
// be removed from the DOM if the enclosing component is removed. This happens in
// AssetActionBar, so we place its modals in a separate node that lives directly under <body>.

import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

export function openModal<ModalProps>(ModalComponent: ComponentType<ModalProps>, props: ModalProps) {
  const targetNode = getTargetNode();

  ReactDOM.render(<ModalComponent onDismiss={closeModal} {...props} />, targetNode);
}

// defer avoids weird react console error
export const closeModal = () => _.defer(() => ReactDOM.unmountComponentAtNode(getTargetNode()));

const getTargetNode = () => {
  let targetNode = document.querySelector('#asset-action-bar-modal-target');

  if (!targetNode) {
    targetNode = document.createElement('div');
    targetNode.setAttribute('id', 'asset-action-bar-modal-target');
    document.body.appendChild(targetNode);
  }

  return targetNode;
};
