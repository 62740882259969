import _ from 'lodash';
import { UPDATE_PREFERENCE_SETTINGS } from 'common/notifications/actions/PreferenceActions';

export default function(state = {}, action) {
  switch (action.type) {
    case UPDATE_PREFERENCE_SETTINGS:
      const settings = _.get(action, 'preferenceSettings.settings', {});
      const transientNotificationsSettings = _.find(settings, (setting) => {
        return setting[0].name === 'transient_notification';
      });

      return {
        ...state,
        ...action.preferenceSettings,
        isTransientNotificationEnabled: _.get(transientNotificationsSettings, '[0].enable', true)
      };
    default:
      return state;
  }
}

