import _ from 'lodash';
import $ from 'jquery';

// Keep track of existing heading to prevent collisions
// keys: lowercased content of heading text.
// values: number of times that key has been used as a header ID.
let headingsIds = {};

// Keep track of table of contents headings. Contains 0 or more objects of shape:
// {
//   level, // Heading level. I.e. H1 is a 1, H4 is a 4.
//   tag,   // Identifier for the heading. Should be unique per page.
//   text   // Full, unmodified text in the heading.
// }
let tableOfContentsHeadings = [];

const HEADINGS_SELECTOR = 'h1,h2,h3,h4,h5,h6';

/*
 * This function is used to clear heading information
 * It is called internally to clear state between ToC generation calls,
 * but it's also exported for use in testing.
*/
export const clearHeadingsIds = () => {
  headingsIds = {};
  tableOfContentsHeadings = [];
};

/**
 * This function checks to see if a block has a Table of Contents component.
 * If so, the component's index is returned. If not, -1 is returned.
 *
 * @param {Object} block A block object (i.e. from StoryStore).
 * @return {Number} The index of the first ToC component in the block, or -1 if none.
 *
 * @example
 *     const hasToC = tocComponentIndex(block) !== -1;
 */
export const tocComponentIndex = (block) => {
  return _.findIndex(block.components, { type: 'html.tableOfContents' });
};


/**
 * This function adds an Id to the heading tag by using the innerText of
 * the element.
 *
 * @param {Object} element A DOM object
 * @return {Object} A DOM object (same as what was passed in)
 *
 * @example
 *
 *     addAnchor(document.querySelector('h1'))
 */
export const addAnchor = (element) => {
  const reservedAndUnsafeChars = /[#<>,&;/?:@="%{}|\\^~[\]\`]/g;
  const text = element.textContent.toLowerCase().replace(reservedAndUnsafeChars, '').replace(/ +/g, ' ');
  let tag = text.replace(/\s+/g, '-');
  if (headingsIds[text]) {
    // tag collision
    tag = `${tag}-${headingsIds[text]}`;
    element.setAttribute('id', tag);
    headingsIds[text] += 1;
  } else {
    element.setAttribute('id', tag);
    headingsIds[text] = 1;
  }
  tableOfContentsHeadings.push({
    level: parseInt(element.tagName.slice(1), 10),
    tag,
    text: element.textContent
  });
  return element;
};

/**
 * This function adds Ids to all heading children of the elements
 *
 * @param {Object} elements A DOM object
 * @return {Object} A DOM object (same as what was passed in)
 *
 * @example
 *
 *     addAnchorToHeadings(document)
 */
export const addAnchorToHeadings = (elements) => {
  const headingNodes = elements.querySelectorAll(HEADINGS_SELECTOR);
  // IE doesn't implement forEach on NodeLists.
  _.each(headingNodes, addAnchor);
  return elements;
};

/**
 * Renders a Table Of Contents (as an HTML string) based on headings
 * previously passed to addAnchorToHeadings or addAnchor.
 *
 * Subsequent calls to generateTableOfContents will yield an empty
 * ToC unless addAnchorToHeadings or addAnchor is called again.
 *
 * @return {String} The rendered Table Of Contents.
 *
 * @example
 *
 *     myElement.innerHTML = generateTableOfContents();
 */
export const generateTableOfContents = () => {
  if (tableOfContentsHeadings.length === 0) {
    return null; // Special renderer handles this.
  }

  const $tableOfContents = $('<ul>', { id: 'table-of-contents' });
  // IE doesn't implement forEach on NodeLists.
  _.each(tableOfContentsHeadings, (heading) => {
    let $currentLevel = $('<li>').appendTo($tableOfContents);
    // Descend the appropriate number of list levels. Note that for level=1 this is skipped
    // because we're already in a UL.
    for (let i = 1; i < heading.level; i++) {
      const $li = $('<li>');
      const $ul = $('<ul>').append($li);
      $currentLevel.append($ul);
      $currentLevel = $li;
    }

    $currentLevel.append(
      $('<a>', { href: `#${heading.tag}` }).text(heading.text)
    );
  });
  clearHeadingsIds();
  return $tableOfContents.prop('outerHTML');
};

/**
 * @param {Node[]} blocks All story blocks
 * @return {Node[]} altered blocks
 *
 * @example
 *
 *     addPresentationModeAnchors(document.querySelectorAll('.user-story .block'))
 */
export const addPresentationModeAnchors = (blocks) => {
  blocks.forEach((block) => {
    const headingNodes = Array.from(block.querySelectorAll(HEADINGS_SELECTOR));
    if (headingNodes.length > 0) {
      const headingsForBlock = headingNodes.map((heading) => heading.getAttribute('id'));
      block.setAttribute('data-heading-ids', `,${headingsForBlock.join(',')},`);
    }
  });
};
