import { ScreenshotSubscription } from 'common/notifications/api/ScreenshotSubscriptionsAPI';
import type { CatalogInteractiveUser, UsersCatalogSearchResult } from 'common/types/users/catalogUsers';
import { ReducerState } from './reducer';

export enum WatchAssetManagerActions {
  SET_RECIPIENT_QUERY = 'SET_RECIPIENT_QUERY',

  RECIPIENT_SEARCH_SUCCESS = 'RECIPIENT_SEARCH_SUCCESS',
  RECIPIENT_SEARCH_FAIL = 'RECIPIENT_SEARCH_FAIL',

  ADD_TO_RECIPIENTS = 'ADD_TO_RECIPIENTS',
  REMOVE_FROM_RECIPIENTS = 'REMOVE_FROM_RECIPIENTS',
  SET_RECIPIENTS = 'SET_RECIPIENTS',

  SET_EXISTING_SUBSCRIPTIONS = 'SET_EXISTING_SUBSCRIPTIONS',

  SET_STATE = 'SET_STATE'
}

export const setRecipientQuery = (query: string) => ({
  type: WatchAssetManagerActions.SET_RECIPIENT_QUERY,
  query
});

export const recipientSearchSuccess = (response: UsersCatalogSearchResult) => ({
  type: WatchAssetManagerActions.RECIPIENT_SEARCH_SUCCESS,
  response
});

export const recipientSearchFail = () => ({
  type: WatchAssetManagerActions.RECIPIENT_SEARCH_FAIL
});

export const addToRecipients = (user?: CatalogInteractiveUser) => ({
  type: WatchAssetManagerActions.ADD_TO_RECIPIENTS,
  user
});

export const removeFromRecipients = (user: { id: string }) => ({
  type: WatchAssetManagerActions.REMOVE_FROM_RECIPIENTS,
  user
});

export const setRecipients = (recipients: CatalogInteractiveUser[]) => ({
  type: WatchAssetManagerActions.SET_RECIPIENTS,
  recipients
});

export const setStateAction = (payload: Partial<ReducerState>) => ({
  type: WatchAssetManagerActions.SET_STATE,
  payload
});

export const setExistingSubscriptions = (existingSubscriptions: ScreenshotSubscription[]) => ({
  type: WatchAssetManagerActions.SET_EXISTING_SUBSCRIPTIONS,
  existingSubscriptions
});
