import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import AlertPreferenceAPI from '../../api/AlertPreferenceAPI';
import { ALERT_SETTING_MODAL_TABS } from '../../constants';
import AlertShareModal from '../AlertShareModal/AlertShareModal';
import ErrorMessage from '../../components/ErrorMessage';
import MyAlerts from './MyAlerts/MyAlerts';
import Watchlist from './Watchlist/Watchlist';
import PreferenceContent from './PreferenceContent';
import Spinner from '../Spinner';
import Tabs from './Tabs';
import { updatePreferenceSettings } from '../../actions/PreferenceActions';
import { FeatureFlags } from 'common/feature_flags';
import Button from 'common/components/Button';
import CreateAlertModal from 'common/components/CreateAlertModal';
import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import { handleTab } from 'common/dom_helpers/keyPressHelpers';
import { INDETERMINATE } from 'common/components/ThreeStateCheckbox';

import './alert-setting-modal.scss';

export class AlertSettingModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      failedLoadingUserPreferences: false,
      preferences: {},
      selectedAlert: {},
      selectedTab: ALERT_SETTING_MODAL_TABS.NOTIFICATION,
      settings: {},
      showAlertSharedMessage: false,
      showAlertShareModal: false,
      showCreateAlertModal: false,
      showAlertDeletedMessage: false,
      showSpinner: false,
      userPreferencesLoaded: false
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onSettingsChange = this.onSettingsChange.bind(this);
    this.saveAlertSettings = this.saveAlertSettings.bind(this);

    this.cancelButtonRef = React.createRef();
    this.modalHeaderRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const { preferenceSettings } = this.props;

    this.setState({
      preferences: preferenceSettings.preferences,
      settings: preferenceSettings.settings,
      userPreferencesLoaded: preferenceSettings.userPreferencesLoaded,
      failedLoadingUserPreferences: preferenceSettings.failedLoadingUserPreferences
    });
  }

  onAlertNotificationChange = (category, categoryData) => {
    let preferences = _.cloneDeep(this.state.preferences);
    preferences[category] = categoryData;

    this.setState({ preferences });
  };

  onSelectAllPreferenceTypeChange = (notificationKey, availablePreferences, currentValue) => {
    let preferences = _.cloneDeep(this.state.preferences);
    let notificationState = currentValue == INDETERMINATE ? true : !currentValue;

    _.each(availablePreferences, function(preferenceKey) {
      _.set(preferences, preferenceKey + '.' + notificationKey, notificationState);
    });

    this.setState({ preferences });
  };

  onSettingsChange(category, options) {
    let settings = _.cloneDeep(this.state.settings);
    let categoryData = _.get(settings, `${category}.0`, {});

    categoryData.name = category;
    categoryData.enable = (_.isUndefined(options.enable) ? categoryData.enable : options.enable);
    categoryData.value = (_.isUndefined(options.value) ? categoryData.value : options.value);
    categoryData.type = (_.isUndefined(options.type) ? categoryData.type : options.type);

    settings[category] = [categoryData];
    this.setState({ settings });
  }

  onEditAlertClick = (alert) => {
    this.setState({ selectedAlert: alert, showCreateAlertModal: true });
  };

  onShareAlertClick = (alert) => {
    this.setState({ selectedAlert: alert, showAlertShareModal: true });
  };

  onCloseAlertModal = (options) => {
    this.setState({
      selectedTab: ALERT_SETTING_MODAL_TABS.MY_ALERTS,
      showCreateAlertModal: false,
      showAlertDeletedMessage: _.get(options, 'isDeleted', false)
    });
  };

  onCloseAlertShareModal = (options) => {
    const selectedTab = ALERT_SETTING_MODAL_TABS.MY_ALERTS;
    const showAlertSharedMessage = _.get(options, 'isShared', false);
    this.setState({ selectedTab, showAlertSharedMessage, showAlertShareModal: false });
  };

  onTabChange(selectedTab) {
    this.setState({ selectedTab });
  }

  renderNotificationTabContent() {
    const { failedLoadingUserPreferences, preferences, settings, userPreferencesLoaded } = this.state;

    if (userPreferencesLoaded) {
      return (
        <PreferenceContent
          onAlertNotificationChange={this.onAlertNotificationChange}
          onSelectAllPreferenceTypeChange={this.onSelectAllPreferenceTypeChange}
          onSettingsChange={this.onSettingsChange}
          preferences={preferences}
          settings={settings} />
      );
    } else if (failedLoadingUserPreferences) {
      const errorText = I18n.t('error_loading_preferences_text_html', { scope: 'shared.site_chrome.notifications.alert_setting_modal' });

      return (
        <div className="user-preferences-error-message-wrapper">
          <ErrorMessage text={errorText} />
        </div>
      );
    }

    return (
      <div className="user-preferences-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  saveAlertSettings() {
    const { preferences, settings } = this.state;
    const { onClose, updatePreferenceSettings } = this.props;
    this.setState({ showSpinner: true });

    AlertPreferenceAPI.set(preferences, settings).then(() => {
      updatePreferenceSettings({ preferenceSettings: { preferences, settings } });
      this.setState({ showSpinner: false });
      onClose();
    }).
    catch(() => {
      this.setState({ showSpinner: false });
    });
  }

  renderAlertShareModal() {
    const { selectedAlert, showAlertShareModal } = this.state;

    if (showAlertShareModal) {
      return (
        <AlertShareModal
          alert={selectedAlert}
          onClose={this.onCloseAlertShareModal} />
      );
    }

    return null;
  }

  renderCreateAlertModal() {
    const { mapboxAccessToken } = this.props;
    const { selectedAlert, showCreateAlertModal } = this.state;
    let editAlertType = _.get(selectedAlert, 'queryType', 'raw');

    if (showCreateAlertModal) {
      return (
        <CreateAlertModal
          alert={selectedAlert}
          editAlertType={editAlertType}
          editMode={showCreateAlertModal}
          mapboxAccessToken={mapboxAccessToken}
          onClose={this.onCloseAlertModal} />
      );
    }

    return null;
  }

  renderTabs() {
    const { userPreferencesLoaded, selectedTab } = this.state;
    const { showMyAlertPreference } = this.props;
    const showWatchlistTab = FeatureFlags.value('enable_screenshot_subscriptions');

    if (userPreferencesLoaded && (showMyAlertPreference || showWatchlistTab)) {
      return (
        <Tabs
          showMyAlertsTab={showMyAlertPreference}
          onTabChange={this.onTabChange}
          selectedTab={selectedTab}
          showWatchlistTab={showWatchlistTab}/>
      );
    }

    return null;
  }

  renderTabContent() {
    const { selectedAlert, selectedTab, showAlertDeletedMessage, showAlertSharedMessage } = this.state;

    switch (selectedTab) {
      case ALERT_SETTING_MODAL_TABS.NOTIFICATION:
        return this.renderNotificationTabContent();
      case ALERT_SETTING_MODAL_TABS.MY_ALERTS:
        return (
          <MyAlerts
            currentSelectedAlert={selectedAlert}
            onEditAlertClick={this.onEditAlertClick}
            showAlertDeletedMessage={showAlertDeletedMessage}
            onShareAlertClick={this.onShareAlertClick}
            showAlertSharedMessage={showAlertSharedMessage} />
        );
      case ALERT_SETTING_MODAL_TABS.WATCHLIST:
        return (<Watchlist />);
    }
  }

  focusClosePanelButton = () => {
    if (this.modalHeaderRef.current) {
      this.modalHeaderRef.current.focus();
    }
  };

  focusCancelButton = () => {
    if (this.cancelButtonRef.current) {
      this.cancelButtonRef.current.focus();
    }
  };

  renderModalFooter() {
    const { userPreferencesLoaded } = this.state;

    if (userPreferencesLoaded) {
      const { onClose } = this.props;

      return (
        <ModalFooter>
          <Button
            className="cancel-button"
            ref={this.cancelButtonRef}
            variant="default"
            onClick={onClose}>
            {I18n.t('cancel', { scope: 'shared.site_chrome.notifications.alert_setting_modal' })}
          </Button>
          <Button
            onKeyDown={handleTab(this.focusClosePanelButton, this.focusCancelButton, true)}
            className="save-button"
            variant="primary"
            onClick={this.saveAlertSettings}>
            {I18n.t('save', { scope: 'shared.site_chrome.notifications.alert_setting_modal' })}
          </Button>
        </ModalFooter>
      );
    }

    return null;
  }

  renderAlertSettingModal() {
    const { onClose } = this.props;
    const { selectedTab } = this.state;
    const containerStyle = {
      'maxWidth': '800px',
      'maxHeight': 'calc(100vh - 40px)',
      'padding': '20px',
      'margin': '20px auto',
      'bottom': 'auto'
    };

    let showFooterData = (selectedTab === ALERT_SETTING_MODAL_TABS.NOTIFICATION);
    return (
      <Modal
        autoFocus
        className="alert-setting-modal"
        onDismiss={onClose}
        containerStyle={containerStyle}>
        <ModalHeader
          ref={this.modalHeaderRef}
          title={I18n.t('settings', { scope: 'shared.site_chrome.notifications.alert_setting_modal' })}
          onDismiss={onClose} />

        <ModalContent className="alert-setting-modal-content">
          {this.renderTabs()}
          {this.renderTabContent()}
          {this.state.showSpinner ? <Spinner /> : null}
        </ModalContent>

        {showFooterData ? this.renderModalFooter() : null}
      </Modal>
    );
  }

  render() {
    const { showCreateAlertModal, showAlertShareModal } = this.state;

    if (showCreateAlertModal) {
      return this.renderCreateAlertModal();
    } else if (showAlertShareModal) {
      return this.renderAlertShareModal();
    }

    return this.renderAlertSettingModal();
  }
}

AlertSettingModal.propTypes = {
  mapboxAccessToken: PropTypes.string,
  onClose: PropTypes.func,
  showMyAlertPreference: PropTypes.bool,
  toOpenModal: PropTypes.func,
  preferenceSettings: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  updatePreferenceSettings
};

const mapStateToProps = state => ({
  mapboxAccessToken: state.configurations.mapboxAccessToken,
  showMyAlertPreference: state.configurations.showMyAlertPreference,
  preferenceSettings: _.cloneDeep(state.preferenceSettings)
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertSettingModal);
