import Actions from 'Actions';
import { createAction, createReducer } from '@reduxjs/toolkit';

interface ModalReducerState {
  isEditMetadataOpen: boolean;
}

const initialState: ModalReducerState = {
  isEditMetadataOpen: false
};

/* Action Creators */
export const openEditMetadataModal = createAction(Actions.EDIT_METADATA_MODAL_OPEN);
export const closeEditMetadataModal = createAction(Actions.EDIT_METADATA_MODAL_CLOSE);

/**
 * ENGINEER NOTE: This reducer should be removed. We should not be storing component state in
 * redux. Once we convert the setting panel to React, we should remove this reducer.
 */

const ModalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openEditMetadataModal, (state) => {
      state.isEditMetadataOpen = true;
    })
    .addCase(closeEditMetadataModal, (state) => {
      state.isEditMetadataOpen = false;
    });
});

export default ModalReducer;
