import _ from 'lodash';
import BigNumber from 'bignumber.js';

import { CalculationTypes } from '../lib/constants';

import {
  filterWhereClauses,
  setupSoqlDataProvider
} from './helpers';

import { count } from './count';
import { sum } from './sum';

export const calculateRateMeasure = async (
  errors,
  measure,
  dateRangeWhereClause,
  dataProvider = setupSoqlDataProvider(measure) // For test injection
) => {
  const {
    aggregationType,
    numeratorColumn,
    numeratorFilters,
    denominatorColumn,
    denominatorFilters,
    fixedDenominator
  } = _.get(measure, 'metricConfig.arguments', {});

  const dateColumn = _.get(measure, 'metricConfig.dateColumn');
  const asPercent = _.get(measure, 'metricConfig.display.asPercent');

  const numeratorOk = aggregationType === CalculationTypes.COUNT
    ? true : !!numeratorColumn;
  const denominatorOk = aggregationType === CalculationTypes.COUNT
    ? true : (denominatorColumn || !_.isEmpty(fixedDenominator));

  errors.calculationNotConfigured = !numeratorOk || !denominatorOk;

  // Defaults for the result
  const result = {
    value: aggregationType === CalculationTypes.COUNT ?
      new BigNumber(0) : // Show 0 as the default for count aggregations, even if there are errors
      null, // Show null as the default for errors/etc
    numerator: null,
    denominator: null
  };

  if (aggregationType && (numeratorOk || denominatorOk) && dataProvider && dateRangeWhereClause) {
    const numeratorWhereClause = filterWhereClauses(numeratorFilters);
    const denominatorWhereClause = filterWhereClauses(denominatorFilters);

    let numeratorPromise;
    let denominatorPromise = _.isEmpty(fixedDenominator) ?
      null :
      Promise.resolve(new BigNumber(fixedDenominator));

    // Come up with promises for numerator and denominator.
    // If either numerator or denominator have the possibility
    // of calculating, we should proceed. The app will provide
    // partial results in edit mode even if half of the fraction
    // is not fully-specified.
    switch (aggregationType) {
      case CalculationTypes.COUNT: {
        numeratorPromise =
          count(
            dataProvider, [numeratorWhereClause, dateRangeWhereClause]
          );

        denominatorPromise = denominatorPromise ||
          count(
            dataProvider, [denominatorWhereClause, dateRangeWhereClause]
          );
        break;
      }
      case CalculationTypes.SUM:
        numeratorPromise = numeratorColumn ?
          sum(
            dataProvider, numeratorColumn, [numeratorWhereClause, dateRangeWhereClause]
          ) : null;

        denominatorPromise = denominatorPromise ||
          (denominatorColumn ? sum(
            dataProvider, denominatorColumn, [denominatorWhereClause, dateRangeWhereClause]
          ) : null);
        break;
      default:
        throw new Error(`Unknown aggregation type: ${aggregationType}`);
    }

    let numerator = await numeratorPromise;
    let denominator = await denominatorPromise;

    if (numerator) {
      result.numerator = numerator;
    }

    if (denominator) {
      result.denominator = denominator;
      errors.dividingByZero = denominator.isZero();
    }

    if (numerator && denominator) {
      if (!errors.dividingByZero) {
        result.value = numerator.dividedBy(denominator).
          times(asPercent ? '100' : '1');
      }
    } else {
      errors.notEnoughData = true;
    }

    return { errors, result };

  } else {
    errors.calculationNotConfigured =
      !numeratorOk || !denominatorOk || !aggregationType || !dateColumn;

    // Special case: If the denominator is fixed, we can always at least return that.
    if (!_.isEmpty(fixedDenominator)) {
      const denominator = new BigNumber(fixedDenominator);
      errors.dividingByZero = denominator.isZero();
      result.denominator = denominator;

      return { errors, result };
    }

    return { errors };
  }
};
