// import _ from 'lodash';
import React from 'react';
import './index.scss';

export interface RadiobuttonGroupProps {
  ariaLabel?: string;
  children: React.ReactNode[] | React.ReactNode;
}

// Style Wrapper, but offers future extensibility
const RadiobuttonGroup: React.FC<RadiobuttonGroupProps> = ({ ariaLabel, children }: RadiobuttonGroupProps) => {
  // if (and only if) optional "aria-label" is supplied, treat this wrapper as a proper radiogroup
  const ariaAttributes = ariaLabel ? { role: 'radiogroup', 'aria-label': ariaLabel } : {};

  return (
    <div className="socrata-RadiobuttonGroup" {...ariaAttributes}>
      {(() => {
        if (children === undefined) {
          throw new Error('No children are defined in RadiobutttonGroup');
        } else if (children instanceof Array === false) {
          return children;
        } else {
          return (children as React.ReactNode[]).map((child) => {
            return child;
          });
        }
      })()
      }
    </div>
  );
};

export default RadiobuttonGroup;
