/* Imports */
/* ============================================================================= */
import { GuidanceCheckSummary } from 'common/core/approvals/Types';
import { WORKFLOWS } from '.';





/* Method */
/* ============================================================================= */
/**
 * Generates a GuidanceCheckSummary object with default values set
 * @param defaultValue The default value to set for each target audience attribute
 * @returns A default GuidanceCheckSummary Object
 */
export const createDefaultGuidanceCheckSummary = <T>(defaultValue: T): GuidanceCheckSummary<T> => {
  const response = {
    length: 0,
    totalWorkflows: WORKFLOWS.length,
  };

  WORKFLOWS.forEach((targetAudience) => response[targetAudience] = defaultValue);

  return response as GuidanceCheckSummary<T>;
};

export default createDefaultGuidanceCheckSummary;
