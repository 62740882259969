import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';

import { USER_TYPES } from 'common/components/AccessManager/Constants';

import './user-label.scss';
import { ViewUser } from 'common/types/view';

export const classNameScope = 'common--components--AccessManager--components--UserDetails--user-label';

interface UserLabelProps {
  user: ViewUser;
}

/**
 * Shows the display name and the email of the given user.
 * If the user has no display name, then they are either unregistered or a
 * community user, in which case we show no subtitle
 */
const UserLabel: FunctionComponent<UserLabelProps> = ({ user }) => {
  const { displayName, email, type } = user;

  if (displayName) {
    return (
      <div className={`${classNameScope}--user-label`}>
        <div className={`${classNameScope}--title`} data-testid="access-manager-user-title">
          {displayName}
        </div>
        {/* Teams have no email */}
        {type !== USER_TYPES.TEAM && email && (
          <div className={`${classNameScope}--subtitle`} data-testid="access-manager-user-subtitle">
            {email}
          </div>
        )}
      </div>
    );
  } else {
    // if we don't have the display name, it means that this user was added
    // via email and the save button hasn't been clicked yet... when it reloads, this user will exist for real
    return (
      <div className={`${classNameScope}--user-label`}>
        <div className={`${classNameScope}--title`}>{email}</div>
      </div>
    );
  }
};

export default UserLabel;
