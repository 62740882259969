// Vendor Imports
import d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';

// Project Imports
import { getMeasures } from '../helpers/measure';
import LabelResizer from './LabelResizer';
import BaseVisualization from './BaseVisualization';
import { renderLegend } from './BaseVisualization/Legend';
import SvgKeyboardPanning from './SvgKeyboardPanning';
import I18n from 'common/i18n';
import assert from 'common/assertions/assert';

// Constants
import {
  AXIS_DEFAULT_COLOR,
  AXIS_GRID_COLOR,
  AXIS_LABEL_MARGIN,
  AXIS_TICK_COLOR,
  D3_TICK_SIZE,
  DATA_POINT_LABELS_FONT_COLOR,
  DATA_POINT_LABELS_FONT_SIZE,
  DATA_POINT_LABELS_OFFSET_ABOVE,
  DATA_POINT_LABELS_OFFSET_ABOVE_MORE,
  DATA_POINT_LABELS_OFFSET_BELOW,
  DATA_POINT_LABELS_OFFSET_BELOW_MORE,
  DEFAULT_CIRCLE_HIGHLIGHT_RADIUS,
  DEFAULT_DESKTOP_COLUMN_WIDTH,
  DEFAULT_LINE_HIGHLIGHT_FILL,
  DEFAULT_MOBILE_COLUMN_WIDTH,
  DIMENSION_LABELS_DEFAULT_HEIGHT,
  DIMENSION_LABELS_FONT_COLOR,
  DIMENSION_LABELS_FONT_SIZE,
  ERROR_BARS_DEFAULT_BAR_COLOR,
  ERROR_BARS_MAX_END_BAR_LENGTH,
  ERROR_BARS_STROKE_WIDTH,
  FONT_STACK,
  LINE_STYLE_POINTS_NONE,
  MEASURE_AXIS_SCALE_MIN_TO_MAX,
  MEASURE_LABELS_FONT_COLOR,
  MEASURE_LABELS_FONT_SIZE,
  MEASURE_VALUE_TEXT_INSIDE_BAR_COLOR,
  MEASURE_VALUE_TEXT_OUTSIDE_BAR_COLOR,
  REFERENCE_LINES_STROKE_DASHARRAY,
  REFERENCE_LINES_STROKE_WIDTH,
  REFERENCE_LINES_UNDERLAY_THICKNESS,
  SERIES_TYPE_COMBO_CHART_COLUMN,
  SERIES_TYPE_COMBO_CHART_LINE,
  TEXT_ANCHOR_END,
  TEXT_ANCHOR_START,
  VALUE_LABEL_FONT_SIZE,
  VALUE_LABEL_MARGIN
} from './SvgConstants';

import {
  getAxisLabels,
  getLineStylePoints,
  getMeasureAxisScale,
  getMeasureAxisMaxValue,
  getMeasureAxisMinValue,
  getReferenceLinesWithValues,
  getShowDimensionLabels,
  getShowLineValueLabels,
  getShowValueLabels,
  getSecondaryMeasureAxisScale,
  getYAxisScalingMode,
  isGroupingOrHasMultipleNonFlyoutSeries,
  isVifStacked,
  validateAxisValue
} from '../helpers/VifSelectors';
import { hideOffscreenDimensionLabels } from '../helpers/SvgHelpers';

// The MARGINS values have been eyeballed to provide enough space for axis
// labels that have been observed 'in the wild'. They may need to be adjusted
// slightly in the future, but the adjustments will likely be small in scale.
// The LEFT and RIGHT margins has been removed because it will be dynamically calculated.
const MARGINS = {
  TOP: 32,
  BOTTOM: 32
};

function SvgComboChart($element, vif, options) {
  const self = this;

  let $chartElement;
  let columnDataToRender;
  let d3DimensionXScale;
  let d3GroupingXScale;
  let d3PrimaryYScale;
  let d3SecondaryYScale;
  let flyoutDataToRender;
  let lastRenderedSeriesWidth = 0;
  let lastRenderedZoomTranslate = 0;
  let lineDataToRender;
  let measures;
  let nonFlyoutDataToRender;
  let referenceLines;

  const labelResizer = new LabelResizer({
    enabled: false,
    getAxisLabels: () => getAxisLabels(self.getVif()),
    getConfiguredLabelHeight: () => {
      return _.get(self.getVif(), 'configuration.dimensionLabelAreaSize', DIMENSION_LABELS_DEFAULT_HEIGHT);
    },
    margins: MARGINS,
    onDrag: () => {
      renderData();
      hideFlyout();
    },
    onDragEnd: (state) => {
      renderData();
      self.emitEvent('SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED', state.overriddenAreaSize);
    }
  });

  _.extend(this, new BaseVisualization($element, vif, options));

  renderTemplate();

  /**
   * Public methods
   */

  this.render = ({ newColumns, newComputedColumns, newData, newFlyoutData, newVif, newTableVif } = {}) => {
    // Forget the label area size the user set - we're
    // loading a brand new vif.
    labelResizer.resetOverride();

    this.clearError();

    if (newVif) {
      if (!_.isEqual(this.getVif().series, newVif.series)) {
        lastRenderedZoomTranslate = 0;
      }

      this.updateVif(newVif);
    }

    if (newColumns || newComputedColumns) {
      this.updateColumns(newColumns, newComputedColumns);
    }

    if (newData) {
      nonFlyoutDataToRender = newData;

      self.addSeriesIndices(nonFlyoutDataToRender);
      self.setDefaultMeasureColumnPrecision(nonFlyoutDataToRender);

      columnDataToRender = self.getDataToRenderOfSeriesType(
        nonFlyoutDataToRender,
        SERIES_TYPE_COMBO_CHART_COLUMN
      );
      self.setDefaultMeasureColumnPrecision(columnDataToRender);

      lineDataToRender = self.getDataToRenderOfSeriesType(
        nonFlyoutDataToRender,
        SERIES_TYPE_COMBO_CHART_LINE
      );
      self.setDefaultMeasureColumnPrecision(lineDataToRender);
    }

    if (newTableVif) {
      this.updateSummaryTableVif(newTableVif);
    }

    if (newFlyoutData) {
      flyoutDataToRender = newFlyoutData;
      self.setDefaultMeasureColumnPrecision(flyoutDataToRender);
    }

    if (self.isOnlyNullValues(nonFlyoutDataToRender)) {
      self.renderNoDataError();
      return;
    }

    labelResizer.updateOptions({ enabled: getShowDimensionLabels(self.getVif()) });
    renderData();
  };

  this.invalidateSize = () => {
    if ($chartElement && nonFlyoutDataToRender) {
      renderData();
    }
  };

  this.destroy = () => {
    d3.select(self.$element[0]).select('svg').remove();

    self.$element.find('.socrata-visualization-container').remove();
  };

  /**
   * Private methods
   */
  function renderTemplate() {
    $chartElement = $('<div>', { class: 'combo-chart chart-with-label-dragger' });
    labelResizer.renderTemplate($chartElement);

    self.$element.find('.socrata-visualization-chart-container').append($chartElement);
  }

  function renderData() {
    const columnWidth = self.isMobile() ? DEFAULT_MOBILE_COLUMN_WIDTH : DEFAULT_DESKTOP_COLUMN_WIDTH;

    const dimensionLabelsHeight = getShowDimensionLabels(self.getVif())
      ? labelResizer.computeLabelHeight()
      : 0;

    const axisLabels = getAxisLabels(self.getVif());
    const topMargin = MARGINS.TOP + (axisLabels.top ? AXIS_LABEL_MARGIN : 0);
    const bottomMargin = MARGINS.BOTTOM + (axisLabels.bottom ? AXIS_LABEL_MARGIN : 0) + dimensionLabelsHeight;
    let viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);
    const panningClipPathId = `combo-chart-panning-clip-path-${_.uniqueId()}`;
    const measureBoundsClipPathId = `combo-chart-measure-bounds-clip-path-${_.uniqueId()}`;
    const dataTableDimensionIndex = nonFlyoutDataToRender.columns.indexOf('dimension');
    const dimensionValues = nonFlyoutDataToRender.rows.map((row) => row[dataTableDimensionIndex]);

    lastRenderedZoomTranslate = 0;
    measures = getMeasures(self, nonFlyoutDataToRender);
    referenceLines = getReferenceLinesWithValues(self.getVif());

    let chartSvg;
    let circlePositions;
    let circleSvgs;
    let columnPositions;
    let columnTextSvgs;
    let d3PrimaryYAxis;
    let d3SecondaryYAxis;
    let d3XAxis;
    let d3Zoom;
    let dimensionGroupSvgs;
    let height;
    let linePositions;
    let lineSvgs;
    let numberOfGroups;
    let numberOfItemsPerGroup;
    let primaryYAxisBound = false;
    let primaryYAxisExtent;
    let referenceLineSvgs;
    let referenceLineUnderlaySvgs;
    let secondaryYAxisBound = false;
    let secondaryYAxisExtent;
    let seriesSvg;
    let textSvgs;
    let viewportSvg;
    let width;
    let xAxisAndSeriesSvg;
    let xAxisBound = false;
    let xAxisPanDistance;
    let xAxisPanningEnabled;

    /**
     * Functions defined inside the scope of renderData() are stateful enough
     * to benefit from sharing variables within a single render cycle.
     */

    // See comment in renderXAxis() for an explanation as to why this is
    // separate.
    function bindXAxisOnce() {
      if (!xAxisBound) {
        let xAxisFormatter;

        if (getShowDimensionLabels(self.getVif())) {
          xAxisFormatter = d3XAxis;
        } else {
          xAxisFormatter = d3XAxis.tickFormat('').tickSize(0);
        }

        xAxisAndSeriesSvg.append('g').attr('class', 'x axis').call(xAxisFormatter);

        xAxisAndSeriesSvg
          .append('g')
          .attr('class', 'x axis baseline')
          .call(d3XAxis.tickFormat('').tickSize(0));

        // Bind the chart data to the x-axis tick labels so that when the user
        // hovers over them we have enough information to distinctly identify
        // the column which should be highlighted and show the flyout.
        chartSvg.selectAll('.x.axis .tick text').data(nonFlyoutDataToRender.rows);

        xAxisBound = true;
      }
    }

    function renderXAxis() {
      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderXAxis() as idempotent.
      bindXAxisOnce();

      const xAxisSvg = viewportSvg.select('.x.axis');
      const xBaselineSvg = viewportSvg.select('.x.axis.baseline');

      xAxisSvg.attr('transform', `translate(0,${height})`);

      xAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_DEFAULT_COLOR);

      xAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', AXIS_TICK_COLOR);

      xAxisSvg
        .selectAll('text')
        .attr('fill', DIMENSION_LABELS_FONT_COLOR)
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${DIMENSION_LABELS_FONT_SIZE}px`)
        .attr('stroke', 'none')
        .attr('data-row-index', (label, rowIndex) => rowIndex)
        .call(self.rotateDimensionLabels, {
          dataToRender: nonFlyoutDataToRender,
          maxHeight: dimensionLabelsHeight,
          maxWidth: d3DimensionXScale.rangeBand()
        });

      hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate, viewportWidth });

      let translateY = getPrimaryOrSecondaryBaselineTranslation();

      if (!_.isNaN(translateY)) {
        xBaselineSvg.attr('transform', `translate(0,${translateY})`);

        xBaselineSvg
          .selectAll('line, path')
          .attr('fill', 'none')
          .attr('stroke', AXIS_DEFAULT_COLOR)
          .attr('shape-rendering', 'crispEdges');
      }
    }

    function getPrimaryOrSecondaryBaselineTranslation() {
      if (
        !_.isNil(d3PrimaryYScale) &&
        !_.isNil(primaryYAxisExtent) &&
        !_.isNaN(primaryYAxisExtent.max) &&
        !_.isNaN(primaryYAxisExtent.min)
      ) {
        if (primaryYAxisExtent.min > 0) {
          return d3PrimaryYScale(primaryYAxisExtent.min);
        } else if (primaryYAxisExtent.max < 0) {
          return d3PrimaryYScale(primaryYAxisExtent.max);
        } else {
          return d3PrimaryYScale(0);
        }
      } else if (
        !_.isNil(d3SecondaryYScale) &&
        !_.isNil(secondaryYAxisExtent) &&
        !_.isNaN(secondaryYAxisExtent.max) &&
        !_.isNaN(secondaryYAxisExtent.min)
      ) {
        if (secondaryYAxisExtent.min > 0) {
          return d3SecondaryYScale(secondaryYAxisExtent.min);
        } else if (secondaryYAxisExtent.max < 0) {
          return d3SecondaryYScale(secondaryYAxisExtent.max);
        } else {
          return d3SecondaryYScale(0);
        }
      } else {
        return null;
      }
    }

    // See comment in renderYAxis() for an explanation as to why this is
    // separate.
    function bindPrimaryYAxisOnce() {
      if (!primaryYAxisBound) {
        chartSvg.select('.y.axis').call(d3PrimaryYAxis);

        chartSvg.select('.y.grid').call(d3PrimaryYAxis.tickSize(viewportWidth).tickFormat(''));

        primaryYAxisBound = true;
      }
    }

    function renderPrimaryYAxis() {
      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderYAxis() as idempotent.
      bindPrimaryYAxisOnce();

      const yAxisSvg = chartSvg.select('.y.axis');

      yAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('text')
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${MEASURE_LABELS_FONT_SIZE}px`)
        .attr('fill', MEASURE_LABELS_FONT_COLOR)
        .attr('stroke', 'none');

      const yGridSvg = chartSvg.select('.y.grid');

      yGridSvg.attr('transform', `translate(${viewportWidth},0)`);

      yGridSvg.selectAll('path').attr('fill', 'none').attr('stroke', 'none');

      yGridSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_GRID_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    // See comment in renderYAxis() for an explanation as to why this is
    // separate.
    function bindSecondaryYAxisOnce() {
      if (!secondaryYAxisBound) {
        chartSvg.select('.y.secondaryAxis').call(d3SecondaryYAxis);

        chartSvg.select('.y.secondaryGrid').call(d3SecondaryYAxis.tickSize(viewportWidth).tickFormat(''));

        secondaryYAxisBound = true;
      }
    }

    function renderSecondaryYAxis() {
      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderYAxis() as idempotent.
      bindSecondaryYAxisOnce();

      const yAxisSvg = chartSvg.select('.y.secondaryAxis');

      yAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('text')
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${MEASURE_LABELS_FONT_SIZE}px`)
        .attr('fill', MEASURE_LABELS_FONT_COLOR)
        .attr('stroke', 'none');

      const yGridSvg = chartSvg.select('.y.secondaryGrid');

      yGridSvg.selectAll('path').attr('fill', 'none').attr('stroke', 'none');
    }

    function renderReferenceLines() {
      const getYPosition = (referenceLine) => d3PrimaryYScale(referenceLine.value);
      const getLineThickness = (referenceLine) => {
        const value = referenceLine.value;
        return self.isInRange(value, primaryYAxisExtent.min, primaryYAxisExtent.max)
          ? REFERENCE_LINES_STROKE_WIDTH
          : 0;
      };

      const getUnderlayThickness = (referenceLine) => {
        const value = referenceLine.value;
        return self.isInRange(value, primaryYAxisExtent.min, primaryYAxisExtent.max)
          ? REFERENCE_LINES_UNDERLAY_THICKNESS
          : 0;
      };

      // This places the underlay half above the line and half below the line.
      const underlayUpwardShift = REFERENCE_LINES_UNDERLAY_THICKNESS / 2;

      referenceLineUnderlaySvgs
        .attr('data-reference-line-index', (referenceLine, index) => index)
        .attr('fill', DEFAULT_LINE_HIGHLIGHT_FILL)
        .attr('fill-opacity', 0)
        .attr('x', 0)
        .attr('y', (referenceLine) => getYPosition(referenceLine) - underlayUpwardShift)
        .attr('width', width)
        .attr('height', getUnderlayThickness);

      referenceLineSvgs
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', (referenceLine) => referenceLine.color)
        .attr('stroke-dasharray', REFERENCE_LINES_STROKE_DASHARRAY)
        .attr('stroke-width', getLineThickness)
        .attr('x1', 0)
        .attr('y1', getYPosition)
        .attr('x2', width)
        .attr('y2', getYPosition);
    }

    function renderErrorBars() {
      if (_.isUndefined(columnDataToRender.errorBars)) {
        return;
      }

      const d3ColumnYScale = isUsingSecondaryAxisForColumns() ? d3SecondaryYScale : d3PrimaryYScale;
      const columnWidth = d3GroupingXScale.rangeBand() - 1;
      const errorBarWidth = Math.min(columnWidth, ERROR_BARS_MAX_END_BAR_LENGTH);
      const color = _.get(self.getVif(), 'series[0].errorBars.barColor', ERROR_BARS_DEFAULT_BAR_COLOR);

      const getMinErrorBarYPosition = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        const minValue = _.clamp(d3.min(errorBarValues), primaryYAxisExtent.min, primaryYAxisExtent.max);
        return d3ColumnYScale(minValue);
      };

      const getMaxErrorBarYPosition = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        const maxValue = _.clamp(d3.max(errorBarValues), primaryYAxisExtent.min, primaryYAxisExtent.max);
        return d3ColumnYScale(maxValue);
      };

      const getMinErrorBarWidth = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        return self.isInRange(d3.min(errorBarValues), primaryYAxisExtent.min, primaryYAxisExtent.max)
          ? ERROR_BARS_STROKE_WIDTH
          : 0;
      };

      const getMaxErrorBarWidth = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = columnDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        return self.isInRange(d3.max(errorBarValues), primaryYAxisExtent.min, primaryYAxisExtent.max)
          ? ERROR_BARS_STROKE_WIDTH
          : 0;
      };

      const getErrorBarXPosition = (d, measureIndex) => {
        return (columnWidth - errorBarWidth) / 2 + d3GroupingXScale(measureIndex);
      };

      dimensionGroupSvgs
        .selectAll('.error-bar-bottom')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', getMinErrorBarWidth)
        .attr('x1', getErrorBarXPosition)
        .attr('y1', getMinErrorBarYPosition)
        .attr('x2', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth)
        .attr('y2', getMinErrorBarYPosition);

      dimensionGroupSvgs
        .selectAll('.error-bar-top')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', getMaxErrorBarWidth)
        .attr('x1', getErrorBarXPosition)
        .attr('y1', getMaxErrorBarYPosition)
        .attr('x2', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth)
        .attr('y2', getMaxErrorBarYPosition);

      dimensionGroupSvgs
        .selectAll('.error-bar-middle')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', ERROR_BARS_STROKE_WIDTH)
        .attr('x1', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth / 2)
        .attr('y1', getMinErrorBarYPosition)
        .attr('x2', (d, measureIndex) => getErrorBarXPosition(d, measureIndex) + errorBarWidth / 2)
        .attr('y2', getMaxErrorBarYPosition);
    }

    // Note that renderXAxis(), renderYAxis(), renderColumnSeries(), etc. all update the elements that have been
    // created by binding the data (which is done inline in this function below).
    //
    function renderColumnSeries() {
      const getFill = (d, measureIndex) => measures[measureIndex].getColor();

      // Column underlays
      //
      if (!isStacked) {
        dimensionGroupSvgs
          .selectAll('.column-underlay')
          .attr('data-default-fill', getFill)
          .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
          .attr('data-measure-index', (d, measureIndex) => measureIndex)
          .attr('fill', 'transparent')
          .attr('height', height)
          .attr('stroke', 'none')
          .attr('width', Math.max(d3GroupingXScale.rangeBand() - 1, 0))
          .attr('x', (d, measureIndex) => d3GroupingXScale(measureIndex))
          .attr('y', 0);
      }

      // Columns
      //
      const d3ColumnYScale = isUsingSecondaryAxisForColumns() ? d3SecondaryYScale : d3PrimaryYScale;

      const columns = dimensionGroupSvgs
        .selectAll('.column')
        .attr(
          'data-percent',
          (d, measureIndex, dimensionIndex) => columnPositions[dimensionIndex][measureIndex].percent
        )
        .attr('y', (d, measureIndex, dimensionIndex) => {
          const position = columnPositions[dimensionIndex][measureIndex];
          return d3ColumnYScale(position.end);
        })
        .attr('height', (d, measureIndex, dimensionIndex) => {
          const position = columnPositions[dimensionIndex][measureIndex];
          const value = position.end - position.start;
          return Math.max(d3ColumnYScale(0) - d3ColumnYScale(value), 0);
        })
        .attr('data-default-fill', getFill)
        .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
        .attr('data-measure-index', (d, measureIndex) => measureIndex)
        .attr('fill', getFill)
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', 'none');

      if (isStacked) {
        columns.attr('x', 0).attr('width', Math.max(d3DimensionXScale.rangeBand() - 1, 0));
      } else {
        columns
          .attr('x', (d, measureIndex) => d3GroupingXScale(measureIndex))
          .attr('width', Math.max(d3GroupingXScale.rangeBand() - 1, 0));
      }

      lastRenderedSeriesWidth = xAxisAndSeriesSvg.node().getBBox().width;

      if (getShowValueLabels(self.getVif()) && _.isUndefined(columnDataToRender.errorBars)) {
        const isLabelInside = (d, measureIndex, dimensionIndex) =>
          self.isValueLabelInside({
            d,
            dataToRender: columnDataToRender,
            dimensionIndex,
            measureIndex,
            positions: columnPositions,
            scale: d3ColumnYScale
          });

        dimensionGroupSvgs
          .selectAll('text')
          .attr('font-family', FONT_STACK)
          .attr('font-size', `${VALUE_LABEL_FONT_SIZE}px`)
          .attr('stroke', 'none')
          .attr('transform', 'translate(0,0) rotate(-90)')
          .attr('x', (d, measureIndex, dimensionIndex) => {
            const position = self.getPosition(columnPositions, measureIndex, dimensionIndex);
            let x;

            if (self.isBelowMeasureAxisMinValue(d)) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3ColumnYScale(position.start) - VALUE_LABEL_MARGIN
                : d3ColumnYScale(position.end) - VALUE_LABEL_MARGIN;
            } else if (self.isAboveMeasureAxisMaxValue(d)) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3ColumnYScale(position.end) + VALUE_LABEL_MARGIN
                : d3ColumnYScale(position.start) + VALUE_LABEL_MARGIN;
            } else if (d >= 0) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3ColumnYScale(position.end) + VALUE_LABEL_MARGIN
                : d3ColumnYScale(position.end) - VALUE_LABEL_MARGIN;
            } else {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3ColumnYScale(position.start) - VALUE_LABEL_MARGIN
                : d3ColumnYScale(position.start) + VALUE_LABEL_MARGIN;
            }

            return -x;
          })
          .attr('y', (d, measureIndex) =>
            self.getValueLabelY({
              dimensionScale: d3DimensionXScale,
              groupingScale: d3GroupingXScale,
              measureIndex,
              isStacked
            })
          )
          .attr('fill', (d, measureIndex, dimensionIndex) =>
            isLabelInside(d, measureIndex, dimensionIndex)
              ? MEASURE_VALUE_TEXT_INSIDE_BAR_COLOR
              : MEASURE_VALUE_TEXT_OUTSIDE_BAR_COLOR
          )
          .attr('text-anchor', (d, measureIndex, dimensionIndex) => {
            if (self.isBelowMeasureAxisMinValue(d)) {
              return TEXT_ANCHOR_START;
            } else if (self.isAboveMeasureAxisMaxValue(d)) {
              return TEXT_ANCHOR_END;
            } else if (d >= 0) {
              return isLabelInside(d, measureIndex, dimensionIndex) ? TEXT_ANCHOR_END : TEXT_ANCHOR_START;
            } else {
              return isLabelInside(d, measureIndex, dimensionIndex) ? TEXT_ANCHOR_START : TEXT_ANCHOR_END;
            }
          })
          .text((d, measureIndex, dimensionIndex) =>
            isLabelInside(d, measureIndex, dimensionIndex)
              ? self.getMeasureColumnFormattedValueText({
                  dataToRender: columnDataToRender,
                  measureIndex,
                  value: d
                })
              : ''
          );
      }
    }

    function renderLineSeries() {
      const d3LineYScale = isUsingSecondaryAxisForLines() ? d3SecondaryYScale : d3PrimaryYScale;

      const getLineColorFromArray = (d) => getLineColor(d[0]);
      const getLineColor = (d) => {
        const measure = measures[d.seriesIndex];
        assert(d.seriesIndex === measure.measureIndex);
        return measure.getColor();
      };

      const halfBandWidth = d3DimensionXScale.rangeBand() / 2.0;
      const isDefined = (d) => !_.isNull(d.value);
      const getX = (d) => Math.floor(d3DimensionXScale(dimensionValues[d.dimensionIndex]) + halfBandWidth);
      const getY = (d) => Math.floor(d3LineYScale(d.value));
      const getLine = (d) => d3.svg.line().defined(isDefined).x(getX).y(getY)(d);

      const getCircleFill = (d) => {
        const lineStyle = self.getLineStyleBySeriesIndex(d.seriesIndex);
        return lineStyle.points !== LINE_STYLE_POINTS_NONE ? getLineColor(d) : 'transparent';
      };

      const getCircleRadius = (d) => {
        const lineStyle = self.getLineStyleBySeriesIndex(d.seriesIndex);
        return _.isNumber(lineStyle.pointRadius) ? lineStyle.pointRadius : DEFAULT_CIRCLE_HIGHLIGHT_RADIUS;
      };

      const getValue = (d) =>
        self.getMeasureColumnFormattedValueText({
          dataToRender: nonFlyoutDataToRender,
          measureIndex: d.seriesIndex,
          value: d.value
        });

      const getTextTranslation = (d, index) => {
        const y = getY(d);
        let yOffset;

        if (index % 2 == 0) {
          yOffset = DATA_POINT_LABELS_OFFSET_BELOW;

          if (y + yOffset > viewportHeight) {
            yOffset = DATA_POINT_LABELS_OFFSET_ABOVE_MORE;
          }
        } else {
          yOffset = DATA_POINT_LABELS_OFFSET_ABOVE;

          if (y + yOffset < 0) {
            yOffset = DATA_POINT_LABELS_OFFSET_BELOW_MORE;
          }
        }

        const translateY = y + yOffset;
        const translateX = getX(d);
        return `translate(${translateX} ${translateY})`;
      };

      const getTextFill = (d) => {
        // Only fill the labels of the points that are on-screen.
        let showValueLabels;

        if (isUsingSecondaryAxisForLines()) {
          showValueLabels = d.value <= secondaryYAxisExtent.max && d.value >= secondaryYAxisExtent.min;
        } else {
          showValueLabels = d.value <= primaryYAxisExtent.max && d.value >= primaryYAxisExtent.min;
        }

        return showValueLabels ? DATA_POINT_LABELS_FONT_COLOR : 'transparent';
      };

      lineSvgs.attr('d', getLine).attr('stroke', getLineColorFromArray);

      circleSvgs
        .attr('cx', getX)
        .attr('cy', getY)
        .attr('data-default-fill', 'transparent')
        .attr('fill', getCircleFill)
        .attr('r', getCircleRadius);

      if (getShowLineValueLabels(self.getVif())) {
        textSvgs
          .attr('fill', getTextFill)
          .attr('font-family', FONT_STACK)
          .attr('font-size', `${DATA_POINT_LABELS_FONT_SIZE}px`)
          .attr('style', 'text-anchor: middle')
          .attr('transform', getTextTranslation)
          .text(getValue);
      }
    }

    function handleZoom() {
      lastRenderedZoomTranslate = _.clamp(d3.event.translate[0], -xAxisPanDistance, 0);

      // We need to override d3's internal translation since it doesn't seem to
      // respect our snapping to the beginning and end of the rendered data.
      d3Zoom.translate([lastRenderedZoomTranslate, 0]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('polygon')
        .attr('transform', `translate(${-lastRenderedZoomTranslate},0)`);

      xAxisAndSeriesSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);

      if (self.isMobile()) {
        hideHighlight();
        hideFlyout();
      }

      hideOffscreenDimensionLabels({ viewportSvg, lastRenderedZoomTranslate, viewportWidth });
    }

    function restoreLastRenderedZoom() {
      const translateXRatio =
        lastRenderedSeriesWidth !== 0 ? Math.abs(lastRenderedZoomTranslate / lastRenderedSeriesWidth) : 0;
      const currentWidth = xAxisAndSeriesSvg.node().getBBox().width;

      lastRenderedZoomTranslate = _.clamp(-translateXRatio * currentWidth, -xAxisPanDistance, 0);

      d3Zoom.translate([lastRenderedZoomTranslate, 0]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('polygon')
        .attr('transform', `translate(${-lastRenderedZoomTranslate},0)`);

      xAxisAndSeriesSvg.attr('transform', `translate(${lastRenderedZoomTranslate},0)`);
    }

    function getYAxisExtent({
      dataToRender,
      measureAxisMaxValue,
      measureAxisMinValue,
      measureAxisScale,
      referenceLines
    }) {
      const rowValues = _.flatMap(dataToRender.rows, (row) => row.slice(dataTableDimensionIndex + 1));

      const errorBarValues = _.flatMapDeep(dataToRender.errorBars, (row) =>
        row.slice(dataTableDimensionIndex + 1)
      );

      const extent = self.getRowValueExtent({
        errorBarValues,
        referenceLines,
        rowValues
      });

      let max;
      let min;

      if (measureAxisMaxValue) {
        max = measureAxisMaxValue;
      } else if (measureAxisScale === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
        max = extent.max;
      } else {
        max = Math.max(extent.max, 0);
      }

      if (measureAxisMinValue) {
        min = measureAxisMinValue;
      } else if (measureAxisScale === MEASURE_AXIS_SCALE_MIN_TO_MAX) {
        min = extent.min;
      } else {
        min = Math.min(extent.min, 0);
      }

      return { max, min };
    }

    function getSummedYAxisExtent({
      columnRows,
      lineRows,
      measureAxisMaxValue,
      measureAxisMinValue,
      referenceLines
    }) {
      let max;
      let min;

      const lineRowValues = _.flatMap(lineRows, (row) => row.slice(dataTableDimensionIndex + 1));

      const extent = self.getRowValueSummedExtent({
        columnOrBarRows: columnRows,
        dimensionIndex: dataTableDimensionIndex,
        lineRowValues,
        referenceLines
      });

      if (getYAxisScalingMode(self.getVif()) === 'showZero' && !measureAxisMinValue) {
        min = Math.min(extent.min, 0);
      } else if (measureAxisMinValue) {
        min = measureAxisMinValue;
      } else {
        min = extent.min;
      }

      if (getYAxisScalingMode(self.getVif()) === 'showZero' && !measureAxisMaxValue) {
        max = Math.max(extent.max, 0);
      } else if (measureAxisMaxValue) {
        max = measureAxisMaxValue;
      } else {
        max = extent.max;
      }

      return { max, min };
    }

    /**
     * 1. Prepare the data for rendering (unfortunately we need to do grouping
     *    on the client at the moment).
     */
    const isStacked = isVifStacked(self.getVif());

    // Get the data for primary and secondary axes
    //
    let primaryAxisDataToRender;
    let primaryAxisFirstNonFlyoutSeries;
    let secondaryAxisDataToRender;
    let secondaryAxisFirstNonFlyoutSeries;

    if (isUsingSecondaryAxisForColumns() && !isUsingSecondaryAxisForLines()) {
      primaryAxisDataToRender = lineDataToRender;
      primaryAxisFirstNonFlyoutSeries = self.getFirstSeriesOfType(['comboChart.line']);
      secondaryAxisDataToRender = columnDataToRender;
      secondaryAxisFirstNonFlyoutSeries = self.getFirstSeriesOfType(['comboChart.column']);
    } else if (!isUsingSecondaryAxisForColumns() && isUsingSecondaryAxisForLines()) {
      primaryAxisDataToRender = columnDataToRender;
      primaryAxisFirstNonFlyoutSeries = self.getFirstSeriesOfType(['comboChart.column']);
      secondaryAxisDataToRender = lineDataToRender;
      secondaryAxisFirstNonFlyoutSeries = self.getFirstSeriesOfType(['comboChart.line']);
    } else if (!isUsingSecondaryAxisForColumns() && !isUsingSecondaryAxisForLines()) {
      primaryAxisDataToRender = nonFlyoutDataToRender;
      primaryAxisFirstNonFlyoutSeries = self.getFirstSeriesOfType(['comboChart.column', 'comboChart.line']);
      secondaryAxisDataToRender = null;
      secondaryAxisFirstNonFlyoutSeries = null;
    } else {
      primaryAxisDataToRender = null;
      primaryAxisFirstNonFlyoutSeries = null;
      secondaryAxisDataToRender = nonFlyoutDataToRender;
      secondaryAxisFirstNonFlyoutSeries = self.getFirstSeriesOfType(['comboChart.column', 'comboChart.line']);
    }

    // Get the left and right margins and viewportWidth
    //
    const leftMargin =
      self.calculateLeftOrRightMargin({
        dataToRender: primaryAxisDataToRender,
        height: viewportHeight,
        isSecondaryAxis: false
      }) + (axisLabels.left ? AXIS_LABEL_MARGIN : 0);

    const rightMargin =
      self.calculateLeftOrRightMargin({
        dataToRender: secondaryAxisDataToRender,
        height: viewportHeight,
        isSecondaryAxis: true
      }) + (axisLabels.right ? AXIS_LABEL_MARGIN : 0);

    let viewportWidth = Math.max(0, $chartElement.width() - leftMargin - rightMargin);

    // There may be instances where the chart is rendering in a hidden element. If this
    // happens, we shouldn't need to continue through this function. Allowing processing
    // to continue and calling `renderLegend()` would put the chart in an state where
    // parts of the chart don't render properly once they come into view (see EN-40617).
    if (viewportHeight <= 0 || viewportWidth <= 0) {
      return;
    }

    // Render legend as it adjusts the viewport dimensions
    const adjustedViewportSize = renderLegend(self, {
      measures,
      referenceLines,
      viewportSize: {
        height: viewportHeight,
        width: viewportWidth
      }
    });

    viewportHeight = adjustedViewportSize.height;
    viewportWidth = adjustedViewportSize.width;

    numberOfGroups = nonFlyoutDataToRender.rows.length;
    numberOfItemsPerGroup = isStacked ? 1 : columnDataToRender.columns.length - 1;

    // When we do allow panning we get a little more sophisticated; primarily
    // we will attempt to adjust the width we give to d3 to account for the
    // width of the labels, which will extend past the edge of the chart
    // since they are rotated by 45 degrees.
    //
    // Since we know the maximum number of items in a group and the total
    // number of groups we can estimate the total width of the chart (this
    // will necessarily be incorrect because we won't actually know the width
    // of the last label until we render it, at which time we will
    // re-measure. This estimate will be sufficient to get d3 to render the
    // columns at widths that are in line with our expectations, however.
    width = Math.max(viewportWidth, columnWidth * numberOfGroups * numberOfItemsPerGroup);

    // Compute height based on the presence or absence of x-axis data labels.
    if (getShowDimensionLabels(self.getVif())) {
      height = viewportHeight;
    } else {
      // In this case we want to mirror the top margin on the bottom so
      // that the chart is visually centered (column charts have no bottom
      // margin by default).
      height = Math.max(0, viewportHeight - MARGINS.TOP);
    }

    /**
     * 2. Set up the x-scale and -axis.
     */

    // This scale is used for dimension categories.
    d3DimensionXScale = generateXScale(
      dimensionValues,
      width,
      isGroupingOrHasMultipleNonFlyoutSeries(self.getVif())
    );

    // This scale is used for groupings of columns under a single dimension
    // category.
    d3GroupingXScale = generateXGroupScale(
      _.range(0, columnDataToRender.columns.length - 1),
      d3DimensionXScale
    );

    d3XAxis = generateXAxis(d3DimensionXScale);

    /**
     * 3. Set up the y-scale and y-axis.
     */

    try {
      // Get primary measure axis bounds
      //
      const primaryMeasureAxisMinValue = getMeasureAxisMinValue(self.getVif());
      const primaryMeasureAxisMaxValue = getMeasureAxisMaxValue(self.getVif());

      if (
        primaryMeasureAxisMinValue &&
        primaryMeasureAxisMaxValue &&
        primaryMeasureAxisMinValue >= primaryMeasureAxisMaxValue
      ) {
        self.renderError(
          I18n.t(
            'shared.visualizations.charts.common.validation.errors.' +
              'measure_axis_min_should_be_lesser_then_max'
          )
        );
        return;
      }

      // Get secondary measure axis bounds
      //
      const secondaryMeasureAxisMinValue = getSecondaryMeasureAxisMinValue();
      const secondaryMeasureAxisMaxValue = getSecondaryMeasureAxisMaxValue();

      if (
        secondaryMeasureAxisMinValue &&
        secondaryMeasureAxisMaxValue &&
        secondaryMeasureAxisMinValue >= secondaryMeasureAxisMaxValue
      ) {
        self.renderError(
          I18n.t(
            'shared.visualizations.charts.common.validation.errors.' +
              'measure_axis_min_should_be_lesser_then_max'
          )
        );
        return;
      }

      // Get max and min for primary Y axis
      //
      if (isUsingPrimaryAxis()) {
        if (isStacked) {
          primaryYAxisExtent = getSummedYAxisExtent({
            columnRows: isUsingSecondaryAxisForColumns() ? null : columnDataToRender.rows,
            lineRows: isUsingSecondaryAxisForLines() ? null : lineDataToRender.rows,
            measureAxisMaxValue: primaryMeasureAxisMaxValue,
            measureAxisMinValue: primaryMeasureAxisMinValue,
            referenceLines // reference lines use the primary axis
          });
        } else {
          primaryYAxisExtent = getYAxisExtent({
            dataToRender: primaryAxisDataToRender,
            measureAxisMaxValue: primaryMeasureAxisMaxValue,
            measureAxisMinValue: primaryMeasureAxisMinValue,
            measureAxisScale: getMeasureAxisScale(self.getVif()),
            referenceLines // reference lines use the primary axis
          });
        }

        if (primaryYAxisExtent.min > primaryYAxisExtent.max) {
          self.renderError(
            I18n.t(
              'shared.visualizations.charts.common.validation.errors.' +
                'measure_axis_biggest_value_should_be_more_than_min_limit'
            )
          );
          return;
        }
      }

      // Get max and min for secondary Y axis
      //
      if (isUsingSecondaryAxis()) {
        if (isStacked) {
          secondaryYAxisExtent = getSummedYAxisExtent({
            columnRows: isUsingSecondaryAxisForColumns() ? columnDataToRender.rows : null,
            lineRows: isUsingSecondaryAxisForLines() ? lineDataToRender.rows : null,
            measureAxisMaxValue: secondaryMeasureAxisMaxValue,
            measureAxisMinValue: secondaryMeasureAxisMinValue
          });
        } else {
          secondaryYAxisExtent = getYAxisExtent({
            dataToRender: secondaryAxisDataToRender,
            measureAxisMaxValue: secondaryMeasureAxisMaxValue,
            measureAxisMinValue: secondaryMeasureAxisMinValue,
            measureAxisScale: getSecondaryMeasureAxisScale(self.getVif())
          });
        }

        if (secondaryYAxisExtent.min > secondaryYAxisExtent.max) {
          self.renderError(
            I18n.t(
              'shared.visualizations.charts.common.validation.errors.' +
                'measure_axis_biggest_value_should_be_more_than_min_limit'
            )
          );
          return;
        }
      }
    } catch (error) {
      self.renderError(error.message);
      return;
    }

    // Get the column positions
    //
    if (isStacked) {
      columnPositions = self.getStackedPositionsForRange(
        columnDataToRender.rows,
        isUsingSecondaryAxisForColumns() ? secondaryYAxisExtent.min : primaryYAxisExtent.min,
        isUsingSecondaryAxisForColumns() ? secondaryYAxisExtent.max : primaryYAxisExtent.max
      );
    } else {
      columnPositions = self.getPositionsForRange(
        columnDataToRender.rows,
        isUsingSecondaryAxisForColumns() ? secondaryYAxisExtent.min : primaryYAxisExtent.min,
        isUsingSecondaryAxisForColumns() ? secondaryYAxisExtent.max : primaryYAxisExtent.max
      );
    }

    // Get the line positions
    //
    linePositions = getLinePositions(lineDataToRender);

    // Get the circle positions
    //
    circlePositions = _.flatten(linePositions);

    // Get the Y scales
    //
    if (isUsingPrimaryAxis()) {
      d3PrimaryYScale = self.generateYScale(primaryYAxisExtent.min, primaryYAxisExtent.max, height);
      d3PrimaryYAxis = self.generateYAxis({
        dataToRender: nonFlyoutDataToRender,
        height,
        isSecondaryAxis: false,
        scale: d3PrimaryYScale,
        series: primaryAxisFirstNonFlyoutSeries
      });
    }

    if (isUsingSecondaryAxis()) {
      d3SecondaryYScale = self.generateYScale(secondaryYAxisExtent.min, secondaryYAxisExtent.max, height);
      d3SecondaryYAxis = self.generateYAxis({
        dataToRender: nonFlyoutDataToRender,
        height,
        isSecondaryAxis: true,
        scale: d3SecondaryYScale,
        series: secondaryAxisFirstNonFlyoutSeries
      });
    }

    /**
     * 4. Clear out any existing chart.
     */

    d3.select($chartElement[0]).select('svg').remove();

    /**
     * 5. Render the chart.
     */

    // Create the top-level <svg> element first.
    chartSvg = d3
      .select($chartElement[0])
      .append('svg')
      .attr('width', width + leftMargin + rightMargin)
      .attr('height', viewportHeight + topMargin + bottomMargin);

    // The viewport represents the area within the chart's container that can
    // be used to draw the x-axis, y-axis and chart marks.
    viewportSvg = chartSvg
      .append('g')
      .attr('class', 'viewport')
      .attr('transform', `translate(${leftMargin},${topMargin})`);

    // The clip path is used as a mask. It is attached to another svg element,
    // at which time all children of that svg element that would be drawn
    // outside of the clip path's bounds will not be rendered. The clip path
    // is used in this implementation to hide the extent of the chart that lies
    // outside of the viewport when the chart is wider than the viewport.
    //
    // The overall effect is for the chart to appear to pan.
    const clipPathSvg = chartSvg.append('clipPath').attr('id', panningClipPathId);

    viewportSvg.append('g').attr('class', 'y axis');

    viewportSvg.append('g').attr('class', 'y grid');

    viewportSvg
      .append('g')
      .attr('class', 'y secondaryAxis')
      .attr('transform', `translate(${viewportWidth},0)`);

    viewportSvg.append('g').attr('class', 'y secondaryGrid');

    // This <rect> exists to capture mouse actions on the chart, but not
    // directly on the columns or labels, that should result in a pan behavior.
    // If we set stroke and fill to none, the mouse events don't seem to get
    // picked up, so we instead set opacity to 0.
    viewportSvg
      .append('rect')
      .attr('class', 'dragger')
      .attr('width', width)
      .attr('height', viewportHeight)
      .attr('opacity', 0);

    // The x-axis and series are groups since they all need to conform to the
    // same clip path for the appearance of panning to be upheld.
    xAxisAndSeriesSvg = viewportSvg
      .append('g')
      .attr('class', 'x-axis-and-series')
      .attr('clip-path', `url(#${panningClipPathId})`);

    seriesSvg = xAxisAndSeriesSvg
      .append('g')
      .attr('class', 'series')
      .attr('clip-path', `url(#${measureBoundsClipPathId})`);

    // Render the measure axes bounds clip path.
    xAxisAndSeriesSvg
      .append('clipPath')
      .attr('id', measureBoundsClipPathId)
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', viewportHeight);

    dimensionGroupSvgs = seriesSvg
      .selectAll('.dimension-group')
      .data(columnDataToRender.rows)
      .enter()
      .append('g');

    dimensionGroupSvgs
      .attr('class', 'dimension-group')
      .attr('data-dimension-index', (d, dimensionIndex) => dimensionIndex)
      .attr('transform', (d) => `translate(${d3DimensionXScale(d[0])},0)`);

    if (!isStacked) {
      dimensionGroupSvgs
        .selectAll('rect.column-underlay')
        .data((d) => d.slice(1))
        .enter()
        .append('rect')
        .attr('class', 'column-underlay');
    }

    dimensionGroupSvgs
      .selectAll('rect.column')
      .data((d) => d.slice(1))
      .enter()
      .append('rect')
      .attr('class', 'column');

    lineSvgs = seriesSvg
      .selectAll('path.line-series')
      .data(linePositions)
      .enter()
      .append('path')
      .attr('class', 'line-series');

    circleSvgs = seriesSvg
      .selectAll('circle.circle-series')
      .data(circlePositions)
      .enter()
      .append('circle')
      .attr('class', 'circle-series');

    if (getShowLineValueLabels(self.getVif())) {
      textSvgs = seriesSvg
        .selectAll('text.text-series')
        .data(circlePositions)
        .enter()
        .append('text')
        .attr('class', 'text-series');
    }

    referenceLineSvgs = seriesSvg
      .selectAll('line.reference-line')
      .data(referenceLines)
      .enter()
      .append('line')
      .attr('class', 'reference-line');

    referenceLineUnderlaySvgs = seriesSvg
      .selectAll('rect.reference-line-underlay')
      .data(referenceLines)
      .enter()
      .append('rect')
      .attr('class', 'reference-line-underlay');

    if (!_.isUndefined(columnDataToRender.errorBars)) {
      dimensionGroupSvgs
        .selectAll('line.error-bar-top')
        .data((d) => d.slice(1))
        .enter()
        .append('line')
        .attr('class', 'error-bar-top');

      dimensionGroupSvgs
        .selectAll('line.error-bar-middle')
        .data((d) => d.slice(1))
        .enter()
        .append('line')
        .attr('class', 'error-bar-middle');

      dimensionGroupSvgs
        .selectAll('line.error-bar-bottom')
        .data((d) => d.slice(1))
        .enter()
        .append('line')
        .attr('class', 'error-bar-bottom');
    }

    if (getShowValueLabels(self.getVif()) && _.isUndefined(columnDataToRender.errorBars)) {
      columnTextSvgs = dimensionGroupSvgs
        .selectAll('text')
        .data((d) => d.slice(1))
        .enter()
        .append('text');

      columnTextSvgs
        .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
        .attr('data-measure-index', (d, measureIndex) => measureIndex);
    }

    // Unfortunately, we need to render the x-axis and the series before we
    // can measure whether or not the chart will pan. Since showing the
    // panning notice also affects the height available to the chart, that
    // means that we need to render once to measure if the chart to pan and if
    // it does, show the panning notice and then re-render the x-axis and the
    // series at the new (smaller) height to accommodate the notice.
    //
    // Also note that we must render the x-axis before setting up the event
    // handlers for flyouts below, since it attempts to bind data to elements
    // that will not exist before the x-axis has been rendered.
    renderXAxis();
    renderColumnSeries();
    renderLineSeries();

    // This is the actual rendered width (which accounts for the labels
    // extending beyond what d3 considers the right edge of the chart on
    // account of their being rotated 45 degrees.
    width = xAxisAndSeriesSvg.node().getBBox().width;

    // The width was being calculated slightly off (by .0001) for small chart sizes, so using fixed precision to calculate.
    // This issue was previously not uncovered because we were not rendering the panning notice for small chart sizes
    xAxisPanDistance = width.toFixed(3) - viewportWidth;
    xAxisPanningEnabled = xAxisPanDistance > 0;

    if (xAxisPanningEnabled) {
      self.showPanningNotice();

      viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);

      // Note that we need to recompute height here since
      // $chartElement.height() may have changed when we showed the panning
      // notice.
      if (getShowDimensionLabels(self.getVif())) {
        height = viewportHeight;
      } else {
        height = Math.max(0, viewportHeight - MARGINS.TOP);
      }

      if (isUsingPrimaryAxis()) {
        d3PrimaryYScale = self.generateYScale(primaryYAxisExtent.min, primaryYAxisExtent.max, height);
        d3PrimaryYAxis = self.generateYAxis({
          dataToRender: nonFlyoutDataToRender,
          height,
          isSecondaryAxis: false,
          scale: d3PrimaryYScale,
          series: primaryAxisFirstNonFlyoutSeries
        });
      }

      if (isUsingSecondaryAxis()) {
        d3SecondaryYScale = self.generateYScale(secondaryYAxisExtent.min, secondaryYAxisExtent.max, height);
        d3SecondaryYAxis = self.generateYAxis({
          dataToRender: nonFlyoutDataToRender,
          height,
          isSecondaryAxis: true,
          scale: d3SecondaryYScale,
          series: secondaryAxisFirstNonFlyoutSeries
        });
      }

      renderXAxis();
      renderColumnSeries();
      renderLineSeries();
    } else {
      self.hidePanningNotice();
    }

    // NOTE: We specifically measure out the clip path _after_ deciding whether to show
    // the panning notice as the panning notice can affect the height of the chart.
    const maskWidth = viewportWidth + leftMargin + rightMargin;
    const maskHeight = viewportHeight + topMargin + bottomMargin;
    const yAxisHeightAndTicks = height + D3_TICK_SIZE;

    const points = [
      '0,0', // top of Y-axis
      `0,${yAxisHeightAndTicks}`, // just below chart origin on Y-axis
      `${-leftMargin},${yAxisHeightAndTicks}`, // jut out to the left edge
      `${-leftMargin},${maskHeight}`, // continue to the bottom left corner
      `${maskWidth},${maskHeight}`, // bottom right corner
      `${maskWidth},${yAxisHeightAndTicks}`, // just below x-axis on the right edge
      `${viewportWidth},${yAxisHeightAndTicks}`, // just below x-axis on the right axis
      `${viewportWidth},0` // top and on the right axis
    ];

    clipPathSvg.append('polygon').attr('points', points.join(' '));

    // We only have to render the y-axis once, after we have decided whether
    // we will show or hide the panning notice.
    if (isUsingPrimaryAxis()) {
      renderPrimaryYAxis();
    }

    if (isUsingSecondaryAxis()) {
      renderSecondaryYAxis();
    }

    renderReferenceLines();
    renderErrorBars();

    labelResizer.update(leftMargin, topMargin + height, width);

    /**
     * 6. Set up event handlers for mouse interactions.
     */
    if (!isStacked && referenceLines.length == 0) {
      dimensionGroupSvgs
        .selectAll('rect.column-underlay')
        // NOTE: The below function depends on this being set by d3, so it is
        // not possible to use the () => {} syntax here.
        .on('mousemove', function () {
          if (!isCurrentlyPanning()) {
            const measureIndex = parseInt(this.getAttribute('data-measure-index'), 10);
            const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

            const dimensionGroup = this.parentNode;
            const siblingColumn = d3
              .select(dimensionGroup)
              .select(`rect.column[data-measure-index="${measureIndex}"]`)[0][0];

            // d3's .datum() method gives us the entire row, whereas everywhere
            // else measureIndex refers only to measure values. We therefore
            // add one to measure index to get the actual measure value from
            // the raw row data provided by d3 (the value at element 0 of the
            // array returned by .datum() is the dimension value).
            const row = d3.select(this.parentNode).datum();
            const dimensionValue = row[0];
            const value = row[measureIndex + 1];

            showColumnHighlight(siblingColumn);
            showColumnFlyout({
              dimensionIndex,
              dimensionValue,
              element: siblingColumn,
              measureIndex,
              value
            });
          }
        })
        .on('mouseleave', () => {
          if (!isCurrentlyPanning()) {
            hideHighlight();
            hideFlyout();
          }
        });
    }

    dimensionGroupSvgs
      .selectAll('rect.column')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (!isCurrentlyPanning()) {
          const measureIndex = parseInt(this.getAttribute('data-measure-index'), 10);
          const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

          // d3's .datum() method gives us the entire row, whereas everywhere
          // else measureIndex refers only to measure values. We therefore
          // add one to measure index to get the actual measure value from
          // the raw row data provided by d3 (the value at element 0 of the
          // array returned by .datum() is the dimension value).
          const row = d3.select(this.parentNode).datum();
          const dimensionValue = row[0];
          const value = row[measureIndex + 1];
          const percent = isStacked ? parseFloat(this.getAttribute('data-percent')) : undefined;

          showColumnHighlight(this);
          showColumnFlyout({
            dimensionIndex,
            dimensionValue,
            element: this,
            measureIndex,
            percent,
            value
          });
        }
      })
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideHighlight();
          hideFlyout();
        }
      });

    seriesSvg
      .selectAll('circle.circle-series')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function (d) {
        if (!isCurrentlyPanning()) {
          // The datum is an object with dimensionIndex, dimensionValue, seriesIndex and value properties.
          const { dimensionIndex, dimensionValue, seriesIndex, value } = d;
          showCircleHighlight(this, seriesIndex);
          showColumnFlyout({
            dimensionIndex,
            dimensionValue,
            element: this,
            measureIndex: seriesIndex,
            value
          });
        }
      })
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideHighlight();
          hideFlyout();
        }
      });

    chartSvg
      .selectAll('.x.axis .tick text')
      .on('mousemove', (d, dimensionIndex) => {
        if (!isCurrentlyPanning()) {
          const dimensionValue = d[0];
          // We need to find nodes with a data-dimension-index attribute matching dimensionIndex.
          // We can't easily use a CSS selector because we lack a simple API to apply CSS-string escaping
          // rules.
          // There's a working draft for a CSS.escape and jQuery >= 3.0 has a $.escapeSelector,
          // but both of those are out of reach for us at the moment.
          const groupElement = d3.select(
            _(xAxisAndSeriesSvg.node().querySelectorAll('g.dimension-group[data-dimension-index]'))
              .filter((group) => group.getAttribute('data-dimension-index') === String(dimensionIndex))
              .first()
          );

          if (groupElement.empty()) {
            return;
          }

          showGroupFlyout({
            dimensionIndex,
            dimensionValue,
            groupElement
          });
        }
      })
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideFlyout();
        }
      });

    seriesSvg
      .selectAll('.reference-line-underlay')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (!isCurrentlyPanning()) {
          const underlayHeight = parseInt($(this).attr('height'), 10);
          const flyoutOffset = {
            left: d3.event.clientX,
            top: $(this).offset().top + underlayHeight / 2 - window.pageYOffset
          };

          self.showReferenceLineFlyout({
            dataToRender: primaryAxisDataToRender,
            element: this,
            flyoutOffset,
            referenceLines
          });

          $(this).attr('fill-opacity', 1);
        }
      })
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mouseleave', function () {
        if (!isCurrentlyPanning()) {
          hideFlyout();
          $(this).attr('fill-opacity', 0);
        }
      });

    /**
     * 7. Conditionally set up the zoom behavior, which is actually used for
     *    panning the chart along the x-axis if panning is enabled.
     */

    if (xAxisPanningEnabled) {
      d3Zoom = d3.behavior.zoom().on('zoom', handleZoom);

      viewportSvg
        .attr('cursor', 'move')
        .call(d3Zoom)
        // By default the zoom behavior seems to capture every conceivable
        // kind of zooming action; we actually just want it to zoom when
        // the user clicks and drags, so we need to immediately deregister
        // the event handlers for the other types.
        //
        // Note that although we listen for the zoom event on the zoom
        // behavior we must detach the zooming actions we do not want to
        // respond to from the element to which the zoom behavior is
        // attached.
        .on('dblclick.zoom', null)
        .on('wheel.zoom', null)
        .on('mousewheel.zoom', null)
        .on('MozMousePixelScroll.zoom', null);

      restoreLastRenderedZoom();

      SvgKeyboardPanning(d3Zoom, chartSvg, '.socrata-visualization-panning-notice');

      chartSvg.selectAll('text').attr('cursor', null);
    } else {
      chartSvg.selectAll('text').attr('cursor', 'default');
    }

    self.renderAxisLabels(chartSvg, {
      x: leftMargin,
      y: topMargin,
      width: viewportWidth,
      height: viewportHeight
    });
  }

  function generateXScale(domain, width, hasMultipleNonFlyoutSeries) {
    const padding = hasMultipleNonFlyoutSeries ? 0.3 : 0.1;

    return (
      d3.scale
        .ordinal()
        .domain(domain)
        // .rangeRoundBands(<interval>, <padding>, <outer padding>)
        //
        // From the documentation:
        //
        // ---
        //
        // Note that rounding necessarily introduces additional outer padding
        // which is, on average, proportional to the length of the domain.
        // For example, for a domain of size 50, an additional 25px of outer
        // padding on either side may be required. Modifying the range extent to
        // be closer to a multiple of the domain length may reduce the additional
        // padding.
        //
        // ---
        // The outer padding looks pretty funny for our use cases, so we
        // override it to be zero, which looks like what we expect.
        .rangeRoundBands([0, width], padding, 0.05)
    );
  }

  function generateXGroupScale(domain, xScale) {
    return d3.scale.ordinal().domain(domain).rangeRoundBands([0, xScale.rangeBand()]);
  }

  function generateXAxis(xScale) {
    const columnName = _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');
    return d3.svg
      .axis()
      .scale(xScale)
      .orient('bottom')
      .tickFormat((d) =>
        self.getColumnFormattedValueText({
          columnName,
          dataToRender: nonFlyoutDataToRender,
          value: d
        })
      )
      .outerTickSize(0);
  }

  // getLinePositions returns data for the line SVGs to render.  It takes a dataToRender object,
  // and returns an array of series data of the following form:
  //
  // [{
  //   dimensionIndex: 0,
  //   dimensionValue: 'Central District',
  //   seriesIndex: 0,
  //   value: 103000
  // }]
  //
  function getLinePositions(dataToRender) {
    const table = dataToRender.rows.map((row) => row.slice(1)); // remove dimension column
    const transposedTable = _.unzip(table); // transpose rows and columns

    return transposedTable.map(
      (
        row,
        measureIndex // for each row
      ) =>
        row.map(
          (
            value,
            dimensionIndex // for each item in row
          ) =>
            _.extend(
              {},
              {
                // extend each item with dimensionIndex & seriesIndex
                dimensionIndex,
                dimensionValue: dataToRender.rows[dimensionIndex][0],
                seriesIndex: dataToRender.seriesIndices[measureIndex + 1],
                value
              }
            )
        )
    );
  }

  function isCurrentlyPanning() {
    // EN-10810 - Bar Chart flyouts do not appear in Safari
    //
    // Internet Explorer will apparently always return a non-zero value for
    // d3.event.which and even d3.event.button, so we need to check
    // d3.event.buttons for a non-zero value (which indicates that a button is
    // being pressed).
    //
    // Safari apparently does not support d3.event.buttons, however, so if it
    // is not a number then we will fall back to d3.event.which to check for a
    // non-zero value there instead.
    //
    // Chrome appears to support both cases, and in the conditional below
    // Chrome will check d3.event.buttons for a non-zero value.
    return _.isNumber(d3.event.buttons) ? d3.event.buttons !== 0 : d3.event.which !== 0;
  }

  function showCircleHighlight(circleElement, measureIndex) {
    const measure = measures[measureIndex];
    d3.select(circleElement).attr('fill', measure.getColor());
  }

  function showColumnHighlight(columnElement) {
    const selection = d3.select(columnElement);

    // NOTE: The below function depends on this being set by d3, so it is not
    // possible to use the () => {} syntax here.
    selection.attr('fill', function () {
      const seriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(
        parseInt(this.getAttribute('data-measure-index'), 10)
      );
      const highlightColor = self.getHighlightColorBySeriesIndex(seriesIndex);

      return highlightColor !== null ? highlightColor : selection.attr('fill');
    });
  }

  function hideHighlight() {
    // NOTE: The below function depends on this being set by d3, so it is not
    // possible to use the () => {} syntax here.
    d3.selectAll('rect.column').each(function () {
      const selection = d3.select(this);
      selection.attr('fill', selection.attr('data-default-fill'));
    });

    const lineStylePoints = getLineStylePoints(self.getVif());
    if (_.isNil(lineStylePoints) || lineStylePoints === LINE_STYLE_POINTS_NONE) {
      // NOTE: The below function depends on this being set by d3, so it is not
      // possible to use the () => {} syntax here.
      d3.selectAll('circle.circle-series').each(function () {
        const selection = d3.select(this);
        selection.attr('fill', selection.attr('data-default-fill'));
      });
    }
  }

  function showGroupFlyout({ dimensionIndex, dimensionValue, groupElement }) {
    const $content = self.getGroupFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measures,
      nonFlyoutDataToRender
    });

    // Payload
    const payload = {
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    // If there is only one bar in the group then we can position the flyout
    // over the bar itself, not the bar group.
    if (groupElement.selectAll('rect.column')[0].length === 1) {
      _.set(payload, 'element', groupElement[0][0].childNodes[1]);
    } else {
      // Calculate the offsets from screen (0, 0) to the top of the tallest
      // column (where at least one value in the group is > 0) or 0 on the
      // y-axis (where all values in the group are <= 0) and the horizontal
      // center of the group in question.
      const flyoutElementBoundingClientRect = groupElement[0][0].getBoundingClientRect();
      const flyoutElementLeftOffset = flyoutElementBoundingClientRect.left;
      const flyoutElementBottomOffset = flyoutElementBoundingClientRect.bottom;
      const halfGroupWidth = Math.ceil(flyoutElementBoundingClientRect.width / 2.0) + 1;

      _.set(payload, 'flyoutOffset', {
        top: flyoutElementBottomOffset,
        left: flyoutElementLeftOffset + halfGroupWidth
      });
    }

    self.emitEvent('SOCRATA_VISUALIZATION_COMBO_CHART_FLYOUT', payload);
  }

  function showColumnFlyout({ dimensionIndex, dimensionValue, element, measureIndex, percent, value }) {
    const $content = self.getFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measureIndex,
      measures,
      nonFlyoutDataToRender,
      percent,
      value
    });

    const payload = {
      element,
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    self.emitEvent('SOCRATA_VISUALIZATION_COMBO_CHART_FLYOUT', payload);
  }

  function hideFlyout() {
    self.emitEvent('SOCRATA_VISUALIZATION_COMBO_CHART_FLYOUT', null);
  }

  function isUsingPrimaryAxis() {
    return !isUsingSecondaryAxisForColumns() || !isUsingSecondaryAxisForLines();
  }

  function isUsingSecondaryAxis() {
    return isUsingSecondaryAxisForColumns() || isUsingSecondaryAxisForLines();
  }

  function isUsingSecondaryAxisForColumns() {
    return _.get(self.getVif(), 'configuration.useSecondaryAxisForColumns', false);
  }

  function isUsingSecondaryAxisForLines() {
    return _.get(self.getVif(), 'configuration.useSecondaryAxisForLines', false);
  }

  function getSecondaryMeasureAxisMaxValue() {
    const value = _.get(self.getVif(), 'configuration.secondaryMeasureAxisMaxValue', null);
    return validateAxisValue(value, 'measure_axis_max_value_should_be_numeric');
  }

  function getSecondaryMeasureAxisMinValue() {
    const value = _.get(self.getVif(), 'configuration.secondaryMeasureAxisMinValue', null);
    return validateAxisValue(value, 'measure_axis_min_value_should_be_numeric');
  }
}

export default SvgComboChart;
