import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import { StatusIcons, StatusValues } from 'common/performance_measures/lib/constants';


/**
 * Creates a status banner for the Measure Result Card.
 *
 * Status is user configured as part of measure creation.
 * Props:
 * - value -- specifies the color, icon, and default label. If not provided, the status banner is disabled.
 * - labels -- overrides specific to each possible value
 */
const StatusBanner = ({ ended, value, labels }) => {
  if (!value) {
    return null;
  }

  const labelOverride = labels && labels[value];
  const labelDefault = I18n.t(`shared.performance_measures.measure.status.values.${value}`);
  const label = ended ? labels.ended : (labelOverride || labelDefault);
  const icon = StatusIcons[value];

  return (
    <div className={`status-banner status-banner-${value}`}>
      <div className="status-banner-content">
        {icon && <SocrataIcon name={icon} />}
        <span className="status-banner-text" title={label}>{label}</span>
      </div>
      <div className="flag-ends"></div>
    </div>
  );
};

StatusBanner.propTypes = {
  ended: PropTypes.bool,
  labels: PropTypes.objectOf(PropTypes.string),
  value: PropTypes.oneOf(_.values(StatusValues))
};

export default StatusBanner;
