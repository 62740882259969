import _ from 'lodash';
import { AlertPreferenceAPI } from 'common/notifications/api/AlertPreferenceAPI';

export const UPDATE_PREFERENCE_SETTINGS = 'UPDATE_PREFERENCE_SETTINGS';

export const updatePreferenceSettings = (options) => ({
  type: UPDATE_PREFERENCE_SETTINGS,
  ...options
});

export const loadPreferenceSettings = () => {
  return (dispatch) => {
    return AlertPreferenceAPI.get().then((response) => {
      const preferences = _.get(response, 'subscription_preferences', []);
      const settings = _.get(response, 'settings', []);
      dispatch(updatePreferenceSettings({
        preferenceSettings: {
          preferences,
          settings,
          userPreferencesLoaded: true,
          failedLoadingUserPreferences: false
        }
      }));
    }, () => {
      dispatch(updatePreferenceSettings({
        preferenceSettings: {
          userPreferencesLoaded: false,
          failedLoadingUserPreferences: true,
          preferences:[],
          settings: []
        }
      }));
    });
  };
};
