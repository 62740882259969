import React from 'react';
import I18n from 'common/i18n';
import * as _ from 'lodash';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

const t = (key: string, options = {}, scope = 'shared.explore_grid.remove_node') => I18n.t(key, { ...options, scope });
interface Props {
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const RemoveNode: React.SFC<Props> = ({ onClick, onMouseEnter, onMouseLeave }: Props) => (
  <div className="remove-node">
    <ForgeIconButton>
      <button
        type="button"
        aria-label={t('remove_button_label')}
        data-testid='remove-node-icon-button'
        onClick={onClick}
        onKeyPress={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        >
        <ForgeIcon name="close" />
      </button>
    </ForgeIconButton>
  </div>
);

export default RemoveNode;
