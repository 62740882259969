import React, { ChangeEvent, FunctionComponent } from 'react';
import { isEmpty } from 'lodash';

import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import MultiSelect from 'common/components/MultiSelect';

import UserSearchResult from './UserSearchResult';
import SelectedUserPillContents from './SelectedUserPillContents';
import { CatalogUserOrTeam, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';

import './index.scss';

export interface UserSearchProps {
  /** Called when a user is selected in the multiselect */
  addToSelectedUsers: (user?: UsersCatalogSearchResults) => void;

  /** The current search query entered into the mutliselect */
  currentQuery: string;

  /** (Optional) Controls whether or not the help text in the search box should include teams. */
  includeTeamsInHelpText?: boolean;

  /** (Optional) Maximum number of users to allow */
  maxSelectedUsers?: number;

  /** (Optional) no result match info text */
  noResultsMessage?: string;

  /** (Optional) Placeholder text for input */
  placeHolderText?: string;

  /** List of search results from cetera */
  results?: UsersCatalogSearchResults[];

  /**
   * Called when removing a selected user from the multiselect
   * (either clicking the remove button or hitting backspace)
   */
  removeFromSelectedUsers: (option?: CatalogUserOrTeam, index?: number) => void;

  /** The list of all the currently selected users */
  selectedUsers: CatalogUserOrTeam[];

  /** Whether to render the selected users as Pills */
  renderSelectedUserPills?: boolean;

  /** Called when the user is typing in to the search box */
  userSearchQueryChanged: (e: ChangeEvent<HTMLInputElement>) => void;

  /** Called to clear the current query from the search box */
  userSearchClearQuery?: () => void;

  /** Whether backspace when the field is empty should remove the last selected user */
  preventBackspaceClearsSelectedUsers?: boolean;
}

const UserSearch: FunctionComponent<UserSearchProps> = ({
  addToSelectedUsers,
  currentQuery = '',
  includeTeamsInHelpText = true,
  maxSelectedUsers = undefined,
  noResultsMessage,
  placeHolderText,
  removeFromSelectedUsers,
  results = undefined,
  selectedUsers = [],
  renderSelectedUserPills = true,
  userSearchQueryChanged,
  userSearchClearQuery,
  preventBackspaceClearsSelectedUsers = false
}) => {
  const teamsEnabled = FeatureFlags.value('enable_teams');
  const placeHolderTextDefault = teamsEnabled && includeTeamsInHelpText
    ? I18n.t('shared.site_chrome.access_manager.search_placeholder_teams')
    : I18n.t('shared.site_chrome.access_manager.search_placeholder');

  return <MultiSelect<UsersCatalogSearchResults, CatalogUserOrTeam>
    currentQuery={currentQuery}
    inputPlaceholder={
      isEmpty(selectedUsers)
        ? placeHolderText || placeHolderTextDefault
        : ''
    }
    noResultsMessage={noResultsMessage || I18n.t('shared.site_chrome.access_manager.no_results')}
    options={results}
    maxSelectedOptions={maxSelectedUsers}
    renderSelectedOptionContents={(user?: CatalogUserOrTeam) => {
      if (user) {
        return <SelectedUserPillContents user={user} />;
      }
    }}
    renderOption={(result: UsersCatalogSearchResults) => <UserSearchResult result={result} />}
    selectedOptions={selectedUsers}
    renderSelectedOptionPills={renderSelectedUserPills}
    onAddSelectedOption={addToSelectedUsers}
    onCurrentQueryChanged={userSearchQueryChanged}
    clearCurrentQuery={userSearchClearQuery}
    onRemoveSelectedOption={removeFromSelectedUsers}
    preventBackspaceClearsSelectedUsers={preventBackspaceClearsSelectedUsers}
  />;
};

export default UserSearch;
