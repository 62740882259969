import { defaultHeaders, fetchJson } from 'common/http';
import { toApiParams } from 'common/components/CreateAlertModal/api/AlertConverter';

export const AlertShareApi = (() => {
  return {
    create: (alert, userEmails) => {
      return fetchJson('/api/notifications_and_alerts/admin/alerts', {
        method: 'POST',
        headers: defaultHeaders,
        credentials: 'same-origin',
        body: JSON.stringify({ alert: toApiParams(alert), user_emails: userEmails })
      });
    }
  };
})();

export default AlertShareApi;
