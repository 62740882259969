import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import Checkbox from 'common/components/Checkbox';
import Button from 'common/components/Button';
import AlertShareApi from 'common/notifications/api/AlertShareAPI';
import AddUser from './AddUser';
import I18n from 'common/i18n';

const scope = 'shared.site_chrome.notifications.alert_share_modal';

class AlertShareModal extends Component {

  state = {
    selectedUsers: [],
    unpauseAlert: false
  };

  onSelectedUserChange = (selectedUsers) => {
    this.setState({ selectedUsers });
  };

  onPauseAlertChange = (e) => {
    this.setState({ unpauseAlert: e.target.checked });
  };

  onShareAlert = () => {
    const { selectedUsers, unpauseAlert } = this.state;
    const { alert, onClose } = this.props;
    const userEmails = _.map(selectedUsers, 'email');

    AlertShareApi.create({ ...alert, paused: !unpauseAlert }, userEmails).then(() => {
      onClose({ isShared: true });
    });
  };

  renderModalFooter() {
    const { onClose } = this.props;
    const { selectedUsers } = this.state;

    return (
      <ModalFooter>
        <Button className="cancel-button" onClick={onClose}>
          {I18n.t('close', { scope })}
        </Button>
        <Button
          className="ok-button"
          disabled={_.isEmpty(selectedUsers)}
          onClick={this.onShareAlert}
          variant="primary">

          {I18n.t('ok', { scope })}
        </Button>
      </ModalFooter>
    );

  }

  renderPauseAlert() {
    const { unpauseAlert } = this.state;
    const pauseId = _.uniqueId();

    return (
      <div className="alert-pause-section">
        <div className="user-input-title pause-section"> {I18n.t('pause_section_title', { scope })} </div>
        <div className="user-input-description"> {I18n.t('pause_section_description', { scope })}</div>
        <form>
          <Checkbox
            checked={unpauseAlert}
            id={pauseId}
            onChange={this.onPauseAlertChange}>
            <span>{I18n.t('pause_section_label', { scope })} </span>
          </Checkbox>
        </form>
      </div>
    );
  }

  renderUserSearch() {
    return (
      <div>
        <div className="user-input-title"> {I18n.t('add_user_title', { scope })}</div>
        <div className="user-input-description">{I18n.t('add_user_description', { scope })}</div>
        <AddUser onSelectedUserChange={this.onSelectedUserChange} />
      </div>
    );
  }

  render() {
    const { onClose } = this.props;
    const containerStyle = {
      'maxWidth': '800px',
      'padding': '20px',
      'margin': '20px auto',
      'bottom': 'auto'
    };

    return (
      <Modal
        className="alert-share-modal alert-setting-modal"
        onDismiss={onClose}
        containerStyle={containerStyle}>
        <ModalHeader
          title={I18n.t('share_title', { scope })}
          onDismiss={onClose} />

        <ModalContent className="alert-share-modal-content">
          <div className="alert-share-modal-wrapper">
            <div className="title">
              {I18n.t('share_description', { scope })}
            </div>
            {this.renderUserSearch()}
            {this.renderPauseAlert()}
          </div>
        </ModalContent>
        {this.renderModalFooter()}
      </Modal>
    );
  }
}

AlertShareModal.propTypes = {
  alert: PropTypes.object,
  onClose: PropTypes.func

};
export default AlertShareModal;
