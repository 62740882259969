import { OWNER_ACCESS_LEVEL } from 'common/components/AccessManager/Constants';
import {
  userHasAccessLevel,
  findUserIndexWithAccessLevel,
  isSameUser
} from 'common/components/AccessManager/Util';

import type { GuidanceSummaryV2 } from 'common/types/approvals';
import type { ViewPermissions, ViewUser, AccessLevel } from 'common/types/view';
import type { CatalogUserOrTeam } from 'common/types/users/catalogUsers';
import type { AccessManagerPermissionsState } from '../types';
import type PermissionsActions from '../actions/PermissionsActionType';
import type ChangeOwnerActions from '../actions/ChangeOwnerActionType';

import { PermissionsActionsTypes } from 'common/components/AccessManager/actions/PermissionsActions';
import { ChangeOwnerActionTypes } from 'common/components/AccessManager/actions/ChangeOwnerActions';
import { AudienceScope, AccessLevelName } from 'common/types/view';

/** Called by AccessManagerSaga when permissions are fetched */
const fetchPermissionsSuccess = (
  state: AccessManagerPermissionsState,
  permissions: ViewPermissions,
  approvalsGuidance: GuidanceSummaryV2,
  visualizationCanvasHasPublicDataSource?: boolean
): AccessManagerPermissionsState => ({
  ...state,
  permissions,
  approvalsGuidance,
  visualizationCanvasHasPublicDataSource,

  // we save this off for if the user hits "Cancel"
  originalPermissions: { ...permissions }
});

/** called by AccessManagerSaga when fetching permissions fails */
const fetchPermissionsFail = (state: AccessManagerPermissionsState): AccessManagerPermissionsState => ({
  ...state,
  permissions: null
});

/** Change the "scope" of the permissions (i.e. public, private) */
const changeAudienceScope = (state: AccessManagerPermissionsState, scope: AudienceScope) => ({
  ...state,
  permissions: {
    ...state.permissions,
    scope
  }
});

/**
 * Change the access level of a specific user
 * (that is, their "view-level" role)
 */
const changeUserAccessLevel = (
  state: AccessManagerPermissionsState,
  user: ViewUser,
  accessLevel: AccessLevel
): AccessManagerPermissionsState => {
  const { permissions } = state;
  const userIndex = (permissions?.users || []).findIndex(
    (u) =>
      isSameUser(user, u) &&
      // can't change the owner using this!
      !userHasAccessLevel(u, AccessLevelName.CurrentOwner)
  );

  if (userIndex < 0) {
    console.error(`No user found for ${user}`);
    return state;
  }

  const updatedUsers = [...(permissions?.users || [])];
  updatedUsers[userIndex] = {
    ...updatedUsers[userIndex],
    accessLevels: [accessLevel]
  };

  return {
    ...state,
    permissions: {
      ...permissions!,
      users: updatedUsers
    }
  };
};

/**
 * Revoke a users' permissions
 *
 * This actually just removed them from the list;
 * When PUTing to the API, their absence will remove their permission
 */
const removeUser = (state: AccessManagerPermissionsState, user: ViewUser): AccessManagerPermissionsState => {
  const { permissions } = state;
  const userIndex = (permissions?.users || []).findIndex((u) => {
    if (userHasAccessLevel(u, AccessLevelName.CurrentOwner)) {
      // can't delete the owner!
      return false;
    }
    return isSameUser(user, u);
  });

  if (userIndex < 0) {
    console.error(`No user found for ${user}`);
    return state;
  }

  const updatedUsers = [...(permissions?.users || [])];
  updatedUsers.splice(userIndex, 1);

  return {
    ...state,
    permissions: {
      ...permissions!,
      users: updatedUsers
    }
  };
};

/**
 * Add a list of users with a given access level
 * (from the UserSearch multi-select component)
 */
const addUsers = (
  state: AccessManagerPermissionsState,
  users: CatalogUserOrTeam[],
  accessLevel: AccessLevel
): AccessManagerPermissionsState => {
  return {
    ...state,
    permissions: {
      ...state.permissions!,
      users: [
        ...(state.permissions?.users || []),
        ...users.map((user) => ({
          id: user.id,

          // this is how the catalog gives it to us...
          displayName: user.screen_name,
          email: user.email,

          // this one we fill out when fetching from the catalog
          type: user.type,

          // just one access level for now; if we ever want multiple
          // access levels in the future, this will need to be changed
          // along with the dropdown
          accessLevels: [accessLevel]
        }))
      ]
    }
  };
};

/** Change the "true" owner of a dataset */
const changeOwner = (
  state: AccessManagerPermissionsState,
  owner: CatalogUserOrTeam
): AccessManagerPermissionsState => {
  const { permissions } = state;

  // we're just going to replace the user at the index of the current owner,
  // effectively "transferring" the ownership to them
  const currentOwnerIndex = findUserIndexWithAccessLevel(
    permissions?.users || [],
    AccessLevelName.CurrentOwner
  );

  // if the user ownership is being transferred to already has access,
  // we want to remove their access before making them owner
  const existingUserIndex = (permissions?.users || []).findIndex((user) => user.id === owner.id);

  const updatedUsers = [...(permissions?.users || [])];

  // replace the owner in the list
  updatedUsers[currentOwnerIndex] = {
    id: owner.id as any,
    displayName: owner.screen_name,
    email: owner.email,
    accessLevels: [OWNER_ACCESS_LEVEL],
    type: owner.type
  };

  // remove user being made owner, if they already exist
  if (existingUserIndex > -1) {
    updatedUsers.splice(existingUserIndex, 1);
  }

  return {
    ...state,
    permissions: {
      ...state.permissions!,
      users: updatedUsers
    }
  };
};

const getScheduleCountSuccess = (
  state: AccessManagerPermissionsState,
  user: ViewUser,
  scheduleCounts: { total: number }
) => {
  return {
    ...state,
    scheduleCounts: {
      ...state.scheduleCounts,
      [user.id!]: scheduleCounts
    }
  };
};

const clearScheduleCount = (state: AccessManagerPermissionsState, user: ViewUser) => {
  return {
    ...state,
    scheduleCounts: {
      ...state.scheduleCounts,
      [user.id!]: undefined
    }
  };
};

export default (
  state: AccessManagerPermissionsState = {} as any,
  action: PermissionsActions | ChangeOwnerActions
): AccessManagerPermissionsState => {
  switch (action.type) {
    // permissionsActions
    case PermissionsActionsTypes.FETCH_PERMISSIONS_SUCCESS:
      return fetchPermissionsSuccess(
        state,
        action.payload.permissions,
        action.payload.approvalsGuidance,
        action.payload.visualizationCanvasHasPublicDataSource
      );
    case PermissionsActionsTypes.FETCH_PERMISSIONS_FAIL:
      return fetchPermissionsFail(state);
    case PermissionsActionsTypes.CHANGE_AUDIENCE_SCOPE:
      return changeAudienceScope(state, action.payload.scope);
    case PermissionsActionsTypes.CHANGE_USER_ACCESS_LEVEL:
      return changeUserAccessLevel(state, action.payload.user, action.payload.accessLevel);
    case PermissionsActionsTypes.REMOVE_USER_ACCESS:
      return removeUser(state, action.payload.user);
    case PermissionsActionsTypes.ADD_USERS:
      return addUsers(state, action.payload.users, action.payload.accessLevel);
    case PermissionsActionsTypes.GET_SCHEDULE_COUNT_SUCCESS:
      return getScheduleCountSuccess(state, action.payload.user, action.payload.scheduleCounts);
    case PermissionsActionsTypes.CLEAR_SCHEDULE_COUNT:
      return clearScheduleCount(state, action.payload.user);

    // changeOwnerActions
    case ChangeOwnerActionTypes.CONFIRM_SELECTED_OWNER:
      return changeOwner(state, action.payload.owner);

    default:
      return state;
  }
};
