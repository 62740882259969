/* Imports */
/* ============================================================================= */
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { GuidanceBooleanCheckSummary } from 'common/core/approvals/Types';
import { summarizeGuidanceCheckMethod, isWorkflowGuidance, createWorkflowGuidanceMethodsObject } from '.';





/* Method */
/* ============================================================================= */
/**
 * Check for which workflow(s) have a guidance object.
 * @param guidance GuidanceSummaryV2 object
 * @returns GuidanceBooleanCheckSummary object
 */
export const summarizeWorkflowsWithGuidance = (guidance: GuidanceSummaryV2): GuidanceBooleanCheckSummary => {
  const response = summarizeGuidanceCheckMethod<boolean, false>(
    guidance,
    createWorkflowGuidanceMethodsObject(isWorkflowGuidance),
    false
  );

  return response;
};

export default summarizeWorkflowsWithGuidance;
