import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import mapboxgl from '@socrata/mapbox-gl';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { GEO_LOCATE_CONTROL_OPTIONS, MAP_CONTROLS_POSITION } from '../mapConstants';
import MapHelper from '../../helpers/MapHelper';
import LayerToggleControl from './LayerToggleControl';
import DropPin from './vifOverlays/partials/DropPin';

import I18n from 'common/i18n';
import { BASEMAP_STYLES, VIF_CONSTANTS } from 'common/authoring_workflow/constants';
import { getBasemapStyle } from 'common/visualizations/views/map/basemapStyle';
import SocrataIcon from 'common/components/SocrataIcon';

/**
* Handles vif map controls init/updates
*    - navigation control
*    - geolocate control
* Returns mapOptions with the above mentioned for mapCreation
*/
export default class VifMapControls {
  constructor(map) {
    const seriesId = _.uniqueId();
    this._map = map;
    this._searchLocation = null;
    this._geoLocateControl = null;
    this._geoCoderControl = null;
    this._navigationControl = null;
    this._attributionControl = null;
    this._layerToggleControl = null;
    this._existingVif = null;
    this._existingLayersDetails = [];
    this._dropPin = new DropPin(map, seriesId);
    this._locale = map.locale;
  }

  initialize(vif) {
    this._dropPin.initialize();
    this._addAttributionControl(vif);

    if (shouldShowNavigationControl(vif)) {
      this._addNavigationControl();
    }
    if (shouldShowGeoLocateControl(vif)) {
      this._addGeoLocateControl();
    }
    if (shouldShowGeoCoderControl(vif)) {
      this._addGeoCoderControl(vif);
    }

    if (shouldShowLayerToggleControl(vif)) {
      this._addLayerToggleControl(vif);
    }
    this._existingVif = vif;
  }

  update(vif) {
    shouldShowNavigationControl(vif) ? this._addNavigationControl() : this._removeNavigationControl();
    shouldShowGeoLocateControl(vif) ? this._addGeoLocateControl() : this._removeGeoLocateControl();
    shouldShowGeoCoderControl(vif) ? this._addGeoCoderControl(vif) : this._removeGeoCoderControl();
    shouldShowLayerToggleControl(vif) ? this._addLayerToggleControl(vif) : this._removeLayerToggleControl();
    this._addAttributionControl(vif);
    this._existingVif = vif;
  }

  static getMapInitOptions(vif) {
    return {};
  }

  _addLayerToggleControl(vif) {
    if (this._layerToggleControl) {
      const layersDetails = _.map(getLayers(vif), 'layerNameWithIndex');
      if (getFilteredSeries(vif).length > 1 && !_.isEqual(this._existingLayersDetails, layersDetails)) {
        this._map.removeControl(this._layerToggleControl);
        this._layerToggleControl = null;
      } else {
        return;
      }
    }

    if (getFilteredSeries(vif).length > 1) {
      this._existingLayersDetails = _.map(getLayers(vif), 'layerNameWithIndex');
      this._layerToggleControl = new LayerToggleControl(getLayers(vif));
      this._map.addControl(this._layerToggleControl, MAP_CONTROLS_POSITION.TOGGLE_LAYER);
    }
  }

  _removeLayerToggleControl() {
    if (!this._layerToggleControl) {
      return;
    }

    this._map.removeControl(this._layerToggleControl);
    this._layerToggleControl = null;
  }

  _addNavigationControl() {
    if (this._navigationControl) {
      return;
    }
    this._navigationControl = new mapboxgl.NavigationControl();
    this._map.addControl(this._navigationControl, MAP_CONTROLS_POSITION.NAVIGATION);
  }

  _removeNavigationControl() {
    if (!this._navigationControl) {
      return;
    }
    this._map.removeControl(this._navigationControl);
    this._navigationControl = null;
  }

  _addAttributionControl(vif) {
    const basemapStyle = getBasemapStyle(vif);

    if (this._existingBasemapStyle === basemapStyle) {
      return;
    }

    if (this._attributionControl) {
      this._map.removeControl(this._attributionControl);
      this._attributionControl = null;
    }

    const { customAttribution, showMapboxAttribution } = getAttributionControlOptions(vif);
    const attributionOptions = _.isNil(customAttribution) ? {} : { customAttribution };
    if (showMapboxAttribution) {
      this._attributionControl = new mapboxgl.AttributionControl(attributionOptions);
      this._map.addControl(this._attributionControl);
    }

    this._existingBasemapStyle = basemapStyle;
  }

  _removeAttributionControl() {
    if (this._attributionControl) {
      this._map.removeControl(this._attributionControl);
      this._attributionControl = null;
    }
  }

  _addGeoLocateControl() {
    if (this._geoLocateControl) {
      return;
    }
    this._geoLocateControl = new mapboxgl.GeolocateControl(GEO_LOCATE_CONTROL_OPTIONS);
    this._map.addControl(this._geoLocateControl, MAP_CONTROLS_POSITION.GEO_LOCATE);
  }

  _removeGeoLocateControl() {
    if (!this._geoLocateControl) {
      return;
    }
    this._map.removeControl(this._geoLocateControl);
    this._geoLocateControl = null;
  }

  _addGeoCoderControl(vif) {
    const geocodeBoundingbox = vif.getGeocodeBoundingbox();

    if (this._existingVif && !_.isEqual(this._existingVif.getGeocodeBoundingbox(), geocodeBoundingbox)) {
      // If the geocode bounding box changes, reinitialize geocoder.
      this._removeGeoCoderControl();
    }

    if (vif.hasBaseMapStyleChanged(this._existingVif)) {
      this._dropPin.initialize();
      this._dropPin.update(this._searchLocation);
    }

    if (this._geoCoderControl) {
      return;
    }

    const options = { accessToken: mapboxgl.accessToken };
    if (geocodeBoundingbox) {
      options.bbox = geocodeBoundingbox;
    }
    this._geoCoderControl = new MapboxGeocoder(options);
    this._map.addControl(this._geoCoderControl, MAP_CONTROLS_POSITION.GEO_CODER);

    this._geoCoderControl.on('result', (e) => {
      this._searchLocation = e.result;
      this._dropPin.update(e.result);
    });

    this._geoCoderControl.on('clear', (e) => {
      this._searchLocation = null;
      this._dropPin.update();
    });

    const geoCoderContainer = this._geoCoderControl.container;
    this._renderSearchIcon(geoCoderContainer);
    const geoCoderSearchContainer = $(geoCoderContainer).find('.geocoder-icon-search');
    $(geoCoderSearchContainer).on('click', () => {
      $(geoCoderContainer).toggleClass('search-box-show');
      $(geoCoderContainer).next('.search-button').toggleClass('search-btn-hide');
    });
    $(geoCoderContainer).find('input[type="text"]').attr(
      'aria-label',
      I18n.t('shared.visualizations.charts.map.search', this._locale)
    );
  }

  _removeGeoCoderControl() {
    if (!this._geoCoderControl) {
      return;
    }
    this._dropPin.update();
    $(this._geoCoderControl.container).next('.search-button').remove();
    this._map.removeControl(this._geoCoderControl);
    this._geoCoderControl = null;
  }

  _renderSearchIcon(geoCoderContainer) {
    let searchIconContainer = document.createElement('div');
    searchIconContainer.className = 'mobile-view-btn search-button';
    let searchIcon = document.createElement('span');
    searchIcon.className = 'search-icon';
    searchIconContainer.appendChild(searchIcon);
    searchIconContainer.onclick = () => {
      $(geoCoderContainer).toggleClass('search-box-show');
      $(searchIconContainer).toggleClass('search-btn-hide');
    };
    $(geoCoderContainer).after(searchIconContainer);

    ReactDOM.render(
      React.createElement(SocrataIcon, { name: 'search' }),
      searchIcon
    );
  }
}

function shouldShowNavigationControl(vif) {
  return _.get(vif, 'configuration.basemapOptions.navigationControl', true);
}

function shouldShowLayerToggleControl(vif) {
  return _.get(vif, 'configuration.basemapOptions.layerToggleControl', true);
}

function shouldShowGeoLocateControl(vif) {
  return _.get(
    vif,
    'configuration.basemapOptions.geoLocateControl',
    !MapHelper.isLineOrBoundaryMap(_.get(vif, 'series[0].mapOptions.mapType'))
  );
}

function shouldShowGeoCoderControl(vif) {
  return _.get(
    vif,
    'configuration.basemapOptions.geoCoderControl',
    !MapHelper.isLineOrBoundaryMap(_.get(vif, 'series[0].mapOptions.mapType'))
  );
}

function getLayers(vif) {
  const series = _.get(vif, 'series', []);
  return _.map(series, (layer, layerIndex) => {
    const name = _.get(layer, 'dataSource.name', VIF_CONSTANTS.DEFAULT_MAP_LAYER_NAME);
    const visible = _.get(layer, 'visible', '');
    return { name, visible, index: layerIndex, layerNameWithIndex: `${name}-${layerIndex}`};
  });
}

function getFilteredSeries(vif) {
  const series = _.get(vif, 'series', []);
  return _.filter(series, (seriesItem) => {
    return _.get(seriesItem, 'dataSource.name', '');
  });
}

function getAttributionControlOptions(vif) {
  const basemapStyle = getBasemapStyle(vif);
  const styleOptions = _.find(BASEMAP_STYLES, { value: basemapStyle });
  const customAttribution = _.get(styleOptions, 'customAttribution', null);
  const showMapboxAttribution = _.get(styleOptions, 'showMapboxAttribution', false);

  return { customAttribution, showMapboxAttribution };
}
