import isArray from 'lodash/isArray';

import assert from './assert';

/**
 * Ensures the given value is of Array type.
 *
 * @param {any} object - The object to check
 */
export default (object) => assert(isArray(object), `Expected ${object} to be an Array, was ${typeof object}`);
