import { cloneDeep, get, set, unset } from 'lodash';
import { Hierarchy, Vif } from 'common/visualizations/vif';
import { createDefaultHierarchy } from 'common/authoring_workflow/helpers';

export function migrateVif7ToVif8(vifToMigrate: any): Vif {
  const migratedVif = cloneDeep(vifToMigrate);

  const vifHierarchies: Hierarchy[] = get(migratedVif, 'series[0].dataSource.hierarchies', []);
  const hierarchyLength = vifHierarchies.length ?? 0;

  if (get(vifToMigrate, 'series[0].type') === 'agTable' && hierarchyLength === 0) {
    const vifOrderConfig = get(migratedVif, 'configuration.order', []);
    const agGridOpenNodeLevel = get(migratedVif, 'configuration.agGridOpenNodeLevel', 0);

    const hierarchy: Hierarchy = createDefaultHierarchy();
    set(migratedVif, 'series[0].dataSource.hierarchies.0', {
      ...hierarchy,
      order: vifOrderConfig,
      agGridOpenNodeLevel
    });

    set(migratedVif, 'series[0].dataSource.activeHierarchyId', hierarchy.id);
    unset(migratedVif, 'configuration.order');
    unset(migratedVif, 'configuration.agGridOpenNodeLevel');
  }

  migratedVif.format.version = 8;
  return migratedVif;
}
