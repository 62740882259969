/* eslint-disable @typescript-eslint/no-shadow */

/*
 * !!! NOTE                           !!!
 * !!! This is a generated file       !!!
 * !!! Do NOT edit this file directly !!!
 *
 * See:
 *  - common/resources/socrata-icons/generate-socrata-icon.ts
 *  - common/resources/socrata-icons/templates/SocrataIcon.handlebars
 *
 * Run:
 * ```
 * pnpm install && pnpm build-socrata-icon-component
 * ```
 * in `common/resources/socrata-icons` to re-generate this file.
 */

import $ from 'jquery';
import React, { HTMLAttributes, FunctionComponent } from 'react';
import classNames from 'classnames';
import './index.scss';

import AddCollaborator from 'common/resources/socrata-icons/svg/add-collaborator.svg';
import Add from 'common/resources/socrata-icons/svg/add.svg';
import ArrowDown from 'common/resources/socrata-icons/svg/arrow-down.svg';
import ArrowDown2 from 'common/resources/socrata-icons/svg/arrow-down2.svg';
import ArrowLeft from 'common/resources/socrata-icons/svg/arrow-left.svg';
import ArrowNext from 'common/resources/socrata-icons/svg/arrow-next.svg';
import ArrowPrev from 'common/resources/socrata-icons/svg/arrow-prev.svg';
import ArrowRight from 'common/resources/socrata-icons/svg/arrow-right.svg';
import ArrowUp from 'common/resources/socrata-icons/svg/arrow-up.svg';
import ArrowUp2 from 'common/resources/socrata-icons/svg/arrow-up2.svg';
import Attachment from 'common/resources/socrata-icons/svg/attachment.svg';
import AxisScale from 'common/resources/socrata-icons/svg/axis-scale.svg';
import BarChartHorz from 'common/resources/socrata-icons/svg/bar-chart-horz.svg';
import BarChart from 'common/resources/socrata-icons/svg/bar-chart.svg';
import Bell from 'common/resources/socrata-icons/svg/bell.svg';
import Bold2 from 'common/resources/socrata-icons/svg/bold2.svg';
import Boolean from 'common/resources/socrata-icons/svg/boolean.svg';
import CalendarAlt from 'common/resources/socrata-icons/svg/calendar-alt.svg';
import Cards from 'common/resources/socrata-icons/svg/cards.svg';
import Chart from 'common/resources/socrata-icons/svg/chart.svg';
import Check2 from 'common/resources/socrata-icons/svg/check-2.svg';
import Check from 'common/resources/socrata-icons/svg/check.svg';
import CheckmarkAlt from 'common/resources/socrata-icons/svg/checkmark-alt.svg';
import Checkmark3 from 'common/resources/socrata-icons/svg/checkmark3.svg';
import ChevronDown from 'common/resources/socrata-icons/svg/chevron-down.svg';
import ChevronUp from 'common/resources/socrata-icons/svg/chevron-up.svg';
import Circle from 'common/resources/socrata-icons/svg/circle.svg';
import ClearFormatting from 'common/resources/socrata-icons/svg/clear-formatting.svg';
import Close2 from 'common/resources/socrata-icons/svg/close-2.svg';
import CloseCircle from 'common/resources/socrata-icons/svg/close-circle.svg';
import Close from 'common/resources/socrata-icons/svg/close.svg';
import Collapse from 'common/resources/socrata-icons/svg/collapse.svg';
import Color from 'common/resources/socrata-icons/svg/color.svg';
import ColumnInfo from 'common/resources/socrata-icons/svg/column-info.svg';
import ColumnMapping from 'common/resources/socrata-icons/svg/column-mapping.svg';
import Column from 'common/resources/socrata-icons/svg/column.svg';
import ComboChart from 'common/resources/socrata-icons/svg/combo-chart.svg';
import Community from 'common/resources/socrata-icons/svg/community.svg';
import CopyDocument from 'common/resources/socrata-icons/svg/copy-document.svg';
import Crop from 'common/resources/socrata-icons/svg/crop.svg';
import Cross2 from 'common/resources/socrata-icons/svg/cross2.svg';
import DataDocument from 'common/resources/socrata-icons/svg/data-document.svg';
import Data from 'common/resources/socrata-icons/svg/data.svg';
import Dataset from 'common/resources/socrata-icons/svg/dataset.svg';
import Date from 'common/resources/socrata-icons/svg/date.svg';
import DbCollection from 'common/resources/socrata-icons/svg/db-collection.svg';
import Delete from 'common/resources/socrata-icons/svg/delete.svg';
import Distribution from 'common/resources/socrata-icons/svg/distribution.svg';
import DockBottom from 'common/resources/socrata-icons/svg/dock-bottom.svg';
import Dollar from 'common/resources/socrata-icons/svg/dollar.svg';
import Download from 'common/resources/socrata-icons/svg/download.svg';
import DraftBadge from 'common/resources/socrata-icons/svg/draft-badge.svg';
import DraftInverted from 'common/resources/socrata-icons/svg/draft-inverted.svg';
import Draft from 'common/resources/socrata-icons/svg/draft.svg';
import Droplet from 'common/resources/socrata-icons/svg/droplet.svg';
import Edit from 'common/resources/socrata-icons/svg/edit.svg';
import Email from 'common/resources/socrata-icons/svg/email.svg';
import Embed from 'common/resources/socrata-icons/svg/embed.svg';
import Expand from 'common/resources/socrata-icons/svg/expand.svg';
import ExternalSquare from 'common/resources/socrata-icons/svg/external-square.svg';
import External from 'common/resources/socrata-icons/svg/external.svg';
import EyeBlocked from 'common/resources/socrata-icons/svg/eye-blocked.svg';
import Eye from 'common/resources/socrata-icons/svg/eye.svg';
import Facebook from 'common/resources/socrata-icons/svg/facebook.svg';
import Failed from 'common/resources/socrata-icons/svg/failed.svg';
import Featured from 'common/resources/socrata-icons/svg/featured.svg';
import Federation from 'common/resources/socrata-icons/svg/federation.svg';
import FileUpload from 'common/resources/socrata-icons/svg/file-upload.svg';
import File from 'common/resources/socrata-icons/svg/file.svg';
import Filter from 'common/resources/socrata-icons/svg/filter.svg';
import Flickr from 'common/resources/socrata-icons/svg/flickr.svg';
import FlyoutOptions from 'common/resources/socrata-icons/svg/flyout-options.svg';
import Function from 'common/resources/socrata-icons/svg/function.svg';
import Gateway from 'common/resources/socrata-icons/svg/gateway.svg';
import Geo from 'common/resources/socrata-icons/svg/geo.svg';
import Github from 'common/resources/socrata-icons/svg/github.svg';
import Goal from 'common/resources/socrata-icons/svg/goal.svg';
import Google from 'common/resources/socrata-icons/svg/google.svg';
import GrabHandle from 'common/resources/socrata-icons/svg/grab-handle.svg';
import Group from 'common/resources/socrata-icons/svg/group.svg';
import Gte from 'common/resources/socrata-icons/svg/gte.svg';
import Hamburger from 'common/resources/socrata-icons/svg/hamburger.svg';
import Hierarchy from 'common/resources/socrata-icons/svg/hierarchy.svg';
import Id from 'common/resources/socrata-icons/svg/id.svg';
import Image from 'common/resources/socrata-icons/svg/image.svg';
import IndeterminateStateCheckbox from 'common/resources/socrata-icons/svg/indeterminate-state-checkbox.svg';
import IndeterminateStateIcon from 'common/resources/socrata-icons/svg/indeterminate-state-icon.svg';
import InfoInverse from 'common/resources/socrata-icons/svg/info-inverse.svg';
import Info from 'common/resources/socrata-icons/svg/info.svg';
import Instagram from 'common/resources/socrata-icons/svg/instagram.svg';
import Italic2 from 'common/resources/socrata-icons/svg/italic2.svg';
import Join from 'common/resources/socrata-icons/svg/join.svg';
import Kebab from 'common/resources/socrata-icons/svg/kebab.svg';
import Layers from 'common/resources/socrata-icons/svg/layers.svg';
import LayoutInline from 'common/resources/socrata-icons/svg/layout-inline.svg';
import LayoutPopOut from 'common/resources/socrata-icons/svg/layout-pop-out.svg';
import LayoutSettings from 'common/resources/socrata-icons/svg/layout-settings.svg';
import LineChart from 'common/resources/socrata-icons/svg/line-chart.svg';
import Link from 'common/resources/socrata-icons/svg/link.svg';
import Linkedin from 'common/resources/socrata-icons/svg/linkedin.svg';
import ListNumbered from 'common/resources/socrata-icons/svg/list-numbered.svg';
import List2 from 'common/resources/socrata-icons/svg/list2.svg';
import Logo from 'common/resources/socrata-icons/svg/logo.svg';
import Lte from 'common/resources/socrata-icons/svg/lte.svg';
import Map from 'common/resources/socrata-icons/svg/map.svg';
import Measure from 'common/resources/socrata-icons/svg/measure.svg';
import MeasuresEditorWorkingCopy from 'common/resources/socrata-icons/svg/measures-editor-working-copy.svg';
import MeasuresEditor from 'common/resources/socrata-icons/svg/measures-editor.svg';
import MoveVertical from 'common/resources/socrata-icons/svg/move-vertical.svg';
import Move from 'common/resources/socrata-icons/svg/move.svg';
import MyOrg from 'common/resources/socrata-icons/svg/my-org.svg';
import NoFilter from 'common/resources/socrata-icons/svg/no-filter.svg';
import Number from 'common/resources/socrata-icons/svg/number.svg';
import Official from 'common/resources/socrata-icons/svg/official.svg';
import Official2 from 'common/resources/socrata-icons/svg/official2.svg';
import Outlink from 'common/resources/socrata-icons/svg/outlink.svg';
import ParagraphCenter3 from 'common/resources/socrata-icons/svg/paragraph-center3.svg';
import ParagraphLeft from 'common/resources/socrata-icons/svg/paragraph-left.svg';
import ParagraphLeft3 from 'common/resources/socrata-icons/svg/paragraph-left3.svg';
import ParagraphRight3 from 'common/resources/socrata-icons/svg/paragraph-right3.svg';
import Pause from 'common/resources/socrata-icons/svg/pause.svg';
import PendingInverted from 'common/resources/socrata-icons/svg/pending-inverted.svg';
import Pending from 'common/resources/socrata-icons/svg/pending.svg';
import Pending2 from 'common/resources/socrata-icons/svg/pending2.svg';
import PieChart from 'common/resources/socrata-icons/svg/pie-chart.svg';
import Pinterest from 'common/resources/socrata-icons/svg/pinterest.svg';
import Play from 'common/resources/socrata-icons/svg/play.svg';
import Play2 from 'common/resources/socrata-icons/svg/play2.svg';
import Plus2 from 'common/resources/socrata-icons/svg/plus2.svg';
import Plus3 from 'common/resources/socrata-icons/svg/plus3.svg';
import Presentation from 'common/resources/socrata-icons/svg/presentation.svg';
import Preview from 'common/resources/socrata-icons/svg/preview.svg';
import Print from 'common/resources/socrata-icons/svg/print.svg';
import Private from 'common/resources/socrata-icons/svg/private.svg';
import Processing from 'common/resources/socrata-icons/svg/processing.svg';
import Progress from 'common/resources/socrata-icons/svg/progress.svg';
import PublicOpen from 'common/resources/socrata-icons/svg/public-open.svg';
import Pulse from 'common/resources/socrata-icons/svg/pulse.svg';
import Puzzle from 'common/resources/socrata-icons/svg/puzzle.svg';
import QuestionInverse from 'common/resources/socrata-icons/svg/question-inverse.svg';
import Question from 'common/resources/socrata-icons/svg/question.svg';
import QuotesLeft from 'common/resources/socrata-icons/svg/quotes-left.svg';
import Redo from 'common/resources/socrata-icons/svg/redo.svg';
import Refresh from 'common/resources/socrata-icons/svg/refresh.svg';
import Region from 'common/resources/socrata-icons/svg/region.svg';
import Remove from 'common/resources/socrata-icons/svg/remove.svg';
import ScatterPlot from 'common/resources/socrata-icons/svg/scatter-plot.svg';
import Search from 'common/resources/socrata-icons/svg/search.svg';
import Security from 'common/resources/socrata-icons/svg/security.svg';
import SettingsSwitch from 'common/resources/socrata-icons/svg/settings-switch.svg';
import Settings from 'common/resources/socrata-icons/svg/settings.svg';
import Share from 'common/resources/socrata-icons/svg/share.svg';
import SignOut from 'common/resources/socrata-icons/svg/sign-out.svg';
import SortAsc from 'common/resources/socrata-icons/svg/sort-asc.svg';
import SortAz from 'common/resources/socrata-icons/svg/sort-az.svg';
import SortDesc from 'common/resources/socrata-icons/svg/sort-desc.svg';
import SortZa from 'common/resources/socrata-icons/svg/sort-za.svg';
import Sort from 'common/resources/socrata-icons/svg/sort.svg';
import StoriesIcon from 'common/resources/socrata-icons/svg/stories-icon.svg';
import StoriesMenu from 'common/resources/socrata-icons/svg/stories-menu.svg';
import Story from 'common/resources/socrata-icons/svg/story.svg';
import SubOrg from 'common/resources/socrata-icons/svg/sub-org.svg';
import TableHierarchy from 'common/resources/socrata-icons/svg/table-hierarchy.svg';
import Table from 'common/resources/socrata-icons/svg/table.svg';
import Tag from 'common/resources/socrata-icons/svg/tag.svg';
import Team from 'common/resources/socrata-icons/svg/team.svg';
import Templates from 'common/resources/socrata-icons/svg/templates.svg';
import Text from 'common/resources/socrata-icons/svg/text.svg';
import Tumblr from 'common/resources/socrata-icons/svg/tumblr.svg';
import Twitter from 'common/resources/socrata-icons/svg/twitter.svg';
import Undo from 'common/resources/socrata-icons/svg/undo.svg';
import Undock from 'common/resources/socrata-icons/svg/undock.svg';
import User from 'common/resources/socrata-icons/svg/user.svg';
import Vimeo from 'common/resources/socrata-icons/svg/vimeo.svg';
import Waiting from 'common/resources/socrata-icons/svg/waiting.svg';
import WarningAlt from 'common/resources/socrata-icons/svg/warning-alt.svg';
import WarningAlt2 from 'common/resources/socrata-icons/svg/warning-alt2.svg';
import Warning from 'common/resources/socrata-icons/svg/warning.svg';
import Watch from 'common/resources/socrata-icons/svg/watch.svg';
import Watched from 'common/resources/socrata-icons/svg/watched.svg';
import Windows from 'common/resources/socrata-icons/svg/windows.svg';
import WorkingCopy from 'common/resources/socrata-icons/svg/working-copy.svg';
import Yahoo from 'common/resources/socrata-icons/svg/yahoo.svg';
import Yammer from 'common/resources/socrata-icons/svg/yammer.svg';
import Youtube from 'common/resources/socrata-icons/svg/youtube.svg';

export enum IconName {
  AddCollaborator = 'add-collaborator',
  Add = 'add',
  ArrowDown = 'arrow-down',
  ArrowDown2 = 'arrow-down2',
  ArrowLeft = 'arrow-left',
  ArrowNext = 'arrow-next',
  ArrowPrev = 'arrow-prev',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  ArrowUp2 = 'arrow-up2',
  Attachment = 'attachment',
  AxisScale = 'axis-scale',
  BarChartHorz = 'bar-chart-horz',
  BarChart = 'bar-chart',
  Bell = 'bell',
  Bold2 = 'bold2',
  Boolean = 'boolean',
  CalendarAlt = 'calendar-alt',
  Cards = 'cards',
  Chart = 'chart',
  Check2 = 'check-2',
  Check = 'check',
  CheckmarkAlt = 'checkmark-alt',
  Checkmark3 = 'checkmark3',
  ChevronDown = 'chevron-down',
  ChevronUp = 'chevron-up',
  Circle = 'circle',
  ClearFormatting = 'clear-formatting',
  Close2 = 'close-2',
  CloseCircle = 'close-circle',
  Close = 'close',
  Collapse = 'collapse',
  Color = 'color',
  ColumnInfo = 'column-info',
  ColumnMapping = 'column-mapping',
  Column = 'column',
  ComboChart = 'combo-chart',
  Community = 'community',
  CopyDocument = 'copy-document',
  Crop = 'crop',
  Cross2 = 'cross2',
  DataDocument = 'data-document',
  Data = 'data',
  Dataset = 'dataset',
  Date = 'date',
  DbCollection = 'db-collection',
  Delete = 'delete',
  Distribution = 'distribution',
  DockBottom = 'dock-bottom',
  Dollar = 'dollar',
  Download = 'download',
  DraftBadge = 'draft-badge',
  DraftInverted = 'draft-inverted',
  Draft = 'draft',
  Droplet = 'droplet',
  Edit = 'edit',
  Email = 'email',
  Embed = 'embed',
  Expand = 'expand',
  ExternalSquare = 'external-square',
  External = 'external',
  EyeBlocked = 'eye-blocked',
  Eye = 'eye',
  Facebook = 'facebook',
  Failed = 'failed',
  Featured = 'featured',
  Federation = 'federation',
  FileUpload = 'file-upload',
  File = 'file',
  Filter = 'filter',
  Flickr = 'flickr',
  FlyoutOptions = 'flyout-options',
  Function = 'function',
  Gateway = 'gateway',
  Geo = 'geo',
  Github = 'github',
  Goal = 'goal',
  Google = 'google',
  GrabHandle = 'grab-handle',
  Group = 'group',
  Gte = 'gte',
  Hamburger = 'hamburger',
  Hierarchy = 'hierarchy',
  Id = 'id',
  Image = 'image',
  IndeterminateStateCheckbox = 'indeterminate-state-checkbox',
  IndeterminateStateIcon = 'indeterminate-state-icon',
  InfoInverse = 'info-inverse',
  Info = 'info',
  Instagram = 'instagram',
  Italic2 = 'italic2',
  Join = 'join',
  Kebab = 'kebab',
  Layers = 'layers',
  LayoutInline = 'layout-inline',
  LayoutPopOut = 'layout-pop-out',
  LayoutSettings = 'layout-settings',
  LineChart = 'line-chart',
  Link = 'link',
  Linkedin = 'linkedin',
  ListNumbered = 'list-numbered',
  List2 = 'list2',
  Logo = 'logo',
  Lte = 'lte',
  Map = 'map',
  Measure = 'measure',
  MeasuresEditorWorkingCopy = 'measures-editor-working-copy',
  MeasuresEditor = 'measures-editor',
  MoveVertical = 'move-vertical',
  Move = 'move',
  MyOrg = 'my-org',
  NoFilter = 'no-filter',
  Number = 'number',
  Official = 'official',
  Official2 = 'official2',
  Outlink = 'outlink',
  ParagraphCenter3 = 'paragraph-center3',
  ParagraphLeft = 'paragraph-left',
  ParagraphLeft3 = 'paragraph-left3',
  ParagraphRight3 = 'paragraph-right3',
  Pause = 'pause',
  PendingInverted = 'pending-inverted',
  Pending = 'pending',
  Pending2 = 'pending2',
  PieChart = 'pie-chart',
  Pinterest = 'pinterest',
  Play = 'play',
  Play2 = 'play2',
  Plus2 = 'plus2',
  Plus3 = 'plus3',
  Presentation = 'presentation',
  Preview = 'preview',
  Print = 'print',
  Private = 'private',
  Processing = 'processing',
  Progress = 'progress',
  PublicOpen = 'public-open',
  Pulse = 'pulse',
  Puzzle = 'puzzle',
  QuestionInverse = 'question-inverse',
  Question = 'question',
  QuotesLeft = 'quotes-left',
  Redo = 'redo',
  Refresh = 'refresh',
  Region = 'region',
  Remove = 'remove',
  ScatterPlot = 'scatter-plot',
  Search = 'search',
  Security = 'security',
  SettingsSwitch = 'settings-switch',
  Settings = 'settings',
  Share = 'share',
  SignOut = 'sign-out',
  SortAsc = 'sort-asc',
  SortAz = 'sort-az',
  SortDesc = 'sort-desc',
  SortZa = 'sort-za',
  Sort = 'sort',
  StoriesIcon = 'stories-icon',
  StoriesMenu = 'stories-menu',
  Story = 'story',
  SubOrg = 'sub-org',
  TableHierarchy = 'table-hierarchy',
  Table = 'table',
  Tag = 'tag',
  Team = 'team',
  Templates = 'templates',
  Text = 'text',
  Tumblr = 'tumblr',
  Twitter = 'twitter',
  Undo = 'undo',
  Undock = 'undock',
  User = 'user',
  Vimeo = 'vimeo',
  Waiting = 'waiting',
  WarningAlt = 'warning-alt',
  WarningAlt2 = 'warning-alt2',
  Warning = 'warning',
  Watch = 'watch',
  Watched = 'watched',
  Windows = 'windows',
  WorkingCopy = 'working-copy',
  Yahoo = 'yahoo',
  Yammer = 'yammer',
  Youtube = 'youtube',
}

const IconMap: { [name in IconName]: any } = {
  [IconName.AddCollaborator]: AddCollaborator,
  [IconName.Add]: Add,
  [IconName.ArrowDown]: ArrowDown,
  [IconName.ArrowDown2]: ArrowDown2,
  [IconName.ArrowLeft]: ArrowLeft,
  [IconName.ArrowNext]: ArrowNext,
  [IconName.ArrowPrev]: ArrowPrev,
  [IconName.ArrowRight]: ArrowRight,
  [IconName.ArrowUp]: ArrowUp,
  [IconName.ArrowUp2]: ArrowUp2,
  [IconName.Attachment]: Attachment,
  [IconName.AxisScale]: AxisScale,
  [IconName.BarChartHorz]: BarChartHorz,
  [IconName.BarChart]: BarChart,
  [IconName.Bell]: Bell,
  [IconName.Bold2]: Bold2,
  [IconName.Boolean]: Boolean,
  [IconName.CalendarAlt]: CalendarAlt,
  [IconName.Cards]: Cards,
  [IconName.Chart]: Chart,
  [IconName.Check2]: Check2,
  [IconName.Check]: Check,
  [IconName.CheckmarkAlt]: CheckmarkAlt,
  [IconName.Checkmark3]: Checkmark3,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ChevronUp]: ChevronUp,
  [IconName.Circle]: Circle,
  [IconName.ClearFormatting]: ClearFormatting,
  [IconName.Close2]: Close2,
  [IconName.CloseCircle]: CloseCircle,
  [IconName.Close]: Close,
  [IconName.Collapse]: Collapse,
  [IconName.Color]: Color,
  [IconName.ColumnInfo]: ColumnInfo,
  [IconName.ColumnMapping]: ColumnMapping,
  [IconName.Column]: Column,
  [IconName.ComboChart]: ComboChart,
  [IconName.Community]: Community,
  [IconName.CopyDocument]: CopyDocument,
  [IconName.Crop]: Crop,
  [IconName.Cross2]: Cross2,
  [IconName.DataDocument]: DataDocument,
  [IconName.Data]: Data,
  [IconName.Dataset]: Dataset,
  [IconName.Date]: Date,
  [IconName.DbCollection]: DbCollection,
  [IconName.Delete]: Delete,
  [IconName.Distribution]: Distribution,
  [IconName.DockBottom]: DockBottom,
  [IconName.Dollar]: Dollar,
  [IconName.Download]: Download,
  [IconName.DraftBadge]: DraftBadge,
  [IconName.DraftInverted]: DraftInverted,
  [IconName.Draft]: Draft,
  [IconName.Droplet]: Droplet,
  [IconName.Edit]: Edit,
  [IconName.Email]: Email,
  [IconName.Embed]: Embed,
  [IconName.Expand]: Expand,
  [IconName.ExternalSquare]: ExternalSquare,
  [IconName.External]: External,
  [IconName.EyeBlocked]: EyeBlocked,
  [IconName.Eye]: Eye,
  [IconName.Facebook]: Facebook,
  [IconName.Failed]: Failed,
  [IconName.Featured]: Featured,
  [IconName.Federation]: Federation,
  [IconName.FileUpload]: FileUpload,
  [IconName.File]: File,
  [IconName.Filter]: Filter,
  [IconName.Flickr]: Flickr,
  [IconName.FlyoutOptions]: FlyoutOptions,
  [IconName.Function]: Function,
  [IconName.Gateway]: Gateway,
  [IconName.Geo]: Geo,
  [IconName.Github]: Github,
  [IconName.Goal]: Goal,
  [IconName.Google]: Google,
  [IconName.GrabHandle]: GrabHandle,
  [IconName.Group]: Group,
  [IconName.Gte]: Gte,
  [IconName.Hamburger]: Hamburger,
  [IconName.Hierarchy]: Hierarchy,
  [IconName.Id]: Id,
  [IconName.Image]: Image,
  [IconName.IndeterminateStateCheckbox]: IndeterminateStateCheckbox,
  [IconName.IndeterminateStateIcon]: IndeterminateStateIcon,
  [IconName.InfoInverse]: InfoInverse,
  [IconName.Info]: Info,
  [IconName.Instagram]: Instagram,
  [IconName.Italic2]: Italic2,
  [IconName.Join]: Join,
  [IconName.Kebab]: Kebab,
  [IconName.Layers]: Layers,
  [IconName.LayoutInline]: LayoutInline,
  [IconName.LayoutPopOut]: LayoutPopOut,
  [IconName.LayoutSettings]: LayoutSettings,
  [IconName.LineChart]: LineChart,
  [IconName.Link]: Link,
  [IconName.Linkedin]: Linkedin,
  [IconName.ListNumbered]: ListNumbered,
  [IconName.List2]: List2,
  [IconName.Logo]: Logo,
  [IconName.Lte]: Lte,
  [IconName.Map]: Map,
  [IconName.Measure]: Measure,
  [IconName.MeasuresEditorWorkingCopy]: MeasuresEditorWorkingCopy,
  [IconName.MeasuresEditor]: MeasuresEditor,
  [IconName.MoveVertical]: MoveVertical,
  [IconName.Move]: Move,
  [IconName.MyOrg]: MyOrg,
  [IconName.NoFilter]: NoFilter,
  [IconName.Number]: Number,
  [IconName.Official]: Official,
  [IconName.Official2]: Official2,
  [IconName.Outlink]: Outlink,
  [IconName.ParagraphCenter3]: ParagraphCenter3,
  [IconName.ParagraphLeft]: ParagraphLeft,
  [IconName.ParagraphLeft3]: ParagraphLeft3,
  [IconName.ParagraphRight3]: ParagraphRight3,
  [IconName.Pause]: Pause,
  [IconName.PendingInverted]: PendingInverted,
  [IconName.Pending]: Pending,
  [IconName.Pending2]: Pending2,
  [IconName.PieChart]: PieChart,
  [IconName.Pinterest]: Pinterest,
  [IconName.Play]: Play,
  [IconName.Play2]: Play2,
  [IconName.Plus2]: Plus2,
  [IconName.Plus3]: Plus3,
  [IconName.Presentation]: Presentation,
  [IconName.Preview]: Preview,
  [IconName.Print]: Print,
  [IconName.Private]: Private,
  [IconName.Processing]: Processing,
  [IconName.Progress]: Progress,
  [IconName.PublicOpen]: PublicOpen,
  [IconName.Pulse]: Pulse,
  [IconName.Puzzle]: Puzzle,
  [IconName.QuestionInverse]: QuestionInverse,
  [IconName.Question]: Question,
  [IconName.QuotesLeft]: QuotesLeft,
  [IconName.Redo]: Redo,
  [IconName.Refresh]: Refresh,
  [IconName.Region]: Region,
  [IconName.Remove]: Remove,
  [IconName.ScatterPlot]: ScatterPlot,
  [IconName.Search]: Search,
  [IconName.Security]: Security,
  [IconName.SettingsSwitch]: SettingsSwitch,
  [IconName.Settings]: Settings,
  [IconName.Share]: Share,
  [IconName.SignOut]: SignOut,
  [IconName.SortAsc]: SortAsc,
  [IconName.SortAz]: SortAz,
  [IconName.SortDesc]: SortDesc,
  [IconName.SortZa]: SortZa,
  [IconName.Sort]: Sort,
  [IconName.StoriesIcon]: StoriesIcon,
  [IconName.StoriesMenu]: StoriesMenu,
  [IconName.Story]: Story,
  [IconName.SubOrg]: SubOrg,
  [IconName.TableHierarchy]: TableHierarchy,
  [IconName.Table]: Table,
  [IconName.Tag]: Tag,
  [IconName.Team]: Team,
  [IconName.Templates]: Templates,
  [IconName.Text]: Text,
  [IconName.Tumblr]: Tumblr,
  [IconName.Twitter]: Twitter,
  [IconName.Undo]: Undo,
  [IconName.Undock]: Undock,
  [IconName.User]: User,
  [IconName.Vimeo]: Vimeo,
  [IconName.Waiting]: Waiting,
  [IconName.WarningAlt]: WarningAlt,
  [IconName.WarningAlt2]: WarningAlt2,
  [IconName.Warning]: Warning,
  [IconName.Watch]: Watch,
  [IconName.Watched]: Watched,
  [IconName.Windows]: Windows,
  [IconName.WorkingCopy]: WorkingCopy,
  [IconName.Yahoo]: Yahoo,
  [IconName.Yammer]: Yammer,
  [IconName.Youtube]: Youtube,
};

interface CustomProps {
  /** Used for accessibility and aria label by. Value must be unique and does NOT contain spaces This is required if either title or description are set */
  accesssibleId?: string;
  /** Name of the icon */
  name: IconName;
  /** Determines if aria-hidden is set to true or false */
  decorative?: boolean;
  /** title to include in the svg for accessibility reasons */
  title?: string;
  /** description to include in the svg for accessibility reasons */
  description?: string;
}

type SocrataIconProps = Omit<HTMLAttributes<HTMLSpanElement> & CustomProps, 'defaultValue' | 'aria-relevant'>;

export const SocrataIcon: FunctionComponent<SocrataIconProps> = ({
  accesssibleId,
  name,
  decorative,
  title,
  description,
  className,
  ...otherAttributes
}) => {
  const iconName = name.replace('socrata-icon-', ''); // No need to be picky.
  const icon = IconMap[iconName];

  if (!icon) {
    console.error(`Icon name ${iconName} doesn't exist!`);
  }

  const $icon = $(icon);
  if (decorative) {
    $icon.attr('aria-hidden', 'true');
  }

  if (description) {
    const descId = `${accesssibleId}-desc-id`;
    $icon.prepend(`<desc id=${descId}>${description}</desc>`);
    $icon.attr('aria-labelledby', descId);
  }

  if (title) {
    const titleId = `${accesssibleId}-title-id`;
    const existingAriaLabelBy = $icon.attr('aria-labelledby');
    $icon.prepend(`<title id=${titleId}>${title}</title>`);
    $icon.attr('aria-labelledby', `${titleId} ${existingAriaLabelBy ? existingAriaLabelBy : ''}`);
  }

  const modifiedIcon = $icon.prop('outerHTML');

  const spanClassName = classNames('socrata-icon', `socrata-icon-${iconName}`, className);

  return (
    <span
      data-testid={`socrata-icon-${name}`}
      dangerouslySetInnerHTML={{ __html: modifiedIcon }}
      className={spanClassName}
      {...otherAttributes}
    />
  );
};

export default SocrataIcon;
