import React, { useState } from 'react';
import { ForgeFloatingActionButton, ForgeIcon } from '@tylertech/forge-react';

import type InteractiveUser from 'common/types/users/interactiveUser';
import type { View } from 'common/types/view';
import WatchAssetManager from 'common/components/WatchAssetManager';

interface Props {
  user: InteractiveUser | undefined;
  view: View | undefined;
}

const AssetActionItem: React.FC<Props> = ({ user, view }) => {
  const [showWatchAssetModal, setShowWatchAssetModal] = useState(false);

  const renderWatchAssetButton = () => {
    return (
      <ForgeFloatingActionButton mini data-testid="watch-action-button">
        <button type="button" onClick={() => setShowWatchAssetModal(true)}>
          <ForgeIcon name="send_clock" aria-hidden="false" external external-type="extended" />
        </button>
      </ForgeFloatingActionButton>
    );
  };

  return (
    <>
      {user && view ? (
        <>
          {renderWatchAssetButton()}
          {showWatchAssetModal && (
            <WatchAssetManager
              onDismiss={() => setShowWatchAssetModal(false)}
              view={view}
              currentUser={user}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default AssetActionItem;
