import React, { ChangeEvent, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { CatalogUserOrTeam, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import { AccessLevel } from 'common/types/view';

import { MODES, PUBLISHED_VIEWER_ACCESS_LEVEL } from 'common/components/AccessManager/Constants';

import * as publishedToActions from 'common/components/AccessManager/actions/PublishedToActions';
import * as permissionsActions from 'common/components/AccessManager/actions/PermissionsActions';
import AddUser from 'common/components/AccessManager/components/AddUser';
import {
  getPublishedToSearchResults,
  getPublishedToSelectedUsers,
  getPublishedToUserSearchQuery,
  getUiMode
} from 'common/components/AccessManager/sagas/Selectors';

/**
 * Basically just wraps the <AddUsers /> component into a bunch of redux stuff
 * for adding collaborators
 */
const AddPublishedTo: FunctionComponent = () => {
  const dispatch = useDispatch();

  const addUsers = (selectedUsers: CatalogUserOrTeam[], selectedAccessLevel: AccessLevel, mode: MODES) =>
    dispatch(permissionsActions.addUsers(selectedUsers, selectedAccessLevel, mode));
  const addToSelectedUsers = (user?: UsersCatalogSearchResults) =>
    user && dispatch(publishedToActions.addSelectedPublishedTo(user.user!));
  const removeFromSelectedUsers = (user?: CatalogUserOrTeam) =>
    dispatch(publishedToActions.removeSelectedPublishedTo(user!));
  const userSearchQueryChanged = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(publishedToActions.publishedToSearchQueryChanged(event.target.value));

  const currentSearchQuery = useSelector(getPublishedToUserSearchQuery);
  const searchResults = useSelector(getPublishedToSearchResults);
  const selectedUsers = useSelector(getPublishedToSelectedUsers);
  const mode = useSelector(getUiMode);

  return (
    <AddUser
      addUsers={addUsers}
      addToSelectedUsers={addToSelectedUsers}
      removeFromSelectedUsers={removeFromSelectedUsers}
      currentSearchQuery={currentSearchQuery}
      selectedAccessLevel={PUBLISHED_VIEWER_ACCESS_LEVEL}
      searchResults={searchResults}
      selectedUsers={selectedUsers}
      userSearchQueryChanged={userSearchQueryChanged}
      mode={mode}
      hideAccessLevelDropdown
    />
  );
};

export default AddPublishedTo;
