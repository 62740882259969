import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import I18n from 'common/i18n';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { getCurrentUser } from 'common/current_user';

import * as permissionsActions from 'common/components/AccessManager/actions/PermissionsActions';

import AccessLevelDropdown from 'common/components/AccessManager/components/AccessLevelDropdown';
import UserDetails from 'common/components/AccessManager/components/UserDetails';
import { accessLevelKey } from 'common/components/AccessManager/Util';

import './user-details-with-access-level.scss';

import { MODES } from 'common/components/AccessManager/Constants';
import Button, { VARIANTS } from 'common/components/Button';
import { AccessLevel, ViewUser } from 'common/types/view';
import {
  getCurrentView,
  getUiMode,
  getScheduleCounts
} from 'common/components/AccessManager/sagas/Selectors';

const classNameScope = 'common--components--AccessManager--components--UserList--user-details';

interface UserDetailsWithAccessLevelProps {
  /** User to display details for */
  user: ViewUser;

  /** The user's current access level on the asset */
  accessLevel: AccessLevel;

  /** Whether or not to hide the access level dropdown */
  hideAccessLevelDropdown: boolean;
}

const renderConfirmation = (
  user: ViewUser,
  clearScheduleCount: (user: ViewUser) => void,
  onRemove: (user: ViewUser) => void,
  scheduleCount?: { total: number }
) => {
  const scope = 'shared.site_chrome.access_manager.access_removal_confirmation';

  // no schedules, don't show anything
  if ((scheduleCount?.total || 0) <= 0) {
    return null;
  }

  return (
    <div className={`alert warning ${classNameScope}--confirmation-alert`}>
      <p>{I18n.t('schedules_warning.any', { count: scheduleCount?.total, scope })}</p>
      <p>{I18n.t('confirmation', { scope })}</p>
      <div className="confirmation-buttons-container">
        <Button inverse={true} variant={VARIANTS.PRIMARY} onClick={() => clearScheduleCount(user)}>
          {I18n.t('cancel_button', { scope })}
        </Button>
        <Button inverse={true} variant={VARIANTS.ERROR} onClick={() => onRemove(user)}>
          {I18n.t('confirm_button', { scope })}
        </Button>
      </div>
      <p className="small">{I18n.t('help_message', { scope })}</p>
    </div>
  );
};

const renderAccessLevelDropdown = (
  user: ViewUser,
  hideAccessLevelDropdown: boolean,
  accessLevel: AccessLevel,
  changeUserAccessLevel: (user: ViewUser, accessLevel: AccessLevel) => void,
  mode: MODES
) => {
  if (hideAccessLevelDropdown || mode === MODES.MANAGE_PLUGIN) {
    return null;
  }

  return (
    <AccessLevelDropdown
      value={accessLevel}
      onSelection={(level: { value: AccessLevel }) => {
        changeUserAccessLevel(user, level.value);
      }}
    />
  );
};

const renderActions = (
  user: ViewUser,
  accessLevel: AccessLevel,
  hideAccessLevelDropdown: boolean,
  changeUserAccessLevel: (user: ViewUser, level: AccessLevel) => void,
  mode: MODES,
  onRemoveClick: () => void
) => {
  const currentUser = getCurrentUser();
  const currentUserUid = currentUser?.id;

  // sometimes grants can be shared to an email instead of a real user
  // if the user looking at this is a real user, this probably shouldn't be the case
  // but you never know and it's better to be safe :)
  const currentUserEmail = currentUser?.email;

  // current user cannot change their own permissions
  if (user.id === currentUserUid || user.email === currentUserEmail) {
    return (
      <div className={`${classNameScope}--static-access-level-container`}>
        {accessLevel && I18n.t(accessLevelKey(accessLevel.name))}
      </div>
    );
  }

  return (
    <div className={`${classNameScope}--user-options-container`}>
      {renderAccessLevelDropdown(user, hideAccessLevelDropdown, accessLevel, changeUserAccessLevel, mode)}
      <button className={`${classNameScope}--remove-button`} onClick={onRemoveClick}>
        <SocrataIcon name={IconName.Close} className={`${classNameScope}--remove-icon`} />
      </button>
    </div>
  );
};

/**
 * Renders user details with an access level dropdown next to them,
 * as well as a button to remove their access.
 */
const UserDetailsWithAccessLevel: FunctionComponent<UserDetailsWithAccessLevelProps> = ({
  user,
  accessLevel,
  hideAccessLevelDropdown
}) => {
  const dispatch = useDispatch();

  /* eslint @typescript-eslint/no-shadow: "warn" */
  const changeUserAccessLevel = (user: ViewUser, level: AccessLevel) =>
    dispatch(permissionsActions.changeUserAccessLevel(user, level));
  /* eslint @typescript-eslint/no-shadow: "warn" */
  const removeUser = (user: ViewUser) => dispatch(permissionsActions.removeUserAccess(user));
  /* eslint @typescript-eslint/no-shadow: "warn" */
  const checkForSchedules = (namespace: { name: string; type: string }, user: ViewUser) =>
    dispatch(permissionsActions.getScheduleCount(namespace, user, true));
  /* eslint @typescript-eslint/no-shadow: "warn" */
  const clearScheduleCount = (user: ViewUser) => dispatch(permissionsActions.clearScheduleCount(user));

  const mode = useSelector(getUiMode);
  const view = useSelector(getCurrentView);
  const scheduleCounts = useSelector(getScheduleCounts);

  const userScheduleCount = user.id ? scheduleCounts && scheduleCounts[user.id] : undefined;

  const onRemoveUser = (user: ViewUser) => {
    clearScheduleCount(user);
    removeUser(user);
  };

  const onRemoveClick = () => {
    if (mode === MODES.MANAGE_PLUGIN) {
      checkForSchedules(get(view, 'namespace'), user);
    } else {
      onRemoveUser(user);
    }
  };

  return (
    <div>
      <UserDetails user={user}>
        {renderActions(
          user,
          accessLevel,
          hideAccessLevelDropdown,
          changeUserAccessLevel,
          mode,
          onRemoveClick
        )}
      </UserDetails>
      {renderConfirmation(user, clearScheduleCount, onRemoveUser, userScheduleCount)}
    </div>
  );
};

export default UserDetailsWithAccessLevel;
