import { ForgeButton, ForgeToolbar } from '@tylertech/forge-react';
import React, { FC } from 'react';
import { useWatchContext } from '../context';

interface FooterButtonsProps {
  onDismiss: () => void;
  onSaveWatch: () => void;
  disableSave: boolean;
}

const FooterButtons: FC<FooterButtonsProps> = (props) => {
  const { t } = useWatchContext();
  const { onDismiss, onSaveWatch, disableSave } = props;

  return (
    <div slot="footer" className="watch-footer">
      <ForgeToolbar inverted={true} className="footer-toolbar">
        <div slot="end">
          <ForgeButton type="outlined">
            <button id="cancel-button" type="button" onClick={onDismiss}>
              {t('cancel')}
            </button>
          </ForgeButton>
          <ForgeButton type="raised">
            <button id="accept-button" type="button" disabled={disableSave} onClick={onSaveWatch}>
              {t('save')}
            </button>
          </ForgeButton>
        </div>
      </ForgeToolbar>
    </div>
  );
};

export default FooterButtons;
