import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import SocrataIcon from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { assert, assertHasProperties, assertHasProperty, assertInstanceOf } from 'common/assertions';

import './shared/componentBase';
import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';

const COMPONENT_VALUE_CACHE_ATTR_NAME = 'classic-visualization-component-value';

$.fn.componentSocrataVisualizationClassic = componentSocrataVisualizationClassic;

/*
  Component format:
  {
    type: "socrata.visualization.classic",
    value: {
      visualization: {
        // This data is a core view metadata json blob.
        // Checkout any 4x4 at /api/views/4x4.json
        ...
      }
    }
  }
*/

export default function componentSocrataVisualizationClassic(props) {
  props = _.extend({}, props, {
    isFilterableAsset: true,
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    }
  });

  const $this = $(this);
  let { componentData } = props;

  assertHasProperties(componentData, 'type');
  assert(
    componentData.type === 'socrata.visualization.classic',
    `componentSocrataVisualizationClassic: Unsupported component type ${componentData.type}`
  );
  assertHasProperty(componentData, 'value.visualization');

  componentData = _.cloneDeep(componentData);
  _.set(componentData, 'value.visualization.displayFormat.disableZoomWheel', true);

  if ($this.children().length === 0) {
    _renderVisualization($this, componentData);
  } else {
    _updateVisualization($this, componentData);
  }

  $this.componentBase(props);

  return $this;
}

function _renderVisualization($element, componentData) {
  assertHasProperty(componentData, 'type');
  assertHasProperty(componentData, 'value');

  const { type, value: { dataset, visualization } } = componentData;
  const classes = StorytellerUtils.typeToClassesForComponentType(type);
  const $iframeElement = $(
    '<iframe>',
    {
      'src': '/component/visualization/v0/show',
      'frameborder': '0',
      'allowfullscreen': true
    }
  );

  $iframeElement.one('load', () => {
    const $iframe = $element.find('iframe');
    // We are checking the existence of the renderVisualization function to determine if the classic viz has loaded
    const hasClassicVizLoaded = _.get($iframe[0], 'contentWindow.renderVisualization');

    // EN-34575: This is a bit of a hacky fix. We need a better way of addressing the race condition that is happening here. This will force everything in the iframe to be loaded before executing updateVisualization to render the classic viz.
    // EN-36549: Added polling logic because it was still broken in Firefox
    const maxRetryTime = 1000;
    const interval = 150;
    let retryTime = 0;
    const intervalId = setInterval(() => {
      if (hasClassicVizLoaded) {
        clearInterval(intervalId);
        _updateVisualization($element, componentData);
      } else if (retryTime < maxRetryTime) {
        retryTime += interval;
      } else {
        clearInterval(intervalId);
        console.error('Cannot render visualization. `renderVisualization`'
          + 'function not available on iframe window');
      }
    }, interval);

    $element[0].dispatchEvent(
      new CustomEvent(
        'component::visualization-loaded',
        { detail: {}, bubbles: true }
      )
    );
  });

  const linkDomain = _.get(dataset, 'federatedFromDomain', window.location.hostname);
  const iconClass = 'socrata-visualization-view-source-data-icon';
  const $sourceLinkElement = $(`
    <div class="socrata-visualization-view-source-data">
      <a href="https://${linkDomain}/d/${visualization.id}" target="_blank">
        <span>${I18n.t('common.view_source_data')}</span><span class="${iconClass}"></span>
      </a>
    </div>
  `);

  ReactDOM.render(
    React.createElement(SocrataIcon, { name: 'external' }),
    $sourceLinkElement.find(`.${iconClass}`)[0]
  );

  const $wrapper = $('<div>', { 'class': 'component-content' }).
    append([$iframeElement, $sourceLinkElement]);

  $element.
    addClass(classes).
    append($wrapper);
}

function _updateVisualization($element, componentData) {
  const $iframe = $element.find('iframe');
  const oldValue = $iframe.data(COMPONENT_VALUE_CACHE_ATTR_NAME);
  const newValue = componentData.value.visualization;

  assertInstanceOf($iframe, $);

  // This guard is to wait for loading.
  // The iframe load event above should invoke _updateVisualization again.
  if (!_.isFunction($iframe[0].contentWindow.renderVisualization)) {
    return;
  }

  // Don't re-render if we've already rendered this visualization.
  if (_.isEqual(oldValue, newValue)) {
    return;
  }

  $iframe.data(COMPONENT_VALUE_CACHE_ATTR_NAME, newValue);

  // The iframe we're using goes to a frontend endpoint: /component/visualization/v0/show.
  // This endpoint contains a function on window called renderVisualization.
  // renderVisualization kicks off a classic visualization rendering using a view
  // metadata object. See the frontend implementation for more information.
  $iframe[0].contentWindow.renderVisualization(newValue);
}
