import { fetchTranslation } from 'common/locale';
import { SoQLType } from 'common/types/soql';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.parameters_editor.suggested_values');

export enum SuggestedValuesType { // referred to as Allowed Values in the UI
  ANY_VALUES = 'any_values',
  SPECIFIC_VALUES = 'specific_values',
  VALUES_FROM_COLUMN = 'values_from_column'
}

export type ClientContextVariable = ClientContextVariableCreate & {
  inherited: boolean;
  viewId: string;
  overrideValue?: string;
};

export type ClientContextVariableCreate = {
  name: string;
  displayName: string;
  dataType: SoQLType;
  defaultValue: string;
  suggestedValuesType: SuggestedValuesType; // defaults to ANY_VALUES
  // if we add | ColumnValues , this type will need to include them
  suggestedValues?: SpecificValues;
};

export type SpecificValue = {
  value: string;
  displayName?: string;
};

export type SpecificValues = {
  valueList: SpecificValue[];
};

export type ColumnValues = {
  columnId: string;
};

export interface DateClientContextVariable extends ClientContextVariable {
  dataType: SoQLType.SoQLFloatingTimestampT | SoQLType.SoQLFixedTimestampT;
}

export interface BooleanClientContextVariable extends ClientContextVariable {
  dataType: SoQLType.SoQLBooleanT;
}

export interface Override {
  override: string,
  isValid: boolean
}

// ensure consistent translation of these values
export const getSuggestedValuesTranslation = (suggestedValueType: SuggestedValuesType): string => {
  switch (suggestedValueType) {
    case SuggestedValuesType.ANY_VALUES: return t('any_values');
    case SuggestedValuesType.SPECIFIC_VALUES: return t('specific_values');
    case SuggestedValuesType.VALUES_FROM_COLUMN: return t('column_values');
  }
};

// TODO: this should probably be localized
// cannonical way to format a date value of a parameter
export const formatParameterDate = (dateString: string) => {
  const d = new Date(dateString);
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
};
