import {
  FunCall,
  FunSpec,
  Expr,
  ColumnRef,
  Literal,
  SoQLStringLiteral,
  SoQLNumberLiteral,
  SoQLBooleanLiteral,
  SoQLNullLiteral,
  WindowFunctionInfo
} from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import {
  isString,
  isNumber,
  isViewColumn,
  isQualifiedViewColumn, QualifiedViewColumn
} from '../util';

type Argument = Expr | QualifiedViewColumn | ViewColumn | Literal['value'] | number;
const convertArgumentToExpr = (arg: Argument): Expr => {
  if (isQualifiedViewColumn(arg)) {
    return {
      type: 'column_ref',
      value: arg.column.fieldName,
      qualifier: arg.qualifier,
    } as ColumnRef;
  } else if (isViewColumn(arg)) {
    return {
      type: 'column_ref',
      value: arg.fieldName,
      qualifier: null,
    } as ColumnRef;
  } else if (isString(arg)) {
    return {
      type: 'string_literal',
      value: arg,
    } as SoQLStringLiteral;
  } else if (isNumber(arg)) {
    return {
      type: 'number_literal',
      value: arg.toString(),
    } as SoQLNumberLiteral;
  } else if (arg === true || arg === false) {
    return {
      type: 'boolean_literal',
      value: arg,
    } as SoQLBooleanLiteral;
  } else if (arg === null) {
    return {
      type: 'null_literal',
      value: arg,
    } as SoQLNullLiteral;
  } else { // should already be a Expr
    return arg;
  }
};

export type CallableFunc = (...args: Argument[]) => FunCall;
export const buildBuilder = (spec: FunSpec): CallableFunc => {
  return (...args: Argument[]): FunCall => {
    const convertedArgs: Expr[] = args.map(convertArgumentToExpr);
    const variadicArgs = convertedArgs.slice(spec.sig.length);

    if (variadicArgs.length % spec.variadic.length > 0) {
      throw new Error('Something wrong with the number of arguments.');
    }

    return {
      type: 'funcall',
      function_name: spec.name,
      args: convertedArgs,
      window: null
    };
  };
};

export type CallableWindowFunc = (...args: [Argument, WindowFunctionInfo]) => FunCall;
export const buildWindowFunctionBuilder = (spec: FunSpec): CallableWindowFunc => {
  return (arg: Argument, window: WindowFunctionInfo): FunCall => {
    return {
      type: 'funcall',
      function_name: spec.name,
      args: [convertArgumentToExpr(arg)],
      window
    };
  };
};
