import React, { FC } from 'react';
import {
  ForgeIconButton,
  ForgeIcon
} from '@tylertech/forge-react';

import UserDetails from 'common/components/AccessManager/components/UserDetails';
import { CatalogInteractiveUser } from 'common/types/users/catalogUsers';
import { ViewUser } from 'common/types/view';
import { useWatchContext } from '../context';

interface RecipientProps {
  catalogUser: CatalogInteractiveUser;
  removeUser: () => void;
  canRemove: boolean;
}

const Recipient: FC<RecipientProps> = ({ catalogUser, removeUser, canRemove }) => {
  const {
    state: { existingSubscriptions }
  } = useWatchContext();
  const canRemoveUsers = canRemove && existingSubscriptions.length < 1;
  // The components we are using sometimes want CatalogUser interfaces and sometimes ViewUser interfaces.
  const viewUser = toViewUser(catalogUser);

  return (
    <div key={viewUser.id}>
      <UserDetails user={viewUser}>
        <div className={'watcher--user-options-container'}>
        <ForgeIconButton densityLevel={3} slot="end">
          {canRemoveUsers && (
            <button type="button" aria-label="remove watcher" onClick={removeUser} className="tyler-icons">
              <ForgeIcon name="remove_circle" />
            </button>
          )}
        </ForgeIconButton>
        </div>
      </UserDetails>
    </div>
  );
};

const toViewUser = (user: CatalogInteractiveUser) =>
  ({
    ...user,
    displayName: user.screen_name,
    accessLevels: []
  } as ViewUser);

export default Recipient;
