/*
 * This is used by socrata-notifications.config.js to create a separate webpack bundle, all dependencies included.
 * This just adds a "headerNotifications" function that can be called by an external application (i.e. socrata_site_chrome)
 * to add notifications to a page without worrying about dependencies and webpack.
 */

// Notifications
// About:
//    Notifications is used to show:
//     1.) User notifications
//        Shows user notifications (and after alerts implementation.) from notifications_and_alerts
//        phoenix application. User needs to subscribe for notifications, either by watching a dataset or
//        using the notification preferences. Uses websocket, to push new notification
//        and notification status updates to the browser.
//
//    This package is mostly self contained with one exception(css: common/styleguide/partials/modal).
//
// Usage:
//    Right now, it is used only in sitechrome, but can be used anywhere.
//    Requirements:
//      * container     : dom element, where to render the notification bell
//      * options       : hash
//      * translations  : all the translations from common/i18n/config/locales/..
//                        <common.shared.site_chrome.notifications> <default: '{}>
//      * locale        : 'en'|.... <default: 'en'>
//
//    CSS Requirements:
//      * common/styleguide/partials/_modal.scss
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { loadSocketConnection } from 'common/notifications/actions/UserNotificationActions';
import reducers from 'common/notifications/reducers';
import createSagaMiddleware from 'redux-saga';
import sagas from 'common/notifications/sagas';

import Notifications from './components/Notifications/Notifications';

window.headerNotifications = (container, options, userId) => {
  const initialState = {
    configurations: options,
    userId,
    userNotificationsConfigs: {
      userNotifications: {},
      unreadUserNotificationCount: 0,
      enqueuedNotifications: [],
      transientNotifications: []
    },
    panelConfigs: {
      showNotificationPanel: false
    }
  };

  const rootNode = document.querySelector(container);

  if (!rootNode) {
    console.error(`Cannot render Notifications; no node matched ${container} in querySelector`);
    return;
  }
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk, sagaMiddleware];

  const store = createStore(reducers, initialState, applyMiddleware(...middlewares));

  sagaMiddleware.run(sagas);

  store.dispatch(
    loadSocketConnection(userId, { loadAlerts: options.showMyAlertPreference })
  );
  ReactDOM.render(
    <Provider store={store}>
      <Notifications />
    </Provider>,
    rootNode
  );
};
