/* Imports */
/* ============================================================================= */
import { APPROVALS } from 'common/core/approvals_enums';
import { WorkflowGuidance } from 'common/types/approvals';

import { SUBMISSION_KEYS_V2 } from 'common/core/approvals/guidanceHelpers';





/* Method */
/* ============================================================================= */
/**
 * Checks if an asset *could* enter the queue for the given workflow
 * e.g., if you are updating a public asset and 'require manual approval' set to true for the public workflow
 *
 * @param guidance WorkflowGuidance object
 * @returns boolean
 */
export const willEnterApprovalQueueV2 = (guidance: WorkflowGuidance) => {
  return !!(
    guidance &&
    SUBMISSION_KEYS_V2.some(
      (key) => guidance[key]?.queueOptions[0].expectedState === APPROVALS.STATUS.PENDING
    )
  );
};

export default willEnterApprovalQueueV2;
