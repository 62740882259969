import _ from 'lodash';
import airbrake from 'common/airbrake';
import { ALERT_PREFERENCE_KEYS } from 'common/notifications/constants';

/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */

export const checkStatus = (response, errorInfo) => {
  let errorMessage;
  if (response.status === 401 || response.status === 403) {
    // session may expired so we are reloading the page
    window.location.reload();
  } else if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    errorMessage = response.statusText;
    try {
      airbrake.notify({
        error: `${errorInfo}: ${errorMessage}`
      });
    } catch (err) {
    }
    throw new Error(errorMessage);
  }
};

export function isIE11() {
  return window.navigator.userAgent.indexOf('Trident') > -1;
}

export const getDefaultPreferences = () => {
  return _.map(ALERT_PREFERENCE_KEYS, (matchParams, preferenceKey) => {
    return { name: preferenceKey, enable_email_notification: false, enable_product_notification: false, match_params: matchParams };
  });
};
