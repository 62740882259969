/**
 * Maps the `ids` parameter which can be either a string, or an array, into a URL parameter for Catalog API
 * using the `param` as the name of the URL parameter.
 *
 * Examples:
 *
 *   mapIdsToParam('abcd-1234', 'ids') => 'ids=abcd-1234'
 *   mapIdsToParam(['feed-face', 'dead-beef'], 'eat') => 'eat=feed-face&eat=dead-beef'
 */
export default function mapIdsToParam(ids: string[] | string, param: string): string | undefined {
  if (!ids) {
    return;
  }
  return (typeof ids == 'string') ? `${param}=${ids}` : ids.map((id: string) => `${param}=${id}`).join('&');
}
