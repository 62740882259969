// A React Higher-Order-Component to encapsulate computing a measure.
// https://reactjs.org/docs/higher-order-components.html
// You give it a `measure` prop and it gives you `computedMeasure` and `dataRequestInFlight` props.

import _ from 'lodash';
import React, { Component } from 'react';

import airbrake from 'common/airbrake';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';

import {
  getMetricSeries as defaultGetMetricSeries,
  getNonNullResults,
  toVizData
} from '../measureCalculator';

// @ts-expect-error
import { computeStatus } from '../status';
import { hasMeasureEnded } from '../lib/measureHelpers';
import { CalculationTypes, PeriodTypes } from '../lib/constants';
import { getCalculationColumns, getDateColumn } from '../lib/columns';
import { ComputedMeasure, Measure, MetricConfig, MetricSeriesResult, ReportingPeriod } from '../types';

/**
 * Avoid recomputing measures if there's already been a request for the same
 * measure. Avoids recomputation in these common scenarios:
 * - Multiple withComputedMeasure components (the Rate editor has 3 on the same measure).
 * - Unrelated parts of the state change (open tab, etc).
 * - Charts also needing access to computed measures (in addition to MeasureResultCards).
 *
 * Maps measure cache key (see computationCacheKey) to request promise.
 */
let computationCache = new Map();

/**
 * Given a measure, returns a key usable for determining computational equality.
 * The key is a subset of the measure configuration, with presentational parts
 * (decimal places, unit label, etc.) removed, converted to a string.
 * Uses (potentially key-unstable) JSON stringification (i.e, same object may encode to different JSON string).
 * The key is stable enough to be usable. We have not observed key instability in practice.
 */
const computationCacheKey = (measure?: Measure | null, isInsituMeasure?: boolean) => {
  if (!_.isPlainObject(measure)) {
    return null;
  }

  const calculationDependentProperties = _.pick(measure, [
    'dataSourceLensUid',
    'metricConfig.display.asPercent',
    'metricConfig.display.decimalPlaces',
    'metricConfig.arguments',
    'metricConfig.type',
    'metricConfig.dateColumn',
    'metricConfig.reportingPeriod',
    'metricConfig.parameterOverrides'
  ]);

  // For inSitu measures we want to check the status and targets as well to see if we need to rerender the data on the card
  if (isInsituMeasure) {
    const calculationDependentPropertiesInsitu = _.pick(measure, [
      'metricConfig.status',
      'metricConfig.targets'
    ]);

    const configWithAdditionalProperties = {
      ...calculationDependentProperties.metricConfig,
      ...calculationDependentPropertiesInsitu.metricConfig
    };
    return JSON.stringify(
      _.set(calculationDependentProperties, 'metricConfig', configWithAdditionalProperties)
    );
  }

  return JSON.stringify(calculationDependentProperties);
};

const defaultGetDataSourceMetadata = async (measure: Measure | null) => {
  const dataSourceLensUid = measure?.dataSourceLensUid ?? '';

  if (_.isEmpty(dataSourceLensUid)) {
    return null;
  }

  const metadataProvider = new MetadataProvider(
    {
      domain: window.location.hostname,
      datasetUid: dataSourceLensUid
    },
    true
  );

  const datasetMetadataAndFederationStatus = await metadataProvider.getDatasetMetadataAndFederationStatus();
  const { metadata } = datasetMetadataAndFederationStatus;
  const displayableFilterableColumns = await metadataProvider.getDisplayableFilterableColumns({
    datasetMetadata: metadata
  });
  const attributionDomain = await metadataProvider.getAttributionDomain(datasetMetadataAndFederationStatus);

  return {
    displayableFilterableColumns,
    attributionDomain,
    dataSourceName: metadata.name
  };
};

// NOTE: This is intended to be used to clear state for tests
export const clearCache = () => {
  computationCache = new Map();
};

/**
 * These are the props that are calculated by withComputedMeasure and injected
 * into whatever component it's wrapping.
 */
export interface WithComputedMeasureInjectedProps {
  hasDataRequestError: boolean;
  computedMeasure: ComputedMeasure;
  /** Title of the data source. Used for the "View Source Data" link. */
  dataSourceName?: string;
  /** Domain for "view dataset" link. If null, will use a relative link. */
  dataSourceDomain?: string | null;
  dataRequestInFlight?: boolean;
}

interface WithComputedMeasureState {
  hasDataRequestError: boolean;
  dataRequestInFlight?: boolean;
  lastRequestedMeasure?: Measure;
  dataResponse?: ComputedMeasure;
  dataSourceName?: string;
  dataSourceDomain?: string | null;
}

/**
 * These are all the props that are used by a component wrapped by withComputedMeasure.
 */
interface WithComputedMeasureProps extends WithComputedMeasureInjectedProps {
  measure: Measure | null;
  isInsituMeasure: boolean;
}

type PropsForWrappedComponent<TProps> = Omit<TProps, keyof WithComputedMeasureInjectedProps>;

interface PromiseCache {
  dataSourceMetadata?: Promise<unknown>;
  metricBigNumber?: Promise<unknown>;
  metricSeries?: Promise<unknown>;
}

// This function takes a component...
export default function withComputedMeasure({
  getMetricSeries = defaultGetMetricSeries,
  getDataSourceMetadata = defaultGetDataSourceMetadata,
  lastPeriodOnly = false
} = {}) {
  return <TProps extends WithComputedMeasureProps>(
    ComponentToWrap: React.ComponentClass<TProps>
  ): React.ComponentClass<PropsForWrappedComponent<TProps>> => {
    const wrappedComponentDisplayName = ComponentToWrap.displayName || ComponentToWrap.name || 'Component';
    // ...and returns another component...
    return class extends Component<PropsForWrappedComponent<TProps>, WithComputedMeasureState> {
      static displayName = `withComputedMeasure(${wrappedComponentDisplayName})`;

      _isMounted = false;

      constructor(props: PropsForWrappedComponent<TProps>) {
        super(props);
        this.state = { hasDataRequestError: false };
      }

      UNSAFE_componentWillMount() {
        this._isMounted = true;
        this.checkProps(this.props);
      }

      componentWillUnmount() {
        this._isMounted = false;
      }

      UNSAFE_componentWillReceiveProps(nextProps: PropsForWrappedComponent<TProps>) {
        this.checkProps(nextProps);
      }

      // When the measure changes, we need to initiate a backend request. If there is
      // already an outstanding request, we defer processing the new measure until
      // the outstanding request completes.
      onMeasureChanged(props: PropsForWrappedComponent<TProps>) {
        if (this.state.dataRequestInFlight) {
          // Do nothing - makeRequest() will automatically call checkProps()
          // when the request completes. That will cause onMeasureChanged to be
          // called again (if the measure has indeed changed).
          return;
        }

        if (!props.measure || !this._isMounted) {
          // Do nothing. Component essentially becomes a pass-through.
          return;
        }

        this.setState(
          (prevState, newProps) => ({
            lastRequestedMeasure: newProps.measure!,
            dataRequestInFlight: true
          }),
          this.makeRequest
        );
      }

      // Called whenever props change or the component receives initial props.
      // Equality is based on the cache key, since it should encompass all of
      // the computation-sensitive properties of the measure.
      checkProps(props: PropsForWrappedComponent<TProps>) {
        const oldCacheKey = computationCacheKey(this.state.lastRequestedMeasure, props.isInsituMeasure);
        const newCacheKey = computationCacheKey(props.measure, props.isInsituMeasure);
        const measureHasChanged = !_.isEqual(oldCacheKey, newCacheKey);

        if (measureHasChanged) {
          this.onMeasureChanged(props);
        }
      }

      makeRequest() {
        const { measure, isInsituMeasure } = this.props;
        const metricConfig = _.get(measure, 'metricConfig', {} as MetricConfig);
        const reportingPeriod = _.get(metricConfig, 'reportingPeriod', {} as ReportingPeriod);
        const isLastReported = reportingPeriod.type === PeriodTypes.LAST_REPORTED;
        const dataResponse: ComputedMeasure = {};

        const handleError = (error: Error) => {
          if (this._isMounted) {
            const newState = {
              dataRequestInFlight: false,
              hasDataRequestError: true
            };

            if (_.get(error, 'status') === 403) {
              // Handle permission denied by reporting it to the user rather than airbrake/throw
              this.setState({
                ...newState,
                dataResponse: {
                  errors: {
                    dataSourcePermissionDenied: true
                  }
                }
              });
              return;
            }

            this.setState(newState);
          }

          if (airbrake.available()) {
            airbrake.notify({
              error: error,
              context: { component: 'withComputedMeasure' }
            });
          } else {
            throw error; // Better debugging experience locally.
          }
        };

        const handleSuccess = (response = {}, dataSourceDomain: string, dataSourceName: string) => {
          if (this._isMounted) {
            this.setState(
              {
                dataResponse: response,
                dataSourceName,
                dataSourceDomain,
                dataRequestInFlight: false,
                hasDataRequestError: false
              },
              () => this.checkProps(this.props)
            );
          }
        };
        const cacheKey = computationCacheKey(measure, isInsituMeasure);
        const promiseCache: PromiseCache = {};

        const dataSourceMetadataPromise =
          _.get(computationCache.get(cacheKey), 'dataSourceMetadata') || getDataSourceMetadata(measure);
        promiseCache.dataSourceMetadata = dataSourceMetadataPromise;

        let metricSeriesPromise;
        // TODO: Remove check for isLastPeriodRateWithSum once EN-41172 is complete.
        // This was added only to unblock the customer in EN-40985.
        const rateType = _.get(metricConfig, 'arguments.aggregationType', {} as MetricConfig);
        const isLastPeriodRateWithSum =
          lastPeriodOnly && (metricConfig?.type ?? '') === CalculationTypes.RATE && rateType === 'sum';
        if (lastPeriodOnly && !isLastPeriodRateWithSum) {
          metricSeriesPromise =
            _.get(computationCache.get(cacheKey), 'metricBigNumber') ||
            getMetricSeries(measure, { lastPeriodOnly: true });
          promiseCache.metricBigNumber = metricSeriesPromise;
        } else {
          metricSeriesPromise =
            _.get(computationCache.get(cacheKey), 'metricSeries') || getMetricSeries(measure);
          promiseCache.metricSeries = metricSeriesPromise;
        }

        Promise.all([dataSourceMetadataPromise, metricSeriesPromise])
          .then(([dataSourceMetadataResult, metricSeriesResult]) => {
            // dataSourceMetadataResult may be null if no data source defined.
            const { attributionDomain, displayableFilterableColumns, dataSourceName } =
              dataSourceMetadataResult || {};

            dataResponse.calculationColumns = getCalculationColumns(measure, displayableFilterableColumns);
            dataResponse.dateColumn = getDateColumn(measure, displayableFilterableColumns);

            const series: MetricSeriesResult[] = computeStatus(
              measure,
              metricSeriesResult.series,
              dataResponse.calculationColumns
            );

            const { errors } = metricSeriesResult;
            if (errors && Object.keys(errors).length > 0) {
              // These errors will be issues with measure configuration
              dataResponse.errors = errors;
            } else {
              dataResponse.series = toVizData(series);
              const lastReportedData =
                isLastReported || hasMeasureEnded(measure)
                  ? _.last(getNonNullResults(series))
                  : _.last(series);

              if (lastReportedData) {
                dataResponse.date = lastReportedData.date;
                dataResponse.result = lastReportedData.result;
                dataResponse.errors = lastReportedData.errors;
              } else {
                dataResponse.errors = { noReportingPeriodAvailable: true };
              }
            }

            handleSuccess(dataResponse, attributionDomain, dataSourceName);
          })
          .catch(handleError);
        // The value in the cache map can have multiple keys, so merge them
        computationCache.set(cacheKey, Object.assign({}, computationCache.get(cacheKey), promiseCache));
      }

      render() {
        // The types are currently correct, but technically, you could subclass WithComputedMeasureProps
        // and then this would break. idk how to fix that ¯\_(ツ)_/¯
        // But DO NOT actually fix this. Just make this entire file a hook/not a HOC.
        // @ts-expect-error
        const returnProps: TProps = {
          hasDataRequestError: this.state.hasDataRequestError,
          computedMeasure: this.state.dataResponse,
          dataSourceDomain: this.state.dataSourceDomain,
          dataSourceName: this.state.dataSourceName,
          dataRequestInFlight: this.state.dataRequestInFlight,
          ...this.props
        };

        return <ComponentToWrap {...returnProps} />;
      }
    };
  };
}
