import _ from 'lodash';

import { StartDateTypes } from '../constants';

/**
 * There are some measures in the wild which have cumulative math enabled
 * but do not have a cumulative math start date. This fixes those.
 * @param measure
 */
const backfillCumulativeMathStartDate = (measure: any) => {
  const backfilledMeasure = _.cloneDeep(measure);
  const startDate = _.get(measure, 'metricConfig.reportingPeriod.startDateConfig.date');
  const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);
  const cumulativeStartDateExist =
    _.get(measure, 'metricConfig.arguments.cumulativeMathStartDate', false);
  const isFloatingStartDate = _.get(measure, 'metricConfig.reportingPeriod.startDateConfig.type') === StartDateTypes.FLOATING;

  if (isCumulativeMath && !cumulativeStartDateExist && !isFloatingStartDate) {
    _.set(
      backfilledMeasure,
      'metricConfig.arguments.cumulativeMathStartDate',
      startDate
    );
  }

  return backfilledMeasure;
};

export default backfillCumulativeMathStartDate;
