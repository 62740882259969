import I18n from 'common/i18n';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import has from 'lodash/has';

import { ViewColumn } from 'common/types/viewColumn';

const Errors = {
  NOT_UNIQUE: 'error_not_unique',
  NO_VALUE: 'error_no_value',
  INVALID_NAME: 'error_invalid_name',
  TOO_LONG: 'error_too_long'
};

function isUnique<S>(val: S, vals: S[]): boolean {
  return !vals.includes(val);
}

function hasValue(val?: string): boolean {
  return !!val;
}

function isValidFieldName(val: string): boolean {
  return /^[a-zA-Z_][a-zA-Z0-9_]*$/.test(val);
}

function withinMaxCharLimit(val: string): boolean {
  // Spread operator gives us Unicode code points, so that we match
  // how postgres determines string length
  return [...val].length <= 255;
}

export function validateColumnFieldName(val: string, existingFieldNames: string[]): string[] {
  const errors = [];

  if (!isUnique(val, existingFieldNames)) {
    errors.push(Errors.NOT_UNIQUE);
  }

  if (!hasValue(val)) {
    errors.push(Errors.NO_VALUE);
  }

  if (!isValidFieldName(val)) {
    errors.push(Errors.INVALID_NAME);
  }

  if (!withinMaxCharLimit(val)) {
    errors.push(Errors.TOO_LONG);
  }

  return errors.map((err) => I18n.t(`common.column.validation.${err}`));
}

export function validateColumnDisplayName(val: string, existingDisplayNames: string[]): string[] {
  const errors = [];

  if (!isUnique(val, existingDisplayNames)) {
    errors.push(Errors.NOT_UNIQUE);
  }

  if (!hasValue(val)) {
    errors.push(Errors.NO_VALUE);
  }

  if (!withinMaxCharLimit(val)) {
    errors.push(Errors.TOO_LONG);
  }

  return errors.map((err) => I18n.t(`common.column.validation.${err}`));
}

export const isRegionComputedColumn = (column: ViewColumn) => {
  return has(column, 'computationStrategy.parameters.region');
};

export const isNotRegionComputedColumn = (column: ViewColumn) => {
  return !isRegionComputedColumn(column);
};

/**
 * Deep diff between two object, using lodash
 * This is useful to learn the exact differences for _.isEqual comparisons.
 * WET notice: duplicated in js_utils/deepDifference, available on window.socrata.utils
 * This instance is unreferenced and can probably be removed.
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
// eslint-disable-next-line
export function deepDifference(object: Object, base: Object) {
  // eslint-disable-next-line
  function changes(object: any, base: any) {
    // eslint-disable-next-line
    return transform(object, function (result: Object, value: any, key: string) {
      if (!isEqual(value, base[key])) {
        // eslint-disable-next-line
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}
