import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import * as SocrataVisualizations from 'common/visualizations';
import MostRecentlyUsed from 'common/most_recently_used';
import 'common/notifications/main';
import 'common/site_wide';
import AssetActionBar from 'common/components/AssetActionBar';

import { Block, ComponentType, StoryData } from 'types';
import Environment from 'StorytellerEnvironment';
import migrateStoryData from 'lib/migrateStoryData';
import 'lib/components/block-component-renderers/shared/componentBase';
import 'lib/components/block-component-renderers';
import 'lib/components/Modal';
import StorytellerUtils from 'lib/StorytellerUtils';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'edit... Remove this comment to see the full error message
import StoryCopierRenderer from 'editor/renderers/StoryCopierRenderer';
import { windowSizeBreakpointStore } from 'editor/stores/WindowSizeBreakpointStore';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Pr... Remove this comment to see the full error message
import PresentationMode from './PresentationMode';
import { renderStoryComponent, RenderStoryComponentArgs } from './utils';
import ViewBlockSection from './components/ViewBlockSection';
import AssetActionItem from './components/AssetActionItem';
import { getView } from 'common/core/views_service';
import { getCurrentUser } from 'common/current_user';
import FeatureFlag from 'common/feature_flags';
import PdfCreatedAtItem from './components/PdfCreatedAtItem';

/**
 * Redux store imports
 */
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import { createStory } from 'store/TopLevelActions';
import { populateGlobalFilter, populateDatasetMetadata } from 'store/reducers/DataSourceReducer';
import { selectors as dataSourceSelectors } from 'store/selectors/DataSourceSelectors';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

// We must attach our Redux Store in view mode so that certain actions can be dispatched from the AssetActionBar.
StorytellerUtils.export(StorytellerReduxStore, 'storyteller.reduxStore');

const renderAssetActionItem = async () => {
  const currentUser = getCurrentUser();
  const currentView = await getView(Environment.STORY_UID);

  if (
    $('.btn-watch-asset-button').length > 0 &&
    FeatureFlag.value('enable_screenshot_subscriptions') &&
    FeatureFlag.value('enable_embedded_schedule_send') &&
    Environment.RENDER_EMBEDDED_SCHEDULE_SEND
  ) {
    ReactDOM.render(
      <AssetActionItem user={currentUser} view={currentView} />,
      document.getElementsByClassName('btn-watch-asset-button')[0]
    );
  }
};

const renderPdfCreatedAtItem = async () => {
  if (Environment.RENDER_PDF_CREATED_TIMESTAMP) {
    const currentUser = await getCurrentUser();
    if ($('#pdf-created-at-timestamp').length > 0) {
      ReactDOM.render(
        <PdfCreatedAtItem user={currentUser} />,
        document.getElementById('pdf-created-at-timestamp')
      );
    }
  }
};

function _renderStory() {
  const globalFilters = dataSourceSelectors.getGlobalFilters(StorytellerReduxStore.getState());
  const parameterOverrides = dataSourceSelectors.getParameterOverrides(StorytellerReduxStore.getState());
  const dataSource = dataSourceSelectors.getReportDataSource(StorytellerReduxStore.getState());

  const defaultProps = {
    blockId: null,
    componentIndex: null,
    theme: null
  };

  const renderFlexibleComponents = () => {
    const $gridElements = $('.block');
    const blocks = Object.values(storySelectors.getBlocks(StorytellerReduxStore.getState()) ?? {});

    blocks.forEach((block: Block, idx: number) => {
      // Global Filters is an exception and should not be rendered via ViewBlockSection
      if (block.components[0].type === ComponentType.GLOBAL_FILTER) {
        const $globalFilterElement = $('.component-container').find('.component.component-global-filter');
        const props: any = {
          ...defaultProps,
          componentData: block.components[0],
          additionalFilters: globalFilters,
          parameterOverrides
        };
        $globalFilterElement.componentGlobalFilter({ ...props, dataSource });
      } else {
        const componentData = block.components;
        ReactDOM.render(
          <ViewBlockSection
            components={componentData}
            globalFilters={globalFilters}
            parameterOverrides={parameterOverrides}
            blockId={`${idx}`}
          />,
          $gridElements[idx]
        );
        if (typeof block.background_color !== 'undefined') {
          StorytellerUtils.setBackgroundColor($gridElements[idx], block.background_color);
        }
      }
    });
  };

  if (isFlexibleStory()) {
    renderFlexibleComponents();
  } else {
    // Init visualizations
    $('[data-component-data]').each(function (index, element) {
      let componentData;
      const $element = $(element);
      const serializedComponentData = element.getAttribute('data-component-data');

      if (serializedComponentData !== null) {
        componentData = JSON.parse(serializedComponentData);
        const elementProps: RenderStoryComponentArgs['elementProps'] = {
          ...defaultProps,
          componentData,
          additionalFilters: globalFilters,
          parameterOverrides
        };
        renderStoryComponent({ $element, elementProps });
      }
    });
  }
}

$(function () {
  /** Do not read directly from STORY_DATA! Instead, prefer reading from the Redux Store. */
  const STORY_DATA = Environment.STORY_DATA!;
  migrateStoryData(STORY_DATA).then(() => {
    new PresentationMode(); // eslint-disable-line no-new

    renderAssetActionItem();
    renderPdfCreatedAtItem();

    SocrataVisualizations.views.RowInspector.setup();

    windowSizeBreakpointStore.addChangeListener(_applyWindowSizeClass);

    const $window = $(window);

    const $userStoryContainer = $('.user-story-container');
    const $userStory = $('.user-story');
    const $adminHeader = $('#site-chrome-admin-header');
    const $header = $('#site-chrome-header');
    const $footer = $('#site-chrome-footer');

    function _resizeVizOnPrint() {
      $('.socrata-visualization-renderer').trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    }

    // This doesn't work in FF, but in Chrome it tells the visualizations to resize themselves
    // when Ctrl+P is pressed or Print is chosen from the browser menu.
    if (
      typeof window.matchMedia === 'function' &&
      typeof window.matchMedia('print')?.addEventListener === 'function'
    ) {
      window.matchMedia('print').addEventListener('change', (e) => {
        if (e.matches) setTimeout(_resizeVizOnPrint);
      });
    }

    function _applyWindowSizeClass() {
      const windowSizeClass = windowSizeBreakpointStore.getWindowSizeClass();
      const unusedWindowSizeClasses = windowSizeBreakpointStore.getUnusedWindowSizeClasses();

      $userStoryContainer.removeClass(unusedWindowSizeClasses.join(' ')).addClass(windowSizeClass);

      $userStory.removeClass(unusedWindowSizeClasses.join(' ')).addClass(windowSizeClass);
    }

    function _moveFooterToBottomOfWindowOrContent() {
      const viewportHeight = $window.height();
      const headerHeight = $header.outerHeight();
      const adminHeaderHeight = $adminHeader.outerHeight() || 0;
      const contentHeight = $userStory.outerHeight();
      const footerHeight = $footer.outerHeight();

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (viewportHeight > contentHeight + adminHeaderHeight + headerHeight + footerHeight) {
        $footer.css({ position: 'absolute', bottom: 0 });
      } else {
        $footer.css({ position: '', bottom: '' });
      }
    }

    /**
     * Populating the Redux store with the story data in view mode
     *
     * TODO: We should refactor this once we fully transition to Redux.
     * These should be set as the initial state and should not need
     * special actions to populate the store.
     */
    StorytellerReduxStore.dispatch(createStory(STORY_DATA));
    StorytellerReduxStore.dispatch(populateGlobalFilter(STORY_DATA));
    // See EN-62496
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    StorytellerReduxStore.dispatch(populateDatasetMetadata());
    StorytellerReduxStore.subscribe(_renderStory);

    _renderStory();

    // Init window size
    _applyWindowSizeClass();

    _moveFooterToBottomOfWindowOrContent();
    $window.on('resize', _moveFooterToBottomOfWindowOrContent);

    const aabContainer = document.getElementById('storyteller-asset-action-bar-container');
    if (
      Environment.RENDER_ASSET_ACTION_BAR &&
      Environment.CURRENT_USER &&
      Environment.CORE_VIEW &&
      aabContainer
    ) {
      ReactDOM.render(
        <AssetActionBar user={Environment.CURRENT_USER} view={Environment.CORE_VIEW} />,
        aabContainer
      );

      /* eslint-disable no-new */
      new StoryCopierRenderer({
        storyCopierContainerElement: $('#make-a-copy-container')
      });
      /* eslint-enable no-new */
    }

    if (Environment.CURRENT_USER && Environment.STORY_UID) {
      new MostRecentlyUsed({ namespace: `socrata:assets:mru:${Environment.CURRENT_USER.id}` }).add(
        Environment.STORY_UID
      );
    }
  });
});
