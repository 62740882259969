// Entry point for new maps.
// Requirements:
//  * mapbox-gl.js to be included in the javascript bundle.
//  * mapbog-gl.css to be loaded in the page.

import $ from 'jquery';
import _ from 'lodash';
import mapboxgl from '@socrata/mapbox-gl';

import { getApiToken, TOKEN_NAMES } from 'common/api_tokens';

import UnifiedMap from './views/UnifiedMap';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';

$.fn.socrataUnifiedMap = function (vif, options) {
  const $element = $(this);
  vif = migrateVif(vif);

  if (!mapboxgl.accessToken) {
    mapboxgl.accessToken = getApiToken(TOKEN_NAMES.MAPBOX_ACCESS_TOKEN);
  }

  const unifiedMapInstance = new UnifiedMap($element, vif, options);
  unifiedMapInstance.initialize(vif);
  unifiedMapInstance.renderSummaryTable(vif);

  attachEvents($element, unifiedMapInstance);
};

const attachEvents = ($element, unifiedMapInstance) => {
  $element.one('SOCRATA_VISUALIZATION_DESTROY', function () {
    unifiedMapInstance.destroy();
    detachEvents($element, unifiedMapInstance);
  });

  // TODO: To be handled in Mouse Interaction and the next set of stories.
  // $element.on('SOCRATA_VISUALIZATION_FLYOUT_SHOW', handleVisualizationFlyoutShow);
  // $element.on('SOCRATA_VISUALIZATION_FLYOUT_HIDE', handleVisualizationFlyoutHide);
  // $element.on('SOCRATA_VISUALIZATION_ROW_INSPECTOR_QUERY', handleRowInspectorQuery);
  // $element.on('SOCRATA_VISUALIZATION_FEATURE_MAP_CENTER_AND_ZOOM_CHANGE', handleMapCenterAndZoomChange);
  $element.on('SOCRATA_VISUALIZATION_IN_EDIT_FILTER_CHANGED', unifiedMapInstance.onInEditFilterChange);
  $element.on('SOCRATA_VISUALIZATION_FILTER_CHANGED', unifiedMapInstance.onFilterChange);
  $element.on('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', unifiedMapInstance.invalidateSize);
  $element.on('SOCRATA_VISUALIZATION_RENDER_VIF', unifiedMapInstance.onUpdateEvent);
};

const detachEvents = ($element, unifiedMapInstance) => {
  // TODO: To be handled in Mouse Interaction and the next set of stories.
  // $element.off('SOCRATA_VISUALIZATION_FLYOUT_SHOW', handleVisualizationFlyoutShow);
  // $element.off('SOCRATA_VISUALIZATION_FLYOUT_HIDE', handleVisualizationFlyoutHide);
  // $element.off('SOCRATA_VISUALIZATION_ROW_INSPECTOR_QUERY', handleRowInspectorQuery);
  // $element.off('SOCRATA_VISUALIZATION_FEATURE_MAP_CENTER_AND_ZOOM_CHANGE', handleMapCenterAndZoomChange);
  $element.off('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', unifiedMapInstance.invalidateSize);
  $element.off('SOCRATA_VISUALIZATION_RENDER_VIF', unifiedMapInstance.onUpdateEvent);
};

$.fn.socrataUnifiedMap.validateVif = (vif) => {
  // TODO: Add validations...
};

export default $.fn.socrataUnifiedMap;
