import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import { Expression, FormatStyle, TableColumnFormat } from '../../../../authoring_workflow/reducers/types';
import { isConditionMet, asParsableNumber } from './TableColumnFormatting';
import { ViewColumn } from 'common/types/viewColumn';
import { get, includes, isEmpty, isEqual, isNil, isUndefined } from 'lodash';
import { SoQLType } from 'common/types/soql';
import { CellClassParams, IRowNode } from '@ag-grid-community/core';
import { GROUP_COLUMN_PREFIX } from '../Constants';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { defaultRowStripeValue } from 'common/authoring_workflow/components/panes/PresentationPane/RowStripeSelector';
import {
  DEFAULT_TABLE_HEADER_TEXT_COLOR,
  DEFAULT_TABLE_HEADER_BACKGROUND_COLOR,
  CHECKBOX_COLUMN_TYPE
} from 'common/authoring_workflow/constants';
import { isColumnStylingInTablesEnabled } from 'common/visualizations/helpers/VifSelectors';

export interface IExcelStylesParams {
  id: string;
  alignment?: { horizontal: string };
  font?: { color?: string; italic?: boolean; bold?: boolean };
  interior?: { color: string; pattern: string };
}

interface ITableExportFormattingParams {
  columnFormats: { [key: string]: TableColumnFormat };
  rowStripeStyle: RowStripeStyle | undefined;
  columnMetadata: ViewColumn[];
  headerFormat: FormatStyle;
}

/**  excelStyles in gridOptions expects an array of style objects, each of which should include
 * an id property (which corresponds to the className) that matches the values generated by generateCellClasses.
 * @returns ```
 * [
 *   {
 *       id: "style-option-<fieldname>-",
 *       font: { color: "#e0ffc1"},
 *       interior: {
 *          color: "#008000", pattern: 'Solid'
 *       }
 *   }
 * ]```;
 */
export const generateExcelStyles = (params: ITableExportFormattingParams) => {
  const columnFormats = params.columnFormats;
  let excelStyles: IExcelStylesParams[] = generateCommonCellClasses(params.headerFormat);
  excelStyles = [
    ...generateDefaultStyle(params.columnMetadata),
    ...excelStyles,
    ...generateRowStriping(params.rowStripeStyle)
  ];

  Object.keys(columnFormats).forEach((colKey) => {
    excelStyles.push(generateStyleOption(colKey, styleAttributes(columnFormats[colKey]), 'style-option'));

    if (columnFormats[colKey].isAlignHeader) {
      const alignment = columnFormats[colKey].format?.align ?? '';
      excelStyles.push(generateHeaderAlignment(alignment));
    }

    const conditionalConfigs = columnFormats[colKey].conditionalConfig;
    conditionalConfigs?.forEach((config, index) => {
      if (index > 0) {
        const isSameCondition = isEqual(conditionalConfigs[index - 1].expression, config.expression);
        if (isSameCondition) return;
      }
      excelStyles.push(generateStyleOption(colKey, styleAttributes(config), 'conditional-config', index));
    });
  });

  return excelStyles;
};

interface IPushColumnStyleClasses {
  params: ICellClassesParams;
  configClassIndex: number | undefined;
  columnName: string;
  styleClasses: string[];
  columnFormatItem: TableColumnFormat;
}

const pushColumnStyleClasses = ({
  configClassIndex,
  params,
  styleClasses,
  columnName,
  columnFormatItem
}: IPushColumnStyleClasses) => {
  if (!isUndefined(configClassIndex) && configClassIndex !== -1 && !params.node.footer) {
    styleClasses.push(`conditional-config-${columnName}-${configClassIndex}`);
  }
  if (columnFormatItem && params.value && !params.node.isRowPinned() && !params.node.footer) {
    styleClasses.push(`style-option-${columnName}`);
  }
};

export interface ICellClassesParams {
  colFieldName: string;
  colDataTypeName: string;
  node: IRowNode;
  context: CustomAgGridContext;
  value: string | number;
}

/**  cellClass expects an array of classes that match the ID values generated by `generateExcelStyles()`.
 *
 * The classNames are dependent to `columnFormatting` values.
 *
 * For conditional formatting with multiple conditions, we only want to return one class, which has the highest priority.
 * @returns
 * ```
 * ['conditional-config-<fieldname>-<matchedConfigIndex>', 'style-option-<fieldname>', 'default-style-<fieldname>'];
 * ```
 */
export const generateCellClasses = (params: ICellClassesParams) => {
  const styleClasses: string[] = [];
  const columnName = params.colFieldName.replace(`${GROUP_COLUMN_PREFIX}-`, '');
  const columnFormatItem = params.context.columnFormats[columnName];

  const conditionalConfig = params.context.columnFormats[columnName]?.conditionalConfig;
  const configClassIndex = conditionalConfig?.findIndex((config: { expression: Expression }) => {
    const expression = config.expression;
    const expressionFunction = get(expression, 'function', '');
    const isNumberType = isEqual(SoQLType.SoQLNumberT, params.colDataTypeName);
    const processedValue = isNumberType ? asParsableNumber(params.value, columnFormatItem) : params.value;
    const booleanValueMatched =
      includes([CHECKBOX_COLUMN_TYPE], params.colDataTypeName) &&
      expressionFunction === processedValue?.toString();
    return booleanValueMatched || isConditionMet(expression, processedValue, isNumberType);
  });

  const isGroupedRowAndGroupedColumn = params.node.group && columnName === params.node.field;
  const isNonGroupedRowAndNonGroupedColumn =
    !params.node.group && !params.context.groupedColumns.includes(columnName);

  if (isGroupedRowAndGroupedColumn || isNonGroupedRowAndNonGroupedColumn) {
    pushColumnStyleClasses({
      configClassIndex,
      columnFormatItem,
      columnName,
      params,
      styleClasses
    });
  }

  styleClasses.push(`default-style-${columnName}`);

  const rowStripeStyle =
    params.node.rowIndex && params.node.rowIndex % 2 !== 0 ? 'alternate-row' : 'base-row';
  styleClasses.push(rowStripeStyle);

  return styleClasses;
};

const generateRowStriping = (rowStripeStyle: RowStripeStyle | undefined) => {
  if (!isNil(rowStripeStyle) && !isEmpty(rowStripeStyle)) {
    return ['base', 'alternate'].map((type) => {
      const stripeStyle = rowStripeStyle[type as keyof RowStripeStyle];
      const defaultStyle = defaultRowStripeValue[type as keyof RowStripeStyle];
      const style = {
        id: `${type}-row`,
        font: {
          color: stripeStyle?.text || '#000'
        }
      };

      if (!isEqual(stripeStyle?.fill, defaultStyle?.fill)) {
        const interiorStyle = {
          interior: {
            color: stripeStyle?.fill || '#fff',
            pattern: 'Solid'
          }
        };
        return { ...style, ...interiorStyle };
      } else {
        return style;
      }
    });
  }
  return [];
};

const generateDefaultStyle = (viewColumns: ViewColumn[]) => {
  return viewColumns.map((viewColumn: ViewColumn) => {
    let alignment = 'Left';
    if (viewColumn.renderTypeName === SoQLType.SoQLNumberT) {
      alignment = 'Right';
    } else if ([SoQLType.SoQLBooleanT, SoQLType.SoQLBooleanAltT].includes(viewColumn.renderTypeName)) {
      alignment = 'Center';
    }
    return {
      id: `default-style-${viewColumn.fieldName}`,
      alignment: {
        horizontal: alignment
      }
    };
  });
};

const generateStyleOption = (colKey: string, styles: any, prefix: string, index?: number) => {
  const id = typeof index === 'number' ? `${prefix}-${colKey}-${index}` : `${prefix}-${colKey}`;
  const baseStyleOption = {
    id: id
  };
  const stylingOption = {
    font: {
      color: styles.fontColor,
      bold: styles.bold,
      italic: styles.italic
    },
    interior: {
      color: styles.backgroundColor,
      pattern: 'Solid'
    }
  };

  if (prefix === 'style-option') {
    return isColumnStylingInTablesEnabled() ? { ...baseStyleOption, ...stylingOption } : baseStyleOption;
  }

  return { ...baseStyleOption, ...stylingOption };
};

const styleAttributes = (formatObj: any) => {
  return {
    fontColor: formatObj.style?.textColor,
    backgroundColor: formatObj.style?.backgroundColor,
    bold: formatObj.style?.fontStyle?.isBold,
    italic: formatObj.style?.fontStyle?.isItalic
  };
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** headerClass in columnDef returns an array of class names - ag-grid-header included.
 * We need to use the class name - 'ag-grid-header' to match the value from columnDef headerClass
 * we don't need multiple class names since the style is same for all column headers.
 * returns a value with type IExcelStylesParams
 * sample returned value: ```
 * { id: 'ag-grid-header', font: { color: <value>, bold: <value>, italic: <value>}, interior: { color: <value>, pattern: 'Solid' }} ```;
 */
const generateHeaderStyle = (headerFormat: FormatStyle) => {
  const isBoldHeaderFontStyle = headerFormat?.fontStyle?.isBold ?? true;
  const isItalicHeaderFontStyle = headerFormat?.fontStyle?.isItalic ?? false;
  const textColor = headerFormat.textColor ?? DEFAULT_TABLE_HEADER_TEXT_COLOR;
  const backgroundColor = headerFormat.backgroundColor ?? DEFAULT_TABLE_HEADER_BACKGROUND_COLOR;
  const style = {
    id: 'ag-grid-header',
    font: {
      color: textColor,
      bold: isBoldHeaderFontStyle,
      italic: isItalicHeaderFontStyle
    }
  };
  const interiorStyle = {
    interior: {
      color: backgroundColor,
      pattern: 'Solid'
    }
  };

  if (isEqual(backgroundColor, DEFAULT_TABLE_HEADER_BACKGROUND_COLOR)) {
    return style as IExcelStylesParams;
  }

  return { ...style, ...interiorStyle } as IExcelStylesParams;
};

/** headerClass in columnDef returns an array of class names - ag-grid-header-${alignment} included.
 * We need to use that class name to match the value from columnDef headerClass
 * returns a value with type IExcelStylesParams
 * sample returned value: ```
 * { id: 'ag-grid-header-left', alignment: { horizontal: 'Left' }} ```;
 */
const generateHeaderAlignment = (alignment: string) => {
  return {
    id: `ag-grid-header-${alignment}`,
    alignment: {
      horizontal: capitalize(alignment)
    }
  } as IExcelStylesParams;
};

const generateCommonCellClasses = (headerFormat: FormatStyle) => {
  const excelStyles: any = [];
  excelStyles.push(generateHeaderStyle(headerFormat));

  // Grand Total Row
  excelStyles.push({
    id: 'total-row',
    font: {
      bold: true
    }
  });

  // Group Total Row
  excelStyles.push({
    id: 'subtotal-row',
    font: {
      bold: true
    }
  });

  // Alignment
  ['Center', 'Left', 'Right'].forEach((alignment) => {
    excelStyles.push({
      id: `${alignment.toLowerCase()}-align`,
      alignment: {
        horizontal: alignment
      }
    });
  });

  return excelStyles;
};

export const getCellClassRules = (params: CellClassParams, rule: string) => {
  const colId = params.colDef?.field;
  if (!colId || params.node.footer) {
    return '';
  }
  return params.context.columnFormats[colId]?.format.align == rule;
};

export const generateUrlValue = (value: { url: string; description?: string }) => {
  if (!value) {
    return;
  }
  const linkValue = value.hasOwnProperty('description') ? `${value.description} (${value.url})` : value.url;
  return linkValue;
};
