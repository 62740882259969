import { defaultHeaders, fetchJson } from 'common/http';
import { toApiParams } from './AlertConverter';

const createAlertAPI = {
  // Validate 'advanced alert' 's raw-soql-query
  validate: (alertParams) => {
    return fetchJson('/api/notifications_and_alerts/alerts/validate_raw_soql', {
      method: 'POST',
      headers: defaultHeaders,
      credentials: 'same-origin',
      body: JSON.stringify({ alert: toApiParams(alertParams) })
    });
  },
  // Validate 'custom alert' 's implied soql  (constructed from the soql slices).
  validateCustomAlert: (alertParams) => {
    return fetchJson('/api/notifications_and_alerts/alerts/validate_abstract_params', {
      method: 'POST',
      headers: defaultHeaders,
      credentials: 'same-origin',
      body: JSON.stringify({ alert: toApiParams(alertParams) })
    });
  },
  // Creates an alert
  create: (alertParams) => {
    return fetchJson('/api/notifications_and_alerts/alerts', {
      method: 'POST',
      headers: defaultHeaders,
      credentials: 'same-origin',
      body: JSON.stringify({ alert: toApiParams(alertParams) })
    }).then((response) => response.data);
  },
  // Updates an alert
  update: (params, alertId) => {
    return fetchJson(`/api/notifications_and_alerts/alerts/${alertId}`, {
      method: 'PUT',
      headers: defaultHeaders,
      credentials: 'same-origin',
      body: JSON.stringify({ alert: toApiParams(params) })
    }).then((response) => response.data);
  },
  // Bulk Update
  bulkUpdate: (alertIds, params) => fetchJson('/api/notifications_and_alerts/alerts/bulk_update', {
    method: 'POST',
    headers: defaultHeaders,
    credentials: 'same-origin',
    body: JSON.stringify({ ids: alertIds.join(','), params: params })
  }).then((response) => response.data)
};

export default createAlertAPI;
