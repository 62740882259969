import React, { FunctionComponent, useCallback } from 'react';
import './VizMessageError.scss';
import I18n from 'common/i18n';

interface VizMessageErrorProps {
  type: string;
  errorMessage: string;
}

const VizMessageError: FunctionComponent<VizMessageErrorProps> = ({ type, errorMessage }) => {
  const typeClasses = {
    noDataError: {
      imageUrl: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/no-search-results-spot-hero.svg',
      headline: I18n.t('shared.no_rows_overlay.title')
    },
    privateOrDeletedAsset: {
      imageUrl: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/general-error-spot-hero.svg',
      headline: I18n.t('shared.visualizations.charts.common.validation.errors.oops_something_isnt_right')
    },
    exceededMaxRowCount: {
      imageUrl: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/too-big-error-spot-hero.svg',
      headline: I18n.t('shared.errors.exceeded_max_row_count.title')
    },
    histogram: {
      imageUrl: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/general-error-spot-hero.svg',
      headline: I18n.t('shared.visualizations.charts.common.validation.errors.oops_something_isnt_right')
    },
    'scatter-chart': {
      imageUrl: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/general-error-spot-hero.svg',
      headline: I18n.t('shared.visualizations.charts.common.validation.errors.oops_something_isnt_right')
    }
  };

  return (
    <div className="viz-error-container">
      <img className="viz-error-img" src={typeClasses[type].imageUrl} alt="" />
      <div className="viz-text-section">
        <h3 className="forge-typography--headline5">{typeClasses[type].headline}</h3>
        <p className="forge-typography--body2" dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
      </div>
    </div>
  );
};

export default VizMessageError;
