class HTTPError extends Error {
  status: number;
  name: string;
  message: string;

  constructor(status: number, ...params: any[]) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }

    this.name = 'HTTPError';
    this.status = status;
  }
}

export default HTTPError;
