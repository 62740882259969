import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { VisualizationRenderer } from 'common/visualizations';
import { getRawSoqlTableVif } from 'common/visualizations/helpers/VifHelpers';

class TableVisualization extends Component {
  componentDidMount() {
    this.visualization = this.createVisualizationRenderer();
  }

  componentDidUpdate() {
    if (!this.visualization) {
      this.visualization = this.createVisualizationRenderer();
    } else {
      this.visualization.update(this.getVif());
    }
  }

  componentWillUnmount() {
    if (this.visualization) {
      this.visualization.destroy();
    }
  }

  createVisualizationRenderer = () => {
    return new VisualizationRenderer(this.getVif(), this.element, this.props.options);
  };

  getVif = () => {
    const { datasetUid, domain, soqlQuery } = this.props;

    return getRawSoqlTableVif(domain, datasetUid, soqlQuery);
  };

  render() {
    return (
      <div ref={(el) => this.element = el} />
    );
  }
}

TableVisualization.propTypes = {
  datasetUid: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  soqlQuery: PropTypes.string.isRequired
};

export default TableVisualization;
