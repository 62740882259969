// Vendor Imports
import _ from 'lodash';

// Constants
export const COLOR_PALETTES_PATH = 'socrata.siteChrome.colors.colorPalettes';
const COLOR_PICKER_HEX_CODES_PATH = 'socrata.siteChrome.colors.colorPicker.hexCodes';

// Returns an array of hex-code strings. Populated if there are
// colors, an empty array if not.
export function getSiteAppearanceColorPaletteHexCodes(paletteIdOrName) {
  const palette = getSiteAppearanceColorPalette(paletteIdOrName);
  const hexCodes = _.get(palette, 'hexCodes', []);
  return _.reject(hexCodes, (hexCode) => _.isEmpty(hexCode));
}

export function getSiteAppearanceColorPalette(paletteIdOrName) {
  const palettes = getSiteAppearanceColorPalettes();
  return _.find(palettes, { id: paletteIdOrName }) || _.find(palettes, { name: paletteIdOrName });
}

export function getSiteAppearanceColorPalettes() {
  const palettes = _.get(window, COLOR_PALETTES_PATH, []);
  return _.reject(palettes, (palette) => _.isEmpty(palette.hexCodes));
}

export function getSiteAppearanceColorPickerHexCodes() {
  const hexCodes = _.get(window, COLOR_PICKER_HEX_CODES_PATH, []);
  return _.reject(hexCodes, (hexCode) => _.isEmpty(hexCode));
}

export function isSiteAppearanceColorPalette(paletteIdOrName) {
  return !_.isNil(getSiteAppearanceColorPalette(paletteIdOrName));
}

// In the past, palettes were solely identified by their human-provided name.
// This is problematic:
// 1- Names are not unique (esp. when using a theme federated from some other domain).
// 2- Renaming a theme shouldn't cause all visualizations using it to revert to a default theme.
//
// We now assign palettes an ID when they're edited. This however leaves some palettes without an
// ID. So we still need to support ID-less palettes.
//
// AX also now uses palette IDs, if available. It also automatically replaces palette name
// references with palette ID references, if possible. This method does that - given a palette
// name (or ID), it will return the palette ID, falling back to the palette name. This value
// is then substituted into the VIF.
export const findPaletteCanonicalReference = (colorPalette) => {
  if (!_.isString(colorPalette)) {
    return colorPalette;
  }

  // Note that palette and id may be undefined! We should fall back to
  // exactly what was in the VIF in this case, since we don't really
  // know why we can't find the palette.
  const palette = getSiteAppearanceColorPalette(colorPalette);
  const id = _.get(palette, 'id');

  if (_.isString(id)) {
    return id;
  } else {
    return colorPalette;
  }
};
