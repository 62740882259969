import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';

import '../index.scss';

/**
 AdvancedAlert => Alert created by giving the entire soql query (rather than constructing
 the soql query for the alert using form fields). Used for creating/editing a AdvancedAlert.
 @prop rawSoqlQuery - alert query string
 @prop onRawSoqlQueryChange - trigger when alert query change
*/
class AdvancedAlert extends Component {
  translationScope = 'shared.components.create_alert_modal.advanced_search';

  renderValidateButton() {
    const { onValidateClick } = this.props;

    return (
      <button className="validate-button btn btn-primary" onClick={onValidateClick}>
        {I18n.t('button.validate', { scope: 'shared.components.create_alert_modal' })}
      </button>
    );
  }

  render() {
    const { onRawSoqlQueryChange, rawSoqlQuery } = this.props;
    const alertTitle = I18n.t('alert_title', { scope: this.translationScope });
    const description = I18n.t('description', { scope: this.translationScope });

    return (
      <div className="advance-alert-section advance-alert">
        <div className="advance-alert-content">
          <div className="advance-alert-title">{alertTitle}</div>
          <div className="advance-alert-description">{description}</div>
        </div>

        <div>
          <label className="raw-query-title" htmlFor="alert-raw-query">
            {I18n.t('text_box_description', { scope: this.translationScope })}
          </label>
          <textarea id="alert-raw-query" value={rawSoqlQuery} onChange={onRawSoqlQueryChange} />
        </div>
        {this.renderValidateButton()}
      </div>
    );
  }
}

AdvancedAlert.defaultProps = {
  rawSoqlQuery: ''
};

AdvancedAlert.propTypes = {
  rawSoqlQuery: PropTypes.string,
  onRawSoqlQueryChange: PropTypes.func.isRequired
};

export default AdvancedAlert;
