import React, { Component, ReactElement } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import get from 'lodash/get';

import I18n from 'common/i18n';

interface FormReCaptchaProps {
  /**
   * Callback to call when recaptcha status changes
   * If response is an empty string, it means that the callback is going from valid -> invalid
   */
  callback: (response: string | null) => void;

  /**
   * Whether or not the recaptcha is valid
   * default: null
   */
  valid: boolean | null;
}

/**
 * Generic ReCAPTCHA component.
 *
 * Will automatically grab the ReCAPTCHA site key from the page,
 * and handles some a11y issues.
 */
class FormReCaptcha extends Component<FormReCaptchaProps> {
  static defaultProps = {
    valid: null
  };

  renderRecaptchaError = (valid: boolean | null): boolean | ReactElement => (
    // usually valid is null if the response is pending, or true if it's been passed
    // this is _always_ rendered but when the content changes, "aria-live" tells the screen reader
    // to re-read it... this makes screen readers tell the user that there's an error
    <div className="form-component-recaptcha-error-message" role="alert" aria-live="assertive">
      {valid === false && I18n.t('account.common.validation.recaptcha2')}
    </div>
  );

  render(): ReactElement | null {
    const { callback, valid } = this.props;
    const siteKey = get(window, 'socrata.recaptchaKey');

    if (!siteKey) {
      console.error('Error getting recaptcha site key');
      return null;
    }

    return (
      <div>
        {this.renderRecaptchaError(valid)}
        {/* This label is needed to pass WAVE */}
        <label htmlFor="g-recaptcha-response" hidden>
          Recaptcha Response
        </label>
        <ReCAPTCHA sitekey={siteKey} onChange={callback} onExpired={(): void => callback('')} />
      </div>
    );
  }
}

export default FormReCaptcha;
