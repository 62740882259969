import { keys } from 'lodash';
import { getStory } from './Stories';
import { Stories, BlockDict, Block } from 'types';
import { assertHasProperty, assertIsOneOfTypes } from 'common/assertions';

//=============================================================================
// Block getters
//
// Below are functions that are used to retrieve information about one or more
// blocks
//=============================================================================

export const getStoryBlockIds = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return story.blockIds;
};

export const getStoryBlockAtIndex = (
  storyUid: string,
  index: number,
  stories: Stories,
  blocks: BlockDict
) => {
  const story = getStory(storyUid, stories);
  const blockIds = story.blockIds;

  if (index < 0 || index >= blockIds.length) {
    throw new Error(
      `\`index\` argument is out of bounds; index: "${index}", blockIds.length: "${blockIds.length}".`
    );
  }

  return blocks[blockIds[index]];
};

export const getStoryBlockIdAtIndex = (storyUid: string, index: number, stories: Stories) => {
  const story = getStory(storyUid, stories);
  const blockIds = story.blockIds;

  if (index < 0 || index >= blockIds.length) {
    throw new Error(
      `\`index\` argument is out of bounds; index: "${index}", blockIds.length: "${blockIds.length}".`
    );
  }

  return blockIds[index];
};

export const getBlockLayout = (blockId: string, blocks: BlockDict) => {
  const block = getBlock(blockId, blocks);

  return block.layout;
};

export const getBlock = (blockId: string, blocks: BlockDict) => {
  assertIsOneOfTypes(blockId, 'string');
  assertHasProperty(
    blocks,
    blockId,
    `Block with id ${blockId} does not exist. Existing block IDs: ${keys(blocks)}`
  );

  return blocks[blockId];
};

export const getBlockComponents = (blockId: string, blocks: BlockDict) => {
  const block = getBlock(blockId, blocks);
  return block.components;
};

export const getBlockBackgroundColor = (blockId: string, blocks: BlockDict) => {
  const block = getBlock(blockId, blocks);
  return block.background_color;
};

export const getBlockComponentAtIndex = (blockId: string, index: number, blocks: BlockDict) => {
  const components = getBlockComponents(blockId, blocks);

  if (index < 0 || index >= components.length) {
    throw new Error(
      `\`index\` argument is out of bounds; index: "${index}", components.length: "${components.length}".`
    );
  }

  return components[index];
};

//=============================================================================
// Permission helpers
//
// Below are functions that are used to retrieve permission information about
// one or more blocks
//=============================================================================

export const isBlockPresentable = (blockId: string, blocks: BlockDict) => {
  const block = getBlock(blockId, blocks);
  return block.presentable;
};

// Returns true if the given block is restricted to measure card embeds only.
export const isBlockRestrictedToMeasureCardsOnly = (blockId: string, blocks: BlockDict) => {
  return getBlockLayout(blockId, blocks) === 'fluid6';
};

//=============================================================================
// Transformer helpers
//
// Below are functions that are used to alter the block object
//=============================================================================

export const serializeBlockFromState = (blockId: string, blocks: BlockDict) => {
  // NOTE! This _must not_ return any reference
  // to internal data structures! Everything
  // must be a fresh instance. Otherwise, the
  // returned object is a backdoor allowing
  // untracked state changes to this store's state.

  const block = getBlock(blockId, blocks);
  return serializeBlock(block);
};

export const serializeBlock = (block: Block) => ({
  layout: block.layout,
  components: block.components,
  presentable: block.presentable,
  background_color: block.background_color
});
