import React, { Component } from 'react';
import I18n from 'common/i18n';
import { ForgeButton } from '@tylertech/forge-react';

export default class StoryEditButton extends Component {
  render() {
    const scope = 'shared.components.asset_action_bar.publication_action';

    return (
      <ForgeButton type="outlined" onClick={() => (location.href = window.STORY_EDIT_URL || '/')}>
        <button type="button" className="edit-button">
          {I18n.t('published.primary_action_text', { scope })}
        </button>
      </ForgeButton>
    );
  }
}
