import _ from 'lodash';
import React, { Component } from 'react';
import url from 'url';

import I18n from 'common/i18n';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import airbrake from 'common/airbrake';
import {
  editDatasetAsRevision,
  findWorkingCopyFor,
  shouldUseExploreInsteadOfGrid
} from 'common/views/helpers';
import whatwgFetch from 'common/fetchUtil.js';
import { defaultHeaders, fetchJson } from 'common/http';
import {
  isDataset,
  isDefaultView,
  isDerivedView,
  isHref,
  isBlob,
  isMeasure,
  isStory,
  isVisualizationCanvas,
  isQueryJSONBased,
  isBrokenQueryJSONBased,
  isTabular
} from 'common/views/view_types';
import { containsLegacyDataTypes } from 'common/views/contains_legacy_data_types';
import { assign as windowLocationAssign } from 'common/window_location';
import { requireApprovalRequestWithdrawal } from 'common/components/AssetActionBar/components/publication_action/utils';
import { CopyStatus, View } from 'common/types/view';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { ForgeButton } from '@tylertech/forge-react';
import { localizeLink } from 'common/locale';

// export for tests
export const fetchOptions: RequestInit = {
  credentials: 'same-origin',
  headers: defaultHeaders
};

// I don't really know the wider usage for this, but in this case, we are merely
// determining the current status of the create-a-working-copy operation.
function getOperationStatuses(uid: string): Promise<CopyStatus> {
  const operationStatusesUrl = `/api/views/${uid}.json?method=operationStatuses`;

  return fetchJson(operationStatusesUrl, fetchOptions).then((statuses: any) =>
    _.get(statuses, 'copying.copyStatus')
  );
}

// Variously copied from util/dataset/dataset.js, base-model.js, and util/util.js
// See: $.path
// See: ds.redirectTo
// See: ds.url = _generateUrl
// See: _generateBaseUrl
function urlForWorkingCopy(workingCopy: View) {
  let base = '';
  let fullUrl = '';

  // This is probably unnecessary since it's not likely we'll cross into federation
  // territory. But then again, maybe we will.
  // TODO EN-39798: this should probably check sourceDomainCName
  // TODO: should we verify that this is not internal to public federated? In theory you could never get here in that case.
  if (!_.isEmpty(workingCopy.domainCName)) {
    const loc = document.location;
    const domain = loc.hostname;

    base = `${loc.protocol}//${domain}`;
  }

  const shouldPrefixLocale = new RegExp(`^\/(${I18n.locale})`).test(
    url.parse(window.location.href, true).pathname!
  );
  const rootPath = shouldPrefixLocale ? `/${I18n.locale}` : '';
  const currentlyOnExplore = !!(window.initialState || {}).onExploreCanvas;

  if (workingCopy.displayType === 'story') {
    fullUrl = base + rootPath + `/stories/s/${workingCopy.id}`;
  } else if (isVisualizationCanvas(workingCopy)) {
    fullUrl = base + rootPath + `/d/${workingCopy.id}/edit`;
  } else if (isMeasure(workingCopy)) {
    fullUrl = base + rootPath + `/d/${workingCopy.id}`;
  } else if (
    isDerivedView(workingCopy) &&
    (currentlyOnExplore || shouldUseExploreInsteadOfGrid(workingCopy))
  ) {
    fullUrl = base + rootPath + `/d/${workingCopy.id}/explore`;
  } else {
    fullUrl = base + rootPath + `/d/${workingCopy.id}/data`;
  }

  return fullUrl;
}

// assumes you've already checked for working copies
export function shouldEditViaRevision(currentView: View) {
  // when editing default datasets, we don't support blob, document, photo columns
  if (isDefaultView(currentView) && containsLegacyDataTypes(currentView)) {
    return false;
  }

  if (isDefaultView(currentView) && (isBlob(currentView) || isHref(currentView) || isTabular(currentView))) {
    // our main revision case: editing default datasets, blobs, hrefs
    return true;
  }

  if (!isQueryJSONBased(currentView) && isTabular(currentView)) {
    return true;
  }

  return false;
}

export interface EditButtonProps {
  currentView: View;
  approvalsGuidance: GuidanceSummaryV2;
}

interface EditButtonState {
  inProgress: boolean;
}

// Let's talk about various edit functionality
// Case 1: its not an asset type that ever has working copies
// - Clicking Edit navigates you to a url that represents your draft/edit-mode
// Case 2: its possible for this asset type to have working copies
// - Clicking Edit kicks off a check for any existing working copies, or any in-progress working copies
// 2a: there is a working copy present -> you're redirected to it
// 2b: there is a working copy in progress -> we poll until it completes, then you're redirected to it
// 2c: there is no working copy present ->
// - we check if this asset type should be edited via revision (DSMP) or via working copy
// - create the appropriate asset, and when it's ready, redirect to it
class EditButton extends Component<EditButtonProps, EditButtonState> {
  state = {
    inProgress: false
  };

  // checks for (and redirects to) an existing working copy
  // if none, runs logic to decide if it should create revision (DSMP) or working copy
  // creates the appropriate one and redirects to it
  getDatasetDraft = (view: View) => {
    const noCopyCallback = () => {
      if (shouldEditViaRevision(view)) {
        this.revisionEdit(view);
      } else {
        this.createWorkingCopy(view.id);
      }
    };
    this.searchForWorkingCopy(view.id, noCopyCallback);
  };

  searchForWorkingCopy(currentViewUid: string, noCopyCallback: () => void) {
    findWorkingCopyFor(currentViewUid).then((workingCopy: View) => {
      if (workingCopy) {
        this.redirectTo(urlForWorkingCopy(workingCopy));
      } else {
        this.checkIfCopyPending(currentViewUid, noCopyCallback, false);
      }
    });
  }

  pollForCopyCompletion(currentViewUid: string, nonePendingCallback: () => void) {
    // used to avoid testing timeouts
    const COPY_POLLING_INTERVAL = window.COPY_POLLING_INTERVAL || 30000;

    setTimeout(() => {
      this.checkIfCopyPending(currentViewUid, nonePendingCallback, true);
    }, COPY_POLLING_INTERVAL);
  }

  checkIfCopyPending(currentViewUid: string, nonePendingCallback: () => void, waitingForProcessing: boolean) {
    getOperationStatuses(currentViewUid).then((copyStatus) => {
      switch (copyStatus) {
        case CopyStatus.Queued:
        case CopyStatus.Processing:
          this.pollForCopyCompletion(currentViewUid, nonePendingCallback);
          break;
        case CopyStatus.Finished:
          if (waitingForProcessing) {
            // if its "finished" while we're inprogress, we requested it
            // request again to get the existing working copy
            // if something's wacky with state, requesting again
            // should ensure we eventually get the copy
            this.createWorkingCopy(currentViewUid);
          } else {
            nonePendingCallback();
          }
          break;
        case CopyStatus.Failed:
          if (waitingForProcessing) {
            showToastNow({
              type: ToastType.ERROR,
              content: I18n.t('shared.site_chrome.asset_action_bar.edit_error')
            });
          }
          nonePendingCallback();
          break;
        default:
          nonePendingCallback();
          break;
      }
    });
  }

  async revisionEdit(view: View) {
    try {
      await editDatasetAsRevision(view.id, isDataset(view));
    } catch (err) {
      airbrake.notify({
        error: err,
        context: { component: 'EditButton#handleClick' }
      });
      this.setState({ inProgress: false });
      showToastNow({
        type: ToastType.ERROR,
        content: I18n.t('shared.site_chrome.asset_action_bar.edit_error')
      });
    }
  }

  // Let's talk about what happens when you make a copy request
  // Case 1: Working copy already exists.
  // - core returns a 200 and the existing copy (views.json format)
  // Case 2: No copy exists & the dataset is small
  // - core returns 200 and a views.json object that tells us where the working copy is located
  // Case 3: No copy exists & the dataset is large.
  // - core returns 202 and the status of the copy's progress (and a ticket to track it, which we don't use here)
  // - in this case, we poll the operationStatuses endpoint until the copy is completed
  createWorkingCopy = (currentViewUid: string) => {
    const copyUrl = `/api/views/${currentViewUid}/publication.json?method=copy`;

    whatwgFetch(copyUrl, { ...fetchOptions, method: 'POST' }).then((response: Response) => {
      switch (response.status) {
        case 200:
          return response.json().then((workingCopy: View) => {
            this.redirectTo(urlForWorkingCopy(workingCopy));
          });
        case 202:
          const retryCopyCreation = () => this.createWorkingCopy(currentViewUid);
          this.checkIfCopyPending(currentViewUid, retryCopyCreation, true);
          break;
        default:
          response.json().then((json) => {
            console.error(response.statusText, ':', json.message);
            showToastNow({
              type: ToastType.ERROR,
              content: I18n.t('shared.site_chrome.asset_action_bar.edit_error')
            });
            this.setState({ inProgress: false });
          });
          break;
      }
    });
  };

  /* eslint @typescript-eslint/no-shadow: "warn" */
  redirectTo(url: string) {
    windowLocationAssign(localizeLink(url));
  }

  async editAction(currentView: View) {
    const { STORY_EDIT_URL } = window;
    const currentViewUid = currentView.id;
    const currentViewType = currentView.viewType;

    const proceed = await requireApprovalRequestWithdrawal(this.props.approvalsGuidance);
    if (!proceed) {
      this.setState({ inProgress: false });
      return;
    }

    if (isBlob(currentView) || isHref(currentView)) {
      if (shouldEditViaRevision(currentView)) {
        this.revisionEdit(currentView);
      } else {
        this.redirectTo(`/d/${currentViewUid}/edit_metadata`);
      }
    } else if (currentViewType !== 'tabular' && !isMeasure(currentView)) {
      const editUrl = isStory(currentView) && STORY_EDIT_URL ? STORY_EDIT_URL : `/d/${currentViewUid}/edit`;
      this.redirectTo(editUrl);
    } else {
      this.getDatasetDraft(currentView);
    }
  }

  render() {
    const { inProgress } = this.state;
    const { currentView } = this.props;

    if (currentView?.locked) {
      return null;
    }

    // user can't open a working copy on a broken json-query view
    // nor should the open it in a revision, so disable the edit button
    const isDisabled = inProgress || isBrokenQueryJSONBased(currentView);
    const i18nScope = 'shared.components.asset_action_bar.publication_action';
    return (
      <ForgeButton
        type="outlined"
        onClick={() => {
          if (!isDisabled) {
            this.setState({ inProgress: true });
            this.editAction(currentView);
          }
        }}
      >
        <button type="button" className="edit-button" disabled={isDisabled}>
          {inProgress && <span className="spinner-default spinner-btn-primary" />}
          {I18n.t('published.primary_action_text', { scope: i18nScope })}
        </button>
      </ForgeButton>
    );
  }
}

export default EditButton;
