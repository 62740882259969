/* Imports */
/* ============================================================================= */
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { GuidanceSummaryV2Helper } from 'common/core/approvals/Types';
import {
  decorateGuidanceSummaryHelper,
  getBaseGuidanceSummaryV2Helper
} from '.';





/* Method */
/* ============================================================================= */
/**
 * Get a guidance helper object for a given guidance summary
 * @param guidance GuidanceSummaryV2 object
 * @returns GuidanceSummaryV2Helper object
 */
export const getGuidanceSummaryHelper = (guidance: GuidanceSummaryV2): GuidanceSummaryV2Helper => {
  /* Add attributes/methods specific to a GuidanceSummaryV2Helper */
  /* ----------------------------------------------- */
  const guidanceHelper = getBaseGuidanceSummaryV2Helper(guidance);


  /* Add attributes/methods to mimic a WorkflowGuidanceHelper */
  /* ----------------------------------------------- */
  return decorateGuidanceSummaryHelper(guidance, guidanceHelper);
};

export default getGuidanceSummaryHelper;
