/* Imports */
/* ============================================================================= */
import { SubmissionObject } from 'common/core/approvals_enums';
import { WorkflowGuidance } from 'common/types/approvals';





/* Method */
/* ============================================================================= */
/**
 * Returns a list of other sites that this asset will end up federating to if its visibility is changed.
 *
 * Currently, only public assets are federated so this only handles making something public
 * (either during publish or an audience change after making it public)
 *
 * This is mainly used by the AccessManager to show a message when making an asset public.
 *
 * @param guidance WorkflowGuidance object
 * @returns Array of domain strings this asset will federate to its visibility is changed
 */
export const sitesThatWillBeFederatedToIfMadePublicV2 = (guidance: WorkflowGuidance) => {
  // this will return the "toChangeAudience" or "toPublishAndChangeAudience" object
  // which includes a submission which has an "object"
  const submissionAction = (
    guidance?.toChangeAudience ||
    guidance?.toPublishAndChangeAudience
  );

  // We only care if submission.object equals "public_audience_request"
  return (submissionAction?.submission?.object === SubmissionObject.PUBLIC_AUDIENCE_REQUEST)
    ? submissionAction?.expectedFederatedTo || []
    : [] ;
};

export default sitesThatWillBeFederatedToIfMadePublicV2;
