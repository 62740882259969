import { BinaryTree, CompoundOp, Leaf, isCompound, leaf } from 'common/types/soql';
import { parens } from './util';

export { leaf as buildLeaf };

export const castToBinaryTree = <T>(
  arg: BinaryTree<T> | T,
  predicate: (param: unknown) => param is T
): BinaryTree<T> => {
  if (isBinaryTree<T>(arg, predicate)) {
    return arg;
  } else {
    return leaf<T>(arg);
  }
};

export const mapOverBinaryTree = <P, Q>(tree: BinaryTree<P>, lambda: (arg: P) => Q): BinaryTree<Q> => {
  if (isCompound<P>(tree)) {
    return {
      type: 'compound',
      op: tree.op,
      left: mapOverBinaryTree<P, Q>(tree.left, lambda),
      right: mapOverBinaryTree<P, Q>(tree.right, lambda)
    };
  } else {
    return { value: lambda(tree.value), type: 'leaf' };
  }
};

export const isBinaryTree = <T>(
  param: T | BinaryTree<T>,
  predicate: (arg: T | unknown) => arg is T
): param is BinaryTree<T> => !predicate(param);

// TODO: Probably put this in a better place?
const renderCompoundOperator = (op: CompoundOp): string => op === CompoundOp.QueryPipe ? '|>' : op;

export const renderBinaryTree = <T>(
  param: BinaryTree<T> | T,
  predicate: (arg: T | unknown) => arg is T,
  renderer: (arg: T) => string
): string => {
  if (isBinaryTree<T>(param, predicate)) {
    if (isCompound<T>(param)) {
      const renderTree = (tree: BinaryTree<T>): string => {
        const rendered = renderBinaryTree<T>(tree, predicate, renderer);
        if (isCompound<T>(tree)) {
          return parens(rendered);
        } else {
          return rendered;
        }
      };
      return [
        param.left,
        param.right
      ].map(renderTree).join(` ${renderCompoundOperator(param.op)} `);
    } else {
      return renderer(param.value);
    }
  } else {
    return renderer(param);
  }
};
