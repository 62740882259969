/* Imports */
/* ============================================================================= */
import { isNil } from 'lodash';

import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { GuidanceCheckSummary } from 'common/core/approvals/Types';
import { getWorkflowValues } from '.';





/* Helpers */
/* ============================================================================= */
const isWorkflowValueDefinedAndNotMatching = (workflowValue: any, value: any) => {
  return (!isNil(workflowValue) && workflowValue !== value);
};





/* Method */
/* ============================================================================= */
/**
 * Check that a GuidanceCheckSummary object has at least 1 workflow attrubute value
 *  which is set and does NOT match the `value` parameter.
 * If a specific WorkflowTargetAudience value was passed,
 *  check that the GuidanceCheckSummary has a defined, but non-matching value for
 *  that specific workflow attribute.
 * @param workflows GuidanceCheckSummary object
 * @param value The value to match WorkflowTargetAudience attributes against
 * @param workflow (optional) A specific WorkflowTargetAudience value to check
 *  for a non-matching value in the workflows object
 * @returns boolean
 */
export const checkForWorkflowValueOtherThan = (workflows: GuidanceCheckSummary<any>, value: any, workflow?: WorkflowTargetAudience): boolean => {
  return (
    // Check `value` against the specific workflow value or that a non-matching value exists in the workflow values
    workflow
      ? isWorkflowValueDefinedAndNotMatching(workflows[workflow], value)
      : getWorkflowValues(workflows).some(
        (v) => isWorkflowValueDefinedAndNotMatching(v, value)
      )
  );
};

export default checkForWorkflowValueOtherThan;
