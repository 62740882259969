import { Vif, V1TableVif } from 'common/visualizations/vif';
import { ReportDataSource } from 'common/types/reportFilters';
import type { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import type { Layout } from 'react-grid-layout';
import type RichTextEditor from 'editor/RichTextEditor';
import Actions from './Actions';
import { Measure } from 'common/performance_measures/types';
import { ClientContextVariable } from 'common/types/clientContextVariable';

/**
 * Types used throughout storyteller, especially types that correspond to the database.
 */

/** The title and description to be used when the story is embedded as a story tile */
export interface TileConfig {
  title: string;
  description: string;
}

export interface StoryCommon {
  /** 4x4 of the story's view */
  uid: string;
  /** Story title shown in UI */
  title: string;
  description: string;
  isFlexibleStory?: boolean;
  theme?: string;
  layout?: string;
  templateDigest?: string;
  templateChangedButHasNotSaved?: boolean;
}

export interface Stories {
  [key: string]: Story;
}

/** An "incoming" story, just deserialized from JSON */
export interface StoryData extends StoryCommon {
  tileConfig: TileConfig;
  blocks: Block[];
  permissions: {
    isPublic: boolean;
    isInternal: boolean;
  };
  dataSource?: ReportDataSource | null;

  // these are marked optional because validateStoryData doesn't check for them
  digest?: string;
  /** user 4x4 */
  createdBy?: string;
  updatedAt?: string;
}

/** An in-memory story, used for most edits */
export interface Story extends StoryCommon {
  tileConfig: TileConfig;
  blockIds: string[];
  dataSource?: ReportDataSource | null;
  digest?: string;
  publishedStory?: { digest: string };
  permissions: {
    isPublic?: boolean;
    isInternal?: boolean;
  };
  updatedAt: string;
  createdBy: string;
}

/** A serialized story, suitable for JSON, the undo history, etc.
    Notably, it includes the blocks rather than referencing them by ID */
export interface StorySerialized extends StoryCommon {
  blocks: Block[];
  layout?: string;
  dataSource?: ReportDataSource | null;
}

/**
 * A Block is a row of BlockComponents. It is also the unit stored in the database.
 */
export interface Block {
  components: BlockComponent[];
  layout: string;
  presentable: boolean;
  background_color?: string;

  created_by?: string; // user 4x4
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

/**
 * Mapping of constant client-side ID
 * to block JSON. Client-side IDs
 * only change when the page reloads.
 */
export interface BlockDict {
  [id: string]: Block;
}

export interface BlockData {
  components: BlockComponent[];
  layout: string;
  presentable: boolean;
  background_color?: string;

  created_by?: string; // user 4x4
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

/**
 * A BlockComponent is a single embed or text block.
 */
export interface BlockComponent {
  type: ComponentType;
  /** This always exists for Flexible Layouts, but not for Classic Layouts */
  layout: Layout; // this inconsistency is driving me crazy, TODO EN-65582
  id?: string;
  value?: any;
}

export interface BlockComponentPayload {
  type: ComponentType;
  componentIndex: number;
  blockId: string;
  value: any;
  layout: Layout;
}

export type RTEContentChangeEvent = {
  originalEvent: {
    detail: {
      content: BlockComponent;
      editor: RichTextEditor;
      layoutHeight: number;
    };
  };
} & JQuery.TriggeredEvent;

export enum ComponentType {
  AG_TABLE = 'socrata.visualization.agTable',
  ASSET_SELECTOR = 'assetSelector',
  AUTHOR = 'author',
  BAR_CHART = 'socrata.visualization.barChart',
  CALENDAR = 'socrata.visualization.calendar',
  CHOROPLETH_MAP = 'socrata.visualization.choroplethMap',
  CLASSIC_VISUALIZATION = 'socrata.visualization.classic',
  COLUMN_CHART = 'socrata.visualization.columnChart',
  COMBO_CHART = 'socrata.visualization.comboChart',
  EMBEDDED_HTML = 'embeddedHtml',
  FEATURE_MAP = 'socrata.visualization.featureMap',
  GLOBAL_FILTER = 'globalFilter',
  GOAL_TILE = 'goal.tile',
  HERO = 'hero',
  HISTOGRAM = 'socrata.visualization.histogram',
  HORIZONTAL_RULE = 'horizontalRule',
  HTML = 'html',
  HTML_TABLE_OF_CONTENTS = 'html.tableOfContents',
  IMAGE = 'image',
  MAP = 'socrata.visualization.map',
  MEASURE_CARD = 'measure.card',
  MEASURE_CHART = 'measure.chart',
  PIE_CHART = 'socrata.visualization.pieChart',
  REGION_MAP = 'socrata.visualization.regionMap',
  SCATTER_CHART = 'socrata.visualization.scatterChart',
  SPACER = 'spacer',
  STORY_TILE = 'story.tile',
  STORY_WIDGET = 'story.widget',
  TABLE = 'socrata.visualization.table',
  TABLE_OF_CONTENTS = 'html.tableOfContents',
  TIMELINE_CHART = 'socrata.visualization.timelineChart',
  VIZ_CANVAS = 'socrata.visualization.vizCanvas',
  YOUTUBE = 'youtube.video'
}

export type LayoutMap = {
  [key in ComponentType]: Layout;
};

export interface AssetSelectorBlockComponent extends BlockComponent {
  type: ComponentType.ASSET_SELECTOR;
}

/**
 * Corresponds to the table visualization embed.
 */
export interface TableBlockComponent extends BlockComponent {
  type: ComponentType.TABLE | ComponentType.AG_TABLE;
  value: {
    /**
     * All table embeds are created as V1 Vifs, but they can be migrated
     * to V3 Vifs when you use the table's filter bar.
     */
    vif: Vif | V1TableVif;
  };
}

/**
 * Corresponds to the global filter bar component.
 */
export interface GlobalFilterDataSource {
  datasetUid: string;
}

export interface GlobalFilterBlockComponent extends BlockComponent {
  type: ComponentType.GLOBAL_FILTER;
  value: {
    dataset?: GlobalFilterDataSource | GlobalFilterDataSource[];
    filters?: SoqlFilter[];
  };
}

export interface VisualizationCanvasBlockComponent extends BlockComponent {
  type: ComponentType.VIZ_CANVAS;
  value: {
    dataset?: {
      datasetUid: string;
      vifId: string;
    };
    filters?: SoqlFilter[];
  };
}

export interface InSituVisualizationBlockComponent extends BlockComponent {
  type:
    | ComponentType.BAR_CHART
    | ComponentType.CALENDAR
    | ComponentType.COLUMN_CHART
    | ComponentType.COMBO_CHART
    | ComponentType.HISTOGRAM
    | ComponentType.PIE_CHART
    | ComponentType.SCATTER_CHART
    | ComponentType.TIMELINE_CHART;
  value: {
    dataset?: {
      datasetUid: string;
    };
    filters?: SoqlFilter[];
    type: string;
    vif: Vif;
  };
}

export interface MapBlockComponent extends BlockComponent {
  type: ComponentType.MAP;
  value: {
    dataset?: {
      datasetUid: string;
    };
    filters?: SoqlFilter[];
    type: string;
    vif: Vif;
  };
}

export interface FeatureMapBlockComponent extends BlockComponent {
  type: ComponentType.FEATURE_MAP;
  value: {
    dataset?: {
      datasetUid: string;
    };
    filters?: SoqlFilter[];
    type: string;
    vif: Vif;
  };
}

export interface RegionMapBlockComponent extends BlockComponent {
  type: ComponentType.REGION_MAP | ComponentType.CHOROPLETH_MAP;
  value: {
    dataset?: {
      datasetUid: string;
    };
    filters?: SoqlFilter[];
    type: string;
    vif: Vif;
  };
}

export interface HTMLBlockComponent extends BlockComponent {
  type: ComponentType.HTML | ComponentType.TABLE_OF_CONTENTS;
  value: any;
}

export interface ImageBlockComponent extends BlockComponent {
  type: ComponentType.IMAGE;
  value: any;
}

export interface HeroBlockComponent extends BlockComponent {
  type: ComponentType.HERO;
  value: {
    crop: any;
    url: string;
    html: string;
  };
}

export interface StoryTileBlockComponent extends BlockComponent {
  type: ComponentType.STORY_TILE | ComponentType.STORY_WIDGET;
  value: any;
}

/**
 * Story embed only props for measures.
 *
 * TODO: These names should change to match what Measures expects when we convert AssetSelectorStore.
 * Should be isCustomLinkEnabled and customLink
 */
interface BaseStorytellerMeasure {
  use_custom_link?: boolean;
  custom_link?: {
    href: string;
    text: string;
  };
}

interface BaseMeasureBlockComponent extends BlockComponent {
  type: ComponentType.MEASURE_CARD | ComponentType.MEASURE_CHART;
  value: {
    measure: BaseStorytellerMeasure;
  };
  isInsitu: boolean;
}

export interface PlatformMeasureBlockComponent extends BaseMeasureBlockComponent {
  value: {
    measure: {
      /** The 4x4 of the measure */
      uid: string;
    } & BaseStorytellerMeasure;
  };
  isInsitu: false;
}

export interface InSituMeasureBlockComponent extends BaseMeasureBlockComponent {
  value: {
    measure: Measure & BaseStorytellerMeasure;
  };
  isInsitu: true;
}

export type AnyMeasureBlockComponent = InSituMeasureBlockComponent | PlatformMeasureBlockComponent;

export interface EmbeddedHTMLBlockComponent extends BlockComponent {
  type: ComponentType.EMBEDDED_HTML;
  value: any;
}

export interface ClassicVisualizationBlockComponent extends BlockComponent {
  type: ComponentType.CLASSIC_VISUALIZATION;
  value: any;
}

export interface GlobalFilters {
  [datasetUid: string]: SoqlFilter[];
}

export interface GlobalFilterColumns {
  /** An array of all column field names in the specified datasetUid used by any filter */
  [datasetUid: string]: string[];
}

export interface GlobalParameterOverrides {
  [datasetUid: string]: ClientContextVariable[];
}

export interface GlobalFiltersUpdatedDetails {
  filters: GlobalFilters;
}

export interface RemoveComponentDetails {
  blockId: string | null;
  componentIndex: number | null;
}

export type GlobalFiltersUpdatedEvent = CustomEvent<GlobalFiltersUpdatedDetails>;
export type RemoveComponentEvent = CustomEvent<RemoveComponentDetails>;
export interface FluxPayload {
  action?: Actions;
  [propName: string]: any;
}

export interface RectObject {
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  bottom: number | null;
  top: number | null;
  left: number | null;
  right: number | null;
}

export type StoryViewRights = string[];
