// intention for this file is to hold the types representing the search results returned when querying the catalog API for datasets/views

// the catalog populates its datatypes from metadb.data_types 'names', whereas most other places use 'db_name'
// see common/types/soql.ts#catalogDataTypeToSoQLType for a mostly implemented converter.
export enum CatalogDataType {
  Text = 'Text',
  Number = 'Number',
  CalendarDate = 'Calendar date',
  Date = 'Date',
  Money = 'Money',
  Phone = 'Phone',
  Checkbox = 'Checkbox',
  Flag = 'Flag',
  Stars = 'Stars',
  Percent = 'Percent',
  URL = 'URL',
  Email = 'Email',
  BlistInBlist  = 'Blist in Blist',
  DropDownList  = 'Drop Down List',
  Photo = 'Photo',
  Document = 'Document',
  HTML = 'HTML',
  Location = 'Location',
  DatasetLink = 'Dataset Link',
  Geospatial = 'Geospatial',
  Object = 'Object',
  List = 'List',
  Point = 'Point',
  MultiLine = 'MultiLine',
  MultiPolygon = 'MultiPolygon',
  Polygon = 'Polygon',
  Line = 'Line',
  MultiPoint = 'MultiPoint',
  Blob = 'Blob',
  json = 'json'
}
