import { noop } from 'lodash';
import React, { FunctionComponent, useCallback, PropsWithChildren } from 'react';
import classnames from 'classnames';

import './index.scss';

/**
 * Special value to indicate the third state.
 */
export const INDETERMINATE = 'indeterminate';

/**
 * The three values the checkbox can have.
 */
export type ThreeStateValue = 'indeterminate' | boolean;

type Props = PropsWithChildren<{
  checked: ThreeStateValue;
  className?: string;
  id: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ariaLabel?: string;
  ariaLabelledBy?: string;
}>;

const ThreeStateCheckbox: FunctionComponent<Props> = ({
  checked,
  children,
  className,
  id,
  onChange = noop,
  ariaLabel,
  ariaLabelledBy
}) => {
  const indeterminate = checked === INDETERMINATE;

  const checkboxClass = (() => {
    if (indeterminate) {
      return 'icon-minus';
    } else {
      return checked ? 'icon-checkmark3' : '';
    }
  })();

  // React doesn't support setting the "indeterminate" property on input elements (it's not a
  // regular DOM attribute and cannot be set in markup), so we have to do it via ref.
  const inputCallbackRef = useCallback((node) => {
    if (node) {
      node.indeterminate = indeterminate;
    }
  }, [checked]);

  const classes = classnames('three-state-checkbox', className);

  // Re: sometimes using onChange and onClick. IE and Edge don't give us an onChange
  // when `indeterminate` is set on the checkbox, because *technically* the input's
  // value hasn't changed. All other browsers take a more pragmatic approach and just
  // give you an onChange. So work around this by using onClick when `indeterminate`
  // is set. Technically, we should be able to always use onClick, but onChange seems
  // to be more robust so we should use it as a best practice when possible.
  //
  // We always pass a function into onChange in order to silence a React warning.

  return (
    <div className={classes} >
      <input
        ref={inputCallbackRef}
        checked={checked !== false}
        id={id}
        type={'checkbox'}
        onChange={indeterminate ? noop : onChange /* see comment above */}
        onClick={indeterminate ? onChange : undefined /* see comment above */}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-checked={indeterminate ? 'mixed' : checked === true} />
      <label className="inline-label" htmlFor={id}>
        <div className="fake-checkbox">
          <span className={checkboxClass}></span>
        </div>
        {children}
      </label>
    </div>
  );
};

export default ThreeStateCheckbox;
