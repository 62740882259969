import React, { FunctionComponent, useState } from 'react';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import MobileSearchDrawer from '../mobile_search_drawer';
import './index.scss';

interface MobileSearchButtonProps {}

const MobileSearchButton: FunctionComponent<MobileSearchButtonProps> = () => {
  const [showSearchDrawer, setShowSearchDrawer] = useState(false);

  return (
    <>
      <ForgeIconButton slot="action">
        <button
          type="button"
          aria-label="View user profile"
          className="tyler-icons"
          onClick={() => setShowSearchDrawer(true)}
        >
          <ForgeIcon name="search" />
        </button>
      </ForgeIconButton>
      <MobileSearchDrawer open={showSearchDrawer} closeDialogCallback={() => setShowSearchDrawer(false)} />
    </>
  );
};

export default MobileSearchButton;
