import _ from 'lodash';
import SoqlDataProvider from './SoqlDataProvider';
import SoqlHelpers from './SoqlHelpers';
import I18n from 'common/i18n';

function makeSocrataCategoricalDataRequest(vif, seriesIndex, maxRowCount) {
  const series = vif.series[seriesIndex];
  const requireGroupingInSelect = vif.requireGroupingInSelect;
  const queryClauses = [];
  const isAggregated =
    !_.isNil(_.get(series, 'dataSource.dimension.aggregationFunction')) ||
    !_.isNil(_.get(series, 'dataSource.measure.aggregationFunction'));

  const fields = [
    `${SoqlHelpers.dimension(vif, seriesIndex)} AS ${SoqlHelpers.dimensionAlias()}`,
    `${SoqlHelpers.measure(vif, seriesIndex)} AS ${SoqlHelpers.measureAlias()}`
  ];

  if (requireGroupingInSelect)
    // We still add the grouping column even if there's no aggregation.
    // This will become a plain select query, and later on we'll see if the dimension is actually unique.
    fields.push(`${SoqlHelpers.grouping(vif, seriesIndex)} AS ${SoqlHelpers.groupingAlias()}`);

  const errorBarsLower = SoqlHelpers.errorBarsLower(vif, seriesIndex);
  const errorBarsUpper = SoqlHelpers.errorBarsUpper(vif, seriesIndex);
  const haveErrorBars = !_.isEmpty(errorBarsLower) && !_.isEmpty(errorBarsUpper);
  if (haveErrorBars) {
    fields.push(
      `${errorBarsLower} AS ${SoqlHelpers.errorBarsLowerAlias()}`,
      `${errorBarsUpper} AS ${SoqlHelpers.errorBarsUpperAlias()}`
    );
  }

  queryClauses.push('SELECT', fields.join(', '));

  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(vif, seriesIndex);
  if (whereClauseComponents.length > 0) queryClauses.push(`WHERE ${whereClauseComponents}`);

  // No group by if there's no aggregation.
  if (isAggregated) {
    let groupByString = `GROUP BY ${SoqlHelpers.aggregationClause(vif, seriesIndex, 'dimension')}`;
    if (requireGroupingInSelect) groupByString += `, ${SoqlHelpers.groupingAlias()}`;
    queryClauses.push(groupByString);
  }

  queryClauses.push(`ORDER BY ${SoqlHelpers.orderByClauseFromSeries(vif, seriesIndex)}`);
  queryClauses.push('NULL LAST');

  // For showOtherCategory, we don't LIMIT in the query. We'll do that in the response handler.
  const limitFromVif = parseInt(_.get(vif, `series[${seriesIndex}].dataSource.limit`), 10);
  const showOtherCategory = _.isNumber(limitFromVif) && _.get(vif, 'configuration.showOtherCategory', false);
  const limit = !showOtherCategory && limitFromVif ? parseInt(limitFromVif, 10) : maxRowCount;
  queryClauses.push(`LIMIT ${limit}`);

  const queryString = queryClauses.join(' ');

  const soqlDataProvider = new SoqlDataProvider(
    {
      datasetUid: series.dataSource.datasetUid,
      domain: series.dataSource.domain,
      clientContextVariables: series.dataSource.parameterOverrides
    },
    true
  );

  return soqlDataProvider
    .query(
      queryString,
      SoqlHelpers.dimensionAlias(),
      SoqlHelpers.measureAlias(),
      haveErrorBars ? SoqlHelpers.errorBarsLowerAlias() : null,
      haveErrorBars ? SoqlHelpers.errorBarsUpperAlias() : null,
      requireGroupingInSelect ? SoqlHelpers.groupingAlias() : null
    )
    .then(checkForDuplicateValues(isAggregated, requireGroupingInSelect))
    .then(aggregateOtherCategory(showOtherCategory, limitFromVif, haveErrorBars))
    .then(mapQueryResponseToDataTable(vif));
}

function checkForDuplicateValues(isAggregated, requireGroupingInSelect) {
  return (queryResponse) => {
    const rowCount = queryResponse.rows.length;
    const dimensionCount = _.uniq(queryResponse.rows.map((row) => row[0])).length;
    const groupCount = _.uniq(queryResponse.rows.map((row) => row[1])).length;
    if (!requireGroupingInSelect && rowCount !== dimensionCount) {
      // Queries that don't do grouping should have exactly one row per dimension.
      duplicateValuesError();
    }
    if (requireGroupingInSelect && !isAggregated) {
      // Special support for queries that group by a column but have aggregate = None
      // It's possible that the dimension/group pairs have unique values even if the dimension itself doesn't.
      // We can start by checking whether there are more rows than possible dimension/group pairs.
      if (dimensionCount * groupCount < rowCount) duplicateValuesError();

      // Now we need to go one by one and check that each dimension has a unique set of groups.
      // lets make a hashmap between dim names and a set of group names
      const dimToGroupMap = {};
      queryResponse.rows.forEach(([dim, group, value]) => {
        if (!dimToGroupMap[dim]) {
          // dim is new
          dimToGroupMap[dim] = new Set();
          dimToGroupMap[dim].add(group);
        } else if (!dimToGroupMap[dim].has(group)) {
          // group is new
          dimToGroupMap[dim].add(group);
        } else {
          // group is not new
          duplicateValuesError();
        }
      });
    }
    return queryResponse;
  };
}

function duplicateValuesError() {
  const error = new Error();
  error.vifValidatorErrors = [I18n.t('shared.visualizations.charts.common.error_duplicated_dimension_value')];
  throw error;
}

function aggregateOtherCategory(showOtherCategory, limitFromVif, haveErrorBars) {
  return (queryResponse) => {
    const rowCount = queryResponse.rows.length;
    delete queryResponse.rowIds;

    // Main functionality of showOtherCategory is here. We want to keep only limitFromVif rows,
    // and add an "Other" row to the end of the data table that aggregates the remaining rows.
    if (showOtherCategory && rowCount > limitFromVif) {
      const otherLabel = I18n.t('shared.visualizations.charts.common.other_category');
      const otherRows = queryResponse.rows.slice(limitFromVif);
      queryResponse.rows = queryResponse.rows.slice(0, limitFromVif);

      // treat undefined (where measure is null) as 0 while calculating the sum so it does not result in NaN
      const otherTotal = _.sumBy(otherRows, (row) => {
        const rowValue = Number.parseInt(row[1], 10);
        return Number.isNaN(rowValue) ? 0 : rowValue;
      });
      queryResponse.rows.push([otherLabel, otherTotal]);

      // Error bars for (Other) category.
      // I don't think this calculation existed before, it was just left empty.
      if (haveErrorBars) {
        const otherErrorBars = queryResponse.errorBars.slice(limitFromVif);
        queryResponse.errorBars = queryResponse.errorBars.slice(0, limitFromVif);
        const lowerTotal = _.sumBy(otherErrorBars, (bar) => parseInt(bar[1], 10));
        const upperTotal = _.sumBy(otherErrorBars, (bar) => parseInt(bar[2], 10));
        queryResponse.errorBars.push([otherLabel, lowerTotal, upperTotal]);
      }
    }

    return Promise.resolve(queryResponse);
  };
}

function mapQueryResponseToDataTable(vif) {
  return (queryResponse) => {
    const dataTable = queryResponse;
    const dimensionIndex = dataTable.columns.indexOf(SoqlHelpers.dimensionAlias());
    const groupingIndex = dataTable.columns.indexOf(SoqlHelpers.groupingAlias());
    const measureIndex = dataTable.columns.indexOf(SoqlHelpers.measureAlias());

    dataTable.columns[dimensionIndex] = 'dimension';
    dataTable.columns[measureIndex] = 'measure';

    if (groupingIndex !== -1) {
      dataTable.columns[groupingIndex] = 'grouping';
    }

    const treatNullValuesAsZero = _.get(vif, 'configuration.treatNullValuesAsZero', false);

    dataTable.rows.forEach((row) => {
      if (_.isUndefined(row[dimensionIndex])) {
        row[dimensionIndex] = null;
      }

      if (groupingIndex !== -1) {
        if (_.isUndefined(row[groupingIndex])) {
          row[groupingIndex] = null;
        }
      }

      if (_.isNil(row[measureIndex])) {
        row[measureIndex] = treatNullValuesAsZero ? 0 : null;
      } else {
        row[measureIndex] = getValue(row[measureIndex]);
      }
    });

    if (!_.isUndefined(dataTable.errorBars)) {
      dataTable.errorBars = dataTable.errorBars.map((row) => {
        const dimensionIndex = 0;
        const lowerBoundIndex = 1;
        const upperBoundIndex = 2;
        const newRow = [];

        // Dimension
        newRow[dimensionIndex] = _.isUndefined(row[dimensionIndex]) ? null : row[dimensionIndex];

        // Error bar bounds
        newRow[measureIndex] = [getValue(row[lowerBoundIndex]), getValue(row[upperBoundIndex])];

        return newRow;
      });
    }

    return dataTable;
  };
}

function getValue(o) {
  if (_.isNil(o)) {
    return null;
  } else if (typeof o !== 'boolean' && window.isFinite(o)) {
    // Do an explicit check for boolean here because Number(true)
    // returns 1, and we want bools to remain bools.
    return Number(o);
  } else {
    return o;
  }
}

export default makeSocrataCategoricalDataRequest;
