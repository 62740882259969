import { fetchJsonWithDefaultHeaders, fetchWithDefaultHeaders } from 'common/http';

const BASE_URL = '/api/domains';

export const addAccountModule = (targetCname, moduleName) => {
  const apiPath = `${BASE_URL}/${targetCname}.json?method=addAccountModule&moduleName=${moduleName}`;

  const options = {
    method: 'PUT',
    headers: { 'X-Socrata-Host': targetCname }
  };

  return fetchWithDefaultHeaders(apiPath, options); // no response
};

// Expectations
export const fetchDomainExpectations = (targetCname) =>
  fetchJsonWithDefaultHeaders(`${BASE_URL}/${targetCname}/expectations`);

export const addDomainExpectation = (targetCname, contractKey) => {
  const apiPath = `${BASE_URL}/${targetCname}/expectations?contractKey=${contractKey}`;
  const options = {
    method: 'POST',
    headers: { 'X-Socrata-Host': targetCname }
  };

  return fetchJsonWithDefaultHeaders(apiPath, options);
};

export const deleteDomainExpectation = (targetCname, contractKey) => {
  const apiPath = `${BASE_URL}/${targetCname}/expectations/${contractKey}`;
  const options = { method: 'DELETE' };

  return fetchWithDefaultHeaders(apiPath, options);
};
