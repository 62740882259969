import _ from 'lodash';
import $ from 'jquery';
import React, { FunctionComponent, useState, useLayoutEffect } from 'react';
import I18n from 'common/i18n';
import DomainRights from 'common/types/domainRights';
import UserSegment from 'common/types/users/userSegment';
import CreateAssetsDialog from '../create_assets_dialog';
import optionallyLocalizeUrls from '../../utils/optionally_localize_urls';
import MobileCreateAssetsDrawer from '../mobile_create_assets_drawer';
import { FeatureFlags } from 'common/feature_flags';
import {
  ForgeDivider,
  ForgeDrawer,
  ForgeExpansionPanel,
  ForgeIcon,
  ForgeList,
  ForgeListItem,
  ForgeOpenIcon
} from '@tylertech/forge-react';
import './index.scss';
import { assign as windowLocationAssign } from 'common/window_location';

interface NavigationDrawerProps {
  userSegment: UserSegment;
  usersRights: DomainRights;
}

interface IInternalLink {
  url: string | undefined;
  text: string | undefined;
}

const NavigationDrawer: FunctionComponent<NavigationDrawerProps> = ({ usersRights, userSegment }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const canCreateDatasets = _.includes(usersRights, DomainRights.create_datasets);
  const canCreateMeasures = _.includes(usersRights, DomainRights.create_measures);
  const canCreateStories =
    _.includes(usersRights, DomainRights.create_story) && FeatureFlags.value('stories_enabled');
  const canSeeActivityLog = _.includes(usersRights, DomainRights.view_all_dataset_status_logs);
  const canSeeDataCatalog =
      FeatureFlags.value('data_catalog_audience_level') === 'open' ||
      (FeatureFlags.value('data_catalog_audience_level') === 'internal' &&
          (_.includes(userSegment, UserSegment.SiteMember) || _.includes(userSegment, UserSegment.SuperAdmin))
      );
  const canSeeApprovals =
    _.includes(usersRights, DomainRights.review_public_approvals) ||
    _.includes(usersRights, DomainRights.review_internal_approvals);
  const canSeeAssetInventory =
    _.includes(userSegment, UserSegment.SiteMember) || _.includes(userSegment, UserSegment.SuperAdmin);
  const canSeeTeams =
    _.includes(usersRights, DomainRights.manage_teams) && FeatureFlags.value('enable_teams');
  const canSeeUser = _.includes(usersRights, DomainRights.manage_users);
  const isAdmin = !_.includes(userSegment, UserSegment.CommunityMember);
  const scope = 'shared.site_chrome.forge_omnibar.navigation';
  const internalLinks = _.get(window, 'socrata.siteChrome.internalLinks') as Array<IInternalLink | null>;
  const internalLinksMenuTitle = _.get(window, 'socrata.siteChrome.internalLinksMenuTitle', '') as string;
  const canSeeStoryTheme =
    _.includes(userSegment, UserSegment.SuperAdmin) && FeatureFlags.value('stories_enabled');

  useLayoutEffect(() => {
    /**
     * TODO: refactor this out once the entire omnibar is in React
     *
     * To keep the scope of EN-51138 small, I am using useLayoutEffect
     * to check to see if we are on a mobile device. If yes, set the
     * state to mobile. This will affect which Create Asset component
     * gets rendered.
     */
    const windowWidth = $(window).width();
    if (windowWidth && windowWidth < 670) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  });

  const CreateAssetsDialogElement = () => {
    return isMobile ? (
      <MobileCreateAssetsDrawer open={showDialog} closeDrawerCallback={() => setShowDialog(false)} />
    ) : (
      <CreateAssetsDialog open={showDialog} closeDialogCallback={() => setShowDialog(false)} />
    );
  };

  const internalLinkList = () => {
    // if localized Menu Title is not set, don't show the menu
    if (internalLinksMenuTitle === '') {
      return null;
    }

    // filter out null values from the internalLinks array
    const filteredInternalLinks = _.filter(internalLinks, (link) => link !== null) as Array<IInternalLink>;

    return filteredInternalLinks.length > 0 ? (
      <>
        <ForgeDivider />
        <ForgeList>
          <ForgeExpansionPanel>
            <ForgeListItem slot="header" id="site-chrome-internal-menu-title">
              <ForgeIcon name="business" slot="leading" />
              {internalLinksMenuTitle}
              <ForgeOpenIcon slot="trailing" />
            </ForgeListItem>
            <ForgeList>
              {filteredInternalLinks.map((link: IInternalLink, key: number) =>
                link.url && link.text ? (
                  <a href={optionallyLocalizeUrls(link.url)} key={key}>
                    <ForgeListItem indented>
                      {link.text}
                    </ForgeListItem>
                  </a>
                ) : null
              )}
            </ForgeList>
          </ForgeExpansionPanel>
        </ForgeList>
      </>
    ) : null;
  };

  return (
    <>
      {(canCreateDatasets || canCreateStories || canCreateMeasures || canSeeStoryTheme) &&
        CreateAssetsDialogElement()}
      <ForgeDrawer id="forge-nav-drawer" open={false}>
        <ForgeList>
          {canSeeDataCatalog ? (
            <a href={optionallyLocalizeUrls('/browse')}>
              <ForgeListItem>
                <ForgeIcon name="menu_book" slot="leading" />
                {I18n.t(`${scope}.browse`)}
              </ForgeListItem>
            </a>
          ) : null }
          <a href={optionallyLocalizeUrls('/profile')}>
            <ForgeListItem>
              <ForgeIcon name="person" slot="leading" />
              {I18n.t(`${scope}.profile`)}
            </ForgeListItem>
          </a>
          {(canCreateDatasets || canCreateStories || canCreateMeasures || canSeeStoryTheme) && (
            <ForgeListItem selected onClick={() => setShowDialog(true)}>
              <ForgeIcon name="add" slot="leading" />
              {I18n.t(`${scope}.create`)}
            </ForgeListItem>
          )}
        </ForgeList>
        <ForgeDivider />
        <ForgeList>
          {canSeeAssetInventory ? (
            <a href={optionallyLocalizeUrls('/admin/assets')}>
              <ForgeListItem>
                <ForgeIcon name="file_multiple_outline" slot="leading" />
                {I18n.t(`${scope}.assets`)}
              </ForgeListItem>
            </a>
          ) : null}
          {canSeeApprovals ? (
            <a href={optionallyLocalizeUrls('/admin/approvals')}>
              <ForgeListItem>
                <ForgeIcon name="checkbox_marked_outline" slot="leading" />
                {I18n.t(`${scope}.approval`)}
              </ForgeListItem>
            </a>
          ) : null}
          {canSeeActivityLog ? (
            <a href={optionallyLocalizeUrls('/admin/activity_feed')}>
              <ForgeListItem>
                <ForgeIcon name="history" slot="leading" />
                {I18n.t(`${scope}.activity`)}
              </ForgeListItem>
            </a>
          ) : null}
          {canSeeUser ? (
            <a href={optionallyLocalizeUrls('/admin/users')}>
              <ForgeListItem>
                <ForgeIcon name="people" slot="leading" />
                {canSeeTeams ? I18n.t(`${scope}.users_and_teams`) : I18n.t(`${scope}.users`)}
              </ForgeListItem>
            </a>
          ) : null}
          {isAdmin ? (
            <a href={optionallyLocalizeUrls('/admin')}>
              <ForgeListItem>
                <ForgeIcon name="settings" slot="leading" />
                {I18n.t(`${scope}.admin`)}
              </ForgeListItem>
            </a>
          ) : null}
        </ForgeList>
        {!_.isEmpty(internalLinks) ? internalLinkList() : null}
        <ForgeDivider />
        <ForgeList>
          <ForgeListItem onClick={() => windowLocationAssign(optionallyLocalizeUrls('/logout'))}>
            <ForgeIcon name="logout" slot="leading" />
            {I18n.t(`${scope}.sign_out`)}
          </ForgeListItem>
        </ForgeList>
      </ForgeDrawer>
    </>
  );
};

export default NavigationDrawer;
