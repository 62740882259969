import React, { FunctionComponent, useState } from 'react';
import I18n from 'common/i18n';
import { ForgeIcon, ForgeIconButton, ForgeTooltip } from '@tylertech/forge-react';
import DomainRights from 'common/types/domainRights';
import MobileActionPage from './../mobile_action_page';

interface MobileActionButtonProps {
  usersRights: DomainRights;
}

const MobileActionButton: FunctionComponent<MobileActionButtonProps> = ({ usersRights }) => {
  const scope = 'shared.site_chrome.forge_omnibar.navigation';
  const [showMobileActionPage, setMobileActionPage] = useState(false);

  return (
    <>
      <ForgeIconButton slot="profile" id="more-action-button">
        <button
          type="button"
          aria-label="View user profile"
          className="tyler-icons"
          onClick={() => setMobileActionPage(!showMobileActionPage)}
        >
          <ForgeIcon name="more_vert" />
        </button>
      </ForgeIconButton>
      <ForgeTooltip open position={'bottom'}>
        {I18n.t(`${scope}.create`)}
      </ForgeTooltip>
      <MobileActionPage
        open={showMobileActionPage}
        closeCallback={() => setMobileActionPage(false)}
        usersRights={usersRights}
      />
    </>
  );
};

export default MobileActionButton;
