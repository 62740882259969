import { picklistSizingStrategy } from './picklistSizingStrategy';

/**
 * optionsRef   : reference to the picklist element which needs to be positioned
 * dropdownRef  : reference to the anchor element where picklist needs to be positioned
 * options      : object
 *      - sizingStrategy : Value from picklistSizingStrategy enum. Default is SAME_AS_DROPDOWN.
 *      - showOptionsBelowHandle  : boolean.
 *          default : false
 *          - true  : positions the picklist below the dropdownRef.
 *          - false : positions the picklist on top of dropdownRef (and the dropdown
 *                    ref will be behind the picklist, not visible.)
 */
export const positionPicklist = (optionsRef, dropdownRef, options) => {
  const hasOptions = optionsRef && optionsRef.querySelectorAll('.picklist-option').length > 0;

  if (!hasOptions) {
    return;
  }

  const { picklistSizingStrategy: sizingStrategy, showOptionsBelowHandle, alwaysCalculateHeight } = options;
  const containerDimensions = dropdownRef.getBoundingClientRect();
  const browserWindowHeight = window.document.documentElement.clientHeight - 10;
  const browserWindowWidth = window.document.documentElement.clientWidth;
  const picklistOptionElement = optionsRef.querySelector('.picklist-option');

  // Calculate X Position
  const optionWidth = picklistOptionElement.clientWidth;
  const exceedsBrowserWindowWidth = browserWindowWidth < containerDimensions.left + optionWidth;

  const menuPosition = {
    left: containerDimensions.left,
    top: containerDimensions.top
  };

  let offsetParent = dropdownRef.offsetParent;

  while (offsetParent) {
    // If an ancestor of the position:fixed dropdown menu has a
    // transform, filter, or perspective style set, the menu will
    // position relative to that ancestor instead of the initial
    // containing block on the page. To fix, subtract the left and
    // top offsets of that first ancestor.
    const style = window.getComputedStyle(offsetParent);

    if (style.transform !== 'none' || style.filter !== 'none' || style.perspective !== 'none') {
      const rect = offsetParent.getBoundingClientRect();
      menuPosition.left -= rect.left;
      menuPosition.top -= rect.top;
      break;
    }

    offsetParent = offsetParent.offsetParent;
  }

  const optionsLeft = exceedsBrowserWindowWidth ? menuPosition.left - optionWidth : menuPosition.left;

  optionsRef.style.left = `${optionsLeft}px`;

  const yOffset = showOptionsBelowHandle ? containerDimensions.height : 0;

  // Calculate Y Position
  let optionsTop = menuPosition.top + yOffset;

  // Calculate Height
  const scrollHeight = optionsRef.scrollHeight;
  const exceedsBrowserWindowHeight = browserWindowHeight < optionsTop + scrollHeight;
  optionsRef.style.height = 'auto'; // default case

  if (exceedsBrowserWindowHeight || alwaysCalculateHeight) {
    // Padding spaceAbove with 10px so it isn't right up against the top of the window.
    const spaceAbove = menuPosition.top - 10;
    // browserWindowHeight has already been padded with 10px.
    const spaceBelow = browserWindowHeight - containerDimensions.bottom;

    let space;

    // Since the options won't all fit below, we need to show them above or below,
    // based on where there is more room. Note there may be cases where
    // we don't end up shrinking the box, because it will all fit above.
    if (spaceAbove > spaceBelow) {
      // Sometimes we have more space than we need.
      space = Math.min(spaceAbove, scrollHeight);
      optionsTop = menuPosition.top - space + containerDimensions.height - yOffset;
    } else {
      // If we aren't showing the handle, then we can add more space.
      const handleSpace = showOptionsBelowHandle ? 0 : containerDimensions.height;
      space = Math.min(spaceBelow + handleSpace, scrollHeight);
    }
    if (space < scrollHeight) {
      // We need to make the box smaller.
      optionsRef.style.height = `${space}px`;
    }
  }

  optionsRef.style.top = `${optionsTop}px`;

  if (sizingStrategy === picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM) {
    optionsRef.style.minWidth = `${containerDimensions.width}px`;
  } else {
    optionsRef.style.width = `${containerDimensions.width}px`;
  }
};
