import $ from 'jquery';
import _ from 'lodash';
import mapboxgl from '@socrata/mapbox-gl';

import MapFactory from './MapFactory';
import { FIT_BOUNDS_PADDING } from '../mapConstants';
import { getBasemapStyle } from './basemapStyle';
import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';

const LAYER = {
  BASEMAP_RASTER_LAYER: 'raster-basemap-tile'
};

/**
* Handles vif center/zoom init/updates.
* Handles vif baseLayer/basemapStyle init/updates.
* Returns mapOptions with the above mentioned for mapCreation.
*/
export default class VifBasemap {
  constructor(map) {
    this._map = map;
    this._existingVif = {};
  }

  initialize(vif) {
    this._existingVif = vif;
  }

  async update(vif) {
    const mapOptions = VifBasemap.getMapInitOptions(vif);
    const newBasemapStyle = getBasemapStyle(vif);
    const newBasemapOpacity = getBasemapOpacity(vif);

    if (getBasemapStyle(this._existingVif) !== newBasemapStyle) {
      // When switching between styles, we do not want to diff and load based on the new diff feature.
      // As some layers of unified map are inserted between existing layers based on a basemap.
      // So that city names display above a filled shapes, otherwise the map will be completed
      // shadowed by filled shapes.
      this._map.setStyle(getStyleDef(vif), { diff: false });
    }

    if (getBasemapOpacity(this._existingVif) !== newBasemapOpacity && !isVectorTile(newBasemapStyle)) {
      this._map.setPaintProperty(LAYER.BASEMAP_RASTER_LAYER, 'raster-opacity', newBasemapOpacity);
    }

    this._updateMapPosition(vif);

    if (_.get(vif, 'series[0].type') === 'map') {
      if (getTitle(this._existingVif) !== getTitle(vif) ||
        getDescription(this._existingVif) !== getDescription(vif)) {
        // resize the map canvas to fit to it's container height
        $('.socrata-visualization').trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
      }
    }

    this._existingVif = vif;
  }

  static getMapInitOptions(vif) {
    const center = _.get(vif, 'configuration.mapCenterAndZoom.center');
    const zoom = _.get(vif, 'configuration.mapCenterAndZoom.zoom');
    const minZoom = getMinZoom(vif);
    const maxZoom = getMaxZoom(vif);
    const pitch = _.get(vif, 'configuration.mapPitchAndBearing.pitch', 0);
    const bearing = _.get(vif, 'configuration.mapPitchAndBearing.bearing', 0);
    const centerAndZoomDefined = _.chain(vif).at(
      'configuration.mapCenterAndZoom.center.lat',
      'configuration.mapCenterAndZoom.center.lng',
      'configuration.mapCenterAndZoom.zoom'
    ).every(_.isNumber).value();

    if (centerAndZoomDefined) {
      return {
        center: new mapboxgl.LngLat(center.lng, center.lat),
        zoom: zoom,
        style: getStyleDef(vif),
        pitch: pitch,
        bearing: bearing,
        minZoom: minZoom,
        maxZoom: maxZoom
      };
    } else {
      return {
        style: getStyleDef(vif),
        pitch: pitch,
        bearing: bearing,
        minZoom: minZoom,
        maxZoom: maxZoom
      };
    }
  }

  async _updateMapPosition(vif) {
    const mapOptions = VifBasemap.getMapInitOptions(vif);
    this._updateMinAndMaxZoomLevel(mapOptions, vif);

    if (this._map.isMoving()) {
      return;
    }

    if (mapOptions.zoom && mapOptions.center && !this._vifSeriesCountChanged(vif)) {
      // If the center/zoom in vif has not changed, then do not update the map's center/zoom.
      // Retain the maps zoom/center. (The user would have changed the map's zoom and center
      // from what is given in the vif by panning/zoom/...).
      // In published mode, on toggling the map layers, we update the visualization with a new vif
      // internally.
      if (isMapCenterAndZoomEqual(vif, this._existingVif)) {
        return;
      }
      this._map.jumpTo({
        zoom: mapOptions.zoom,
        center: mapOptions.center,
        pitch: mapOptions.pitch,
        bearing: mapOptions.bearing
      });
    } else {
      const { featureBounds } = await MapFactory.getFeatureBounds(vif);

      if (featureBounds !== null) {
        this._map.fitBounds(featureBounds, {
          animate: true,
          padding: FIT_BOUNDS_PADDING
        });
      }
    }
  }

  _updateMinAndMaxZoomLevel(mapOptions, vif) {
    if (!isMapZoomLevelEqual(vif, this._existingVif)) {
      this._map.setMinZoom(mapOptions.minZoom);
      this._map.setMaxZoom(mapOptions.maxZoom);
    }
  }

  _vifSeriesCountChanged(newVif) {
    return _.get(this._existingVif, 'series.length', 0) !==
      _.get(newVif, 'series.length', 0);
  }
}

function isMapCenterAndZoomEqual(existingVif, newVif) {
  const newMapOptions = VifBasemap.getMapInitOptions(newVif);
  const existingMapOptions = VifBasemap.getMapInitOptions(existingVif);

  return !_.isUndefined(newMapOptions.zoom) && newMapOptions.zoom === existingMapOptions.zoom &&
    !_.isUndefined(newMapOptions.center) && _.isEqual(newMapOptions.center, existingMapOptions.center);
}

function isMapZoomLevelEqual(existingVif, newVif) {
  const newMapOptions = VifBasemap.getMapInitOptions(newVif);
  const existingMapOptions = VifBasemap.getMapInitOptions(existingVif);

  return !_.isUndefined(newMapOptions.minZoom) && newMapOptions.minZoom === existingMapOptions.minZoom &&
    !_.isUndefined(newMapOptions.maxZoom) && _.isEqual(newMapOptions.maxZoom, existingMapOptions.maxZoom);
}

function getStyleDef(vif) {
  const style = getBasemapStyle(vif);

  if (isVectorTile(style)) {
    return style;
  }

  return {
    'version' : 8,
    'glyphs' : 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    'sources': {
      'raster-tiles': {
        'type': 'raster',
        'tiles': [style],
        'tileSize': 256
      }
    },
    'layers': [{
      'id': LAYER.BASEMAP_RASTER_LAYER,
      'type': 'raster',
      'source': 'raster-tiles',
      'minzoom': 0,
      'maxzoom': 22,
      'paint': {
        'raster-opacity': getBasemapOpacity(vif)
      }
    }]
  };
}

function isVectorTile(style) {
  return (style + '').indexOf('mapbox://styles/') === 0;
}

function getBasemapOpacity(vif) {
  const basemapOpacity = parseFloat(_.get(vif, 'configuration.basemapOptions.basemapOpacity'));

  return _.isNaN(basemapOpacity) ? 1 : _.clamp(basemapOpacity, 0, 1);
}

function getTitle(vif) {
  return _.get(vif, 'title');
}

function getDescription(vif) {
  return _.get(vif, 'description');
}

function getMinZoom(vif) {
  return _.get(vif, 'configuration.mapZoomLevel.start', VIF_CONSTANTS.DEFAULT_MAP_ZOOM_LEVEL.start);
}

function getMaxZoom(vif) {
  return _.get(vif, 'configuration.mapZoomLevel.end', VIF_CONSTANTS.DEFAULT_MAP_ZOOM_LEVEL.end);
}
