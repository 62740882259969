import $ from 'jquery';

export default function DisablePreview() {
  $('.disablePreviewMode').on('click', function(evt) {
    evt.preventDefault();
    // Apparently this is how you delete cookies?
    if ($.cookies) {
      $.cookies.del('socrata_site_chrome_preview');
    } else {
      document.cookie = 'socrata_site_chrome_preview=deleted; expires='
        + new Date(0).toUTCString()
        + '; path=/';
    }
    window.location.reload();
  });
}
