import { isString, get } from 'lodash';
import { assert, assertIsOneOfTypes } from 'common/assertions';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'serv... Remove this comment to see the full error message
import httpRequest, { storytellerHeaders } from 'services/httpRequest';
import { storyStore } from './stores/StoryStore';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import { storySaveStarted, storySaveFailed } from 'store/reducers/StorySaveStatusReducer';
import { selectors } from 'store/selectors/DigestSelectors';
import { saveStory } from 'store/TopLevelActions';
import { updateStory } from 'store/reducers/StoryReducer';

export default { saveDraft };

interface DraftResponse {
  updatedAt?: Date;
  status: number;
  responseJSON: string;
}

/**
 * Saves a new draft of a story.
 * Emits STORY_SAVE_STARTED, STORY_SAVE_FAILED, and STORY_SAVED as steps are completed.
 *
 * @param {string} storyUid - The UID of the story to save.
 * @return {Promise<string>} A promise for the new story draft's digest.
 */
function saveDraft(storyUid: string) {
  assertIsOneOfTypes(storyUid, 'string');
  assert(storyStore.doesStoryExist(storyUid), 'Cannot save draft of non-existent story');

  const storyJson = storyStore.serializeStory(storyUid);

  // Should be updated from the X-Story-Digest header in the last save response.
  const storyDigest = selectors.getStoryDigest(StorytellerReduxStore.getState());

  StorytellerReduxStore.dispatch(
    storySaveStarted({
      serializedStory: storyJson
    })
  );

  const headers = storytellerHeaders();

  headers['If-Match'] = storyDigest;

  const url = `/stories/api/v1/stories/${storyUid}/drafts`;

  return httpRequest('POST', url, {
    data: storyJson,
    headers: headers
  })
    .then(
      ({ data, jqXHR }: { data: DraftResponse; jqXHR: any }) => {
        StorytellerReduxStore.dispatch(
          updateStory({
            updatedAt: data.updatedAt,
            storyUid: storyUid
          })
        );

        // Get the new draft digest from the response X-Story-Digest header.
        const newDigest = jqXHR.getResponseHeader('X-Story-Digest');
        if (isString(newDigest) && newDigest.length > 0) {
          return newDigest;
        } else {
          return handleError({ data });
        }
      },
      (error: { response: string; statusCode: any }) => {
        let responseJSON;
        try {
          responseJSON = JSON.parse(error.response);
        } catch (_err) {
          responseJSON = error.response;
        }

        const errorData = {
          status: error.statusCode,
          responseJSON: responseJSON
        };
        return handleError({ data: errorData });
      }
    )
    .then((newDigest: string) => {
      StorytellerReduxStore.dispatch(
        saveStory({
          storyUid: storyUid,
          digest: newDigest
        })
      );

      return newDigest;
    });

  // This function was originally written as a handler for $.Deferred.fail which
  // could be reached either upon the initial AJAX call's failure or upon a lack
  // of story digest header in an otherwise successful response. The latter case
  // invoked this handler with a string, while the former case used an error,
  // but manipulated either object to create a consistent dispatched payload.
  function handleError({ data }: { data: DraftResponse }) {
    const { status, responseJSON } = data;
    const errorReportingLabel = 'StoryDraftCreator#saveDraft';
    const errorMessage = `${errorReportingLabel}: Saving over already saved version (story: ${storyUid}, status: ${status}).`;

    StorytellerReduxStore.dispatch(
      storySaveFailed({
        storyUid: storyUid,
        message: data,
        conflictingUserId: get(responseJSON, 'conflictingUserId'),
        // A 412 (Precondition Failed) means our If-Match check failed, indicating someone else already
        // saved over the version of the story our user is editing. Aside: it's not 409 (Conflict) because
        // this error is generated by the If-Match, which is a precondition header.
        // Downstream code needs to handle this case specially, so it is called out as a separate field.
        conflict: status === 412,
        errorReporting: {
          message: errorMessage,
          label: errorReportingLabel
        }
      })
    );

    return Promise.reject(errorMessage);
  }
}
