/* Imports */
/* ============================================================================= */
import getFrom from 'lodash/fp/get'; // Renaming to avoid confusion with the _.get method

import { Status } from 'common/core/approvals_enums';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { GuidanceCheckSummary } from 'common/core/approvals/Types';
import { summarizeGuidanceCheckMethod, createWorkflowGuidanceMethodsObject } from '.';





/* Method */
/* ============================================================================= */
/**
 * Summarize the status (currentState) of each workflow within a GuidanceSummaryV2 object
 * @param guidance GuidanceSummaryV2 object
 * @returns A GuidanceCheckSummary object with a Status value or undefined for each workflow attribute
 */
export const summarizeAssetStatus = (guidance: GuidanceSummaryV2): GuidanceCheckSummary<Status> => {
  const getFromMethod = getFrom('currentState');
  const response = summarizeGuidanceCheckMethod<Status | undefined>(
    guidance,
    createWorkflowGuidanceMethodsObject<Status | undefined>(getFromMethod),
    undefined
  );

  return response as GuidanceCheckSummary<Status>;
};

export default summarizeAssetStatus;
