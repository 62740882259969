import _ from 'lodash';
import * as ss from 'simple-statistics';
import I18n from 'common/i18n';
import {  getPrecisionPlaces } from 'common/numbers';

import { getLinearBuckets, roundOffDecimalDigits, getMidpointRangeBuckets } from './RangeHelper';

import {
  COLOR_BY_BUCKETS_COUNT,
  DEFAULT_BOOLEAN_VALUE,
  DEFAULT_COLUMN_PRECISION,
  EMPTY_TRANSPARENT_COLOR,
  EMPTY_BUCKET_VALUE,
  STEP_VALUES
} from 'common/authoring_workflow/constants';

const OTHER_COLOR_BY_CATEGORY = '__$$other$$__';

// Given a list of categories, it returns required number of buckets and clubs extra buckets
// into other bucket.
export const categoriesToColorByBuckets = ({
  colorByCategories,
  colorByColumnDetails,
  bucketsCount,
  getColor = _.noop,
  isCastNullAsFalseInSeries,
  valueToLabelFormatter
}) => {
  if (_.isNull(colorByCategories)) {
    return [];
  }

  const numberOfCategories = Math.min(bucketsCount, colorByCategories.length);
  const isCheckbox = _.get(colorByColumnDetails, 'renderTypeName') === 'checkbox';
  const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');

  let categoryBuckets = _.chain(colorByCategories).
    map((category, index) => {
      let label;
      if (isCheckbox && _.isEmpty(category)) {
        label = isCastNullAsFalseInSeries ? DEFAULT_BOOLEAN_VALUE : noValueLabel;
      } else if (_.isNil(category)) {
        label = noValueLabel;
      } else {
        label = valueToLabelFormatter(category);
      }

      return {
        charmName: '',
        color: getColor(index, category || null),
        dashed: false,
        id: category,
        label
      };
    }).
    take(numberOfCategories).
    value();

  if (colorByCategories.length > COLOR_BY_BUCKETS_COUNT) {
    categoryBuckets = categoryBuckets.concat({
      color: getColor(numberOfCategories, OTHER_COLOR_BY_CATEGORY),
      dashed: false,
      charmName: '',
      id: OTHER_COLOR_BY_CATEGORY,
      label: 'Other'
    });
  }

  return categoryBuckets;
};

export const findBucket = (buckets, value) => {
  return buckets[findBucketIndex(buckets, value)];
};

export const findBucketIndex = (buckets, value) => {
  let index = _.findIndex(buckets, (bucket, index) => {
    if (!_.isUndefined(bucket.start) && !_.isUndefined(bucket.end)) {
      const isLastBucket = index === (buckets.length - 1);
      const isFirstBucket = index === 0;

      return (isFirstBucket || (value > bucket.start)) && ((value < bucket.end) || isLastBucket);
    } else {
      const sanitizedValue = _.isNil(value) ? '' : value;
      return bucket.id === sanitizedValue;
    }
  });

  const nullBucketIndex = _.findIndex(buckets, (bucket) => bucket.id === OTHER_COLOR_BY_CATEGORY);

  if (index >= 0) {
    return index;
  } else if (nullBucketIndex >= 0) {
    return nullBucketIndex;
  } else {
    return 0;
  }
};

// Given a colorByRange ({ min: <numerical>, max: <numerical value> }), it returns equal interval
// buckets with each bucket having a start, end and color.
export const rangeToColorByBuckets = ({
  colorByRange,
  bucketsCount,
  getColor = _.noop,
  includeNullBucket,
  legendPrecision,
  midpoint,
  nullBucketColor,
  valueToLabelFormatter
}) => {
  let rangeIntervals;
  if (!_.isNil(midpoint)) {
    rangeIntervals = getMidpointRangeBuckets({
      min: colorByRange.min,
      max: colorByRange.max,
      midpoint,
      dataClasses: bucketsCount,
      columnPrecison: legendPrecision
    });
  } else {
    rangeIntervals = getLinearBuckets(colorByRange.max, colorByRange.min, bucketsCount, legendPrecision);
  }
  let rangeBuckets = _.chain(0).
    range(rangeIntervals.length - 1, 1).
    map((index) => {
      const start = rangeIntervals[index];
      const end = rangeIntervals[index + 1];
      const label = getRangeLabel(start, end, valueToLabelFormatter);
      return {
        color: getColor(index),
        dashed: false,
        end,
        charmName: '',
        id: rangeIntervals[index],
        label,
        start
      };
    }).
    value();

  if (includeNullBucket) {
    rangeBuckets = rangeBuckets.concat({
      charmName: '',
      color: nullBucketColor,
      dashed: false,
      end: null,
      id: EMPTY_BUCKET_VALUE,
      label: I18n.t('shared.visualizations.charts.common.no_value'),
      start: null
    });
  }

  return rangeBuckets;
};

// Given a resizeByRange ({ min: <numerical>, max: <numerical value> }), it returns equal interval
// buckets with each bucket having a start, end and color.
export const rangeToSizeByBuckets = ({
  sizeByRange,
  bucketsCount,
  getSize = _.noop,
  includeNullBucket,
  valueToLabelFormatter = _.noop
}) => {
  const buckets = rangeToColorByBuckets({ colorByRange: sizeByRange, bucketsCount, valueToLabelFormatter });

  _.each(buckets, (bucket, index) => bucket.size = getSize(index));

  return buckets;
};

// Given an array of measure values(numeric values), it returns
//  - jenks based (if there are sufficient values for the required bucketsCount)
//  - or equal interval
// buckets with each bucket having a start, end and color.
export const measuresToColorByBuckets = ({
  measures,
  measureColumnDetails,
  getColor = _.noop,
  bucketsCount,
  isEqualIntervalBucketType,
  legendPrecision,
  midpoint,
  valueToLabelFormatter
}) => {
  const values = _.chain(measures).
      map('value').
      uniq().
      without(undefined, null).
      value();

  // If there is only one value, we cannot create buckets,
  // so adding 0, 1 to existing value, so that we can get
  // one or more buckets.
  const sanitizedValues = (values.length <= 1) ? values.concat([0, 1]) : values;
  const columnPrecison = _.get(measureColumnDetails, 'format.precision', DEFAULT_COLUMN_PRECISION);
  const precision = !_.isNil(legendPrecision) ? legendPrecision : columnPrecison;

  let bucketRanges;
  const insufficientValuesToUseJenks = values.length <= bucketsCount + 1;

  if (isEqualIntervalBucketType || insufficientValuesToUseJenks) {
    // if required number of buckets is 7(meaning, we want 8 stops) and we have only 8 unique values
    // then jenks will have undefineds in the stops. So using jenks only when there are more than
    // (bucket_count + 1) uniq values.
    let intervals;
    const min = _.min(sanitizedValues);
    const max = _.max(sanitizedValues);
    if (!_.isNil(midpoint)) {
      intervals = getMidpointRangeBuckets({
        min,
        max,
        midpoint,
        dataClasses: bucketsCount,
        columnPrecison: precision
      });
    } else {
      let bucketPrecision = precision;
      if (bucketPrecision != legendPrecision && bucketPrecision === DEFAULT_COLUMN_PRECISION) {
        // Check that the default precision is not less specific than the min and max numbers
        const precisionPlaces = _.max([getPrecisionPlaces(min),getPrecisionPlaces(max)]);
        bucketPrecision = precision > precisionPlaces ? precision : precisionPlaces;
      }
      intervals = getLinearBuckets(max, min, bucketsCount, bucketPrecision);
    }

    bucketRanges = _.chain(0).
      range(intervals.length - 1).
      map((index) => {
        let start;
        if (bucketsCount > _.max(sanitizedValues)) {
          start = intervals[index];
        } else {
          start = (index === 0) ?
            roundOffDecimalDigits(intervals[index], precision) :
            roundOffDecimalDigits(intervals[index] + STEP_VALUES[precision], precision);
        }

        return { start, end: intervals[index + 1] };
      }).
      value();
  } else {
    const intervalGroups = ss.ckmeans(values, bucketsCount);
    bucketRanges = _.map(intervalGroups, (intervalGroup) => {
      return { start: _.head(intervalGroup), end: _.last(intervalGroup) };
    });
  }

  return _.chain(bucketRanges).
    map((bucketRange, index) => {
      const label = getRangeLabel(bucketRange.start, bucketRange.end, valueToLabelFormatter);

      return {
        color: getColor(index),
        dashed: false,
        end: bucketRange.end,
        id: bucketRange.start,
        label: label,
        start: bucketRange.start
      };
    }).
    value().
    concat({
      color: EMPTY_TRANSPARENT_COLOR,
      dashed: false,
      end: null,
      id: EMPTY_BUCKET_VALUE,
      label: I18n.t('shared.visualizations.charts.common.no_value'),
      start: null
    });
};

export const getColorByBucket = ({ buckets, isColorByBooleanColumn, value }) => {
  if (isColorByBooleanColumn) {
    return getColorByBooleanBucket(buckets, value);
  } else {
    return findBucket(buckets, value);
  }
};

export const getRangeBucket = ({ buckets, value, bucketCount }) => {
  const defaultBucket = buckets[buckets.length - 1];

  if (_.isNil(value)) {
    return defaultBucket;
  }

  const rangeBucket = _.find(buckets, (bucket, index) => {
    let isLastBucket = index === (bucketCount - 1);

    if (isLastBucket) {
      return value >= bucket.start && value <= bucket.end;
    }

    return value >= bucket.start && value < bucket.end;
  });

  return _.isEmpty(rangeBucket) ? defaultBucket : rangeBucket;
};

export const sortMeasuresBasedOnIndex = (measures, vif) => {
  const series = _.get(vif, 'series[0]');
  const dimensionColumnName = _.get(series, 'dataSource.dimension.grouping.columnName');
  const isCustomColorPalette = _.get(series, 'color.palette') === 'custom';
  const customColorPalette = _.get(series, `color.customPalette[${dimensionColumnName}]`);

  if (isCustomColorPalette) {
    const newMeasures = applyIndexsFromPalette(customColorPalette, measures, true);
    return _.sortBy(newMeasures, 'index');
  }

  return measures;
};

export const applyIndexsFromPalette = (currentPalette, newColorPalette, isMeasureType = false) => {
  if (_.isEmpty(currentPalette)) {
    return newColorPalette;
  }

  const currentPaletteKeys = getPaletteKeys(currentPalette);
  let newColorPaletteKeys = getPaletteKeys(newColorPalette);
  let type = 'label';
  const otherLabel = I18n.t('shared.visualizations.charts.common.other_category');
  const unescapedLabel = (label) => (label === '') ? otherLabel : _.unescape(label);

  if (isMeasureType) {

    newColorPaletteKeys = _.map(newColorPalette, (paletteItem) => {
      return unescapedLabel(paletteItem.labelHtml);
    });
    type = 'labelHtml';
  }

  if (shouldRetainCustomPaletteIndex(currentPaletteKeys, newColorPaletteKeys)) {
    _.each(newColorPalette, (palette, index) => {
      const paletteDetails = _.find(currentPalette, (paletteItem) => {
        return (paletteItem.label === unescapedLabel(palette[type]) || paletteItem.label === palette[type]);
      });
      const paletteIndex = _.get(paletteDetails, 'index', Number(index));

      palette.index = paletteIndex;
    });
  }

  return newColorPalette;
};

export const shouldRetainCustomPaletteIndex = (currentPaletteKeys, newColorPaletteKeys) => {
  const differencedNewColorPalette = _.difference(newColorPaletteKeys, currentPaletteKeys);
  const differencedCurrentColorPalette = _.difference(currentPaletteKeys, newColorPaletteKeys);

  return _.isEmpty(differencedNewColorPalette) && _.isEmpty(differencedCurrentColorPalette);
};

function getRangeLabel(start, end, formatValue) {
  return (start !== end) ? `${formatValue(start)} - ${formatValue(end)}` : formatValue(start);
}

function getColorByBooleanBucket(buckets, value) {
  const defaultBucket = _.find(buckets, ['label', 'false']);
  const categoryBucket = _.find(buckets, (bucket) => {
    return bucket.id == value;
  });

  return categoryBucket || defaultBucket;
}

function getPaletteKeys(customPalette) {
  return _.chain(customPalette).
    filter((palette) => palette.index > -1).
    map('label').
    value();
}

export function mergeCustomPaletteAndBucket({
  customColorPalette,
  hasLabelChanged,
  bucket,
  bucketIndex
}) {
  const { label } = bucket;
  const sortedCustomColorPaletteBasedOnValue = sortCustomColorPaletteBasedOnValue(customColorPalette);

  /**
 * When buckets get recalculated, they always get passed in ascending order. To make sure that
 * the color palette align with the ones prior to the bucket recalculation, we are going to sort
 * the color palette via the start value so that they are in ascending order as well.
 */
  const customColorPaletteConfig = hasLabelChanged ? sortedCustomColorPaletteBasedOnValue[bucketIndex] : _.find(sortedCustomColorPaletteBasedOnValue, { label });

  return _.merge({}, bucket, {
    index: _.get(customColorPaletteConfig || bucket, 'index', bucketIndex),
    color: _.get(customColorPaletteConfig || bucket, 'color'),
    charmName: _.get(customColorPaletteConfig || bucket, 'charmName', '')
  });
}

export function hasLabelChange(customColorPalette, buckets) {
  const sortedCustomColorPalette = sortCustomColorPaletteBasedOnIndex(customColorPalette);
  const customPaletteKeys = _.map(sortedCustomColorPalette, 'label');
  const bucketKeys = _.map(buckets, 'label');

  return !shouldRetainCustomPaletteIndex(customPaletteKeys, bucketKeys);
}

export function sortCustomColorPaletteBasedOnIndex(customColorPalette) {
  return _.chain(customColorPalette).
    filter((paletteItem) => paletteItem.index > -1).
    sortBy('index').
    value();
}

export function sortCustomColorPaletteBasedOnValue(customColorPalette) {
  return _.chain(customColorPalette).
    filter((paletteItem) => paletteItem.index > -1).
    sortBy('start').
    value();
}
