import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import getLocale from 'common/js_utils/getLocale';

export const getTimeZones = () => {
  const timezones = momentTimezone.tz.names();

  return _.map(timezones, (zone) => {
    return {
      title: (zone + ' - ' + momentTimezone.tz(zone).format('Z z')),
      value: zone
    };
  });
};

// returns => [{title: 'Monday', value: 1} ..... {title: 'Sunday', value: 7}]
export const getDayNames = () => {
  const locale = getLocale(window);

  return _.map([1,2,3,4,5,6,7], (day) => {
    return {
      title: moment().day(day).locale(locale).format('dddd'),
      value: (day).toString()
    };
  });
};
