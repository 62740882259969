import React, { PureComponent } from 'react';

import './spinner.scss';

class Spinner extends PureComponent {
  render() {
    return <div className="common-notifications-spinner spinner-default spinner-large" />;
  }
}

export default Spinner;
