/** This function checks story links using href's to heading Ids (#) to remove
 *  the edit story path when in the edit modal. If the user is on the edit page of
 *  a story the edit path will be removed from the url so it will not
 *  appear as if the url is referencing the edit page in the link modal
 *  and link tip. */
export function removeEditStoryUrl(link, storyUid) {
    var editUrlRegex = new RegExp(`${storyUid}\/edit`);

    return editUrlRegex.test(link) ? link.replace(editUrlRegex, storyUid) : link;
}
