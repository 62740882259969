/**
 * Returns the currency name for given locale for formatting a number, defaults to USD
 */
export default function getCurrency(locale) {
  switch (locale) {
    case 'en':
      return 'USD';

    case 'ca':
    case 'es':
    case 'fr':
    case 'it':
    case 'nl':
      return 'EUR';

    case 'zh':
      return 'CNY';

    default:
      return 'USD';
  }
}
