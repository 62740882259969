import _ from 'lodash';

import RenderByHelper from 'common/visualizations/helpers/RenderByHelper';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { getTileUrl } from 'common/visualizations/helpers/MapHelper';
import { QUERY_TIMEOUT_SECONDS } from 'common/visualizations/views/mapConstants';
import { RANGE_BUCKET_TYPES, EMPTY_BUCKET_VALUE } from 'common/authoring_workflow/constants';
import { roundOffDecimalDigits } from 'common/visualizations/helpers/RangeHelper';
import { DEFAULT_COLUMN_PRECISION } from 'common/authoring_workflow/constants';

import PartialWrapper from './partials/PartialWrapper';
import Regions, { LAYERS as REGION_LAYERS } from './partials/Regions';

// Prepares renderOptions and renders partials.
// Refer: common/visualizations/views/map/README.md
export default class VifRegionOverlay {
  constructor(map, mouseInteractionHandler) {
    const seriesId = _.uniqueId();

    this._regions = new PartialWrapper(map, new Regions(map, seriesId));
    this._mouseInteractionHandler = mouseInteractionHandler;
  }

  // Renders the partials required for this overlay and sets up required mouse interactions.
  // vif            : <object> vif got from AX/visualization
  // renderOptions  : <object> got from this overlays prepare method
  // overlayOptions : object
  // overlayOptions.renderLayersBefore : <string> renders all mapbox-gl-layers for this overlay
  //                                      before given layer-id
  render(vif, renderOptions, overlayOptions) {
    this._regions.render(vif, renderOptions, overlayOptions);
    this._mouseInteractionHandler.register(
      this._regions.getLayerId(REGION_LAYERS.REGIONS_FILL),
      vif,
      renderOptions, {
        popupOnMouseOver: true,
        highlight: true
      }
    );
  }

  async prepare(vif, metaDataPromise) {
    if (_.isUndefined(vif.getDomain()) || _.isUndefined(vif.getShapeDatasetUid())) {
      return Promise.reject('domain|datasetUid|column are yet to be filled in.');
    }

    const measures = await RenderByHelper.getMeasuresForRegions(vif);

    const [buckets, datasetMetadata, shapeDatasetMetadata] = await Promise.all([
      RenderByHelper.getColorByBuckets(vif, null, measures, metaDataPromise, vif.isCustomColorPalette()),
      metaDataPromise,
      vif.getShapeDatasetMetadata()
    ]);

    const tileDataUrl = this._getDataUrl(vif, shapeDatasetMetadata);

    const defaultBucket = _.find(buckets, ['id', EMPTY_BUCKET_VALUE]);
    const isEqualIntervalBucketType = vif.getRangeBucketType() === RANGE_BUCKET_TYPES.equalInterval.value;
    const measureColumn = vif.getMeasureColumn();
    const measureColumnDetails = _.find(datasetMetadata.columns, ['fieldName', measureColumn]);
    const legendPrecision = vif.getMapLegendPrecision();
    const columnPrecison = _.get(measureColumnDetails, 'format.precision', DEFAULT_COLUMN_PRECISION);
    const precision = !_.isNil(legendPrecision) ? legendPrecision : columnPrecison;
    const maxMeasureValue = _.chain(measures).map('value').without(undefined, null).max().value();
    const colorByBucketsCount = vif.getColorByBucketsCount();

    const shapeColorConfigs = _.map(measures, (measure) => {
      let matchingBucket;

      if (_.isNil(measure.value)) {
        matchingBucket = defaultBucket;
      } else {
        matchingBucket = _.find(buckets, (bucket, index) => {
          let value = measure.value;

          if (isEqualIntervalBucketType) {
            value = colorByBucketsCount > maxMeasureValue ? value : roundOffDecimalDigits(value, precision);
          }

          // In jenks bucketing, the buckets will be non continuous and some buckets start/end will be the same.
          // For example: [10-20], [30-30], [31-45], [50-100]
          return value >= bucket.start && value <= bucket.end;
        });
      }
      const colorForShape = _.get((matchingBucket || defaultBucket), 'color');

      return { shapeId: measure.shapeId, color: colorForShape };
    });

    return {
      buckets,
      datasetMetadata,
      dataUrl: tileDataUrl,
      legendItems: _.reverse(_.cloneDeep(buckets)),
      measures,
      shapeColorConfigs,
      shapePrimaryKey: vif.getShapeDatasetPrimaryKey()
    };
  }

  _getDataUrl(vif, shapeDatasetMetadata) {
    const domain = vif.getDomain();
    const datasetUid = vif.getShapeDatasetUid();
    const columnName = vif.getShapeGeometryColumn(shapeDatasetMetadata);

    let selects = [`simplify_preserve_topology(snap_to_grid(${SoqlHelpers.escapeColumnName(columnName)}, {snap_precision}),{simplify_precision}),` +
      `${vif.getShapeDatasetPrimaryKey()}`];

    if (!_.isNull(vif.getShapeGeometryLabelColumn())) {
      selects.push(vif.getShapeGeometryLabelColumn());
    }

    const query = `select ${selects.join(',')} ` +
      `where {{'${columnName}' column condition}} ` +
      'limit 10000 ';

    return getTileUrl(domain, datasetUid, query, vif.getSimplificationLevel());
  }

  destroy() {
    this._regions.destroy();
    this._mouseInteractionHandler.unregister(
      this._regions.getLayerId(REGION_LAYERS.REGIONS_FILL)
    );
  }
}
