import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import Spinner from 'common/notifications/components/Spinner';
import DeleteAlertApi from './api/DeleteAlertApi';

import './deleteAlertModal.scss';

/**
 DeleteAlertModal - Confirmation section shown on click of 'delete alert' button. This section renders
 the following buttons:
   'Delete alert(s)'    :  will delete the alert, and on api finish, will call onDeleteSuccess
   'cancel' :  will cancel the delete operation
*/
const scope = 'shared.components.create_alert_modal.delete_view';
class DeleteAlertModal extends Component {

  state = { showSpinner: false, showErrorMessage: false };

  onDeleteAlertClick = () => {
    const { onDeleteSuccess, selectedAlertIds } = this.props;
    this.setState({ showSpinner: true });

    DeleteAlertApi.bulkDelete(selectedAlertIds).then(() => {
      onDeleteSuccess();
      this.setState({ showSpinner: false });
    }).catch((error) => {
      this.setState({ showSpinner: false, showErrorMessage: true });
      console.error(error);
    });
  };

  renderErrorMessage() {
    const { showErrorMessage } = this.state;
    if (showErrorMessage) {
      return (
        <span className="error-message">
          {I18n.t('error_message', { scope })}
        </span>
      );
    }
  }

  renderModalFooter(alertUnit) {
    const { onCancel } = this.props;
    const { showErrorMessage, showSpinner } = this.state;

    return (
      <ModalFooter>
        <div className="delete-footer delete-page">
          {showSpinner ? <Spinner /> : null}
          <div className="delete-options">
            <button
              className="btn btn-cancel"
              onClick={onCancel}>
              {I18n.t('button.cancel', { scope })}
            </button>
            <button
              disabled={showErrorMessage}
              className="btn btn-delete"
              onClick={this.onDeleteAlertClick}>
              {I18n.t(`${alertUnit}.button_label`, { scope })}
            </button>
          </div>
        </div>
      </ModalFooter>
    );
  }

  render() {
    const { onDismiss, selectedAlertIds } = this.props;
    const alertUnit = selectedAlertIds.length > 1 ? 'plural' : 'singular';

    return (
      <Modal
        className="delete-alert-modal alert-setting-modal"
        onDismiss={onDismiss}>
        <ModalHeader
          title={I18n.t(`${alertUnit}.title`, { scope })}
          showCloseButton={false} />
        <ModalContent>
          {this.renderErrorMessage()}
          <div className="title">
            {I18n.t(`${alertUnit}.subtitle`, { scope })}
          </div>
        </ModalContent>
        {this.renderModalFooter(alertUnit)}
      </Modal>
    );
  }
}

DeleteAlertModal.propTypes = {
  onCancel: PropTypes.func,
  onDismiss: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  selectedAlertIds: PropTypes.array.isRequired
};

export default DeleteAlertModal;
