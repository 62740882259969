/* Imports */
/* ============================================================================= */
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

import { SubmissionKeyV2 } from 'common/core/approvals/Types';





/* Pre-Defined Values */
/* ============================================================================= */
export const SUBMISSION_KEYS_V2: SubmissionKeyV2[] = Object.values(SubmissionKeyV2).sort();

export const WORKFLOWS: WorkflowTargetAudience[] = [
  WorkflowTargetAudience.INTERNAL,
  WorkflowTargetAudience.PUBLIC
];
