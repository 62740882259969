import { Either, iif, buildBothForTest } from 'common/either';
import { FeatureFlags } from 'common/feature_flags';
import { FunCall, TypedSoQLFunCall, Expr, TypedExpr } from 'common/types/soql';
import { Eexpr, EexprNA } from '../types';

export const usingNewAnalysisEndpoint = () =>
  FeatureFlags.valueOrDefault('soql_analyzer_implementation', 'original') === 'new-analyzer';

export const whichAnalyzer = iif(usingNewAnalysisEndpoint);
export const eitherForTest = buildBothForTest(usingNewAnalysisEndpoint);

export const createUpdateContents = (
  eitherEexpr: Either<Eexpr<FunCall, TypedSoQLFunCall>, EexprNA<TypedSoQLFunCall>>,
  otherArgs: Either<Expr[], TypedExpr[]>
) =>
  eitherEexpr.map(
    eexpr => ({
      ...eexpr.untyped,
      args: [eexpr.untyped.args[0], ...otherArgs.left]
    }),
    eexpr => ({
      ...eexpr.expr,
      args: [eexpr.expr.args[0], ...otherArgs.right]
    })
  );
