import _ from 'lodash';

import MapHelper from 'common/visualizations/helpers/MapHelper';
import { EMPTY_TRANSPARENT_COLOR, EMPTY_BUCKET_VALUE } from 'common/authoring_workflow/constants';

export const SOURCES = Object.freeze({
  REGIONS: 'regions-source'
});

export const LAYERS = Object.freeze({
  REGIONS_FILL: 'regions-fill-layer',
  REGIONS_OUTLINE: 'regions-line-layer'
});

// Render shapes retrieved by the tile data call and colors them based on
// measures got from renderOptions.
export default class Regions {
  constructor(map, seriesId) {
    this._map = map;

    this.layerIds = MapHelper.getNameToIdMap(_.values(LAYERS), seriesId);
    this.sourceIds = MapHelper.getNameToIdMap(_.values(SOURCES), seriesId);
  }

  alreadySetup() {
    return this._map.getSource(this.sourceIds[SOURCES.REGIONS]);
  }

  // Render options:
  //    measures: [{ shapeId: "1", value: 106290 }, ....]
  //    shapeColorConfigs: [{}]
  //    bucket: [{ start: 6, end: 121, color: "#e41a1c" }, ...]
  //    dataUrl: https://example.com/resource/four-four.geojson?$query=.... (tile url)
  //    shapePrimaryKey: _feature_id (primaryKey name in the tile data)
  setup(vif, renderOptions, overlayOptions) {
    this._map.addSource(this.sourceIds[SOURCES.REGIONS], this.sourceOptions(vif, renderOptions));

    this._map.addLayer({
      'id': this.layerIds[LAYERS.REGIONS_FILL],
      'type': 'fill',
      'source': this.sourceIds[SOURCES.REGIONS],
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': vif.isLayerVisible() ? 'visible' : 'none'
      },
      'paint': {
        'fill-color': getStops(renderOptions),
        'fill-opacity': vif.getShapeFillOpacity()
      }
    }, overlayOptions.renderLayersBefore);

    this._map.addLayer({
      'id': this.layerIds[LAYERS.REGIONS_OUTLINE],
      'type': 'line',
      'source': this.sourceIds[SOURCES.REGIONS],
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': vif.isLayerVisible() ? 'visible' : 'none'
      },
      'paint': {
        'line-color': vif.getShapeOutlineColor(),
        'line-width': vif.getShapeOutlineWidth()
      }
    }, overlayOptions.renderLayersBefore);
  }

  update(vif, renderOptions) {
    this._map.setLayoutProperty(this.layerIds[LAYERS.REGIONS_FILL],
      'visibility',
      vif.isLayerVisible() ? 'visible' : 'none'
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.REGIONS_FILL],
      'fill-color',
      getStops(renderOptions)
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.REGIONS_FILL],
      'fill-opacity',
      vif.getShapeFillOpacity()
    );
    this._map.setLayoutProperty(this.layerIds[LAYERS.REGIONS_OUTLINE],
      'visibility',
      vif.isLayerVisible() ? 'visible' : 'none'
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.REGIONS_OUTLINE],
      'line-color',
      vif.getShapeOutlineColor()
    );
    this._map.setPaintProperty(this.layerIds[LAYERS.REGIONS_OUTLINE],
      'line-width',
      vif.getShapeOutlineWidth()
    );
  }

  sourceOptions(vif, renderOptions) {
    return {
      'type': 'vector',
      'geojsonTile': true,
      'tiles': [renderOptions.dataUrl]
    };
  }

  destroy() {
    _.each(this.layerIds, (layerId) => {
      this._map.removeLayer(layerId);
    });
    _.each(this.sourceIds, (sourceId) => {
      this._map.removeSource(sourceId);
    });
  }
}

function getStops(renderOptions) {
  let paintProperty = ['match', ['to-string', ['get', renderOptions.shapePrimaryKey]]];
  const defaultColor = getDefaultColor(renderOptions.buckets);

  if (_.isEmpty(renderOptions.shapeColorConfigs)) {
    paintProperty.push(EMPTY_BUCKET_VALUE, EMPTY_TRANSPARENT_COLOR);
  } else {
    _.each(renderOptions.shapeColorConfigs, (shapeColorConfig) => {
      paintProperty.push(shapeColorConfig.shapeId, shapeColorConfig.color);
    });
  }

  paintProperty.push(defaultColor);
  return paintProperty;
}

export function getDefaultColor(buckets) {
  const defaultBucket = _.find(buckets, ['id', EMPTY_BUCKET_VALUE]);
  return _.get(defaultBucket, 'color', EMPTY_TRANSPARENT_COLOR);
}
