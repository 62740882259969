// See README.md in this folder - there's lots of info there.
import _ from 'lodash';
import BigNumber from 'bignumber.js';

import {
  getValidTargets,
  hasOverlappingTargets
} from 'common/performance_measures/lib/measureHelpers';

import { getPercentScaleMultiplier } from '../lib/percents';

import {
  StatusFunctions,
  StatusValues,
  TargetTypes,
  AboveBelowDirection
} from 'common/performance_measures/lib/constants';

// Enhances the computed measure with status judgment information. Keep in mind,
// user-provided labels are *not* considered here. Everything is using enum values.
//
// The returned value is a copy of `computedMeasure` with an additional `status`
// property on the `result` object.
export const computeStatus = (measure, computedMeasure, calculationColumns) => {
  if (!measure || !computedMeasure) {
    return null;
  }

  const status = _.get(measure, 'metricConfig.status') || {};
  const { type } = status;

  if (!type || type === StatusFunctions.NONE) {
    return computedMeasure;
  } else if (type === StatusFunctions.MANUAL) {
    return computeManualStatus(measure, computedMeasure);
  } else if (type === StatusFunctions.PROXIMITY) {
    return computeProximityStatus(measure, computedMeasure, calculationColumns);
  } else if (type === StatusFunctions.ABOVE_BELOW) {
    return computeAboveBelowStatus(measure, computedMeasure, calculationColumns);
  } else {
    console.warn(`Unsupported status type: ${type}, skipping status computation`);
    return computedMeasure;
  }
};

/* Internal helpers */

// Internal helper to compute manual status.
const computeManualStatus = (measure, computedMeasure) => {
  const status = _.get(measure, 'metricConfig.status') || {};
  const manualStatus = status[StatusFunctions.MANUAL] || null;
  return computedMeasure.map((computedPeriod) => {
    return _.set(_.cloneDeep(computedPeriod), 'result.status', manualStatus);
  });
};

const findTargetForPeriod = (computedPeriod, targets, targetType, targetsByDate) => {
  if (targetType === TargetTypes.ONGOING) {
    // Will only ever have 1 ongoing (because we check above in hasOverlappingTargets).
    return targets[0];
  } else if (targetType === TargetTypes.PERIODIC) {
    // Targets use mm-dd-yyyy, while computation uses iso8601.
    return targetsByDate[computedPeriod.date.substring(0, 10)];
  } else {
    console.warn(`Unsupported target type: ${targets[0].type}, skipping status computation`);
    return null;
  }
};


const computeAboveBelowStatus = (measure, computedMeasure, calculationColumns) => {
  const status = _.get(measure, 'metricConfig.status') || {};
  const targets = getValidTargets(measure);

  const direction = _.get(status, 'above_below.direction');
  const invalidDirection = !_.includes(_.values(AboveBelowDirection), direction);

  if (hasOverlappingTargets(measure) || _.isEmpty(targets) || invalidDirection) {
    return computedMeasure.map((computedPeriod) => {
      return _.set(_.cloneDeep(computedPeriod), 'result.status', null);
    });
  }

  const includeTarget = _.get(status, 'above_below.include_target');

  const tolerance = BigNumber(_.get(status, 'above_below.tolerance'));

  let ontrackComparison = direction === AboveBelowDirection.ABOVE ? 'gt' : 'lt';
  if (includeTarget) {
    ontrackComparison += 'e';
  }

  // See comment in computeProximityStatus
  const calculationType = _.get(measure, 'metricConfig.type');
  const valueMultiplier = getPercentScaleMultiplier(calculationColumns, calculationType);

  const calculateStatus = (computedPeriod, target) => {
    if (!computedPeriod || !target) {
      return null;
    }

    const { result } = computedPeriod;
    const resultValue = BigNumber(result.value).times(valueMultiplier);
    const targetValue = BigNumber(target.value);

    // if actual > target [for above], status is green, otherwise red
    let status = resultValue[ontrackComparison](targetValue) ?
      StatusValues.ON_TRACK :
      StatusValues.OFF_TRACK;

    // if target tolerance exists && status is red,
    if (tolerance.isFinite() && status === StatusValues.OFF_TRACK) {
      // If Above, we tolerate values less than the target.
      // If Below, we tolerate values greater than the target.
      const yellowTarget = direction === AboveBelowDirection.ABOVE ?
        targetValue.minus(tolerance) :
        targetValue.add(tolerance);

      // Assumption: the yellow target is has the same target-inclusivity as the green target
      if (resultValue[ontrackComparison](yellowTarget)) {
        status = StatusValues.NEAR_TARGET;
      }
    }

    return status;
  };

  const targetType = targets[0].type;
  const targetsByDate = _.keyBy(targets, 'startDate');

  return computedMeasure.map((computedPeriod) => {
    const target = findTargetForPeriod(computedPeriod, targets, targetType, targetsByDate);
    const status = calculateStatus(computedPeriod, target);
    return _.set(_.cloneDeep(computedPeriod), 'result.status', status);
  });
};

// Internal helper to compute proximity status.
const computeProximityStatus = (measure, computedMeasure, calculationColumns) => {
  const status = _.get(measure, 'metricConfig.status') || {};
  const targets = getValidTargets(measure);
  if (hasOverlappingTargets(measure) || _.isEmpty(targets)) {
    return computedMeasure.map((computedPeriod) => {
      return _.set(_.cloneDeep(computedPeriod), 'result.status', null);
    });
  }

  const green = BigNumber(_.get(status, ['proximity', StatusValues.ON_TRACK]) || 0);
  const yellow = BigNumber(_.get(status, ['proximity', StatusValues.NEAR_TARGET]));
  const hasYellow = yellow.isFinite();

  // Target values are entered and stored in the units the user sees in the card and chart.
  // We decided to store the target values exactly as the user typed them (so changing between
  // percent and non-percent columns doesn't cause unexpected changes to the targets). Unfortunately
  // the data coming back from measure computation is not already pre-multiplied, so we may need to
  // rescale the computation values to suit the target/proximity range values.
  const calculationType = _.get(measure, 'metricConfig.type');
  const valueMultiplier = getPercentScaleMultiplier(calculationColumns, calculationType);

  // Takes a row from computedMeasure and returns its appropriate status,
  // given the measure's config.
  const calculateStatus = (computedPeriod, target) => {
    if (!computedPeriod || !target) {
      return null;
    }

    const { result } = computedPeriod;
    const resultValue = BigNumber(result.value).times(valueMultiplier);
    const targetValue = BigNumber(target.value);

    const targetHigh = targetValue.plus(green);
    const targetLow = targetValue.minus(green);

    const isAboveTarget = resultValue.gt(targetHigh);
    const isBelowTarget = resultValue.lt(targetLow);

    // Apply red/green.
    let computedStatus = (isAboveTarget || isBelowTarget) ? StatusValues.OFF_TRACK : StatusValues.ON_TRACK;

    // Possibly convert red status to yellow.
    if (hasYellow && computedStatus === StatusValues.OFF_TRACK) {
      const yellowHigh = targetValue.plus(green).plus(yellow);
      const yellowLow = targetValue.minus(green).minus(yellow);
      const isAboveYellow = resultValue.gt(yellowHigh);
      const isBelowYellow = resultValue.lt(yellowLow);
      // Not above the highest yellow value, and not below the lowest yellow value.
      // We know we're not green because we took the above branch, so we must be
      // yellow.
      if (!isAboveYellow && !isBelowYellow) {
        computedStatus = StatusValues.NEAR_TARGET;
      }
    }

    return computedStatus;
  };

  const targetType = targets[0].type;
  const targetsByDate = _.keyBy(targets, 'startDate');

  return computedMeasure.map((computedPeriod) => {
    const target = findTargetForPeriod(computedPeriod, targets, targetType, targetsByDate);
    const status = calculateStatus(computedPeriod, target);
    return _.set(_.cloneDeep(computedPeriod), 'result.status', status);
  });
};
