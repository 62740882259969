import _ from 'lodash';
import { StorytellerState } from 'store/StorytellerReduxStore';
import reducer, { LINK_MODAL_SLICE_NAME } from 'store/reducers/LinkModalSlice';

export function getLinkModalEditorId(state: StorytellerState) {
  return _getTypedSlice(state).editorId;
}

export function getLinkModalVisibility(state: StorytellerState) {
  return _getTypedSlice(state).visible;
}

export function getLinkModalAccepted(state: StorytellerState) {
  return _getTypedSlice(state).accepted;
}

export function getLinkModalInputs(state: StorytellerState) {
  return _getTypedSlice(state).inputs;
}

export function getLinkModalValidity(state: StorytellerState) {
  return _getTypedSlice(state).valid;
}

export function getLinkModalURLValidity(state: StorytellerState) {
  return _getTypedSlice(state).urlValidity;
}

export function shouldSelectLink(state: StorytellerState, targetEditorId: string) {
  const { editorId, visible } = _getTypedSlice(state);
  return editorId === targetEditorId && visible;
}

export function shouldInsertLink(state: StorytellerState, targetEditorId: string) {
  const { editorId, accepted } = _getTypedSlice(state);
  return editorId === targetEditorId && accepted;
}

type LinkModalState = ReturnType<typeof reducer>;

function _getTypedSlice(state: StorytellerState) {
  const link = state[LINK_MODAL_SLICE_NAME];
  return link as LinkModalState;
}
