import { IconComponentDelegate } from '@tylertech/forge';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';

const nonDataTypeColumnForgeIcons = {
  // These are not data types but other types we reference in our drop downs
  // maybe we keep these here and just check dataTypeMetadata for the icons if its not one of these
  column: 'view_column',
  parameter: 'variable',
  function: 'function'
};

// this is to be used with the leadBuilder function that a lot of forge components have to add icons
//Example: leadingBuilder: () => getSoqlTypeForgeIconBuilder(ForgeIconTypes.String)
export const getSoqlTypeForgeIconBuilder = (type: string) => {
  const iconName = (() => {
    if (type === 'column' || type === 'parameter' || type === 'function') {
      return nonDataTypeColumnForgeIcons[type];
    } else {
      return getForgeIconNameForDataType(type);
    }
  })();

  const props = { name: iconName };
  // The forge stuff is a bit misleading with its types so doing a cast here to avoid TS complaining
  return (new IconComponentDelegate({ props }).element as unknown as HTMLElement);
};
