import { fetchJsonWithParsedError } from 'common/http';
import { assign as windowLocationAssign, pathname as windowLocationPathname } from 'common/window_location';
import airbrake from 'common/airbrake';

/** Shape of object returned by the session_expiration service */
interface ExpireIfIdleResponse {
  /** Number of seconds left before the session expires */
  seconds?: number;

  /** If this returns the string "expired" it means the session is expired */
  expired?: 'expired';

  /**
   * If this returns with the string "noSession" it means that the user did not have a
   * `_core_session_id` cookie with the request that was sent.
   *
   * This generally means that no user is logged in, and realistically this component will never get it because
   * it only checks the endpoint if we have a "current user" object
   */
  noSession?: 'noSession';
}

/** Calls out to core to get how long the current session has left */
export const getExpireIfIdle = async (): Promise<ExpireIfIdleResponse> => {
  try {
    const response = await fetchJsonWithParsedError('/api/session_expiration/current.json', {
      cache: 'no-store',
      headers: {
        // normally, any call to core will cause a session to be renewed...
        // this header makes it so that this call does _not_ renew the session
        'X-Socrata-Skip-Session-Renewal': 'true',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache'
      }
    });

    return response;
  } catch (error) {
    airbrake.notify({
      error: 'Error getting session expiration for session timeout modal',
      context: {
        component: 'SessionTimeoutModal',
        requestId: error.requestId,
        response: JSON.stringify(error.json)
      }
    });

    // if we got an error, just assume that the session has expired or something
    // (more realistically, this probably means core or something else is down)
    return {
      expired: 'expired'
    };
  }
};

/** Redirect the user to "signed_out" which handles clearing their cookies */
export const redirectToSignedOut = () =>
  windowLocationAssign(`/signed_out?return_to=${encodeURIComponent(windowLocationPathname())}`);

/**
 * Renews the current session by hitting core
 *
 * Note that ANY call to core that sends the `_core_session_id` cookie without the
 * `X-Socrata-Skip-Session-Renewal` header results in a session renewal
 */
export const renewSession = async () =>
  await fetchJsonWithParsedError('/api/session_expiration?method=renew', {
    method: 'POST'
  });
