import React, { FunctionComponent } from 'react';
import isEmpty from 'lodash/isEmpty';

import airbrake from 'common/airbrake';
import type { CoreErrorWithRequestId } from 'common/types/coreError';
import I18n from 'common/i18n';

const classNameScope = 'common--components--AccessManager--access-manager';

interface ErrorsProps {
  /** List of errors to render */
  errors?: CoreErrorWithRequestId[];

  /** "Intro" to render for error list */
  errorIntro?: string | null;
}

/** All error translations live under this scope */
export const errorTranslationScope = 'shared.site_chrome.access_manager.errors';

/**
 * A mapping of core error code to translation key.
 *
 * All of these keys live under `errorTranslationScope`.
 */
const errorTranslationKeys = {
  'PERMISSIONS.CANNOT_SHARE_WITH_SELF': 'cant_share_with_self',
  'PERMISSIONS.USER_CANNOT_CHANGE_PROVENANCE': 'cant_manage_provenance',
  'PERMISSIONS.USER_CANNOT_COLLABORATE': 'user_cannot_collaborate',
  'PERMISSIONS.TEAM_CANNOT_COLLABORATE': 'team_cannot_collaborate',
  'PERMISSIONS.USER_CANNOT_MAKE_ASSET_INTERNAL': 'user_cannot_make_asset_internal',
  'PERMISSIONS.USER_CANNOT_MANAGE_PERMISSIONS': 'user_cannot_manage_permissions',
  'PERMISSIONS.USER_CANNOT_TRANSFER_OWNERSHIP': 'user_cannot_transfer_ownership',
  'PERMISSIONS.ONLY_SITE_MEMBERS_CAN_OWN_ASSETS': 'only_site_members_can_own_assets',
  'USER.DISABLED': 'generic_disabled_user_message' // this can unfortunately happen in a few different scenarios
};

/**
 * Get a translated error message for an error from core.
 *
 * This will look at the error's `code`; not all errors from core have this.
 * If you want to show a specific error message, please give the error in core a code first!
 */
export const getErrorMessage = (error: CoreErrorWithRequestId): string => {
  // this error code is special 😉
  // we want to parse out the email and show that here
  if (error.json?.code === 'PERMISSIONS.USER_NOT_FOUND') {
    // Look for a 4x4 or email address between single quotes. Give preference to an email address
    const userIdOrEmailMatch =
      error.json?.message.match(/email: '([^']+)'/) || error.json?.message.match(/userId: '([^']+)'/);

    if (userIdOrEmailMatch && userIdOrEmailMatch[1] && userIdOrEmailMatch[1] !== 'null') {
      return I18n.t(`${errorTranslationScope}.user_not_found`, {
        userIdOrEmail: `'${userIdOrEmailMatch[1]}'` // Re-Wrap in single quotes for display
      });
    } else {
      // we let airbrake know that we had some trouble parsing the email out...
      // note that we still fall down below to returning the generic "unknown" error message
      airbrake.notify({
        error: `Unable to parse email out of PERMISSIONS.USER_NOT_FOUND error; errorMessage="${error.json?.message}"`,
        context: { component: 'AccessManagerErrors' }
      });
    }
  }

  const translationKey =
    error.json?.code && errorTranslationKeys[error.json?.code as keyof typeof errorTranslationKeys];

  return translationKey
    ? I18n.t(`${errorTranslationScope}.${translationKey}`)
    : I18n.t(`${errorTranslationScope}.unknown`);
};

/** Renders the list of given errors as alerts */
const Errors: FunctionComponent<ErrorsProps> = ({ errors = [], errorIntro }) => {
  if (isEmpty(errors)) {
    return null;
  }

  return (
    <div className="alert error">
      <div className={`${classNameScope}--error-intro`}>{errorIntro}</div>
      {errors.map((e, i) => (
        <div key={i}>
          {getErrorMessage(e)}
          {e.requestId && (
            <div data-testid="error-message-request-id" className={`${classNameScope}--error-request-id`}>
              {I18n.t('shared.site_chrome.access_manager.request_id')}: <code>{e.requestId}</code>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Errors;
