import omit from 'lodash/omit';

import type {
  CatalogInteractiveUser,
  UsersCatalogSearchResult,
  CatalogUserOrTeam
} from 'common/types/users/catalogUsers';
import type AddCollaboratorsActions from '../actions/AddCollaboratorsActionType';
import type PermissionsActions from '../actions/PermissionsActionType';
import type { ViewPermissions, AccessLevel } from 'common/types/view';
import type { AccessManagerAddCollaboratorsState } from '../types';

import { AddCollaboratorsActionsTypes } from 'common/components/AccessManager/actions/AddCollaboratorsActions';
import { PermissionsActionsTypes } from 'common/components/AccessManager/actions/PermissionsActions';
import {
  strictPermissionsEnabled,
  accessLevelsEqual,
  filterSearchResults
} from 'common/components/AccessManager/Util';
import { PUBLISHED_VIEWER_ACCESS_LEVEL } from 'common/components/AccessManager/Constants';
import { MODES } from 'common/components/AccessManager/Constants';

/** User is typing into the search box */
const collaboratorsSearchQueryChanged = (
  state: AccessManagerAddCollaboratorsState,
  query: string
): AccessManagerAddCollaboratorsState => ({
  ...state,
  query,

  // blank out the results if we have no query
  results: null
});

/** Clear the search box */
const collaboratorsSearchClearQuery = (
  state: AccessManagerAddCollaboratorsState,
  query: string
): AccessManagerAddCollaboratorsState => ({
  ...state,
  query: query === '' ? query : '',

  // blank out the results if we have no query
  results: null
});

/** AddUsersSaga will call this with the search results from the catalog */
export const collaboratorsSearchResultsFetchSuccess = (
  state: AccessManagerAddCollaboratorsState,
  results: UsersCatalogSearchResult,
  existingUsers: CatalogUserOrTeam[]
): AccessManagerAddCollaboratorsState => ({
  ...state,
  results: filterSearchResults(results, state.selectedUsers, existingUsers, state.query, state.mode!)
});

/**
 * If the user is a future account, the catalog will return it with an integer id
 * that represents their id in the future_account table...
 *
 * Unfortunately, core only accepts 4x4s as the id of targeted shares.
 * so, we remove the id here (sharing to just their email works fine!)
 *
 * @param {Object} user User to possibly mangle
 */
export const mangleUserIfNecessary = (user: CatalogUserOrTeam): CatalogUserOrTeam => {
  if ((user as CatalogInteractiveUser).future_account === true) {
    return omit(user, 'id') as unknown as CatalogUserOrTeam;
  }

  return user;
};

/** User is selected from the search results */
const addSelectedCollaborator = (
  state: AccessManagerAddCollaboratorsState,
  user: CatalogUserOrTeam
): AccessManagerAddCollaboratorsState => ({
  ...state,
  selectedUsers: [...state.selectedUsers, mangleUserIfNecessary(user)],
  results: null,
  query: ''
});

/** Remove a user that has been selected */
const removeSelectedCollaborator = (
  state: AccessManagerAddCollaboratorsState,
  user: CatalogUserOrTeam
): AccessManagerAddCollaboratorsState => ({
  ...state,
  selectedUsers: state.selectedUsers.filter((u) =>
    user.id
      ? user.id !== u.id
      : (user as CatalogInteractiveUser).email !== (u as CatalogInteractiveUser).email
  )
});

/** Access level dropdown next to the search box has been changed */
const selectedCollaboratorAccessLevelChanged = (
  state: AccessManagerAddCollaboratorsState,
  accessLevel: AccessLevel
): AccessManagerAddCollaboratorsState => ({
  ...state,
  accessLevel
});

/**
 * We want to set access level to the default if:
 * 1. Strict permissions are enabled, or
 * 2. Mode is manage_plugin (since we only have one access level for plugins).
 *
 * See EN-35318
 */
const shouldUseDefaultAccessLevel = (mode: MODES) => {
  return strictPermissionsEnabled() || mode === MODES.MANAGE_PLUGIN;
};

export const fetchPermissionsSuccess = (
  state: AccessManagerAddCollaboratorsState,
  permissions: ViewPermissions,
  mode: MODES
): AccessManagerAddCollaboratorsState => {
  const firstAccessLevelThatsNotPublicViewer = permissions.accessLevels!.find(
    (accessLevel: AccessLevel) => !accessLevelsEqual(accessLevel, PUBLISHED_VIEWER_ACCESS_LEVEL)
  );

  return {
    ...state,
    accessLevel: shouldUseDefaultAccessLevel(mode) ? firstAccessLevelThatsNotPublicViewer : null,
    defaultAccessLevel: firstAccessLevelThatsNotPublicViewer
  };
};

export const resetState = (
  state: AccessManagerAddCollaboratorsState,
  mode: MODES
): AccessManagerAddCollaboratorsState => {
  return {
    ...state,
    selectedUsers: [],
    accessLevel: shouldUseDefaultAccessLevel(mode) ? state.defaultAccessLevel : null,
    query: '',
    results: null
  };
};

const initialState: AccessManagerAddCollaboratorsState = {
  query: '',
  results: null,
  selectedUsers: [],
  accessLevel: null,
  defaultAccessLevel: null
};

const handleValidatedInput = (
  state: AccessManagerAddCollaboratorsState,
  validationErrors: { translationKey: string }[]
): AccessManagerAddCollaboratorsState => ({
  ...state,
  validationErrors
});

export default (
  state = initialState,
  action: AddCollaboratorsActions | PermissionsActions
): AccessManagerAddCollaboratorsState => {
  switch (action.type) {
    // addCollaboratorsActions
    case AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_QUERY_CHANGED:
      return collaboratorsSearchQueryChanged(state, action.payload.query);
    case AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_CLEAR_QUERY:
      return collaboratorsSearchClearQuery(state, action.payload.query);
    case AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_RESULTS_FETCH_SUCCESS:
      return collaboratorsSearchResultsFetchSuccess(
        state,
        action.payload.results,
        action.payload.existingUsers
      );
    case AddCollaboratorsActionsTypes.ADD_SELECTED_COLLABORATOR:
      return addSelectedCollaborator(state, action.payload.user);
    case AddCollaboratorsActionsTypes.REMOVE_SELECTED_COLLABORATOR:
      return removeSelectedCollaborator(state, action.payload.user);
    case AddCollaboratorsActionsTypes.SELECTED_COLLABORATOR_ACCESS_LEVEL_CHANGED:
      return selectedCollaboratorAccessLevelChanged(state, action.payload.accessLevel.value);
    case AddCollaboratorsActionsTypes.VALIDATED_USER_INPUT:
      return handleValidatedInput(state, action.payload.validationErrors);

    case PermissionsActionsTypes.FETCH_PERMISSIONS_SUCCESS:
      return fetchPermissionsSuccess(state, action.payload.permissions, action.payload.mode);
    case PermissionsActionsTypes.ADD_USERS: // Dispatched when the "confirm" button is clicked and the users have been added to the list of users with access
      return resetState(state, action.payload.mode);
    default:
      return state;
  }
};
