export const ACCESS_LEVELS = {
  CONTRIBUTOR: 'contributor',
  CURRENT_OWNER: 'current_owner',
  OWNER: 'owner',
  VIEWER: 'viewer'
};

export const ACCESS_LEVEL_VERSIONS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  ALL: 'all'
};

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const USER_TYPES = {
  INTERACTIVE: 'interactive',
  TEAM: 'team',
  USER: 'user'
};
