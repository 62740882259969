import _ from 'lodash';

// if event contains an <a> tag that leads to external site
const shouldShowGoodbye = (eventTarget: EventTarget | null) => {
  return !_.get(eventTarget, 'href', '').includes(window.location.origin);
};

const showGoodbye = (warningText: string, eventTarget: EventTarget | null) => {
  const externalLink: URL = new URL(_.get(eventTarget, 'href', ''));
  if (externalLink.origin.includes('.gov')) {
    open(externalLink.toString());
  } else {
    confirm(warningText) && open(externalLink.toString());
  }
};

export const triggerExternalLinkDisclaimer = () => {
  const warningText = window.socrata.externalLinkDisclaimer;
  const hasAncestralAnchor = (elem: any): EventTarget | false => {
    if (elem === null) return false;
    if (elem.tagName.toLowerCase() === 'a') return elem;
    return hasAncestralAnchor(elem.parentElement);
  };

  document.addEventListener('click', function(event) {
    const anchorElem = hasAncestralAnchor(event.target);
    if (warningText && anchorElem && shouldShowGoodbye(anchorElem)) {
      event.preventDefault();
      showGoodbye(warningText, anchorElem);
    }
  });
};
