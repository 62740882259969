import $ from 'jquery';
import _ from 'lodash';
import { assert, assertEqual, assertInstanceOfAny, assertIsOneOfTypes } from 'common/assertions';
import { formatString } from 'common/js_utils/formatString.js';
import addToWindow from 'common/js_utils/addToWindow';

import Environment from 'StorytellerEnvironment';
import httpRequest from 'services/httpRequest.js';

const utils = {
  export: addToWindow,
  format: (string: string | undefined, ...args: any[]): string => {
    if (_.isString(string)) {
      return formatString.apply(string, args);
    } else {
      return '';
    }
  },

  // Expands a dot-delimited component type to itself and any parent types recursively.
  // Ex: 'a.b.c.d' => [ 'a', 'a.b', 'a.b.c', 'a.b.c.d' ]
  componentTypeAndParents: (type: string): string[] => {
    assertIsOneOfTypes(type, 'string');

    const accum: string[] = [];
    return type.split('.').map((subtype) => {
      accum.push(subtype);
      return accum.join('.');
    });
  },

  // Returns true if the given component type is an instance of the desired type (including parent types).
  // Ex: componentInstanceOf('html', 'html') # true
  // Ex: componentInstanceOf('html.tableOfContents', 'html') # true
  // Ex: componentInstanceOf('html', 'html.tableOfContents') # false
  componentInstanceOf: (typeToCheck: string, desiredType: string): boolean => {
    return utils.componentTypeAndParents(typeToCheck).indexOf(desiredType) >= 0;
  },

  /**
   * @function typeToClassesForComponentType
   * @description
   * Turns component type into one or more css class names. Any subtypes (dot-delimited) are expanded recursively.
   * IMPORTANT: Needs to mirror the Ruby method type_to_classes_for_component_type
   * Ex: a.b.c.d => component-a component-a-b component-a-b-c component-a-b-c-d
   * Ex: socrata.visualization.columnChart => component-socrata component-socrata-visualization component-socrata-visualization-column-chart
   * @param {String} type - A dot-delimited Storyteller component type.
   * @returns {String} - the list of classes.
   */
  typeToClassesForComponentType: (type: string): string => {
    assertIsOneOfTypes(type, 'string');

    // Duplicates enough of ActiveSupport::Inflector.underscore to work for our purposes.
    const underscore = (word: string) => {
      word = word.replace(/([A-Z\d]+)([A-Z][a-z])/g, '$1_$2');
      word = word.replace(/([a-z\d])([A-Z])/g, '$1_$2');
      word = word.replace(/-/g, '_');
      return word.toLowerCase();
    };

    // Duplicates ActiveSupport::Inflector.dasherize (exactly).
    const dasherize = (word: string) => {
      return word.replace(/_/g, '-');
    };

    return utils
      .componentTypeAndParents(type)
      .map(underscore)
      .map(dasherize)
      .map((subtype) => `component-${subtype.replace(/\./g, '-')}`)
      .join(' ');
  },

  /**
   * @function queryParameters
   * @description
   * Build URL query parameters into a consumable data structure.
   * This function does not combine array-style parameters:
   *
   *   // for the URL http://example.com/?foo[]=bar&foo[]=baz
   *   > queryParameters()
   *   < [["foo[]", "bar"], ["foo[]", "baz"]]
   *
   * @returns {Array} - A list of key-value pairs: [['key', 'value'], ...]
   */
  queryParameters: (): string[][] => {
    const search =
      window.location.search && window.location.search.length !== 0 && window.location.search.substring(1);

    if (search) {
      const parameters = search.split('&');

      return parameters.map((parameter) => {
        return parameter.split('=');
      });
    } else {
      return [];
    }
  },

  /**
   * Returns true if the given named parameter matches the given value.
   * If provided, the value will be stringified for comparison.
   * If no value is provided, this function only checks for the key's existence.
   *
   *   Example URL: https://example.com/?test=true&other=false
   *
   *   True examples:
   *   queryParameterMatches('test')
   *   queryParameterMatches('other')
   *   queryParameterMatches('test', true)
   *   queryParameterMatches('other', false)
   *
   *   False examples:
   *   queryParameterMatches('georgina')
   *   queryParameterMatches('test', 'pass')
   *   queryParameterMatches('other', true)
   *
   */
  queryParameterMatches: (paramKey: string, paramValue: string | undefined): boolean => {
    return utils.queryParameters().some((param) => {
      const [key, value] = param;
      return key === `${paramKey}` && (_.isUndefined(paramValue) || value === `${paramValue}`);
    });
  },

  mapDOMFragmentDescending: (
    element: Node,
    applyFn: (el: Node) => Node,
    shouldTerminateFn: (el: Node) => boolean
  ): Node => {
    const clonedElement = applyFn(element.cloneNode());

    if (!shouldTerminateFn(element)) {
      const childCount = element.childNodes.length;

      for (let i = 0; i < childCount; i++) {
        clonedElement.appendChild(
          utils.mapDOMFragmentDescending(element.childNodes[i], applyFn, shouldTerminateFn)
        );
      }
    }

    return clonedElement;
  },

  /**
   * Binds a set of events, whether delegated or direct, on a single node.
   * Events are specified as a mapping of event types to handlers, e.g.
   *
   * {
   *   'keyup': [
   *     ['.custom-url-input', validateForm]
   *   ],
   *   'modal-dismissed': [
   *     [handleModalDismissed]
   *   ]
   * }
   *
   * Handlers are specified as an array of arrays (which allows an event to be
   * handled in multiple ways); the inner array consists of an optional selector
   * (for delegated events) and the handling function.
   *
   * In conjunction with an unbinding counterpart (below), consolidating handlers
   * into a data structure provides us with a more explicit guarantee of behavior.
   */
  bindEvents: (
    node: JQuery<HTMLElement>,
    eventHandlers: { [eventName: string]: (string | any)[][] }
  ): void => {
    const $node = $(node);
    _.each(eventHandlers, (handlers, eventName) => {
      _.each(handlers, (handlerArray) => {
        const handler = handlerArray.pop();
        const delegate = handlerArray.pop();
        $node.on(eventName, delegate, handler);
      });
    });
  },

  /**
   * Unbinds a set of events, whether delegated or direct, on a single ancestor node.
   * See previous method for more details.
   */
  unbindEvents: (
    node: JQuery<HTMLElement>,
    eventHandlers: { [eventName: string]: (string | any)[][] }
  ): void => {
    const $node = $(node);
    _.each(eventHandlers, (handlers, eventName) => {
      _.each(handlers, (handlerArray) => {
        const handler = handlerArray.pop();
        const delegate = handlerArray.pop();
        $node.off(eventName, delegate, handler);
      });
    });
  },

  /**
   * Prevents scrolling from bubbling up to the document
   * Ex: element.on('mousewheel', '.scrollable', Util.preventScrolling)
   */
  preventScrolling: function preventScrolling(e: JQuery.TriggeredEvent): void {
    const target = $(this);
    const scrollTop = target.scrollTop();

    const delta = (<WheelEvent>e.originalEvent).deltaY;
    if (delta < 0) {
      // Scrolling up.
      if (scrollTop === 0) {
        // Past top.
        e.preventDefault();
      }
    } else if (delta > 0) {
      // Scrolling down.
      const innerHeight = target.innerHeight();
      const scrollHeight = target[0].scrollHeight;

      if (typeof scrollTop !== 'undefined' && typeof innerHeight !== 'undefined') {
        if (scrollTop >= scrollHeight - innerHeight) {
          // Past bottom.
          e.preventDefault();
        }
      }
    }
  },

  reduceDOMFragmentAscending: <AccType>(
    element: Node,
    applyFn: (el: Node, acc: AccType) => void,
    shouldTerminateFn: (el: Node) => boolean,
    accumulator: AccType
  ): AccType => {
    if (!shouldTerminateFn(element)) {
      if (element.parentNode !== null) {
        utils.reduceDOMFragmentAscending(element.parentNode, applyFn, shouldTerminateFn, accumulator);
      }
    }

    applyFn(element, accumulator);

    return accumulator;
  },

  reduceDOMFragmentDescending: <AccType>(
    element: Node,
    applyFn: (el: Node, acc: AccType) => void,
    shouldTerminateFn: (el: Node) => boolean,
    accumulator: AccType
  ): AccType => {
    applyFn(element, accumulator);

    if (!shouldTerminateFn(element)) {
      const childCount = element.childNodes.length;

      for (let i = 0; i < childCount; i++) {
        utils.reduceDOMFragmentDescending(element.childNodes[i], applyFn, shouldTerminateFn, accumulator);
      }
    }

    return accumulator;
  },

  generateStoryTileIframeSrc: (storyDomain: string, storyUid: string): string => {
    assertIsOneOfTypes(storyDomain, 'string');
    assertIsOneOfTypes(storyUid, 'string');
    assertEqual(storyDomain.match(/[^a-z0-9\.\-]/gi), null);
    assert(
      storyUid.match(/^\w{4}\-\w{4}$/) !== null,
      '`storyUid` does not match anchored four-by-four pattern'
    );

    return `https://${storyDomain}/stories/s/${storyUid}/tile`;
  },

  generateStoryTileJsonSrc: (storyDomain: string, storyUid: string): string => {
    assertIsOneOfTypes(storyDomain, 'string');
    assertIsOneOfTypes(storyUid, 'string');
    assertEqual(storyDomain.match(/[^a-z0-9\.\-]/gi), null);
    assert(
      storyUid.match(/^\w{4}\-\w{4}$/) !== null,
      '`storyUid` does not match anchored four-by-four pattern'
    );

    return `https://${storyDomain}/stories/s/${storyUid}/tile.json`;
  },

  generateYoutubeUrl: (youtubeId: string): string => {
    assertIsOneOfTypes(youtubeId, 'string');

    return `https://www.youtube.com/embed/${youtubeId}`;
  },

  generateYoutubeIframeSrc: (youtubeId: string, autoplay: boolean): string => {
    assertIsOneOfTypes(youtubeId, 'string');

    let src = `https://www.youtube.com/embed/${youtubeId}?rel=0&showinfo=0`;

    if (autoplay) {
      src += '&autoplay=true';
    }

    return src;
  },

  /**
   * Given a DOM element, returns a string summarizing this node and its parents.
   * Useful for debugging.
   *
   * @param {HTMLElement | jQuery} subject = The element to inspect.
   * @return {String}
   */
  inspectNode: (subject: HTMLElement | JQuery<HTMLElement>): string => {
    const toPrettyString = (element: HTMLElement | JQuery<HTMLElement>) => {
      element = $(element);
      const tagName = element.prop('tagName');

      if (!tagName) {
        return '<not a dom node>';
      }

      const id = element.prop('id');
      const className = element.prop('class');

      return _.compact([
        `<${tagName}`.toLowerCase(),
        id ? `id="${id}"` : null,
        className ? `class="${className}"` : null,
        '/>'
      ]).join(' ');
    };

    const parents = _.map($(subject).parents(), toPrettyString).join(' ');

    return toPrettyString(subject) + (parents.length ? ` parents: ${parents}` : '');
  },

  /**
   * When given a DOM element, this function traverses the parent
   * hierarchy to find both the blockId and componentIndex.
   *
   * This function will throw if it cannot find either a blockId
   * or componentIndex.
   *
   * @param {HTMLElement | jQuery} element = The starting point of the search.
   * @return {Object} - contains a blockId and componentIndex.
   */
  findBlockIdAndComponentIndex: (
    element: HTMLElement | JQuery<HTMLElement>
  ): {
    blockId: string;
    componentIndex: number;
  } => {
    const blockId = utils.findClosestAttribute(element, 'data-block-id');
    const componentIndex = parseInt(utils.findClosestAttribute(element, 'data-component-index') || '', 10);

    assert(
      _.isString(blockId),
      `Failed to find attribute data-block-id in the ancestors of: ${utils.inspectNode(element)}`
    );

    assert(
      _.isFinite(componentIndex),
      // eslint-disable-next-line max-len
      `Failed to find an integer-valued data-component-index attribute in the ancestors of: ${utils.inspectNode(
        element
      )}`
    );

    return { blockId: blockId as string, componentIndex };
  },

  /**
   * Walks up the DOM looking for elements with the given attribute.
   * When it finds one, returns the value of the given attribute.
   *
   * @param {HTMLElement | jQuery} element - The starting point of the search.
   * @param {string} attribute - The name of the attribute to search for.
   *
   * @return {string | undefined} - The value of the found attribute, or undefined if not found.
   */
  findClosestAttribute: (
    element: HTMLElement | JQuery<HTMLElement>,
    attribute: string
  ): string | undefined => {
    assertInstanceOfAny(element, $, HTMLElement);
    assertIsOneOfTypes(attribute, 'string');

    return $(element).closest(`[${attribute}]`).attr(attribute);
  },

  /**
   * @function ellipsifyText
   * @description
   * Truncates a string and appends an ellipsis such that when rendered in its
   * container element the number of lines of text is less than or equal to the
   * argument `lineCount`.
   *
   * @param {Object} $element - a jQuery-wrapped DOM element.
   * @param {Number} lineCount - an integer specifying the maximum number of
   * lines of text to render before truncating the string and appending an
   * ellipsis.
   *
   * @return {Undefined} - this method is side-effecty.
   */
  ellipsifyText: ($element: JQuery<HTMLElement>, lineCount: number): void => {
    const elementHeight = $element.height();
    const lineHeight = Math.ceil(parseFloat($element.css('line-height')));
    const targetElementHeight = lineHeight * lineCount;
    let words;
    let truncatedWords;

    assert(Math.floor(lineCount) === lineCount, '`lineCount` must be an integer');

    if (typeof elementHeight !== 'undefined' && elementHeight > targetElementHeight) {
      words = $element.text().split(' ');

      if (words[words.length - 1] === '…') {
        truncatedWords = words.slice(0, -2);
      } else {
        truncatedWords = words.slice(0, -1);
      }

      $element.text(`${truncatedWords.join(' ')}…`);

      if (truncatedWords.length > 0) {
        utils.ellipsifyText($element, lineCount);
      }
    }
  },

  formatValueWithoutRounding: (value: number): string => {
    const valueIsNegative = value < 0;
    const absValue = Math.abs(value);
    let valueInteger;
    let valueFraction;
    let valueUnit;

    function deriveValueFraction(val: number, digits: number) {
      const fraction = val.toString().split('.')[0];

      return fraction.substring(fraction.length - digits);
    }

    if (!_.isNumber(value)) {
      return value;
    }

    valueInteger = Math.floor(absValue);

    if (valueInteger < 1e3) {
      valueFraction = absValue.toString().split('.')[1] || '';
      valueUnit = '';
    } else if (valueInteger < 1e6) {
      valueInteger = Math.floor(absValue / 1e3);
      valueFraction = deriveValueFraction(absValue, 3);
      valueUnit = 'K';
    } else if (valueInteger < 1e9) {
      valueInteger = Math.floor(absValue / 1e6);
      valueFraction = deriveValueFraction(absValue, 6);
      valueUnit = 'M';
    } else if (valueInteger < 1e12) {
      valueInteger = Math.floor(absValue / 1e9);
      valueFraction = deriveValueFraction(absValue, 9);
      valueUnit = 'B';
    } else {
      valueInteger = Math.floor(absValue / 1e12);
      valueFraction = deriveValueFraction(absValue, 12);
      valueUnit = 'T';
    }

    const valueFractionHundredths = valueFraction.length > 1 ? parseInt(valueFraction.charAt(1), 10) : 0;

    if (valueFractionHundredths >= 5) {
      valueFraction = Math.min(9, parseInt(valueFraction.charAt(0), 10) + 1).toString();
    } else {
      valueFraction = valueFraction.charAt(0) === '0' ? '' : valueFraction.charAt(0);
    }

    return (
      (valueIsNegative ? '-' : '') +
      valueInteger.toLocaleString() +
      (valueFraction.length > 0 ? `.${valueFraction}` : '') +
      valueUnit
    );
  },

  // Prevent form autosubmission on <enter> key.
  // All our forms that actually use the default
  // browser form submission have an action attribute.
  preventFormAutoSubmit: (): void => {
    $(document.body).on('submit', 'form:not([action])', _.constant(false));
  },

  getCoreView: async (): Promise<any> => {
    const { STORY_UID } = Environment;

    return (await httpRequest('GET', `/api/views/${STORY_UID}`, { credentials: 'same-origin' })).data;
  },

  // Add a layout-* and a theme-* class to the element, removing any stale such classes.
  // Unrelated classes are preserved.
  // The optional callback onChangeApplied is called if the class needed an update.
  applyThemeAndLayoutClass(
    element: HTMLElement,
    theme: string,
    layout: string,
    onChangeApplied = _.noop
  ): void {
    const desiredClasses = _.uniq([
      ..._.reject(element.classList, (className) => /^(theme|layout)-/.test(className)),
      `theme-${theme}`,
      `layout-${layout}`
    ]);

    // If the set of desired and currently-applied classes is different, update.
    if (_.xor(desiredClasses, element.classList).length > 0) {
      element.setAttribute('class', desiredClasses.join(' '));
      onChangeApplied();
    }
  },

  applyGlobalFilterClass(
    element: HTMLElement,
    globalFilterDisabled: boolean,
    onChangeApplied = _.noop
  ): void {
    const disabledClass = globalFilterDisabled ? 'disabled' : '';
    const desiredClasses = _.uniq([
      ..._.reject(element.classList, (className) => className === 'disabled'),
      `${disabledClass}`
    ]);

    if (_.xor(desiredClasses, element.classList).length > 0) {
      element.setAttribute('class', desiredClasses.join(' '));
      onChangeApplied();
    }
  },

  templateFlyout: (className: string, content: string): JQuery => {
    return $(`<div class="${className} flyout flyout-hidden flyout-right flyout-block-label">`).append([
      $('<section class="flyout-content">').append(content)
    ]);
  },

  setBackgroundColor(element: HTMLElement | JQuery<HTMLElement>, color: string): void {
    const $block = $(element);
    $block.css('background-color', color);
  }
};

addToWindow(utils, 'socrata.utils');
export default utils;
