import _ from 'lodash';
import I18n from 'common/i18n';

const scope = 'shared.visualizations.charts.common';

export type DisplayStyle = 'default' | 'number' | 'yesno' | 'checkmark';

type FormatMap = {
  [P in DisplayStyle]: {
    'true': string | number;
    'false': string | number;
  };
};

export interface FormatOption {
  title: string;
  value: DisplayStyle;
  order: number;
}

// for tests - we load translations in a beforeEach hook, which is called
// after this evaluates if declared as a plain object
function getFormatMap(): FormatMap {
  return ({
    'number': {
      'true': 1,
      'false': 0
    },
    'yesno': {
      'true': I18n.t('bool_yes', { scope }),
      'false': I18n.t('bool_no', { scope })
    },
    'checkmark': {
      'true': '✔',
      'false': '✘'
    },
    'default': {
      'true': I18n.t('true_value', { scope }),
      'false': I18n.t('false_value', { scope })
    }
  });
}

export function formatForDisplay(style: DisplayStyle = 'default', val?: boolean): string {
  const formatMap = getFormatMap();

  if (val == undefined) {
    return '';
  }

  return _.get(formatMap, `${style}.${val.toString()}`, '');
}

export const formatOptions: FormatOption[] = _.sortBy((Object.keys(getFormatMap()) as DisplayStyle[]).map((k: DisplayStyle, idx: number) => ({
  title: I18n.t(k, { scope }),
  value: k,
  order: k === 'default' ? 0 : idx + 1
})), ['order']);
