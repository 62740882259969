import React, { FunctionComponent, useEffect, useRef } from 'react';
import { ForgeIcon, ForgeIconButton, ForgeAppBarSearch } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { regexMatchPatterns } from 'common/utilities/Constants';
import optionallyLocalizeUrls from '../../utils/optionally_localize_urls';
import './index.scss';

interface MobileSearchDrawerProps {
  open: boolean;
  closeDialogCallback: () => void;
}

const MobileSearchDrawer: FunctionComponent<MobileSearchDrawerProps> = ({ open, closeDialogCallback }) => {
  const scope = 'shared.site_chrome.header';
  const forgeAppBarSearchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const appBarMobileSearch = forgeAppBarSearchRef.current;
    if (appBarMobileSearch) {
      appBarMobileSearch.addEventListener('forge-app-bar-search-input', (evt: Event) => {
        const event = evt as CustomEvent;
        const searchTerm = event.detail.value;
        if (regexMatchPatterns.FOUR_BY_FOUR_PATTERN.test(searchTerm.trim())) {
          window.open(
            optionallyLocalizeUrls(`/admin/assets?ids=${searchTerm.trim()}&tab=allAssets`),
            '_self'
          );
        } else {
          window.open(optionallyLocalizeUrls(`/admin/assets?q=${searchTerm.trim()}&tab=allAssets`), '_self');
        }
      });
    }
  });

  return open ? (
    <div className="omnibar-mobile-search-drawer">
      <ForgeIconButton slot="action">
        <button
          type="button"
          aria-label="View user profile"
          className="tyler-icons"
          onClick={() => closeDialogCallback()}
        >
          <ForgeIcon name="arrow_back" />
        </button>
      </ForgeIconButton>
      <ForgeAppBarSearch ref={forgeAppBarSearchRef}>
        <input type="text" aria-label={I18n.t(`${scope}.search`)} placeholder={I18n.t(`${scope}.search`)} />
      </ForgeAppBarSearch>
    </div>
  ) : null;
};

export default MobileSearchDrawer;
