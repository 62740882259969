// Vendor Imports
import _ from 'lodash';
import moment from 'moment';

// Project Imports
import { dataProviders } from 'common/visualizations';
import { VECTOR_BASEMAP_STYLES, VIF_CONSTANTS, BASE_LAYERS } from './constants';
import { load } from './vifs/loader';
import {
  getColorPalette,
  getColorPaletteGroupingColumnName,
  getCustomColorPaletteForNewGLMaps,
  getCurrentSeriesIndex,
  getDimension,
  getEmptyVif,
  getMapLayerName,
  getPrimaryMapLayerSeriesIndex,
  getSeries,
  getVifs,
  getColorByQuantificationMethod,
  isMap,
  isNewGLMap,
  isPieChart
} from './selectors/vifAuthoring';

export const SET_VIF_CHECKPOINT = 'SET_VIF_CHECKPOINT';
export function setVifCheckpoint(vifs) {
  return {
    type: SET_VIF_CHECKPOINT,
    vifs
  };
}

export function setDataSource(domain, datasetUid, filters = null, useCache = true, seriesIndex = 0) {
  return (dispatch, getState) => {
    if (!/\w{4}\-\w{4}/.test(datasetUid)) {
      return;
    }

    const datasetMetadataProvider = new dataProviders.MetadataProvider({ domain, datasetUid }, useCache);
    const isUpdating = !useCache;

    const finishMetadataRequests = (datasetMetadata, baseViewMetadata, hasColumnStats) => {
      const { vifAuthoring } = getState();
      const options = { domain, datasetUid, seriesIndex };
      dispatch(receiveMetadata(datasetMetadata, baseViewMetadata, hasColumnStats, options));
      dispatch(setVifCheckpoint(getVifs(vifAuthoring)));

      // We introduce name key for visualization_interchange_format 3.
      // which is not present in visualization_interchange_format 2.
      // existing saved VIF are in visualization_interchange_format 2.
      // when we edit the old VIF, it will become to format 3.
      // so the name key should not be there, since the VIF format was 2,
      // for that we set the name,source key and value.
      if (getMapLayerName(vifAuthoring) === VIF_CONSTANTS.DEFAULT_MAP_LAYER_NAME) {
        dispatch(setMapLayerName(_.get(datasetMetadata, 'name')));
      }

      const primaryMapLayerIndex = getPrimaryMapLayerSeriesIndex(vifAuthoring);

      if (isNewGLMap(vifAuthoring) && seriesIndex !== primaryMapLayerIndex) {
        // For NewGLMaps to support multi layers we need to auto populate the dimension with
        // geo location column on mapLayersPane load

        const dimension = getDimension(vifAuthoring);

        if (_.isNull(dimension.columnName)) {
          dispatch(setTriggerAutoSelectGeoLocationColumn(true));
        }
      }
    };

    dispatch(requestMetadata(domain, datasetUid, isUpdating, seriesIndex));
    // On switching to a different dataset, update the table but not other chart types.
    // Other charts currently work with only the dataset from which the visualization was
    // created(primary dataset).
    dispatch(setDatasetUid(datasetUid, 'table'));
    dispatch(setDomain(domain, 'table'));
    if (filters !== null) {
      dispatch(setFilters(filters, 'table'));
    }

    return Promise.all([
      // See comment in implementation to learn why we catch-all.
      datasetMetadataProvider.getBaseViewMetadata().catch(() => null),
      datasetMetadataProvider.getDatasetMetadata()
    ]).then((resolutions) => {
      const datasetMetadata = resolutions[1];
      const baseViewMetadata = resolutions[0] || datasetMetadata;

      finishMetadataRequests(datasetMetadata, baseViewMetadata, true);
    }).catch((error) => {
      console.error(error);
      dispatch(handleMetadataError(error, seriesIndex));
    });
  };
}

export const REQUEST_METADATA = 'REQUEST_METADATA';
export function requestMetadata(domain, datasetUid, updating = false, seriesIndex = 0) {
  return {
    type: REQUEST_METADATA,
    domain,
    datasetUid,
    updating,
    seriesIndex
  };
}

export const RECEIVE_METADATA = 'RECEIVE_METADATA';
export function receiveMetadata(
  datasetMetadata,
  baseViewMetadata,
  hasColumnStats,
  requestDetails
) {
  return {
    type: RECEIVE_METADATA,
    datasetMetadata,
    baseViewMetadata,
    hasColumnStats,
    requestDetails
  };
}

export const HANDLE_METADATA_ERROR = 'HANDLE_METADATA_ERROR';
export function handleMetadataError(error, seriesIndex) {
  return {
    type: HANDLE_METADATA_ERROR,
    error,
    seriesIndex
  };
}

export function setCuratedRegions(domain, datasetUid, seriesIndex = 0) {
  return (dispatch) => {
    if (!/\w{4}\-\w{4}/.test(datasetUid)) {
      return;
    }

    const datasetMetadataProvider = new dataProviders.MetadataProvider({ domain, datasetUid }, true);

    dispatch(requestCuratedRegions(seriesIndex));

    return datasetMetadataProvider.getCuratedRegions().then((curatedRegions) => {
      dispatch(receiveCuratedRegions(curatedRegions, seriesIndex));
    }).catch((error) => {
      console.error(error);
      dispatch(handleCuratedRegionsError(seriesIndex));
    });
  };
}

export function setComputedRegionColumns(domain, datasetUid, seriesIndex = 0) {
  return (dispatch) => {
    if (!/\w{4}\-\w{4}/.test(datasetUid)) {
      return;
    }

    const datasetMetadataProvider = new dataProviders.MetadataProvider({ domain, datasetUid }, true);

    dispatch(requestComputedRegionColumns(seriesIndex));

    return datasetMetadataProvider.getComputedRegionColumns().then((computedRegionColumns) => {
      dispatch(receiveComputedRegionColumns(computedRegionColumns, seriesIndex));
    }).catch((error) => {
      console.error(error);
      dispatch(handleComputedRegionColumnsError(seriesIndex));
    });
  };
}

export const REQUEST_CURATED_REGIONS = 'REQUEST_CURATED_REGIONS';
export function requestCuratedRegions(seriesIndex) {
  return {
    type: REQUEST_CURATED_REGIONS,
    seriesIndex
  };
}

export const RECEIVE_CURATED_REGIONS = 'RECEIVE_CURATED_REGIONS';
export function receiveCuratedRegions(curatedRegions, seriesIndex) {
  return {
    type: RECEIVE_CURATED_REGIONS,
    curatedRegions,
    seriesIndex
  };
}

export const HANDLE_CURATED_REGIONS_ERROR = 'HANDLE_CURATED_REGIONS_ERROR';
export function handleCuratedRegionsError(seriesIndex) {
  return {
    type: HANDLE_CURATED_REGIONS_ERROR,
    seriesIndex
  };
}

export const REQUEST_COMPUTED_REGION_COLUMNS = 'REQUEST_COMPUTED_REGION_COLUMNS';
export function requestComputedRegionColumns(seriesIndex) {
  return {
    type: REQUEST_COMPUTED_REGION_COLUMNS,
    seriesIndex
  };
}

export const RECEIVE_COMPUTED_REGION_COLUMNS = 'RECEIVE_COMPUTED_REGION_COLUMNS';
export function receiveComputedRegionColumns(computedRegionColumns, seriesIndex) {
  return {
    type: RECEIVE_COMPUTED_REGION_COLUMNS,
    computedRegionColumns,
    seriesIndex
  };
}

export const HANDLE_COMPUTED_REGION_COLUMNS_ERROR = 'HANDLE_COMPUTED_REGION_COLUMNS_ERROR';
export function handleComputedRegionColumnsError(seriesIndex) {
  return {
    type: HANDLE_COMPUTED_REGION_COLUMNS_ERROR,
    seriesIndex
  };
}

export const SET_DATASET_UID = 'SET_DATASET_UID';
export function setDatasetUid(datasetUid, targetVisualizationType, forceUpdate = false) {
  return {
    type: SET_DATASET_UID,
    datasetUid,
    targetVisualizationType,
    forceUpdate
  };
}

export const SET_DOMAIN = 'SET_DOMAIN';
export function setDomain(domain, targetVisualizationType) {
  return {
    type: SET_DOMAIN,
    domain,
    targetVisualizationType
  };
}

export const SET_MAP_LAYER_NAME = 'SET_MAP_LAYER_NAME';
export function setMapLayerName(name) {
  return {
    type: SET_MAP_LAYER_NAME,
    name
  };
}

export const SET_MAP_LAYER_SOURCE = 'SET_MAP_LAYER_SOURCE';
export function setMapLayerSource(source) {
  return {
    type: SET_MAP_LAYER_SOURCE,
    source
  };
}

export const SET_MAP_LAYER_VISIBLE = 'SET_MAP_LAYER_VISIBLE';
export function setMapLayerVisible(relativeIndex, isVisible) {
  return {
    type: SET_MAP_LAYER_VISIBLE,
    relativeIndex,
    isVisible
  };
}

export const SET_SIMPLIFICATION_LEVEL = 'SET_SIMPLIFICATION_LEVEL';
export function setSimplificationLevel(relativeIndex, simplificationLevel) {
  return {
    type: SET_SIMPLIFICATION_LEVEL,
    relativeIndex,
    simplificationLevel
  };
}

export const SET_CHARM_NAME = 'SET_CHARM_NAME';
export function setCharmName(relativeIndex, charmName) {
  return {
    type: SET_CHARM_NAME,
    relativeIndex,
    charmName
  };
}

export const SET_FILTERS = 'SET_FILTERS';
export function setFilters(filters, targetVisualizationType) {
  return {
    type: SET_FILTERS,
    filters,
    targetVisualizationType
  };
}

export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS';
export function setTableColumns(tableColumns) {
  return {
    type: SET_TABLE_COLUMNS,
    tableColumns
  };
}

export const SET_TABLE_HIERARCHIES = 'SET_TABLE_HIERARCHIES';
export function setTableHierarchies(tableHierarchies) {
  return {
    type: SET_TABLE_HIERARCHIES,
    tableHierarchies
  };
}

export const SET_COLUMN_FORMAT = 'SET_COLUMN_FORMAT';
export function setColumnFormat(columnFormat) {
  return {
    type: SET_COLUMN_FORMAT,
    columnFormat
  };
}

export const SET_TABLE_VIZ_COLUMN_FORMATS = 'SET_TABLE_VIZ_COLUMN_FORMATS';
export function setTableVisualizationColumnFormats(columnFormats) {
  return {
    type: SET_TABLE_VIZ_COLUMN_FORMATS,
    columnFormats
  };
}

export const SET_ROW_FORMAT = 'SET_ROW_FORMAT';
export function setRowFormat(rowFormat) {
  return {
    type: SET_ROW_FORMAT,
    rowFormat
  };
}

export const SET_DIMENSION = 'SET_DIMENSION';
export function setDimension(dimension) {
  return {
    type: SET_DIMENSION,
    dimension
  };
}

export const SET_CURRENT_DRILL_DOWN_COLUMN_NAME = 'SET_CURRENT_DRILL_DOWN_COLUMN_NAME';
export function setCurrentDrilldownColumnName(currentDrilldownColumnName) {
  return {
    type: SET_CURRENT_DRILL_DOWN_COLUMN_NAME,
    currentDrilldownColumnName
  };
}

export const SET_DRILL_DOWNS = 'SET_DRILL_DOWNS';
export function setDrilldowns(drilldowns) {
  return {
    type: SET_DRILL_DOWNS,
    drilldowns
  };
}

export const APPEND_SERIES = 'APPEND_SERIES';
export function appendSeries({
    isFlyoutSeries,
    isInitialLoad,
    isMapSeries = false,
    measureAggregationFunction,
    measureColumnName,
    rowDisplayUnit,
    seriesVariant
  }) {

  return {
    type: APPEND_SERIES,
    isFlyoutSeries,
    isInitialLoad,
    isMapSeries,
    measureAggregationFunction,
    measureColumnName,
    rowDisplayUnit,
    seriesVariant
  };
}

export const REMOVE_SERIES = 'REMOVE_SERIES';
export function removeSeries({ isFlyoutSeries, relativeIndex, isMapSeries = false }) {
  return {
    type: REMOVE_SERIES,
    isFlyoutSeries,
    relativeIndex,
    isMapSeries
  };
}

export const REMOVE_METADATA_SERIES = 'REMOVE_METADATA_SERIES';
export function removeMetadataSeries(seriesIndex) {
  return {
    type: REMOVE_METADATA_SERIES,
    seriesIndex
  };
}

export const SWAP_SERIES = 'SWAP_SERIES';
export function swapSeries(fromIndex, toIndex) {
  return {
    type: SWAP_SERIES,
    fromIndex,
    toIndex
  };
}

export const SWAP_COLOR_PALETTE = 'SWAP_COLOR_PALETTE';
export function swapColorPalette(columnName, fromIndex, toIndex) {
  return {
    type: SWAP_COLOR_PALETTE,
    columnName,
    fromIndex,
    toIndex
  };
}

export const SWAP_COLOR_PALETTE_WITH_QUANTIFICATION = 'SWAP_COLOR_PALETTE_WITH_QUANTIFICATION';
export function swapColorPaletteWithQuantification(columnName, fromIndex, toIndex, quantificationMethod) {
  return {
    type: SWAP_COLOR_PALETTE_WITH_QUANTIFICATION,
    columnName,
    fromIndex,
    toIndex,
    quantificationMethod
  };
}

export const SWAP_METADATA_COLLECTION_SERIES = 'SWAP_METADATA_COLLECTION_SERIES';
export function swapMetaDataCollectionSeries(fromIndex, toIndex) {
  return {
    type: SWAP_METADATA_COLLECTION_SERIES,
    fromIndex,
    toIndex
  };
}

export const SET_SERIES_VARIANT = 'SET_SERIES_VARIANT';
export function setSeriesVariant(seriesIndex, seriesVariant) {
  return {
    type: SET_SERIES_VARIANT,
    seriesIndex,
    seriesVariant
  };
}

export const SET_MEASURE_AGGREGATION = 'SET_MEASURE_AGGREGATION';
export function setMeasureAggregation({ aggregationFunction, isFlyoutSeries, relativeIndex }) {
  return {
    type: SET_MEASURE_AGGREGATION,
    aggregationFunction,
    isFlyoutSeries,
    relativeIndex
  };
}

export const SET_MEASURE_COLUMN = 'SET_MEASURE_COLUMN';
export function setMeasureColumn({ columnName, isFlyoutSeries, relativeIndex }) {
  return {
    type: SET_MEASURE_COLUMN,
    columnName,
    isFlyoutSeries,
    relativeIndex
  };
}

export const APPEND_REFERENCE_LINE = 'APPEND_REFERENCE_LINE';
export function appendReferenceLine() {
  return {
    type: APPEND_REFERENCE_LINE
  };
}

export const REMOVE_REFERENCE_LINE = 'REMOVE_REFERENCE_LINE';
export function removeReferenceLine(referenceLineIndex) {
  return {
    type: REMOVE_REFERENCE_LINE,
    referenceLineIndex
  };
}

export const SET_REFERENCE_LINE_COLOR = 'SET_REFERENCE_LINE_COLOR';
export function setReferenceLineColor({ referenceLineIndex, color }) {
  return {
    type: SET_REFERENCE_LINE_COLOR,
    referenceLineIndex,
    color
  };
}

export const SET_REFERENCE_LINE_LABEL = 'SET_REFERENCE_LINE_LABEL';
export function setReferenceLineLabel({ referenceLineIndex, label }) {
  return {
    type: SET_REFERENCE_LINE_LABEL,
    referenceLineIndex,
    label
  };
}

export const SET_REFERENCE_LINE_VALUE = 'SET_REFERENCE_LINE_VALUE';
export function setReferenceLineValue({ referenceLineIndex, value }) {
  return {
    type: SET_REFERENCE_LINE_VALUE,
    referenceLineIndex,
    value
  };
}

export const SET_VISUALIZATION_TYPE = 'SET_VISUALIZATION_TYPE';
export function setVisualizationType(visualizationType) {
  return {
    type: SET_VISUALIZATION_TYPE,
    visualizationType
  };
}

export const INITIATE_REGION_CODING = 'INITIATE_REGION_CODING';
export function initiateRegionCoding(domain, datasetUid, sourceColumn, curatedRegion) {
  return (dispatch, getState) => {
    const providerOptions = { domain, datasetUid };
    const datasetMetadataProvider = new dataProviders.MetadataProvider(providerOptions);
    const regionCodingProvider = new dataProviders.RegionCodingProvider(providerOptions);
    const { featurePk, geometryLabel, uid } = curatedRegion;
    const handleError = (error) => {
      if (checkAndAbortOnShapefileIdMismatch()) {
        return;
      }

      dispatch(handleRegionCodingError(error));
      dispatch(setRegionCodingShapefileId(null));
    };
    const handleCompletion = () => {
      if (checkAndAbortOnShapefileIdMismatch()) {
        return;
      }

      datasetMetadataProvider.
        getDatasetMetadata().
        then((response) => {
          const { columns } = response;
          const computedColumn = _.find(columns, (column) => {
            return _.get(column, 'computationStrategy.parameters.region', '').slice(1) === uid;
          });

          dispatch(finishRegionCoding());
          dispatch(setComputedColumn(computedColumn.fieldName));
          dispatch(setShapefile(uid, featurePk, geometryLabel));
          dispatch(setCenterAndZoom(null));

          // fetch the latest data source so that the region selector will
          // be updated with latest computed columns and curated regions
          dispatch(setDataSource(domain, datasetUid, null, false));
          dispatch(setRegionCodingShapefileId(null));
        }).
        catch(handleError);
    };
    const handleInitiation = (response) => {
      if (response.success) {
        regionCodingProvider.
          awaitRegionCodingCompletion({ jobId: response.jobId }, handleStatus).
          then(handleCompletion).
          catch(handleError);
      } else {
        handleError();
      }
    };
    const checkAndAbortOnShapefileIdMismatch = () => {
      const { regionCodingShapefileIdInProcess } = getState().vifAuthoring.authoring;

      if (regionCodingShapefileIdInProcess !== uid) {
        regionCodingProvider.abortPollingForRegionCodingStatus();
        return true;
      }

      return false;
    };
    const handleStatus = (response) => {
      if (checkAndAbortOnShapefileIdMismatch()) {
        return;
      }

      switch (response.status) {
        case 'unknown':
          regionCodingProvider.
            initiateRegionCoding(uid, sourceColumn).
            then(handleInitiation).
            catch(handleError);
          break;
        case 'processing':
          dispatch(awaitRegionCoding());
          regionCodingProvider.
            awaitRegionCodingCompletion({ jobId: response.data.jobId }).
            then(handleCompletion).
            catch(handleError);
          break;
        case 'completed':
          handleCompletion();
          break;
        case 'await':
          dispatch(awaitRegionCoding());
          break;
        default:
          handleError();
          break;
      }
    };

    dispatch(setShapefileUid(uid));
    dispatch(setComputedColumn(null));
    dispatch(setRegionCodingShapefileId(uid));
    dispatch(requestRegionCoding());

    return regionCodingProvider.
      getRegionCodingStatus({ shapefileId: uid }).
        then(handleStatus).
        catch(handleError);
  };
}

export const REQUEST_REGION_CODING = 'REQUEST_REGION_CODING';
export function requestRegionCoding() {
  return {
    type: REQUEST_REGION_CODING
  };
}

export const SET_REGION_CODING_SHAPEFILE_ID = 'SET_REGION_CODING_SHAPEFILE_ID';
export function setRegionCodingShapefileId(shapefileId) {
  return {
    type: SET_REGION_CODING_SHAPEFILE_ID,
    shapefileId
  };
}

export const AWAIT_REGION_CODING = 'AWAIT_REGION_CODING';
export function awaitRegionCoding() {
  return {
    type: AWAIT_REGION_CODING,
    updatedAt: moment().calendar()
  };
}

export const FINISH_REGION_CODING = 'FINISH_REGION_CODING';
export function finishRegionCoding() {
  return {
    type: FINISH_REGION_CODING
  };
}

export const HIDE_REGION_CODING_ERROR = 'HIDE_REGION_CODING_ERROR';
export function hideRegionCodingError() {
  return {
    type: HIDE_REGION_CODING_ERROR
  };
}

export const HANDLE_REGION_CODING_ERROR = 'HANDLE_REGION_CODING_ERROR';
export function handleRegionCodingError(error) {
  return {
    type: HANDLE_REGION_CODING_ERROR,
    error
  };
}

export const SET_COMPUTED_COLUMN = 'SET_COMPUTED_COLUMN';
export function setComputedColumn(computedColumn) {
  return {
    type: SET_COMPUTED_COLUMN,
    computedColumn
  };
}

export const SET_COMPUTED_COLUMN_FOR_SHAPE_FILE = 'SET_COMPUTED_COLUMN_FOR_SHAPE_FILE';
export function setComputedColumnForShapeFile(computedColumn, shapeFileUid) {
  return {
    type: SET_COMPUTED_COLUMN_FOR_SHAPE_FILE,
    computedColumn,
    shapeFileUid
  };
}

export const REQUEST_SHAPEFILE_METADATA = 'REQUEST_SHAPEFILE_METADATA';
export function requestShapefileMetadata(domain, shapefileUid) {
  return (dispatch, getState) => {
    const shapefileMetadataProvider = new dataProviders.MetadataProvider({ domain, datasetUid: shapefileUid });
    const handleShapefileMetadata = ({ featurePk, geometryLabel }) => {
      dispatch(setCenterAndZoom(null));
      dispatch(setShapefile(shapefileUid, featurePk, geometryLabel));
    };

    dispatch(requestShapefile());

    const { showRegionCodingProcessingMessage, regionCodingError } = getState().vifAuthoring.authoring;

    if (showRegionCodingProcessingMessage === true) {
      dispatch(finishRegionCoding());
      dispatch(setRegionCodingShapefileId(null));
    }

    if (regionCodingError === true) {
      dispatch(hideRegionCodingError());
    }

    return shapefileMetadataProvider.
      getShapefileMetadata(shapefileUid).
      then(handleShapefileMetadata);
  };
}

export const REQUEST_SHAPEFILE = 'REQUEST_SHAPEFILE';
export function requestShapefile() {
  return {
    type: REQUEST_SHAPEFILE
  };
}

export const SET_SHAPEFILE = 'SET_SHAPEFILE';
export function setShapefile(shapefileUid, shapefilePrimaryKey, shapefileGeometryLabel) {
  return {
    type: SET_SHAPEFILE,
    shapefileUid,
    shapefilePrimaryKey,
    shapefileGeometryLabel
  };
}

export const SET_SHAPEFILE_UID = 'SET_SHAPEFILE_UID';
export function setShapefileUid(shapefileUid) {
  return {
    type: SET_SHAPEFILE_UID,
    shapefileUid
  };
}

export const SET_SHAPEFILE_PRIMARY_KEY = 'SET_SHAPEFILE_PRIMARY_KEY';
export function setShapefilePrimaryKey(shapefilePrimaryKey) {
  return {
    type: SET_SHAPEFILE_PRIMARY_KEY,
    shapefilePrimaryKey
  };
}

export const SET_SHAPEFILE_GEOMETRY_LABEL = 'SET_SHAPEFILE_GEOMETRY_LABEL';
export function setShapefileGeometryLabel(shapefileGeometryLabel) {
  return {
    type: SET_SHAPEFILE_GEOMETRY_LABEL,
    shapefileGeometryLabel
  };
}

export const SET_TITLE = 'SET_TITLE';
export function setTitle(title) {
  return {
    type: SET_TITLE,
    title
  };
}

export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export function setDescription(description) {
  return {
    type: SET_DESCRIPTION,
    description
  };
}

export const SET_VIEW_SOURCE_DATA_LINK = 'SET_VIEW_SOURCE_DATA_LINK';
export function setViewSourceDataLink(viewSourceDataLink) {
  return {
    type: SET_VIEW_SOURCE_DATA_LINK,
    viewSourceDataLink
  };
}

export const SET_PRIMARY_COLOR = 'SET_PRIMARY_COLOR';
export function setPrimaryColor(seriesIndex, primaryColor) {
  return {
    type: SET_PRIMARY_COLOR,
    seriesIndex,
    primaryColor
  };
}

export const SET_SECONDARY_COLOR = 'SET_SECONDARY_COLOR';
export function setSecondaryColor(seriesIndex, secondaryColor) {
  return {
    type: SET_SECONDARY_COLOR,
    seriesIndex,
    secondaryColor
  };
}

export const SET_COLOR_SCALE = 'SET_COLOR_SCALE';
export function setColorScale(negativeColor, zeroColor, positiveColor) {
  return {
    type: SET_COLOR_SCALE,
    negativeColor,
    zeroColor,
    positiveColor
  };
}

export const SET_COLOR_PALETTE_PROPERTIES = 'SET_COLOR_PALETTE_PROPERTIES';
export function setColorPaletteProperties() {
  return (dispatch, getState) => {
    const { vifAuthoring } = getState();
    const currentSeriesIndex = getCurrentSeriesIndex(vifAuthoring);
    const quantificationMethod = getColorByQuantificationMethod(vifAuthoring);
    const hasCustomColorPalette = _.isEqual(getColorPalette()(vifAuthoring), 'custom');
    let customColorPaletteForNewGLMaps = null;
    let colorPaletteGroupingColumnName = null;
    dispatch({ type: SET_COLOR_PALETTE_PROPERTIES });

    if (hasCustomColorPalette) {
      if (isNewGLMap(vifAuthoring) === 'map') {
        colorPaletteGroupingColumnName = getColorPaletteGroupingColumnName(vifAuthoring);
        if (!_.isNull(colorPaletteGroupingColumnName)) {
          customColorPaletteForNewGLMaps = getCustomColorPaletteForNewGLMaps(vifAuthoring);
        }
      }

      dataProviders.CustomColorPaletteManager.
        generateCustomColorPalette(vifAuthoring).
        then(({ customColorPalette, dimensionColumnName }) => {
          // if (!_.isNull(colorPaletteGroupingColumnName)) {
          //   if (isMap(vifAuthoring) && quantificationMethod) {
          //     dispatch(setCustomColorPaletteWithQuantification(customColorPalette, colorPaletteGroupingColumnName, currentSeriesIndex, quantificationMethod));
          //   } else {
          //     dispatch(setCustomColorPalette(customColorPalette, colorPaletteGroupingColumnName, currentSeriesIndex));
          //   }
          // } else {
          //   if (isMap(vifAuthoring) && quantificationMethod) {
          //     dispatch(setCustomColorPaletteWithQuantification(customColorPalette, dimensionColumnName, currentSeriesIndex, quantificationMethod));
          //   } else {
          //     dispatch(setCustomColorPalette(customColorPalette, dimensionColumnName, currentSeriesIndex));
          //   }
          // }
          setCustomColorPaletteForAllVizType({
            colorPaletteGroupingColumnName,
            customColorPalette,
            currentSeriesIndex,
            dimensionColumnName,
            dispatch,
            vifAuthoring,
            quantificationMethod
          });

          if (!_.isNull(customColorPaletteForNewGLMaps)) {
            _.each(customColorPaletteForNewGLMaps, (group, palette) => {
              if (isMap(vifAuthoring) && quantificationMethod) {
                updateCustomColorPaletteWithQuantification(palette.color, group, colorPaletteGroupingColumnName, currentSeriesIndex, quantificationMethod);
                updateCustomCharmNameWithQuantification(palette.charmName, group, colorPaletteGroupingColumnName, currentSeriesIndex, quantificationMethod);
              } else {
                updateCustomColorPalette(palette.color, group, colorPaletteGroupingColumnName, currentSeriesIndex);
                updateCustomCharmName(palette.charmName, group, colorPaletteGroupingColumnName, currentSeriesIndex);
              }
            });
          }
        }
      ).catch((error) => {
        dispatch(handleCustomColorPaletteError(error));
        console.error(error);
      });
    }
  };
}

export const HANDLE_CUSTOM_COLOR_PALETTE_ERROR = 'HANDLE_CUSTOM_COLOR_PALETTE_ERROR';
export function handleCustomColorPaletteError(error) {
  return {
    type: HANDLE_CUSTOM_COLOR_PALETTE_ERROR,
    error
  };
}

export const SET_COLOR_PALETTE = 'SET_COLOR_PALETTE';
export function setColorPalette(colorPalette) {
  return {
    type: SET_COLOR_PALETTE,
    colorPalette
  };
}

export const SET_NEGATIVE_COLOR = 'SET_NEGATIVE_COLOR';
export function setNegativeColor(negativeColor) {
  return {
    type: SET_NEGATIVE_COLOR,
    negativeColor
  };
}

export const SET_ZERO_COLOR = 'SET_ZERO_COLOR';
export function setZeroColor(zeroColor) {
  return {
    type: SET_ZERO_COLOR,
    zeroColor
  };
}
export const SET_POSITIVE_COLOR = 'SET_POSITIVE_COLOR';
export function setPositiveColor(positiveColor) {
  return {
    type: SET_POSITIVE_COLOR,
    positiveColor
  };
}

export const SET_BASE_LAYER = 'SET_BASE_LAYER';
export function setBaseLayer(baseLayer) {
  return {
    type: SET_BASE_LAYER,
    baseLayer
  };
}

export const SET_BASE_LAYER_OPACITY = 'SET_BASE_LAYER_OPACITY';
export function setBaseLayerOpacity(baseLayerOpacity) {
  return {
    type: SET_BASE_LAYER_OPACITY,
    baseLayerOpacity
  };
}

export const SET_LABEL_TOP = 'SET_LABEL_TOP';
export function setLabelTop(labelTop) {
  return {
    type: SET_LABEL_TOP,
    labelTop
  };
}

export const SET_LABEL_LEFT = 'SET_LABEL_LEFT';
export function setLabelLeft(labelLeft) {
  return {
    type: SET_LABEL_LEFT,
    labelLeft
  };
}

export const SET_LABEL_BOTTOM = 'SET_LABEL_BOTTOM';
export function setLabelBottom(labelBottom) {
  return {
    type: SET_LABEL_BOTTOM,
    labelBottom
  };
}

export const SET_LABEL_RIGHT = 'SET_LABEL_RIGHT';
export function setLabelRight(labelRight) {
  return {
    type: SET_LABEL_RIGHT,
    labelRight
  };
}

export const SET_LEGEND_POSITION = 'SET_LEGEND_POSITION';
export function setLegendPosition(position) {
  return {
    type: SET_LEGEND_POSITION,
    position
  };
}

export const SET_SHOW_DIMENSION_LABELS = 'SET_SHOW_DIMENSION_LABELS';
export function setShowDimensionLabels(showDimensionLabels) {
  return {
    type: SET_SHOW_DIMENSION_LABELS,
    showDimensionLabels
  };
}

export const SET_SHOW_LINE_VALUE_LABELS = 'SET_SHOW_LINE_VALUE_LABELS';
export function setShowLineValueLabels(showLineValueLabels) {
  return {
    type: SET_SHOW_LINE_VALUE_LABELS,
    showLineValueLabels
  };
}

export const SET_SHOW_NULLS_AS_FALSE = 'SET_SHOW_NULLS_AS_FALSE';
export function setShowNullsAsFalse(showNullsAsFalse) {
  return {
    type: SET_SHOW_NULLS_AS_FALSE,
    showNullsAsFalse
  };
}

export const SET_SHOW_VALUE_LABELS = 'SET_SHOW_VALUE_LABELS';
export function setShowValueLabels(showValueLabels) {
  return {
    type: SET_SHOW_VALUE_LABELS,
    showValueLabels
  };
}

export const SET_SHOW_VALUE_LABELS_AS_PERCENT = 'SET_SHOW_VALUE_LABELS_AS_PERCENT';
export function setShowValueLabelsAsPercent(showValueLabelsAsPercent) {
  return {
    type: SET_SHOW_VALUE_LABELS_AS_PERCENT,
    showValueLabelsAsPercent
  };
}

export const SET_SHOW_LEGEND = 'SET_SHOW_LEGEND';
export function setShowLegend(showLegend) {
  return {
    type: SET_SHOW_LEGEND,
    showLegend
  };
}

export const SET_SHOW_MULTIPLE_POINTS_SYMBOL_IN_LEGEND = 'SET_SHOW_MULTIPLE_POINTS_SYMBOL_IN_LEGEND';
export function setShowMultiplePointsSymbolInLegend(showMultiplePointsSymbolInLegend) {
  return {
    type: SET_SHOW_MULTIPLE_POINTS_SYMBOL_IN_LEGEND,
    showMultiplePointsSymbolInLegend
  };
}

export const SET_SHOW_LEGEND_FOR_MAP = 'SET_SHOW_LEGEND_FOR_MAP';
export function setShowLegendForMap(showLegendForMap) {
  return {
    type: SET_SHOW_LEGEND_FOR_MAP,
    showLegendForMap
  };
}

export const SET_SHOW_LEGEND_OPENED = 'SET_SHOW_LEGEND_OPENED';
export function setShowLegendOpened(showLegendOpened) {
  return {
    type: SET_SHOW_LEGEND_OPENED,
    showLegendOpened
  };
}

export const SET_WRAP_DIMENSION_LABELS = 'SET_WRAP_DIMENSION_LABELS';
export function setWrapDimensionLabels(wrapDimensionLabels) {
  return {
    type: SET_WRAP_DIMENSION_LABELS,
    wrapDimensionLabels
  };
}

export const SET_X_AXIS_SCALING_MODE = 'SET_X_AXIS_SCALING_MODE';
export function setXAxisScalingMode({ shouldFit }) {
  return {
    type: SET_X_AXIS_SCALING_MODE,
    xAxisScalingMode: shouldFit ? 'fit' : 'pan'
  };
}

export const SET_MEASURE_AXIS_MAX_VALUE = 'SET_MEASURE_AXIS_MAX_VALUE';
export function setMeasureAxisMaxValue(measureAxisMaxValue) {
  return {
    type: SET_MEASURE_AXIS_MAX_VALUE,
    measureAxisMaxValue
  };
}

export const SET_MEASURE_AXIS_MIN_VALUE = 'SET_MEASURE_AXIS_MIN_VALUE';
export function setMeasureAxisMinValue(measureAxisMinValue) {
  return {
    type: SET_MEASURE_AXIS_MIN_VALUE,
    measureAxisMinValue
  };
}

export const SET_MEASURE_AXIS_PRECISION = 'SET_MEASURE_AXIS_PRECISION';
export function setMeasureAxisPrecision(precision) {
  return {
    type: SET_MEASURE_AXIS_PRECISION,
    precision
  };
}

export const SET_LOGARITHMIC_SCALE = 'SET_LOGARITHMIC_SCALE';
export function setLogarithmicScale(logarithmicScale) {
  return {
    type: SET_LOGARITHMIC_SCALE,
    logarithmicScale
  };
}

export const SET_MAP_LEGEND_PRECISION = 'SET_MAP_LEGEND_PRECISION';
export function setMapLegendPrecision(precision) {
  return {
    type: SET_MAP_LEGEND_PRECISION,
    precision
  };
}

export const SET_MAP_FLYOUT_PRECISION = 'SET_MAP_FLYOUT_PRECISION';
export function setMapFlyoutPrecision(precision) {
  return {
    type: SET_MAP_FLYOUT_PRECISION,
    precision
  };
}

export const SET_MEASURE_AXIS_SCALE = 'SET_MEASURE_AXIS_SCALE';
export function setMeasureAxisScale(scale) {
  return {
    type: SET_MEASURE_AXIS_SCALE,
    scale
  };
}

export const SET_SECONDARY_MEASURE_AXIS_MAX_VALUE = 'SET_SECONDARY_MEASURE_AXIS_MAX_VALUE';
export function setSecondaryMeasureAxisMaxValue(measureAxisMaxValue) {
  return {
    type: SET_SECONDARY_MEASURE_AXIS_MAX_VALUE,
    measureAxisMaxValue
  };
}

export const SET_SECONDARY_MEASURE_AXIS_MIN_VALUE = 'SET_SECONDARY_MEASURE_AXIS_MIN_VALUE';
export function setSecondaryMeasureAxisMinValue(measureAxisMinValue) {
  return {
    type: SET_SECONDARY_MEASURE_AXIS_MIN_VALUE,
    measureAxisMinValue
  };
}

export const SET_SECONDARY_MEASURE_AXIS_PRECISION = 'SET_SECONDARY_MEASURE_AXIS_PRECISION';
export function setSecondaryMeasureAxisPrecision(precision) {
  return {
    type: SET_SECONDARY_MEASURE_AXIS_PRECISION,
    precision
  };
}
export const SET_SECONDARY_MEASURE_AXIS_SCALE = 'SET_SECONDARY_MEASURE_AXIS_SCALE';
export function setSecondaryMeasureAxisScale(scale) {
  return {
    type: SET_SECONDARY_MEASURE_AXIS_SCALE,
    scale
  };
}

export const SET_UNIT_ONE = 'SET_UNIT_ONE';
export function setUnitsOne(seriesIndex, one) {
  return {
    type: SET_UNIT_ONE,
    seriesIndex,
    one
  };
}

export const SET_UNIT_OTHER = 'SET_UNIT_OTHER';
export function setUnitsOther(seriesIndex, other) {
  return {
    type: SET_UNIT_OTHER,
    seriesIndex,
    other
  };
}

export const SET_ROW_INSPECTOR_TITLE_COLUMN_NAME = 'SET_ROW_INSPECTOR_TITLE_COLUMN_NAME';
export function setRowInspectorTitleColumnName(rowInspectorTitleColumnName) {
  return {
    type: SET_ROW_INSPECTOR_TITLE_COLUMN_NAME,
    rowInspectorTitleColumnName
  };
}

export const SET_SHOW_SLICE_PERCENTS_IN_FLYOUTS = 'SET_SHOW_SLICE_PERCENTS_IN_FLYOUTS';
export function setShowSlicePercentsInFlyouts(showSlicePercentsInFlyouts) {
  return {
    type: SET_SHOW_SLICE_PERCENTS_IN_FLYOUTS,
    showSlicePercentsInFlyouts
  };
}

export const SET_DIMENSION_LABEL_AREA_SIZE = 'SET_DIMENSION_LABEL_AREA_SIZE';
export function setDimensionLabelAreaSize(width) {
  return {
    type: SET_DIMENSION_LABEL_AREA_SIZE,
    width
  };
}

export const SET_DIMENSION_LABEL_ROTATION_ANGLE = 'SET_DIMENSION_LABEL_ROTATION_ANGLE';
export function setDimensionLabelRotationAngle(angle) {
  return {
    type: SET_DIMENSION_LABEL_ROTATION_ANGLE,
    angle
  };
}

export const SET_CENTER_AND_ZOOM = 'SET_CENTER_AND_ZOOM';
export function setCenterAndZoom(centerAndZoom) {
  return {
    type: SET_CENTER_AND_ZOOM,
    centerAndZoom
  };
}

export const SET_MAP_INFO_DISMISSED = 'SET_MAP_INFO_DISMISSED';
export function setMapInfoDismissed() {
  return {
    type: SET_MAP_INFO_DISMISSED
  };
}

export const SET_CURRENT_MAP_LAYER_INDEX = 'SET_CURRENT_MAP_LAYER_INDEX';
export function setCurrentMapLayerIndex(currentMapLayerIndex = 0) {
  return {
    type: SET_CURRENT_MAP_LAYER_INDEX,
    currentMapLayerIndex
  };
}

export const SET_LAYER_AS_PRIMARY = 'SET_LAYER_AS_PRIMARY';
export function setLayerAsPrimary(relativeIndex, isPrimary) {
  return {
    type: SET_LAYER_AS_PRIMARY,
    relativeIndex,
    isPrimary
  };
}

export const SET_CURRENT_MAP_LAYER_MODE = 'SET_CURRENT_MAP_LAYER_MODE';
export function setCurrentMapLayerMode(currentMapLayerIndexView) {
  return {
    type: SET_CURRENT_MAP_LAYER_MODE,
    currentMapLayerIndexView
  };
}

export const SET_DATASET_SELECTION_MODAL_TOGGLE = 'SET_DATASET_SELECTION_MODAL_TOGGLE';
export function setDatasetSelectionModalToggle(toggle) {
  return {
    type: SET_DATASET_SELECTION_MODAL_TOGGLE,
    toggle
  };
}

export const SET_CUSTOM_COLOR_PALETTE = 'SET_CUSTOM_COLOR_PALETTE';
export function setCustomColorPalette(customColorPalette, dimensionGroupingColumnName, seriesIndex) {
  return {
    type: SET_CUSTOM_COLOR_PALETTE,
    customColorPalette,
    dimensionGroupingColumnName,
    seriesIndex
  };
}

export const SET_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION = 'SET_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION';
export function setCustomColorPaletteWithQuantification(customColorPalette, dimensionGroupingColumnName, seriesIndex, quantificationMethod) {
  return {
    type: SET_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION,
    customColorPalette,
    dimensionGroupingColumnName,
    seriesIndex,
    quantificationMethod
  };
}

export const UPDATE_CUSTOM_COLOR_PALETTE = 'UPDATE_CUSTOM_COLOR_PALETTE';
export function updateCustomColorPalette(selectedColor, group, dimensionGroupingColumnName, seriesIndex) {
  return {
    type: UPDATE_CUSTOM_COLOR_PALETTE,
    selectedColor,
    group,
    dimensionGroupingColumnName,
    seriesIndex
  };
}

export const UPDATE_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION = 'UPDATE_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION';
export function updateCustomColorPaletteWithQuantification(selectedColor, group, dimensionGroupingColumnName, seriesIndex, quantificationMethod) {
  return {
    type: UPDATE_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION,
    selectedColor,
    group,
    dimensionGroupingColumnName,
    seriesIndex,
    quantificationMethod
  };
}

export const UPDATE_CUSTOM_CHARM_NAME = 'UPDATE_CUSTOM_CHARM_NAME';
export function updateCustomCharmName(selectedCharmName, group, dimensionGroupingColumnName, seriesIndex) {
  return {
    type: UPDATE_CUSTOM_CHARM_NAME,
    selectedCharmName,
    group,
    dimensionGroupingColumnName,
    seriesIndex
  };
}

export const UPDATE_CUSTOM_CHARM_NAME_WITH_QUANTIFICATION = 'UPDATE_CUSTOM_CHARM_NAME_WITH_QUANTIFICATION';
export function updateCustomCharmNameWithQuantification(selectedCharmName, group, dimensionGroupingColumnName, seriesIndex, quantificationMethod) {
  return {
    type: UPDATE_CUSTOM_CHARM_NAME_WITH_QUANTIFICATION,
    selectedCharmName,
    group,
    dimensionGroupingColumnName,
    seriesIndex,
    quantificationMethod
  };
}

export const SET_DATE_DISPLAY_FORMAT = 'SET_DATE_DISPLAY_FORMAT';
export function setDateDisplayFormat(format) {
  return {
    type: SET_DATE_DISPLAY_FORMAT,
    format
  };
}

export const SET_DIMENSION_GROUPING_COLUMN_NAME = 'SET_DIMENSION_GROUPING_COLUMN_NAME';
export function setDimensionGroupingColumnName(dimensionGroupingColumnName) {
  return {
    type: SET_DIMENSION_GROUPING_COLUMN_NAME,
    dimensionGroupingColumnName
  };
}

export const SET_ERROR_BARS_BAR_COLOR = 'SET_ERROR_BARS_BAR_COLOR';
export function setErrorBarsBarColor(color) {
  return {
    type: SET_ERROR_BARS_BAR_COLOR,
    color
  };
}

export const SET_ERROR_BARS_LOWER_BOUND_COLUMN_NAME = 'SET_ERROR_BARS_LOWER_BOUND_COLUMN_NAME';
export function setErrorBarsLowerBoundColumnName(columnName) {
  return {
    type: SET_ERROR_BARS_LOWER_BOUND_COLUMN_NAME,
    columnName
  };
}

export const SET_ERROR_BARS_UPPER_BOUND_COLUMN_NAME = 'SET_ERROR_BARS_UPPER_BOUND_COLUMN_NAME';
export function setErrorBarsUpperBoundColumnName(columnName) {
  return {
    type: SET_ERROR_BARS_UPPER_BOUND_COLUMN_NAME,
    columnName
  };
}

export const SET_GROUPING_ORDER_BY = 'SET_GROUPING_ORDER_BY';
export function setGroupingOrderBy(orderBy) {
  return {
    type: SET_GROUPING_ORDER_BY,
    orderBy
  };
}

export const SET_ORDER_BY = 'SET_ORDER_BY';
export function setOrderBy(orderBy) {
  return {
    type: SET_ORDER_BY,
    orderBy
  };
}

export const SET_COLUMN_ORDER_CONFIGURATION = 'SET_COLUMN_ORDER_CONFIGURATION';
export function setColumnOrderConfiguration(order) {
  return {
    type: SET_COLUMN_ORDER_CONFIGURATION,
    order
  };
}

export const REPLACE_VIF = 'REPLACE_VIF';
export function replaceVif(updatedVif) {
  return {
    type: REPLACE_VIF,
    vif: updatedVif
  };
}

export const SET_DEFAULT_DISPLAY_DATE = 'SET_DEFAULT_DISPLAY_DATE';
export function setDefaultDisplayDate(defaultDisplayDate) {
  return {
    type: SET_DEFAULT_DISPLAY_DATE,
    defaultDisplayDate
  };
}

export const SET_CURRENT_DISPLAY_DATE = 'SET_CURRENT_DISPLAY_DATE';
export function setCurrentDisplayDate(currentDisplayDate) {
  return {
    type: SET_CURRENT_DISPLAY_DATE,
    currentDisplayDate
  };
}

export const SET_START_DATE_COLUMN = 'SET_START_DATE_COLUMN';
export function setStartDateColumn(startDateColumn) {
  return {
    type: SET_START_DATE_COLUMN,
    startDateColumn
  };
}

export const SET_END_DATE_COLUMN = 'SET_END_DATE_COLUMN';
export function setEndDateColumn(endDateColumn) {
  return {
    type: SET_END_DATE_COLUMN,
    endDateColumn
  };
}

export const SET_EVENT_TITLE_COLUMN = 'SET_EVENT_TITLE_COLUMN';
export function setEventTitleColumn(eventTitleColumn) {
  return {
    type: SET_EVENT_TITLE_COLUMN,
    eventTitleColumn
  };
}

export const SET_EVENT_BACKGROUND_COLOR = 'SET_EVENT_BACKGROUND_COLOR';
export function setEventBackgroundColor(eventBackgroundColor) {
  return {
    type: SET_EVENT_BACKGROUND_COLOR,
    eventBackgroundColor
  };
}

export const SET_EVENT_OUTLINE_COLOR = 'SET_EVENT_OUTLINE_COLOR';
export function setEventOutlineColor(eventOutlineColor) {
  return {
    type: SET_EVENT_OUTLINE_COLOR,
    eventOutlineColor
  };
}

export const SET_EVENT_TEXT_COLOR = 'SET_EVENT_TEXT_COLOR';
export function setEventTextColor(eventTextColor) {
  return {
    type: SET_EVENT_TEXT_COLOR,
    eventTextColor
  };
}

export const SET_PRECISION = 'SET_PRECISION';
export function setPrecision(precision) {
  return {
    type: SET_PRECISION,
    precision
  };
}

export const SET_STACKED = 'SET_STACKED';
export function setStacked({ stacked, oneHundredPercent }) {
  return {
    type: SET_STACKED,
    stacked,
    oneHundredPercent
  };
}

export const SET_TREAT_NULL_VALUES_AS_ZERO = 'SET_TREAT_NULL_VALUES_AS_ZERO';
export function setTreatNullValuesAsZero(treatNullValuesAsZero) {
  return {
    type: SET_TREAT_NULL_VALUES_AS_ZERO,
    treatNullValuesAsZero
  };
}

export const SET_LIMIT_NONE_AND_SHOW_OTHER_CATEGORY = 'SET_LIMIT_NONE_AND_SHOW_OTHER_CATEGORY';
export function setLimitNoneAndShowOtherCategory() {
  return {
    type: SET_LIMIT_NONE_AND_SHOW_OTHER_CATEGORY,
    limitNone: true,
    showOtherCategory: false
  };
}

export const SET_LIMIT_COUNT_AND_SHOW_OTHER_CATEGORY = 'SET_LIMIT_COUNT_AND_SHOW_OTHER_CATEGORY';
export function setLimitCountAndShowOtherCategory(limitCount, showOtherCategory) {
  return {
    type: SET_LIMIT_COUNT_AND_SHOW_OTHER_CATEGORY,
    limitCount,
    showOtherCategory
  };
}

export const SET_USE_SECONDARY_AXIS_FOR_COLUMNS = 'SET_USE_SECONDARY_AXIS_FOR_COLUMNS';
export function setUseSecondaryAxisForColumns(useSecondaryAxis) {
  return {
    type: SET_USE_SECONDARY_AXIS_FOR_COLUMNS,
    useSecondaryAxis
  };
}

export const SET_USE_SECONDARY_AXIS_FOR_LINES = 'SET_USE_SECONDARY_AXIS_FOR_LINES';
export function setUseSecondaryAxisForLines(useSecondaryAxis) {
  return {
    type: SET_USE_SECONDARY_AXIS_FOR_LINES,
    useSecondaryAxis
  };
}

export const SET_SHOW_OTHER_CATEGORY = 'SET_SHOW_OTHER_CATEGORY';
export function setShowOtherCategory(showOtherCategory) {
  return {
    type: SET_SHOW_OTHER_CATEGORY,
    showOtherCategory
  };
}

export const RESET_STATE = 'RESET_STATE';
export function resetState() {
  return (dispatch, getState) => {
    const state = getState();

    // Dispatching RESET_STATE to all reducers
    // Reducers will return initial states
    dispatch({ type: RESET_STATE });

    // Trigger load on empty VIF
    load(dispatch, getEmptyVif(state.vifAuthoring));
  };
}

export const MIGRATE_OLD_MAP_TO_NEW_GL_MAP = 'MIGRATE_OLD_MAP_TO_NEW_GL_MAP';
export function migrateOldMapToNewGLMap(currentMapType) {
  return (dispatch, getState) => {
    const { vifAuthoring } = getState();
    const initialVif = _.get(vifAuthoring, 'vifs.initialVif');
    let basemapStyle = _.get(initialVif, 'configuration.baseLayerUrl', VECTOR_BASEMAP_STYLES.basic.value);
    const mapStyleConfig = _.find(BASE_LAYERS, { legacyMapsTileUrlValue: basemapStyle });
    if (mapStyleConfig) {
      basemapStyle = _.get(mapStyleConfig, 'value');
    }

    const basemapOpacity = _.get(initialVif, 'configuration.baseLayerOpacity', 1);
    const pointOpacity = parseFloat(_.get(initialVif, 'series[0].mapOptions.pointOpacity')) * 100 || 100;
    const flyoutColumnName = _.get(initialVif, 'configuration.rowInspectorTitleColumnName', null);
    dispatch(setVisualizationType('map'));
    dispatch(setMapType('pointMap'));

    if (currentMapType === 'regionMap') {
      dispatch(setPointAggregation('region_map'));
    }

    // Most of the keys of old featureMap and regionMap are subset of new map options
    // and will be loaded automatically, manually setting rest of the options
    dispatch(setBasemapStyle(basemapStyle));
    dispatch(setBasemapOpacity(basemapOpacity));
    dispatch(setPointOpacity(pointOpacity));

    if (!_.isEmpty(flyoutColumnName)) {
      dispatch(setMapFlyoutTitleColumnName(flyoutColumnName));
    }
  };
}

export const SET_USER_ACTIVE = 'SET_USER_ACTIVE';
export function setUserActive() {
  return {
    type: SET_USER_ACTIVE
  };
}

export const SET_USER_IDLE = 'SET_USER_IDLE';
export function setUserIdle() {
  return {
    type: SET_USER_IDLE
  };
}

export const SET_MAP_TYPE = 'SET_MAP_TYPE';
export function setMapType(mapType) {
  return {
    type: SET_MAP_TYPE,
    mapType
  };
}

export const SET_POINT_SIZE = 'SET_POINT_SIZE';
export function setPointSize(pointSize) {
  return {
    type: SET_POINT_SIZE,
    pointSize
  };
}

export const SET_POINT_OPACITY = 'SET_POINT_OPACITY';
export function setPointOpacity(pointOpacity) {
  return {
    type: SET_POINT_OPACITY,
    pointOpacity
  };
}

export const SET_RESIZE_POINTS_BY_COLUMN = 'SET_RESIZE_POINTS_BY_COLUMN';
export function setResizePointsByColumn(resizePointsBy) {
  return {
    type: SET_RESIZE_POINTS_BY_COLUMN,
    resizePointsBy
  };
}

export const SET_COLOR_BY_COLUMN = 'SET_COLOR_BY_COLUMN';
export function setColorByColumn(colorByColumn) {
  return {
    type: SET_COLOR_BY_COLUMN,
    colorByColumn
  };
}

export const SET_RESIZE_BY_COLUMN = 'SET_RESIZE_BY_COLUMN';
export function setResizeByColumn(resizeByColumn) {
  return {
    type: SET_RESIZE_BY_COLUMN,
    resizeByColumn
  };
}

export const SET_X_AXIS_COLUMN = 'SET_X_AXIS_COLUMN';
export function setXAxisColumn(xAxisColumn) {
  return {
    type: SET_X_AXIS_COLUMN,
    xAxisColumn
  };
}

export const SET_PLOT_SIZE = 'SET_PLOT_SIZE';
export function setPlotSize(plotSize) {
  return {
    type: SET_PLOT_SIZE,
    plotSize
  };
}

export const SET_Y_AXIS_COLUMN = 'SET_Y_AXIS_COLUMN';
export function setYAxisColumn(yAxisColumn) {
  return {
    type: SET_Y_AXIS_COLUMN,
    yAxisColumn
  };
}

export const SET_MINIMUM_PLOT_SIZE = 'SET_MINIMUM_PLOT_SIZE';
export function setMinimumPlotSize(minimumPlotSize) {
  return {
    type: SET_MINIMUM_PLOT_SIZE,
    minimumPlotSize
  };
}

export const SET_MAXIMUM_PLOT_SIZE = 'SET_MAXIMUM_PLOT_SIZE';
export function setMaximumPlotSize(maximumPlotSize) {
  return {
    type: SET_MAXIMUM_PLOT_SIZE,
    maximumPlotSize
  };
}

export const SET_MINIMUM_POINT_SIZE = 'SET_MINIMUM_POINT_SIZE';
export function setMinimumPointSize(minimumPointSize) {
  return {
    type: SET_MINIMUM_POINT_SIZE,
    minimumPointSize
  };
}

export const SET_MAXIMUM_POINT_SIZE = 'SET_MAXIMUM_POINT_SIZE';
export function setMaximumPointSize(maximumPointSize) {
  return {
    type: SET_MAXIMUM_POINT_SIZE,
    maximumPointSize
  };
}

export const SET_NUMBER_OF_DATA_CLASSES = 'SET_NUMBER_OF_DATA_CLASSES';
export function setNumberOfDataClasses(numberOfDataClasses) {
  return {
    type: SET_NUMBER_OF_DATA_CLASSES,
    numberOfDataClasses
  };
}

export const SET_COLOR_BY_BUCKETS_COUNT = 'SET_COLOR_BY_BUCKETS_COUNT';
export function setColorByBucketsCount(colorByBucketsCount) {
  return {
    type: SET_COLOR_BY_BUCKETS_COUNT,
    colorByBucketsCount
  };
}

export const SET_MID_POINT = 'SET_MID_POINT';
export function setMidpoint(midpoint) {
  return {
    type: SET_MID_POINT,
    midpoint
  };
}

export const SET_MAX_CLUSTERING_ZOOM_LEVEL = 'SET_MAX_CLUSTERING_ZOOM_LEVEL';
export function setMaxClusteringZoomLevel(maxClusteringZoomLevel) {
  return {
    type: SET_MAX_CLUSTERING_ZOOM_LEVEL,
    maxClusteringZoomLevel
  };
}

export const SET_POINT_THRESHOLD = 'SET_POINT_THRESHOLD';
export function setPointThreshold(pointThreshold) {
  return {
    type: SET_POINT_THRESHOLD,
    pointThreshold
  };
}

export const SET_CLUSTER_RADIUS = 'SET_CLUSTER_RADIUS';
export function setClusterRadius(clusterRadius) {
  return {
    type: SET_CLUSTER_RADIUS,
    clusterRadius
  };
}

export const SET_CAST_NULL_AS_FALSE_IN_SERIES = 'SET_CAST_NULL_AS_FALSE_IN_SERIES';
export function setCastNullAsFalseInSeries(castNullAsFalseInSeries) {
  return {
    type: SET_CAST_NULL_AS_FALSE_IN_SERIES,
    castNullAsFalseInSeries
  };
}

export const SET_MAX_CLUSTER_SIZE = 'SET_MAX_CLUSTER_SIZE';
export function setMaxClusterSize(maxClusterSize) {
  return {
    type: SET_MAX_CLUSTER_SIZE,
    maxClusterSize
  };
}

export const SET_STACK_RADIUS = 'SET_STACK_RADIUS';
export function setStackRadius(stackRadius) {
  return {
    type: SET_STACK_RADIUS,
    stackRadius
  };
}

export const SET_COLOR_POINTS_BY_COLUMN = 'SET_COLOR_POINTS_BY_COLUMN';
export function setColorPointsByColumn(colorPointsBy) {
  return {
    type: SET_COLOR_POINTS_BY_COLUMN,
    colorPointsBy
  };
}

export const SET_LINE_WEIGHT = 'SET_LINE_WEIGHT';
export function setLineWeight(lineWeight) {
  return {
    type: SET_LINE_WEIGHT,
    lineWeight
  };
}

export const SET_WEIGH_LINES_BY_COLUMN = 'SET_WEIGH_LINES_BY_COLUMN';
export function setWeighLinesByColumn(weighLinesBy) {
  return {
    type: SET_WEIGH_LINES_BY_COLUMN,
    weighLinesBy
  };
}

export const SET_MINIMUM_LINE_WEIGHT = 'SET_MINIMUM_LINE_WEIGHT';
export function setMinimumLineWeight(minimumLineWeight) {
  return {
    type: SET_MINIMUM_LINE_WEIGHT,
    minimumLineWeight
  };
}

export const SET_MAXIMUM_LINE_WEIGHT = 'SET_MAXIMUM_LINE_WEIGHT';
export function setMaximumLineWeight(maximumLineWeight) {
  return {
    type: SET_MAXIMUM_LINE_WEIGHT,
    maximumLineWeight
  };
}

export const SET_COLOR_LINES_BY_COLUMN = 'SET_COLOR_LINES_BY_COLUMN';
export function setColorLinesByColumn(colorLinesBy) {
  return {
    type: SET_COLOR_LINES_BY_COLUMN,
    colorLinesBy
  };
}

export const SET_BOUNDARY_COLOR_BY_COLUMN = 'SET_BOUNDARY_COLOR_BY_COLUMN';
export function setBoundaryColorByColumn(colorBoundariesBy) {
  return {
    type: SET_BOUNDARY_COLOR_BY_COLUMN,
    colorBoundariesBy
  };
}

export const SET_COLOR_BY_QUANTIFICATION_METHOD = 'SET_COLOR_BY_QUANTIFICATION_METHOD';
export function setColorByQuantificationMethod(colorByQuantificationMethod) {
  return {
    type: SET_COLOR_BY_QUANTIFICATION_METHOD,
    colorByQuantificationMethod
  };
}

export const SET_RANGE_BUCKET_TYPE = 'SET_RANGE_BUCKET_TYPE';
export function setRangeBucketType(rangeBucketType) {
  return {
    type: SET_RANGE_BUCKET_TYPE,
    rangeBucketType
  };
}

export const SET_POINT_AGGREGATION = 'SET_POINT_AGGREGATION';
export function setPointAggregation(pointAggregation) {
  return {
    type: SET_POINT_AGGREGATION,
    pointAggregation
  };
}

export const SET_BASEMAP_STYLE = 'SET_BASEMAP_STYLE';
export function setBasemapStyle(basemapStyle) {
  return {
    type: SET_BASEMAP_STYLE,
    basemapStyle
  };
}

export const SET_BASEMAP_OPACITY = 'SET_BASEMAP_OPACITY';
export function setBasemapOpacity(basemapOpacity) {
  return {
    type: SET_BASEMAP_OPACITY,
    basemapOpacity
  };
}

export const SET_NAVIGATION_CONTROL = 'SET_NAVIGATION_CONTROL';
export function setNavigationControl(navigationControl) {
  return {
    type: SET_NAVIGATION_CONTROL,
    navigationControl
  };
}

export const SET_LAYER_TOGGLE_CONTROL = 'SET_LAYER_TOGGLE_CONTROL';
export function setLayerToggleControl(layerToggleControl) {
  return {
    type: SET_LAYER_TOGGLE_CONTROL,
    layerToggleControl
  };
}

export const SET_GEO_CODER_CONTROL = 'SET_GEO_CODER_CONTROL';
export function setGeoCoderControl(geoCoderControl) {
  return {
    type: SET_GEO_CODER_CONTROL,
    geoCoderControl
  };
}

export const SET_SHOW_DATA_TABLE_CONTROL = 'SET_SHOW_DATA_TABLE_CONTROL';
export function setShowDataTableControl(showDataTableControl) {
  return {
    type: SET_SHOW_DATA_TABLE_CONTROL,
    showDataTableControl
  };
}

export const SET_LOCK_CALENDAR_VIEW_CONTROL = 'SET_LOCK_CALENDAR_VIEW_CONTROL';
export function setLockCalendarViewControl(lockCalendarViewControl) {
  return {
    type: SET_LOCK_CALENDAR_VIEW_CONTROL,
    lockCalendarViewControl
  };
}

export const SET_GEO_LOCATE_CONTROL = 'SET_GEO_LOCATE_CONTROL';
export function setGeoLocateControl(geoLocateControl) {
  return {
    type: SET_GEO_LOCATE_CONTROL,
    geoLocateControl
  };
}

export const SET_MAP_FLYOUT_TITLE_COLUMN_NAME = 'SET_MAP_FLYOUT_TITLE_COLUMN_NAME';
export function setMapFlyoutTitleColumnName(mapFlyoutTitleColumnName) {
  return {
    type: SET_MAP_FLYOUT_TITLE_COLUMN_NAME,
    mapFlyoutTitleColumnName
  };
}

export const ADD_DRILL_DOWN_COLUMN = 'ADD_DRILL_DOWN_COLUMN';
export function addDrilldownColumn(drilldownColumnName) {
  return {
    type: ADD_DRILL_DOWN_COLUMN,
    drilldownColumnName
  };
}

export const REMOVE_DRILL_DOWN_COLUMN = 'REMOVE_DRILL_DOWN_COLUMN';
export function removeDrilldownColumn(index) {
  return {
    type: REMOVE_DRILL_DOWN_COLUMN,
    index
  };
}

export const CHANGE_DRILL_DOWN_COLUMN = 'CHANGE_DRILL_DOWN_COLUMN';
export function changeDrilldownColumn(index, columnName) {
  return {
    type: CHANGE_DRILL_DOWN_COLUMN,
    columnName,
    index
  };
}

export const ADD_TABLE_COLUMN = 'ADD_TABLE_COLUMN';
export function addTableColumn(tableColumnName, columns) {
  return {
    type: ADD_TABLE_COLUMN,
    tableColumnName,
    columns
  };
}

export const REMOVE_TABLE_COLUMN = 'REMOVE_TABLE_COLUMN';
export function removeTableColumn(index) {
  return {
    type: REMOVE_TABLE_COLUMN,
    index
  };
}

export const SELECT_ALL_COLUMNS = 'SELECT_ALL_COLUMNS';
export function selectAllTableColumns(columns) {
  return {
    type: SELECT_ALL_COLUMNS,
    columns
  };
}

export const RESET_ALL_COLUMNS = 'RESET_ALL_COLUMNS';
export function resetAllColumns() {
  return {
    type: RESET_ALL_COLUMNS
  };
}

export const REORDER_TABLE_COLUMNS = 'REORDER_TABLE_COLUMNS';
export function reorderTableColumns(newOrder) {
  return {
    type: REORDER_TABLE_COLUMNS,
    newOrder
  };
}

export const NEW_TABLE_HIERARCHY = 'NEW_TABLE_HIERARCHY';
export function newTableHierarchy() {
  return {
    id: Math.random().toString(36).slice(2),
    type: NEW_TABLE_HIERARCHY
  };
}

export const UPDATE_COLUMN_FORMAT = 'UPDATE_COLUMN_FORMAT';
export function updateColumnFormat(updatedColumnFormat, columnIndex) {
  return {
    updatedColumnFormat,
    columnIndex,
    type: UPDATE_COLUMN_FORMAT
  };
}

export const UPDATE_TABLE_VIZ_COLUMN_FORMAT = 'UPDATE_TABLE_VIZ_COLUMN_FORMAT';
export function updateTableVisualizationColumnFormat(updatedColumnFormat, columnName) {
  return {
    updatedColumnFormat,
    columnName,
    type: UPDATE_TABLE_VIZ_COLUMN_FORMAT
  };
}

export const UPDATE_COLUMN_FORMAT_STYLE = 'UPDATE_COLUMN_FORMAT_STYLE';
export function updateColumnFormatStyle(updatedColumnFormatStyle, columnStyleIndex) {
  return {
    updatedColumnFormatStyle,
    columnStyleIndex,
    type: UPDATE_COLUMN_FORMAT_STYLE
  };
}

export const UPDATE_TABLE_VIZ_COLUMN_STYLE = 'UPDATE_TABLE_VIZ_COLUMN_STYLE';
export function updateTableVisualizationColumnStyle(updatedColumnFormatStyle, columnName) {
  return {
    updatedColumnFormatStyle,
    columnName,
    type: UPDATE_TABLE_VIZ_COLUMN_STYLE
  };
}

export const UPDATE_TABLE_VIZ_COLUMN_STYLE_CONDITION = 'UPDATE_TABLE_VIZ_COLUMN_STYLE_CONDITION';
export function updateTableVisualizationColumnStyleCondition(updatedColumnFormatStyle, columnName) {
  return {
    updatedColumnFormatStyle,
    columnName,
    type: UPDATE_TABLE_VIZ_COLUMN_STYLE_CONDITION
  };
}

export const UPDATE_TABLE_VIZ_HEADER_STYLE = 'UPDATE_TABLE_VIZ_HEADER_STYLE';
export function updateTableVisualizationHeaderStyle(updatedHeaderFormatStyle) {
  return {
    updatedHeaderFormatStyle,
    type: UPDATE_TABLE_VIZ_HEADER_STYLE
  };
}

export const UPDATE_TABLE_VIZ_HEADER_TEXT_STYLE = 'UPDATE_TABLE_VIZ_HEADER_TEXT_STYLE';
export function updateTableVisualizationHeaderTextStyle(updatedHeaderFormatTextStyle) {
  return {
    updatedHeaderFormatTextStyle,
    type: UPDATE_TABLE_VIZ_HEADER_TEXT_STYLE
  };
}

export const UPDATE_ROW_FORMAT = 'UPDATE_ROW_FORMAT';
export function updateRowFormat(updatedRowFormat, rowIndex) {
  return {
    updatedRowFormat,
    rowIndex,
    type: UPDATE_ROW_FORMAT
  };
}

export const UPDATE_ROW_FORMAT_STYLE = 'UPDATE_ROW_FORMAT_STYLE';
export function updateRowFormatStyle(updatedRowFormatStyle, rowStyleIndex) {
  return {
    updatedRowFormatStyle,
    rowStyleIndex,
    type: UPDATE_ROW_FORMAT_STYLE
  };
}

export const NEW_COLUMN_FORMAT = 'NEW_COLUMN_FORMAT';
export function newColumnFormat() {
  return {
    type: NEW_COLUMN_FORMAT,
    newColumnFormat: {
      columnName: '',
      displayName: '',
      conditionalType: 'condition',
      colorPalette: 'accent',
      colorPaletteType: 'default',
      isFormatValue: true,
      isAlignHeader: false,
      isFormatTotal: false,
      format:{},
      style: {
        backgroundColor: '#fff',
        textColor: 'black',
        textFont: ''
      },
      expression: {
        function:'=',
        arguments:{
          start:'',
          end:'',
          value:''
        }
      }
    }
  };
}

export const REMOVE_COLUMN_FORMAT = 'REMOVE_COLUMN_FORMAT';
export function removeColumnFormat(index) {
  return {
    type: REMOVE_COLUMN_FORMAT,
    index
  };
}

export const NEW_ROW_FORMAT = 'NEW_ROW_FORMAT';
export function newRowFormat() {
  return {
    type: NEW_ROW_FORMAT,
    newRowFormat: {
      columnName: '',
      conditionalType: 'condition',
      colorPalette: 'accent',
      colorPaletteType: 'default',
      style: {
        backgroundColor: '#fff',
        textColor: 'black',
        textFont: ''
      },
      expression: {
        function:'=',
        arguments:{
          start:'',
          end:'',
          value:''
        }
      }
    }
  };
}

export const REMOVE_ROW_FORMAT = 'REMOVE_ROW_FORMAT';
export function removeRowFormat(index) {
  return {
    type: REMOVE_ROW_FORMAT,
    index
  };
}

export const SET_ACTIVE_HIERARCHY_ID = 'SET_ACTIVE_HIERARCHY_ID';
export function setActiveHierarchyId(hierarchyId) {
  return {
    type: SET_ACTIVE_HIERARCHY_ID,
    hierarchyId
  };
}

export const SET_NON_STANDARD_AGGREGATION = 'SET_NON_STANDARD_AGGREGATION';
export function setNonStandardAggregation(value) { // boolean
  return {
    type: SET_NON_STANDARD_AGGREGATION,
    value
  };
}

export const SHOW_GRAND_TOTAL = 'SHOW_GRAND_TOTAL';
export function showGrandTotal(hierarchyIndex, isShowing) { // boolean
  return {
    type: SHOW_GRAND_TOTAL,
    hierarchyIndex,
    isShowing
  };
}

export const SHOW_SUB_TOTAL = 'SHOW_SUB_TOTAL';
export function showSubTotal(hierarchyIndex, isShowing) { // boolean
  return {
    type: SHOW_SUB_TOTAL,
    hierarchyIndex,
    isShowing
  };
}

export const UPDATE_TABLE_HIERARCHY_NAME = 'UPDATE_TABLE_HIERARCHY_NAME';
export function updateTableHierarchyName(hierarchyIndex, name) {
  return {
    type: UPDATE_TABLE_HIERARCHY_NAME,
    hierarchyIndex,
    name
  };
}

export const UPDATE_TABLE_HIERARCHY = 'UPDATE_TABLE_HIERARCHY';
export function updateTableHierarchy(updatedColumnConfigs, index) {
  return {
    type: UPDATE_TABLE_HIERARCHY,
    updatedColumnConfigs,
    index
  };
}

export const DUPLICATE_TABLE_HIERARCHY = 'DUPLICATE_TABLE_HIERARCHY';
export function duplicateTableHierarchy(index) {
  return {
    type: DUPLICATE_TABLE_HIERARCHY,
    id: Math.random().toString(36).slice(2),
    index
  };
}

export const REMOVE_TABLE_HIERARCHY = 'REMOVE_TABLE_HIERARCHY';
export function removeTableHierarchy(index) {
  return {
    type: REMOVE_TABLE_HIERARCHY,
    index
  };
}

export const RESET_TABLE_HIERARCHY = 'RESET_TABLE_HIERARCHY';
export function resetTableHierarchy(index) {
  return {
    type: RESET_TABLE_HIERARCHY,
    index
  };
}

export const ADD_BASEMAP_FLYOUT_COLUMN = 'ADD_BASEMAP_FLYOUT_COLUMN';
export function addBasemapFlyoutColumn(columnName) {
  return {
    type: ADD_BASEMAP_FLYOUT_COLUMN,
    columnName
  };
}

export const REMOVE_BASEMAP_FLYOUT_COLUMN = 'REMOVE_BASEMAP_FLYOUT_COLUMN';
export function removeBasemapFlyoutColumn(relativeIndex) {
  return {
    type: REMOVE_BASEMAP_FLYOUT_COLUMN,
    relativeIndex
  };
}

export const CHANGE_ADDITIONAL_FLYOUT_COLUMN = 'CHANGE_ADDITIONAL_FLYOUT_COLUMN';
export function changeAdditionalFlyoutColumn(relativeIndex, columnName) {
  return {
    type: CHANGE_ADDITIONAL_FLYOUT_COLUMN,
    columnName,
    relativeIndex
  };
}

export const SET_SHOW_ANNOTATIONS_IN_LEGEND = 'SET_SHOW_ANNOTATIONS_IN_LEGEND';
export function setShowAnnotationsInLegend(showAnnotationsInLegend) {
  return {
    type: SET_SHOW_ANNOTATIONS_IN_LEGEND,
    showAnnotationsInLegend
  };
}

export const SET_ANNOTATIONS = 'SET_ANNOTATIONS';
export function setAnnotations(annotations) {
  return {
    type: SET_ANNOTATIONS,
    annotations
  };
}

export const ADD_ANNOTATION = 'ADD_ANNOTATION';
export function addAnnotation(annotation) {
  return {
    type: ADD_ANNOTATION,
    annotation
  };
}

export const REMOVE_ANNOTATION = 'REMOVE_ANNOTATION';
export function removeAnnotation(index) {
  return {
    type: REMOVE_ANNOTATION,
    index
  };
}

export const UPDATE_ANNOTATION = 'UPDATE_ANNOTATION';
export function updateAnnotation(index, annotation) {
  return {
    type: UPDATE_ANNOTATION,
    annotation,
    index
  };
}

export const ADD_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION = 'ADD_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION';
export function addRegionMapFlyoutColumnAndAggregation(columnAndAggregation) {
  return {
    type: ADD_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION,
    columnAndAggregation
  };
}

export const REMOVE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION = 'REMOVE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION';
export function removeRegionMapFlyoutColumnAndAggregation(index) {
  return {
    type: REMOVE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION,
    index
  };
}

export const CHANGE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION = 'CHANGE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION';
export function changeRegionMapFlyoutColumnAndAggregation(index, columnAndAggregation) {
  return {
    type: CHANGE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION,
    columnAndAggregation,
    index
  };
}

export const SET_ADDITIONAL_FLYOUT_COLUMNS = 'SET_ADDITIONAL_FLYOUT_COLUMNS';
export function setAdditionalFlyoutColumns(columns) {
  return {
    type: SET_ADDITIONAL_FLYOUT_COLUMNS,
    columns
  };
}

export const SET_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION = 'SET_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION';
export function setRegionMapFlyoutColumnAndAggregations(columnAndAggregation) {
  return {
    type: SET_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION,
    columnAndAggregation
  };
}

export const SET_SEARCH_BOUNDARY_UPPER_LEFT_LATITUDE = 'SET_SEARCH_BOUNDARY_UPPER_LEFT_LATITUDE';
export function setSearchBoundaryUpperLeftLatitude(searchBoundaryUpperLeftLatitude) {
  return {
    type: SET_SEARCH_BOUNDARY_UPPER_LEFT_LATITUDE,
    searchBoundaryUpperLeftLatitude
  };
}

export const SET_SEARCH_BOUNDARY_UPPER_LEFT_LONGITUDE = 'SET_SEARCH_BOUNDARY_UPPER_LEFT_LONGITUDE';
export function setSearchBoundaryUpperLeftLongitude(searchBoundaryUpperLeftLongitude) {
  return {
    type: SET_SEARCH_BOUNDARY_UPPER_LEFT_LONGITUDE,
    searchBoundaryUpperLeftLongitude
  };
}

export const SET_SEARCH_BOUNDARY_LOWER_RIGHT_LATITUDE = 'SET_SEARCH_BOUNDARY_LOWER_RIGHT_LATITUDE';
export function setSearchBoundaryLowerRightLatitude(searchBoundaryLowerRightLatitude) {
  return {
    type: SET_SEARCH_BOUNDARY_LOWER_RIGHT_LATITUDE,
    searchBoundaryLowerRightLatitude
  };
}

export const SET_SEARCH_BOUNDARY_LOWER_RIGHT_LONGITUDE = 'SET_SEARCH_BOUNDARY_LOWER_RIGHT_LONGITUDE';
export function setSearchBoundaryLowerRightLongitude(searchBoundaryLowerRightLongitude) {
  return {
    type: SET_SEARCH_BOUNDARY_LOWER_RIGHT_LONGITUDE,
    searchBoundaryLowerRightLongitude
  };
}

export const SET_PITCH_AND_BEARING = 'SET_PITCH_AND_BEARING';
export function setPitchAndBearing(pitchAndBearing) {
  return {
    type: SET_PITCH_AND_BEARING,
    pitchAndBearing
  };
}

export const SET_MAP_ZOOM_LEVEL = 'SET_MAP_ZOOM_LEVEL';
export function setMapZoomLevel(mapZoomLevel) {
  return {
    type: SET_MAP_ZOOM_LEVEL,
    mapZoomLevel
  };
}

export const SET_SHAPE_FILL_COLOR = 'SET_SHAPE_FILL_COLOR';
export function setShapeFillColor(shapeFillColor) {
  return {
    type: SET_SHAPE_FILL_COLOR,
    shapeFillColor
  };
}

export const SET_SHAPE_FILL_OPACITY = 'SET_SHAPE_FILL_OPACITY';
export function setShapeFillOpacity(shapeFillOpacity) {
  return {
    type: SET_SHAPE_FILL_OPACITY,
    shapeFillOpacity
  };
}

export const SET_LINE_COLOR_OPACITY = 'SET_LINE_COLOR_OPACITY';
export function setLineColorOpacity(lineColorOpacity) {
  return {
    type: SET_LINE_COLOR_OPACITY,
    lineColorOpacity
  };
}

export const SET_LINE_STYLE_POINTS = 'SET_LINE_STYLE_POINTS';
export function setLineStylePoints(points) {
  return {
    type: SET_LINE_STYLE_POINTS,
    points
  };
}

export const SET_LINE_STYLE_POINT_RADIUS = 'SET_LINE_STYLE_POINT_RADIUS';
export function setLineStylePointRadius(pointRadius) {
  return {
    type: SET_LINE_STYLE_POINT_RADIUS,
    pointRadius
  };
}

export const SET_SHAPE_OUTLINE_COLOR = 'SET_SHAPE_OUTLINE_COLOR';
export function setShapeOutlineColor(shapeOutlineColor) {
  return {
    type: SET_SHAPE_OUTLINE_COLOR,
    shapeOutlineColor
  };
}

export const SET_SHAPE_OUTLINE_WIDTH = 'SET_SHAPE_OUTLINE_WIDTH';
export function setShapeOutlineWidth(shapeOutlineWidth) {
  return {
    type: SET_SHAPE_OUTLINE_WIDTH,
    shapeOutlineWidth
  };
}

export const TRIGGER_AUTO_SELECT_GEO_LOCATION_COLUMN = 'TRIGGER_AUTO_SELECT_GEO_LOCATION_COLUMN';
export function setTriggerAutoSelectGeoLocationColumn(toggle) {
  return {
    type: TRIGGER_AUTO_SELECT_GEO_LOCATION_COLUMN,
    toggle
  };
}

export const SET_TABLE_INDENTED_LAYOUT  = 'SET_TABLE_INDENTED_LAYOUT ';
export function setTableIndentedLayout(toggle) {
  return {
    type: SET_TABLE_INDENTED_LAYOUT ,
    toggle
  };
}

export const SET_TABLE_ROW_STRIPING = 'SET_TABLE_ROW_STRIPING';
export function setTableRowStripeStyleAction(tableRowStripeStyle) {
  return {
    type: SET_TABLE_ROW_STRIPING,
    tableRowStripeStyle
  };
}

/**
 * Handles setting the custom color palette of various viz types. This function
 * will use info in the Vif to determine how to set the custom color palette in
 * the vif. If a quantification method is set, it will store the custom palette
 * under series[0].color.customPalette.COLUMN_NAME.QUANTIFICATION_METHOD rather
 * than series[0].color.customPalette.COLUMN_NAME.
 */
const setCustomColorPaletteForAllVizType = ({
  colorPaletteGroupingColumnName,
  customColorPalette,
  currentSeriesIndex,
  dimensionColumnName,
  dispatch,
  vifAuthoring,
  quantificationMethod
}) => {
  if (!_.isNull(colorPaletteGroupingColumnName)) {
    if (isMap(vifAuthoring) && quantificationMethod) {
      dispatch(setCustomColorPaletteWithQuantification(customColorPalette, colorPaletteGroupingColumnName, currentSeriesIndex, quantificationMethod));
    } if (isPieChart(vifAuthoring)) { // For pie charts, we need to ensure all series (including flyouts) get the updates to color palettes
      const series = getSeries(vifAuthoring);
      _.forEach(series, (s, index) => {
        dispatch(setCustomColorPalette(customColorPalette, colorPaletteGroupingColumnName, index));
      });
    } else {
      dispatch(setCustomColorPalette(customColorPalette, colorPaletteGroupingColumnName, currentSeriesIndex));
    }
  } else {
    if (isMap(vifAuthoring) && quantificationMethod) {
      dispatch(setCustomColorPaletteWithQuantification(customColorPalette, dimensionColumnName, currentSeriesIndex, quantificationMethod));
    } else {
      dispatch(setCustomColorPalette(customColorPalette, dimensionColumnName, currentSeriesIndex));
    }
  }
};
