import _ from 'lodash';
import BigNumber from 'bignumber.js';

import assert from 'common/assertions/assert';

import {
  filterWhereClauses,
  joinWhereClauses,
  setupSoqlDataProvider
} from './helpers';

// Returns: BigNumber (when rows are present) or null (when no rows are present).
export const sum = async (dataProvider, fieldName, whereClauses) => {
  assert(whereClauses.length > 0, 'At least one where clause must be supplied.');

  const sumAlias = '__measure_sum_alias__';
  const query = `select sum(${fieldName}) as ${sumAlias} where ${joinWhereClauses(whereClauses)}`;

  const data = _.get(await dataProvider.rawQuery(query), [0, sumAlias]);
  return _.isUndefined(data) ? null : new BigNumber(data);
};

export const calculateSumMeasure = async (
  errors,
  measure,
  dateRangeWhereClause,
  dataProvider = setupSoqlDataProvider(measure), // For test injection
  sumFunction = sum // For test injection
) => {
  const column = _.get(measure, 'metricConfig.arguments.column');
  const dateColumn = _.get(measure, 'metricConfig.dateColumn');
  const calculationFilters = _.get(measure, 'metricConfig.arguments.calculationFilters', []);
  const calculationWhereClause = filterWhereClauses(calculationFilters);

  let value = null;

  if (column && dataProvider && dateRangeWhereClause) {
    const sumResult = await sumFunction(
      dataProvider,
      column,
      [calculationWhereClause, dateRangeWhereClause]
    );

    if (_.isNull(sumResult)) {
      errors.notEnoughData = true;
      value = null;
    } else {
      value = sumResult;
    }
  } else {
    errors.calculationNotConfigured = !column || !dateColumn;
  }

  return { errors, result: { value } };
};

