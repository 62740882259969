export const ACTIVITY_TAB_TYPE = 'activity';
export const DEFAULT_FILTER_TAB = 'alert';
export const FADE_TRANSIENT_NOTIFICATION_AFTER_MILLISECONDS = 4 * 1000;
export const MAX_NOTIFICATION_DISPLAY_COUNT = 99;
export const NOTIFICATIONS_PER_PAGE = 25;
export const THROTTLE_LAZY_LOADING_FOR = 300;
export const TRUNCATE_DESCRIPTION_AFTER = 150;
export const VIEW_METADATA_CHANGED = 'ViewMetadataChanged';
export const COLLABORATOR_ADDED = 'CollaboratorAdded';
export const STORY_DRAFT_CREATED = 'StoryDraftCreated';
export const USER_ACTIVITY_TYPES = ['UserAdded', 'UserRemoved', 'UserRoleChanged'];
export const AGENT_ACTIVITY_TYPES = ['AgentOnline', 'AgentOffline'];
export const DATA_UPDATE_TYPES = ['DraftPublished'];

export const DETAILS_STATII = {
  SuccessWithErrors: 'SuccessWithDataErrors'
};
export const SCHEDULE_FAILED = 'ScheduleFailed';


export enum ALERT_SETTING_MODAL_TABS {
  NOTIFICATION = 'notification',
  MY_ALERTS = 'my_alerts',
  WATCHLIST = 'watchlist'
}

export const DEFAULT_SCHEDULE_FAILURE_THRESHOLD = 5;


export const ALERT_PREFERENCE_KEYS = {
  'my_assets_updated': {},
  'my_assets_watched_asset_updated': {},
  'my_assets_collaborators_change': {},
  'my_assets_approvals': {},
  'all_assets_published': {},
  'all_assets_meta_change': {},
  'all_assets_data_change': {},
  'all_asset_permission_change': {},
  'all_asset_collaborator_change': {},
  'all_assets_deleted': {},
  'all_assets_approvals': {},
  'user_accounts': {},
  'any_schedule_failed': { consecutive_failures: DEFAULT_SCHEDULE_FAILURE_THRESHOLD },
  'my_schedule_failed': { consecutive_failures: DEFAULT_SCHEDULE_FAILURE_THRESHOLD }
};
