// WARNING: keyCode is _DEPRECATED_ and appears to have spotty React support.
// See https://socrata.atlassian.net/browse/EN-20529 and
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
//
// Consider using KeyboardEvent.key or KeyboardEvent.code, as suggested by Mozilla.
import { Key } from 'common/types/keyboard/key';
import { DOWN, ENTER, SPACE, TAB } from './keycodes_deprecated';
import _ from 'lodash';

// Checks if event is a space or an enter
export const handleKeyPress = (handler, preventDefault) => {
  return (event) => {
    // Enter or Space key code
    if (event.keyCode === ENTER || event.keyCode === SPACE || event.key === Key.Enter || event.key === ' ') {
      if (preventDefault) {
        event.preventDefault();
      }

      return handler(event);
    }
  };
};

export const handleKeyEvent = (keyCodes, handler, preventDefault = true) => {
  return (event) => {
    const match = Array.isArray(keyCodes) && keyCodes.filter(code => code === event.keyCode).length > 0;
    if (match) {
      if (preventDefault) {
        event.preventDefault();
      }
      return handler(event);
    }
  };
};

export const handleEnter = (handler, preventDefault) => {
  return (event) => {
    if (event.keyCode === ENTER || event.key === Key.Enter) {
      if (preventDefault) {
        event.preventDefault();
      }

      return handler(event);
    }
  };
};

export const handleDownArrow = (handler, preventDefault) => {
  return (event) => {
    // See https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8860571/
    if (event.keyCode === DOWN || event.key === Key.ArrowDown || event.key === Key.Down_MSIE) {
      if (preventDefault) {
        event.preventDefault();
      }

      return handler(event);
    }
  };
};

// This function is to help with navigating by tabbing.
// shiftHandler: function that happens when you shift+tab onto something
// handler: function that happens when you tab onto something
export const handleTab = (handler, shiftHandler, preventDefault) => {
  return (event) => {
    if (event.keyCode === TAB || event.key === Key.Tab) {
      if (preventDefault) {
        event.preventDefault();
      }

      if (event.shiftKey && _.isFunction(shiftHandler)) {
        return shiftHandler(event);
      }

      if (_.isFunction(handler)) {
        return handler(event);
      }
    }
  };
};
