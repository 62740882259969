// Vendor Imports
import _ from 'lodash';

// Project Imports
import makeSocrataCategoricalDataRequest from './makeSocrataCategoricalDataRequest';
import { applyOrderBy, applyOrderByToErrorBars } from 'common/visualizations/helpers/SortHelpers';

export function getData(vif, options) {
  const dataRequests = vif.series.map((series, seriesIndex) => {
    const type = _.get(series, 'dataSource.type');

    switch (type) {

      case 'socrata.soql':
        return makeSocrataCategoricalDataRequest(
          vif,
          seriesIndex,
          options.MAX_ROW_COUNT
        );

      default:
        return Promise.reject(
          `Invalid/unsupported series dataSource.type: "${type}".`
        );
    }
  });

  function mapUngroupedDataResponsesToMultiSeriesTable(dataResponses) {
    const dimensionIndex = 0;
    const measureIndex = 1;
    const errorBarsIndex = 1;
    const measureLabels = vif.series.map((series) => _.get(series, 'label', ''));
    const uniqueDimensionValues = _.uniq(
      _.flatMap(
        dataResponses.map((dataResponse) => {
          return dataResponse.rows.map((row) => row[dimensionIndex]);
        })
      )
    );
    const dataToRenderColumns = ['dimension'].concat(measureLabels);
    const dataToRenderRows = uniqueDimensionValues.map(
      (uniqueDimensionValue) => {

        const row = [uniqueDimensionValue];
        dataResponses.forEach((dataResponse) => {

          const rowForDimension = _.find(
            dataResponse.rows,
            (dataResponseRow) => (dataResponseRow[dimensionIndex] === uniqueDimensionValue));

          const measureValue = rowForDimension ? rowForDimension[measureIndex] : null;
          row.push(measureValue);
        });

        return row;
      }
    );

    const response = {
      columns: dataToRenderColumns,
      rows: dataToRenderRows
    };

    // Apply the date display format if specified
    //
    const dateDisplayFormat = _.get(vif, 'series[0].dataSource.dateDisplayFormat');

    if (!_.isNil(dateDisplayFormat)) {
      response.dateDisplayFormat = dateDisplayFormat;
    }

    // See if the first response has errorBars.  Either they all do or none do.
    //
    if ((dataResponses.length > 0) && !_.isUndefined(dataResponses[0].errorBars)) {
      const dataToRenderErrorBars = uniqueDimensionValues.map(
        (uniqueDimensionValue) => {

          const row = [uniqueDimensionValue];
          dataResponses.forEach((dataResponse) => {

            const rowForDimension = _.find(
              dataResponse.errorBars,
              (dataResponseRow) => (dataResponseRow[dimensionIndex] === uniqueDimensionValue));

            const errorBarsValue = rowForDimension ? rowForDimension[errorBarsIndex] : null;
            row.push(errorBarsValue);
          });

          return row;
        }
      );

      response.errorBars = dataToRenderErrorBars;
    }

    return response;
  }

  return Promise.
    all(dataRequests).
    then(mapUngroupedDataResponsesToMultiSeriesTable).
    then((dataTable) => applyOrderBy(dataTable, vif)).
    then(applyOrderByToErrorBars);
}

export default {
  getData
};
