import _ from 'lodash';


export const isTransientNotificationEnabled = state => _.get(state, 'preferenceSettings.isTransientNotificationEnabled');

export const getShowNotificationPanelFlag = state => _.get(
    state,
    'panelConfigs.showNotificationPanel',
    false
  );

export const getShowAlertFlag = state => _.get(state, 'configurations.showMyAlertPreference', false);

export const getEnqueuedNotifications = state => _.get(state, 'userNotificationsConfigs.enqueuedNotifications', []);
export const getTransientNotifications = state => _.get(state, 'userNotificationsConfigs.transientNotifications', []);
export const getUserNotificationsConfigs = state => {
  return _.get(state, 'userNotificationsConfigs', []);
};
export const getUserNotifications = state => _.get(state, 'userNotificationsConfigs.userNotifications', []);
