import React from 'react';
import I18n from 'common/i18n';
import './NoRowsAgGridOverlay.scss';

interface NoRowsAgGridOverlayProps {
  outerDivClassName?: string;
  imageClassName?: string;
  textSectionClassName?: string;
}

const NoRowsAgGridOverlay = ({
  outerDivClassName,
  imageClassName,
  textSectionClassName
}: NoRowsAgGridOverlayProps) => (
  <div className={outerDivClassName}>
    <img
      className={imageClassName}
      src="https://cdn.forge.tylertech.com/v1/images/spot-hero/no-search-results-spot-hero.svg"
      alt=""
    />
    <div className={textSectionClassName}>
      <h3 className="forge-typography--title">{I18n.t('shared.no_rows_overlay.title')}</h3>
      <p className="forge-typography--body2">{I18n.t('shared.no_rows_overlay.body')}</p>
    </div>
  </div>
);

export default NoRowsAgGridOverlay;
