export enum ToastType {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  FORGE_DEFAULT = 'forge_default',
  FORGE_SUCCESS = 'forge_success',
  FORGE_ERROR = 'forge_error'
}

