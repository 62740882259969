import { Expr, TypedExpr, FunCall, Scope, SoQLType, isFixed, isFunCall, typedNullLiteral, isTypedFunCall, TypedSoQLFunCall } from 'common/types/soql';
import * as _ from 'lodash';
import React, { ReactNode, useState } from 'react';
import I18n from 'common/i18n';
import { ViewColumnColumnRef } from '../../lib/selectors';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import ChangeFunction from './ChangeFunction';
import { FeatureFlags } from 'common/feature_flags';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { clientContextVariableToParameter } from 'common/core/client_context_variables';
import { ForgeIcon } from '@tylertech/forge-react';
import { dateToString } from 'common/explore_grid/lib/soql-helpers';
import { ForgeMenu, ForgeIconButton } from '@tylertech/forge-react';
import { IMenuOption, IconComponentDelegate } from '@tylertech/forge';
import { getSoqlTypeForgeIconBuilder } from 'common/explore_grid/lib/forge-icon-builder';
import { whichAnalyzer } from 'common/explore_grid/lib/feature-flag-helpers';
import { Either } from 'common/either';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.vee_kebab_menu' });

const funcall = (name: string, args: Expr[]): FunCall => ({ type: 'funcall', function_name: name, args, window: null });
const typedFuncall = (name: string, soql_type: SoQLType, args: TypedExpr[]): TypedSoQLFunCall => ({ type: 'funcall', function_name: name, args, window: null, soql_type });
const nullExpr: Expr = { type: 'null_literal', value: null };
const typedNullExpr: TypedExpr = { type: 'null_literal', value: null, soql_type: null };

interface Props {
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  isTypeAllowed: (st: SoQLType) => boolean;
  scope: Scope;
  toWrap?: ReactNode;
  update: (newExpr: Either<Expr, TypedExpr>) => void;
}

const KebabMenu = (props: Props) => {
  const [showingSubmenu, setShowingSubmenu] = useState<boolean>(false);

  const possibleFunction = props.scope.find(fs => {
    const result = fs.result;
    if (result.kind === 'fixed') {
      return props.isTypeAllowed(result.type);
    } else {
      return true;
    }
  });
  if (showingSubmenu && possibleFunction && isFixed(possibleFunction.result)) {
    const untyped = funcall(possibleFunction.name, possibleFunction.sig.map(() => nullExpr));
    const typed = {
      ...untyped,
      args: untyped.args.map(arg => typedNullExpr), // They're all nulls, so there's only one layer.
      window: null,
      soql_type: possibleFunction.result.type
    };
    const eexpr = whichAnalyzer(() => ({ untyped, typed }), () => ({ expr: typed }))();

    return <div className="kebab-submenu">
      <div className="kebab-submenu-back" onClick={() => setShowingSubmenu(false)}>
        <SocrataIcon name={IconName.ArrowLeft} />
        {t('back')}
      </div>
      <ChangeFunction
        columns={[]}
        parameters={[]}
        remove={_.noop}
        showRemove={true}
        showKebab={false}
        isTypeAllowed={props.isTypeAllowed}
        eexpr={eexpr}
        scope={props.scope}
        update={(e: Either<Expr, TypedExpr>) => {
          if (e.fold(isFunCall, isTypedFunCall)) {
            props.update(e);
            setShowingSubmenu(false);
          }
        }}
        showPickerByDefault={true}
        onBlur={() => setShowingSubmenu(false)} />
    </div>;
  }

  const toWrap = props.toWrap || (
    <ForgeIconButton>
      <button
        type="button"
        data-testid="kebab-btn-default"
        aria-label={t('button_label')}
        className="tyler-icons">
        <ForgeIcon name="more_vert" />
      </button>
    </ForgeIconButton>
  );

  const getOptions = (): IMenuOption<TypedExpr>[] => {
    let options: IMenuOption[] = [];
    if (props.isTypeAllowed(SoQLType.SoQLTextT)) {
      options.push({
        value: { type: 'string_literal', value: '', soql_type: SoQLType.SoQLTextT },
        label: t('text'),
        leadingBuilder: () => getSoqlTypeForgeIconBuilder('text'),
      });
    }
    if (props.isTypeAllowed(SoQLType.SoQLNumberT)) {
      options.push(
        {
          value: { type: 'number_literal', value: '0', soql_type: SoQLType.SoQLNumberT },
          label: t('number'),
          leadingBuilder: () => getSoqlTypeForgeIconBuilder('number'),
        }
      );
    }
    if (props.isTypeAllowed(SoQLType.SoQLBooleanT)) {
      options.push(
        {
          value: { type: 'boolean_literal', value: true, soql_type: SoQLType.SoQLBooleanT },
          label: t('boolean'),
          leadingBuilder: () => getSoqlTypeForgeIconBuilder('boolean'),
        }
      );
    }
    if (props.isTypeAllowed(SoQLType.SoQLFloatingTimestampAltT) || props.isTypeAllowed(SoQLType.SoQLFloatingTimestampT)) {
      options.push(
        {
          value: { type: 'funcall', window: null, function_name: 'cast$floating_timestamp', label: t('date'), args: [
            {
              type: 'string_literal',
              value: dateToString(new Date(), SoQLType.SoQLFloatingTimestampT).get
            }],
            soql_type: SoQLType.SoQLFloatingTimestampT
          },
          label: t('date'),
          leadingBuilder: () => getSoqlTypeForgeIconBuilder('date'),
        }
      );
    }

    const aColumnOfTheRequiredType = _.first(props.columns.filter(c => props.isTypeAllowed(c.typedRef.soql_type)));
    if (aColumnOfTheRequiredType) {
      options.push({
        value: aColumnOfTheRequiredType.typedRef,
        label: t('column'),
        leadingBuilder: () => getSoqlTypeForgeIconBuilder('column'),
      });
    }

    const aParameterOfTheRequiredType = _.first(props.parameters.filter(ccv => props.isTypeAllowed(ccv.dataType)));
    if (aParameterOfTheRequiredType) {
      options.push({
        value: { ...clientContextVariableToParameter(aParameterOfTheRequiredType), soql_type: clientContextVariableToParameter(aParameterOfTheRequiredType).type },
        label: t('parameter'),
        leadingBuilder: () => getSoqlTypeForgeIconBuilder('parameter'),
      });
    }

    if (possibleFunction && isFixed(possibleFunction.result) && !FeatureFlags.valueOrDefault('remove_vqe_add_function', false)) {
      options = options.concat([
        {
          value: typedFuncall(possibleFunction.name, possibleFunction.result.type, possibleFunction.sig.map(() => typedNullExpr)),
          label: t('function'),
          leadingBuilder: () => getSoqlTypeForgeIconBuilder('function'),
          trailingBuilder: () => {
            // The forge stuff is a bit misleading with its types so doing a cast here to avoid TS complaining
            return (new IconComponentDelegate({ props: { name: 'keyboard_arrow_right' } }).element as unknown as HTMLElement);
          }
        }
      ]);
    }
    return options;
  };

  return (
    <div className="change-ast-node-type">
       <ForgeMenu
        options={getOptions()}
        placement='bottom-end'
        dense={true}
        data-testid='change-ast-node-type-menu'
        on-forge-menu-select={(e: CustomEvent<IMenuOption<TypedExpr>>) =>{
          // we want to suppress the function menu when we are doing "use date" since we have already set the function type
          if (e.detail.value.type === 'funcall' && e.detail.label != t('date')) {
            setShowingSubmenu(true);
          } else {
            props.update(whichAnalyzer(() => e.detail.value, () => e.detail.value)());
          }
        }}
      >
        {toWrap}
      </ForgeMenu>
    </div>
  );
};

export default KebabMenu;
