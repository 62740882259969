import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import './index.scss';

export type FlyoutDirection = 'left' | 'right';

export enum FlyoutDirectionEnum {
  Left = 'left',
  Right = 'right'
}

/**
 * Each number represents a pixel value.
 */
export interface FlyoutOffset {
  x: number,
  y: number,
}

interface AdvancedFlyoutProps {

  // The element to attach the flyout.
  children: React.ReactElement[] | React.ReactElement;

  flyoutDirection?: FlyoutDirection;
  flyoutContent: string | React.ReactElement;
  flyoutElementId: string;

  // Trigger the action on click rather than on hover. By default it will be on hover & focus.
  triggerOnClick?: boolean;

  // Add a custom x or y offset to the flyout
  flyoutOffset?: FlyoutOffset;

  flyoutPosition?: FlyoutPosition;
  // This disables blurring on the container.
  // This allows the user to tab through to something tab-able on the flyout.
  // Without this, the flyout will disappear after tabbing away from the container.
  allowTabToFlyout?: boolean;
}

/**
 * @param left - CSS left property using CSS unit value of px or %
 * @param right - CSS right property using CSS unit value of px or %
 * @param top - CSS top property using CSS unit value of px or %
 */
export interface FlyoutPosition {
  left?: string,
  right?: string,
  top: string
}

const FlyoutArrowHeight = 18;

const AdvancedFlyout: React.FunctionComponent<AdvancedFlyoutProps> = (props: AdvancedFlyoutProps) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const [calculatedFlyoutPosition, setFlyoutPosition] = useState<FlyoutPosition>({
    top: '0',
    left: '0'
  });
  const flyoutContainerRef = useRef<HTMLDivElement>(null);

  const {
    flyoutDirection = 'left',
    flyoutPosition,
    flyoutElementId,
    flyoutContent,
    triggerOnClick = false,
    allowTabToFlyout = false,
    flyoutOffset = {
      x: 0,
      y: 0
    },
    children } = props;

  const flyoutClass = classNames('advanced-flyout', {
    'flyout-hidden': hidden,
    'flyout-right': flyoutDirection === FlyoutDirectionEnum.Right,
    'flyout-left': flyoutDirection === FlyoutDirectionEnum.Left
  });

  const childrenWithProps = React.Children.map(children,
    child => React.cloneElement(child)
  );


  /**
   * Converts a number to string with `px` at the end.
   * If value is null or undefined, return '0px'
   * @param val - number value to convert to string with appended `px`
   */
  const toPixel = (val: number | undefined): string => {
    if (val !== undefined && val !== null) {
      return `${val}px`;
    }

    return '0px';
  };

  /**
   * Gets the flyout position in CSS 'left', 'top', and/or 'right' values
   * depending on the flyout direction.
   * @param direction - A flyout direction
   */
  const getPosition = (direction: FlyoutDirection): FlyoutPosition => {
    const containerBoundingBox = flyoutContainerRef.current?.getBoundingClientRect(),
          containerWidth = containerBoundingBox?.width ?? 0,
          containerTopPos = containerBoundingBox?.height ?? 0,
          topPos = containerTopPos + FlyoutArrowHeight + flyoutOffset.y,
          hPos = containerWidth / 2 + flyoutOffset.x;

    switch (direction) {
      case FlyoutDirectionEnum.Left:
        return {
          right: toPixel(hPos),
          top: toPixel(topPos),
        };
      case FlyoutDirectionEnum.Right:
        return {
          left: toPixel(hPos),
          top: toPixel(topPos),
        };
      default:
        throw new Error('No flyout position is defined');
    }
  };

  const onContainerMouseEnter = () => {
    if (!triggerOnClick) {
      setHidden(false);
    }
  };

  const onContainerFocus = () => {
    if (!triggerOnClick) {
      setHidden(false);
    }
  };

  /**
   * Handles shift-tab and enter key events.
   * Shift-tab on the container should hide the message.
   * Enter key should toggle the message visibility.
   * @param e - Keyboard Event
   */
  const onContainerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.shiftKey && e.key === 'Tab') {
      setHidden(true);
    } else if (e.key === 'Enter') {
      if (triggerOnClick) {
        setHidden(!hidden);
      }
    }
  };

  const onContainerBlur = () => {
    if (!allowTabToFlyout) {
      setHidden(true);
    }
  };

  const onContentBlur = () => {
    setHidden(true);
  };

  const onContainerMouseLeave = () => {
    if (!triggerOnClick) {
      setHidden(true);
    }
  };

  const onContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (triggerOnClick) {
      setHidden(!hidden);
    }
  };

  useEffect(() => {
    setFlyoutPosition(getPosition(flyoutDirection));
  },[]);

  return (
    <div ref={flyoutContainerRef}
      className="advanced-flyout-container"
      onMouseEnter={onContainerMouseEnter}
      onFocus={onContainerFocus}
      onClick={onContainerClick}
      onMouseLeave={onContainerMouseLeave}
      onBlur={onContainerBlur}
      tabIndex={0}
      role={(triggerOnClick) ? 'button' : 'tooltip'}
      onKeyDown={onContainerKeyDown}>
        {childrenWithProps}
      <div
        style={flyoutPosition || calculatedFlyoutPosition}
        aria-hidden={hidden}
        onBlur={onContentBlur}
        className={flyoutClass} id={flyoutElementId}>
          {_.isString(flyoutContent) ? <section className="flyout-content"><p>{flyoutContent}</p></section> : flyoutContent }
      </div>
    </div>
  );
};

export default AdvancedFlyout;
