import { combineReducers } from 'redux';
import UserNotificationReducers from 'common/notifications/reducers/UserNotificationReducers';
import PanelReducers from 'common/notifications/reducers/PanelReducers';
import PreferenceReducer from 'common/notifications/reducers/PreferenceReducer';

export default combineReducers({
  userNotificationsConfigs: UserNotificationReducers,
  configurations: (state = {}) => state,
  userId: (state = {}) => state,
  panelConfigs: PanelReducers,
  preferenceSettings: PreferenceReducer
});


