import PropTypes from 'prop-types';
import getOr from 'lodash/fp/getOr';

/**
 Proptype Utility Functions
 */

/**
 * Verify that the items passed as prop to a component are all of the desired type, based on their 'displayName'.
 * The displayName for a component can typically be inferred by React and not need to be explicitly set, but
 * in situations where components are being manipulated by higher-order components or other non-standard means,
 * you may need to explicitly set the static `displayName` property.
 * @example
 * class MyComponent extends React.Component {
 *   static propTypes = {
 *     children: componentsOfType('ChildComponent')
 *   }
 *   render() {
 *     return <b>{children}</b>
 *   }
 * }
 * @param displayName
 * @returns PropType definition
 */
export const componentsOfType = (displayName) =>
  componentsOfTypeWithError(
    displayName,
    (componentName, propFullName) =>
      `Non-TableColumn prop '${propFullName}' supplied to '${componentName}'. Received ${displayName}.`
  );

/**
 * componentsOfType, but allowing for a custom error message handler
 * @param displayName
 * @param onError
 * @returns PropType definition
 */
export const componentsOfTypeWithError = (displayName, onError) =>
  PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    const typeDisplayName = getOr(null, `[${key}].type.displayName`, propValue);
    if (typeDisplayName == null) {
      return;
    }
    if (typeDisplayName !== displayName) {
      return new Error(onError(componentName, propFullName, typeDisplayName));
    }
  });

/**
 * Verify that one of two props is provided.
 * @example
 * const eitherPathOrPathSelector = propTypeEitherRequired({
 *  path: PropTypes.string,
 *  pathSelector: PropTypes.func
 * });

 * export class MyComponent extends Component {
 *  static propTypes = {
 *    path: eitherPathOrPathSelector,
 *    pathSelector: eitherPathOrPathSelector
 *  }
 *  ...
 */

export const propTypeEitherRequired = (propTypes) => (props, propName, componentName) => {
  const [propName1, propName2] = Object.keys(propTypes);

  if (!props.hasOwnProperty(propName1) && !props.hasOwnProperty(propName2)) {
    return new Error(`Either "${propName1}" or "${propName2}" is required`);
  }

  if (propName === propName1) {
    return PropTypes.checkPropTypes({ [propName1]: propTypes[propName1] }, props, propName, componentName);
  }
  if (propName === propName2) {
    return PropTypes.checkPropTypes({ [propName2]: propTypes[propName2] }, props, propName, componentName);
  }
  return false;
};
