import { isEqual } from 'lodash';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!isEqual(value, ref.current)) ref.current = value;

  return ref.current;
}

/**
 * Custom useEffect hook that compares dependencies deeply. It should be used sparingly in cases when
 * executing the effect is expensive and/or when the effect needs to be executed only when the dependencies
 * have changed deeply.
 * @param effect
 * @param dependencies
 */
export const useDeepCompareEffect = (effect: EffectCallback, dependencies: DependencyList) => {
  useEffect(effect, dependencies.map(useDeepCompareMemoize));
};
