import { withGuidanceV2 } from 'common/core/approvals/index_new';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { View } from 'common/types/view';
import { assetIsDraft } from 'common/views/helpers';

/**
 * This represents the status of an asset in the system.
 *
 * In general...
 * - Drafts can have _some_ actions taken on them and can be published.
 * - Pending assets are in the approvals queue and need to have
 *   the approval request canceled before being able to do anything to them
 * - Published assets can have all sorts of things done to them!
 *
 * See all of the `current_user_can_*` files in `common/views` for more information about what can be done to assets
 * with each of these statuses.
 */
export enum AssetStatus {
  /**
   * This will be the asset's status if it has been submitted to an approvals workflow, but has not yet
   * been approved.
   *
   * This tends to take precedence over the other statuses.
   */
  Pending = 'pending',

  /**
   * "Draft" can mean any number of things!
   *
   * If you are looking at a DSMAPI revision within the context of DSMUI, this will be the asset's status
   * (by virtue of the existence of `window.initialState.revisionSeq`)
   *
   * If you are looking at a story draft within the context of storyteller OR a view from the catalog,
   * this will be the asset's status (by virtue of the existence of `window.IS_STORY_PUBLISHED === false`)
   *
   * Otherwise, this will be the asset's status if its `publicationStage` is `unpublished`.
   */
  Draft = 'draft',

  /** This will be the asset's status only if it is published (not a draft) AND approved! */
  Published = 'published'
}

/**
 * Combines approvals status and publication/revision/story draft state to provide a holistic asset status.
 *
 * @param coreView View to get status for
 * @param approvalsGuidance Approvals guidance summary for view
 * @return The status of the asset
 */
export const assetStatusFor = (coreView: Partial<View>, approvalsGuidance: GuidanceSummaryV2) => {
  // If an asset is pending approval, that takes precedence over all other statuses.
  // This is because the approval request usually has to be rescinded before any other actions can be taken on the asset.
  if (withGuidanceV2(approvalsGuidance).isPending()) {
    return AssetStatus.Pending;
  }

  // Note that this leverages a lot of the default values for this.
  // This is more less true based on:
  // - The existence of `window.initialState.revisionSeq` (a DSMAPI revision and we're in DSMUI)
  // - The existence of `window.IS_STORY_PUBLISHED === false` (a story draft and we're in storyteller)
  // - The view having a `publicationStatus` of `unpublished`
  if (assetIsDraft({ coreView })) {
    return AssetStatus.Draft;
  }

  // Not pending and not a draft means it's published!
  return AssetStatus.Published;
};
