import React from 'react';

import { Federation, FederationFlags, Type } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import ResultsTable from 'common/components/ResultsTable';
import FederationCnameLabel from 'common/components/Federation/FederationCnameLabel';

/*
Currently, these colums are used by the FederationsTable (on /admin/federations)
and by the asset_federation_table (in the AssetActionBar).

Due to how ResultsTable works, these are not functional components themselves but are just plain JSX.
So, to use them, you have to do i.e. `{SourceDomainColumn}` and not `<SourceDomainColumn />`
*/

export const SourceDomainColumn = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.source_domain')}
    headerClassName="source-domain-cname"
    dataClassName="source-domain-cname"
    dataIndex="sourceDomainCName"
  >
    {(ignored: unknown, rowData: Federation) => <FederationCnameLabel cname={rowData.sourceDomainCName!} />}
  </ResultsTable.Column>
);

export const TargetDomainColumn = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.target_domain')}
    headerClassName="target-domain-cname"
    dataClassName="target-domain-cname"
    dataIndex="targetDomainCName"
  >
    {(ignored: unknown, rowData: Federation) => <FederationCnameLabel cname={rowData.targetDomainCName!} />}
  </ResultsTable.Column>
);

const federationTypeToolTip = (
  <div className="federation-type-tooltip">
    <h6>{I18n.t('shared.federations.tooltips.catalog_federation')}</h6>
    <p>{I18n.t('shared.federations.tooltips.catalog_federation_description')}</p>
    <h6>{I18n.t('shared.federations.tooltips.data_federation')}</h6>
    <p>{I18n.t('shared.federations.tooltips.data_federation_description')}</p>
  </div>
);

export const FederationTypeColumn = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.federation_type')}
    headerClassName="federation-type"
    dataClassName="federation-type"
    dataIndex="type"
    blockLabelDescription={federationTypeToolTip}
  >
    {(ignored: unknown, rowData: Federation) =>
      I18n.t(`shared.federations.type.${rowData.type}_${rowData.isIncomingFederation ? 'in' : 'out'}`)
    }
  </ResultsTable.Column>
);

// TODO get rid of this in EN-46679
export const FederationTypeColumnWithoutTooltip = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.federation_type')}
    headerClassName="federation-type"
    dataClassName="federation-type"
    dataIndex="type"
  >
    {(ignored: unknown, rowData: Federation) =>
      I18n.t(`shared.federations.type.${rowData.type}_${rowData.isIncomingFederation ? 'in' : 'out'}`)
    }
  </ResultsTable.Column>
);

export const AudienceColumn = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.assets')}
    headerClassName="assets"
    dataClassName="assets"
    dataIndex="assets"
    blockLabelDescription={I18n.t('shared.federations.tooltips.audience')}
  >
    {(ignored: unknown, rowData: Federation) => I18n.t(`shared.federations.assets.${rowData.audienceLevel}`)}
  </ResultsTable.Column>
);

export const SearchBoostColumn = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.search_boost')}
    headerClassName="search-boost"
    dataClassName="search-boost"
    dataIndex="searchBoost"
    blockLabelDescription={I18n.t('shared.federations.tooltips.search_boost')}
  >
    {(ignored: unknown, rowData: Federation) =>
      rowData.type === Type.Data ? I18n.t('shared.federations.search_boost.na') : `${rowData.searchBoost}`
    }
  </ResultsTable.Column>
);

export const ContactColumn = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.contact')}
    headerClassName="contact"
    dataClassName="contact"
    dataIndex="contact"
  >
    {(ignored: unknown, rowData: Federation) =>
      rowData.flags?.includes(FederationFlags.Accepted)
        ? rowData.acceptorScreenName
        : rowData.providerScreenName
    }
  </ResultsTable.Column>
);

export const StatusColumn = (
  <ResultsTable.Column
    header={I18n.t('shared.federations.headers.status')}
    headerClassName="status"
    dataClassName="status"
    dataIndex="id"
  >
    {(ignored: unknown, rowData: Federation) => {
      if (rowData.flags?.includes(FederationFlags.Accepted)) {
        return I18n.t('shared.federations.status.accepted');
      }

      // unaccepted federations are "requested" on the target domain and "pending" on the source
      if (rowData.isIncomingFederation) {
        return I18n.t('shared.federations.status.requested');
      }

      return I18n.t('shared.federations.status.pending');
    }}
  </ResultsTable.Column>
);
