export enum Status {
  APPROVED = 'approved',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  NOT_APPLICABLE = 'not_applicable',
  PENDING = 'pending',
  REJECTED = 'rejected',
  SUBMITTABLE = 'submittable',
  UNSUBMITTABLE = 'unsubmittable'
}

export enum Actions {
  APPROVE = 'approve',
  REJECT = 'reject'
}

export enum Settings {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual'
}

export enum Prerequisite {
  PUBLISHED = 'published'
}

export enum ResubmissionSettings {
  MAINTAIN_STATE = 'maintain_state',
  RESUBMIT = 'resubmit'
}

export enum Outcome {
  CHANGE_AUDIENCE = 'change_audience',
  UPDATE_PUBLISHED_ASSET = 'update_published_asset'
}

export enum SubmissionOutcomeStatus {
  FAILURE = 'failure',
  IN_PROGRESS = 'in_progress',
  NOT_APPLICABLE = 'not_applicable',
  SUCCESS = 'success',
  UNCLAIMED = 'unclaimed'
}

export enum ApprovalState {
  NOT_APPLICABLE = 'not_applicable',
  SUBMITTABLE = 'submittable',
  UNSUBMITTABLE = 'unsubmittable',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum SubmissionObject {
  PUBLIC_AUDIENCE_REQUEST = 'public_audience_request',
  WORKING_COPY = 'working_copy',
  REVISION = 'revision',
  DRAFT_STORY = 'draft_story'
}

export enum WorkflowTaskScope {
  ALL = 'all',
  COMMUNITY = 'community',
  OFFICIAL = 'official'
}

export enum WorkflowTargetAudience {
  PUBLIC = 'public',
  INTERNAL = 'internal',
}

export const APPROVALS = {
  STATUS: Status,
  ACTIONS: Actions,
  SETTINGS: Settings,
  PREREQUISITE: Prerequisite,
  RESUBMISSION_SETTINGS: ResubmissionSettings,
  OUTCOME: Outcome,
  SUBMISSION_OUTCOME_STATUS: SubmissionOutcomeStatus,
  APPROVAL_STATE: ApprovalState,
  WORKFLOW_TARGET_AUDIENCE: WorkflowTargetAudience
};
