import React from 'react';
import I18n from 'common/i18n';
import * as _ from 'lodash';
import { option } from 'ts-option';
import { Expr, FunCall, JoinByFromTable, TypedSoQLFunCall, TypedExpr, SoQLType, FunSpec, typedNullLiteral, UnAnalyzedJoin, nullLiteral } from 'common/types/soql';
import { replaceAt } from 'common/util';
import { Either } from 'common/either';
import ExpressionEditor, { ExprProps, matchEexpr, matchEexprNA } from '../VisualExpressionEditor';
import { resolveTypesAtPositions } from '../../lib/soql-helpers';
import { hasDefaultJoinConditionShape } from '../../lib/data-helpers';
import { EditableExpression, EditableExpressionNA, UnEditableExpression, UnEditableExpressionNA } from 'common/explore_grid/types';
import { whichAnalyzer } from 'common/explore_grid/lib/feature-flag-helpers';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.visual_join_editor' });

export interface ArgumentProps<U> {
  argExpr: U;
  argPosition: number;
  exprProps: ExprProps<FunCall, TypedSoQLFunCall>;
}

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/* Determine the column qualifier to be used for special join UX based on argument position.
 * Null means that the column belongs to the current view. Checks in hasDefaultJoinConditionShape
 * should ensure that unAnalyzedJoin.from is JoinByFromTable. */
function qualifierByArgPosition(argPosition: number, unAnalyzedJoin?: UnAnalyzedJoin) {
  const fromQualifier = (unAnalyzedJoin?.from as JoinByFromTable).from_table.alias || (unAnalyzedJoin?.from as JoinByFromTable).from_table.name;
  return (argPosition === 1) ? fromQualifier : null;
}

export function withSubExprUpdates<
  U extends Expr,
  T extends TypedExpr,
  P extends ExprProps<U, T>>(WrappedComponent: React.ComponentType<P>) {
  class WithSubExprUpdates extends React.Component<ArgumentProps<U> & Pick<P, Exclude<keyof P, keyof ExprProps<U, T>>>> {
    render() {
      const { argExpr, argPosition } = this.props;
      const { eexpr, scope, columns, update, unAnalyzedJoin, parameters } = this.props.exprProps;

      const subExprUpdate = (newSubExpr: Either<Expr, TypedExpr>) => {
        update(eexpr.mapBoth(
          (ee) => ({ ...ee.untyped, args: replaceAt(ee.untyped.args, newSubExpr.left, argPosition)}),
          (ee) => ({ ...ee.expr, args: replaceAt(ee.expr.args, newSubExpr.right, argPosition)})
        ));
      };

      const subExprRemove = () => {
        // TODO: what does it mean for someone to delete a function's argument?
        // this will always typecheck, but what is the intent?
        subExprUpdate(whichAnalyzer(() => nullLiteral, () => typedNullLiteral)());
      };

      const subEexpr = eexpr.map(
        eexprOA => matchEexpr(
          eexprOA,
          (editable: EditableExpression<FunCall, TypedSoQLFunCall>) => {
            return { untyped: argExpr, typed: editable.typed.args[argPosition] as T }; // being bad here..
          },
          (uneditable: UnEditableExpression<FunCall>) => {
            return { untyped: argExpr, error: uneditable.error };
          }
        ),
        eexprNA => matchEexprNA(
          eexprNA,
          (editable: EditableExpressionNA<TypedSoQLFunCall>) => {
            return { expr: editable.expr.args[argPosition] as T }; // being bad here..
          },
          (uneditable: UnEditableExpressionNA<TypedSoQLFunCall>) => {
            return { expr: argExpr, error: uneditable.error };
          }
        ),
      );

      const spec = option(scope.find(fs => fs.name === eexpr.fold(eexprOA => eexprOA.untyped, eexprNA => eexprNA.expr).function_name));


      const isTypeAllowed: (st: SoQLType) => boolean = (st: SoQLType) => eexpr.fold(
        eexprOA => matchEexpr(
          eexprOA,
          (editable: EditableExpression<FunCall, TypedSoQLFunCall>) => (
            spec.flatMap((fs: FunSpec) => {
              const args = editable.typed.args.map((a, i) => i === argPosition ? typedNullLiteral : a);
              // pretend the current node is null, so that the constraints returned
              // are the constraints that are informed by the other arguments being passed
              // to the function.
              const ignoringThisArg = { ...editable.typed, args };
              const resolved = resolveTypesAtPositions(ignoringThisArg, fs);
              const bounds = option(resolved[argPosition]);
              return bounds.map(typeBound => _.includes(typeBound, st));
            }).getOrElseValue(true)
          ),
          (_uneditable: UnEditableExpression<FunCall>) => true
        ),
        eexprNA => matchEexprNA(
          eexprNA,
          (editable: EditableExpressionNA<TypedSoQLFunCall>) => (
            spec.flatMap((fs: FunSpec) => {
              const args = editable.expr.args.map((a, i) => i === argPosition ? typedNullLiteral : a);
              // pretend the current node is null, so that the constraints returned
              // are the constraints that are informed by the other arguments being passed
              // to the function.
              const ignoringThisArg = { ...editable.expr, args };
              const resolved = resolveTypesAtPositions(ignoringThisArg, fs);
              const bounds = option(resolved[argPosition]);
              return bounds.map(typeBound => _.includes(typeBound, st));
            }).getOrElseValue(true)
          ),
          (_uneditable: UnEditableExpressionNA<FunCall>) => true
        )
      );

      // Special default join UX behavior
      const hasSpecialJoinShape = hasDefaultJoinConditionShape(unAnalyzedJoin);

      // Special default join UX behavior
      const columnsForQualifier = () => {
        const qualifier = qualifierByArgPosition(argPosition, unAnalyzedJoin);
        return columns.filter(col => col.ref.qualifier === qualifier);
      };

      // Special default join UX behavior
      const specialJoinLabel = () => {
        const qualifier = qualifierByArgPosition(argPosition, unAnalyzedJoin);
        const vccr = _.find(columns, (col) => (col.ref.qualifier === qualifier));
        return vccr ? vccr.view.name : t('unknown_view');
      };

      const wrappedProps: P = {
        ...this.props,
        eexpr: subEexpr,
        update: subExprUpdate,
        remove: subExprRemove,
        isTypeAllowed: hasSpecialJoinShape ? (st : SoQLType) => true : isTypeAllowed,
        columns: hasSpecialJoinShape ? columnsForQualifier() : columns,
        parameters: parameters,
        scope,
        showKebab: this.props.exprProps.showKebab,
        showRemove: this.props.exprProps.showRemove,
        addFilterType: this.props.exprProps.addFilterType,
        projectionInfo: this.props.exprProps.projectionInfo,
        hasGroupOrAggregate: this.props.exprProps.hasGroupOrAggregate
      } as any as P; // i give up...

      return (
        <div>
          {hasSpecialJoinShape && (<div className="function-arg-dataset">{specialJoinLabel()}</div>)}
          <div className="function-arg" key={argPosition}>
            <WrappedComponent {...wrappedProps} />
          </div>
        </div>
      );
    }
  }

  (WithSubExprUpdates as any).displayName = `WithSubExprUpdates(${getDisplayName(WrappedComponent)})`;
  return WithSubExprUpdates;
}

const Argument = withSubExprUpdates(ExpressionEditor);


export default Argument;
