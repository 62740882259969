// Vendor Imports
import _ from 'lodash';

// Project Imports
import makeSocrataTimeDataRequest from './makeSocrataTimeDataRequest';
import { getDefaultDateDisplayFormat } from 'common/visualizations/helpers/DateHelpers.js';
import { applyOrderBy } from 'common/visualizations/helpers/SortHelpers.js';

// Constants
const VALID_SORTS = ['asc', 'desc'];
const DEFAULT_SORT = 'asc';

export function getData(vif, options) {
  const isTimelineChart = (_.get(vif, 'series[0].type') === 'timelineChart');
  const dataRequests = vif.series.map((series, seriesIndex) => {
    const type = _.get(series, 'dataSource.type');

    switch (type) {

      case 'socrata.soql':
        return options.getPrecisionBySeriesIndex(vif, seriesIndex).
          then((precision) => {
            const dateTruncFunction = options.mapPrecisionToDateTruncFunction(
              precision
            );
            const dataRequestOptions = {
              dateTruncFunction: dateTruncFunction,
              precision: precision,
              maxRowCount: options.MAX_ROW_COUNT
            };

            // Note that we cache this within the scope of getUngroupedData so
            // that we can have access to each series' precision later on down
            // the promise chain.
            precisions.push(precision);

            return makeSocrataTimeDataRequest(
              vif,
              seriesIndex,
              dataRequestOptions
            );
          });

      default:
        return Promise.reject(
          `Invalid/unsupported series dataSource.type: "${type}".`
        );
    }
  });

  // We accumulate each series' precision so that we can pass the finest-
  // grained one along to the visualization renderer, which uses a precision
  // to determine how to draw the dimension scale.
  let precisions = [];

  function mapUngroupedDataResponsesToMultiSeriesTable(dataResponses) {
    const dimensionIndex = 0;
    const measureIndex = 1;

    const measureLabels = vif.series.map((series) => _.get(series, 'label', ''));
    const uniqueDimensionValues = _.uniq(
      _.flatMap(
        dataResponses.map((dataResponse) => {
          return dataResponse.rows.map((row) => row[dimensionIndex]);
        })
      )
    );

    // Time data on the timeline chart must only be sorted by the time,
    // and not some other measure.
    if (isTimelineChart) {
      const sortFromVif = _.toLower(
        _.get(vif, 'series[0].dataSource.orderBy.sort')
      );
      const sortFromVifOrDefault = (_.includes(VALID_SORTS, sortFromVif)) ?
        sortFromVif :
        DEFAULT_SORT;
      const ascendingComparator = (a, b) => (a >= b) ? 1 : -1; // eslint-disable-line no-confusing-arrow
      const descendingComparator = (a, b) => (a <= b) ? 1 : -1; // eslint-disable-line no-confusing-arrow
      const comparator = (sortFromVifOrDefault === 'asc') ?
        ascendingComparator :
        descendingComparator;

      uniqueDimensionValues.sort(comparator);
    }

    const dataToRenderColumns = ['dimension'].concat(measureLabels);
    const dataToRenderRows = uniqueDimensionValues.map(
      (uniqueDimensionValue) => {
        const row = [uniqueDimensionValue];

        dataResponses.forEach((dataResponse) => {
          const rowForDimension = _.find(
            dataResponse.rows,
            (dataResponseRow) => {
              return dataResponseRow[dimensionIndex] === uniqueDimensionValue;
            }
          );
          const measureValue = (rowForDimension) ?
            rowForDimension[measureIndex] :
            null;

          row.push(measureValue);
        });

        return row;
      }
    );

    let finestGrainedPrecision = 'year';

    if (precisions.indexOf('day') >= 0) {
      finestGrainedPrecision = 'day';
    } else if (precisions.indexOf('month') >= 0) {
      finestGrainedPrecision = 'month';
    }

    let dateDisplayFormat = _.get(
      vif,
      'series[0].dataSource.dateDisplayFormat',
      getDefaultDateDisplayFormat(finestGrainedPrecision)
    );

    return {
      columns: dataToRenderColumns,
      rows: dataToRenderRows,
      precision: finestGrainedPrecision,
      dateDisplayFormat
    };
  }

  const promise = Promise.
    all(dataRequests).
    then(mapUngroupedDataResponsesToMultiSeriesTable);

  if (!isTimelineChart) {
    promise.then((dataToRender) => applyOrderBy(dataToRender, vif));
  }

  return promise;
}

export default {
  getData
};
