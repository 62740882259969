import _ from 'lodash';
import { Notifier } from '@airbrake/browser';
import { Filter } from '@airbrake/browser/dist/filter/filter';

import { isTestEnvironment, isDevelopmentEnvironment } from 'common/environment';

import ieFilter from './filters/ie';
import environmentFilter from './filters/environment';

let airbrake: Notifier | null;

function init(projectId?: string, projectKey?: string) {
  if (isDevelopmentEnvironment() || isTestEnvironment()) {
    warnOnce();
    return;
  }

  if (projectId === undefined || projectId === null) {
    if (window.console && console.error) {
      console.error('`projectId` is required for airbrake.init()');
    }
    return;
  }

  if (projectKey === undefined || projectKey === null) {
    if (window.console && console.error) {
      console.error('`projectKey` is required for airbrake.init()');
    }
    return;
  }

  airbrake = new Notifier({
    projectId: parseInt(projectId as string),
    projectKey: projectKey as string
  });

  // Default filters
  airbrake.addFilter(ieFilter);
  airbrake.addFilter(environmentFilter);

  airbrake.addFilter((notice) => {
    if (!isTestEnvironment()) {
      console.log('Airbrake error: ', notice);
    }
    return notice;
  });

  airbrake.onerror;
}

const close = () => {
  if (airbrake) {
    const originalAirbrake = airbrake;
    airbrake = null;
    originalAirbrake.close();
  }
};

const warnOnce = _.once(() => {
  if (window.console && console.info) {
    if (!isTestEnvironment()) {
      console.info('Airbrake not initialized.');
    }
  }
});

// If airbrake has been initialized, provide it to the callback.
// If it has not been initialized, prints a warning once.
function getAirbrake(callback: (airbrake: Notifier) => void) {
  if (airbrake) {
    callback(airbrake);
  } else {
    warnOnce();
  }
}

// Convenience function - if airbrake has been initialized, call notify
// on it with the given payload. If not, warn once.
function notify(payload: any) {
  if (window.console && console.error) {
    if (!isTestEnvironment()) {
      console.error('Airbrake notification:', JSON.stringify(payload));
    }
  }

  getAirbrake((ab) => {
    ab.notify(payload);
  });
}

function addFilter(filterCallback: Filter) {
  getAirbrake((ab) => {
    ab.addFilter(filterCallback);
  });
}

export default {
  init,
  notify,
  addFilter,
  available: () => !!airbrake,
  close
};
