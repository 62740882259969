import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import './index.scss';

export interface RadiobuttonProps {
  checked: boolean;
  disabled?: boolean;
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  label?: React.ReactElement | React.ReactElement[] | string;
  children?: React.ReactElement | React.ReactElement[] | string;
}

// A simple controlled radio button. The containing app is in full
// control of whether or not the radio button is selected.
const Radiobutton = ({ checked, disabled = false, id, onChange = _.noop, name, label = '', children }: RadiobuttonProps) => {

  const inputAttributes = {
    checked,
    disabled,
    id,
    onChange,
    name
  };

  const labelClass = classNames({ disabled });
  return (
    <div className="socrata-radiobutton">
      <div className="socrata-radiobutton-header">
        <input type="radio" {...inputAttributes} />
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      </div>
      {(() => {
        if (children) {
          return (<div className="socrata-radiobutton-children">
            {children}
          </div>);
        }
      })()}
    </div>
  );
};

export default Radiobutton;
