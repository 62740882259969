import _ from 'lodash';
import { getUserProfileLink } from 'common/js_utils/accounts';
import {
  getDatasetLink,
  getDomainNameLink,
  getDownloadLink,
  getDraftLink,
  getStoryLink
} from 'common/notifications/helpers/LinkHelpers';

import {
  VIEW_METADATA_CHANGED,
  COLLABORATOR_ADDED,
  STORY_DRAFT_CREATED
} from 'common/notifications/constants';

export const setNotificationObject = (options) => {
  return _.defaults(options, {
    hasMoreNotifications: false,
    loading: false,
    notifications: [],
    total: 0,
    offset: 0,
    unread: 0
  });
};

// why can't the components just render notifications how they want? why
// do we need one giant function that tries to do everything, and then
// one complicated component with a bunch of special cases?
// TODO: maybe this should not be here.
export const transformNotification = (notification) => {

  const activityType = _.get(notification, 'activity.activity_type', '');
  const activityUniqueKey = _.get(notification, 'activity_unique_key', '');
  const assetType = _.get(notification, 'activity.asset_type', '');
  const createdAt = _.get(notification, 'activity.created_at', '');
  const domainCname = _.get(notification, 'activity.domain_cname', '');
  const id = _.get(notification, 'id', '');
  const read = _.get(notification, 'read', false);
  const type = _.get(notification, 'type', '');
  const userActivityTypes = ['UserAdded', 'UserRemoved', 'UserRoleChanged'];
  const userId = _.get(notification, 'activity.acting_user_id', '');
  const userName = _.get(notification, 'activity.acting_user_name', '');
  const userProfileLink = getUserProfileLink(userId, domainCname);
  const domainNameLink = getDomainNameLink(domainCname);

  const transformedNotification = {
    activityType,
    activityUniqueKey,
    assetType,
    createdAt,
    domainCname,
    domainNameLink,
    id,
    read,
    type,
    userName,
    userProfileLink
  };
  if (type === 'alert') {
    return transformAlertNotification(notification, transformedNotification);
  } else {
    let link = null;
    let messageBody;
    let targetUserName = null;
    let activityDetails;

    try {
      activityDetails = JSON.parse(_.get(notification, 'activity.details', '{}'));
    } catch (err) {
      console.info('malformed data', _.get(notification, 'activity.details', {}));
    }

    if (activityType === VIEW_METADATA_CHANGED) {
      const viewId = _.get(notification, 'activity.view_uid', '');
      const viewName = _.get(notification, 'activity.view_name', '');

      link = getDatasetLink(domainCname, viewName, viewId);
      messageBody = viewName;
    } else if (_.includes(userActivityTypes, activityType)) {
      link = null;

      messageBody = _.get(activityDetails, 'summary', '');
      targetUserName = _.get(notification, 'activity.target_user_name_1', '');
    } else {
      const datasetId = _.get(notification, 'activity.dataset_uid', '');
      const datasetName = _.get(notification, 'activity.dataset_name', '');
      const useDraftLink = _.has(activityDetails, 'draft_id') && _.has(activityDetails, 'draft_type');
      const useStoryEditLink =
        (activityType === COLLABORATOR_ADDED && assetType === 'story') || (activityType === STORY_DRAFT_CREATED);
      if (useStoryEditLink) {
        // If a story has not been published, the View link to the story will 404, so we need to
        // link new collaborators to the Edit link where they can view and edit the draft. We
        // special-case this in other places as well.
        link = getStoryLink(domainCname, datasetName, datasetId, 'edit');
      } else if (useDraftLink) {
        link = getDraftLink(domainCname, datasetName, datasetId, activityDetails);
      } else {
        link = getDatasetLink(domainCname, datasetName, datasetId);
      }
      messageBody = datasetName;
    }

    _.assignIn(transformedNotification, {
      link,
      messageBody,
      activityDetails,
      targetUserName,
      domainName: domainCname
    });
    return transformedNotification;
  }
};

export const getNotificationIndex = (notifications, notificationId) => {
  return _.findIndex(notifications, { id: notificationId });
};

export const getUserNotificationCount = (userNotifications) => {
  return _.reduce(
    userNotifications,
    (total, notificationStats) => (total + _.get(notificationStats, 'unread', 0)),
    0
  );
};


const transformAlertNotification = (notification, transformedNotification) => {
  const domain = _.get(notification, 'alert.domain', '');
  const datasetUid = _.get(notification, 'alert.dataset_uid', '');
  const datasetName = _.get(notification, 'alert.dataset_name', '');
  const alertQuery = _.get(notification, 'alert_query', '');
  const domainNameLink = getDomainNameLink(domain);

  _.assignIn(transformedNotification, {
    alertDatasetUid: datasetUid,
    alertDomain: domain,
    alertDescription: _.get(notification, 'alert.description', null),
    alertName: _.get(notification, 'alert.name', ''),
    alertQueryString: alertQuery,
    createdAt: _.get(notification, 'alert_triggered_at', ''),
    datasetName,
    domainNameLink,
    downloadLink: getDownloadLink(domain, datasetUid, alertQuery),
    link: getDatasetLink(domain, datasetName, datasetUid),
    messageBody: datasetName,
    isSnoozedAlert: _.get(notification, 'is_from_snoozed_alert', false)
  });

  return transformedNotification;
};
