import _ from 'lodash';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import { getQuarterNumber } from 'common/dates';

import measurePropType from '../../propTypes/measurePropType';
import { PeriodSizes, TargetTypes } from '../../lib/constants';
import {
  formatMeasureValue,
  getValidTargets
} from '../../lib/measureHelpers';

/**
 * Display information about the current and next targets.
 */
export class RecentTargetsInfo extends Component {
  /**
   * Filter relevant targets out of the list of all targets.
   * - For periodic targets, this should be the target that lines up with the `date` passed
   *   in through props, and the next target that begins after that `date`.
   * - For ongoing targets with any reporting period type, show the first two entered
   *   in the UI.
   * @return {object|array} has a current and next target, either could be null (see above for definitions)
   */
  selectRecentTargets() {
    const { date, measure } = this.props;
    const targets = getValidTargets(measure);
    const filteredTargets = _.filter(targets, (t) => (!_.isUndefined(t.value)));

    if (filteredTargets.length === 0) {
      return [];
    } else if (filteredTargets[0].type === TargetTypes.ONGOING) {
      // A measure can only have one type of target, so if the first is ongoing, they all are.
      return filteredTargets.slice(0, 2);
    }

    // Sort periodic targets in chronological order.
    const sortedTargets = _.sortBy(filteredTargets, ['startDate']);

    let recentTargets = [];
    const lastReportingPeriodStartDate = moment(date);

    // Logic should be revisted to see what we want to do for new measure cards. For now just return the most recent two targets.
    for (let target of sortedTargets) {
      const targetStartDate = moment(target.startDate);
      if (targetStartDate.isSame(lastReportingPeriodStartDate, 'day')) {
        recentTargets.push(target);
        break;
      } else if (lastReportingPeriodStartDate.isBefore(targetStartDate)) {
        recentTargets.push(target);
        break;
      }
    }

    return recentTargets;
  }

  /**
   * Weekly: Dec 28, 2016 - Jan 4, 2017
   * Quarterly: Q1 Jan - Mar 2018
   * Monthly: January 2017
   * Annual: 2017
   *
   * @param  {object} target
   * @return {string} formatted date for a periodic target
   */
  formatTargetDate(target) {
    const { measure } = this.props;
    const periodSize = _.get(measure, 'metricConfig.reportingPeriod.size');
    const firstQuarterStartMonth = _.get(measure, 'metricConfig.reportingPeriod.firstQuarterStartMonth', 0);

    switch (periodSize) {
      case PeriodSizes.YEAR:
        return moment(target.startDate).add(1, 'year').subtract(1, 'day').format('YYYY');
      case PeriodSizes.QUARTER:
        const startDate = moment(target.startDate);
        const endDate = moment(target.startDate).add(2, 'month');
        const quarter = getQuarterNumber(startDate.month(), firstQuarterStartMonth);
        return `Q${quarter} ${endDate.format('MMM YYYY')}`;
      case PeriodSizes.MONTH:
        return moment(target.startDate).format('MMMM YYYY');
      case PeriodSizes.WEEK:
        const rangeEnd = moment(target.startDate).add(1, 'w').format('l');
        return `${rangeEnd}`;
      case PeriodSizes.DAY:
        const targetDate = moment(target.startDate);
        return `${targetDate.format('M/D/YYYY')}`;
      default:
        return moment(target.startDate).format('YYYY');
    }
  }

  /**
   * Format a target as a table row
   * @param  {object} target
   * @param  {string} key
   * @return {jsx|null} a table row if there is a target, else null
   */
  renderTargetRow(target, key) {
    if (!target) {
      return null;
    }

    const {
      calculationColumns,
      measure
    } = this.props;

    const targetTerminology = _.get(measure, 'metricConfig.display.targetTerminology');
    const label = targetTerminology || I18n.t('shared.performance_measures.measure.targets.target');

    const formattedValue = formatMeasureValue(new BigNumber(target.value), measure, calculationColumns, false);

    return (
      <div className="measure-result-card-target-row" key={key}>
        <div className="measure-result-card-target-label">{label}</div>
        <div className="measure-result-card-target-value">{formattedValue}</div>
        {target.type === TargetTypes.PERIODIC ? (<div className="measure-result-card-target-date">{this.formatTargetDate(target)}</div>) : null}
      </div>
    );
  }

  render() {
    const recentTargets = this.selectRecentTargets();

    if (_.isEmpty(recentTargets)) {
      return null;
    }

    return (
      <div className="measure-result-card-targets">
        {this.renderTargetRow(_.first(recentTargets))}
      </div>
    );
  }
}

RecentTargetsInfo.propTypes = {
  calculationColumns: PropTypes.array,
  date: PropTypes.string,
  measure: measurePropType.isRequired
};

export default RecentTargetsInfo;
