import classNames from 'classnames';
import { SoQLType } from 'common/types/soql';
import React from 'react';
import { getIconNameForDataType } from 'common/views/dataTypeMetadata';
import SocrataIcon from '../SocrataIcon';
import './soql-type-icon.scss';

interface Props {
  type: SoQLType | null;
  isDisabled?: boolean;
}

const SoQLTypeIcon: React.SFC<Props> = ({ type, isDisabled }) => {
  if (!type) return null;
  const iconName = getIconNameForDataType(type.toString());
  const klass = classNames(
    `soql-type-icon-${iconName}`,
    { 'type-icon-disabled': isDisabled }
  );
  return <SocrataIcon name={iconName} className={klass} />;
};

export default SoQLTypeIcon;
