import React from 'react';
import { ForgeIcon, ForgeIconButton, ForgeTooltip } from '@tylertech/forge-react';
import I18n from 'common/i18n';

interface ExpandCollapseButtonsProps {
  level?: number;
  onNodeLevelChange?: (level?: number) => void;
}

export const ExpandCollapseButtons = (props: ExpandCollapseButtonsProps) => {
  const { level, onNodeLevelChange } = props;

  const onExpand = () => onNodeLevelChange?.((level ?? 0) + 1);
  const onCollapseAll = () => onNodeLevelChange?.(undefined);
  const collapseDisabled = props.level == undefined || props.level === 0;

  const expandButtonText = I18n.t('shared.visualizations.charts.common.toolbar_options.expand_groups');
  const collapseButtonText = I18n.t(
    'shared.visualizations.charts.common.toolbar_options.collapse_all_groups'
  );

  const handleEnterKey = (onClick: () => void, e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      // keyCode 13 is "Enter"
      onClick();
    }
  };

  return (
    <>
      <ForgeIconButton disabled={collapseDisabled} onKeyDown={handleEnterKey.bind(this, onCollapseAll)}>
        <button
          type="button"
          disabled={collapseDisabled}
          data-test-id="expand-collapse-bar-collapse-btn"
          aria-label={collapseButtonText}
          className="tyler-icons"
          onClick={onCollapseAll}
        >
          <ForgeIcon name="chevron_double_up" />
        </button>
        <ForgeTooltip position="bottom">{collapseButtonText}</ForgeTooltip>
      </ForgeIconButton>
      <ForgeIconButton onKeyDown={handleEnterKey.bind(this, onExpand)}>
        <button
          type="button"
          data-test-id="expand-collapse-expand-collapse-btn"
          aria-label={expandButtonText}
          className="tyler-icons"
          onClick={onExpand}
        >
          <ForgeIcon name="chevron_double_down" />
        </button>
        <ForgeTooltip position="bottom">{expandButtonText}</ForgeTooltip>
      </ForgeIconButton>
    </>
  );
};

export default ExpandCollapseButtons;
