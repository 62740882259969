import React, { Component } from 'react';
import FlyoutFactory from 'common/components/legacy/Flyout';

const FLYOUT_ID = 'disabled-action-item';

interface Props {
  label: string;
  /**
   * NOTE: if this and `href` are empty, then nothing will happen when you click on this action.
   */
  onClick?: () => void;
  toolTipText?: string;
  /**
   * Added to the link element, when it is enabled.
   * NOTE: if this and `onClick` are empty, then nothing will happen when you click on this action.
   */
  href?: string;
  /** Added to the link element, when it is enabled. */
  target?: string;
}

class ActionItem extends Component<Props> {
  flyoutButtonEl: HTMLDivElement | null = null;
  componentDidMount() {
    this.attachFlyout();
  }

  componentDidUpdate() {
    this.attachFlyout();
  }

  attachFlyout() {
    const toolTipText = this.props;
    if (toolTipText && this.flyoutButtonEl) {
      new FlyoutFactory(this.flyoutButtonEl);
    }
  }

  render() {
    const { label, onClick, toolTipText, href, target } = this.props;

    const disabledLink = (
      <div
        className="flyout-wrapper"
        ref={element => {
          this.flyoutButtonEl = element;
        }}>
        <div data-flyout={FLYOUT_ID}>
          <a
            className={'disabled-action'}
            onClick={onClick}
            tabIndex={0}>
            {label}
          </a>
        </div>
        <div id={FLYOUT_ID} className="flyout flyout-hidden">
          <div className="flyout-content">
            {toolTipText}
          </div>
        </div>
      </div>
    );

    const link = (
      <a
        onClick={onClick}
        href={href}
        target={target}
        tabIndex={0}>
        {label}
      </a>
    );

    if (toolTipText) {
      return (
        disabledLink
      );
    } else {
      return (
        link
      );
    }
  }
}

export default ActionItem;
