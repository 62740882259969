import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import './myAlerts.scss';

const scope = 'shared.site_chrome.notifications.alert_setting_modal.my_alert_tab';

class MyAlertsInfo extends Component {

  renderInfoMessage() {
    const { alert, showSharedMessage, showDeleteMessage } = this.props;
    const deleteMessage = I18n.t('alert_deleted_info', { scope, name: _.get(alert, 'name', 'Alert') });
    const shareMessage = I18n.t('alert_shared_message', { scope });

    if (showDeleteMessage || showSharedMessage) {
      return (
        <div className="alert-info info-message">
          <span>
            {showDeleteMessage ? deleteMessage : shareMessage}
          </span>
          <hr />
        </div>
      );
    }

    return null;
  }

  renderEmptyMessage() {
    const { showEmptyMessage } = this.props;

    if (showEmptyMessage) {
      return (
        <div className="alert-info empty-message">
          <span dangerouslySetInnerHTML={{ __html: I18n.t('empty_message_with_helper_text', { scope }) }} />
          <hr />
        </div>
      );
    }

    return null;
  }

  renderErrorMessage() {
    const { showErrorMessage } = this.props;

    if (showErrorMessage) {
      return (
        <div className="alert-info error error-info">
          <span> {I18n.t('error_message', { scope })} </span>
          <hr />
        </div>
      );
    }

    return null;
  }

  render() {
    const { showSharedMessage, showDeleteMessage, showEmptyMessage, showErrorMessage } = this.props;

    if (showDeleteMessage || showSharedMessage) {
      return this.renderInfoMessage();
    } else if (showEmptyMessage) {
      return this.renderEmptyMessage();
    } else if (showErrorMessage) {
      return this.renderErrorMessage();
    }

    return null;
  }
}


MyAlertsInfo.propTypes = {
  alert: PropTypes.object,
  showSharedMessage: PropTypes.bool,
  showDeleteMessage: PropTypes.bool,
  showEmptyMessage: PropTypes.bool,
  showErrorMessage: PropTypes.bool
};

export default MyAlertsInfo;
