import { createReducer } from '@reduxjs/toolkit';

import {
  assertHasProperties,
  assertHasProperty,
  assertIsOneOfTypes,
  assertIsString
} from 'common/assertions';

import { createStory, saveStory, prepareStoryForTemplate } from 'store/TopLevelActions';

export interface DigestState {
  storyDigest: string;
  templateDigest: string;
  templateChangedButHasNotSaved: boolean;
}

const initialState: DigestState = {
  storyDigest: '',
  templateDigest: '',
  templateChangedButHasNotSaved: false
};

// The digest reducer is used to keep track of the digest of the story for undo and redo purposes.
// The digest is the unique identifier for the story, and is used to get story snapshot.
const digestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createStory, (state: DigestState, action) => {
      const { payload } = action;

      // Make sure payload from Redux and Flux both work
      const storyData = payload.data || payload;

      state.storyDigest = storyData.digest;
      state.templateDigest = storyData.templateDigest;
      state.templateChangedButHasNotSaved = storyData.templateChangedButHasNotSaved;
    })
    .addCase(saveStory, (state, action) => {
      const { payload } = action;
      assertHasProperty(payload, 'digest');

      const { digest } = payload;
      assertIsString(digest);

      if (state.templateChangedButHasNotSaved) {
        state.templateDigest = digest;
        state.templateChangedButHasNotSaved = false;
      }

      state.storyDigest = digest;
    })
    .addCase(prepareStoryForTemplate, (state, action) => {
      const { payload } = action;
      assertHasProperties(payload, 'templateContent', 'layout');

      const { templateContent, layout } = payload;
      assertIsOneOfTypes(templateContent, 'object');
      assertIsOneOfTypes(layout, 'string');

      state.templateChangedButHasNotSaved = true;
    });
});

export default digestReducer;
