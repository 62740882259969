import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import NavigationDrawer from './forge_omnibar/navigation_drawer';
import CreateAssetButton from './forge_omnibar/create_assets_button';
import optionallyLocalizeUrls from './utils/optionally_localize_urls';
import MobileActionButton from './forge_omnibar/mobile_action_button';
import MobileSearchButton from './forge_omnibar/mobile_search_button';
import LanguageSwitcher from './forge_omnibar/language_switcher';
import FeatureFlags from 'common/feature_flags';
import { regexMatchPatterns } from 'common/utilities/Constants';
import I18n from 'common/i18n';
import ceteraUtils from '../../../../../cetera/utils';

export default function AdminHeader() {
  const header = $('#site-chrome-admin-header');

  const $mobileHeader = header.find('#site-chrome-admin-header-mobile-menu-wrapper');
  const $mobileHeaderBackground = header.find('#site-chrome-admin-header-mobile-menu-background');
  const $mobileHeaderContent = header.find('#site-chrome-admin-header-mobile-menu-content');
  const $mobileHeaderButton = header.find('#site-chrome-admin-header-mobile-menu-button');

  header
    .find('[aria-haspopup]')
    .not('.notifications-bell') // notifications component handles behavior
    .on('click', toggleAdminDropdown)
    .on('blur', blurAdminDropdown)
    .on('keypress', keypressAdminDropdown)
    .on('keydown', keydownAdminDropdown)
    .on('keyup', keyupAdminDropdown);

  header
    .find('[role="menu"] li a')
    .on('blur', blurAdminDropdown)
    .on('keydown', keydownAdminDropdownItem)
    .on('keyup', keyupAdminDropdownItem);

  let desktopHeaderContentWidth = 0;
  header.find('.site-chrome-admin-header-section').each(function (i, section) {
    desktopHeaderContentWidth += section.offsetWidth;
  });

  $(window).on('resize', checkMobileBreakpoint);
  checkMobileBreakpoint();

  // The opacity for these sections is set to 0 in the css so that we can determine the width of the header
  // before it is actually visible. On mobile devices this prevents the "flash" of the full header.
  // After determining whether to show the admin header vs the mobile menu, reset the opacity.
  $('.site-chrome-admin-header-section').css('opacity', 1);

  mobileClickListeners();

  setupForgeOmnibarProfileButtons();
  setupForgeOmnibarMenuButton();
  setupForgeOmnibarLanguageSwitcher();
  setupForgeOmnibarHelpButtons();
  setupForgeOmnibarAddButton();
  setupForgeOmnibarSearch();
  setupForgeOmnibarMobileActionPage();
  setupForgeOmnibarSearchAutocomplete();
  setupForgeOmnibarMobileSearch();
  setupForgeOmnibarTitleClick();
  triggerCanary();

  $('body').append('<div id="forge-omnibar-drawer"></div>');
  const container = $('#forge-omnibar-drawer')[0];
  const currentUser = _.get(window, 'socrata.currentUser', {});

  if (container && !_.isEmpty(currentUser)) {
    const { rights, userSegment } = currentUser;
    ReactDOM.render(<NavigationDrawer usersRights={rights} userSegment={userSegment} />, container);
  }

  // End script execution; only hoisted methods below.
  return;

  /**
   * - Toggle active class when any click
   *   occurs on the menu button.
   * - Toggle a11y aria-hidden.
   * - Focus the first menu item to assist navigation.
   */
  function toggleAdminDropdown(event) {
    const $dropdown = $(event.target).closest('[aria-haspopup]');
    const $menu = $dropdown.find('[role="menu"]');

    $dropdown.toggleClass('active');

    $menu
      // Is the menu showing?
      .attr('aria-hidden', !$dropdown.hasClass('active'))
      // Focus the first element of the menu.
      .find('li a')
      .first()
      .trigger('focus');
  }

  /**
   * Catch and block DOWN on the dropdown toggle.
   */
  function keydownAdminDropdown(event) {
    // 32 === SPACE, 40 === DOWN
    if (event.keyCode == 40) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Toggle dropdown menu visibility on DOWN.
   * Only toggle on SPACE if not using forge.
   */
  function keyupAdminDropdown(event) {
    // 32 === SPACE, 40 === DOWN
    if (event.keyCode === 40) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Catch and block UP and DOWN on the dropdown item.
   */
  function keydownAdminDropdownItem(event) {
    // 40 === DOWN, 38 === UP
    if (event.keyCode === 40 || event.keyCode === 38) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Catch keypress and dispatch as click for accessibility purposes
   */
  function keypressAdminDropdown(event) {
    // 13 === ENTER, 32 === SPACE
    if ([13, 32].includes(event.which)) {
      const clickEvent = new Event('click', { bubbles: true });
      event.target.dispatchEvent(clickEvent);
    }
  }

  /**
   * - Chooses the next focusable dropdown item when DOWN
   *   is pressed. If there isn't one, the current item
   *   remains focused.
   * - Chooses the previous focusable dropdown item when
   *   UP is pressed. If there isn't one, the menu toggle
   *   is focused.
   */
  function keyupAdminDropdownItem(event) {
    const $target = $(event.target);
    const keyCode = event.keyCode;

    // 40 === DOWN, 38 === UP
    if (keyCode === 40 || keyCode === 38) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (keyCode === 40) {
      $target.closest('li').next('li').find('a').trigger('focus');
    } else if (keyCode === 38) {
      const $previousItem = $target.closest('li').prev('li').find('a');

      if ($previousItem.length) {
        $previousItem.trigger('focus');
      } else {
        $target.closest('[aria-haspopup]').trigger('focus');
      }
    }
  }

  /**
   * Wait and watch where focus goes to, if the focus
   * ends up in the same dropdown, don't do anything.
   * If it ends up anywhere else, close the dropdown right up.
   */
  function blurAdminDropdown(event) {
    const target = event.target;

    setTimeout(function () {
      const $menu = $(document.activeElement).closest('[aria-haspopup]');
      const $targetMenu = $(target).closest('[aria-haspopup]');

      if ($menu.length === 0 || $menu[0] !== $targetMenu[0]) {
        $targetMenu.removeClass('active');
        $targetMenu.find('[role="menu"]').attr('aria-hidden', 'true');
      }
    }, 1);
  }

  // EN-23581: Mobile admin header
  function checkMobileBreakpoint() {
    if ($(window).width() < desktopHeaderContentWidth) {
      if (!header.hasClass('mobile')) {
        applyMobileStyling();
      }
    } else {
      if (header.hasClass('mobile')) {
        applyDesktopStyling();
      }
    }
  }

  function applyMobileStyling() {
    header.addClass('mobile');
    $mobileHeaderButton.show().attr('aria-hidden', 'false');
  }

  function applyDesktopStyling() {
    hideMobileMenu();
    $mobileHeaderButton.hide().attr('aria-hidden', 'true');
    header.removeClass('mobile');
  }

  function hideMobileMenu() {
    $mobileHeaderBackground.fadeOut(200);
    $mobileHeaderContent.animate({ left: '-100vw' }, 200, () => {
      $mobileHeader.hide().attr('aria-hidden', 'true');
    });
  }

  function showMobileMenu() {
    $mobileHeader.show().attr('aria-hidden', 'false');
    $mobileHeaderBackground.fadeIn(200);
    $mobileHeaderContent.animate({ left: 0 }, 300);
  }

  function mobileClickListeners() {
    $mobileHeaderButton.on('click', function () {
      if ($mobileHeader.is(':visible')) {
        hideMobileMenu();
      } else {
        showMobileMenu();
      }
    });

    // Close mobile menu on click outside of menu.
    $mobileHeaderBackground.on('click', hideMobileMenu);

    // Close mobile dropdown menu on ESCAPE keypress.
    $(document).on('keyup', function (e) {
      if ($mobileHeader.is(':visible') && e.keyCode === 27) {
        hideMobileMenu();
      }
    });

    $mobileHeaderContent
      .find('.site-chrome-admin-menus .nested-menu li label')
      .on('click', openSubmenu)
      .on('keypress', (e) => {
        if ([13, 32].includes(e.which)) {
          openSubmenu(e);
        }
      });

    $mobileHeaderContent
      .find('#main-menu-back-button')
      .on('click', closeSubmenu)
      .on('keypress', (e) => {
        if ([13, 32].includes(e.which)) {
          closeSubmenu();
        }
      });
  }

  function openSubmenu(e) {
    const submenuKey = $(e.target).data('submenuKey');
    const $menuLevel1 = $mobileHeaderContent.find('#menu-level-1');

    $menuLevel1.animate({ left: '-100vw' }, 200, () => $menuLevel1.hide().attr('aria-hidden', true));

    $mobileHeaderContent
      .find('#menu-level-2 ul.submenu')
      .filter((i, submenu) => $(submenu).data('submenuKey') === submenuKey)
      .show()
      .attr('aria-hidden', false);

    $mobileHeaderContent.find('#menu-level-2').show().animate({ left: 0 }, 200).attr('aria-hidden', false);
  }

  function closeSubmenu() {
    $mobileHeaderContent.find('#menu-level-1').show().animate({ left: 0 }, 200).attr('aria-hidden', false);
    $mobileHeaderContent
      .find('#menu-level-2')
      .animate({ left: '100vw' }, 200, () => {
        $mobileHeaderContent.find('#menu-level-2 ul.submenu').hide().attr('aria-hidden', true);
      })
      .attr('aria-hidden', true);
  }

  /**
   * Checks to see if you have the frontend-canary cookie
   * If yes, changes the looks of the forge omnibar
   */
  function triggerCanary() {
    const isCanary = !!document.cookie.match(/frontend-canary/g);
    if (isCanary) {
      $('forge-app-bar').addClass('canary');
      $('forge-app-bar').attr('title-text', 'Canary 🐦');
    }
  }

  // EN-50782: This is currently the only way to specify the behavior of the omnibar profile buttons
  function setupForgeOmnibarProfileButtons() {
    const forgeAppBarProfileButtons = $('forge-app-bar-profile-button')[0];
    if (forgeAppBarProfileButtons) {
      forgeAppBarProfileButtons.addEventListener('forge-profile-card-profile', () => {
        window.location.href = optionallyLocalizeUrls('/profile');
      });

      forgeAppBarProfileButtons.addEventListener('forge-profile-card-sign-out', () => {
        window.location.href = optionallyLocalizeUrls('/logout');
      });
    }
  }

  function setupForgeOmnibarTitleClick() {
    const forgeAppBar = $('.tyler-forge-app-bar')[0];
    if (forgeAppBar) {
      const forgeAppBarTitle = $('slot[name="title"]', forgeAppBar.shadowRoot);

      forgeAppBarTitle.css('cursor', 'pointer');
      forgeAppBarTitle.on('click', () => {
        window.location.href = optionallyLocalizeUrls('/');
      });
    }
  }

  function setupForgeOmnibarMenuButton() {
    const forgeAppBarMenuButton = $('#omnibar-menu-button')[0];
    const forgeMobileAppBarMenuButton = $('#omnibar-mobile-menu-button')[0];
    if (forgeAppBarMenuButton) {
      forgeAppBarMenuButton.addEventListener('click', () => {
        const navSideBar = $('#forge-nav-drawer')[0];
        if (navSideBar) {
          navSideBar.open = !navSideBar.open;
        }
      });
      forgeMobileAppBarMenuButton.addEventListener('click', () => {
        const navSideBar = $('#forge-nav-drawer')[0];
        if (navSideBar) {
          navSideBar.open = !navSideBar.open;
        }
      });
    }
  }

  function setupForgeOmnibarAddButton() {
    const assetButtonContainer = $('#add-asset-button')[0];
    if (assetButtonContainer) {
      const currentUser = _.get(window, 'socrata.currentUser', {});
      ReactDOM.render(<CreateAssetButton usersRights={currentUser.rights} />, assetButtonContainer);
    }
  }

  function setupForgeOmnibarLanguageSwitcher() {
    const languageSwitcherContainer = $('#language-switcher')[0];
    if (languageSwitcherContainer) {
      ReactDOM.render(<LanguageSwitcher />, languageSwitcherContainer);
    }
  }

  function setupForgeOmnibarMobileSearch() {
    const mobileSearchContainer = $('#mobile-search-button')[0];
    if (mobileSearchContainer) {
      ReactDOM.render(<MobileSearchButton />, mobileSearchContainer);
    }
  }

  function setupForgeOmnibarSearch() {
    const omnibarSearch = $('#omnibar-search')[0];
    if (omnibarSearch) {
      omnibarSearch.addEventListener('forge-app-bar-search-input', (evt) => {
        const searchTerm = evt.detail.value;
        if (regexMatchPatterns.FOUR_BY_FOUR_PATTERN.test(searchTerm.trim())) {
          window.open(`/admin/assets?ids=${searchTerm.trim()}&tab=allAssets`, '_self');
        } else {
          window.open(`/admin/assets?q=${searchTerm.trim()}&tab=allAssets`, '_self');
        }
      });
    }
  }

  function setupForgeOmnibarSearchAutocomplete() {
    const omnibarSearchAutocomplete = $('#omnibar-search-autocomplete')[0];
    if (omnibarSearchAutocomplete) {
      // Setup autocomplete properties
      omnibarSearchAutocomplete.filterOnFocus = false;
      omnibarSearchAutocomplete.filter = (value) => {
        let response = [];

        if (value && value.trim().length > 2) {
          response = ceteraUtils.autocompleteQuery(value.trim()).then((r) => {
            let promiseResponse = [];

            if (r.results && r.results.length) {
              promiseResponse = r.results.map((result) => {
                return {
                  value: result.title,
                  label: result.title
                };
              });
            }

            return promiseResponse;
          });
        }

        return response;
      };
      omnibarSearchAutocomplete.beforeValueChange = (value) => {
        window.open(`/admin/assets?q=${value}&sortBy=relevance&tab=allAssets`, '_self');
      };
    }
  }

  function setupForgeOmnibarMobileActionPage() {
    const currentUser = _.get(window, 'socrata.currentUser', {});
    const $mobileActionPageContainer = $('#more-action-button');
    if ($mobileActionPageContainer.length > 0) {
      ReactDOM.render(<MobileActionButton usersRights={currentUser.rights} />, $mobileActionPageContainer[0]);
    }
  }

  function setupForgeOmnibarHelpButtons() {
    const omnibarHelpButton = $('forge-app-bar-help-button')[0];
    const scope = 'shared.site_chrome.forge_omnibar.help_menu';

    if (omnibarHelpButton) {
      // Here we need to check both the ffs and if user hasn't opted out of pendo tracking
      // If the user has opted out of pendo or is not logged in the snippet should not exist
      // Note: We believe that the pendo snippet should load before the site_chrome so we shouldn't run into a race condition.
      // However these things are complicated and if we are seeing odd behavior around this button check that this is not happening.
      if (FeatureFlags.value('enable_pendo') && !_.isUndefined(window.pendo)) {
        // this adds a class to allow pendo to detect when the feature flag is being used
        omnibarHelpButton.classList.add('pendo-resource-center-enabled');
        // we don't want any options displayed as Pendo will render its own
        omnibarHelpButton.options = [];
      } else {
        omnibarHelpButton.options = [
          {
            value: 'helpLink',
            label: I18n.t(`${scope}.help_link`),
            leadingBuilder: () => {
              return $('<forge-icon name="help_outline" />')[0];
            }
          },
          {
            value: 'knowledge',
            label: I18n.t(`${scope}.knowledge`),
            leadingBuilder: () => {
              return $('<forge-icon name="menu_book" />')[0];
            }
          },
          {
            value: 'education',
            label: I18n.t(`${scope}.education`),
            leadingBuilder: () => {
              return $('<forge-icon name="school" />')[0];
            }
          },
          {
            value: 'quick_start_videos',
            label: I18n.t(`${scope}.quick_start_videos`),
            leadingBuilder: () => {
              return $('<forge-icon name="video_library" />')[0];
            }
          },
          {
            value: 'releaseNotes',
            label: I18n.t(`${scope}.release_notes`),
            leadingBuilder: () => {
              return $('<forge-icon name="campaign" />')[0];
            }
          },
          {
            value: 'developerResources',
            label: I18n.t(`${scope}.developer_resources`),
            leadingBuilder: () => {
              return $('<forge-icon name="code" />')[0];
            }
          }
        ];
      }

      omnibarHelpButton.addEventListener('forge-menu-select', (evt) => {
        const linkConfigs = {
          helpLink: {
            location: 'https://support.socrata.com/hc/en-us/requests/new',
            target: '_blank'
          },
          knowledge: {
            location: 'https://support.socrata.com/hc/en-us',
            target: '_blank'
          },
          education: {
            location: 'http://learn.socrata.com',
            target: '_blank'
          },
          quick_start_videos: {
            location: '/videos',
            target: '_blank'
          },
          releaseNotes: {
            location: 'https://support.socrata.com/hc/en-us/categories/202630927',
            target: '_blank'
          },
          developerResources: {
            location: 'https://dev.socrata.com',
            target: '_blank'
          }
        };
        const link = linkConfigs[evt.detail.value];
        window.open(link.location, link.target);
      });
    }
  }
}
