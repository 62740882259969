import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import { assertHasProperty, assertInstanceOf } from 'common/assertions';
import ColorPicker from 'common/components/ColorPicker';

import Constants from 'lib/Constants';
import { dispatcher } from 'Dispatcher';
import Actions from 'Actions';
import { richTextEditorColorStore } from './stores/RichTextEditorColorStore';

/**
 * The Formats configuration block is used by the RichTextEditorToolbar and
 * the RichTextEditorFormatController to specify which format options are
 * supported, how they should be displayed on the toolbar and what action
 * should be taken when they are executed.
 *
 * @constructor
 * @param {jQuery} element
 * @param {object[]} formats
 *   @property {string} id - The internal name of the operation that is
 *     associated with this format.
 *   @property {string} tag
 *   @property {string} name - The human-readable name that will appear
 *     as a tool-tip if the user hovers the cursor over the option or button.
 *   @property {boolean} dropdown - Whether or not this format should appear
 *     as an option in the block format select control. False indicates that
 *     the format should appear as a button.
 *   @property {number} [group] - The button group in which this format's
 *     button should appear.
 */
export var richTextEditorToolbar = new RichTextEditorToolbar(
  '#rich-text-editor-toolbar',
  Constants.RICH_TEXT_FORMATS
);
export default function RichTextEditorToolbar(selector, formats) {
  assertInstanceOf(formats, Array);

  var element;
  var formatController = null;

  $(function () {
    element = $(selector);
    createToolbar();
    showToolbar();
    attachStoreListeners();
  });

  dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action) {
      case Actions.RTE_TOOLBAR_UPDATE_ACTIVE_FORMATS:
        updateActiveFormats(payload);
        break;
      default:
        break;
    }
  });

  /**
   * Public methods
   */

  this.link = function (editorFormatController) {
    formatController = editorFormatController;

    element.removeClass('dim').addClass('active');
    element.find('.rich-text-editor-toolbar-select').prop('disabled', false);
  };

  this.unlink = function () {
    formatController = null;
    element.removeClass('active').addClass('dim');
    element.find('.rich-text-editor-toolbar-select').prop('disabled', true);
  };

  this.destroy = function () {
    detachEventListeners();
    detachStoreListeners();
    element.remove();
  };

  /**
   * Private methods
   */

  function attachStoreListeners() {
    richTextEditorColorStore.addChangeListener(updateTextColorPanel);
  }

  function detachStoreListeners() {
    richTextEditorColorStore.removeChangeListener(updateTextColorPanel);
  }

  function attachEventListeners() {
    element.on('change', '[data-editor-action="change-format"]', handleToolbarSelectChange);

    element.on('click', '[data-editor-action="toggle-format"]', handleToolbarButtonClick);

    element.on('click', '[data-editor-action="toggle-panel"]', handleToolbarPanelToggle);

    element.on('click', '.color-picker-overlay', handlePickerOverlayClick);

    element.on('click', '[data-editor-command="textColor"]', handleColorSwatchClick);
  }

  function detachEventListeners() {
    element.off('change', '[data-editor-action="change-format"]', handleToolbarSelectChange);

    element.off('click', '[data-editor-action="toggle-format"]', handleToolbarButtonClick);

    element.off('click', '[data-editor-action="toggle-panel"]', handleToolbarPanelToggle);

    element.off('click', '.color-picker-overlay', handlePickerOverlayClick);

    element.off('click', '[data-editor-command="textColor"]', handleColorSwatchClick);
  }

  function renderSelect(selectFormats) {
    var selectFormatElements = [];
    var option;

    for (var i = 0; i < selectFormats.length; i++) {
      option = $('<option>', { value: selectFormats[i].id });
      option.text(selectFormats[i].name);

      if (selectFormats[i].tag === null) {
        option.prop('selected', true);
      }

      selectFormatElements.push(option);
    }

    return $('<div>', { class: 'rich-text-editor-toolbar-select-group' }).append(
      $('<div>', { class: 'rich-text-editor-toolbar-select-container' }).append([
        $('<select>', {
          class: 'rich-text-editor-toolbar-select',
          disabled: true,
          'data-editor-action': 'change-format'
        }).append(selectFormatElements),
        $('<div>', { class: 'rich-text-editor-toolbar-select-hint' })
      ])
    );
  }

  function renderColorPickerPanel() {
    var $panel = $('<div>', { class: 'rich-text-editor-toolbar-text-color-panel color-picker-panel' });
    return $panel;
  }

  function renderButtonGroup(group) {
    var toolbarButtons = [];
    var buttonClass;
    var buttonOptions;
    var $button;

    toolbarButtons.push($('<span>', { class: 'rich-text-editor-toolbar-divider' }));

    for (var i = 0; i < group.length; i++) {
      buttonClass = `rich-text-editor-toolbar-btn rich-text-editor-toolbar-btn-${group[i].id}`;
      buttonOptions = {
        class: buttonClass,
        'data-editor-action': group[i].panel ? 'toggle-panel' : 'toggle-format',
        'data-label': group[i].name
      };

      if (!group[i].panel) {
        buttonOptions['data-editor-command'] = group[i].id;
      }

      $button = $('<button>', buttonOptions);

      if (group[i].panel === true) {
        switch (group[i].id) {
          case 'textColor':
            $button.append([
              $('<div>', {
                class: 'rich-text-editor-toolbar-text-color-swatch'
              })
            ]);
            toolbarButtons.push(
              $('<div>', {
                class: 'rich-text-editor-toolbar-btn-panel-group'
              }).append([$button, renderColorPickerPanel()])
            );
            break;

          default:
            break;
        }
      } else {
        toolbarButtons.push($button);
      }
    }

    var isTextColor = toolbarButtons.some(function (toolbarButton) {
      return toolbarButton.find('.rich-text-editor-toolbar-btn-textColor').length > 0;
    });

    var className = isTextColor
      ? 'rich-text-editor-toolbar-btn-group rich-text-editor-toolbar-btn-group-with-block-elements'
      : 'rich-text-editor-toolbar-btn-group';

    return $('<div>', { class: className }).append(toolbarButtons);
  }

  function createToolbar() {
    var dropdownFormats = formats.filter(function (format) {
      return format.dropdown === true;
    });
    var buttonFormats = formats.filter(function (format) {
      return format.dropdown === false;
    });
    var buttonGroups = [];
    var controls = [];

    // Split buttons into button groups.
    buttonFormats.forEach(function (format) {
      if (format.group >= buttonGroups.length) {
        buttonGroups.push([]);
      }

      buttonGroups[format.group].push(format);
    });

    // Render the button groups and the format select.
    if (buttonGroups.length > 0) {
      controls.push(renderButtonGroup(buttonGroups[0]));
    }

    controls.push(renderSelect(dropdownFormats));

    for (var i = 1; i < buttonGroups.length; i++) {
      controls.push(renderButtonGroup(buttonGroups[i]));
    }

    element.append(controls);
    element.addClass('dim');

    // Finally, set up events and add the toolbar to the container.
    attachEventListeners();
  }

  function rgbToHex(rgb) {
    const rgbValues = rgb.substr(4).split(')')[0].split(',');
    const hex = rgbValues
      // Convert each value to base 16 (hex) integer
      .map((x) => parseInt(x).toString(16))
      // Pad single digit numbers with a zero
      .map((value) => (value.length === 1 ? '0' + value : value))
      .join('');

    return '#' + hex;
  }

  function renderColorPicker(color = '') {
    const button = element.find('.rich-text-editor-toolbar-text-color-panel');

    // When toolbar is unlinked we pass an empty string to render transparent swatch.
    // Format controller returns color in rgb format. We convert it to hex
    // before passing it to ColorPicker so that the input container renders the color
    // as hex.
    const colorPickerProps = {
      handleColorChange,
      value: color.length ? rgbToHex(color) : ''
    };

    return ReactDOM.render(<ColorPicker {...colorPickerProps} />, button[0]);
  }

  function showToolbar() {
    element.addClass('visible');
  }

  function updateActiveFormats(payload) {
    assertHasProperty(payload, 'activeFormats');

    var activeFormats = payload.activeFormats;
    var selectElement = element.find('.rich-text-editor-toolbar-select');

    element.find('.rich-text-editor-toolbar-btn').removeClass('active');

    if (activeFormats.length === 0) {
      selectElement.val('text');
    } else {
      selectElement.val('text');

      for (var i = 0; i < activeFormats.length; i++) {
        var thisFormat = activeFormats[i];

        if (thisFormat.dropdown === true) {
          selectElement.val(thisFormat.id);
        } else if (thisFormat.id === 'textColor') {
          if (!element.hasClass('dim')) {
            renderColorPicker(thisFormat.color);
            element
              .find('.rich-text-editor-toolbar-text-color-swatch')
              .css('background-color', thisFormat.color);
          }
        } else {
          element.find(`.rich-text-editor-toolbar-btn-${thisFormat.id}`).addClass('active');
        }
      }
    }
  }

  function updateTextColorPanel() {
    var colors = richTextEditorColorStore.getColors();
    var defaultColors = colors.defaultColors;
    var $defaultColorSwatches = $('.rich-text-editor-toolbar-text-color-panel-color-swatch');

    $defaultColorSwatches.each(function (i, el) {
      var $el = $(el);
      var currentColor = $el.css('background-color');

      if (defaultColors[i] === null) {
        if (currentColor !== 'transparent') {
          $el.css('background-color', 'transparent');
          $el.removeAttr('data-editor-command');
          $el.removeAttr('data-text-color');
        }
      } else {
        if (currentColor !== defaultColors[i]) {
          $el.css('background-color', defaultColors[i]);
          $el.attr('data-editor-command', 'textColor');
          $el.attr('data-text-color', defaultColors[i]);
        }
      }
    });
  }

  function handleToolbarSelectChange(e) {
    var command = e.target.value;

    if (formatController !== null) {
      formatController.execute(command);
    }
  }

  function handleToolbarButtonClick(e) {
    var command = e.target.getAttribute('data-editor-command');

    if (formatController !== null) {
      formatController.execute(command);
    }
  }

  function handleToolbarPanelToggle(e) {
    if ($('#rich-text-editor-toolbar').hasClass('dim')) return;

    // We only want to trigger this on the parent button, not any
    // of its children (which will also trigger this event handler
    // when clicked).

    if ($(e.target).hasClass('rich-text-editor-toolbar-btn-textColor')) {
      $(e.target).toggleClass('active');
      element.find('.color-frame').trigger('click');
    } else if ($(e.target).hasClass('selected-color-frame')) {
      $('.rich-text-editor-toolbar-btn-textColor').addClass('active');
    }
  }

  function handlePickerOverlayClick() {
    $('.rich-text-editor-toolbar-btn-textColor').removeClass('active');
  }

  function handleColorSwatchClick(e) {
    const color = e.target.getAttribute('data-text-color');
    handleColorChange(color);
  }

  function handleColorChange(color) {
    var command = 'textColor';

    if (formatController !== null) {
      formatController.execute(command, color);
    }
  }
}
