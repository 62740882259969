import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import I18n from 'common/i18n';
import Button from 'common/components/Button';

import { getFooterConfig, getOnDismiss } from 'common/components/AccessManager/sagas/Selectors';

import FooterConfirmButton from './FooterConfirmButton';
import CollaboratorsToggle from './CollaboratorsToggle';

import './footer.scss';

const classNameScope = 'common--components--AccessManager--components--Footer';

interface FooterProps {
  /**
   * This controls whether or not the confirm button and the CollaboratorsToggle
   * are rendered.
   *
   * This more or less just means that we have `permission` and `approvalsGuidance` available,
   * since those are needed to verify any actual changes that are allowed to happen.
   */
  prerequisitesAvailable: boolean;
}

/**
 * Footer at the bottom of the access manager modal.
 * Has both confirm and cancel buttons
 */
const Footer: FunctionComponent<FooterProps> = ({ prerequisitesAvailable }) => {
  const footerConfig = useSelector(getFooterConfig);

  const { showConfirmButton, confirmButtonBusy } = footerConfig;

  const onDismiss = useSelector(getOnDismiss);

  return (
    <footer className={`${classNameScope}--footer-container`}>
      {prerequisitesAvailable && !confirmButtonBusy && <CollaboratorsToggle />}
      <Button
        data-testid="access-manager-footer-cancel-button"
        disabled={confirmButtonBusy}
        onClick={() => onDismiss && onDismiss()}
        className={`${classNameScope}--cancel-button`}
      >
        {I18n.t('shared.site_chrome.access_manager.cancel')}
      </Button>
      {showConfirmButton && prerequisitesAvailable && <FooterConfirmButton />}
    </footer>
  );
};

export default Footer;
