import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import { SocrataIcon } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';

import * as actions from 'common/autocomplete/actions';

import './search-box.scss';

const classNameScope = 'common--autocomplete--components--SearchBox';

class SearchBox extends Component {
  state = {
    autocomplete: {
      // focused starts out undefined to prevent an animation on page load
      focused: undefined
    }
  };

  constructor(props) {
    super(props);

    // debounce getting results so it doesn't happen with EVERY keypress
    this.debouncedGetResults = _.debounce(this.props.getSearchResults, this.props.millisecondsBeforeSearch);
  }

  componentDidMount() {
    this.domNode.addEventListener('keydown', this.handleKeyDown);

    // if we're collapsible, this component gets mounted when it expands
    if (this.props.collapsible) {
      this.domNode.focus();
    }
  }

  componentWillUnmount() {
    this.domNode.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate() {
    // focuses back on search input if key-upping out of results
    const activeClass = document.activeElement && document.activeElement.className;
    if (this.props.focusedResult === undefined
      && activeClass === 'common--autocomplete--components--Results--result') {
      this.domNode.focus();
    }
  }

  getIconStyleName = () => {
    const { collapsible, animate, mobile } = this.props;
    const { focused } = this.state;

    if (animate === false) {
      if (mobile === true) {
        return `${classNameScope}--icon-container-static-mobile`;
      } else {
        return `${classNameScope}--icon-container-static`;
      }
    } else if (_.isUndefined(focused)) {
      return `${classNameScope}--icon-container-base`;
    } else if (collapsible) {
      return `${classNameScope}--icon-container-collapsible`;
    } else if (focused === true) {
      return `${classNameScope}--icon-container-focused`;
    } else {
      return `${classNameScope}--icon-container-unfocused`;
    }
  };

  getInputStyleName = () => {
    const { collapsible, animate, mobile } = this.props;
    const { focused } = this.state;

    if (animate === false) {
      if (mobile === true) {
        return `${classNameScope}--search-box-static-mobile`;
      } else {
        return `${classNameScope}--search-box-static`;
      }
    } else if (_.isUndefined(focused)) {
      return `${classNameScope}--search-box-base`;
    } else if (collapsible) {
      return `${classNameScope}--search-box-collapsible`;
    } else if (focused === true) {
      return `${classNameScope}--search-box-focused`;
    } else {
      return `${classNameScope}--search-box-unfocused`;
    }
  };

  handleKeyDown = (event) => {
    // Show results on ArrowDown
    if (event.keyCode === 40) {
      if (this.state.focused) {
        this.props.onResultVisibilityChanged(true);
      }

      event.preventDefault();
    }

    // TODO (EN-45144): remove this code and stop using nested forms
    if (event.keyCode === 13) {
      this.handleFormSubmit(event);
    }
  };

  handleFormSubmit = (event) => {
    const { focusedResult, onChooseResult, query } = this.props;
    event.preventDefault();

    // goto the search URL if we DON'T have a focused result
    if (_.isUndefined(focusedResult)) {
      onChooseResult(query);

      // Collapse any search results that might be there.
      this.domNode.blur();
      this.setState({ focused: false });
    }
  };

  handleChange = (event) => {
    const { onClearSearch, onSearchBoxChanged, onResultsReceived, anonymous } = this.props;
    const query = event.target.value;

    if (query.length <= 0) {
      onSearchBoxChanged('');
      onClearSearch();
    } else {
      onSearchBoxChanged(query);
    }

    // update state to reflect new textbox
    onSearchBoxChanged(query);

    // call the debounce'd getResults function
    this.debouncedGetResults(query, onResultsReceived, undefined, anonymous);
  };

  handleFocusChanged = (focused) => {
    const { onResultsReceived, onResultFocusChanged, getSearchResults, anonymous, query } = this.props;
    const originalQuery = query || '';

    if (originalQuery !== this.props.query) {
      this.props.onSearchBoxChanged(originalQuery);
    }

    // keep "focused" state if the current search isn't empty...
    if (!_.isEmpty(query)) {
      this.setState({ focused: true });

      // also get results if we're gaining focus and have a query
      if (focused === true) {
        getSearchResults(query, onResultsReceived, undefined, anonymous);
        onResultFocusChanged(-1);
      }
    } else {
      onResultsReceived({ results: [] });
      this.setState({ focused });
    }
  };

  render() {
    const { collapsible, disabled, query, listboxId, resultsVisible, searchResults } = this.props;
    const autocompleteSearchInputId = `autocomplete-search-input-${_.random(32768)}`;
    const hasResults = resultsVisible && (searchResults && searchResults.resultSetSize > 0);

    return (
      <form
        className={collapsible ? `${classNameScope}--form-collapsible` : `${classNameScope}--form`}
        onSubmit={this.handleFormSubmit}
        role="search"
        aria-owns={listboxId}
        title="Search Box"
      >
        <div className={classNames(this.getIconStyleName())} onClick={() => this.domNode.focus()}>
          <SocrataIcon name="search" />
        </div>
        <label htmlFor={autocompleteSearchInputId} className={`${classNameScope}--aria-not-displayed`}>
          {I18n.t('shared.site_chrome.header.search')}
        </label>
        <input
          autoComplete="off"
          className={`autocomplete-input ${classNames(this.getInputStyleName())}`}
          aria-autocomplete="list"
          aria-controls={listboxId}
          aria-expanded={!!hasResults}
          aria-haspopup="listbox"
          disabled={disabled}
          id={autocompleteSearchInputId}
          onBlur={() => this.handleFocusChanged(false)}
          onChange={this.handleChange}
          onFocus={() => this.handleFocusChanged(true)}
          placeholder={I18n.t('shared.site_chrome.header.search')}
          ref={(domNode) => (this.domNode = domNode)}
          role="combobox"
          type="search"
          name="search"
          value={query || ''}
        />
      </form>
    );
  }
}

SearchBox.propTypes = {
  /* Redux actions */
  onClearSearch: PropTypes.func,
  onSearchBoxChanged: PropTypes.func.isRequired,
  onResultsReceived: PropTypes.func.isRequired,
  onResultVisibilityChanged: PropTypes.func.isRequired,
  // takes newFocus: int. Sets index of focused result
  onResultFocusChanged: PropTypes.func.isRequired,

  /* Search config*/
  millisecondsBeforeSearch: PropTypes.number.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  onChooseResult: PropTypes.func.isRequired,
  anonymous: PropTypes.bool,

  /* State */
  collapsible: PropTypes.bool,
  animate: PropTypes.bool,
  mobile: PropTypes.bool,

  // need to know this since if it's undefined, it means on form submission and
  // we search for what's in the textbox instead of for the selected result
  focusedResult: PropTypes.number,
  disabled: PropTypes.bool,

  // accessibility
  listboxId: PropTypes.string,
  resultsVisible: PropTypes.bool,
  searchResults: PropTypes.object
};

SearchBox.defaultProps = {
  onClearSearch: _.noop,
  disabled: false
};

const mapStateToProps = (state) => ({
  focusedResult: state.autocomplete.focusedResult,
  query: state.autocomplete.query,
  resultsVisible: state.autocomplete.resultsVisible,
  searchResults: state.autocomplete.searchResults
});

const mapDispatchToProps = (dispatch) => ({
  onResultFocusChanged: (newFocus) => {
    dispatch(actions.resultFocusChanged(newFocus));
  },
  onSearchBoxChanged: (text) => {
    dispatch(actions.queryChanged(text));
    dispatch(actions.clearFocusedResult());
  },
  onResultsReceived: (response) => dispatch(actions.resultsChanged(response)),
  onResultVisibilityChanged: (visible) => dispatch(actions.resultVisibilityChanged(visible))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
