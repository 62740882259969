import React from 'react';
import _ from 'lodash';

import I18n from 'common/i18n';
import Checkbox from 'common/components/Checkbox';

interface Props {
  checked?: boolean;
  description: string;
  id?: string;
  toggleCheckbox: () => void;
  preferenceName: string;
  preferenceType: string;
  preferenceTypeLabel: string;
}

const PreferenceTableCheckBox = ({
  checked,
  description,
  id,
  toggleCheckbox,
  preferenceName,
  preferenceType,
  preferenceTypeLabel
}: Props) => {
  const scope = 'shared.site_chrome.notifications.alert_setting_modal';
  const checkboxId = id || _.uniqueId(preferenceName);
  const ariaLabel = `${I18n.t('table_header.feature', { scope })} ${description} ${I18n.t('table_header.notify_by', { scope })} ${preferenceTypeLabel}`;
  return (
    <div className={`preferences-checkbox ${preferenceType}`}>
      <Checkbox
        id={checkboxId}
        ariaLabel={ariaLabel}
        checked={checked}
        onChange={toggleCheckbox}>
        {preferenceTypeLabel}
      </Checkbox>
    </div>
  );
};

export default PreferenceTableCheckBox;
