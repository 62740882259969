// Vendor Imports
import d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';

// Project Imports
import { formatValuePlainTextWithFormatInfo } from '../helpers/ColumnFormattingHelpers';
import { getMeasures } from '../helpers/measure';
import BaseVisualization from './BaseVisualization';
import { onDrilldown, renderDrilldownPane } from './BaseVisualization/DrilldownContainer';
import { renderLegend } from './BaseVisualization/Legend';
import SvgKeyboardPanning from './SvgKeyboardPanning';
import I18n from 'common/i18n';
import {
  getAxisLabels,
  getCurrentDrilldownColumnName,
  getMeasureAxisMaxValue,
  getMeasureAxisMinValue,
  getReferenceLinesWithValues,
  getShowDimensionLabels,
  getShowValueLabels,
  isGroupingOrHasMultipleNonFlyoutSeries,
  isVifStacked,
  isOneHundredStacked,
  shouldAnimateColumnOrBar,
  shouldRenderDrillDown
} from 'common/visualizations/helpers/VifSelectors';

// Constants
import {
  AXIS_DEFAULT_COLOR,
  AXIS_GRID_COLOR,
  AXIS_LABEL_MARGIN,
  AXIS_TICK_COLOR,
  DEFAULT_LINE_HIGHLIGHT_FILL,
  DIMENSION_LABELS_DEFAULT_WIDTH,
  DIMENSION_LABELS_FONT_COLOR,
  DIMENSION_LABELS_FONT_SIZE,
  DRILLDOWN_ANIMATION_DURATION,
  ERROR_BARS_DEFAULT_BAR_COLOR,
  ERROR_BARS_MAX_END_BAR_LENGTH,
  ERROR_BARS_STROKE_WIDTH,
  FONT_STACK,
  MEASURE_LABELS_FONT_COLOR,
  MEASURE_LABELS_FONT_SIZE,
  MEASURE_VALUE_TEXT_INSIDE_BAR_COLOR,
  MEASURE_VALUE_TEXT_OUTSIDE_BAR_COLOR,
  MINIMUM_X_AXIS_TICK_DISTANCE,
  REFERENCE_LINES_STROKE_DASHARRAY,
  REFERENCE_LINES_STROKE_WIDTH,
  REFERENCE_LINES_UNDERLAY_THICKNESS,
  TEXT_ANCHOR_END,
  TEXT_ANCHOR_START,
  VALUE_LABEL_FONT_SIZE,
  VALUE_LABEL_MARGIN
} from './SvgConstants';

// The MARGINS values have been eyeballed to provide enough space for axis
// labels that have been observed 'in the wild'. They may need to be adjusted
// slightly in the future, but the adjustments will likely be small in scale.
const MARGINS = {
  TOP: 32,
  RIGHT: 32,
  BOTTOM: 0,
  LEFT: 32
};

const DEFAULT_DESKTOP_BAR_HEIGHT = 14;
const DEFAULT_MOBILE_BAR_HEIGHT = 50;

function SvgBarChart($element, vif, options) {
  const self = this;

  let $chartElement;
  let barDataToRender;
  let d3DimensionYScale;
  let d3GroupingYScale;
  let d3XScale;
  let flyoutDataToRender;
  let lastRenderedSeriesHeight = 0;
  let lastRenderedZoomTranslate = 0;
  let measures;
  let referenceLines;

  // To animate the bars, set the below variable to
  // {
  //    height: ..., // in pixels
  //    width: ..., // in pixels
  //    transform: ..., // in pixels
  // }
  // When drilling down, the clicked on columns coordinates are set here.
  // When the new data is rendered, they will be animated from this clicked
  // column's position
  let animateBarsFrom = null;
  let isDrilldownEnabled = true; // this.shouldRenderDrillDown();

  // TODO: Get rid of this and use LabelResizer. Currently it only works for xAxis.
  // Update it to work for resizing yAxis and use it
  const labelResizeState = {
    draggerElement: null,

    // True during interactive resize, false otherwise.
    dragging: false,

    // Controls how much horizontal space the labels take up.
    // The override persists until cleared by a VIF update.
    // The override is active if this value is defined.
    // Otherwise, the chart falls back to the space
    // configured in the VIF or the default.
    overriddenAreaSize: undefined
  };
  const panningClipPathId = `bar-chart-clip-path-${_.uniqueId()}`;

  _.extend(this, new BaseVisualization($element, vif, options));

  renderTemplate();
  renderSvgTemplate();

  /**
   * Public methods
   */

  this.render = ({ newColumns, newComputedColumns, newData, newFlyoutData, newVif, newTableVif } = {}) => {
    if (!newData && !newFlyoutData && !barDataToRender) {
      return;
    }

    // Forget the label area size the user set - we're
    // loading a brand new vif.
    labelResizeState.overriddenAreaSize = undefined;

    this.clearError();

    self._previouslyTouchedBarValue = null;
    self._hideBarChartFlyoutContent = false;
    isDrilldownEnabled = shouldRenderDrillDown(newVif);

    if (newVif) {
      if (!_.isEqual(this.getVif().series, newVif.series)) {
        lastRenderedZoomTranslate = 0;
      }

      this.updateVif(newVif);
    }

    if (newColumns || newComputedColumns) {
      this.updateColumns(newColumns, newComputedColumns);
    }

    if (newData) {
      if (!_.isEqual(barDataToRender, newData)) {
        renderSvgTemplate();
      }

      barDataToRender = newData;
      self.setDefaultMeasureColumnPrecision(barDataToRender);
    }

    if (newTableVif) {
      this.updateSummaryTableVif(newTableVif);
    }

    if (newFlyoutData) {
      flyoutDataToRender = newFlyoutData;
      self.setDefaultMeasureColumnPrecision(flyoutDataToRender);
    }

    if (self.isOnlyNullValues(barDataToRender)) {
      self.renderNoDataError();
      return;
    }

    renderDrilldownPane(self);
    $(labelResizeState.draggerElement).toggleClass('enabled', getShowDimensionLabels(self.getVif()));

    // When drilling down, the first render will render all the columns at the parent column's
    // position and size using the `animateColumnsFrom`. The second render will render all the
    // columns in their respective position/size. While doing that the css transitions will
    // kick in and animate the bars from the parent columns location to the new locations.
    renderData();
    if (animateBarsFrom) {
      animateBarsFrom = null;

      // Background: For rendering columns, we create and append rects first.
      // At this stage, all the rects default to 0 for x,y,height,width
      // Then we add the x,y,heigh,width based on the data.
      //
      // The transition should be added after the initial rendering only.
      // Otherwise, while setting the x,y,height,width initially all those rects
      // will animate from
      // x: 0,y: 0,height: 0,width: 0 -> x: <initial_value>,y: <initial_value>,...
      d3.select($chartElement[0])
        .select('svg')
        .selectAll('rect.bar')
        .attr(
          'style',
          `transition: ${DRILLDOWN_ANIMATION_DURATION / 1000}s ease-in-out;` +
            'transition-property: height,width,x,y'
        );

      renderData();
    }
  };

  this.invalidateSize = function () {
    if ($chartElement && barDataToRender) {
      renderData();
    }
  };

  this.destroy = function () {
    d3.select(self.$element[0]).select('svg').remove();

    self.$element.find('.socrata-visualization-container').remove();
  };

  /**
   * Private methods
   */

  function labelWidthDragger() {
    const dragger = document.createElement('div');
    labelResizeState.draggerElement = dragger;

    dragger.setAttribute('class', 'label-width-dragger');

    d3.select(dragger).call(
      d3.behavior
        .drag()
        .on('dragstart', () => {
          $chartElement.addClass('dragging-label-width-dragger');
          labelResizeState.dragging = true;
          labelResizeState.overriddenAreaSize = computeLabelWidth();
        })
        .on('drag', () => {
          labelResizeState.overriddenAreaSize += d3.event.dx;
          renderData();
          hideFlyout();
        })
        .on('dragend', () => {
          $chartElement.removeClass('dragging-label-width-dragger');
          labelResizeState.dragging = false;
          renderData();
          self.emitEvent(
            'SOCRATA_VISUALIZATION_DIMENSION_LABEL_AREA_SIZE_CHANGED',
            labelResizeState.overriddenAreaSize
          );
        })
    );

    return dragger;
  }

  function updateLabelWidthDragger(leftOffset, topOffset, height) {
    // Only move if not dragging. Otherwise,
    // d3's dragger becomes confused.
    if (!labelResizeState.dragging) {
      labelResizeState.draggerElement.setAttribute(
        'style',
        `left: ${leftOffset}px; top: ${topOffset}px; height: ${height}px`
      );
    }
  }

  function renderTemplate() {
    $chartElement = $('<div>', {
      class: 'bar-chart'
    }).append(labelWidthDragger());

    self.$element.find('.socrata-visualization-chart-container').append($chartElement);
  }

  function renderSvgTemplate() {
    d3.select($chartElement[0]).select('svg').remove();

    const chartSvg = d3.select($chartElement[0]).append('svg');

    // The viewport represents the area within the chart's container that can
    // be used to draw the x-axis, y-axis and chart marks.
    const viewportSvg = chartSvg.append('g').attr('class', 'viewport');

    viewportSvg.append('g').attr('class', 'x axis');
    viewportSvg.append('g').attr('class', 'x grid');
    viewportSvg.append('rect').attr('class', 'dragger');

    const yAxisAndSeriesSvg = viewportSvg
      .append('g')
      .attr('class', 'y-axis-and-series')
      // The x-axis and series are groups since they all need to conform to the
      // same clip path for the appearance of panning to be upheld.
      .attr('clip-path', `url(#${panningClipPathId})`);

    yAxisAndSeriesSvg.append('g').attr('class', 'series');
    yAxisAndSeriesSvg.append('g').attr('class', 'y axis');
    yAxisAndSeriesSvg.append('g').attr('class', 'y axis baseline');

    // The clip path is used as a mask. It is attached to another svg element,
    // at which time all children of that svg element that would be drawn
    // outside of the clip path's bounds will not be rendered. The clip path
    // is used in this implementation to hide the extent of the chart that lies
    // outside of the viewport when the chart is wider than the viewport.
    //
    // The overall effect is for the chart to appear to pan.
    const clipPathSvg = chartSvg.append('clipPath').attr('id', panningClipPathId);
    clipPathSvg.append('rect');
  }

  function computeLabelWidth() {
    const width = _.isFinite(labelResizeState.overriddenAreaSize)
      ? labelResizeState.overriddenAreaSize
      : _.get(self.getVif(), 'configuration.dimensionLabelAreaSize', DIMENSION_LABELS_DEFAULT_WIDTH);

    const axisLabels = getAxisLabels(self.getVif());
    const leftMargin = MARGINS.LEFT + (axisLabels.left ? AXIS_LABEL_MARGIN : 0);
    const rightMargin = MARGINS.RIGHT;

    return _.clamp(width, 0, $chartElement.width() - (leftMargin + rightMargin));
  }

  function renderData() {
    const barHeight = self.isMobile() ? DEFAULT_MOBILE_BAR_HEIGHT : DEFAULT_DESKTOP_BAR_HEIGHT;

    const dimensionLabelsWidth = getShowDimensionLabels(self.getVif()) ? computeLabelWidth() : 0;

    const axisLabels = getAxisLabels(self.getVif());
    const leftMargin = MARGINS.LEFT + (axisLabels.left ? AXIS_LABEL_MARGIN : 0) + dimensionLabelsWidth;
    const rightMargin = MARGINS.RIGHT + (axisLabels.right ? AXIS_LABEL_MARGIN : 0);
    const topMargin = MARGINS.TOP + (axisLabels.top ? AXIS_LABEL_MARGIN : 0);
    const bottomMargin = MARGINS.BOTTOM + (axisLabels.bottom ? AXIS_LABEL_MARGIN : 0);

    let viewportWidth = Math.max(0, $chartElement.width() - leftMargin - rightMargin);
    let viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);

    // There may be instances where the chart is rendering in a hidden element. If this
    // happens, we shouldn't need to continue through this function. Allowing processing
    // to continue and calling `renderLegend()` would put the chart in an state where
    // parts of the chart don't render properly once they come into view (see EN-40617).
    if (viewportHeight <= 0 || viewportWidth <= 0) {
      return;
    }

    const dataTableDimensionIndex = barDataToRender.columns.indexOf('dimension');
    const dimensionValues = barDataToRender.rows.map((row) => row[dataTableDimensionIndex]);

    measures = getMeasures(self, barDataToRender);
    referenceLines = getReferenceLinesWithValues(self.getVif());

    let width;
    let height;
    let numberOfGroups;
    let numberOfItemsPerGroup;
    let minXValue;
    let maxXValue;
    let positions;
    let d3YAxis;
    let d3XAxis;
    let d3Zoom;
    let chartSvg;
    let viewportSvg;
    let clipPathSvg;
    let yAxisAndSeriesSvg;
    let seriesSvg;
    let dimensionGroupSvgs;
    let referenceLineSvgs;
    let referenceLineUnderlaySvgs;
    let xAxisBound = false;
    let yAxisBound = false;
    let yAxisPanDistance;
    let yAxisPanningEnabled;

    /**
     * Functions defined inside the scope of renderData() are stateful enough
     * to benefit from sharing variables within a single render cycle.
     */

    // See comment in renderXAxis() for an explanation as to why this is
    // separate.
    function bindXAxisOnce() {
      if (!xAxisBound) {
        chartSvg.select('.x.axis').call(d3XAxis);

        chartSvg.select('.x.grid').call(d3XAxis.tickSize(viewportHeight).tickFormat(''));

        xAxisBound = true;
      }
    }

    function renderXAxis() {
      const xAxisSvg = chartSvg.select('.x.axis');
      const xGridSvg = chartSvg.select('.x.grid');

      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderXAxis() as idempotent.
      bindXAxisOnce();

      xAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');

      xAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      xAxisSvg
        .selectAll('text')
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${MEASURE_LABELS_FONT_SIZE}px`)
        .attr('fill', MEASURE_LABELS_FONT_COLOR)
        .attr('stroke', 'none');

      xGridSvg.selectAll('path').attr('fill', 'none').attr('stroke', 'none');

      xGridSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_GRID_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    // See comment in renderYAxis() for an explanation as to why this is
    // separate.
    function bindYAxisOnce() {
      if (!yAxisBound) {
        let yAxisFormatter;

        if (getShowDimensionLabels(self.getVif())) {
          yAxisFormatter = d3YAxis;
        } else {
          yAxisFormatter = d3YAxis.tickFormat('').tickSize(0);
        }

        yAxisAndSeriesSvg.select('g.y.axis:not(baseline)').call(yAxisFormatter);

        yAxisAndSeriesSvg.select('g.y.axis.baseline').call(d3YAxis.tickFormat('').tickSize(0));

        // Bind the chart data to the y-axis tick labels so that when the user
        // hovers over them we have enough information to distinctly identify
        // the bar which should be highlighted and show the flyout.
        chartSvg.selectAll('.y.axis .tick text').data(barDataToRender.rows);

        yAxisBound = true;
      }
    }

    function renderYAxis() {
      const yAxisSvg = viewportSvg.select('.y.axis');
      const yBaselineSvg = viewportSvg.select('.y.axis.baseline');

      // Binding the axis to the svg elements is something that only needs to
      // happen once even if we want to update the rendered properties more
      // than once; separating the bind from the layout in this way allows us
      // to treat renderYAxis() as idempotent.
      bindYAxisOnce();

      yAxisSvg
        .selectAll('path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('line')
        .attr('fill', 'none')
        .attr('stroke', AXIS_TICK_COLOR)
        .attr('shape-rendering', 'crispEdges');

      yAxisSvg
        .selectAll('text')
        .attr('font-family', FONT_STACK)
        .attr('font-size', `${DIMENSION_LABELS_FONT_SIZE}px`)
        .attr('fill', DIMENSION_LABELS_FONT_COLOR)
        .attr('stroke', 'none')
        .call(self.rotateDimensionLabels, {
          dataToRender: barDataToRender,
          maxHeight: d3DimensionYScale.rangeBand(),
          maxWidth: dimensionLabelsWidth
        });

      let baselineValue = 0;

      if (minXValue > 0) {
        baselineValue = minXValue;
      } else if (maxXValue < 0) {
        baselineValue = maxXValue;
      }

      yBaselineSvg.attr('transform', `translate(${d3XScale(baselineValue)},0)`);

      yBaselineSvg
        .selectAll('line, path')
        .attr('fill', 'none')
        .attr('stroke', AXIS_DEFAULT_COLOR)
        .attr('shape-rendering', 'crispEdges');
    }

    function renderReferenceLines() {
      const getXPosition = (referenceLine) => d3XScale(referenceLine.value);
      const getLineThickness = (referenceLine) => {
        const value = isOneHundredPercentStacked ? referenceLine.value / 100 : referenceLine.value;
        return self.isInRange(value, minXValue, maxXValue) ? REFERENCE_LINES_STROKE_WIDTH : 0;
      };

      const getUnderlayThickness = (referenceLine) => {
        const value = isOneHundredPercentStacked ? referenceLine.value / 100 : referenceLine.value;
        return self.isInRange(value, minXValue, maxXValue) ? REFERENCE_LINES_UNDERLAY_THICKNESS : 0;
      };

      // This places the underlay half to the left of the line and half to the right of the line.
      const underlayLeftwardShift = REFERENCE_LINES_UNDERLAY_THICKNESS / 2;

      referenceLineUnderlaySvgs
        .attr('data-reference-line-index', (referenceLine, index) => index)
        .attr('fill', DEFAULT_LINE_HIGHLIGHT_FILL)
        .attr('fill-opacity', 0)
        .attr('x', (referenceLine) => getXPosition(referenceLine) - underlayLeftwardShift)
        .attr('y', 0)
        .attr('width', getUnderlayThickness)
        .attr('height', height);

      referenceLineSvgs
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', (referenceLine) => referenceLine.color)
        .attr('stroke-dasharray', REFERENCE_LINES_STROKE_DASHARRAY)
        .attr('stroke-width', getLineThickness)
        .attr('x1', getXPosition)
        .attr('y1', 0)
        .attr('x2', getXPosition)
        .attr('y2', height);
    }

    function renderErrorBars() {
      if (_.isUndefined(barDataToRender.errorBars)) {
        return;
      }

      const barHeight = d3GroupingYScale.rangeBand() - 1;
      const errorBarHeight = Math.min(barHeight, ERROR_BARS_MAX_END_BAR_LENGTH);
      const color = _.get(self.getVif(), 'series[0].errorBars.barColor', ERROR_BARS_DEFAULT_BAR_COLOR);

      const getMinErrorBarXPosition = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = barDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        const minValue = _.clamp(d3.min(errorBarValues), minXValue, maxXValue);
        return d3XScale(minValue);
      };

      const getMaxErrorBarXPosition = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = barDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        const maxValue = _.clamp(d3.max(errorBarValues), minXValue, maxXValue);
        return d3XScale(maxValue);
      };

      const getMinErrorBarWidth = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = barDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        return self.isInRange(d3.min(errorBarValues), minXValue, maxXValue) ? ERROR_BARS_STROKE_WIDTH : 0;
      };

      const getMaxErrorBarWidth = (d, measureIndex, dimensionIndex) => {
        const errorBarValues = barDataToRender.errorBars[dimensionIndex][measureIndex + 1]; // 0th column holds the dimension value
        return self.isInRange(d3.max(errorBarValues), minXValue, maxXValue) ? ERROR_BARS_STROKE_WIDTH : 0;
      };

      const getErrorBarYPosition = (d, measureIndex) => {
        return (barHeight - errorBarHeight) / 2 + d3GroupingYScale(measureIndex);
      };

      dimensionGroupSvgs
        .selectAll('.error-bar-left')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', getMinErrorBarWidth)
        .attr('x1', getMinErrorBarXPosition)
        .attr('y1', getErrorBarYPosition)
        .attr('x2', getMinErrorBarXPosition)
        .attr('y2', (d, measureIndex) => getErrorBarYPosition(d, measureIndex) + errorBarHeight);

      dimensionGroupSvgs
        .selectAll('.error-bar-right')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', getMaxErrorBarWidth)
        .attr('x1', getMaxErrorBarXPosition)
        .attr('y1', getErrorBarYPosition)
        .attr('x2', getMaxErrorBarXPosition)
        .attr('y2', (d, measureIndex) => getErrorBarYPosition(d, measureIndex) + errorBarHeight);

      dimensionGroupSvgs
        .selectAll('.error-bar-middle')
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', color)
        .attr('stroke-width', ERROR_BARS_STROKE_WIDTH)
        .attr('x1', getMinErrorBarXPosition)
        .attr('y1', (d, measureIndex) => getErrorBarYPosition(d, measureIndex) + errorBarHeight / 2)
        .attr('x2', getMaxErrorBarXPosition)
        .attr('y2', (d, measureIndex) => getErrorBarYPosition(d, measureIndex) + errorBarHeight / 2);
    }

    // Note that renderXAxis(), renderYAxis() and renderSeries() all update the
    // elements that have been created by binding the data (which is done
    // inline in this function below).
    function renderSeries() {
      const getFill = (d, measureIndex) => measures[measureIndex].getColor();
      const firstSeriesTotal = barDataToRender.rows.map((d) => d[1]).reduce((acc, d) => acc + d, 0);
      const getPercent = (d, measureIndex, dimensionIndex) => {
        if (isOneHundredPercentStacked) {
          return positions[dimensionIndex][measureIndex].percent;
        } else if (self.shouldShowValueLabelsAsPercent()) {
          return firstSeriesTotal !== 0 ? (100 * d) / firstSeriesTotal : null;
        } else {
          return null;
        }
      };

      const underlaySvgs = dimensionGroupSvgs.selectAll('rect.bar-underlay').attr('data-percent', getPercent);

      if (!isStacked && referenceLines.length == 0) {
        underlaySvgs
          .attr('x', 0)
          .attr('y', (d, measureIndex) => d3GroupingYScale(measureIndex))
          .attr('width', width)
          .attr('height', Math.max(d3GroupingYScale.rangeBand() - 1, 0))
          .attr('stroke', 'none')
          .attr('fill', 'transparent')
          .attr('data-default-fill', getFill);
      }

      const bars = dimensionGroupSvgs.selectAll('rect.bar');

      bars
        .attr(
          'x',
          animateBarsFrom
            ? animateBarsFrom.x
            : (d, measureIndex, dimensionIndex) => {
                const position = positions[dimensionIndex][measureIndex];
                return d3XScale(position.start);
              }
        )
        .attr(
          'width',
          animateBarsFrom
            ? animateBarsFrom.width
            : (d, measureIndex, dimensionIndex) => {
                const position = positions[dimensionIndex][measureIndex];
                const value = position.end - position.start;
                return Math.max(d3XScale(value) - d3XScale(0), 0);
              }
        )
        .attr('shape-rendering', 'crispEdges')
        .attr('stroke', 'none')
        .attr('fill', getFill)
        .attr('data-default-fill', getFill)
        .attr('data-percent', getPercent);

      if (isStacked) {
        bars
          .attr('y', animateBarsFrom ? animateBarsFrom.y : 0)
          .attr(
            'height',
            animateBarsFrom ? animateBarsFrom.height : Math.max(d3DimensionYScale.rangeBand() - 1, 0)
          );
      } else {
        bars
          .attr(
            'y',
            animateBarsFrom ? animateBarsFrom.y : (d, measureIndex) => d3GroupingYScale(measureIndex)
          )
          .attr(
            'height',
            animateBarsFrom ? animateBarsFrom.height : Math.max(d3GroupingYScale.rangeBand() - 1, 0)
          );
      }

      lastRenderedSeriesHeight = yAxisAndSeriesSvg.node().getBBox().height;

      if (getShowValueLabels(self.getVif()) && _.isUndefined(barDataToRender.errorBars)) {
        const isLabelInside = (d, measureIndex, dimensionIndex) => {
          return self.shouldShowValueLabelsAsPercent()
            ? self.isPercentLabelInside({
                dimensionIndex,
                measureIndex,
                percent: getPercent(d, measureIndex, dimensionIndex),
                positions,
                scale: d3XScale
              })
            : self.isValueLabelInside({
                d,
                dataToRender: barDataToRender,
                dimensionIndex,
                measureIndex,
                positions,
                scale: d3XScale
              });
        };
        const getText = (d, measureIndex, dimensionIndex) => {
          if (isLabelInside(d, measureIndex, dimensionIndex)) {
            if (self.shouldShowValueLabelsAsPercent()) {
              return self.getPercentFormattedValueText({
                percent: getPercent(d, measureIndex, dimensionIndex)
              });
            } else if (getShowValueLabels(self.getVif())) {
              return self.getMeasureColumnFormattedValueText({
                dataToRender: barDataToRender,
                measureIndex,
                value: d
              });
            }
          }
          return null;
        };

        dimensionGroupSvgs
          .selectAll('text')
          .attr('font-family', FONT_STACK)
          .attr('font-size', `${VALUE_LABEL_FONT_SIZE}px`)
          .attr('stroke', 'none')
          .attr('x', (d, measureIndex, dimensionIndex) => {
            const position = self.getPosition(positions, measureIndex, dimensionIndex);
            let x;

            if (self.isBelowMeasureAxisMinValue(d)) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3XScale(position.start) + VALUE_LABEL_MARGIN
                : d3XScale(position.end) + VALUE_LABEL_MARGIN;
            } else if (self.isAboveMeasureAxisMaxValue(d)) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3XScale(position.end) - VALUE_LABEL_MARGIN
                : d3XScale(position.start) - VALUE_LABEL_MARGIN;
            } else if (d >= 0) {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3XScale(position.end) - VALUE_LABEL_MARGIN
                : d3XScale(position.end) + VALUE_LABEL_MARGIN;
            } else {
              x = isLabelInside(d, measureIndex, dimensionIndex)
                ? d3XScale(position.start) + VALUE_LABEL_MARGIN
                : d3XScale(position.start) - VALUE_LABEL_MARGIN;
            }

            return x;
          })
          .attr('y', (d, measureIndex) =>
            self.getValueLabelY({
              dimensionScale: d3DimensionYScale,
              groupingScale: d3GroupingYScale,
              measureIndex,
              isStacked
            })
          )
          .attr('fill', (d, measureIndex, dimensionIndex) =>
            isLabelInside(d, measureIndex, dimensionIndex)
              ? MEASURE_VALUE_TEXT_INSIDE_BAR_COLOR
              : MEASURE_VALUE_TEXT_OUTSIDE_BAR_COLOR
          )
          .attr('text-anchor', (d, measureIndex, dimensionIndex) => {
            if (self.isBelowMeasureAxisMinValue(d)) {
              return TEXT_ANCHOR_START;
            } else if (self.isAboveMeasureAxisMaxValue(d)) {
              return TEXT_ANCHOR_END;
            } else if (d >= 0) {
              return isLabelInside(d, measureIndex, dimensionIndex) ? TEXT_ANCHOR_END : TEXT_ANCHOR_START;
            } else {
              return isLabelInside(d, measureIndex, dimensionIndex) ? TEXT_ANCHOR_START : TEXT_ANCHOR_END;
            }
          })
          .text(getText);
      }
    }

    function handleZoom() {
      lastRenderedZoomTranslate = _.clamp(d3.event.translate[1], -1 * yAxisPanDistance, 0);

      // We need to override d3's internal translation since it doesn't seem to
      // respect our snapping to the beginning and end of the rendered data.
      d3Zoom.translate([0, lastRenderedZoomTranslate]);

      chartSvg
        .select(`#${panningClipPathId}`)
        .select('rect')
        .attr('transform', () => {
          const translateX = -dimensionLabelsWidth;
          const translateY = -lastRenderedZoomTranslate;

          return getShowDimensionLabels(self.getVif())
            ? `translate(${translateX},${translateY})`
            : `translate(0,${translateY})`;
        });

      yAxisAndSeriesSvg.attr('transform', `translate(0,${lastRenderedZoomTranslate})`);

      hideHighlight();
      hideFlyout();
    }

    function restoreLastRenderedZoom() {
      const translateYRatio =
        lastRenderedSeriesHeight !== 0 ? Math.abs(lastRenderedZoomTranslate / lastRenderedSeriesHeight) : 0;
      const currentHeight = yAxisAndSeriesSvg.node().getBBox().height;

      lastRenderedZoomTranslate = _.clamp(-1 * translateYRatio * currentHeight, -1 * yAxisPanDistance, 0);

      d3Zoom.translate([0, lastRenderedZoomTranslate]);

      chartSvg
        .select('#' + panningClipPathId)
        .select('rect')
        .attr('transform', () => {
          const translateX = -dimensionLabelsWidth;
          const translateY = -lastRenderedZoomTranslate;

          return getShowDimensionLabels(self.getVif())
            ? `translate(${translateX},${translateY})`
            : `translate(0,${translateY})`;
        });

      yAxisAndSeriesSvg.attr('transform', `translate(0,${lastRenderedZoomTranslate})`);
    }

    function setAnimateColumnsFrom(clickedColumn, clickedGroup) {
      if (isDrilldownEnabled) {
        animateBarsFrom = {
          x: Number(clickedColumn.attr('x')),
          y: Number(clickedColumn.attr('y')),
          height: Number(clickedColumn.attr('height')),
          width: Number(clickedColumn.attr('width')),
          transform: clickedGroup.attr('transform')
        };
      }
    }

    function shouldAnimateBar(dimensionValue) {
      return shouldAnimateColumnOrBar({
        vif: self.getVif(),
        columnData: barDataToRender,
        dimensionValue
      });
    }

    // Just extracted the on('click', <fn>) and on('touchstart', <fn>) and applied the same to rect underlay,
    // so that drill down happens when clicking above the bar.
    // (This helps in small screen and if the bars are very small.)
    function onClickDrilldownForBar() {
      if (self._isTouchstartEventEnabled || self._lastRenderedZoomTranslate !== lastRenderedZoomTranslate) {
        return;
      }
      const clickedColumn = d3.select(this);
      const clickedGroup = d3.select(this.parentNode);
      const row = clickedGroup.datum();
      const dimensionValue = _.get(row, '[0]');

      if (shouldAnimateBar(dimensionValue)) {
        setAnimateColumnsFrom(clickedColumn, clickedGroup);
      }

      onDrilldown(self, dimensionValue, barDataToRender);
    }

    function onTouchDrilldownForBar() {
      const clickedColumn = d3.select(this);
      const clickedGroup = d3.select(this.parentNode);
      const row = clickedGroup.datum();
      const dimensionValue = _.get(row, '[0]');

      if (self._previouslyTouchedBarValue === dimensionValue) {
        self._hideBarChartFlyoutContent = true;
        hideFlyout();

        if (shouldAnimateBar(dimensionValue)) {
          setAnimateColumnsFrom(clickedColumn, clickedGroup);
        }

        onDrilldown(self, dimensionValue, barDataToRender);
        self._previouslyTouchedBarValue = null;
      } else {
        self._hideBarChartFlyoutContent = false;
        self._previouslyTouchedBarValue = dimensionValue;
      }

      self._isTouchstartEventEnabled = true;
    }

    function onMousedownForBar() {
      self._lastRenderedZoomTranslate = lastRenderedZoomTranslate;
    }

    /**
     * 1. Prepare the data for rendering (unfortunately we need to do grouping
     *    on the client at the moment).
     */
    const adjustedViewportSize = renderLegend(self, {
      measures,
      referenceLines,
      viewportSize: {
        height: viewportHeight,
        width: viewportWidth
      }
    });

    viewportHeight = adjustedViewportSize.height;
    viewportWidth = adjustedViewportSize.width;

    const isStacked = isVifStacked(self.getVif());
    const isOneHundredPercentStacked = isOneHundredStacked(self.getVif());

    numberOfGroups = barDataToRender.rows.length;
    numberOfItemsPerGroup = isStacked ? 1 : barDataToRender.columns.length - 1;

    // When we do allow panning we get a little more sophisticated; primarily
    // we will attempt to adjust the height we give to d3 to account for the
    // height of the labels, which will extend past the edge of the chart
    // since they are rotated by 45 degrees.
    //
    // Since we know the maximum number of items in a group and the total
    // number of groups we can estimate the total height of the chart (this
    // will necessarily be incorrect because we won't actually know the height
    // of the last label until we render it, at which time we will
    // re-measure. This estimate will be sufficient to get d3 to render the
    // bars at widths that are in line with our expectations, however.
    height = Math.max(viewportHeight, barHeight * numberOfGroups * numberOfItemsPerGroup);

    width = viewportWidth;

    /**
     * 2. Set up the x-scale and -axis.
     */
    try {
      const measureAxisMinValue = getMeasureAxisMinValue(self.getVif());
      const measureAxisMaxValue = getMeasureAxisMaxValue(self.getVif());

      if (measureAxisMinValue && measureAxisMaxValue && measureAxisMinValue >= measureAxisMaxValue) {
        self.renderError(
          I18n.t(
            'shared.visualizations.charts.common.validation.errors.' +
              'measure_axis_min_should_be_lesser_then_max'
          )
        );
        return;
      }

      if (isOneHundredPercentStacked) {
        positions = self.getStackedPositions({
          isOneHundredPercentStacked: true,
          rows: barDataToRender.rows
        }); // measure axes do not change for 100% stacked
        minXValue = self.getMinOneHundredPercentStackedValue(positions);
        maxXValue = self.getMaxOneHundredPercentStackedValue(positions);
      } else if (isStacked) {
        const extent = self.getRowValueSummedExtent({
          columnOrBarRows: barDataToRender.rows,
          dimensionIndex: dataTableDimensionIndex,
          referenceLines
        });

        minXValue = measureAxisMinValue || Math.min(extent.min, 0);
        maxXValue = measureAxisMaxValue || Math.max(extent.max, 0);
        positions = self.getStackedPositionsForRange(barDataToRender.rows, minXValue, maxXValue);
      } else {
        const rowValues = _.flatMap(barDataToRender.rows, (row) => row.slice(dataTableDimensionIndex + 1));

        const errorBarValues = _.flatMapDeep(barDataToRender.errorBars, (row) =>
          row.slice(dataTableDimensionIndex + 1)
        );

        const extent = self.getRowValueExtent({
          errorBarValues,
          rowValues,
          referenceLines
        });

        minXValue = measureAxisMinValue || Math.min(extent.min, 0);
        maxXValue = measureAxisMaxValue || Math.max(extent.max, 0);
        positions = self.getPositionsForRange(barDataToRender.rows, minXValue, maxXValue);
      }

      if (minXValue > maxXValue) {
        self.renderError(
          I18n.t(
            'shared.visualizations.charts.common.validation.errors.' +
              'measure_axis_biggest_value_should_be_more_than_min_limit'
          )
        );
        return;
      }
    } catch (error) {
      self.renderError(error.message);
      return;
    }

    const firstNonFlyoutSeries = self.getFirstSeriesOfType(['barChart']);

    d3XScale = generateXScale(minXValue, maxXValue, width);
    d3XAxis = generateXAxis({
      dataToRender: barDataToRender,
      scale: d3XScale,
      series: firstNonFlyoutSeries,
      width
    });

    /**
     * 3. Set up the y-scale and -axis.
     */

    // This scale is used for dimension categories.
    d3DimensionYScale = generateYScale(
      dimensionValues,
      height,
      isGroupingOrHasMultipleNonFlyoutSeries(self.getVif())
    );

    // This scale is used for groupings of bars under a single dimension category.
    d3GroupingYScale = generateYGroupScale(_.range(0, barDataToRender.columns.length - 1), d3DimensionYScale);

    d3YAxis = generateYAxis(d3DimensionYScale);

    /**
     * 5. Render the chart.
     */

    // Create the top-level <svg> element first.
    chartSvg = d3
      .select($chartElement[0])
      .select('svg')
      .attr('width', viewportWidth + rightMargin + leftMargin)
      .attr('height', height + topMargin + bottomMargin);

    // The viewport represents the area within the chart's container that can
    // be used to draw the x-axis, y-axis and chart marks.

    viewportSvg = chartSvg.select('g.viewport').attr('transform', `translate(${leftMargin}, ${topMargin})`);

    // The clip path is used as a mask. It is attached to another svg element,
    // at which time all children of that svg element that would be drawn
    // outside of the clip path's bounds will not be rendered. The clip path
    // is used in this implementation to hide the extent of the chart that lies
    // outside of the viewport when the chart is wider than the viewport.
    //
    // The overall effect is for the chart to appear to pan.
    clipPathSvg = chartSvg.select('clipPath');

    const rect = clipPathSvg
      .select('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', viewportWidth + leftMargin + rightMargin)
      .attr('height', viewportHeight + topMargin + bottomMargin);

    if (getShowDimensionLabels(self.getVif())) {
      rect.attr('transform', `translate(${-dimensionLabelsWidth},0)`);
    }

    viewportSvg.select('g.x.grid').attr('transform', `translate(0,${viewportHeight})`);

    // This <rect> exists to capture mouse actions on the chart, but not
    // directly on the bars or labels, that should result in a pan behavior.
    // If we set stroke and fill to none, the mouse events don't seem to get
    // picked up, so we instead set opacity to 0.
    viewportSvg.select('rect.dragger').attr('width', viewportWidth).attr('height', height).attr('opacity', 0);

    yAxisAndSeriesSvg = viewportSvg.select('g.y-axis-and-series');

    seriesSvg = yAxisAndSeriesSvg.select('.series');

    dimensionGroupSvgs = seriesSvg
      .selectAll('.dimension-group')
      .data(barDataToRender.rows)
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('g').attr('class', 'dimension-group'));

    dimensionGroupSvgs
      .attr('style', `transition: transform ${DRILLDOWN_ANIMATION_DURATION / 1000}s ease-in-out;`)
      .attr('data-dimension-index', (d, dimensionIndex) => dimensionIndex)
      .attr(
        'transform',
        animateBarsFrom ? animateBarsFrom.transform : (d) => `translate(0,${d3DimensionYScale(d[0])})`
      );

    if (!isStacked) {
      dimensionGroupSvgs
        .selectAll('rect.bar-underlay')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('rect').attr('class', 'bar-underlay'))
        .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
        .attr('data-measure-index', (d, measureIndex) => measureIndex);
    }

    dimensionGroupSvgs
      .selectAll('rect.bar')
      .data((d) => d.slice(1))
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('rect').attr('class', 'bar'))
      .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
      .attr('data-measure-index', (d, measureIndex) => measureIndex);

    referenceLineSvgs = seriesSvg
      .selectAll('line.reference-line')
      .data(referenceLines)
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('line').attr('class', 'reference-line'));

    referenceLineUnderlaySvgs = seriesSvg
      .selectAll('rect.reference-line-underlay')
      .data(referenceLines)
      .call((selection) => selection.exit().remove())
      .call((selection) => selection.enter().append('rect').attr('class', 'reference-line-underlay'));

    if (!_.isUndefined(barDataToRender.errorBars)) {
      dimensionGroupSvgs
        .selectAll('line.error-bar-left')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('line').attr('class', 'error-bar-left'));

      dimensionGroupSvgs
        .selectAll('line.error-bar-middle')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('line').attr('class', 'error-bar-middle'));

      dimensionGroupSvgs
        .selectAll('line.error-bar-right')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('line').attr('class', 'error-bar-right'));
    }

    if (getShowValueLabels(self.getVif()) && _.isUndefined(barDataToRender.errorBars)) {
      dimensionGroupSvgs
        .selectAll('text')
        .data((d) => d.slice(1))
        .call((selection) => selection.exit().remove())
        .call((selection) => selection.enter().append('text'))
        .attr('data-dimension-index', (d, measureIndex, dimensionIndex) => dimensionIndex)
        .attr('data-measure-index', (d, measureIndex) => measureIndex);
    } else {
      dimensionGroupSvgs.selectAll('text').remove();
    }

    // We need to render the y-axis before we can determine whether or not to
    // enable panning, since panning depends on the actual rendered height of
    // the axis.
    renderYAxis();

    // This is the actual rendered height.
    height = yAxisAndSeriesSvg.node().getBBox().height;

    // The width was being calculated slightly off (by .0001), so using fixed precision to calculate.
    yAxisPanDistance = height.toFixed(3) - viewportHeight;

    yAxisPanningEnabled = yAxisPanDistance > 0;

    if (yAxisPanningEnabled) {
      self.showPanningNotice();

      // If we are showing the panning notice, it may be that the info bar
      // was previously hidden but is now shown. If this is the case, we need
      // to recompute the viewport height to accommodate the new, decreased
      // vertical space available in which to draw the chart. We also need
      // to immediately recompute yAxisPanDistance, because it is derived
      // from the viewport height and using the stale value will cause the
      // panning behavior to be incorrect (it will become impossible to pan
      // the bottom-most edge of the chart into view).
      viewportHeight = Math.max(0, $chartElement.height() - topMargin - bottomMargin);

      yAxisPanDistance = height - viewportHeight;
    } else {
      self.hidePanningNotice();
    }

    // Now that we have determined if panning is enabled and potentially
    // updated the viewport height, we need to render everything at the new
    // viewport size.
    renderYAxis();
    renderSeries();
    renderXAxis();
    renderReferenceLines();
    renderErrorBars();

    updateLabelWidthDragger(leftMargin, topMargin, height);

    /**
     * 6. Set up event handlers for mouse interactions.
     */
    if (!isStacked) {
      dimensionGroupSvgs
        .selectAll('rect.bar-underlay')
        // NOTE: The below function depends on this being set by d3, so it is
        // not possible to use the () => {} syntax here.
        .on('mousemove', function () {
          if (!isCurrentlyPanning()) {
            const measureIndex = parseInt(this.getAttribute('data-measure-index'), 10);
            const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

            const dimensionGroup = this.parentNode;
            const siblingBar = d3
              .select(dimensionGroup)
              .select(`rect.bar[data-measure-index="${measureIndex}"]`)[0][0];

            // d3's .datum() method gives us the entire row, whereas everywhere
            // else measureIndex refers only to measure values. We therefore
            // add one to measure index to get the actual measure value from
            // the raw row data provided by d3 (the value at element 0 of the
            // array returned by .datum() is the dimension value).
            const row = d3.select(this.parentNode).datum();
            const dimensionValue = row[0];
            const value = row[measureIndex + 1];
            const percent = parseFloat(this.getAttribute('data-percent'));

            showBarHighlight(siblingBar);
            showBarFlyout({
              dimensionIndex,
              dimensionValue,
              element: siblingBar,
              measureIndex,
              percent,
              value
            });
          }
        })
        .on('mouseleave', () => {
          if (!isCurrentlyPanning()) {
            hideHighlight();
            hideFlyout();
          }
        });
    }

    dimensionGroupSvgs
      .selectAll('rect.bar-underlay')
      .on('click', onClickDrilldownForBar)
      .on('mousedown', onMousedownForBar)
      .on('touchstart', onTouchDrilldownForBar);

    dimensionGroupSvgs
      .selectAll('rect.bar')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (self._isTouchstartEventEnabled && self._hideBarChartFlyoutContent) {
          return;
        }
        if (!isCurrentlyPanning()) {
          const measureIndex = parseInt(this.getAttribute('data-measure-index'), 10);
          const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

          // d3's .datum() method gives us the entire row, whereas everywhere
          // else measureIndex refers only to measure values. We therefore
          // add one to measure index to get the actual measure value from
          // the raw row data provided by d3 (the value at element 0 of the
          // array returned by .datum() is the dimension value).
          const row = d3.select(this.parentNode).datum();
          const dimensionValue = row[0];
          const value = row[measureIndex + 1];
          const percent = parseFloat(this.getAttribute('data-percent'));

          showBarHighlight(this);
          showBarFlyout({
            dimensionIndex,
            dimensionValue,
            element: this,
            measureIndex,
            percent,
            value
          });
        }
      })
      .on('mousedown', onMousedownForBar)
      .on('click', onClickDrilldownForBar)
      .on('touchstart', onTouchDrilldownForBar)
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideHighlight();
          hideFlyout();
        }
      });

    seriesSvg
      .selectAll('.reference-line-underlay')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (!isCurrentlyPanning()) {
          const underlayWidth = parseInt($(this).attr('width'), 10);
          const flyoutOffset = {
            left: $(this).offset().left + underlayWidth / 2 - window.pageXOffset,
            top: d3.event.clientY
          };

          self.showReferenceLineFlyout({
            dataToRender: barDataToRender,
            element: this,
            flyoutOffset,
            referenceLines
          });

          $(this).attr('fill-opacity', 1);
        }
      })
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mouseleave', function () {
        if (!isCurrentlyPanning()) {
          hideFlyout();
          $(this).attr('fill-opacity', 0);
        }
      });

    dimensionGroupSvgs
      .selectAll('text')
      // NOTE: The below function depends on this being set by d3, so it is
      // not possible to use the () => {} syntax here.
      .on('mousemove', function () {
        if (!isCurrentlyPanning()) {
          const measureIndex = parseInt(this.getAttribute('data-measure-index'), 10);
          const dimensionIndex = parseInt(this.getAttribute('data-dimension-index'), 10);

          const dimensionGroup = this.parentNode;
          const siblingBar = d3
            .select(dimensionGroup)
            .select(`rect.bar[data-measure-index="${measureIndex}"]`)[0][0];

          // d3's .datum() method gives us the entire row, whereas everywhere
          // else measureIndex refers only to measure values. We therefore
          // add one to measure index to get the actual measure value from
          // the raw row data provided by d3 (the value at element 0 of the
          // array returned by .datum() is the dimension value).
          const row = d3.select(this.parentNode).datum();
          const dimensionValue = row[0];
          const value = row[measureIndex + 1];
          const percent = parseFloat(this.getAttribute('data-percent'));

          showBarHighlight(siblingBar);
          showBarFlyout({
            dimensionIndex,
            dimensionValue,
            element: siblingBar,
            measureIndex,
            percent,
            value
          });
        }
      })
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideHighlight();
          hideFlyout();
        }
      });

    chartSvg
      .selectAll('.y.axis .tick text')
      .on('mousemove', (d, dimensionIndex) => {
        if (!isCurrentlyPanning()) {
          const dimensionValue = d[0];
          // We need to find nodes with a data-dimension-index attribute matching dimensionIndex.
          // We can't easily use a CSS selector because we lack a simple API to apply CSS-string escaping
          // rules.
          // There's a working draft for a CSS.escape and jQuery >= 3.0 has a $.escapeSelector,
          // but both of those are out of reach for us at the moment.
          const groupElement = d3.select(
            _(yAxisAndSeriesSvg.node().querySelectorAll('g.dimension-group[data-dimension-index]'))
              .filter((group) => group.getAttribute('data-dimension-index') === String(dimensionIndex))
              .first()
          );

          if (groupElement.empty()) {
            return;
          }

          const seriesElement = yAxisAndSeriesSvg.select('g.series')[0][0];

          showGroupFlyout({
            dimensionIndex,
            dimensionValue,
            groupElement,
            positions,
            seriesElement
          });
        }
      })
      .on('mouseleave', () => {
        if (!isCurrentlyPanning()) {
          hideFlyout();
        }
      });

    /**
     * 7. Conditionally set up the zoom behavior, which is actually used for
     *    panning the chart along the x-axis if panning is enabled.
     */

    if (yAxisPanningEnabled) {
      d3Zoom = d3.behavior.zoom().on('zoom', handleZoom);

      viewportSvg
        .attr('cursor', 'move')
        .call(d3Zoom)
        // By default the zoom behavior seems to capture every conceivable
        // kind of zooming action; we actually just want it to zoom when
        // the user clicks and drags, so we need to immediately deregister
        // the event handlers for the other types.
        //
        // Note that although we listen for the zoom event on the zoom
        // behavior we must detach the zooming actions we do not want to
        // respond to from the element to which the zoom behavior is
        // attached.
        .on('dblclick.zoom', null)
        .on('wheel.zoom', null)
        .on('mousewheel.zoom', null)
        .on('MozMousePixelScroll.zoom', null)
        // Mobile Safari doesn't support touch-action: none, and as
        // a result we can't scroll the chart without scrolling the
        // whole window.  To fix, we need to prevent the touchstart
        // from being handled by the body when it originates on the
        // viewport.
        .on('touchstart', () => {
          d3.select('body').on('touchstart', () => d3.event.preventDefault(), { passive: false });
        })
        .on('touchend', () => {
          d3.select('body').on('touchstart', null);
        });

      restoreLastRenderedZoom();

      SvgKeyboardPanning(d3Zoom, chartSvg, '.socrata-visualization-panning-notice');

      chartSvg.selectAll('text').attr('cursor', null);
    } else {
      chartSvg.selectAll('text').attr('cursor', 'default');
    }

    self.renderAxisLabels(chartSvg, {
      x: leftMargin,
      y: topMargin,
      width: viewportWidth,
      height: viewportHeight - viewportSvg.select('.x.axis').node().getBBox().height
    });
  }

  function generateYScale(domain, height, hasMultipleNonFlyoutSeries) {
    const padding = hasMultipleNonFlyoutSeries ? 0.3 : 0.1;

    return (
      d3.scale
        .ordinal()
        .domain(domain)
        // .rangeRoundBands(<interval>, <padding>, <outer padding>)
        //
        // From the documentation:
        //
        // ---
        //
        // Note that rounding necessarily introduces additional outer padding
        // which is, on average, proportional to the length of the domain.
        // For example, for a domain of size 50, an additional 25px of outer
        // padding on either side may be required. Modifying the range extent
        // to be closer to a multiple of the domain length may reduce the
        // additional padding.
        //
        // ---
        // The outer padding looks pretty funny for our use cases, so we
        // override it to be 0.05, which looks like what we expect.
        .rangeRoundBands([0, height], padding, 0.05)
    );
  }

  function generateYGroupScale(domain, yScale) {
    return d3.scale.ordinal().domain(domain).rangeRoundBands([0, yScale.rangeBand()]);
  }

  function generateYAxis(yScale) {
    let columnName = _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');
    const currentDrilldownColumnName = getCurrentDrilldownColumnName(self.getVif());
    if (shouldRenderDrillDown(self.getVif()) && currentDrilldownColumnName) {
      columnName = currentDrilldownColumnName;
    }

    return d3.svg
      .axis()
      .scale(yScale)
      .orient('left')
      .tickFormat((d) =>
        self.getColumnFormattedValueText({
          columnName,
          dataToRender: barDataToRender,
          value: d
        })
      )
      .outerTickSize(0);
  }

  function generateXScale(minValue, maxValue, width) {
    return d3.scale.linear().domain([minValue, maxValue]).range([0, width]);
  }

  function generateXAxis({ dataToRender, scale, series, width }) {
    const isOneHundredPercentStacked = isOneHundredStacked(self.getVif());
    let formatter;

    if (isOneHundredPercentStacked) {
      formatter = d3.format('.0%'); // rounds to a whole number percentage
    } else {
      const columnName = _.get(series, 'dataSource.measure.columnName');
      const formatInfo = _.cloneDeep(_.get(dataToRender, `columnFormats.${columnName}`, {}));
      const axisPrecision = _.get(self.getVif(), 'configuration.measureAxisPrecision');
      let forceHumane = true; // X-axis ticks default to displaying nicely formatted values (forceHumane == true)

      if (!_.isNil(axisPrecision)) {
        _.set(formatInfo, 'format.precision', axisPrecision);
        forceHumane = false;
      }

      formatter = (value) =>
        axisPrecision === 0 && !_.isInteger(value)
          ? null
          : formatValuePlainTextWithFormatInfo({
              dataToRender,
              forceHumane,
              formatInfo,
              value
            });
    }

    const xAxis = d3.svg.axis().scale(scale).orient('top').tickFormat(formatter);

    const ticksToFitWidth = Math.ceil(width / MINIMUM_X_AXIS_TICK_DISTANCE);
    const isCount = _.get(series, 'dataSource.measure.aggregationFunction') === 'count';

    if (isCount) {
      // If the number of possible values is small, limit number of ticks to force integer values.
      const [minXValue, maxXValue] = scale.domain();
      const span = maxXValue - minXValue;

      if (span < 10) {
        const ticks = d3.range(minXValue, maxXValue + 1, 1);

        if (ticks.length <= ticksToFitWidth) {
          xAxis.tickValues(ticks);
        } else {
          xAxis.ticks(ticksToFitWidth);
        }
      } else {
        xAxis.ticks(ticksToFitWidth);
      }
    } else {
      xAxis.ticks(ticksToFitWidth);
    }

    return xAxis;
  }

  function isCurrentlyPanning() {
    // EN-10810 - Bar Chart flyouts do not appear in Safari
    //
    // Internet Explorer will apparently always return a non-zero value for
    // d3.event.which and even d3.event.button, so we need to check
    // d3.event.buttons for a non-zero value (which indicates that a button is
    // being pressed).
    //
    // Safari apparently does not support d3.event.buttons, however, so if it
    // is not a number then we will fall back to d3.event.which to check for a
    // non-zero value there instead.
    //
    // Chrome appears to support both cases, and in the conditional below
    // Chrome will check d3.event.buttons for a non-zero value.
    return _.isNumber(d3.event.buttons) ? d3.event.buttons !== 0 : d3.event.which !== 0;
  }

  function showBarHighlight(barElement) {
    const selection = d3.select(barElement);

    // NOTE: The below function depends on this being set by d3, so it is not
    // possible to use the () => {} syntax here.
    selection.attr('fill', function () {
      const seriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(
        parseInt(this.getAttribute('data-measure-index'), 10)
      );
      const highlightColor = self.getHighlightColorBySeriesIndex(seriesIndex);

      return highlightColor !== null ? highlightColor : selection.attr('fill');
    });
  }

  function hideHighlight() {
    // NOTE: The below function depends on this being set by d3, so it is not
    // possible to use the () => {} syntax here.
    d3.selectAll('rect.bar').each(function () {
      const selection = d3.select(this);
      selection.attr('fill', selection.attr('data-default-fill'));
    });
  }

  function showGroupFlyout({ dimensionIndex, dimensionValue, groupElement, positions, seriesElement }) {
    const $content = self.getGroupFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measures,
      nonFlyoutDataToRender: barDataToRender
    });

    // Payload
    const payload = {
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    // If there is only one bar in the group then we can position the flyout
    // over the bar itself, not the bar group.
    if (groupElement.selectAll('rect.bar')[0].length === 1) {
      _.set(payload, 'element', groupElement[0][0].childNodes[1]);
    } else {
      // Calculate the offsets from screen (0, 0) to the right of the widest
      // bar (where at least one value in the group is > 0) or 0 on the x-axis
      // (where all values in the group are <= 0) and the vertical center of
      // the group in question.
      const flyoutElementBoundingClientRect = groupElement[0][0].getBoundingClientRect();
      const flyoutElementHeight = flyoutElementBoundingClientRect.height;
      const flyoutElementTopOffset = flyoutElementBoundingClientRect.top;

      const maxEnd = _.max(positions[dimensionIndex].map((position) => position.end));
      const seriesBoundingClientRect = seriesElement.getBoundingClientRect();
      const flyoutLeftOffset = seriesBoundingClientRect.left + d3XScale(maxEnd);

      _.set(payload, 'flyoutOffset', {
        top: flyoutElementTopOffset + flyoutElementHeight / 2 - 1,
        left: flyoutLeftOffset
      });
    }

    self.emitEvent('SOCRATA_VISUALIZATION_BAR_CHART_FLYOUT', payload);
  }

  function showBarFlyout({ dimensionIndex, dimensionValue, element, measureIndex, percent, value }) {
    if (value === null && self.shouldDropNullFlyouts()) return;
    const $content = self.getFlyoutContent({
      dimensionIndex,
      dimensionValue,
      flyoutDataToRender,
      measureIndex,
      measures,
      nonFlyoutDataToRender: barDataToRender,
      percent,
      value
    });

    const payload = {
      element,
      content: $content,
      rightSideHint: false,
      belowTarget: false,
      dark: true
    };

    self.emitEvent('SOCRATA_VISUALIZATION_BAR_CHART_FLYOUT', payload);
  }

  function hideFlyout() {
    self.emitEvent('SOCRATA_VISUALIZATION_BAR_CHART_FLYOUT', null);
  }
}

export default SvgBarChart;
