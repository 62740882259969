import {
  chain,
  clamp,
  each,
  every,
  filter,
  find,
  get,
  isEmpty,
  isNil,
  isNumber,
  isString,
  isUndefined,
  merge,
  take,
  values,
  zip
} from 'lodash';
import I18n from 'common/i18n';
import { View } from 'common/types/view';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import {
  COLOR_PALETTE_VALUES_FOR_MAPS,
  DEFAULT_COLOR_PALETTE,
  DEFAULT_SHAPE_FILL_COLOR,
  DEFAULT_POINT_AND_LINE_COLOR,
  EMPTY_BUCKET_VALUE,
  POINT_AGGREGATIONS,
  QUANTIFICATION_METHODS,
  VIF_CONSTANTS
} from 'common/authoring_workflow/constants';
import { getBasemapStyle } from 'common/visualizations/views/map/basemapStyle';
import { getLinearBuckets } from 'common/visualizations/helpers/RangeHelper';
import { OTHER_COLOR_BY_CATEGORY } from 'common/visualizations/views/map/vifOverlays/VifPointOverlay';
import {
  DEFAULT_LAYER_VISIBLE,
  MAP_TYPES,
  QUERY_TIMEOUT_SECONDS
} from 'common/visualizations/views/mapConstants';
import { getSiteAppearanceColorPaletteHexCodes } from 'common/visualizations/helpers/SiteAppearanceColors';
import * as VifSelectors from 'common/visualizations/helpers/VifSelectors';
import { ColorBucket, Vif } from 'common/visualizations/vif';
import { DecoratedVif } from './vifDecorator';
import { getColumnCustomColorPalette } from 'common/visualizations/helpers/palettes/customPaletteHelpers';

export function getAllFlyoutColumns(this: DecoratedVif) {
  return chain([this.getFlyoutTitleColumn()].concat(this.getFlyoutAdditionalColumns()))
    .uniq()
    .compact()
    .value();
}

export function getCastNullAsFalseInSeries(this: DecoratedVif) {
  const type = this.series[0].type;
  if (type === 'scatterChart') {
    return this.configuration.showNullsAsFalse ?? VIF_CONSTANTS.DEFAULT_SHOW_NULLS_AS_FALSE;
  }

  return this.series[0].mapOptions?.castNullAsFalseInSeries ?? VIF_CONSTANTS.DEFAULT_CAST_NULL_AS_FALSE;
}

export function getColorByBucketsCount(this: DecoratedVif) {
  return this.series[0].mapOptions?.colorByBucketsCount ?? VIF_CONSTANTS.COLOR_BY_BUCKETS_COUNT.DEFAULT;
}

export function getColorByQuantificationMethod(this: DecoratedVif) {
  return this.series[0].mapOptions?.colorByQuantificationMethod ?? QUANTIFICATION_METHODS.category.value;
}

/**
 * WARNING: This function only handles maps
 * See common/authoring_workflow/selectors/vifAuthoring's
 * getColorPaletteGroupingColumnName for all chart types with color palette grouping.
 */
export function getMapColorGroupNameByColumn(
  this: DecoratedVif,
  vif = this,
  mapType = this.getMapType(),
  pointAggregation = this.getPointAggregation(),
  seriesIndex = 0
) {
  let colorGroupingColumnName;

  if (mapType === 'pointMap') {
    if (pointAggregation === POINT_AGGREGATIONS.REGION_MAP) {
      colorGroupingColumnName = vif.series[seriesIndex].computedColumnName;
    } else {
      colorGroupingColumnName = vif.series[seriesIndex].mapOptions?.colorPointsBy;
    }
  } else if (mapType === 'lineMap') {
    colorGroupingColumnName = vif.series[seriesIndex].mapOptions?.colorLinesBy;
  } else if (mapType === 'boundaryMap') {
    colorGroupingColumnName = vif.series[seriesIndex].mapOptions?.colorBoundariesBy;
  } else {
    return null;
  }

  return colorGroupingColumnName ?? null;
}

/**
 *
 * @param count Number of colors to return
 * @returns Array of hexcode colors in order
 */
export function getColorPalette(this: DecoratedVif, count?: number) {
  if (this.getColorPaletteId() === 'custom') {
    const defaultColorPalette = COLOR_PALETTE_VALUES_FOR_MAPS[DEFAULT_COLOR_PALETTE];
    const customColors = defaultColorPalette(count);
    const customColorPalettes = this.getCustomColorPalettes();

    if (customColorPalettes !== null) {
      const colorGroupName = this.getMapColorGroupNameByColumn();
      if (colorGroupName === null) {
        return;
      }
      const currentCustomPalette = getColumnCustomColorPalette(this, 0, colorGroupName);
      const customPaletteColorConfigs = values(currentCustomPalette);
      each(customPaletteColorConfigs, (colorConfig) => {
        customColors[colorConfig.index] = colorConfig.color;
      });
      return isUndefined(count) ? customColors : take(customColors, count);
    }
  }
  const hexCodes: string[] = getSiteAppearanceColorPaletteHexCodes(this.getColorPaletteId());
  const isCategorical =
    this.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.category.value && !this.isRegionMap();
  const paletteValue = VifSelectors.getColorPaletteValue(this.getColorPaletteId(), isCategorical);
  const colorPaletteGetter = isEmpty(hexCodes)
    ? COLOR_PALETTE_VALUES_FOR_MAPS[paletteValue] ?? COLOR_PALETTE_VALUES_FOR_MAPS[DEFAULT_COLOR_PALETTE]
    : (_count = 20) => {
        return take(hexCodes, clamp(_count, 1, hexCodes.length));
      };

  return colorPaletteGetter(count);
}

export function getColorPaletteId(this: DecoratedVif) {
  return this.series[0].color?.palette || DEFAULT_COLOR_PALETTE;
}

export function getColumnName(this: DecoratedVif) {
  return this.series[0].dataSource.dimension.columnName;
}

export function getCustomColorPalettes(this: DecoratedVif) {
  return this.series[0].color?.customPalette ?? null;
}

export async function getDatasetMetadata(this: DecoratedVif): Promise<View> {
  const datasetConfig = {
    domain: this.getDomain(),
    datasetUid: this.getDatasetUid()
  };

  return new MetadataProvider(datasetConfig, true).getDatasetMetadata();
}

export function getDatasetSoqlDataProvider(this: DecoratedVif, cache = true): SoqlDataProvider {
  return new SoqlDataProvider(
    {
      domain: this.getDomain(),
      datasetUid: this.getDatasetUid(),
      queryTimeout: QUERY_TIMEOUT_SECONDS,
      clientContextVariables: this.getParameterOverrides()
    },
    cache
  );
}

export function getParameterOverrides(this: DecoratedVif) {
  if (this.series[0].dataSource.type === 'socrata.inline') {
    throw new Error('socrata.inline data source does not make sense here');
  }

  return VifSelectors.getParameterOverrides(this);
}

export function getDatasetUid(this: DecoratedVif) {
  if (this.series[0].dataSource.type === 'socrata.inline') {
    throw new Error('socrata.inline data source does not make sense here');
  }

  return this.series[0].dataSource.datasetUid;
}

export function getDomain(this: DecoratedVif) {
  return this.series[0].dataSource.domain ?? (getDefaultDomain() as string);
}

interface GetFeatureColorOptions {
  /** Column name or alias to color by  */
  colorBy: string;
  colorByBuckets: ColorBucket[];
  datasetMetadata: View;
}
/**
 * If there are no buckets
 *    Use the primary configured color for all features
 * If there are buckets linear quantification
 *    Set stops with one color for each range from the configured palette.
 * If there are buckets and categorical or no quantification
 *    Set stops with one color for each category from the configured palette.
 *    And set the next available color in the palette as default
 *    for the remaining categories.
 */
export function getFeatureColor(
  this: DecoratedVif,
  { colorBy, colorByBuckets, datasetMetadata }: GetFeatureColorOptions
) {
  if (isEmpty(colorByBuckets)) {
    const mapType = this.series[0].mapOptions?.mapType;

    if (mapType === MAP_TYPES.BOUNDARY_MAP) {
      return this.series[0].mapOptions?.shapeFillColor ?? DEFAULT_SHAPE_FILL_COLOR;
    }

    return this.series[0].color?.primary ?? VIF_CONSTANTS.FEATURE_COLOR;
  }

  const otherColorByBucket = this.getOtherColorByBucket(
    colorByBuckets,
    this.isColorByBooleanColumn(datasetMetadata)
  );

  if (this.getColorByQuantificationMethod() === QUANTIFICATION_METHODS.linear.value) {
    return getPaintPropertyForColorByRanges(colorBy, colorByBuckets, this.getColorByBucketsCount());
  }

  return getPaintPropertyForColorByCategories(colorBy, colorByBuckets, otherColorByBucket);
}

export function getFlyoutAdditionalColumns(this: DecoratedVif) {
  return this.series[0].mapOptions?.additionalFlyoutColumns;
}

export function getFlyoutTitleColumn(this: DecoratedVif) {
  return this.series[0].mapOptions?.mapFlyoutTitleColumnName;
}

// Returns geocoding boundary [minX, minY, maxX, maxY] if configured in vif.
export function getGeocodeBoundingbox(this: DecoratedVif) {
  const boundary = [
    this.configuration.basemapOptions?.searchBoundaryUpperLeftLongitude,
    this.configuration.basemapOptions?.searchBoundaryLowerRightLatitude,
    this.configuration.basemapOptions?.searchBoundaryLowerRightLongitude,
    this.configuration.basemapOptions?.searchBoundaryUpperLeftLatitude
  ];

  if (every(boundary, isNumber)) {
    return boundary;
  } else {
    return null;
  }
}

export function getMapFlyoutPrecision(this: DecoratedVif) {
  return this.configuration.mapFlyoutPrecision;
}

export function getMapLegendPrecision(this: DecoratedVif) {
  return this.configuration.mapLegendPrecision;
}

export function getMapType(this: DecoratedVif) {
  return this.series[0].mapOptions?.mapType;
}

export function getMeasureAggregation(this: DecoratedVif) {
  return this.series[0].dataSource.measure?.aggregationFunction ?? 'count';
}

export function getMeasureColumn(this: DecoratedVif) {
  return this.series[0].dataSource.measure?.columnName ?? '*';
}

export function getMeasureForeignKey(this: DecoratedVif) {
  return this.series[0].computedColumnName;
}

export function getMidpoint(this: DecoratedVif) {
  const midpoint = this.series[0].mapOptions?.midpoint;
  return isEmpty(midpoint) || midpoint === undefined ? null : parseFloat(midpoint);
}

export function getPointAggregation(this: DecoratedVif) {
  return this.series[0].mapOptions?.pointAggregation;
}

export function getRegionMapFlyoutColumnAndAggregations(this: DecoratedVif) {
  return this.series[0].mapOptions?.regionMapFlyoutColumnAndAggregations;
}

export function getResizeByRangeOptions(this: DecoratedVif) {
  let rangeOptions;
  const dataClasses =
    this.series[0].mapOptions?.numberOfDataClasses ?? VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.DEFAULT;

  if (this.getMapType() === MAP_TYPES.LINE_MAP) {
    rangeOptions = merge(
      {},
      {
        minValue:
          this.series[0].mapOptions?.minimumLineWeight ?? VIF_CONSTANTS.LINE_MAP_MIN_LINE_WEIGHT.DEFAULT,
        maxValue:
          this.series[0].mapOptions?.maximumLineWeight ?? VIF_CONSTANTS.LINE_MAP_MAX_LINE_WEIGHT.DEFAULT,
        dataClasses: dataClasses
      }
    );
  } else {
    rangeOptions = {
      minValue: this.series[0].mapOptions?.minimumPointSize ?? VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.DEFAULT,
      maxValue: this.series[0].mapOptions?.maximumPointSize ?? VIF_CONSTANTS.POINT_MAP_MAX_POINT_SIZE.DEFAULT,
      dataClasses: dataClasses
    };
  }

  return rangeOptions;
}

/**
 * Example:
 *    Input:
 *      resizeByRange: {min: 100 max: 200}, minPixels: 10, maxPixels: 20, buckets: 2
 *    Output:
 *      {
 *        stops: [[100, 10], [150, 20]],  // Meaning (100 & above => 10 pixels) (150 & above => 20 pixels)
 *          ...
 *      }
 *    Input:
 *      resizeByRange: {min: 100 max: 200}, minPixels: 10, maxPixels: 20, buckets: 3
 *    Output:
 *      {
 *        stops: [[100, 10], [133, 15], [166, 20]],
 *          ...
 *      }
 * @param aggregateAndResizeBy select as alias used for ResizeBy column in the tile data calls
 * @param resizeByRange minimum and maximum value of the selected column in ResizeRangeBy
 * @param minPixels min Value from the MapOptions will be LineWeight | PointSize
 * @param maxPixels max Value from the MapOptions will be  LineWeight | PointSize
 * @param dataClasses Number of data classes from the mapOptions
 */
export function getResizeByRangePaintProperty(
  this: DecoratedVif,
  aggregateAndResizeBy: '__count__' | '__resize_by__' | '__weigh_by__',
  resizeByRange: { max: number; min: number },
  minPixels: number,
  maxPixels: number,
  dataClasses: number
) {
  if (minPixels === maxPixels) {
    return minPixels;
  }
  if (resizeByRange.max === resizeByRange.min) {
    return (minPixels + maxPixels) / 2;
  }

  const valueBuckets: number[] = getLinearBuckets(resizeByRange.max, resizeByRange.min, dataClasses);
  const radiusBuckets: number[] = getLinearBuckets(maxPixels, minPixels, dataClasses - 1);
  const stops = zip(take(valueBuckets, valueBuckets.length - 1), radiusBuckets);

  // If it is a cluster, it would have already converted the resizeBy value to number based on
  // the expression in `clusterProperties`. Otherwise the reszieby value is returned in json
  // as a string. We are converting it into number before using it.
  const variable = [
    'let',
    'i',
    [
      'case',
      ['has', 'point_count'],
      ['get', aggregateAndResizeBy],
      ['to-number', ['get', aggregateAndResizeBy]]
    ]
  ];
  const steps = ['step', ['var', 'i'], minPixels];
  each(stops, (stop) => {
    const stopRangeEnd = get(stop, 0);
    const stopValue = get(stop, 1);

    if (!isUndefined(stopRangeEnd) && !isUndefined(stopValue)) {
      steps.push(stopRangeEnd);
      steps.push(stopValue);
    }
  });
  return [...variable, steps];
}

export function getSeriesName(this: DecoratedVif) {
  if (this.series[0].dataSource.type === 'socrata.inline') {
    throw new Error('socrata.inline data source does not make sense here');
  }
  return this.series[0].dataSource.name;
}

export function getShapeDatasetPrimaryKey(this: DecoratedVif) {
  return this.series[0].shapefile?.primaryKey;
}

export function getShapeDatasetUid(this: DecoratedVif) {
  if (this.series[0].shapefile === undefined) {
    throw new Error('No shapefile associated with this map');
  }
  return this.series[0].shapefile?.uid;
}

export function getShapeDatasetMetadata(this: DecoratedVif) {
  const datasetConfig = {
    domain: this.getDomain(),
    datasetUid: this.getShapeDatasetUid()
  };

  return new MetadataProvider(datasetConfig, true).getDatasetMetadata();
}

export function getShowLegendForMap(this: DecoratedVif) {
  return this.configuration.showLegendForMap ?? VIF_CONSTANTS.DEFAULT_SHOW_LEGEND_FOR_MAP;
}

export function getSimplificationLevel(this: DecoratedVif) {
  return this.series[0].mapOptions?.simplificationLevel ?? VIF_CONSTANTS.SIMPLIFICATION_LEVEL.DEFAULT;
}

export function getUnits(this: DecoratedVif, count: number, aggregation = 'count') {
  const scope = 'shared.visualizations.panes.legends_and_flyouts.fields';
  const singular = this.series[0].unit?.one;
  const plural = this.series[0].unit?.other;

  let singularDefault = I18n.t('placeholders.row', { scope });
  let pluralDefault = I18n.t('placeholders.rows', { scope });

  if (aggregation !== 'count') {
    singularDefault = I18n.t('sum_aggregation_unit', { scope });
    pluralDefault = I18n.t('sum_aggregation_unit', { scope });
  }

  // If singular/plural are empty strings, we should use the respective defaults.
  if (Math.abs(count) === 1) {
    return isEmpty(singular) ? singularDefault : singular;
  } else {
    return isEmpty(plural) ? pluralDefault : plural;
  }
}

export function isColorByBooleanColumn(this: DecoratedVif, datasetMetadata: View) {
  const colorByColumn = this.getMapColorGroupNameByColumn();
  const colorByColumnDetails = find(datasetMetadata.columns, ['fieldName', colorByColumn]);
  return colorByColumnDetails?.renderTypeName === 'checkbox';
}

export function isCustomColorPalette(this: DecoratedVif) {
  return this.getColorPaletteId() === 'custom';
}

export function isLayerVisible(this: DecoratedVif) {
  const series = this.series ?? [];
  return series[0].visible ?? DEFAULT_LAYER_VISIBLE;
}

export function shouldShowLegendItems(this: DecoratedVif) {
  return this.series[0].showLegend ?? false;
}

/**
 * Returns mapbox-gl expression that paints the geometry based on the colorByColumn value
 * and the colorByCategories.
 */
function getPaintPropertyForColorByCategories(
  colorByColumnAlias: string,
  colorByBuckets: ColorBucket[],
  otherColorByBucket: ColorBucket
) {
  const paintProperty = ['match', ['to-string', ['get', colorByColumnAlias]]];
  if (isEmpty(colorByBuckets)) {
    return VIF_CONSTANTS.FEATURE_COLOR;
  }

  chain(colorByBuckets)
    .take(colorByBuckets.length)
    .each((colorByBucket) => {
      paintProperty.push(colorByBucket.id, colorByBucket.color);
    })
    .value();

  paintProperty.push(otherColorByBucket.color);

  return paintProperty;
}

/**
 * Returns mapbox-gl expression that colors the geometries based on colorByColumn
 * values and the colorByRange
 */
function getPaintPropertyForColorByRanges(
  colorByColumnAlias: string,
  colorByBuckets: ColorBucket[],
  numberOfBuckets: number
) {
  const paintSteps = ['step', ['to-number', ['get', colorByColumnAlias], -1, -1]];

  const defaultColor = chain(colorByBuckets)
    .find(['id', EMPTY_BUCKET_VALUE])
    .get('color', DEFAULT_POINT_AND_LINE_COLOR)
    .value();

  // default color
  paintSteps.push(defaultColor);

  chain(colorByBuckets)
    .take(numberOfBuckets)
    .each((colorByBucket) => {
      paintSteps.push(colorByBucket.id, colorByBucket.color);
    })
    .value();

  return ['case', ['==', ['get', colorByColumnAlias], null], defaultColor, paintSteps];
}

export function getStackSize(this: DecoratedVif) {
  return chain(this.series)
    .map((seriesItem) => {
      const { mapOptions } = seriesItem;
      const resizePointsBy = mapOptions?.resizePointsBy;

      if (!isString(resizePointsBy)) {
        return mapOptions?.pointSize ?? VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT / 2;
      }
      return mapOptions?.maximumPointSize ?? VIF_CONSTANTS.POINT_MAP_MAX_POINT_SIZE.DEFAULT / 2;
    })
    .max()
    .value();
}

export function getOtherColorByBucket(
  this: DecoratedVif,
  colorByBuckets: ColorBucket[],
  isBooleanColumn = false
) {
  let otherColorByCategoryBucket = find(colorByBuckets, (colorByBucket) => {
    return colorByBucket.id === OTHER_COLOR_BY_CATEGORY;
  });

  if (isBooleanColumn) {
    const defaultBooleanBucket = find(colorByBuckets, ['label', 'false']);
    otherColorByCategoryBucket = isNil(defaultBooleanBucket)
      ? otherColorByCategoryBucket
      : defaultBooleanBucket;
  }

  return (
    otherColorByCategoryBucket ??
    ({
      color: VIF_CONSTANTS.FEATURE_COLOR,
      dashed: false,
      charmName: '',
      id: OTHER_COLOR_BY_CATEGORY,
      label: 'Other'
    } as ColorBucket)
  );
}

export function hasBaseMapStyleChanged(this: DecoratedVif, existingVif: Vif) {
  const newBasemapStyle = getBasemapStyle(this);
  const existingBasemapStyle = getBasemapStyle(existingVif);

  return newBasemapStyle !== existingBasemapStyle;
}

export function hasMultiplePointMapSeries(this: DecoratedVif) {
  const pointMapSeries = filter(this.series, (seriesItem) => {
    return seriesItem.mapOptions?.mapType === 'pointMap';
  });

  return pointMapSeries.length > 1;
}

export function isMultiPointColumn(this: DecoratedVif, datasetMetadata: View) {
  const columnFormat = find(datasetMetadata.columns, { fieldName: this.getColumnName() });

  return columnFormat?.renderTypeName === 'multipoint';
}
