import Actions from 'Actions';
import { actionStarted, actionEnded, actionCancelled } from 'lib/ActionComponentHelper';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import { chosenMoveComponentDestination } from 'store/reducers/StoryReducer';

interface ActionComponentState {
  actionType: string | null;
  sourceBlockId: string | null;
  sourceComponentIndex: number | null;
  isUserChoosingTargetComponent: boolean;
}

export interface ActionComponentPayload {
  actionType: COMPONENT_ACTION_TYPES;
  blockId: string | null;
  componentIndex: number | null;
}

const initialState = {
  actionType: null,
  sourceBlockId: null,
  sourceComponentIndex: null,
  isUserChoosingTargetComponent: false
} as ActionComponentState;

/* Action Creators */
export const actionComponentStart = createAction<ActionComponentPayload>(Actions.ACTION_COMPONENT_START);
export const actionComponentCancel = createAction(Actions.ACTION_COMPONENT_CANCEL);

const actionComponentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actionComponentStart, (state, action) => {
      const { payload } = action;
      actionStarted(payload, state);
    })
    .addCase(chosenMoveComponentDestination, (state) => {
      actionEnded(state);
    })
    .addCase(actionComponentCancel, (state) => {
      actionCancelled(state);
    });
});

export default actionComponentReducer;
