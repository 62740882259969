import isString from 'lodash/isString';

import assert from './assert';

type AssertIsString = (value: unknown, message?: string) => asserts value is string;

/**
 * Ensures the given value is a string.
 *
 * @param {any} object - The object to check
 * @param {string} message - An explanatory error message.
 */
const assertIsString: AssertIsString = (value, message) => {
  assert(isString(value), message || `Expected ${value} to be a string.`);
};

export default assertIsString;
