import { chain, cloneDeep, each, get, has, set } from 'lodash';

import { BASEMAP_STYLES, VIF_CONSTANTS } from 'common/authoring_workflow/constants';
import { Vif } from 'common/visualizations/vif';

// To simplify migration functions, they should be ordered by integer version numbers.
// To add a new migration:
// 1. import the new migration function
// 2. add the new migration function to the migrateFunctions object
// 3. update the latest version number in VIF_CONSTANTS

import { migrateVif1ToVif2 } from './vif1ToVif2';
import { migrateVif2ToVif3 } from './vif2ToVif3';
import { migrateVif3ToVif4 } from './vif3ToVif4';
import { migrateVif4ToVif5 } from './vif4ToVif5';
import { addColumnsToFilters, migrateVif5ToVif6 } from './vif5ToVif6';
import { migrateVif6ToVif7 } from './vif6ToVif7';
import { migrateVif7ToVif8 } from './vif7ToVif8';

const migrateFunctions = {
  0: incrementVifVersion,
  1: migrateVif1ToVif2,
  2: migrateVif2ToVif3,
  3: migrateVif3ToVif4,
  4: migrateVif4ToVif5,
  5: migrateVif5ToVif6,
  6: migrateVif6ToVif7,
  7: migrateVif7ToVif8
};

export function migrateVif(vifToMigrate: any): Vif {
  let newVif = recurseMigrateVif(vifToMigrate);
  newVif = handleDeprecations(newVif);
  return migrateFilters(newVif);
}

// Increment version number only.
function incrementVifVersion(vifToMigrate: any): any {
  const newVif = cloneDeep(vifToMigrate);
  const version = parseInt(get(vifToMigrate, 'format.version', 1), 10);
  newVif.format.version = version + 1;
  return newVif;
}

function recurseMigrateVif(vifToMigrate: any): any {
  const version = parseInt(get(vifToMigrate, 'format.version', 1), 10);
  if (migrateFunctions[version]) {
    const newVif = migrateFunctions[version](vifToMigrate);
    return recurseMigrateVif(newVif);
  } else if (version < VIF_CONSTANTS.LATEST_VERSION) {
    // If we don't have a migration function for the version, but we are not at the latest,
    // possibly we skipped a major version number for some reason. Continue to recurse.
    return recurseMigrateVif(incrementVifVersion(vifToMigrate));
  } else {
    // We have the latest version.
    return cloneDeep(vifToMigrate);
  }
}

/**
 * Given a VIF, replace deprecated basemap styles.
 * @param {Vif} newVif
 */
export function handleDeprecations(newVif: Vif) {
  const basemapStylePathToReplacementKey = {
    'configuration.basemapOptions.basemapStyle': 'value',
    'configuration.baseLayerUrl': 'legacyMapsTileUrlValue'
  };

  each(basemapStylePathToReplacementKey, (basemapReplacementKey, basemapStylePath) =>
    handleBasemapDeprecations(newVif, basemapStylePath, basemapReplacementKey)
  );

  return newVif;
}

const deprecatedBaseMapStylesToConfig = () =>
  chain(BASEMAP_STYLES)
    .filter((basemapStyle) => has(basemapStyle, 'deprecatedValue'))
    .keyBy('deprecatedValue')
    .value();

/**
 * Replace deprecated basemap styles in any object. This is used for VIFs and legacy map renderOptions.
 * @param {object} anyObject
 * @param {string} basemapStylePath Path in anyObject to find the basemap style
 * @param {string} basemapReplacementKey Key in a BASEMAP_STYLES object to replace with
 */
export function handleBasemapDeprecations(
  anyObject: Record<string, any>,
  basemapStylePath: string,
  basemapReplacementKey: string
) {
  const basemapStyleValue = get(anyObject, basemapStylePath);
  const deprecatedBaseMapStyleConfig = get(deprecatedBaseMapStylesToConfig(), [basemapStyleValue]);

  if (deprecatedBaseMapStyleConfig) {
    set(anyObject, basemapStylePath, deprecatedBaseMapStyleConfig[basemapReplacementKey]);
  }

  return anyObject;
}

function migrateFilters(vifToMigrate: Vif) {
  vifToMigrate.series = vifToMigrate.series.map((series) => {
    if (
      !series.dataSource.filters ||
      series.dataSource.filters?.length === 0 ||
      series.dataSource.type !== 'socrata.soql'
    ) {
      return series;
    }
    const { filters, datasetUid } = series.dataSource;

    series.dataSource.filters = addColumnsToFilters(filters, datasetUid);

    return series;
  });

  return vifToMigrate;
}
