import React, { FunctionComponent, PropsWithChildren } from 'react';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { USER_TYPES } from 'common/components/AccessManager/Constants';

import UserLabel from './UserLabel';

import './list-item.scss';
import { ViewUser } from 'common/types/view';

const classNameScope = 'common--components--AccessManager--components--UserDetails--list-item';

interface UserDetailsProps {
  user: ViewUser;
  className?: string;
}

/**
 * Children are rendered after the user label is, for optional actions that can
 * be taken for a user (i.e. changing permissions)
 */
const UserDetails: FunctionComponent<PropsWithChildren<UserDetailsProps>> = ({
  user,
  className,
  children
}) => {
  const { type } = user;

  return (
    <div className={`${className} ${classNameScope}--container`}>
      {/*
        Unfortunately we can't just use "type" here as the icon name since
        the type is "interactive" but the icon is "user"
      */}
      {type && type === USER_TYPES.TEAM ? (
        <SocrataIcon name={IconName.Team} className={`${classNameScope}--icon`} />
      ) : (
        <SocrataIcon name={IconName.User} className={`${classNameScope}--icon`} />
      )}
      <div className={`${classNameScope}--inner-container`}>
        <UserLabel user={user} />
        {children}
      </div>
    </div>
  );
};

export default UserDetails;
