import React, { FC } from 'react';
import { ForgeInlineMessage, ForgeIcon } from '@tylertech/forge-react';
import { useWatchContext } from '../context';

export interface CreationErrorsProps {
  createErrors: string[];
  requestId: string;
}

const CreationErrors: FC<CreationErrorsProps> = ({ createErrors, requestId }) => {
  const { t } = useWatchContext();
  if (createErrors.length < 1) return null;

  return (
    <ForgeInlineMessage theme="warning" slot="header">
      <ForgeIcon slot="icon" name="warning" className="socrata-icon-warning">
        warning
      </ForgeIcon>
      <div>{t('subscription_creation_error_msg_part1')}</div>
      <div>
        {createErrors.map((error, i) => (
          <li key={i}>{error} </li>
        ))}
      </div>
      <br></br>
      <div>{t('subscription_creation_error_msg_part2')}</div>
      <div>
        <li>{t('subscription_creation_error_msg_part3')}</li>
        <li>
          {t('subscription_creation_error_msg_part4')} {requestId}
        </li>
      </div>
    </ForgeInlineMessage>
  );
};

export default CreationErrors;
