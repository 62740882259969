import has from 'lodash/has';
import assert from './assert';

type PropertyKey = string | number | symbol;

type AssertHasProperties = <T, P extends PropertyKey>(
  object: T,
  name: P,
  message?: string
) => asserts object is T & Record<P, unknown>;

const assertHasProperties: AssertHasProperties = (object, name, message) => {
  const getDefaultErrorMessage = () => {
    const stringName = String(name);
    const propertyMessage = `'${stringName}' property must be present.`;

    // If the object is undefined/null, object.keys will throw an error
    const endingMessage = object
      ? `Object has properties: [${Object.keys(object).join(', ')}].`
      : 'Object is not an object.';

    return `${propertyMessage} ${endingMessage}`;
  };

  assert(has(object, name), message || getDefaultErrorMessage());
};

export default assertHasProperties;
