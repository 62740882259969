import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import I18n from 'common/i18n';

import { userHasAccessLevel } from 'common/components/AccessManager/Util';
import { AccessLevelName, ViewUser } from 'common/types/view';

import './index.scss';
import { getAddedUsers } from 'common/components/AccessManager/sagas/Selectors';

const classNameScope = 'common--components--AccessManager--components--AudienceScopeChooser--UserCounts';

const i18nScope = 'shared.site_chrome.access_manager.audience.user_counts';

/**
 * Get the count of users that are collaborators on an asset.
 *
 * This will filter out the current owner of the asset,
 * as well as any disabled users.
 *
 * @param users List of users
 * @param userFilter Optional extra filter to filter out users
 */
const collaboratorCount = (users: ViewUser[], userFilter: ((user: ViewUser) => boolean) | null = null) =>
  users
    .filter((user) => !userHasAccessLevel(user, AccessLevelName.CurrentOwner))
    .filter((user) => !((user as any).flags || []).includes('disabled'))
    .filter(userFilter || (() => true)).length;

const UserCounts: FunctionComponent = () => {
  const users = useSelector(getAddedUsers);

  if (!users) {
    return null;
  }

  const individualCollaborators = collaboratorCount(users, (user) => user.type !== 'team');
  const teamCollaborators = collaboratorCount(users, (user) => user.type === 'team');

  const individualCollaboratorsDiv = individualCollaborators > 0 && (
    <div>{I18n.t('user', { count: individualCollaborators, scope: i18nScope })}</div>
  );

  const teamCollaboratorsDiv = teamCollaborators > 0 && (
    <div>{I18n.t('team', { count: teamCollaborators, scope: i18nScope })}</div>
  );

  return (
    <div className={`user-counts ${classNameScope}user-counts`}>
      {individualCollaboratorsDiv}
      {teamCollaboratorsDiv}
    </div>
  );
};

export default UserCounts;
