// Vendor Imports
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './tabs.scss';

export class Tabs extends Component {

  // The tab argument can be a string or a object of the form:
  // { text, value }
  renderTab = (tab) => {
    const { selectedTab, onTabChange } = this.props;
    const tabName = _.isObject(tab) ? _.get(tab, 'value') : tab;
    const title = _.isObject(tab) ? _.get(tab, 'title') : tab;
    const listProps = {
      className: classNames('tab-link', {
        'current': selectedTab === tabName
      }),
      key: tabName
    };

    return (
      <li {...listProps}>
        <a href="#" onClick={() => onTabChange(tabName)}>{title}</a>
      </li>
    );
  };

  render() {
    const renderedTabs = this.props.tabs.map((tab) => this.renderTab(tab));
    return (
      <ul className="tabs">
        {renderedTabs}
      </ul>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func
};

export default Tabs;
