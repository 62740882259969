import { FeatureFlags } from 'common/feature_flags';
import { currentUserIsSuperAdmin } from 'common/current_user';
import { isDataFederationUnsupportedAssetType } from 'common/js_utils/federation';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { View } from 'common/types/view';
import { hasGrantRight } from 'common/views/has_rights';

import { AssetStatus, assetStatusFor } from './asset_status';

/**
 * Determine whether or not a user can "selectively federate" an asset.
 *
 * At the time of writing, this is gated by the "selective_federation" feature flag and is only available to superadmins.
 *
 * @param view View to check for federation privileges of
 * @param approvalsGuidance Approvals guidance summary
 * @returns Whether or not the current user can selectively federate an asset
 */
const currentUserCanFederate = (view: View, approvalsGuidance: GuidanceSummaryV2) =>
  // only allow superadmins IF selective_federation is on
  FeatureFlags.value('selective_federation') &&
  currentUserIsSuperAdmin() &&
  !isDataFederationUnsupportedAssetType(view) &&
  // being lazy here and checking for grant as a proxy for "on the source domain"
  // on the target domain, the user will only have "read"
  hasGrantRight(view) &&
  assetStatusFor(view, approvalsGuidance) === AssetStatus.Published;

export default currentUserCanFederate;
