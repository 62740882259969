import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';

export interface FilterFooterProps {
  showApplyButton?: boolean;
  disableApplyFilter?: boolean;
  isDrilldown?: boolean;
  isReadOnly?: boolean;
  onClickApply?: (e: React.MouseEvent) => void;
  onClickRemove?: (e: React.MouseEvent) => void;
  onClickReset?: (e: React.MouseEvent) => void;
  showRemoveButton?: boolean;
}

export default function FilterFooter(props: FilterFooterProps) {
  const {
    disableApplyFilter,
    isDrilldown,
    isReadOnly,
    showApplyButton,
    onClickApply,
    onClickRemove,
    onClickReset,
    showRemoveButton
  } = props;

  const removeButton = (
      <ForgeButton>
        <button type="button" className="remove-btn" onClick={onClickRemove}>
          <ForgeIcon name="delete" external external-type="standard" />
          {I18n.t('shared.components.filter_bar.remove')}
        </button>
      </ForgeButton>
    );

  const applyButton = (
      <ForgeButton type="raised">
        <button
          type="button"
          className="apply-btn"
          onClick={onClickApply}
          disabled={disableApplyFilter}>
          {I18n.t('shared.components.filter_bar.apply')}
        </button>
      </ForgeButton>
    );

  const resetButton = (
      <ForgeButton>
        <button type="button" className="reset-btn" onClick={onClickReset}>
          {I18n.t('shared.components.filter_bar.reset')}
        </button>
      </ForgeButton>
    );

  const containerClasses = classNames('filter-footer', {
    'no-apply-button': !showApplyButton,
  });

  // Use an empty div instead of null so flexbox works properly
  return (
    <div className={containerClasses}>
      {(showRemoveButton && !(isReadOnly || isDrilldown)) ? removeButton : <div />}
      <div className="apply-btn-container">
        {!!onClickReset && resetButton}
        {(showApplyButton && applyButton )}
      </div>
    </div>
  );
}

FilterFooter.propTypes = {
  showApplyButton: PropTypes.bool,
  disableApplyFilter: PropTypes.bool,
  isDrilldown: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onClickApply: PropTypes.func,
  onClickRemove: PropTypes.func,
  onClickReset: PropTypes.func,
  showRemoveButton: PropTypes.bool
};

FilterFooter.defaultProps = {
  showApplyButton: true,
  isDrilldown: false,
  onClickApply: _.noop,
  showRemoveButton: false
};
