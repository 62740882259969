/* Imports */
/* ============================================================================= */
import {
  GuidanceV2,
  WorkflowGuidance,
} from 'common/types/approvals';

import { getSubmissionActionObject } from 'common/core/approvals/guidanceHelpers';





/* Method */
/* ============================================================================= */
/**
 * Get the published UID of a draft asset.
 *
 * @param guidance WorkflowGuidance object
 * @returns The UID or undefined
 */
export const publishedUidV2 = (guidance: WorkflowGuidance): string | undefined => {
  const submissionActionObject: GuidanceV2 | undefined = getSubmissionActionObject(guidance);

  return submissionActionObject?.publishedViewUid;
};

export default publishedUidV2;
