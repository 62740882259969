import _ from 'lodash';

import { DEFAULT_BASEMAP_STYLE } from 'common/authoring_workflow/constants';

// Layer styles for every map style.
// Key =>  map style url,
// Value => layer style constants.
const BASEMAP_SPECIFIC_LAYER_STYLES = Object.freeze({
  // Raster: Simple Blue
  'mapbox://styles/socrata/ckgs33yql0thi19nn0qvl9op8': Object.freeze({
    CLUSTER_COLOR: '#cc3030',
    CLUSTER_BORDER_COLOR: '#330000',
    CLUSTER_TEXT_COLOR: '#ffffff',
    INSERT_FILL_LAYERS_BEFORE: 'waterway-label',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#cc3030',
    STACK_TEXT_COLOR: '#000000'
  }),
  // Raster: Simple Grey
  'mapbox://styles/socrata/ckgs5ndzb0w4i19pfiw9mmbbu': Object.freeze({
    CLUSTER_COLOR: '#196aa1',
    CLUSTER_BORDER_COLOR: '#ffffff',
    CLUSTER_TEXT_COLOR: '#ffffff',
    INSERT_FILL_LAYERS_BEFORE: 'waterway-label',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#196aa1',
    STACK_TEXT_COLOR: '#000000'
  }),
  // Raster: ESRI
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}': Object.freeze({
    CLUSTER_COLOR: '#b1017e',
    CLUSTER_BORDER_COLOR: '#ffffff',
    CLUSTER_TEXT_COLOR: '#ffffff',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#b1017e',
    STACK_TEXT_COLOR: '#000000'
  }),
  'mapbox://styles/mapbox/basic-v9': {
    CLUSTER_COLOR: '#046c8f',
    CLUSTER_BORDER_COLOR: '#003b4d',
    CLUSTER_TEXT_COLOR: '#ffffff',
    INSERT_FILL_LAYERS_BEFORE: 'admin_country',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#046c8f',
    STACK_TEXT_COLOR: '#656565'
  },
  'mapbox://styles/socrata/ck8ezilto0my11is3xzjlf8d1': {
    CLUSTER_COLOR: '#046c8f',
    CLUSTER_BORDER_COLOR: '#003b4d',
    CLUSTER_TEXT_COLOR: '#ffffff',
    INSERT_FILL_LAYERS_BEFORE: 'waterway-label',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#046c8f',
    STACK_TEXT_COLOR: '#656565'
  },
  'mapbox://styles/mapbox/streets-v9': {
    CLUSTER_COLOR: '#1114de',
    CLUSTER_BORDER_COLOR: '#ffffff',
    CLUSTER_TEXT_COLOR: '#ffffff',
    INSERT_FILL_LAYERS_BEFORE: 'waterway-label',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#1114de',
    STACK_TEXT_COLOR: '#000e60'
  },
  'mapbox://styles/mapbox/bright-v9': {
    CLUSTER_COLOR: '#c2869b',
    CLUSTER_BORDER_COLOR: '#741f3d',
    CLUSTER_TEXT_COLOR: '#000',
    INSERT_FILL_LAYERS_BEFORE: 'rail_station_label',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#c2869b',
    STACK_TEXT_COLOR: '#000e60'
  },
  'mapbox://styles/mapbox/light-v9': {
    CLUSTER_COLOR: '#d3f7ff',
    CLUSTER_BORDER_COLOR: '#5bd0ea',
    CLUSTER_TEXT_COLOR: '#000000',
    INSERT_FILL_LAYERS_BEFORE: 'waterway-label',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#5bd0ea',
    STACK_TEXT_COLOR: '#000e60'
  },
  'mapbox://styles/mapbox/dark-v9': {
    CLUSTER_COLOR: '#fdc9d3',
    CLUSTER_BORDER_COLOR: '#ffffff',
    CLUSTER_TEXT_COLOR: '#000000',
    INSERT_FILL_LAYERS_BEFORE: 'waterway-label',
    STACK_COLOR: '#d6d6d6',
    STACK_BORDER_COLOR: '#ffffff',
    STACK_TEXT_COLOR: '#000e60'
  },
  'mapbox://styles/mapbox/satellite-v9': {
    CLUSTER_COLOR: '#ffe777',
    CLUSTER_BORDER_COLOR: '#ffffff',
    CLUSTER_TEXT_COLOR: '#000000',
    STACK_COLOR: '#ffffff',
    STACK_BORDER_COLOR: '#747474',
    STACK_TEXT_COLOR: '#000e60'
  }
});

const DEFAULT_LAYER_STYLES = {
  CLUSTER_BORDER_SIZE: 2,
  CLUSTER_BORDER_OPACITY: 0.8,
  STACK_BORDER_SIZE: 2,
  STACK_BORDER_OPACITY: 0.8
};

export function getBasemapLayerStyles(vif) {
  const basemapStyle = getBasemapStyle(vif);
  const basemapSpecificStyle = _.get(BASEMAP_SPECIFIC_LAYER_STYLES, basemapStyle, BASEMAP_SPECIFIC_LAYER_STYLES.DEFAULT);

  return _.merge({}, DEFAULT_LAYER_STYLES, basemapSpecificStyle);
}

export function getBasemapStyle(vif) {
  return _.get(vif, 'configuration.basemapOptions.basemapStyle', DEFAULT_BASEMAP_STYLE);
}
