import { action } from 'typesafe-actions';

import { FederatedSite } from 'common/types/approvals';

import { MODES } from '../Constants';
import { AudienceScope } from 'common/types/view';
import { ApprovalOutcomeMessages } from '../types';

export enum UiActionTypes {
  SAVE_BUTTON_CLICKED = 'SAVE_BUTTON_CLICKED',
  SET_CONFIRM_BUTTON_DISABLED = 'SET_CONFIRM_BUTTON_DISABLED',
  SHOW_APPROVAL_MESSAGE_CHANGED = 'SHOW_APPROVAL_MESSAGE_CHANGED',
  REDIRECT_TO = 'REDIRECT_TO',
  SWITCH_MODES = 'SWITCH_MODES',
  DID_SWITCH_MODES = 'DID_SWITCH_MODES'
}

// Save button was clicked
export const saveButtonClicked = () => action(UiActionTypes.SAVE_BUTTON_CLICKED);

export const setConfirmButtonDisabled = (disabled: boolean) =>
  action(UiActionTypes.SET_CONFIRM_BUTTON_DISABLED, { disabled });

export const redirectTo = (url: string) => action(UiActionTypes.REDIRECT_TO, { url });

export const showApprovalMessageChanged = (
  outcomes: ApprovalOutcomeMessages,
  sitesFederatedToIfMadePublic: FederatedSite[],
  currentScope?: AudienceScope,
  targetScope?: AudienceScope
) =>
  action(UiActionTypes.SHOW_APPROVAL_MESSAGE_CHANGED, {
    outcomes,
    sitesFederatedToIfMadePublic,
    currentScope,
    targetScope
  });

export const switchModes = (newMode: MODES) => action(UiActionTypes.SWITCH_MODES, { newMode });

export const didSwitchModes = (newMode: MODES) => action(UiActionTypes.DID_SWITCH_MODES, { newMode });
