import React from 'react';
import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from '../SocrataIcon';

interface FlannelDismissButtonProps {
  onDismiss: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FlannelDismissButton = ({ onDismiss }: FlannelDismissButtonProps) => {
  const closeButtonAttributes = {
    className: 'socrata-flannel-header-dismiss',
    'aria-label': I18n.t('shared.components.flannel.close_popup'),
    onClick: onDismiss,
    variant: VARIANTS.TRANSPARENT
  };

  return (
    <Button {...closeButtonAttributes}>
      <SocrataIcon name={IconName.Close2} />
    </Button>
  );
};

export default FlannelDismissButton;
