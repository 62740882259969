// This is VIF compatibility checker. It makes sure the socrata.soql
// data source is compatible with the demands of a visualization.
//
// The intent of this is to provide feedback while authoring a visualization,
// not to provide feedback to a developer. As such, messages returned are
// worded to make sense to a user.
import _ from 'lodash';
import I18n from 'common/i18n';
import { MetadataProvider } from 'common/visualizations/dataProviders';

const errorScope = {scope: 'shared.visualizations.charts.common.validation.errors'};
// Obtains a soqlVifValidator (see below).
// Returns a Promise<soqlVifValidator>.
// The async is required because dataset metadata
// must be fetched.
//
// If this promise is rejected, dataset metadata
// failed to fetch.
export function getSoqlVifValidator(vif) {
  const metadataRequests = (vif.series || []).
    map((series) => {
      let metadataPromise;

      if (_.get(series, 'dataSource.type') === 'socrata.soql') {
        const metadataProviderConfig = {
          domain: _.get(series, 'dataSource.domain'),
          datasetUid: _.get(series, 'dataSource.datasetUid')
        };

        metadataPromise = new MetadataProvider(metadataProviderConfig, true).
          getDatasetMetadata();
      } else {
        metadataPromise = Promise.resolve({});
      }

      return metadataPromise;
    });

  return Promise.all(metadataRequests).
    then((metadataPerSeries) => soqlVifValidator(vif, metadataPerSeries));
}

// Checks a VIF data source for compatibility with a set of
// requirements (usually called by visualizations).
// The intent of this function is to provide feedback while
// authoring a visualization, not to provide feedback to a developer.
// As such, messages returned are worded to make sense to a user.
//
// Returns a chaining object. Example usage:
// var validation = soqlVifValidator(vif, datasetMetadata).
//   requireSingleSeries().
//   requireNumericDimension().
//   validate();
// if (!validation.ok) {
//   alert(validation.vifValidatorErrors.join('\n');
// }
//
// Methods:
//
// .requireAtLeastOneSeries()
//   Requires at least one series.
// .requireExactlyOneSeries()
//   Requires exactly one series.
// .requireAllSeriesFromSameDomain()
//   Requires all series to be sourced from the same domain.
// .requireNoMeasureAggregation()
//   Requires that all measure columns are NOT aggregated in some way (count(*) counts
//   as an aggregation and therefore does not satisfy the condition).
// .requireMeasureAggregation()
//   Requires that all measure columns are aggregated in some way (count(*) counts
//   as an aggregation).
// .requireCalendarDateDimension()
//   Requires all dimension columns to be time.
// .requirePointDimension()
//   Requires all dimension columns to be location.
// .requireNumericDimension()
//   Requires all dimension columns to be numeric.
// .requireUniformDataSourceType()
//   Requires that all data sources have the same type. I.e.,
//   mixing an inline and a soql data source is *not* valid.
// .validate()
//   If the VIF conforms to the requirements, { ok: true } is returned.
//   If the VIF does not conform, an object is returned:
//   {
//     ok: false,
//     vifValidatorErrors: Array<String>
//   }
// .toPromise()
//   Wraps the return value of .validate() in a promise.
//   If the VIF conforms to the requirements, the promise is
//   resolved. If not, the error object from .validate() is used
//   as a rejection.
export function soqlVifValidator(vif, datasetMetadataPerSeries) {
  const vifValidatorErrors = [];
  let useGenericErrorViz = false;

  const addError = (errorMessage) => {
    if (!vifValidatorErrors.includes(errorMessage)) {
      vifValidatorErrors.push(errorMessage);
    }
  };
  const allSeries = _.get(vif, 'series', []);
  const getColumn = (columnName, seriesIndex) => {

    const column = _.find(
      datasetMetadataPerSeries[seriesIndex].columns,
      { fieldName: columnName }
    );

    if (_.isUndefined(column)) {

      throw new Error(
        `[soqlVifValidator] column "${columnName}" does not exist in dataset.`
      );
    }

    return column;
  };

  const hasColumnWithType = (type, seriesIndex) => {
    const column = _.find(datasetMetadataPerSeries[seriesIndex].columns, (c) => c.renderTypeName === type);
    return !_.isUndefined(column);
  };

  if (allSeries.length !== datasetMetadataPerSeries.length) {
    throw new Error('vif.series.length does not match datasetMetadataPerSeries.length');
  }

  allSeries.forEach((series) => {
    const dataSourceType = _.get(series, 'dataSource.type');

    switch (dataSourceType) {

      case 'socrata.soql':
      case 'socrata.inline':
      case 'socrata.sample':
        break;

      default:
        throw new Error(`Cannot validate unknown dataSource type: ${dataSourceType}`);
    }
  });

  const validator = {

    requireAtLeastOneSeries() {
      if (allSeries.length === 0) {
        addError(I18n.t('need_at_least_one_series', errorScope));
      }
      return validator;
    },

    requireXAndYMeasureColumn() {
      const xMeasureColumn = _.get(allSeries[0], 'dataSource.measure.columnName');
      const yMeasureColumn = _.get(allSeries[1], 'dataSource.measure.columnName');
      const vizType = _.get(allSeries[0], 'type');

      if (_.isNil(xMeasureColumn) || _.isNil(yMeasureColumn)) {
        if (vizType === 'scatterChart') {
          useGenericErrorViz = true;
          addError(I18n.t('scatter_plot_need_x_and_y_axis', errorScope));
        } else {
          addError(I18n.t('need_x_and_y_axis', errorScope));
        }
      }

      return validator;
    },

    requireExactlyOneSeries() {
      if (allSeries.length !== 1) {
        addError(I18n.t('need_single_series', errorScope));
      }
      return validator;
    },

    requireAllSeriesFromSameDomain() {
      const allDomains = allSeries.map((series) => _.get(series, 'dataSource.domain'));
      const uniqDomains = _.uniq(allDomains);
      if (uniqDomains.length > 1) {
        addError(I18n.t('need_all_series_from_same_domain', errorScope));
      }
      return validator;
    },

    requireUniformDataSourceType() {
      const allTypes = _.map(allSeries, 'dataSource.type');
      const uniqTypes = _.uniq(allTypes);
      if (uniqTypes.length > 1) {
        addError(I18n.t('need_all_series_from_same_data_source_type', errorScope));
      }
      return validator;
    },

    requireNoMeasureAggregation() {
      allSeries.forEach((series) => {
        if (_.get(series, 'dataSource.measure.aggregationFunction')) {
          addError(I18n.t('need_no_aggregation', errorScope));
        }
      });
      return validator;
    },

    requireMeasureAggregation() {
      allSeries.forEach((series) => {
        if (!_.get(series, 'dataSource.measure.aggregationFunction')) {
          addError(I18n.t('need_aggregation', errorScope));
        }
      });
      return validator;
    },

    requirePointDimension() {
      allSeries.forEach((series, seriesIndex) => {
        const columnName = _.get(series, 'dataSource.dimension.columnName');
        const renderTypeName = getColumn(columnName, seriesIndex).renderTypeName;
        if (renderTypeName !== 'point') {
          if (hasColumnWithType('point', seriesIndex)) {
            addError(I18n.t('dimension_column_should_be_point', errorScope));
          } else {
            addError(I18n.t('dataset_does_not_include_point_column', errorScope));
          }
        }
      });
      return validator;
    },

    requireCalendarDateDimension() {
      allSeries.forEach((series, seriesIndex) => {
        const columnName = _.get(series, 'dataSource.dimension.columnName');
        const renderTypeName = getColumn(columnName, seriesIndex).renderTypeName;
        if (renderTypeName !== 'calendar_date') {
          if (hasColumnWithType('calendar_date', seriesIndex)) {
            addError(I18n.t('dimension_column_should_be_calendar_date', errorScope));
          } else {
            addError(I18n.t('dataset_does_not_include_calendar_date_column', errorScope));
          }
        }
      });
      return validator;
    },

    requireNumericDimension() {
      allSeries.forEach((series, seriesIndex) => {
        const columnName = _.get(series, 'dataSource.dimension.columnName');
        const renderTypeName = getColumn(columnName, seriesIndex).renderTypeName;
        const vizType = _.get(series, 'type');

        if (renderTypeName !== 'number' && renderTypeName !== 'money') {
          if (vizType === 'histogram') {
            useGenericErrorViz = true;
            addError(I18n.t('histogram_dimension_column_should_be_numeric', errorScope));
          } else if (hasColumnWithType('number', seriesIndex) || hasColumnWithType('money', seriesIndex)) {
            addError(I18n.t('dimension_column_should_be_numeric', errorScope));
          } else {
            addError(I18n.t('dataset_does_not_include_numeric_column', errorScope));
          }
        }
      });
      return validator;
    },

    validate() {
      if (vifValidatorErrors.length > 0) {
        return { ok: false, vifValidatorErrors, useGenericErrorViz };
      } else {
        return { ok: true };
      }
    },
    toPromise() {
      const validation = validator.validate();
      if (validation.ok) {
        return Promise.resolve();
      } else {
        return Promise.reject(validation);
      }
    }
  };

  return validator;
}
