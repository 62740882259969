import _ from 'lodash';
import d3 from 'd3';

const PRECISON = { MIN: 2, MAX: 7 };

export function getLinearBuckets(max, min, dataClasses, columnPrecison = PRECISON.MIN) {
  [min, max] = getMinAndMaxValue([min, max]);

  const rangeDiff = Math.abs(max - min);
  let bucketSize = rangeDiff / dataClasses;
  const roundOffValue = roundOffDecimalDigits(bucketSize, columnPrecison);
  bucketSize = roundOffValue === 0 ? bucketSize : roundOffValue;
  const precison = roundOffValue === 0 ? PRECISON.MAX : columnPrecison;

  const intermediateValues = _.map(_.range(1, dataClasses), (bucketIndex) => {
    return roundOffDecimalDigits(min + (bucketIndex * bucketSize), precison);
  });

  return [min, ...intermediateValues, max];
}

export function getLinearBucketValueForFeature(rangeOptions = {}) {
  const { dataClasses, featureValue, minValue, maxValue, resizeByRange } = rangeOptions;

  if (_.isNull(featureValue)) {
    return minValue;
  }

  if (minValue === maxValue) {
    return minValue;
  }

  if (resizeByRange.max === resizeByRange.min) {
    return (minValue + maxValue) / 2;
  }

  const valueBuckets = getLinearBuckets(resizeByRange.max, resizeByRange.min, dataClasses);
  const diameterBuckets = getLinearBuckets(maxValue, minValue, dataClasses - 1);

  const bucketValue = _.find(diameterBuckets, (diameterBucket, index) => {
    const isLastBucket = index === (diameterBuckets.length - 1);
    const bucketStartVaue = valueBuckets[index];
    const bucketEndValue = valueBuckets[index + 1];

    if (isLastBucket) {
      return featureValue >= bucketStartVaue && featureValue <= bucketEndValue;
    }

    return featureValue >= bucketStartVaue && featureValue < bucketEndValue;
  });

  return _.isNil(bucketValue) ? minValue : bucketValue;
}

export function getMinAndMaxValue(values) {
  let min = _.min(values);
  let max = _.max(values);

  if (min === max) {
    if (min === 0 && max === 0) {
      return [0, 1];
    }

    return max > 0 ? [0, max] : [min, 0];
  }

  return [min, max];
}

// Rounds off numbers to nice round values using d3's nice function.
// For ex:
//    niceRoundOff(0.92342) => 1
//    niceRoundOff(19.7899) => 20
//    niceRoundOff(59) => 60
//    niceRoundOff(1127) => 1200
//    niceRoundOff(1333333) => 1400000
export function niceRoundOff(value) {
  return d3.scale.linear().domain([0, value]).nice().domain()[1];
}

export function roundOffDecimalDigits(value, precison) {
  return parseFloat(value.toFixed(precison));
}

export const getMidpointRangeBuckets = ({
  min,
  max,
  midpoint,
  dataClasses,
  columnPrecison = PRECISON.MIN
}) => {
  const newRange = Math.max((max - midpoint), (midpoint - min));
  const bucketSize = (newRange * 2) / dataClasses;
  const roundOffValue = roundOffDecimalDigits(bucketSize, columnPrecison);
  const precison = roundOffValue === 0 ? PRECISON.MAX : columnPrecison;
  const start = roundOffDecimalDigits(midpoint - newRange, precison);
  const end = roundOffDecimalDigits(midpoint + newRange, precison);

  const intermediateValues = _.map(_.range(1, dataClasses), (bucketIndex) => {
    return roundOffDecimalDigits(start + (bucketIndex * bucketSize), precison);
  });

  return [start, ...intermediateValues, end];
};
