import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { BaseVisualization, Annotation, LegendGroup } from './types';
import * as Legend from './Legend';
import { Vif } from 'common/visualizations/vif';
import { newVizCardLayoutEnabled } from 'common/visualizations/helpers/VifSelectors';
import { setLegendMenuVisibility } from 'common/components/CommonFilterHelpers';

/**
 * This is the collapsible legend that appears in the bottom right of the chart.

 */

export const template = (): JQuery => (
  $('<div>', { 'class': 'socrata-visualization-legend-bar-container' })
);

export const renderLegendBar = (self: BaseVisualization, legendGroups: LegendGroup[], annotations: Annotation[] = []) => {
  self.$container.addClass('socrata-visualization-legend-bar');

  // Empty legend bar
  const $legendBarContainer = self.$container.find('.socrata-visualization-legend-bar-container');
  $legendBarContainer.empty();

  // Inner container
  const $innerContainer = $('<div>', { 'class': 'socrata-visualization-legend-bar-inner-container' });

  if (newVizCardLayoutEnabled(self.getVif())) {
    $innerContainer.addClass('forge-legend-bar-inner-container');
  }
  $legendBarContainer.append($innerContainer);

  // Button
  const $button = $('<button>', { 'class': 'socrata-legend-button' }).
    append($('<label>').text(I18n.t('shared.visualizations.charts.common.legend')));

  if (newVizCardLayoutEnabled(self.getVif())) {
    $button.addClass('forge-legend-button')
      .append($('<forge-icon>', { 'class': 'arrow-up-icon' }).attr('name', 'keyboard_arrow_up'))
      .append($('<forge-icon>', { 'class': 'close-2-icon legend-button-display-none' }).attr('name', 'close'));
  } else {
    $button
      .append($('<span>', { 'class': 'arrow-up-icon' }))
      .append($('<span>', { 'class': 'close-2-icon legend-button-display-none' }));
  }

  $innerContainer.append($button);

  // Menu
  const $legendMenu = $('<div>', { 'class': 'socrata-legend-menu' });
  if (newVizCardLayoutEnabled(self.getVif())) {
    $legendMenu.addClass('forge-legend-menu');
  }

  if (_.isEmpty(legendGroups) && !_.isEmpty(annotations)) {
    Legend._renderLegendGroup({ items: [] }, $legendMenu, annotations);
  }

  legendGroups.forEach((legendGroup) => {
    Legend._renderLegendGroup(legendGroup, $legendMenu, annotations);
  });

  $innerContainer.append($legendMenu);

  ReactDOM.render(
    React.createElement(SocrataIcon, { name: IconName.ArrowUp }),
    $button.find('.arrow-up-icon')[0]
  );

  ReactDOM.render(
    React.createElement(SocrataIcon, { name: IconName.Close2 }),
    $button.find('.close-2-icon')[0]
  );

  if (getShowLegendOpened(self.getVif())) {
    toggleShowLegend($button, $legendMenu);
  }

  // Triggered during initial loading or when applying a filter
  setLegendMenuVisibility({ verticalFilterBarContainer: $legendMenu[0] as HTMLDivElement });

  // Set menu max height so it may scroll
  const containerHeight = self.$container.find('.socrata-visualization-container').height() || 0;
  const $socrataLegendButton = self.$container.find('.socrata-legend-button');
  const legendMenuMaxHeight = $socrataLegendButton.is(':visible') ?
    (containerHeight - ($socrataLegendButton.height() || 0)) :
    (containerHeight + 2); // slid up 1px and down 1px to cover borders

  $legendMenu.css({ 'max-height': legendMenuMaxHeight });
};

// Exported for testing
export const getShowLegendOpened = (vif: Vif) => _.get(vif, 'configuration.showLegendOpened', false);

export const removeLegendBar = ($container: JQuery) => {
  $container.removeClass('socrata-visualization-legend-bar');
  $container.find('.socrata-visualization-legend-bar-container').empty();
};

export const attachLegendBarEventHandlers = ($container: JQuery) => {
  $container.
    find('.socrata-legend-button').
    off('click').
    on('click', function(event) { // note: using function because we are using $(this)
      event.stopPropagation();
      const $menu = $container.find('.socrata-legend-menu');
      toggleShowLegend($(this), $menu);
    });
};

const toggleShowLegend = ($button: JQuery, $menu: JQuery) => {
  $menu.toggle().scrollTop(0);
  $button.find('.arrow-up-icon').toggleClass('legend-button-display-none');
  $button.find('.close-2-icon').toggleClass('legend-button-display-none');
};
